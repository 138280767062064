import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {tabDonationCodeSchema} from '../schemas'

export default {
  detail: makeEndpoint({
    path: 'users/tabs/:tabId/donation_code',
    method: 'GET',
    responseSchema: tabDonationCodeSchema,
  }),
  create: makeEndpoint({
    path: 'users/tabs/:tabId/donation_code',
    method: 'POST',
    bodySchema: z.object({code: z.string()}),
    responseSchema: tabDonationCodeSchema,
  }),
  validate: makeEndpoint({
    path: 'users/tabs/:tabId/donation_code/validate',
    method: 'GET',
    queryParamsSchema: z.object({code: z.string()}),
    responseSchema: z.object({
      status: z.enum(['available', 'unavailable']),
      available_codes: z.string().array(),
    }),
  }),
  update: makeEndpoint({
    path: 'users/tabs/:tabId/donation_code',
    method: 'PUT',
    bodySchema: z.object({code: z.string(), active: z.boolean()}).partial(),
    responseSchema: tabDonationCodeSchema,
  }),
  expire: makeEndpoint({
    path: 'users/tabs/:tabId/donation_code/expire',
    method: 'PATCH',
    responseSchema: tabDonationCodeSchema,
  }),
  delete: makeEndpoint({
    path: 'users/tabs/:tabId/donation_code',
    method: 'DELETE',
    responseSchema: z.void(),
  }),
}
