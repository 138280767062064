import PrimitiveRecaptcha from 'react-google-recaptcha'
import React from 'react'

export interface RecaptchaInstance extends PrimitiveRecaptcha {}

export interface RecaptchaProps
  extends Omit<
    React.ComponentPropsWithoutRef<typeof PrimitiveRecaptcha>,
    'sitekey'
  > {
  sitekey?: string
}

export const Recaptcha = React.forwardRef<RecaptchaInstance, RecaptchaProps>(
  (
    {
      size = 'invisible',
      badge = 'bottomright',
      sitekey = '6LdqOlMUAAAAAGEUIK5jzFytOGxS-sHfvQiIhFjn',
      ...restProps
    },
    forwardedRef,
  ) => (
    <PrimitiveRecaptcha
      ref={forwardedRef}
      size={size}
      badge={badge}
      sitekey={sitekey}
      {...restProps}
    />
  ),
)
