export {
  chunk,
  differenceWith,
  firstBy,
  groupBy,
  keys,
  unique,
  uniqueBy,
  last,
  sample,
  splice,
  swapIndices,
} from 'remeda'
export {sort} from 'fast-sort'

export const pureInsert = <T>(arr: T[], idx: number, element: T) => {
  const originArrHead = arr.slice(0, idx)
  const nextArrHead =
    originArrHead.length < idx
      ? [
          ...originArrHead,
          ...Array.from({length: idx - originArrHead.length}).map(
            () => undefined,
          ),
        ]
      : originArrHead

  return [...nextArrHead, element, ...arr.slice(idx + 1)] as T[]
}

export function difference<T>(a1: T[], a2: T[]) {
  const a2Set = new Set(a2)
  return a1.filter((x) => !a2Set.has(x))
}

export function intersection<T>(...arrays: readonly T[][]): readonly T[] {
  return arrays?.reduce((acc, currentArray) => {
    const intersectionSet = new Set(acc)
    return currentArray.filter((item) => intersectionSet.has(item))
  })
}

export function isArrayValuesIdentical(arr: any[]) {
  return new Set(arr).size === 1
}
