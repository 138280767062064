import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'
import {
  useManagerRole,
  useManagerRoleFolders,
} from 'src/components/ManageRoleProvider'
import {useFolderId} from 'src/components/FolderProvider'
import {ManagerSummaryModal} from 'src/components/ManagerSummaryModal'

import FoldersNavHeader from '../../FoldersNavHeader'

export const ManagerFoldersGroup = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => {
  const [managerRole] = useManagerRole()
  const managerRoleFolders = useManagerRoleFolders()
  return (
    <WebUI.VStack className={WebUI.cn('gap-2', className)} {...restProps}>
      <WebUI.VStack className="gap-4">
        <FoldersNavHeader folderTitle="Managed Folders" canCreate={false} />

        {managerRole && (
          <ManagerSummaryModal
            className="text-ds-xs uppercase"
            managerRole={managerRole}
          >
            {managerRole.name}
          </ManagerSummaryModal>
        )}
      </WebUI.VStack>
      {Util.sort(managerRoleFolders ?? [])
        .asc((f) => f.position)
        .map((folder) => (
          <ManagedFolderNavItem key={folder.id} folder={folder} />
        ))}
    </WebUI.VStack>
  )
}

// MARK: – ManagedFolderNavItem

// TODO: `FolderNavItem` is very similar to `ManagedFolderNavItem`
// may want to separate some stuff in a separate component

interface ManagedFolderNavItemProps
  extends React.ComponentPropsWithoutRef<'button'> {
  folder: Pick<Api.TabFolder, 'name'> & {id: number | null}
}

const ManagedFolderNavItem = ({
  folder,
  className,
  ...restProps
}: ManagedFolderNavItemProps) => {
  const [folderId, setFolderId] = useFolderId()
  return (
    <WebUI.Button
      aria-selected={(folderId ?? null) === folder.id}
      className={WebUI.cn(
        'h-[46px] justify-start whitespace-normal py-2 text-left hover:bg-teal-90 aria-busy:bg-teal-90 aria-selected:font-body',
        className,
      )}
      variant="ghost"
      as={WebUI.Droppable as any}
      dropData={folder}
      onClick={() => setFolderId(folder.id)}
      {...restProps}
    >
      <WebUI.Text className="grow text-gray800">{folder.name}</WebUI.Text>
    </WebUI.Button>
  )
}
