export {debounce, throttle} from '@github/mini-throttle'
export {identity} from 'remeda'
export {default as moize} from 'moize'
export type {Options as MoizeOptions} from 'moize'
export {default as memoize} from 'micro-memoize'

export function catchAsNull<T>(fn: () => T | null) {
  try {
    return fn()
  } catch {
    return null
  }
}
