import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import {useLiveRef} from '@cheddarup/react-util'
import React, {useEffect, useState} from 'react'

export interface ListingSelectProps
  extends React.ComponentPropsWithoutRef<'div'> {
  invalid?: boolean
  initialOptionValues?: Record<string, string | null> | null
  optionKeysOrdered: string[]
  listings: Api.VariantListing[]
  onListingUuidChange?: (listingUuid: string | null) => void
}

const ListingSelect = React.forwardRef<HTMLDivElement, ListingSelectProps>(
  (
    {
      invalid,
      initialOptionValues,
      optionKeysOrdered,
      listings,
      onListingUuidChange,
      'aria-invalid': ariaInvalid,
      className,
      ...restProps
    },
    forwardedRef,
  ) => {
    const [selectedOptionValues, setSelectedOptionValues] = useState(
      initialOptionValues ?? {},
    )
    const listingsRef = useLiveRef(listings)
    const onListingUuidChangeRef = useLiveRef(onListingUuidChange)

    const possibleOptionValues = listings.map((l) => l.optionValues)
    const options = possibleOptionValues.reduce<Record<string, string[]>>(
      (acc, optionValues) => ({
        ...acc,
        ...Util.mapValues(optionValues, (v, k) =>
          Util.unique([...(acc[k] ?? []), v]),
        ),
      }),
      {},
    )
    const isSingleVariant = Object.keys(options).length === 1
    const shouldDisplayOptionAmount = isSingleVariant
      ? listings.some((l) => listings[0]?.amount !== l.amount)
      : false

    useEffect(() => {
      const nextLising = listingsRef.current.find((l) =>
        Util.deepEqual(l.optionValues, selectedOptionValues),
      )
      onListingUuidChangeRef.current?.(nextLising?.uuid ?? null)
    }, [selectedOptionValues])

    return (
      <div
        aria-invalid={ariaInvalid ?? invalid}
        ref={forwardedRef}
        className={WebUI.cn('flex flex-col gap-8', className)}
        {...restProps}
      >
        {Util.sort(Object.entries(options))
          .asc(([key]) => optionKeysOrdered.indexOf(key))
          .map(([key, values]) => (
            <WebUI.FormField key={key} required label={key} error={invalid}>
              <WebUI.DropdownSelect
                name={key}
                value={selectedOptionValues?.[key] ?? ''}
                onValueChange={(value) => {
                  setSelectedOptionValues((prevSelectedOptionValues) => {
                    const isAvailable = possibleOptionValues.some((ov) =>
                      Util.isMatch(ov, {...selectedOptionValues, [key]: value}),
                    )
                    const newSelectedOptionValue = {
                      [key]: value == null ? null : String(value),
                    }

                    return isAvailable
                      ? {...prevSelectedOptionValues, ...newSelectedOptionValue}
                      : newSelectedOptionValue
                  })
                }}
              >
                {values.map((v) => {
                  const isAvailable = possibleOptionValues.some((ov) =>
                    Util.isMatch(ov, {...selectedOptionValues, [key]: v}),
                  )
                  const variantPrice = shouldDisplayOptionAmount
                    ? listings.find((l) => l.optionValues[key] === v)?.amount
                    : null
                  const variantFormattedPrice = variantPrice
                    ? `(${Util.formatAmount(variantPrice)})`
                    : ''

                  return (
                    <WebUI.DropdownSelectOption
                      key={v}
                      className={isAvailable ? '' : 'opacity-50'}
                      value={v}
                    >
                      {`${v} ${variantFormattedPrice}`.trim()}
                    </WebUI.DropdownSelectOption>
                  )
                })}
              </WebUI.DropdownSelect>
            </WebUI.FormField>
          ))}
      </div>
    )
  },
)

export default ListingSelect
