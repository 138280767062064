import * as WebUI from '@cheddarup/web-ui'
import React from 'react'
import {useLocation} from 'react-router-dom'

import {useAlbums} from './hooks'
import {NavLink, NavLinkProps} from '../../components/Link'

const ImageAlbumsSidebar: React.FC<React.ComponentPropsWithoutRef<'div'>> = (
  props,
) => {
  const location = useLocation()
  const {userAlbums, partnerAlbums} = useAlbums()
  return (
    <WebUI.VStack {...props}>
      {partnerAlbums.length > 0 && (
        <AlbumNavItemList
          className="border-b text-teal-50"
          albums={partnerAlbums}
        />
      )}
      <AlbumNavLink
        to={{pathname: '../edit', search: location.search}}
        iconBefore={
          <WebUI.PhosphorIcon className="text-ds-2xl" icon="plus-square" />
        }
      >
        Design a Banner
      </AlbumNavLink>
      {userAlbums.length > 0 && (
        <AlbumNavItemList
          albums={userAlbums}
          icon={<WebUI.PhosphorIcon icon="image" width={32} />}
        />
      )}
    </WebUI.VStack>
  )
}

// MARK: – AlbumNavItemList

interface AlbumNavItemListProps extends React.ComponentPropsWithoutRef<'div'> {
  albums: ReturnType<typeof useAlbums>['userAlbums']
  icon?: React.ReactNode
}

const AlbumNavItemList: React.FC<AlbumNavItemListProps> = ({
  albums,
  icon,
  ...restProps
}) => {
  const location = useLocation()

  return (
    <WebUI.VStack {...restProps}>
      {albums.map((album) => (
        <AlbumNavLink
          key={album.id}
          iconBefore={icon}
          to={{pathname: `../albums/${album.id}`, search: location.search}}
        >
          {album.name}
        </AlbumNavLink>
      ))}
    </WebUI.VStack>
  )
}

// MARK: – AlbumNavLink

const AlbumNavLink = ({
  className,
  ...restProps
}: NavLinkProps & React.ComponentPropsWithoutRef<'a'>) => (
  <NavLink
    className={WebUI.cn(
      'justify-center px-8 py-4 text-ds-sm hover:text-inherit aria-current-page:bg-teal-80 aria-current-page:text-inherit',
      className,
    )}
    {...restProps}
  />
)

export default ImageAlbumsSidebar
