import {makeEndpoint} from '../utils'
import {z} from '@cheddarup/util'
import {headerListItemSchema} from '../schemas'

export default {
  list: makeEndpoint({
    path: 'user/header_images',
    responseSchema: headerListItemSchema.array(),
  }),
  delete: makeEndpoint({
    path: 'user/delete_image/:imageId',
    method: 'DELETE',
    responseSchema: z.any(),
  }),
}
