import * as WebUI from '@cheddarup/web-ui'

import {FormFieldInlineLabelEdit} from './FormFieldInlineLabelEdit'
import type {FieldGroupFieldsEditProps} from './FieldGroupFieldsEdit'

export interface TextFieldsEditValues {
  value: string
}

export const TextFieldsEdit = ({
  required,
  focused,
  initialLabels,
  placeholders,
  onLabelChange,
}: Omit<
  FieldGroupFieldsEditProps<'text'>,
  'fieldGroupType' | 'initialValue' | 'onValueChange'
>) => (
  <FormFieldInlineLabelEdit
    labelInputFocused={focused}
    required={required}
    initialLabel={initialLabels.value}
    placeholder={placeholders.value}
    onLabelChange={(label) => onLabelChange('value', label)}
  >
    <WebUI.Input className="sm:w-1/2" disabled />
  </FormFieldInlineLabelEdit>
)
