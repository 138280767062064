import * as WebUI from '@cheddarup/web-ui'

export interface LayoutDescriptionFieldsEditProps
  extends Omit<
    WebUI.RichTextEditorProps,
    'initialMarkdownValue' | 'onMarkdownValueChange'
  > {
  value: string
  onValueChange: (value: string) => void
}

export const LayoutDescriptionFieldsEdit = ({
  value,
  onValueChange,
  className,
  readOnly = false,
  ...restProps
}: LayoutDescriptionFieldsEditProps) => (
  <WebUI.RichTextEditor
    className={WebUI.cn(
      'LayoutDescriptionFieldsEdit',
      'm-8 min-h-[auto] data-[read-only=false]:min-h-[200px] data-[read-only=false]:bg-natural-100 data-[read-only=true]:shadow-none',
      className,
    )}
    autoFocus={!readOnly}
    readOnly={readOnly}
    initialMarkdownValue={value}
    placeholder="Click to edit this text"
    onMarkdownValueChange={(newMarkdownValue) =>
      onValueChange(newMarkdownValue)
    }
    {...restProps}
  >
    {!readOnly && (
      <WebUI.RichTextEditorToolbar rootClassName="LayoutDescriptionFieldsEdit-toolbar -order-1" />
    )}
  </WebUI.RichTextEditor>
)
