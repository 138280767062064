import {ArrayParam, useQueryParam, withDefault} from 'use-query-params'
import React, {useMemo} from 'react'
import * as WebUI from '@cheddarup/web-ui'

export interface CategoryPathProps
  extends React.ComponentPropsWithoutRef<'div'> {
  categories: Api.PublicTabCategory[]
}

const CategoryPath = ({
  categories,
  className,
  ...restProps
}: CategoryPathProps) => {
  const [categoryPath, setCategoryPath] = useQueryParam(
    'categoryPath',
    withDefault(ArrayParam, []),
  )
  const crumbClassName = 'cursor-pointer'
  const [categoryId, subcategoryId] = categoryPath

  const category = categories.find((c) => String(c.id) === categoryId)
  const subcategory = (category?.options.subcategories ?? []).find(
    (sc) => sc.uuid === subcategoryId,
  )
  const crumbs = [
    {key: 'ALL', title: 'Shop All'},
    category ? {key: category.id, title: category.name} : null,
    subcategory ? {key: subcategory.uuid, title: subcategory.name} : null,
  ].filter((c) => !!c)
  const isMobile = useMemo(() => {
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0,
    )
    return vw < 480
  }, [])

  return (
    <div
      className={WebUI.cn('py-4 text-ds-xs sm:text-ds-sm', className)}
      {...restProps}
    >
      {crumbs.map((crumb, idx) => {
        const handleClick = () => {
          if (crumb.key === 'ALL') {
            setCategoryPath([])
          } else if (crumb.key === categoryId) {
            setCategoryPath([String(categoryId)])
          } else {
            setCategoryPath([String(categoryId), subcategoryId ?? null])
          }
        }

        const trailing = idx === crumbs.length - 1

        return (
          <React.Fragment key={crumb.key}>
            {idx === 0 && (
              <span
                className={WebUI.cn(
                  crumbClassName,
                  trailing && 'font-semibold',
                )}
                onClick={handleClick}
              >
                {crumb.title}
              </span>
            )}

            {idx === 1 && category?.options?.subcategories && (
              <WebUI.Menu>
                <WebUI.MenuButton
                  className={WebUI.cn(
                    crumbClassName,
                    trailing && 'font-semibold',
                  )}
                  variant="text"
                >
                  {isMobile
                    ? crumb.title.length > 22
                      ? `${crumb.title.slice(0, 22)}...`
                      : crumb.title
                    : crumb.title}
                </WebUI.MenuButton>

                <WebUI.MenuList>
                  {category.options.subcategories.map((subCategory) => (
                    <WebUI.MenuItem
                      key={subCategory.uuid}
                      onClick={() =>
                        setCategoryPath([String(categoryId), subCategory.uuid])
                      }
                    >
                      {isMobile
                        ? subCategory.name.length > 22
                          ? `${subCategory.name.slice(0, 22)}...`
                          : subCategory.name
                        : subCategory.name}
                    </WebUI.MenuItem>
                  ))}
                </WebUI.MenuList>
              </WebUI.Menu>
            )}

            {idx === 1 &&
              category?.options &&
              !category.options.subcategories && (
                <span
                  className={WebUI.cn(
                    crumbClassName,
                    trailing && 'font-semibold',
                  )}
                >
                  {isMobile
                    ? crumb.title.length < 8
                      ? crumb.title
                      : `${crumb.title.slice(0, 8)}...`
                    : crumb.title}
                </span>
              )}

            {idx === 2 && (
              <span
                className={WebUI.cn(
                  crumbClassName,
                  trailing && 'font-semibold',
                )}
                onClick={handleClick}
              >
                {isMobile
                  ? crumb.title.length < 8
                    ? crumb.title
                    : `${crumb.title.slice(0, 8)}...`
                  : crumb.title}
              </span>
            )}

            {!trailing && <span className="mx-4">{'>'}</span>}
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default CategoryPath
