import React, {useState} from 'react'
import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import {PremiumFeaturesSideSheet} from 'src/components/PremiumFeaturesSideSheet'
import {api} from '@cheddarup/api-client'

import {ItemSettingDisclosureSwitch} from '../../components/ItemSettingsDisclosureSwitch'
import type {DonationItemFormFormik} from './DonationItemForm'
import {ItemSettingsUpgradePlanButton} from '../../components/ItemSettingsUpgradePlanButton'
import {FormSettingsSwitch} from 'src/components'
import {CollectionSettingsUpgradePlanButton} from 'src/views/collection/build/CollectionSettingsPage/CollectionSettingsUpgradePlanButton'
import {EinVerificationInfoModal} from 'src/views/account/settings/AccountDetailsPage'

export interface DonationItemFormSettingsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  formik: DonationItemFormFormik
  tabId: number
  item?: Api.TabItem
}

const DonationItemFormSettings: React.FC<DonationItemFormSettingsProps> = ({
  formik,
  tabId,
  item,
  className,
  ...restProps
}) => {
  const [enforcedPremiumMeta, setEnforcedPremiumMeta] = useState<
    Util.PartialDeep<Api.TabPremiumMeta>
  >({})
  const {data: collection} = api.tabs.detail.useQuery({
    pathParams: {
      tabId,
    },
  })
  const isBasic = !collection?.is_pro
  const collectionPublished = collection && collection.status !== 'draft'

  const fundraisingGoalLabel = 'Set a fundraising goal for this item'
  const fundraisingGoalDescription =
    'Motivate donors to give with a real-time progress bar toward your fundraising goal.'
  const textduductibleLabel = 'Show item as tax deductible'
  const textDuductibleDescription = (
    <div className="flex flex-col gap-2">
      <span>
        Your account has been verified as a 501(c)(3). If this item is tax
        deductible, Team plan users can toggle this option, and we will send a
        tax-deductible receipt to anyone who makes a payment.
      </span>
      <span>
        <EinVerificationInfoModal
          disclosure={
            <WebUI.DialogDisclosure
              variant="link"
              className="[&_>_.Text]:font-light"
            >
              Learn more
            </WebUI.DialogDisclosure>
          }
        />{' '}
        about displaying your “verified Non-Profit” badge on your collections!
      </span>
    </div>
  )

  return (
    <PremiumFeaturesSideSheet
      enforcedPremiumMeta={enforcedPremiumMeta}
      tabId={tabId}
    >
      <div
        className={WebUI.cn(
          '[&_>_.Separator]:-mx-2 flex flex-col gap-6',
          className,
        )}
        {...restProps}
      >
        <div className="flex flex-col gap-2">
          <WebUI.Disclosure
            className="gap-2"
            initialVisible={
              !!formik.values.options.donation.suggestedAmounts.buttonText
            }
            onVisibleChange={(visible) => {
              if (!visible) {
                formik.setFieldValue(
                  'options.donation.suggestedAmounts.buttonText',
                  null,
                )
              }
            }}
          >
            <ItemSettingDisclosureSwitch
              label="Customize “Enter Amount” button text"
              description="Include a custom call to action on your donation button."
            />

            <WebUI.DisclosureContent className="text-ds-sm">
              <WebUI.FormField
                className="mx-3"
                label="Button text"
                caption="12 character limit"
                required
              >
                <WebUI.Input
                  className="max-w-[320px]"
                  name="options.donation.suggestedAmounts.buttonText"
                  placeholder="Give Today!"
                  maxLength={12}
                  value={
                    formik.values.options.donation.suggestedAmounts.buttonText
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </WebUI.FormField>
            </WebUI.DisclosureContent>
          </WebUI.Disclosure>
        </div>
        <WebUI.Separator />

        <div className="flex flex-col gap-2">
          <FormSettingsSwitch
            name="available_quantity_enabled"
            title="Set available quantity"
            description="“Sold out” will show when all available items have been purchased."
            checked={formik.values.available_quantity_enabled}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.values.available_quantity_enabled && (
            <WebUI.FormField
              className="mx-3"
              label="Total Available"
              required
              error={formik.errors.available_quantity}
            >
              <WebUI.Input
                className="max-w-32"
                name="available_quantity"
                placeholder="Qty."
                type="number"
                value={formik.values.available_quantity}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </WebUI.FormField>
          )}
        </div>
        <WebUI.Separator />

        {formik.values.available_quantity_enabled && (
          <>
            <FormSettingsSwitch
              name="options.makeAvailableQuantityPublic"
              title="Make available quantity public"
              description="Payers will see how many items are left to purchase."
              checked={formik.values.options.makeAvailableQuantityPublic}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <WebUI.Separator />
          </>
        )}
        <FormSettingsSwitch
          name="options.makeTotalCollectedPublic"
          title="Make total collected public"
          description="Display the total collected for this donation."
          checked={formik.values.options.makeTotalCollectedPublic}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <WebUI.Separator />
        <WebUI.Disclosure
          className="gap-3"
          visible={formik.values.options.fundraisingGoal.enabled}
          onVisibleChange={(newPaymentGoalEnabled) => {
            formik.setFieldValue(
              'options.fundraisingGoal.enabled',
              newPaymentGoalEnabled,
            )
            setEnforcedPremiumMeta((prevEnforcedPremiumMeta) => ({
              ...prevEnforcedPremiumMeta,
              pro: {
                ...prevEnforcedPremiumMeta?.pro,
                fundraisingGoal: newPaymentGoalEnabled,
              },
            }))
          }}
        >
          {isBasic && collectionPublished ? (
            <ItemSettingsUpgradePlanButton
              description={fundraisingGoalDescription}
              plan="pro"
            >
              {fundraisingGoalLabel}
            </ItemSettingsUpgradePlanButton>
          ) : (
            <ItemSettingDisclosureSwitch
              featureKey={isBasic ? 'fundraisingGoal' : undefined}
              label={fundraisingGoalLabel}
              description={fundraisingGoalDescription}
            />
          )}

          <WebUI.DisclosureContent className="text-ds-sm">
            <div className="flex flex-col gap-2">
              <WebUI.FormField
                label="Goal Amount:"
                required
                error={formik.errors.options?.fundraisingGoal?.value}
              >
                <WebUI.AmountInput
                  className="max-w-[256px]"
                  name="options.fundraisingGoal.value"
                  placeholder="$0"
                  value={formik.values.options.fundraisingGoal.value}
                  onValueChange={(newValue) =>
                    formik.setFieldValue(
                      'options.fundraisingGoal.value',
                      newValue,
                    )
                  }
                  onBlur={formik.handleBlur}
                />
              </WebUI.FormField>
            </div>
          </WebUI.DisclosureContent>
        </WebUI.Disclosure>
        {collection?.organizer.verifiedNonProfitStatus === 'verified' && (
          <>
            <WebUI.Separator />
            {collection?.is_team ? (
              <FormSettingsSwitch
                className="items-start"
                name="options.isTaxDeductible"
                checked={formik.values.options.isTaxDeductible}
                onChange={formik.handleChange}
                title={textduductibleLabel}
                description={textDuductibleDescription}
              />
            ) : (
              <CollectionSettingsUpgradePlanButton
                className="items-start"
                plan="team"
                asPaidBadge
              >
                <div className="flex flex-col gap-2">
                  <WebUI.Text>{textduductibleLabel}</WebUI.Text>
                  <span className="text-ds-sm">
                    {textDuductibleDescription}
                  </span>
                </div>
              </CollectionSettingsUpgradePlanButton>
            )}
          </>
        )}
      </div>
    </PremiumFeaturesSideSheet>
  )
}

export default DonationItemFormSettings
