import * as Yup from 'yup'
import React from 'react'
import {useFormik} from '@cheddarup/react-util'
import * as WebUI from '@cheddarup/web-ui'
import CartHelpers from 'src/helpers/CartHelpers'
import {api} from '@cheddarup/api-client'
import {useAuthToken, useIsAuthed} from 'src/hooks/useAuthToken'
import {useLogout} from 'src/hooks/useAuth'

import usePublicCollection from '../hooks/usePublicCollection'
import useCart, {useEnhancedUpdateCartMutation} from '../hooks/useCart'
import {useActiveCheckoutStep} from '../CheckoutPage/CheckoutPageFlowContext'
import {CheckoutSteppedPanel} from './CheckoutSteppedPanel'

export interface AuthedPanelProps
  extends React.ComponentPropsWithoutRef<'div'>,
    Pick<LogoutViewProps, 'onDidLogout' | 'onResetGuestMode'> {
  onDidSubmitPhoneNumber?: () => void
}

export const AuthedPanel = ({
  onDidLogout,
  onResetGuestMode,
  onDidSubmitPhoneNumber,
  className,
  ...restProps
}: AuthedPanelProps) => {
  const isAuthed = useIsAuthed()
  const {publicCollection} = usePublicCollection()
  const {cart} = useCart()
  const hasFullNumberQuery = api.auth.session.useQuery(undefined, {
    enabled: isAuthed,
    select: (session) => !!session.user.profile.phone.fullNumber,
  })
  const isActive = useActiveCheckoutStep() === 'auth'

  const isQuickCheckout =
    !CartHelpers.isPaymentRequired({cart}) &&
    !publicCollection.shippingOptions.shipToEnabled

  return (
    <div className={WebUI.cn('flex flex-col gap-3', className)} {...restProps}>
      <CheckoutSteppedPanel
        step="auth"
        heading={cart && cart.itemCount > 0 ? 'Checkout' : 'Submit'}
        expanded
      >
        <LogoutView
          onDidLogout={onDidLogout}
          onResetGuestMode={onResetGuestMode}
        />
      </CheckoutSteppedPanel>
      {isActive &&
        !isQuickCheckout &&
        hasFullNumberQuery.data === false &&
        cart &&
        !cart.phoneNumber && (
          <CheckoutSteppedPanel step="auth">
            <PhoneNumberForm onDidSubmit={onDidSubmitPhoneNumber} />
          </CheckoutSteppedPanel>
        )}
    </div>
  )
}

// MARK: – LogoutView

interface LogoutViewProps extends React.ComponentPropsWithoutRef<'div'> {
  onDidLogout?: () => void
  onResetGuestMode?: () => void
}

const LogoutView = ({
  onDidLogout,
  onResetGuestMode,
  className,
  ...restProps
}: LogoutViewProps) => {
  const isAuthed = useIsAuthed()
  const [authToken] = useAuthToken()
  const {cart} = useCart()
  const [logout, logoutMutation] = useLogout()

  const userEmail =
    (isAuthed && authToken?.claims?.data.payload.tokenType === 'User'
      ? authToken.claims?.data.payload.user?.email
      : '') || cart?.member?.email

  return (
    <div
      className={WebUI.cn(
        'flex flex-row items-baseline gap-2 text-ds-sm',
        className,
      )}
      {...restProps}
    >
      <WebUI.Text>{userEmail ?? ''}</WebUI.Text>
      {isAuthed ? (
        <WebUI.Button
          variant="link"
          disabled={logoutMutation.isPending}
          onClick={async () => {
            await logout()
            onDidLogout?.()
          }}
        >
          Log out
        </WebUI.Button>
      ) : (
        <WebUI.Button variant="link" onClick={() => onResetGuestMode?.()}>
          Edit
        </WebUI.Button>
      )}
    </div>
  )
}

// MARK – PhoneNumberForm

interface PhoneNumberFormValues {
  phoneNumber: string
}

interface PhoneNumberFormProps extends React.ComponentPropsWithoutRef<'div'> {
  onDidSubmit?: () => void
}

const PhoneNumberForm: React.FC<PhoneNumberFormProps> = ({
  onDidSubmit,
  className,
  ...restProps
}) => {
  const {cart} = useCart()
  const [, updateCartAsync] = useEnhancedUpdateCartMutation()

  const formik = useFormik<PhoneNumberFormValues>({
    validationSchema: Yup.object().shape({
      phoneNumber: Yup.string().required('Required'),
    }),
    initialValues: {
      phoneNumber: cart?.phoneNumber ?? '',
    },
    onSubmit: async (values, formikHelpers) => {
      if (!WebUI.isPossiblePhoneNumber(values.phoneNumber)) {
        formikHelpers.setFieldError('phoneNumber', 'Invalid format')
        return
      }

      try {
        await updateCartAsync({
          body: {
            phoneNumber: values.phoneNumber || undefined,
          },
        })

        onDidSubmit?.()
      } catch {
        // noop
      }
    },
  })

  return (
    <div className={WebUI.cn('flex flex-col gap-4', className)} {...restProps}>
      <WebUI.Text className="font-light">
        We’ll use this information to send a receipt and for billing purposes.
      </WebUI.Text>

      <form
        className="flex flex-col gap-4"
        onReset={formik.handleReset}
        onSubmit={formik.handleSubmit}
      >
        <WebUI.FormField
          required
          label="Phone"
          error={formik.errors.phoneNumber}
        >
          <WebUI.PhoneInput
            name="phoneNumber"
            countries={WebUI.ALLOWED_COUNTRY_CODES}
            value={formik.values.phoneNumber}
            onChange={(newPhoneNumber) =>
              formik.setFieldValue('phoneNumber', newPhoneNumber)
            }
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>

        <WebUI.Button
          className="self-start"
          type="submit"
          loading={formik.isSubmitting}
          roundness="capsule"
        >
          Save
        </WebUI.Button>
      </form>
    </div>
  )
}
