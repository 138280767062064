import {debounce} from '@cheddarup/util'
import {
  ForwardRefComponent,
  useLiveRef,
  useUpdateEffect,
} from '@cheddarup/react-util'
import {forwardRef, useEffect, useMemo, useState} from 'react'

import {Input} from './Input'
import {cn} from '../utils'

export interface DeferredInputProps {
  commitValue?: (newValue: string) => void
}

export const DeferredInput = forwardRef(
  (
    {
      commitValue,
      as: Comp = Input,
      defaultValue: defaultValueProp,
      value: valueProp,
      className,
      onChange,
      ...restProps
    },
    forwardedRef,
  ) => {
    const initialValue = valueProp ?? defaultValueProp ?? ''

    const [pendingValue, setPendingValue] = useState(initialValue)
    const commitValueRef = useLiveRef(commitValue)

    const debouncedHandleChange = useMemo(
      () =>
        debounce((newValue: typeof pendingValue) => {
          if (initialValue === newValue) {
            return
          }
          commitValueRef.current?.(String(newValue))
        }, 500),
      [initialValue],
    )

    useUpdateEffect(() => {
      debouncedHandleChange(pendingValue)
    }, [debouncedHandleChange, pendingValue])

    useEffect(
      () => () => debouncedHandleChange.cancel(),
      [debouncedHandleChange],
    )

    return (
      <Comp
        ref={forwardedRef}
        className={cn('DeferredInput', className)}
        value={pendingValue}
        onChange={(event) => {
          onChange?.(event)
          if (event.defaultPrevented) {
            return
          }

          setPendingValue(event.target.value)
        }}
        {...restProps}
      />
    )
  },
) as ForwardRefComponent<typeof Input, DeferredInputProps>
