import * as WebUI from '@cheddarup/web-ui'
import React from 'react'
import {api} from '@cheddarup/api-client'
import * as Util from '@cheddarup/util'
import {Link} from 'src/components/Link'
import {SharpAvatar, SharpAvatarProps} from 'src/components/SharpAvatar'

export interface PaymentOverviewSideSheetProps extends WebUI.ModalProps {
  customerPayment: Api.CustomerByEmailPayment | null | undefined
}

export const PaymentOverviewSideSheet = ({
  className,
  customerPayment,
  ...restProps
}: PaymentOverviewSideSheetProps) => {
  const paymentQuery = api.tabPayments.detail.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: customerPayment?.collection?.id!,
        // biome-ignore lint/style/noNonNullAssertion:
        paymentId: customerPayment?.id!,
      },
      headers: {
        // biome-ignore lint/style/noNonNullAssertion:
        'User-Id': customerPayment?.organizer?.uuid!,
      },
    },
    {
      enabled: !!customerPayment,
    },
  )
  return (
    <WebUI.Modal
      aria-label="Payment details"
      className={WebUI.cn(
        '[&_>_.SideSheetContentView]:w-[min(100%,600px)]',
        className,
      )}
      initialVisible={false}
      contentViewAppearance="rightToLeft"
      {...restProps}
    >
      <WebUI.ModalCloseButton />
      {paymentQuery.data && (
        <WebUI.VStack className="gap-4 overflow-y-auto px-6 py-12 *:flex-0">
          <PaymentHeader paymentData={paymentQuery.data} />
          <CustomerContainer
            userName={paymentQuery.data?.tab_member.name ?? ''}
            image={paymentQuery.data?.tab_member.profile_pic}
            email={paymentQuery.data?.tab_member.email ?? ''}
          />
          {Object.keys(paymentQuery.data?.shipping_info?.shipTo ?? {}).length >
            0 && (
            <ShippingInformation
              shipTo={paymentQuery.data.shipping_info.shipTo}
            />
          )}
          <CustomerPaymentDetails
            payment={paymentQuery.data}
            customerPayment={customerPayment}
          />
          <ItemPurchased data={paymentQuery.data} />
        </WebUI.VStack>
      )}
    </WebUI.Modal>
  )
}

// MARK: – PaymentHeader

interface PaymentHeaderProps extends React.ComponentPropsWithoutRef<'div'> {
  paymentData: Api.TabPayment
}

const PaymentHeader: React.FC<PaymentHeaderProps> = ({
  paymentData,
  className,
  ...restProps
}) => (
  <WebUI.HStack
    className={WebUI.cn('items-center font-normal', className)}
    as={WebUI.Panel}
    {...restProps}
  >
    <div className="pt-2 pr-8 pb-2 pl-4 text-5xl">
      Payment: {Util.formatAmount(paymentData?.total ?? 0)}
    </div>
    {!!paymentData?.status && (
      <WebUI.Tag className="bg-teal-70 text-ds-xs capitalize">
        {paymentData?.status}
      </WebUI.Tag>
    )}
  </WebUI.HStack>
)

// MARK: – CustomerContainer

interface CustomerContainerProps extends React.ComponentPropsWithoutRef<'div'> {
  email: string
  userName: string
  image: SharpAvatarProps['image']
}

const CustomerContainer: React.FC<CustomerContainerProps> = ({
  email,
  userName,
  image,
  ...restProps
}) => (
  <WebUI.VStack as={WebUI.Panel} {...restProps}>
    <div className="p-6 font-normal text-ds-md">Payer</div>
    <WebUI.Separator variant="primary" />
    <WebUI.HStack className="items-baseline justify-between px-6 py-4">
      <WebUI.HStack className="items-center gap-3">
        <SharpAvatar size={36} image={image} name={userName} />
        <div className="font-normal text-ds-sm">{userName}</div>
      </WebUI.HStack>
      {!!email && (
        <Link
          className="text-ds-sm"
          variant="primary"
          to={`/reporting/payers/${email}`}
        >
          View Details
        </Link>
      )}
    </WebUI.HStack>
  </WebUI.VStack>
)

// MARK: – ShippingInformation

interface ShippingInformationProps {
  shipTo: Api.PaymentShippingInfo['shipTo']
}

const ShippingInformation = ({shipTo}: ShippingInformationProps) => (
  <WebUI.VStack className="gap-4 p-6" as={WebUI.Panel}>
    <div className="font-normal text-ds-xs text-gray400">
      SHIPPING INFORMATION
    </div>
    <WebUI.HStack className="gap-4">
      <WebUI.PhosphorIcon icon="map-pin" className="text-ds-xl" />
      <WebUI.Ellipsis className="text-ds-sm">
        {shipTo.name}
        <br />
        {shipTo.city}
        <br />
        {shipTo.state}
        <br />
        {shipTo.country}
      </WebUI.Ellipsis>
    </WebUI.HStack>
  </WebUI.VStack>
)

// MARK: – CustomerPaymentDetails

export interface CustomerPaymentDetailsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  payment: Api.TabPayment
  customerPayment: Api.CustomerByEmailPayment | null | undefined
}

const CustomerPaymentDetails = ({
  payment,
  customerPayment,
  ...restProps
}: CustomerPaymentDetailsProps) => (
  <WebUI.VStack as={WebUI.Panel} {...restProps}>
    <div className="p-6 font-normal text-ds-md">Payment Details</div>
    <WebUI.Separator variant="primary" />
    <WebUI.VStack className="gap-4 p-6 font-normal text-gray400 [&_>_.Stack_>.Stack_>_*]:flex-[0_0_50%]">
      <WebUI.VStack className="text-ds-sm">
        <WebUI.HStack>
          <div>Collection Page</div>
          <div className="text-gray800">{customerPayment?.collection.name}</div>
        </WebUI.HStack>
        <WebUI.HStack>
          <div>Payer</div>
          <div className="text-gray800">{customerPayment?.organizer.name}</div>
        </WebUI.HStack>
        <WebUI.HStack>
          <div>Payment Date</div>
          <div className="text-gray800">
            {customerPayment
              ? Util.formatDateAs(customerPayment.date, 'date_tabular')
              : ''}
          </div>
        </WebUI.HStack>
        <WebUI.HStack>
          <div>Method</div>
          <div className="text-gray800">
            {payment.metadata_exposed?.source &&
            'brand' in payment.metadata_exposed.source
              ? `${payment.metadata_exposed.source.brand} ending in ${payment.metadata_exposed.source.exp_year}`
              : 'Link'}
          </div>
        </WebUI.HStack>
      </WebUI.VStack>
      <WebUI.Separator variant="primary" />
      <WebUI.VStack className="text-ds-sm">
        <WebUI.HStack>
          <div>Subtotal</div>
          <div className="text-gray800">
            {Util.formatAmount(payment.subtotal)}
          </div>
        </WebUI.HStack>
        <WebUI.HStack>
          <div>Shipping</div>
          <div className="text-gray800">
            {Util.formatAmount(payment.shipping_charge ?? 0)}
          </div>
        </WebUI.HStack>
        <WebUI.HStack>
          <div>Fees</div>
          <div className="text-gray800">
            {Util.formatAmount(payment.user_fee)}
          </div>
        </WebUI.HStack>
      </WebUI.VStack>
      <WebUI.Separator variant="primary" />
      <WebUI.VStack>
        <WebUI.HStack>
          <div>Total Payment</div>
          <div className="text-gray800">
            {Util.formatAmount(
              payment.subtotal +
                (payment.shipping_charge ?? 0) +
                payment.user_fee,
            )}
          </div>
        </WebUI.HStack>
      </WebUI.VStack>
    </WebUI.VStack>
  </WebUI.VStack>
)

// MARK: – ItemPurchased

interface ItemPurchasedProps extends React.ComponentPropsWithoutRef<'div'> {
  data: Api.TabPayment
}

const ItemPurchased: React.FC<ItemPurchasedProps> = ({
  data,
  className,
  ...restProps
}) => (
  <WebUI.VStack
    className={WebUI.cn('font-normal', className)}
    as={WebUI.Panel}
    {...restProps}
  >
    <div className="p-6 text-ds-md text-gray750">
      Items Purchased: {data?.payment_items?.length || 0}
    </div>
    <WebUI.Separator variant="primary" />
    <WebUI.VStack>
      {data?.payment_items?.map((purchasedItem) => (
        <WebUI.HStack key={purchasedItem.id} className="items-center p-4">
          <WebUI.HStack className="flex-[0_0_70%] items-center gap-4">
            <WebUI.PhosphorIcon className="text-ds-2xl" icon="tag" />
            <WebUI.VStack>
              <div className="font-normal text-ds-sm">
                {purchasedItem?.tab_item?.name}
              </div>{' '}
              <div className="font-normal text-ds-xs text-gray400">
                {purchasedItem?.tab_item?.amount_type} Price:{' '}
                {Util.formatAmount(purchasedItem?.tab_item?.amount ?? 0)}
              </div>
            </WebUI.VStack>
          </WebUI.HStack>
          <WebUI.Tag className="flex-0 text-ds-xs">
            {purchasedItem?.quantity}
          </WebUI.Tag>
          <WebUI.HStack className="shrink-0 grow basis-auto justify-end text-right">
            {Util.formatAmount(purchasedItem?.total)}
          </WebUI.HStack>
        </WebUI.HStack>
      ))}
    </WebUI.VStack>
  </WebUI.VStack>
)
