import {useNavigate, useParams} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'
import {api, useUpdateSubscriptionMutation} from '@cheddarup/api-client'
import {trackUserSubscriptionChangeEvent} from 'src/helpers/analytics'

const points = [
  'Save all of your collections and items',
  'View your collections anytime',
  'Export your reports anytime',
  'Come back when you are ready to accept payments',
]

const PlanPausePage = () => {
  const navigate = useNavigate()
  const urlParams = useParams()
  const {data: billingPeriodEndDay} = api.subscriptions.own.useSuspenseQuery(
    undefined,
    {
      select: ({plan}) =>
        plan.pending_changes
          ? Util.formatDateAs(plan.pending_changes.starting)
          : '',
    },
  )

  const updateSubscriptionMutation = useUpdateSubscriptionMutation()
  const growlActions = WebUI.useGrowlActions()

  return (
    <WebUI.Alert
      aria-label="Pause subscription confirmation"
      initialVisible
      onDidHide={() => navigate('..')}
    >
      <WebUI.AlertHeader>Need to pause your subscription?</WebUI.AlertHeader>
      <WebUI.AlertContentView
        text={
          <>
            If you’re going to take a temporary break,{' '}
            <span className="font-bold">
              pause your account for only $5 a month.
            </span>
            <ul className="mb-4">
              {points.map((point, key) => (
                <li
                  key={key}
                  className={WebUI.cn(
                    'relative pl-6 text-ds-base text-gray800',
                    key && 'mt-2',
                  )}
                >
                  <WebUI.PhosphorIcon
                    className="-translate-y-1/2 absolute top-1/2 left-0 text-tint"
                    icon="check"
                  />
                  {point}
                </li>
              ))}
            </ul>
            <p className="mb-8 font-light text-ds-sm text-gray800 italic">
              Your subscription will be downgraded to our Pro Pause Plan at the
              end of your current billing period
              {billingPeriodEndDay ? ` ${billingPeriodEndDay}` : ''}. At that
              time, your collections utilizing Pro Plan features will be
              inactive until you upgrade back to Pro.
            </p>
          </>
        }
        actions={
          <>
            <WebUI.AlertActionButton
              execute={async () => {
                try {
                  const newPlan = await updateSubscriptionMutation.mutateAsync({
                    body: {plan: 'pause', source_id: ''},
                  })
                  trackUserSubscriptionChangeEvent(newPlan)
                  growlActions.show('success', {
                    title: 'Subscription Paused',
                    body: 'You have paused your subscription',
                  })

                  navigate('src/my-account/plan-billing')
                } catch (err: any) {
                  let errMsg = err.message || err.response?.data?.error
                  if (errMsg === 'tabs_have_balances') {
                    errMsg =
                      'You must withdraw your balance prior to downgrading'
                  }

                  if (errMsg) {
                    growlActions.show('error', {title: 'Error', body: errMsg})
                  }
                }
              }}
            >
              Pause my subscription
            </WebUI.AlertActionButton>
            <WebUI.AlertActionButton
              variant="secondary"
              onClick={() =>
                navigate(`../downgrade/${urlParams.to ?? 'basic'}`)
              }
            >
              Cancel my subscription
            </WebUI.AlertActionButton>
          </>
        }
      />
    </WebUI.Alert>
  )
}

export default PlanPausePage
