import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {notificationSchema} from '../schemas'

export default {
  list: makeEndpoint({
    path: 'users/notifications',
    responseSchema: notificationSchema.array(),
  }),
  markSeen: makeEndpoint({
    path: 'users/notifications/mark_seen',
    method: 'PATCH',
    bodySchema: z.object({
      notifications: z.number().array(),
    }),
    responseSchema: notificationSchema,
  }),
  hide: makeEndpoint({
    path: 'users/notifications/hide',
    method: 'PATCH',
    bodySchema: z.object({
      notifications: z.number().array(),
    }),
    responseSchema: z.any(),
  }),
}
