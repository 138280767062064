import * as WebUI from '@cheddarup/web-ui'

import {FormFieldInlineLabelEdit} from './FormFieldInlineLabelEdit'
import type {FieldGroupFieldsEditProps} from './FieldGroupFieldsEdit'

export const SignatureFieldsEdit = ({
  required,
  focused,
  initialLabels,
  placeholders,
  onLabelChange,
}: Omit<
  FieldGroupFieldsEditProps<'signature'>,
  'fieldGroupType' | 'initialValue' | 'onValueChange'
>) => (
  <FormFieldInlineLabelEdit
    labelInputFocused={focused}
    required={required}
    initialLabel={initialLabels.value}
    placeholder={placeholders.value}
    onLabelChange={(label) => onLabelChange('value', label)}
  >
    <WebUI.SignatureCanvas
      className={
        '[&_>_.SignatureCanvas-canvasContainer_>_.SignatureCanvas-canvas]:bg-natural-100'
      }
      disabled
    />
  </FormFieldInlineLabelEdit>
)
