// Adapted from https://github.com/pie6k/use-method/blob/ab3ecb22193793591d259e0b3ea9ef0d20a14ce1/src/index.ts

import {useCallback} from 'react'

import {useLiveRef} from './useLiveRef'

type Callback<TArgs extends any[], TResult> = (...args: TArgs) => TResult

/**
 * Create callback that keeps the same reference during entire lifecycle of the component while having access to fresh
 * variables values on every call
 */
export const useStableCallback = <TArgs extends any[], TResult>(
  callback: Callback<TArgs, TResult>,
  deps: React.DependencyList = [],
) => {
  const callbackRef = useLiveRef<Callback<TArgs, TResult>>(callback)

  const stableCallback = useCallback(
    (...args: TArgs) => callbackRef.current(...args),
    deps,
  )

  return stableCallback
}
