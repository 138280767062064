import {deepPartialify, z} from '@cheddarup/util'

import {groupPageTeamMemberSchema} from '../schemas'
import {makeEndpoint} from '../utils'

export default {
  detail: makeEndpoint({
    path: 'users/group_page/meet_the_team/team_members/:id',
    responseSchema: groupPageTeamMemberSchema,
  }),
  create: makeEndpoint({
    path: 'users/group_page/meet_the_team/team_members',
    method: 'POST',
    bodySchema: deepPartialify(z.object(groupPageTeamMemberSchema.shape)),
    responseSchema: groupPageTeamMemberSchema,
  }),
  update: makeEndpoint({
    path: 'users/group_page/meet_the_team/team_members/:id',
    method: 'PATCH',
    bodySchema: deepPartialify(z.object(groupPageTeamMemberSchema.shape)),
    responseSchema: groupPageTeamMemberSchema,
  }),
  delete: makeEndpoint({
    path: 'users/group_page/meet_the_team/team_members/:id',
    method: 'DELETE',
    responseSchema: z.any(),
  }),
  deleteProfilePicture: makeEndpoint({
    path: 'users/group_page/meet_the_team/team_members/:teamMemberId/delete_image/:imageId',
    method: 'DELETE',
    responseSchema: z.any(),
  }),
}
