import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {tabAttachmentSchema} from '../schemas'

export default {
  list: makeEndpoint({
    path: 'users/tabs/:tabId/attachments',
    responseSchema: tabAttachmentSchema.array(),
  }),
  create: makeEndpoint({
    path: 'users/tabs/:tabId/attachments',
    method: 'POST',
    bodySchema: z.object({
      file_name: z.string(),
    }),
    responseSchema: tabAttachmentSchema,
  }),
  delete: makeEndpoint({
    path: 'users/tabs/:tabId/attachments/:attachmentId',
    method: 'DELETE',
    responseSchema: z.object({}),
  }),
  sign: makeEndpoint({
    path: 'users/tabs/:tabId/attachments/sign',
    method: 'POST',
    bodySchema: z.object({
      objectName: z.string(),
      contentType: z.string(),
    }),
    responseSchema: z.object({
      signedUrl: z.string(),
    }),
  }),
}
