import * as Util from '@cheddarup/util'
import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'

export interface MarketplaceShopItemsFiltersContainerProps
  extends WebUI.ModalProps {
  selectedCatalogIds: number[]
  onChangeSelectedCatalogIds: (catalogIds: number[]) => void
}

const MarketplaceShopItemsFiltersContainer = ({
  selectedCatalogIds,
  onChangeSelectedCatalogIds,
  ...restProps
}: MarketplaceShopItemsFiltersContainerProps) => {
  const {data: session} = api.auth.session.useQuery()
  const {data: marketplaceHome} = api.marketplace.home.useQuery(undefined, {
    enabled: !!session?.organization_data?.id,
  })

  return (
    <WebUI.Modal
      aria-label="Filters"
      className="absolute [&_>_.SideSheetContentView]:overflow-y-auto"
      backdropClassName="absolute bg-transparent"
      contentViewAppearance="leftToRight"
      initialVisible={false}
      preventBodyScroll={false}
      modal={false}
      {...restProps}
    >
      <WebUI.Button
        className="mx-4 mt-8"
        variant="outlined"
        onClick={() => onChangeSelectedCatalogIds([])}
        disabled={selectedCatalogIds.length === 0}
      >
        Clear All
      </WebUI.Button>

      <MarketplaceShopItemsFilterList
        title="Collections"
        items={marketplaceHome?.catalogs ?? []}
        selectedItemIds={selectedCatalogIds}
        onChangeSelectedItemIds={onChangeSelectedCatalogIds}
      />
    </WebUI.Modal>
  )
}

// MARK: – MarketplaceShopItemsFilterList

interface MarketplaceShopItemsFilterListProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'title'> {
  title: React.ReactNode
  items: Api.MarketplaceCatalog[]
  selectedItemIds: number[]
  onChangeSelectedItemIds: (itemIds: number[]) => void
}

const MarketplaceShopItemsFilterList = ({
  title,
  items,
  selectedItemIds,
  onChangeSelectedItemIds,
  className,
  ...restProps
}: MarketplaceShopItemsFilterListProps) => (
  <WebUI.VStack className={WebUI.cn('gap-8 p-8', className)} {...restProps}>
    <div className="font-accent text-ds-sm italic">{title}</div>
    <WebUI.VStack className="gap-4">
      {items.map((item) => (
        <WebUI.Checkbox
          key={item.id}
          state={selectedItemIds.includes(item.id)}
          onChange={(event) =>
            onChangeSelectedItemIds(
              event.target.checked
                ? Util.unique([...selectedItemIds, item.id])
                : selectedItemIds.filter((itemId) => itemId !== item.id),
            )
          }
        >
          {item.name}
        </WebUI.Checkbox>
      ))}
    </WebUI.VStack>
  </WebUI.VStack>
)

export default MarketplaceShopItemsFiltersContainer
