import * as WebUI from '@cheddarup/web-ui'
import React from 'react'
import {
  useUpdateBatchFormsMutation,
  useUpdateBatchItemsMutation,
} from '@cheddarup/api-client'
import {generic} from '@cheddarup/react-util'

type TabObject = Api.TabItem | Api.TabForm
type ObjectType = 'item' | 'form'

// MARK: – TabObjectBatchToggleVisibilityButton

export interface TabObjectBatchToggleVisibilityButtonProps<
  TTabObject extends TabObject,
> extends WebUI.ButtonProps,
    React.ComponentPropsWithoutRef<'button'> {
  objectType: ObjectType

  tabObjects: Array<TTabObject>
}

export const TabObjectBatchToggleVisibilityButton = generic(
  <TTabObject extends TabObject>({
    objectType,
    tabObjects,
    ...restProps
  }: TabObjectBatchToggleVisibilityButtonProps<TTabObject>) => {
    const updateBatchItemsMutation = useUpdateBatchItemsMutation()
    const updateBatchFormsMutation = useUpdateBatchFormsMutation()
    const growlActions = WebUI.useGrowlActions()

    const isEveryHidden = tabObjects.every(({hidden}) => hidden)

    return (
      <WebUI.Button
        loading={
          updateBatchItemsMutation.isPending ||
          updateBatchFormsMutation.isPending
        }
        iconBefore={
          isEveryHidden ? (
            <WebUI.PhosphorIcon icon="eye" />
          ) : (
            <WebUI.PhosphorIcon icon="eye-slash" />
          )
        }
        onClick={() => {
          if (!tabObjects[0]) {
            return
          }

          const mutation =
            objectType === 'item'
              ? updateBatchItemsMutation
              : updateBatchFormsMutation
          const tabObjectsToHide = isEveryHidden
            ? tabObjects
            : tabObjects.filter((to) => !to.required && !to.hidden)

          if (tabObjectsToHide.length > 0) {
            mutation.mutateAsync({
              pathParams: {
                tabId: tabObjects[0].tab_id,
              },
              body: {
                tab_item_ids: tabObjectsToHide.map((to) => to.id),
                hidden: !isEveryHidden,
              },
            })

            if (!isEveryHidden && tabObjects.some((to) => to.required)) {
              growlActions.show('error', {
                title: 'Warning',
                body: "Required items can't be hidden",
              })
            }
          }
        }}
        {...restProps}
      >
        {isEveryHidden ? 'Show' : 'Hide'}
      </WebUI.Button>
    )
  },
)
