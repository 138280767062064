import {useDebounceValue, useLiveRef} from '@cheddarup/react-util'
import {useLayoutEffect, useState} from 'react'

export function useDebouncedSize(
  ref: React.RefObject<HTMLElement | SVGElement>,
  timeoutMs?: number,
) {
  const size = useSize(ref)
  const debouncedSize = useDebounceValue(size, timeoutMs)
  return debouncedSize[0] === 0 && debouncedSize[1] === 0 ? size : debouncedSize
}

export function useSize(ref: React.RefObject<HTMLElement | SVGElement>) {
  const [size, setSize] = useState<[number, number]>(() => {
    const initialRect = ref.current?.getBoundingClientRect()
    return initialRect ? [initialRect.width, initialRect.height] : [0, 0]
  })

  useSizeCallback(ref, (newSize) =>
    setSize((prev) =>
      prev[0] === newSize[0] && prev[1] === newSize[1] ? prev : newSize,
    ),
  )

  return size
}

export function useSizeSelector<T>(
  ref: React.RefObject<HTMLElement | SVGElement>,
  selector: (size: [number, number]) => T,
) {
  const selectorRef = useLiveRef(selector)
  const [value, setValue] = useState(() => {
    const initialRect = ref.current?.getBoundingClientRect()
    return selectorRef.current(
      initialRect ? [initialRect.width, initialRect.height] : [0, 0],
    )
  })

  useSizeCallback(ref, (newSize) => {
    const newValue = selectorRef.current(newSize)
    setValue(newValue)
  })

  return value
}

export function useSizeCallback(
  ref: React.RefObject<HTMLElement | SVGElement>,
  onSizeChange: (newSize: [number, number]) => void,
) {
  const onSizeChangeRef = useLiveRef(onSizeChange)

  useLayoutEffect(() => {
    if (!ref.current) {
      return
    }

    const observer = new ResizeObserver((entries) => {
      const rect = entries[0]?.contentRect
      if (rect) {
        onSizeChangeRef.current([rect.width, rect.height])
      }
    })
    observer.observe(ref.current)

    const rect = ref.current.getBoundingClientRect()
    onSizeChangeRef.current([rect.width, rect.height])

    return () => {
      observer.disconnect()
    }
  }, [ref])
}
