import {endpoints} from '../../endpoints'
import {makeQueryUpdate, makeUseMutation} from '../use-mutation'

export const useCreateFolderMutation = makeUseMutation(
  endpoints.tabFolders.create,
  () => ({
    regular: (newFolder) => [
      makeQueryUpdate(endpoints.tabFolders.list, (prevFolders) =>
        prevFolders ? [...prevFolders, newFolder] : prevFolders,
      ),
    ],
  }),
)

export const useUpdateFolderMutation = makeUseMutation(
  endpoints.tabFolders.update,
  (vars) => ({
    regular: (newFolder) => [
      makeQueryUpdate(endpoints.tabFolders.list, (prevFolders) =>
        prevFolders?.map((f) =>
          f.id === Number(vars.pathParams.folderId) ? newFolder : f,
        ),
      ),
    ],
  }),
)

export const useDeleteFolderMutation = makeUseMutation(
  endpoints.tabFolders.delete,
  (vars) => ({
    regular: () => [
      makeQueryUpdate(endpoints.tabFolders.list, (prevFolders) =>
        prevFolders?.filter((f) => f.id !== Number(vars.pathParams.folderId)),
      ),
    ],
  }),
)

export const useSortFoldersMutation = makeUseMutation(
  endpoints.tabFolders.sort,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(endpoints.tabFolders.list, (prevFolders) =>
        prevFolders
          ? vars.body?.order
              .map((fId) => prevFolders.find((f) => f.id === fId))
              .filter((f) => f != null)
          : prevFolders,
      ),
    ],
  }),
)
