import React from 'react'
import * as WebUI from '@cheddarup/web-ui'

import {PayerInfoPanels} from './PayerInfoPanels'
import {PayerContinueBand, PoweredByBadge} from './PayerContinueBand'
import {usePayerUIState} from '../PayerUIStateProvider'

// MARK: – PayerObjectsLayout

export const PayerObjectsLayout: React.FC<
  React.ComponentPropsWithoutRef<'div'>
> = ({children, ...restProps}) => {
  const media = WebUI.useMedia()
  const payerUIState = usePayerUIState()

  return (
    <>
      <PayerContentContainerLayout {...restProps}>
        <PayerContentLayout className="grow justify-center">
          <PayerMainBlock className="flex-[1_0_0px]">{children}</PayerMainBlock>
          {media.lg && (
            <PayerInfoPanels className="max-w-[420px] flex-[1_1_0px]" />
          )}
        </PayerContentLayout>
        <PayerContinueBand className="mt-16 w-full sm:mx-4 sm:mt-0 lg:mx-8 lg:max-w-screen-xl" />
      </PayerContentContainerLayout>
      <div
        data-resting={payerUIState.footerInView}
        className="!z-[1] sticky inset-x-0 bottom-0 flex h-11 flex-col items-center justify-center bg-natural-100 px-2 shadow-z3 data-[resting=true]:shadow-none lg:hidden"
      >
        <PoweredByBadge />
      </div>
    </>
  )
}

// MARK: – PayerContentContainerLayout

export const PayerContentContainerLayout = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'>
>(({className, ...restProps}, forwardedRef) => (
  <div
    ref={forwardedRef}
    className={WebUI.cn(
      'flex min-h-screen flex-[1_0_auto] flex-col items-center gap-4 sm:min-h-[85vh] sm:gap-8',
      className,
    )}
    {...restProps}
  />
))

// MARK: – PayerContentLayout

export const PayerContentLayout = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'>
>(({className, ...restProps}, forwardedRef) => (
  <div
    ref={forwardedRef}
    className={WebUI.cn(
      'relative flex w-full max-w-[calc(1280px+theme(space.8)*2)] flex-row items-start gap-4 lg:gap-6 lg:px-8',
      className,
    )}
    {...restProps}
  />
))

// MARK: – PayerMainBlock

export interface PayerMainBlockProps
  extends React.ComponentPropsWithoutRef<'div'> {}

export const PayerMainBlock: React.FC<PayerMainBlockProps> = ({
  className,
  ...restProps
}) => (
  <div
    className={WebUI.cn(
      'relative flex min-w-[320px] max-w-[836px] flex-col gap-4',
      className,
    )}
    {...restProps}
  />
)
