import {ForwardRefComponent, useForkRef} from '@cheddarup/react-util'
import React, {useRef} from 'react'
import {cva} from 'class-variance-authority'

import {PhosphorIcon} from '../icons'
import {VariantsProps, cn} from '../utils'

export const select = cva(
  [
    `inline-flex h-full w-full min-w-[10rem] cursor-pointer select-none
    appearance-none rounded border-none bg-natural-100 px-2 py-2 font-body
    leading-none text-gray800 shadow-[inset_0_0_0_1px_theme(colors.natural.70)]`,
    'transition-colors duration-100 ease-in-out',
    'focus:outline-none',
    'hover:[&:not(:focus):not([aria-invalid=true]):not(:disabled):not([aria-disabled=true])]:bg-inputHoverBackground',
    `aria-invalid:border-orange-50
    aria-invalid:shadow-[inset_0_0_0_1px_theme(colors.orange.50)]`,
    'focus:shadow-[inset_0_0_0_1px_theme(colors.teal.50)]',
    'disabled:cursor-not-allowed aria-disabled:cursor-not-allowed',
  ],
  {
    variants: {
      disabledVariant: {
        default: 'disabled:opacity-50 aria-disabled:opacity-50',
        filled: 'disabled:bg-natural-80 aria-disabled:bg-natural-80',
      },
      size: {
        default: 'min-h-10 font-light text-ds-base',
        compact: 'min-h-9 text-ds-sm',
        large: 'min-h-11 font-light text-ds-md',
      },
    },
    defaultVariants: {
      size: 'default',
      disabledVariant: 'default',
    },
  },
)

export interface SelectProps extends VariantsProps<typeof select> {
  onMouseDown?: React.MouseEventHandler<HTMLDivElement>
}

export const Select = React.forwardRef(
  (
    {
      className,
      as: Comp = 'select',
      size = 'default',
      disabledVariant = 'default',
      onMouseDown,
      ...restProps
    },
    forwardedRef,
  ) => {
    const selectRef = useRef<HTMLSelectElement>(null)
    return (
      <div
        className={cn(
          'Select',
          'relative max-w-full',
          size === 'compact' && 'min-w-[8rem]',
          className,
        )}
        onMouseDown={onMouseDown}
      >
        <Comp
          ref={useForkRef(forwardedRef, selectRef)}
          className={cn(
            'Select-select',
            'peer/select',
            select({size, disabledVariant}),
          )}
          onFocus={() => selectRef.current?.parentElement?.focus()}
          {...restProps}
        />
        <PhosphorIcon
          className={cn(
            'Select-arrow',
            '-translate-y-1/2 pointer-events-none absolute top-1/2 right-2',
            'peer-disabled/select:opacity-50',
            'peer-aria-disabled/select:opacity-50',
          )}
          icon="caret-down-fill"
        />
      </div>
    )
  },
) as ForwardRefComponent<'select', SelectProps>
