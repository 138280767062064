// @ts-ignore

import {z} from '@cheddarup/util'

export const recipientSchema = z.object({
  name: z.string(),
  email: z.string(),
  uuid: z.string(),
})

export const messageTypeSchema = z.enum([
  'message',
  'invite',
  'reminder_parent',
])

export const messageStatusSchema = z.enum(['scheduled'])

export const messageDetailsFromSchema = z.object({
  name: z.string(),
  email: z.string(),
})

export const messageAttachmentSchema = z.object({
  file_name: z.string(),
  content_type: z.string(),
  base64: z.string(),
})

export const messageDetailSchema = z.object({
  from: messageDetailsFromSchema,
  subject: z.string(),
  body: z.string(),
  header: z.string().nullish(),
  includeLink: z.boolean(),
  recipients: recipientSchema.array(),
  attachments: messageAttachmentSchema.array(),
  legacy: z.boolean(),
  linkText: z.string(),
})

export const messageChildReminderDetailToSchema = z.enum([
  'everyone',
  'invited_but_not_paid',
])

export const messageChildReminderDetailSchema = z.object({
  to: messageChildReminderDetailToSchema,
})

export const messageReminderStatusSchema = z.enum(['active', 'cancelled'])

export const messageChildReminderSchema = z.object({
  id: z.number(),
  parent_message_id: z.number(),
  detail: messageChildReminderDetailSchema,
  status: messageReminderStatusSchema,
  repeat_seconds: z.number(),
  recipients: z.string(),
  start_at: z.string(),
  end_at: z.string(),
  deleted_at: z.string().nullish(),
  created_at: z.string(),
  next_send_at: z.string(),
  updated_at: z.string(),
})

export const messageTabSchema = z.object({
  id: z.number(),
  name: z.string(),
  slug: z.string(),
})

export const messageRemindersToSchema = z.enum([
  'everyone',
  'invited_but_not_paid',
])

export const messageSchema = z.object({
  id: z.number(),
  user_id: z.number(),
  tab_id: z.number().nullish(),
  reminder_id: z.number().nullish(),
  created_at: z.string(),
  updated_at: z.string(),
  deleted_at: z.string().nullish(),
  send_at: z.string(),
  status: messageStatusSchema,
  message_type: messageTypeSchema,
  detail: messageDetailSchema,
  child_reminder: messageChildReminderSchema.nullish(),
  tab: messageTabSchema.nullish(),
})

export const deliveryRecipientSchema = z.object({
  delivery_status: z.string(),
  email: z.string(),
  name: z.string(),
  identify_count: z.number(),
  message_id: z.number(),
  payment_count: z.number(),
  payment_total: z.number(),
  sent_at: z.string(),
  sent_mail_id: z.string(),
  uuid: z.string(),
})

declare global {
  namespace Api {
    // @ts-ignore
    type DeliveryRecipient = z.infer<typeof deliveryRecipientSchema>
    // @ts-ignore
    type Message = z.infer<typeof messageSchema>
    // @ts-ignore
    type MessageRemindersTo = z.infer<typeof messageRemindersToSchema>
    // @ts-ignore
    type MessageTab = z.infer<typeof messageTabSchema>
    // @ts-ignore
    type MessageChildReminder = z.infer<typeof messageChildReminderSchema>
    // @ts-ignore
    type MessageReminderStatus = z.infer<typeof messageReminderStatusSchema>
    // @ts-ignore
    type MessageChildReminderDetail = z.infer<
      typeof messageChildReminderDetailSchema
    >
    // @ts-ignore
    type MessageChildReminderDetailTo = z.infer<
      typeof messageChildReminderDetailToSchema
    >
    // @ts-ignore
    type MessageDetail = z.infer<typeof messageDetailSchema>
    // @ts-ignore
    type MessageAttachment = z.infer<typeof messageAttachmentSchema>
    // @ts-ignore
    type MessageDetails = z.infer<typeof messageDetailsFromSchema>
    // @ts-ignore
    type MessageStatus = z.infer<typeof messageStatusSchema>
    // @ts-ignore
    type MessageType = z.infer<typeof messageTypeSchema>
    // @ts-ignore
    type Recipient = z.infer<typeof recipientSchema>
  }
}
