import {api} from '@cheddarup/api-client'
import {useNavigate, useParams} from 'react-router-dom'
import {InvoiceDetails} from 'src/components'

const InvoiceDetailsPage = () => {
  const urlParams = useParams()
  const navigate = useNavigate()
  const {data: userPayments} = api.payments.list.useSuspenseQuery()
  const {
    data: {banks, cards},
  } = api.recurringPaymentContracts.list.useSuspenseQuery()

  const payment = userPayments.find(
    (userPayment) => userPayment.id === Number(urlParams.payment),
  )

  if (!payment) {
    return null
  }

  return (
    <InvoiceDetails
      payment={payment}
      cards={cards}
      banks={banks}
      onDidHide={() => navigate('../..')}
    />
  )
}

export default InvoiceDetailsPage
