export function maskPhoneNumber(
  phoneNumber: string | undefined | null,
  exceptLast = 6,
): string {
  if (!phoneNumber) {
    return ''
  }
  // First, remove all non-digit characters and get the length of the resulting string
  const digitsOnly = phoneNumber.replace(/\D/g, '')
  const maskLength = digitsOnly.length - exceptLast

  if (maskLength <= 0) {
    return phoneNumber
  }

  // Replace the first `maskLength` digits with 'X', and then reconstruct the phone number
  const maskedDigits = digitsOnly
    .split('')
    .map((digit, index) => (index < maskLength ? 'X' : digit))
    .join('')

  // Re-insert the non-digit characters in their original positions
  let maskedPhoneNumber = ''
  let digitIndex = 0
  for (const char of phoneNumber) {
    if (/\D/.test(char)) {
      maskedPhoneNumber += char
    } else {
      maskedPhoneNumber += maskedDigits[digitIndex++]
    }
  }

  return maskedPhoneNumber
}
