import React from 'react'

import {CardDragHandle} from './Card'
import {HStack, HStackProps} from './Stack'
import {cn} from '../utils'

export interface SectionListHeaderProps
  extends HStackProps,
    React.ComponentPropsWithoutRef<'div'> {
  dragHandleVisible?: boolean
  accessoryView?: React.ReactNode
}

export const SectionListHeader = React.forwardRef<
  HTMLDivElement,
  SectionListHeaderProps
>(
  (
    {dragHandleVisible, accessoryView, className, children, ...restProps},
    forwardedRef,
  ) => (
    <HStack
      ref={forwardedRef}
      className={cn('SectionListHeader', 'relative gap-1 pr-3', className)}
      {...restProps}
    >
      {dragHandleVisible && (
        <CardDragHandle
          className={cn(
            'invisible min-h-17 self-stretch opacity-0',
            '[@media(hover:hover){&}]:flex',
            'transition-opacity duration-100 ease-in-out',
            '[@media(hover:hover){.SectionListHeader:hover_>_&}]:visible [@media(hover:hover){.SectionListHeader:hover_>_&}]:opacity-100',
            `[@media(hover:hover){.DataSectionListSection[data-sectionfocused="true"]_&}]:visible [@media(hover:hover){.DataSectionListSection[data-sectionfocused="true"]_&}]:opacity-100`,
          )}
        />
      )}
      {children}
      {!!accessoryView && (
        <div
          data-no-dnd="true"
          className={cn(
            'Card-accessoryView',
            '!m-0 absolute top-3 right-3',
            '[@media(hover:hover){&}]:invisible [@media(hover:hover){&}]:opacity-0',
            'transition-opacity duration-100 ease-in-out',
            '[@media(hover:hover){.SectionListHeader:hover_>_&}]:visible [@media(hover:hover){.SectionListHeader:hover_>_&}]:opacity-100',
            `[@media(hover:hover){.DataSectionListSection[data-sectionfocused="true"_&}]:opacity-100 [@media(hover:hover){.DataSectionListSection[data-sectionfocused="true"_&}]:visible`,
          )}
        >
          {accessoryView}
        </div>
      )}
    </HStack>
  ),
)
