import * as Sentry from '@sentry/react'
import {
  ApiError,
  Endpoints,
  InferBody,
  useLoginWithTokenMutation,
  useLogoutMutation,
  useSignupWithTokenMutation,
} from '@cheddarup/api-client'
import React, {useCallback} from 'react'
import Rupt from 'rupt'
import config from 'src/config'
import * as WebUI from '@cheddarup/web-ui'
import {useManagerRoleId} from 'src/components/ManageRoleProvider'
import {isAxiosError} from 'axios'
import AdvertisingUtils from 'src/helpers/AdvertisingUtils'
import * as Util from '@cheddarup/util'

import {useAuthToken} from './useAuthToken'

// MARK: – useLogin

export function useLogin(options: {
  captchaDisabled?: boolean
  recaptchaRef?: React.RefObject<WebUI.RecaptchaInstance>
}) {
  const captchaDisabled =
    options.captchaDisabled == null ? !config.isProd : false

  const loginWithTokenMutation = useLoginWithTokenMutation()
  const [, setAuthToken] = useAuthToken()
  const [, setManagerRoleId] = useManagerRoleId()

  const loginWithTokenAsync = loginWithTokenMutation.mutateAsync

  const login = useCallback(
    async (body: InferBody<Endpoints['auth']['login']>) => {
      try {
        let captchaToken: string | undefined
        if (!captchaDisabled) {
          options.recaptchaRef?.current?.reset()
          captchaToken =
            (await options.recaptchaRef?.current?.executeAsync()) ?? undefined
          if (!captchaToken) {
            return
          }
        }

        const ruptRes = await Promise.race([
          Util.delay(config.isProd ? 500 : 2000),
          Rupt.identify({client_id: config.ruptClientId}),
        ])

        const loginRes = await loginWithTokenAsync({
          queryParams: {
            respondWithToken: true,
            includeSession: true,
          },
          body: {
            captchaToken,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            identification_token: ruptRes?.identification,
            device_information: navigator.userAgent,
            zip: window.zip,
            ...body,
          },
        })

        setAuthToken(loginRes.token)
        setManagerRoleId(undefined)

        // biome-ignore lint/style/noNonNullAssertion:
        return loginRes.session!
      } catch (err) {
        if (isAxiosError(err)) {
          if (err.response && err.response.data.type === 'api_error') {
            throw new ApiError(err.response.data)
          }
        } else {
          Sentry.captureException(err)
        }

        throw err
      }
    },
    [
      loginWithTokenAsync,
      captchaDisabled,
      options.recaptchaRef,
      setAuthToken,
      setManagerRoleId,
    ],
  )

  return [login, loginWithTokenMutation] as const
}

// MARK: - useSignup

export function useSignup(options: {
  captchaDisabled?: boolean
  recaptchaRef?: React.RefObject<WebUI.RecaptchaInstance>
}) {
  const captchaDisabled =
    options.captchaDisabled == null ? !config.isProd : options.captchaDisabled

  const [, setNewSignup] = WebUI.useLocalStorage('cu-new-signup', false)
  const signupWithTokenMutation = useSignupWithTokenMutation()
  const [, setAuthToken] = useAuthToken()
  const [, setManagerRoleId] = useManagerRoleId()

  const signupWithTokenAsync = signupWithTokenMutation.mutateAsync

  const signup = useCallback(
    async (body: InferBody<Endpoints['auth']['signup']>) => {
      try {
        let captchaToken: string | undefined
        if (!captchaDisabled) {
          options.recaptchaRef?.current?.reset()
          captchaToken =
            (await options.recaptchaRef?.current?.executeAsync()) ?? undefined
          if (!captchaToken) {
            return false
          }
        }

        const signupRes = await signupWithTokenAsync({
          queryParams: {
            respondWithToken: true,
          },
          body: {
            ...AdvertisingUtils.getSignupParams(),
            zip: window.zip,
            city: window.city1,
            state: window.state1,
            country_code: window.country1_abb,
            captchaToken,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            ...body,
            profile: {
              signupSource: 'web',
              ...body.profile,
            },
          },
        })
        const sessionRes = signupRes.session
        const managerRoleId =
          sessionRes?.manager_roles[sessionRes?.manager_roles.length - 1]?.id ??
          null

        setAuthToken(signupRes.token)
        setManagerRoleId(managerRoleId)
        sessionStorage.removeItem('signupData')
        setNewSignup(true)
        return true
      } catch (err) {
        if (isAxiosError(err)) {
          if (err.response && err.response.data.type === 'api_error') {
            throw new ApiError(err.response.data)
          }
        } else {
          Sentry.captureException(err)
        }

        throw err
      }
    },
    [
      captchaDisabled,
      options.recaptchaRef,
      setAuthToken,
      setNewSignup,
      setManagerRoleId,
      signupWithTokenAsync,
    ],
  )

  return [signup, signupWithTokenMutation] as const
}

export function useLogout() {
  const [, setAuthToken] = useAuthToken()
  const logoutMutation = useLogoutMutation()

  const logoutAsync = logoutMutation.mutateAsync
  const logout = useCallback(async () => {
    await logoutAsync().catch(() => {
      // noop
    })
    setAuthToken(null)
  }, [logoutAsync, setAuthToken])

  return [logout, logoutMutation] as const
}
