import {makeQueryUpdate, makeUseMutation} from '../use-mutation'
import {endpoints} from '../../endpoints'
import {getEndpointKey} from '../../utils'

export const useCreateExternalAccountMutation = makeUseMutation(
  endpoints.externalAccounts.create,
  () => ({
    regular: (newExternalAccount) => [
      makeQueryUpdate(
        endpoints.externalAccounts.list,
        (prevExternalAccounts) =>
          prevExternalAccounts
            ? {
                banks:
                  'account_holder_name' in newExternalAccount
                    ? [newExternalAccount, ...prevExternalAccounts.banks]
                    : prevExternalAccounts.banks,
                cards:
                  'brand' in newExternalAccount
                    ? [newExternalAccount, ...prevExternalAccounts.cards]
                    : prevExternalAccounts.cards,
              }
            : prevExternalAccounts,
      ),
    ],
  }),
  (queryClient) => ({
    onSuccess: () => {
      const sessionQueryKey = getEndpointKey(endpoints.auth.session)
      queryClient.invalidateQueries({queryKey: sessionQueryKey})
    },
  }),
)

export const useUpdateExternalAccountMutation = makeUseMutation(
  endpoints.externalAccounts.update,
  () => ({
    regular: (newExternalAccount) => [
      makeQueryUpdate(
        endpoints.externalAccounts.list,
        (prevExternalAccounts) =>
          prevExternalAccounts
            ? {
                banks: prevExternalAccounts.banks.map((b) =>
                  b.id === newExternalAccount.id
                    ? (newExternalAccount as Api.BankAccount)
                    : {
                        ...b,
                        isDefault: newExternalAccount.isDefault
                          ? false
                          : b.isDefault,
                      },
                ),
                cards: prevExternalAccounts.cards.map((c) =>
                  c.id === newExternalAccount.id
                    ? (newExternalAccount as Api.CreditCard)
                    : {
                        ...c,
                        isDefault: newExternalAccount.isDefault
                          ? false
                          : c.isDefault,
                      },
                ),
              }
            : prevExternalAccounts,
      ),
    ],
  }),
)

export const useRemoveExternalAccountMutation = makeUseMutation(
  endpoints.externalAccounts.remove,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(
        endpoints.externalAccounts.list,
        (prevExternalAccounts) =>
          prevExternalAccounts
            ? {
                banks: prevExternalAccounts.banks.filter(
                  (b) => b.id !== vars.pathParams.accountId,
                ),
                cards: prevExternalAccounts.cards.filter(
                  (c) => c.id !== vars.pathParams.accountId,
                ),
              }
            : prevExternalAccounts,
      ),
    ],
  }),
)
