// Based on https://github.com/jquense/yup/blob/master/src/date.ts

import * as Yup from 'yup'
import {CalendarDate, CalendarDateTime, Time} from '@internationalized/date'
import * as Util from '@cheddarup/util'

export type InternationalizedDate = CalendarDate | Time | CalendarDateTime
export type InternationalizedDateClass<T extends InternationalizedDate> =
  T extends CalendarDate
    ? typeof CalendarDate
    : T extends Time
      ? typeof Time
      : T extends CalendarDateTime
        ? typeof CalendarDateTime
        : never

export function yupInternationalizedDateSchema<T extends InternationalizedDate>(
  DateClass: InternationalizedDateClass<T>,
): InternationalizedDateSchema
export function yupInternationalizedDateSchema<
  T extends InternationalizedDate,
  TContext extends Yup.Maybe<Yup.AnyObject> = Yup.AnyObject,
>(
  DateClass: InternationalizedDateClass<T>,
): InternationalizedDateSchema<T | undefined, TContext>
export function yupInternationalizedDateSchema<T extends InternationalizedDate>(
  DateClass: InternationalizedDateClass<T>,
) {
  return new InternationalizedDateSchema(DateClass)
}

export function yupTimeSchema() {
  return new InternationalizedDateSchema<Time>(Time)
}

export function yupCalendarDateSchema() {
  return new InternationalizedDateSchema<CalendarDate>(CalendarDate)
}

export function yupCalendarDateTimeSchema() {
  return new InternationalizedDateSchema<CalendarDateTime>(CalendarDateTime)
}

export class InternationalizedDateSchema<
  TType extends Yup.Maybe<InternationalizedDate> = InternationalizedDate,
  TContext = Yup.AnyObject,
  TDefault = undefined,
  TFlags extends Yup.Flags = '',
> extends Yup.Schema<TType, TContext, TDefault, TFlags> {
  constructor(
    private DateClass: InternationalizedDateClass<NonNullable<TType>>,
  ) {
    super({
      type: 'calendar-date',
      check(v: any): v is NonNullable<TType> {
        return v instanceof DateClass
      },
    })

    this.withMutation(() => {
      this.transform((value, _raw, ctx) => {
        // treat all nulls as null and let it fail on
        // nullability check vs TypeErrors
        if (!ctx.spec.coerce || ctx.isType(value) || value === null) {
          return value
        }

        if (this.DateClass === Time) {
          return Util.parseTime(value)
        }
        if (this.DateClass === CalendarDate) {
          return Util.parseCalendarDate(value)
        }

        throw new Error(`DateClass of ${this.DateClass} is not supported`)
      })
    })
  }

  private prepareParam(
    ref: unknown | Yup.Reference<TType>,
    name: string,
  ): TType | Yup.Reference<TType> {
    let param: TType | Yup.Reference<TType>

    if (isRef(ref)) {
      param = ref as Yup.Reference<TType>
    } else {
      const cast = this.cast(ref)
      if (!this._typeCheck(cast)) {
        throw new TypeError(
          `\`${name}\` must be an InternationalizedDate or a value that can be \`cast()\` to an InternationalizedDate`,
        )
      }
      param = cast
    }
    return param
  }

  min(
    min: unknown | Yup.Reference<TType>,
    message = Yup.defaultLocale.date?.min,
  ) {
    const limit = this.prepareParam(min, 'min')

    return this.test({
      message,
      name: 'min',
      exclusive: true,
      params: {min},
      skipAbsent: true,
      test(value) {
        return value && value.compare(limit as any) > 0
      },
    })
  }

  max(
    max: unknown | Yup.Reference<TType>,
    message = Yup.defaultLocale.date?.max,
  ) {
    const limit = this.prepareParam(max, 'max')

    return this.test({
      message,
      name: 'max',
      exclusive: true,
      params: {max},
      skipAbsent: true,
      test(value) {
        return value && value.compare(limit as any) < 0
      },
    })
  }
}

yupInternationalizedDateSchema.prototype = InternationalizedDateSchema.prototype

export interface InternationalizedDateSchema<
  TType extends Yup.Maybe<InternationalizedDate>,
  TContext = Yup.AnyObject,
  TDefault = undefined,
  TFlags extends Yup.Flags = '',
> extends Yup.Schema<TType, TContext, TDefault, TFlags> {
  default<D extends Yup.Maybe<TType>>(
    def: Yup.DefaultThunk<D, TContext>,
  ): InternationalizedDateSchema<
    TType,
    TContext,
    D,
    Yup.ToggleDefault<TFlags, D>
  >

  concat<TOther extends InternationalizedDateSchema<TType, any>>(
    schema: TOther,
  ): TOther

  defined(
    msg?: Yup.Message,
  ): InternationalizedDateSchema<Yup.Defined<TType>, TContext, TDefault, TFlags>
  optional(): InternationalizedDateSchema<
    TType | undefined,
    TContext,
    TDefault,
    TFlags
  >

  required(
    msg?: Yup.Message,
  ): InternationalizedDateSchema<NonNullable<TType>, TContext, TDefault, TFlags>
  notRequired(): InternationalizedDateSchema<
    Yup.Maybe<TType>,
    TContext,
    TDefault,
    TFlags
  >

  nullable(
    msg?: Yup.Message,
  ): InternationalizedDateSchema<TType | null, TContext, TDefault, TFlags>
  nonNullable(): InternationalizedDateSchema<
    Yup.NotNull<TType>,
    TContext,
    TDefault,
    TFlags
  >

  strip(
    enabled: false,
  ): InternationalizedDateSchema<
    TType,
    TContext,
    TDefault,
    Yup.UnsetFlag<TFlags, 's'>
  >
  strip(
    enabled?: true,
  ): InternationalizedDateSchema<
    TType,
    TContext,
    TDefault,
    Yup.SetFlag<TFlags, 's'>
  >
}

// MARK: – Helpers

// Yup.Reference is not actually exported although yup's types say otherwise
// See https://github.com/jquense/yup/blob/a58f02d2f6164c46a9757a818eebac582f7a441c/src/Reference.ts#L91C9-L93C4
function isRef(value: any): value is Yup.Reference {
  return value?.__isYupRef
}
