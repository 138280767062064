import {ForwardRefComponent, genericForwardRef} from '@cheddarup/react-util'
import React from 'react'

import {Image} from './Image'
import {HStack, VStack} from './Stack'
import {IconButton} from './IconButton'
import {Popover, PopoverContent, PopoverDisclosure} from './Popover'
import {Text} from './Text'
import {VisuallyHidden} from './VisuallyHidden'
import {PhosphorIcon} from '../icons'
import {cn} from '../utils'

export interface AvatarProps extends React.ComponentPropsWithoutRef<'img'> {
  size: number
  name?: string
  errorFallback?: React.ReactNode
  errorFallbackStyles?: React.CSSProperties
}

export const Avatar = React.forwardRef(
  (
    {
      as: Comp = Image,
      className,
      alt = 'Avatar',
      size,
      name,
      errorFallback,
      onError,
      errorFallbackStyles,
      ...restProps
    },
    forwardedRef,
  ) => {
    const initials = getInitials(name ?? '')

    return (
      <Comp
        ref={forwardedRef}
        className={cn('Avatar rounded-full object-cover', className)}
        alt={alt}
        width={size}
        height={size}
        errorFallback={
          errorFallback ||
          (initials.length > 0 ? (
            <HStack
              className="Avatar-label items-center justify-center rounded-full bg-teal-70"
              style={{width: size, height: size, ...errorFallbackStyles}}
            >
              <VisuallyHidden>{name}</VisuallyHidden>
              <Text
                className={cn(
                  'Avatar-labelText font-semibold leading-none',
                  !errorFallbackStyles && 'text-natural-100',
                )}
                style={{fontSize: 0.4 * size}}
              >
                {initials}
              </Text>
            </HStack>
          ) : (
            <PhosphorIcon
              className="text-teal-70"
              icon="user-circle"
              width={size}
              height={size}
            />
          ))
        }
        onError={onError as any}
        {...restProps}
      />
    )
  },
) as ForwardRefComponent<'img', AvatarProps>

// MARK: – AvatarGroup

interface AvatarGroupItemData extends Omit<AvatarProps, 'size'> {
  image?: any | null
}

export interface AvatarGroupProps<T extends AvatarGroupItemData>
  extends React.ComponentPropsWithoutRef<'div'> {
  popoverContentClassName?: string
  AvatarComp: React.ComponentType<AvatarProps & {image?: any}>
  data: T[]
  maxVisibleCount?: number
  size?: number
  popoverShrinkable?: boolean
  renderSummary?: (datum: T, idx: number) => React.ReactNode
}

export const AvatarGroup = genericForwardRef(
  <T extends AvatarGroupItemData>(
    {
      popoverContentClassName,
      AvatarComp,
      data,
      maxVisibleCount = 3,
      renderSummary,
      size = 28,
      popoverShrinkable = true,
      className,
      ...restProps
    }: AvatarGroupProps<T> & {ref?: React.Ref<HTMLDivElement>},
    forwardedRef: React.Ref<HTMLDivElement>,
  ) => {
    const visibleData = data.slice(0, maxVisibleCount)
    const hiddenDataCount = data.slice(maxVisibleCount).length

    return (
      <HStack
        ref={forwardedRef}
        className={cn('AvatarGroup gap-1', className)}
        {...restProps}
      >
        {visibleData.map((d, idx) => (
          <AvatarComp
            key={String(idx)}
            className="AvatarGroup-avatar"
            size={size}
            {...(d as any)}
          />
        ))}
        {hiddenDataCount > 0 && (
          <AvatarComp
            className="AvatarGroup-avatarPlus"
            size={size}
            image={null}
            name={`+ ${hiddenDataCount}`}
          />
        )}
        {!!renderSummary && data.length > 0 && (
          <Popover placement="bottom-start">
            <PopoverDisclosure
              className={
                'AvatarGroup-popoverDisclosure text-teal-50 [&_svg]:transition-transform [&_svg]:duration-100 [&_svg]:ease-linear aria-expanded:[&_svg]:rotate-90'
              }
              style={{
                width: size,
                height: size,
                borderRadius: size / 2,
                fontSize: size * 0.6,
              }}
              as={IconButton}
              size="default_alt"
              variant="secondary"
            >
              <PhosphorIcon icon="caret-right-fill" />
            </PopoverDisclosure>
            <PopoverContent
              aria-label="Avatar group summary"
              className={cn(
                'AvatarGroup-popoverContent [&_>_.PopoverContent-inner_>_.PopoverContent-body]:min-w-[240px] [&_>_.PopoverContent-inner_>_.PopoverContent-body]:max-w-[340px] [&_>_.PopoverContent-inner_>_.PopoverContent-body]:overflow-y-auto [&_>_.PopoverContent-inner_>_.PopoverContent-body]:bg-gray100 [&_>_.PopoverContent-inner_>_.PopoverContent-body]:p-4',
                popoverContentClassName,
              )}
              fullWidth={false}
              shrinkable={popoverShrinkable}
              unstable_autoFocusOnShow={false}
              unstable_autoFocusOnHide={false}
            >
              <VStack className="AvatarGroup-popoverAvatarsStack gap-4">
                {data.map((d, idx) => (
                  <React.Fragment key={String(idx)}>
                    {renderSummary(d, idx)}
                  </React.Fragment>
                ))}
              </VStack>
            </PopoverContent>
          </Popover>
        )}
      </HStack>
    )
  },
)

// MARK: – Helpers

export const getInitials = (name = '') =>
  name
    .trim()
    .replace(SPECIAL_CHAR_RE, '')
    .replace(WHITESPACE_RE, ' ')
    .split(' ')
    .slice(0, 2)
    .map((v) => v[0])
    .join('')
    .toUpperCase()

const SPECIAL_CHAR_RE = /[^\s\w]/g
const WHITESPACE_RE = /\s+/g
