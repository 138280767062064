import {useCallback, useEffect, useMemo, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'
import {
  api,
  useResendTabPaymentRefundEmailsMutation,
} from '@cheddarup/api-client'
import {Link} from 'src/components/Link'
import ExpandableSidebarLayout from 'src/components/ExpandableSidebarLayout'
import {RefundOverview} from 'src/components'

import {RefundsManage} from '../components'

const CENTS_IN_USD = 100

export interface RefundsManageContainerProps {
  collectionId: number
  paymentId: number
  selectedPaymentItemId: number | null
  onDismiss: () => void
}

const RefundsManageContainer = ({
  collectionId,
  paymentId,
  selectedPaymentItemId,
  onDismiss,
}: RefundsManageContainerProps) => {
  const [selectedRefund, setSelectedRefund] =
    useState<Api.TabPaymentRefund | null>(null)
  const {data: payment} = api.tabPayments.detail.useQuery({
    pathParams: {
      tabId: collectionId,
      paymentId,
    },
  })
  const {data: refundableData} = api.tabPaymentRefunds.refundableData.useQuery({
    pathParams: {
      tabId: collectionId,
      paymentId,
    },
  })
  const {data: collection} = api.tabs.detail.useQuery({
    pathParams: {
      tabId: collectionId,
    },
  })
  const resendRefundEmailsMutation = useResendTabPaymentRefundEmailsMutation()
  const growlActions = WebUI.useGrowlActions()

  const totalRefundable = useMemo(() => {
    if (!refundableData || !collection) {
      return undefined
    }
    const withdrawalBalanceAvailable =
      (collection.withdrawal_balance_available +
        refundableData.accountFeesRefundable / 100) *
      CENTS_IN_USD
    const totalWithAbsorbedFees =
      refundableData.totalRefundable + refundableData.accountFeesRefundable

    return withdrawalBalanceAvailable > totalWithAbsorbedFees
      ? totalWithAbsorbedFees
      : withdrawalBalanceAvailable
  }, [collection, refundableData])

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (payment?.payment_method === 'cash') {
      onDismiss()
    }
  }, [payment])

  useEffect(() => {
    if (selectedPaymentItemId && payment) {
      const nextSelectedRefund = payment.refunds?.find((refund) => {
        const refundPaymentItemIds = (refund.detail?.items || []).map(
          ({payment_item_id}) => payment_item_id,
        )

        return refundPaymentItemIds.includes(selectedPaymentItemId)
      })

      setSelectedRefund(nextSelectedRefund ?? null)
    }
  }, [payment, selectedPaymentItemId])

  const handleViewRefund = useCallback((refund: Api.TabPaymentRefund) => {
    setSelectedRefund((prevSelectedRefund) =>
      prevSelectedRefund?.id === refund.id ? null : refund,
    )
  }, [])

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const handleDismissRefundView = useCallback(() => {
    setSelectedRefund(null)
  }, [setSelectedRefund])

  const handleResendRefundConfirmation = useCallback(async () => {
    try {
      await resendRefundEmailsMutation.mutateAsync({
        pathParams: {
          tabId: collectionId,
          paymentId,
          refundId: selectedRefund?.id ?? -1,
        },
      })
      growlActions.show('success', {
        title: 'Confirmation resent',
        body: 'An email is on its way.',
      })
    } catch {
      // noop
    }
  }, [
    resendRefundEmailsMutation,
    collectionId,
    paymentId,
    selectedRefund?.id,
    growlActions,
  ])

  if (!collection || !refundableData) {
    return null
  }

  return (
    <ExpandableSidebarLayout
      sidebarVisible={!!selectedRefund}
      sidebar={
        payment && (
          <RefundOverview
            // biome-ignore lint/style/noNonNullAssertion:
            refund={selectedRefund!}
            payment={payment}
            onResendRefundConfirmation={handleResendRefundConfirmation}
          />
        )
      }
      heading="Refunds"
      headingSubtitle={
        <WebUI.VStack className="mt-2">
          <span className="text-ds-base">
            Available: {Util.formatAmount(totalRefundable ?? 0, {cents: true})}
          </span>
          <Link
            className="font-light text-ds-sm"
            variant="primary"
            to={`i/collection/${collection.id}/summary`}
          >
            Balance Summary
          </Link>
        </WebUI.VStack>
      }
      onClickClose={onDismiss}
      onClickCloseSidebar={handleDismissRefundView}
    >
      {!!payment && (
        <RefundsManage
          selectedRefundId={selectedRefund ? selectedRefund.id : null}
          totalRefundable={totalRefundable}
          payment={payment}
          refundableData={refundableData}
          onViewRefund={handleViewRefund}
        />
      )}
    </ExpandableSidebarLayout>
  )
}

export default RefundsManageContainer
