import React, {useMemo, useRef, useState} from 'react'

import {PhosphorIcon} from '../icons'
import {Button} from './Button'
import {
  FileUploader,
  FileUploaderButton,
  FileUploaderDropzone,
  FileUploaderInput,
  FileUploaderProps,
} from './FileUploader'
import {IconButton} from './IconButton'
import {ImageCrop, ImageCropArea} from './ImageCrop'
import {Text} from './Text'
import {ImTool, cn, resetImageOrientation} from '../utils'

export interface ImageEditorCrop {
  x: number
  y: number
  width: number
  height: number
}

export interface ImageEditorProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'onDrop'> {
  width?: number | string | null
  height?: number | string
  dropZoneClassName?: string
  image: Blob | null
  initialCrop?: ImageCropArea | null
  onApplyCrop: (newCrop: ImageEditorCrop | null) => void
  onChangeImage: (file: Blob | null) => void
  toolbarTitle?: React.ReactNode
}

export const ImageEditor = ({
  width = 400,
  height = 260,
  image,
  dropZoneClassName,
  initialCrop,
  onApplyCrop,
  onChangeImage,
  toolbarTitle,
  className,
  ...restProps
}: ImageEditorProps) => {
  const [cropping, setCropping] = useState(false)
  const pixelCropRef = useRef<ImageEditorCrop | null>(null)
  const imagePreviewURL = useMemo(
    () => (image ? URL.createObjectURL(image) : null),
    [image],
  )

  if (!image || !imagePreviewURL) {
    return (
      <ImageEditorDropZone
        className={dropZoneClassName}
        style={{width: width ?? undefined, height}}
        onDrop={(imageFile) => onChangeImage(imageFile)}
      />
    )
  }

  return (
    <div
      className={cn('flex flex-col items-center border', className)}
      {...restProps}
    >
      <ImageCrop
        width={width}
        height={height}
        initialCrop={initialCrop}
        disabled={!cropping}
        src={imagePreviewURL}
        onCropChange={(newPixelCrop) => {
          pixelCropRef.current = newPixelCrop
        }}
      />
      <div className="flex flex-row items-center gap-4 self-stretch bg-gray100 p-2">
        <Text className="grow text-ds-xs">
          {cropping ? 'Set thumbnail view' : toolbarTitle}
        </Text>
        <div className="flex flex-row gap-4 text-ds-lg">
          {cropping ? (
            <>
              <Button
                size="compact"
                variant="secondary"
                onClick={() => setCropping(false)}
              >
                Cancel
              </Button>
              <Button
                size="compact"
                onClick={() => {
                  onApplyCrop(pixelCropRef.current)
                  setCropping(false)
                }}
              >
                Apply
              </Button>
            </>
          ) : (
            <>
              <IconButton
                variant="link"
                size="default_alt"
                onClick={async () => {
                  const file = await ImTool.fromImage(image).then((imTool) =>
                    imTool.rotateDeg(90).toBlob(),
                  )
                  onChangeImage(file)
                }}
              >
                <PhosphorIcon icon="arrow-counter-clockwise" />
              </IconButton>
              <IconButton
                variant="link"
                size="default_alt"
                onClick={() => setCropping(true)}
              >
                <PhosphorIcon icon="crop" />
              </IconButton>
              <IconButton
                variant="link"
                size="default_alt"
                onClick={() => onChangeImage(null)}
              >
                <PhosphorIcon icon="trash" />
              </IconButton>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

// MARK: – ImageEditorDropZone

interface ImageEditorDropZoneProps
  extends Omit<FileUploaderProps, 'accept' | 'onDrop' | 'children'>,
    Pick<React.ComponentPropsWithoutRef<'div'>, 'className' | 'style'> {
  onDrop?: (imageFile: Blob) => void
}

const ImageEditorDropZone = ({
  className,
  style,
  onDrop,
  ...restProps
}: ImageEditorDropZoneProps) => (
  <FileUploader
    accept={{'image/*': []}}
    noKeyboard
    noClick
    onDrop={async ([imageFile]) => {
      if (imageFile) {
        const normalizedImageFile = await resetImageOrientation(imageFile)
        onDrop?.(normalizedImageFile)
      }
    }}
    {...restProps}
  >
    <FileUploaderInput />
    <FileUploaderDropzone
      className={cn(
        'flex flex-col items-center justify-center gap-4 border',
        className,
      )}
      style={style}
    >
      <PhosphorIcon className="text-gray600" icon="camera" width={60} />
      <Text className="text-ds-md">Drag and drop</Text>
      <FileUploaderButton>Upload Photo</FileUploaderButton>
    </FileUploaderDropzone>
  </FileUploader>
)
