// @ts-ignore

import {z} from '@cheddarup/util'

export const subscriptionPlanTypeSchema = z.enum([
  'free',
  'pro',
  'pause',
  'team',
])

export const billingHistoryItemSchema = z.object({
  charge: z
    .object({
      amount: z.number(),
      amount_refunded: z.number(),
      status: z.string(),
    })
    .optional(),
  created: z.number().nullish(),
  hosted_invoice_url: z.string(),
})

export const subscriptionPlanSchema = z.object({
  identifier: z.string(),
  name: z.string(),
  pending_changes: z.object({
    identifier: z.literal('no_change'),
    new_plan: z.unknown(),
    starting: z.string(),
  }),
})

declare global {
  namespace Api {
    // @ts-ignore
    type SubscriptionPlan = z.infer<typeof subscriptionPlanSchema>
    // @ts-ignore
    type BillingHistoryItem = z.infer<typeof billingHistoryItemSchema>
  }
}
