import * as WebUI from '@cheddarup/web-ui'
import {forwardRef} from 'react'
import {ForwardRefComponent} from '@cheddarup/react-util'

import {NavLinkButton} from './LinkButton'

export interface ToolbarLinkItemProps extends WebUI.ToolbarItemProps {}

export const ToolbarLinkItem = forwardRef(
  ({as = NavLinkButton, className, ...restProps}, forwardedRef) => (
    <WebUI.ToolbarItem
      ref={forwardedRef}
      as={as}
      className={WebUI.cn(
        'ToolbarLinkItem',
        `h-auto rounded-none px-4 text-ds-sm text-natural-70 hover:bg-gray600 hover:text-natural-100 aria-current-page:text-natural-100 aria-current-page:after:absolute aria-current-page:after:bottom-0 aria-current-page:after:left-0 aria-current-page:after:h-[5px] aria-current-page:after:w-full aria-current-page:after:bg-orange-50 aria-current-page:after:content-['']`,
        className,
      )}
      {...restProps}
    />
  ),
) as ForwardRefComponent<typeof NavLinkButton, ToolbarLinkItemProps>
