// @ts-ignore

import {z} from '@cheddarup/util'

import {tabOptionsSchema, tabSchema, tabTypeSchema} from './tabs'

export const templateCollectionCategorySchema = z.enum([
  'Fundraisers',
  'Events',
  'Online Stores',
  'Dues & Fees',
  'Registrations',
  'Staff Picks',
  'Seasonal Collections',
  'Group Gifts',
  'Camps & Classes',
  'Group Travel',
  'Sign Ups',
])

export const tabTemplateGroupTypeSchema = z.enum([
  'Schools & PTAs',
  'Scouts',
  'Sports',
  'Nonprofits',
  'Clubs',
  'Reunions',
  'Bands & Choir',
  'Cheer',
  'Fine Arts',
  'HOAs',
  'Religious',
  'Small Business',
  'Greek Life',
  'Friends & Family',
  'Workplace',
])

export const tabTemplateCategorySchema = z.object({
  type: templateCollectionCategorySchema,
  order: z.number(),
})

export const tabTemplateGroupSchema = z.object({
  type: tabTemplateGroupTypeSchema,
  order: z.number(),
})

export const tabTemplateOptionsSchema = tabOptionsSchema
  .pick({
    posCode: true,
    paymentGoal: true,
    premiumMeta: true,
    internalMarketplace: true,
    onlyAllowOneDiscountPerPurchase: true,
  })
  .extend({
    template: z.object({
      categories: tabTemplateCategorySchema.array(),
      groups: tabTemplateGroupSchema.array(),
      presentedBy: z.string(),
    }),
    presentedBy: z.string(),
  })

export const tabTemplateUserSchema = z.object({
  full_name: z.string(),
})

export const tabTemplateSchema = tabSchema
  .pick({
    id: true,
    slug: true,
    created_at: true,
    deleted_at: true,
    creating_user_id: true,
    theme_id: true,
    name: true,
    description: true,
    collection_method: true,
    access_code: true,
    allow_echeck_payments: true,
    display_order: true,
    shipping_options: true,
    discounts_enabled: true,
    custom_receipt_enabled: true,
    custom_receipt_content: true,
    taxes_enabled: true,
    requires_pro: true,
    requires_team: true,
    collection_purpose: true,
    collection_subcategory: true,
    status: true,
    theme: true,
    featured_image: true,
    header_images: true,
  })
  .extend({
    type: tabTypeSchema.extract(['Template']),
    collection_category: templateCollectionCategorySchema,
    logo_id: z.number().nullable(),
    tab_name_description: z.string(),
    options: tabTemplateOptionsSchema,
    user: tabTemplateUserSchema,
  })

export const tabTemplatesOrderBySchema = z.enum(['recentlyAdded', 'mostUsed'])

declare global {
  namespace Api {
    // @ts-ignore
    type TemplateCollectionCategory = z.infer<
      typeof templateCollectionCategorySchema
    >
    // @ts-ignore
    type TabTemplateGroupType = z.infer<typeof tabTemplateGroupTypeSchema>
    // @ts-ignore
    type TabTemplateCategory = z.infer<typeof tabTemplateCategorySchema>
    // @ts-ignore
    type TabTemplateGroup = z.infer<typeof tabTemplateGroupSchema>
    // @ts-ignore
    type TabTemplateOptions = z.infer<typeof tabTemplateOptionsSchema>
    // @ts-ignore
    type TabTemplateUser = z.infer<typeof tabTemplateUserSchema>
    // @ts-ignore
    type TabTemplate = z.infer<typeof tabTemplateSchema>
    // @ts-ignore
    type TabTemplatesOrderBy = z.infer<typeof tabTemplatesOrderBySchema>
  }
}
