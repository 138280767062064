import {api, useUpdateTabMutation} from '@cheddarup/api-client'
import * as WebUI from '@cheddarup/web-ui'
import {useNavigate} from 'react-router-dom'
import {Link} from 'src/components/Link'
import {useManagerRole} from 'src/components/ManageRoleProvider'

export interface CollectionAccessGroupPageLinkProps {
  collection: Api.Tab
}

export const CollectionAccessGroupPageLink: React.FC<
  CollectionAccessGroupPageLinkProps
> = ({collection}) => {
  const navigate = useNavigate()
  const [managerRole] = useManagerRole()
  const sessionQuery = api.auth.session.useQuery()
  const groupPageCollectionSectionQuery = api.groupPageSections.detail.useQuery(
    {pathParams: {sectionName: 'collection_section'}},
  )
  const updateCollectionMutation = useUpdateTabMutation()
  const isTeamUser =
    !!managerRole || !!sessionQuery.data?.capabilities.subscribed_to_team

  return (
    <>
      {(!managerRole || managerRole.permissions.group_page) && (
        <WebUI.Tooltip>
          <WebUI.TooltipAnchor>
            <WebUI.Switch
              size="compact"
              disabled={!groupPageCollectionSectionQuery.data}
              checked={!!collection.collection_section_category_id}
              onChange={() => {
                if (!isTeamUser || !sessionQuery.data) {
                  return
                }
                updateCollectionMutation.mutate({
                  pathParams: {tabId: collection.id},
                  body: {
                    collection_section_category_id:
                      collection.collection_section_category_id
                        ? null
                        : (groupPageCollectionSectionQuery.data?.categories?.find(
                            (c) => c.name === 'default',
                          )?.id as number | undefined),
                  },
                })
              }}
              onClick={() => {
                if (!isTeamUser) {
                  navigate('i/plans?recommendedPlan=team')
                }
              }}
            >
              Include link in Group Page
            </WebUI.Switch>
          </WebUI.TooltipAnchor>
          <WebUI.TooltipContent
            variant="light"
            className="max-w-60 px-3 py-4 text-left"
          >
            {groupPageCollectionSectionQuery.data ? (
              <>
                The Group Page is a{' '}
                <WebUI.Anchor
                  href="https://www.cheddarup.com/team-plan/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Team feature
                </WebUI.Anchor>{' '}
                and serves as a one-stop shop for communities, so they’ll never
                have to hunt down different URLs again.
                <br />
                <WebUI.Anchor
                  href="https://support.cheddarup.com/hc/en-us/articles/360035226552-Find-and-share-your-Group-Page"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more
                </WebUI.Anchor>{' '}
              </>
            ) : (
              <>
                You need to create Collection Section in{' '}
                <Link to="/group" className="text-tint">
                  Group Page
                </Link>{' '}
                first
              </>
            )}
          </WebUI.TooltipContent>
        </WebUI.Tooltip>
      )}
    </>
  )
}

export default CollectionAccessGroupPageLink
