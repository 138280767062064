import * as Yup from 'yup'
import * as WebUI from '@cheddarup/web-ui'
import {useFormik} from '@cheddarup/react-util'
import React from 'react'
import {useCreateContactMutation} from '@cheddarup/api-client'
import FooterBig from 'src/components/FooterBig'

import {ContactPageHelmet} from './components'

const ContactPage: React.FC = () => (
  <>
    <WebUI.VStack className="gap-10 bg-gray100 pt-10 pb-16">
      <ContactPageHelmet />
      <WebUI.VStack className="max-w-screen-lg gap-2 self-center">
        <WebUI.Heading className="mx-6 font-accentAlt text-h-3 [font-stretch:expanded] sm:mx-0">
          Contact Us
        </WebUI.Heading>

        <WebUI.VStack
          className="w-full sm:flex-row [&_>_.Stack]:py-9"
          as={WebUI.Panel}
        >
          <WebUI.VStack className="gap-4 px-6">
            <WebUI.Heading className="uppercase" as="h5">
              Common Questions
            </WebUI.Heading>

            <ExternalAnchorButton href="https://support.cheddarup.com/hc/en-us/articles/360035586691-What-Cheddar-Up-does">
              What does Cheddar Up do?
            </ExternalAnchorButton>
            <ExternalAnchorButton href="https://support.cheddarup.com/hc/en-us/articles/360035586711-What-payers-need-to-know">
              How do I pay on Cheddar Up?
            </ExternalAnchorButton>
            <ExternalAnchorButton href="https://support.cheddarup.com/hc/en-us/articles/360035586731-About-fees">
              What are Cheddar Up’s fees?
            </ExternalAnchorButton>
            <ExternalAnchorButton href="https://support.cheddarup.com/hc/en-us/articles/360035586751-Ways-to-use-">
              How can I use Cheddar Up?
            </ExternalAnchorButton>
            <ExternalAnchorButton href="https://www.cheddarup.com/demo">
              Does Cheddar Up offer demos?
            </ExternalAnchorButton>
            <ExternalAnchorButton href="https://support.cheddarup.com/hc/en-us/articles/360036589452-Videos-Features">
              Where can I learn about features?
            </ExternalAnchorButton>
            <ExternalAnchorButton href="https://support.cheddarup.com/hc/en-us/articles/360035586711-What-payers-need-to-know#payerquestions">
              Where is my order?
            </ExternalAnchorButton>
            <ExternalAnchorButton href="https://support.cheddarup.com/hc/en-us/articles/360035586711-What-payers-need-to-know#commonquestions">
              How do I get a refund?
            </ExternalAnchorButton>
            <ExternalAnchorButton href="https://support.cheddarup.com/hc/en-us/articles/360035586991-Transfer-your-account">
              How do I transfer my account?
            </ExternalAnchorButton>
          </WebUI.VStack>

          <WebUI.Separator orientation="vertical" variant="primary" />

          <WebUI.VStack className="gap-4 px-9">
            <WebUI.Heading className="uppercase" as="h5">
              Get In Touch
            </WebUI.Heading>
            <WebUI.Text className="font-bold">
              Have questions about an order or need a refund?
            </WebUI.Text>
            <WebUI.Text className="mt-0 font-light">
              If so, do NOT use the form below. Instead, reach out to the group
              organizer who created your collection page. You should see a link
              to <b>contact the organizer</b> in your email receipt and/or the
              collection page.
            </WebUI.Text>
            <WebUI.Text className="font-bold">
              Have a question related to your account?
            </WebUI.Text>
            <WebUI.Text className="mt-0 font-light">
              Fill out the form below. We do our best to respond within 48
              hours. Or,{' '}
              <WebUI.Button
                variant="link"
                onClick={() => window.zE?.('webWidget', 'open')}
              >
                chat with us
              </WebUI.Button>{' '}
              from 9-5 (MT) by clicking the chat link below.
            </WebUI.Text>

            <ContactUsForm />
          </WebUI.VStack>
        </WebUI.VStack>
      </WebUI.VStack>
    </WebUI.VStack>
    <FooterBig />
  </>
)

// // MARK: – ContactUsForm

const ContactUsForm = (props: WebUI.FormProps) => {
  const createContactMutation = useCreateContactMutation()
  const growlActions = WebUI.useGrowlActions()

  const formik = useFormik({
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required'),
      email: Yup.string().required('Required').email('Invalid format'),
      subject: Yup.string().required('Required'),
      message: Yup.string().required('Required'),
    }),
    initialValues: {
      name: '',
      orgName: '',
      email: '',
      subject: '',
      message: '',
    },
    onSubmit: async (values, formikHelpers) => {
      try {
        await createContactMutation.mutateAsync({
          body: {
            name: values.name,
            orgName: values.orgName,
            email: values.email,
            subject: values.subject,
            message: `${values.message}\n\nUser agent: "${navigator.userAgent}"`,
          },
        })
        formikHelpers.resetForm()
        growlActions.show('success', {
          body: 'Your message was sent successfully',
          title: 'Thank you!',
        })
      } catch {
        growlActions.show('error', {
          body: 'An error happened while sending your message. Please try again.',
          title: 'Sorry!',
        })
      }
    },
  })

  return (
    <WebUI.Form onSubmit={formik.handleSubmit} {...props}>
      <WebUI.FormField
        required
        label="First and Last Name"
        error={formik.errors.name}
      >
        <WebUI.Input
          name="name"
          autoComplete="name"
          placeholder="First and Last Name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </WebUI.FormField>
      <WebUI.FormField
        label="Organization or Group Name"
        error={formik.errors.orgName}
      >
        <WebUI.Input
          name="orgName"
          autoComplete="organization"
          placeholder="Organization or Group Name (optional)"
          value={formik.values.orgName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </WebUI.FormField>
      <WebUI.FormField required label="Email" error={formik.errors.email}>
        <WebUI.Input
          type="email"
          name="email"
          autoComplete="email"
          placeholder="Cheddar Up account email (if applicable)"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </WebUI.FormField>
      <WebUI.FormField required label="Subject" error={formik.errors.subject}>
        <WebUI.Input
          name="subject"
          placeholder="Subject"
          value={formik.values.subject}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </WebUI.FormField>
      <WebUI.FormField required label="Message" error={formik.errors.message}>
        <WebUI.Textarea
          className="py-3"
          name="message"
          placeholder="I’m interested in learning more about…"
          rows={6}
          value={formik.values.message}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </WebUI.FormField>

      <WebUI.Button
        className="self-start"
        type="submit"
        loading={formik.isSubmitting}
      >
        Submit
      </WebUI.Button>
    </WebUI.Form>
  )
}

// MARK: – ExternalAnchorButton

const ExternalAnchorButton = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'a'>) => (
  <WebUI.AnchorButton
    className={WebUI.cn(
      'ExternalAnchorButton min-w-[280px] justify-start bg-teal-80 px-3 text-left text-gray800 [&.ExternalAnchorButton:hover]:bg-teal-70',
      className,
    )}
    variant="default"
    iconAfter={<WebUI.PhosphorIcon icon="arrow-right" />}
    target="_blank"
    rel="noopener noreferrer"
    {...restProps}
  />
)

export default ContactPage
