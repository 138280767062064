import React from 'react'
import * as WebUI from '@cheddarup/web-ui'

import ItemFormImagesField from './ItemFormImagesField'
import type {FixedItemFormFormik} from '../../containers/FixedItemForm/FixedItemForm'
import type {DonationItemFormFormik} from '../../containers/DonationItemForm/DonationItemForm'
import type {RecurringItemFormFormik} from '../../containers/RecurringItemForm/RecurringItemForm'

export interface ItemFormImagesAndDescriptionProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collectionId: number
  formik: FixedItemFormFormik | DonationItemFormFormik | RecurringItemFormFormik
}

const ItemFormImagesAndDescription = ({
  collectionId,
  formik,
  className,
  ...restProps
}: ItemFormImagesAndDescriptionProps) => (
  <div
    className={WebUI.cn(
      'flex flex-col [&_.Disclosure]:border-natural-80 [&_.Disclosure]:border-b [&_.Disclosure_.DisclosureContent]:pb-8',
      className,
    )}
    {...restProps}
  >
    <WebUI.Disclosure initialVisible>
      <WebUI.AccordionHeader>Description</WebUI.AccordionHeader>
      <WebUI.DisclosureContent>
        <WebUI.FormField
          label="Add a description of your item"
          error={formik.errors.description}
        >
          <WebUI.RichTextEditor
            key={formik.initialValues.description}
            className="min-h-[200px] max-w-[512px]"
            name="description"
            placeholder="Description"
            initialMarkdownValue={formik.values.description}
            onMarkdownValueChange={(newDescription) =>
              formik.setFieldValue('description', newDescription)
            }
          >
            <WebUI.RichTextEditorToolbar
              className="justify-end [&_>_.RichTextEditorToolbar-contentMain]:flex-0 [&_>_.RichTextEditorToolbar-extra]:flex-[0_0_0px]"
              rootClassName="-order-1"
              pick={['bold', 'italic', 'a', 'ul', 'ol']}
            />
          </WebUI.RichTextEditor>
        </WebUI.FormField>
      </WebUI.DisclosureContent>
    </WebUI.Disclosure>

    <WebUI.Disclosure>
      <WebUI.AccordionHeader>Image</WebUI.AccordionHeader>
      <WebUI.DisclosureContent>
        <div className="flex flex-col gap-3">
          <WebUI.Text className="font-light text-ds-sm">
            By uploading an image, you agree that your images do not violate
            copyright permissions.
          </WebUI.Text>
          <ItemFormImagesField
            collectionId={collectionId}
            images={formik.values.images}
            onChange={(newImages) => formik.setFieldValue('images', newImages)}
          />
        </div>
      </WebUI.DisclosureContent>
    </WebUI.Disclosure>
  </div>
)

export default ItemFormImagesAndDescription
