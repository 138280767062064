import React, {useMemo} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'

import type {CollectionAdditionalInformationFormik} from './CollectionAdditionalInformationForm'

export interface TabFaqValue extends Pick<Api.TabFaq, 'question' | 'answer'> {
  id: number | string
}

interface FormikRow
  extends CollectionAdditionalInformationFormik,
    Pick<TabFaqValue, 'id'> {}

export interface TabFaqsEditProps
  extends React.ComponentPropsWithoutRef<'div'> {
  formik: CollectionAdditionalInformationFormik
  maxAnswerChars: number
}

export const TabFaqsEdit: React.FC<TabFaqsEditProps> = ({
  formik,
  maxAnswerChars,
  className,
  ...restProps
}) => {
  const EnhancedTabFaqEditCard: WebUI.ListRowComponentType<FormikRow> = useMemo(
    () =>
      React.forwardRef((rowProps, rowForwardedRef) => (
        <TabFaqEditCard
          ref={rowForwardedRef as any}
          formik={rowProps.data}
          maxAnswerChars={maxAnswerChars}
          {...rowProps}
        />
      )),
    [maxAnswerChars],
  )

  // HACK: – preventing recreating the EnhancedTabFaqEditCard
  // can't decide if this is genius or stupid as hell
  const formikRows = useMemo(
    () =>
      formik.values.faqs.map((faq) => ({
        ...formik,
        id: faq.id,
      })),
    [formik],
  )

  return (
    <WebUI.VStack className={WebUI.cn('gap-5', className)} {...restProps}>
      <WebUI.SortableList
        className="max-w-screen-sm gap-4"
        touchEnabled
        dragOverlayPortal={false}
        modifiers={[WebUI.followMouseModifier]}
        rowsDraggable={false}
        EmptyStateViewComponent={null}
        RowComponent={EnhancedTabFaqEditCard}
        data={formikRows}
        onOrderChange={(orderedFaqIds) =>
          formik.setFieldValue(
            'faqs',
            orderedFaqIds.map((faqId) =>
              formik.values.faqs.find((faq) => faq.id === faqId),
            ),
          )
        }
      />

      {formik.values.faqs.length < 4 && (
        <WebUI.Button
          className="self-start"
          variant="secondary"
          onClick={() =>
            formik.setFieldValue('faqs', [
              ...formik.values.faqs,
              makeTabFaqValue(),
            ])
          }
        >
          Add Question
        </WebUI.Button>
      )}
    </WebUI.VStack>
  )
}

// MARK: – TabFaqEditCard

interface TabFaqEditCardProps
  extends WebUI.CardProps,
    React.ComponentPropsWithoutRef<'div'> {
  formik: FormikRow
  index: number
  maxAnswerChars: number
}

const TabFaqEditCard = React.memo(
  React.forwardRef<HTMLDivElement, TabFaqEditCardProps>(
    (
      {
        formik,
        index: idx,
        maxAnswerChars,
        dragHandleVisible = true,
        dragListeners,
        className,
        ...restProps
      },
      forwardedRef,
    ) => {
      const faq = formik.values.faqs[idx]

      return (
        <WebUI.VStack
          ref={forwardedRef}
          className={WebUI.cn('gap-4 p-6 pl-10', className)}
          as={WebUI.Card}
          dragHandleVisible
          dragListeners={dragListeners}
          accessoryView={
            <WebUI.ActionGroup>
              <WebUI.Action
                icon={<WebUI.PhosphorIcon icon="trash" />}
                onClick={() =>
                  formik.setFieldValue(
                    'faqs',
                    formik.values.faqs.filter((f) => f.id !== faq?.id),
                  )
                }
              >
                Delete
              </WebUI.Action>
            </WebUI.ActionGroup>
          }
          {...restProps}
        >
          <WebUI.FormField
            required
            label="Question"
            error={(formik.errors.faqs as any)?.[idx]?.question}
          >
            <WebUI.Input
              name={`faqs[${idx}].question`}
              value={faq?.question}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </WebUI.FormField>
          <WebUI.FormField
            className="[&:has(*[aria-invalid=true])_>_.FormField-caption]:text-orange-50 [&_>_.FormField-caption]:[display:initial]"
            required
            label="Answer"
            error={(formik.errors.faqs as any)?.[idx]?.answer}
            caption={`${faq?.answer.length ?? 0} / ${maxAnswerChars}`}
          >
            <WebUI.Textarea
              aria-invalid={(faq?.answer.length ?? 0) > maxAnswerChars}
              name={`faqs[${idx}].answer`}
              value={faq?.answer}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </WebUI.FormField>
        </WebUI.VStack>
      )
    },
  ),
)

// MARK: – Helpers

export function makeTabFaqValue(overrides?: TabFaqValue) {
  return {
    id: Util.makeShortId(),
    question: '',
    answer: '',
    ...overrides,
  }
}
