import {useForkRef, useUpdateEffect} from '@cheddarup/react-util'
import React, {useRef} from 'react'

import {RichTextEditor, RichTextEditorInstance} from './Plate'
import {cn} from '../utils'

export interface MarkdownParagraphProps
  extends React.ComponentPropsWithoutRef<'div'> {
  markdown: string
}

export const MarkdownParagraph = React.memo(
  React.forwardRef<RichTextEditorInstance, MarkdownParagraphProps>(
    ({markdown, className, ...restProps}, forwardedRef) => {
      const ownRef = useRef<RichTextEditorInstance>(null)
      const ref = useForkRef(ownRef, forwardedRef)

      useUpdateEffect(() => {
        ownRef.current?.setMarkdownValue(markdown)
      }, [markdown])

      return (
        <RichTextEditor
          ref={ref}
          className={cn(
            '[&_>_.RichTextEditor]:!bg-inherit min-h-[auto] shadow-none focus-within:shadow-none [&_>_.RichTextEditor]:p-0',
            className,
          )}
          readOnly
          initialMarkdownValue={markdown.replace(
            // Move spaces out of md tokens: "** bold **"" =>  "**bold** "
            /([*_]+)(\s*)([^*]+?)(\s*)([*_]+)/g,
            (
              _m,
              leadingFormatters,
              leadingSpaces,
              rawText,
              trailingSpaces,
              trailingFormatters,
            ) =>
              `${leadingFormatters}${leadingSpaces}${rawText}${trailingSpaces}${trailingFormatters}`,
          )}
          {...restProps}
        />
      )
    },
  ),
)
