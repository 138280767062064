import {capitalize, sumBy} from '@cheddarup/util'

export function formatCardEntryMethod(cardEntryMethod: Api.CardEntryMethod) {
  switch (cardEntryMethod) {
    case 'tapOnMobile':
      return 'Tap on mobile'
    default:
      return capitalize(cardEntryMethod)
  }
}

export function isItemViewSoldOut({
  itemView,
  listingUuid,
  cart,
}: {
  itemView: Api.PublicTabItem
  listingUuid?: string | null
  cart?: Api.Cart
}) {
  if (itemView.options.variants?.enabled) {
    if (!listingUuid) {
      const hasSomeAvailableListing = itemView.options.variants.listings.some(
        (l) => l.available_quantity == null || l.available_quantity > 0,
      )
      const hasSomeAvailableInventoryGroup = itemView.inventory_items.some(
        (ii) =>
          ii.inventory_groups.length === 0 ||
          ii.inventory_groups.some((ig) => ig.available_quantity > 0),
      )
      return !hasSomeAvailableListing || !hasSomeAvailableInventoryGroup
    }

    const listing = itemView.options.variants.listings.find(
      (l) => l.uuid === listingUuid,
    )

    if (!listing) {
      throw new Error(`Listing with uuid "${listingUuid}" not found`)
    }

    const listingInventoryGroups =
      itemView.inventory_items.find((ii) => ii.variant_uuid === listingUuid)
        ?.inventory_groups ?? []
    const cartListingQuantity = sumBy(
      cart?.items.filter((ci) => ci.detail.variant?.uuid === listingUuid) ?? [],
      (ci) => ci.quantity,
    )

    return (
      (listing.available_quantity != null &&
        listing.available_quantity - cartListingQuantity < 1) ||
      listingInventoryGroups.some(
        (ig) => ig.available_quantity - cartListingQuantity < 1,
      )
    )
  }

  if (itemView.available_quantity == null) {
    return false
  }

  const cartItemQuantity = sumBy(
    cart?.items.filter((ci) => ci.tab_item.id === itemView.id) ?? [],
    (ci) => ci.quantity,
  )

  return itemView.available_quantity - cartItemQuantity < 1
}

// MARK: – Missing required

export function getMissingRequiredItems({
  publicTab,
  cart,
}: {publicTab: Api.PublicTab; cart: Api.Cart | undefined}) {
  const requiredCartItemViewIds =
    cart?.items
      .filter((ci) => ci.tab_item.required)
      .map((ci) => ci.tab_item.id) ?? []

  return publicTab.items
    .filter((i) => i.required)
    .filter((i) => !requiredCartItemViewIds.includes(i.id))
}

export function getMissingRequiredForms({
  publicTab,
  cart,
}: {publicTab: Api.PublicTab; cart: Api.Cart | undefined}) {
  const tabRequiredForms = publicTab.forms.filter((f) => f.required)
  const dynamicForms =
    cart?.forms
      .filter((cf) => cf.detail.linked_item_id)
      .map((cf) => {
        const tabForm = tabRequiredForms.find((f) => f.id === cf.tab_form.id)

        if (!tabForm) {
          return null
        }

        return {
          ...tabForm,
          detail: cf.detail,
        }
      })
      .filter((f) => f != null) ?? []
  const dynamicFormIds = dynamicForms.map((df) => df.id)
  const requiredForms = [
    ...tabRequiredForms.filter((rf) => !dynamicFormIds.includes(rf.id)),
    ...dynamicForms,
  ]
  return requiredForms.filter((f) => {
    const formInCart = cart?.forms.find(
      (cf) =>
        cf.tab_form.id === f.id &&
        (!cf.detail.linked_item_id ||
          cf.detail.linked_item_id === f.detail?.linked_item_id),
    )
    // linked item with form is not added in cart
    if (f.linked_item_id && !formInCart) {
      return false
    }

    const isFormFilled =
      formInCart &&
      (!formInCart.detail.linked_item_id ||
        f.fields.some((fField) =>
          formInCart.cart_field_views.some(
            (cFieldView) => cFieldView.item_field_id === fField.id,
          ),
        ))
    return !isFormFilled
  })
}

export function getMissingRequiredSignups({
  publicTab,
  cart,
}: {publicTab: Api.PublicTab; cart: Api.Cart | undefined}) {
  const cartTimeSlotIds = cart?.time_slots.map((cTs) => cTs.time_slot.id) ?? []
  const requiredSignups = publicTab.signups.filter(
    (s) => s.options.requireAtLeastOneSpot,
  )

  return requiredSignups.filter(
    (s) =>
      !s.visible_spots.some((v) =>
        v.time_slots.some((m) => cartTimeSlotIds.includes(m.id)),
      ),
  )
}

export function getMissingRequiredCartObjectFields<
  T extends Api.PublicTabItem | Api.PublicTabForm,
>({
  objects,
  cartObjects,
}: {
  objects: T[]
  cartObjects: T extends Api.PublicTabItem
    ? Api.CheddarUpCartItem[]
    : Api.CheddarUpCartForm[]
}) {
  const requiredFields = objects
    .flatMap((f) => f.fields)
    .filter((f) => f.required)
  const fieldViewFieldIds =
    cartObjects
      .flatMap((f) => f.cart_field_views)
      .map((fv) => fv.item_field_id) ?? []

  return requiredFields.filter((f) => !fieldViewFieldIds.includes(f.id))
}

export function getMissingRequiredItemFields({
  publicTab,
  cart,
}: {publicTab: Api.PublicTab; cart: Api.Cart | undefined}) {
  return getMissingRequiredCartObjectFields({
    objects: publicTab.items,
    cartObjects: cart?.items ?? [],
  })
}

export function getMissingRequiredFormFields({
  publicTab,
  cart,
}: {publicTab: Api.PublicTab; cart: Api.Cart | undefined}) {
  const cartFormIds = cart?.forms.map((cf) => cf.id) ?? []
  const requiredForms = publicTab.forms.filter(
    (f) => (f.required && !f.linked_item_id) || cartFormIds.includes(f.id),
  )
  return getMissingRequiredCartObjectFields({
    objects: requiredForms,
    cartObjects: cart?.forms ?? [],
  })
}

export function hasMissingRequiredItems({
  publicTab,
  cart,
}: {publicTab: Api.PublicTab; cart: Api.Cart | undefined}) {
  return getMissingRequiredItems({publicTab, cart}).length > 0
}

export function hasMissingRequiredItemFields({
  publicTab,
  cart,
}: {publicTab: Api.PublicTab; cart: Api.Cart | undefined}) {
  return getMissingRequiredItemFields({publicTab, cart}).length > 0
}

export function hasMissingRequiredFormFields({
  publicTab,
  cart,
}: {publicTab: Api.PublicTab; cart: Api.Cart | undefined}) {
  return getMissingRequiredFormFields({publicTab, cart}).length > 0
}

export function hasMissingRequiredSignups({
  publicTab,
  cart,
}: {publicTab: Api.PublicTab; cart: Api.Cart | undefined}) {
  return getMissingRequiredSignups({publicTab, cart}).length > 0
}

export function hasMissingRequiredForms({
  publicTab,
  cart,
}: {publicTab: Api.PublicTab; cart: Api.Cart | undefined}) {
  return getMissingRequiredForms({publicTab, cart}).length > 0
}

export function getVisibleCartForms(cart?: Api.Cart) {
  if (!cart) {
    return []
  }

  return cart.forms.filter((f) => {
    if (!f.detail.linked_item_id) {
      return true
    }

    const itemsCartFieldViewFieldIds = cart.items.flatMap((i) =>
      i.cart_field_views.map((cfv) => cfv.item_field_id),
    )
    return f.cart_field_views
      .map((cfv) => cfv.item_field_id)
      .some((fieldId) => itemsCartFieldViewFieldIds.indexOf(fieldId) === -1)
  })
}
