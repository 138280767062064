import React, {useContext, useMemo, useState} from 'react'

export type SelectionStateId = string | number

export interface SelectionStateContextValue<TId extends SelectionStateId> {
  selectionMap: Record<TId, boolean>
  select: (id: TId) => void
  unselect: (id: TId) => void
}

const SelectionStateContext = React.createContext(
  {} as SelectionStateContextValue<any>,
)

interface SelectionStateProviderProps<TId extends SelectionStateId> {
  children:
    | React.ReactNode
    | ((contextValue: SelectionStateContextValue<TId>) => React.ReactNode)
}

export function SelectionStateProvider<TId extends SelectionStateId>({
  children,
}: SelectionStateProviderProps<TId>) {
  const [selectionMap, setSelectionMap] = useState({} as Record<TId, boolean>)

  const contextValue: SelectionStateContextValue<TId> = useMemo(
    () => ({
      selectionMap,
      select: (id) =>
        setSelectionMap((prevSelectionMap) => ({
          ...prevSelectionMap,
          [id]: true,
        })),
      unselect: (id) =>
        setSelectionMap((prevSelectionMap) => ({
          ...prevSelectionMap,
          [id]: false,
        })),
    }),
    [selectionMap],
  )

  return (
    <SelectionStateContext.Provider value={contextValue}>
      {typeof children === 'function' ? children(contextValue) : children}
    </SelectionStateContext.Provider>
  )
}

export function useIsSelected(id: SelectionStateId) {
  const contextValue = useContext(SelectionStateContext)
  return contextValue.selectionMap?.[id] === true
}

export function useSelectionActions() {
  const contextValue = useContext(SelectionStateContext)

  return useMemo(
    () => ({
      select: contextValue.select,
      unselect: contextValue.unselect,
    }),
    [contextValue.select, contextValue.unselect],
  )
}

export function useSelectionMap() {
  const contextValue = useContext(SelectionStateContext)

  return contextValue.selectionMap
}
