import {SharpAvatar} from 'src/components/SharpAvatar'
import config from 'src/config'

export interface ReportingSharpAvatarProps {
  image: string
  name?: string
  size: number
}

const ReportingSharpAvatar: React.FC<ReportingSharpAvatarProps> = ({
  image,
  name,
  size,
}) => (
  <SharpAvatar
    image={
      image
        ? `https://${config.s3BucketName}.s3.amazonaws.com/${image}`
        : undefined
    }
    name={name}
    size={size}
  />
)

export default ReportingSharpAvatar
