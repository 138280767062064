import {Link} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import {tailwindConfig} from '@cheddarup/tailwind-config'

const NoPayments = () => (
  <div className="py-16 text-center">
    <h2 className="font-light">
      You don&apos;t have any payments.
      <br />
      Share your collection!
    </h2>
    <Link to="../share">
      <WebUI.PlanePlusSolidIcon
        className="mt-8 h-[6.25rem]"
        fill={tailwindConfig.theme.colors.primary}
      />
    </Link>
  </div>
)

export default NoPayments
