import {useLocation, useNavigate} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'

import usePublicCollection from './hooks/usePublicCollection'

const CollectionPayerListPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const {publicCollection} = usePublicCollection()

  return (
    <WebUI.Modal
      className="[&_>_.ModalContentView]:max-w-screen-lg sm:[&_>_.ModalContentView]:rounded-extended"
      onDidHide={() => {
        navigate({
          pathname: '..',
          search: location.search,
        })
      }}
    >
      <WebUI.VStack className="gap-5 overflow-y-auto px-4 py-8 sm:px-8">
        <WebUI.Heading className="font-semibold" as="h3">
          {publicCollection.infoBlockSettings?.payerList
            ?.customContributorName || 'Participants'}
        </WebUI.Heading>
        <main>
          <WebUI.HStack className="flex-wrap gap-1" as="ul">
            {publicCollection.payers?.map((payer, idx) => (
              <li
                key={idx}
                className="w-1/2 text-ds-base text-gray800 md:w-1/3 lg:w-1/4"
              >
                {payer}
              </li>
            ))}
          </WebUI.HStack>
        </main>
      </WebUI.VStack>
      <WebUI.ModalCloseButton />
    </WebUI.Modal>
  )
}

export default CollectionPayerListPage
