import React from 'react'
import * as WebUI from '@cheddarup/web-ui'

type DivAndSvgPropsIntersection = React.ComponentPropsWithoutRef<'div'> &
  React.ComponentPropsWithoutRef<'svg'>

export interface LogoProps
  extends DivAndSvgPropsIntersection,
    Pick<WebUI.LogoIconProps, 'iconVariant'> {
  forceDisplayText?: boolean
}

export const Logo = ({
  forceDisplayText = false,
  iconVariant,
  className,
  ...restProps
}: LogoProps) => {
  const media = WebUI.useMedia()

  return forceDisplayText || media.sm ? (
    <WebUI.LogoIcon
      className={WebUI.cn('text-ds-xl', className)}
      iconVariant={iconVariant}
      {...restProps}
    />
  ) : (
    <WebUI.LogoGraphicsIcon
      className={WebUI.cn(className, 'text-ds-2xl')}
      variant={iconVariant}
      {...restProps}
    />
  )
}

export default Logo
