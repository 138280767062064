import * as WebUI from '@cheddarup/web-ui'
import React, {useEffect} from 'react'
import {NavLink, NavLinkProps} from './Link'
import {useLocation, useResolvedPath} from 'react-router-dom'

export interface NavListItemNavLinkProps extends NavLinkProps {}

export const NavListItemNavLink = React.forwardRef<
  HTMLAnchorElement,
  NavListItemNavLinkProps
>(({iconBefore, ...restProps}, forwardedRef) => {
  const navListSubNav = WebUI.useNavListSubNav()
  const locationPathname = useLocation().pathname
  const toPathname = useResolvedPath(restProps.to, {
    relative: restProps.relative,
  }).pathname

  const isActive = locationPathname === toPathname

  useEffect(() => {
    if (isActive) {
      navListSubNav?.setVisible(true)
    }
  }, [isActive, navListSubNav?.setVisible])

  return (
    <WebUI.NavListItemAnchor
      render={
        <NavLink
          ref={forwardedRef as any}
          iconBefore={iconBefore ?? <div className="h-4 w-4" />}
          {...restProps}
        />
      }
    />
  )
})
