import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {categorySchema} from '../schemas'

export default {
  list: makeEndpoint({
    path: 'users/tabs/:tabId/categories',
    responseSchema: categorySchema.array(),
  }),
  detail: makeEndpoint({
    path: 'users/tabs/:tabId/categories/:categoryId',
    responseSchema: categorySchema,
  }),
  create: makeEndpoint({
    path: 'users/tabs/:tabId/categories',
    method: 'POST',
    bodySchema: z.object({
      name: z.string(),
      description: z.string().optional(),
      anchor: z.boolean().optional(),
      options: z
        .object({
          subcategories: z.object({name: z.string()}).array().optional(),
        })
        .optional(),
    }),
    responseSchema: categorySchema,
  }),
  update: makeEndpoint({
    path: 'users/tabs/:tabId/categories/:categoryId',
    method: 'PATCH',
    bodySchema: z
      .object({
        name: z.string(),
        description: z.string(),
        anchor: z.boolean().optional(),
        options: z
          .object({
            subcategories: z.object({name: z.string()}).array(),
          })
          .partial(),
      })
      .partial(),
    responseSchema: categorySchema,
  }),
  delete: makeEndpoint({
    path: 'users/tabs/:tabId/categories/:categoryId',
    method: 'DELETE',
    responseSchema: z.any(),
  }),
}
