import {useParams} from 'react-router-dom'
import {api} from '@cheddarup/api-client'
import {InvoiceDetails} from 'src/components'

const PaymentDetailsPage = () => {
  const urlParams = useParams()
  const {data: payment} = api.tabPayments.detail.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: urlParams.collection!,
      // biome-ignore lint/style/noNonNullAssertion:
      paymentId: urlParams.payment!,
    },
  })

  if (!payment) {
    return null
  }

  return <InvoiceDetails payment={payment} />
}

export default PaymentDetailsPage
