import * as Yup from 'yup'
import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'
import {useFormik} from '@cheddarup/react-util'
import {useParams} from 'react-router-dom'
import {
  api,
  useAdjustPricesItemsMutation,
  useBulkDeleteItemsMutation,
} from '@cheddarup/api-client'
import React, {Dispatch} from 'react'
import {TabObjectBatchToggleVisibilityButton} from 'src/components/TabObjectBatchButtons'
import {useUndoableMutation} from 'src/hooks/useUndoableMutation'
import {MoveTabObjectsToAnotherCollectionOrCategoryModal} from 'src/components'

export interface ItemListBulkActionsToolbarProps
  extends WebUI.DataListToolbarProps {
  categories: Array<Api.Category | {id: -1}>
  items: Api.TabItem[]
  listDispatch: Dispatch<WebUI.DataListAction>
  listState: WebUI.DataListState
}

export const ItemListBulkActionsToolbar = ({
  categories,
  items,
  listState,
  listDispatch,
  ...restProps
}: ItemListBulkActionsToolbarProps) => {
  const urlParams = useParams()
  const collectionId = Number(urlParams.collection)
  const {data: otherCollections} = api.tabs.list.useQuery(undefined, {
    select: (collections) => collections.filter(({id}) => id !== collectionId),
    placeholderData: [] as Api.Tab[],
  })
  const bulkDeleteItemsUndoableMutation = useUndoableMutation(
    useBulkDeleteItemsMutation({timeout: 8000}),
  )

  const selectedItemIds = Object.keys(
    Util.pickBy(listState.selectedItemIdsMap, (v) => v === true),
  ).map(Number)
  const selectedItems = items.filter((i) => selectedItemIds.includes(i.id))

  return (
    <WebUI.DataListToolbar sectionList {...restProps}>
      {(categories.length > 0 ||
        (otherCollections && otherCollections.length > 0)) && (
        <MoveTabObjectsToAnotherCollectionOrCategoryModal
          disclosure={
            <WebUI.DialogDisclosure
              size="compact"
              variant="secondaryAlt"
              iconBefore={<WebUI.PhosphorIcon icon="arrow-right" />}
            >
              Move to
            </WebUI.DialogDisclosure>
          }
          objectType="item"
          collectionId={collectionId}
          tabObjectIds={selectedItemIds}
          selectedItems={selectedItems}
          onDidMove={() =>
            listDispatch({
              type: 'SET_ALL_ITEMS_SELECTED',
              value: false,
            })
          }
        />
      )}
      <AdjustPricingModal selectedItemIds={selectedItemIds} />
      {selectedItems.some((i) => !i.required) && (
        <TabObjectBatchToggleVisibilityButton
          size="compact"
          variant="secondaryAlt"
          objectType="item"
          tabObjects={selectedItems}
        />
      )}
      <WebUI.Button
        size="compact"
        variant="secondaryAlt"
        onClick={() => {
          bulkDeleteItemsUndoableMutation.mutateWithUndo(
            {
              body: `You’ve deleted ${Util.pluralize(
                'item',
                selectedItemIds.length,
              )}.`,
            },
            {
              pathParams: {
                tabId: collectionId,
              },
              body: {
                tab_item_ids: selectedItemIds,
              },
            },
          )

          listDispatch({type: 'SET_ALL_ITEMS_SELECTED', value: false})
        }}
      >
        Delete
      </WebUI.Button>
    </WebUI.DataListToolbar>
  )
}

// MARK: – AdjustPricingModal

interface AdjustPricingModalProps
  extends WebUI.ButtonProps,
    React.ComponentPropsWithoutRef<'button'> {
  selectedItemIds: number[]
}

const AdjustPricingModal = ({
  selectedItemIds,
  ...restProps
}: AdjustPricingModalProps) => (
  <WebUI.Modal
    aria-label="Adjust items pricing"
    className="[&_>_.ModalContentView]:max-w-[490px] [&_>_.ModalContentView]:rounded-large [&_>_.ModalContentView]:p-9"
    initialVisible={false}
    disclosure={
      <WebUI.DialogDisclosure
        size="compact"
        variant="secondaryAlt"
        iconBefore={<WebUI.PhosphorIcon icon="percent" />}
        {...restProps}
      >
        Adjust Pricing
      </WebUI.DialogDisclosure>
    }
  >
    {(dialog) => (
      <>
        <WebUI.ModalCloseButton />
        <WebUI.VStack className="gap-6">
          <WebUI.Heading as="h2">Adjust Pricing</WebUI.Heading>
          <WebUI.Text className="font-light text-ds-sm">
            Change the price of selected items to a fixed amount or by a
            percentage.
          </WebUI.Text>
          <WebUI.Text className="font-light text-ds-sm italic">
            Tip: If you select to increase the percentage 100% it doubles the
            price of the item while decreasing the percentage 50% reduces the
            price of the item by half.
          </WebUI.Text>
          <ItemsAdjustPricingForm
            selectedItemIds={selectedItemIds}
            onDidSave={() => dialog.hide()}
          />
        </WebUI.VStack>
      </>
    )}
  </WebUI.Modal>
)

// MARK: – ItemsAdjustPricingForm

interface ItemsAdjustPricingFormProps
  extends WebUI.FormProps,
    React.ComponentPropsWithoutRef<'form'> {
  selectedItemIds: number[]
  onDidSave?: () => void
}

const ItemsAdjustPricingForm = ({
  selectedItemIds,
  onDidSave,
  className,
  ...restProps
}: ItemsAdjustPricingFormProps) => {
  const urlParams = useParams()
  const itemsHaveRetailPriceQuery = api.tabItems.list.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: urlParams.collection!,
      },
    },
    {
      select: (items) =>
        items.some((item) => {
          const anyListingHasRetailPrice =
            item.options?.variants?.enabled &&
            item.options.variants.listings?.some(
              (l) => typeof l.retailPrice === 'number',
            )

          return (
            anyListingHasRetailPrice ||
            typeof item.options?.retailPrice === 'number'
          )
        }),
    },
  )
  const adjustPricesItemsUndoableMutation = useUndoableMutation(
    useAdjustPricesItemsMutation({timeout: 8000}),
  )

  const formik = useFormik({
    validationSchema: Yup.object().shape({
      adjustBy: Yup.string()
        .required('Required')
        .oneOf(['DECREASE', 'INCREASE', 'FIXED_AMOUNT']),
      percentageOrAmount: Yup.number()
        .required('Required')
        .when('adjustBy', {
          is: 'DECREASE',
          // biome-ignore lint/suspicious/noThenProperty: <explanation>
          then: (schema) => schema.max(99.99, 'Max is 99'),
        }),
    }),
    initialValues: {
      adjustBy: '',
      percentageOrAmount: '',
      basis: 'sale' as 'sale' | 'retail',
    },
    onSubmit: (values) => {
      const changeAsFloat = Number.parseFloat(values.percentageOrAmount)
      const parsedChange =
        values.adjustBy === 'INCREASE'
          ? 100 + changeAsFloat
          : values.adjustBy === 'DECREASE'
            ? 100 - changeAsFloat
            : changeAsFloat

      adjustPricesItemsUndoableMutation.mutateWithUndo(
        {
          body: 'Prices adjusted successfully',
        },
        {
          pathParams: {
            // biome-ignore lint/style/noNonNullAssertion:
            tabId: urlParams.collection!,
          },
          body: {
            tab_item_ids: selectedItemIds,
            basis: values.basis,
            ...(values.adjustBy === 'FIXED_AMOUNT'
              ? {fixed: parsedChange}
              : {percentage: parsedChange}),
          },
        },
      )

      onDidSave?.()
    },
  })

  const percentInputPlaceholder = `${formik.values.adjustBy === 'INCREASE' ? '+' : '-'}% `

  return (
    <form onSubmit={formik.handleSubmit} {...restProps}>
      <WebUI.VStack className="grow gap-6 overflow-y-auto">
        <WebUI.FormFieldGroup>
          <WebUI.FormField
            className="min-w-[60%] sm:max-w-[60%]"
            error={formik.errors.adjustBy}
          >
            <WebUI.DropdownSelect
              name="adjustBy"
              placeholder="Select Adjustment"
              value={formik.values.adjustBy}
              onValueChange={(newAdjustBy) =>
                formik.setFieldValue('adjustBy', newAdjustBy)
              }
              onBlur={formik.handleBlur}
            >
              <WebUI.DropdownSelectOption value="INCREASE">
                Increase by a percentage
              </WebUI.DropdownSelectOption>
              <WebUI.DropdownSelectOption value="DECREASE">
                Decrease by a percentage
              </WebUI.DropdownSelectOption>
              <WebUI.DropdownSelectOption value="FIXED_AMOUNT">
                Fixed Amount
              </WebUI.DropdownSelectOption>
            </WebUI.DropdownSelect>
          </WebUI.FormField>
          {formik.values.adjustBy && (
            <WebUI.FormField error={formik.errors.percentageOrAmount}>
              {formik.values.adjustBy === 'FIXED_AMOUNT' ? (
                <WebUI.AmountInput
                  name="percentageOrAmount"
                  prefix="$ "
                  placeholder="$ "
                  value={formik.values.percentageOrAmount}
                  onValueChange={(newPercent) =>
                    formik.setFieldValue('percentageOrAmount', newPercent)
                  }
                  onBlur={formik.handleBlur}
                />
              ) : (
                <WebUI.PercentInput
                  name="percentageOrAmount"
                  prefix={percentInputPlaceholder}
                  suffix=""
                  placeholder={percentInputPlaceholder}
                  value={formik.values.percentageOrAmount}
                  onValueChange={(newPercent) =>
                    formik.setFieldValue('percentageOrAmount', newPercent)
                  }
                  onBlur={formik.handleBlur}
                />
              )}
            </WebUI.FormField>
          )}
        </WebUI.FormFieldGroup>
        {itemsHaveRetailPriceQuery.data && (
          <WebUI.RadioGroup
            name="basis"
            size="compact"
            state={formik.values.basis}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            <WebUI.Radio value="retail">
              Apply to original retail price (for display only)
            </WebUI.Radio>
            <WebUI.Radio value="sale">Apply to sale price</WebUI.Radio>
          </WebUI.RadioGroup>
        )}
        <WebUI.Button
          className="self-start px-8"
          type="submit"
          loading={formik.isSubmitting}
        >
          Save
        </WebUI.Button>
      </WebUI.VStack>
    </form>
  )
}
