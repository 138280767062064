import {DisclosureStateReturn as ReakitDisclosureStateReturn} from 'reakit'
import React from 'react'

import {useMedia} from '../hooks'
import {
  Disclosure,
  DisclosureContent,
  DisclosureInstance,
  DisclosureProps,
} from './Disclosure'
import {IconButton} from './IconButton'
import {PhosphorIcon} from '../icons'
import {cn} from '../utils'

export interface MasterDetailDisclosedProps extends DisclosureProps {
  detail:
    | React.ReactNode
    | ((disclosure: ReakitDisclosureStateReturn) => React.ReactNode)
}

export const MasterDetailDisclosed = React.forwardRef<
  DisclosureInstance,
  MasterDetailDisclosedProps
>(({detail, className, children, ...restProps}, forwardedRef) => {
  const media = useMedia()
  return (
    <Disclosure
      ref={forwardedRef}
      className={cn('MasterDetailDisclosed', 'grow overflow-hidden', className)}
      animated={media.sm}
      direction="horizontal"
      {...restProps}
    >
      {(disclosure) => (
        <>
          <div className="MasterDetailDisclosed-master relative max-w-full flex-auto overflow-y-auto">
            {typeof children === 'function' ? children(disclosure) : children}
          </div>
          <DisclosureContent
            className={
              'MasterDetailDisclosed-detail [&_>_.DisclosureContent-inner]:!h-full absolute top-0 right-0 bottom-0 left-0 sm:relative [&_>_.DisclosureContent-inner]:overflow-y-auto'
            }
          >
            <IconButton
              className="MasterDetailDisclosed-detailCloseButton absolute top-4 right-4 z-[1] text-ds-xl"
              size="default_alt"
              onClick={() => disclosure.hide()}
            >
              <PhosphorIcon icon="x" />
            </IconButton>
            {typeof detail === 'function' ? detail(disclosure) : detail}
          </DisclosureContent>
        </>
      )}
    </Disclosure>
  )
})
