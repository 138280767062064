import {mapToObj} from './object-utils'

// Adapted from https://github.com/blend/promise-utils/blob/6ca9c71c55781b6db20a5d196e61b58489ed0478/src/delay.ts#L9
export async function delay<T>(delayTimeMs: number, value: T): Promise<T>
export async function delay<T>(delayTimeMs: number): Promise<void>
export async function delay<T>(
  delayTime: any,
  value?: T,
): Promise<undefined | T> {
  return new Promise((resolve) => setTimeout(() => resolve(value), delayTime))
}

export async function combinePromises<T>(obj: Record<string, Promise<T>>) {
  const resolvedValues = await Promise.all(Object.values(obj))

  return mapToObj(Object.keys(obj), (key, idx) => [key, resolvedValues[idx]])
}
