import React from 'react'
import {cva} from 'class-variance-authority'

import {HStack} from './Stack'
import {Ellipsis} from './Ellipsis'
import {VariantsProps, cn} from '../utils'

export type BarVariant = 'default' | 'goal'

export const bar = cva('relative w-full rounded', {
  variants: {
    variant: {
      default: 'h-9 bg-[#E4F5F7]',
      goal: 'h-3 bg-gray100',
    },
  },
})
export const barFilledBox = cva('rounded-[inherit]', {
  variants: {
    variant: {
      default: 'bg-[#B1DFE5]',
      goal: 'bg-gradient-to-r from-teal-50 to-[#2FB2D6]',
    },
  },
})

export interface BarProps
  extends VariantsProps<typeof bar>,
    VariantsProps<typeof barFilledBox>,
    React.ComponentPropsWithoutRef<'div'> {
  fill: number
  variant?: BarVariant
  filledBarStyles?: React.CSSProperties
}

export const Bar = React.forwardRef<HTMLDivElement, BarProps>(
  (
    {
      fill,
      variant = 'default',
      className,
      children,
      filledBarStyles,
      ...restProps
    },
    forwardedRef,
  ) => (
    <HStack
      ref={forwardedRef}
      className={cn('Bar', bar({variant}), className)}
      role="figure"
      aria-label="Bar"
      {...restProps}
    >
      <div
        className={cn('Bar-filledBox', barFilledBox({variant}))}
        style={{flexGrow: fill, ...filledBarStyles}}
      />
      <div
        className="Bar-unfilledBox bg-transparent"
        style={{flexGrow: 1 - fill}}
      />

      <div className="Bar-content absolute top-0 right-0 bottom-0 left-0 flex items-center px-3">
        {typeof children === 'string' ? (
          <Ellipsis className="Bar-label">{children}</Ellipsis>
        ) : (
          children
        )}
      </div>
    </HStack>
  ),
)
