import {AnimatePresence, motion} from 'framer-motion'
import * as WebUI from '@cheddarup/web-ui'
import React from 'react'

export interface ExpandableSidebarLayoutProps {
  sidebarVisible?: boolean
  title?: string
  heading: string
  headingSubtitle?: React.ReactNode
  sidebar?: React.ReactNode
  headerButtons?: React.ReactNode
  onClickClose?: () => void
  onClickCloseSidebar?: () => void
  children?: React.ReactNode
}

const ExpandableSidebarLayout = ({
  sidebarVisible,
  title,
  heading,
  headingSubtitle,
  sidebar,
  headerButtons,
  onClickClose,
  onClickCloseSidebar,
  children,
}: ExpandableSidebarLayoutProps) => {
  const media = WebUI.useMedia()
  return (
    <WebUI.HStack className="min-h-full">
      <motion.div
        className="min-w-0"
        style={{position: onClickClose ? 'relative' : 'static'}}
        animate={
          sidebarVisible
            ? {flex: media.sm ? '0 0 66.6%' : '0 0 0px'}
            : {flex: '0 0 100%'}
        }
      >
        {!!onClickClose && (
          <WebUI.IconButton
            className="absolute top-4 right-4 text-ds-xl"
            size="default_alt"
            onClick={onClickClose}
          >
            <WebUI.PhosphorIcon icon="x" />
          </WebUI.IconButton>
        )}
        <div className="border-b p-4 sm:p-8">
          {title && <h2 className="mb-4 px-2 text-ds-base">{title}</h2>}
          <h3 className="text-ds-lg">{heading}</h3>
          {headingSubtitle && <div className="mt-2">{headingSubtitle}</div>}
          {headerButtons && <div className="mt-4">{headerButtons}</div>}
        </div>
        <div className="max-h-[calc(100vh-180px)] overflow-y-auto p-4 sm:p-8">
          {children}
        </div>
      </motion.div>
      <AnimatePresence mode="wait">
        {sidebarVisible && (
          <motion.div
            className="relative z-[2] min-w-0 overflow-y-scroll bg-gray100"
            initial={{flex: '0 0 0px'}}
            animate={{flex: media.sm ? '0 0 33.3%' : '0 0 100%'}}
            exit={{flex: '0 0 0px'}}
          >
            <WebUI.IconButton
              className="absolute top-4 right-4 text-3xl"
              size="default_alt"
              onClick={onClickCloseSidebar}
            >
              <WebUI.PhosphorIcon icon="x" />
            </WebUI.IconButton>

            <div className="p-4 sm:p-8">{sidebar}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </WebUI.HStack>
  )
}

export default ExpandableSidebarLayout
