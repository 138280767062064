import {useNavigate} from 'react-router-dom'
import React from 'react'
import * as WebUI from '@cheddarup/web-ui'

const MailedSecurityCodeAboutPage: React.FC = () => {
  const navigate = useNavigate()
  return (
    <WebUI.Alert
      aria-label="About Receiving a Mailed Security Code"
      className="[&_>_.ModalContentView]:max-w-screen-sm"
      initialVisible
      onDidHide={() => navigate('../two-factor-authentication')}
    >
      <WebUI.AlertHeader>
        About Receiving a Mailed Security Code
      </WebUI.AlertHeader>

      <WebUI.AlertContentView
        text={
          <>
            We understand that some situations can arise that prohibit your
            access to the two-factor codes sent to your current device on file.
            <br />
            <br />
            It's important to understand that veering from any method for two-
            factor authentication other than the current device on file is less
            secure and can present risks to your account and any current or
            future balance. By proceeding with a mailed security code, you are
            accepting this additional risk.
            <br />
            <br />A mailed security code option is only available for accounts
            with physical addresses located in the United States. A mailed
            security code is sent by postal mail to the physical address listed
            on file. Upon request, this code typically arrives to its
            destination within 3-5 business days.
            <br />
            <br />
            Please note that if someone else at the listed address receives the
            mailed security code and has access to your Cheddar Up account,
            there is nothing preventing them from gaining access to your account
            and collected funds. For that reason, it's important to look for the
            mailed code and take action upon its receipt. Once you receive the
            mailed security code, come back to My{' '}
            <strong>Account {'>'} Security</strong> to enter the code, in
            combination with your account password, to clear out your current
            two-factor authentication device.
            <br />
            <br />
          </>
        }
        actions={<WebUI.AlertCancelButton />}
      />
    </WebUI.Alert>
  )
}

export default MailedSecurityCodeAboutPage
