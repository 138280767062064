import {useNavigate, useParams} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'
import {LinkButton} from 'src/components/LinkButton'

const ORG_HOW_IT_WORKS_VIDEO_URL_DEFAULT =
  'https://player.vimeo.com/video/230275990'

const HowItWorksPage = () => {
  const navigate = useNavigate()
  const urlParams = useParams()

  const org = urlParams.org

  const media = WebUI.useMedia()
  const {data: session} = api.auth.session.useQuery()
  const userId = session?.user.id
  const videoUrl =
    session?.organization_data?.howItWorksVideoUrl ??
    ORG_HOW_IT_WORKS_VIDEO_URL_DEFAULT
  return (
    <WebUI.Modal
      aria-label="How it works"
      className="[&_>_.ModalContentView]:max-w-screen-md"
      onDidHide={() => navigate('..')}
    >
      <WebUI.ModalCloseButton />
      <div className="p-8">
        <h1 className="mx-2 mt-2">Welcome to Cheddar Up</h1>
        <div
          className="relative mt-2 h-0"
          style={{paddingBottom: media.sm ? 500 : '56.25%'}}
        >
          <iframe
            className="absolute top-0 left-0 h-full w-full border-none"
            title="Video"
            src={videoUrl}
          />
        </div>
        <p className="mt-4">
          {videoUrl === ORG_HOW_IT_WORKS_VIDEO_URL_DEFAULT
            ? 'Cheddar Up will help you move your community online in minutes and save you time. Get started by investing three minutes to learn how Cheddar Up works.'
            : 'As one of our partners, you have access to custom tools that will make creating a collection even easier. This three-minute video will show you how to get started with these tools.'}
        </p>
        <div className="mt-4 flex justify-end">
          <LinkButton
            size="large"
            variant="primary"
            to={
              userId ? `/collection/${userId}/details` : `/orgs/${org}/signup`
            }
          >
            Create
          </LinkButton>
        </div>
      </div>
    </WebUI.Modal>
  )
}

export default HowItWorksPage
