import {BrandKitColors} from '@cheddarup/core'
import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import {getCUReadableColor} from 'src/helpers/color-utils'

interface AboutUsProps extends React.ComponentPropsWithoutRef<'div'> {
  aboutUs: Api.GroupPageSection
  brandKitColors: BrandKitColors
}

const AboutUs: React.FC<AboutUsProps> = ({
  brandKitColors,
  aboutUs,
  className,
  style,
  ...restProps
}) => {
  const media = WebUI.useMedia()

  return (
    <WebUI.VStack
      className={WebUI.cn('px-2 py-18', className)}
      style={{
        backgroundColor: brandKitColors[aboutUs.background_color],
        color: getCUReadableColor(brandKitColors[aboutUs.background_color], {
          darkColor: '#373737',
        }),
        ...style,
      }}
      {...restProps}
    >
      <WebUI.VStack className="mx-auto w-full max-w-[1100px] gap-5 px-6">
        <WebUI.Heading className="font-accentAlt text-h-4">
          {aboutUs.headline}
        </WebUI.Heading>
        <div className="flex flex-col gap-6">
          <WebUI.MarkdownParagraph
            className="sm:text-ds-md [&_h2]:text-ds-md"
            markdown={aboutUs.description}
          />
          {aboutUs.details.buttonText && aboutUs.details.url && (
            <WebUI.RoundedButton
              as={WebUI.AnchorButton}
              size={media.sm ? 'big' : 'default'}
              className="self-start"
              style={{
                color: getCUReadableColor(brandKitColors.primary, {
                  darkColor: '#373737',
                }),
                backgroundColor: brandKitColors.primary,
              }}
              rel="noopener noreferrer"
              target="_blank"
              href={Util.prependProtocol(aboutUs.details.url)}
            >
              {aboutUs.details.buttonText}
            </WebUI.RoundedButton>
          )}
        </div>
      </WebUI.VStack>
    </WebUI.VStack>
  )
}

export default AboutUs
