import {useLocation, useNavigate} from 'react-router-dom'
import {useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'

export interface CollectionLogoUploadPromptProps extends WebUI.PromptProps {
  onLogoAndCropSubmit?: (logoImage: Blob, crop: Api.CropDetails | null) => void
}

export const CollectionLogoUploadPrompt = ({
  onLogoAndCropSubmit,
  ...restProps
}: CollectionLogoUploadPromptProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [image, setImage] = useState<Blob | null>(null)
  const [crop, setCrop] = useState<WebUI.ImageEditorCrop | null>(null)
  return (
    <WebUI.Prompt
      aria-label="Upload collection logo"
      onDidHide={() => navigate('..', {state: location.state})}
      {...restProps}
    >
      {(dialog) => (
        <WebUI.VStack className="gap-4">
          <WebUI.PromptHeader heading="Add Logo Image" />

          <WebUI.ImageEditor
            toolbarTitle="Crop your profile image"
            image={image}
            onChangeImage={(newImage) => setImage(newImage)}
            onApplyCrop={(newCrop) => setCrop(newCrop)}
          />
          <WebUI.HStack className="justify-end">
            <WebUI.Button
              variant="primary"
              disabled={!image}
              onClick={async () => {
                if (image) {
                  await onLogoAndCropSubmit?.(image, crop)
                  dialog.hide()
                }
              }}
            >
              Save Image
            </WebUI.Button>
          </WebUI.HStack>
        </WebUI.VStack>
      )}
    </WebUI.Prompt>
  )
}
