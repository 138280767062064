import {makeQueryUpdate, makeUseMutation} from '../use-mutation'
import {endpoints} from '../../endpoints'
import {getEndpointKey} from '../../utils'

export const useCreateContactListMutation = makeUseMutation(
  endpoints.contacts.createContactList,
  () => ({
    regular: (newContactList) => [
      makeQueryUpdate(endpoints.contacts.contactListList, (prevContactLists) =>
        prevContactLists
          ? [...prevContactLists, newContactList]
          : prevContactLists,
      ),
    ],
  }),
)

export const useUpdateContactListMutation = makeUseMutation(
  endpoints.contacts.updateContactList,
  (vars) => ({
    regular: (newContactList) => [
      makeQueryUpdate(endpoints.contacts.contactListList, (prevContactLists) =>
        prevContactLists?.map((cl) =>
          cl.id === Number(vars.pathParams.contactListId) ? newContactList : cl,
        ),
      ),
      makeQueryUpdate(
        endpoints.contacts.contactListDetail,
        () => newContactList,
        {
          pathParams: {
            contactListId: vars.pathParams.contactListId,
          },
        },
      ),
    ],
  }),
)

export const useDeleteContactListMutation = makeUseMutation(
  endpoints.contacts.deleteContactList,
  (vars) => ({
    regular: () => [
      makeQueryUpdate(endpoints.contacts.contactListList, (prevContactLists) =>
        prevContactLists?.filter(
          (cl) => cl.id !== Number(vars.pathParams.contactListId),
        ),
      ),
    ],
  }),
)

export const useAddContactsToContactListMutation = makeUseMutation(
  endpoints.contacts.addContactsToContactList,
  undefined,
  (queryClient) => ({
    onSuccess: () => {
      const contactListQueryKey = getEndpointKey(endpoints.contacts.list)
      queryClient.invalidateQueries({queryKey: contactListQueryKey})
    },
  }),
)

export const useRemoveContactsFromContactListMutation = makeUseMutation(
  endpoints.contacts.removeContactsFromContactList,
  undefined,
  (queryClient) => ({
    onSuccess: () => {
      const contactListQueryKey = getEndpointKey(endpoints.contacts.list)
      queryClient.invalidateQueries({queryKey: contactListQueryKey})
    },
  }),
)

export const useAddContactsMutation = makeUseMutation(
  endpoints.contacts.create,
  undefined,
  (queryClient) => ({
    onSuccess: () => {
      const contactListQueryKey = getEndpointKey(endpoints.contacts.list)
      queryClient.invalidateQueries({queryKey: contactListQueryKey})
    },
  }),
)

export const useUpdateContactMutation = makeUseMutation(
  endpoints.contacts.update,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(endpoints.contacts.list, (prevContacts) =>
        prevContacts
          ? {
              ...prevContacts,
              data: prevContacts.data.map((c) =>
                c.id === Number(vars.pathParams.contactId)
                  ? {
                      ...c,
                      ...vars.body,
                    }
                  : c,
              ),
            }
          : prevContacts,
      ),
    ],
  }),
)

export const useDeleteContactMutation = makeUseMutation(
  endpoints.contacts.delete,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(endpoints.contacts.list, (prevContacts) =>
        prevContacts
          ? {
              ...prevContacts,
              data: prevContacts.data.filter(
                (c) => c.id !== Number(vars.pathParams.contactId),
              ),
            }
          : prevContacts,
      ),
    ],
  }),
)

export const useBulkDeleteContactsMutation = makeUseMutation(
  endpoints.contacts.bulkDelete,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(endpoints.contacts.list, (prevContacts) =>
        prevContacts
          ? {
              ...prevContacts,
              data: prevContacts.data.filter(
                (c) => !vars.body?.contact_ids.includes(c.id),
              ),
            }
          : prevContacts,
      ),
    ],
  }),
)
