import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'

import type {FixedItemFormFormik} from '../../../../../containers/FixedItemForm/FixedItemForm'

export interface ItemVariantsListingDeleteModalProps {
  formik: FixedItemFormFormik
  listingsUuids: string[]
  onDismiss?: () => void
}

const ItemVariantsListingDeleteModal = ({
  formik,
  listingsUuids,
  onDismiss,
}: ItemVariantsListingDeleteModalProps) => (
  <WebUI.Alert
    aria-label="Delete variants"
    initialVisible
    onDidHide={onDismiss}
  >
    <WebUI.AlertHeader>Delete selected variants</WebUI.AlertHeader>
    <WebUI.AlertContentView
      text="This will delete the selected variants from your variant table and from your collection, do you wish to continue?"
      actions={
        <>
          <WebUI.AlertActionButton
            execute={() => {
              const newListings =
                formik.values.options.variants.listings.filter(
                  (listing) => !listingsUuids.includes(listing.uuid),
                )
              formik.setFieldValue('options.variants.listings', newListings)

              const allListingOptionValues = newListings.map(
                ({optionValues}) => optionValues,
              )
              const allListingOptionValuesKeys = allListingOptionValues.flatMap(
                (optionValues) => Object.keys(optionValues),
              )
              const optionValuesMap = Util.mapToObj(
                allListingOptionValuesKeys,
                (optionValuesKey) => [
                  optionValuesKey,
                  Util.unique(
                    allListingOptionValues.map(
                      (innerOptionValues) => innerOptionValues[optionValuesKey],
                    ),
                  ),
                ],
              )
              formik.setFieldValue(
                'options.variants.options',
                formik.values.options.variants.options.map((option) => ({
                  ...option,
                  values: option.values.filter((value) =>
                    optionValuesMap[option.key]?.includes(value),
                  ),
                })),
              )
            }}
          >
            Delete
          </WebUI.AlertActionButton>
          <WebUI.AlertCancelButton />
        </>
      }
    />
  </WebUI.Alert>
)

export default ItemVariantsListingDeleteModal
