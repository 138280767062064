import React, {useRef} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {FieldGroupEditList} from 'src/components/FieldsEdit/FieldGroupEditList'
import {FieldGroupTypePicker} from 'src/components/FieldsEdit/FieldGroupTypePicker'
import {FieldsEdit, FieldsEditProps} from 'src/components/FieldsEdit/FieldsEdit'
import type {FieldGroupType} from '@cheddarup/core'
import * as Util from '@cheddarup/util'

export interface ItemFormItemFieldsProps
  extends Util.Merge<
    React.ComponentPropsWithoutRef<'div'>,
    Omit<FieldsEditProps, 'children'>
  > {
  label?: string
  text?: string
}

const ItemFormItemFields = ({
  initialFieldSets,
  initialFields,
  onChange,
  onInit,
  label = 'Collect information from your payers about this item',
  text = 'e.g., t-shirt size, registrant’s name, etc.',
  className,
  ...restProps
}: ItemFormItemFieldsProps) => {
  const media = WebUI.useMedia()
  const drawerRef = useRef<WebUI.DialogInstance>(null)
  const hiddenFieldGroupTypes: FieldGroupType[] = [
    'signature',
    'file',
    'layout_divider',
    'layout_description',
  ]

  return (
    <FieldsEdit
      initialFieldSets={initialFieldSets}
      initialFields={initialFields}
      onChange={(newFieldsEditValue) => {
        onChange(newFieldsEditValue)
        drawerRef.current?.hide()
      }}
      onInit={onInit}
    >
      <WebUI.HStack className={WebUI.cn('h-full', className)} {...restProps}>
        <FieldGroupTypePicker
          hiddenFieldGroupTypes={hiddenFieldGroupTypes}
          className="hidden sm:flex"
        />

        <WebUI.VStack className="grow gap-6 overflow-y-auto p-6 sm:px-11">
          <WebUI.VStack className="gap-0_5">
            <WebUI.Text className="text-ds-md">{label}</WebUI.Text>
            {text && <span>({text})</span>}
          </WebUI.VStack>
          <FieldGroupEditList className="h-auto overflow-y-visible" />
          {!media.sm && (
            <WebUI.Drawer
              ref={drawerRef}
              disclosure={
                <WebUI.DialogDisclosure className="inline-flex self-start">
                  Add Question
                </WebUI.DialogDisclosure>
              }
            >
              <FieldGroupTypePicker
                hiddenFieldGroupTypes={hiddenFieldGroupTypes}
                className="grow"
              />
            </WebUI.Drawer>
          )}
        </WebUI.VStack>
      </WebUI.HStack>
    </FieldsEdit>
  )
}

export default ItemFormItemFields
