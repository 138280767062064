import React from 'react'

import {cn} from '../utils'

export const PlanePlusSolidIcon = React.forwardRef(
  (
    {className, ...restProps}: React.SVGProps<SVGSVGElement>,
    ref: React.Ref<SVGSVGElement>,
  ) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 88.83"
      ref={ref}
      className={cn('PlanePlusSolidIcon', 'h-[1.8em] w-auto', className)}
      {...restProps}
    >
      <defs>
        <style>{'.cls-1{fill:#9d9d9d}'}</style>
      </defs>
      <title>ShareCollection</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="wireframe">
          <g id="WIRE_FRAME_INFO" data-name="WIRE FRAME INFO">
            <path
              className="cls-1"
              d="M72.2 88.83a2.08 2.08 0 0 1-1.45-.59l-23.7-23.12a2.09 2.09 0 0 1-.17-2.8L96.29.78A2.08 2.08 0 0 1 99.9 2.7L74.19 87.36a2.08 2.08 0 0 1-1.47 1.41 2.4 2.4 0 0 1-.52.06ZM51.31 63.46 71.2 82.87 92.78 11.8Z"
            />
            <path
              className="cls-1"
              d="M31.94 54.64a2.08 2.08 0 0 1-.64-.1L1.44 44.9a2.08 2.08 0 0 1-.18-3.9L97.1.17a2.08 2.08 0 0 1 2.08 3.57l-66 50.47a2.08 2.08 0 0 1-1.24.43ZM8 42.65l23.51 7.58L83.48 10.5Z"
            />
            <path
              className="cls-1"
              d="M31.63 84.3a2.19 2.19 0 0 1-.7-.12 2.08 2.08 0 0 1-1.38-2V52.55a2.08 2.08 0 0 1 .82-1.65L96.34.43a2.08 2.08 0 0 1 2.87 3l-66 80.13a2.09 2.09 0 0 1-1.58.74Zm2.08-30.72v22.83l50.77-61.66Z"
            />
          </g>
        </g>
        <g id="canvas">
          <circle cx={83.29} cy={63.14} r={15.47} />
          <path
            d="M75.28 61.3h6.16v-6.16h3.68v6.16h6.16V65h-6.15v6.16h-3.68V65h-6.17Z"
            style={{
              fill: '#fff',
            }}
          />
        </g>
      </g>
    </svg>
  ),
)
