import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'
import {getAttendeeName} from '@cheddarup/core'

export interface RefundOverviewProps
  extends React.ComponentPropsWithoutRef<'div'> {
  refund: Api.TabPaymentRefund
  payment: Api.PaymentToOthersDetailed | Api.TabPayment
  onResendRefundConfirmation?: () => void
}

const RefundOverview = ({
  refund,
  payment,
  onResendRefundConfirmation,
  className,
  ...restProps
}: RefundOverviewProps) => {
  const receiptDetailsVisible =
    (refund.detail?.taxes && refund.detail?.taxes?.length > 0) ||
    !!refund.detail?.shipping

  return (
    <WebUI.VStack className={WebUI.cn('gap-4', className)} {...restProps}>
      <WebUI.VStack className="gap-1">
        <WebUI.Heading className="text-ds-base" as="h2">
          Refund Issued
        </WebUI.Heading>
        {!!onResendRefundConfirmation && (
          <WebUI.Button
            className="text-ds-sm"
            variant="link"
            onClick={onResendRefundConfirmation}
          >
            Resend Refund Confirmation to Payer
          </WebUI.Button>
        )}
      </WebUI.VStack>

      <WebUI.Separator variant="primary" />

      <div className="text-ds-sm">
        <div>Payer: {payment.tab_member.name}</div>
        <div>Date: {Util.formatDateAs(refund.created_at, 'date_compact')}</div>
        <div>Amount: {Util.formatAmount(refund.amount)}</div>
      </div>

      <WebUI.Separator variant="primary" />

      {refund?.detail?.items?.length > 0 && (
        <>
          <WebUI.VStack className="gap-8">
            {refund?.detail?.items
              ?.filter(({amount, quantity}) => amount > 0 || quantity > 0)
              ?.map((refundItem) => {
                const paymentItem = payment.payment_items.find(
                  (pi) => pi.id === refundItem.payment_item_id,
                )

                return (
                  <WebUI.HStack
                    key={refundItem.payment_item_id}
                    className="justify-between text-ds-sm"
                  >
                    <WebUI.VStack className="min-w-0 [&_>_.Ellipsis]:font-light">
                      <WebUI.Ellipsis>{refundItem.name}</WebUI.Ellipsis>
                      <WebUI.Ellipsis>
                        Qty: {refundItem.quantity}
                      </WebUI.Ellipsis>
                      {paymentItem?.detail &&
                        'itemType' in paymentItem.detail &&
                        paymentItem.detail.itemType === 'ticket' && (
                          <>
                            <WebUI.Ellipsis>
                              Ticket #
                              {Util.encodeToBase36(refundItem.payment_item_id)}
                            </WebUI.Ellipsis>
                            <WebUI.Ellipsis>
                              Attendee:{' '}
                              {getAttendeeName(paymentItem?.item_field_views)}
                            </WebUI.Ellipsis>
                          </>
                        )}
                    </WebUI.VStack>
                    <div>
                      {Util.formatAmount(refundItem.amount, {cents: true})}
                    </div>
                  </WebUI.HStack>
                )
              })}
          </WebUI.VStack>
          <WebUI.Separator variant="primary" />
        </>
      )}
      {receiptDetailsVisible && (
        <>
          <div className="text-ds-sm">
            {refund.detail.taxes?.map((tax, idx) => (
              <WebUI.HStack key={idx} className="items-center justify-between">
                <div>{tax.name}</div>
                <div>{Util.formatAmount(tax.amount, {cents: true})}</div>
              </WebUI.HStack>
            ))}
            {!!refund.detail.shipping && (
              <WebUI.HStack className="items-center justify-between">
                <div>Shipping:</div>
                <div>
                  {Util.formatAmount(refund.detail.shipping, {cents: true})}
                </div>
              </WebUI.HStack>
            )}
          </div>

          <WebUI.Separator variant="primary" />
        </>
      )}

      <WebUI.HStack className="items-center justify-between font-normal text-ds-sm">
        <div>Refund Total</div>
        <div>{Util.formatAmount(refund.amount)}</div>
      </WebUI.HStack>
    </WebUI.VStack>
  )
}

export default RefundOverview
