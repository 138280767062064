import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {useFormik} from '@cheddarup/react-util'
import {readApiError} from 'src/helpers/error-formatting'
import * as Util from '@cheddarup/util'

import useCart, {useEnhancedCreateCartItemMutation} from '../../hooks/useCart'
import {usePayerUIState} from '../../PayerUIStateProvider'

export interface AddItemViewToCartFormValues {}

export type AddItemViewToCartFormik = ReturnType<
  typeof useFormik<AddItemViewToCartFormValues>
>

export interface AddItemViewToCartFormProps
  extends Omit<React.ComponentPropsWithoutRef<'form'>, 'children'> {
  itemView: Api.PublicTabItem
  children:
    | React.ReactNode
    | ((formik: AddItemViewToCartFormik) => React.ReactNode)
}

export const AddItemViewToCartForm: React.FC<AddItemViewToCartFormProps> = ({
  itemView,
  className,
  onClick,
  children,
  ...restProps
}) => {
  const {cart} = useCart()
  const [, createCartItemAsync] = useEnhancedCreateCartItemMutation()
  const growlActions = WebUI.useGrowlActions()
  const payerUIState = usePayerUIState()

  const formik = useFormik({
    initialValues: {},
    onSubmit: async () => {
      if (isItemViewPerPersonMaxQuantityExceeded(cart, itemView)) {
        growlActions.show('error', {
          title: 'Per-Person Item Limit Reached',
          body: `You've reached a per-person limit on this item. You can only purchase ${
            itemView.options.perPersonMaxQuantity?.value ?? 0
          } of this item.`,
        })
        return
      }

      try {
        await createCartItemAsync({
          body: {item_id: itemView.id, quantity: 1},
        })

        payerUIState.setCartVisible(true)
      } catch (err) {
        const errorCode = readApiError(err, {
          exceeded_available_quantity: () => 'exceeded_available_quantity',
        })

        if (errorCode === 'exceeded_available_quantity') {
          growlActions.show('error', {
            title: 'Sorry',
            body: `Sorry, ${itemView.name} is no longer available`,
          })
        }
      }
    },
  })

  return (
    <WebUI.Form
      className={WebUI.cn('relative overflow-hidden rounded', className)}
      onReset={formik.handleReset}
      onSubmit={formik.handleSubmit}
      onClick={(event) => {
        onClick?.(event)

        if (!event.defaultPrevented) {
          formik.submitForm()
        }
      }}
      {...restProps}
    >
      {typeof children === 'function' ? children(formik) : children}
      {formik.isSubmitting && (
        <div className="absolute inset-0 flex items-center justify-center bg-[rgba(51,51,51,0.1)]">
          <WebUI.Loader />
        </div>
      )}
    </WebUI.Form>
  )
}

// MARK: – Helpers

function isItemViewPerPersonMaxQuantityExceeded(
  cart: Api.Cart | undefined,
  itemView: Api.PublicTabItem,
) {
  if (!itemView.options.perPersonMaxQuantity?.enabled) {
    return false
  }

  const cartItemCount = Util.sumBy(
    cart?.items.filter((ci) => ci.tab_item.id === itemView.id) ?? [],
    (i) => i.quantity,
  )

  return cartItemCount >= itemView.options.perPersonMaxQuantity.value
}
