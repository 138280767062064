import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {clientUserByEmailSchema} from '../schemas'

export default {
  userDetail: makeEndpoint({
    path: 'clients/users/:userId',
    responseSchema: clientUserByEmailSchema,
  }),
  userDetailByEmail: makeEndpoint({
    path: 'clients/users/email',
    queryParamsSchema: z.object({
      email: z.string(),
      showCustomer: z.boolean().optional(),
    }),
    responseSchema: clientUserByEmailSchema,
  }),
}
