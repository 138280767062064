import {Navigate, useNavigate, useParams} from 'react-router-dom'
import React, {useEffect, useRef, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import TicketLogo from 'src/images/ticket-logo.svg'
import {api} from '@cheddarup/api-client'
import {
  PremiumFeatureSideSheetDisclosure,
  PremiumFeaturesSideSheet,
} from 'src/components/PremiumFeaturesSideSheet'

import {TicketForm} from './TicketForm'

const TicketFormPage: React.FC = () => {
  const navigate = useNavigate()
  const urlParams = useParams()
  const dialogRef = useRef<WebUI.DialogInstance>(null)
  const dialogDefaultHide = useRef<(() => void) | null>(null)
  const alertRef = useRef<WebUI.DialogInstance>(null)
  const [isDirty, setIsDirty] = useState(false)
  const tabQuery = api.tabs.detail.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: urlParams.collection!,
    },
  })
  const isTeam = tabQuery.data?.is_team

  useEffect(() => {
    if (dialogRef.current) {
      dialogDefaultHide.current = dialogRef.current.hide
      dialogRef.current.hide = () => {
        if (isDirty) {
          alertRef.current?.show()
        } else {
          dialogDefaultHide.current?.()
        }
      }
    }
  }, [isDirty])

  if (
    tabQuery.data &&
    tabQuery.data.status !== 'draft' &&
    !tabQuery.data.is_team
  ) {
    return <Navigate to="../i/plans" />
  }

  return (
    <PremiumFeaturesSideSheet
      tabId={tabQuery.data?.id}
      enforcedPremiumMeta={{team: {ticketing: true}}}
    >
      <WebUI.Modal
        ref={dialogRef}
        aria-label="Ticket form"
        className="[&_>_.ModalContentView]:h-full [&_>_.ModalContentView]:max-w-screen-lg"
        initialVisible
        onDidHide={() => navigate('..')}
      >
        <WebUI.ModalHeader className="border-b-0">
          <WebUI.PageHeader
            data-is-team={isTeam}
            className="items-baseline data-[is-team=true]:items-center"
            graphics={
              isTeam && (
                <img className="h-[4em]" alt="Item Logo" src={TicketLogo} />
              )
            }
            subheading={
              <>
                Everyone who purchases a ticket will receive their own unique QR
                code via email that can be scanned in-person.{' '}
                <WebUI.Anchor
                  className="inline whitespace-normal [&_>_.Anchor-content]:whitespace-normal"
                  href="https://support.cheddarup.com/hc/en-us/articles/19682660024212-Scan-QR-code-tickets"
                  target="_blank"
                >
                  Learn more about scanning tickets with our mobile app.
                </WebUI.Anchor>
              </>
            }
          >
            <WebUI.HStack className="gap-4">
              {`${urlParams.ticket ? 'Edit' : 'Add'} Ticket`}{' '}
              {!isTeam && (
                <PremiumFeatureSideSheetDisclosure
                  featureKeys={['ticketing']}
                />
              )}
            </WebUI.HStack>
          </WebUI.PageHeader>
        </WebUI.ModalHeader>
        <WebUI.ModalCloseButton />
        <TicketForm
          className="min-h-0 grow"
          collectionId={Number(urlParams.collection)}
          itemId={Number(urlParams.ticket)}
          onDismiss={() => dialogDefaultHide.current?.()}
          onDirtyChange={(value) => setIsDirty(value)}
        />
        <DirtyFormConfirmAlert
          ref={alertRef}
          onProceed={() => dialogDefaultHide.current?.()}
        />
      </WebUI.Modal>
    </PremiumFeaturesSideSheet>
  )
}

// MARK: – DirtyFormConfirmAlert

export interface DirtyFormConfirmAlertProps extends WebUI.AlertProps {
  onProceed?: () => void
}

export const DirtyFormConfirmAlert = React.forwardRef<
  WebUI.DialogInstance,
  DirtyFormConfirmAlertProps
>(({onProceed, className, ...restProps}, forwardedRef) => (
  <WebUI.Alert
    ref={forwardedRef}
    aria-label="Confirm closing ticket modal"
    className={WebUI.cn('[&_.Alert-closeButton]:invisible', className)}
    {...restProps}
  >
    <WebUI.AlertHeader>
      Are you sure you want to close this ticket?
    </WebUI.AlertHeader>
    <WebUI.AlertContentView
      text="You haven’t saved your ticket and your information will be lost."
      actions={
        <>
          <WebUI.AlertActionButton onClick={() => onProceed?.()}>
            Close Ticket
          </WebUI.AlertActionButton>
          <WebUI.AlertCancelButton>Cancel</WebUI.AlertCancelButton>
        </>
      }
    />
  </WebUI.Alert>
))

export default TicketFormPage
