import * as Util from '@cheddarup/util'
import React from 'react'
import * as WebUI from '@cheddarup/web-ui'

import {Link} from '../Link'
import {bottomLinks, links, social} from './data'

const FooterBig = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'>
>(({className, ...restProps}, forwardedRef) => (
  <WebUI.VStack
    ref={forwardedRef}
    className={WebUI.cn(
      'gap-16 bg-gray800 px-6 pt-11 pb-14 sm:px-16',
      className,
    )}
    {...restProps}
  >
    <WebUI.VStack className="justify-start gap-8 sm:flex-row sm:justify-between">
      {links.map((link, idx) => {
        const subLinksChunked = Util.chunk(link.ul, idx === 0 ? 8 : 7)

        return (
          <WebUI.VStack key={link.heading} className="gap-4">
            <WebUI.Text className="font-semibold text-ds-sm text-natural-100 uppercase">
              {link.heading}
            </WebUI.Text>
            <WebUI.HStack className="gap-8">
              {subLinksChunked.map((subLinks, jdx) => (
                <WebUI.VStack
                  key={`${idx}-${jdx}`}
                  className={
                    'gap-2 [&_>_.Anchor]:flex-0 [&_>_.Anchor_>_.Anchor-content]:font-light [&_>_.Anchor_>_.Anchor-content]:text-ds-sm [&_>_.Anchor_>_.Anchor-content]:text-natural-100'
                  }
                >
                  {subLinks.map((subLink, kdx) =>
                    'link' in subLink && subLink.link ? (
                      <Link
                        key={kdx}
                        variant="primary"
                        target={subLink.target || '_self'}
                        to={subLink.url}
                      >
                        {subLink.label}
                      </Link>
                    ) : (
                      <WebUI.Anchor
                        key={kdx}
                        href={subLink.url}
                        target={subLink.target || '_self'}
                      >
                        {subLink.label}
                      </WebUI.Anchor>
                    ),
                  )}
                </WebUI.VStack>
              ))}
            </WebUI.HStack>
          </WebUI.VStack>
        )
      })}
      <WebUI.HStack className="gap-4 [&_>_.Anchor]:block">
        {social.map((soc) => (
          <WebUI.Anchor
            key={soc.url}
            className="text-ds-xl text-gray400"
            href={soc.url}
            rel="noopener noreferrer"
            target="_blank"
          >
            <WebUI.PhosphorIcon icon={soc.iconName} />
          </WebUI.Anchor>
        ))}
      </WebUI.HStack>
    </WebUI.VStack>
    <WebUI.VStack className="items-stretch justify-center gap-4 sm:flex-row sm:items-center sm:justify-between">
      <WebUI.HStack
        className="items-center gap-2"
        as={WebUI.Anchor}
        href="https://www.cheddarup.com/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <WebUI.LogoGraphicsIcon className="shrink-0" variant="alt" />
        <WebUI.Text className="whitespace-break-spaces text-ds-xs text-natural-70">
          Collect money and forms. Online payments for your group.
        </WebUI.Text>
      </WebUI.HStack>

      <WebUI.HStack
        className={
          'items-center justify-between gap-4 text-ds-xs text-natural-70 [&_>_.Anchor]:text-natural-70'
        }
      >
        {bottomLinks.map((bl) =>
          bl.url ? (
            <WebUI.Anchor
              key={bl.label}
              href={bl.url}
              rel="noopener noreferrer"
              target="_blank"
            >
              {bl.label}
            </WebUI.Anchor>
          ) : (
            <WebUI.Text key={bl.label} className="text-ds-xs">
              {bl.label}
            </WebUI.Text>
          ),
        )}
      </WebUI.HStack>
    </WebUI.VStack>
  </WebUI.VStack>
))

export default FooterBig
