import ProgressBar from './ProgressBar'

export interface UploadMemberInvitesProgressProps {
  memberCount: number
  processedMemberCount: number
}

const UploadMemberInvitesProgress = ({
  memberCount,
  processedMemberCount,
}: UploadMemberInvitesProgressProps) => {
  const processingMemberCount = Math.min(processedMemberCount + 10, memberCount)

  return (
    <div>
      <h2 className="my-4 font-normal text-ds-md">
        We&apos;re creating your new accounts in batches of {10}. Please do not
        click off of this screen during this process. We appreciate your
        patience!
      </h2>
      <ProgressBar progress={(processingMemberCount / memberCount) * 100} />
      <div className="mt-4 font-normal text-ds-sm">
        Processing {processingMemberCount} / {memberCount} accounts
      </div>
    </div>
  )
}

export default UploadMemberInvitesProgress
