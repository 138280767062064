import {FormikConfig} from 'formik'
import * as WebUI from '@cheddarup/web-ui'
import {useFormik, useLiveRef, useUpdateEffect} from '@cheddarup/react-util'
import React from 'react'

export interface SearchFormValues {
  term: string
}

export interface SearchFormProps
  extends WebUI.FormProps,
    Omit<React.ComponentPropsWithoutRef<'form'>, 'onSubmit'> {
  iconClassName?: string
  containerClassName?: string
  size?: WebUI.InputProps['size']
  placeholder?: React.ComponentPropsWithoutRef<'input'>['placeholder']
  initialValues?: SearchFormValues
  values?: SearchFormValues
  noResult?: boolean
  onTermChange?: (term: string) => void
  onSubmit?: FormikConfig<SearchFormValues>['onSubmit']
}

export const SearchForm = ({
  className,
  containerClassName,
  iconClassName,
  size,
  initialValues = {term: ''},
  values: valuesProp,
  noResult = false,
  onTermChange,
  role = 'search',
  placeholder,
  onSubmit,
  ...restProps
}: SearchFormProps) => {
  const formik = useFormik({
    initialValues: valuesProp ?? initialValues,
    onSubmit: (values, formikHelpers) => onSubmit?.(values, formikHelpers),
  })
  const onTermChangeRef = useLiveRef(onTermChange)

  const setValues = formik.setValues
  useUpdateEffect(() => {
    if (valuesProp != null) {
      setValues(valuesProp)
    }
  }, [setValues, valuesProp])

  useUpdateEffect(() => {
    onTermChangeRef.current?.(formik.values.term)
  }, [formik.values.term])

  return (
    <WebUI.HStack className={WebUI.cn('gap-2', containerClassName)}>
      <WebUI.Form
        className={WebUI.cn(
          'relative [&_.Form-inner]:h-full [&_.Form-inner]:w-full',
          className,
        )}
        role={role}
        direction="horizontal"
        onSubmit={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
        {...restProps}
      >
        <WebUI.Input
          className={WebUI.cn(
            'grow pr-6 sm:w-[240px]',
            size === 'compact' && 'min-h-[30px]',
          )}
          name="term"
          size={size}
          placeholder={placeholder}
          value={formik.values.term}
          onChange={formik.handleChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              formik.submitForm()
            }
          }}
          onBlur={formik.handleBlur}
          form="search-form"
        />

        <WebUI.IconButton
          className={WebUI.cn(
            'Search-cancelButton',
            '-translate-y-1/2 absolute top-1/2 right-2 text-gray400',
            iconClassName,
          )}
          size="default_alt"
          disabled={formik.values.term.length === 0}
          onClick={() => {
            formik.setFieldValue('term', '')
            formik.submitForm()
          }}
        >
          {formik.values.term.length > 0 ? (
            <WebUI.PhosphorIcon
              className="absolute top-0 left-0 h-full w-full"
              icon="x"
            />
          ) : (
            <WebUI.PhosphorIcon
              className="absolute top-0 left-0 h-full w-full"
              icon="magnifying-glass"
            />
          )}
        </WebUI.IconButton>
      </WebUI.Form>

      {!!noResult && (
        <WebUI.Ellipsis className="self-center text-ds-sm" variant="danger">
          No results found
        </WebUI.Ellipsis>
      )}
    </WebUI.HStack>
  )
}

export default SearchForm
