export type BrandKitColors = ReturnType<typeof getBrandKitColors>
export type PayerBrandKitColors = ReturnType<typeof getPayerBrandKitColors>

export const brandKitPalettes = {
  tint: [
    '#277B90',
    '#26B8CF',
    '#3FBEA6',
    '#42B05B',
    '#F36D36',
    '#E078BB',
    '#9E9E9E',
    '#414042',
  ],
  neutral: [
    '#EEEEEE',
    '#D0E9ED',
    '#F1ECE3',
    '#CCE6D2',
    '#E9CDF2',
    '#D3B2F5',
    '#DEDEDE',
    '#EEEEEE',
  ],
}

export function getBrandKitColors(
  branding:
    | Omit<Api.UserBranding['color_palette']['groupPage'], 'enabled'>
    | undefined,
) {
  return {
    primary: (branding ?? defaultBrandKitColorMap).primary,
    secondary: (branding ?? defaultBrandKitColorMap).secondary,
    neutral: (branding ?? defaultBrandKitColorMap).neutral,
    white: defaultBrandKitColorMap.white,
  }
}

export function getPayerBrandKitColors(
  payerBranding:
    | Omit<Api.UserBranding['color_palette']['payerPage'], 'enabled'>
    | undefined,
) {
  return {
    primaryButton:
      payerBranding?.primaryButton ?? defaultBrandKitColorMap.primary,
    secondaryButton:
      payerBranding?.secondaryButton ?? defaultBrandKitColorMap.secondary,
  }
}

export function getBrandKitColorKey(
  brandKitColors:
    | Omit<Api.UserBranding['color_palette']['groupPage'], 'enabled'>
    | undefined,
  color: string,
): Api.BrandKitColorKey {
  let key: Api.BrandKitColorKey = 'white'
  if (
    color.toLowerCase() ===
    (brandKitColors ?? defaultBrandKitColorMap).primary.toLowerCase()
  ) {
    key = 'primary'
  } else if (
    color.toLowerCase() ===
    (brandKitColors ?? defaultBrandKitColorMap).secondary.toLowerCase()
  ) {
    key = 'secondary'
  } else if (
    color.toLowerCase() ===
    (brandKitColors ?? defaultBrandKitColorMap).neutral.toLowerCase()
  ) {
    key = 'neutral'
  }
  return key
}

export const defaultBrandKitColorMap = {
  primary: '#277B90',
  secondary: '#F36D36',
  neutral: '#EEEEEE',
  white: '#FFFFFF',
}
