import React, {useRef, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {useUpdateEffect} from '@cheddarup/react-util'

export interface FormFieldInlineLabelEditProps
  extends WebUI.FormFieldProps,
    React.ComponentPropsWithoutRef<'fieldset'>,
    Pick<React.ComponentPropsWithoutRef<'input'>, 'placeholder'> {
  labelInputClassName?: string
  labelInputFocused?: boolean
  initialLabel?: string
  onLabelChange?: (label: string) => void
}

export const FormFieldInlineLabelEdit = ({
  labelInputClassName,
  labelInputFocused: labelInputFocusedProp,
  initialLabel = '',
  placeholder,
  required,
  onLabelChange,
  className,
  ...restProps
}: FormFieldInlineLabelEditProps) => {
  const [labelInputFocused, setLabelInputFocused] = useState(
    labelInputFocusedProp ?? false,
  )
  const labelInlineEditInputRef = useRef<HTMLInputElement>(null)

  useUpdateEffect(() => {
    if (labelInputFocusedProp != null) {
      setLabelInputFocused(labelInputFocusedProp)
    }
  }, [labelInputFocusedProp])

  return (
    <WebUI.FormField
      className={WebUI.cn('FormFieldInlineLabelEdit', className)}
      label={
        <LabelInlineEditInput
          ref={labelInlineEditInputRef}
          className="FormFieldInlineLabelEdit-label break-words pr-15"
          inputClassName={labelInputClassName}
          autoFocus={labelInputFocused}
          fixedMode={labelInputFocused ? 'input' : undefined}
          required={required}
          defaultValue={initialLabel}
          placeholder={placeholder}
          onChange={(event) => onLabelChange?.(event.target.value)}
          onFocus={(event) => {
            event.preventDefault()

            if (event.target.textContent === placeholder) {
              event.target.value = ''
            } else if (event.target.textContent) {
              event.target.setSelectionRange(
                event.target.textContent.length,
                event.target.textContent.length,
              )
            }
          }}
          onBlur={(event) => {
            event.preventDefault()

            setLabelInputFocused(false)

            if (!event.target.textContent) {
              event.target.value = initialLabel
            }
          }}
        />
      }
      {...restProps}
    />
  )
}

// MARK: – LabelInlineEditInput

export const LabelInlineEditInput = React.forwardRef<
  HTMLInputElement,
  WebUI.InlineEditInputProps<
    React.ComponentPropsWithoutRef<'textarea'>,
    HTMLTextAreaElement
  >
>(
  (
    {
      required,
      inputClassName,
      inputVariant = 'underlined',
      inputOffset = [-4, -4],
      DisplayComponent = ({DefaultDisplayComponent}) => (
        <DefaultDisplayComponent />
      ),
      className,
      defaultValue,
      value,
      ...restProps
    },
    forwardedRef,
  ) => (
    <WebUI.InlineEditInput
      ref={forwardedRef}
      className={WebUI.cn('LabelInlineEditInput', className)}
      inputClassName={WebUI.cn(
        `h-auto min-h-0 resize-none overflow-hidden bg-gray100 px-1 py-0 pr-15
        [font-size:inherit] font-normal leading-default`,
        inputClassName,
      )}
      InputComponent={WebUI.TextareaAutosize}
      rows={1}
      DisplayComponent={({DefaultDisplayComponent}) => (
        <DisplayComponent
          DefaultDisplayComponent={(displayProps) => (
            <DefaultDisplayComponent
              content={
                <WebUI.Text className="whitespace-break-spaces leading-default">
                  {value ?? defaultValue}
                  {required && <span className="text-orange-50"> *</span>}
                </WebUI.Text>
              }
              {...displayProps}
            />
          )}
        />
      )}
      inputVariant={inputVariant}
      inputOffset={inputOffset}
      defaultValue={defaultValue}
      value={value}
      {...restProps}
    />
  ),
)
