/// <reference types="../@types/react-player" />

import React from 'react'
import ReactPlayer, {ReactPlayerProps} from 'react-player/lazy'
import {
  SupportedVideoPlatform as ReactPlayerSupportedVideoPlatform,
  canPlay,
} from 'react-player/lib/patterns'

import {VStack} from './Stack'
import {Loader} from './Loader'
import {cn} from '../utils'

export const supportedVideoPlatforms = [
  'youtube',
  'vimeo',
  'facebook',
] satisfies ReactPlayerSupportedVideoPlatform[]

export type SupportedVideoPlatform = Extract<
  ReactPlayerSupportedVideoPlatform,
  (typeof supportedVideoPlatforms)[number]
>

export type VideoPlayerInstance = typeof ReactPlayer

export interface VideoPlayerProps extends ReactPlayerProps {}

export const VideoPlayer = React.forwardRef<ReactPlayer, VideoPlayerProps>(
  (
    {
      width,
      height,
      muted = true,
      playsinline = true,
      controls = true,
      fallback = <DefaultVideoPlayerFallback style={{width, height}} />,
      ...restProps
    },
    forwardedRef,
  ) => (
    <ReactPlayer
      ref={forwardedRef}
      width={width}
      height={height}
      muted={muted}
      playsinline={playsinline}
      controls={controls}
      fallback={fallback}
      {...restProps}
    />
  ),
)

// MARK: – DefaultVideoPlayerFallback

export const DefaultVideoPlayerFallback = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'>
>(({className, ...restProps}, forwardedRef) => (
  <VStack
    ref={forwardedRef}
    className={cn('items-center justify-center', className)}
    {...restProps}
  >
    <Loader />
  </VStack>
))

// MARK: – Helpers

export function getVideoPlatformForUrl(url: string) {
  for (const [videoPlatform, canPlayFn] of Object.entries(canPlay)) {
    if (isSupportedVideoPlatform(videoPlatform) && canPlayFn(url)) {
      return videoPlatform
    }
  }

  return null
}

export const formattedVideoPlatforms: Record<SupportedVideoPlatform, string> = {
  youtube: 'YouTube',
  vimeo: 'Vimeo',
  facebook: 'Facebook',
}

function isSupportedVideoPlatform(
  videoPlatform: string,
): videoPlatform is SupportedVideoPlatform {
  return supportedVideoPlatforms.includes(videoPlatform as any)
}
