export const formatEmailStatus = (status: string) =>
  ({
    delivered: 'Sent',
    processed: 'Sent',
    sent: 'Sent',
    scheduled: 'Sent',
    open: 'Opened',
    uniqopen: 'Opened',
    click: 'Clicked',
    uniqclick: 'Clicked',
    dropped: 'Failed',
    invalid_email: 'Failed',
    error: 'Failed',
    deffered: 'Bounced',
    spamreport: 'Bounced',
    bounce: 'Bounced',
    bouncedrop: 'Bounced',
    blocked: 'Bounced',
    unsubscribe: 'Unsubscribed',
    group_unsubscribe: 'Unsubscribed',
    group_resubscribe: 'Resubscribed',
    unknown: 'Unknown',
  })[status] ?? 'Unknown'

export const INVALID_EMAIL_STATUSES = [
  'disposable',
  'error',
  'invalid',
  'inbox_full',
  'disabled',
]
