import React from 'react'
import {useInView} from 'react-intersection-observer'
import {BrandedFooter} from 'src/views/MePage/MePage'
import usePublicCollection from '../hooks/usePublicCollection'
import FooterBig from 'src/components/FooterBig'
import {usePayerUIState} from '../PayerUIStateProvider'

export const PayerFooter: React.FC<React.ComponentPropsWithoutRef<'div'>> = (
  props,
) => {
  const {publicCollection} = usePublicCollection()
  const userBranding = publicCollection?.organizer?.branding
  const brandedFooterEnabled =
    userBranding?.enable_brand_footer && userBranding.footer
  const payerUIState = usePayerUIState()
  const inViewRes = useInView({
    onChange: (isInView) => payerUIState.setFooterInView(isInView),
  })

  return brandedFooterEnabled ? (
    <BrandedFooter footer={userBranding.footer} />
  ) : (
    <FooterBig ref={inViewRes.ref} {...props} />
  )
}
