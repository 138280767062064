import {ForwardRefComponent} from '@cheddarup/react-util'
import React from 'react'

import {Ellipsis} from './Ellipsis'
import {HStack} from './Stack'
import {cn} from '../utils'

export interface AnchorProps {
  iconBefore?: React.ReactNode
  iconAfter?: React.ReactNode
}

export const Anchor = React.forwardRef(
  (
    {as: Comp = 'a', iconBefore, iconAfter, className, children, ...restProps},
    forwardedRef,
  ) => (
    <Comp
      ref={forwardedRef}
      className={cn(
        'Anchor relative inline-flex flex-row items-center justify-center whitespace-nowrap text-tint transition-colors duration-100 ease-in-out hover:text-anchorHoverText focus:outline-none',
        className,
      )}
      {...restProps}
    >
      {iconBefore && (
        <HStack className="Anchor-iconBefore mr-2 flex-0 items-center justify-center">
          {iconBefore}
        </HStack>
      )}

      {typeof children === 'string' ? (
        <Ellipsis className="Anchor-content flex-[1]">{children}</Ellipsis>
      ) : (
        children
      )}

      {iconAfter && (
        <HStack className="Anchor-iconAfter ml-2 flex-0 items-center justify-center">
          {iconAfter}
        </HStack>
      )}
    </Comp>
  ),
) as ForwardRefComponent<'a', AnchorProps>
