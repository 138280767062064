import * as Util from '@cheddarup/util'
import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'
import {LinkButton} from 'src/components/LinkButton'
import {Link} from 'src/components/Link'
import {
  TabObjectLimitExceededLabel,
  TabObjectLimitMeter,
  TabObjectLimitMeterProps,
} from 'src/components/TabObjectLimitMeter'
import {useParams} from 'react-router-dom'

const ItemsNav = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => {
  const urlParams = useParams()
  const {data: session} = api.auth.session.useQuery()
  const itemCatalogsQuery = api.itemCatalogs.list.useQuery()
  const tabQuery = api.tabs.detail.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: urlParams.collection!,
    },
  })

  return (
    <WebUI.VStack
      className={WebUI.cn(
        'w-[200px] justify-between gap-3 overflow-y-auto px-6 py-8',
        className,
      )}
      {...restProps}
    >
      <WebUI.VStack className="gap-10">
        <WebUI.VStack className="gap-3">
          <WebUI.Menu>
            <WebUI.MenuButton
              variant="primary"
              iconAfter={<WebUI.PhosphorIcon icon="caret-down-fill" />}
            >
              Add
            </WebUI.MenuButton>
            <WebUI.MenuList>
              <WebUI.MenuItem
                as={LinkButton}
                iconBefore={<WebUI.PhosphorIcon icon="tag" />}
                to="add-item?type=fixed"
              >
                Fixed Price
              </WebUI.MenuItem>
              <WebUI.MenuItem
                as={LinkButton}
                iconBefore={<WebUI.PhosphorIcon icon="hand-heart" />}
                to="add-item?type=donation"
              >
                Donation
              </WebUI.MenuItem>
              <WebUI.MenuItem
                as={LinkButton}
                iconBefore={
                  !!tabQuery.data?.published_at || tabQuery.data?.is_team ? (
                    <WebUI.PhosphorIcon icon="ticket" />
                  ) : (
                    <MiniBadge />
                  )
                }
                to="add-ticket"
              >
                Ticket
              </WebUI.MenuItem>
              <WebUI.MenuItem
                as={LinkButton}
                iconBefore={
                  !!tabQuery.data?.published_at || tabQuery.data?.is_team ? (
                    <WebUI.PhosphorIcon icon="arrows-clockwise" />
                  ) : (
                    <MiniBadge />
                  )
                }
                to="add-item?type=recurring"
              >
                Recurring Plan
              </WebUI.MenuItem>
            </WebUI.MenuList>
          </WebUI.Menu>
          {session?.capabilities.can_upload_catalogs && (
            <LinkButton variant="default" to="upload-items">
              Upload Items
            </LinkButton>
          )}
        </WebUI.VStack>
        {itemCatalogsQuery.data && itemCatalogsQuery.data.length > 0 && (
          <WebUI.VStack className="gap-2">
            <WebUI.Text className="text-ds-xs uppercase">
              My Item Libraries
            </WebUI.Text>
            {Util.uniqueBy(itemCatalogsQuery.data, ({user}) => user.id).map(
              ({user: seller}) => (
                <Link
                  key={seller.id}
                  className="max-w-[180px] text-ds-sm"
                  variant="primary"
                  to={`seller/${seller.id}/catalogs`}
                >
                  {seller.name}
                </Link>
              ),
            )}
          </WebUI.VStack>
        )}
      </WebUI.VStack>

      {tabQuery.data && tabQuery.data.itemLimit != null && (
        <ItemCountLimitMeter
          maxValue={tabQuery.data.itemLimit}
          value={tabQuery.data.reportsAvailable.activeItemsCount}
        />
      )}
    </WebUI.VStack>
  )
}

// MARK: - MiniBadge

export const MiniBadge: React.FC<React.ComponentPropsWithoutRef<'span'>> = ({
  className,
  ...restProps
}) => (
  <span
    className={WebUI.cn(
      'flex h-5 w-5 items-center justify-center rounded-[2px] bg-aquaLight',
      className,
    )}
    {...restProps}
  >
    <WebUI.PhosphorIcon className="text-teal-50" icon="star" width={10} />
  </span>
)

// MARK: – ItemCountLimitMeter

export interface ItemCountLimitProps
  extends React.ComponentPropsWithoutRef<'div'>,
    Pick<TabObjectLimitMeterProps, 'value' | 'maxValue'> {}

export const ItemCountLimitMeter = ({
  maxValue,
  value,
  className,
  ...restProps
}: ItemCountLimitProps) => {
  const media = WebUI.useMedia()
  return (
    <WebUI.VStack className={WebUI.cn('gap-1', className)} {...restProps}>
      {media.sm ? (
        <TabObjectLimitMeter
          tabObjectType="item"
          value={value}
          maxValue={maxValue}
        />
      ) : (
        <TabObjectLimitExceededLabel
          className="text-ds-xs"
          tabObjectType="item"
        />
      )}

      {value > maxValue && (
        <Link
          className="text-ds-sm [&_>_.Anchor-content]:whitespace-normal"
          variant="primary"
          to="i/plans"
        >
          Upgrade for unlimited items
        </Link>
      )}
    </WebUI.VStack>
  )
}

export default ItemsNav
