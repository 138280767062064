import {useMemo, useRef} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {useStableCallback} from '@cheddarup/react-util'

export interface ItemVariantNameSelectProps
  extends Omit<WebUI.ComboboxProps, 'onChange'> {
  disabled?: boolean
  keys: string[]
  value: string
  onDelete: () => void
  onChange: (newValue: string) => void
}

const ItemVariantNameSelect = ({
  disabled,
  keys,
  onDelete,
  value,
  onChange,
  ...restProps
}: ItemVariantNameSelectProps) => {
  const deleteAlertRef = useRef<WebUI.DialogInstance>(null)
  return (
    <>
      <WebUI.Combobox
        creatable
        selectedItem={useMemo(
          () => (value ? {value, label: value} : null),
          [value],
        )}
        stateReducer={useStableCallback((state, actionAndChanges) => {
          let changes = actionAndChanges.changes
          switch (actionAndChanges.type) {
            case WebUI.useCombobox.stateChangeTypes.InputChange:
            case WebUI.useCombobox.stateChangeTypes.FunctionSetInputValue:
            case WebUI.useCombobox.stateChangeTypes.FunctionReset:
            case WebUI.useCombobox.stateChangeTypes
              .ControlledPropUpdatedSelectedItem:
              changes = {
                ...changes,
                highlightedIndex: 0,
              }
              break
          }
          return {...state, ...changes}
        })}
        onSelectedItemChange={(change) => {
          const newSelectedOption = change.selectedItem
          if (change.inputValue && newSelectedOption?.value === '__create__') {
            onChange(change.inputValue)
          } else if (newSelectedOption) {
            onChange(newSelectedOption.value)
          }
        }}
        onInputValueChange={({inputValue}) => {
          if (value && (!inputValue || inputValue.length === 0)) {
            deleteAlertRef.current?.show()
          }
        }}
        {...restProps}
      >
        <WebUI.ComboboxInput
          disabled={disabled}
          size="compact"
          placeholder="E.g. Size"
        />
        <WebUI.ComboboxPopover>
          <WebUI.ComboboxList>
            {keys.map((k) => (
              <WebUI.ComboboxOption className="text-ds-sm" key={k} value={k} />
            ))}
            <WebUI.ComboboxOptionCreate
              className="text-ds-sm"
              value="__create__"
            />
          </WebUI.ComboboxList>
        </WebUI.ComboboxPopover>
      </WebUI.Combobox>

      <WebUI.Alert
        ref={deleteAlertRef}
        aria-label="Delete item option"
        unstable_autoFocusOnShow={false}
      >
        <WebUI.AlertHeader>Delete option</WebUI.AlertHeader>
        <WebUI.AlertContentView
          text="You cannot recover deleted options, do you wish to continue?"
          actions={
            <>
              <WebUI.AlertActionButton onClick={() => onDelete()}>
                Delete
              </WebUI.AlertActionButton>
              <WebUI.AlertCancelButton />
            </>
          }
        />
      </WebUI.Alert>
    </>
  )
}

export default ItemVariantNameSelect
