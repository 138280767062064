import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'

import type {DonationItemFormFormik} from './DonationItemForm'
import {formatAmount} from '@cheddarup/util'
import {
  ClearableAmountInput,
  EinVerificationUpsellDisclosure,
} from '../../components'
import {useManagerRoleId} from 'src/components/ManageRoleProvider'

export interface DonationItemFormDetailsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collectionId: number
  formik: DonationItemFormFormik
}

const DonationItemFormDetails: React.FC<DonationItemFormDetailsProps> = ({
  formik,
  collectionId,
  className,
  ...restProps
}) => {
  const DONATION_SUGGESTIONS = [10, 25, 50, 250, 500, 1000]
  const [managerRoleId] = useManagerRoleId()
  const categoriesQuery = api.tabCategories.list.useQuery({
    pathParams: {
      tabId: collectionId,
    },
  })
  const collectionQuery = api.tabs.detail.useQuery({
    pathParams: {
      tabId: collectionId,
    },
  })

  return (
    <div
      className={WebUI.cn('flex items-start gap-4', className)}
      {...restProps}
    >
      <div className="flex grow flex-col gap-4">
        <WebUI.FormField
          label="Donation Name:"
          error={formik.errors.name}
          required
        >
          <WebUI.Input
            className="max-w-[340px]"
            name="name"
            placeholder="Donation Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
        <WebUI.FormField
          label="Minimum Donation Amount:"
          caption="Optional. This appears under enter amount field."
          error={
            formik.errors.options?.donation?.suggestedAmounts?.minimumAmount
          }
        >
          <WebUI.AmountInput
            className="max-w-[340px]"
            name="options.donation.suggestedAmounts.minimumAmount"
            placeholder="$0"
            value={
              formik.values.options.donation.suggestedAmounts.minimumAmount
            }
            onValueChange={(newAmount) =>
              formik.setFieldValue(
                'options.donation.suggestedAmounts.minimumAmount',
                newAmount,
              )
            }
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>

        {categoriesQuery.data && categoriesQuery.data.length > 0 && (
          <WebUI.FormField label="Category" error={formik.errors.parent_id}>
            <WebUI.Select
              className="max-w-[340px] [&_>_.Select-select]:min-h-10"
              name="parent_id"
              value={formik.values.parent_id ?? ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option value="">Add to Category</option>
              {categoriesQuery.data.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </WebUI.Select>
          </WebUI.FormField>
        )}
        <WebUI.Disclosure
          initialVisible={
            formik.values.options.donation.suggestedAmounts.enabled
          }
          onVisibleChange={(newVisible) =>
            formik.setFieldValue(
              'options.donation.suggestedAmounts.enabled',
              newVisible,
            )
          }
        >
          <WebUI.DisclosureSwitch
            name="options.donation.suggestedAmounts.enabled"
            size="compact"
            reverse={false}
          >
            Include suggested amount(s)
          </WebUI.DisclosureSwitch>
          <WebUI.DisclosureContent className="py-6">
            <WebUI.FormFieldLabel required>
              Suggested Amount(s)
            </WebUI.FormFieldLabel>
            {typeof formik.errors.options?.donation?.suggestedAmounts
              ?.values === 'string' && (
              <span className="ml-2 font-normal text-ds-xs text-orange-50">
                At least one suggested amount is required.
              </span>
            )}
            <div className="grid max-w-2xl grid-cols-2 gap-4 pt-2 sm:grid-cols-3">
              {DONATION_SUGGESTIONS.map((amount, idx) => (
                <WebUI.FormField
                  key={idx}
                  error={
                    (Array.isArray(
                      formik.errors.options?.donation?.suggestedAmounts?.values,
                    )
                      ? formik.errors.options.donation.suggestedAmounts.values
                      : [])[idx]
                  }
                >
                  <ClearableAmountInput
                    placeholder={formatAmount(amount)}
                    value={
                      formik.values.options.donation.suggestedAmounts.values[
                        idx
                      ] ?? ''
                    }
                    onValueChange={(newAmount) => {
                      const updatedValues = [
                        ...formik.values.options.donation.suggestedAmounts
                          .values,
                      ]
                      updatedValues[idx] = newAmount
                      formik.setFieldValue(
                        'options.donation.suggestedAmounts.values',
                        updatedValues,
                      )
                    }}
                  />
                </WebUI.FormField>
              ))}
            </div>
          </WebUI.DisclosureContent>
        </WebUI.Disclosure>
      </div>

      <EinVerificationUpsellDisclosure
        initialVisible={
          !managerRoleId &&
          !collectionQuery.data?.is_team &&
          !!collectionQuery.data?.organizer.verifiedNonProfitStatus
        }
      />
    </div>
  )
}

export default DonationItemFormDetails
