import SkeletonPrimitive, {
  SkeletonProps as SkeletonPrimitiveProps,
} from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import {cn} from '../utils'

export interface SkeletonProps extends SkeletonPrimitiveProps {}

export const Skeleton = ({className, ...restProps}: SkeletonProps) => (
  <SkeletonPrimitive
    className={cn('Skeleton', '[&.Skeleton]:z-0', className)}
    {...restProps}
  />
)
