import * as Ariakit from '@ariakit/react'
import React from 'react'

import {cn} from '../../utils'

export interface TextProps extends Ariakit.RoleProps<'span'> {}

export const Text = React.forwardRef<HTMLSpanElement, TextProps>(
  ({className, ...restProps}, forwardedRef) => {
    // GlamourAbsolute does not look good with subpixel antialiase
    const shouldAntialias = className?.includes('font-accentAlt')

    return (
      <Ariakit.Role.span
        ref={forwardedRef}
        className={cn(shouldAntialias && 'antialiased', className)}
        {...restProps}
      />
    )
  },
)
