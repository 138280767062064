import * as WebUI from '@cheddarup/web-ui'
import {useLiveRef} from '@cheddarup/react-util'
import * as Util from '@cheddarup/util'
import React, {useMemo, useRef, useState} from 'react'
import ImagesUtils from 'src/helpers/ImagesUtils'
import {useCroppedImage} from 'src/hooks/useCroppedImage'

import type {
  FixedItemFormFormik,
  ItemFormValuesListing,
} from '../../../../containers/FixedItemForm/FixedItemForm'
import ItemVariantsListingModals from './ItemVariantsListingModals'
import ItemVariantsListingTableActionDropdown from './ItemVariantsListingTableActionDropdown'
import {VariantQuantityGroupModal} from './VariantQuantityGroupModal'

export interface ItemVariantsListingTableProps
  extends React.ComponentPropsWithoutRef<'div'> {
  formik: FixedItemFormFormik
  itemImages: Api.S3Image[]
}

const ItemVariantsListingTable = ({
  formik,
  itemImages,
  className,
  ...restProps
}: ItemVariantsListingTableProps) => {
  const [selectedUuidsMap, setSelectedUuidsMap] = useState<
    Record<string, boolean>
  >({})
  const [priceModalVisible, setPriceModalVisible] = useState(false)
  const [quantityModalVisible, setQuantityModalVisible] = useState(false)
  const [skuModalVisible, setSkuModalVisible] = useState(false)
  const [markAsSoldOutModalVisible, setMarkAsSoldOutModalVisible] =
    useState(false)
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [imageEditingUuids, setImageEditingUuids] = useState<string[]>([])
  const setFieldValueRef = useLiveRef(formik.setFieldValue)
  const variantQuantityGroupModalRef = useRef<WebUI.DialogInstance>(null)

  const optionKeys = useMemo(
    () => formik.values.options.variants.options.map((o) => o.key),
    [formik.values.options.variants.options],
  )

  const listings = formik.values.options.variants.listings
  const isSkuColumnVisible = listings.some((l) => l.sku?.length > 0)
  const isRetailPriceColumnVisible = listings.some((l) => !!l.retailPrice)

  const columnHelper = useMemo(
    () => WebUI.createColumnHelper<ItemFormValuesListing>(),
    [],
  )

  const columns = useMemo(
    () =>
      [
        columnHelper.display({
          id: 'dragHandle',
          minSize: 40,
          size: 40,
          maxSize: 40,
          cell: () => (
            <WebUI.IconButton
              className="cursor-move text-ds-xl text-gray400"
              size="default_alt"
            >
              <WebUI.PhosphorIcon icon="dots-three-outline-vertical-fill" />
            </WebUI.IconButton>
          ),
        }),
        columnHelper.display({
          id: 'image',
          minSize: 80,
          size: 80,
          maxSize: 80,
          cell: ({row: {original: listing}}) => (
            <ItemListingImageCell
              listing={listing}
              images={itemImages}
              onClick={() => setImageEditingUuids([listing.uuid])}
            />
          ),
        }),
        ...optionKeys.map((optionKey, idx) =>
          columnHelper.accessor((listing) => listing.optionValues[optionKey], {
            id: `${optionKey}-${idx}`,
            header: optionKey,
          }),
        ),
        columnHelper.accessor((listing) => listing.amount, {
          id: 'amount',
          maxSize: 120,
          header: 'Price',
          cell: ({row: {original: listing, index}}) => {
            const listingError =
              formik.errors.options?.variants?.listings?.[index]
            return (
              <WebUI.FormField
                error={
                  typeof listingError === 'object' &&
                  'amount' in listingError &&
                  !!listingError?.amount
                }
              >
                <WebUI.AmountInput
                  name={`options.variants.listings[${index}].amount`}
                  size="compact"
                  placeholder="required"
                  value={listing.amount ?? ''}
                  onValueChange={(newAmount) =>
                    setFieldValueRef.current(
                      `options.variants.listings[${index}].amount`,
                      newAmount,
                    )
                  }
                  onBlur={formik.handleBlur}
                />
              </WebUI.FormField>
            )
          },
        }),
        isRetailPriceColumnVisible
          ? columnHelper.accessor((listing) => listing.retailPrice, {
              id: 'retailPrice',
              maxSize: 120,
              header: 'Retail Price',
              cell: ({row: {index}, cell}) => {
                const listingError =
                  formik.errors.options?.variants?.listings?.[index]
                return (
                  <WebUI.FormField
                    error={
                      typeof listingError === 'object' &&
                      !!listingError.retailPrice
                    }
                  >
                    <WebUI.AmountInput
                      name={`options.variants.listings[${index}].retailPrice`}
                      size="compact"
                      value={cell.getValue()}
                      onValueChange={(newRetailPrice) =>
                        setFieldValueRef.current(
                          `options.variants.listings[${index}].retailPrice`,
                          newRetailPrice,
                        )
                      }
                      onBlur={formik.handleBlur}
                    />
                  </WebUI.FormField>
                )
              },
            })
          : undefined,
        columnHelper.accessor((listing) => listing.available_quantity, {
          id: 'available_quantity',
          maxSize: 120,
          header: 'Qty Avail',
          cell: ({row: {index}, cell}) => {
            const groupedQuantity =
              formik.values.inventoryGroup?.selectedVariantUuids.includes(
                cell.row.original.uuid,
              )
                ? formik.values.inventoryGroup.availableQuantity
                : null
            const listingError =
              formik.errors.options?.variants?.listings?.[index]

            return (
              <div className="relative">
                <WebUI.FormField
                  error={
                    typeof listingError === 'object' &&
                    !!listingError.available_quantity
                  }
                >
                  <WebUI.NumberInput
                    name={`options.variants.listings[${index}].available_quantity`}
                    size="compact"
                    value={cell.getValue()}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </WebUI.FormField>

                {groupedQuantity != null && (
                  <WebUI.Button
                    className="-bottom-5 absolute text-ds-sm [&_>_.Button-content]:font-light"
                    variant="link"
                    onClick={() => variantQuantityGroupModalRef.current?.show()}
                  >
                    {`Grouped Qty: ${groupedQuantity}`}
                  </WebUI.Button>
                )}
              </div>
            )
          },
        }),
        isSkuColumnVisible
          ? columnHelper.accessor((listing) => listing.sku, {
              id: 'sku',
              header: 'SKU',
              maxSize: 120,
              cell: ({row: {index}, cell}) => {
                const listingError =
                  formik.errors.options?.variants?.listings?.[index]
                return (
                  <WebUI.FormField
                    error={
                      typeof listingError === 'object' && !!listingError.sku
                    }
                  >
                    <WebUI.Input
                      name={`options.variants.listings[${index}].sku`}
                      size="compact"
                      value={cell.getValue()}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </WebUI.FormField>
                )
              },
            })
          : undefined,
      ].filter((c): c is NonNullable<typeof c> => !!c),
    [
      columnHelper,
      optionKeys,
      isRetailPriceColumnVisible,
      isSkuColumnVisible,
      itemImages,
      formik.errors.options?.variants?.listings,
      formik.handleBlur,
      formik.handleChange,
      formik.values.inventoryGroup,
    ],
  )

  const selectedUuids = useMemo(
    () => Object.keys(Util.pickBy(selectedUuidsMap, (val) => val === true)),
    [selectedUuidsMap],
  )

  return (
    <WebUI.VStack className={WebUI.cn('gap-8', className)} {...restProps}>
      <WebUI.VStack className="gap-1">
        <WebUI.Heading className="text-ds-md" as="h4">
          Customize Variants
        </WebUI.Heading>
        <p className="text-ds-sm">
          Enter pricing and available quantities for your variants.
        </p>
      </WebUI.VStack>
      <div className="flex flex-row gap-3">
        <ItemVariantsListingTableActionDropdown
          variantsSelected={selectedUuids.length > 0}
          onEnterPrice={() => setPriceModalVisible(true)}
          onEnterQuantity={() => setQuantityModalVisible(true)}
          onEnterSku={() => setSkuModalVisible(true)}
          onMarkAsSoldOut={() => setMarkAsSoldOutModalVisible(true)}
          onUploadImage={() => setImageEditingUuids(selectedUuids)}
          onDelete={() => setDeleteModalVisible(true)}
        />

        <VariantQuantityGroupModal
          ref={variantQuantityGroupModalRef}
          disclosure={
            <WebUI.DialogDisclosure variant="outlined">
              Variant Quantity Group
            </WebUI.DialogDisclosure>
          }
          formik={formik}
          listings={listings}
          optionKeys={optionKeys}
        />
      </div>

      <WebUI.DragAndDrop
        collisionDetection={WebUI.closestCorners}
        onDragEnd={({active, over}) => {
          if (!over) {
            return
          }

          const prevOrder = listings.map((l) => l.uuid)
          const newOrder = WebUI.arrayMoveByValue(prevOrder, active.id, over.id)
          const newListings = newOrder.map((lUuid) =>
            listings.find((l) => l.uuid === lUuid),
          )

          formik.setFieldValue('options.variants.listings', newListings)
        }}
      >
        <WebUI.SortableContext
          items={useMemo(() => listings.map((l) => l.uuid), [listings])}
        >
          <div className="overflow-x-auto">
            <WebUI.TableView
              className="overflow-x-visible [&_.TableViewCell]:overflow-visible [&_>_.TableView-body_>_.TableViewRow_>_.TableViewCell]:text-ds-sm [&_>_.TableView-headerGroupList_>_.TableView-headerGroup]:border-b-0 [&_>_.TableView-headerGroupList_>_.TableView-headerGroup]:bg-natural-80"
              columns={columns}
              data={listings}
              state={{rowSelection: selectedUuidsMap}}
              getRowProps={(row) =>
                ({
                  as: WebUI.Sortable,
                  id: row.id,
                }) as any
              }
              enableRowSelection
              onRowSelectionChange={setSelectedUuidsMap}
              getRowId={(listing) => listing.uuid}
            />
          </div>
        </WebUI.SortableContext>
      </WebUI.DragAndDrop>

      {priceModalVisible && (
        <ItemVariantsListingModals.Price
          formik={formik}
          listingsUuids={selectedUuids}
          onDismiss={() => {
            setPriceModalVisible(false)
            setSelectedUuidsMap({})
          }}
        />
      )}
      <ItemVariantsListingModals.Quantity
        visible={quantityModalVisible}
        formik={formik}
        listingsUuids={selectedUuids}
        onDidHide={() => {
          setQuantityModalVisible(false)
          setSelectedUuidsMap({})
        }}
      />
      {skuModalVisible && (
        <ItemVariantsListingModals.Sku
          formik={formik}
          listingsUuids={selectedUuids}
          onDismiss={() => {
            setSkuModalVisible(false)
            setSelectedUuidsMap({})
          }}
        />
      )}
      {markAsSoldOutModalVisible && (
        <ItemVariantsListingModals.MarkAsSold
          formik={formik}
          listingsUuids={selectedUuids}
          onDismiss={() => {
            setMarkAsSoldOutModalVisible(false)
            setSelectedUuidsMap({})
          }}
        />
      )}
      {deleteModalVisible && (
        <ItemVariantsListingModals.Delete
          formik={formik}
          listingsUuids={selectedUuids}
          onDismiss={() => {
            setDeleteModalVisible(false)
            setSelectedUuidsMap({})
          }}
        />
      )}
      <ItemVariantsListingModals.Image
        visible={imageEditingUuids.length > 0}
        formik={formik}
        itemImages={[
          ...itemImages,
          ...Util.uniqueBy(
            listings
              .map((l) => l.localImage)
              .filter(
                (localImage): localImage is NonNullable<typeof localImage> =>
                  !!localImage,
              ),
            (localImage) =>
              `${WebUI.getFileId(localImage.image)}-${Util.sort(
                Object.values(localImage.thumbnail?.cropDetails ?? {}),
              ).asc((v) => v)}`,
          ),
        ]}
        listingUuids={imageEditingUuids}
        onDidHide={() => setImageEditingUuids([])}
      />
    </WebUI.VStack>
  )
}

// MARK: – ItemListingImageCell

interface ItemListingImageCellProps
  extends React.ComponentPropsWithoutRef<'button'> {
  listing: ItemFormValuesListing
  images: Api.S3Image[]
}

const ItemListingImageCell = ({
  listing,
  images,
  className,
  ...restProps
}: ItemListingImageCellProps) => {
  const croppedLocalImageUrl = useCroppedImage(
    useMemo(
      () =>
        listing.localImage
          ? {
              image: listing.localImage.image,
              crop: listing.localImage.thumbnail.cropDetails,
            }
          : null,
      [listing.localImage],
    ),
  )

  const croppedImageUrl = (() => {
    if (croppedLocalImageUrl) {
      return croppedLocalImageUrl
    }

    const listingImage = images.find(({id}) => id === listing.imageId)
    return listingImage
      ? ImagesUtils.getCroppedImageUrl(listingImage, {
          width: 112,
          height: 112,
        })
      : null
  })()

  return (
    <WebUI.IconButton
      className={WebUI.cn('h-14 w-14 bg-gray100', className)}
      {...restProps}
    >
      {croppedImageUrl ? (
        <img
          className="h-full w-full object-cover"
          alt="Preview"
          src={croppedImageUrl}
        />
      ) : (
        <WebUI.PhosphorIcon icon="camera" className="text-ds-2xl" />
      )}
    </WebUI.IconButton>
  )
}

export default ItemVariantsListingTable
