import {makeQueryUpdate, makeUseMutation} from '../use-mutation'
import {endpoints} from '../../endpoints'
import {getEndpointKey} from '../../utils'

export const useCreateManagerMutation = makeUseMutation(
  endpoints.managers.create,
  () => ({
    regular: (newManager) => [
      makeQueryUpdate(endpoints.managers.list, (prevManagers) =>
        prevManagers ? [newManager, ...prevManagers] : prevManagers,
      ),
    ],
  }),
)

export const useUpdateManagerMutation = makeUseMutation(
  endpoints.managers.update,
  () => ({
    regular: (newManager) => [
      makeQueryUpdate(endpoints.managers.list, (prevManagers) =>
        prevManagers?.map((m) => (m.id === newManager.id ? newManager : m)),
      ),
    ],
  }),
  (queryClient) => ({
    onSuccess: (_newManager, vars) => {
      vars.body?.permissions?.collectionIds?.forEach((tabId: number) => {
        const tabDetailQueryKey = getEndpointKey(endpoints.tabs.detail, {
          pathParams: {
            tabId,
          },
        })
        queryClient.invalidateQueries({queryKey: tabDetailQueryKey})
      })
    },
  }),
)

export const useDeleteManagerFromTabMutation = makeUseMutation(
  endpoints.managers.delete,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(endpoints.managers.list, (prevManagers) =>
        prevManagers?.filter((m) => m.id !== Number(vars.pathParams.managerId)),
      ),
    ],
  }),
)

export const useRemindManagerMutation = makeUseMutation(
  endpoints.managers.remind,
)

export const useAcceptManagerInviteMutation = makeUseMutation(
  endpoints.managers.acceptInvitation,
  undefined,
  (queryClient) => ({
    onSuccess: () => {
      const sessionQueryKey = getEndpointKey(endpoints.auth.session)
      const tabListQueryKey = getEndpointKey(endpoints.tabs.list)

      // TODO: is this needed ?
      queryClient.invalidateQueries({queryKey: sessionQueryKey})
      queryClient.invalidateQueries({queryKey: tabListQueryKey})
    },
  }),
)

export const useUpdateManagerNotificationMutation = makeUseMutation(
  endpoints.managers.updateNotification,
  undefined,
  (queryClient) => ({
    onSuccess: () => {
      const sessionQueryKey = getEndpointKey(endpoints.auth.session)
      queryClient.invalidateQueries({queryKey: sessionQueryKey})
    },
  }),
)
