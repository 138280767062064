import React from 'react'

export interface WithErrorBoundaryProps {
  fallback?: React.ReactNode
  onError?: (error: Error, errorInfo: React.ErrorInfo) => void
  children?: React.ReactNode
}

export class WithErrorBoundary extends React.Component<WithErrorBoundaryProps> {
  static getDerivedStateFromError(_error: Error) {
    return {hasError: true}
  }

  override state = {
    hasError: false,
  }

  override componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.props.onError?.(error, errorInfo)
  }

  override render() {
    if (this.state.hasError) {
      return this.props.fallback ?? null
    }
    return this.props.children ?? null
  }
}
