import {debounce} from '@cheddarup/util'
import {
  ForwardRefComponent,
  useLiveRef,
  useUpdateEffect,
} from '@cheddarup/react-util'
import {forwardRef, useEffect, useMemo, useState} from 'react'

import {CurrencyInput, CurrencyInputProps} from './CurrencyInput'
import {cn} from '../utils'

export interface AmountInputProps extends CurrencyInputProps {}

export const AmountInput = forwardRef(
  (
    {
      prefix = '$',
      step = 1,
      decimalSeparator = '.',
      groupSeparator = ',',
      allowNegativeValue = false,
      decimalsLimit = 2,
      className,
      size,
      maxLength = 15,
      ...restProps
    },
    forwardedRef,
  ) => (
    <CurrencyInput
      ref={forwardedRef}
      className={cn('AmountInput slashed-zero', className)}
      size={size as any}
      prefix={prefix}
      step={step}
      maxLength={maxLength}
      decimalSeparator={decimalSeparator}
      groupSeparator={groupSeparator}
      allowNegativeValue={allowNegativeValue}
      decimalsLimit={decimalsLimit}
      {...restProps}
    />
  ),
) as ForwardRefComponent<'input', AmountInputProps>

// MARK: – DeferredAmountInput

export interface DeferredAmountInputProps extends AmountInputProps {
  value?: string
  defaultValue?: string
  commitValue?: (newValue: string | undefined) => void
}

export const DeferredAmountInput = forwardRef<
  HTMLInputElement,
  DeferredAmountInputProps
>(
  (
    {
      defaultValue: defaultValueProp,
      value: valueProp,
      className,
      onValueChange,
      commitValue,
      ...restProps
    },
    forwardedRef,
  ) => {
    const initialValue = valueProp ?? defaultValueProp

    const [pendingValue, setPendingValue] = useState(initialValue)
    const commitValueRef = useLiveRef(commitValue)

    const debouncedHandleChange = useMemo(
      () =>
        debounce((newValue: typeof pendingValue) => {
          if (initialValue === newValue) {
            return
          }
          commitValueRef.current?.(String(newValue))
        }, 500),
      [initialValue],
    )

    useUpdateEffect(() => {
      debouncedHandleChange(pendingValue)
    }, [debouncedHandleChange, pendingValue])

    useEffect(
      () => () => debouncedHandleChange.cancel(),
      [debouncedHandleChange],
    )

    return (
      <AmountInput
        ref={forwardedRef}
        className={cn('DeferredAmountInput', className)}
        value={pendingValue}
        onValueChange={(newValue, ...rest) => {
          onValueChange?.(newValue, ...rest)

          setPendingValue(newValue)
        }}
        {...restProps}
      />
    )
  },
)
