import {endpoints} from '../../endpoints'
import {makeQueryUpdate, makeUseMutation} from '../use-mutation'
export const useDeleteHeaderImageMutation = makeUseMutation(
  endpoints.headerImages.delete,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(endpoints.headerImages.list, (prevImages) =>
        prevImages?.filter((h) => h.id !== Number(vars.pathParams.imageId)),
      ),
    ],
  }),
)
