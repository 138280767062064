import * as WebUI from '@cheddarup/web-ui'
import {useEffect, useState} from 'react'

export const useCroppedImage = (
  imageWithCrop:
    | {image: Blob; crop: Api.CropDetails | null | {}}
    | null
    | undefined,
) => {
  const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null)

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (imageWithCrop) {
      const image = new Image()
      image.addEventListener('load', async () => {
        if (Object.keys(imageWithCrop.crop ?? {}).length > 0) {
          const croppedImage = await WebUI.getCroppedImage(
            image,
            imageWithCrop.crop as Api.CropDetails,
          )
          if (croppedImage) {
            setCroppedImageUrl(croppedImage.preview)
          }
        } else {
          setCroppedImageUrl(image.src)
        }
      })
      image.src = URL.createObjectURL(imageWithCrop.image)
    } else {
      setCroppedImageUrl(null)
    }

    return () => {
      if (croppedImageUrl) {
        URL.revokeObjectURL(croppedImageUrl)
      }
    }
  }, [imageWithCrop])

  return croppedImageUrl
}
