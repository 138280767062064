import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {
  tabSchema,
  tabTemplateGroupTypeSchema,
  tabTemplateSchema,
  tabTemplatesOrderBySchema,
  templateCollectionCategorySchema,
} from '../schemas'

export default {
  list: makeEndpoint({
    path: 'templates',
    queryParamsSchema: z
      .object({
        search: z.string(),
        filter: z.object({
          key: z.enum(['groups', 'categories']),
          value: tabTemplateGroupTypeSchema.or(
            templateCollectionCategorySchema,
          ),
        }),
        orderBy: z.object({
          key: z.enum(['groups', 'categories']).or(tabTemplatesOrderBySchema),
          value: tabTemplateGroupTypeSchema
            .or(templateCollectionCategorySchema)
            .optional(),
        }),
      })
      .partial(),
    responseSchema: tabTemplateSchema.array(),
  }),
  detail: makeEndpoint({
    path: 'templates/:templateId',
    responseSchema: tabTemplateSchema,
  }),
  convertToTab: makeEndpoint({
    path: 'templates/:templateId/convert_to_tab',
    method: 'PATCH',
    responseSchema: tabSchema,
  }),
}
