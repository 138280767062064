import {useState} from 'react'
import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'

import type {FixedItemFormFormik} from '../../../../../containers/FixedItemForm/FixedItemForm'

export interface ItemVariantsListingPriceModalProps extends WebUI.PromptProps {
  formik: FixedItemFormFormik
  listingsUuids: string[]
  onDismiss?: () => void
}

const ItemVariantsListingPriceModal = ({
  formik,
  listingsUuids,
  onDismiss,
  ...restProps
}: ItemVariantsListingPriceModalProps) => {
  const [amountValue, setAmountValue] = useState('')
  const [retailPriceValue, setRetailPriceValue] = useState('')

  return (
    <WebUI.Prompt
      aria-label="Edit variants prices"
      initialVisible
      onDidHide={onDismiss}
      {...restProps}
    >
      {(dialog) => (
        <WebUI.VStack className="gap-3">
          <WebUI.PromptHeader
            className="[&_>_.PromptHeader-heading]:text-ds-lg"
            heading={`Enter pricing for ${Util.pluralize(
              'variant',
              listingsUuids.length,
              true,
            )}`}
          />
          <WebUI.FormFieldGroup>
            <WebUI.FormField label="Item price">
              <WebUI.AmountInput
                placeholder="$0"
                onValueChange={(newPrice) => setAmountValue(newPrice ?? '')}
              />
            </WebUI.FormField>
            <WebUI.FormField label="Compare at price">
              <WebUI.AmountInput
                placeholder="$ Optional"
                onValueChange={(newPrice) =>
                  setRetailPriceValue(newPrice ?? '')
                }
              />
            </WebUI.FormField>
          </WebUI.FormFieldGroup>

          <WebUI.HStack className="gap-3">
            <WebUI.Button
              onClick={() => {
                formik.setFieldValue(
                  'options.variants.listings',
                  formik.values.options.variants.listings.map((listing) =>
                    listingsUuids.includes(listing.uuid)
                      ? {
                          ...listing,
                          amount:
                            amountValue === '' ? listing.amount : amountValue,
                          retailPrice:
                            retailPriceValue === ''
                              ? listing.retailPrice
                              : retailPriceValue,
                        }
                      : listing,
                  ),
                )

                dialog.hide()
              }}
            >
              Save
            </WebUI.Button>
            <WebUI.Button variant="secondary" onClick={() => dialog.hide()}>
              Cancel
            </WebUI.Button>
          </WebUI.HStack>
        </WebUI.VStack>
      )}
    </WebUI.Prompt>
  )
}

export default ItemVariantsListingPriceModal
