import {Outlet, Route, Routes} from 'react-router-dom'
import {CollectionSummaryPage} from 'src/views/i/collection/CollectionSummaryPage'
import AccountSettingsPage from 'src/views/account/settings'
import DowngradePage from 'src/views/i/plans/DowngradePage'
import HowItWorksPage from 'src/views/i/orgs/HowItWorksPage'
import OrderSummaryPage from 'src/views/OrderSummaryPage'
import PlanPausePage from 'src/views/i/plans/PlanPausePage'
import PlansBasicErrorPage from 'src/views/i/plans/PlansBasicErrorPage'
import PlansPage from 'src/views/i/plans/PlansPage'
import ProPlanPage from 'src/views/i/plans/ProPlanPage'
import ProPlanWarningPage from 'src/views/i/plans/ProPlanWarningPage'
import TeamUpgradePage from 'src/views/i/plans/TeamUpgradePage'
import {EnsureAuth} from 'src/hooks/useAuthToken'
import {PageContainer} from 'src/components/PageContainer'
import {GroupPageQuickTourModal} from 'src/views/group/GroupPage/components'

export const ModalRoutes = () => (
  <Routes>
    <Route
      path="my-account/*"
      element={
        <EnsureAuth>
          <AccountSettingsPage />
        </EnsureAuth>
      }
    />
    <Route path="i">
      <Route
        path="plans/*"
        element={
          <EnsureAuth>
            <Routes>
              <Route index element={<PlansPage />} />
              <Route path="pro" element={<ProPlanPage />} />
              <Route path="team-upgrade" element={<TeamUpgradePage />} />

              <Route path="basic/error" element={<PlansBasicErrorPage />} />
              <Route path="pro/warning" element={<ProPlanWarningPage />} />
              <Route path="downgrade/:to" element={<DowngradePage />} />
              <Route path="pause" element={<PlanPausePage />} />
              <Route path="pause/:to" element={<PlanPausePage />} />
            </Routes>
          </EnsureAuth>
        }
      />

      <Route path="orgs/:org/how-it-works" element={<HowItWorksPage />} />

      <Route path="collection">
        <Route
          path=":collection/summary/*"
          element={
            <EnsureAuth>
              <PageContainer>
                <CollectionSummaryPage />
                <Outlet />
              </PageContainer>
            </EnsureAuth>
          }
        />
      </Route>
    </Route>
    <Route
      path="group-page-quick-tour"
      element={
        <EnsureAuth>
          <GroupPageQuickTourModal />
        </EnsureAuth>
      }
    />
    <Route
      path="collection/:collection/payment/:payment"
      element={
        <EnsureAuth>
          <OrderSummaryPage />
        </EnsureAuth>
      }
    />
  </Routes>
)
