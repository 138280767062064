import {Filter} from '@cubejs-client/core'
import {useMemo, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'

import {CreateCubeReportExportButton} from './../ReportsPage/components/CubeReports'
import {CubeFiltersPopover} from './../ReportsPage/ReportsPage'
import {CubeFilterField} from '../../components/CubeFilters'
import {CubeTable, CubeTableColumn} from '../../components/CubeTable'
import {LinkButton} from '../../components/LinkButton'

const PayersPage = () => {
  const [filters, setFilters] = useState<Filter[]>([])
  const query = useMemo(
    () => ({
      dimensions: ['Customers.email', 'Customers.nameSearch'],
      measures: [
        'Customers.paymentCount',
        'Customers.totalPaid',
        'Customers.totalQuantity',
        'Customers.collectionCount',
        'Customers.lastSeenAt',
        'Customers.name',
      ],
      filters,
    }),
    [filters],
  )

  const initialSortBy = useMemo(
    () => [{id: 'Customers.lastSeenAt', desc: true}],
    [],
  )

  const columns: CubeTableColumn[] = useMemo(
    () => [
      {
        path: 'Customers.name',
        headerTitle: 'Name',
        // @ts-expect-error
        Cell: ({row: {original: user}}) => (
          <div>
            <div className="font-normal text-ds-sm">
              {user['Customers.name']}
            </div>
            <div className="font-normal text-ds-xs text-gray400">
              {user['Customers.email']}
            </div>
          </div>
        ),
      },
      {path: 'Customers.totalPaid', headerTitle: 'Total Spent', maxSize: 160},
      {path: 'Customers.totalQuantity', maxSize: 160},
      {
        path: 'Customers.paymentCount',
        maxSize: 160,
      },
      {
        path: 'Customers.collectionCount',
        maxSize: 160,
      },
    ],
    [],
  )

  return (
    <WebUI.VStack className="gap-4">
      <WebUI.HStack
        className="items-center justify-between gap-3 p-4"
        as={WebUI.Panel}
      >
        <WebUI.Heading as="h2">Payers</WebUI.Heading>

        <WebUI.HStack className="items-center gap-3">
          <CubeFiltersPopover
            aria-label="Payers filters"
            filters={filters}
            onFiltersApply={(newFilters) => setFilters(newFilters.filters)}
          >
            <CubeFilterField dimension="Customers.totalPaid">
              Total Spent
            </CubeFilterField>
            <CubeFilterField dimension="Payments.count" />
            <CubeFilterField dimension="Customers.collectionCount" />
          </CubeFiltersPopover>
          <CreateCubeReportExportButton
            query={query}
            countMeasure="Customers.emailCount"
          />
        </WebUI.HStack>
      </WebUI.HStack>

      <WebUI.Panel>
        <CubeTable
          className="[&_.TableViewRow]:px-2 [&_TableView-headerGroup]:px-2"
          initialState={{sorting: initialSortBy}}
          query={query}
          columns={columns}
          countMeasure="Customers.emailCount"
          getRowProps={(row) =>
            row.original['Customers.email']
              ? ({
                  as: LinkButton,
                  className: 'px-0 h-auto [font-size:inherit] text-left',
                  to: row.original['Customers.email'],
                } as any)
              : {}
          }
        />
      </WebUI.Panel>
    </WebUI.VStack>
  )
}

export default PayersPage
