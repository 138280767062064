import React, {useContext, useMemo} from 'react'
import {unparseFieldGroups, type TransientField} from '@cheddarup/core'

import {FieldSetList} from '../FieldSetList'
import {FieldsEditInnerContext} from './FieldsEdit'

export interface FieldGroupsPreviewProps {
  fields?: TransientField[]
}

export const FieldGroupsPreview: React.FC<FieldGroupsPreviewProps> = ({
  fields: fieldsProp,
}) => {
  const contextValue = useContext(FieldsEditInnerContext)

  const fieldGroups = useMemo(
    () =>
      fieldsProp
        ? []
        : unparseFieldGroups({
            fieldGroups: contextValue.fieldGroups,
            fields: [],
          }),
    [fieldsProp, contextValue.fieldGroups],
  )

  const fieldSets = useMemo(
    () => fieldGroups.map((fg) => fg.fieldSet),
    [fieldGroups],
  )
  const fields =
    fieldsProp ??
    useMemo(() => fieldGroups.flatMap((fg) => fg.fields), [fieldGroups])

  return (
    <FieldSetList
      fieldSets={fieldSets}
      fields={fields as any}
      defaultValues={{}}
      onValuesChange={() => {}}
    />
  )
}
