import {useNavigate} from 'react-router-dom'
import {useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {useResetVerificationMutation} from '@cheddarup/api-client'
import {readApiError} from 'src/helpers/error-formatting'

const BackupSecurityCodeInputPage = () => {
  const navigate = useNavigate()
  const [password, setPassword] = useState('')
  const [backupSecurityCode, setBackupSecurityCode] = useState('')
  const growlActions = WebUI.useGrowlActions()
  const resetVerificationMutation = useResetVerificationMutation()

  return (
    <WebUI.Alert
      aria-label="Backup security code form"
      initialVisible
      onDidHide={() => navigate('../two-factor-authentication')}
    >
      {(dialog) => (
        <>
          <WebUI.AlertHeader>Enter Your Backup Security Code</WebUI.AlertHeader>
          <WebUI.VStack className="gap-4 p-6">
            <WebUI.Text className="font-light text-gray800">
              Enter your backup security code you received and saved when
              originally setting up two-factor authentication.
            </WebUI.Text>

            <WebUI.FormField className="mt-4 w-3/5" label="Account Password">
              <WebUI.Input
                type="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </WebUI.FormField>
            <WebUI.FormField
              className="my-4 w-3/5"
              label="Backup Security Code"
            >
              <WebUI.Input
                placeholder="________-____-____-____-____________"
                value={backupSecurityCode}
                onChange={(event) => setBackupSecurityCode(event.target.value)}
              />
            </WebUI.FormField>

            <WebUI.HStack className="gap-3">
              <WebUI.Button
                variant="primary"
                onClick={async () => {
                  try {
                    await resetVerificationMutation.mutateAsync({
                      body: {
                        reset_code: backupSecurityCode,
                        password,
                      },
                    })
                    growlActions.show('success', {
                      title: 'Success',
                      body: 'Your two-factor authentication phone number has been reset.',
                    })

                    dialog.hide()
                  } catch (error) {
                    const errorMessage = readApiError(error, {
                      unauthorized: 'Your password is not correct.',
                      incorrect_reset_code: 'Reset code is not correct.',
                    })
                    growlActions.show('error', {
                      title: 'Error!',
                      body: errorMessage,
                    })
                  }
                }}
              >
                Continue
              </WebUI.Button>
              <WebUI.Button variant="secondary" onClick={() => dialog.hide()}>
                Back
              </WebUI.Button>
            </WebUI.HStack>
          </WebUI.VStack>
        </>
      )}
    </WebUI.Alert>
  )
}

export default BackupSecurityCodeInputPage
