export interface UploadMemberInvitesSuccessProps {
  memberCount: number
}

const UploadMemberInvitesSuccess = ({
  memberCount,
}: UploadMemberInvitesSuccessProps) => (
  <h2 className="mb-16 font-normal text-ds-md">
    Congrats! You&apos;ve successfully created {memberCount} new accounts, which
    you&apos;ll see in the table below.
  </h2>
)

export default UploadMemberInvitesSuccess
