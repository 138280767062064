import * as Yup from 'yup'
import {useFormik} from '@cheddarup/react-util'
import {useStripe} from '@stripe/react-stripe-js'
import React, {useMemo, useRef} from 'react'
import * as Util from '@cheddarup/util'
import {CreateTokenBankAccountData} from '@stripe/stripe-js'
import * as WebUI from '@cheddarup/web-ui'
import {
  api,
  useCreatePaymentMethodMutation,
  useCreateTabDepositMutation,
} from '@cheddarup/api-client'
import {BankAccountFormSection, BankAccountSelectRow} from 'src/components'
import config from 'src/config'
import {AddPhoneNumberModal} from 'src/components/AddPhoneNumberModal'
import {InquireVerificationCodeInstance} from 'src/components/InquireVerificationCode'
import {read2FAError} from 'src/helpers/error-formatting'

const CENTS_IN_USD = 100

export interface CollectionDepositFormProps
  extends Omit<React.ComponentPropsWithoutRef<'form'>, 'onSubmit' | 'onReset'> {
  tabId: number
  onDidCreateCollectionDeposit?: () => void
  verificationHelpers: InquireVerificationCodeInstance
}

const CollectionDepositForm = ({
  tabId,
  onDidCreateCollectionDeposit,
  verificationHelpers,
  className,
  ...restProps
}: CollectionDepositFormProps) => {
  const stripe = useStripe()
  const {data: banks} = api.paymentMethods.list.useQuery(undefined, {
    select: (paymentMethodsData) => paymentMethodsData.banks,
  })
  const {data: session, refetch: refetchSession} = api.auth.session.useQuery()

  const createDepositMutation = useCreateTabDepositMutation()
  const createPaymentMethodMutation = useCreatePaymentMethodMutation()
  const growlActions = WebUI.useGrowlActions()
  const addPhoneNumberModalRef = useRef<WebUI.DialogInstance>(null)

  const idempotencyKey = useMemo(() => Util.makeShortId(), [])

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      amount: Yup.number().required('Required').positive('Must be positive'),
      useSavedBank: Yup.boolean(),
      bankAccount: Yup.object().when('useSavedBank', {
        is: true,
        otherwise: (schema) =>
          schema.shape({
            routingNumber: Yup.string().required('Required'),
            accountNumber: Yup.string().required('Required'),
            accountNumberRepeat: Yup.string()
              .required('Required')
              .oneOf([Yup.ref('accountNumber')], "Account numbers don't match"),
          }),
      }),
    }),
    initialValues: {
      amount: '',
      useSavedBank: !!banks && banks.length > 0,
      bankAccountId: banks?.[0]?.id ?? null,
      bankAccount: {
        routingNumber: '',
        accountNumber: '',
        accountNumberRepeat: '',
      },
    },
    onSubmit: async (values, formikHelpers) => {
      if (session?.user.profile.phone?.verified) {
        const {verificationCode} = await verificationHelpers
          .verifyPhone()
          .catch(() => ({verificationCode: ''}))

        if (verificationCode) {
          growlActions.show('success', {
            title: 'You’ve initiated a deposit',
            body: 'A confirmation has been emailed',
          })

          try {
            let paymentSourceId = values.bankAccountId
            if (!values.useSavedBank) {
              const stripeRes = await stripe?.createToken('bank_account', {
                country: 'US',
                currency: 'usd',
                account_number: values.bankAccount.accountNumber,
                routing_number: values.bankAccount.routingNumber,
                account_holder_name: '',
              } as CreateTokenBankAccountData)

              if (stripeRes?.error) {
                throw new Error(stripeRes.error.message)
              }
              if (stripeRes?.token) {
                const res = await createPaymentMethodMutation.mutateAsync({
                  body: {nickname: '', source: stripeRes.token.id},
                })
                paymentSourceId = res.id
              }
            }

            await createDepositMutation.mutateAsync({
              pathParams: {
                tabId,
              },
              body: {
                amount_cents: Number(values.amount) * CENTS_IN_USD,
                idempotency_key: idempotencyKey,
                stripe_payment_source_id: paymentSourceId ?? '',
                security: {
                  token: verificationCode,
                },
              },
            })
            growlActions.show('success', {
              title: 'Success',
              body: 'Collection has been funded',
            })
            formikHelpers.resetForm()
            onDidCreateCollectionDeposit?.()
          } catch (err: any) {
            const errMsg = read2FAError(err)
            growlActions.show('error', {
              title: 'Error',
              body: errMsg || err.response?.data?.error || err.message,
            })
          }
        }
      } else {
        addPhoneNumberModalRef.current?.show()
      }
    },
  })

  return (
    <>
      <WebUI.Form
        className={WebUI.cn(
          'max-w-screen-sm rounded bg-gray100 p-6 [&_>_.Form-inner]:gap-8',
          className,
        )}
        onSubmit={formik.handleSubmit}
        onReset={formik.handleReset}
        {...restProps}
      >
        <WebUI.FormField
          label="Amount to add to this collection"
          error={formik.errors.amount}
        >
          <WebUI.AmountInput
            className="max-w-[210px]"
            name="amount"
            placeholder="$0.00"
            value={formik.values.amount}
            onValueChange={(newAmount) =>
              formik.setFieldValue('amount', newAmount)
            }
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>

        <WebUI.VStack className="max-w-[340px] gap-4">
          <WebUI.Text className="text-ds-sm">Select Funding Source:</WebUI.Text>

          {formik.values.useSavedBank ? (
            <WebUI.RadioGroup
              name="bankAccountId"
              aria-label="Bank account select"
              state={formik.values.bankAccountId as any}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              {banks?.map((bank) => (
                <BankAccountSelectRow
                  key={bank.id}
                  className="bg-natural-100 px-4 py-3"
                  value={bank.id}
                  as={WebUI.Radio}
                  bankAccount={bank}
                  size="compact"
                />
              ))}
            </WebUI.RadioGroup>
          ) : (
            <BankAccountFormSection formik={formik as any} />
          )}

          {!!banks && banks.length > 0 && (
            <WebUI.Button
              variant="link"
              onClick={() =>
                formik.setFieldValue(
                  'useSavedBank',
                  !formik.values.useSavedBank,
                )
              }
            >
              Use {formik.values.useSavedBank ? 'New' : 'Saved'} Account
            </WebUI.Button>
          )}
        </WebUI.VStack>

        <WebUI.VStack className="gap-4">
          <WebUI.Button
            className="self-start"
            type="submit"
            variant="primary"
            loading={formik.isSubmitting}
          >
            Fund Collection
          </WebUI.Button>
          <WebUI.Text className="font-light text-ds-sm text-gray800">
            Transfer speeds for funding a collection vary but typically take 3
            business days. Transfers are reviewed, which may result in delays or
            the need for additional information.&nbsp;
            <WebUI.Anchor
              target="_blank"
              rel="noopener noreferrer"
              href={config.links.withdraw}
            >
              Learn more
            </WebUI.Anchor>
          </WebUI.Text>
        </WebUI.VStack>
      </WebUI.Form>
      <AddPhoneNumberModal
        ref={addPhoneNumberModalRef}
        priorTo="fund a collection"
        onDidVerify={() => {
          refetchSession()
          addPhoneNumberModalRef.current?.hide()
        }}
      />
    </>
  )
}

export default CollectionDepositForm
