import {Navigate, Outlet, Route} from 'react-router-dom'
import {EnsureAuth} from 'src/hooks/useAuthToken'
import {GuestAuthPage, GuestHeader} from 'src/views/guest'
import {GuestPaymentsPage} from 'src/views/GuestPaymentsPage'
import PayerHelpPage from 'src/views/c/PayerHelpPage'
import {GuestTicketsPage} from 'src/views/GuestTicketsPage'

import {paymentToOthersRoutes} from './PaymentToOthersRoutes'

export const guestRoutes = [
  <Route key="" path="" element={<Navigate to="payments" />} />,
  <Route
    key="payments"
    path="payments"
    element={
      <EnsureAuth allowInsecure redirectTo="../auth" tokenType="any">
        <GuestHeader />
        <GuestPaymentsPage />
        <Outlet />
      </EnsureAuth>
    }
  >
    {paymentToOthersRoutes}
    <Route path="help" element={<PayerHelpPage />} />
  </Route>,
  <Route
    key="tickets"
    path="tickets"
    element={
      <>
        <GuestHeader />
        <GuestTicketsPage />
        <Outlet />
      </>
    }
  >
    <Route path="help" element={<PayerHelpPage />} />
  </Route>,
  <Route
    key="auth"
    path="auth"
    element={
      <EnsureAuth
        allowInsecure={false}
        requireAuth={false}
        redirectTo="../payments"
      >
        <GuestAuthPage />
      </EnsureAuth>
    }
  />,
]
