import * as WebUI from '@cheddarup/web-ui'

import {FormFieldInlineLabelEdit} from './FormFieldInlineLabelEdit'
import type {FieldGroupFieldsEditProps} from './FieldGroupFieldsEdit'

export const AddressFieldsEdit = ({
  required,
  focused,
  initialLabels,
  placeholders,
  onLabelChange,
}: Omit<
  FieldGroupFieldsEditProps<'address'>,
  'fieldGroupType' | 'initialValue' | 'onValueChange'
>) => (
  <>
    <FormFieldInlineLabelEdit
      labelInputFocused={focused}
      required={required}
      initialLabel={initialLabels.line1}
      placeholder={placeholders.line1}
      onLabelChange={(label) => onLabelChange('line1', label)}
    >
      <WebUI.Input disabled autoComplete="address-line1" />
    </FormFieldInlineLabelEdit>
    <FormFieldInlineLabelEdit
      initialLabel={initialLabels.line2}
      placeholder={placeholders.line2}
      onLabelChange={(label) => onLabelChange('line2', label)}
    >
      <WebUI.Input disabled autoComplete="address-line2" />
    </FormFieldInlineLabelEdit>
    <WebUI.FormFieldGroup>
      <FormFieldInlineLabelEdit
        required={required}
        initialLabel={initialLabels.city}
        placeholder={placeholders.city}
        onLabelChange={(label) => onLabelChange('city', label)}
      >
        <WebUI.Input disabled autoComplete="address-level1" />
      </FormFieldInlineLabelEdit>
      <FormFieldInlineLabelEdit
        required={required}
        initialLabel={initialLabels.state}
        placeholder={placeholders.state}
        onLabelChange={(label) => onLabelChange('state', label)}
      >
        <WebUI.Input disabled autoComplete="address-level2" />
      </FormFieldInlineLabelEdit>
    </WebUI.FormFieldGroup>
    <FormFieldInlineLabelEdit
      className="sm:w-[calc(50%-theme(spacing.2))]"
      required={required}
      initialLabel={initialLabels.zip}
      placeholder={placeholders.zip}
      onLabelChange={(label) => onLabelChange('zip', label)}
    >
      <WebUI.Input disabled autoComplete="postal-code" />
    </FormFieldInlineLabelEdit>
  </>
)
