import {useEffect} from 'react'
import {useAuthToken} from 'src/hooks/useAuthToken'
import {useQueryClient} from '@cheddarup/api-client'

export const AuthErrorHandler = () => {
  const [authToken, setAuthToken] = useAuthToken()
  const queryClient = useQueryClient()

  const isUserToken = authToken?.tokenType === 'User'
  useEffect(() => {
    if (!isUserToken) {
      return
    }

    const sub = queryClient.getQueryCache().subscribe((event) => {
      if (
        event.type === 'updated' &&
        event.query.queryKey[0] === 'session' &&
        event.action.type === 'error' &&
        event.action.error.response?.status === 401
      ) {
        setAuthToken(null)
      }
    })

    return () => sub()
  }, [isUserToken, queryClient, setAuthToken])

  return null
}
