import * as WebUI from '@cheddarup/web-ui'
import React from 'react'

export const FeeTransparencyIcon = React.forwardRef<
  SVGSVGElement,
  Omit<WebUI.PhosphorIconProps, 'icon'>
>(({className, ...restProps}, forwardedRef) => (
  <WebUI.PhosphorIcon
    ref={forwardedRef}
    className={WebUI.cn('rotate-90 text-teal-600', className)}
    icon="cube-transparent-thin"
    {...restProps}
  />
))
