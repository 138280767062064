import {pending} from '@cheddarup/util'
import {useEffect} from 'react'

import {useAsyncCallback} from './useAsyncCallback'

export function useAsyncEffect<T>(
  fn: () => PromiseLike<T>,
  deps?: React.DependencyList,
) {
  const [callback, state] = useAsyncCallback<T, []>(fn, deps, {
    initialResult: pending,
    allowConcurrent: true,
  })

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    callback().catch((err: any) => {
      console.warn('Failed to execute async function', err)
    })
  }, deps)

  return state
}
