import React from 'react'
import * as WebUI from '@cheddarup/web-ui'

export const AuthLayout = ({
  className,
  children,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => (
  <WebUI.HStack
    className={WebUI.cn('grow justify-center', className)}
    {...restProps}
  >
    <WebUI.Panel
      className={
        'AuthLayout-panel basis-[640px] self-stretch border-none sm:shadow-z24 sm:[&.AuthLayout-panel]:self-center sm:[&.AuthLayout-panel]:rounded-[10px]'
      }
    >
      {children}
    </WebUI.Panel>
  </WebUI.HStack>
)
