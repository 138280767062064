import UpgradePlanContainer from './components/UpgradePlanContainer'

const fieldPanels = [
  {
    title: 'Team Annual',
    description: '$360 charged anually (equal to only $30/month)',
    value: 'team_annual',
    price: 360,
    discountBadge: (
      <div className="text-ds-sm">
        <span className="font-bold text-orange-50">BEST VALUE!</span> Save 20%
        with this plan (that's basically{' '}
        <span className="whitespace-nowrap font-semibold">2 months</span>{' '}
        free!). Use it all year round!
      </div>
    ),
  },
  {
    description: '$36 charged monthly',
    title: 'Team Monthly',
    value: 'team_monthly',
    price: 36,
  },
]

const TeamUpgradePage = () => (
  <UpgradePlanContainer
    fieldPanels={fieldPanels}
    heading="The Team Plan"
    subheading={
      <>
        The Cheddar Up Team plan is paid yearly or monthly. We make it simple to
        adjust your plan at any time from your My Account page.
      </>
    }
  />
)

export default TeamUpgradePage
