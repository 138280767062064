export const getUserSegment = (customerCategory: Api.UserCustomerCategory) => {
  if (
    ['SCOUT', 'SCHOOL', 'CLUB', 'FINE ARTS', 'NON-PROFIT', 'SPORT'].includes(
      customerCategory,
    )
  ) {
    return 'repeat'
  }
  if (
    customerCategory === 'RELIGIOUS' ||
    customerCategory === 'BUSINESS' ||
    customerCategory === 'FRIENDS'
  ) {
    return customerCategory.toLowerCase() as
      | 'religious'
      | 'business'
      | 'friends'
  }

  return 'other'
}

type Plan = 'basic' | 'pro' | 'team'

export const formatUserPlan = (plan: Api.SubscriptionPlanType): Plan =>
  plan === 'free' || plan === 'pause' ? 'basic' : plan
