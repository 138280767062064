import {useState} from 'react'
import {useParams} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'
import {ParammedTabs} from 'src/components/ParammedTabs'
import {PremiumFeaturesSideSheet} from 'src/components/PremiumFeaturesSideSheet'

import {
  ShareByEmailPanel,
  ShareByHTMLButtonPanel,
  ShareByQRCodePanel,
  ShareLinkPanel,
} from './components/InviteOthersPanels'
import {SharePosCode} from './components/SharePosCode'
import {MessageCenterPanel} from './components/MessageCenterPanel'
import {
  PublishTabPanel,
  PublishTabSuccessPanel,
} from './components/PublishTabPanel'
import TextToPayPanel from './components/TextToPayPanel'

const CollectionShareLinkPage = () => {
  const urlParams = useParams()
  const [isJustPublished, setIsJustPublished] = useState(false)
  const tabQuery = api.tabs.detail.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: urlParams.collection!,
    },
  })

  const isTabInDraftStatus = tabQuery.data?.status === 'draft'

  return (
    <PremiumFeaturesSideSheet tabId={Number(urlParams.collection)}>
      <ParammedTabs
        className="mx-auto w-full max-w-[min(100%,1366px)] grow px-4 py-8"
        variant="underlined"
        defaultPaneKey="invite"
      >
        <WebUI.VStack
          className={`items-center justify-start gap-4 md:flex-row md:items-start md:justify-center [&_*[aria-disabled="true"]]:pointer-events-none [&_*[aria-disabled="true"]]:cursor-not-allowed [&_*[aria-disabled="true"]]:select-none [&_*[aria-disabled="true"]]:opacity-70`}
        >
          <WebUI.VStack className="max-w-full grow gap-4">
            <WebUI.Heading>
              {isTabInDraftStatus ? 'Publish to Share' : 'Share'}
            </WebUI.Heading>
            <WebUI.TabList aria-label="Collection share navigation">
              <WebUI.Tab id="invite">Invite Others</WebUI.Tab>
              <WebUI.Tab id="website-widget">Add a Website Widget</WebUI.Tab>
              <WebUI.Tab
                id="pos-code"
                as={WebUI.Button}
                disabled={isTabInDraftStatus}
              >
                Share an Event Code
              </WebUI.Tab>
            </WebUI.TabList>

            <WebUI.TabPanel tabId="invite">
              <WebUI.VStack className="gap-4">
                {tabQuery.data?.status === 'draft' && (
                  <PublishTabPanel
                    tab={tabQuery.data}
                    onDidPublish={() => setIsJustPublished(true)}
                  />
                )}
                {isJustPublished && tabQuery.data && (
                  <PublishTabSuccessPanel tab={tabQuery.data} />
                )}
                {!!tabQuery.data && (
                  <>
                    <ShareLinkPanel
                      aria-disabled={tabQuery.data?.status === 'draft'}
                      collection={tabQuery.data}
                    />
                    <WebUI.VStack
                      aria-disabled={tabQuery.data?.status === 'draft'}
                      className="gap-6 lg:flex-row lg:[&_>_.Panel]:flex-[1_0_0px]"
                    >
                      <ShareByEmailPanel collection={tabQuery.data} />
                      <ShareByQRCodePanel collection={tabQuery.data} />
                      <TextToPayPanel collection={tabQuery.data} />
                    </WebUI.VStack>
                  </>
                )}
              </WebUI.VStack>
            </WebUI.TabPanel>
            <WebUI.TabPanel tabId="website-widget">
              {!!tabQuery.data && (
                <ShareByHTMLButtonPanel
                  className="items-start"
                  aria-disabled={tabQuery.data?.status === 'draft'}
                  collection={tabQuery.data}
                />
              )}
            </WebUI.TabPanel>
            <WebUI.TabPanel tabId="pos-code">
              {!!tabQuery.data && <SharePosCode collection={tabQuery.data} />}
            </WebUI.TabPanel>
          </WebUI.VStack>

          <MessageCenterPanel
            aria-disabled={tabQuery.data?.status === 'draft'}
            className="min-w-full md:mt-[calc(theme(spacing.8)+55px+48px)] md:min-w-[min(100%,320px)]"
            collectionId={Number(urlParams.collection)}
          />
        </WebUI.VStack>
      </ParammedTabs>
    </PremiumFeaturesSideSheet>
  )
}

export default CollectionShareLinkPage
