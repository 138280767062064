// Based on https://github.com/jaredLunde/react-hook/blob/master/packages/debounce/src/index.tsx

import {useCallback, useEffect, useRef, useState} from 'react'

import {useLiveRef} from './useLiveRef'

export function useDebounceCallback<CallbackArgs extends any[]>(
  callback: (...args: CallbackArgs) => void,
  wait = 100,
  leading = false,
): (...args: CallbackArgs) => void {
  const callbackRef = useLiveRef(callback)
  const timeout = useRef<ReturnType<typeof setTimeout>>()
  // Cleans up pending timeouts when the deps change
  useEffect(
    () => () => {
      timeout.current && clearTimeout(timeout.current)
      timeout.current = void 0
    },
    [],
  )

  return useCallback(
    (...args) => {
      const {current} = timeout
      // Calls on leading edge
      if (current === void 0 && leading) {
        timeout.current = setTimeout(() => {
          timeout.current = void 0
        }, wait)
        callbackRef.current.apply(null, args as any)
        return
      }
      // Clear the timeout every call and start waiting again
      current && clearTimeout(current)
      // Waits for `wait` before invoking the callback
      timeout.current = setTimeout(() => {
        timeout.current = void 0
        callbackRef.current.apply(null, args as any)
      }, wait)
    },
    [leading, wait],
  )
}

export function useDebounceState<T>(
  initialState: T | (() => T),
  wait?: number,
  leading?: boolean,
) {
  const [state, setState] = useState(initialState)
  return [
    state,
    useDebounceCallback(setState, wait, leading),
    setState,
  ] as const
}

// See https://usehooks-ts.com/react-hook/use-debounce
export function useDebounceValue<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useDebounceState<T>(value, delay)

  useEffect(() => {
    setDebouncedValue(value)
  }, [value, setDebouncedValue])

  return debouncedValue
}
