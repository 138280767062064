import {makeEndpoint} from '../utils'
import {groupPageSchema, groupPageStatusSchema} from '../schemas'
import {z} from '@cheddarup/util'

export default {
  detail: makeEndpoint({
    path: 'users/group_page',
    responseSchema: groupPageSchema,
  }),
  update: makeEndpoint({
    path: 'users/group_page',
    method: 'PATCH',
    bodySchema: z.object({status: groupPageStatusSchema}),
    responseSchema: groupPageSchema,
  }),
  home: makeEndpoint({
    path: 'group_page/:slug',
    responseSchema: groupPageSchema,
  }),
  deleteTopBannerImage: makeEndpoint({
    path: 'users/group_page/top_banner/delete_image/:id',
    method: 'DELETE',
    responseSchema: z.void(),
  }),
}
