// @ts-ignore

import {z} from '@cheddarup/util'

import {cheddarUpImageSchema, s3ImageSchema} from './common'
import {managerSchema} from './managers'
import {tabFolderSchema} from './tab-folders'
import {tabItemSchema} from './tab-items'
import {tabFormSchema} from './tab-forms'
import {einVerificationStatus, userUiClientFlagsSchema} from './clients'
import {tabTaxSchema} from './tab-taxes'
import {tabDonationCodeSchema} from './tab-donation-codes'

export const tabCollectionMethodTypeSchema = z.enum(['bank_account'])

export const tabOptionsGroupPageSchema = z.object({
  shared: z.boolean(),
  order: z.number().optional(),
})

export const tabCustomCardDescriptorSchema = z.object({
  enabled: z.boolean(),
  value: z.string().optional(),
})

export const tabInternalMarketplaceSchema = z.object({
  enabled: z.boolean(),
})

export const tabPOSCodeSchema = z.object({
  value: z.string().nullish(),
  enabled: z.boolean(),
})

export const tabPaymentGoalSchema = z.object({
  value: z.number().nullish(),
  enabled: z.boolean(),
  order: z.number().optional(),
})

export const tabProPremiumMetaSchema = z.object({
  taxes: z.boolean(),
  shipping: z.boolean(),
  discounts: z.boolean(),
  accessCode: z.boolean(),
  multipleForms: z.boolean(),
  itemVariations: z.boolean(),
  paymentByEcheck: z.boolean(),
  moreThanFiveItems: z.boolean(),
  startAndStopTimes: z.boolean(),
  itemWithMultipleImages: z.boolean(),
  visitorReport: z.boolean(),
  fundraisingGoal: z.boolean(),
  customReceipt: z.boolean(),
  eSign: z.boolean(),
  initials: z.boolean(),
  fileField: z.boolean(),
  waiver: z.boolean(),
  videoLink: z.boolean(),
  multipleBannerImages: z.boolean().optional(),
})

export const tabTeamPremiumMetaSchema = z.object({
  waitlist: z.boolean(),
  ticketing: z.boolean(),
  recurringPayments: z.boolean(),
  faqs: z.boolean(),
  dateTime: z.boolean(),
  location: z.boolean(),
  nonProfitStatus: z.boolean(),
  mainContact: z.boolean(),
  groupPage: z.boolean(),
  dynamicLinkedItems: z.boolean(),
})

export const tabPremiumMetaSchema = z.object({
  pro: tabProPremiumMetaSchema.partial(),
  team: tabTeamPremiumMetaSchema.partial(),
})

export const tabInformationBlockSettingsSchema = z.object({
  promoteSharing: z
    .object({
      enabled: z.boolean(),
      order: z.number(),
    })
    .optional(),
  nonProfitStatus: z
    .object({
      enabled: z.boolean(),
      order: z.number(),
    })
    .optional(),
  time: z
    .object({
      enabled: z.boolean(),
      startTime: z.string().optional(),
      endTime: z.string().optional(),
      timeZone: z.string(),
      order: z.number().optional(),
    })
    .optional(),
  location: z
    .object({
      enabled: z.boolean(),
      address: z.string(),
      latitude: z.number().nullish(),
      longitude: z.number().nullish(),
      order: z.number().optional(),
    })
    .optional(),
  faqs: z
    .object({
      enabled: z.boolean(),
      order: z.number().optional(),
    })
    .optional(),
  payerList: z
    .object({
      order: z.number(),
      enabled: z.boolean(),
      customContributorName: z.string(),
    })
    .optional(),
  totalCollected: z
    .object({
      order: z.number(),
      enabled: z.boolean(),
    })
    .optional(),
  video: z
    .object({
      enabled: z.boolean(),
      platform: z.string(),
      link: z.string(),
      order: z.number(),
    })
    .optional(),
})

export const tabOptionsSchema = z.object({
  groupPage: tabOptionsGroupPageSchema.nullish(),
  customCardDescriptor: tabCustomCardDescriptorSchema.nullish(),
  doNotIndex: z.boolean().nullish(),
  internalMarketplace: tabInternalMarketplaceSchema.nullish(),
  hideSoldOutItems: z.boolean().nullish(),
  imageCarouselIds: z.number().array().optional(),
  posCode: tabPOSCodeSchema.nullish(),
  paymentGoal: tabPaymentGoalSchema.nullish(),
  customContributorName: z
    .object({
      enabled: z.boolean(),
      value: z.string(),
    })
    .optional(),
  allowPreview: z.boolean().optional(),
  payerCanCoverFees: z.boolean().nullish(),
  coverOnlyEcheckFees: z.boolean().nullish(),
  requirePayment: z.boolean().nullish(),
  defaultToGalleryView: z.boolean().optional(),
  displayOrder: z.string().array().nullish(),
  onlyAllowOneDiscountPerPurchase: z.boolean().nullish(),
  payerCanCoverFeesCustomMessage: z.string().optional(),
  allowIndexing: z.boolean().optional(),
  premiumMeta: tabPremiumMetaSchema.optional(),
  doNotEnforceAddlGated: z.boolean().optional(),
  infoBlockSettings: tabInformationBlockSettingsSchema.optional(),
})

export const processingPreferenceTypeSchema = z.enum(['member', 'user'])

export const tabShippingOptionsSchema = z.object({
  shipToEnabled: z.boolean(),
  freeOver: z.number(),
  freeOverEnabled: z.boolean(),
  cost: z.number(),
  localPickupEnabled: z.boolean(),
  localPickupInstructions: z.string(),
})

export const tabFaqSchema = z.object({
  id: z.number(),
  tab_id: z.number(),
  position: z.number(),
  detail: z.null(),
  created_at: z.string(),
  updated_at: z.string(),
  deleted_at: z.string().nullable(),
  question: z.string(),
  answer: z.string(),
})

export const tabOrganizerSchema = z.object({
  id: z.number(),
  uuid: z.string().nullish(),
  email: z.string(),
  name: z.string(),
  customerCategory: z.string(),
  displayName: z.string(),
  uiClientFlags: userUiClientFlagsSchema.nullish(),
  verifiedNonProfitStatus: einVerificationStatus,
})

export const tabStatusSchema = z
  .enum(['active', 'closed', 'draft'])
  .or(z.literal(null))
export const tabTypeSchema = z.enum(['Tab', 'Template'])

export const tabSchema = z.object({
  id: z.number(),
  updated_at: z.string(),
  created_at: z.string(),
  access: z.object({
    owner: z.boolean(),
    canDelete: z.boolean().nullish(),
  }),
  access_code: z.string().optional(),
  allow_echeck_payments: z.boolean().optional(),
  allow_offline_payments: z.boolean().nullish(),
  approved_withdrawals: z.number().optional(),
  close_datetime: z.string().nullish(),
  collection_category: z.string().nullable(),
  collection_subcategory: z.string().array().nullish(),
  collection_purpose: z.string().nullable(),
  closed_at: z.string().nullish(),
  collection_method: tabCollectionMethodTypeSchema.optional(),
  creating_user_id: z.number(),
  deleted_at: z.string().nullish(),
  deleting_user_id: z.number().nullish(),
  donationCode: tabDonationCodeSchema.nullish(),
  contact_manager_id: z.number().nullish(),
  description: z.string().nullable(),
  display_order: z.number().nullable(),
  due_date: z.string().nullish(),
  errors: z.string().array().or(z.record(z.any())).nullish(),
  failed_total: z.number().optional(),
  faqs: tabFaqSchema.array().optional(),
  folder: tabFolderSchema.nullish(),
  grandfathered_item_limits: z.boolean().optional(),
  image: cheddarUpImageSchema.nullish(),
  is_pro: z.boolean(),
  is_team: z.boolean(),
  managers: managerSchema.array().or(z.literal(false)).optional(),
  name: z.string(),
  offline_confirmed_total: z.number().optional(),
  offline_payment_instructions: z.string().nullish(),
  offline_pending_total: z.number().optional(),
  offline_total: z.number().optional(),
  online_confirmed_total: z.number().optional(),
  online_pending_total: z.number(),
  online_total: z.number().optional(),
  card_confirmed_total: z.number().nullish(),
  card_pending_total: z.number().nullish(),
  echeck_confirmed_total: z.number().nullish(),
  echeck_pending_total: z.number().nullish(),
  open_datetime: z.string().nullish(),
  organizer: tabOrganizerSchema,
  parent_tab_id: z.number().nullish(),
  payer_identify: z.boolean().optional(),
  payments_total: z.number(),
  pending_total: z.number().optional(),
  pending_withdrawals: z.number().optional(),
  processing_preference: processingPreferenceTypeSchema.optional(),
  reminder_frequency: z.any().nullish(),
  requires_pro: z.boolean(),
  requires_team: z.boolean(),
  slug: z.string(),
  /**
   * @deprecated – Use `header_images` and `featured_image`
   */
  theme: z
    .object({
      id: z.number(),
      image: s3ImageSchema.partial().optional(),
    })
    .nullish(),
  /**
   * @deprecated – Use `header_images` and `featured_image`
   */
  theme_id: z.number().nullish(),
  total_member_fees: z.number().optional(),
  total_user_fees: z.number(),
  total_withdrawals: z.number(),
  user_id: z.number(),
  withdrawal_balance_available: z.number(),
  total_platform_fees: z.number().optional(),
  total_dispute_fees: z.number().optional(),
  confirmed_deposits: z.number().optional(),
  custom_receipt_enabled: z.boolean().optional(),
  custom_receipt_content: z.string().nullish(),
  shipping_options: tabShippingOptionsSchema.or(z.object({})).optional(),
  discounts_enabled: z.boolean().optional(),
  taxes_enabled: z.boolean().optional(),
  taxes: tabTaxSchema.array().or(z.object({})).optional(),
  items: tabItemSchema.array().nullish(),
  forms: tabFormSchema.array().nullish(),
  itemLimit: z.number().nullable(),
  formLimit: z.number().nullable(),
  pos_item_id: z.number().nullish(),
  options: tabOptionsSchema,
  statement_descriptor: z.string().nullish(),
  alwaysShowRetailPrice: z.boolean().nullish(),
  reportsAvailable: z
    .object({
      payerIdentify: z.boolean(),
      paymentCount: z.number(),
      soldItemsCount: z.number(),
      formsCount: z.number(),
      activeFormsCount: z.number(),
      itemsCount: z.number(),
      activeItemsCount: z.number(),
      soldTicketsCount: z.number(),
      activeSignupsCount: z.number(),
      shippingPayments: z.boolean(),
    })
    .nullish(),
  logo: s3ImageSchema.nullish(),
  timing: z
    .object({
      opens: z.string().nullish(),
      closes: z.string().nullish(),
    })
    .nullish(),
  active_items: z.boolean(),
  forms_and_signups: z.boolean(),
  respondents: z.number(),
  status: tabStatusSchema,
  type: tabTypeSchema,
  template_id: z.number().nullable(),
  header_images: s3ImageSchema.array().optional(),
  featured_image: s3ImageSchema.nullable(),
  /**
   * @deprecated
   * @see [`infoBlockSettings.totalCollected.enabled`]{@link tabOptionsSchema}
   */
  display_total_collected: z.boolean().nullish(),
  /**
   * @deprecated
   * @see [`infoBlockSettings.payerList.enabled`]{@link tabOptionsSchema}
   */
  display_member_list: z.boolean().optional(),
  collection_section_category_id: z.number().nullable(),
})

export const tabDetailedSchema = tabSchema.extend({
  reportsAvailable: tabSchema.shape.reportsAvailable
    .unwrap()
    .unwrap()
    .required(),
  published_at: z.string().nullable(),
})

export const posUserSchema = z.object({
  collector_name: z.string(),
  collector_email: z.string(),
  last_payment: z.string(),
  transactions: z.number(),
  total: z.number(),
})

export const tabListMemberSchema = z.object({
  email: z.string(),
  first_name: z.string().nullish(),
  last_name: z.string().nullish(),
  invited: z.boolean(),
  paid: z.boolean(),
  visited: z.boolean(),
  unsubscribed: z.boolean(),
})

declare global {
  namespace Api {
    // @ts-ignore
    type TabListMember = z.infer<typeof tabListMemberSchema>
    // @ts-ignore
    type PosUser = z.infer<typeof posUserSchema>
    // @ts-ignore
    type TabDetailed = z.infer<typeof tabDetailedSchema>
    // @ts-ignore
    type Tab = z.infer<typeof tabSchema>
    // @ts-ignore
    type TabOrganizer = z.infer<typeof tabOrganizerSchema>
    // @ts-ignore
    type TabShippingOptions = z.infer<typeof tabShippingOptionsSchema>
    // @ts-ignore
    type ProcessingPreferenceType = z.infer<
      typeof processingPreferenceTypeSchema
    >
    // @ts-ignore
    type TabOptions = z.infer<typeof tabOptionsSchema>
    // @ts-ignore
    type TabPaymentGoal = z.infer<typeof tabPaymentGoalSchema>
    // @ts-ignore
    type TabPOSCode = z.infer<typeof tabPOSCodeSchema>
    // @ts-ignore
    type TabInternalMarketplace = z.infer<typeof tabInternalMarketplaceSchema>
    // @ts-ignore
    type TabCustomCardDescriptor = z.infer<typeof tabCustomCardDescriptorSchema>
    // @ts-ignore
    type TabOptionsGroupPage = z.infer<typeof tabOptionsGroupPageSchema>
    // @ts-ignore
    type TabCollectionMethodType = z.infer<typeof tabCollectionMethodTypeSchema>
    // @ts-ignore
    type TabPremiumMeta = z.infer<typeof tabPremiumMetaSchema>
    // @ts-ignore
    type TabProPremiumMeta = z.infer<typeof tabProPremiumMetaSchema>
    // @ts-ignore
    type TabTeamPremiumMeta = z.infer<typeof tabTeamPremiumMetaSchema>
    // @ts-ignore
    type TabFaq = z.infer<typeof tabFaqSchema>
    // @ts-ignore
    type TabInformationBlockSettings = z.infer<
      typeof tabInformationBlockSettingsSchema
    >
  }
}
