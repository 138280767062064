// @ts-ignore

import {z} from '@cheddarup/util'

export const contactListDetailSchema = z.any()

export const contactListSchema = z.object({
  id: z.number(),
  user_id: z.number(),
  created_at: z.string(),
  updated_at: z.string(),
  deleted_at: z.string().nullable(),
  detail: contactListDetailSchema,
  name: z.string(),
})

export const contactSchema = z.object({
  id: z.number(),
  user_id: z.number(),
  name: z.string(),
  email: z.string(),
  detail: z.any(),
  deleted_at: z.string().nullish(),
  created_at: z.string(),
  updated_at: z.string(),
  contact_lists: contactListSchema.array(),
})

declare global {
  namespace Api {
    // @ts-ignore
    export type Contact = z.infer<typeof contactSchema>
    // @ts-ignore
    export type ContactListDetail = z.infer<typeof contactListDetailSchema>
    // @ts-ignore
    export type ContactList = z.infer<typeof contactListSchema>
  }
}
