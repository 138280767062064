import * as WebUI from '@cheddarup/web-ui'

import type {TotalCollectedFormFormik} from './TotalCollectedPage'

interface AbousFormProps
  extends Omit<React.ComponentPropsWithoutRef<'form'>, 'onSubmit'> {
  formik: TotalCollectedFormFormik
}

const TotalCollectedForm: React.FC<AbousFormProps> = ({
  formik,
  className,
  ...restProps
}) => {
  return (
    <form
      className={WebUI.cn('flex flex-col', className)}
      onSubmit={formik.handleSubmit}
      onReset={formik.handleReset}
      noValidate
      {...restProps}
    >
      <WebUI.VStack className="grow gap-5">
        <WebUI.FormField
          label="Section Header"
          className="max-w-96"
          error={formik.errors.headline}
          required
        >
          <WebUI.Input
            name="headline"
            placeholder="e.g. “Thanks to you, we’ve collected:”"
            value={formik.values.headline}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
        <WebUI.FormField label="Description" error={formik.errors.description}>
          <WebUI.Textarea
            name="description"
            className="max-w-96"
            placeholder="Smaller text under header"
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            rows={3}
          />
        </WebUI.FormField>
        <WebUI.Separator />
        <WebUI.VStack>
          <WebUI.Text>Select a time period</WebUI.Text>
          <WebUI.Text className="font-light text-ds-sm">
            We’ll automatically update the total amount collected on Cheddar Up.
          </WebUI.Text>
        </WebUI.VStack>
        <WebUI.FormField title="Time Period">
          <WebUI.DropdownSelect
            name="details.timePeriod"
            className="max-w-60"
            value={formik.values.details.timePeriod}
            defaultValue="all_time"
            onValueChange={(timePeriod) =>
              formik.setFieldValue('details.timePeriod', timePeriod)
            }
          >
            <WebUI.DropdownSelectOption value="last_year">
              Year to date
            </WebUI.DropdownSelectOption>
            <WebUI.DropdownSelectOption value="last_month">
              Month to date
            </WebUI.DropdownSelectOption>
            <WebUI.DropdownSelectOption value="last_week">
              Last 7 days
            </WebUI.DropdownSelectOption>
            <WebUI.DropdownSelectOption value="all_time">
              All Time
            </WebUI.DropdownSelectOption>
          </WebUI.DropdownSelect>
        </WebUI.FormField>
      </WebUI.VStack>
    </form>
  )
}

export default TotalCollectedForm
