import * as WebUI from '@cheddarup/web-ui'
import React from 'react'
import {LinkButton} from 'src/components/LinkButton'

export interface ItemSettingsUpgradePlanButtonProps
  extends React.ComponentPropsWithoutRef<'div'> {
  plan: Exclude<Api.SubscriptionPlanType, 'free' | 'pause'>
  children: React.ReactNode
  description?: React.ReactNode
}

export const ItemSettingsUpgradePlanButton: React.FC<
  ItemSettingsUpgradePlanButtonProps
> = ({plan, children, description, className, ...restProps}) => (
  <div
    className={WebUI.cn('flex items-center justify-between gap-4', className)}
    {...restProps}
  >
    <div className="flex flex-col">
      <WebUI.Text>{children}</WebUI.Text>
      {description && <span className="text-ds-sm">{description}</span>}
    </div>
    <LinkButton size="compact" variant="secondary" preserveSearch to="i/plans">
      Go {{team: 'TEAM', pro: 'PRO'}[plan]}
    </LinkButton>
  </div>
)
