import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'

export default {
  delete: makeEndpoint({
    path: 'users/tabs/:tabId/signups/:signupId/spots/:spotId/time_slots/:timeSlotId',
    method: 'DELETE',
    queryParamsSchema: z
      .object({
        sendEmail: z.boolean(),
      })
      .partial()
      .optional(),
    responseSchema: z.any(),
  }),
}
