// @ts-ignore

import {z} from '@cheddarup/util'

import {currencySchema, isoCountryCodeSchema} from './common'

export const bankAccountStatus = z.enum(['verified'])

export const bankAccountSchema = z.object({
  id: z.string(),
  account_holder_name: z.string(),
  bank_name: z.string(),
  country: isoCountryCodeSchema,
  currency: currencySchema,
  last4: z.string(),
  routing_number: z.string(),
  name: z.string().optional(),
  status: bankAccountStatus,
  nickname: z.string().nullish(),
  hidden: z.boolean().nullish(),
  isDefault: z.boolean().nullable(),
})

export const creditCardFundingSchema = z.enum([
  'credit',
  'debit',
  'prepaid',
  'unknown',
])

export const creditCardSchema = z.object({
  id: z.string(),
  address_city: z.string().nullish(),
  address_country: z.string().nullish(),
  address_line1: z.string().nullish(),
  address_line2: z.string().nullish(),
  address_state: z.string().nullish(),
  address_zip: z.string().nullish(),
  exp_month: z.number(),
  exp_year: z.number(),
  brand: z.string(),
  funding: creditCardFundingSchema.optional(),
  last4: z.string(),
  name: z.string().nullish(),
  nickname: z.string().nullish(),
  created_at: z.string(),
  isDefault: z.boolean().nullable(),
  country: isoCountryCodeSchema,
})

declare global {
  namespace Api {
    // @ts-ignore
    type CreditCard = z.infer<typeof creditCardSchema>
    // @ts-ignore
    type CreditCardFunding = z.infer<typeof creditCardFundingSchema>
    // @ts-ignore
    type BankAccount = z.infer<typeof bankAccountSchema>
    // @ts-ignore
    type BankAccountStatus = z.infer<typeof bankAccountStatus>
  }
}
