import {mapValues} from '@cheddarup/util'
import {endpoints} from '../../endpoints'
import {getEndpointKey} from '../../utils'
import {makeQueryUpdate, makeUseMutation} from '../use-mutation'

export const useCreateGroupPageSectionMutation = makeUseMutation(
  endpoints.groupPageSections.create,
  (vars) => ({
    regular: (newSection) =>
      [
        makeQueryUpdate(endpoints.groupPageSections.detail, () => newSection, {
          pathParams: vars.pathParams,
        }),
        makeQueryUpdate(endpoints.groupPages.detail, (groupPage) =>
          groupPage
            ? {
                ...groupPage,
                sections: {
                  ...groupPage.sections,
                  [vars.pathParams.sectionName]: newSection,
                } as any,
              }
            : groupPage,
        ),
        vars.body?.userSlug
          ? makeQueryUpdate(
              endpoints.groupPages.home,
              (groupPage) =>
                groupPage
                  ? ({
                      ...groupPage,
                      sections: {
                        ...groupPage.sections,
                        [vars.pathParams.sectionName]: newSection,
                      },
                    } as any)
                  : groupPage,
              {pathParams: {slug: vars.body.userSlug}},
            )
          : undefined,
      ].filter((u) => u != null),
  }),
)

export const useUpdateGroupPageSectionMutation = makeUseMutation(
  endpoints.groupPageSections.update,
  (vars) => ({
    optimistic:
      vars.body?.position == null
        ? []
        : [
            makeQueryUpdate(endpoints.groupPages.detail, (prevGroupPage) =>
              prevGroupPage
                ? {
                    ...prevGroupPage,
                    sections: mapValues(
                      prevGroupPage.sections ?? [],
                      (section, sectionKey) =>
                        section
                          ? {
                              ...section,
                              position:
                                sectionKey === vars.pathParams.sectionName
                                  ? (vars.body?.position ?? -1)
                                  : section.position >=
                                      (vars.body?.position ?? -1)
                                    ? section.position + 1
                                    : section.position,
                            }
                          : section,
                    ) as any,
                  }
                : prevGroupPage,
            ),
            makeQueryUpdate(
              endpoints.groupPageSections.detail,
              (prevSection) =>
                prevSection
                  ? {
                      ...prevSection,
                      position: vars.body?.position ?? -1,
                    }
                  : prevSection,
              vars,
            ),
            ...[
              'collection_section',
              'collection_spotlight',
              'about_us',
              'total_collected',
              'meet_the_team',
              'sponsor',
            ]
              .filter((s) => s !== vars.pathParams.sectionName)
              .map((s) =>
                makeQueryUpdate(
                  endpoints.groupPageSections.detail,
                  (prevSection) =>
                    prevSection &&
                    prevSection.position >= (vars.body?.position ?? -1)
                      ? {
                          ...prevSection,
                          position: (vars.body?.position ?? -1) + 1,
                        }
                      : prevSection,
                  {pathParams: {sectionName: s}},
                ),
              ),
          ],
    regular: (updatedSection) =>
      [
        makeQueryUpdate(
          endpoints.groupPageSections.detail,
          () => updatedSection,
          {
            pathParams: vars.pathParams,
          },
        ),
        makeQueryUpdate(endpoints.groupPages.detail, (groupPage) =>
          groupPage
            ? {
                ...groupPage,
                sections: {
                  ...groupPage.sections,
                  [vars.pathParams.sectionName]: updatedSection,
                } as any,
              }
            : groupPage,
        ),
        vars.body?.userSlug
          ? makeQueryUpdate(
              endpoints.groupPages.home,
              (prevGroupPage) =>
                prevGroupPage && vars.body?.userSlug
                  ? {
                      ...prevGroupPage,
                      sections: {
                        ...prevGroupPage.sections,
                        [vars.pathParams.sectionName]: updatedSection,
                      } as any,
                    }
                  : prevGroupPage,
              {
                pathParams: {
                  slug: vars.body.userSlug,
                },
              },
            )
          : undefined,
      ].filter((u) => u != null),
  }),
)

export const useDeleteGroupPageSectionMutation = makeUseMutation(
  endpoints.groupPageSections.delete,
  (vars) => ({
    regular: () => [
      makeQueryUpdate(endpoints.groupPages.detail, (groupPage) =>
        groupPage
          ? {
              ...groupPage,
              sections: {
                ...groupPage.sections,
                [vars.pathParams.sectionName]: null,
              } as any,
            }
          : groupPage,
      ),
    ],
  }),
  (queryClient) => ({
    onSuccess: (_, vars) => {
      const sectionDetailQueryKey = getEndpointKey(
        endpoints.groupPageSections.detail,
        vars,
      )
      queryClient.resetQueries({queryKey: sectionDetailQueryKey})
    },
  }),
)
