import {forwardRef} from 'react'
import {ForwardRefComponent} from '@cheddarup/react-util'

import {CurrencyInput, CurrencyInputProps} from './CurrencyInput'
import {cn} from '../utils'

export interface PercentInputProps
  extends Omit<
    CurrencyInputProps,
    'disableAbbreviations' | 'disableGroupSeparators'
  > {}

export const PercentInput = forwardRef(
  (
    {
      step = 1,
      allowNegativeValue = false,
      allowDecimals = false,
      decimalSeparator = '.',
      decimalsLimit = 2,
      size,
      placeholder = '%',
      suffix = '%',
      className,
      ...restProps
    },
    forwardedRef,
  ) => (
    <CurrencyInput
      ref={forwardedRef}
      className={cn('PercentInput', className)}
      placeholder={placeholder}
      size={size as any}
      disableAbbreviations
      disableGroupSeparators
      allowDecimals={allowDecimals}
      suffix={suffix}
      step={step}
      decimalSeparator={decimalSeparator}
      allowNegativeValue={allowNegativeValue}
      decimalsLimit={decimalsLimit}
      {...restProps}
    />
  ),
) as ForwardRefComponent<'input', PercentInputProps>
