import React, {useContext} from 'react'
import {ForwardRefComponent} from '@cheddarup/react-util'

import {PhosphorIcon} from '../icons'
import {Button, ButtonProps} from './Button'
import {DialogDisclosure, DialogInstance} from './Dialog'
import {VStack} from './Stack'
import {IconButton} from './IconButton'
import {Modal, ModalProps} from './Modal'
import {cn} from '../utils'

interface InternalSideNavContextValue extends DialogInstance {}

const InternalSideNavContext = React.createContext(
  {} as InternalSideNavContextValue,
)

export interface SideNavProps extends ModalProps {}

export const SideNav = React.forwardRef<DialogInstance, SideNavProps>(
  ({children: _children, ...restProps}, forwardedRef) => (
    <Modal
      ref={forwardedRef}
      initialVisible={false}
      contentViewAppearance="leftToRight"
      disclosure={
        <DialogDisclosure as={IconButton}>
          <PhosphorIcon icon="list" width={30} />
        </DialogDisclosure>
      }
      aria-label="Navigation"
      {...restProps}
    >
      {(dialog) => {
        const children =
          typeof _children === 'function' ? _children(dialog) : _children
        return (
          <InternalSideNavContext.Provider value={dialog}>
            <VStack className="overflow-y-auto">{children}</VStack>
          </InternalSideNavContext.Provider>
        )
      }}
    </Modal>
  ),
)

// MARK: – SideNavButton

export interface SideNavButtonProps extends ButtonProps {
  hideDialogOnClick?: boolean
}

export const SideNavButton = React.forwardRef(
  (
    {
      as: Comp = Button,
      hideDialogOnClick = true,
      className,
      variant = 'ghost',
      onClick,
      ...restProps
    },
    forwardedRef,
  ) => {
    const dialog = useContext(InternalSideNavContext)
    return (
      <Comp
        ref={forwardedRef}
        className={cn(
          'SideNavButton',
          'h-14 justify-start text-left',
          className,
        )}
        variant={variant}
        onClick={(event) => {
          onClick?.(event)
          if (hideDialogOnClick && !event.defaultPrevented) {
            dialog.hide()
          }
        }}
        {...restProps}
      />
    )
  },
) as ForwardRefComponent<'button', SideNavButtonProps>

// MARK: – SideNavBackButton

export interface SideNavBackButtonProps
  extends SideNavButtonProps,
    React.ComponentPropsWithoutRef<'button'> {}

export const SideNavBackButton = React.forwardRef<
  HTMLButtonElement,
  SideNavBackButtonProps
>(({className, onClick, children, ...restProps}, forwardedRef) => (
  <SideNavButton
    ref={forwardedRef}
    className={cn('SideNavBackButton', className)}
    {...restProps}
  >
    {children ?? '< Back'}
  </SideNavButton>
))
