import React, {useRef} from 'react'
import {useDateSegment} from 'react-aria'
import type {
  DateFieldState,
  DateSegment as DateSegmentType,
  TimeFieldState,
} from 'react-stately'
import {useForkRef} from '@cheddarup/react-util'

import {InputProps, input} from './Input'
import {HStack} from './Stack'
import {cn} from '../utils'

export interface DateSegmentProps
  extends React.ComponentPropsWithoutRef<'div'> {
  segment: DateSegmentType
  state: TimeFieldState | DateFieldState
}

export const DateSegment = React.forwardRef<HTMLDivElement, DateSegmentProps>(
  ({segment, state, className, style, ...restProps}, forwardedRef) => {
    const ownRef = useRef<HTMLDivElement>(null)
    const ref = useForkRef(ownRef, forwardedRef)
    const {segmentProps} = useDateSegment(segment, state, ownRef)

    return (
      <div
        ref={ref}
        data-placeholder={segment.isPlaceholder}
        // TODO: Ask Molly to provide a monospace font for this
        className={cn(
          'DateSegment',
          `relative box-content rounded px-0_5 text-right tabular-nums outline-none transition-colors duration-100 ease-in-out focus:bg-teal-90 focus:shadow-[inset_0_0_0_1px_theme(colors.teal.50)] hover:[&:not(:focus):not([aria-invalid="true"]):not(:disabled):not([aria-disabled="true"])]:bg-inputHoverBackground`,
          className,
        )}
        style={{
          minWidth:
            segment.maxValue == null
              ? undefined
              : `${String(segment.maxValue).length}ch`,
          ...style,
        }}
        {...segmentProps}
        {...restProps}
      >
        {segment.isPlaceholder && (
          <span
            aria-hidden
            className="DateSegment-placeholder block w-full text-center text-inputPlaceholder"
          >
            {segment.placeholder}
          </span>
        )}
        {segment.isPlaceholder ? '' : segment.text}
      </div>
    )
  },
)

// MARK: – DateSegmentBox

export interface DateSegmentBoxProps
  extends Pick<
      React.ComponentPropsWithoutRef<'input'>,
      'disabled' | 'readOnly' | 'required'
    >,
    Pick<InputProps, 'size' | 'variant' | 'disabledVariant'>,
    Omit<React.ComponentPropsWithoutRef<'div'>, 'placeholder'> {}

export const DateSegmentBox = React.forwardRef<
  HTMLDivElement,
  DateSegmentBoxProps
>(
  (
    {
      size = 'default',
      variant = 'default',
      disabledVariant = 'default',
      className,
      ...restProps
    },
    forwardedRef,
  ) => (
    <HStack
      ref={forwardedRef}
      className={cn(
        `DateSegmentBox DateSegmentBox--${variant}-${size}`,
        'relative inline-flex items-center *:flex-[0_1_auto]',
        input({variant, size, disabledVariant}),
        className,
      )}
      {...restProps}
    />
  ),
)
