import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import DynamicFormNamesModalPlaceholder from 'src/images/DynamicFormNamesModalPlaceholder.svg'
import DynamicWaiverNamesModalPlaceholder from 'src/images/DynamicWaiverNamesModalPlaceholder.svg'

interface DynamicFormNamesModalProps extends WebUI.ModalProps {
  formType?: 'waiver' | 'form'
}

const DynamicFormNamesModal = React.forwardRef<
  WebUI.DialogInstance,
  DynamicFormNamesModalProps
>(
  (
    {initialVisible = false, className, formType = 'form', ...restProps},
    forwardedRef,
  ) => (
    <WebUI.Modal
      ref={forwardedRef}
      aria-label="Dynamic Form Names Info"
      className={WebUI.cn(
        '[&_>_.ModalContentView]:max-w-2xl [&_>_.ModalContentView]:overflow-auto [&_>_.ModalContentView]:px-9 [&_>_.ModalContentView]:py-12',
        className,
      )}
      initialVisible={initialVisible}
      {...restProps}
    >
      <WebUI.ModalCloseButton />
      <div className="flex flex-col gap-7">
        <WebUI.Heading as="h2">
          Dynamic {formType === 'form' ? 'Form' : 'Waiver'} Names
        </WebUI.Heading>
        <WebUI.Text className="font-light">
          Individualize your dynamic {formType} names by including a payer’s
          response to a required item question.
        </WebUI.Text>
        <div className="bg-teal-90">
          <div className="flex flex-col gap-4 px-7 py-6">
            <WebUI.Text className="font-extrabold text-ds-sm">
              What does this look like?
            </WebUI.Text>
            <WebUI.Text className="font-light text-ds-sm">
              Let’s say you’ve created a {formType} and named it{' '}
              {formType === 'form' ? 'Camper Info' : 'Safety Waiver'}. If you
              select a required question asking for a participant’s name, each{' '}
              {formType} generated will add the participant’s name to the end of
              the {formType}’s name.
            </WebUI.Text>
          </div>
          <img
            src={
              formType === 'form'
                ? DynamicFormNamesModalPlaceholder
                : DynamicWaiverNamesModalPlaceholder
            }
            alt=""
            className="w-full"
          />
        </div>
      </div>
    </WebUI.Modal>
  ),
)

export default DynamicFormNamesModal
