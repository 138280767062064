import {AsyncResult, failure, initial, pending, success} from '@cheddarup/util'
import {useCallback, useRef, useState} from 'react'

import {useMountedRef} from './useMountedRef'

export function useAsyncCallback<T, TArgs extends [...any[]]>(
  fn: (...args: TArgs) => PromiseLike<T>,
  deps?: React.DependencyList,
  {
    initialResult = initial,
    allowConcurrent = false,
  }: {
    initialResult?: AsyncResult<Error, T>
    allowConcurrent?: boolean
  } = {},
) {
  const [result, setResult] = useState(initialResult)
  const mountedRef = useMountedRef()

  const activeInvocationCountRef = useRef(0)

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  const callback = useCallback(
    async (...args: TArgs): Promise<T> => {
      if (activeInvocationCountRef.current > 0 && !allowConcurrent) {
        throw new Error('Concurrent invocation not allowed')
      }

      setResult(pending)
      activeInvocationCountRef.current += 1

      try {
        const value = await fn(...args)
        setTimeout(() => {
          if (mountedRef.current) {
            if (activeInvocationCountRef.current === 1) {
              setResult(success(value))
            }
            activeInvocationCountRef.current -= 1
          }
        }, 0)

        return value
      } catch (error: any) {
        setTimeout(() => {
          if (mountedRef.current) {
            if (activeInvocationCountRef.current === 1) {
              setResult(failure(error))
            }
            activeInvocationCountRef.current -= 1
          }
        }, 0)

        throw error
      }
    },

    deps ?? [fn],
  )

  return [callback, result] as const
}
