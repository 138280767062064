import * as Yup from 'yup'
import {Formik, FormikProps} from 'formik'
import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import {useUpdateEffect} from '@cheddarup/react-util'
import {useMemo, useRef} from 'react'
import {
  api,
  usePurchaseTabPaymentShipmentMutation,
  useSelectShipmentIntentRateMutation,
} from '@cheddarup/api-client'
import {WithStripe} from 'src/components/WithStripe'
import EasypostAddressHelpers from 'src/helpers/EasypostAddressHelpers'
import PrintShippingHelpers, {
  PackageType,
} from 'src/helpers/PrintShippingHelpers'
import {useManagerRole} from 'src/components/ManageRoleProvider'

import {AddressValidationSchema} from '../../components/PrintShippingLabelShipAddress'
import {WithShippingIntent} from './WithShippingIntent'
import PrintShippingLabelFormContainer from './PrintShippingLabelFormContainer'

export interface PrintShippingLabelValues {
  shipTo: Api.PaymentShippingInfo['shipTo']
  shipFrom: Api.PaymentShippingInfo['shipTo']
  savedCardId?: string
  shipment: {
    serviceType: Api.PaymentShipmentService
    packageType: PackageType
    tracking: boolean
    signatureConfirmation: boolean
    packageWeightOz: number
    packageWeightLbs: number
  }
}

export type PrintShippingLabelFormik = FormikProps<PrintShippingLabelValues>

export interface PrintShippingLabelContainerProps {
  collectionId: number
  paymentId: number
  onRedirectToPrintShippingLabelSummaryPage: () => void
}

const PrintShippingLabelContainer = ({
  collectionId,
  paymentId,
  onRedirectToPrintShippingLabelSummaryPage,
}: PrintShippingLabelContainerProps) => {
  const formikRef = useRef<PrintShippingLabelFormik>(null)
  const formikValuesRef = useRef<PrintShippingLabelValues | null>(null)
  const {data: payment} = api.tabPayments.detail.useQuery({
    pathParams: {
      tabId: collectionId,
      paymentId,
    },
  })
  const {data: shipment} = api.shipments.detail.useQuery({
    pathParams: {
      tabId: collectionId,
      paymentId,
    },
  })
  const {data: paymentMethods} = api.paymentMethods.list.useQuery()
  const {data: user} = api.auth.session.useQuery(undefined, {
    select: (session) => session.user,
  })
  const [managerRole] = useManagerRole()
  const purchaseShipmentMutation = usePurchaseTabPaymentShipmentMutation()
  const selectShipmentIntentRateMutation = useSelectShipmentIntentRateMutation()
  const growlActions = WebUI.useGrowlActions()

  const paymentShipToAddress = useMemo(
    () =>
      payment?.shipping_info?.shipTo?.country === 'US'
        ? {
            ...Util.omit(payment.shipping_info.shipTo, ['address']),
            street1: payment.shipping_info.shipTo.address ?? '',
          }
        : {
            country: 'US',
            name: '',
            street1: '',
            city: '',
            state: 'AL',
            zip: '',
          },
    [payment?.shipping_info?.shipTo],
  )

  const initialShipToAddress = useMemo(
    () =>
      shipment?.shipTo
        ? EasypostAddressHelpers.getAddressFromEasypostAddress(shipment.shipTo)
        : paymentShipToAddress,
    [paymentShipToAddress, shipment?.shipTo],
  )

  const shipFromInitialValue = useMemo(() => {
    const returnAddress =
      managerRole?.profile.returnAddress ?? user?.profile.returnAddress

    if (!returnAddress) {
      return {
        country: 'US',
        name: '',
        street1: '',
        city: '',
        state: 'AL',
        zip: '',
      }
    }

    const userReturnAddress =
      EasypostAddressHelpers.getAddressFromEasypostAddress(returnAddress)
    const initialAddress =
      Object.keys(user?.business_address ?? {}).length === 0
        ? user?.personal_address
        : user?.business_address

    return userReturnAddress
      ? {
          ...userReturnAddress,
          country: 'US',
        }
      : {
          name: user?.display_name || '',
          street1: initialAddress?.line1 || '',
          zip: initialAddress?.postal_code || '',
          state: initialAddress?.state || '',
          city: initialAddress?.city || '',
          country: 'US',
        }
  }, [
    managerRole?.profile.returnAddress,
    user?.business_address,
    user?.display_name,
    user?.personal_address,
    user?.profile.returnAddress,
  ])

  useUpdateEffect(() => {
    formikRef.current?.setFieldValue('shipTo', initialShipToAddress)
  }, [initialShipToAddress])

  useUpdateEffect(() => {
    formikRef.current?.setFieldValue('shipFrom', shipFromInitialValue)
  }, [shipFromInitialValue])

  return (
    <WithShippingIntent
      collectionId={collectionId}
      paymentId={paymentId}
      shipmentExists={!!shipment}
    >
      {(intent) => (
        <WithStripe clientSecret={intent?.client_secret}>
          {({stripe, elements}) => (
            <Formik
              innerRef={formikRef}
              initialValues={
                // HACK: formik loses its state when intent is created
                formikValuesRef.current ?? {
                  shipTo: initialShipToAddress,
                  shipFrom: shipFromInitialValue,
                  shipment: {
                    serviceType: 'First',
                    packageType: 'Parcel',
                    tracking: true,
                    signatureConfirmation: false,
                    packageWeightOz: 0,
                    packageWeightLbs: 0,
                  },
                }
              }
              validationSchema={Yup.object().shape({
                shipTo: AddressValidationSchema,
                shipFrom: AddressValidationSchema,
              })}
              onSubmit={async (values) => {
                if (!shipment || !stripe || !elements || !intent) {
                  return
                }
                const selectedRate =
                  PrintShippingHelpers.getSelectedShipmentRate({
                    rates: shipment.rates ?? [],
                    service: values.shipment.serviceType,
                  })
                if (!selectedRate) {
                  return
                }

                await selectShipmentIntentRateMutation.mutateAsync({
                  pathParams: {
                    tabId: collectionId,
                    paymentId,
                  },
                  body: {
                    selected_rate_id: selectedRate.id,
                    payment_intent_id: intent.payment_intent_id,
                  },
                })
                try {
                  if (values.savedCardId) {
                    const confirmSetupRes = await stripe.confirmCardPayment(
                      intent.client_secret,
                      {
                        payment_method: values.savedCardId,
                      },
                    )
                    if (confirmSetupRes.error) {
                      throw new Error(confirmSetupRes.error.message)
                    }
                  } else {
                    const confirmSetupRes = await stripe.confirmPayment({
                      elements,
                      confirmParams: {
                        return_url: window.location.href,
                      },
                      redirect: 'if_required',
                    })
                    if (confirmSetupRes.error) {
                      throw new Error(confirmSetupRes.error.message)
                    }
                  }

                  await purchaseShipmentMutation.mutateAsync({
                    pathParams: {
                      tabId: collectionId,
                      paymentId,
                    },
                    body: {
                      selected_rate_id: selectedRate.id,
                      payment_intent_id: intent.payment_intent_id,
                    },
                  })

                  onRedirectToPrintShippingLabelSummaryPage()
                } catch (err: any) {
                  growlActions.clear()
                  growlActions.show('error', {
                    title: 'Could not purchase shipping label',
                    body: err.data?.errors?.[0]?.details ?? err.message,
                  })
                }
              }}
            >
              {(formik) => {
                const selectedRate = shipment?.rates
                  ? PrintShippingHelpers.getSelectedShipmentRate({
                      rates: shipment.rates,
                      service: formik.values.shipment.serviceType,
                    })
                  : null

                formikValuesRef.current = formik.values

                return (
                  <WebUI.Form>
                    <PrintShippingLabelFormContainer
                      stripeReady={!!stripe}
                      formik={formik}
                      collectionId={collectionId}
                      paymentId={paymentId}
                      selectedRate={selectedRate ?? null}
                      paymentTabMember={payment?.tab_member ?? null}
                      creditCards={paymentMethods?.cards ?? []}
                    />
                  </WebUI.Form>
                )
              }}
            </Formik>
          )}
        </WithStripe>
      )}
    </WithShippingIntent>
  )
}

export default PrintShippingLabelContainer
