import {endpoints} from '../../endpoints'
import {getEndpointKey} from '../../utils'
import {makeQueryUpdate, makeUseMutation} from '../use-mutation'

export const useModifyFieldViewsMutation = makeUseMutation(
  endpoints.fieldViews.modify,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(
        endpoints.tickets.list,
        (prevTickets) =>
          prevTickets?.map((prevTicket) =>
            prevTicket.id === vars.body?.paymentItemId
              ? {
                  ...prevTicket,
                  item_field_views: prevTicket.item_field_views.map((ifv) => {
                    const correspondingIfv = vars.body?.fields[ifv.id]
                    return correspondingIfv
                      ? {...ifv, value: correspondingIfv.value as any}
                      : ifv
                  }),
                }
              : prevTicket,
          ),
        vars,
      ),
      makeQueryUpdate(
        endpoints.tickets.detail,
        (prevTicket) =>
          prevTicket
            ? {
                ...prevTicket,
                item_field_views: prevTicket.item_field_views.map((ifv) => {
                  const correspondingIfv = vars.body?.fields[ifv.id]
                  return correspondingIfv
                    ? {...ifv, value: correspondingIfv.value as any}
                    : ifv
                }),
              }
            : prevTicket,
        {
          pathParams: {
            // biome-ignore lint/style/noNonNullAssertion:
            ticketId: vars.body!.paymentItemId,
          },
        },
      ),
      makeQueryUpdate(
        endpoints.tabPayments.detail,
        (prevTabPayment) =>
          prevTabPayment
            ? {
                ...prevTabPayment,
                payment_items: prevTabPayment.payment_items.map((pi) =>
                  pi.id === vars.body?.paymentItemId
                    ? {
                        ...pi,
                        item_field_views: pi.item_field_views.map((ifv) => {
                          const correspondingIfv = vars.body?.fields[ifv.id]
                          return correspondingIfv
                            ? {...ifv, value: correspondingIfv.value as any}
                            : ifv
                        }),
                      }
                    : pi,
                ),
              }
            : prevTabPayment,
        vars,
      ),
      makeQueryUpdate(
        endpoints.payments.detail,
        (prevPayment) =>
          prevPayment
            ? {
                ...prevPayment,
                payment_items: prevPayment.payment_items.map((pi) =>
                  pi.id === vars.body?.paymentItemId
                    ? {
                        ...pi,
                        item_field_views: pi.item_field_views.map((ifv) => {
                          const correspondingIfv = vars.body?.fields[ifv.id]
                          return correspondingIfv
                            ? {...ifv, value: correspondingIfv.value as any}
                            : ifv
                        }),
                      }
                    : pi,
                ),
              }
            : prevPayment,
        vars,
      ),
    ],
  }),
  (queryClient) => ({
    onSuccess: (_newFieldValue, vars) => {
      if (vars.body?.tabObjectId != null) {
        const itemReportQueryKey = getEndpointKey(endpoints.tabItems.report, {
          pathParams: {
            tabId: vars.pathParams.tabId,
            itemId: vars.body.tabObjectId,
          },
        })

        queryClient.invalidateQueries({queryKey: itemReportQueryKey})
      }
    },
  }),
)
