import React, {
  createContext,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'

import type {ItemPickerInstance} from './PayerPage/components/ItemPicker/ItemPicker'
import type {FormListInstance} from './PayerFormsPage'

interface PayerUIStateContextValue {
  shouldRenderModalCloseButton: boolean
  cartVisible: boolean
  setCartVisible: React.Dispatch<React.SetStateAction<boolean>>
  noItemsInCartAlertVisible: boolean
  setNoItemsInCartAlertVisible: React.Dispatch<React.SetStateAction<boolean>>
  footerInView: boolean
  setFooterInView: React.Dispatch<React.SetStateAction<boolean>>
  itemPickerRef: React.RefObject<ItemPickerInstance>
  formPickerRef: React.RefObject<FormListInstance>
}

const PayerUIStateContext = createContext({} as PayerUIStateContextValue)

export interface PayerUIStateProviderProps {
  shouldRenderModalCloseButton?: boolean
  initialCartVisible?: boolean
  children: React.ReactNode
}

export const PayerUIStateProvider: React.FC<PayerUIStateProviderProps> = ({
  shouldRenderModalCloseButton = false,
  initialCartVisible = false,
  children,
}) => {
  const [cartVisible, setCartVisible] = useState(initialCartVisible)
  const [footerInView, setFooterInView] = useState(false)
  const [noItemsInCartAlertVisible, setNoItemsInCartAlertVisible] =
    useState(false)
  const itemPickerRef = useRef<ItemPickerInstance>(null)
  const formPickerRef = useRef<FormListInstance>(null)

  const value: PayerUIStateContextValue = useMemo(
    () => ({
      shouldRenderModalCloseButton,
      cartVisible,
      setCartVisible,
      footerInView,
      setFooterInView,
      noItemsInCartAlertVisible,
      setNoItemsInCartAlertVisible,
      itemPickerRef,
      formPickerRef,
    }),
    [
      shouldRenderModalCloseButton,
      cartVisible,
      footerInView,
      noItemsInCartAlertVisible,
    ],
  )

  return (
    <PayerUIStateContext.Provider value={value}>
      {children}
    </PayerUIStateContext.Provider>
  )
}

export const usePayerUIState = () => useContext(PayerUIStateContext)
