import {formatAmount} from '@cheddarup/util'

export function formatQuantityDiscount(
  quantityDiscount: Api.TabItemQuantityDiscount,
) {
  const offValue = {
    fixed: formatAmount(quantityDiscount.fixedAmount ?? 0),
    percentage: `${quantityDiscount.percentage ?? 0}%`,
  }[quantityDiscount.calculationMethod]

  return `Buy ${quantityDiscount.minimumQuantity} get ${offValue} off!`
}
