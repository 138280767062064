import * as Yup from 'yup'
import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api, useUpdateUserMutation} from '@cheddarup/api-client'
import {useFormik} from '@cheddarup/react-util'
import {
  AccountSettingsContentCard,
  AccountSettingsContentLayout,
} from './components/AccountSettingsContentLayouts'
import {
  STATEMENT_DESCRIPTOR_MAX_LEN,
  STATEMENT_DESCRIPTOR_PREFIX,
} from 'src/helpers/user-helpers'
import {guessError} from 'src/helpers/error-utils'

const StatementDescriptorPage = () => {
  return (
    <AccountSettingsContentLayout
      heading="Statement Descriptor"
      body={
        <WebUI.VStack className="gap-3">
          <WebUI.Text className="font-light">
            It’s important to customize what payers see on their credit card
            statements so you can avoid disputes. Choose a statement descriptor
            that your community will easily recognize.
          </WebUI.Text>
          <WebUI.Anchor
            className="text-ds-sm"
            href="https://support.cheddarup.com/hc/en-us/articles/360035587011-Prevent-and-manage-disputes"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more about disputes
          </WebUI.Anchor>
        </WebUI.VStack>
      }
    >
      <StatementDescriptorForm />
    </AccountSettingsContentLayout>
  )
}

// MARK: – StatementDescriptorForm

const StatementDescriptorForm = (
  props: React.ComponentPropsWithoutRef<'form'>,
) => {
  const {data: session} = api.auth.session.useQuery()
  const updateUserMutation = useUpdateUserMutation()
  const growlActions = WebUI.useGrowlActions()

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      statement_descriptor: Yup.string()
        .max(
          STATEMENT_DESCRIPTOR_MAX_LEN,
          `Statement descriptor must be ${STATEMENT_DESCRIPTOR_MAX_LEN} characters or less.`,
        )
        .matches(/^[\sA-Za-z]+$/, 'Credit Card Statement Descriptor is Invalid')
        .required('Required')
        .trim(),
    }),
    initialValues: {
      statement_descriptor: (
        session?.user.profile.uiClientFlags.customCardDescriptor ||
        session?.user.display_name ||
        ''
      ).slice(0, STATEMENT_DESCRIPTOR_MAX_LEN),
    },
    onSubmit: async (values) => {
      try {
        await updateUserMutation.mutateAsync({
          body: {
            profile: {
              uiClientFlags: {
                customCardDescriptor: values.statement_descriptor.trim(),
                onboardingChecklist: {
                  statementDescriptorComplete:
                    values.statement_descriptor.length > 0 &&
                    values.statement_descriptor !== session?.user.display_name,
                },
              },
            },
          },
        })
        growlActions.show('success', {
          title: 'Success!',
          body: 'Statement Descriptor is successfully updated.',
        })
      } catch (err: any) {
        growlActions.show('error', {
          title: 'Error',
          body: guessError(err).message,
        })
      }
    },
  })

  return (
    <AccountSettingsContentCard
      as="form"
      onReset={formik.handleReset}
      onSubmit={formik.handleSubmit}
      {...props}
    >
      <WebUI.FormField
        className={formik.dirty ? '[&_>_.FormField-caption]:block' : ''}
        label="Credit Card Statement Descriptor"
        caption={`Your payers will see ${STATEMENT_DESCRIPTOR_PREFIX} followed by your descriptor which is limited to ${STATEMENT_DESCRIPTOR_MAX_LEN} characters. Numbers and special characters are not allowed.`}
        required
        error={formik.errors.statement_descriptor}
      >
        <WebUI.Input
          name="statement_descriptor"
          placeholder="Credit Card Statement Descriptor"
          value={`${STATEMENT_DESCRIPTOR_PREFIX} ${formik.values.statement_descriptor}`}
          onChange={(event) => {
            const newDescriptor = event.target.value.slice(
              STATEMENT_DESCRIPTOR_PREFIX.length + 1,
            )
            formik.setFieldValue('statement_descriptor', newDescriptor)
          }}
          onBlur={formik.handleBlur}
        />
      </WebUI.FormField>

      <WebUI.Disclosure visible={formik.dirty || formik.isSubmitting}>
        <WebUI.DisclosureContent>
          <WebUI.Button type="submit" loading={formik.isSubmitting}>
            Save
          </WebUI.Button>
        </WebUI.DisclosureContent>
      </WebUI.Disclosure>
    </AccountSettingsContentCard>
  )
}

export default StatementDescriptorPage
