import {useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'

import {EntityLabel} from './EntityTypeForm'

export interface CustomerCategoryFormValues {
  customerCategory: Api.UserCustomerCategory | ''
}

interface CustomerCategoryFormProps
  extends Omit<React.ComponentPropsWithoutRef<'form'>, 'onSubmit'> {
  initialValues: CustomerCategoryFormValues
  onSubmit: (values: CustomerCategoryFormValues) => void
}

const CUSTOMER_CATEGORIS = [
  {
    label: 'School',
    text: 'PTAs, parent clubs, and class funds',
    value: 'SCHOOL',
  },
  {label: 'Sports or Cheer Team', text: 'Teams and boosters', value: 'SPORT'},
  {label: 'Scouts', text: 'Scouting organizations and troops', value: 'SCOUT'},
  {
    label: 'Music or Fine Arts',
    text: 'Band, choir, theater, and dance',
    value: 'FINE ARTS',
  },
  {
    label: 'Club',
    text: 'Greek life, social, and professional organizations',
    value: 'CLUB',
  },
  {
    label: 'Non-Profit',
    text: 'Civic organizations, HOAs, and other 501(c)(3)s',
    value: 'NON-PROFIT',
  },
  {label: 'Faith-based', text: 'Congregations and groups', value: 'RELIGIOUS'},
  {
    label: 'Friend or Family',
    text: 'Group gifts, reunions, and personal fundraisers',
    value: 'FRIENDS',
  },
  {
    label: 'Business',
    text: 'Direct sellers and small businesses',
    value: 'BUSINESS',
  },
  // { label: 'Myself', text: '', value: 'PERSONAL' }, // not in mocs
  {label: 'None of the Above', text: '', value: 'NONE_OF_THE_ABOVE'},
]

const CustomerCategoryForm = ({
  initialValues,
  onSubmit,
  ...restProps
}: CustomerCategoryFormProps) => {
  const [customerCategory, setCustomerCategory] = useState<
    CustomerCategoryFormValues['customerCategory']
  >(initialValues.customerCategory)

  return (
    <WebUI.Form
      className="[&_>_.Form-inner]:gap-7"
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit({customerCategory})
      }}
      {...restProps}
    >
      <WebUI.Text className="font-black text-ds-lg">
        What best describes your group?
      </WebUI.Text>
      <WebUI.ToggleGroup
        className="flex-wrap gap-6"
        value={customerCategory}
        onValueChange={(value) =>
          setCustomerCategory(value as Api.UserCustomerCategory)
        }
      >
        {CUSTOMER_CATEGORIS.map((cat, idx) => (
          <WebUI.ToggleGroupItem
            className="w-full sm:w-[46%]"
            key={idx}
            {...cat}
            as={EntityLabel}
          />
        ))}
      </WebUI.ToggleGroup>
      <WebUI.RoundedButton
        className="w-[180px]"
        type="submit"
        disabled={!customerCategory}
      >
        Continue
      </WebUI.RoundedButton>
    </WebUI.Form>
  )
}

export default CustomerCategoryForm
