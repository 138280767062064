import {useState} from 'react'
import {MoizeOptions, moize} from '@cheddarup/util'

export function useMoize<
  TArgs extends [any, ...any[]],
  TReturn,
  TOptions extends MoizeOptions,
>(fn: (...args: TArgs) => TReturn, args: TArgs, options?: TOptions) {
  const [moizedFn] = useState(() => (options ? moize(fn, options) : moize(fn)))
  return moizedFn(...args)
}
