import * as Yup from 'yup'
import {Field} from 'formik'
import React, {useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import provinces from 'src/data/provinces.json'

import type {PrintShippingLabelValues} from '../containers/PrintShippingLabelContainer/PrintShippingLabelContainer'

export interface PrintShippingLabelShipAddressProps
  extends Omit<PrintShippingLabelShipAddressFormProps, 'setMode'> {
  className?: string
}

const PrintShippingLabelShipAddress = ({
  className,
  ...restProps
}: PrintShippingLabelShipAddressProps) => {
  const [mode, setMode] = useState('view')

  return (
    <WebUI.Panel className={WebUI.cn('p-4', className)}>
      {mode === 'edit' ? (
        <PrintShippingLabelShipAddressForm {...restProps} setMode={setMode} />
      ) : (
        <PrintShippingLabelShipAddressView {...restProps} setMode={setMode} />
      )}
    </WebUI.Panel>
  )
}

// MARK: – PrintShippingLabelShipAddressForm

export const AddressValidationSchema = Yup.object().shape({
  country: Yup.string()
    .matches(/US|CA/, 'Must be either US or CA')
    .required('Required'),
  name: Yup.string().required('Required'),
  street1: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  zip: Yup.string().required('Required'),
})

interface PrintShippingLabelShipAddressFormProps {
  namePrefix: 'shipTo' | 'shipFrom'
  loading?: boolean
  title: React.ReactNode
  values: PrintShippingLabelValues
  isSubmitting?: boolean
  onCheck: () => void
  setMode: (mode: 'view' | 'edit') => void
}

const PrintShippingLabelShipAddressForm = ({
  namePrefix,
  loading,
  title,
  values: allValues,
  isSubmitting,
  onCheck,
  setMode,
}: PrintShippingLabelShipAddressFormProps) => {
  const values = allValues[namePrefix]
  return (
    <WebUI.VStack className="gap-4">
      <div className="font-normal text-ds-md">{title}</div>
      <WebUI.FormField label={`${title}'s full name`}>
        <Field
          name={`${namePrefix}.name`}
          as={WebUI.Input}
          size="compact"
          placeholder="First and last name"
        />
      </WebUI.FormField>
      <WebUI.FormField label="Address number and street">
        <Field
          name={`${namePrefix}.street1`}
          as={WebUI.Input}
          size="compact"
          placeholder="Address"
        />
      </WebUI.FormField>

      <WebUI.FormFieldGroup>
        <WebUI.FormField label="City">
          <Field
            name={`${namePrefix}.city`}
            as={WebUI.Input}
            size="compact"
            placeholder="City"
          />
        </WebUI.FormField>
        <WebUI.FormField label={values.country === 'CA' ? 'Province' : 'State'}>
          <Field name={`${namePrefix}.state`}>
            {({field}: any) => (
              <WebUI.Select
                className="[&_>_.Select-select]:min-[width:unset] min-[width:unset]"
                size="compact"
                {...field}
              >
                <option value="">
                  {values.country === 'CA' ? 'Province' : 'State'}
                </option>
                {provinces[values.country === 'CA' ? 'CA' : 'US'].map(
                  ({name, short}) => (
                    <option key={short} value={short}>
                      {name}
                    </option>
                  ),
                )}
              </WebUI.Select>
            )}
          </Field>
        </WebUI.FormField>
        <WebUI.FormField
          label={values.country === 'CA' ? 'Postal code' : 'Zip code'}
        >
          <Field
            name={`${namePrefix}.zip`}
            as={WebUI.Input}
            size="compact"
            placeholder={values.country === 'CA' ? 'Postal code' : 'Zip code'}
          />
        </WebUI.FormField>
      </WebUI.FormFieldGroup>
      <WebUI.HStack className="items-center gap-3">
        <WebUI.Button
          loading={loading || isSubmitting}
          onClick={() => setMode('view')}
        >
          Save
        </WebUI.Button>
        <WebUI.Button
          className="text-ds-sm"
          variant="link"
          disabled={loading || isSubmitting}
          onClick={onCheck}
        >
          Perform address check
        </WebUI.Button>
      </WebUI.HStack>
    </WebUI.VStack>
  )
}

// MARK: – PrintShippingLabelShipAddressView

interface PrintShippingLabelShipAddressViewProps {
  namePrefix: 'shipTo' | 'shipFrom'
  title: React.ReactNode
  values: PrintShippingLabelValues
  setMode: (mode: 'edit') => void
}

const PrintShippingLabelShipAddressView = ({
  namePrefix,
  title,
  values: allValues,
  setMode,
}: PrintShippingLabelShipAddressViewProps) => {
  const values = allValues[namePrefix]
  return (
    <WebUI.VStack className="gap-4">
      <WebUI.HStack className="justify-between gap-3">
        <span className="font-normal text-ds-md">{title}</span>
        <WebUI.Button
          className="text-ds-sm"
          variant="link"
          onClick={() => setMode('edit')}
        >
          Edit
        </WebUI.Button>
      </WebUI.HStack>
      <WebUI.VStack className="text-ds-sm">
        <div>{values.name}</div>
        <div>{values.street1}</div>
        <div>
          {values.city}, {values.state} {values.zip}
        </div>
        <div>{values.country}</div>
      </WebUI.VStack>
    </WebUI.VStack>
  )
}

export default PrintShippingLabelShipAddress
