import Saturation, {SaturationProps} from '@uiw/react-color-saturation'
import {hexToHsva, HsvaColor, hsvaToHex} from '@uiw/color-convert'
import React from 'react'
import {ColorThumb} from './ColorThumb'
import {toHex} from '@cheddarup/util'
import {useControlledState} from '@cheddarup/react-util'
import {useColorPicker} from './ColorPicker'
import {cn} from '../../utils'

export interface ColorSaturationAreaProps
  extends Omit<
    SaturationProps,
    'hue' | 'hsva' | 'prefixCls' | 'pointer' | 'onChange'
  > {
  defaultValue?: string
  value?: string
  onValueChange?: (hex: string | null) => void
  thumb?: SaturationProps['pointer']
}

export const ColorSaturationArea = React.forwardRef<
  HTMLDivElement,
  ColorSaturationAreaProps
>(
  (
    {defaultValue, value: valueProp, onValueChange, thumb, ...restProps},
    forwardedRef,
  ) => {
    const colorPicker = useColorPicker()

    const value = valueProp ?? colorPicker?.value

    const valueAsHex = toHex(value ?? '')
    const defaultValueAsHex = toHex(defaultValue ?? '')

    const initialValue = valueAsHex ? hexToHsva(valueAsHex) : undefined
    const initialDefaultValue = defaultValueAsHex
      ? hexToHsva(defaultValueAsHex)
      : undefined
    const [colorValue, setColorValue] = useControlledState<HsvaColor | null>(
      initialValue,
      initialDefaultValue ?? null,
      (newValue) => {
        if (newValue != null) {
          colorPicker?.setValue(hsvaToHex(newValue))
          onValueChange?.(hsvaToHex(newValue))
        }
      },
    )

    return (
      <Saturation
        ref={forwardedRef}
        pointer={
          thumb ??
          (({prefixCls, left, top, color}) => (
            <ColorThumb
              className={cn('absolute', prefixCls)}
              style={{top, left}}
              color={color}
            />
          ))
        }
        hsva={colorValue ?? undefined}
        onChange={setColorValue}
        {...restProps}
      />
    )
  },
)
