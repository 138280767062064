import {PathMatch, matchPath, useHref, useLocation} from 'react-router-dom'
import React, {useContext, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {ForwardRefComponent} from '@cheddarup/react-util'

import {Link, LinkProps} from './Link'

export interface RoutableTabsProps extends WebUI.TabsProps {
  // Should be `true` if should match the entire URL pathname
  end?: boolean
}

export const RoutableTabs = React.forwardRef<
  WebUI.TabsInstance,
  RoutableTabsProps
>(({end = false, manual = true, ...restProps}, forwardedRef) => {
  const location = useLocation()
  const [tabsItems, setTabsItems] = useState<WebUI.CompositeItem[]>([])

  let selectedId = location.pathname
  if (!end) {
    const match = tabsItems
      .map((i) => i.id)
      .filter((tabId) => tabId !== null)
      .map((tabId) => matchPath({path: tabId, end: false}, location.pathname))
      .find((m): m is PathMatch<string> => m != null)

    if (match && match.pathname !== selectedId) {
      selectedId = match.pathname
    }
  }

  return (
    <WebUI.Tabs
      ref={(tabsInstance) => {
        if (typeof forwardedRef === 'function') {
          forwardedRef(tabsInstance)
        } else if (forwardedRef) {
          forwardedRef.current = tabsInstance
        }

        if (tabsInstance) {
          setTabsItems(tabsInstance.items)
        }
      }}
      manual={manual}
      selectedId={selectedId}
      currentId={selectedId}
      {...restProps}
    />
  )
})

// MARK: – RoutableTab

export interface RoutableTabProps
  extends Omit<WebUI.TabProps, 'id'>,
    Omit<LinkProps, 'id'> {}

export const RoutableTab = React.forwardRef<
  HTMLAnchorElement,
  RoutableTabProps
>(({className, to, variant = 'default', ...restProps}, forwardedRef) => {
  const resolvedTo = useHref(to)
  return (
    <WebUI.Tab
      ref={forwardedRef}
      className={WebUI.cn('RoutableTab', className)}
      id={resolvedTo.split('?')[0]}
      as={Link}
      variant={variant}
      to={to}
      {...restProps}
    />
  )
})

// MARK: – RoutableTabPanel

export interface RoutableTabPanelProps
  extends Omit<WebUI.TabPanelProps, 'tabId'> {}

export const RoutableTabPanel = React.forwardRef(
  ({className, ...restProps}, forwardedRef) => {
    const contextValue = useContext(WebUI.InternalTabsContext)

    return (
      <WebUI.TabPanel
        ref={forwardedRef}
        className={WebUI.cn('RoutableTabPanel', className)}
        tabId={contextValue.selectedId ?? undefined}
        {...restProps}
      />
    )
  },
) as ForwardRefComponent<'div', RoutableTabPanelProps>
