import * as WebUI from '@cheddarup/web-ui'
import * as Yup from 'yup'

const regexMap = {
  email:
    /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/,
}

export const extendYup = () => {
  Yup.addMethod(Yup.string, 'email', function validateEmail(message) {
    return this.matches(regexMap.email, {
      message,
      name: 'email',
      excludeEmptyString: true,
    })
  })

  Yup.addMethod(
    Yup.string,
    'possiblePhoneNumber',
    function validatePhoneNumber(message) {
      return this.test('possiblePhoneNumber', message, (value = '') => {
        if (!WebUI.isPossiblePhoneNumber(value)) {
          return false
        }
        return true
      })
    },
  )
}

declare module 'yup' {
  export interface StringSchema {
    possiblePhoneNumber(errorMessage?: string): StringSchema
  }
}
