import React from 'react'
import * as WebUI from '@cheddarup/web-ui'

import {FieldGroupCard, FieldGroupCardProps} from './FieldGroupCard'

export interface FieldGroupEditProps
  extends FieldGroupCardProps,
    React.ComponentPropsWithoutRef<'div'> {
  focused?: boolean
  required?: boolean
  onRequiredChange?: (required: boolean) => void
  children?: React.ReactNode
}

export const FieldGroupEdit = React.forwardRef<
  HTMLDivElement,
  FieldGroupEditProps
>(
  (
    {
      focused = false,
      required = false,
      onRequiredChange,
      className,
      children,
      ...restProps
    },
    forwardedRef,
  ) => (
    <FieldGroupCard
      ref={forwardedRef}
      className={WebUI.cn(
        'FieldGroupEdit',
        'gap-4 p-6 [&_>_.FieldGroupEdit-footerControls_>_*:not(.Separator)]:ml-4',
        className,
      )}
      tabIndex={0}
      as={WebUI.VStack}
      {...restProps}
    >
      <WebUI.VStack className="FieldGroupEdit-fieldsContainer gap-4">
        {children}
      </WebUI.VStack>

      <WebUI.Disclosure visible={focused}>
        <WebUI.DisclosureContent>
          <WebUI.VStack className="FieldGroupEdit-footerControls gap-4">
            <WebUI.Separator variant="primary" />
            <WebUI.HStack className="justify-end">
              <WebUI.Switch
                size="compact"
                state={required}
                onChange={(event) => onRequiredChange?.(event.target.checked)}
              >
                Required
              </WebUI.Switch>
            </WebUI.HStack>
          </WebUI.VStack>
        </WebUI.DisclosureContent>
      </WebUI.Disclosure>
    </FieldGroupCard>
  ),
)
