import React from 'react'

import {RichTextEditor, RichTextEditorInstance} from './Plate'
import {cn} from '../utils'

export interface MarkdownPreviewProps
  extends React.ComponentPropsWithoutRef<'div'> {
  markdown: string
}

export const MarkdownPreview = React.forwardRef<
  RichTextEditorInstance,
  MarkdownPreviewProps
>(({markdown, className, ...restProps}, forwardedRef) => (
  <RichTextEditor
    ref={forwardedRef}
    className={cn(
      'shadow-none',
      '[&_>_.RichTextEditor]:!bg-inherit',
      {
        boxShadow: 'none',
        '& > .RichTextEditor': {
          backgroundColor: 'inherit !important',
        },
      },
      className,
    )}
    readOnly
    initialMarkdownValue={markdown}
    {...restProps}
  />
))
