import {authTokenSchema} from '../schemas'

export interface CookedAuthToken<
  T extends Api.AuthTokenType = Api.AuthTokenType,
> extends Partial<Api.AuthToken> {
  tokenType: T
  token: string
  expiresAt: string
}

export function isAuthTokenValid(authToken: CookedAuthToken) {
  return new Date().toISOString() < authToken.expiresAt
}

export function decodeAuthToken(jwt: string): Api.AuthToken {
  const [headerEncoded, payloadEncoded] = jwt.split('.')

  if (headerEncoded == null || payloadEncoded == null) {
    throw new Error('Invalid JWT')
  }

  const headerDecoded = JSON.parse(atob(base64UrlToBase64(headerEncoded)))
  const headerTyped = authTokenSchema.shape.header.safeParse(headerDecoded)

  const payloadDecoded = JSON.parse(atob(base64UrlToBase64(payloadEncoded)))
  const payloadTyped = authTokenSchema.shape.claims.safeParse(payloadDecoded)

  return {
    header: headerTyped.success ? headerTyped.data : headerDecoded,
    claims: payloadTyped.success ? payloadTyped.data : payloadDecoded,
    expires_at: new Date(
      (payloadTyped.success ? payloadTyped.data : (payloadDecoded as any)).exp *
        1000,
    ).toISOString(),
  }
}

// MARK: – Helpers

function base64UrlToBase64(input: string) {
  let base64 = input.replace(/-/g, '+').replace(/_/g, '/')

  const pad = base64.length % 4
  if (pad !== 0) {
    if (pad === 1) {
      throw new Error(
        'InvalidLengthError: Input base64url string is the wrong length to determine padding',
      )
    }

    base64 += Array.from({length: 5 - pad}).join('=')
  }

  return base64
}
