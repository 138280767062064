import {useMemo} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'
import {useCubeQuery} from 'src/hooks/cube'

interface AnalyticChartProps extends React.ComponentPropsWithoutRef<'div'> {
  targetRange: [string, string]
  baseRange: [string, string]
  measure: string
  dimension: string
  title: string
  isAmount?: boolean
}

export const AnalyticChart: React.FC<AnalyticChartProps> = ({
  targetRange,
  baseRange,
  measure,
  dimension,
  title,
  isAmount,
  ...restProps
}) => {
  const {resultSet: targetResultSet} = useCubeQuery({
    measures: [measure],
    timeDimensions: [{dimension, dateRange: targetRange}],
  })
  const {resultSet: baseResultSet} = useCubeQuery({
    measures: [measure],
    timeDimensions: [{dimension, dateRange: baseRange}],
  })
  const targetChartQuery = useCubeQuery({
    measures: [measure],
    timeDimensions: [{dimension, dateRange: targetRange, granularity: 'day'}],
  })
  const baseChartQuery = useCubeQuery({
    measures: [measure],
    timeDimensions: [{dimension, dateRange: baseRange, granularity: 'day'}],
  })
  const targetValue = targetResultSet?.rawData()?.[0]?.[measure] ?? 0
  const baseValue = baseResultSet?.rawData()?.[0]?.[measure] ?? 0

  const chartSeries: Array<WebUI.UserSerie<WebUI.ChartDatum>> = useMemo(() => {
    if (targetChartQuery.isLoading || baseChartQuery.isLoading) {
      return []
    }

    const targetChartSeriesData =
      targetChartQuery.resultSet?.chartPivot().map((datum) => ({
        primary: new Date(datum.x),
        secondary: datum[measure] ?? 0,
      })) ?? []

    return [
      {
        label: 'Current',
        data: targetChartSeriesData,
      },
      {
        label: 'Previous',
        color: 'red',
        data:
          baseChartQuery.resultSet?.chartPivot().map((datum, idx) => ({
            // biome-ignore lint/style/noNonNullAssertion:
            primary: targetChartSeriesData[idx]?.primary!,
            secondary: datum[measure] ?? 0,
          })) ?? [],
      },
    ]
  }, [
    baseChartQuery.isLoading,
    baseChartQuery.resultSet,
    measure,
    targetChartQuery.isLoading,
    targetChartQuery.resultSet,
  ])

  return (
    <div {...restProps}>
      <WebUI.VStack className="p-2 *:flex-[1_0_50%] sm:flex-row">
        <WebUI.VStack className="gap-3 font-semibold">
          <div className="text-ds-md">{title}</div>
          <WebUI.HStack className="max-w-[480px] items-baseline gap-4 text-5xl *:flex-[1_0_33.3%]">
            <div>
              {isAmount ? Util.formatAmount(Number(targetValue)) : targetValue}
            </div>
            {getRelativePercentage(Number(baseValue), Number(targetValue))}
            <div className="text-gray400">
              {isAmount ? Util.formatAmount(Number(baseValue)) : baseValue}
            </div>
          </WebUI.HStack>
        </WebUI.VStack>
        <div className="relative">
          <WebUI.Chart options={{data: chartSeries}} />
        </div>
      </WebUI.VStack>
    </div>
  )
}

// MARK: – Helpers

const getRelativePercentage = (previous: number, current: number) => {
  const diff = (current / previous) * 100 - 100

  if (
    current === 0 ||
    previous === 0 ||
    Number.isNaN(current) ||
    Number.isNaN(previous) ||
    Number.isNaN(diff) ||
    current === previous
  ) {
    return <div className="text-teal-50">0%</div>
  }
  return (
    <div className={diff >= 0 ? 'text-teal-50' : 'text-orange-50'}>{`${
      diff >= 0 ? '+' : ''
    }${diff.toFixed(0)}%`}</div>
  )
}
