import {useMemo} from 'react'
import {
  api,
  endpoints,
  getEndpointKey,
  useQueryClient,
} from '@cheddarup/api-client'
import {useParams} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'

const usePublicCollection = () => {
  const urlParams = useParams()
  const queryClient = useQueryClient()

  const collectionSlug = urlParams.tabSlug as string
  const [updatePublicTabPayload, setUpdatePublicTabPayload] =
    WebUI.useSessionStorage<{
      name?: string
      email?: string
      access_code?: string
    } | null>(`COLLECTION_PAYLOAD_${collectionSlug}`, null)
  const {
    data: publicCollection,
    isPending,
    refetch,
  } = api.publicTabs.detail.useQuery(
    {
      pathParams: {
        tabId: collectionSlug,
      },
      queryParams: updatePublicTabPayload ?? undefined,
    },
    {
      enabled: !!collectionSlug,
      refetchOnMount: (query) =>
        query.state.data?.userManagesCollection ? 'always' : true,
    },
  )

  const value = useMemo(
    () => ({
      // biome-ignore lint/style/noNonNullAssertion:
      publicCollection: (publicCollection
        ? {
            ...publicCollection,
            categories: publicCollection?.categories ?? [],
            items: publicCollection?.items ?? [],
            forms: publicCollection?.forms ?? [],
            signups: publicCollection?.signups ?? [],
          }
        : publicCollection)!,
      refetchPublicCollection: refetch,
      updatePublicTabPayload,
      isPending,
      updatePublicCollection: async (newUpdatePublicTabPayload: {
        name?: string
        email?: string
        access_code?: string
      }) => {
        const res = await api.publicTabs.detail.fetch({
          pathParams: {
            tabId: collectionSlug,
          },
          queryParams: newUpdatePublicTabPayload,
        })
        const publicTabDetailQueryKey = getEndpointKey(
          endpoints.publicTabs.detail,
          {
            pathParams: {
              tabId: collectionSlug,
            },
            queryParams: newUpdatePublicTabPayload,
          },
        )
        queryClient.setQueryData(publicTabDetailQueryKey, res)
        setUpdatePublicTabPayload(newUpdatePublicTabPayload)
        return res
      },
      clearPublicCollectionPayload: () => setUpdatePublicTabPayload(null),
    }),
    [
      publicCollection,
      refetch,
      updatePublicTabPayload,
      isPending,
      collectionSlug,
      queryClient,
      setUpdatePublicTabPayload,
    ],
  )

  return value
}

export default usePublicCollection
