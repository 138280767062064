import * as Ariakit from '@ariakit/react'
import React, {useContext, useMemo, useState} from 'react'

import {PhosphorIcon} from '../../icons'
import {cn} from '../../utils'
import type {Merge} from '@cheddarup/util'
import {Group, GroupProps} from './Group'

export interface CheckboxProps extends Ariakit.CheckboxProps {}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      defaultValue = false,
      value,
      store,

      className,
      disabled: disabledProp,
      onFocusVisible,
      onBlur,
      children,
      ...restProps
    },
    forwardedRef,
  ) => {
    const checkbox = Ariakit.useCheckboxContext()
    const checkboxGroupContextValue = useContext(InternalCheckboxGroupContext)
    const [isFocusVisible, setIsFocusVisible] = useState(false)

    const disabled = disabledProp ?? checkboxGroupContextValue?.disabled

    return (
      // biome-ignore lint/a11y/noLabelWithoutControl:
      <Ariakit.Role.label
        data-focus-visible={isFocusVisible || undefined}
        data-disabled={disabled}
        className={cn(
          'group/checkbox relative inline-flex cursor-pointer flex-row items-baseline gap-3',
          'font-normal data-[disabled=true]:text-grey-300',
          className,
        )}
      >
        <Ariakit.Checkbox
          ref={forwardedRef}
          className="peer/input absolute z-[-1] h-px w-px overflow-hidden opacity-0"
          store={checkbox}
          name={checkboxGroupContextValue?.name}
          disabled={disabled}
          value={value}
          onFocusVisible={(event) => {
            onFocusVisible?.(event)
            setIsFocusVisible(true)
          }}
          onBlur={(event) => {
            onBlur?.(event)
            setIsFocusVisible(false)
          }}
          {...restProps}
        />
        <div
          className={cn(
            'relative size-4 rounded border border-grey-200 bg-trueWhite transition-colors',
            'inline-flex flex-row items-center',
            'group-hover/checkbox:inputHoverBackground',
            'peer-aria-disabled/input:cursor-not-allowed',
            'peer-aria-checked/input:border-none peer-aria-checked/input:bg-teal-600',
            'peer-aria-[checked=mixed]/input:border-none peer-aria-[checked=mixed]/input:bg-teal-600',
            'peer-aria-checked/input:peer-aria-disabled/input:bg-teal-400 peer-aria-checked/input:peer-aria-disabled/input:opacity-100',
            'peer-aria-checked/input:[&>.check-icon]:visible',
            'peer-aria-[checked=mixed]/input:[&>.mixed-icon]:visible',
            'group-data-[focus-visible]/checkbox:border-teal-600',
          )}
        >
          {/* Zero-width space character, used to align checkbox properly */}
          &#8203;
          <PhosphorIcon
            aria-hidden
            className="check-icon -translate-x-1/2 -translate-y-1/2 invisible absolute top-1/2 left-1/2 text-[0.8em] text-trueWhite"
            icon="check-bold"
          />
          <PhosphorIcon
            aria-hidden
            className="mixed-icon -translate-x-1/2 -translate-y-1/2 invisible absolute top-1/2 left-1/2 text-[0.8em] text-trueWhite"
            icon="minus-bold"
          />
        </div>
        {children}
      </Ariakit.Role.label>
    )
  },
)

// MARK: – InternalCheckboxGroupContext

interface InternalCheckboxGroupContextValue
  extends Pick<CheckboxProps, 'name' | 'disabled'> {}

const InternalCheckboxGroupContext = React.createContext<
  InternalCheckboxGroupContextValue | undefined
>(undefined)

// MARK: – CheckboxGroup

export interface CheckboxGroupProps
  extends Merge<GroupProps, Ariakit.CheckboxStoreProps<Array<string | number>>>,
    Pick<InternalCheckboxGroupContextValue, 'name' | 'disabled'> {}

export const CheckboxGroup = React.forwardRef<
  HTMLDivElement,
  CheckboxGroupProps
>(
  (
    {
      defaultValue,
      value,
      setValue,
      store,

      className,
      name,
      disabled,
      ...restProps
    },
    forwardedRef,
  ) => {
    const checkbox = Ariakit.useCheckboxStore({
      defaultValue: defaultValue ?? [],
      value,
      setValue,
      store,
    })

    const contextValue = useMemo(
      () => ({
        name,
        disabled,
      }),
      [name, disabled],
    )

    return (
      <Ariakit.CheckboxProvider store={checkbox}>
        <InternalCheckboxGroupContext.Provider value={contextValue}>
          <Group
            ref={forwardedRef}
            className={cn(
              'group/checkbox-group flex flex-col gap-2',
              className,
            )}
            {...restProps}
          />
        </InternalCheckboxGroupContext.Provider>
      </Ariakit.CheckboxProvider>
    )
  },
)
