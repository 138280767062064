import {sort} from '@cheddarup/util'

import {makeQueryUpdate, makeUseMutation} from '../use-mutation'
import {Endpoints, endpoints} from '../../endpoints'
import {FetchInput, getEndpointKey} from '../../utils'
import {makeUseBatchMutation} from './misc'

export const useCreateTabSignupMutation = makeUseMutation(
  endpoints.tabSignups.create,
  (vars) => ({
    regular: (newSignup) => [
      makeQueryUpdate(
        endpoints.tabSignups.list,
        (prevSignups) =>
          prevSignups ? [...prevSignups, newSignup] : prevSignups,
        vars,
      ),
      makeQueryUpdate(endpoints.tabSignups.detail, () => newSignup, {
        pathParams: {
          tabId: vars.pathParams.tabId,
          signupId: newSignup.id,
        },
      }),
      makeQueryUpdate(
        endpoints.tabs.detail,
        (prevTab) =>
          prevTab
            ? {
                ...prevTab,
                reportsAvailable: {
                  ...prevTab.reportsAvailable,
                  activeSignupsCount:
                    prevTab.reportsAvailable.activeSignupsCount + 1,
                },
              }
            : prevTab,
        {
          pathParams: {
            tabId: vars.pathParams.tabId,
            signupId: newSignup.id,
          },
        },
      ),
    ],
  }),
)

const useCreateTimeSlotsBatchMutationImpl = makeUseBatchMutation<
  FetchInput<Endpoints['misc']['batch']> & {
    tabId: number
    signUpId: number
    spotId: number
  }
>((vars) => ({
  regular: (newData) => [
    makeQueryUpdate(
      endpoints.tabSignups.detail,
      (prevSignup) =>
        prevSignup
          ? {
              ...prevSignup,
              spots: prevSignup.spots.map((s) =>
                s.id === vars.spotId
                  ? {
                      ...s,
                      time_slots: [
                        ...s.time_slots,
                        ...newData.results.map((d) => d.body),
                      ],
                    }
                  : s,
              ),
            }
          : prevSignup,
      {
        pathParams: {
          tabId: vars.tabId,
          signupId: vars.signUpId,
        },
      },
    ),
    makeQueryUpdate(
      endpoints.tabSignupSpots.detail,
      (prevSpot) =>
        prevSpot
          ? {
              ...prevSpot,
              time_slots: [
                ...prevSpot.time_slots,
                ...newData.results.map((d) => d.body),
              ],
            }
          : prevSpot,
      {
        pathParams: {
          tabId: vars.tabId,
          signupId: vars.signUpId,
          spotId: vars.spotId,
        },
      },
    ),
    makeQueryUpdate(
      endpoints.tabSignupSpots.list,
      (prevSpots) =>
        prevSpots
          ? prevSpots.map((s) =>
              s.id === vars.spotId
                ? {
                    ...s,
                    time_slots: [
                      ...s.time_slots,
                      ...newData.results.map((d) => d.body),
                    ],
                  }
                : s,
            )
          : prevSpots,
      {
        pathParams: {
          tabId: vars.tabId,
          signupId: vars.signUpId,
        },
      },
    ),
  ],
}))

export function useCreateTimeSlotsBatchMutation() {
  const batchMutation = useCreateTimeSlotsBatchMutationImpl()

  return async (
    {
      tabId,
      signUpId,
      spotId,
    }: {
      tabId: number
      signUpId: number
      spotId: number
    },
    timeSlots: Array<
      Omit<
        Api.TimeSlot,
        'id' | 'name' | 'position' | 'payment_items' | 'errors'
      >
    >,
  ) =>
    batchMutation.mutateAsync({
      tabId,
      signUpId,
      spotId,
      body: {
        sequential: true,
        ops: timeSlots.map((timeSlot) => ({
          method: 'POST' as const,
          url: `/api/users/tabs/${tabId}/signups/${signUpId}/spots/${spotId}/time_slots`,
          params: {
            options: {
              startTime: timeSlot.options?.startTime,
              endTime: timeSlot.options?.endTime,
            },
            available_quantity: timeSlot.available_quantity,
          },
        })),
      },
    })
}

const useUpdateTimeSlotsBatchMutationImpl = makeUseBatchMutation<
  FetchInput<Endpoints['misc']['batch']> & {
    tabId: number
    signUpId: number
    spotId: number
  }
>(
  (vars) => ({
    regular: (newData) => {
      const updatedTimeSlots = newData.results.map((r) => r.body)

      return [
        makeQueryUpdate(
          endpoints.tabSignups.detail,
          (prevSignup) =>
            prevSignup
              ? {
                  ...prevSignup,
                  spots: prevSignup.spots.map((s) =>
                    s.id === vars.spotId
                      ? {
                          ...s,
                          time_slots: s.time_slots.map(
                            (ts) =>
                              updatedTimeSlots.find(
                                (uts) => uts.id === ts.id,
                              ) ?? ts,
                          ),
                        }
                      : s,
                  ),
                }
              : prevSignup,
          {
            pathParams: {
              tabId: vars.tabId,
              signupId: vars.signUpId,
            },
          },
        ),
        makeQueryUpdate(
          endpoints.tabSignupSpots.detail,
          (prevSpot) =>
            prevSpot
              ? {
                  ...prevSpot,
                  time_slots: prevSpot.time_slots.map(
                    (ts) =>
                      updatedTimeSlots.find((uts) => uts.id === ts.id) ?? ts,
                  ),
                }
              : prevSpot,
          {
            pathParams: {
              tabId: vars.tabId,
              signupId: vars.signUpId,
              spotId: vars.spotId,
            },
          },
        ),
        makeQueryUpdate(
          endpoints.tabSignupSpots.list,
          (prevSpots) =>
            prevSpots
              ? prevSpots.map((s) =>
                  s.id === vars.spotId
                    ? {
                        ...s,
                        time_slots: s.time_slots.map(
                          (ts) =>
                            updatedTimeSlots.find((uts) => uts.id === ts.id) ??
                            ts,
                        ),
                      }
                    : s,
                )
              : prevSpots,
          {
            pathParams: {
              tabId: vars.tabId,
              signupId: vars.signUpId,
            },
          },
        ),
      ]
    },
  }),
  (queryClient) => ({
    onSuccess: (_, vars) => {
      const signupDetailQueryKey = getEndpointKey(endpoints.tabSignups.detail, {
        pathParams: {
          tabId: vars.tabId,
          signupId: vars.signUpId,
        },
      })
      const spotDetailQueryKey = getEndpointKey(
        endpoints.tabSignupSpots.detail,
        {
          pathParams: {
            tabId: vars.tabId,
            signupId: vars.signUpId,
            spotId: vars.spotId,
          },
        },
      )

      queryClient.invalidateQueries({queryKey: signupDetailQueryKey})
      queryClient.invalidateQueries({queryKey: spotDetailQueryKey})
    },
  }),
)

export function useUpdateTimeSlotsBatchMutation() {
  const batchMutation = useUpdateTimeSlotsBatchMutationImpl()

  return async (
    {
      tabId,
      signUpId,
      spotId,
    }: {
      tabId: number
      signUpId: number
      spotId: number
    },
    timeSlots: Array<Partial<Api.TimeSlot>>,
  ) =>
    batchMutation.mutateAsync({
      tabId,
      signUpId,
      spotId,
      body: {
        sequential: true,
        ops: timeSlots.map((timeSlot) => ({
          method: 'PATCH' as const,
          url: `/api/users/tabs/${tabId}/signups/${signUpId}/spots/${spotId}/time_slots/${timeSlot.id}`,
          params: {
            options: {
              startTime: timeSlot.options?.startTime,
              endTime: timeSlot.options?.endTime,
            },
            available_quantity: timeSlot.available_quantity,
          },
        })),
      },
    })
}

const useDeleteTimeSlotsBatchMutationImpl = makeUseBatchMutation<
  FetchInput<Endpoints['misc']['batch']> & {
    tabId: number
    signUpId: number
    spotId: number
  }
>((vars) => ({
  regular: (_newData, resAndOps) => {
    const deletedTimeSlotIds = resAndOps.map(({op}) =>
      Number(op.url.split('/').pop()),
    )

    return [
      makeQueryUpdate(
        endpoints.tabSignups.detail,
        (prevSignup) =>
          prevSignup
            ? {
                ...prevSignup,
                spots: prevSignup.spots.map((s) =>
                  s.id === vars.spotId
                    ? {
                        ...s,
                        time_slots: s.time_slots.filter(
                          (ts) => !deletedTimeSlotIds.includes(ts.id),
                        ),
                      }
                    : s,
                ),
              }
            : prevSignup,
        {
          pathParams: {
            tabId: vars.tabId,
            signupId: vars.signUpId,
          },
        },
      ),
      makeQueryUpdate(
        endpoints.tabSignupSpots.detail,
        (prevSpot) =>
          prevSpot
            ? {
                ...prevSpot,
                time_slots: prevSpot.time_slots.filter(
                  (ts) => !deletedTimeSlotIds.includes(ts.id),
                ),
              }
            : prevSpot,
        {
          pathParams: {
            tabId: vars.tabId,
            signupId: vars.signUpId,
            spotId: vars.spotId,
          },
        },
      ),
      makeQueryUpdate(
        endpoints.tabSignupSpots.list,
        (prevSpots) =>
          prevSpots
            ? prevSpots.map((s) =>
                s.id === vars.spotId
                  ? {
                      ...s,
                      time_slots: s.time_slots.filter(
                        (ts) => !deletedTimeSlotIds.includes(ts.id),
                      ),
                    }
                  : s,
              )
            : prevSpots,
        {
          pathParams: {
            tabId: vars.tabId,
            signupId: vars.signUpId,
          },
        },
      ),
    ]
  },
}))

export function useDeleteTimeSlotsBatchMutation() {
  const batchMutation = useDeleteTimeSlotsBatchMutationImpl()

  return (
    {
      tabId,
      signUpId,
      spotId,
    }: {
      tabId: number
      signUpId: number
      spotId: number
    },
    timeSlotIds: number[],
  ) =>
    batchMutation.mutateAsync({
      tabId,
      signUpId,
      spotId,
      body: {
        sequential: true,
        ops: timeSlotIds.map((tsId) => ({
          method: 'DELETE' as const,
          url: `/api/users/tabs/${tabId}/signups/${signUpId}/spots/${spotId}/time_slots/${tsId}`,
        })),
      },
    })
}

export const useUpdateTabSignupMutation = makeUseMutation(
  endpoints.tabSignups.update,
  (vars) => ({
    regular: (newSignup) => [
      makeQueryUpdate(
        endpoints.tabSignups.list,
        (prevSignups) =>
          prevSignups?.map((sl) =>
            sl.id === Number(vars.pathParams.signupId) ? newSignup : sl,
          ),
        vars,
      ),
      makeQueryUpdate(endpoints.tabSignups.detail, () => newSignup, vars),
    ],
  }),
)

export const useCloneTabSignupMutation = makeUseMutation(
  endpoints.tabSignups.clone,
  (vars) => ({
    regular: (newSignup) => [
      makeQueryUpdate(
        endpoints.tabSignups.list,
        (prevSignups) => {
          if (!prevSignups || vars.body?.new_tab_id != null) {
            return prevSignups
          }

          const clonedSignUpIdx = prevSignups.findIndex(
            (s) => s.id === Number(vars.pathParams.signupId),
          )
          return [
            ...prevSignups.slice(0, clonedSignUpIdx),
            newSignup,
            ...prevSignups.slice(clonedSignUpIdx),
          ]
        },
        vars,
      ),
      makeQueryUpdate(endpoints.tabSignups.detail, () => newSignup, vars),
      makeQueryUpdate(
        endpoints.tabs.detail,
        (prevTab) =>
          prevTab
            ? {
                ...prevTab,
                reportsAvailable: {
                  ...prevTab.reportsAvailable,
                  activeSignupsCount:
                    prevTab.reportsAvailable.activeSignupsCount + 1,
                },
              }
            : prevTab,
        vars,
      ),
    ],
  }),
)

export const useDeleteTabSignupMutation = makeUseMutation(
  endpoints.tabSignups.delete,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(
        endpoints.tabSignups.list,
        (prevSignups) =>
          prevSignups?.filter((s) => s.id !== Number(vars.pathParams.signupId)),
        vars,
      ),
      makeQueryUpdate(
        endpoints.tabs.detail,
        (prevTab) =>
          prevTab
            ? {
                ...prevTab,
                reportsAvailable: {
                  ...prevTab.reportsAvailable,
                  activeSignupsCount:
                    prevTab.reportsAvailable.activeSignupsCount - 1,
                },
              }
            : prevTab,
        vars,
      ),
    ],
  }),
)

const useCreateSpotsBatchMutationImpl = makeUseBatchMutation<
  FetchInput<Endpoints['misc']['batch']> & {
    tabId: number
    signUpId: number
  }
>()

export function useCreateSpotsBatchMutation() {
  const batchMutation = useCreateSpotsBatchMutationImpl()

  return async (
    {
      tabId,
      signUpId,
    }: {
      tabId: number
      signUpId: number
    },
    spots: Array<{
      name: string
      description: string
      hidden: boolean
      available_quantity: number
    }>,
  ) =>
    batchMutation.mutateAsync({
      tabId,
      signUpId,
      body: {
        sequential: true,
        ops: spots.map((spot) => ({
          method: 'POST' as const,
          url: `/api/users/tabs/${tabId}/signups/${signUpId}/spots`,
          params: {
            name: spot.name,
            description: spot.description,
            hidden: spot.hidden,
            available_quantity: spot.available_quantity,
          },
        })),
      },
    })
}

export const useCreateSpotMutation = makeUseMutation(
  endpoints.tabSignupSpots.create,
  (vars) => ({
    regular: (newSpot) => [
      makeQueryUpdate(
        endpoints.tabSignupSpots.list,
        (prevSpots) => (prevSpots ? [...prevSpots, newSpot] : prevSpots),
        vars,
      ),
      makeQueryUpdate(
        endpoints.tabSignups.detail,
        (prevSignup) =>
          prevSignup
            ? {...prevSignup, spots: [...prevSignup.spots, newSpot]}
            : prevSignup,
        vars,
      ),
    ],
  }),
)

export const useCloneSpotMutation = makeUseMutation(
  endpoints.tabSignupSpots.clone,
  (vars) => ({
    regular: (newSpot) => [
      makeQueryUpdate(
        endpoints.tabSignupSpots.list,
        (prevSpots) => {
          if (!prevSpots) {
            return prevSpots
          }

          const sortedSpot = sort(prevSpots).asc((s) => s.position)
          const clonedSpotIdx = sortedSpot.findIndex(
            (s) => s.id === Number(vars.pathParams.spotId),
          )
          return [
            ...sortedSpot.slice(0, clonedSpotIdx),
            newSpot,
            ...sortedSpot.slice(clonedSpotIdx),
          ]
        },
        vars,
      ),
    ],
  }),
)

export const useUpdateSpotMutation = makeUseMutation(
  endpoints.tabSignupSpots.update,
  (vars) => ({
    regular: (newSpot) => [
      makeQueryUpdate(
        endpoints.tabSignupSpots.list,
        (prevSpots) =>
          prevSpots?.map((s) =>
            s.id === Number(vars.pathParams.spotId) ? newSpot : s,
          ),
        vars,
      ),
      makeQueryUpdate(endpoints.tabSignupSpots.detail, () => newSpot, vars),
      makeQueryUpdate(
        endpoints.tabSignups.detail,
        (prevSignup) =>
          prevSignup
            ? {
                ...prevSignup,
                spots: prevSignup.spots.map((s) =>
                  s.id === newSpot.id ? newSpot : s,
                ),
              }
            : prevSignup,
        vars,
      ),
    ],
  }),
)

export const useDeleteSpotMutation = makeUseMutation(
  endpoints.tabSignupSpots.delete,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(
        endpoints.tabSignupSpots.list,
        (prevSpots) =>
          prevSpots?.filter((s) => s.id !== Number(vars.pathParams.spotId)),
        vars,
      ),
    ],
  }),
)

export const useSortSpotsMutation = makeUseMutation(
  endpoints.tabSignupSpots.sort,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(
        endpoints.tabSignupSpots.list,
        (prevSpots) => {
          if (!prevSpots) {
            return prevSpots
          }

          const prevOrder = prevSpots.map((s) => s.id)
          const nextOrder = sort(prevOrder).asc((sId) =>
            vars.body?.order.indexOf(sId),
          )

          return [
            ...nextOrder
              .map((spotId) => prevSpots.find((s) => s.id === spotId))
              .map((s, idx) => ({...s, position: idx}))
              .filter((s): s is Api.SignUpSpot => s != null),
            ...prevSpots.filter((s) => !vars.body?.order.includes(s.id)),
          ]
        },
        vars,
      ),
    ],
  }),
  (queryClient) => ({
    onSuccess: (_, vars) => {
      const signupDetailQueryKey = getEndpointKey(
        endpoints.tabSignups.detail,
        vars,
      )
      queryClient.invalidateQueries({queryKey: signupDetailQueryKey})
    },
  }),
)

export const useDeleteTimeSlotMutation = makeUseMutation(
  endpoints.timeSlots.delete,
  (vars) => ({
    regular: () => [
      makeQueryUpdate(
        endpoints.tabSignups.detail,
        (prevSignup) =>
          prevSignup
            ? {
                ...prevSignup,
                spots: prevSignup.spots.map((s) =>
                  s.id === Number(vars.pathParams.spotId)
                    ? {
                        ...s,
                        time_slots: s.time_slots.filter(
                          (ts) => ts.id !== Number(vars.pathParams.timeSlotId),
                        ),
                      }
                    : s,
                ),
              }
            : prevSignup,
        vars,
      ),
      makeQueryUpdate(
        endpoints.tabSignupSpots.detail,
        (prevSpot) =>
          prevSpot
            ? {
                ...prevSpot,
                time_slots: prevSpot.time_slots.filter(
                  (ts) => ts.id !== Number(vars.pathParams.timeSlotId),
                ),
              }
            : prevSpot,
        vars,
      ),
      makeQueryUpdate(
        endpoints.tabSignupSpots.list,
        (prevSpots) =>
          prevSpots?.map((s) =>
            s.id === Number(vars.pathParams.spotId)
              ? {
                  ...s,
                  time_slots: s.time_slots.filter(
                    (ts) => ts.id !== Number(vars.pathParams.timeSlotId),
                  ),
                }
              : s,
          ),
        vars,
      ),
    ],
  }),
)
