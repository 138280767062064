import * as Yup from 'yup'
import * as WebUI from '@cheddarup/web-ui'
import {useFormik} from '@cheddarup/react-util'
import {forwardRef, useState} from 'react'
import * as Util from '@cheddarup/util'
import {api, useCreateWithdrawalMutation} from '@cheddarup/api-client'
import {BankAccountSelectRow, CreditCardSelectRow} from 'src/components'
import {Link} from 'src/components/Link'
import config from 'src/config'
import {useApiHeaders} from 'src/helpers/client'
import {useManagerRoleQuery} from 'src/components/ManageRoleProvider'

const STATEMENT_DESCRIPTION_MAX_LENGTH = 22

export interface WithdrawFormContainerProps {
  collection: Api.Tab
}

const WithdrawFormContainer = ({collection}: WithdrawFormContainerProps) => {
  const [selectedAccount, setSelectedAccount] = useState<{
    type: string
    data: any
  }>({type: '', data: {}})
  const [managerRoleQuery] = useManagerRoleQuery()
  const createWithdrawalMutation = useCreateWithdrawalMutation()
  const externalAccountsQuery = api.externalAccounts.list.useQuery(undefined, {
    enabled:
      !managerRoleQuery.isPending &&
      (!managerRoleQuery.data ||
        managerRoleQuery.data.permissions.role === 'admin'),
    staleTime: 0,
  })
  const {data: session, refetch: refetchSession} = api.auth.session.useQuery()
  const growlActions = WebUI.useGrowlActions()
  const headers = useApiHeaders()

  const formik = useFormik({
    validationSchema: Yup.object().shape({
      amount: Yup.number()
        .moreThan(
          0,
          ({more}) => `Must be greater than ${Util.formatAmount(more)}`,
        )
        .max(
          collection.withdrawal_balance_available,
          ({max}) => `Must be less than or equal to ${Util.formatAmount(max)}`,
        )
        .required('Required'),
      statement_descriptor: Yup.string().max(
        STATEMENT_DESCRIPTION_MAX_LENGTH,
        '${max} character limit',
      ),
    }),
    initialValues: {
      amount: '',
      statement_descriptor: '',
      stripe_bank_account_id: null,
    },
    onSubmit: async (values, formikHelpers) => {
      try {
        const response = await createWithdrawalMutation.mutateAsync({
          pathParams: {
            tabId: collection.id,
          },
          body: {
            ...values,
            amount: Number(values.amount),
          },
          headers,
        })

        if (!response.error) {
          growlActions.show('success', {
            title: `Transfer of ${Util.formatAmount(response.amount)} initiated`,
            body: 'Transfers are reviewed, which may delay deposit beyond the typical timeframe.',
          })
          formikHelpers.resetForm()
        }
      } catch (err: any) {
        const isDebitCardError =
          !!err.response?.data?.errors?.stripe_bank_account_id
        const isPersonaError = Boolean(
          err.response?.data?.type === 'api_error' &&
            err.response.data.errors[0]?.error === 'persona_required',
        )
        const isTooSoon = Boolean(
          err.response?.data?.type === 'api_error' &&
            err.response.data.errors[0]?.error === 'too_soon',
        )
        if (isPersonaError) {
          refetchSession()
          growlActions.show('error', {
            title: 'Verification Requested',
            body: 'Before you can withdraw, we need a little more information.',
          })
        } else if (isTooSoon) {
          refetchSession()
          growlActions.show('error', {
            title: 'Slow down!',
            body: `You're moving pretty fast! We can only process one withdrawal per minute on a single collection.`,
          })
        } else {
          growlActions.show('error', {
            title: isDebitCardError
              ? 'Debit Card Withdrawal Limit'
              : 'Withdrawal Failed',
            body: isDebitCardError
              ? 'Withdrawals to debit cards are limited to $500 per week. Withdraw to a bank account to avoid this limit.'
              : 'Please contact Cheddar Up',
          })
        }
      }
    },
  })

  return (
    <WebUI.VStack className="gap-4">
      <WebUI.Heading className="px-4 text-gray800" as="h3">
        Transfer to Bank
      </WebUI.Heading>
      <WebUI.VStack className="gap-5 rounded bg-gray100 px-5 py-7">
        <WebUI.VStack>
          <WebUI.Text className="font-light text-ds-base text-gray800">
            {Util.formatAmount(collection.withdrawal_balance_available)}{' '}
            Available
          </WebUI.Text>
          <Link
            className="text-ds-sm"
            variant="primary"
            to={`../${collection.id}/summary`}
          >
            Balance Summary
          </Link>
        </WebUI.VStack>
        <WebUI.Form className="max-w-[375px]">
          <WebUI.VStack className="gap-7">
            <WebUI.FormFieldGroup>
              <WebUI.FormField
                label="Amount to Transfer"
                error={formik.errors.amount}
              >
                <WebUI.AmountInput
                  name="amount"
                  readOnly={formik.isSubmitting}
                  placeholder="$"
                  value={formik.values.amount}
                  onValueChange={(newAmount) =>
                    formik.setFieldValue('amount', newAmount ?? '')
                  }
                  onBlur={formik.handleBlur}
                />
              </WebUI.FormField>
              <WebUI.FormField
                label="Statement Description"
                error={formik.errors.statement_descriptor}
              >
                <WebUI.Input
                  name="statement_descriptor"
                  readOnly={formik.isSubmitting}
                  maxLength={17}
                  placeholder="Up to 17 characters"
                  value={formik.values.statement_descriptor}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </WebUI.FormField>
            </WebUI.FormFieldGroup>

            <WebUI.VStack className="gap-4">
              <WebUI.Text className="text-ds-sm">
                Select Bank Account:
              </WebUI.Text>

              <WebUI.RadioGroup
                name="stripe_bank_account_id"
                aria-label="Bank account select"
                state={formik.values.stripe_bank_account_id as any}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                {externalAccountsQuery.data?.banks?.map((bank) => (
                  <BankAccountSelectRow
                    key={bank.id}
                    className="bg-natural-100 px-4 py-3"
                    value={bank.id}
                    as={WebUI.Radio}
                    bankAccount={bank}
                    size="compact"
                    onClick={() =>
                      setSelectedAccount({type: 'bank', data: bank})
                    }
                  />
                ))}
                {externalAccountsQuery.data?.cards?.map((card) => (
                  <CreditCardSelectRow
                    key={card.id}
                    className="bg-natural-100 px-4 py-3"
                    value={card.id}
                    as={WebUI.Radio}
                    creditCard={card}
                    size="compact"
                    onClick={() =>
                      setSelectedAccount({type: 'card', data: card})
                    }
                  />
                ))}
              </WebUI.RadioGroup>
              {!session?.stripe_data?.bankModificationsDisabled &&
                !managerRoleQuery.data && (
                  <Link
                    className="text-ds-sm"
                    variant="primary"
                    to="../../../my-account/withdrawal-settings"
                  >
                    Add an Account
                  </Link>
                )}

              <WebUI.Button
                className="self-start"
                type="button"
                variant="primary"
                loading={formik.isSubmitting}
                as={WithdrawalConfirmationAlert as any}
                onConfirm={formik.submitForm}
                submitting={formik.isSubmitting}
                accountName={
                  selectedAccount.type === 'bank'
                    ? `${
                        selectedAccount.data.nickname ||
                        selectedAccount.data.bank_name
                      }: ***${selectedAccount.data.last4}`
                    : `${
                        selectedAccount.data.nickname ||
                        selectedAccount.data.name
                      } (Debit): ***${selectedAccount.data.last4}`
                }
                disabled={!selectedAccount.type}
              >
                Transfer to Bank
              </WebUI.Button>
            </WebUI.VStack>
          </WebUI.VStack>
        </WebUI.Form>
        <WebUI.Text className="font-light text-ds-xs text-gray800">
          Transfer speeds for funding a collection vary but typically take 3
          business days. Transfers are reviewed, which may result in delays or
          the need for additional information.&nbsp;
          <WebUI.Anchor
            target="_blank"
            rel="noopener noreferrer"
            href={config.links.withdraw}
          >
            Learn more
          </WebUI.Anchor>
        </WebUI.Text>
      </WebUI.VStack>
    </WebUI.VStack>
  )
}

// MARK: – WithdrawalConfirmationAlert

interface WithdrawalConfirmationAlertProps extends WebUI.DialogDisclosureProps {
  accountName: string
  submitting?: boolean
  onConfirm: () => void
}

const WithdrawalConfirmationAlert = forwardRef<
  HTMLButtonElement,
  WithdrawalConfirmationAlertProps
>(({accountName, onConfirm, submitting, ...restProps}, forwardedRef) => (
  <WebUI.Alert
    aria-label="Withdrawal confirmation"
    disclosure={<WebUI.DialogDisclosure ref={forwardedRef} {...restProps} />}
  >
    <WebUI.AlertHeader>Confirm your withdrawal</WebUI.AlertHeader>
    <WebUI.AlertContentView
      text={
        <WebUI.VStack className="gap-1">
          <WebUI.Text className="font-light">
            You're withdrawing to the following account:
          </WebUI.Text>
          <WebUI.Text className="font-bold">{accountName}</WebUI.Text>
        </WebUI.VStack>
      }
      actions={
        <>
          <WebUI.AlertActionButton
            loading={submitting}
            execute={() => onConfirm()}
          >
            Confirm
          </WebUI.AlertActionButton>
          <WebUI.AlertCancelButton disabled={submitting} />
        </>
      }
    />
  </WebUI.Alert>
))

export default WithdrawFormContainer
