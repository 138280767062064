import React, {useRef, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'
import {useNavigate} from 'react-router-dom'
import {AccountSettingsContentLayout} from './components/AccountSettingsContentLayouts'
import {useManagerRoleId} from 'src/components/ManageRoleProvider'

const ZapierKeyPage: React.FC = () => {
  const navigate = useNavigate()
  const dialogRef = useRef<WebUI.DialogInstance>(null)
  const [managerRoleId] = useManagerRoleId()
  const generateAPIKeyMutation = api.misc.makeZapToken.useUnstableMutation()
  const sessionQuery = api.auth.session.useQuery()

  const isTeam =
    sessionQuery.data?.capabilities.plan === 'team' || managerRoleId

  return (
    <AccountSettingsContentLayout
      className="[&_>_.Text]:font-light"
      heading="Integrations"
    >
      <WebUI.Text>
        Use the Cheddar Up API to integrate with Zapier, allowing you to connect
        with thousands of different apps.
      </WebUI.Text>
      <WebUI.Text>
        A Cheddar Up API key is specific to your unique Cheddar Up account.
        Similar to how you would not share your password with anyone, you should
        also not share your API key with anyone.
      </WebUI.Text>
      <WebUI.Anchor
        className="text-ds-sm"
        href="https://support.cheddarup.com/hc/en-us/articles/14098556683412"
        rel="noopener noreferrer"
        target="_blank"
      >
        Learn how to use your API key to connect to Zapier
      </WebUI.Anchor>
      {isTeam ? (
        <ZapierKeyModal
          ref={dialogRef}
          apiKey={(generateAPIKeyMutation.data ?? '') as string}
          disclosure={
            <WebUI.DialogDisclosure
              className="relative self-start"
              onClick={() => generateAPIKeyMutation.mutate()}
            >
              Generate an API Key
            </WebUI.DialogDisclosure>
          }
        />
      ) : (
        <WebUI.Button
          className="self-start"
          onClick={() => navigate('i/plans')}
        >
          Upgrade to Team
        </WebUI.Button>
      )}
    </AccountSettingsContentLayout>
  )
}

interface ZapierKeyModalProps extends WebUI.ModalProps {
  apiKey: string
}

const ZapierKeyModal = React.forwardRef<
  WebUI.DialogInstance,
  ZapierKeyModalProps
>(({apiKey, initialVisible = false, ...restProps}, forwardedRef) => {
  const [copied, setCopied] = useState(false)

  return (
    <WebUI.Modal
      ref={forwardedRef}
      aria-label="Your API Key"
      initialVisible={initialVisible}
      className={
        '[&_>_.ModalContentView]:max-w-screen-sm [&_>_.ModalContentView]:rounded-large [&_>_.ModalContentView]:p-10'
      }
      {...restProps}
    >
      {(dialog) => (
        <>
          <WebUI.ModalCloseButton />
          <WebUI.VStack className="gap-6">
            <WebUI.Heading as="h2">Your API Key</WebUI.Heading>
            <WebUI.Text className="font-light">
              Your API key has been generated successfully. Please copy this key
              and save it somewhere safe. For security reasons, we cannot show
              it to you again.
            </WebUI.Text>
            <WebUI.HStack className="items-center gap-3">
              {apiKey ? (
                <WebUI.Input
                  className="min-w-[300px] bg-natural-80"
                  readOnly
                  name="apiKey"
                  value={apiKey}
                />
              ) : (
                <WebUI.Skeleton width={300} height={40} />
              )}
              <WebUI.IconButton
                variant="default"
                className="p-5 text-ds-xl"
                size="default_alt"
                onClick={() => {
                  WebUI.copyToClipboard(apiKey)
                  setCopied(true)
                  setTimeout(() => setCopied(false), 3000)
                }}
              >
                <WebUI.PhosphorIcon icon={copied ? 'check' : 'copy'} />
              </WebUI.IconButton>
            </WebUI.HStack>
            <WebUI.Button className="self-start" onClick={() => dialog.hide()}>
              Close
            </WebUI.Button>
          </WebUI.VStack>
        </>
      )}
    </WebUI.Modal>
  )
})

export default ZapierKeyPage
