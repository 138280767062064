import {makeUseMutation} from '../use-mutation'
import {endpoints} from '../../endpoints'
import {getEndpointKey} from '../../utils'

export const useCheckSubscriptionMutation = makeUseMutation(
  endpoints.subscriptions.check,
)

export const useUpdateSubscriptionMutation = makeUseMutation(
  endpoints.subscriptions.update,
  undefined,
  (queryClient) => ({
    onSuccess: () => {
      const subscriptionOwnQueryKey = getEndpointKey(
        endpoints.subscriptions.own,
      )
      const subscriptionInvoicesQueryKey = getEndpointKey(
        endpoints.subscriptions.invoices,
      )
      const sessionQueryKey = getEndpointKey(endpoints.auth.session)

      queryClient.invalidateQueries({queryKey: subscriptionOwnQueryKey})
      queryClient.invalidateQueries({queryKey: subscriptionInvoicesQueryKey})
      queryClient.invalidateQueries({queryKey: sessionQueryKey})

      queryClient.invalidateQueries({
        predicate: (query) => {
          const [queryPath] = query.queryKey
          return (
            typeof queryPath === 'string' && queryPath.startsWith('users/tabs')
          )
        },
      })
    },
  }),
)
