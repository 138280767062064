import {Link} from 'react-router-dom'
import React from 'react'
import {api} from '@cheddarup/api-client'

import {MarketplaceSectionTitle} from '../../components'

const HeaderSection = (props: React.ComponentPropsWithoutRef<'div'>) => {
  const {data: session} = api.auth.session.useSuspenseQuery()
  const marketplaceName =
    session.organization_data?.internalMarketplace?.organizerNickname ?? ''

  return (
    <div {...props}>
      <div className="flex items-center">
        <MarketplaceSectionTitle>Welcome!</MarketplaceSectionTitle>
      </div>
      <div className="mt-2 pt-2">
        Here you can search for items in other {marketplaceName} sales. Find
        items you&apos;re looking for via the search field or by using the{' '}
        <Link to="/marketplace/shop-items">Shop Items</Link> or{' '}
        <Link to="/marketplace/sales">{marketplaceName} Sales</Link> views.
      </div>
      <div className="mt-2 pt-2">
        All sales are added here by default, but if you wish to not have your
        sale listed, simply toggle off &quot;{marketplaceName} Marketplace&quot;
        within your sale settings.
      </div>
    </div>
  )
}

export default HeaderSection
