import * as Yup from 'yup'
import * as WebUI from '@cheddarup/web-ui'
import {useLiveRef} from '@cheddarup/react-util'
import * as Util from '@cheddarup/util'
import {useMemo} from 'react'

export interface Recipient {
  name: string
  email: string
}

export interface RecipientsTableViewProps
  extends Omit<WebUI.TableViewProps<Recipient>, 'columns' | 'data'> {
  recipients: Array<Partial<Recipient>>
  onRecipientsChange: (recipients: Recipient[]) => void
}

export const RecipientsTableView = ({
  recipients,
  onRecipientsChange,
  ...restProps
}: RecipientsTableViewProps) => {
  const recipientsRef = useLiveRef(recipients)
  const onRecipientsChangeRef = useLiveRef(onRecipientsChange)

  const columnHelper = useMemo(() => WebUI.createColumnHelper<Recipient>(), [])

  const columns = useMemo(
    () => [
      columnHelper.accessor((r) => r.name, {
        id: 'name',
        header: 'First and Last Name',
        cell: ({cell, row: {original: recipient}}) => (
          <WebUI.InlineEditInput<typeof WebUI.Input>
            className={
              'text-ds-sm [&_.Input[class]]:max-w-[120px] [&_.Input[class]]:py-0 [&_.Input[class]]:font-normal [&_.Input[class]]:text-ds-sm'
            }
            displayPadded
            defaultValue={cell.getValue()}
            onChange={(event) =>
              onRecipientsChangeRef.current(
                recipientsRef.current.map((r) =>
                  r.email === recipient.email
                    ? {...r, name: event.target.value}
                    : r,
                ) as any,
              )
            }
          />
        ),
      }),
      columnHelper.accessor((r) => r.email, {
        id: 'email',
        header: 'Email',
        cell: ({cell}) => (
          <WebUI.InlineEditInput<typeof WebUI.Input>
            aria-invalid={
              Yup.string().email().isValidSync(cell.getValue())
                ? 'false'
                : 'true'
            }
            className={`[&_.Input[class]]:max-w-[120px] [&_.Input[class]]:py-0 [&_.Input[class]]:font-normal [&_.Input[class]]:text-ds-sm [&_>_.InlineEdit-disclosure[aria-invalid="true"]]:text-error`}
            displayPadded
            defaultValue={cell.getValue()}
            onChange={(event) =>
              onRecipientsChangeRef.current(
                recipientsRef.current.map((r) =>
                  r.email === cell.getValue()
                    ? {...r, email: event.target.value}
                    : r,
                ) as any,
              )
            }
          />
        ),
      }),
      columnHelper.display({
        id: 'delete',
        meta: {
          align: 'right',
        },
        header: () => (
          <WebUI.Alert
            aria-label="Delete all recipients"
            disclosure={
              <WebUI.DialogDisclosure className="text-ds-xs" variant="link">
                Delete all
              </WebUI.DialogDisclosure>
            }
          >
            <WebUI.AlertHeader>Are you sure?</WebUI.AlertHeader>
            <WebUI.AlertContentView
              text={`You’re about to delete ${Util.pluralize(
                'recipient',
                recipientsRef.current.length,
                true,
              )} from this message.`}
              actions={
                <>
                  <WebUI.AlertActionButton
                    onClick={(event) => {
                      onRecipientsChangeRef.current([])
                      event.preventDefault()
                    }}
                  >
                    Delete
                  </WebUI.AlertActionButton>
                  <WebUI.AlertCancelButton />
                </>
              }
            />
          </WebUI.Alert>
        ),
        cell: ({row: {original: recipient}}) => (
          <WebUI.IconButton
            className="text-ds-md"
            size="default_alt"
            onClick={() =>
              onRecipientsChangeRef.current(
                recipientsRef.current.filter(
                  (r) => r.email !== recipient.email,
                ) as any,
              )
            }
          >
            <WebUI.PhosphorIcon icon="trash" />
          </WebUI.IconButton>
        ),
      }),
    ],
    [columnHelper],
  )

  return (
    <WebUI.TableView
      className={`[&_.TableViewCell[data-columnid="delete"]]:h-auto [&_.TableViewCell]:h-9 [&_.TableViewRow]:py-2`}
      data={recipients as Recipient[]}
      columns={columns}
      {...restProps}
    />
  )
}
