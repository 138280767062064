import * as WebUI from '@cheddarup/web-ui'

import type {FixedItemFormFormik} from '../../../../../containers/FixedItemForm/FixedItemForm'

export interface ItemVariantsListingMarkAsSoldModalProps {
  formik: FixedItemFormFormik
  listingsUuids: string[]
  onDismiss?: () => void
}

const ItemVariantsListingMarkAsSoldModal = ({
  formik,
  listingsUuids,
  onDismiss,
}: ItemVariantsListingMarkAsSoldModalProps) => (
  <WebUI.Alert
    aria-label="Mark variants as sold out"
    initialVisible
    onDidHide={onDismiss}
  >
    <WebUI.AlertHeader>Mark selected variants as sold out</WebUI.AlertHeader>
    <WebUI.AlertContentView
      text="This will mark the selected variants as sold out, do you wish to continue?"
      actions={
        <>
          <WebUI.AlertActionButton
            execute={() => {
              formik.setFieldValue(
                'options.variants.listings',
                formik.values.options.variants.listings.map((listing) =>
                  listingsUuids.includes(listing.uuid)
                    ? {
                        ...listing,
                        available_quantity: 0,
                      }
                    : listing,
                ),
              )
            }}
          >
            Mark Sold Out
          </WebUI.AlertActionButton>
          <WebUI.AlertCancelButton />
        </>
      }
    />
  </WebUI.Alert>
)

export default ItemVariantsListingMarkAsSoldModal
