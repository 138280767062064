import {ForwardRefComponent} from '@cheddarup/react-util'
import * as WebUI from '@cheddarup/web-ui'
import React from 'react'

// MARK: – AccountSettingsContentLayout

export interface AccountSettingsContentLayoutProps
  extends React.ComponentPropsWithoutRef<'div'> {
  heading?: React.ReactNode
  body?: React.ReactNode
}

export const AccountSettingsContentLayout = ({
  heading,
  body,
  className,
  children,
  ...restProps
}: AccountSettingsContentLayoutProps) => (
  <div className={WebUI.cn('flex flex-col gap-7', className)} {...restProps}>
    {typeof heading === 'string' ? (
      <WebUI.Heading as="h2">{heading}</WebUI.Heading>
    ) : (
      heading
    )}
    {typeof body === 'string' ? (
      <span className="text-ds-base">{body}</span>
    ) : (
      body
    )}
    {children}
  </div>
)

// MARK: – AccountSettingsContentCard

export interface AccountSettingsContentCardProps {
  heading?: React.ReactNode
}

export const AccountSettingsContentCard = React.forwardRef(
  ({heading, className, children, ...restProps}, forwardedRef) => (
    <WebUI.Card
      ref={forwardedRef}
      className={WebUI.cn(
        'AccountSettingsContentCard flex flex-col gap-6 px-6 py-4',
        className,
      )}
      {...restProps}
    >
      {typeof heading === 'string' ? (
        <WebUI.Heading className="font-bold" as="h5">
          {heading}
        </WebUI.Heading>
      ) : (
        heading
      )}

      {children}
    </WebUI.Card>
  ),
) as ForwardRefComponent<'div', AccountSettingsContentCardProps>
