import {useNavigate} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import {LinkButton} from 'src/components/LinkButton'

const MakeItSecurePage = () => {
  const navigate = useNavigate()
  return (
    <WebUI.Alert
      aria-label="Two factor auth inquiry"
      initialVisible
      onDidHide={() => navigate('..')}
    >
      <WebUI.AlertHeader>Make it secure</WebUI.AlertHeader>
      <WebUI.AlertContentView
        text="To help keep your account secure, we use two-factor authentication. You’ll need to complete your Security settings before taking this action."
        actions={
          <WebUI.AlertActionButton as={LinkButton} to="../../security">
            Complete Security Settings
          </WebUI.AlertActionButton>
        }
      />
    </WebUI.Alert>
  )
}

export default MakeItSecurePage
