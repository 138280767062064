import {Group as ReakitGroup} from 'reakit'
import {
  ForwardRefComponent,
  useLiveRef,
  useUpdateEffect,
} from '@cheddarup/react-util'
import React, {useContext, useMemo, useState} from 'react'

import {Stack, StackProps} from './Stack'
import {Button} from './Button'
import {cn} from '../utils'

interface InternalToggleGroupContextValue {
  selectedValue: string | null
  setSelectedValue: React.Dispatch<React.SetStateAction<string | null>>
}

const InternalToggleGroupContext = React.createContext(
  {} as InternalToggleGroupContextValue,
)

export interface ToogleGroupProps
  extends StackProps,
    React.ComponentPropsWithoutRef<'div'> {
  defaultValue?: string
  value?: string | null
  onValueChange?: (newValue: string | null) => void
}

export const ToggleGroup = React.forwardRef<HTMLDivElement, ToogleGroupProps>(
  (
    {defaultValue, value, onValueChange, className, ...restProps},
    forwardedRef,
  ) => {
    const [selectedValue, setSelectedValue] = useState<string | null>(
      value ?? defaultValue ?? null,
    )
    const onValueChangeRef = useLiveRef(onValueChange)

    const contextValue = useMemo(
      () => ({
        selectedValue,
        setSelectedValue,
      }),
      [selectedValue],
    )

    useUpdateEffect(() => {
      if (value !== undefined) {
        setSelectedValue(value)
      }
    }, [value])

    useUpdateEffect(() => {
      onValueChangeRef.current?.(contextValue.selectedValue)
    }, [contextValue.selectedValue])

    return (
      <InternalToggleGroupContext.Provider value={contextValue}>
        <ReakitGroup
          ref={forwardedRef}
          as={Stack}
          className={cn('ToggleGroup', 'gap-2', className)}
          {...restProps}
        />
      </InternalToggleGroupContext.Provider>
    )
  },
)

// MARK: – ToggleGroupItem

export interface ToggleGroupItemProps {
  value: string
}

export const ToggleGroupItem = React.forwardRef(
  (
    {as: Comp = Button, variant, value, className, onClick, ...restProps},
    forwardedRef,
  ) => {
    const context = useContext(InternalToggleGroupContext)
    const isChecked = context.selectedValue === value

    return (
      <Comp
        ref={forwardedRef}
        role="radio"
        aria-checked={isChecked}
        className={cn('ToggleGroupItem', className)}
        variant={variant ?? (isChecked ? 'default' : 'secondary')}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          onClick?.(event)

          if (event.defaultPrevented) {
            return
          }

          context.setSelectedValue(value)
        }}
        {...restProps}
      />
    )
  },
) as ForwardRefComponent<typeof Button, ToggleGroupItemProps>
