import queryString, {ParsedQuery} from 'query-string'

import {objectFromObject} from './object-utils'
import {getTokens, replaceTokens} from './string-utils'
import type {ExtractParams} from './type-utils'

// MARK: – Search params

type AnyParsedQuery = ParsedQuery<string | boolean | number>
type AnyParsedQueryKey = keyof AnyParsedQuery
type AnyParsedQueryValue = AnyParsedQuery[AnyParsedQueryKey]

export type Predicate =
  | AnyParsedQueryKey[]
  | ((key: AnyParsedQueryKey, value: AnyParsedQueryValue) => boolean)

export function pickSearchParams(search: string, predicate: Predicate) {
  const searchObj = queryString.parse(search)

  const filter = (key: AnyParsedQueryKey, value: AnyParsedQueryValue) =>
    typeof predicate === 'function'
      ? predicate(key, value)
        ? value
        : undefined
      : predicate.includes(key)
        ? value
        : undefined

  const filteredSearchObj = objectFromObject(searchObj, filter)

  return queryString.stringify(filteredSearchObj)
}

export function excludeSearchParams(search: string, predicate: Predicate) {
  return pickSearchParams(
    search,
    typeof predicate === 'function'
      ? (key, value) => !predicate(key, value)
      : (key) => !predicate.includes(key),
  )
}

export function mergeSearchParams(
  search: string,
  _newSearchParams:
    | Record<AnyParsedQueryKey, AnyParsedQueryValue | undefined>
    | string,
) {
  let newSearchParams = _newSearchParams
  if (typeof newSearchParams === 'string') {
    newSearchParams = queryString.parse(newSearchParams)
  }
  const searchObj = queryString.parse(search)

  return queryString.stringify({
    ...searchObj,
    ...newSearchParams,
  })
}

// MARK: – Path tokens

const pathParamRegex = /:[^/]+/g

export function replacePathTokens<T extends string>(
  path: T,
  pathParams: ExtractParams<T>,
) {
  return replaceTokens(path, pathParams, pathParamRegex, (match) =>
    match.slice(1),
  )
}

export function getPathTokens<T extends string>(path: T) {
  return getTokens(path, pathParamRegex)
}

export function prependProtocol(url: string): string {
  let _url = url.trim()
  if (!_url.match(/^[a-zA-Z]+:\/\//)) {
    _url = `https://${_url}`
  }
  return _url
}

export function cleanUrl(str: string): string | null {
  try {
    const url = new URL(str)
    let cleanedUrl = `${url.protocol}//${url.hostname}`

    const portMatch = str.match(/:(\d+)/)
    if (portMatch) {
      cleanedUrl += `:${portMatch[1]}`
    } else if (url.port) {
      cleanedUrl += `:${url.port}`
    }

    if (url.pathname !== '/') {
      cleanedUrl += url.pathname
    }

    return cleanedUrl
  } catch (error) {
    return null
  }
}

/**
 * Configuration options for URL validation
 */
interface UrlValidationOptions {
  /** Whether to require protocol (http/https). Default is true. */
  requireProtocol?: boolean
  /** Whether to allow data URLs. Default is false. */
  allowDataUrl?: boolean
  /** Array of allowed protocols. Default is ['http:', 'https:']. */
  allowedProtocols?: string[]
}

/**
 * Checks if a given string is a valid URL.
 *
 * @param {string} str - The string to check.
 * @param {UrlValidationOptions} options - Optional configuration object.
 * @returns {boolean} - True if the string is a valid URL, false otherwise.
 *
 * @example
 * isUrl('https://www.example.com'); // true
 * isUrl('example.com', { requireProtocol: false }); // true
 * isUrl('not a url'); // false
 * isUrl('data:text/plain;base64,SGVsbG8sIFdvcmxkIQ==', { allowDataUrl: true }); // true
 */
export function isUrl(
  str: string,
  options: UrlValidationOptions = {},
): boolean {
  const {
    requireProtocol = true,
    allowDataUrl = false,
    allowedProtocols = ['http:', 'https:'],
  } = options

  if (!str.trim()) {
    return false
  }

  // Handle data URLs
  if (allowDataUrl && str.startsWith('data:')) {
    return /^data:([a-z]+\/[a-z0-9-+.]+)?;?(base64)?,([a-z0-9!$&',()*+;=\-._~:@/?%\s]*)?$/i.test(
      str,
    )
  }

  try {
    const url = new URL(str)

    if (!allowedProtocols.includes(url.protocol)) {
      if (requireProtocol || url.protocol !== 'http:') {
        return false
      }
    }

    return (
      /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/.test(
        url.hostname,
      ) || url.hostname === 'localhost'
    )
  } catch (e) {
    // If protocol is not required, try prepending 'http://' and check again
    if (!requireProtocol) {
      try {
        const urlWithProtocol = new URL(`http://${str}`)
        return (
          /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/.test(
            urlWithProtocol.hostname,
          ) || urlWithProtocol.hostname === 'localhost'
        )
      } catch (e) {
        return false
      }
    }
    return false
  }
}
