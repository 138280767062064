import Hue, {HueProps} from '@uiw/react-color-hue'
import React from 'react'
import {ColorThumb} from './ColorThumb'
import {useColorPicker} from './ColorPicker'
import {toHex} from '@cheddarup/util'
import {hexToHsva, HsvaColor, hsvaToHex} from '@uiw/color-convert'
import {useControlledState} from '@cheddarup/react-util'
import {cn} from '../../utils'
import {tailwindConfig} from '@cheddarup/tailwind-config'

export interface ColorHueSliderProps
  extends Omit<
    HueProps,
    | 'prefixCls'
    | 'pointer'
    | 'background'
    | 'bgProps'
    | 'pointerProps'
    | 'hue'
    | 'onChange'
  > {
  defaultValue?: string
  value?: string
  onValueChange?: (hex: string | null) => void
  thumb?: HueProps['pointer']
}

export const ColorHueSlider = React.forwardRef<
  HTMLDivElement,
  ColorHueSliderProps
>(
  (
    {defaultValue, value: valueProp, onValueChange, thumb, ...restProps},
    forwardedRef,
  ) => {
    const colorPicker = useColorPicker()

    const value = valueProp ?? colorPicker?.value

    const valueAsHex = toHex(value ?? '')
    const defaultValueAsHex = toHex(defaultValue ?? '')

    const initialValue = valueAsHex ? hexToHsva(valueAsHex) : undefined
    const initialDefaultValue = defaultValueAsHex
      ? hexToHsva(defaultValueAsHex)
      : {h: 0, s: 0, v: 68, a: 1}
    const [colorValue, setColorValue] = useControlledState<HsvaColor>(
      initialValue,
      initialDefaultValue,
      (newValue) => {
        if (newValue != null) {
          colorPicker?.setValue(hsvaToHex(newValue))
          onValueChange?.(hsvaToHex(newValue))
        }
      },
    )

    return (
      <Hue
        ref={forwardedRef}
        hue={colorValue?.h}
        radius={tailwindConfig.theme.borderRadius.default}
        onChange={({h: newHue}) => setColorValue({...colorValue, h: newHue})}
        pointer={
          thumb ??
          (({prefixCls, left, top, color}) => (
            <ColorThumb
              className={cn('absolute h-4 w-4', prefixCls)}
              style={{top, left}}
              color={color}
            />
          ))
        }
        {...restProps}
      />
    )
  },
)
