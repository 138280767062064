import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'
import React, {Dispatch, useRef} from 'react'
import {
  useCloseTabsMutation,
  useDeleteTabsMutation,
  useMoveTabsMutation,
} from '@cheddarup/api-client'
import {
  useManagerRole,
  useManagerRoleFolders,
  useManagerRoleId,
} from 'src/components/ManageRoleProvider'

import CreateFolderFormPrompt from './CreateFolderFormPrompt'

export type CollectionListSortBy =
  | 'created_at'
  | 'updated_at'
  | 'name'
  | 'payments_total'
  | 'status'

export interface CollectionListToolbarProps extends WebUI.DataListToolbarProps {
  sortBy: CollectionListSortBy
  sortDirection: 'asc' | 'desc'
  onSortByChange: (sortBy: CollectionListSortBy) => void
  onSortDirectionChange: (sortDirection: 'asc' | 'desc') => void
}

export const CollectionListToolbar = ({
  sortBy,
  sortDirection,
  onSortByChange,
  onSortDirectionChange,
  ...restProps
}: CollectionListToolbarProps) => {
  const [managerRole] = useManagerRole()
  return (
    <WebUI.DataListToolbar
      selectedAllHidden={managerRole?.permissions?.role === 'viewer'}
      {...restProps}
    >
      <WebUI.DropdownSelect<CollectionListSortBy>
        size="small"
        value={sortBy}
        onValueChange={(newSortBy) => {
          if (!newSortBy) {
            return
          }

          onSortByChange(newSortBy)
          onSortDirectionChange(
            // @ts-expect-error
            {name: 'asc', status: 'asc'}[newSortBy] ?? 'desc',
          )
        }}
        onStateChange={(newState) => {
          // value changed to an already selected value
          if (
            [
              WebUI.useSelect.stateChangeTypes.ItemClick,
              WebUI.useSelect.stateChangeTypes.ToggleButtonKeyDownEnter,
              WebUI.useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton,
            ].includes(newState.type as any) &&
            !newState.selectedItem
          ) {
            onSortDirectionChange(sortDirection === 'asc' ? 'desc' : 'asc')
          }
        }}
      >
        <WebUI.DropdownSelectOption value="created_at">
          Created Date
        </WebUI.DropdownSelectOption>
        <WebUI.DropdownSelectOption value="updated_at">
          Updated Date
        </WebUI.DropdownSelectOption>
        <WebUI.DropdownSelectOption value="name">
          Name
        </WebUI.DropdownSelectOption>
        <WebUI.DropdownSelectOption value="payments_total">
          Payments Total
        </WebUI.DropdownSelectOption>
        <WebUI.DropdownSelectOption value="status">
          Status
        </WebUI.DropdownSelectOption>
      </WebUI.DropdownSelect>
    </WebUI.DataListToolbar>
  )
}

// MARK: – CollectionListBulkActionsToolbar

export interface CollectionListBulkActionsToolbarProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collections: Api.Tab[]
  defaultFolderName: string
  listDispatch: Dispatch<WebUI.DataListAction>
  listState: WebUI.DataListState
}

export const CollectionListBulkActionsToolbar = ({
  collections,
  defaultFolderName,
  listState,
  listDispatch,
  ...restProps
}: CollectionListBulkActionsToolbarProps) => {
  const [managerRoleId] = useManagerRoleId()
  const moveCollectionsMutation = useMoveTabsMutation()
  const createFolderFormPromptRef = useRef<WebUI.DialogInstance>(null)

  const selectedCollectionIds = Object.keys(
    Util.pickBy(listState.selectedItemIdsMap, (v) => v === true),
  ).map(Number)

  const deselectAll = () =>
    listDispatch({
      type: 'SET_ALL_ITEMS_SELECTED',
      value: false,
    })

  return (
    <WebUI.DataListToolbar
      className="[&_.DataListToolbar-inner]:justify-start"
      {...restProps}
    >
      <MoveCollectionToFolderDropdownSelect
        defaultFolderName={defaultFolderName}
        collectionIds={selectedCollectionIds}
        onCreateFolder={() => createFolderFormPromptRef.current?.show()}
        onDidMove={() => deselectAll()}
      />
      <CloseCollectionsAlert
        collectionIds={selectedCollectionIds}
        disclosure={
          <WebUI.DialogDisclosure
            as={WebUI.DataListToolbarBulkActionButton}
            iconBefore={<WebUI.PhosphorIcon icon="calendar-x" />}
          >
            Close
          </WebUI.DialogDisclosure>
        }
        onDidClose={() => deselectAll()}
      />
      {managerRoleId == null && (
        <DeleteCollectionsAlert
          collections={collections.filter((c) =>
            selectedCollectionIds.includes(c.id),
          )}
          onDidDelete={() => deselectAll()}
          disclosure={
            <WebUI.DialogDisclosure
              as={WebUI.DataListToolbarBulkActionButton}
              iconBefore={<WebUI.PhosphorIcon icon="trash" />}
            >
              Delete
            </WebUI.DialogDisclosure>
          }
        />
      )}

      <CreateFolderFormPrompt
        ref={createFolderFormPromptRef}
        subheading="Once created, add collections to a folder by selecting or drag and drop."
        onDidCreateFolder={(newFolder) =>
          moveCollectionsMutation.mutate({
            body: {
              collection_ids: selectedCollectionIds,
              folder_id: newFolder.id,
            },
          })
        }
        onDidHide={() => deselectAll()}
      />
    </WebUI.DataListToolbar>
  )
}

// MARK: – CloseCollectionsAlert

export interface CloseCollectionsAlertProps extends WebUI.AlertProps {
  collectionIds: number[]
  onDidClose: () => void
}

export const CloseCollectionsAlert = ({
  collectionIds,
  onDidClose,
  ...restProps
}: CloseCollectionsAlertProps) => {
  const closeCollectionsMutation = useCloseTabsMutation()
  return (
    <WebUI.Alert aria-label="Close collections confirmation" {...restProps}>
      <WebUI.AlertHeader>Are you sure?</WebUI.AlertHeader>
      <WebUI.AlertContentView
        text="Closing a collection makes it inactive, which means you can no longer receive payments on it. All reporting remains intact."
        actions={
          <>
            <WebUI.AlertActionButton
              execute={() =>
                closeCollectionsMutation.mutateAsync({
                  body: {collection_ids: collectionIds},
                })
              }
              onDidExecute={onDidClose}
            >
              Close
            </WebUI.AlertActionButton>
            <WebUI.AlertCancelButton />
          </>
        }
      />
    </WebUI.Alert>
  )
}

// MARK: – MoveCollectionToFolderDropdownSelect

interface MoveCollectionToFolderDropdownSelectProps
  extends WebUI.DropdownSelectProps<number | 'CREATE_NEW'> {
  defaultFolderName: string
  collectionIds: number[]
  onDidMove: () => void
  onCreateFolder: () => void
}

const MoveCollectionToFolderDropdownSelect = ({
  defaultFolderName,
  collectionIds,
  onDidMove,
  onCreateFolder,
  ...restProps
}: MoveCollectionToFolderDropdownSelectProps) => {
  const moveCollectionsMutation = useMoveTabsMutation()
  const [managerRoleId] = useManagerRoleId()
  const managerRoleFolders = useManagerRoleFolders()

  if (!managerRoleFolders || managerRoleFolders.length < 2) {
    return null
  }

  return (
    <WebUI.DropdownSelect<number | 'CREATE_NEW'>
      size="small"
      placeholder="Move to Folder"
      disclosure={
        <WebUI.DropdownSelectButton
          className="px-4"
          as={WebUI.DataListToolbarBulkActionButton}
          iconBefore={<WebUI.PhosphorIcon icon="arrow-right" />}
        >
          Move to Folder
        </WebUI.DropdownSelectButton>
      }
      onValueChange={(newValue) => {
        if (newValue === 'CREATE_NEW') {
          onCreateFolder()
        } else {
          const newFolderId = newValue
          moveCollectionsMutation.mutate({
            body: {
              collection_ids: collectionIds,
              folder_id:
                newFolderId === -1 || newFolderId == null ? null : newFolderId,
            },
          })
          onDidMove()
        }
      }}
      {...restProps}
    >
      {managerRoleFolders.map((f) => (
        <WebUI.DropdownSelectOption key={f.id ?? -1} value={f.id}>
          {f.name}
        </WebUI.DropdownSelectOption>
      ))}
      {managerRoleId == null && (
        <WebUI.DropdownSelectOption variant="link" value="CREATE_NEW">
          Create new
        </WebUI.DropdownSelectOption>
      )}
    </WebUI.DropdownSelect>
  )
}

// MARK: – DeleteCollectionsAlert

export interface DeleteCollectionsAlertProps extends WebUI.AlertProps {
  collections: Api.Tab[]
  onDidDelete: () => void
}

export const DeleteCollectionsAlert = ({
  collections,
  onDidDelete,
  className,
  ...restProps
}: DeleteCollectionsAlertProps) => {
  const deleteCollectionsMutation = useDeleteTabsMutation()
  const undeleteableCollections = collections.filter((c) => !c.access.canDelete)
  return (
    <WebUI.Alert
      aria-label="Delete collections confirmation"
      className={WebUI.cn('[&_>_.ModalContentView]:overflow-y-auto', className)}
      {...restProps}
    >
      <WebUI.AlertHeader>Are you sure?</WebUI.AlertHeader>
      <WebUI.AlertContentView
        text={
          <WebUI.VStack className="gap-8">
            <span>
              This deletes the collection page itself as well as all payer data
              and information and cannot be undone.
            </span>
            {undeleteableCollections.length > 0 && (
              <WebUI.VStack className="max-h-full gap-2 overflow-y-auto">
                <span>
                  The following collections won‘t be deleted, they either have
                  pending balance or you're not the owner:
                </span>
                <WebUI.VStack className="gap-1">
                  {undeleteableCollections.map((c) => (
                    <WebUI.Ellipsis key={c.id}>{c.name}</WebUI.Ellipsis>
                  ))}
                </WebUI.VStack>
              </WebUI.VStack>
            )}
          </WebUI.VStack>
        }
        actions={
          <>
            <WebUI.AlertActionButton
              execute={() =>
                deleteCollectionsMutation.mutateAsync({
                  body: {collection_ids: collections.map((c) => c.id)},
                })
              }
              onDidExecute={onDidDelete}
            >
              Delete
            </WebUI.AlertActionButton>
            <WebUI.AlertCancelButton />
          </>
        }
      />
    </WebUI.Alert>
  )
}
