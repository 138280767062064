import {deepPartialify, z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {tabDiscountSchema} from '../schemas'

export default {
  list: makeEndpoint({
    path: 'users/tabs/:tabId/discounts',
    responseSchema: tabDiscountSchema.array(),
  }),
  create: makeEndpoint({
    path: 'users/tabs/:tabId/discounts',
    method: 'POST',
    bodySchema: deepPartialify(tabDiscountSchema),
    responseSchema: tabDiscountSchema,
  }),
  update: makeEndpoint({
    path: 'users/tabs/:tabId/discounts/:discountId',
    method: 'PATCH',
    bodySchema: deepPartialify(
      tabDiscountSchema.extend({
        discounts_enabled: z.boolean(),
      }),
    ),
    responseSchema: tabDiscountSchema,
  }),
  delete: makeEndpoint({
    path: 'users/tabs/:tabId/discounts/:discountId',
    method: 'DELETE',
    responseSchema: z.any(),
  }),
}
