import {defaultBrandKitColorMap} from '@cheddarup/core'
import {getReadableColor} from '@cheddarup/util'

export function getCUReadableColor(
  backgroundColor: string,
  contentColors?: {lightColor?: string; darkColor?: string},
) {
  if (
    backgroundColor === defaultBrandKitColorMap.primary ||
    backgroundColor === defaultBrandKitColorMap.secondary ||
    backgroundColor == null
  ) {
    return contentColors?.lightColor ?? defaultBrandKitColorMap.white
  }
  return getReadableColor(backgroundColor, contentColors)
}
