import {z} from 'zod'

type ZodObjectDeepPartial<
  T extends z.AnyZodObject,
  S extends T['shape'] = T['shape'],
> = z.ZodObject<
  {
    [K in keyof S]: z.ZodOptional<ZodDeepPartial<S[K]>>
  },
  T['_def']['unknownKeys'],
  T['_def']['catchall']
>

type ZodArrayDeepPartial<T extends z.ZodArray<any>> = z.ZodArray<
  ZodDeepPartial<T['element']>,
  T['_def']['minLength'] extends number ? 'atleastone' : 'many'
>

type ZodTupleDeepPartial<T extends z.AnyZodTuple> = T extends z.ZodTuple<
  infer Items
>
  ? {
      [k in keyof Items]: Items[k] extends z.ZodTypeAny
        ? ZodDeepPartial<Items[k]>
        : never
    } extends infer PI
    ? PI extends z.ZodTupleItems
      ? z.ZodTuple<PI>
      : never
    : never
  : never

type ZodOptionalDeepPartial<T extends z.ZodOptional<any>> = z.ZodOptional<
  ZodDeepPartial<T['_def']['innerType']>
>

type ZodNullableDeepPartial<T extends z.ZodNullable<any>> = z.ZodNullable<
  ZodDeepPartial<T['_def']['innerType']>
>

type ZodNullishDeepPartial<T extends z.ZodOptional<z.ZodNullable<any>>> =
  z.ZodOptional<
    z.ZodNullable<ZodDeepPartial<T['_def']['innerType']['_def']['innerType']>>
  >

type ZodUnwrap<T extends z.ZodOptional<any> | z.ZodNullable<any>> =
  T extends z.ZodOptional<z.ZodNullable<any>>
    ? ZodNullishDeepPartial<T>
    : T extends z.ZodOptional<any>
      ? ZodOptionalDeepPartial<T>
      : T extends z.ZodNullable<any>
        ? ZodNullableDeepPartial<T>
        : never

// See https://github.com/colinhacks/zod/blob/8552233c77426f77d3586cc877f7aec1aa0aa45b/src/helpers/partialUtil.ts#L38
// Optimized to avoid instantiation depth error
// TODO: needs more optimizations
export type ZodDeepPartial<T extends z.ZodTypeAny> = T extends
  | z.ZodOptional<any>
  | z.ZodNullable<any>
  ? ZodUnwrap<T>
  : T extends z.AnyZodObject
    ? ZodObjectDeepPartial<T>
    : T extends z.ZodArray<any>
      ? ZodArrayDeepPartial<T>
      : T extends z.AnyZodTuple
        ? ZodTupleDeepPartial<T>
        : T

export function deepPartialify<T extends z.ZodObject<any>>(
  schema: T,
): ZodDeepPartial<T> {
  return deepPartialifyImpl(schema)
}

// See https://github.com/colinhacks/zod/blob/8552233c77426f77d3586cc877f7aec1aa0aa45b/src/types.ts#L2382
function deepPartialifyImpl(schema: z.ZodType<any>): any {
  if (schema instanceof z.ZodObject) {
    const newShape: any = {}

    for (const key in schema.shape) {
      const fieldSchema = schema.shape[key]
      newShape[key] = z.ZodOptional.create(deepPartialifyImpl(fieldSchema))
    }
    return new z.ZodObject({
      ...schema._def,
      shape: () => newShape,
    })
  }
  if (schema instanceof z.ZodArray) {
    return new z.ZodArray({
      ...schema._def,
      type: deepPartialifyImpl(schema.element),
    })
  }
  if (schema instanceof z.ZodOptional) {
    return z.ZodOptional.create(deepPartialifyImpl(schema.unwrap()))
  }
  if (schema instanceof z.ZodNullable) {
    return z.ZodNullable.create(deepPartialifyImpl(schema.unwrap()))
  }
  if (schema instanceof z.ZodTuple) {
    return z.ZodTuple.create(
      schema.items.map((item: any) => deepPartialifyImpl(item)),
    )
  }
  return schema
}

export {z}
