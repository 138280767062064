import {endpoints} from '../../endpoints'
import {makeQueryUpdate, makeUseMutation} from '../use-mutation'

export const useConvertTemplateToTabMutation = makeUseMutation(
  endpoints.templates.convertToTab,
  () => ({
    regular: (newTab) => [
      makeQueryUpdate(endpoints.tabs.list, (tabs) =>
        tabs ? [...tabs, newTab] : tabs,
      ),
    ],
  }),
)
