import {BrowserRouter} from 'react-router-dom'
import ReactDOM from 'react-dom/client'

import './init'
import './index.css'
import '../global.css'
import {RootLayout} from './RootLayout'
import {SentryErrorBoundary} from './components/ErrorBoundary'

// biome-ignore lint/style/noNonNullAssertion:
ReactDOM.createRoot(document.querySelector('#root')!).render(
  <BrowserRouter>
    <SentryErrorBoundary>
      <RootLayout />
    </SentryErrorBoundary>
  </BrowserRouter>,
)
