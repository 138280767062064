import {
  api,
  useCreateInventoryGroupMutation,
  useUpdateInventoryGroupMutation,
} from '@cheddarup/api-client'
import * as Yup from 'yup'
import {useFormik} from '@cheddarup/react-util'
import * as WebUI from '@cheddarup/web-ui'
import {SearchAndSelectableItems} from 'src/components'
import {readApiError} from 'src/helpers/error-formatting'
import {useRef} from 'react'

interface ItemQuantityGroupsFormValues {
  name: string
  available_quantity: string
  inventory_item_ids: number[]
}

export interface AddItemQuantityGroupsModalProps extends WebUI.ModalProps {
  collectionId: number
  inventoryGroup?: Api.InventoryGroup | null
}

const AddItemQuantityGroupsModal: React.FC<AddItemQuantityGroupsModalProps> = ({
  collectionId,
  inventoryGroup,
  initialVisible = false,
  onDidHide,
  className,
  ...restProps
}) => {
  const dialogRef = useRef<WebUI.DialogInstance | null>(null)
  const groupableItemsQuery = api.tabItems.list.useQuery(
    {
      pathParams: {
        tabId: collectionId,
      },
    },
    {
      select: (items) =>
        items.filter(
          (item) =>
            item.amount_type !== 'open' && !item.options.variants?.enabled,
        ),
    },
  )
  const createInventoryGroupMutation = useCreateInventoryGroupMutation()
  const updateInventoryGroupMutation = useUpdateInventoryGroupMutation()
  const growlActions = WebUI.useGrowlActions()

  const formik = useFormik<ItemQuantityGroupsFormValues>({
    enableReinitialize: true,
    initialValues: {
      name: inventoryGroup?.name ?? '',
      available_quantity: inventoryGroup
        ? String(inventoryGroup.available_quantity)
        : '',
      inventory_item_ids:
        inventoryGroup?.inventory_items.map((ii) => ii.id) ?? [],
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required'),
      available_quantity: Yup.number()
        .typeError('Required')
        .required('Required')
        .min(0, "Quantity can't be negative"),
    }),
    onSubmit: async (values) => {
      const body = {
        ...values,
        available_quantity: Number(values.available_quantity),
      }

      try {
        if (inventoryGroup) {
          await updateInventoryGroupMutation.mutateAsync({
            pathParams: {
              tabId: collectionId,
              inventoryGroupId: inventoryGroup.id,
            },
            body,
          })
        } else {
          await createInventoryGroupMutation.mutateAsync({
            pathParams: {
              tabId: collectionId,
            },
            body,
          })
        }
        dialogRef.current?.hide()
      } catch (err) {
        growlActions.show('error', {title: 'Error', body: readApiError(err)})
      }
    },
  })

  return (
    <WebUI.Modal
      ref={dialogRef}
      className={WebUI.cn(
        '[&_>_.ModalContentView]:max-w-screen-sm [&_>_.ModalContentView]:rounded-large [&_>_.ModalContentView]:p-10',
        className,
      )}
      initialVisible={initialVisible}
      onDidHide={() => {
        formik.resetForm()

        onDidHide?.()
      }}
      {...restProps}
    >
      <WebUI.ModalCloseButton />
      <form
        className="flex flex-col gap-6 overflow-hidden"
        onSubmit={(event) => {
          event.preventDefault()

          if (formik.values.inventory_item_ids.length > 1) {
            formik.handleSubmit(event)
          } else {
            growlActions.show('error', {
              body: 'Please select at least 2 items',
              title: 'Error',
            })
          }
        }}
        onReset={formik.handleReset}
        noValidate
      >
        <div className="flex flex-col gap-6 overflow-hidden">
          <WebUI.Heading as="h2">
            {inventoryGroup ? 'Edit' : 'Add'} Item Quantity Group
          </WebUI.Heading>

          <WebUI.FormFieldGroup>
            <WebUI.FormField
              label="Name this Group"
              error={formik.errors.name}
              required
            >
              <WebUI.Input
                name="name"
                value={formik.values.name}
                placeholder="Name this Group"
                maxLength={20}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </WebUI.FormField>
            <WebUI.FormField
              label="Total Quantity Available"
              error={formik.errors.available_quantity}
              required
            >
              <WebUI.NumberInput
                name="available_quantity"
                value={formik.values.available_quantity}
                placeholder="Total Quantity Available"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </WebUI.FormField>
          </WebUI.FormFieldGroup>
          <WebUI.FormFieldLabel required>Select Items</WebUI.FormFieldLabel>
          <SearchAndSelectableItems
            className="overflow-y-auto pb-8"
            searchVisible={false}
            items={groupableItemsQuery.data ?? []}
            selectedItemIds={
              groupableItemsQuery.data
                ?.filter((i) =>
                  i.inventory_items.some((ii) =>
                    formik.values.inventory_item_ids.includes(ii.id),
                  ),
                )
                .map((i) => i.id) ?? []
            }
            onChangeSelectedItemsIds={(newItemIds) => {
              const iiIds = newItemIds
                .map((iId) =>
                  groupableItemsQuery.data?.find((i) => i.id === iId),
                )
                .flatMap((i) =>
                  i?.inventory_items.filter((ii) => ii.variant_uuid === 'NONE'),
                )
                .filter((i) => i != null)
                .map((ii) => ii.id)

              formik.setFieldValue('inventory_item_ids', iiIds)
            }}
          />
        </div>

        <WebUI.Button
          className="shrink-0 self-start"
          type="submit"
          loading={formik.isSubmitting}
        >
          Apply Quantity Across {formik.values.inventory_item_ids.length} Items
        </WebUI.Button>
      </form>
    </WebUI.Modal>
  )
}

export default AddItemQuantityGroupsModal
