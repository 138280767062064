import React, {useState} from 'react'
import {
  WithErrorBoundary,
  WithErrorBoundaryProps,
  useTimeout,
} from '@cheddarup/react-util'

import {Skeleton} from './Skeleton'
import {cn} from '../utils'

export interface ImageProps
  extends Omit<
      React.ComponentPropsWithoutRef<'img'>,
      'src' | 'srcSet' | 'onError'
    >,
    Pick<WithErrorBoundaryProps, 'onError'> {
  width?: React.ComponentPropsWithoutRef<'img'>['width']
  height?: React.ComponentPropsWithoutRef<'img'>['height']
  ignoreSizeStyling?: boolean
  aspectRatio?: number
  src?: React.ComponentPropsWithoutRef<'img'>['src'] | null // falsy values render error state
  alt: string
  delay?: number
  fallback?: React.ReactNode
  errorFallback?: React.ReactNode
}

export const Image = React.forwardRef<HTMLImageElement, ImageProps>(
  (
    {
      width: widthProp,
      height: heightProp,
      ignoreSizeStyling = false,
      aspectRatio: aspectRatioProp,
      delay,
      onError,
      className,
      style,
      loading = 'lazy',
      decoding = 'async',
      src,
      fallback: fallbackProp,
      errorFallback: errorFallbackProp,
      ...restProps
    },
    forwardedRef,
  ) => {
    const [canRenderFallback, setCanRenderFallback] = useState(delay == null)

    delay != null && useTimeout(() => setCanRenderFallback(true), delay)

    const aspectRatio =
      widthProp == null || heightProp == null ? aspectRatioProp : undefined
    const width =
      widthProp ??
      (typeof heightProp === 'number' && typeof aspectRatio === 'number'
        ? heightProp * aspectRatio
        : undefined)
    const height =
      heightProp ??
      (typeof widthProp === 'number' && typeof aspectRatio === 'number'
        ? widthProp / aspectRatio
        : undefined)

    const fallback = fallbackProp ?? (
      <Skeleton
        containerClassName="rounded-[inherit] w-full h-full"
        className="[&.Skeleton]:rounded-[inherit]"
        width="100%"
        height="100%"
      />
    )
    const errorFallback = errorFallbackProp ?? (
      <div style={ignoreSizeStyling ? undefined : {width, height}} />
    )

    return (
      <div
        className={cn('Image', 'flex flex-col', className)}
        style={
          ignoreSizeStyling ? style : {width, height, aspectRatio, ...style}
        }
      >
        {!src && errorFallback}
        {!!src && (
          <WithErrorBoundary fallback={errorFallback} onError={onError}>
            <React.Suspense
              fallback={
                canRenderFallback ? (
                  fallback
                ) : (
                  <div
                    style={
                      ignoreSizeStyling
                        ? undefined
                        : {width, height, aspectRatio}
                    }
                  />
                )
              }
            >
              {/* biome-ignore lint/a11y/useAltText: */}
              <img
                ref={forwardedRef}
                className={cn('Image-image rounded-[inherit]', className)}
                style={
                  ignoreSizeStyling
                    ? style
                    : {width, height, aspectRatio, ...style}
                }
                width={width}
                height={height}
                loading={loading}
                decoding={decoding}
                src={src}
                {...restProps}
              />
            </React.Suspense>
          </WithErrorBoundary>
        )}
      </div>
    )
  },
)
