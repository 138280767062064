import React, {useEffect, useState} from 'react'
import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'
import {
  PremiumFeatureSideSheetDisclosure,
  PremiumFeaturesSideSheet,
} from 'src/components/PremiumFeaturesSideSheet'

import ItemVariantsFormSection from '../../components/ItemFormDetails/ItemVariantsFormSection'
import type {FixedItemFormFormik} from './FixedItemForm'
import {EinVerificationUpsellDisclosure} from '../../components'
import {useManagerRoleId} from 'src/components/ManageRoleProvider'

export interface ItemFormDetailsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  formik: FixedItemFormFormik
  collectionId: number
  item?: Api.TabItem | null
  itemImages: any[]
}

const ItemFormDetails: React.FC<ItemFormDetailsProps> = ({
  formik,
  collectionId,
  item,
  itemImages,
  className,
  ...restProps
}) => {
  const [enforcedPremiumMeta, setEnforcedPremiumMeta] = useState<
    Util.PartialDeep<Api.TabPremiumMeta>
  >({})
  const [managerRoleId] = useManagerRoleId()
  const tabQuery = api.tabs.detail.useQuery({pathParams: {tabId: collectionId}})
  const categoriesQuery = api.tabCategories.list.useQuery({
    pathParams: {tabId: collectionId},
  })
  const growlActions = WebUI.useGrowlActions()

  const isBasicCollection = !tabQuery.data?.is_pro
  const subcategories =
    categoriesQuery.data?.find(
      (category) => String(category.id) === String(formik.values.parent_id),
    )?.options.subcategories ?? []

  useEffect(() => {
    if (isBasicCollection) {
      setEnforcedPremiumMeta((prevEnforcedPremiumMeta) => ({
        ...prevEnforcedPremiumMeta,
        pro: {
          ...prevEnforcedPremiumMeta?.pro,
          itemVariations: formik.values.options.variants.enabled,
        },
      }))
    }
  }, [isBasicCollection, formik.values.options.variants.enabled])

  return (
    <PremiumFeaturesSideSheet
      tabId={collectionId}
      enforcedPremiumMeta={enforcedPremiumMeta}
    >
      <div
        className={WebUI.cn('flex flex-col gap-4', className)}
        {...restProps}
      >
        <div className="flex items-start">
          <div className="flex grow flex-col gap-4">
            <WebUI.FormField
              label="Item Name"
              error={formik.errors.name}
              required
            >
              <WebUI.Input
                name="name"
                className="max-w-[340px]"
                placeholder="Item Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </WebUI.FormField>
            {!formik.values.options.variants.enabled && (
              <WebUI.FormField
                label="Item Price"
                error={formik.errors.amount}
                required
              >
                <WebUI.AmountInput
                  className="max-w-[340px]"
                  name="amount"
                  placeholder="$0"
                  value={formik.values.amount}
                  onValueChange={(newAmount) =>
                    formik.setFieldValue('amount', newAmount)
                  }
                  onBlur={formik.handleBlur}
                />
              </WebUI.FormField>
            )}
            {categoriesQuery.data && categoriesQuery.data.length > 0 && (
              <WebUI.FormField label="Category" error={formik.errors.parent_id}>
                <WebUI.Select
                  className="max-w-[340px] [&_>_.Select-select]:min-h-10"
                  name="parent_id"
                  value={formik.values.parent_id ?? ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="">Add to Category</option>
                  {categoriesQuery.data.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </WebUI.Select>
              </WebUI.FormField>
            )}
            <div className="flex flex-col justify-center gap-3 *:flex-[1_0_0px] sm:flex-row sm:items-center sm:justify-start">
              <div className="flex gap-3">
                {!formik.values.options.quantityDiscount.enabled && (
                  <WebUI.Switch
                    name="options.variants.enabled"
                    size="compact"
                    checked={formik.values.options.variants.enabled}
                    onChange={(event) => {
                      if (
                        item?.inventory_items.find(
                          (ii) =>
                            ii.variant_uuid === 'NONE' &&
                            ii.inventory_groups.length > 0,
                        ) &&
                        event.target.checked
                      ) {
                        growlActions.show('error', {
                          title: 'Error',
                          body: "This item has a quantity group applied to it and can't have variants",
                        })
                        return
                      }

                      formik.handleChange(event)
                    }}
                    onBlur={formik.handleBlur}
                    tabIndex={-1}
                  >
                    Add variations
                  </WebUI.Switch>
                )}
                {isBasicCollection &&
                  formik.values.options.variants.enabled && (
                    <PremiumFeatureSideSheetDisclosure
                      featureKeys={['itemVariations']}
                    />
                  )}
              </div>
              {subcategories.length > 0 && (
                <WebUI.Select
                  name="options.subcategoryId"
                  value={formik.values.options.subcategoryId ?? ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="">Add to Subcategory</option>
                  {subcategories.map((subcategory) => (
                    <option key={subcategory.uuid} value={subcategory.uuid}>
                      {subcategory.name}
                    </option>
                  ))}
                </WebUI.Select>
              )}
            </div>
          </div>
          <EinVerificationUpsellDisclosure
            initialVisible={
              !managerRoleId &&
              !tabQuery.data?.is_team &&
              tabQuery.data?.organizer.verifiedNonProfitStatus === 'verified'
            }
          />
        </div>
        {formik.values.options.variants.enabled && (
          <ItemVariantsFormSection
            collectionId={collectionId}
            formik={formik}
            itemImages={itemImages}
          />
        )}
      </div>
    </PremiumFeaturesSideSheet>
  )
}

export default ItemFormDetails
