import * as Ariakit from '@ariakit/react'
import React, {useContext, useMemo, useState} from 'react'
import {cva} from 'class-variance-authority'

import {VariantsProps, cn} from '../../utils'
import {Merge} from '@cheddarup/util'
import {PhosphorIcon} from '../../icons'

interface InternalRadioGroupContextValue
  extends Pick<RadioProps, 'disabled' | 'size' | 'variant'> {
  name?: string
}

const InternalRadioGroupContext = React.createContext<
  InternalRadioGroupContextValue | undefined
>(undefined)

export interface RadioGroupProps
  extends Ariakit.RadioStoreProps,
    Pick<RadioProps, 'disabled' | 'size' | 'variant'>,
    Omit<Ariakit.RadioGroupProps, 'defaultValue' | 'store' | 'children'> {
  name?: string
  children?: React.ReactNode | ((radio: Ariakit.RadioStore) => React.ReactNode)
}

export const RadioGroup = React.forwardRef<HTMLDivElement, RadioGroupProps>(
  (
    {
      activeId,
      defaultActiveId,
      defaultItems,
      defaultValue,
      focusLoop,
      focusShift,
      focusWrap,
      includesBaseElement,
      items,
      orientation,
      rtl,
      setActiveId,
      setItems,
      setValue,
      store,
      value,
      virtualFocus,
      className,
      name,
      disabled,
      size,
      variant,
      children,
      ...restProps
    },
    forwardedRef,
  ) => {
    const radioStore = Ariakit.useRadioStore({
      activeId,
      defaultActiveId,
      defaultItems,
      defaultValue,
      focusLoop,
      focusShift,
      focusWrap,
      includesBaseElement,
      items,
      orientation,
      rtl,
      setActiveId,
      setItems,
      setValue,
      store,
      value,
      virtualFocus,
    })

    const contextValue = useMemo(
      () => ({
        name,
        disabled,
        size,
        variant,
      }),
      [name, disabled, size, variant],
    )

    return (
      <Ariakit.RadioProvider store={radioStore}>
        <InternalRadioGroupContext.Provider value={contextValue}>
          <Ariakit.RadioGroup
            ref={forwardedRef}
            className={cn('flex flex-col gap-3', className)}
            {...restProps}
          >
            {typeof children === 'function' ? children(radioStore) : children}
          </Ariakit.RadioGroup>
        </InternalRadioGroupContext.Provider>
      </Ariakit.RadioProvider>
    )
  },
)

// MARK: – Radio

export const radio = cva(
  [
    'group/radio relative inline-flex flex-row items-baseline gap-3',
    'font-normal text-grey-600',
    'peer-aria-disabled/input:cursor-not-allowed peer-aria-disabled/input:text-grey-300',
    'data-[disabled=true]:cursor-not-allowed',
  ],
  {
    variants: {
      size: {
        sm: 'text-ds-sm',
        md: 'text-ds-base',
        lg: 'text-ds-md',
        headless: '',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  },
)

export const radioIcon = cva(
  [
    'relative rounded-full border bg-trueWhite transition-colors',
    'inline-flex flex-row items-center',
    'peer-aria-disabled/input:bg-grey-100',
    'group-hover/radio:bg-inputHoverBackground',
  ],
  {
    variants: {
      variant: {
        orange: [
          'peer-aria-checked/input:border-none peer-aria-checked/input:bg-orange-600',
          'peer-aria-checked/input:peer-aria-disabled/input:bg-orange-200',
          'group-data-[focus-visible]/radio:border-orange-600',
        ],
        teal: [
          'peer-aria-checked/input:border-none peer-aria-checked/input:bg-teal-600',
          'peer-aria-checked/input:peer-aria-disabled/input:bg-teal-400',
          'group-data-[focus-visible]/radio:border-teal-600',
        ],
        headless: '',
      },
      size: {
        sm: 'size-[1.14em]',
        md: 'size-[1.25em]',
        lg: 'size-[1.22em]',
        headless: '',
      },
    },
    defaultVariants: {
      variant: 'orange',
      size: 'md',
    },
  },
)

export interface RadioProps
  extends Merge<
    Ariakit.RadioProps,
    VariantsProps<typeof radio & typeof radioIcon>
  > {}

export const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
  (
    {
      size: sizeProp,
      variant: variantProp,
      className,
      disabled: disabledProp,
      onFocusVisible,
      onBlur,
      children,
      ...restProps
    },
    forwardedRef,
  ) => {
    const contextValue = useContext(InternalRadioGroupContext)
    const [isFocusVisible, setIsFocusVisible] = useState(false)

    const size = sizeProp ?? contextValue?.size ?? 'md'
    const variant = variantProp ?? contextValue?.variant ?? 'orange'
    const disabled = contextValue?.disabled ?? disabledProp

    return (
      <Ariakit.Role.label
        data-focus-visible={isFocusVisible || undefined}
        data-disabled={disabled}
        className={cn(radio({size}), className)}
      >
        <Ariakit.Radio
          ref={forwardedRef}
          className={cn(
            'peer/input',
            variant !== 'headless' &&
              'absolute z-[-1] h-px w-px overflow-hidden opacity-0',
          )}
          disabled={disabled}
          onFocusVisible={(event) => {
            onFocusVisible?.(event)
            setIsFocusVisible(true)
          }}
          onBlur={(event) => {
            onBlur?.(event)
            setIsFocusVisible(false)
          }}
          {...restProps}
        />
        {variant !== 'headless' && (
          <div
            className={cn(
              radioIcon({size, variant}),
              'peer-aria-checked/input:[&>.check-icon]:visible',
            )}
          >
            {/* Zero-width space character, used to align checkbox properly */}
            &#8203;
            <PhosphorIcon
              aria-hidden
              className="check-icon -translate-x-1/2 -translate-y-1/2 invisible absolute top-1/2 left-1/2 text-[0.8em] text-trueWhite transition-colors"
              icon="check-bold"
            />
          </div>
        )}
        {children}
      </Ariakit.Role.label>
    )
  },
)
