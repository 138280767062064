import * as WebUI from '@cheddarup/web-ui'
import {useEffect, useState} from 'react'

export interface ItemVariantsListingTableActionDropdownProps {
  variantsSelected?: boolean
  onEnterPrice: () => void
  onEnterQuantity: () => void
  onEnterSku: () => void
  onMarkAsSoldOut: () => void
  onUploadImage: () => void
  onDelete: () => void
}

const ItemVariantsListingTableActionDropdown = ({
  variantsSelected,
  onEnterPrice,
  onEnterQuantity,
  onEnterSku,
  onMarkAsSoldOut,
  onUploadImage,
  onDelete,
}: ItemVariantsListingTableActionDropdownProps) => {
  const [errorVisible, setErrorVisible] = useState(false)

  useEffect(() => {
    if (variantsSelected) {
      setErrorVisible(false)
    }
  }, [variantsSelected])

  return (
    <WebUI.Menu>
      <WebUI.HStack className="items-center gap-4">
        <WebUI.MenuButton
          variant="outlined"
          iconAfter={<WebUI.PhosphorIcon icon="caret-down-fill" width={14} />}
          onClick={(event) => {
            if (!variantsSelected) {
              event.preventDefault()
              setErrorVisible(true)
            }
          }}
        >
          Actions
        </WebUI.MenuButton>
        {errorVisible && (
          <p className="font-normal text-ds-sm text-orange-50">
            please select at least one variant
          </p>
        )}
      </WebUI.HStack>

      <WebUI.MenuList>
        <WebUI.MenuItem onClick={onEnterPrice}>Enter Price</WebUI.MenuItem>
        <WebUI.MenuItem onClick={onEnterQuantity}>
          Enter Quantity
        </WebUI.MenuItem>
        <WebUI.MenuItem onClick={onEnterSku}>Enter SKU</WebUI.MenuItem>
        <WebUI.MenuItem onClick={onMarkAsSoldOut}>
          Mark as Sold Out
        </WebUI.MenuItem>
        <WebUI.MenuItem onClick={onUploadImage}>Upload Image</WebUI.MenuItem>
        <WebUI.MenuItem onClick={onDelete}>Delete</WebUI.MenuItem>
      </WebUI.MenuList>
    </WebUI.Menu>
  )
}

export default ItemVariantsListingTableActionDropdown
