import React, {useMemo} from 'react'
import {
  api,
  endpoints,
  getEndpointKey,
  useQueryClient,
} from '@cheddarup/api-client'
import * as WebUI from '@cheddarup/web-ui'

export interface PersonaButtonProps
  extends WebUI.ButtonProps,
    React.ComponentPropsWithoutRef<'button'> {
  onComplete?: () => void
}

export const PersonaButton = React.forwardRef<
  HTMLButtonElement,
  PersonaButtonProps
>(({onComplete, className, onClick, children, ...restProps}, forwardedRef) => {
  const fullNameQuery = api.auth.session.useQuery(undefined, {
    select: (session) => session.user.full_name,
  })
  const growlActions = WebUI.useGrowlActions()
  const personaClient = usePersonaClient({
    onComplete: () => {
      growlActions.show('success', {
        title: 'Thank you for this validation!',
      })
      onComplete?.()
    },
    onCancel: () => {
      growlActions.show('error', {
        title:
          'It looks like the verification is incomplete. To enable withdrawals, please complete the full verification process.',
      })
    },
    onError: () => {
      growlActions.show('error', {
        title:
          'Something went wrong completing your verification, please try again.',
      })
    },
  })

  return (
    <WebUI.Button
      ref={forwardedRef}
      className={WebUI.cn('PersonaButton', className)}
      onClick={(event) => {
        onClick?.(event)

        if (!event.defaultPrevented) {
          personaClient?.open()
        }
      }}
      {...restProps}
    >
      {children ?? `Verify ${fullNameQuery.data ?? ''}`}
    </WebUI.Button>
  )
})

// MARK: – usePersonaClient

let cachedPersona: any | null = null

export function usePersonaClient(options: any) {
  const {invalidateQueries} = useQueryClient()
  const {data: persona} = api.users.personaInquiry.useQuery(undefined, {
    select: (session) => session.user?.profile?.persona,
  })

  const client = useMemo(() => {
    if (cachedPersona) {
      return cachedPersona
    }
    if (persona?.currentInquiry?.data?.id) {
      cachedPersona = new window.Persona.Client({
        inquiryId: persona?.currentInquiry?.data?.id,
        sessionToken: persona?.currentInquiry?.meta?.['session-token'],
        onComplete: () => {
          const sessionQueryKey = getEndpointKey(endpoints.auth.session)
          const personaInquiryQueryKey = getEndpointKey(
            endpoints.users.personaInquiry,
          )
          invalidateQueries({queryKey: sessionQueryKey})
          invalidateQueries({queryKey: personaInquiryQueryKey})

          options.onComplete?.()
        },
        onCancel: () => {
          const personaInquiryQueryKey = getEndpointKey(
            endpoints.users.personaInquiry,
          )
          invalidateQueries({queryKey: personaInquiryQueryKey})

          options.onCancel?.()
        },
        onError: () => {
          const personaInquiryQueryKey = getEndpointKey(
            endpoints.users.personaInquiry,
          )
          invalidateQueries({queryKey: personaInquiryQueryKey})

          options.onError?.()
        },
        ...options,
      })
      return cachedPersona
    }

    return null
  }, [invalidateQueries, options, persona])

  return client
}
