import {z} from '@cheddarup/util'

import {groupPageCategorySchema} from '../schemas'
import {makeEndpoint} from '../utils'

export default {
  list: makeEndpoint({
    path: 'users/group_page/collection_section/categories',
    responseSchema: groupPageCategorySchema.array(),
  }),
  create: makeEndpoint({
    path: 'users/group_page/collection_section/categories',
    method: 'POST',
    bodySchema: z.object({
      category: z.object(groupPageCategorySchema.shape).partial(),
    }),
    responseSchema: groupPageCategorySchema,
  }),
  update: makeEndpoint({
    path: 'users/group_page/collection_section/categories/:id',
    method: 'PATCH',
    bodySchema: z.object({
      category: z.object(groupPageCategorySchema.shape).partial(),
    }),
    responseSchema: groupPageCategorySchema,
  }),
  delete: makeEndpoint({
    path: 'users/group_page/collection_section/categories/:id',
    method: 'DELETE',
    responseSchema: z.void(),
  }),
  addCollections: makeEndpoint({
    path: 'users/group_page/collection_section/categories/category_collections',
    method: 'PATCH',
    bodySchema: z.object({
      sourceCategoryId: z.number().nullish(),
      category_id: z.number().nullable(),
      collection_ids: z.number().array(),
    }),
    responseSchema: groupPageCategorySchema,
  }),
  sortCollections: makeEndpoint({
    path: 'users/group_page/collection_section/categories/:id/sort_collections',
    method: 'PATCH',
    bodySchema: z.object({
      order: z.number().array(),
    }),
    responseSchema: groupPageCategorySchema,
  }),
}
