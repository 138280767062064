import {Outlet, Route} from 'react-router-dom'
import {MessageCenterPage} from 'src/views/collection/share/MessageCenterPage/MessageCenterPage'
import {PageContainer} from 'src/components/PageContainer'
import AddressBookPage from 'src/views/AddressBookPage'
import CollectionsPage from 'src/views/CollectionsPage'
import GroupPage from 'src/views/group/GroupPage'
import InviteMembersPage from 'src/views/organizations/views/invite-members/InviteMembersPage'
import ManagersPage from 'src/views/ManagersPage'
import MembersPage from 'src/views/organizations/views/MembersPage'
import PaymentsPage from 'src/views/payments/PaymentsPage'
import ReportsPage from 'src/views/ReportsPage'
import ResendAllConfirmPage from 'src/views/organizations/views/invite-members/ResendAllConfirmPage'
import WithdrawNegativeBalancePage from 'src/views/withdraw/WithdrawNegativeBalancePage'
import WithdrawPage from 'src/views/withdraw/WithdrawPage'
import {EnsureAuth, EnsureTabMemberAuth} from 'src/hooks/useAuthToken'
import {FolderProvider} from 'src/components/FolderProvider'
import {EnsureNoManager} from 'src/components/ManageRoleProvider'
import AddTopBannerPage from 'src/views/group/GroupPage/AddTopBannerPage/AddTopBannerPage'
import AddCollectionSpotlightPage from 'src/views/group/GroupPage/AddCollectionSpotlightPage/AddCollectionSpotlightPage'
import SpotlightItemForm from 'src/views/group/GroupPage/AddCollectionSpotlightPage/SpotlightItemForm'
import AddAboutUsPage from 'src/views/group/GroupPage/AddAboutUsPage/AddAboutUsPage'
import AddCollectionsPage from 'src/views/group/GroupPage/AddCollectionsPage/AddCollectionsPage'

import PartnerDashboardRouter from '../views/reporting'
import {paymentToOthersRoutes} from './PaymentToOthersRoutes'
import AddTotalCollectedPage from 'src/views/group/GroupPage/TotalCollectedPage/TotalCollectedPage'
import TeamSectionPage from 'src/views/group/GroupPage/TeamSectionPage/TeamSectionPage'
import TeamMemberForm from 'src/views/group/GroupPage/TeamSectionPage/TeamMemberForm'
import SponsorsPage from 'src/views/group/GroupPage/SponsorsSectionPage/SponsorsPage'
import SponsorForm from 'src/views/group/GroupPage/SponsorsSectionPage/SponsorForm'
import {CollectionDraftPreviewPage} from 'src/views/collection/manage/CollectionDraftPreviewPage'

export const homeRoutes = [
  <Route
    key="collections/*"
    path="collections/*"
    element={
      <EnsureAuth>
        <PageContainer>
          <FolderProvider>
            <CollectionsPage />

            <Outlet />
          </FolderProvider>
        </PageContainer>
      </EnsureAuth>
    }
  >
    <Route path="c/:tabSlug/*" element={<CollectionDraftPreviewPage />} />
  </Route>,
  <Route
    key="payments/*"
    path="payments/*"
    element={
      <EnsureTabMemberAuth authedRedirectTo="/guest/payments">
        <EnsureAuth>
          <EnsureNoManager>
            <PageContainer>
              <PaymentsPage />
              <Outlet />
            </PageContainer>
          </EnsureNoManager>
        </EnsureAuth>
      </EnsureTabMemberAuth>
    }
  >
    {paymentToOthersRoutes}
  </Route>,
  <Route
    key="managers/*"
    path="managers/*"
    element={
      <EnsureAuth>
        <EnsureNoManager>
          <PageContainer>
            <ManagersPage />
          </PageContainer>
        </EnsureNoManager>
      </EnsureAuth>
    }
  />,
  <Route
    key="group/*"
    path="group/*"
    element={
      <EnsureAuth>
        <PageContainer>
          <GroupPage />
          <Outlet />
        </PageContainer>
      </EnsureAuth>
    }
  >
    <Route
      path="add-collections/*"
      element={
        <PageContainer>
          <AddCollectionsPage />
          <Outlet />
        </PageContainer>
      }
    />
    <Route
      path="top-banner/*"
      element={
        <PageContainer>
          <AddTopBannerPage />
          <Outlet />
        </PageContainer>
      }
    />
    <Route
      path="collection-spotlight/*"
      element={
        <PageContainer>
          <AddCollectionSpotlightPage />
          <Outlet />
        </PageContainer>
      }
    >
      <Route
        path="add-item"
        element={
          <PageContainer>
            <SpotlightItemForm />
            <Outlet />
          </PageContainer>
        }
      />
      <Route
        path="items/:id"
        element={
          <PageContainer>
            <SpotlightItemForm />
            <Outlet />
          </PageContainer>
        }
      />
    </Route>
    <Route
      path="about-us/*"
      element={
        <PageContainer>
          <AddAboutUsPage />
          <Outlet />
        </PageContainer>
      }
    />
    <Route
      path="total-collected/*"
      element={
        <PageContainer>
          <AddTotalCollectedPage />
          <Outlet />
        </PageContainer>
      }
    />
    <Route
      path="team/*"
      element={
        <PageContainer>
          <TeamSectionPage />
          <Outlet />
        </PageContainer>
      }
    >
      <Route
        path="add-member"
        element={
          <PageContainer>
            <TeamMemberForm />
            <Outlet />
          </PageContainer>
        }
      />
      <Route
        path="members/:id"
        element={
          <PageContainer>
            <TeamMemberForm />
            <Outlet />
          </PageContainer>
        }
      />
    </Route>
    <Route
      path="sponsors/*"
      element={
        <PageContainer>
          <SponsorsPage />
          <Outlet />
        </PageContainer>
      }
    >
      <Route
        path="add-sponsor"
        element={
          <PageContainer>
            <SponsorForm />
            <Outlet />
          </PageContainer>
        }
      />
      <Route
        path="sponsors/:id"
        element={
          <PageContainer>
            <SponsorForm />
            <Outlet />
          </PageContainer>
        }
      />
    </Route>
    <Route
      path="message-center/*"
      element={
        <PageContainer>
          <MessageCenterPage />
          <Outlet />
        </PageContainer>
      }
    />
  </Route>,
  <Route
    key="reports/*"
    path="reports/*"
    element={
      <EnsureAuth>
        <PageContainer>
          <ReportsPage />
        </PageContainer>
      </EnsureAuth>
    }
  />,
  <Route
    key="/reporting/*"
    path="/reporting/*"
    element={
      <EnsureAuth>
        <EnsureNoManager>
          <PageContainer>
            <PartnerDashboardRouter />
          </PageContainer>
        </EnsureNoManager>
      </EnsureAuth>
    }
  />,
  <Route
    key="address-book/*"
    path="address-book/*"
    element={
      <EnsureAuth>
        <PageContainer>
          <AddressBookPage />
          <Outlet />
        </PageContainer>
      </EnsureAuth>
    }
  >
    <Route
      path="message-center/*"
      element={
        <PageContainer>
          <MessageCenterPage />
          <Outlet />
        </PageContainer>
      }
    />
  </Route>,
  <Route
    key="withdraw/*"
    path="withdraw/*"
    element={
      <EnsureAuth>
        <EnsureNoManager allowedPermissionRoles={['admin']}>
          <PageContainer>
            <WithdrawPage />
            <Outlet />
          </PageContainer>
        </EnsureNoManager>
      </EnsureAuth>
    }
  >
    <Route path="negative-balance" element={<WithdrawNegativeBalancePage />} />
  </Route>,
  <Route
    key="organizations"
    path="organizations"
    element={
      <EnsureAuth>
        <EnsureNoManager>
          <Outlet />
        </EnsureNoManager>
      </EnsureAuth>
    }
  >
    <Route path="members" element={<MembersPage />} />
    <Route
      path="invite-members"
      element={
        <>
          <InviteMembersPage />
          <Outlet />
        </>
      }
    >
      <Route path="resend-all-confirm" element={<ResendAllConfirmPage />} />
    </Route>
  </Route>,
]
