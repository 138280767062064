import {endpoints} from '../../endpoints'
import {makeQueryUpdate, makeUseMutation} from '../use-mutation'

export const useUpdateGroupPageMutation = makeUseMutation(
  endpoints.groupPages.update,
  () => ({
    regular: (updatedGroupPage) => [
      makeQueryUpdate(endpoints.groupPages.detail, () => updatedGroupPage),
    ],
  }),
)

export const useDeleteTopBannerImageMutation = makeUseMutation(
  endpoints.groupPages.deleteTopBannerImage,
)
