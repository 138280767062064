import {useFieldsCUDBatchMutateAsync} from '@cheddarup/api-client'
import type {TransientField} from '@cheddarup/core'
import {useCallback} from 'react'

export function useSaveFields() {
  const fieldsCUDBatchMutateAsync = useFieldsCUDBatchMutateAsync()

  const saveFields = useCallback(
    async ({
      tabId,
      tabObjectId,
      tabObjectType,
      existingFields,
      newFields,
    }: {
      tabId: number
      tabObjectId: number
      tabObjectType: 'item' | 'form'
      existingFields: Api.TabObjectField[]
      newFields: Array<Api.TabObjectField | TransientField>
    }) => {
      const fieldsToCreate = newFields.filter(
        (newF) =>
          !('id' in newF) || !existingFields.some((f) => f.id === newF.id),
      )
      const fieldsToUpdate =
        newFields.filter(
          (newF) =>
            'id' in newF && existingFields.some((f) => newF.id === f.id),
        ) ?? []
      const fieldsToDelete =
        existingFields.filter(
          (f) => !newFields.some((newF) => 'id' in newF && newF.id === f.id),
        ) ?? []

      if (
        fieldsToCreate.length > 0 ||
        fieldsToUpdate.length > 0 ||
        fieldsToDelete.length > 0
      ) {
        return fieldsCUDBatchMutateAsync(
          {tabId, tabObjectId, tabObjectType},
          {
            create: fieldsToCreate,
            update: fieldsToUpdate,
            delete: fieldsToDelete,
          },
        )
      }

      return undefined
    },
    [fieldsCUDBatchMutateAsync],
  )

  return saveFields
}
