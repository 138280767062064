export const isNumeric = (str: string | undefined | null) => {
  if (str == null || str === '') {
    return false
  }

  return !Number.isNaN(Number.parseInt(str, 10))
}

const defaultLocale = 'en-US'

const usdCurrencyFormatter = new Intl.NumberFormat(defaultLocale, {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'symbol',
})

const usdCurrencyScientificFormatter = new Intl.NumberFormat(defaultLocale, {
  style: 'currency',
  currency: 'USD',
  notation: 'scientific',
  currencyDisplay: 'symbol',
})

const formatCurrency = (num: number) => usdCurrencyFormatter.format(num)

const formatCurrencyScientific = (num: number) =>
  usdCurrencyScientificFormatter.format(num)

export function formatAmount(
  number: number | bigint | string,
  config: {cents?: boolean} = {},
) {
  const num = config.cents ? Number(number) / 100 : Number(number)

  if (num > 1e12) {
    return formatCurrencyScientific(num)
  }
  return formatCurrency(num)
}
