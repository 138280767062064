// @ts-ignore

import {z} from '@cheddarup/util'

import {tabItemOptionsVariantsSchema, tabItemSchema} from './tab-items'
import {s3ImageSchema} from './common'

export const userProfilePicSchema = z.object({
  url: z.string().nullish(),
})

export const tabObjectPartnerSchema = z.object({
  id: z.number(),
  first_name: z.string(),
  last_name: z.string(),
  profile_pic: userProfilePicSchema,
  name: z.string(),
})

export const itemCatalogImageSchema = s3ImageSchema.extend({
  id: z.number(),
  created_at: z.string(),
  updated_at: z.string(),
  uuid: z.string(),
  imageable_type: z.literal('Tab'),
  imageable_id: z.number(),
  file_name: z.string(),
  user_id: z.number(),
})

export const tabItemCatalogItemSubcategorySchema = z.object({
  uuid: z.string(),
  name: z.string(),
})

export const itemCatalogItemCategoryOptionsSchema = z.object({
  subcategories: tabItemCatalogItemSubcategorySchema.array(),
})

export const itemCatalogItemCategorySchema = z.object({
  id: z.number(),
  name: z.string(),
  position: z.number(),
  options: itemCatalogItemCategoryOptionsSchema,
})

export const itemCatalogItemSchema = tabItemSchema
  .pick({
    id: true,
    name: true,
    amount: true,
    amount_type: true,
    position: true,
    description: true,
    images: true,
    type: true,
  })
  .extend({
    variants: tabItemOptionsVariantsSchema,
    category: itemCatalogItemCategorySchema.nullish(),
  })

export const itemCatalogSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string(),
  theme_id: z.number(),
  image: itemCatalogImageSchema.nullish(),
  user: tabObjectPartnerSchema,
  display_order: z.number().nullish(),
  active_items: itemCatalogItemSchema.array(),
})

declare global {
  namespace Api {
    // @ts-ignore
    type ItemCatalog = z.infer<typeof itemCatalogSchema>
    // @ts-ignore
    type ItemCatalogItem = z.infer<typeof itemCatalogItemSchema>
    // @ts-ignore
    type ItemCatalogItemCategory = z.infer<typeof itemCatalogItemCategorySchema>
    // @ts-ignore
    type ItemCatalogItemCategoryOptions = z.infer<
      typeof itemCatalogItemCategoryOptionsSchema
    >
    // @ts-ignore
    type TabItemCatalogItemSubcategory = z.infer<
      typeof tabItemCatalogItemSubcategorySchema
    >
    // @ts-ignore
    type ItemCatalogImage = z.infer<typeof itemCatalogImageSchema>
    // @ts-ignore
    type TabObjectPartner = z.infer<typeof tabObjectPartnerSchema>
  }
}
