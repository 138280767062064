import {formTemplateSchema} from '../schemas'
import {makeEndpoint} from '../utils'

export default {
  list: makeEndpoint({
    path: 'users/form_templates',
    responseSchema: formTemplateSchema.omit({active_forms: true}).array(),
  }),
  detail: makeEndpoint({
    path: 'users/form_templates/:templateId',
    responseSchema: formTemplateSchema,
  }),
}
