import {Filter, TimeDimensionRanged} from '@cubejs-client/core'
import {useMemo, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'

import {Link} from '../../components/Link'
import {CreateCubeReportExportButton} from './../ReportsPage/components/CubeReports'
import {CubeFiltersPopover} from './../ReportsPage/ReportsPage'
import {CubeFilterField} from '../../components/CubeFilters'
import {CubeTable, CubeTableColumn} from '../../components/CubeTable'

const SalesPagesPage = () => {
  const [filters, setFilters] = useState<Filter[]>([])

  const [timeDimensions, setTimeDimensions] = useState<TimeDimensionRanged[]>(
    [],
  )

  const query = useMemo(
    () => ({
      dimensions: [
        'Collections.id',
        'Collections.name',
        'Users.name',
        'Users.email',
        'Users.uuid',
        'Collections.lastActivity',
        'Users.profilePicturePath',
        'Collections.createdAt',
        'Collections.paymentsTotal',
        'Collections.lastActivity',
        'Collections.paymentsCount',
        'Collections.status',
        'Collections.visitCount',
      ],
      filters,
      timeDimensions,
    }),
    [filters, timeDimensions],
  )

  const columns: CubeTableColumn[] = useMemo(
    () => [
      {path: 'Collections.name', headerTitle: 'Collection Title'},
      {path: 'Users.name', headerTitle: 'Organizer'},
      {path: 'Collections.lastActivity', maxSize: 160},
      {
        path: 'Collections.status',
        maxSize: 160,
        cell: ({cell}) => (
          <WebUI.Tag
            className={WebUI.cn(
              'text-ds-xs capitalize',
              {
                closed: 'bg-teal-70',
                open: 'bg-teal-50',
              }[cell.getValue() as string],
            )}
          >
            {String(cell.getValue())}
          </WebUI.Tag>
        ),
      },
      {
        path: 'Collections.paymentsCount',
        headerTitle: 'Payments',
        align: 'right',
        maxSize: 160,
      },
      {
        path: 'Collections.paymentsTotal',
        align: 'right',
        maxSize: 160,
      },
    ],
    [],
  )

  const initialSortingState = useMemo(
    () => [{id: 'Collections.createdAt', desc: true}],
    [],
  )

  return (
    <WebUI.VStack className="gap-4">
      <WebUI.HStack
        className="items-center justify-between gap-3 p-4"
        as={WebUI.Panel}
      >
        <WebUI.Heading as="h2">Collection Pages</WebUI.Heading>

        <WebUI.HStack className="gap-3">
          <CubeFiltersPopover
            aria-label="Collection pages filters"
            filters={filters}
            timeDimensions={timeDimensions}
            onFiltersApply={(newFilters) => {
              setFilters(newFilters.filters)
              setTimeDimensions(newFilters.timeDimensions)
            }}
          >
            <CubeFilterField dimension="Payments.createdAt" />
            <CubeFilterField dimension="Collections.status" />
            <CubeFilterField dimension="Collections.paymentsCount">
              Payments
            </CubeFilterField>
            <CubeFilterField dimension="Collections.paymentsTotal" />

            {/* <CubeFilterField dimension="Users.name">Collector</CubeFilterField> */}
            {/* <CubeFilterField dimension="Collections.name" /> */}
          </CubeFiltersPopover>
          <CreateCubeReportExportButton
            query={query}
            countMeasure="Collections.count"
          />
        </WebUI.HStack>
      </WebUI.HStack>

      <WebUI.Panel>
        <CubeTable
          className="[&_.TableView-headerGroup]:px-2 [&_.TableViewRow]:px-2"
          initialState={{sorting: initialSortingState}}
          query={query}
          columns={columns}
          countMeasure="Collections.count"
          getRowProps={(row) =>
            row.original['Collections.id']
              ? ({
                  as: Link,
                  className: 'px-0 auto [font-size:inherit] text-left',
                  to: {
                    pathname: `/reporting/collection-pages/${row.original['Collections.id']}`,
                  },
                  state: {
                    uuid: row.original['Users.uuid'],
                    email: row.original['Users.email'],
                  },
                } as any)
              : {}
          }
        />
      </WebUI.Panel>
    </WebUI.VStack>
  )
}

export default SalesPagesPage
