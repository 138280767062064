import {invert} from '@cheddarup/util'

import {isMac} from './platform-utils'

export type Keybinding = KeybindingPress[]
export type KeybindingPress = [string[], string]

/**
 * grammar    = `<sequence>`
 * <sequence> = `<press> <press> <press> ...`
 * <press>    = `<key>` or `<mods>+<key>`
 * <mods>     = `<mod>+<mod>+...`
 *
 * Borrowed from https://github.com/jamiebuilds/tinykeys/blob/8245bf6084f23a5c268ddbbc4b48038a16a58395/src/tinykeys.ts#L32-L39
 */
export function parseKeybinding(keybindingStr: string): Keybinding {
  return keybindingStr
    .split(' ')
    .map((pressStr) =>
      // Special case to handle the `+` key since we use it as a separator
      pressStr.replace('++', '+Shift+Equal'),
    )
    .map((pressStr): KeybindingPress => {
      const modsAndKey = pressStr.split('+')
      const mods = modsAndKey
        .slice(0, -1)
        .map((mod) => (mod === '$mod' ? COMMAND_MOD : mod))

      // biome-ignore lint/style/noNonNullAssertion:
      let key = modsAndKey.slice(-1)[0]!
      if (key.length === 1) {
        key = key.toLowerCase()
      }
      if (key in KEY_CODE_MAP) {
        // biome-ignore lint/style/noNonNullAssertion:
        key = KEY_CODE_MAP[key]!
      }
      return [mods, key]
    })
}

/**
 * Cross-platform command modifier key
 */
export const COMMAND_MOD = isMac() ? 'Meta' : 'Control'

/**
 * A mapping table from KeyboardEvent.code values into strings that can be shown
 * to the user to identify that physical key
 *
 * Borrowed from https://github.com/WICG/keyboard-map/blob/2d752b280be27a2072fc62867b981970edf5b2f7/keyboard-polyfill.js#L10
 */
export const KEYBOARD_LAYOUT_MAP: Record<string, string> = {
  // Top row
  Backquote: '`',
  Digit1: '1',
  Digit2: '2',
  Digit3: '3',
  Digit4: '4',
  Digit5: '5',
  Digit6: '6',
  Digit7: '7',
  Digit8: '8',
  Digit9: '9',
  Digit0: '0',
  Minus: '-',
  Equal: '=',
  IntlYen: '\\',
  // Backspace: 'Backspace',

  // 2nd row
  // Tab: 'Tab',
  KeyQ: 'q',
  KeyW: 'w',
  KeyE: 'e',
  KeyR: 'r',
  KeyT: 't',
  KeyY: 'y',
  KeyU: 'u',
  KeyI: 'i',
  KeyO: 'o',
  KeyP: 'p',
  BracketLeft: '[',
  BracketRight: ']',
  Backslash: '\\',

  // 3rd row
  // CapsLock: 'CapsLock',
  KeyA: 'a',
  KeyS: 's',
  KeyD: 'd',
  KeyF: 'f',
  KeyG: 'g',
  KeyH: 'h',
  KeyJ: 'j',
  KeyK: 'k',
  KeyL: 'l',
  Semicolon: ':',
  Quote: "'",
  // Enter: 'Enter',

  // 4th row
  // ShiftLeft: 'Shift',
  IntlBackslash: '\\',
  KeyZ: 'z',
  KeyX: 'x',
  KeyC: 'c',
  KeyV: 'v',
  KeyB: 'b',
  KeyN: 'n',
  KeyM: 'm',
  Comma: ',',
  Period: '.',
  Slash: '/',
  // IntlRo: 'IntlRo',
  // ShiftRight: 'Shift',

  // 5th row
  // ControlLeft: 'Control',
  // MetaLeft: 'Meta',
  // AltLeft: 'Alt',
  // Space: ' ',
  // AltRight: 'Alt',
  // MetaRight: 'Meta',
  // ContextMenu: 'ContextMenu',
  // ControlRight: 'Control',
}

/**
 * A mapping table from characters typed by the user into
 * KeyboardEvent.code values
 */
export const KEY_CODE_MAP = invert(KEYBOARD_LAYOUT_MAP)
