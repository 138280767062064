import {BooleanParam, useQueryParam} from 'use-query-params'
import React, {useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import ImagesUtils from 'src/helpers/ImagesUtils'
import useCart from 'src/views/c/hooks/useCart'
import {getItemAmountType} from 'src/components/ItemAmountDisplay'
import * as Util from '@cheddarup/util'
import {SharpImage} from 'src/components/SharpImage'
import ItemViewImagePlaceholderIcon from 'src/images/item-view-image-placeholder.svg'
import {ItemViewPrice} from 'src/components'
import {useIsItemViewSoldOut} from 'src/views/c/utils/cart-item-utils'
import {PayerButton} from 'src/views/c/components/PayerStyled'

// MARK: – ItemViewRow

export interface ItemViewListItemProps
  extends React.ComponentPropsWithoutRef<'div'> {
  itemView: Api.PublicTabItem
  feesTransparency: boolean
}

export const ItemViewRow = React.forwardRef<
  HTMLDivElement,
  ItemViewListItemProps
>(({className, itemView, feesTransparency, ...restProps}, forwardedRef) => {
  const [preview] = useQueryParam('preview', BooleanParam)
  const {cart} = useCart()
  const [hasImages, setHasImages] = useState(itemView.images.length > 0)

  const isSoldOut = useIsItemViewSoldOut({itemId: itemView.id})
  const waitlistEnabled = isItemViewWaitlistEnabled(cart, itemView)
  const isAvailableQuantityVisible =
    !itemView.options.variants?.enabled &&
    itemView.options.makeAvailableQuantityPublic &&
    itemView.available_quantity != null

  const addToCartButton = (
    <div className="flex shrink-0 flex-col gap-2 pl-0 sm:items-center sm:justify-center sm:p-4">
      {isAvailableQuantityVisible && (
        <WebUI.Ellipsis className="text-ds-sm text-orange-50">
          {getCartItemAvailableQuantity(cart, itemView)} Left
        </WebUI.Ellipsis>
      )}
      <PayerButton
        className="w-[8.5rem]"
        variant={
          preview || isSoldOut
            ? waitlistEnabled
              ? 'secondaryAlt'
              : 'secondary'
            : 'themed-primary'
        }
      >
        {(() => {
          if (preview) {
            return 'Preview'
          }
          if (isSoldOut) {
            return waitlistEnabled ? 'Join Waitlist' : 'Sold Out'
          }
          if (getItemAmountType(itemView) === 'open') {
            return (
              itemView.options.donation?.suggestedAmounts?.buttonText ||
              'Enter Amount'
            )
          }
          return 'Add to Cart'
        })()}
      </PayerButton>
    </div>
  )

  const requireOrVerifiedNonProfitLabel =
    itemView.options.isTaxDeductible && itemView.required
      ? 'Required & Tax Deductible'
      : itemView.required
        ? 'Required'
        : itemView.options.isTaxDeductible
          ? 'Tax Deductible'
          : null

  return (
    <WebUI.Panel
      ref={forwardedRef}
      className={WebUI.cn(
        'flex aria-disabled:cursor-not-allowed aria-invalid:border-primary',
        className,
      )}
      {...restProps}
    >
      <div className="relative flex flex-grow justify-between overflow-hidden">
        <div className="flex min-h-0 min-w-0 flex-[1_1_0px] flex-col justify-between gap-2 p-4 pr-2 sm:gap-4 sm:pr-4">
          <div className="flex h-full flex-col gap-0_5 text-ds-sm">
            <WebUI.Ellipsis className="text-ds-base">
              {itemView.name}
            </WebUI.Ellipsis>
            {!!requireOrVerifiedNonProfitLabel && (
              <WebUI.Text className="text-orange-50">
                {requireOrVerifiedNonProfitLabel}
              </WebUI.Text>
            )}

            <ItemViewPrice
              itemView={itemView}
              includeFees={feesTransparency}
              listing={
                itemView.options.variants?.enabled
                  ? Util.firstBy(
                      itemView.options.variants.listings,
                      (l) => l.amount,
                    )
                  : undefined
              }
            />
            {feesTransparency && <FeeTransparencyTooltip />}
          </div>
          <div className="sm:hidden">{addToCartButton}</div>
        </div>
        {hasImages && (
          <SharpImage
            className="object-cover object-center"
            alt={itemView.name}
            width={128}
            height={128}
            image={ImagesUtils.getMainImage(
              itemView.images,
              itemView.options.variants?.enabled
                ? itemView.images.find(
                    (image) => image.metadata.thumbnail?.order === 0,
                  )?.id
                : undefined,
            )}
            onError={() => setHasImages(false)}
          />
        )}
        {itemView.options.quantityDiscount?.enabled && <DealCornerRibbon />}
      </div>
      <div className="hidden min-w-[20%] flex-col items-center justify-center border-l sm:flex">
        {addToCartButton}
      </div>
    </WebUI.Panel>
  )
})

// MARK: – ItemViewGridItem

export interface ItemViewGridItemProps
  extends React.ComponentPropsWithoutRef<'div'> {
  itemView: Api.PublicTabItem
  feesTransparency: boolean
}

export const ItemViewGridItem = React.forwardRef<
  HTMLDivElement,
  ItemViewGridItemProps
>(({className, itemView, feesTransparency, ...restProps}, forwardedRef) => {
  const {cart} = useCart()

  const hasImages = itemView.images.length > 0

  const isSoldOut = useIsItemViewSoldOut({itemId: itemView.id})
  const waitlistEnabled = isItemViewWaitlistEnabled(cart, itemView)
  const requireOrVerifiedNonProfitLabel =
    itemView.options.isTaxDeductible && itemView.required
      ? 'Required & Tax Deductible'
      : itemView.required
        ? 'Required'
        : itemView.options.isTaxDeductible
          ? 'Tax Deductible'
          : null

  return (
    <WebUI.Panel
      ref={forwardedRef}
      className={WebUI.cn('relative', className)}
      {...restProps}
    >
      <div
        className={WebUI.cn(
          !hasImages && 'bg-natural-80',
          'relative h-0 w-full pt-[100%]',
        )}
      >
        <img
          className={WebUI.cn(
            hasImages ? 'h-full w-full' : 'w-[128px]',
            '-translate-x-1/2 -translate-y-1/2 absolute top-1/2 left-1/2 block object-cover object-center',
          )}
          alt={itemView.name}
          src={
            hasImages
              ? (ImagesUtils.getItemMainThumbnailUrl(
                  itemView.images,
                  {width: 300},
                  itemView.options.variants?.enabled
                    ? itemView.images.find(
                        (image) => image.metadata.thumbnail?.order === 0,
                      )?.id
                    : undefined,
                ) as any)
              : ItemViewImagePlaceholderIcon
          }
        />
        {((!itemView.options.variants?.enabled &&
          itemView.options.makeAvailableQuantityPublic &&
          itemView.available_quantity !== null) ||
          isSoldOut) && (
          <div
            className={WebUI.cn(
              'absolute top-0 right-0 rounded rounded-t-none border-r-0 px-4 py-2',
              isSoldOut
                ? waitlistEnabled
                  ? 'bg-teal-70'
                  : 'bg-natural-80'
                : 'bg-tint',
            )}
          >
            <div
              className={WebUI.cn(
                'text-center font-light text-ds-sm',
                isSoldOut ? 'text-gray800' : 'text-natural-100',
              )}
            >
              {isSoldOut
                ? waitlistEnabled
                  ? 'Join Waitlist'
                  : 'Sold Out'
                : `${itemView.available_quantity} Left`}
            </div>
          </div>
        )}

        {itemView.options.quantityDiscount?.enabled && <DealCornerRibbon />}
      </div>
      <div className="flex h-[8rem] min-h-0 min-w-0 flex-col justify-between p-4">
        <div className="flex flex-col gap-1">
          <h5 className="block overflow-hidden text-ellipsis whitespace-nowrap text-ds-sm text-gray800 leading-compact">
            {itemView.name}
          </h5>
          {feesTransparency && <FeeTransparencyTooltip />}
          {!!requireOrVerifiedNonProfitLabel && (
            <div className="font-normal text-ds-sm text-orange-50">
              {requireOrVerifiedNonProfitLabel}
            </div>
          )}
        </div>
        <ItemViewPrice
          className="text-tint"
          itemView={itemView}
          includeFees={feesTransparency}
          listing={
            itemView.options.variants?.enabled
              ? Util.firstBy(
                  itemView.options.variants.listings,
                  (l) => l.amount,
                )
              : undefined
          }
        />
      </div>
    </WebUI.Panel>
  )
})

// MARK: – DealCornerRibbon

const DealCornerRibbon = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => {
  return (
    <div
      className={WebUI.cn(
        'bg-orange-500 font-bold text-ds-xs text-trueWhite',
        'absolute top-0 right-0 origin-[0_0] rotate-45 shadow-[0_0_0_999px_theme(colors.orange.500)] [clip-path:inset(0_-100%)] [translate:calc(100%*(1-cos(45deg)))]',
        className,
      )}
      {...restProps}
    >
      DEAL
    </div>
  )
}
// MARK: - FeeTransparencyTooltip

const FeeTransparencyTooltip = () => (
  <WebUI.Tooltip placement="bottom-start">
    <WebUI.TooltipAnchor className="text-ds-xs text-gray-500 underline decoration-dashed underline-offset-4">
      Includes fees
    </WebUI.TooltipAnchor>

    <WebUI.TooltipContent
      variant="light"
      arrow={false}
      className="rounded-extended p-6 text-start font-normal"
    >
      All prices include platform fees, ensuring no surprises at checkout.
    </WebUI.TooltipContent>
  </WebUI.Tooltip>
)

// MARK: – Helpers

function getCartItemQuantity(cart: Api.Cart | undefined, itemViewId: number) {
  const cartItems =
    cart?.items.filter((item) => item.tab_item.id === itemViewId) ?? []
  return Util.sumBy(cartItems, (item) => item.quantity)
}

function getCartItemAvailableQuantity(
  cart: Api.Cart | undefined,
  itemView: Api.PublicTabItem,
) {
  const availableQuantity =
    itemView.available_quantity ?? Number.POSITIVE_INFINITY
  return availableQuantity - getCartItemQuantity(cart, itemView.id)
}

function isItemViewSoldOut(
  cart: Api.Cart | undefined,
  itemView: Api.PublicTabItem,
) {
  if (itemView.options.variants?.enabled) {
    const maxListingQuantity = Math.max(
      ...itemView.options.variants.listings
        .map((l) => l.available_quantity)
        .filter((q) => q != null),
    )

    return maxListingQuantity === 0
  }

  return getCartItemAvailableQuantity(cart, itemView) === 0
}

function isItemViewWaitlistEnabled(
  cart: Api.Cart | undefined,
  itemView: Api.PublicTabItem,
) {
  return (
    !!itemView.options.waitlist?.enabled && isItemViewSoldOut(cart, itemView)
  )
}
