import {ApiError} from '@cheddarup/api-client'
import {isAxiosError} from 'axios'

export function guessError(
  err: unknown,
  defaultErrorMessage = 'Something went wrong...',
) {
  if (
    isAxiosError(err) &&
    err.response &&
    err.response.data.type === 'api_error'
  ) {
    return new ApiError(err.response.data)
  }
  if (isAxiosError(err) && typeof err.response?.data.error === 'string') {
    return new Error(err.response.data.error)
  }
  if (err instanceof Error) {
    return err
  }
  if (typeof err === 'string') {
    return new Error(err)
  }

  return new Error(defaultErrorMessage)
}
