import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {
  itemTypeSchema,
  tabObjectFieldViewSchema,
  ticketPaymentSchema,
} from '../schemas'

export default {
  list: makeEndpoint({
    path: 'users/tabs/:tabId/tickets',
    responseSchema: ticketPaymentSchema
      .omit({
        updated_at: true,
        payment: true,
        tab_object: true,
        item_field_views: true,
      })
      .extend({
        payment: ticketPaymentSchema.shape.payment.pick({
          created_at: true,
          updated_at: true,
          tab_member: true,
        }),
        tab_object: z.object({
          name: z.string(),
        }),
        item_field_views: tabObjectFieldViewSchema
          .omit({
            tab_id: true,
            tab_object_id: true,
            item_field_id: true,
            payment_id: true,
            payment_item_id: true,
            tab_member_id: true,
            required: true,
            values: true,
          })
          .array(),
      })
      .array(),
  }),
  listAlt: makeEndpoint({
    path: 'tickets',
    queryParamsSchema: z.object({
      token: z.string(),
    }),
    responseSchema: ticketPaymentSchema
      .omit({
        updated_at: true,
        payment: true,
        tab_object: true,
        item_field_views: true,
      })
      .extend({
        payment: ticketPaymentSchema.shape.payment.pick({
          created_at: true,
          updated_at: true,
          tab_member: true,
          tab: true,
        }),
        tab_object: z.object({
          name: z.string(),
        }),
        item_field_views: tabObjectFieldViewSchema
          .omit({
            tab_id: true,
            tab_object_id: true,
            item_field_id: true,
            payment_id: true,
            payment_item_id: true,
            tab_member_id: true,
            required: true,
            values: true,
          })
          .array(),
      })
      .array(),
  }),
  detail: makeEndpoint({
    path: 'tickets/:ticketId',
    queryParamsSchema: z
      .object({
        token: z.string(),
        code: z.string(),
      })
      .partial()
      .optional(),
    responseSchema: ticketPaymentSchema.omit({item_field_views: true}).extend({
      item_field_views: tabObjectFieldViewSchema
        .pick({
          id: true,
          name: true,
          value: true,
          field_type: true,
          metadata: true,
        })
        .array(),
    }),
  }),
  download: makeEndpoint({
    path: 'tickets/:paymentId/download',
    queryParamsSchema: z
      .object({
        ticket_id: z.number(),
      })
      .optional(),
    responseSchema: ticketPaymentSchema.omit({item_field_views: true}).extend({
      item_field_views: tabObjectFieldViewSchema
        .pick({
          name: true,
          value: true,
          field_type: true,
          metadata: true,
        })
        .array(),
    }),
  }),
  downloadList: makeEndpoint({
    path: 'tickets/:paymentId/download',
    responseSchema: ticketPaymentSchema
      .omit({item_field_views: true})
      .extend({
        item_field_views: tabObjectFieldViewSchema
          .pick({
            name: true,
            value: true,
            field_type: true,
            metadata: true,
          })
          .array(),
      })
      .array(),
  }),
  resend: makeEndpoint({
    path: 'users/tabs/:tabId/payments/:paymentId/payment_items/:paymentItemId/resend_ticket',
    method: 'POST',
    bodySchema: z.object({
      email: z.string().optional(),
    }),
    queryParamsSchema: z
      .object({
        itemType: itemTypeSchema,
        code: z.string(),
        collectorName: z.string(),
        collectorEmail: z.string(),
      })
      .partial()
      .optional(),
    responseSchema: z.any(),
  }),
}
