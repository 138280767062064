import {mergeDeep, sort} from '@cheddarup/util'

import {makeQueryUpdate, makeUseMutation} from '../use-mutation'
import {endpoints} from '../../endpoints'
import {getEndpointKey} from '../../utils'

export const useCreateTabMutation = makeUseMutation(
  endpoints.tabs.create,
  () => ({
    regular: (newTab) => [
      makeQueryUpdate(endpoints.tabs.list, (prevTabs) =>
        prevTabs ? [...prevTabs, newTab] : prevTabs,
      ),
    ],
  }),
)

export const useUpdateTabMutation = makeUseMutation(
  endpoints.tabs.update,
  (vars, queryClient) => ({
    optimistic: [
      makeQueryUpdate(
        endpoints.tabs.detail,
        (prevTab) =>
          prevTab ? (mergeDeep as any)(prevTab, vars.body ?? {}) : prevTab,
        vars,
      ),
      makeQueryUpdate(
        endpoints.tabs.list,
        (prevTabs) => {
          if (
            !prevTabs ||
            vars.body?.collection_section_category_id === undefined
          ) {
            return prevTabs
          }

          return prevTabs.map((t) =>
            t.id === vars.pathParams.tabId
              ? {
                  ...t,
                  collection_section_category_id:
                    vars.body?.collection_section_category_id ?? null,
                }
              : t,
          )
        },
        vars,
      ),
      makeQueryUpdate(endpoints.groupPageCategories.list, (prevCategories) => {
        if (
          !prevCategories ||
          vars.body?.collection_section_category_id === undefined
        ) {
          return prevCategories
        }

        const tabDetailQueryKey = getEndpointKey(endpoints.tabs.detail, vars)
        const tab = queryClient.getQueryData<Api.Tab>(tabDetailQueryKey)

        return prevCategories.map((cat) => {
          if (cat.id !== vars.body?.collection_section_category_id) {
            return {
              ...cat,
              collections: cat.collections.filter(
                (col) => col.id !== vars.pathParams.tabId,
              ),
            }
          }

          return {
            ...cat,
            collections: tab
              ? [...cat.collections, tab as any]
              : cat.collections,
          }
        })
      }),
    ],
    regular: (newTab) => [
      makeQueryUpdate(endpoints.tabs.list, (prevTabs) =>
        vars.body?.collection_section_category_id === undefined
          ? prevTabs?.map((t) => (t.id === newTab.id ? newTab : t))
          : prevTabs,
      ),
    ],
  }),
  (queryClient) => ({
    onSuccess: () => {
      const groupPageCatogoriesListQueryKey = getEndpointKey(
        endpoints.groupPageCategories.list,
      )
      queryClient.invalidateQueries({queryKey: groupPageCatogoriesListQueryKey})
    },
  }),
)

export const useDeleteTabMutation = makeUseMutation(
  endpoints.tabs.delete,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(endpoints.tabs.list, (prevTabs) =>
        prevTabs?.filter((t) => t.id !== Number(vars.pathParams.tabId)),
      ),
    ],
  }),
)

export const useReplicateTabMutation = makeUseMutation(
  endpoints.tabs.replicate,
  () => ({
    regular: (newTab) => [
      makeQueryUpdate(endpoints.tabs.list, (prevTabs) =>
        prevTabs ? [...prevTabs, newTab] : prevTabs,
      ),
    ],
  }),
)

export const useCloseTabMutation = makeUseMutation(
  endpoints.tabs.close,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(endpoints.tabs.list, (prevTabs) =>
        prevTabs?.map((t) =>
          t.id === Number(vars.pathParams.tabId)
            ? {...t, closed_at: new Date().toISOString()}
            : t,
        ),
      ),
      makeQueryUpdate(
        endpoints.tabs.detail,
        (prevTab) =>
          prevTab ? {...prevTab, closed_at: new Date().toISOString()} : prevTab,
        vars,
      ),
    ],
  }),
)

export const useCreateTabPOSCodeMutation = makeUseMutation(
  endpoints.tabs.setPOSCode,
  (vars) => ({
    regular: (newTab) => [
      makeQueryUpdate(endpoints.tabs.detail, () => newTab, vars),
    ],
  }),
)

export const useCloseTabsMutation = makeUseMutation(
  endpoints.tabs.closeBatch,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(endpoints.tabs.list, (prevTabs) =>
        prevTabs?.map((t) =>
          vars.body?.collection_ids.includes(t.id)
            ? {...t, closed_at: new Date().toISOString()}
            : t,
        ),
      ),
      ...(vars.body?.collection_ids ?? []).map((tId) =>
        makeQueryUpdate(
          endpoints.tabs.detail,
          (prevTab) =>
            prevTab
              ? {...prevTab, closed_at: new Date().toISOString()}
              : prevTab,
          {
            pathParams: {
              tabId: tId,
            },
          },
        ),
      ),
    ],
  }),
)

export const useDeleteTabsMutation = makeUseMutation(
  endpoints.tabs.deleteBatch,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(endpoints.tabs.list, (prevTabs) =>
        prevTabs?.filter((t) => !vars.body?.collection_ids.includes(t.id)),
      ),
    ],
  }),
)

export const useMoveTabsMutation = makeUseMutation(
  endpoints.tabs.moveBatch,
  () => ({
    regular: (newTabs) => [makeQueryUpdate(endpoints.tabs.list, () => newTabs)],
  }),
)

export const useCreateTabDepositMutation = makeUseMutation(
  endpoints.tabDeposits.create,
  (vars) => ({
    regular: (newDeposit) => [
      makeQueryUpdate(
        endpoints.tabDeposits.list,
        (prevDeposits) =>
          prevDeposits ? [...prevDeposits, newDeposit] : prevDeposits,
        vars,
      ),
    ],
  }),
)

export const useCreateTabAttachmentMutation = makeUseMutation(
  endpoints.tabAttachments.create,
  (vars) => ({
    regular: (newDeposit) => [
      makeQueryUpdate(
        endpoints.tabAttachments.list,
        (prevAttachments) =>
          prevAttachments ? [...prevAttachments, newDeposit] : prevAttachments,
        vars,
      ),
    ],
  }),
)

export const useDeleteTabAttachmentMutation = makeUseMutation(
  endpoints.tabAttachments.delete,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(
        endpoints.tabAttachments.list,
        (prevAttachments) =>
          prevAttachments?.filter(
            (a) => a.id !== Number(vars.pathParams.attachmentId),
          ),
        vars,
      ),
    ],
  }),
)

export const useCreateTabDiscountMutation = makeUseMutation(
  endpoints.tabDiscounts.create,
  (vars) => ({
    regular: (newDiscount) => [
      makeQueryUpdate(
        endpoints.tabDiscounts.list,
        (prevDiscounts) =>
          prevDiscounts ? [...prevDiscounts, newDiscount] : prevDiscounts,
        vars,
      ),
    ],
  }),
)

export const useUpdateTabDiscountMutation = makeUseMutation(
  endpoints.tabDiscounts.update,
  (vars) => ({
    regular: (newDiscount) => [
      makeQueryUpdate(
        endpoints.tabDiscounts.list,
        (prevDiscounts) =>
          prevDiscounts?.map((d) =>
            d.id === newDiscount.id ? newDiscount : d,
          ),
        vars,
      ),
    ],
  }),
)

export const useDeleteTabDiscountMutation = makeUseMutation(
  endpoints.tabDiscounts.delete,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(
        endpoints.tabDiscounts.list,
        (prevDiscounts) =>
          prevDiscounts?.filter(
            (d) => d.id !== Number(vars.pathParams.discountId),
          ),
        vars,
      ),
    ],
  }),
)

export const usePublishTabMutation = makeUseMutation(
  endpoints.tabs.publish,
  (vars) => ({
    regular: (newTab) => [
      makeQueryUpdate(endpoints.tabs.detail, () => newTab, vars),
      makeQueryUpdate(endpoints.tabs.list, (prevTabs) =>
        prevTabs?.map((prevTab) =>
          prevTab.id === newTab.id ? newTab : prevTab,
        ),
      ),
    ],
  }),
)

export const useContactPublicCollectionOrganizerMutation = makeUseMutation(
  endpoints.publicTabs.contact,
)

export const useCreateTabFaqMutation = makeUseMutation(
  endpoints.tabs.createFaq,
  (vars) => ({
    regular: (newFaq) => [
      makeQueryUpdate(
        endpoints.tabs.detail,
        (prevTab) =>
          prevTab
            ? {
                ...prevTab,
                faqs: sort([...(prevTab.faqs ?? []), newFaq]).asc(
                  (faq) => faq.position,
                ),
              }
            : prevTab,
        vars,
      ),
      makeQueryUpdate(
        endpoints.tabs.listFaqs,
        (prevFaqs) =>
          prevFaqs
            ? sort([...prevFaqs, newFaq]).asc((faq) => faq.position)
            : prevFaqs,
        vars,
      ),
    ],
  }),
)

export const useUpdateTabFaqMutation = makeUseMutation(
  endpoints.tabs.updateFaq,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(
        endpoints.tabs.detail,
        (prevTab) =>
          prevTab
            ? {
                ...prevTab,
                faqs: prevTab.faqs?.map((faq) =>
                  faq.id === vars.pathParams.faqId
                    ? {...faq, ...(vars.body as any)}
                    : faq,
                ),
              }
            : prevTab,
        vars,
      ),
      makeQueryUpdate(
        endpoints.tabs.listFaqs,
        (prevFaqs) =>
          prevFaqs
            ? sort(
                prevFaqs.map((faq) =>
                  faq.id === vars.pathParams.faqId
                    ? {...faq, ...(vars.body as any)}
                    : faq,
                ),
              ).asc((faq) => faq.position)
            : prevFaqs,
        vars,
      ),
    ],
  }),
)

export const useDeleteTabFaqMutation = makeUseMutation(
  endpoints.tabs.deleteFaq,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(
        endpoints.tabs.detail,
        (prevTab) =>
          prevTab
            ? {
                ...prevTab,
                faqs: prevTab.faqs?.filter(
                  (faq) => faq.id !== vars.pathParams.faqId,
                ),
              }
            : prevTab,
        vars,
      ),
      makeQueryUpdate(
        endpoints.tabs.listFaqs,
        (prevFaqs) =>
          prevFaqs?.filter((faq) => faq.id !== vars.pathParams.faqId),
        vars,
      ),
    ],
  }),
)
