import {useNavigate} from 'react-router-dom'
import React from 'react'
import * as WebUI from '@cheddarup/web-ui'

const BackupSecurityCodeAboutPage: React.FC = () => {
  const navigate = useNavigate()
  return (
    <WebUI.Alert
      aria-label="About Your Backup Security Code"
      className="sm:[&_>_.ModalContentView]:max-w-screen-sm"
      initialVisible
      onDidHide={() => navigate('../two-factor-authentication')}
    >
      <WebUI.AlertHeader>About Your Backup Security Code</WebUI.AlertHeader>
      <WebUI.AlertContentView
        text={
          <>
            Whenever two-factor authentication is set with a new device on your
            account, you are given a backup security code.
            <br />
            <br />
            This backup code is only shared one time, upon setting a new device,
            and cannot be reshared. The purpose of this backup code is for it to
            be used should you no longer have access to the device currently
            listed on file.
            <br />
            <br />
            When this code is shared, you are required to check a box confirming
            that you have either downloaded a pdf file that contains the code or
            that you have copied the code and saved it in a secure place.
            <br />
            <br />
            If you can locate this backup security code, it can be used in
            combination with your account password and in place of a two-factor
            code sent to the device currently on file.
            <br />
            <br />
          </>
        }
        actions={<WebUI.AlertCancelButton />}
      />
    </WebUI.Alert>
  )
}

export default BackupSecurityCodeAboutPage
