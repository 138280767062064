import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {paginationSchema} from '../schemas'
import {orgMemberSchema} from '../schemas/org-members'

export default {
  list: makeEndpoint({
    path: 'users/org_members',
    queryParamsSchema: z
      .object({
        sort: z.string(),
        direction: z.enum(['asc', 'desc']),
        search: z.string(),
        page: z.number(),
        per_page: z.number(),
      })
      .partial()
      .optional(),
    responseSchema: z.object({
      data: orgMemberSchema.array(),
      pagination: paginationSchema,
      search: z.string(),
      sort: z.string(),
    }),
  }),
  delete: makeEndpoint({
    path: 'users/org_members/:memberId',
    method: 'DELETE',
    responseSchema: z.any(),
  }),
}
