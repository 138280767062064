import {forwardRef} from 'react'
import {ForwardRefComponent, getStringFromChildren} from '@cheddarup/react-util'

import {Text, TextProps} from './Text'
import {cn} from '../utils'

export interface EllipsisProps extends TextProps {}

export const Ellipsis = forwardRef(
  ({className, title, children, ...restProps}, forwardedRef) => (
    <Text
      ref={forwardedRef}
      className={cn(
        'Ellipsis max-w-full overflow-hidden text-ellipsis whitespace-nowrap',
        className,
      )}
      title={title ?? getStringFromChildren(children)}
      {...restProps}
    >
      {children}
    </Text>
  ),
) as ForwardRefComponent<'span', EllipsisProps>
