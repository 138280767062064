import * as WebUI from '@cheddarup/web-ui'

export interface FormSettingsSwitchProps
  extends Omit<WebUI.SwitchProps, 'title'> {
  title: React.ReactNode
  description: React.ReactNode
}

export const FormSettingsSwitch: React.FC<FormSettingsSwitchProps> = ({
  title,
  description,
  className,
  ...restProps
}) => (
  <WebUI.Switch className={WebUI.cn('gap-3', className)} reverse {...restProps}>
    <div className="flex flex-auto flex-col gap-0_5">
      <WebUI.Text>{title}</WebUI.Text>
      <div className="max-w-[94%] text-ds-sm">{description}</div>
    </div>
  </WebUI.Switch>
)

export default FormSettingsSwitch
