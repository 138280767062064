import * as Yup from 'yup'
import {useForkRef, useFormik} from '@cheddarup/react-util'
import * as WebUI from '@cheddarup/web-ui'
import React, {useMemo, useRef} from 'react'
import type {
  FixedItemFormFormik,
  ItemFormValuesListing,
} from '../../../../containers/FixedItemForm/FixedItemForm'
import * as Util from '@cheddarup/util'

export interface VariantQuantityGroupFormValues {
  availableQuantity: string
  selectedVariantUuids: string[]
}

export interface VariantQuantityGroupModalProps extends WebUI.ModalProps {
  formik: FixedItemFormFormik
  optionKeys: string[]
  listings: ItemFormValuesListing[]
}

export const VariantQuantityGroupModal = React.forwardRef<
  WebUI.DialogInstance,
  VariantQuantityGroupModalProps
>(
  (
    {
      formik: fixedItemFormik,
      optionKeys,
      listings,
      initialVisible = false,
      onDidHide,
      className,
      ...restProps
    },
    forwardedRef,
  ) => {
    const ownRef = useRef<WebUI.DialogInstance>(null)
    const ref = useForkRef(ownRef, forwardedRef)

    const formik = useFormik<VariantQuantityGroupFormValues>({
      enableReinitialize: true,
      validationSchema: Yup.object().shape({
        availableQuantity: Yup.number().required('Required').positive(),
      }),
      initialValues: {
        availableQuantity:
          fixedItemFormik.values.inventoryGroup?.availableQuantity ?? '',
        selectedVariantUuids:
          fixedItemFormik.values.inventoryGroup?.selectedVariantUuids ?? [],
      },
      onSubmit: (values) => {
        fixedItemFormik.setFieldValue('inventoryGroup', values)

        ownRef.current?.hide()
      },
    })

    const columnHelper = useMemo(
      () => WebUI.createColumnHelper<ItemFormValuesListing>(),
      [],
    )

    const columns = useMemo(
      () =>
        optionKeys.map((optionKey, idx) =>
          columnHelper.accessor((l) => l.optionValues[optionKey], {
            id: `${optionKey}-${idx}`,
            minSize: 64,
            size: 64,
          }),
        ),
      [columnHelper, optionKeys],
    )

    const selectionState = useMemo(
      () =>
        formik.values.selectedVariantUuids.reduce(
          (o, key) => Object.assign(o, {[key]: true}),
          {},
        ),
      [formik.values.selectedVariantUuids],
    )

    return (
      <WebUI.Modal
        aria-label="Variant quantity group form"
        ref={ref}
        className={WebUI.cn(
          '[&_>_.ModalContentView]:max-w-screen-sm [&_>_.ModalContentView]:p-9 sm:[&_>_.ModalContentView]:rounded-large',
          className,
        )}
        initialVisible={initialVisible}
        onDidHide={() => {
          onDidHide?.()

          formik.resetForm()
        }}
        {...restProps}
      >
        <WebUI.ModalCloseButton />

        <div className="flex flex-col gap-4">
          <WebUI.Heading as="h2">Variant Quantity Group</WebUI.Heading>

          <span>
            This feature allows you to enter the total quantity available across
            multiple variants. The selected variant will show “sold out” once
            your combined-variant quantity limit is reached.
          </span>

          <form
            className="flex flex-col items-start gap-7"
            onReset={formik.handleReset}
            onSubmit={formik.handleSubmit}
          >
            <WebUI.FormField
              size="compact"
              label="Total Quantity Available"
              error={formik.errors.availableQuantity}
            >
              <WebUI.Input
                type="number"
                min={0}
                name="availableQuantity"
                value={formik.values.availableQuantity}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </WebUI.FormField>

            <div className="flex flex-col gap-4">
              <WebUI.FormFieldLabel size="compact" as="span">
                Select Items
              </WebUI.FormFieldLabel>
              <WebUI.TableView
                className="[&_.TableViewCell]:!flex-auto [&_.TableViewCell]:!min-w-fit [&_.TableViewCell]:!w-fit pl-3 [&_.TableViewCell]:px-0 [&_.TableViewCell]:font-normal [&_.TableViewCell]:text-ds-sm [&_.TableViewRow]:h-5 [&_.TableViewRow]:gap-2 [&_.TableViewRow]:border-b-0"
                columns={columns}
                data={listings}
                getRowId={(l) => l.uuid}
                enableRowSelection
                selectAllVisible={false}
                state={{rowSelection: selectionState}}
                onRowSelectionChange={(updater) => {
                  const newRowSelection =
                    typeof updater === 'function'
                      ? updater(selectionState)
                      : updater

                  formik.setFieldValue(
                    'selectedVariantUuids',
                    Object.keys(
                      Util.pickBy(newRowSelection, (v) => v === true),
                    ),
                  )
                }}
              />
            </div>

            <WebUI.Button type="submit">{`Apply Quantity Across ${Util.pluralize('Variants', formik.values.selectedVariantUuids.length, true)}`}</WebUI.Button>
          </form>
        </div>
      </WebUI.Modal>
    )
  },
)
