import {makeQueryUpdate, makeUseMutation} from '../use-mutation'
import {endpoints} from '../../endpoints'

export const useCreateESignatureMutation = makeUseMutation(
  endpoints.eSignatures.create,
  (vars) => ({
    regular: (newESignature) => [
      makeQueryUpdate(
        endpoints.eSignatures.list,
        (prevESignatures) =>
          prevESignatures
            ? [...prevESignatures, newESignature]
            : prevESignatures,
        vars,
      ),
    ],
  }),
)

export const useUpdateESignatureMutation = makeUseMutation(
  endpoints.eSignatures.update,
  (vars) => ({
    regular: (newESignature) => [
      makeQueryUpdate(
        endpoints.eSignatures.list,
        (prevESignatures) =>
          prevESignatures?.map((eSig) =>
            eSig.signer_number === newESignature.signer_number
              ? newESignature
              : eSig,
          ),
        vars,
      ),
    ],
  }),
)
