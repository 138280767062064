import {Navigate, To, useLocation} from 'react-router-dom'
import React, {useCallback} from 'react'
import {BooleanParam, StringParam, useQueryParam} from 'use-query-params'
import * as WebUI from '@cheddarup/web-ui'
import {
  CookedAuthToken,
  decodeAuthToken,
  isAuthTokenValid,
} from '@cheddarup/api-client'
import * as Util from '@cheddarup/util'

const LOCAL_STORAGE_AUTH_TOKEN_KEY = 'auth-token'

export function useAuthToken() {
  const [cookedAuthToken, setCookedAuthToken] =
    WebUI.useLocalStorage<CookedAuthToken | null>(
      LOCAL_STORAGE_AUTH_TOKEN_KEY,
      null,
    )

  const setAuthToken = useCallback(
    (
      _authToken:
        | string
        | Util.SetOptional<Api.AuthToken, 'id'>
        | null
        | undefined,
      _token?: string | null,
    ) => {
      let authToken = _authToken
      let token = _token

      if (typeof authToken === 'string') {
        token = authToken
        authToken = decodeAuthToken(authToken)
      }

      setCookedAuthToken(
        authToken && token
          ? {
              ...authToken,
              tokenType: authToken.claims.data.payload.tokenType,
              token,
              expiresAt: authToken.expires_at,
            }
          : null,
      )
    },
    [setCookedAuthToken],
  )

  return [cookedAuthToken, setAuthToken] as const
}

export function useIsAuthed() {
  const [authToken] = useAuthToken()
  return (
    !!authToken && authToken.tokenType === 'User' && isAuthTokenValid(authToken)
  )
}

// MARK: – Helpers

export function getAuthToken() {
  const authToken = WebUI.readLocalStorageItem<CookedAuthToken | null>(
    LOCAL_STORAGE_AUTH_TOKEN_KEY,
    null,
  )

  return authToken && isAuthTokenValid(authToken) ? authToken : null
}

export function clearAuthToken() {
  localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKEN_KEY)
}

// MARK: – Ensurers

export interface EnsureAuthProps {
  allowInsecure?: boolean
  requireAuth?: boolean
  redirectTo?: string
  tokenType?: Api.AuthTokenType | 'any'
  children: React.ReactNode
}

export const EnsureAuth: React.FC<EnsureAuthProps> = ({
  allowInsecure: allowInsecureProp,
  requireAuth = true,
  redirectTo: _redirectTo,
  tokenType = 'User',
  children,
}) => {
  const location = useLocation()
  const [preventAuthRedirect] = useQueryParam(
    'preventAuthRedirect',
    BooleanParam,
  )
  const [redirectParam] = useQueryParam('redirect', StringParam)
  const [authToken] = useAuthToken()

  const authTokenValid = !!authToken && isAuthTokenValid(authToken)

  const allowInsecure = allowInsecureProp ?? !requireAuth
  const defaultRedirectTo =
    authToken?.tokenType === 'TabMember'
      ? '/guest/payments'
      : requireAuth
        ? '/login'
        : '/collections'

  const [redirectTo, redirectToQueryParams] = (
    redirectParam ??
    _redirectTo ??
    defaultRedirectTo
  ).split('?')

  if (!preventAuthRedirect) {
    if (requireAuth) {
      if (
        !authTokenValid ||
        (!allowInsecure && authToken.claims?.insecure) ||
        (tokenType !== 'any' && authToken.tokenType !== tokenType)
      ) {
        const nextRedirect = `${location.pathname}${location.search}`

        return (
          <Navigate
            to={{
              pathname: redirectTo,
              search: Util.mergeSearchParams(
                location.search,
                `redirect=${nextRedirect}${
                  redirectToQueryParams ? `&${redirectToQueryParams}` : ''
                }`,
              ),
            }}
          />
        )
      }
    } else {
      if (authTokenValid && (!allowInsecure || !authToken?.claims?.insecure)) {
        return <Navigate to={{pathname: redirectTo, search: location.search}} />
      }
    }
  }

  return <>{children}</>
}

export interface EnsureTabMemberAuthProps {
  authedRedirectTo?: To
  notAuthedRedirectTo?: To
  children: React.ReactNode
}

export const EnsureTabMemberAuth: React.FC<EnsureTabMemberAuthProps> = ({
  authedRedirectTo,
  notAuthedRedirectTo,
  children,
}) => {
  const [preventAuthRedirect] = useQueryParam(
    'preventAuthRedirect',
    BooleanParam,
  )
  const [authToken] = useAuthToken()

  const isTabMemberAuthed =
    authToken &&
    isAuthTokenValid(authToken) &&
    authToken.tokenType === 'TabMember'

  if (isTabMemberAuthed && authedRedirectTo && !preventAuthRedirect) {
    return <Navigate to={authedRedirectTo} />
  }
  if (!isTabMemberAuthed && notAuthedRedirectTo && !preventAuthRedirect) {
    return <Navigate to={notAuthedRedirectTo} />
  }

  return <>{children}</>
}
