import BaseTextareaAutosize, {
  TextareaAutosizeProps as BaseTextareaAutosizeProps,
} from 'react-textarea-autosize'
import React from 'react'
import {ForwardRefComponent} from '@cheddarup/react-util'

import {Input, InputProps} from './Input'

export interface TextareaProps extends InputProps {}

export const Textarea = React.forwardRef(
  ({as = 'textarea', ...restProps}, forwardedRef) => (
    <Input ref={forwardedRef} as={as} {...restProps} />
  ),
) as ForwardRefComponent<'textarea', TextareaProps>

// MARK: - TextareaAutosize

export interface TextareaAutosizeProps
  extends TextareaProps,
    Omit<
      BaseTextareaAutosizeProps,
      keyof React.TextareaHTMLAttributes<HTMLTextAreaElement>
    > {}

export const TextareaAutosize = React.forwardRef<
  HTMLTextAreaElement,
  TextareaAutosizeProps
>((props, forwardedRef) => (
  <Textarea ref={forwardedRef} as={BaseTextareaAutosize} {...props} />
))
