import {Link} from 'react-router-dom'
import {api} from '@cheddarup/api-client'

import {
  CatalogGallerySection,
  HeaderSection,
  JustListedItemsSection,
  NewSalesSection,
} from './components'
import {MarketplacePageContainer} from '../containers'
import {MarketplacePageContentContainer} from '../components'

const MarketplaceHomePage = () => {
  const {data: session} = api.auth.session.useQuery()
  const {data: marketplaceHome} = api.marketplace.home.useQuery(
    {
      queryParams: {
        partnerId: session?.organization_data?.id ?? 0,
      },
    },
    {
      enabled: !!session?.organization_data?.id,
    },
  )

  return (
    <MarketplacePageContainer>
      <MarketplacePageContentContainer>
        <HeaderSection className="mt-1 mb-8" />
        <CatalogGallerySection catalogs={marketplaceHome?.catalogs ?? []} />
        <NewSalesSection
          className="mt-8"
          sales={marketplaceHome?.new_sales ?? []}
          salesPath="sales"
        />
        <Link className="mt-4 block font-semibold text-ds-sm" to="sales">
          All Sales
        </Link>
        <JustListedItemsSection
          className="mt-8"
          items={marketplaceHome?.just_listed.data ?? []}
          categoriesPath="shop-items"
        />
        <Link className="mt-4 block font-semibold text-ds-sm" to="shop-items">
          All Items
        </Link>
      </MarketplacePageContentContainer>
    </MarketplacePageContainer>
  )
}

export default MarketplaceHomePage
