import {EnsureCollectionBelongsToManagerRole} from 'src/components/ManageRoleProvider'
import {MessageCenterPage} from 'src/views/collection/share/MessageCenterPage'
import {Navigate, Outlet, Route} from 'react-router-dom'
import {PageContainer} from 'src/components/PageContainer'
import AddSellerFormPage from 'src/views/collection/build/forms/AddSellerFormPage'
import AddWaiverPage from 'src/views/collection/build/forms/AddWaiverPage'
import CatalogItemsPage from 'src/views/collection/build/items/CatalogItemsPage'
import CollectionBuilderLayout from 'src/components/CollectionBuilderLayout'
import CollectionCenterLayout from 'src/components/CollectionCenterLayout'
import CollectionDetailsPage from 'src/views/collection/build/CollectionDetailsPage'
import CollectionManagePage from 'src/views/collection/manage/CollectionManagePage'
import CollectionSettingsPage from 'src/views/collection/build/CollectionSettingsPage'
import CollectionShareLinkPage from 'src/views/collection/share/CollectionShareLinkPage'
import FormFormPage from 'src/views/collection/build/forms/FormFormPage'
import FormsPage from 'src/views/collection/build/forms/FormsPage'
import ItemCatalogsPage from 'src/views/collection/build/items/ItemCatalogsPage'
import ItemCatalogsSearchPage from 'src/views/collection/build/items/ItemCatalogsSearchPage'
import ItemFormPage from 'src/views/collection/build/items/ItemFormPage'
import ItemReportPage from 'src/views/collection/build/items/ItemReportPage'
import ItemsPage from 'src/views/collection/build/items/ItemsPage'
import PaymentDetailsPage from 'src/views/collection/manage/PaymentDetailsPage'
import PrintShippingLabelPage from 'src/views/collection/PrintShippingLabelPage'
import PrintShippingLabelSummaryPage from 'src/views/collection/PrintShippingLabelSummaryPage'
import RefundsManagePage from 'src/views/collection/manage/RefundsManagePage'
import SellerFormsPage from 'src/views/collection/build/forms/SellerFormsPage'
import SignUpFormPage from 'src/views/collection/build/forms/SignUpFormPage/SignUpFormPage'
import SpotReportPage from 'src/views/collection/build/forms/SpotReportPage'
import UploadItemsPage from 'src/views/collection/build/items/UploadItemsPage'
import {CollectionManageRouteOrchestrator} from 'src/views/collection/manage/CollectionManageRouteOrchestrator'
import OrderSummaryPage from 'src/views/OrderSummaryPage'
import CollectionManageItemsTab from 'src/views/collection/manage/CollectionManageItemsTab/CollectionManageItems'
import ItemSpecificPage from 'src/views/collection/manage/CollectionManageItemsTab/ItemSpecificPage'
import CollectionManageFormsSignupsTab from 'src/views/collection/manage/CollectionManageFormsSignupsTab'
import FormSpecificPage from 'src/views/collection/manage/CollectionManageFormsSignupsTab/FormSpecificPage'
import SignupSpecificPage from 'src/views/collection/manage/CollectionManageFormsSignupsTab/SignupSpecificPage'
import CollectionManageVisitorsTable from 'src/views/collection/manage/CollectionManageVisitorsTable'
import CollectionManageShippingTable from 'src/views/collection/manage/CollectionManageShippingTable'
import ItemWaitlistPage from 'src/views/collection/build/items/ItemWaitlistPage'
import CollectionManagePayments from 'src/views/collection/manage/CollectionManagePaymentsTable'
import TicketFormPage from 'src/views/collection/build/items/TicketFormPage'
import EditAttendeePage from 'src/views/collection/manage/CollectionManageItemsTab/TicketSpecificPage/EditAttendeePage'
import TicketSpecificPage from 'src/views/collection/manage/CollectionManageItemsTab/TicketSpecificPage'
import {CollectionDraftPreviewPage} from 'src/views/collection/manage/CollectionDraftPreviewPage'
import CollectionAdditionalInformationPage from 'src/views/collection/build/CollectionAdditionalInformationPage'
import {EnsureAuth} from 'src/hooks/useAuthToken'
import CollectionGuard from './guards/CollectionGuard'

export const collectionRoutes = [
  <Route
    key=""
    element={
      <CollectionCenterLayout>
        <Outlet />
      </CollectionCenterLayout>
    }
  >
    <Route
      path="details/*"
      element={
        <PageContainer>
          <CollectionBuilderLayout>
            <Outlet />
          </CollectionBuilderLayout>
        </PageContainer>
      }
    >
      <Route path="*" element={<CollectionDetailsPage />} />
    </Route>
  </Route>,
  <Route
    key=":collection/*"
    path=":collection/*"
    element={
      <PageContainer>
        <CollectionGuard>
          <EnsureCollectionBelongsToManagerRole>
            <CollectionCenterLayout>
              <Outlet />
            </CollectionCenterLayout>
          </EnsureCollectionBelongsToManagerRole>
        </CollectionGuard>
      </PageContainer>
    }
  >
    <Route
      path="details/*"
      element={
        <PageContainer>
          <CollectionBuilderLayout>
            <Outlet />
          </CollectionBuilderLayout>
        </PageContainer>
      }
    >
      <Route path="*" element={<CollectionDetailsPage />} />
      <Route
        path="information/*"
        element={
          <PageContainer>
            <CollectionAdditionalInformationPage />
            <Outlet />
          </PageContainer>
        }
      >
        <Route path="" element={<Navigate to="participation" />} />
      </Route>
      <Route
        path="items/*"
        element={
          <PageContainer>
            <ItemsPage />
            <Outlet />
          </PageContainer>
        }
      >
        <Route path="upload-items" element={<UploadItemsPage />} />
        <Route
          path="add-item/*"
          element={
            <PageContainer>
              <ItemFormPage />
              <Outlet />
            </PageContainer>
          }
        />
        <Route
          path="item/:item/edit/*"
          element={
            <PageContainer>
              <ItemFormPage />
              <Outlet />
            </PageContainer>
          }
        />
        <Route
          path="add-ticket/*"
          element={
            <PageContainer>
              <TicketFormPage />
              <Outlet />
            </PageContainer>
          }
        />
        <Route
          path="ticket/:ticket/edit/*"
          element={
            <PageContainer>
              <TicketFormPage />
              <Outlet />
            </PageContainer>
          }
        />
        <Route
          path="seller/:seller/catalogs"
          element={
            <>
              <ItemCatalogsPage />
              <Outlet />
            </>
          }
        >
          <Route path="search" element={<ItemCatalogsSearchPage />} />
          <Route
            path="catalog/:catalog/items/*"
            element={
              <PageContainer>
                <CatalogItemsPage />
              </PageContainer>
            }
          />
        </Route>

        <Route path="item/:item" element={<ItemReportPage />} />
        <Route
          path="item/:item/waitlist"
          element={
            <PageContainer>
              <ItemWaitlistPage />
              <Outlet />
            </PageContainer>
          }
        >
          <Route
            path="message-center/*"
            element={
              <PageContainer>
                <MessageCenterPage />
                <Outlet />
              </PageContainer>
            }
          />
        </Route>

        <Route path="c/:tabSlug/*" element={<CollectionDraftPreviewPage />} />
      </Route>
      <Route
        path="forms/*"
        element={
          <PageContainer>
            <FormsPage />
            <Outlet />
          </PageContainer>
        }
      >
        <Route
          path="add-form/*"
          element={
            <PageContainer>
              <FormFormPage />
              <Outlet />
            </PageContainer>
          }
        />
        <Route path=":form/edit" element={<FormFormPage />} />
        <Route
          path="add-waiver/*"
          element={
            <PageContainer>
              <AddWaiverPage />
              <Outlet />
            </PageContainer>
          }
        />
        <Route
          path="sign-up/*"
          element={
            <PageContainer>
              <Outlet />
            </PageContainer>
          }
        >
          <Route index element={<SignUpFormPage />} />
          <Route
            path=":signup"
            element={
              <>
                <SignUpFormPage />
                <Outlet />
              </>
            }
          >
            <Route path="spot-report/:spot" element={<SpotReportPage />} />
          </Route>
        </Route>

        <Route
          path="seller/:seller/forms"
          element={
            <>
              <SellerFormsPage />
              <Outlet />
            </>
          }
        >
          <Route path=":form" element={<AddSellerFormPage />} />
        </Route>

        <Route path="c/:tabSlug/*" element={<CollectionDraftPreviewPage />} />
      </Route>
      <Route
        path="settings/*"
        element={
          <PageContainer>
            <CollectionSettingsPage />
            <Outlet />
          </PageContainer>
        }
      >
        <Route path="c/:tabSlug/*" element={<CollectionDraftPreviewPage />} />
      </Route>

      <Route path="c/:tabSlug/*" element={<CollectionDraftPreviewPage />} />
    </Route>
    <Route
      path="share/*"
      element={
        <PageContainer>
          <CollectionShareLinkPage />
          <Outlet />
        </PageContainer>
      }
    >
      <Route
        path="message-center/*"
        element={
          <PageContainer>
            <MessageCenterPage />
            <Outlet />
          </PageContainer>
        }
      />

      <Route path="c/:tabSlug/*" element={<CollectionDraftPreviewPage />} />
    </Route>
    <Route
      path="manage/*"
      element={
        <PageContainer>
          <div className="h-[inherit] print:hidden">
            <CollectionManagePage />
          </div>
        </PageContainer>
      }
    >
      <Route path="" element={<CollectionManageRouteOrchestrator />} />
      <Route
        path="payments/*"
        element={
          <PageContainer>
            <CollectionManagePayments />
            <Outlet />
          </PageContainer>
        }
      >
        <Route
          path="payment/:payment/order-summary"
          element={
            <EnsureAuth>
              <OrderSummaryPage />
              <Outlet />
            </EnsureAuth>
          }
        >
          <Route
            path="edit-attendee"
            element={
              <EnsureAuth>
                <EditAttendeePage />
              </EnsureAuth>
            }
          />
        </Route>
        <Route
          path=":payment/refunds/*"
          element={
            <PageContainer>
              <RefundsManagePage />
            </PageContainer>
          }
        />
        <Route
          path=":payment/recurring/*"
          element={
            <PageContainer>
              <PaymentDetailsPage />
              <Outlet />
            </PageContainer>
          }
        />
        <Route
          path="payment/:payment/scheduled/:invoice/*"
          element={
            <PageContainer>
              <PaymentDetailsPage />
              <Outlet />
            </PageContainer>
          }
        />
        <Route
          path="message-center/*"
          element={
            <PageContainer>
              <MessageCenterPage />
              <Outlet />
            </PageContainer>
          }
        />
        <Route
          path="print-shipping-label/:payment"
          element={<PrintShippingLabelPage />}
        />
        <Route
          path="print-shipping-label-summary/:payment"
          element={<PrintShippingLabelSummaryPage />}
        />
      </Route>
      <Route
        path="items/*"
        element={
          <PageContainer>
            <CollectionManageItemsTab />
            <Outlet />
          </PageContainer>
        }
      >
        <Route path="ticket/:ticket/edit" element={<TicketFormPage />} />
        <Route path="item/:item/edit" element={<ItemFormPage />} />
        <Route path="item/:item/report" element={<ItemReportPage />} />
        <Route
          path="item/:item/waitlist"
          element={
            <PageContainer>
              <ItemWaitlistPage />
              <Outlet />
            </PageContainer>
          }
        >
          <Route
            path="message-center/*"
            element={
              <PageContainer>
                <MessageCenterPage />
                <Outlet />
              </PageContainer>
            }
          />
        </Route>
      </Route>
      <Route
        path="items/item/:item"
        element={
          <PageContainer>
            <ItemSpecificPage />
            <Outlet />
          </PageContainer>
        }
      >
        <Route
          path="payment/:payment/order-summary"
          element={
            <EnsureAuth>
              <OrderSummaryPage />
            </EnsureAuth>
          }
        />
      </Route>
      <Route
        path="items/ticket/:ticket"
        element={
          <PageContainer>
            <TicketSpecificPage />
            <Outlet />
          </PageContainer>
        }
      >
        <Route
          path="payment/:payment/edit-attendee"
          element={
            <EnsureAuth>
              <EditAttendeePage />
            </EnsureAuth>
          }
        />
        <Route
          path="payment/:payment/order-summary/*"
          element={
            <EnsureAuth>
              <OrderSummaryPage />
              <Outlet />
            </EnsureAuth>
          }
        >
          <Route
            path="edit-attendee"
            element={
              <EnsureAuth>
                <EditAttendeePage />
              </EnsureAuth>
            }
          />
        </Route>
      </Route>
      <Route
        path="forms-signups/*"
        element={
          <PageContainer>
            <CollectionManageFormsSignupsTab />
            <Outlet />
          </PageContainer>
        }
      >
        <Route path="forms/:form/edit" element={<FormFormPage />} />
        <Route path="signups/:signup/edit" element={<SignUpFormPage />} />
      </Route>
      <Route
        path="forms-signups/forms/:form"
        element={
          <PageContainer>
            <FormSpecificPage />
            <Outlet />
          </PageContainer>
        }
      >
        <Route
          path="payment/:payment/order-summary"
          element={<OrderSummaryPage />}
        />
      </Route>
      <Route
        path="forms-signups/signups/:signup"
        element={
          <PageContainer>
            <SignupSpecificPage />
            <Outlet />
          </PageContainer>
        }
      />
      <Route
        path="visitors/*"
        element={
          <PageContainer>
            <CollectionManageVisitorsTable />
            <Outlet />
          </PageContainer>
        }
      >
        <Route
          path="message-center/*"
          element={
            <PageContainer>
              <MessageCenterPage />
              <Outlet />
            </PageContainer>
          }
        />
      </Route>
      <Route
        path="shipping/*"
        element={
          <PageContainer>
            <CollectionManageShippingTable />
            <Outlet />
          </PageContainer>
        }
      >
        <Route
          path="print-shipping-label/:payment"
          element={<PrintShippingLabelPage />}
        />
        <Route
          path="visitors/*"
          element={
            <PageContainer>
              <CollectionManageVisitorsTable />
              <Outlet />
            </PageContainer>
          }
        >
          <Route
            path="message-center/*"
            element={
              <PageContainer>
                <MessageCenterPage />
                <Outlet />
              </PageContainer>
            }
          />
        </Route>
        <Route
          path="print-shipping-label/:payment"
          element={<PrintShippingLabelPage />}
        />
        <Route
          path="print-shipping-label-summary/:payment"
          element={<PrintShippingLabelSummaryPage />}
        />
        <Route
          path="message-center/*"
          element={
            <PageContainer>
              <MessageCenterPage />
              <Outlet />
            </PageContainer>
          }
        />
      </Route>
      <Route
        path="message-center/*"
        element={
          <PageContainer>
            <MessageCenterPage />
            <Outlet />
          </PageContainer>
        }
      />
    </Route>
  </Route>,
]
