import {endpoints} from '../../endpoints'
import {makeQueryUpdate, makeUseMutation} from '../use-mutation'

export const useCreateGroupPageTeamMemberMutation = makeUseMutation(
  endpoints.groupPageTeamMembers.create,
  () => ({
    regular: (newTeamMember) => [
      makeQueryUpdate(
        endpoints.groupPageSections.detail,
        (groupPageSection) =>
          groupPageSection
            ? {
                ...groupPageSection,
                team_members: [
                  ...(groupPageSection.team_members || []),
                  newTeamMember,
                ],
              }
            : groupPageSection,

        {pathParams: {sectionName: 'meet_the_team'}},
      ),
    ],
  }),
)

export const useUpdateGroupPageTeamMemberMutation = makeUseMutation(
  endpoints.groupPageTeamMembers.update,
  (vars) => ({
    optimistic:
      vars.body?.position == null
        ? []
        : [
            makeQueryUpdate(
              endpoints.groupPageSections.detail,
              (prevGroupPageSection) => {
                if (!prevGroupPageSection) {
                  return prevGroupPageSection
                }

                const newPosition = vars.body?.position ?? -1

                return {
                  ...prevGroupPageSection,
                  team_members: prevGroupPageSection.team_members?.map((tm) =>
                    tm.id === vars.body?.id
                      ? {
                          ...tm,
                          position: newPosition,
                        }
                      : newPosition >= tm.position
                        ? {
                            ...tm,
                            position: tm.position + 1,
                          }
                        : tm,
                  ),
                }
              },
              {pathParams: {sectionName: 'meet_the_team'}},
            ),
          ],
    regular: (updatedTeamMember) => [
      makeQueryUpdate(
        endpoints.groupPageTeamMembers.detail,
        () => updatedTeamMember,
        {pathParams: vars.pathParams},
      ),
      makeQueryUpdate(
        endpoints.groupPageSections.detail,
        (groupPageSection) =>
          groupPageSection
            ? {
                ...groupPageSection,
                team_members: groupPageSection.team_members?.map(
                  (teamMember) =>
                    teamMember.id === updatedTeamMember.id
                      ? updatedTeamMember
                      : teamMember,
                ),
              }
            : groupPageSection,

        {pathParams: {sectionName: 'meet_the_team'}},
      ),
    ],
  }),
)

export const useDeleteGroupPageTeamMemberMutation = makeUseMutation(
  endpoints.groupPageTeamMembers.delete,
  (vars) => ({
    regular: () => [
      makeQueryUpdate(
        endpoints.groupPageSections.detail,
        (groupPageSection) =>
          groupPageSection
            ? {
                ...groupPageSection,
                team_members: groupPageSection.team_members?.filter(
                  (teamMember) => teamMember.id !== vars.pathParams.id,
                ),
              }
            : groupPageSection,

        {pathParams: {sectionName: 'meet_the_team'}},
      ),
    ],
  }),
)
