import cubejs, {
  DateRange,
  DeeplyReadonly,
  Query,
  QueryRecordType,
} from '@cubejs-client/core'
import {
  UseCubeQueryOptions,
  UseCubeQueryResult,
  useCubeQuery as useReactCubeQuery,
} from '@cubejs-client/react'
import * as Util from '@cheddarup/util'
import {useMemo} from 'react'
import {useCubeTokenMutation} from '@cheddarup/api-client'
import {useManagerRoleId} from 'src/components/ManageRoleProvider'

import config from '../config'

const cachedCubejsApiTokensMap: Record<string, string> = {}

export function useCubeApi() {
  const [managerRoleId] = useManagerRoleId()
  const cubeTokenMutation = useCubeTokenMutation()

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const cubeApi = useMemo(
    () =>
      cubejs(
        async () => {
          const cachedCubejsApiTokenKey = managerRoleId ?? 'own'
          const cachedCubejsApiToken =
            cachedCubejsApiTokensMap[cachedCubejsApiTokenKey]

          if (cachedCubejsApiToken) {
            return cachedCubejsApiToken
          }

          const {token} = await cubeTokenMutation.mutateAsync()

          cachedCubejsApiTokensMap[cachedCubejsApiTokenKey] = token

          return token
        },
        {
          apiUrl: `${config.cubeApiUrl}/cubejs-api/v1`,
        },
      ),
    [managerRoleId],
  )

  return cubeApi
}

export const useCubeQuery = <
  TData,
  TQuery extends DeeplyReadonly<Query> = DeeplyReadonly<Query>,
>(
  query: TQuery,
  options?: UseCubeQueryOptions,
): UseCubeQueryResult<
  any,
  unknown extends TData ? QueryRecordType<TQuery> : TData
> =>
  useReactCubeQuery(
    {
      ...query,
      timeDimensions: query.timeDimensions?.map((td) => ({
        ...td,
        dateRange: td.dateRange ? formatDateRange(td.dateRange) : td.dateRange,
        compareDateRange:
          'compareDateRange' in td
            ? td.compareDateRange.map((dr) => formatDateRange(dr))
            : undefined,
      })),
    },
    options,
  )

// MARK: – Helpers

const formatDateRange = (
  dateRange: DeeplyReadonly<DateRange>,
): DeeplyReadonly<DateRange> => {
  if (typeof dateRange === 'string') {
    return Util.formatISO(new Date(dateRange), {representation: 'date'})
  }
  return dateRange.map((d) => formatDateRange(d)) as [string, string]
}
