import * as Yup from 'yup'
import React, {useRef, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {
  useCreateGroupPageCategoryMutation,
  useUpdateGroupPageCategoryMutation,
} from '@cheddarup/api-client'

import {
  GroupCollectionList,
  GroupCollectionListProps,
} from '../components/CollectionsGroup/GroupCollectionList'
import {CollectionSectionFormFormik} from './AddCollectionsPage'
import {useForkRef, useFormik} from '@cheddarup/react-util'
import {isAxiosError} from 'axios'

interface GroupCollectionsFormProps
  extends Omit<React.ComponentPropsWithoutRef<'form'>, 'onSubmit'>,
    Pick<GroupCollectionListProps, 'query' | 'hiddenRowsData'> {
  formik: CollectionSectionFormFormik
}

const GroupCollectionsForm: React.FC<GroupCollectionsFormProps> = ({
  formik,
  query,
  hiddenRowsData,
  ...restProps
}) => {
  const categoryModalRef = useRef<WebUI.DialogInstance>(null)
  const [category, setCategory] = useState<Api.GroupPageCategory | null>(null)

  return (
    <form
      className="flex flex-col"
      onReset={formik.handleReset}
      onSubmit={formik.handleSubmit}
      {...restProps}
    >
      <WebUI.VStack className="items-stretch justify-start gap-3 px-8 py-6 sm:flex-row sm:items-center sm:justify-between">
        <WebUI.Switch
          size="compact"
          checked={formik.values.details.removeWhenClosed}
          onChange={(event) =>
            formik.setFieldValue(
              'details.removeWhenClosed',
              event.target.checked,
            )
          }
        >
          Remove collections from Group Page when closed
        </WebUI.Switch>
        <WebUI.Button
          variant="secondary"
          size="compact"
          onClick={() => {
            setCategory(null)
            categoryModalRef.current?.show()
          }}
        >
          Add Category
        </WebUI.Button>
      </WebUI.VStack>
      <WebUI.Separator variant="primary" />
      <WebUI.VStack className="gap-6 p-8">
        <WebUI.FormField
          label="Section Header"
          required
          error={formik.errors.headline}
        >
          <WebUI.Input
            name="headline"
            value={formik.values.headline}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
        <WebUI.FormField label="Description" error={formik.errors.description}>
          <WebUI.Textarea
            name="description"
            rows={3}
            placeholder="Smaller text under headline"
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
        <GroupCollectionList
          query={query}
          hiddenRowsData={hiddenRowsData}
          onEditCategory={(newCategory) => {
            setCategory(newCategory)
            categoryModalRef.current?.show()
          }}
        />
      </WebUI.VStack>
      <CategoryModal
        key={category?.id}
        ref={categoryModalRef}
        category={category}
      />
    </form>
  )
}

// MARK: – CategoryModal

interface CategoryFormValues {
  name: string
}

interface CategoryModalProps extends WebUI.ModalProps {
  category: Api.GroupPageCategory | null
  onDidSubmit?: () => void
}

const CategoryModal = React.forwardRef<
  WebUI.DialogInstance,
  CategoryModalProps
>(
  (
    {onDidSubmit, category, onDidHide, className, ...restProps},
    forwardedRef,
  ) => {
    const ownRef = useRef<WebUI.DialogInstance>(null)
    const ref = useForkRef(forwardedRef, ownRef)
    const createGroupPageCategoryMutation = useCreateGroupPageCategoryMutation()
    const updateGroupPageCategoryMutation = useUpdateGroupPageCategoryMutation()
    const growlActions = WebUI.useGrowlActions()

    const formik = useFormik<CategoryFormValues>({
      enableReinitialize: true,
      initialValues: {
        name: category?.name ?? '',
      },
      validationSchema: Yup.object().shape({
        name: Yup.string().required('Required').trim(),
      }),
      onSubmit: async (values) => {
        try {
          if (category) {
            await updateGroupPageCategoryMutation.mutateAsync({
              pathParams: {id: category.id},
              body: {
                category: {
                  name: values.name,
                },
              },
            })
          } else {
            await createGroupPageCategoryMutation.mutateAsync({
              body: {
                category: {
                  name: values.name,
                },
              },
            })
          }
        } catch (err: any) {
          if (isAxiosError(err) && err.response) {
            growlActions.show('error', {
              title: 'Error',
              body:
                (Object.values(
                  err.response.data.errors ?? {},
                ).flat()[0] as any) || 'Something went wrong...',
            })
          } else {
            growlActions.show('error', {
              title: 'Error',
              body: 'Something went wrong...',
            })
          }
        }

        onDidSubmit?.()

        ownRef.current?.hide()
      },
    })

    return (
      <WebUI.Prompt
        ref={ref}
        aria-label="Group page category form"
        className={WebUI.cn('p-7', className)}
        onDidHide={() => {
          onDidHide?.()

          formik.resetForm()
        }}
        {...restProps}
      >
        {(dialog) => (
          <WebUI.VStack className="gap-4">
            <WebUI.PromptHeader
              heading={category ? 'Edit a Category' : 'Create a Category'}
              subheading="Add a category header and drag it into place on your collection list."
            />
            <form
              className="flex flex-col gap-3"
              onSubmit={formik.handleSubmit}
              onReset={formik.handleReset}
            >
              <WebUI.FormField error={formik.errors.name}>
                <WebUI.Input
                  name="name"
                  size="compact"
                  placeholder="Category Name"
                  maxLength={44}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </WebUI.FormField>
              <WebUI.HStack className="gap-3">
                <WebUI.Button type="submit" loading={formik.isSubmitting}>
                  {category ? 'Update Category' : 'Create Category'}
                </WebUI.Button>
                <WebUI.Button
                  variant="secondary"
                  disabled={formik.isSubmitting}
                  onClick={() => dialog.hide()}
                >
                  Cancel
                </WebUI.Button>
              </WebUI.HStack>
            </form>
          </WebUI.VStack>
        )}
      </WebUI.Prompt>
    )
  },
)

export default GroupCollectionsForm
