import React from 'react'
import {NextAnchorButton, NextAnchorButtonProps} from '@cheddarup/web-ui/next'
import {NextLink, NextLinkProps} from './Link'
import * as Util from '@cheddarup/util'

export interface NextLinkButtonProps
  extends Util.Merge<NextLinkProps, NextAnchorButtonProps> {}

export const NextLinkButton = React.forwardRef<
  HTMLAnchorElement,
  NextLinkButtonProps
>(({render, to, ...restProps}, forwardedRef) => {
  return (
    <NextAnchorButton
      ref={forwardedRef}
      render={render ?? <NextLink variant="headless" to={to} />}
      {...restProps}
    />
  )
})
