import {useBeforeUnload} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import {useCallback, useMemo} from 'react'
import {useLiveRef} from '@cheddarup/react-util'
import {
  FetchInput,
  MutateFunction,
  UseMutationResult,
} from '@cheddarup/api-client'

export function useUndoableMutation<
  T extends UseMutationResult<any, any, number>,
  TVariables = T extends UseMutationResult<
    any,
    infer _T extends FetchInput<any>,
    any
  >
    ? _T
    : never,
>(
  mutation: T,
): T & {
  mutateWithUndo: (
    growlPayload: Omit<WebUI.GrowlAlertUndoPayload, 'timeout'>,
    ...args: Parameters<MutateFunction<T['data'], unknown, TVariables>>
  ) => void
  mutateAsyncWithUndo: (
    growlPayload: Omit<WebUI.GrowlAlertUndoPayload, 'timeout'>,
    ...args: Parameters<MutateFunction<T['data'], unknown, TVariables>>
  ) => Promise<MutateFunction<T['data']>>
} {
  const mutationRef = useLiveRef(mutation)
  const growlActions = WebUI.useGrowlActions()

  useBeforeUnload(
    useCallback((event) => {
      if (!mutationRef.current.isCancelPendingRef.current) {
        return
      }

      event.preventDefault()

      // biome-ignore lint/suspicious/noAssignInExpressions:
      return (event.returnValue = '') // See https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#compatibility_notes
    }, []),
  )

  return useMemo(() => {
    function showUndoGrowl(payload: WebUI.GrowlAlertUndoPayload) {
      growlActions.show('undo', {
        ...payload,
        timeout: mutationRef.current.timeout,
        onUndo: () => {
          payload.onUndo?.()
          try {
            mutationRef.current.cancelRef.current?.()
          } catch {
            // noop, ignore "User cancelled error"
          }
        },
        onDidClose: () => {
          payload.onDidClose?.()
          mutationRef.current.forceRunRef.current?.()
        },
      })
    }

    return {
      ...mutationRef.current,
      mutateWithUndo: (growlPayload, ...args) => {
        showUndoGrowl(growlPayload)
        return mutationRef.current.mutate(...args)
      },
      mutateAsyncWithUndo: (growlPayload, ...args) => {
        showUndoGrowl(growlPayload)
        return mutationRef.current.mutateAsync(...args)
      },
    }
  }, [growlActions])
}
