import {api, useDeleteInventoryGroupMutation} from '@cheddarup/api-client'
import * as WebUI from '@cheddarup/web-ui'
import {PlanUpgradeButton} from 'src/components/PremiumFeaturesSideSheet'
import AddItemQuantityGroupsModal from './AddItemQuantityGroupsModal'
import React, {useState} from 'react'

const MAX_INVENTORY_GROUPS = 2

export interface ItemQuantityGroupsModalProps extends WebUI.ModalProps {
  collectionId: number
}

const ItemQuantityGroupsModal = React.forwardRef<
  WebUI.DialogInstance,
  ItemQuantityGroupsModalProps
>(
  (
    {collectionId, initialVisible = false, className, ...restProps},
    forwardedRef,
  ) => {
    const [inventoryGroupToEdit, setInventoryGroupToEdit] =
      useState<Api.InventoryGroup | null>(null)

    const isProTabQuery = api.tabs.detail.useQuery(
      {
        pathParams: {
          tabId: collectionId,
        },
      },
      {select: (tab) => tab.is_pro},
    )
    const inventoryGroupsQuery = api.inventoryGroups.list.useQuery(
      {
        pathParams: {
          tabId: collectionId,
        },
      },
      {
        select: (inventoryGroups) =>
          inventoryGroups.filter((ig) =>
            ig.inventory_items.some((ii) => ii.variant_uuid === 'NONE'),
          ),
      },
    )

    return (
      <WebUI.Modal
        ref={forwardedRef}
        className={WebUI.cn(
          '[&_>_.ModalContentView]:max-w-screen-sm [&_>_.ModalContentView]:rounded-large [&_>_.ModalContentView]:p-10',
          className,
        )}
        initialVisible={initialVisible}
        {...restProps}
      >
        <WebUI.ModalCloseButton />
        <WebUI.VStack className="gap-6">
          <WebUI.Heading as="h2">Item Quantity Groups</WebUI.Heading>
          <WebUI.Text className="font-light">
            Add up to two Item Quantity Groups that allow you to enter the total
            quantity available across multiple items. The selected items will
            show “sold out” once your combined-item quantity limit is reached.
          </WebUI.Text>
          <WebUI.Text className="font-light italic">
            Example: If you are selling three types of event registrations but
            only have 100 spots available at the event, you would select your
            three tickets and enter a quantity of 100.
          </WebUI.Text>
          <div className="flex flex-col gap-5">
            {inventoryGroupsQuery.data?.map((iGroup, idx) => (
              <InventoryGroupRow
                key={idx}
                inventoryGroup={iGroup}
                onEdit={() => setInventoryGroupToEdit(iGroup)}
              />
            ))}
          </div>
          {isProTabQuery.data ? (
            <AddItemQuantityGroupsModal
              key={inventoryGroupToEdit?.id}
              collectionId={collectionId}
              inventoryGroup={inventoryGroupToEdit}
              onDidHide={() => setInventoryGroupToEdit(null)}
              visible={!!inventoryGroupToEdit}
              disclosure={
                inventoryGroupsQuery.data &&
                inventoryGroupsQuery.data.length < MAX_INVENTORY_GROUPS ? (
                  <WebUI.DialogDisclosure className="self-start">
                    Add Item Quantity Group
                  </WebUI.DialogDisclosure>
                ) : null
              }
            />
          ) : (
            <PlanUpgradeButton upgradeTo="pro" />
          )}
        </WebUI.VStack>
      </WebUI.Modal>
    )
  },
)

// MARK: - InventoryGroupRow

interface InventoryGroupRowProps extends React.ComponentPropsWithoutRef<'div'> {
  inventoryGroup: Api.InventoryGroup
  onEdit: () => void
}

const InventoryGroupRow: React.FC<InventoryGroupRowProps> = ({
  className,
  inventoryGroup,
  onEdit,
  ...restProps
}) => {
  const deleteInventoryGroupMutation = useDeleteInventoryGroupMutation()

  return (
    <WebUI.Panel
      className={WebUI.cn(
        'flex flex-row items-center px-7 py-5 *:flex-1',
        className,
      )}
      {...restProps}
    >
      <WebUI.Text className="text-ds-sm">{inventoryGroup.name}</WebUI.Text>
      <WebUI.Text className="text-ds-sm">
        Qty Available: {inventoryGroup.available_quantity}
      </WebUI.Text>
      <WebUI.Button
        className="text-center text-ds-sm"
        variant="link"
        onClick={() => onEdit()}
      >
        Edit
      </WebUI.Button>
      <WebUI.Tooltip>
        <WebUI.TooltipAnchor
          render={
            <WebUI.IconButton
              className="!flex-0"
              size="default_alt"
              loading={deleteInventoryGroupMutation.isPending}
              onClick={() =>
                deleteInventoryGroupMutation.mutate({
                  pathParams: {
                    inventoryGroupId: inventoryGroup.id,
                    tabId: inventoryGroup.tab_id,
                  },
                })
              }
            >
              <WebUI.PhosphorIcon icon="x" />
            </WebUI.IconButton>
          }
        />
        <WebUI.TooltipContent>Delete Group</WebUI.TooltipContent>
      </WebUI.Tooltip>
    </WebUI.Panel>
  )
}

export default ItemQuantityGroupsModal
