// @ts-ignore

import {z} from '@cheddarup/util'

import {tabObjectFieldMetadataSchema, tabObjectFieldTypeSchema} from './fields'
import {
  tabItemQuantityDiscountCalculationMethodSchema,
  variantListingSchema,
} from './tab-items'
import {
  publicSignUpSpotSchema,
  publicTabSignupSchema,
  publicTimeSlotSchema,
} from './public-tabs'

export const cardEntryMethodSchema = z.enum([
  'swipe',
  'manual',
  'scan',
  'tapOnMobile',
])

export const cartPointOfSaleSchema = z.object({
  cardEntryMethod: cardEntryMethodSchema.nullish(),
  device: z.enum(['Android', 'iOS']).nullish(),
  codeUsed: z.string().nullish(),
  collectorName: z.string().nullish(),
  collectorEmail: z.string().nullish(),
  isTerminal: z.boolean().nullish(),
  signature: z.string().nullish(),
  usesPaymentIntents: z.boolean().nullish(),
})

export const cartShippingToSchema = z.object({
  name: z.string().nullish(),
  address: z.string().nullish(),
  city: z.string().nullish(),
  country: z.string().nullish(),
  state: z.string().nullish(),
  zip: z.string().nullish(),
})

export const cartShippingInfoSchema = z.object({
  shippingMethod: z.enum(['toMe', 'localPickup']).nullish(),
  shipTo: cartShippingToSchema.nullish(),
})

export const cartFeesSchema = z.object({
  cash: z.number(),
  echeck: z.number(),
  card: z.number(),
  paypal: z.number().optional(),
})

export const cheddarUpCartObjectTabFormFieldViewSchema = z.object({
  item_field_id: z.number(),
  value: z.string(),
  name: z.string(),
  required: z.boolean().nullable(),
  position: z.number().nullable(),
  field_type: tabObjectFieldTypeSchema,
  values: z.string().nullable(),
  metadata: tabObjectFieldMetadataSchema,
})

export const cartItemDiscountSchema = z.object({
  amountOff: z.number(),
  discountAmount: z.number(),
  type: tabItemQuantityDiscountCalculationMethodSchema,
})

export const cheddarUpCartItemSchema = z.object({
  id: z.number(),
  amount: z.number(),
  quantity: z.number(),
  tab_item: z.any(),
  cart_field_views: cheddarUpCartObjectTabFormFieldViewSchema.array(),
  total: z.number(),
  detail: z.object({
    variant: variantListingSchema.nullish(),
    itemDiscount: cartItemDiscountSchema.optional(),
  }),
})

export const cheddarUpCartFormSchema = z.object({
  id: z.number(),
  tab_form: z.any(),
  cart_field_views: cheddarUpCartObjectTabFormFieldViewSchema.array(),
  detail: z.object({
    linked_item_id: z.number().optional(),
    name: z.string().optional(),
  }),
})

export const cheddarUpCartTimeSlotTimeSlotSchema = publicTimeSlotSchema.extend({
  spot: publicSignUpSpotSchema.omit({time_slots: true}).extend({
    signup: publicTabSignupSchema.omit({visible_spots: true}),
  }),
})

export const cheddarUpCartTimeSlotSchema = z.object({
  id: z.number(),
  amount: z.number(),
  detail: z.object({
    itemType: z.literal('ticket'),
  }),
  quantity: z.number(),
  total: z.number(),
  time_slot: cheddarUpCartTimeSlotTimeSlotSchema,
  cart_field_views: cheddarUpCartObjectTabFormFieldViewSchema.array(),
})

export const cartSchema = z.object({
  paymentComplete: z.boolean(),
  uuid: z.string(),
  total: z.number(),
  subtotal: z.number(),
  totalDiscount: z.number(),
  totalTaxes: z.number(),
  recurringTotal: z.number(),
  dripOptIn: z.boolean().nullish(),
  fees: cartFeesSchema.nullish(),
  itemCount: z.number(),
  items_total: z.number(),
  totalWithoutFutureRecurring: z.number(),
  items: cheddarUpCartItemSchema.array(),
  forms: cheddarUpCartFormSchema.array(),
  time_slots: cheddarUpCartTimeSlotSchema.array(),
  shippingMethod: z.enum(['localPickup', 'toMe']).nullish(),
  shipping: z.object({
    localPickup: z.number(),
    toMe: z.number().nullish(),
  }),
  member: z
    .object({
      email: z.string().nullish(),
      name: z.string().nullish(),
    })
    .nullish(),
  smartlook: z
    .object({
      visitorId: z.string().nullish(),
      playUrl: z.string().nullish(),
    })
    .nullish(),
  discounts: z.record(
    z.object({
      amount: z.number(),
      basis: z.number(),
    }),
  ),
  analytics: z.object({
    pointOfSale: cartPointOfSaleSchema,
  }),
  payerChoseToCoverFees: z.boolean().nullish(),
  locCheck: z
    .object({
      zip: z.string().nullish(),
      city: z.string().nullish(),
      country_code: z.string().nullish(),
      region_name: z.string().nullish(),
    })
    .nullish(),
  pcdata: z.number().nullish(),
  fbdata: z.string().nullish(),
  cartPaid: z
    .object({
      identity: z.string().nullish(),
      confidence: z.string().nullish(),
    })
    .nullish(),
  shippingInfo: cartShippingInfoSchema,
  phoneNumber: z.string().nullish(),
})

declare global {
  namespace Api {
    // @ts-ignore
    type CardEntryMethod = z.infer<typeof cardEntryMethodSchema>
    // @ts-ignore
    type Cart = z.infer<typeof cartSchema>
    // @ts-ignore
    type CheddarUpCartTimeSlot = z.infer<typeof cheddarUpCartTimeSlotSchema>
    // @ts-ignore
    type CheddarUpCartTimeSlotTimeSlot = z.infer<
      typeof cheddarUpCartTimeSlotTimeSlotSchema
    >
    // @ts-ignore
    type CheddarUpCartItem = z.infer<typeof cheddarUpCartItemSchema>
    // @ts-ignore
    type CheddarUpCartObjectTabFormFieldView = z.infer<
      typeof cheddarUpCartObjectTabFormFieldViewSchema
    >
    // @ts-ignore
    type CartShippingInfo = z.infer<typeof cartShippingInfoSchema>
    // @ts-ignore
    type CartShippingTo = z.infer<typeof cartShippingToSchema>
    // @ts-ignore
    type CartPointOfSale = z.infer<typeof cartPointOfSaleSchema>
    // @ts-ignore
    type CartFees = z.infer<typeof cartFeesSchema>
    // @ts-ignore
    type CheddarUpCartForm = z.infer<typeof cheddarUpCartFormSchema>
    type CartItemDiscount = z.infer<typeof cartItemDiscountSchema>

    // TODO: Get rid of this
    // @ts-ignore
    interface CartItemField {
      tab_item_field: Api.PublicTabObjectField
      value: string
    }
    // @ts-ignore
    interface CartFormField {
      tab_form_field: {
        id: number
      }
      value: string
    }
    // @ts-ignore
    interface CartItem {
      tab_item: Api.PublicTabItem
      type: 'TabItem' | 'CustomCharge'
      amount: number
      quantity: number
      fields: CartItemField[]
    }
    // @ts-ignore
    interface CartForm {
      tab_form: {
        id: number
      }
      fields: CartFormField[]
    }
  }
}
