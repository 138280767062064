import {useNavigate} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'

const BankModificationDisabledPage = () => {
  const navigate = useNavigate()
  return (
    <WebUI.Alert
      aria-label="Bank modification disabled"
      initialVisible
      onDidHide={() => navigate('..')}
    >
      <WebUI.AlertHeader>Banking changes have been disabled</WebUI.AlertHeader>
      <WebUI.AlertContentView
        text={
          <>
            Banking changes have been disabled by your council. Please reach out
            to <span className="text-tint">friendlysupport@cheddarup.com</span>{' '}
            to request a change to banking account information.
          </>
        }
      />
    </WebUI.Alert>
  )
}

export default BankModificationDisabledPage
