import {useLocation, useNavigate} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import React, {useEffect, useRef, useState} from 'react'
import queryString from 'query-string'
import * as Util from '@cheddarup/util'
import {api} from '@cheddarup/api-client'
import MarketPlaceLogoIcon from 'src/images/ManagersLogoIcon.svg'

import {
  MarketplaceShopItemsFilterBar,
  MarketplaceShopItemsFiltersOverview,
} from './components'
import {MarketplacePageContentContainer} from '../components'
import {MarketplacePageContainer} from '../containers'
import {
  MarketplaceItemsGalleryContainer,
  MarketplaceShopItemsFiltersContainer,
} from './containers'

const MarketplaceShopItemsPage = () => {
  const location = useLocation()
  const [sortBy, setSortBy] = useState({
    sort: 'updated_at',
    direction: 'desc' as 'desc' | 'asc',
  })
  const [filtersVisible, setFiltersVisible] = useState(false)
  const [selectedCatalogIds, setSelectedCatalogIds] = useState(
    location.state?.catalogIds ?? [],
  )

  const _searchKeyword = queryString.parse(location.search).searchKeyword
  const searchKeyword = typeof _searchKeyword === 'string' ? _searchKeyword : ''

  return (
    <MarketplacePageContainer
      initialSearchType="item"
      filterSortElement={
        <MarketplaceShopItemsFilterBar
          sortBy={sortBy}
          filtersCount={selectedCatalogIds.length}
          onChangeSortBy={setSortBy}
          onClickFilters={() =>
            setFiltersVisible((prevFiltersVisible) => !prevFiltersVisible)
          }
        />
      }
    >
      <MarketplaceShopItemsFiltersOverview
        className={'sticky top-16 z-[1] w-full border-b px-3'}
        catalogIds={selectedCatalogIds}
        onChangeCatalogIds={setSelectedCatalogIds}
      />
      <WebUI.VStack className="relative shrink-0 grow basis-auto">
        <MarketplacePageContentContainer>
          <MarketplaceItemsGalleryContainer
            searchKeyword={searchKeyword}
            sortBy={sortBy}
            selectedCatalogIds={selectedCatalogIds}
            onClearFilters={() => setSelectedCatalogIds([])}
          />
        </MarketplacePageContentContainer>
        <MarketplaceShopItemsFiltersContainer
          visible={filtersVisible}
          selectedCatalogIds={selectedCatalogIds}
          onChangeSelectedCatalogIds={setSelectedCatalogIds}
          onVisibleChange={(newVisible) => setFiltersVisible(newVisible)}
        />
      </WebUI.VStack>
      <MarketplaceGuideModal />
    </MarketplacePageContainer>
  )
}

// MARK: – MarketplaceGuideModal

const MarketplaceGuideModal: React.FC<
  React.ComponentPropsWithoutRef<'div'>
> = ({className, ...restProps}) => {
  const navigate = useNavigate()
  const modalRef = useRef<WebUI.DialogInstance>(null)
  const [welcomeModalLocalStorage, setWelcomeModalLocalStorage] =
    WebUI.useLocalStorage('welcomeModal', false)
  const {data: marketplaceName} = api.auth.session.useQuery(undefined, {
    select: (session) =>
      session.organization_data?.internalMarketplace?.organizerNickname ?? '',
  })
  const planNameQuery = api.subscriptions.invoices.useQuery(undefined, {
    select: (invoices) => invoices.plan.name,
  })

  useEffect(() => {
    if (!welcomeModalLocalStorage || planNameQuery.data === 'BASIC') {
      modalRef.current?.show()
    }
  }, [planNameQuery.data, welcomeModalLocalStorage])

  return (
    <WebUI.Modal
      ref={modalRef}
      aria-label="Welcome to the Stylist Marketplace"
      className={WebUI.cn(
        '[&_>_.ModalContentView]:rounded-[20px] [&_>_.ModalContentView]:p-8 sm:[&_>_.ModalContentView]:max-w-[480px]',
        className,
      )}
      initialVisible={false}
      visible={planNameQuery.data === 'BASIC' ? true : undefined}
      hideOnClickOutside={planNameQuery.data !== 'BASIC'}
      hideOnEsc={planNameQuery.data !== 'BASIC'}
      onDidHide={() => setWelcomeModalLocalStorage(true)}
      {...restProps}
    >
      {(dialog) => (
        <>
          {planNameQuery.data !== 'BASIC' && <WebUI.ModalCloseButton />}

          <WebUI.VStack className="items-center gap-6 text-center">
            <img className="w-[100px]" src={MarketPlaceLogoIcon} alt="" />
            <div className="font-bold text-ds-md">
              Welcome to the {marketplaceName} Marketplace
            </div>
            <div className="font-bold text-error">
              Access is exclusive only to other{' '}
              {Util.pluralize(marketplaceName ?? '')}
            </div>
            <WebUI.VStack className="gap-0_5">
              <span className="font-bold">Looking for a specific item?</span>
              <div>
                See if another {marketplaceName} has it listed on their sale.
                Search by Item or {marketplaceName}. Even filter items by
                catalog to narrow your search.
              </div>
            </WebUI.VStack>
            <WebUI.Button
              onClick={() => {
                if (planNameQuery.data === 'BASIC') {
                  navigate('i/plans')
                } else {
                  dialog.hide()
                }
              }}
            >
              {planNameQuery.data === 'BASIC'
                ? 'Go Pro to Start Searching'
                : 'Start Searching'}
            </WebUI.Button>
          </WebUI.VStack>
        </>
      )}
    </WebUI.Modal>
  )
}

export default MarketplaceShopItemsPage
