import React, {useImperativeHandle, useRef} from 'react'
import Webcam from 'react-webcam'
import * as WebUI from '@cheddarup/web-ui'

export interface CameraModalInstance {
  show: () => Promise<string> | null
  dialog: WebUI.DialogInstance | null
}

export interface CameraModalProps extends WebUI.ModalProps {}

export const CameraModal = React.forwardRef<
  CameraModalInstance,
  CameraModalProps
>(
  (
    {initialVisible = false, onDidHide, className, ...restProps},
    forwardedRef,
  ) => {
    const dialogRef = useRef<WebUI.DialogInstance>(null)
    const onShowDidResolveRef = useRef<((blob: string) => void) | undefined>(
      undefined,
    )
    const onShowDidRejectRef = useRef<((reason?: any) => void) | undefined>(
      undefined,
    )

    useImperativeHandle(
      forwardedRef,
      () => ({
        show: () =>
          new Promise((resolve, reject) => {
            dialogRef.current?.show()

            onShowDidResolveRef.current = resolve
            onShowDidRejectRef.current = reject
          }),
        dialog: dialogRef.current,
      }),
      [],
    )

    return (
      <WebUI.Modal
        ref={dialogRef}
        aria-label="Capture an image with camera"
        className={WebUI.cn('[&_>_.ModalContentView]:h-full', className)}
        initialVisible={initialVisible}
        onDidHide={() => {
          onDidHide?.()

          onShowDidRejectRef.current?.(null)
        }}
        {...restProps}
      >
        {(dialog) => (
          <>
            <WebUI.ModalCloseButton />
            <WebUI.ModalHeader>Take with camera</WebUI.ModalHeader>
            <div className="flex grow flex-col justify-center">
              <div className="relative flex flex-col items-center gap-4">
                <Webcam
                  className={
                    'w-full overflow-hidden rounded-[8px] border sm:h-[480px] sm:w-[640px]'
                  }
                  onUserMediaError={(err) => onShowDidRejectRef.current?.(err)}
                  screenshotQuality={1}
                  screenshotFormat="image/jpeg"
                >
                  {(webcam) => (
                    <WebUI.IconButton
                      className={
                        '-translate-x-1/2 absolute bottom-5 left-1/2 h-10 w-10 rounded-[20px] bg-teal-90'
                      }
                      onClick={() => {
                        const imageSrc = webcam.getScreenshot()
                        // biome-ignore lint/style/noNonNullAssertion:
                        onShowDidResolveRef.current?.(imageSrc!)
                        dialog.hide()
                      }}
                    >
                      <WebUI.PhosphorIcon icon="camera" />
                    </WebUI.IconButton>
                  )}
                </Webcam>
              </div>
            </div>
          </>
        )}
      </WebUI.Modal>
    )
  },
)
