import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {
  cardEntryMethodSchema,
  cartPointOfSaleSchema,
  cartSchema,
  cartShippingToSchema,
  paymentMethodTypeSchema,
} from '../schemas'

export default {
  detail: makeEndpoint({
    path: 'collections/:tabId/carts/:cartUuid',
    queryParamsSchema: z
      .object({
        name: z.string(),
        email: z.string(),
      })
      .partial()
      .optional(),
    responseSchema: cartSchema,
  }),
  intentDetail: makeEndpoint({
    path: 'collections/:tabId/carts/:cartUuid/terminal/intent',
    responseSchema: z.object({
      paymentIntentId: z.string(),
      clientSecret: z.string(),
      status: z.string(),
    }),
  }),
  create: makeEndpoint({
    path: 'collections/:tabId/carts',
    method: 'POST',
    bodySchema: z.object({
      branchName: z.string().optional(),
      name: z.string().optional(),
      email: z.string().optional(),
      shippingMethod: z.enum(['toMe', 'localPickup']).optional(),
      dripOptIn: z.boolean().optional(),
      record_payment: z.boolean().optional(),
      paymentSource: z.enum(['web', 'pointOfSale']).optional(),
      googleOptimizeThankYouPage: z.string().optional(),
      pointOfSale: cartPointOfSaleSchema.optional(),
      location: z
        .object({
          latitude: z.number(),
          longitude: z.number(),
        })
        .optional(),
    }),
    responseSchema: cartSchema,
  }),
  update: makeEndpoint({
    path: 'collections/:tabId/carts/:cartUuid',
    method: 'PUT',
    bodySchema: cartSchema
      .extend({
        branchName: z.string(),
        name: z.string(),
        email: z.string(),
        phoneNumber: z.string(),
        shippingMethod: z.enum(['toMe', 'localPickup']),
        shipTo: cartShippingToSchema,
        method: paymentMethodTypeSchema,
        pointOfSale: z
          .object({
            device: z.string(),
            cardEntryMethod: cardEntryMethodSchema,
            usesPaymentIntents: z.boolean(),
            isTerminal: z.boolean(),
            codeUsed: z.string(),
            collectorName: z.string(),
            collectorEmail: z.string(),
            signature: z.string(),
          })
          .partial(),
        setCustomer: z.boolean().optional(),

        force_captcha: z.boolean().optional(), // force triggers invalid_captcha error
      })
      .partial(),
    responseSchema: cartSchema,
  }),
  createItem: makeEndpoint({
    path: 'collections/:tabId/carts/:cartUuid/items',
    method: 'POST',
    bodySchema: z.object({
      item_id: z.number(),
      quantity: z.number(),
      variant_id: z.string().nullish(),
      amount: z.number().optional(),
      cart_field_values: z
        .array(
          z.object({
            item_field_id: z.number(),
            value: z.string(),
          }),
        )
        .optional(),
    }),
    responseSchema: cartSchema,
  }),
  updateItem: makeEndpoint({
    path: 'collections/:tabId/carts/:cartUuid/items/:itemId',
    method: 'PATCH',
    bodySchema: z
      .object({
        quantity: z.number(),
        variant_id: z.string(),
        amount: z.number(),
        cart_field_values: z
          .object({
            item_field_id: z.number(),
            value: z.string(),
          })
          .array(),
      })
      .partial(),
    responseSchema: cartSchema,
  }),
  deleteItem: makeEndpoint({
    path: 'collections/:tabId/carts/:cartUuid/items/:itemId',
    method: 'DELETE',
    responseSchema: cartSchema,
  }),
  createBlindItem: makeEndpoint({
    path: 'collections/:tabId/carts/:cartUuid/blind',
    method: 'POST',
    bodySchema: z.object({
      amount: z.number(),
      quantity: z.number().optional(),
      note: z.string().optional(),
    }),
    responseSchema: cartSchema,
  }),
  createForm: makeEndpoint({
    path: 'collections/:tabId/carts/:cartUuid/forms',
    method: 'POST',
    bodySchema: z.object({
      form_id: z.number(),
      cart_field_values: z
        .object({
          item_field_id: z.number(),
          value: z.string(),
        })
        .array(),
    }),
    responseSchema: cartSchema,
  }),
  updateForm: makeEndpoint({
    path: 'collections/:tabId/carts/:cartUuid/forms/:formId',
    method: 'PATCH',
    bodySchema: z.object({
      form_id: z.number(),
      cart_field_values: z
        .object({
          item_field_id: z.number(),
          value: z.string(),
        })
        .array(),
    }),
    responseSchema: cartSchema,
  }),
  deleteForm: makeEndpoint({
    path: 'collections/:tabId/carts/:cartUuid/forms/:formId',
    method: 'DELETE',
    responseSchema: cartSchema,
  }),
  createTimeSlot: makeEndpoint({
    path: 'collections/:tabId/carts/:cartUuid/time_slots',
    method: 'POST',
    bodySchema: z.object({
      time_slot_id: z.number(),
      quantity: z.number(),
      cart_field_values: z
        .object({
          item_field_id: z.number(),
          value: z.string(),
        })
        .array(),
    }),
    responseSchema: cartSchema,
  }),
  updateTimeSlot: makeEndpoint({
    path: 'collections/:tabId/carts/:cartUuid/time_slots/:timeSlotId',
    method: 'PATCH',
    bodySchema: z
      .object({
        time_slot_id: z.number(),
        quantity: z.number(),
        cart_field_values: z
          .object({
            item_field_id: z.number(),
            value: z.string(),
          })
          .array(),
      })
      .partial(),
    responseSchema: cartSchema,
  }),
  deleteTimeSlot: makeEndpoint({
    path: 'collections/:tabId/carts/:cartUuid/time_slots/:timeSlotId',
    method: 'DELETE',
    responseSchema: cartSchema,
  }),
  createPaymentIntent: makeEndpoint({
    path: 'collections/:tabId/carts/:cartUuid/terminal/intent',
    method: 'POST',
    responseSchema: z.object({
      clientSecret: z.string(),
      paymentIntentId: z.string(),
      status: z.string(),
    }),
  }),
  cancelPaymentIntent: makeEndpoint({
    path: 'collections/:tabId/carts/:cartUuid/terminal/intent',
    method: 'DELETE',
    responseSchema: z.object({
      status: z.string(),
    }),
  }),
  applyDiscount: makeEndpoint({
    path: 'collections/:tabId/carts/:cartUuid/discounts/:discountCode',
    method: 'PUT',
    responseSchema: cartSchema,
  }),
  resetDiscount: makeEndpoint({
    path: 'collections/:tabId/carts/:cartUuid/discounts/:discountCode',
    method: 'DELETE',
    responseSchema: cartSchema,
  }),
  pay: makeEndpoint({
    path: 'collections/:tabId/carts/:cartUuid/pay',
    method: 'POST',
    bodySchema: cartSchema
      .extend({
        tabId: z.number().optional(),
        method: paymentMethodTypeSchema,
        captchaToken: z.string().optional(),
        source: z.object({
          token: z.string().optional(),
          saveSource: z.boolean().optional(),
        }),
      })
      .partial(),
    responseSchema: cartSchema,
  }),
  createTerminalToken: makeEndpoint({
    path: 'collections/:tabId/carts/terminal_token',
    method: 'POST',
    responseSchema: z.object({
      object: z.literal('terminal.connection_token'),
      secret: z.string(),
    }),
  }),
  sign: makeEndpoint({
    path: 'collections/:tabId/carts/:cartUuid/sign',
    method: 'POST',
    bodySchema: z.object({
      objectName: z.string(),
      contentType: z.string(),
    }),
    responseSchema: z.object({
      signedUrl: z.string(),
    }),
  }),
}
