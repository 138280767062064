import {Helmet} from 'react-helmet-async'

const ContactPageHelmet = () => (
  <Helmet defer={false}>
    <title>Contact Us - Collect Money Online for Groups - Cheddar Up</title>
    <meta
      property="og:title"
      content="Contact Us - Collect Money Online for Groups - Cheddar Up"
    />
    <meta
      property="twitter:title"
      content="Contact Us - Collect Money Online for Groups - Cheddar Up"
    />
  </Helmet>
)

export default ContactPageHelmet
