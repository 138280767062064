import {NumberParam, useQueryParam, withDefault} from 'use-query-params'
import * as WebUI from '@cheddarup/web-ui'
import {useMemo} from 'react'

export interface CollectionComboboxProps
  extends WebUI.ComboboxProps,
    Pick<React.ComponentPropsWithoutRef<'input'>, 'placeholder'> {
  popoverClassName?: string
  inputSize?: WebUI.InputProps['size']
  additionalOptions?: WebUI.ComboboxItem[]
  collections: Api.Tab[]
  selectedCollectionId: number | null
  onSelectedCollectionIdChange?: (collectionId: number | null) => void
}

export const CollectionCombobox = ({
  popoverClassName,
  inputSize = 'compact',
  additionalOptions = [],
  collections,
  selectedCollectionId,
  onSelectedCollectionIdChange,
  ...restProps
}: CollectionComboboxProps) => {
  const [, setCollectionId] = useQueryParam(
    'collectionId',
    withDefault(NumberParam, selectedCollectionId),
  )

  const options = useMemo(
    () => [
      ...additionalOptions,
      ...collections.map(
        (c): WebUI.ComboboxItem => ({
          value: String(c.id),
          label: c.name,
        }),
      ),
    ],
    [collections, additionalOptions],
  )
  const selectedOption = useMemo(
    () =>
      options.find((o) => o.value === String(selectedCollectionId || '')) ??
      null,
    [options, selectedCollectionId],
  )

  return (
    <WebUI.Combobox
      selectedItem={selectedOption}
      onSelectedItemChange={(change) => {
        const newSelectedOption = change.selectedItem
        if (newSelectedOption) {
          const newCollectionId = newSelectedOption.value
            ? Number(newSelectedOption.value)
            : null
          if (newCollectionId) {
            setCollectionId(newCollectionId)
          }
          onSelectedCollectionIdChange?.(newCollectionId)
        }
      }}
      {...restProps}
    >
      <WebUI.ComboboxInput size={inputSize} placeholder="Collection name" />

      <WebUI.ComboboxPopover className={popoverClassName}>
        <WebUI.VirtualizedComboboxList>
          {options.map((o) => (
            <WebUI.ComboboxOption key={o.value} {...o} />
          ))}
        </WebUI.VirtualizedComboboxList>
      </WebUI.ComboboxPopover>
    </WebUI.Combobox>
  )
}
