import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import React from 'react'
import {useManagerRole} from 'src/components/ManageRoleProvider'
import CollectionCreationMethodModal from './CollectionCreationMethodModal'

const CreateCollectionButton = (
  props: Util.Merge<
    React.ComponentPropsWithoutRef<'button'>,
    WebUI.ButtonProps
  >,
) => {
  const [managerRole] = useManagerRole()
  return (
    <CollectionCreationMethodModal
      disclosure={
        <WebUI.DialogDisclosure
          variant="primary"
          disabled={managerRole?.canCreate === false}
          {...props}
        >
          Create a Collection
        </WebUI.DialogDisclosure>
      }
    />
  )
}

export default CreateCollectionButton
