import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {headerSchema} from '../schemas'

export default {
  list: makeEndpoint({
    path: 'users/headers',
    responseSchema: headerSchema.array(),
  }),
  delete: makeEndpoint({
    path: 'users/headers/:headerId',
    method: 'DELETE',
    responseSchema: z.any(),
  }),
}
