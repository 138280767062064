import {useNavigate, useParams} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import {Link} from 'src/components/Link'

import {PrintShippingLabelContainer} from './containers'

const PrintShippingLabelPage = () => {
  const navigate = useNavigate()
  const urlParams = useParams()

  return (
    <WebUI.Modal
      aria-label="Print shipping label"
      className="[&_>_.ModalContentView]:max-w-screen-lg [&_>_.ModalContentView]:overflow-y-auto"
      onDidHide={() => navigate('..')}
    >
      <WebUI.ModalCloseButton />
      <WebUI.VStack className="gap-4 bg-gray100 px-8 py-16">
        <WebUI.HStack className="items-baseline justify-between gap-3">
          <WebUI.Heading as="h2">Print a USPS Shipping Label</WebUI.Heading>
          <Link
            className="text-ds-sm"
            variant="primary"
            target="_blank"
            to={`/pdf/collection/${urlParams.collection}/tab-payment/${urlParams.payment}`}
          >
            View Order Summary
          </Link>
        </WebUI.HStack>
        <PrintShippingLabelContainer
          collectionId={Number(urlParams.collection)}
          paymentId={Number(urlParams.payment)}
          onRedirectToPrintShippingLabelSummaryPage={() =>
            navigate(
              {
                pathname: `../print-shipping-label-summary/${urlParams.payment}`,
                search: '?firstVisit=true',
              },
              {replace: true},
            )
          }
        />
      </WebUI.VStack>
    </WebUI.Modal>
  )
}

export default PrintShippingLabelPage
