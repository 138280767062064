import {useNavigate, useParams} from 'react-router-dom'
import React, {useMemo, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'
import {api} from '@cheddarup/api-client'
import {FieldSetList} from 'src/components/FieldSetList'
import {PaymentItemTableView} from 'src/components/PaymentItemTableView'
import {formatPaymentMethod} from 'src/helpers/formatPaymentMethod'
import {Link} from 'src/components/Link'
import {useApiHeaders} from 'src/helpers/client'

const ItemReportPage = () => {
  const navigate = useNavigate()
  const urlParams = useParams()
  const {data: collection} = api.tabs.detail.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: urlParams.collection!,
    },
  })
  const itemReportQuery = api.tabItems.report.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: urlParams.collection!,
      // biome-ignore lint/style/noNonNullAssertion:
      itemId: urlParams.item!,
    },
  })
  const [selectedPaymentItem, setSelectedPaymentItem] =
    useState<Api.TabItemReportPaymentItem | null>(null)

  return (
    <WebUI.Modal
      aria-label="Item report"
      className={
        '[&_>_.ModalContentView]:h-full [&_>_.ModalContentView]:max-w-screen-lg sm:[&_>_.ModalContentView]:w-full'
      }
      preventBodyScroll
      onDidHide={() => navigate('..')}
    >
      <WebUI.MasterDetailDisclosed
        className={
          '[&_>_.MasterDetailDisclosed-detail]:flex-0 [&_>_.MasterDetailDisclosed-detail]:bg-natural-80'
        }
        visible={!!selectedPaymentItem}
        onDidHide={() => setSelectedPaymentItem(null)}
        detail={
          !!itemReportQuery.data &&
          !!selectedPaymentItem && (
            <WebUI.VStack className="gap-4 p-4 sm:w-[320px] sm:p-8">
              <div className="font-normal text-ds-sm">
                {selectedPaymentItem.payment.tab_member.name}
              </div>
              <WebUI.Separator variant="primary" />
              {!!itemReportQuery.data.description && (
                <>
                  <div className="text-ds-sm">
                    {itemReportQuery.data.description}
                  </div>
                  <WebUI.Separator variant="primary" />
                </>
              )}
              <FieldSetList
                size="compact"
                variant="organizer"
                EmptyStateViewComponent={React.Fragment}
                defaultValues={Util.mapToObj(
                  selectedPaymentItem.item_field_views,
                  (ifv) => [ifv.item_field_id, ifv.value],
                )}
                fieldSets={itemReportQuery.data.options.fieldSets ?? undefined}
                fields={itemReportQuery.data.fields}
              />
            </WebUI.VStack>
          )
        }
      >
        {(disclosure) => (
          <>
            <WebUI.ModalCloseButton
              className={disclosure.visible ? 'hidden sm:block' : undefined}
            />

            <WebUI.VStack className="flex-auto gap-4 p-4 sm:p-8">
              {itemReportQuery.data && (
                <WebUI.VStack className="gap-1">
                  <WebUI.Heading as="h3">
                    {Util.capitalize(
                      itemReportQuery.data.options.itemSubType ?? 'item',
                    )}{' '}
                    Report
                  </WebUI.Heading>

                  <p className="text-ds-sm">
                    <p>{itemReportQuery.data.name}</p>
                    <p>
                      {itemReportQuery.data.amount_type === 'fixed'
                        ? Util.formatAmount(itemReportQuery.data.amount ?? 0)
                        : '$Open'}
                    </p>
                  </p>
                </WebUI.VStack>
              )}

              {!!collection && !!itemReportQuery.data && (
                <ItemReport
                  collection={collection}
                  item={itemReportQuery.data}
                  hasWaitlist={itemReportQuery.data.waitlist_count > 0}
                  fieldViewsVisiblePaymentItemId={
                    selectedPaymentItem?.id ?? null
                  }
                  onViewFieldViews={(paymentItem) => {
                    setSelectedPaymentItem((prevSelectedPaymentItem) =>
                      prevSelectedPaymentItem === paymentItem
                        ? null
                        : paymentItem,
                    )
                  }}
                />
              )}
            </WebUI.VStack>
          </>
        )}
      </WebUI.MasterDetailDisclosed>
    </WebUI.Modal>
  )
}

// MARK: – ItemReport

interface ItemReportProps extends React.ComponentPropsWithoutRef<'div'> {
  collection: Api.Tab
  item: Api.TabItemReport
  hasWaitlist?: boolean
  fieldViewsVisiblePaymentItemId: number | null
  onViewFieldViews: (paymentItem: Api.TabItemReportPaymentItem | null) => void
}

const ItemReport = ({
  collection,
  item,
  hasWaitlist,
  fieldViewsVisiblePaymentItemId,
  onViewFieldViews,
  className,
  ...restProps
}: ItemReportProps) => {
  const headers = useApiHeaders()
  const growlActions = WebUI.useGrowlActions()
  const hasVariations =
    item.options.variants?.enabled &&
    item.options.variants?.listings &&
    item.options.variants?.listings.length > 0
  const itemVariations =
    item.options.variants?.listings
      .filter((listing) => listing.waitlist_count)
      .map((listing) => ({
        uuid: listing.uuid,
        variation: Object.values(listing?.optionValues ?? {}).join(', '),
      })) ?? []

  return (
    <WebUI.VStack
      className={WebUI.cn('gap-8 text-ds-sm', className)}
      {...restProps}
    >
      <WebUI.VStack className="gap-0_5">
        <ItemReportQuantityStats item={item} />
        <ItemReportAmountStats item={item} />
        <div>Collection: {collection.name}</div>
      </WebUI.VStack>

      <WebUI.Button
        className="self-start print:hidden"
        size="compact"
        variant="secondary"
        onClick={async () => {
          if (!collection) {
            return
          }

          const res = await api.tabs.itemsExport.fetch({
            pathParams: {
              tabId: collection.id,
            },
            headers,
          })

          if (res.file_url) {
            WebUI.downloadFile(res.file_url, `${collection.name}_items.xlsx`)
          }
          growlActions.show('success', {
            title: 'Your report is in progress',
            body:
              res.message ??
              'You will receive an email with a link to download your report.',
          })
        }}
      >
        Export Spreadsheet
      </WebUI.Button>

      {hasWaitlist &&
        (hasVariations ? (
          <WebUI.VStack className="items-start gap-1">
            {itemVariations.map((variation) => (
              <Link
                key={variation.uuid}
                className="font-light text-ds-sm"
                variant="primary"
                to={{
                  pathname: `../item/${item.id}/waitlist`,
                  search: `uuid=${variation.uuid}`,
                }}
              >
                View waitlist for "{variation.variation}"
              </Link>
            ))}
          </WebUI.VStack>
        ) : (
          <Link
            className="font-light text-ds-sm"
            variant="primary"
            to={`../item/${item.id}/waitlist`}
          >
            View waitlist for this item
          </Link>
        ))}
      {item.payment_items && item.payment_items.length !== 0 && (
        <ItemReportPaymentItemTable
          fieldViewsVisiblePaymentItemId={fieldViewsVisiblePaymentItemId}
          data={item.payment_items}
          onViewFieldViews={onViewFieldViews}
        />
      )}
    </WebUI.VStack>
  )
}

// MARK: – ItemReportAmountStats

interface ItemReportAmountStatsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  item: Api.TabItemReport
}

const ItemReportAmountStats = ({
  item,
  className,
  ...restProps
}: ItemReportAmountStatsProps) => (
  <WebUI.VStack
    className={WebUI.cn('gap-0_5 font-light text-ds-sm', className)}
    {...restProps}
  >
    <div>Collected: {Util.formatAmount(item.amount_sold)}</div>
    {item.amount_refunded > 0 && (
      <div>Refunds: {Util.formatAmount(item.amount_refunded)}</div>
    )}
    {item.amount_discounted > 0 && (
      <div>Discounts: {Util.formatAmount(item.amount_discounted)}</div>
    )}
    {(item.amount_refunded > 0 || item.amount_discounted > 0) && (
      <div className="font-normal">
        Net Amount: {Util.formatAmount(item.net_amount)}
      </div>
    )}
  </WebUI.VStack>
)

// MARK: – ItemReportQuantityStats

interface ItemReportQuantityStatsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  item: Api.TabItemReport
}

const ItemReportQuantityStats = ({
  item,
  className,
  ...restProps
}: ItemReportQuantityStatsProps) => (
  <WebUI.VStack
    className={WebUI.cn('gap-0_5 text-ds-sm', className)}
    {...restProps}
  >
    <div>Quantity Sold: {item.quantity_sold}</div>
    {item.quantity_refunded > 0 && (
      <>
        <div>Quantity Refunded: {item.quantity_refunded}</div>
        <div className="font-normal">Net Quantity: {item.net_quantity}</div>
      </>
    )}
  </WebUI.VStack>
)

// MARK: – ItemReportPaymentItemTable

interface ItemReportPaymentItemTableProps
  extends Omit<WebUI.TableViewProps<Api.TabItemReportPaymentItem>, 'columns'> {
  fieldViewsVisiblePaymentItemId: number | null
  onViewFieldViews: (paymentItem: Api.TabItemReportPaymentItem | null) => void
}

const ItemReportPaymentItemTable = ({
  data,
  fieldViewsVisiblePaymentItemId,
  onViewFieldViews,
  className,
  ...restProps
}: ItemReportPaymentItemTableProps) => {
  const refundLinkClassNames = 'text-ds-sm text-tint'
  const isFieldViewsCellVisible = data.some(
    ({item_field_views}) => item_field_views.length > 0,
  )
  const hasItemVariations = data.some((pi) => pi.detail.variant)

  const columnHelper = useMemo(
    () => WebUI.createColumnHelper<Api.TabItemReportPaymentItem>(),
    [],
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const columns = useMemo(
    () =>
      [
        columnHelper.accessor((pi) => pi.payment.tab_member.name, {
          id: 'payerName',
          meta: {
            subtle: false,
          },
          header: 'Payer',
          cell: ({cell, row: {original: pi}}) => (
            <WebUI.VStack>
              <p>{cell.getValue()}</p>
              {pi.refund_data &&
                (pi.refund_data.total_refunded_cents ?? 0) >= 0 && (
                  <p className={refundLinkClassNames}>
                    {(pi.refund_data?.net_amount_cents ?? 0) > 0
                      ? 'Partially Refunded'
                      : 'Refunded'}
                  </p>
                )}
            </WebUI.VStack>
          ),
        }),
        hasItemVariations
          ? columnHelper.accessor(
              (pi) =>
                Object.values(pi.detail.variant?.optionValues ?? {}).join(','),
              {
                id: 'item_variation',
                size: 100,
                header: 'Item variation',
              },
            )
          : null,
        columnHelper.accessor((pi) => pi.payment.created_at, {
          id: 'payment.created_at',
          size: 100,
          header: 'Date',
          cell: ({cell}) => Util.formatDateAs(cell.getValue()),
        }),
        columnHelper.accessor((pi) => pi.quantity, {
          id: 'qty',
          size: 60,
          meta: {
            align: 'right',
          },
          header: 'Qty',
          cell: ({cell, row: {original: pi}}) => {
            const refunded = (pi.refund_data?.total_refunded_cents ?? 0) >= 0
            const isPartiallyRefunded =
              (pi.refund_data?.net_amount_cents ?? 0) > 0

            return (
              <WebUI.VStack>
                <p>{cell.getValue()}</p>
                {refunded &&
                  !isPartiallyRefunded &&
                  pi.refund_data?.quantity_refunded && (
                    <p className={refundLinkClassNames}>
                      -{pi.refund_data?.quantity_refunded}
                    </p>
                  )}
              </WebUI.VStack>
            )
          },
        }),
        columnHelper.accessor((pi) => pi.total, {
          id: 'subtotal',
          size: 100,
          meta: {
            align: 'right',
          },
          header: 'Payment',
          cell: ({cell, row: {original: pi}}) => {
            const refunded = (pi.refund_data?.total_refunded_cents ?? 0) >= 0
            const isPartiallyRefunded =
              (pi.refund_data?.net_amount_cents ?? 0) > 0

            return (
              <WebUI.VStack>
                <p>{Util.formatAmount(cell.getValue())}</p>
                {refunded &&
                  !isPartiallyRefunded &&
                  pi.refund_data?.total_refunded_cents && (
                    <p className={refundLinkClassNames}>
                      -
                      {Util.formatAmount(pi.refund_data?.total_refunded_cents, {
                        cents: true,
                      })}
                    </p>
                  )}
              </WebUI.VStack>
            )
          },
        }),
        columnHelper.accessor((pi) => formatPaymentMethod(pi.payment), {
          id: 'payment.payment_method',
          size: 80,
          meta: {
            align: 'right',
          },
          header: 'Method',
        }),
        isFieldViewsCellVisible
          ? columnHelper.display({
              id: 'viewResponses',
              size: 80,
              meta: {
                align: 'right',
              },
              header: 'Responses',
              cell: ({row: {original: pi}}) => {
                const isVisible = pi.id === fieldViewsVisiblePaymentItemId
                return pi.item_field_views.length > 0 ? (
                  <WebUI.Button
                    variant="link"
                    onClick={() => {
                      onViewFieldViews(isVisible ? null : pi)
                    }}
                  >
                    {isVisible ? 'hide' : 'view'}
                  </WebUI.Button>
                ) : (
                  ''
                )
              },
            })
          : undefined,
      ].filter((c) => c != null),
    [
      columnHelper,
      hasItemVariations,
      isFieldViewsCellVisible,
      refundLinkClassNames,
      fieldViewsVisiblePaymentItemId,
      onViewFieldViews,
    ],
  )

  return (
    <PaymentItemTableView
      className={WebUI.cn(
        '[&_>_.TableView-headerGroupList_>_.TableView-headerGroup]:bg-aquaLight',
        className,
      )}
      columns={columns}
      data={data}
      {...restProps}
    />
  )
}

export default ItemReportPage
