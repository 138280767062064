import * as WebUI from '@cheddarup/web-ui'
import React, {useRef} from 'react'
import {api} from '@cheddarup/api-client'
import CollectionsLayout from 'src/components/CollectionsLayout'
import {Link} from 'src/components/Link'
import {useManagerRole} from 'src/components/ManageRoleProvider'
import {
  PremiumFeatureSideSheetDisclosure,
  PremiumFeaturesSideSheet,
} from 'src/components/PremiumFeaturesSideSheet'
import * as Util from '@cheddarup/util'
import {getBrandKitColors} from '@cheddarup/core'
import GroupPageZeroStateImage from 'src/images/GroupPageZeroState.png'
import GPOverviewSlideLady from 'src/images/GPOverviewSlideLady.png'

import {
  GroupPageHelmet,
  GroupPagePublishAndSharePanel,
  GroupPageSectionsList,
} from './components'
import {
  NextLinkButton,
  NextLinkButtonProps,
} from 'src/components/__next/LinkButton'
import {SharpImage} from 'src/components/SharpImage'
import {NextLink} from 'src/components/__next/Link'
import {
  NextButton,
  NextPopover,
  NextPopoverAnchor,
  NextPopoverContent,
  NextPopoverDescription,
  NextPopoverDisclosure,
  NextPopoverDismiss,
  NextPopoverHeading,
} from '@cheddarup/web-ui/next'
import {useNavigate} from 'react-router-dom'
import {CollectionsHeaderMobile} from 'src/components/CollectionsMobileLayout/CollectionsMobileLayout'

const GroupPage = () => {
  const media = WebUI.useMedia()
  const [managerRole] = useManagerRole()
  const {data: session} = api.auth.session.useQuery()
  const groupPageQuery = api.groupPages.detail.useQuery()
  const popoverAnchorRef = useRef<HTMLDivElement>(null)

  const brandKitColors = groupPageQuery.data?.branding?.color_palette
    ? getBrandKitColors(groupPageQuery.data.branding.color_palette.groupPage)
    : null

  const groupPage = groupPageQuery.data
  const userOnTeamPlan = session?.capabilities.subscribed_to_team

  return media.sm ? (
    <PremiumFeaturesSideSheet
      tabId={null}
      enforcedPremiumMeta={{team: {groupPage: true}}}
    >
      <GroupPageHelmet groupPage={groupPage} />
      <CollectionsLayout className="bg-natural-100 [&_.CollectionsLayout-contentContainer]:overflow-y-auto lg:[&_.CollectionsLayout-contentContainer]:gap-6 lg:[&_.CollectionsLayout-contentContainer]:overflow-hidden lg:[&_.CollectionsLayout-contentContainer]:p-0">
        <NextPopover placement="right-start">
          <div className="flex min-h-0 grow flex-col divide-y lg:flex-row lg:divide-x lg:divide-y-0">
            {media.lg && (
              <div className="flex w-64 shrink-0 flex-col gap-4 lg:overflow-y-auto">
                <div className="flex flex-col gap-3 px-4 py-6">
                  <WebUI.Text className="text-ds-md">
                    Add Page Sections
                  </WebUI.Text>
                  <WebUI.Text className="font-light text-ds-sm">
                    Click the buttons below to add sections to your Group Page.
                  </WebUI.Text>
                  <div className="flex flex-col gap-4">
                    <NextPopoverAnchor ref={popoverAnchorRef}>
                      <PageSectionLinkButton
                        tooltipLabel="Set the tone with a headline and background image at the top of your Group Page."
                        to="top-banner"
                      >
                        <WebUI.PhosphorIcon
                          className="text-ds-2xl text-teal-50"
                          icon="image"
                        />
                        Top Banner
                      </PageSectionLinkButton>
                    </NextPopoverAnchor>
                    <PageSectionLinkButton
                      tooltipLabel="Add collections to display on your Group Page."
                      to="add-collections"
                    >
                      <WebUI.PhosphorIcon
                        className="text-ds-2xl text-teal-50"
                        icon="briefcase"
                      />
                      <span>
                        Collections<span className="text-orange-50">*</span>
                      </span>
                    </PageSectionLinkButton>
                    <PageSectionLinkButton
                      tooltipLabel="Inspire participation and giving with a slideshow of your top collections."
                      to="collection-spotlight"
                    >
                      <WebUI.PhosphorIcon
                        className="text-ds-2xl text-teal-50"
                        icon="star"
                      />
                      Collection Spotlights
                    </PageSectionLinkButton>
                    <PageSectionLinkButton
                      tooltipLabel="Display how much your organization has collected over a selected time period."
                      to="total-collected"
                    >
                      <WebUI.PhosphorIcon
                        className="text-ds-2xl text-teal-50"
                        icon="megaphone"
                      />
                      Total Collected
                    </PageSectionLinkButton>
                    <PageSectionLinkButton
                      tooltipLabel="Build credibility with a mission statement or general info about your organization."
                      to="about-us"
                    >
                      <WebUI.PhosphorIcon
                        className="text-ds-2xl text-teal-50"
                        icon="info"
                      />
                      About Us
                    </PageSectionLinkButton>
                    <PageSectionLinkButton
                      tooltipLabel="Add a personal touch to your organization with names, titles and, if desired, contact info."
                      to="team"
                    >
                      <WebUI.PhosphorIcon
                        className="text-ds-2xl text-teal-50"
                        icon="users-three"
                      />
                      Meet the Team
                    </PageSectionLinkButton>
                    <PageSectionLinkButton
                      tooltipLabel="Showcase sponsors by placing their logos and URLs on your Group Page."
                      to="sponsors"
                    >
                      <WebUI.PhosphorIcon
                        className="text-ds-2xl text-teal-50"
                        icon="hand-heart"
                      />
                      Sponsors
                    </PageSectionLinkButton>
                  </div>
                </div>
              </div>
            )}

            <div className="flex max-w-full grow flex-col gap-7 p-0 px-3 lg:overflow-y-auto lg:px-9 lg:py-6">
              <div className="flex flex-row gap-3">
                <WebUI.Heading className="leading-compact" as="h2">
                  Group Page
                </WebUI.Heading>
                {session && !userOnTeamPlan && !managerRole && (
                  <PremiumFeatureSideSheetDisclosure
                    featureKeys={['groupPage']}
                  />
                )}
              </div>
              {groupPageQuery.isLoading ? (
                <div className="flex flex-row justify-center">
                  <WebUI.Loader />
                </div>
              ) : groupPage?.sections &&
                Object.values(groupPage.sections).filter((v) => v != null)
                  .length > 0 ? (
                <>
                  <WebUI.Text className="font-light">
                    Your Group Page is a custom landing page that showcases your
                    organization and your collections all in one spot.{' '}
                    <NextLink to="group-page-quick-tour">Quick Tour</NextLink>
                  </WebUI.Text>
                  <GroupPageSectionsList groupPage={groupPage} />
                </>
              ) : (
                <GroupPageEmptyState />
              )}
            </div>

            <div className="flex flex-0 flex-col gap-4 overflow-y-auto bg-natural-95 p-3 py-5 *:flex-0 lg:w-[380px] lg:overflow-y-auto lg:px-5 lg:py-6">
              {!!groupPage && <GroupPagePublishAndSharePanel />}
              {brandKitColors && (
                <WebUI.Panel className="flex flex-col gap-6 p-6 text-ds-sm">
                  <div className="flex flex-col gap-4">
                    <WebUI.Heading className="text-ds-md" as="h3">
                      Branding
                    </WebUI.Heading>
                    <WebUI.Text>
                      Replace Cheddar Up’s logo at the top of all of your pages
                      and customize button colors within your{' '}
                      <Link variant="primary" to="my-account/brand-kit-palette">
                        Brand Kit
                      </Link>
                      .
                    </WebUI.Text>
                    <div className="flex flex-col gap-3">
                      <Link className="block" to="my-account/brand-kit-palette">
                        <ColorRow color={brandKitColors.primary}>
                          Primary
                        </ColorRow>
                      </Link>
                      <Link className="block" to="my-account/brand-kit-palette">
                        <ColorRow color={brandKitColors.secondary}>
                          Secondary
                        </ColorRow>
                      </Link>
                      <Link className="block" to="my-account/brand-kit-palette">
                        <ColorRow color={brandKitColors.neutral}>
                          Neutral
                        </ColorRow>
                      </Link>
                    </div>
                  </div>

                  {groupPageQuery.data?.branding?.enable_brand_logo &&
                    groupPageQuery.data.branding.logo && (
                      <>
                        <WebUI.Separator variant="primary" />
                        <NextLink to="my-account/brand-kit-logo">
                          <SharpImage
                            className="max-h-13 w-fit"
                            alt="Custom logo"
                            image={groupPageQuery.data.branding.logo}
                          />
                        </NextLink>
                      </>
                    )}
                </WebUI.Panel>
              )}
            </div>
          </div>

          <NextPopoverContent
            className="flex max-w-80 flex-col gap-4 px-6 py-7"
            arrow={false}
            getAnchorRect={() =>
              popoverAnchorRef.current?.getBoundingClientRect() ?? null
            }
          >
            <NextPopoverHeading
              className="flex flex-row items-center gap-4"
              render={<WebUI.Heading className="text-ds-md" as="h3" />}
            >
              <WebUI.PhosphorIcon
                className="text-ds-xl text-orange-50"
                icon="arrow-fat-left-fill"
              />
              Start by adding a section
            </NextPopoverHeading>
            <NextPopoverDescription>
              Your Group Page is made up of sections that can be rearranged and
              customized with your brand colors.
              <br />
              <br /> We recommend starting with your Top Banner and working your
              way down.{' '}
              <NextLink className="font-bold" to="group-page-quick-tour">
                Take a quick tour
              </NextLink>
            </NextPopoverDescription>

            <NextPopoverDismiss />
          </NextPopoverContent>
        </NextPopover>
      </CollectionsLayout>
    </PremiumFeaturesSideSheet>
  ) : (
    <GroupPageMobileModal />
  )
}

// MARK: - GroupPageMobileModal

const GroupPageMobileModal = () => {
  const navigate = useNavigate()

  return (
    <WebUI.Modal onDidHide={() => navigate('..')}>
      <CollectionsHeaderMobile />
      <div className="relative flex flex-col items-center gap-8 px-9 py-14">
        <WebUI.ModalCloseButton />
        <div className="flex flex-col items-center gap-5">
          <WebUI.Heading as="h2">Group Page</WebUI.Heading>
          <WebUI.Text className="text-center font-light">
            Showcase your organization and your collections on a custom-branded
            landing page. Add sections to inspire community involvement, reward
            sponsors and more!
          </WebUI.Text>
        </div>
        <WebUI.Text className="text-center text-orange-50">
          Open the Group Page builder on your laptop or computer to get started.
        </WebUI.Text>
        <WebUI.AnchorButton
          className="w-44"
          href="https://www.cheddarup.com/group-page/"
          target="_blank"
          rel="noopener noreferrer"
          variant="default"
        >
          Learn More
        </WebUI.AnchorButton>
        <WebUI.Image src={GPOverviewSlideLady} alt="" />
      </div>
    </WebUI.Modal>
  )
}

// MARK: - GroupPageEmptyState

interface GroupPageEmptyStateProps
  extends React.ComponentPropsWithoutRef<'div'> {}

const GroupPageEmptyState = ({
  className,
  ...restProps
}: GroupPageEmptyStateProps) => (
  <div
    className={WebUI.cn(
      'flex flex-col items-center gap-1 rounded-none px-6 pt-10 shadow-z4 lg:rounded-[30px]',
      className,
    )}
    {...restProps}
  >
    <div className="flex max-w-3xl flex-col items-center gap-4">
      <span className="text-center text-ds-md">
        Showcase your organization and your collections on a custom-branded
        landing page. Add sections to inspire community involvement, reward
        sponsors and more!
      </span>
      <NextPopoverDisclosure
        className="w-36"
        render={<NextButton variant="orange" />}
      >
        Get Started
      </NextPopoverDisclosure>
    </div>
    <WebUI.Image
      className="max-h-[400px]"
      src={GroupPageZeroStateImage}
      alt=""
    />
  </div>
)

// MARK: – PageSectionLinkButton

interface PageSectionButtonProps
  extends NextLinkButtonProps,
    React.ComponentPropsWithoutRef<'a'> {
  tooltipLabel: React.ReactNode
}

const PageSectionLinkButton: React.FC<PageSectionButtonProps> = ({
  tooltipLabel,
  ...restProps
}) => (
  <WebUI.Tooltip placement="right">
    <WebUI.TooltipAnchor
      render={
        <NextLinkButton
          className="w-full flex-col gap-2 bg-teal-90 p-2 font-normal text-ds-sm text-natural-20"
          size="headless"
          variant="headless"
          {...restProps}
        />
      }
    />
    <WebUI.TooltipContent variant="light">{tooltipLabel}</WebUI.TooltipContent>
  </WebUI.Tooltip>
)

// MARK: – ColorRow

export interface ColorRowProps
  extends Util.Merge<
    React.ComponentPropsWithoutRef<'div'>,
    Pick<WebUI.ColorSwatchProps, 'color'>
  > {}

export const ColorRow = ({
  color,
  className,
  children,
  ...restProps
}: ColorRowProps) => (
  <WebUI.Panel
    className={WebUI.cn(
      'flex flex-row items-center gap-3 px-3 py-2',
      className,
    )}
    {...restProps}
  >
    <WebUI.ColorSwatch className="h-6 w-6" color={color} />
    <span className="font-body text-ds-sm">{children}</span>
  </WebUI.Panel>
)

export default GroupPage
