import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {
  paymentToOthersDetailedSchema,
  paymentToOthersSchema,
  recurringPaymentInvoiceSchema,
} from '../schemas'

export default {
  list: makeEndpoint({
    path: 'users/payments',
    responseSchema: paymentToOthersSchema.array(),
  }),
  detail: makeEndpoint({
    path: 'users/payments/:paymentId',
    responseSchema: paymentToOthersDetailedSchema,
  }),
  addToCalendar: makeEndpoint({
    path: 'users/payments/add_to_calendar',
    queryParamsSchema: z.object({
      payment_uuid: z.string(),
    }),
    responseSchema: z.unknown(),
  }),
  resendReceipt: makeEndpoint({
    path: 'users/payments/:paymentId/resend_receipt',
    method: 'POST',
    responseSchema: z.object({success: z.boolean()}),
  }),
  retryInvoice: makeEndpoint({
    path: 'users/recurring_payment_invoices/:invoiceId/retry',
    method: 'POST',
    responseSchema: recurringPaymentInvoiceSchema,
  }),
  deletePaymentItem: makeEndpoint({
    path: 'users/tabs/:tabId/payments/:paymentId/payment_items/:paymentItemId',
    method: 'DELETE',
    queryParamsSchema: z
      .object({
        comment: z.string(),
        sendEmail: z.boolean(),
      })
      .partial()
      .optional(),
    responseSchema: z.any(),
  }),
}
