import * as Util from '@cheddarup/util'
import {useNavigate, useParams} from 'react-router-dom'
import {
  api,
  endpoints,
  getEndpointKey,
  useQueryClient,
  useUploadItemsMutation,
} from '@cheddarup/api-client'
import {useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {fetchAndSave} from 'src/helpers/api-helpers'
import {uploadImage} from '@cheddarup/core'

const cleanItemName = (name: string) =>
  name
    .toLowerCase()
    .replace(/[([{]+.*?[)\]}]+/g, '')
    .replace(/\W/g, '')
    .trim()

const findMatchingItem = (existingItems: Api.TabItem[], image: File) => {
  const cleanedImageName = cleanItemName(image.name.split('.')[0] ?? '')
  return Util.sort(
    existingItems
      .map((item) => ({
        item,
        score: Util.fuzzyMatch(cleanItemName(item.name), cleanedImageName)
          .score,
      }))
      .filter((result) => result.score >= 5),
  ).desc((i) => i.score)[0]?.item
}

const UploadItemsPage = () => {
  const navigate = useNavigate()
  const urlParams = useParams()
  const collectionId = Number(urlParams.collection)

  const [imagesUpload, setImagesUpload] = useState<{
    uploaded: number
    nonMatches: string[]
    updated: number
  } | null>(null)
  const [imageUploadProgress, setImageUploadProgress] = useState(0)
  const itemsQuery = api.tabItems.list.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: urlParams.collection!,
    },
  })
  const uploadItemsMutation = useUploadItemsMutation()
  const queryClient = useQueryClient()
  const {data: collection} = api.tabs.detail.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: urlParams.collection!,
    },
  })

  return (
    <WebUI.Modal
      aria-label="Upload items"
      className="[&_>_.ModalContentView]:h-full [&_>_.ModalContentView]:max-w-screen-lg"
      onDidHide={() => navigate('..')}
    >
      {(dialog) => (
        <>
          <WebUI.ModalCloseButton />

          <WebUI.ModalHeader>Upload Your Inventory</WebUI.ModalHeader>

          <WebUI.VStack className="grow gap-2 overflow-y-auto px-4 py-4 sm:px-8">
            <p>
              Add your items. Then, drag images <b>with matching names</b> to
              associate them.
            </p>
            <WebUI.VStack className="gap-8">
              <p>
                {/* biome-ignore lint/a11y/useValidAnchor: */}
                <a
                  href="#"
                  onClick={() =>
                    fetchAndSave({
                      url: `users/tabs/${collectionId}/uploads/template.csv`,
                      fileName: `${collection?.name ?? '?'}-template.csv`,
                    })
                  }
                >
                  Click Here
                </a>{' '}
                to download a CSV template for the items.
              </p>
              {(() => {
                if (uploadItemsMutation.isPending) {
                  return <WebUI.Loader />
                }

                if (imageUploadProgress > 0) {
                  return <ProgressBar percent={imageUploadProgress} />
                }

                return (
                  <WebUI.VStack className="gap-16">
                    <WebUI.HStack className="items-center gap-3 text-center [&_>_.Stack]:flex-[0_1_50%]">
                      <WebUI.FileUploader
                        multiple={false}
                        accept={{'text/csv': ['.csv']}}
                        onDropAccepted={(nextItems) => {
                          if (nextItems[0]) {
                            const formData = new window.FormData()
                            formData.append('items', nextItems[0])
                            uploadItemsMutation.mutate({
                              pathParams: {
                                tabId: collectionId,
                              },
                              body: formData,
                            })
                          }
                        }}
                      >
                        <WebUI.FileUploaderInput />
                        <WebUI.VStack className="items-center">
                          <WebUI.FileUploaderDropzone className="cursor-pointer border border-dashed p-8">
                            <div>Drop your CSV here</div>
                            {!!uploadItemsMutation.data && (
                              <>
                                <div>
                                  {uploadItemsMutation.data.created} items
                                  created
                                </div>
                                <div>
                                  {uploadItemsMutation.data.updated} items
                                  updated
                                </div>
                              </>
                            )}
                          </WebUI.FileUploaderDropzone>
                        </WebUI.VStack>
                      </WebUI.FileUploader>

                      {itemsQuery.data && itemsQuery.data.length > 0 && (
                        <WebUI.FileUploader
                          accept={{'image/*': []}}
                          multiple
                          onDropAccepted={async (images) => {
                            try {
                              const matches = images
                                .map((image) => {
                                  const item = findMatchingItem(
                                    itemsQuery.data ?? [],
                                    image,
                                  )
                                  return item ? {item, image} : null
                                })
                                .filter(
                                  (i): i is NonNullable<typeof i> => !!i?.item,
                                )
                              const nonMatches = Util.difference(
                                images,
                                matches.map((i) => i.image),
                              ).map((i) => i.name)
                              const batches = Util.chunk(matches, 3).map(
                                (batch) => async () => {
                                  const uploadImagesPromises = batch.map(
                                    async ({item, image}, idx) => {
                                      const normalizedImage =
                                        await WebUI.resetImageOrientation(image)

                                      return uploadImage(
                                        `users/tabs/${collectionId}/items/${item.id}`,
                                        normalizedImage,
                                        undefined,
                                        {
                                          metadata: {
                                            thumbnail: {
                                              order: idx,
                                              cropDetails: {},
                                            },
                                          },
                                        },
                                      )
                                    },
                                  )
                                  await Promise.all(uploadImagesPromises)

                                  return batch.length
                                },
                              )
                              let progress = 1

                              for (const batch of batches) {
                                setImageUploadProgress(
                                  (progress / matches.length) * 100,
                                )
                                progress += await batch()
                              }

                              setImageUploadProgress(0)
                              setImagesUpload({
                                uploaded: images.length,
                                nonMatches,
                                updated: Util.unique(
                                  matches.map((i) => i.item?.id),
                                ).length,
                              })

                              const tabItemsQueryKey = getEndpointKey(
                                endpoints.tabItems.list,
                                {
                                  pathParams: {
                                    tabId: collectionId,
                                  },
                                },
                              )
                              queryClient.invalidateQueries({
                                queryKey: tabItemsQueryKey,
                              })
                            } catch {
                              // noop
                            }
                          }}
                        >
                          <WebUI.FileUploaderInput />
                          <WebUI.VStack className="items-center">
                            <WebUI.FileUploaderDropzone className="cursor-pointer border border-dashed p-8">
                              <div>Drop your item images</div>
                              {!!imagesUpload && (
                                <>
                                  <div>
                                    {imagesUpload.uploaded} images uploaded
                                  </div>
                                  <div>
                                    {imagesUpload.updated} items matched and
                                    updated
                                  </div>
                                </>
                              )}
                            </WebUI.FileUploaderDropzone>
                          </WebUI.VStack>
                        </WebUI.FileUploader>
                      )}
                    </WebUI.HStack>
                    {!!imagesUpload && (
                      <div>
                        <h4>These images had no matched items:</h4>
                        <div>
                          {imagesUpload.nonMatches.map((nonMatch, key) => (
                            <div key={key}>{nonMatch}</div>
                          ))}
                        </div>
                      </div>
                    )}
                  </WebUI.VStack>
                )
              })()}
            </WebUI.VStack>
          </WebUI.VStack>

          <WebUI.HStack className="justify-end border-t p-4">
            {!uploadItemsMutation.isPending && imageUploadProgress === 0 && (
              <WebUI.Button
                size="large"
                variant="primary"
                onClick={() => dialog.hide()}
              >
                Done
              </WebUI.Button>
            )}
          </WebUI.HStack>
        </>
      )}
    </WebUI.Modal>
  )
}

// MARK: – ProgressBar

const ProgressBar = ({percent = 0, ...props}) => (
  <div className="flex items-center" {...props}>
    <div className="ml-2 h-10 w-full border-2">
      <div
        className="h-[34px] bg-orange-50"
        style={{
          width: `${percent}%`,
        }}
      />
    </div>
  </div>
)

export default UploadItemsPage
