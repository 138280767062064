import {forwardRef} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {useDeleteContactListMutation} from '@cheddarup/api-client'

export interface DeleteContactListAlertProps extends WebUI.AlertProps {
  contactListId: number
}

export const DeleteContactListAlert = forwardRef<
  WebUI.DialogInstance,
  DeleteContactListAlertProps
>(({contactListId, ...restProps}, forwardedRef) => {
  const deleteContactListMutation = useDeleteContactListMutation()
  return (
    <WebUI.Alert
      ref={forwardedRef}
      aria-label="Delete contact list confirmation"
      {...restProps}
    >
      <WebUI.AlertHeader>Delete List</WebUI.AlertHeader>
      <WebUI.AlertContentView
        text="Are you sure you want to delete this list?"
        actions={
          <>
            <WebUI.AlertActionButton
              execute={() =>
                deleteContactListMutation.mutate({
                  pathParams: {
                    contactListId,
                  },
                })
              }
            >
              Delete
            </WebUI.AlertActionButton>
            <WebUI.AlertCancelButton />
          </>
        }
      />
    </WebUI.Alert>
  )
})
