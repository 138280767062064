import * as Yup from 'yup'
import {useForkRef, useFormik} from '@cheddarup/react-util'
import * as WebUI from '@cheddarup/web-ui'
import React, {useRef, useState} from 'react'
import {
  api,
  useDeleteFolderMutation,
  useSortFoldersMutation,
  useUpdateFolderMutation,
  useUpdateUserMutation,
} from '@cheddarup/api-client'
import {useFolderId} from 'src/components/FolderProvider'
import {useDefaultFolder} from 'src/hooks/useFolder'
import {useManagerRoleId} from 'src/components/ManageRoleProvider'

import {FolderNavItem} from './components'
import {ManagerFoldersGroup} from './components/ManagedFolders'
import FoldersNavHeader from '../FoldersNavHeader'

export interface FoldersNavProps extends React.ComponentPropsWithoutRef<'div'> {
  defaultFolderName: string
}

const FoldersNav = ({
  defaultFolderName,
  className,
  ...restProps
}: FoldersNavProps) => {
  const [folderId, setFolderId] = useFolderId()
  const {data: folders} = api.tabFolders.list.useQuery()
  const sortFoldersMutation = useSortFoldersMutation()
  const defaultFolder = useDefaultFolder()
  const isDragging = WebUI.useIsDragging()
  const [managerRoleId] = useManagerRoleId()
  const [folderToRename, setFolderToRename] = useState<{
    id: number | null
    name: string
  } | null>(null)
  const [folderToDelete, setFolderToDelete] = useState<{
    id: number | null
    name: string
  } | null>(null)

  const selectFolder = (fId: number | null) => setFolderId(fId)

  return (
    <WebUI.VStack
      className={WebUI.cn('overflow-y-auto', className)}
      {...restProps}
    >
      {managerRoleId ? (
        <ManagerFoldersGroup className="p-4" />
      ) : (
        <WebUI.VStack className="gap-2 p-4">
          <FoldersNavHeader />

          <FolderNavItem
            defaultFolder
            droppable
            selectFolder={selectFolder}
            active={folderId == null}
            folder={defaultFolder}
            onRename={() => setFolderToRename(defaultFolder)}
            onDelete={() => setFolderToDelete(defaultFolder)}
          />
          {folders &&
            (isDragging ? (
              <WebUI.VStack>
                {folders.map((f) => (
                  <WebUI.DeprecatedTooltip key={f.id} label="Drag to reorder">
                    <FolderNavItem
                      className="w-full pl-7"
                      droppable
                      selectFolder={selectFolder}
                      active={folderId === f.id}
                      folder={f}
                    />
                  </WebUI.DeprecatedTooltip>
                ))}
              </WebUI.VStack>
            ) : (
              <WebUI.DragAndDrop
                collisionDetection={WebUI.closestCorners}
                onDragEnd={({active, over}) => {
                  if (!over) {
                    return
                  }
                  const prevOrder = folders.map((f) => f.id)
                  const newOrder = WebUI.arrayMoveByValue(
                    prevOrder,
                    active.id as number,
                    over.id as number,
                  )
                  sortFoldersMutation.mutate({body: {order: newOrder}})
                }}
              >
                <WebUI.VStack as={WebUI.SortableContext} items={folders}>
                  {folders.map((f) => (
                    <WebUI.DeprecatedTooltip key={f.id} label="Drag to reorder">
                      <WebUI.Sortable
                        id={f.id}
                        className="w-full pl-7"
                        as={FolderNavItem as any}
                        selectFolder={selectFolder}
                        active={folderId === f.id}
                        folder={f}
                        onRename={() => setFolderToRename(f)}
                        onDelete={() => setFolderToDelete(f)}
                      />
                    </WebUI.DeprecatedTooltip>
                  ))}
                </WebUI.VStack>
              </WebUI.DragAndDrop>
            ))}
        </WebUI.VStack>
      )}

      <RenameFolderModal
        visible={!!folderToRename}
        folderId={folderToRename?.id}
        initialName={folderToRename?.name}
        onDidHide={() => setFolderToRename(null)}
      />
      <DeleteFolderModal
        visible={!!folderToDelete}
        folderId={folderToDelete?.id}
        defaultFolderName={defaultFolderName}
        onDidHide={() => setFolderToDelete(null)}
      />
    </WebUI.VStack>
  )
}

// MARK: – RenameFolderModal

interface RenameFolderModalProps extends WebUI.ModalProps {
  folderId?: number | null
  initialName?: string
}

const RenameFolderModal = React.forwardRef<
  WebUI.DialogInstance,
  RenameFolderModalProps
>(({folderId, initialName, ...restProps}, forwardedRef) => {
  const ownRef = useRef<WebUI.DialogInstance>(null)
  const ref = useForkRef(forwardedRef, ownRef)
  const updateFolderMutation = useUpdateFolderMutation()
  const updateUserMutation = useUpdateUserMutation()
  const nameMaxLength = 63

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required').max(nameMaxLength),
    }),
    initialValues: {
      name: initialName,
    },
    onSubmit: async (values) => {
      if (folderId) {
        await updateFolderMutation.mutateAsync({
          pathParams: {
            folderId,
          },
          body: {name: values.name},
        })
      } else {
        await updateUserMutation.mutateAsync({
          body: {
            profile: {uiClientFlags: {customDefaultFolderName: values.name}},
          },
        })
      }
      ownRef.current?.hide()
    },
  })

  return (
    <WebUI.Prompt ref={ref} aria-label="Rename folder form" {...restProps}>
      {(dialog) => (
        <WebUI.VStack className="gap-6">
          <WebUI.PromptHeader heading="Rename folder" />
          <WebUI.Form onSubmit={formik.handleSubmit}>
            <WebUI.FormField error={formik.errors.name}>
              <WebUI.Input
                name="name"
                maxLength={nameMaxLength}
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </WebUI.FormField>

            <WebUI.HStack className="items-center gap-3">
              <WebUI.Button type="submit" loading={formik.isSubmitting}>
                Save
              </WebUI.Button>
              <WebUI.Button
                type="button"
                variant="secondary"
                onClick={() => dialog.hide()}
              >
                Cancel
              </WebUI.Button>
            </WebUI.HStack>
          </WebUI.Form>
        </WebUI.VStack>
      )}
    </WebUI.Prompt>
  )
})

// MARK: – DeleteFolderModal

interface DeleteFolderModalProps extends WebUI.ModalProps {
  defaultFolderName: string
  folderId?: number | null
}

const DeleteFolderModal = React.forwardRef<
  WebUI.DialogInstance,
  DeleteFolderModalProps
>(({defaultFolderName, folderId, ...restProps}, forwardedRef) => {
  const deleteFolderMutation = useDeleteFolderMutation()
  return (
    <WebUI.Alert
      ref={forwardedRef}
      aria-label="Delete folder confirmation"
      {...restProps}
    >
      <WebUI.AlertHeader>Delete Folder</WebUI.AlertHeader>
      <WebUI.AlertContentView
        text={
          <>
            Your collections will not be deleted and will be added to your
            default folder:{' '}
            <span className="font-bold">{defaultFolderName}</span>
          </>
        }
        actions={
          <>
            <WebUI.AlertActionButton
              execute={() => {
                if (folderId) {
                  deleteFolderMutation.mutate({
                    pathParams: {
                      folderId,
                    },
                  })
                }
              }}
            >
              Delete
            </WebUI.AlertActionButton>
            <WebUI.AlertCancelButton />
          </>
        }
      />
    </WebUI.Alert>
  )
})

export default FoldersNav
