import React, {useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {tailwindConfig} from '@cheddarup/tailwind-config'

import {
  PhoneNumberVerification,
  PhoneNumberVerificationStep,
} from './PhoneNumberVerification'

export interface AddPhoneNumberModalProps extends WebUI.ModalProps {
  onDidVerify: () => void
  priorTo?: string
}

export const AddPhoneNumberModal = React.forwardRef<
  WebUI.DialogInstance,
  AddPhoneNumberModalProps
>(
  (
    {
      onDidVerify,
      initialVisible = false,
      priorTo = 'publishing a collection',
      ...restProps
    },
    forwardedRef,
  ) => {
    const [phoneNumberVerificationStep, setPhoneNumberVerificationStep] =
      useState<PhoneNumberVerificationStep>('not-verified')
    const [codeCopied, setCodeCopied] = useState(false)

    const verificationSteps = {
      'not-verified': {
        no: 0,
        title: '1: Verify your email address',
        text: 'Verify the email address that you used to set up your account',
      },
      'email-verified': {
        no: 1,
        title: '2: Verify your phone number',
        text: 'Enter a phone number for receiving two-factor verifications',
      },
      'verification-code': {
        no: 1,
        title: '2: Verify your phone number',
        text: null,
      },
      'backup-code': {
        no: 2,
        title: '3: Save your back-up security code',
        text: null,
      },
    }

    return (
      <WebUI.Modal
        aria-label="Phone number verification"
        ref={forwardedRef}
        initialVisible={initialVisible}
        className={WebUI.cn(
          '[&_>_.ModalContentView]:max-w-screen-sm [&_>_.ModalContentView]:p-9',
        )}
        {...restProps}
      >
        <WebUI.ModalCloseButton />

        <WebUI.VStack className="gap-6">
          <WebUI.Text as="h3">Set Two-Factor Authentication</WebUI.Text>
          <WebUI.Text className="font-light">
            Two-Factor Authentication is required prior to {priorTo} and helps
            keep your account safe when key actions are taken on the platform.
          </WebUI.Text>
          <WebUI.VStack className="gap-4 border px-5 pt-4 pb-7 sm:rounded">
            <WebUI.HStack className="gap-1">
              {['#ff693e', '#ff9b4e', '#ffc255'].map((color, index) => (
                <WebUI.PhosphorIcon
                  key={index}
                  width={12}
                  color={
                    verificationSteps[phoneNumberVerificationStep].no >= index
                      ? color
                      : tailwindConfig.theme.colors.natural[70]
                  }
                  icon="circle-fill"
                />
              ))}
            </WebUI.HStack>
            <WebUI.Text>
              {verificationSteps[phoneNumberVerificationStep].title}
            </WebUI.Text>
            <WebUI.Text className="font-light">
              {verificationSteps[phoneNumberVerificationStep].text}
            </WebUI.Text>
            <PhoneNumberVerification
              onStepChange={setPhoneNumberVerificationStep}
              onToggleChange={setCodeCopied}
              hideInstructions
            />
            {phoneNumberVerificationStep === 'backup-code' && (
              <WebUI.Button
                className="self-start"
                size="large"
                variant="primary"
                disabled={!codeCopied}
                onClick={onDidVerify}
              >
                Save
              </WebUI.Button>
            )}
          </WebUI.VStack>
        </WebUI.VStack>
      </WebUI.Modal>
    )
  },
)
