import {fuzzyMatch, mapToObj, pickBy, startCase} from '@cheddarup/util'
import {
  UseComboboxGetInputPropsOptions,
  UseComboboxGetItemPropsOptions,
  UseComboboxGetMenuPropsOptions,
  UseComboboxProps,
  UseComboboxReturnValue,
  useCombobox,
} from 'downshift'
import {ForwardRefComponent, useForkRef} from '@cheddarup/react-util'
import {useVirtualizer} from '@tanstack/react-virtual'
import {compute as computeScrollIntoView} from 'compute-scroll-into-view'
import flattenChildren from 'react-keyed-flatten-children'
import React, {
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import {PhosphorIcon, PhosphorIconName} from '../icons'
import {Button, ButtonProps} from './Button'
import {Ellipsis} from './Ellipsis'
import {HStack, VStack} from './Stack'
import {IconButton} from './IconButton'
import {Input} from './Input'
import {Popover, PopoverContent, PopoverInstance, PopoverProps} from './Popover'
import {Separator} from './Separator'
import {Text, TextProps} from './Text'
import {cn} from '../utils'

export interface ComboboxItem {
  value: string
  label?: string
  labelEl?: React.ReactNode
  groupId?: string
  score?: number
  chunks?: Chunk[]
  offset?: string
}

export interface ComboboxListInstance {
  scrollToItem: (index: number) => void
}

export interface InternalComboboxContextValue
  extends UseComboboxReturnValue<ComboboxItem> {
  inputValue: string
  optionGroups: OptionGroup[]
  items: ComboboxItem[]
  creatable: boolean
  setOptionGroups: (newOptionGroups: OptionGroup[]) => void
  inputRef: React.RefObject<HTMLInputElement>
  listRef: React.RefObject<ComboboxListInstance>
}
export interface InternalComboboxItemContextValue extends ComboboxItem {}

export const InternalComboboxContext = React.createContext(
  {} as InternalComboboxContextValue,
)
export const InternalComboboxItemContext = React.createContext(
  {} as InternalComboboxItemContextValue,
)

// MARK: - Combobox

export interface ComboboxInstance extends UseComboboxReturnValue<ComboboxItem> {
  inputRef: React.RefObject<HTMLInputElement>
  listRef: React.RefObject<ComboboxListInstance>
}

export interface ComboboxProps
  extends Omit<UseComboboxProps<ComboboxItem>, 'items' | 'itemToString'>,
    React.ComponentPropsWithoutRef<'div'> {
  creatable?: boolean
}

export const Combobox = React.forwardRef<ComboboxInstance, ComboboxProps>(
  (
    {
      children,
      className,
      getA11yStatusMessage,
      highlightedIndex,
      initialHighlightedIndex,
      defaultHighlightedIndex,
      isOpen,
      initialIsOpen,
      defaultIsOpen,
      selectedItem,
      initialSelectedItem,
      defaultSelectedItem,
      initialInputValue,
      defaultInputValue,
      id,
      labelId,
      menuId,
      toggleButtonId,
      inputId,
      getItemId,
      stateReducer,
      onSelectedItemChange,
      onIsOpenChange,
      onHighlightedIndexChange,
      onStateChange,
      onInputValueChange,
      environment,
      creatable = false,
      ...restProps
    },
    forwardedRef,
  ) => {
    const [inputValue, setInputValue] = useState(
      initialInputValue ?? selectedItem?.label ?? '',
    )
    const [optionGroups, setOptionGroups] = useState<OptionGroup[]>([])

    const items = useMemo(
      () =>
        getItems(
          {
            inputValue: selectedItem?.label === inputValue ? '' : inputValue,
          },
          optionGroups,
        ),
      [inputValue, optionGroups, selectedItem?.label],
    )

    const inputRef = useRef<HTMLInputElement>(null)
    const listRef = useRef<ComboboxListInstance>(null)

    const comboboxProps: UseComboboxProps<ComboboxItem> = {
      items,
      itemToString: (item) => item?.label ?? item?.value ?? '',
      getA11yStatusMessage,
      highlightedIndex,
      initialHighlightedIndex,
      defaultHighlightedIndex,
      isOpen,
      initialIsOpen,
      defaultIsOpen,
      selectedItem,
      initialSelectedItem,
      defaultSelectedItem,
      initialInputValue,
      defaultInputValue,
      id,
      labelId,
      menuId,
      toggleButtonId,
      inputId,
      getItemId,
      stateReducer: (state, actionAndChanges) => {
        let changes = actionAndChanges.changes

        // Reset `inputValue` for an already selected __create__ item
        if (
          changes.selectedItem?.value === '__create__' &&
          changes.inputValue === ''
        ) {
          changes = {
            ...changes,
            inputValue: state.inputValue,
          }
        }

        return stateReducer
          ? stateReducer(state, {...actionAndChanges, changes})
          : {...state, ...changes}
      },
      onSelectedItemChange: (changes) => {
        inputRef.current?.blur()
        onSelectedItemChange?.(changes)
      },
      onIsOpenChange,
      onHighlightedIndexChange,
      onStateChange,
      onInputValueChange: (change) => {
        onInputValueChange?.(change)
        setInputValue(change.inputValue ?? '')
      },
      environment,
    }
    const combobox = useCombobox(
      pickBy(
        comboboxProps,
        (val) => val !== undefined,
      ) as UseComboboxProps<ComboboxItem>,
    )

    useImperativeHandle(
      forwardedRef,
      (): ComboboxInstance => ({
        ...combobox,
        inputRef,
        listRef,
      }),
      [combobox],
    )

    const internalContextValue = useMemo(
      () => ({
        ...combobox,
        inputValue,
        optionGroups,
        items,
        creatable,
        setOptionGroups,
        inputRef,
        listRef,
      }),
      [combobox, inputValue, items, creatable, optionGroups],
    )

    // Suppress "You forgot to call" errors from Downshift
    combobox.getMenuProps({}, {suppressRefError: true})
    combobox.getInputProps({}, {suppressRefError: true})

    return (
      <InternalComboboxContext.Provider value={internalContextValue}>
        <VStack className={cn('Combobox', className)} {...restProps}>
          {children}
        </VStack>
      </InternalComboboxContext.Provider>
    )
  },
)

// MARK: - ComboboxInput

export interface ComboboxInputProps
  extends Omit<
    UseComboboxGetInputPropsOptions,
    keyof React.HTMLProps<HTMLInputElement>
  > {
  chevron?: boolean
  chevronIconName?: PhosphorIconName
  onClear?: () => void
  preventEnterDefault?: boolean
}

export const ComboboxInput = React.forwardRef(
  (
    {
      as: Comp = Input,
      className,
      chevron = true,
      chevronIconName = 'caret-down',
      preventEnterDefault = false,
      onFocus,
      onBlur,
      onKeyDown,
      onClear,
      value,
      ...restProps
    },
    forwardedRef,
  ) => {
    const {getInputProps, inputRef, reset, inputValue} = useContext(
      InternalComboboxContext,
    )
    return (
      <HStack
        className={cn(
          'ComboboxInput relative min-w-[10rem] max-w-full',
          className,
        )}
      >
        <Comp
          className={cn(
            'ComboboxInput-input min-w-full',
            chevron && 'pr-[calc(1rem+calc(theme(spacing.2)*2))]',
          )}
          {...getInputProps({
            ref: useForkRef(inputRef, forwardedRef),
            value: value ?? inputValue,
            onFocus: (event: React.FocusEvent<HTMLInputElement>) => {
              onFocus?.(event)
              event.target.select()
            },
            onBlur: (event: React.FocusEvent<HTMLInputElement>) => {
              onBlur?.(event)

              // Suppress downshift auto selecting an item on blur
              ;(event as any).preventDownshiftDefault = true
            },
            onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
              onKeyDown?.(event)
              // Suppress "Enter" handler on "Shift+Enter"
              if (
                event.key === 'Enter' &&
                (preventEnterDefault || event.getModifierState('Shift'))
              ) {
                ;(event as any).preventDownshiftDefault = true
              }
            },
            ...restProps,
          } as any)}
        />

        {chevron &&
          (onClear && value !== '' ? (
            <IconButton
              className="-translate-y-1/2 absolute top-1/2 right-2 h-[1rem] w-[1rem] text-gray400"
              onClick={() => {
                onClear()
                reset()
              }}
            >
              <PhosphorIcon
                className="absolute top-0 left-0 h-full w-full"
                icon="x"
              />
            </IconButton>
          ) : (
            <PhosphorIcon
              className={cn(
                'ComboboxInput-chevron',
                '-translate-y-1/2 pointer-events-none absolute top-1/2 right-2 h-[1rem] w-[1rem] leading-compact',
                '[.ComboboxInput-input[data-disabled=true]_~_&]:opacity-50',
              )}
              icon={
                chevronIconName === 'caret-down'
                  ? 'caret-down-fill'
                  : chevronIconName
              }
            />
          ))}
      </HStack>
    )
  },
) as ForwardRefComponent<typeof Input, ComboboxInputProps>

// MARK: - ComboboxPopover

export interface ComboboxPopoverProps extends PopoverProps {
  className?: string
  reference?: {getBoundingClientRect: () => ClientRect | DOMRect} | null
  portal?: boolean
}

export const ComboboxPopover = React.forwardRef<
  PopoverInstance,
  ComboboxPopoverProps
>(
  (
    {reference: referenceProp, portal, children, className, ...restProps},
    forwardedRef,
  ) => {
    const {isOpen, inputRef} = useContext(InternalComboboxContext)

    const ownRef = useRef<PopoverInstance>(null)
    const ref = useForkRef(ownRef, forwardedRef)

    useLayoutEffect(() => {
      if (isOpen) {
        if (ownRef.current) {
          ;(ownRef.current.unstable_referenceRef as any).current =
            referenceProp ?? inputRef.current
          setTimeout(() => ownRef.current?.setVisible(true), 0)
        }
      } else {
        ownRef.current?.setVisible(false)
      }
    }, [inputRef, isOpen, referenceProp])

    return (
      <Popover ref={ref} placement="bottom-start" {...restProps}>
        <PopoverContent
          className={className}
          aria-label="Combobox options"
          unstable_autoFocusOnShow={false}
          unstable_autoFocusOnHide={false}
          arrow={false}
          portal={portal}
        >
          {children}
        </PopoverContent>
      </Popover>
    )
  },
)

// MARK: - VirtualizedComboboxList

export interface VirtualizedComboboxListProps extends ComboboxListProps {
  wrapperClassName?: string
  overscan?: number
  rowHeight?: (index: number, option: Option | undefined) => number
}

export const VirtualizedComboboxList = React.forwardRef(
  (
    {
      className,
      wrapperClassName,
      children,
      overscan = 10,
      rowHeight: rowHeightProp,
      ...restProps
    },
    forwardedRef,
  ) => {
    const [elements, renderElements] = useComboboxListElements(children)
    const scrollableRef = useRef<HTMLDivElement>(null)

    const virtualizer = useVirtualizer({
      getScrollElement: () => scrollableRef.current,
      overscan,
      count: elements.length,
      estimateSize: (index) => {
        if (rowHeightProp == null) {
          return COMBOBOX_DEFAULT_ITEM_HEIGHT
        }

        const option = elements[index]?.props as Option | undefined
        return rowHeightProp(index, option)
      },
      getItemKey: (index) => {
        const option = elements[index]?.props as Option | undefined
        return option?.value ?? index
      },
    })

    const virtualizedElements = virtualizer.getVirtualItems().map((vi) =>
      // biome-ignore lint/style/noNonNullAssertion:
      React.cloneElement(elements[vi.index]!, {
        style: {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: vi.size,
          transform: `translateY(${vi.start}px)`,
        },
      }),
    )

    return (
      <VStack
        ref={scrollableRef}
        className={cn(
          'ComboboxList-wrapper w-full flex-[0_1_auto] overflow-y-auto',
          wrapperClassName,
        )}
      >
        <BaseComboboxList
          ref={forwardedRef}
          className={cn(
            'relative flex-0 [&.ComboboxList--empty]:h-auto',
            className,
          )}
          style={{
            height: `${virtualizer.getTotalSize()}px`,
          }}
          isEmpty={elements.length === 0}
          {...restProps}
        >
          {renderElements(virtualizedElements)}
        </BaseComboboxList>
      </VStack>
    )
  },
) as ForwardRefComponent<'div', VirtualizedComboboxListProps>

export const COMBOBOX_DEFAULT_ITEM_HEIGHT = 30

// MARK: - ComboboxList

export interface ComboboxListProps
  extends Pick<BaseComboboxListProps, 'noResultsFoundText'> {}

export const ComboboxList = React.forwardRef(
  ({as: Comp = 'div', children, className, ...restProps}, forwardedRef) => {
    const {listRef} = useContext(InternalComboboxContext)
    const [elements, renderElements] = useComboboxListElements(children)

    const scrollableRef = useRef<HTMLDivElement>(null)
    useImperativeHandle(
      listRef,
      (): ComboboxListInstance => ({
        scrollToItem: (index) => {
          const scrollable = scrollableRef.current
          if (!scrollable) {
            return
          }

          const itemNode = scrollable.querySelector(
            `[data-itemindex="${index}"]`,
          )
          if (!itemNode) {
            return
          }

          const actions = computeScrollIntoView(itemNode, {
            boundary: scrollable,
            block: 'nearest',
            scrollMode: 'if-needed',
          })
          actions.forEach(({el, top, left}) => {
            el.scrollTop = top
            el.scrollLeft = left
          })
        },
      }),
      [],
    )

    return (
      <BaseComboboxList
        ref={useForkRef(scrollableRef, forwardedRef)}
        className={cn('w-full flex-[0_1_auto] overflow-y-auto', className)}
        isEmpty={elements.length === 0}
        {...restProps}
      >
        {renderElements(elements)}
      </BaseComboboxList>
    )
  },
) as ForwardRefComponent<'div', ComboboxListProps>

// MARK: - BaseComboboxList

interface BaseComboboxListProps
  extends Omit<
    UseComboboxGetMenuPropsOptions,
    keyof React.HTMLProps<HTMLElement>
  > {
  noResultsFoundText?: string
  isEmpty?: boolean
}

const BaseComboboxList = React.forwardRef(
  (
    {
      children,
      className,
      noResultsFoundText = 'No results found',
      isEmpty,
      ...restProps
    },
    forwardedRef,
  ) => {
    const {getMenuProps} = useContext(InternalComboboxContext)

    if (isEmpty) {
      return noResultsFoundText === '' ? null : (
        <VStack
          className={cn(
            'ComboboxList',
            'ComboboxList--empty',
            'p-2 text-ds-xs',
            className,
          )}
        >
          <Text>{noResultsFoundText}</Text>
        </VStack>
      )
    }

    return (
      <VStack
        className={cn('ComboboxList', 'w-full *:flex-0', className)}
        {...getMenuProps({ref: forwardedRef, ...restProps})}
      >
        {children}
      </VStack>
    )
  },
) as ForwardRefComponent<'div', BaseComboboxListProps>

function useComboboxListElements(childrenProp: React.ReactNode) {
  const _ctx = useContext(InternalComboboxContext)
  const {
    inputValue,
    optionGroups: _optionGroups,
    items: _items,
    setOptionGroups,
  } = _ctx

  const newOptionGroups = useMemo(
    () => getOptionGroups(flattenChildren(childrenProp)),
    [childrenProp],
  )

  useEffect(() => {
    setOptionGroups(newOptionGroups)
  }, [newOptionGroups, setOptionGroups])

  const isFirstRenderRef = useRef(true)
  let optionGroups = _optionGroups
  let items = _items
  if (isFirstRenderRef.current) {
    isFirstRenderRef.current = false
    optionGroups = newOptionGroups
    items = getItems({inputValue}, optionGroups)
  }

  const listElements = useMemo(() => {
    const optionElements = optionGroups
      .reduce<OptionElement[]>(
        (acc, group) => [...acc, ...group.optionElements],
        [],
      )
      .map((el) => React.cloneElement(el, {key: `item-${el.props.value}`}))
    const optionElementByValue = mapToObj(optionElements, (el) => [
      el.props.value,
      el,
    ])

    const ret: React.ReactElement[] = []
    for (const [idx, item] of items.entries()) {
      const optionEl = optionElementByValue[item.value]
      if (optionEl == null || ret.includes(optionEl)) {
        continue
      }

      const group =
        item.groupId == null
          ? null
          : optionGroups.find((g) => g.id === item.groupId)

      if (group) {
        if (group.label == null) {
          if (idx !== 0) {
            ret.push(
              <VStack
                key={`groupHeader-${group.id}`}
                className="ComboboxOptionGroup-header justify-center pt-2 pb-1"
              >
                <Separator className="ComboboxOptionGroup-separator border-natural-80" />
              </VStack>,
            )
          }
        } else {
          ret.push(
            <VStack
              key={`groupHeader-${group.id}`}
              className={
                'ComboboxOptionGroup-header ComboboxOptionGroup-header--labeled justify-center px-1 pt-2 pb-1'
              }
            >
              <Ellipsis className="ComboboxOptionGroup-label">
                {group.label}
              </Ellipsis>
            </VStack>,
          )
        }

        ret.push(
          ...items
            .filter((i) => i.groupId === group.id)
            .map((i) => optionElementByValue[i.value])
            .filter((el) => el != null),
        )
      } else {
        ret.push(optionEl)
      }
    }
    return ret
  }, [items, optionGroups])

  const contextValue = useMemo(
    (): InternalComboboxContextValue =>
      optionGroups === _optionGroups && items === _items
        ? _ctx
        : {..._ctx, optionGroups, items},
    [_ctx, _items, _optionGroups, items, optionGroups],
  )
  const renderListElements = useCallback(
    (children: React.ReactNode) => (
      <InternalComboboxContext.Provider value={contextValue}>
        {children}
      </InternalComboboxContext.Provider>
    ),
    [contextValue],
  )

  return [listElements, renderListElements] as const
}

// MARK: - ComboboxOption

export interface ComboboxOptionProps
  extends Omit<
      UseComboboxGetItemPropsOptions<ComboboxItem>,
      keyof React.HTMLProps<HTMLElement> | 'item' | 'index'
    >,
    ButtonProps {
  value: string
  label?: string
  labelEl?: React.ReactNode
  groupId?: string
  sortingScoreMultiplier?: number
}

export const ComboboxOption = React.forwardRef(
  (
    {
      as = 'div',
      children,
      className,
      value,
      label,
      labelEl,
      groupId,
      ...restProps
    },
    forwardedRef,
  ) => {
    const {getItemProps, items, selectedItem} = useContext(
      InternalComboboxContext,
    )
    let index = items.findIndex((item) => item.value === value)

    // biome-ignore lint/style/noNonNullAssertion:
    const item = items[index]!
    index = index === -1 ? 0 : index
    const checked = selectedItem?.value === item.value

    return (
      <InternalComboboxItemContext.Provider value={item}>
        <Button
          as={as}
          className={cn(
            'ComboboxOption',
            'justify-start rounded-none pr-2 pl-[2em] text-left text-ds-xs',
            'aria-selected:bg-teal-90',
            '[&[aria-selected=true]_.ComboboxOptionText-chunk[data-highlighted=true]]:font-bold [&[aria-selected=true]_.ComboboxOptionText-chunk[data-highlighted=true]]:text-inherit',
            '[&_>_.Button-iconBefore]:mr-[6px] [&_>_.Button-iconBefore]:text-[1.2em]',
            className,
          )}
          variant="ghost"
          data-itemindex={index}
          {...getItemProps({
            ref: forwardedRef,
            item,
            index,
            ...restProps,
          } as any)}
        >
          {checked && (
            <PhosphorIcon
              className="ComboboxOption-check -translate-y-1/2 absolute top-1/2 left-[0.2rem]"
              icon="check"
            />
          )}
          {children ?? labelEl ?? <ComboboxOptionText />}
        </Button>
      </InternalComboboxItemContext.Provider>
    )
  },
) as ForwardRefComponent<'div', ComboboxOptionProps>

// MARK: – ComboboxOptionCreate

export interface ComboboxOptionCreateProps
  extends Omit<ComboboxOptionProps, 'value' | 'label'> {
  value: '__create__'
  children?: React.ReactNode | ((inputValue: string) => React.ReactNode)
}

export const ComboboxOptionCreate = React.forwardRef(
  ({className, children, ...restProps}, forwardedRef) => {
    const {inputValue, items: _items} = useContext(InternalComboboxContext)

    const items = _items.filter((i) => i.value !== '__create__')
    const exactMatchExists =
      items.length > 0 && items.some((i) => i.label === inputValue)
    if (inputValue.length === 0 || exactMatchExists) {
      return null
    }

    return (
      <ComboboxOption
        ref={forwardedRef}
        className={cn('ComboboxOptionCreate', className)}
        {...restProps}
      >
        {typeof children === 'function'
          ? children(inputValue)
          : (children ?? `Create "${inputValue}"`)}
      </ComboboxOption>
    )
  },
) as ForwardRefComponent<'div', ComboboxOptionCreateProps>

// MARK: - ComboboxOptionText

export interface ComboboxOptionTextProps extends TextProps {}

export const ComboboxOptionText = React.forwardRef(
  ({className, ...restProps}, forwardedRef) => {
    const item = useContext(InternalComboboxItemContext)
    const label = item.label ?? item.value

    return (
      <Ellipsis
        ref={forwardedRef}
        className={cn(
          'ComboboxOptionText',
          '[&_.ComboboxOptionText-chunk[data-highlighted=true]]:text-teal-50',
          className,
        )}
        {...restProps}
      >
        {item.chunks
          ? item.chunks.map((chunk, index) => (
              <span
                key={index}
                className="ComboboxOptionText-chunk"
                data-highlighted={chunk.highlight ? 'true' : 'false'}
              >
                {label.slice(chunk.start, chunk.end)}
              </span>
            ))
          : label}
      </Ellipsis>
    )
  },
) as ForwardRefComponent<'span', ComboboxOptionTextProps>

// MARK: - ComboboxOptionGroup

export interface ComboboxOptionGroupProps {
  id: string
  label?: string
  children?: React.ReactNode
}

export const ComboboxOptionGroup = ({children}: ComboboxOptionGroupProps) => (
  <>{children}</>
)

// MARK: - Helpers

interface Option extends ComboboxOptionProps {}

interface OptionGroup extends ComboboxOptionGroupProps {
  optionElements: OptionElement[]
}

type OptionElement = React.ReactElement<ComboboxOptionProps>
type OptionCreateElement = React.ReactElement<ComboboxOptionCreateProps>
type OptionGroupElement = React.ReactElement<ComboboxOptionGroupProps>

function getItems(ctx: {inputValue: string}, optionGroups: OptionGroup[]) {
  const optionElements = optionGroups.reduce<OptionElement[]>(
    (acc, group) => [...acc, ...group.optionElements],
    [],
  )
  const options = optionElements.map((optionEl): Option => {
    const group = optionGroups.find((g) => g.optionElements.includes(optionEl))
    return {
      ...optionEl.props,
      groupId: group?.id,
      label: isOptionCreateElement(optionEl)
        ? ctx.inputValue
        : optionEl.props.label,
    }
  })

  const _items = options
    .map((option): ComboboxItem | null => {
      const value = option.value
      const labelElStrings = option.labelEl
        ? extractText(option.labelEl as any)
        : []

      const label = option.label ?? option.value
      if (!ctx.inputValue) {
        return {
          value,
          label,
          labelEl: option.labelEl,
          groupId: option.groupId,
          score: -1000,
          chunks: [{start: 0, end: label.length, highlight: false}],
        }
      }

      const res = fuzzyMatch(
        ctx.inputValue.trim(),
        `${label} ${labelElStrings.join(' ')}`,
        {withScore: true},
      )
      if (!res.match) {
        return null
      }

      return {
        value,
        label,
        labelEl: option.labelEl,
        groupId: option.groupId,
        score: (option.sortingScoreMultiplier ?? 1) * (res.score ?? 0),
        chunks: chunksForFuzzyMatch(label, res),
      }
    })
    .filter((item) => item != null)
    .sort((a, b) => (b.score ?? 0) - (a.score ?? 0))

  // Order items according to group
  const ret: ComboboxItem[] = []
  for (const item of _items) {
    if (ret.includes(item)) {
      continue
    }
    if (item.groupId == null) {
      ret.push(item)
    } else {
      const otherItemsInGroup = _items.filter(
        (i) => i.groupId === item.groupId && i !== item,
      )
      ret.push(item, ...otherItemsInGroup)
    }
  }
  return ret
}

function getOptionGroups(node: React.ReactNode) {
  const ret: OptionGroup[] = []
  if (Array.isArray(node)) {
    const defaultGroup: OptionGroup = {id: '', optionElements: []}
    const elements = node.filter(
      (subnode) =>
        isOptionElement(subnode) ||
        isOptionCreateElement(subnode) ||
        isOptionGroupElement(subnode),
    )
    for (const el of elements) {
      if (isOptionElement(el) || isOptionCreateElement(el)) {
        let group = defaultGroup
        if (el.props.groupId != null) {
          const found = ret.find((g) => g.id === el.props.groupId)
          if (found) {
            group = found
          } else {
            group = {
              id: el.props.groupId,
              label: startCase(el.props.groupId),
              optionElements: [],
            }
            ret.push(group)
          }
        }
        group.optionElements.push(el)
      }
      if (isOptionGroupElement(el)) {
        ret.push({
          id: el.props.id,
          label: el.props.label,
          optionElements: getOptionElements(el.props.children),
        })
      }
    }
    if (defaultGroup.optionElements.length > 0) {
      ret.push(defaultGroup)
    }
  }
  return ret
}

function getOptionElements(node: React.ReactNode) {
  const ret: OptionElement[] = []
  if (Array.isArray(node)) {
    for (const subnode of node) {
      ret.push(...getOptionElements(subnode))
    }
  }
  if (isOptionElement(node)) {
    ret.push(node)
  }
  return ret
}

function isOptionElement(node: React.ReactNode): node is OptionElement {
  // HACK: Only works for simple option/group comparison
  return React.isValidElement(node) && node.props.value != null
}

function isOptionCreateElement(
  node: React.ReactNode,
): node is OptionCreateElement {
  // HACK: Only works for simple option/group comparison
  return React.isValidElement(node) && node.props.value === '__create__'
}

function isOptionGroupElement(
  node: React.ReactNode,
): node is OptionGroupElement {
  // HACK: Only works for simple option/group comparison
  return React.isValidElement(node) && node.props.value == null
}

// Shape inspired by https://github.com/bvaughn/highlight-words-core
interface Chunk {
  start: number
  end: number
  highlight: boolean
}

function chunksForFuzzyMatch(
  value: string,
  res: ReturnType<typeof fuzzyMatch> & {score: number},
): Chunk[] {
  const ret: Chunk[] = []
  let i = 0
  for (const range of res.ranges) {
    if (range.start > i) {
      ret.push({start: i, end: range.start, highlight: false})
    }
    ret.push({
      start: range.start,
      end: range.stop,
      highlight: true,
    })
    i = range.stop
  }
  if (i < value.length) {
    ret.push({start: i, end: value.length, highlight: false})
    i = value.length
  }
  return ret
}

function extractText(el: React.ReactElement | string | number): string[] {
  if (!el) {
    return []
  }
  if (typeof el === 'string' || typeof el === 'number') {
    return [`${el}`]
  }

  const children = el.props?.children
  if (Array.isArray(children)) {
    return children.flatMap((e: any) => extractText(e))
  }
  return extractText(children)
}

export {useCombobox}
