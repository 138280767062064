import {endpoints} from '../../endpoints'
import {getEndpointKey} from '../../utils'
import {makeUseMutation} from '../use-mutation'

export const useUpdateTabMemberMutation = makeUseMutation(
  endpoints.tabMembers.update,
  undefined,
  (queryClient) => ({
    onSuccess: (_newTabMember, vars) => {
      const membersListKey = getEndpointKey(endpoints.tabMembers.list, vars)
      queryClient.invalidateQueries({queryKey: membersListKey})
    },
  }),
)
