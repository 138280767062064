import React, {useEffect, useRef, useState} from 'react'
import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import {api, useUpdateTabMutation} from '@cheddarup/api-client'
import {getLocalTimeZone} from '@internationalized/date'
import {useUpdateEffect} from '@cheddarup/react-util'

import {CollectionSettingsUpgradePlanButton} from './CollectionSettingsUpgradePlanButton'
import {CollectionSettingsPanel} from './CollectionSettingsPanel'
import {useDebounceUpdateTab} from './CollectionSettingsPayment'
import {SettingDisclosureSwitch} from './SettingDisclosureSwitch'

// MARK: – TimingSettings

export interface TimingSettingsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collection: Api.Tab
  collectionHasRecurringItems: boolean
}

export const TimingSettings = ({
  collection,
  collectionHasRecurringItems,
  ...restProps
}: TimingSettingsProps) => {
  const timingDisclosureRef = useRef<WebUI.DisclosureInstance>(null)
  const updateCollectionMutation = useUpdateTabMutation()
  const debouncedUpdateTab = useDebounceUpdateTab()

  const updateCollection = updateCollectionMutation.mutate
  useEffect(() => {
    if (!collection.is_pro && collection.status !== 'draft') {
      updateCollection({
        pathParams: {
          tabId: collection.id,
        },
        body: {open_datetime: null, close_datetime: null},
      })

      timingDisclosureRef.current?.hide()
    }
  }, [collection.id, collection.is_pro, collection.status, updateCollection])

  const label = 'Set a start and end time for your collection page'

  return (
    <CollectionSettingsPanel
      heading="Timing"
      settings={[
        <WebUI.Disclosure
          key="disclosure"
          ref={timingDisclosureRef}
          className="gap-4"
          initialVisible={
            !!collection.open_datetime || !!collection.close_datetime
          }
          onVisibleChange={(newIsTimingVisible) => {
            if (!newIsTimingVisible) {
              debouncedUpdateTab({
                pathParams: {
                  tabId: collection.id,
                },
                body: {open_datetime: null, close_datetime: null},
              })
            }
          }}
        >
          {collection.status === 'draft' || collection.is_pro ? (
            <>
              <SettingDisclosureSwitch
                featureKey={collection.is_pro ? undefined : 'startAndStopTimes'}
                disabled={collectionHasRecurringItems}
              >
                {label}
              </SettingDisclosureSwitch>
              {collectionHasRecurringItems && (
                <WebUI.Text className="text-ds-sm text-orange-50">
                  Note: This setting is not available on collections with
                  recurring payment items.
                </WebUI.Text>
              )}
            </>
          ) : (
            <CollectionSettingsUpgradePlanButton plan="pro">
              {label}
            </CollectionSettingsUpgradePlanButton>
          )}

          <WebUI.DisclosureContent>
            <WebUI.VStack className="gap-4">
              <div className="text-ds-sm">
                Control when people can access your collection page. Prior to
                start time, visitors see a snazzy countdown timer.
              </div>
              <WebUI.FormFieldGroup className="sm:w-[460px] [&_>.FormField_>_.FormField-label]:font-light">
                <WebUI.FormField label="Start:">
                  <WebUI.DatePicker
                    size="compact"
                    granularity="minute"
                    value={
                      collection.open_datetime
                        ? Util.parseCalendarDateTime(collection.open_datetime)
                        : null
                    }
                    onValueChange={(newOpenDatetime) => {
                      if (newOpenDatetime) {
                        updateCollectionMutation.mutate({
                          pathParams: {
                            tabId: collection.id,
                          },
                          body: {
                            open_datetime: newOpenDatetime
                              .toDate(getLocalTimeZone())
                              .toISOString(),
                            close_datetime:
                              collection.close_datetime ||
                              newOpenDatetime
                                .add({days: 1})
                                .toDate(getLocalTimeZone())
                                .toISOString(),
                          },
                        })
                      }
                    }}
                  />
                </WebUI.FormField>
                <WebUI.FormField label="End:">
                  <WebUI.DatePicker
                    size="compact"
                    value={
                      collection.close_datetime
                        ? Util.parseCalendarDateTime(collection.close_datetime)
                        : null
                    }
                    onValueChange={(newCloseDatetime) => {
                      if (newCloseDatetime) {
                        updateCollectionMutation.mutate({
                          pathParams: {
                            tabId: collection.id,
                          },
                          body: {
                            open_datetime:
                              collection.open_datetime ||
                              newCloseDatetime
                                .subtract({days: 1})
                                .toDate(getLocalTimeZone())
                                .toISOString(),
                            close_datetime: newCloseDatetime
                              .toDate(getLocalTimeZone())
                              .toISOString(),
                          },
                        })
                      }
                    }}
                  />
                </WebUI.FormField>
              </WebUI.FormFieldGroup>

              {!!collection.close_datetime && !!collection.open_datetime && (
                <>
                  <WebUI.Separator className="-ml-6 -mr-4" />
                  <WebUI.Disclosure
                    className="gap-4"
                    initialVisible={collection.options?.allowPreview ?? false}
                    onVisibleChange={(newAllowPreview) =>
                      debouncedUpdateTab({
                        pathParams: {
                          tabId: collection.id,
                        },
                        body: {options: {allowPreview: newAllowPreview}},
                      })
                    }
                  >
                    <WebUI.DisclosureSwitch>
                      Allow people to preview your items prior to start time
                    </WebUI.DisclosureSwitch>
                    <WebUI.DisclosureContent className="text-ds-sm">
                      People can view item details but will not be able to add
                      items to their cart.
                    </WebUI.DisclosureContent>
                  </WebUI.Disclosure>
                </>
              )}
            </WebUI.VStack>
          </WebUI.DisclosureContent>
        </WebUI.Disclosure>,
      ]}
      {...restProps}
    />
  )
}

// MARK: – SearchSettings

export interface SearchSettingsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collection: Api.Tab
}

export const SearchSettings = ({
  collection,
  ...restProps
}: SearchSettingsProps) => {
  const updateCollectionMutation = useUpdateTabMutation()

  return (
    <CollectionSettingsPanel
      heading="Search"
      settings={[
        <WebUI.Switch
          key="switch"
          reverse
          checked={collection.options?.allowIndexing}
          onChange={(event) =>
            updateCollectionMutation.mutate({
              pathParams: {
                tabId: collection.id,
              },
              body: {
                options: {
                  allowIndexing: event.target.checked,
                },
              },
            })
          }
        >
          Allow search engines to find your collection page
        </WebUI.Switch>,
      ]}
      {...restProps}
    />
  )
}

// MARK: – EntryCodeSettings

export interface EntryCodeSettingsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collection: Api.Tab
}

export const EntryCodeSettings = ({
  collection,
  ...restProps
}: EntryCodeSettingsProps) => {
  const [accessCode, setAccessCode] = useState(collection.access_code ?? '')
  const updateCollectionMutation = useUpdateTabMutation()
  const debouncedUpdateTab = useDebounceUpdateTab()

  useUpdateEffect(() => {
    setAccessCode(collection.access_code ?? '')
  }, [collection.access_code])

  const updateCollection = updateCollectionMutation.mutate
  useEffect(() => {
    if (!collection.is_pro && collection.status !== 'draft') {
      updateCollection({
        pathParams: {
          tabId: collection.id,
        },
        body: {access_code: ''},
      })
    }
  }, [collection.id, collection.is_pro, collection.status, updateCollection])

  const label = 'Put my collection page behind an access code'

  return (
    <CollectionSettingsPanel
      heading="Access code"
      settings={[
        <WebUI.Disclosure
          key="disclosure"
          className="gap-4"
          initialVisible={!!collection.access_code}
          onVisibleChange={(newIsVisible) => {
            if (!newIsVisible) {
              debouncedUpdateTab({
                pathParams: {
                  tabId: collection.id,
                },
                body: {access_code: ''},
              })
            }
          }}
        >
          {collection.status === 'draft' || collection.is_pro ? (
            <SettingDisclosureSwitch
              featureKey={collection.is_pro ? undefined : 'accessCode'}
            >
              {label}
            </SettingDisclosureSwitch>
          ) : (
            <CollectionSettingsUpgradePlanButton plan="pro">
              {label}
            </CollectionSettingsUpgradePlanButton>
          )}
          <WebUI.DisclosureContent>
            <WebUI.VStack className="gap-4">
              <WebUI.FormField
                label="Create a code that visitors must enter to access your collection page."
                error={accessCode ? undefined : 'Required'}
              >
                <WebUI.DeferredInput
                  className="text-ds-sm lg:mr-2 lg:w-1/3"
                  style={{maxWidth: '240px'}}
                  placeholder="Access Code"
                  defaultValue={collection.access_code ?? ''}
                  onChange={(event) => setAccessCode(event.target.value)}
                  commitValue={(newAccessCode) =>
                    updateCollectionMutation.mutate({
                      pathParams: {
                        tabId: collection.id,
                      },
                      body: {access_code: newAccessCode},
                    })
                  }
                />
              </WebUI.FormField>
            </WebUI.VStack>
          </WebUI.DisclosureContent>
        </WebUI.Disclosure>,
      ]}
      {...restProps}
    />
  )
}

// MARK: – VisitorReportSettings

export interface VisitorReportSettingsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collection: Api.Tab
}

export const VisitorReportSettings = ({
  collection,
  ...restProps
}: VisitorReportSettingsProps) => {
  const updateCollectionMutation = useUpdateTabMutation()
  const debouncedUpdateTab = useDebounceUpdateTab()

  const updateCollection = updateCollectionMutation.mutate
  useEffect(() => {
    if (!collection.is_pro && collection.status !== 'draft') {
      updateCollection({
        pathParams: {
          tabId: collection.id,
        },
        body: {payer_identify: false},
      })
    }
  }, [collection.id, collection.is_pro, collection.status, updateCollection])

  const label = 'See who visited your collection page'

  return (
    <CollectionSettingsPanel
      heading="Visitor report"
      settings={[
        <WebUI.Disclosure
          key="disclosure"
          className="gap-4"
          visible={collection.payer_identify}
          onVisibleChange={(newPayerIdentify) =>
            debouncedUpdateTab({
              pathParams: {
                tabId: collection.id,
              },
              body: {payer_identify: newPayerIdentify},
            })
          }
        >
          {collection.status === 'draft' || collection.is_pro ? (
            <SettingDisclosureSwitch
              featureKey={collection.is_pro ? undefined : 'visitorReport'}
            >
              {label}
            </SettingDisclosureSwitch>
          ) : (
            <CollectionSettingsUpgradePlanButton plan="pro">
              {label}
            </CollectionSettingsUpgradePlanButton>
          )}
          <WebUI.DisclosureContent className="text-ds-sm">
            Gather the names and emails of everyone who views your page, both
            payers and visitors. Access and export your visitor report directly
            from your manage view.
          </WebUI.DisclosureContent>
        </WebUI.Disclosure>,
      ]}
      {...restProps}
    />
  )
}

// MARK: – MarketplaceSettings

export interface MarketplaceSettingsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collection: Api.Tab
}

export const MarketplaceSettings = ({
  collection,
  ...restProps
}: MarketplaceSettingsProps) => {
  const {data: marketplaceName} = api.auth.session.useQuery(undefined, {
    select: (session) =>
      session.organization_data?.internalMarketplace?.organizerNickname ?? '',
  })
  const updateCollectionMutation = useUpdateTabMutation()

  return (
    <CollectionSettingsPanel
      heading={
        <>
          {marketplaceName} marketplace{' '}
          <span className="font-bold text-teal-50">beta</span>
        </>
      }
      settings={[
        <WebUI.Switch
          key="switch"
          reverse
          checked={!!collection.options?.internalMarketplace?.enabled}
          onChange={(event) =>
            updateCollectionMutation.mutate({
              pathParams: {
                tabId: collection.id,
              },
              body: {
                options: {internalMarketplace: {enabled: event.target.checked}},
              },
            })
          }
        >
          List your sale page in the {marketplaceName} Marketplace
        </WebUI.Switch>,
      ]}
      {...restProps}
    />
  )
}
