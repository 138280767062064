import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {
  signUpOptionsSchema,
  signUpSchema,
  tabSignUpDetailedSchema,
} from '../schemas'

export default {
  list: makeEndpoint({
    path: 'users/tabs/:tabId/signups',
    responseSchema: signUpSchema.array(),
  }),
  detail: makeEndpoint({
    path: 'users/tabs/:tabId/signups/:signupId',
    responseSchema: tabSignUpDetailedSchema,
  }),
  create: makeEndpoint({
    path: 'users/tabs/:tabId/signups',
    method: 'POST',
    bodySchema: signUpSchema.partial(),
    responseSchema: tabSignUpDetailedSchema,
  }),
  update: makeEndpoint({
    path: 'users/tabs/:tabId/signups/:signupId',
    method: 'PATCH',
    bodySchema: z
      .object({
        name: z.string(),
        description: z.string(),
        hidden: z.boolean(),
        options: signUpOptionsSchema.partial(),
      })
      .partial(),
    responseSchema: tabSignUpDetailedSchema,
  }),
  delete: makeEndpoint({
    path: 'users/tabs/:tabId/signups/:signupId',
    method: 'DELETE',
    queryParamsSchema: z
      .object({
        sendEmail: z.boolean(),
      })
      .partial()
      .optional(),
    responseSchema: z.any(),
  }),
  clone: makeEndpoint({
    path: 'users/tabs/:tabId/signups/:signupId/clone',
    method: 'POST',
    bodySchema: z.object({
      new_tab_id: z.number().optional(),
    }),
    responseSchema: tabSignUpDetailedSchema,
  }),
}
