import * as WebUI from '@cheddarup/web-ui'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {useState} from 'react'
import FacebookIcon from 'src/images/FacebookIcon.svg'
import EmailIcon from 'src/images/EmailIcon.svg'

import usePublicCollection from './hooks/usePublicCollection'
import config from '../../config'

const PayerSharePage: React.FC = () => {
  const urlParams = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const {publicCollection} = usePublicCollection()
  const [collectionUrlCopied, setCollectionUrlCopied] = useState(false)

  // biome-ignore lint/style/noNonNullAssertion:
  const collectionSlug = (urlParams.tabSlug || urlParams.slug)!
  const userSlug = urlParams.user

  const collectionUrl = userSlug
    ? `https://${userSlug}.mycheddarup.com`
    : config.helpers.shareUrl(collectionSlug)

  return (
    <WebUI.Modal
      className="[&_>_.ModalContentView]:gap-6 [&_>_.ModalContentView]:px-10 [&_>_.ModalContentView]:py-8 sm:[&_>_.ModalContentView]:max-w-screen-sm sm:[&_>_.ModalContentView]:rounded-extended sm:[&_>_.ModalContentView]:px-8"
      onDidHide={() => navigate({pathname: '..', search: location.search})}
    >
      <WebUI.Heading as="h3" className="font-semibold">
        Share this collection
      </WebUI.Heading>
      <WebUI.Text className="font-light">
        Thanks for spreading the word! Copy and paste this link anywhere or
        share in message, post or email.
      </WebUI.Text>
      <WebUI.HStack className="flex-wrap gap-2">
        <WebUI.VStack className="grow gap-1">
          <div className="rounded border px-4 py-2 [word-wrap:break-word]">
            {collectionUrl}
          </div>
          {collectionUrlCopied && (
            <span className="font-normal text-ds-sm text-tint">
              Copied to clipboard!
            </span>
          )}
        </WebUI.VStack>

        <WebUI.Button
          size="large"
          roundness="capsule"
          onClick={async () => {
            await WebUI.copyToClipboard(collectionUrl)
            setCollectionUrlCopied(true)
          }}
        >
          Copy Link
        </WebUI.Button>
      </WebUI.HStack>
      <WebUI.HStack className="gap-4">
        <WebUI.Anchor
          href={`https://www.facebook.com/dialog/feed?app_id=413118622200717&link=${collectionUrl}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <WebUI.Image
            height={40}
            width={40}
            src={FacebookIcon}
            alt="Facebook logo"
          />
        </WebUI.Anchor>
        <WebUI.Anchor
          href={`mailto:?&subject=You're invited to ${
            publicCollection?.name ?? userSlug
          }!&body=${collectionUrl}`}
        >
          <WebUI.Image height={40} width={40} src={EmailIcon} alt="Mail icon" />
        </WebUI.Anchor>
      </WebUI.HStack>

      <WebUI.ModalCloseButton />
    </WebUI.Modal>
  )
}

export default PayerSharePage
