import PrimitiveCurrencyInput, {
  CurrencyInputProps as PrimitiveCurrencyInputProps,
} from 'react-currency-input-field'
import {forwardRef} from 'react'
import {ForwardRefComponent} from '@cheddarup/react-util'
import {Merge} from '@cheddarup/util'

import {Input, InputProps} from './Input'
import {cn} from '../utils'

export interface CurrencyInputProps
  extends Merge<Omit<PrimitiveCurrencyInputProps, 'customInput'>, InputProps> {}

export const CurrencyInput = forwardRef(
  ({as = Input, className, ...restProps}, forwardedRef) => (
    <PrimitiveCurrencyInput
      ref={forwardedRef}
      className={cn('CurrencyInput', className)}
      customInput={as}
      {...restProps}
    />
  ),
) as ForwardRefComponent<
  'input',
  Omit<PrimitiveCurrencyInputProps, 'customInput'>
>
