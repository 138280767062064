import * as WebUI from '@cheddarup/web-ui'

import {FormFieldInlineLabelEdit} from './FormFieldInlineLabelEdit'
import type {FieldGroupFieldsEditProps} from './FieldGroupFieldsEdit'

export interface FileFieldsEditProps
  extends Omit<
    FieldGroupFieldsEditProps<'file'>,
    'fieldGroupType' | 'initialValue' | 'onValueChange'
  > {}

export const FileFieldsEdit = ({
  focused,
  required,
  initialLabels,
  placeholders,
  onLabelChange,
}: FileFieldsEditProps) => (
  <FormFieldInlineLabelEdit
    className="[&_>_.FormField-caption]:block [&_>_.FormField-caption]:text-ds-sm"
    labelInputFocused={focused}
    required={required}
    initialLabel={initialLabels.value}
    placeholder={placeholders.value}
    caption="Respondents can upload files under 10mb."
    onLabelChange={(label) => onLabelChange('value', label)}
  >
    <WebUI.FilesManager disabled />
  </FormFieldInlineLabelEdit>
)
