import {useFormik} from '@cheddarup/react-util'
import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {
  useCreateContactListMutation,
  useUpdateContactListMutation,
} from '@cheddarup/api-client'

export interface CreateContactListPromptProps extends WebUI.PromptProps {
  contactList?: Api.ContactList | null
  onDidSaveContactList?: (folder: Api.ContactList) => void
}

export const ContactListFormPrompt = React.forwardRef<
  WebUI.DialogInstance,
  CreateContactListPromptProps
>(({contactList, onDidSaveContactList, ...restProps}, forwardedRef) => (
  <WebUI.Prompt
    ref={forwardedRef}
    aria-label="Contact list form"
    {...restProps}
  >
    {(dialog) => (
      <WebUI.VStack className="gap-6">
        <WebUI.PromptHeader
          heading={contactList == null ? 'Create a new list' : 'Rename list'}
        />
        <ContactListForm
          contactList={contactList}
          onDidSaveContactList={(newContactList) => {
            onDidSaveContactList?.(newContactList)
            dialog.hide()
          }}
          onCancel={() => dialog.hide()}
        />
      </WebUI.VStack>
    )}
  </WebUI.Prompt>
))

// MARK: – CreateFolderForm

interface ContactListFormProps extends React.ComponentPropsWithoutRef<'form'> {
  contactList?: Api.ContactList | null
  onDidSaveContactList?: (folder: Api.ContactList) => void
  onCancel: () => void
}

const ContactListForm = ({
  contactList,
  onCancel,
  onDidSaveContactList,
  ...restProps
}: ContactListFormProps) => {
  const createContactListMutation = useCreateContactListMutation()
  const updateContactListMutation = useUpdateContactListMutation()

  const formik = useFormik({
    initialValues: {
      name: contactList?.name ?? '',
    },
    onSubmit: async (values) => {
      if (contactList == null) {
        const newContactList = await createContactListMutation.mutateAsync({
          body: {name: values.name},
        })
        onDidSaveContactList?.(newContactList)
      } else {
        const newContactList = await updateContactListMutation.mutateAsync({
          pathParams: {
            contactListId: contactList.id,
          },
          body: {name: values.name},
        })
        onDidSaveContactList?.(newContactList)
      }
    },
  })

  return (
    <WebUI.Form
      onSubmit={formik.handleSubmit}
      onReset={formik.handleReset}
      {...restProps}
    >
      <WebUI.Input
        autoFocus
        name="name"
        maxLength={63}
        value={formik.values.name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <WebUI.HStack className="gap-4">
        <WebUI.Button type="submit" loading={formik.isSubmitting}>
          {contactList == null ? 'Create List' : 'Save'}
        </WebUI.Button>
        <WebUI.Button type="button" variant="secondary" onClick={onCancel}>
          Cancel
        </WebUI.Button>
      </WebUI.HStack>
    </WebUI.Form>
  )
}
