import {Route, Routes} from 'react-router-dom'
import React from 'react'

const LazyPaymentReportPage = React.lazy(
  () => import('src/views/payments/PaymentReportPage'),
)
const LazyPaymentPaymentItemReportPage = React.lazy(
  () => import('src/views/payments/PaymentPaymentItemReportPage'),
)
const LazyOrdersReportPage = React.lazy(
  () => import('src/views/collection/OrdersReportPage'),
)
const LazyTabObjectRespondentReportPage = React.lazy(
  () => import('src/views/collection/TabObjectRespondentReportPage'),
)
const LazyOrderSummaryReportPage = React.lazy(
  () => import('src/views/collection/OrderSummaryReportPage'),
)
const LazyPaymentItemReportPage = React.lazy(
  () => import('src/views/collection/PaymentItemReportPage'),
)
const LazyFieldViewsReportPage = React.lazy(
  () => import('src/views/collection/FieldViewsReportPage'),
)
const LazyTabObjectFieldsReportPage = React.lazy(
  () => import('src/views/collection/TabObjectFieldsReportPage'),
)
const LazyShippingLabelPdfPage = React.lazy(
  () => import('src/views/collection/ShippingLabelPdfPage'),
)
const LazyCollectionSummaryReportPage = React.lazy(
  () =>
    import(
      'src/views/i/collection/CollectionSummaryPage/CollectionSummaryReportPage'
    ),
)
const LazySignupResponseReportPage = React.lazy(
  () => import('src/views/collection/SignupResponseReportPage'),
)
const LazyTicketItemReportPage = React.lazy(
  () => import('src/views/collection/TicketItemReportPage'),
)
const LazyTicketItemsReportPage = React.lazy(
  () => import('src/views/collection/TicketItemsReportPage'),
)

export const PdfRoutes: React.FC = () => (
  <Routes>
    <Route path="collection/:collection">
      <Route path="orders-report" element={<LazyOrdersReportPage />} />
      <Route
        path="summary-report"
        element={<LazyCollectionSummaryReportPage />}
      />
      <Route
        path="tab-payment/:payment"
        element={<LazyOrderSummaryReportPage />}
      />
      <Route
        path="tab-payment/:payment/payment-item/:paymentItem"
        element={<LazyPaymentItemReportPage />}
      />
      <Route
        path="tab-payment/:payment/shipping-label"
        element={<LazyShippingLabelPdfPage />}
      />
      <Route
        path=":tabObjectType/:tabObjectId/field-views-report"
        element={<LazyFieldViewsReportPage />}
      />
      <Route
        path=":tabObjectType/field-views-report"
        element={<LazyFieldViewsReportPage />}
      />
      <Route
        path="tab-object/:tabObject/tab-member/:tabMember"
        element={<LazyTabObjectRespondentReportPage />}
      />
      <Route
        path="tab-object/:tabObject/field/:field"
        element={<LazyTabObjectFieldsReportPage />}
      />
      <Route
        path="tab-object/:tabObject/fields"
        element={<LazyTabObjectFieldsReportPage />}
      />
      <Route path="signup/:signup" element={<LazySignupResponseReportPage />} />
      <Route
        path="signup/:signup/report"
        element={<LazySignupResponseReportPage />}
      />
    </Route>

    <Route path="payments/:payment">
      <Route path="report" element={<LazyPaymentReportPage />} />
      <Route
        path="payment-item/:paymentItem"
        element={<LazyPaymentPaymentItemReportPage />}
      />
      <Route
        path="ticket-item/:ticketItem"
        element={<LazyTicketItemReportPage />}
      />
      <Route path="tickets" element={<LazyTicketItemsReportPage />} />
    </Route>

    <Route path="tickets/:ticketItem" element={<LazyTicketItemsReportPage />} />
  </Routes>
)
