import React, {useEffect, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {
  api,
  useUpdateManagerNotificationMutation,
  useUpdateUserMutation,
} from '@cheddarup/api-client'
import {AccountSettingsContentLayout} from './components/AccountSettingsContentLayouts'

const NotificationSettingsPage = () => {
  const {data: managerRoles} = api.auth.session.useQuery(undefined, {
    select: (session) => session.manager_roles,
  })
  const {data: disabledEmailNotifications} = api.auth.session.useSuspenseQuery(
    undefined,
    {
      select: (session) =>
        session.user.profile.uiClientFlags.disabledEmailNotifications,
    },
  )
  const updateUserMutation = useUpdateUserMutation()
  const updateManagerNotificationMutation =
    useUpdateManagerNotificationMutation()
  const [notificationSettings, setNotificationSettings] = useState({
    paymentFlag: !disabledEmailNotifications?.paymentsReceived,
    reminderFlag: !disabledEmailNotifications?.remindersToPay,
    marketingOptInFlag: !disabledEmailNotifications?.marketingOptIn,
  })

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.checked
    const flag = event.target.name
    setNotificationSettings({...notificationSettings, [flag]: value})
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    updateUserMutation.mutate({
      body: {
        profile: {
          uiClientFlags: {
            disabledEmailNotifications: {
              paymentsReceived: !notificationSettings.paymentFlag,
              remindersToPay: !notificationSettings.reminderFlag,
              marketingOptIn: !notificationSettings.marketingOptInFlag,
            },
          },
        },
      },
    })
  }, [notificationSettings])

  return (
    <AccountSettingsContentLayout
      heading="Notifications"
      body="The emails below are sent to you by default. Toggling off notifications will disable those emails across all of your collections."
    >
      <WebUI.VStack className="gap-4">
        <NotificationSettingsSwitch
          name="paymentFlag"
          checked={notificationSettings.paymentFlag}
          label="Payment Notifications (Personal Account)"
          description="Receive an email each time someone submits a payment and/or form."
          onChange={onChange}
        />
        {managerRoles?.map((managerRole) => (
          <NotificationSettingsSwitch
            key={managerRole.id}
            label={`Payment Notifications (${managerRole.name})`}
            description="Receive an email each time someone submits a payment and/or form."
            checked={!!managerRole.permissions?.notifications?.payments}
            onChange={(e) => {
              updateManagerNotificationMutation.mutate({
                pathParams: {
                  managerId: managerRole.managerRoleId,
                },
                body: {
                  notifications: {
                    payments: e.target.checked,
                    reminders: false,
                  },
                },
              })
            }}
          />
        ))}
        <NotificationSettingsSwitch
          name="reminderFlag"
          label="Reminders to Pay"
          description="Receive an email if someone sends you a reminder to pay on a collection."
          checked={notificationSettings.reminderFlag}
          onChange={onChange}
        />
        <NotificationSettingsSwitch
          name="marketingOptInFlag"
          label="Subscribe to Marketing Emails"
          description="Keep me updated with exclusive tips, clever ideas, and inspiring stories to make collecting money a breeze. (No spam, we promise!)"
          checked={notificationSettings.marketingOptInFlag}
          onChange={onChange}
        />
      </WebUI.VStack>
    </AccountSettingsContentLayout>
  )
}

interface NotificationSettingsSwitchProps extends WebUI.SwitchProps {
  label: string
  description: string
}

const NotificationSettingsSwitch: React.FC<NotificationSettingsSwitchProps> = ({
  label,
  description,
  size = 'compact',
  className,
  ...restProps
}) => (
  <WebUI.VStack as={WebUI.Panel} className="p-6">
    <WebUI.Switch
      className={WebUI.cn('items-baseline gap-3', className)}
      size={size}
      {...restProps}
    >
      <WebUI.VStack className="gap-2 text-ds-sm">
        <WebUI.Text>{label}</WebUI.Text>
        <WebUI.Text className="font-light">{description}</WebUI.Text>
      </WebUI.VStack>
    </WebUI.Switch>
  </WebUI.VStack>
)

export default NotificationSettingsPage
