import {forwardRef} from 'react'
import {
  Separator as ReakitSeparator,
  SeparatorOptions as ReakitSeparatorOptions,
} from 'reakit'
import {ForwardRefComponent} from '@cheddarup/react-util'
import {cva} from 'class-variance-authority'

import {VariantsProps, cn} from '../utils'

export const separator = cva(
  [
    'self-stretch p-0',
    `aria-orientation-horizontal:max-h-0 aria-orientation-horizontal:w-auto
    aria-orientation-horizontal:border-b`,
    `aria-orientation-vertical:h-auto aria-orientation-vertical:max-w-0
    aria-orientation-vertical:border-r`,
  ],
  {
    variants: {
      variant: {
        dark: 'border-natural-80',
        light: 'border-natural-95',
        black: 'border-gray400',
        // TODO: `primary` variant is used as PanelSeparator only,
        // add it to Panel.js and use from there
        primary: 'border-natural-70',
        input: 'border-natural-70',
      },
    },
    defaultVariants: {
      variant: 'dark',
    },
  },
)

export interface SeparatorProps
  extends ReakitSeparatorOptions,
    VariantsProps<typeof separator> {}

export const Separator = forwardRef(
  ({className, variant = 'dark', ...restProps}, forwardedRef) => (
    <ReakitSeparator
      ref={forwardedRef}
      className={cn('Separator', separator({variant}), className)}
      {...restProps}
    />
  ),
) as ForwardRefComponent<'hr', SeparatorProps>
