import * as WebUI from '@cheddarup/web-ui'
import {useLiveRef} from '@cheddarup/react-util'
import React, {useCallback} from 'react'
import {
  UseQueryResult,
  useDeleteGroupPageCategoryMutation,
  useUpdateTabMutation,
} from '@cheddarup/api-client'
import {CollectionListItemImage} from 'src/components'
import {QueryDataSectionList} from 'src/components/QueryDataList'
import {formatTabStatus} from '@cheddarup/core'

export interface GroupCollectionListProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'children'> {
  query: UseQueryResult<
    Array<WebUI.SectionListData<Api.GroupPageCategory, Api.GroupTab>>
  >
  hiddenRowsData?: Api.GroupTab[]
  onEditCategory: (category: Api.GroupPageCategory) => void
}

export const GroupCollectionList = ({
  onEditCategory,
  className,
  ...restProps
}: GroupCollectionListProps) => {
  const deleteGroupPageCategoryMutation = useDeleteGroupPageCategoryMutation()

  const onEditCategoryRef = useLiveRef(onEditCategory)
  const GroupCollectionListCategoryWithHandlers: WebUI.DataSectionComponentType<Api.GroupPageCategory> =
    useCallback(
      (sectionProps) => (
        <GroupCollectionListCategory
          onEdit={() => {
            if (sectionProps.section) {
              onEditCategoryRef.current(sectionProps.section.original)
            }
          }}
          onRemove={() => {
            if (sectionProps.section) {
              deleteGroupPageCategoryMutation.mutate({
                pathParams: {
                  id: Number(sectionProps.section.id),
                },
              })
            }
          }}
          {...sectionProps}
        />
      ),
      [deleteGroupPageCategoryMutation.mutate],
    )

  return (
    <QueryDataSectionList
      className={WebUI.cn(
        'gap-4 overflow-y-visible [&_.SectionContainer]:gap-2',
        className,
      )}
      estimateRowSize={76}
      SectionListComponent={WebUI.SortableSectionList}
      noDndContext
      virtualized={false}
      sortingStrategy={WebUI.rectSortingStrategy}
      EmptyStateSectionViewComponent={GroupCollectionListEmptyStateSectionView}
      DataSectionComponent={GroupCollectionListCategoryWithHandlers}
      DataRowComponent={GroupCollectionListRow}
      {...restProps}
    />
  )
}

// MARK: – GroupCollectionListCategory

interface GroupCollectionListCategoryProps
  extends WebUI.DataSectionComponentProps<Api.GroupPageCategory> {
  onEdit?: () => void
  onRemove?: () => void
}

const GroupCollectionListCategory = ({
  onEdit,
  onRemove,
  section,
  setSelected,
  children,
  className,
  ...restProps
}: GroupCollectionListCategoryProps) => (
  <WebUI.Card
    className={WebUI.cn('grow py-5', className)}
    id={section ? String(section.id) : undefined}
    dragHandleVisible={!!section}
    accessoryView={
      section &&
      section.original.name !== 'default' && (
        <WebUI.ActionGroup>
          <WebUI.Action
            icon={<WebUI.PhosphorIcon icon="pencil" />}
            onClick={onEdit}
          >
            Edit category
          </WebUI.Action>
          <WebUI.Action
            icon={<WebUI.PhosphorIcon icon="trash" />}
            execute={onRemove}
          >
            Delete category
          </WebUI.Action>
        </WebUI.ActionGroup>
      )
    }
    {...restProps}
  >
    {section?.original.name !== 'default' && (
      <WebUI.Ellipsis className="ml-10 text-ds-md">
        {section?.original.name ?? ''}
      </WebUI.Ellipsis>
    )}

    <div className="px-10 py-4 [&_>_.SectionContainer]:flex [&_>_.SectionContainer]:flex-row [&_>_.SectionContainer]:flex-wrap [&_>_.SectionContainer]:items-center [&_>_.SectionContainer]:gap-5">
      {children}
    </div>
  </WebUI.Card>
)

// MARK: – GroupCollectionListEmptyStateSectionView

const GroupCollectionListEmptyStateSectionView = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => (
  <div
    className={WebUI.cn(
      'flex h-[120px] flex-col items-center justify-center',
      className,
    )}
    {...restProps}
  >
    <WebUI.Text className="max-w-[300px] text-center font-light text-ds-base text-gray400">
      Drag and drop collections to display on your group page here
    </WebUI.Text>
  </div>
)

// MARK: – GroupCollectionListRow

const GroupCollectionListRow: WebUI.DataRowComponentType<Api.GroupTab> =
  React.forwardRef(
    ({row, setSelected, className, ...restProps}, forwardedRef) => {
      const updateTabMutation = useUpdateTabMutation()
      return (
        <WebUI.Card
          ref={forwardedRef as any}
          className={WebUI.cn('flex w-[226px] flex-col gap-3 p-5', className)}
          dragHandleVisible
          accessoryView={
            <WebUI.ActionGroup>
              <WebUI.Action
                icon={<WebUI.PhosphorIcon icon="trash" />}
                execute={async () => {
                  if (row) {
                    await updateTabMutation.mutateAsync({
                      pathParams: {tabId: row.original.id},
                      body: {collection_section_category_id: null},
                    })
                  }
                }}
              >
                Delete
              </WebUI.Action>
            </WebUI.ActionGroup>
          }
          {...restProps}
        >
          <CollectionListItemImage
            width={180}
            height={96}
            image={row?.original.featured_image}
          />
          <WebUI.VStack className="gap-2 overflow-hidden">
            <WebUI.Ellipsis>{row?.original.name}</WebUI.Ellipsis>
            {!!row && (
              <WebUI.Text className="text-ds-sm">
                {formatTabStatus(row.original)}
              </WebUI.Text>
            )}
          </WebUI.VStack>
        </WebUI.Card>
      )
    },
  )
