import {QueryUpdate, makeQueryUpdate, makeUseMutation} from '../use-mutation'
import {endpoints} from '../../endpoints'
import {getEndpointKey} from '../../utils'
import {makeQueryPredicate} from '../query-utils'

export const useAddObjectsFromCatalogMutation = makeUseMutation(
  endpoints.itemCatalogs.add,
  undefined,
  (queryClient) => ({
    onSuccess: (_newTab, vars) => {
      const tabDetailQueryKey = getEndpointKey(endpoints.tabs.detail, vars)
      queryClient.invalidateQueries({queryKey: tabDetailQueryKey})

      if (vars.body?.catalog.items) {
        const categoryListQueryKey = getEndpointKey(
          endpoints.tabCategories.list,
          vars,
        )
        const itemListQueryKey = getEndpointKey(endpoints.tabItems.list, vars)

        queryClient.invalidateQueries({
          predicate: makeQueryPredicate(categoryListQueryKey),
        })
        queryClient.invalidateQueries({
          predicate: makeQueryPredicate(itemListQueryKey),
        })
      }

      if (vars.body?.catalog.forms) {
        const formListQueryKey = getEndpointKey(endpoints.tabForms.list, vars)
        queryClient.invalidateQueries({
          predicate: makeQueryPredicate(formListQueryKey),
        })
      }
    },
  }),
)

export const useSortCollectionObjectsMutation = makeUseMutation(
  endpoints.tabs.sortObjects,
  (vars, queryClient) => {
    let optimisticUpdates: QueryUpdate[] = []

    switch (vars.body?.type) {
      case 'categories':
        optimisticUpdates = [
          makeQueryUpdate(
            endpoints.tabCategories.list,
            (prevCategories) =>
              prevCategories
                ? vars.body?.order
                    .map((categoryId) =>
                      prevCategories.find((c) => c.id === categoryId),
                    )
                    .filter((c) => c != null)
                : prevCategories,
            vars,
          ),
        ]
        break
      case 'items':
        optimisticUpdates = [
          makeQueryUpdate(
            endpoints.tabItems.list,
            (prevItems) => {
              const categoryListQueryKey = getEndpointKey(
                endpoints.tabCategories.list,
                vars,
              )
              const itemCategories =
                queryClient.getQueryData<Api.Category[]>(categoryListQueryKey)
              const category = itemCategories?.find(
                (iC) => iC.id === vars.body?.parent_id,
              )

              return prevItems
                ? [
                    ...prevItems.filter(
                      (i) => !vars.body?.order.includes(i.id),
                    ),
                    ...(vars.body?.order ?? [])
                      .map((iId, idx) => {
                        const item = prevItems.find((i) => i.id === iId)
                        return item
                          ? {
                              ...item,
                              position: idx,
                              parent_id: vars.body?.parent_id ?? null,
                              category,
                            }
                          : null
                      })
                      .filter((i) => !!i),
                  ]
                : prevItems
            },
            vars,
          ),
          makeQueryUpdate(
            endpoints.tabCategories.list,
            (prevCategories) => {
              const itemListQueryKey = getEndpointKey(
                endpoints.tabItems.list,
                vars,
              )
              const items =
                queryClient.getQueryData<Api.TabItem[]>(itemListQueryKey) ?? []

              return prevCategories?.map((cat) => ({
                ...cat,
                items:
                  vars.body?.parent_id === cat.id
                    ? vars.body.order
                        .map((iId) => items.find((i) => i.id === iId))
                        .filter((i) => i != null)
                        .map((i, idx) => ({
                          ...i,
                          position: idx,
                          parent_id: vars.body?.parent_id,
                          category: cat,
                        }))
                    : (cat.items?.filter((i) =>
                        vars.body?.order.includes(i.id),
                      ) ?? []),
              }))
            },
            vars,
          ),
        ]
        break
      case 'forms':
        optimisticUpdates = [
          makeQueryUpdate(
            endpoints.tabForms.list,
            (prevForms) =>
              prevForms
                ? (vars.body?.order ?? [])
                    .map((fId) => prevForms.find((f) => f.id === fId))
                    .filter((f) => !!f)
                : prevForms,
            vars,
          ),
        ]
        break
      case 'signups':
        optimisticUpdates = [
          makeQueryUpdate(
            endpoints.tabSignups.list,
            (prevSignups) =>
              prevSignups
                ? (vars.body?.order ?? [])
                    .map((sId) => prevSignups.find((s) => s.id === sId))
                    .filter((s) => s != null)
                : prevSignups,
            vars,
          ),
        ]
        break
    }

    return {
      optimistic: optimisticUpdates,
    }
  },
)
