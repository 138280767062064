export * from '@dnd-kit/core'
export * from '@dnd-kit/sortable'

import React, {
  useContext,
  useId,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  AnimateLayoutChanges,
  SortableContext as SortableContextPrimitive,
  SortableContextProps as SortableContextPrimitiveProps,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import {CSS, getEventCoordinates} from '@dnd-kit/utilities'
import {
  Activators,
  DndContext,
  DndContextDescriptor,
  DndContextProps,
  ClientRect as DndKitClientRect,
  DragOverlay,
  DraggableSyntheticListeners,
  KeyboardSensor,
  KeyboardSensorOptions,
  Modifier,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useDndContext,
  useDndMonitor,
  useDraggable,
  useDroppable,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {Merge, unique} from '@cheddarup/util'
import {
  ForwardRefComponent,
  useForkRef,
  useLiveRef,
  useUpdateEffect,
} from '@cheddarup/react-util'

import {Portal, getPortalElement} from './Portal'
import {cn} from '../utils'

interface DragAndDropInternalContextValue {
  isDragging: boolean
  isDroppable?: (
    activeData: Record<string, any>,
    overData: Record<string, any>,
  ) => boolean
}

const DragAndDropInternalContext = React.createContext(
  {} as DragAndDropInternalContextValue,
)

// MARK: – DragAndDrop

export interface DragAndDropInstance extends DragAndDropInnerInstance {}

export interface DragAndDropProps
  extends DndContextProps,
    DragAndDropInnerProps {
  touchEnabled?: boolean
  keyboardEnabled?: boolean
}

export const DragAndDrop = React.forwardRef<
  DragAndDropInstance,
  DragAndDropProps
>(
  (
    {
      dragOverlayClassName,
      dragOverlayPortal,
      isDroppable,
      touchEnabled = false,
      keyboardEnabled = false,
      onDragEnd,
      children,
      ...restProps
    },
    forwardedRef,
  ) => {
    const sensors = useSensors(
      useSensor(SmartMouseSensor, {
        activationConstraint: {
          distance: 50,
        },
      }),
      keyboardEnabled
        ? useSensor(SmartKeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
          })
        : undefined,
      touchEnabled
        ? useSensor(TouchSensor, {
            activationConstraint: {
              delay: 200,
              tolerance: 5,
            },
          })
        : undefined,
    )

    return (
      <DndContext
        sensors={sensors}
        onDragEnd={(event) =>
          onDragEnd?.({
            ...event,
            over:
              event.active.data.current &&
              event.over?.data.current &&
              isDroppable?.(
                event.active.data.current,
                event.over.data.current,
              ) === false
                ? null
                : event.over,
          })
        }
        {...restProps}
      >
        <DragAndDropInner
          ref={forwardedRef}
          dragOverlayClassName={dragOverlayClassName}
          dragOverlayPortal={dragOverlayPortal}
          isDroppable={isDroppable}
        >
          {children}
        </DragAndDropInner>
      </DndContext>
    )
  },
)

// MARK: – DragAndDropInner

interface DragAndDropInnerInstance {
  dndContext: DndContextDescriptor
}

interface DragAndDropInnerProps {
  dragOverlayClassName?: string
  dragOverlayPortal?: boolean
  isDroppable?: DragAndDropInternalContextValue['isDroppable']
  children?: React.ReactNode
}

const DragAndDropInner = React.forwardRef<
  DragAndDropInnerInstance,
  DragAndDropInnerProps
>(
  (
    {dragOverlayPortal = true, dragOverlayClassName, isDroppable, children},
    forwardedRef,
  ) => {
    const dndContext = useDndContext()
    const isDroppableRef = useLiveRef(isDroppable)
    const dragOverlayElementRef = useRef<HTMLDivElement>(null)

    const dropAnimDuration = 150

    useImperativeHandle(forwardedRef, () => ({dndContext}), [dndContext])

    useUpdateEffect(() => {
      const activeNode = dndContext.activeNode
      if (activeNode) {
        requestAnimationFrame(() => {
          const clonedActiveNode = activeNode.cloneNode(true) as any
          // When switching between sortable containers, transform may
          // break DragOverlay's layout
          clonedActiveNode.style.transform = 'none'
          clonedActiveNode.classList.add(dragOverlayClassName)
          if (dragOverlayElementRef.current) {
            dragOverlayElementRef.current.replaceChildren(clonedActiveNode)
          }
        })
      } else {
        setTimeout(() => {
          if (dragOverlayElementRef.current) {
            dragOverlayElementRef.current.innerHTML = ''
          }
        }, dropAnimDuration)
      }
    }, [dndContext.activeNode, dragOverlayClassName])

    const isDragging = !!dndContext.active

    const dragAndDrop = useMemo(
      () => ({
        isDragging,
        isDroppable: isDroppableRef.current,
      }),
      [isDragging],
    )

    const dragOverlay = (
      <DragOverlay
        className={cn('DragOverlay', 'opacity-75')}
        dropAnimation={
          'animate' in document.documentElement
            ? {duration: dropAnimDuration, easing: 'ease'}
            : null
        }
        adjustScale={false}
        zIndex={0}
      >
        <div ref={dragOverlayElementRef} />
      </DragOverlay>
    )

    return (
      <DragAndDropInternalContext.Provider value={dragAndDrop}>
        {children}
        {dragOverlayPortal ? (
          <Portal portalElement={getPortalElement}>{dragOverlay}</Portal>
        ) : (
          dragOverlay
        )}
      </DragAndDropInternalContext.Provider>
    )
  },
)

// MARK: – Droppable

export interface DroppableProps {
  disabled?: boolean
  dropData?: any
}

export const Droppable = React.forwardRef(
  (
    {
      as: Comp = 'div',
      disabled = false,
      dropData,
      id: idProp,
      className,
      ...restProps
    },
    forwardedRef,
  ) => {
    const dragAndDrop = useContext(DragAndDropInternalContext)
    const id = idProp ?? useId()
    const {setNodeRef, active, over, isOver} = useDroppable({
      id,
      data: dropData,
      disabled,
    })
    return (
      <Comp
        ref={useForkRef(setNodeRef, forwardedRef)}
        data-targeted={
          isOver &&
          active?.data.current &&
          over?.data.current &&
          dragAndDrop.isDroppable?.(active.data.current, over.data.current)
        }
        className={cn(
          'Droppable',
          'data-[targeted=true]:shadow-[inset_0_0_0_1px_theme(colors.teal.50)]',
          className,
        )}
        {...restProps}
      />
    )
  },
) as ForwardRefComponent<'div', DroppableProps>

// MARK: – Draggable

export interface DraggableProps {
  dragDisabled?: boolean
  dragData?: any
}

export const Draggable = React.forwardRef(
  (
    {
      as: Comp = 'div',
      dragDisabled,
      dragData,
      id: idProp,
      className,
      ...restProps
    },
    forwardedRef,
  ) => {
    const id = idProp ?? useId()
    const {setNodeRef, listeners, attributes} = useDraggable({
      id,
      data: dragData,
      disabled: dragDisabled,
    })
    return (
      <Comp
        ref={useForkRef(setNodeRef, forwardedRef)}
        className={cn('Draggable cursor-grab', className)}
        {...listeners}
        {...attributes}
        {...restProps}
      />
    )
  },
) as ForwardRefComponent<'div', DraggableProps>

// MARK: – SortableContext

export interface SortableContextProps
  extends Merge<
    SortableContextPrimitiveProps,
    {
      id?: UniqueIdentifier
      onItemsChange?: (items: UniqueIdentifier[]) => void
      children:
        | React.ReactNode
        | ((options: {items: UniqueIdentifier[]}) => React.ReactNode)
    }
  > {}

export const SortableContext = ({
  items: itemsProp,
  strategy = verticalListSortingStrategy,
  id: idProp,
  onItemsChange,
  children,
  disabled,
  ...restProps
}: SortableContextProps) => {
  const id = String(idProp ?? useId())

  const normalizedItemsProp = useMemo(
    () => unique(itemsProp.map((i) => (typeof i === 'object' ? i.id : i))),
    [itemsProp],
  )
  const [items, setItems] = useState(normalizedItemsProp)
  const itemsUpdatedRef = useRef(false)

  useUpdateEffect(() => {
    setItems(normalizedItemsProp)
  }, [normalizedItemsProp])

  const updateItems: React.Dispatch<
    React.SetStateAction<UniqueIdentifier[]>
  > = (action) => {
    setItems((prevItems) => {
      const newItems = typeof action === 'function' ? action(prevItems) : action
      onItemsChange?.(newItems)

      return newItems
    })
  }

  useDndMonitor({
    onDragOver: (event) => {
      if (
        disabled ||
        Object.keys(event.active.data.current ?? {}).length === 0
      ) {
        return
      }
      if (!event.over) {
        updateItems(normalizedItemsProp)
        return
      }

      const overContainerId = event.over?.data.current?.sortable?.containerId
      const activeContainerId = event.active.data.current?.sortable?.containerId

      if (
        event.active.data.current?.$type !== 'container' &&
        event.over?.data.current?.$type !== 'container' &&
        activeContainerId !== overContainerId
      ) {
        if (activeContainerId === id) {
          itemsUpdatedRef.current = true
          updateItems((prevItems) =>
            prevItems.filter((i) => i !== event.active.id),
          )
        } else if (overContainerId === id) {
          itemsUpdatedRef.current = true
          updateItems((_prevItems) => {
            if (!event.over) {
              return _prevItems
            }

            const overIdIdx = _prevItems.indexOf(event.over.id)

            if (overIdIdx === -1) {
              return [event.active.id]
            }

            const prevItems = _prevItems.filter((i) => i !== event.active.id)

            return [
              ...prevItems.slice(0, overIdIdx),
              event.active.id,
              ...prevItems.slice(overIdIdx),
            ]
          })
        }
      } else {
        itemsUpdatedRef.current = false
      }
    },
    onDragCancel: () => updateItems(normalizedItemsProp),
  })

  return (
    <SortableContextPrimitive
      id={id}
      strategy={strategy}
      items={items}
      disabled={disabled}
      {...restProps}
    >
      {typeof children === 'function'
        ? children({
            items: itemsUpdatedRef.current ? items : normalizedItemsProp,
          })
        : children}
    </SortableContextPrimitive>
  )
}

// MARK: – Sortable

export interface SortableProps {
  id?: UniqueIdentifier
  disabled?: boolean
  data?: any
  animateLayoutChanges?: AnimateLayoutChanges
  children?:
    | React.ReactNode
    | ((options: {
        dragListeners: DraggableSyntheticListeners
      }) => React.ReactNode)
}

export const Sortable = React.forwardRef(
  (
    {
      disabled = false,
      draggable = true,
      data,
      animateLayoutChanges = () => false,
      as: Comp = 'div',
      id: idProp,
      className,
      style,
      children,
      ...restProps
    },
    forwardedRef,
  ) => {
    const id = idProp ?? useId()
    const {
      setNodeRef,
      attributes,
      listeners: dragListeners,
      transform,
      transition,
    } = useSortable({id, animateLayoutChanges, disabled, data})
    const dragListenersRef = useLiveRef(dragListeners)

    const childrenOptions = useMemo(
      () => ({
        dragListeners: dragListenersRef.current,
      }),
      [],
    )

    return (
      <Comp
        ref={useForkRef(setNodeRef, forwardedRef)}
        id={String(id)}
        className={cn('Sortable touch-manipulation select-none', className)}
        style={{
          ...style,
          transform: CSS.Translate.toString(transform),
          transition,
        }}
        {...attributes}
        {...(draggable && dragListenersRef.current)}
        {...restProps}
      >
        {typeof children === 'function' ? children(childrenOptions) : children}
      </Comp>
    )
  },
) as ForwardRefComponent<'div', SortableProps>

// MARK: – SortableContainer

export interface SortableContainerProps {
  id?: UniqueIdentifier
  disabled?: boolean
  draggable?: boolean
  data?: any
  animateLayoutChanges?: AnimateLayoutChanges
  children:
    | React.ReactNode
    | ((options: {
        dragListeners: DraggableSyntheticListeners
      }) => React.ReactNode)
}

export const SortableContainer = React.forwardRef(
  (
    {
      disabled = false,
      draggable = true,
      data,
      animateLayoutChanges = () => false,
      as: Comp = 'div',
      id: idProp,
      className,
      style,
      children,
      ...restProps
    },
    forwardedRef,
  ) => {
    const id = idProp ?? useId()
    const {
      active,
      setNodeRef,
      over,
      attributes,
      listeners: dragListeners,
      transform,
      transition,
      rect,
    } = useSortable({
      id,
      animateLayoutChanges,
      disabled,
      data: {$type: 'container', ...data},
    })
    const dragListenersRef = useLiveRef(dragListeners)

    const childrenOptions = useMemo(
      () => ({
        dragListeners: dragListenersRef.current,
      }),
      [],
    )

    const isItemOverContainer =
      active?.data.current?.$type !== 'container' &&
      over &&
      rect.current &&
      isIntersection(rect.current, over.rect)

    return (
      <Comp
        ref={useForkRef(setNodeRef, forwardedRef)}
        data-targeted={isItemOverContainer}
        id={String(id)}
        className={cn(
          'SortableContainer data-[targeted=true]:shadow-[inset_0_0_0_1px_theme(colors.teal.50)]',
          className,
        )}
        style={{
          ...style,
          transform: CSS.Transform.toString(transform),
          transition,
        }}
        {...attributes}
        {...(draggable && dragListenersRef.current)}
        {...restProps}
      >
        {typeof children === 'function' ? children(childrenOptions) : children}
      </Comp>
    )
  },
) as ForwardRefComponent<'div', SortableContainerProps>

// MARK: – Helpers

export const useIsDragging = () =>
  useContext(DragAndDropInternalContext).isDragging

export {arrayMove}

export const arrayMoveByValue = <T,>(
  arr: T[],
  fromValue: T,
  toValue: T,
): T[] => {
  const fromIdx = arr.indexOf(fromValue)
  const toIdx = arr.indexOf(toValue)
  return arrayMove(arr, fromIdx, toIdx)
}

const isIntersection = (entry: DndKitClientRect, target: DndKitClientRect) => {
  const top = Math.max(target.top, entry.top)
  const left = Math.max(target.left, entry.left)
  const right = Math.min(target.left + target.width, entry.left + entry.width)
  const bottom = Math.min(target.top + target.height, entry.top + entry.height)

  return left < right && top < bottom
}

/**
 * Based on https://github.com/clauderic/dnd-kit/issues/477#issuecomment-985194908
 * An extended "PointerSensor" that prevent some
 * interactive html element(button, input, textarea, select, option...) from dragging
 */
class SmartMouseSensor extends MouseSensor {
  static activators = [
    {
      eventName: 'onMouseDown' as const,
      handler: ({nativeEvent: event}: React.MouseEvent) =>
        shouldHandleEvent(event.target as HTMLElement),
    },
  ]
}

class SmartKeyboardSensor extends KeyboardSensor {
  static activators: Activators<KeyboardSensorOptions> = [
    {
      eventName: 'onKeyDown',
      handler: (event, ...rest) => {
        if (!shouldHandleEvent(event.nativeEvent.target as HTMLElement)) {
          return false
        }
        const defaultHandler = super.activators.find(
          (a) => a.eventName === 'onKeyDown',
        )?.handler

        return defaultHandler?.(event, ...rest) ?? false
      },
    },
  ]
}

function shouldHandleEvent(element: HTMLElement | null) {
  let cur = element

  while (cur) {
    if (cur.dataset.noDnd === 'true') {
      return false
    }
    cur = cur.parentElement
  }

  return true
}

/** https://github.com/clauderic/dnd-kit/issues/885#issuecomment-1236765956 */
export const followMouseModifier: Modifier = ({
  activatorEvent,
  draggingNodeRect,
  transform,
}) => {
  if (draggingNodeRect && activatorEvent) {
    const activatorCoordinates = getEventCoordinates(activatorEvent)

    if (!activatorCoordinates) {
      return transform
    }

    const padding = 12
    const offsetX = activatorCoordinates.x - draggingNodeRect.left
    const offsetY = activatorCoordinates.y - draggingNodeRect.top

    return {
      ...transform,
      x: transform.x + offsetX - padding,
      y: transform.y + offsetY - padding,
    }
  }

  return transform
}
