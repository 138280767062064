import * as Sentry from '@sentry/react'
import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {AuthLayout} from 'src/components/AuthLayout'

export const SentryErrorBoundary = ({
  fallback = <ErrorFallback />,
  ...restProps
}: Sentry.ErrorBoundaryProps) => (
  <Sentry.ErrorBoundary fallback={fallback} {...restProps} />
)

// MARK: – ErrorFallback

export const ErrorFallback = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => (
  <AuthLayout className={WebUI.cn('h-screen', className)} {...restProps}>
    <WebUI.VStack className="items-center gap-5 p-10">
      <WebUI.TesselateSpinner />
      <WebUI.VStack className="gap-1">
        <WebUI.Heading className="text-center">
          Oh no, something went wrong
        </WebUI.Heading>

        <WebUI.Text className="text-center font-light text-ds-md">
          The link you followed is broken and has been reported.
          <br />
          We're working to resolve it as quickly as possible.
        </WebUI.Text>
      </WebUI.VStack>
      <WebUI.VStack className="gap-5">
        <WebUI.Button
          className="hover:!text-natural-100 h-[3.125rem] self-center px-8 text-ds-md"
          onClick={() => window.zE?.('webWidget', 'open')}
          variant="primary"
        >
          Chat with us, 9-5 (MT)
        </WebUI.Button>
        <WebUI.Button
          className="h-[3.125rem] w-full self-center px-8 !hover:text-natural-100 text-ds-md"
          variant="default"
          as={WebUI.Anchor}
          href="https://my.cheddarup.com/contact"
          rel="noopener noreferrer"
          target="_blank"
        >
          Send us an email
        </WebUI.Button>
      </WebUI.VStack>
    </WebUI.VStack>
  </AuthLayout>
)
