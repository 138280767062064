import * as Util from '@cheddarup/util'
import {useNavigate} from 'react-router-dom'
import {Waypoint} from 'react-waypoint'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'

import {
  MarketplaceItemNoResultsFoundView,
  MarketplaceItemsGallery,
  MarketplaceSectionTitle,
} from '../../components'

export interface MarketplaceItemsGalleryContainerProps {
  searchKeyword: string
  selectedCatalogIds: number[]
  sortBy: {sort: string; direction: 'asc' | 'desc'}
  onClearFilters: () => void
}

const MarketplaceItemsGalleryContainer = ({
  searchKeyword,
  selectedCatalogIds,
  sortBy,
  onClearFilters,
}: MarketplaceItemsGalleryContainerProps) => {
  const navigate = useNavigate()
  const {data: session} = api.auth.session.useQuery()
  const {data, fetchNextPage, hasNextPage, isFetchingNextPage} =
    api.marketplace.items.useInfiniteQuery(
      {
        queryParams: Util.pickBy(
          {
            per_page: 20,
            search: searchKeyword,
            catalogIds: selectedCatalogIds,
            sort: sortBy.sort,
            direction: sortBy.direction,
          },
          (value) => (Array.isArray(value) ? value.length > 0 : !!value),
        ),
      },
      {
        initialPageParam: 1,
        enabled: !!session?.organization_data,
        getNextPageParam: ({pagination}) => {
          const totalFetched =
            (pagination.page - 1) * pagination.perPage + pagination.count

          if (totalFetched < pagination.total) {
            return pagination.page + 1
          }
          return undefined
        },
      },
    )

  return (
    <>
      <MarketplaceSectionTitle>Shop Items</MarketplaceSectionTitle>
      {(data?.pages[0]?.data.length ?? 1) > 0 ? (
        data ? (
          <>
            <MarketplaceItemsGallery
              className="mt-4"
              items={data?.pages.flatMap((p) => p.data) ?? []}
            />
            {hasNextPage && (
              <Waypoint onEnter={() => fetchNextPage()}>
                {isFetchingNextPage && (
                  <div className="flex w-full justify-center py-8">
                    <WebUI.Loader size="3em" />
                  </div>
                )}
              </Waypoint>
            )}
          </>
        ) : (
          <div
            className={
              'grid grid-cols-2 gap-x-3 gap-y-3 sm:grid-cols-[repeat(2,1fr)] lg:grid-cols-3 xl:grid-cols-4'
            }
          >
            <MarketplaceItemsSkeleton />
            <MarketplaceItemsSkeleton />
            <MarketplaceItemsSkeleton />
            <MarketplaceItemsSkeleton />
            <MarketplaceItemsSkeleton />
            <MarketplaceItemsSkeleton />
          </div>
        )
      ) : (
        <MarketplaceItemNoResultsFoundView
          onClearSearch={() => {
            onClearFilters?.()
            navigate('/marketplace/shop-items')
          }}
        />
      )}
    </>
  )
}

// MARK: MarkeplaceItemsSkeleton

const MarketplaceItemsSkeleton = () => (
  <div>
    <WebUI.Skeleton height={200} />
    <WebUI.Skeleton className="mt-[10px]" />
    <WebUI.Skeleton width={100} />
  </div>
)

export default MarketplaceItemsGalleryContainer
