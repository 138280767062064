import React from 'react'

import {ModalRoutes} from '../routes/ModalRoutes'

export const PageContainer = ({children}: {children: React.ReactNode}) => (
  <>
    {children}
    <ModalRoutes />
  </>
)
