import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'
import {useNavigate, useParams} from 'react-router-dom'
import React from 'react'
import {api} from '@cheddarup/api-client'
import {LinkButton} from 'src/components/LinkButton'
import {Link} from 'src/components/Link'
import {SharpImage} from 'src/components/SharpImage'

const ItemCatalogsPage = () => {
  const navigate = useNavigate()
  const urlParams = useParams()
  const sellerId = Number(urlParams.seller)
  const {data: session} = api.auth.session.useQuery()
  const itemCatalogsQuery = api.itemCatalogs.list.useQuery(undefined, {
    select: (catalogs) =>
      Util.sort(catalogs.filter(({user}) => user.id === sellerId)).asc(
        (catalog) =>
          session?.organization_data?.catalogOrder.indexOf(catalog.id) ??
          Number.MAX_SAFE_INTEGER,
      ),
  })

  return (
    <WebUI.Modal
      aria-label="Item catalogs"
      className="[&_>_.ModalContentView]:h-full [&_>_.ModalContentView]:max-w-screen-lg"
      onDidHide={() => navigate('..')}
    >
      <WebUI.ModalCloseButton />

      <WebUI.ModalHeader>
        <WebUI.Heading as="h2">
          {itemCatalogsQuery.data?.find(({user}) => user.id === sellerId)?.user
            .name ?? ''}
        </WebUI.Heading>
        <span>Click on a library to add items</span>
      </WebUI.ModalHeader>

      <WebUI.VStack className="gap-4 overflow-y-auto p-4 sm:px-8">
        <LinkButton
          className="self-start"
          variant="outlined"
          iconBefore={<WebUI.PhosphorIcon icon="magnifying-glass" />}
          to="search"
        >
          Search
        </LinkButton>
        <WebUI.VStack className="gap-4">
          {itemCatalogsQuery.data?.map((catalog) => (
            <Link key={catalog.id} to={`catalog/${catalog.id}/items`}>
              <CatalogRow className="grow" catalog={catalog} />
            </Link>
          ))}
        </WebUI.VStack>
      </WebUI.VStack>
    </WebUI.Modal>
  )
}

// MARK: – CatalogRow

interface CatalogRowProps extends React.ComponentPropsWithoutRef<'div'> {
  catalog: Omit<Api.ItemCatalog, 'active_items'>
}

const CatalogRow = React.memo(
  ({catalog, className, ...restProps}: CatalogRowProps) => (
    <WebUI.HStack
      className={WebUI.cn(
        'h-18 items-center gap-8 overflow-hidden border pr-8',
        className,
      )}
      {...restProps}
    >
      <SharpImage alt="Catalog" width={180} height={88} image={catalog.image} />
      <span className="grow font-normal text-ds-base">{catalog.name}</span>
    </WebUI.HStack>
  ),
)

export default ItemCatalogsPage
