import {
  Button as ReakitButton,
  ButtonOptions as ReakitButtonOptions,
} from 'reakit'
import {ForwardRefComponent} from '@cheddarup/react-util'
import React from 'react'
import {cva} from 'class-variance-authority'

import {Ellipsis} from './Ellipsis'
import {HStack} from './Stack'
import {Loader} from './Loader'
import {VariantsProps, cn} from '../utils'

export const button = cva(
  [
    `relative inline-flex cursor-pointer select-none appearance-none items-center
    justify-center whitespace-nowrap border-none bg-transparent py-1 text-center
    font-body leading-compact no-underline`,
    'transition-colors duration-100 ease-in-out',
    'disabled:cursor-not-allowed disabled:opacity-50',
    'aria-disabled:cursor-not-allowed aria-disabled:opacity-50',
    'focus:outline-none',
    'focus:focus-visible:shadow-[inset_0_0_0_1px_theme(colors.teal.50)]',
    `aria-orientation-vertical:h-auto aria-orientation-vertical:flex-col
    aria-orientation-vertical:p-6 aria-orientation-vertical:px-0
    aria-orientation-vertical:font-semibold`,
    'aria-orientation-horizontal:flex-row',
  ],
  {
    variants: {
      variant: {
        default: [
          'bg-buttonDefaultBackground text-natural-100',
          [
            `active:bg-buttonDefaultBackground
            aria-haspopup:aria-expanded:bg-buttonDefaultBackground
            data-[active]:bg-buttonDefaultBackground`,
          ],
          'hover:bg-buttonDefaultBackground',
        ],
        primary: [
          'bg-orange-50 text-natural-100',
          [
            `active:bg-orange-50
            aria-haspopup:aria-expanded:bg-orange-50
            data-[active]:bg-orange-50`,
          ],
          'hover:bg-orange-50',
        ],
        secondary: [
          'bg-natural-80 text-buttonSecondaryText',
          [
            `data-[active]:-bg-natural-80 active:bg-natural-80
            aria-haspopup:aria-expanded:bg-natural-80`,
          ],
          'hover:bg-natural-80',
        ],
        secondaryAlt: [
          'bg-teal-70 text-buttonSecondaryText',
          [
            `data-[active]:-bg-teal-70 active:bg-teal-70
            aria-haspopup:aria-expanded:bg-teal-70`,
          ],
          'hover:bg-teal-70',
        ],
        ghost: [
          'bg-transparent text-buttonGhostText',
          [
            `data-[active]:-bg-buttonGhostActiveBackground
            active:bg-buttonGhostActiveBackground
            aria-haspopup:aria-expanded:bg-buttonGhostActiveBackground`,
          ],
          'hover:bg-buttonGhostHoverBackground',
        ],
        link: [
          'justify-start text-buttonLinkText [text-align:unset]',
          [
            `active:text-teal-80
            aria-haspopup:aria-expanded:text-teal-80
            data-[active]:text-teal-80`,
          ],
          'hover:text-linkPrimaryHoverText',
        ],
        outlined: [
          [
            `bg-natural-100 text-buttonGhostText
            shadow-[inset_0_0_0_1px_theme(colors.natural.70)]`,
          ],
          [
            `active:bg-buttonGhostActiveBackground
            aria-haspopup:aria-expanded:bg-buttonGhostActiveBackground
            data-[active]:bg-buttonGhostActiveBackground`,
          ],
          'hover:bg-buttonGhostHoverBackground',
        ],
        text: [
          'justify-start text-buttonGhostText [text-align:unset]',
          [
            `active:opacity-75 aria-haspopup:aria-expanded:opacity-75
            data-[active]:opacity-75`,
          ],
          'hover:opacity-75',
        ],
      },
      roundness: {
        default: 'rounded',
        capsule: 'rounded-[20px]',
      },
      size: {
        default: 'h-[2.25rem] px-5 text-ds-sm',
        compact: 'h-[1.875rem] px-4 text-ds-xs',
        large: 'h-[2.5rem] px-6 font-light text-ds-base',
        big: 'h-[3rem] px-8 font-light text-ds-md',
      },
    },
    compoundVariants: [
      {
        variant: 'link',
        className: 'px-0 py-0 [font-size:inherit] [height:unset]',
      },
      {
        variant: 'text',
        className: 'px-0 py-0 [font-size:inherit] [height:unset]',
      },
    ],
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)

export interface ButtonProps
  extends VariantsProps<typeof button>,
    ReakitButtonOptions {
  orientation?: React.AriaAttributes['aria-orientation']
  iconBefore?: React.ReactNode
  iconAfter?: React.ReactNode
  loading?: boolean
}

export const Button = React.forwardRef(
  (
    {
      className,
      variant = 'default',
      roundness = 'default',
      size = 'default',
      orientation = 'horizontal',
      iconBefore,
      iconAfter,
      loading,
      disabled,
      children,
      ...restProps
    },
    forwardedRef,
  ) => (
    <ReakitButton
      ref={forwardedRef}
      aria-orientation={orientation}
      data-loading={loading}
      className={cn(
        `Button Button--${variant}Button--${variant}-${size}Button--${variant}-${size}-${roundness}`,
        'group',
        restProps.autoFocus
          ? 'shadow-[0_0_0_1px_theme(colors.teal.50)]'
          : variant === 'outlined'
            ? undefined
            : 'shadow-none',
        button({variant, size, roundness}),
        className,
      )}
      disabled={disabled || loading}
      {...restProps}
    >
      {loading && (
        <HStack
          className={
            'Button-spinner -translate-x-1/2 -translate-y-1/2 absolute top-1/2 left-1/2 items-center'
          }
        >
          <Loader
            size="1.75em"
            variant={
              ['ghost', 'outlined', 'link', 'text'].includes(variant)
                ? 'default'
                : 'light'
            }
          />
        </HStack>
      )}

      {iconBefore && (
        <HStack
          className={cn(
            'Button-iconBefore',
            'flex-0 items-center justify-center',
            'group-aria-orientation-horizontal:mr-2 group-aria-orientation-vertical:mb-2',
            `group-data-[loading="true"]:opacity-0 group-data-[loading="true"]:transition-opacity group-data-[loading="true"]:duration-100 group-data-[loading="true"]:ease-out`,
          )}
        >
          {iconBefore}
        </HStack>
      )}

      {typeof children === 'string' ? (
        <Ellipsis
          className={cn(
            'Button-content flex-[1]',
            `group-data-[loading="true"]:opacity-0 group-data-[loading="true"]:transition-opacity group-data-[loading="true"]:duration-100 group-data-[loading="true"]:ease-out`,
          )}
          aria-hidden={loading}
        >
          {children}
        </Ellipsis>
      ) : (
        children
      )}

      {iconAfter && (
        <HStack
          className={cn(
            'Button-iconAfter',
            'flex-0 items-center justify-center',
            'group-aria-orientation-vertical:mt-1 group-aria-orientation-horizontal:ml-1',
            `group-data-[loading="true"]:opacity-0 group-data-[loading="true"]:transition-opacity group-data-[loading="true"]:duration-100 group-data-[loading="true"]:ease-out`,
          )}
        >
          {iconAfter}
        </HStack>
      )}
    </ReakitButton>
  ),
) as ForwardRefComponent<'button', ButtonProps>

// MARK: AnchorButton

export interface AnchorButtonProps extends Partial<ButtonProps> {}

export const AnchorButton = React.forwardRef(
  (
    {as = 'a', variant = 'ghost', className, style, ...restProps},
    forwardedRef,
  ) => (
    <Button
      ref={forwardedRef}
      className={cn('AnchorButton', className)}
      as={as}
      variant={variant}
      style={{pointerEvents: 'auto', ...style}}
      {...restProps}
    />
  ),
) as ForwardRefComponent<'a', AnchorButtonProps>

// MARK: – RoundedButton

export const RoundedButton = React.forwardRef(
  ({size = 'big', className, ...restProps}, forwardedRef) => (
    <Button
      ref={forwardedRef}
      className={cn('[&.Button]:rounded-xl', className)}
      size={size}
      {...restProps}
    />
  ),
) as ForwardRefComponent<'button', ButtonProps>
