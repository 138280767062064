import * as WebUI from '@cheddarup/web-ui'
import {useNavigate} from 'react-router-dom'
import React from 'react'

import ImageAlbumsSidebar from './ImageAlbumsSidebar'

export interface ImageUploadPageProps extends WebUI.ModalProps {
  onImageSubmit: (image: Blob) => void
}

const ImageUploadPage = ({
  onImageSubmit,
  className,
  ...restProps
}: ImageUploadPageProps) => {
  const navigate = useNavigate()
  const media = WebUI.useMedia()
  return (
    <WebUI.Modal
      aria-label="Upload image form"
      className={WebUI.cn(
        '[&_>_.ModalContentView]:h-full [&_>_.ModalContentView]:max-h-full [&_>_.ModalContentView]:w-full [&_>_.ModalContentView]:rounded-none',
        className,
      )}
      onDidHide={() => navigate({pathname: '../..'})}
      {...restProps}
    >
      <WebUI.ModalCloseButton />

      <WebUI.ModalHeader>Add a Banner Image</WebUI.ModalHeader>

      <WebUI.VStack className="grow sm:flex-row">
        <ImageAlbumsSidebar className="flex-0 sm:flex-[0_1_320px]" />
        <WebUI.Separator
          orientation={media.sm ? 'vertical' : 'horizontal'}
          variant="primary"
        />
        <WebUI.VStack className="grow gap-8 p-4">
          <ImageUploader onImageUpload={onImageSubmit} />
          <span className="text-center text-ds-sm">
            We recommend at least a 1020px x 340px (3:1 ratio) image that is no
            larger than 10 mb.
            <br /> Images with file types JPEG, PNG, or GIF work best.
          </span>
        </WebUI.VStack>
      </WebUI.VStack>
    </WebUI.Modal>
  )
}

// MARK: – ImageUploader

interface ImageUploaderProps extends React.ComponentPropsWithoutRef<'div'> {
  onImageUpload: (image: Blob) => void
}

const ImageUploader = ({
  onImageUpload,
  className,
  ...restProps
}: ImageUploaderProps) => {
  const growlActions = WebUI.useGrowlActions()
  return (
    <WebUI.FileUploader
      accept={{'image/*': []}}
      maxSize={1024 * 1024 * 10}
      onDropRejected={() =>
        growlActions.show('error', {
          title: 'Error',
          body: 'This file is too large or the file type is not supported. Pictures with file types JPEG, PNG, or GIF work best.',
        })
      }
      onDropAccepted={async ([newImage]) => {
        if (newImage) {
          const newImageNormalized = await WebUI.resetImageOrientation(newImage)
          onImageUpload(newImageNormalized)
        }
      }}
    >
      <WebUI.FileUploaderInput name="image" />

      <WebUI.FileUploaderDropzone
        className={WebUI.cn(
          'mx-auto h-[232px] w-[660px] max-w-full items-center justify-center gap-3 rounded p-6 shadow-z2',
          className,
        )}
        as={WebUI.VStack}
        {...restProps}
      >
        <WebUI.PhosphorIcon icon="camera" width={48} />
        <WebUI.Text className="font-light" variant="subtle">
          Select a Banner Image
        </WebUI.Text>
      </WebUI.FileUploaderDropzone>
    </WebUI.FileUploader>
  )
}

export default ImageUploadPage
