// @ts-ignore
import {z} from '@cheddarup/util'

import {cartPointOfSaleSchema} from './carts'
import {recurringPaymentContractSchema} from './recurring-payment-contracts'

export const paymentBalanceStatusSchema = z.enum(['available'])

export const paymentCheddarUpStatusSchema = z.enum(['pending', 'approved'])

export const paymentMethodTypeSchema = z.enum([
  'card',
  'echeck',
  'cash',
  'paypal',
  'refunded',
  'offline',
  'chained',
])

export const paymentDisputeSchema = z.object({
  created_at: z.string(),
  status: z.enum(['needs_response', 'under_review', 'won', 'lost']),
  fee_cents: z.number(),
  total: z.number().nullish(),
})

export const paymentMetadataSourceCardSchema = z.object({
  brand: z.string(),
  country: z.string(), // two letter ISO code
  exp_year: z.number(),
  funding: z.enum(['credit', 'debit', 'prepaid', 'unknown']),
  last4: z.string(),
})

export const paymentMetadataSourceBankSchema = z.object({
  account_holder_name: z.string(),
  bank_name: z.string(),
  country: z.string(),
  last4: z.string(),
  object: z.literal('bank_account'),
  routing_number: z.string(),
})

export const paymentMetadataSourceLinkSchema = z.object({
  link: z.object({}).optional(),
  type: z.literal('link'),
})

export const paymentMetadataSourceSchema = z.union([
  paymentMetadataSourceCardSchema,
  paymentMetadataSourceBankSchema,
  paymentMetadataSourceLinkSchema,
])

export const paymentMetadataExposedSchema = z.object({
  analytics: z.object({
    location: z
      .object({
        latitude: z.number(),
        longitude: z.number(),
      })
      .nullish(),
    paymentSource: z.enum(['web', 'pointOfSale']),
    pointOfSale: cartPointOfSaleSchema.nullish(),
  }),
  source: paymentMetadataSourceSchema.nullish(),
})

export const recurringPaymentInvoiceStatusSchema = z.enum([
  'scheduled',
  'failed',
])

export const recurringPaymentInvoiceSchema = z.object({
  id: z.number(),
  recurring_payment_contract: recurringPaymentContractSchema.optional(),
  status: recurringPaymentInvoiceStatusSchema,
  metadata: z.object({
    payment_number: z.number(),
  }),
})

export const scheduledInvoiceSchema = z.object({
  payment_id: z.number(),
  tab_object_id: z.number(),
  start_date: z.string(),
  amount_pennies: z.number(),
  status: z.enum(['active', 'cancelled', 'completed']),
  options: z.string(),
  recurring_payment_contract_id: z.number(),
  item_name: z.string(),
  recurring_payment_contract_options: z.string(),
  recurring_payment_contract_metadata: z.string(),
})

declare global {
  namespace Api {
    // @ts-ignore
    type PaymentBalanceStatus = z.infer<typeof paymentBalanceStatusSchema>
    // @ts-ignore
    type PaymentDispute = z.infer<typeof paymentDisputeSchema>
    // @ts-ignore
    type PaymentCheddarUpStatus = z.infer<typeof paymentCheddarUpStatusSchema>
    // @ts-ignore
    type RecurringPaymentInvoice = z.infer<typeof recurringPaymentInvoiceSchema>
    // @ts-ignore
    type RecurringPaymentInvoiceStatus = z.infer<
      typeof recurringPaymentInvoiceStatusSchema
    >
    // @ts-ignore
    type ScheduledInvoice = z.infer<typeof scheduledInvoiceSchema>
    // @ts-ignore
    type PaymentMetadataExposed = z.infer<typeof paymentMetadataExposedSchema>
    // @ts-ignore
    type PaymentMetadataSource = z.infer<typeof paymentMetadataSourceSchema>
    // @ts-ignore
    type PaymentMetadataSourceLink = z.infer<
      typeof paymentMetadataSourceLinkSchema
    >
    // @ts-ignore
    type PaymentMetadataSourceCard = z.infer<
      typeof paymentMetadataSourceCardSchema
    >
    // @ts-ignore
    type PaymentMetadataSourceBank = z.infer<
      typeof paymentMetadataSourceBankSchema
    >
    // @ts-ignore
    type PaymentMethodType = z.infer<typeof paymentMethodTypeSchema>
  }
}
