import * as Yup from 'yup'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import {
  api,
  useAddCollectionInGroupPageCategoryMutation,
  useCreateGroupPageSectionMutation,
  useUpdateGroupPageCategoryMutation,
  useUpdateGroupPageSectionMutation,
} from '@cheddarup/api-client'
import {CollectionListItemImage} from 'src/components'
import {useUserId, useUserSlug} from 'src/components/ManageRoleProvider'
import {formatTabStatus} from '@cheddarup/core'

import {useFormik, useLiveRef} from '@cheddarup/react-util'
import {readApiError} from 'src/helpers/error-formatting'
import GroupCollectionsForm from './GroupCollectionsForm'
import {isAxiosError} from 'axios'
import * as Util from '@cheddarup/util'
import {GroupPageSectionHeader} from '../components'

interface CollectionSectionFormValues {
  headline: string
  description: string
  background_color: Api.BrandKitColorKey
  details: {
    removeWhenClosed: boolean
  }
}

export type CollectionSectionFormFormik = ReturnType<
  typeof useFormik<CollectionSectionFormValues>
>

const AddCollectionsPage = () => {
  const [filterActive, setFilterActive] = useState(true)
  const navigate = useNavigate()
  const userId = useUserId()
  const dialogRef = useRef<WebUI.DialogInstance>(null)
  const growlActions = WebUI.useGrowlActions()
  const collectionSectionQuery = api.groupPageSections.detail.useQuery({
    pathParams: {sectionName: 'collection_section'},
  })
  const userSlug = useUserSlug()
  const visibleTabsQuery = api.tabs.list.useQuery(undefined, {
    select: (collections) =>
      Util.sort(
        collections
          .filter((c) => c.status !== 'draft')
          .filter((c) => c.collection_section_category_id == null)
          .filter((c) => c.user_id === userId)
          .filter((c) => !filterActive || isTabActive(c))
          .map((c, idx) => ({
            ...c,
            position: idx + 1,
          })),
      ).desc((c) => c.created_at),
  })
  const {data: collectionSection} = api.groupPageSections.detail.useQuery({
    pathParams: {sectionName: 'collection_section'},
  })
  const categoriesQuery = api.groupPageCategories.list.useQuery(undefined, {
    enabled: !!collectionSection,
    select: (
      categories,
    ): Array<WebUI.SectionListData<Api.GroupPageCategory, Api.GroupTab>> =>
      Util.sort(categories)
        .asc((cat) => cat.position)
        .map((cat) => ({
          sectionData: {
            ...cat,
            id: String(cat.id),
          },
          rowsData: Util.sort(cat.collections).asc((c) => c.position),
        })),
  })
  const createGroupPageSectionMutation = useCreateGroupPageSectionMutation()
  const updateGroupPageSectionMutation = useUpdateGroupPageSectionMutation()
  const updateGroupPageCategoryMutation = useUpdateGroupPageCategoryMutation()
  const addCollectionInGroupPageCategoryMutation =
    useAddCollectionInGroupPageCategoryMutation()

  const initialValues: CollectionSectionFormValues = useMemo(
    () => ({
      headline: collectionSectionQuery.data?.headline ?? 'Collections',
      description: collectionSectionQuery.data?.description ?? '',
      background_color:
        collectionSectionQuery.data?.background_color ?? 'white',
      details: {
        removeWhenClosed:
          collectionSectionQuery.data?.details.removeWhenClosed ?? false,
      },
    }),
    [collectionSectionQuery.data],
  )

  const isCollectionSectionMissing =
    !!collectionSectionQuery.error &&
    isAxiosError(collectionSectionQuery.error) &&
    collectionSectionQuery.error.response?.data?.errors?.[0]?.error ===
      'section_not_found'
  const initialValuesRef = useLiveRef(initialValues)
  useEffect(() => {
    if (isCollectionSectionMissing) {
      createGroupPageSectionMutation.mutate({
        pathParams: {sectionName: 'collection_section'},
        body: initialValuesRef.current,
      })
    }
  }, [isCollectionSectionMissing, createGroupPageSectionMutation.mutate])

  const formik = useFormik<CollectionSectionFormValues>({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      headline: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      try {
        const saveSection = collectionSectionQuery.data
          ? updateGroupPageSectionMutation
          : createGroupPageSectionMutation
        await saveSection.mutateAsync({
          pathParams: {sectionName: 'collection_section'},
          body: {
            ...values,
            userSlug,
          },
        })
        dialogRef.current?.hide()
      } catch (err) {
        const errMsg = readApiError(err)
        if (errMsg) {
          growlActions.show('error', {body: errMsg})
        }
      }
    },
  })

  return (
    <WebUI.Modal
      aria-label="Collection list"
      ref={dialogRef}
      className="[&_>_.ModalContentView]:h-full"
      onDidHide={() => navigate('..')}
    >
      <WebUI.ModalCloseButton />
      <WebUI.DragAndDrop
        touchEnabled
        dragOverlayPortal={false}
        collisionDetection={(args) => {
          if (args.active.data.current?.$type === 'container') {
            return WebUI.rectIntersection(args)
          }

          return WebUI.pointerWithin(args)
        }}
        modifiers={[
          (args: Parameters<WebUI.Modifier>[0]) => {
            if (args.active?.data.current?.$type === 'container') {
              return args.transform
            }

            return WebUI.followMouseModifier(args)
          },
        ].filter((m) => m != null)}
        onDragEnd={(event) => {
          let isProcessed = false

          WebUI.processSortableSectionListOnDragEnd(event, {
            data: categoriesQuery.data ?? [],
            onOrderChange: (newOrder, event) => {
              isProcessed = true
              if (newOrder.sectionId === null) {
                const activeCategoryId = event.active.id as string
                const updatedCategoryPosition =
                  newOrder.orderedRowIds.indexOf(activeCategoryId) + 1

                updateGroupPageCategoryMutation.mutate({
                  pathParams: {
                    id: activeCategoryId,
                  },
                  body: {
                    category: {
                      position: updatedCategoryPosition,
                    },
                  },
                })
              } else {
                addCollectionInGroupPageCategoryMutation.mutate({
                  body: {
                    category_id: Number(newOrder.sectionId),
                    collection_ids: newOrder.orderedRowIds.map(Number),
                  },
                })
              }
            },
            onMoveToSection: (newSourceOrder, newDestOrder, event) => {
              isProcessed = true

              addCollectionInGroupPageCategoryMutation.mutate({
                body: {
                  sourceCategoryId: Number(newSourceOrder.sectionId),
                  category_id: Number(newDestOrder.sectionId),
                  collection_ids: [Number(event.active.id)],
                },
              })
            },
          })

          if (isProcessed || !event.over) {
            return
          }

          const overCategoryId = Number(
            event.over.data.current?.section?.sectionData.id ??
              event.over.data.current?.sortable?.containerId,
          )
          const categorySection = categoriesQuery.data?.find(
            (s) => Number(s.sectionData.id) === overCategoryId,
          )

          if (categorySection) {
            const newOrder: number[] = event.over.data.current?.rowKeys?.map(
              (tabId: number | string) => Number(tabId),
            ) ?? [Number(event.active.id)]

            addCollectionInGroupPageCategoryMutation.mutate({
              body: {
                category_id: Number(categorySection.sectionData.id),
                collection_ids: newOrder,
              },
            })
          }
        }}
      >
        <div className="flex grow flex-col divide-y overflow-y-auto lg:flex-row lg:divide-x lg:overflow-hidden">
          <div className="flex min-w-[220px] flex-0 flex-col gap-5 overflow-y-auto p-7 lg:max-w-[320px]">
            <div className="flex flex-col gap-4 text-ds-sm">
              <span className="font-bold">Collections</span>
              <span>Only published collections will be available.</span>
            </div>

            <WebUI.Switch
              size="compact"
              checked={filterActive}
              onChange={(event) => setFilterActive(event.target.checked)}
            >
              Show only active collections
            </WebUI.Switch>

            {visibleTabsQuery.data?.map((tab) => (
              <WebUI.Draggable key={tab.id} id={String(tab.id)} dragData={tab}>
                <CollectionListItem collection={tab} />
              </WebUI.Draggable>
            ))}
          </div>
          <div className="flex min-h-0 min-w-0 shrink grow basis-auto flex-col divide-y">
            <GroupPageSectionHeader
              className="px-8 py-6"
              subheading="Select collections to display on your Group Page."
              quickTourSlideId="collections"
            >
              Collection Section
            </GroupPageSectionHeader>
            <GroupCollectionsForm
              className="overflow-y-auto"
              formik={formik}
              query={categoriesQuery}
              hiddenRowsData={visibleTabsQuery.data}
            />
          </div>
        </div>
      </WebUI.DragAndDrop>

      <WebUI.Separator variant="primary" />
      <div className="flex flex-row items-center justify-end px-8 py-4">
        <WebUI.Button
          size="large"
          variant="primary"
          loading={formik.isSubmitting}
          onClick={formik.submitForm}
        >
          Save
        </WebUI.Button>
      </div>
    </WebUI.Modal>
  )
}

// MARK: – CollectionListItem

interface CollectionListItemProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collection: Api.Tab
}

const CollectionListItem = ({
  collection,
  className,
  ...restProps
}: CollectionListItemProps) => (
  <div
    className={WebUI.cn(
      'relative flex flex-row items-center gap-4 rounded-default border p-3',
      className,
    )}
    {...restProps}
  >
    <CollectionListItemImage
      height={40}
      width={48}
      image={collection.featured_image}
    />
    <div className="flex flex-col">
      <WebUI.Text className="text-ds-sm">{collection.name}</WebUI.Text>
      <WebUI.Text className="font-light text-ds-sm text-gray-500">
        {formatTabStatus(collection)}
      </WebUI.Text>
    </div>
  </div>
)

// MARK: – Helpers

function isTabActive(tab: Api.Tab) {
  if (
    tab.closed_at ||
    (!tab.is_pro && tab.requires_pro) ||
    (tab.timing?.opens && new Date() < new Date(tab.timing.opens)) ||
    (tab.timing?.closes && new Date() > new Date(tab.timing.closes))
  ) {
    return false
  }

  return true
}

export default AddCollectionsPage
