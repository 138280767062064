import {
  DateRange,
  DateValue,
  useDateRangePicker,
  useRangeCalendar,
} from 'react-aria'
import React, {useRef} from 'react'
import {
  DateRangePickerStateOptions,
  RangeCalendarStateOptions,
  useDateRangePickerState,
  useRangeCalendarState,
} from 'react-stately'
import {SetOptional, SimpleMerge} from '@cheddarup/util'
import {genericForwardRef, useForkRef} from '@cheddarup/react-util'

import {Popover, PopoverContent, PopoverDisclosure} from './Popover'
import {DateInput, createCalendar} from './DateInput'
import {HStack, VStack} from './Stack'
import {IconButton} from './IconButton'
import {CalendarGrid} from './DatePicker'
import {Text} from './Text'
import {DateSegmentBoxProps} from './DateSegment'
import {PhosphorIcon} from '../icons'
import {cn} from '../utils'

export interface DateRangePickerProps<T extends DateValue>
  extends Omit<
    SimpleMerge<DateSegmentBoxProps, DateRangePickerStateOptions<T>>,
    'createCalendar' | 'onChange'
  > {
  contentClassName?: string
  onValueChange?: (newValue: DateRange) => void
}

export const DateRangePicker = genericForwardRef(
  <T extends DateValue>(
    {
      size,
      variant,
      contentClassName,
      onValueChange,
      className,
      ...restProps
    }: DateRangePickerProps<T>,
    forwardedRef: React.Ref<HTMLDivElement>,
  ) => {
    const ownRef = useRef<HTMLDivElement>(null)
    const ref = useForkRef(ownRef, forwardedRef)

    const state = useDateRangePickerState({
      ...restProps,
      onChange: onValueChange,
    })
    const {
      startFieldProps,
      endFieldProps,
      buttonProps,
      dialogProps,
      calendarProps,
    } = useDateRangePicker(restProps, state, ownRef)

    return (
      <Popover
        visible={state.isOpen}
        onVisibleChange={state.setOpen}
        placement="bottom-start"
      >
        <div
          ref={ref}
          className={cn('DateRangePicker relative max-w-full', className)}
        >
          <HStack className="DateRangePicker-inputsContainer w-full items-center gap-0_5 bg-natural-100 pr-9">
            <DateInput
              className="DateRangePicker-startInput shadow-none"
              size={size}
              variant={variant}
              {...startFieldProps}
            />
            <span className="DateRangePicker-inputsDivider">–</span>
            <DateInput
              className="DateRangePicker-endInput shadow-none"
              size={size}
              variant={variant}
              {...endFieldProps}
            />
          </HStack>
          <PopoverDisclosure
            className="DatePicker-disclosure -translate-y-1/2 absolute top-1/2 right-2 text-ds-lg"
            as={IconButton}
            size="default_alt"
            {...buttonProps}
            onClick={(event) => {
              event.preventDefault()
              state.toggle()
            }}
          >
            <PhosphorIcon icon="calendar-fill" />
          </PopoverDisclosure>
        </div>
        <PopoverContent
          className={cn(
            'DatePicker-content',
            '[&_>_.PopoverContent-inner_>_.PopoverContent-body]:!min-w-[240px] [&_>_.PopoverContent-inner_>_.PopoverContent-body]:!max-w-[240px]',
            contentClassName,
          )}
          {...dialogProps}
        >
          <RangeCalendar
            {...calendarProps}
            onValueChange={(newValue) => {
              calendarProps.onChange?.(newValue)
              state.close()
            }}
          />
        </PopoverContent>
      </Popover>
    )
  },
)

// MARK: – RangeCalendar

export interface RangeCalendarProps<T extends DateValue>
  extends Omit<
    SimpleMerge<
      React.ComponentPropsWithoutRef<'div'>,
      SetOptional<RangeCalendarStateOptions<T>, 'locale'>
    >,
    'createCalendar' | 'onChange'
  > {
  onValueChange?: (newValue: DateRange) => void
}

export const RangeCalendar = genericForwardRef(
  <T extends DateValue>(
    {locale = 'en-US', className, ...restProps}: RangeCalendarProps<T>,
    forwardedRef: React.Ref<HTMLDivElement>,
  ) => {
    const ownRef = useRef<HTMLDivElement>(null)
    const ref = useForkRef(ownRef, forwardedRef)
    const state = useRangeCalendarState({
      ...restProps,
      locale,
      createCalendar,
    })
    const {calendarProps, prevButtonProps, nextButtonProps, title} =
      useRangeCalendar(restProps, state, ownRef)

    return (
      <VStack
        ref={ref}
        className={cn('RangeCalendar', className)}
        {...calendarProps}
      >
        <HStack className="RangeCalendar-navContainer items-center justify-between bg-teal-80 p-2">
          <IconButton
            className="RangeCalendar-navPrev text-tint"
            size="default_alt"
            disabled={state.isPreviousVisibleRangeInvalid()}
            onClick={() => state.focusPreviousPage()}
            {...prevButtonProps}
          >
            <PhosphorIcon icon="caret-left" />
          </IconButton>

          <Text className="RangeCalendar-navDisplayDate">{title}</Text>

          <IconButton
            className="RangeCalendar-navNext text-tint"
            size="default_alt"
            disabled={state.isNextVisibleRangeInvalid()}
            onClick={() => state.focusNextPage()}
            {...nextButtonProps}
          >
            <PhosphorIcon icon="caret-right" />
          </IconButton>
        </HStack>

        <CalendarGrid state={state} />
      </VStack>
    )
  },
)
