import {useAsyncEffect} from '@cheddarup/react-util'
import {useEffect} from 'react'
import {toNullable} from '@cheddarup/util'

import {ImageType, ImTool} from '../utils'

export const useImageAsImTool = (imageUrl: ImageType | null | undefined) => {
  const imageAsBlob = useAsyncEffect(
    async () => (imageUrl ? ImTool.fromImage(imageUrl) : null),
    [imageUrl],
  )

  return imageUrl ? toNullable(imageAsBlob) : null
}

export const useImageAsBlob = (
  imageUrl: ImageType | null | undefined,
  type = 'image/jpeg',
) => {
  const imTool = useImageAsImTool(imageUrl)
  const imageAsBlob = useAsyncEffect(
    async () => imTool?.toBlob(type) ?? null,
    [imTool, type],
  )

  return imTool ? toNullable(imageAsBlob) : null
}

export const useImageAsBlobUrl = (imageUrl: ImageType | null | undefined) => {
  const imTool = useImageAsImTool(imageUrl)

  const asyncImageAsBlobUrl = useAsyncEffect(
    async () => imTool?.toBlobURL() ?? null,
    [imTool],
  )
  const imageAsBlobUrl = toNullable(asyncImageAsBlobUrl)

  useEffect(
    () => () => {
      if (imageAsBlobUrl) {
        URL.revokeObjectURL(imageAsBlobUrl)
      }
    },
    [imageAsBlobUrl],
  )

  return imageAsBlobUrl
}
