import {cn} from '@cheddarup/web-ui'
import {SVGProps} from 'react'

const CartIcon = ({
  fill = '#2c7b91',
  className,
  ...restProps
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={fill}
    className={cn('CartIcon', className)}
    {...restProps}
  >
    <title>{'cart-icon'}</title>
    <path d="M21.95 8.506 17.396.645a1.287 1.287 0 0 0-2.228 1.289l3.8 6.571H6.855l3.806-6.566A1.288 1.288 0 0 0 10.196.18l-.012-.006A1.288 1.288 0 0 0 8.42.641L3.873 8.504H1.291A1.292 1.292 0 0 0 0 9.797c0 .713.578 1.29 1.291 1.291h.43l1.792 10.754A2.584 2.584 0 0 0 6.06 24h13.7a2.584 2.584 0 0 0 2.547-2.158L24.1 11.088h.43a1.292 1.292 0 0 0 0-2.582h-2.58Zm-3.258 12.912H7.154a1.29 1.29 0 0 1-1.273-1.079l-1.542-9.25h17.378l-1.756 9.278a1.292 1.292 0 0 1-1.269 1.051Z" />
  </svg>
)

export default CartIcon
