import React, {useContext, useMemo, useRef, useState} from 'react'
import {useLiveRef, usePrevious} from '@cheddarup/react-util'

import {PhosphorIcon} from '../../icons'
import {Canvas, CanvasInstance, CanvasProps} from '../Canvas'
import {Text} from '../Text'
import {cn, isCanvasBlank} from '../../utils'
import {NextButton, NextButtonProps} from './Button'

interface InternalSignatureCanvasContextValue {
  isEmpty: boolean
  setIsEmpty: React.Dispatch<React.SetStateAction<boolean>>
  defaultDataURL?: string
  disabled?: CanvasProps['disabled']
  readOnly?: CanvasProps['readOnly']
  onDataURLChange?: CanvasProps['onDataURLChange']
  canvasRef: React.RefObject<CanvasInstance>
}

const InternalSignatureCanvasContext = React.createContext(
  {} as InternalSignatureCanvasContextValue,
)

// MARK: – SignatureCanvasProvider

export interface SignatureCanvasProviderProps
  extends Pick<
    InternalSignatureCanvasContextValue,
    'disabled' | 'readOnly' | 'defaultDataURL' | 'onDataURLChange'
  > {
  children:
    | React.ReactNode
    | ((contextValue: InternalSignatureCanvasContextValue) => React.ReactNode)
}

export const SignatureCanvasProvider = ({
  disabled,
  readOnly,
  defaultDataURL,
  onDataURLChange,
  children,
}: SignatureCanvasProviderProps) => {
  const canvasRef = useRef<CanvasInstance>(null)
  const [frozenDefaultDataURL] = useState(defaultDataURL)
  const [isEmpty, setIsEmpty] = useState(!frozenDefaultDataURL)
  const onDataURLChangeRef = useLiveRef(onDataURLChange)

  const contextValue: InternalSignatureCanvasContextValue = useMemo(
    () => ({
      isEmpty,
      setIsEmpty,
      disabled,
      readOnly,
      defaultDataURL: frozenDefaultDataURL,
      onDataURLChange: onDataURLChangeRef.current,
      canvasRef,
    }),
    [isEmpty, disabled, readOnly, frozenDefaultDataURL],
  )

  return (
    <InternalSignatureCanvasContext.Provider value={contextValue}>
      {typeof children === 'function' ? children(contextValue) : children}
    </InternalSignatureCanvasContext.Provider>
  )
}

// MARK: – SignatureCanvas

export interface SignatureCanvasProps
  extends Omit<
    CanvasProps,
    'elementRef' | 'defaultDataURL' | 'onDataURLChange'
  > {}

export const SignatureCanvas = React.forwardRef<
  HTMLCanvasElement,
  SignatureCanvasProps
>(({className, disabled, readOnly, ...restProps}, forwardedRef) => {
  const contextValue = useContext(InternalSignatureCanvasContext)
  const prevDefaultDataUrl = usePrevious(contextValue.defaultDataURL)

  function drawImage(canvas: HTMLCanvasElement) {
    if (
      contextValue.defaultDataURL &&
      (contextValue.defaultDataURL !== prevDefaultDataUrl ||
        isCanvasBlank(canvas))
    ) {
      function draw() {
        const ctx = canvas.getContext('2d')
        if (!ctx) {
          return
        }

        canvas.width = canvas.clientWidth
        canvas.height = canvas.clientHeight

        const destWidth = canvas.width * 0.8
        const destX = canvas.width * 0.1

        const imageRatio = image.naturalHeight / image.naturalWidth
        const canvasRatio = canvas.height / destWidth

        if (imageRatio < canvasRatio) {
          const h = destWidth * imageRatio
          ctx.drawImage(image, destX, (canvas.height - h) / 2, destWidth, h)
        } else {
          const w = (destWidth * canvasRatio) / imageRatio
          ctx.drawImage(image, (destWidth - w) / 2, destX, w, canvas.height)
        }
      }

      const image = new Image()
      image.addEventListener('load', draw)
      image.crossOrigin = 'anonymous'
      image.src = contextValue.defaultDataURL
    }
  }

  return (
    <Canvas
      ref={contextValue.canvasRef}
      elementRef={(el) => {
        if (el) {
          drawImage(el)
        }

        if (typeof forwardedRef === 'function') {
          forwardedRef?.(el)
        } else if (forwardedRef) {
          ;(forwardedRef as any).current = el
        }
      }}
      className={cn(
        'h-[120px] w-full rounded focus:ring-1 focus:ring-teal-50 aria-invalid:ring-1 aria-invalid:ring-orange-50',
        className,
      )}
      disabled={disabled ?? contextValue.disabled}
      readOnly={readOnly ?? contextValue.readOnly}
      tabIndex={0}
      onDataURLChange={contextValue.onDataURLChange}
      onIsEmptyChange={contextValue.setIsEmpty}
      {...restProps}
    />
  )
})

// MARK: – SignatureCanvasEmptyStateView

export interface SignatureCanvasEmptyStateViewProps
  extends React.ComponentPropsWithoutRef<'div'> {}

export const SignatureCanvasEmptyStateView = React.forwardRef<
  HTMLDivElement,
  SignatureCanvasEmptyStateViewProps
>(({className, children, ...restProps}, forwardedRef) => {
  const contextValue = useContext(InternalSignatureCanvasContext)

  if (!contextValue.isEmpty) {
    return null
  }

  return (
    <div
      ref={forwardedRef}
      className={cn(
        'pointer-events-none absolute inset-0 flex select-none flex-col items-center justify-center',
        className,
      )}
      {...restProps}
    >
      {!children || typeof children === 'string' ? (
        <div className="flex flex-col items-center justify-center gap-0_5">
          <PhosphorIcon icon="scribble-loop" width={36} />
          <Text className="text-center text-ds-sm">{children}</Text>
        </div>
      ) : (
        children
      )}
    </div>
  )
})

// MARK: – SignatureCanvasClearButton

export interface SignatureCanvasClearButtonProps extends NextButtonProps {}

export const SignatureCanvasClearButton = React.forwardRef<
  HTMLButtonElement,
  SignatureCanvasClearButtonProps
>(({className, onClick, children, ...restProps}, forwardedRef) => {
  const contextValue = useContext(InternalSignatureCanvasContext)

  return (
    <NextButton
      data-visible={!contextValue.isEmpty}
      ref={forwardedRef}
      className={cn(
        'invisible text-teal-50 opacity-0 transition-opacity data-[visible=true]:visible data-[visible=true]:opacity-100',
        className,
      )}
      disabled={contextValue.disabled || contextValue.isEmpty}
      variant="transparent"
      size="xs"
      onClick={(event) => {
        onClick?.(event)

        if (event.defaultPrevented) {
          return
        }

        contextValue.canvasRef.current?.clear()
      }}
      {...restProps}
    >
      {children ?? 'Clear'}
    </NextButton>
  )
})
