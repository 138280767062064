import {useNavigate} from 'react-router-dom'
import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'
import {api} from '@cheddarup/api-client'
import {getMostSignificantDisputedTab} from '@cheddarup/core'

import {LinkButton} from '../../components/LinkButton'

const WithdrawNegativeBalancePage = () => {
  const navigate = useNavigate()
  return (
    <WebUI.Modal
      className="[&_>_.ModalContentView]:max-w-screen-md"
      onDidHide={() => navigate('..')}
    >
      <WebUI.ModalCloseButton />
      <div className="px-8 py-16 text-center">
        <WebUI.Heading as="h2">Negative Collection Balance</WebUI.Heading>
        <WithdrawNegativeBalanceContainer className="py-8" />
      </div>
    </WebUI.Modal>
  )
}

// MARK: – WithdrawNegativeBalanceContainer

const WithdrawNegativeBalanceContainer = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => {
  const {data: collections} = api.tabs.list.useQuery()
  const disputedCollection = getMostSignificantDisputedTab(collections ?? [])

  if (!disputedCollection) {
    return null
  }

  return (
    <WebUI.VStack
      className={WebUI.cn('items-center gap-8', className)}
      {...restProps}
    >
      <p className="max-w-[30em]">
        Due to a disputed payment on your collection, {disputedCollection.name},
        you&apos;re currently unable to withdraw{' '}
        {Util.formatAmount(
          Math.abs(disputedCollection.withdrawal_balance_available),
        )}
        . You can fund this collection to correct this negative balance.
      </p>
      <LinkButton
        size="large"
        variant="primary"
        to={`/collection/${disputedCollection.id}/manage/i/collection/${disputedCollection.id}/summary`}
      >
        Go to Collection
      </LinkButton>
    </WebUI.VStack>
  )
}

export default WithdrawNegativeBalancePage
