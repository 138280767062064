import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'

export interface CollectionPaymentsStatsProps {
  heading: string
  paymentTotal: number
}

const CollectionPaymentsStats = ({
  heading,
  paymentTotal,
}: CollectionPaymentsStatsProps) => (
  <div className="flex flex-col gap-2">
    <WebUI.Heading className="text-gray400 uppercase" as="h6">
      {heading}
    </WebUI.Heading>
    <p className="font-light text-gray800 text-h-6">
      {Util.formatAmount(paymentTotal)}
    </p>
  </div>
)

export default CollectionPaymentsStats
