import {uniqueBy} from '@cheddarup/util'

import {makeQueryUpdate, makeUseMutation} from '../use-mutation'
import {endpoints} from '../../endpoints'
import {getEndpointKey} from '../../utils'

export const useCreatePaymentMethodMutation = makeUseMutation(
  endpoints.paymentMethods.create,
  () => ({
    regular: (newPaymentMethod) => [
      makeQueryUpdate(endpoints.paymentMethods.list, (prevPaymentMethods) =>
        prevPaymentMethods
          ? {
              banks:
                'account_holder_name' in newPaymentMethod
                  ? [...prevPaymentMethods.banks, newPaymentMethod]
                  : prevPaymentMethods.banks,
              cards:
                'account_holder_name' in newPaymentMethod
                  ? prevPaymentMethods.cards
                  : [...prevPaymentMethods.cards, newPaymentMethod],
            }
          : prevPaymentMethods,
      ),
    ],
  }),
)

export const useUpdatePaymentMethodMutation = makeUseMutation(
  endpoints.paymentMethods.update,
  () => ({
    regular: (newPaymentMethod) => [
      makeQueryUpdate(endpoints.paymentMethods.list, (prevPaymentMethods) =>
        prevPaymentMethods
          ? {
              banks:
                'account_holder_name' in newPaymentMethod
                  ? uniqueBy(
                      [
                        ...prevPaymentMethods.banks.map((b) =>
                          b.id === newPaymentMethod.id ? newPaymentMethod : b,
                        ),
                        newPaymentMethod,
                      ],
                      (pm) => pm.id,
                    )
                  : prevPaymentMethods.banks,
              cards:
                'account_holder_name' in newPaymentMethod
                  ? prevPaymentMethods.cards
                  : uniqueBy(
                      [
                        ...prevPaymentMethods.cards.map((c) =>
                          c.id === newPaymentMethod.id ? newPaymentMethod : c,
                        ),
                        newPaymentMethod,
                      ],
                      (pm) => pm.id,
                    ),
            }
          : prevPaymentMethods,
      ),
    ],
  }),
  (queryClient) => ({
    onSuccess: () => {
      const paymentMethodsListQueryKey = getEndpointKey(endpoints.payments.list)
      const recurringPaymentContractsListQueryKey = getEndpointKey(
        endpoints.recurringPaymentContracts.list,
      )
      queryClient.invalidateQueries({queryKey: paymentMethodsListQueryKey})
      queryClient.invalidateQueries({
        queryKey: recurringPaymentContractsListQueryKey,
      })
    },
  }),
)

export const useDeletePaymentMethodMutation = makeUseMutation(
  endpoints.paymentMethods.delete,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(endpoints.paymentMethods.list, (prevPaymentMethods) =>
        prevPaymentMethods
          ? {
              banks: prevPaymentMethods.banks.filter(
                (b) => b.id !== vars.pathParams.paymentMethodId,
              ),
              cards: prevPaymentMethods.cards.filter(
                (c) => c.id !== vars.pathParams.paymentMethodId,
              ),
            }
          : prevPaymentMethods,
      ),
    ],
  }),
)
