import * as WebUI from '@cheddarup/web-ui'
import EasyIcon from 'src/images/EasyIcon.svg'

const SignupFooter = () => {
  const {sm} = WebUI.useMedia()

  return sm ? (
    <div className="bg-natural-80 px-10 py-5">
      <WebUI.HStack className="justify-between gap-3">
        <WebUI.HStack className="items-center gap-3 [&_>_svg]:w-10">
          <img
            className="h-[2.5em] w-[2.5em] scale-[0.7]"
            src={EasyIcon}
            alt=""
          />
          <span className="font-normal text-ds-sm text-gray800">
            Payments + Forms
          </span>
        </WebUI.HStack>
        <WebUI.HStack className="items-center gap-3 [&_>_svg]:w-10">
          <img
            className="h-[2.5em] w-[2.5em] scale-[0.7]"
            src={EasyIcon}
            alt=""
          />
          <span className="font-normal text-ds-sm text-gray800">
            Frictionless Paying
          </span>
        </WebUI.HStack>
        <WebUI.HStack className="items-center gap-3 [&_>_svg]:w-10">
          <img
            className="h-[2.5em] w-[2.5em] scale-[0.7]"
            src={EasyIcon}
            alt=""
          />
          <span className="font-normal text-ds-sm text-gray800">
            Easy Handoff
          </span>
        </WebUI.HStack>
      </WebUI.HStack>
    </div>
  ) : null
}

export default SignupFooter
