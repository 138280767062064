import React, {useEffect, useRef} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'
import {UpgradeRequiredAlert} from 'src/components/UpgradeRequiredAlert'

import FixedItemForm from './FixedItemForm/FixedItemForm'
import DonationItemForm from './DonationItemForm/DonationItemForm'
import RecurringItemForm from './RecurringItemForm/RecurringItemForm'

export interface ItemFormProps extends React.ComponentPropsWithoutRef<'form'> {
  tabId: number
  itemId?: number
  itemType: Extract<Api.ItemType, 'fixed' | 'donation' | 'recurring'>
  onDirtyChange?: (value: boolean) => void
  onDismiss: () => void
}

export const ItemForm: React.FC<ItemFormProps> = ({
  tabId,
  itemId,
  itemType,
  onDismiss,
  className,
  ...restProps
}) => {
  const upgradeRequiredAlertRef = useRef<WebUI.DialogInstance>(null)
  const upgradeRequireCheckPerformedRef = useRef(false)
  const {data: collection, isFetching: isCollectionFetching} =
    api.tabs.detail.useQuery({
      pathParams: {
        tabId,
      },
    })
  const itemQuery = api.tabItems.detail.useQuery(
    {
      pathParams: {
        tabId,
        // biome-ignore lint/style/noNonNullAssertion:
        itemId: itemId!,
      },
    },
    {
      enabled: itemId != null,
    },
  )
  const fieldsQuery = api.fields.itemList.useQuery(
    {
      pathParams: {
        tabId,
        // biome-ignore lint/style/noNonNullAssertion:
        itemId: itemId!,
      },
    },
    {
      enabled: itemId != null,
    },
  )

  useEffect(() => {
    if (
      !upgradeRequireCheckPerformedRef.current &&
      !itemQuery.data &&
      !isCollectionFetching
    ) {
      upgradeRequireCheckPerformedRef.current = true

      if (
        collection &&
        collection.status !== 'draft' &&
        collection.itemLimit != null &&
        !collection.is_pro &&
        collection.reportsAvailable.activeItemsCount >= collection.itemLimit
      ) {
        upgradeRequiredAlertRef.current?.show()
      }
    }
  }, [collection, isCollectionFetching, itemQuery.data])

  const FormComp = {
    fixed: FixedItemForm,
    donation: DonationItemForm,
    recurring: RecurringItemForm,
  }[itemType]

  return (
    <>
      {(!itemId || (itemQuery.isSuccess && fieldsQuery.isSuccess)) && (
        <FormComp
          className={WebUI.cn('grow', className)}
          collectionId={tabId}
          item={itemQuery.data}
          fields={fieldsQuery.data}
          onDismiss={onDismiss}
          {...restProps}
        />
      )}

      <UpgradeRequiredAlert
        ref={upgradeRequiredAlertRef}
        onDidHide={() => onDismiss()}
      />
    </>
  )
}
