import {useNavigate} from 'react-router-dom'
import * as Yup from 'yup'
import * as WebUI from '@cheddarup/web-ui'
import {useRef} from 'react'
import {useFormik} from '@cheddarup/react-util'
import {
  api,
  useCreateGroupPageSectionMutation,
  useUpdateGroupPageSectionMutation,
} from '@cheddarup/api-client'
import {guessError} from 'src/helpers/error-utils'

import TotalCollectedForm from './TotalCollectedForm'
import {GroupPageSectionHeader} from '../components'
import {useUserSlug} from 'src/components/ManageRoleProvider'

interface TotalCollectedFormValues {
  headline: string
  description: string
  background_color?: Api.BrandKitColorKey
  details: {
    timePeriod: Api.GroupPageTotalCollectionTimePeriod
  }
}

export type TotalCollectedFormFormik = ReturnType<
  typeof useFormik<TotalCollectedFormValues>
>

const AddTotalCollectedPage = () => {
  const navigate = useNavigate()
  const userSlug = useUserSlug()
  const dialogRef = useRef<WebUI.DialogInstance>(null)
  const {data: totalCollected} = api.groupPageSections.detail.useQuery({
    pathParams: {
      sectionName: 'total_collected',
    },
  })
  const createGroupPageSectionMutation = useCreateGroupPageSectionMutation()
  const updateGroupPageSectionMutation = useUpdateGroupPageSectionMutation()
  const growlActions = WebUI.useGrowlActions()

  const formik = useFormik<TotalCollectedFormValues>({
    enableReinitialize: true,
    initialValues: {
      headline: totalCollected?.headline ?? '',
      description: totalCollected?.description ?? '',
      background_color: totalCollected?.background_color ?? 'secondary',
      details: {
        timePeriod: totalCollected?.details.timePeriod ?? 'all_time',
      },
    },
    validationSchema: Yup.object().shape({
      headline: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      try {
        const saveSection = totalCollected
          ? updateGroupPageSectionMutation
          : createGroupPageSectionMutation
        await saveSection.mutateAsync({
          pathParams: {sectionName: 'total_collected'},
          body: {
            ...values,
            userSlug,
          },
        })
        dialogRef.current?.hide()
      } catch (err) {
        growlActions.show('error', {
          title: 'Error',
          body: guessError(err).message,
        })
      }
    },
  })

  return (
    <WebUI.Modal
      ref={dialogRef}
      aria-label="Group Page Total Collected form"
      className="[&_>_.ModalContentView]:h-full [&_>_.ModalContentView]:max-w-5xl"
      initialVisible
      onDidHide={() => navigate('..')}
    >
      <WebUI.ModalCloseButton />
      <WebUI.ModalHeader>
        <GroupPageSectionHeader
          subheading="Time to show off! Display how much your organization has collected
              across all of your collections."
          quickTourSlideId="total-collected"
        >
          Total Collected Section
        </GroupPageSectionHeader>
      </WebUI.ModalHeader>
      <TotalCollectedForm
        className="grow overflow-y-auto px-9 py-6 sm:px-12"
        formik={formik}
      />
      <div className="flex flex-row justify-end border-t bg-natural-100 px-4 py-5">
        <WebUI.Button
          variant="primary"
          size="large"
          className="w-48"
          loading={formik.isSubmitting}
          onClick={formik.submitForm}
        >
          Save
        </WebUI.Button>
      </div>
    </WebUI.Modal>
  )
}

export default AddTotalCollectedPage
