import * as Sentry from '@sentry/react'
import type {ErrorEvent} from '@sentry/types'
import {useEffect} from 'react'
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom'
import config from 'src/config'

export function initSentry() {
  Sentry.init({
    dsn: config.sentryDsn,
    enabled: config.isProd,
    integrations: [
      Sentry.thirdPartyErrorFilterIntegration({
        filterKeys: ['cheddarup-key'],
        behaviour: 'drop-error-if-contains-third-party-frames',
      }),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.5,
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/.*\.cheddarup\.com\/api/,
    ],
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'The operation is insecure.',
      'ResizeObserver loop completed with undelivered notifications.',
    ],
    beforeSend: (error) => (shouldIgnoreErrorEvent(error) ? null : error),
  })
}

// MARK: – shouldIgnoreErrorEvent
// Based on https://gist.github.com/jeengbe/4bc86f05a41a1831e6abf2369579cc7a

const typeErrorFetchFailedValues = new Set([
  'Failed to fetch',
  'NetworkError when attempting to fetch resource.',
  'Load failed',
  'Network Error',
])

function shouldIgnoreErrorEvent(error: ErrorEvent): boolean {
  const exception = error.exception?.values?.[0]
  const now = Date.now()

  // Ignore recaptcha's error on mobile safari
  if (
    exception?.type === 'TypeError' &&
    exception.value?.startsWith("null is not an object (evaluating '") &&
    exception.value.endsWith(".style')")
  ) {
    return true
  }

  // Ignore query cancellations
  if (exception?.type === 'TypeError' && exception.value === 'cancelled') {
    return true
  }

  // Ignore chunk load errors, can't do anything about them
  if (exception?.type === 'ChunkLoadError') {
    return true
  }

  if (
    exception?.type !== 'TypeError' ||
    !typeErrorFetchFailedValues.has(exception.value as string)
  ) {
    return false
  }

  if (!error.breadcrumbs) {
    return false
  }

  // We go from the back since the last breadcrumb is most likely the erroneous one
  for (let i = error.breadcrumbs.length - 1; i >= 0; i--) {
    const breadcrumb = error.breadcrumbs[i]
    if (!breadcrumb) {
      continue
    }

    // We only need to check the last 5s of breadcrumbs as any earlier breadcrumbs are definitely unrelated
    if (breadcrumb.timestamp && now - breadcrumb.timestamp * 1000 > 5000) {
      break
    }

    if (isErroneousBreadcrumb(breadcrumb)) {
      return true
    }
  }

  return false
}

function isErroneousBreadcrumb(breadcrumb: Sentry.Breadcrumb): boolean {
  if (
    breadcrumb.level !== 'error' ||
    (breadcrumb.category !== 'xhr' && breadcrumb.category !== 'fetch')
  ) {
    return false
  }

  const url = breadcrumb.data?.url as string | undefined
  if (!url) {
    return false
  }

  return (
    url.startsWith('https://sibautomation.com') ||
    url.startsWith('https://analytics.google.com') ||
    url.startsWith('https://stats.g.doubleclick.net') ||
    url.startsWith('https://www.google.com') ||
    url.startsWith('https://cdn-cookieyes.com')
  )
}
