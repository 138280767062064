import * as Util from '@cheddarup/util'

export const readApiError = (
  response: any,
  messages: any = {},
  returnResponseByDefault = false,
): string => {
  const resp = response?.response?.data || response?.data || response
  const apiError = resp?.errors?.[0] || resp?.error || response || {}
  const msg = messages[apiError.error]
  if (typeof msg === 'function') {
    return msg(apiError.details)
  }
  if (msg) {
    return msg
  }
  if (returnResponseByDefault) {
    return apiError.toString()
  }

  return 'Something went wrong.'
}

export const read2FAError = (error: any) =>
  error.response?.data?.errors?.[0]?.error === 'invalid_security_token'
    ? 'Your two-factor code is incorrect.'
    : error.response?.data?.errors?.[0]?.error === 'two_factor_lockout'
      ? error.response.data.errors[0].details.permanent
        ? 'Too many two-factor codes requested. Please contact support.'
        : `Too many two-factor codes requested. Please try again in ${Util.formatDuration(
            Util.normalizeDuration({
              seconds: Math.ceil(
                Number(error.response.data.errors[0].details.remaining),
              ),
            }),
          )}.`
      : undefined
