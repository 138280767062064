import * as Util from '@cheddarup/util'
import {useParams} from 'react-router-dom'
import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {useDeleteSpotMutation} from '@cheddarup/api-client'

interface SpotDeleteAlertProps extends WebUI.AlertProps {
  signupType?: Api.SignUpType
  spot: Api.SignUpSpot | null
}

const SpotDeleteAlert = React.forwardRef<
  WebUI.DialogInstance,
  SpotDeleteAlertProps
>(({spot, signupType, ...restProps}, forwardedRef) => {
  const urlParams = useParams()
  const deleteSpotMutation = useDeleteSpotMutation()

  const timeSlotsPaymentItemsCounts =
    spot?.time_slots.map((ts) => ts.payment_items.length) ?? []
  const paymentItemsCount = Util.sum(timeSlotsPaymentItemsCounts)

  return (
    <WebUI.Alert
      ref={forwardedRef}
      aria-label="Delete spot confirmation"
      {...restProps}
    >
      <WebUI.AlertHeader>Are you sure?</WebUI.AlertHeader>
      <WebUI.AlertContentView
        text={
          paymentItemsCount > 0
            ? `There ${paymentItemsCount === 1 ? 'is' : 'are'} ${Util.pluralize(
                'participant',
                paymentItemsCount,
                true,
              )} signed up for this spot${
                signupType === 'list'
                  ? ''
                  : ` across ${Util.pluralize(
                      'time',
                      timeSlotsPaymentItemsCounts?.length ?? 0,
                      true,
                    )}`
              }.
                Their information will be removed.`
            : 'This action can not be undone.'
        }
        actions={
          <>
            <WebUI.AlertActionButton
              execute={() => {
                if (!spot) {
                  return
                }

                deleteSpotMutation.mutate({
                  pathParams: {
                    // biome-ignore lint/style/noNonNullAssertion:
                    tabId: urlParams.collection!,
                    // biome-ignore lint/style/noNonNullAssertion:
                    signupId: urlParams.signup!,
                    spotId: spot.id,
                  },
                })
              }}
            >
              Delete
            </WebUI.AlertActionButton>
            <WebUI.AlertCancelButton />
          </>
        }
      />
    </WebUI.Alert>
  )
})

export default SpotDeleteAlert
