import React from 'react'
import {ForwardRefComponent} from '@cheddarup/react-util'

import {Text, TextProps} from './Text'
import {cn} from '../utils'

export interface HeadingProps extends TextProps {}

export const Heading = React.forwardRef(
  ({as = 'h1', className, ...restProps}, forwardedRef) => {
    const fontSizeClassName = (() => {
      switch (as as React.ElementType) {
        case 'h6':
          return 'text-ds-xs'
        case 'h5':
          return 'text-ds-sm'
        case 'h4':
          return 'text-ds-base'
        case 'h3':
          return 'text-ds-lg'
        case 'h2':
          return 'text-ds-xl'
        case 'h1':
          return 'text-ds-2xl'
        default:
          return 'text-ds-sm'
      }
    })()

    return (
      <Text
        ref={forwardedRef}
        as={as}
        className={cn('Heading', 'font-normal', fontSizeClassName, className)}
        {...restProps}
      />
    )
  },
) as ForwardRefComponent<'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6', HeadingProps>
