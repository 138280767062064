import React from 'react'
import {HEADING_KEYS} from '@udecode/plate-heading'
import {
  BulletedListPlugin,
  NumberedListPlugin,
  ListItemPlugin,
} from '@udecode/plate-list/react'
import {
  useListToolbarButton,
  useListToolbarButtonState,
} from '@udecode/plate-list/react'
import {HorizontalRulePlugin} from '@udecode/plate-horizontal-rule/react'
import {BlockquotePlugin} from '@udecode/plate-block-quote/react'
import {LinkPlugin} from '@udecode/plate-link/react'
import {TLinkElement} from '@udecode/plate-link'
import {
  FloatingLinkUrlInput,
  LinkFloatingToolbarState,
  LinkOpenButton,
  useFloatingLinkEdit,
  useFloatingLinkEditState,
  useFloatingLinkInsert,
  useFloatingLinkInsertState,
  useLink,
  useLinkToolbarButton,
  useLinkToolbarButtonState,
} from '@udecode/plate-link/react'
import {
  BoldPlugin,
  ItalicPlugin,
  StrikethroughPlugin,
  UnderlinePlugin,
} from '@udecode/plate-basic-marks/react'
import {flip, offset} from '@udecode/plate-floating'
import {
  CreateHOCOptions,
  PlateElement,
  PlateElementProps,
  PlateLeaf,
  ParagraphPlugin,
  PlaceholderProps,
  createNodesHOC,
  useMarkToolbarButton,
  useMarkToolbarButtonState,
  usePlaceholderState,
  NodeComponent,
  useFormInputProps,
} from '@udecode/plate-common/react'
import {useFocused, useSelected} from 'slate-react'
import {withProps} from '@cheddarup/react-util'

import {ToolbarItem} from '../Toolbar'
import {Separator} from '../Separator'
import {ToggleGroup, ToggleGroupItem} from '../ToggleGroup'
import {PhosphorIcon} from '../../icons'
import {Heading} from '../Heading'
import {IconButton, IconButtonProps} from '../IconButton'
import {Input} from '../Input'
import {Button} from '../Button'
import {cn} from '../../utils'

// MARK: – PlateBlockquote

export const PlateBlockquote = React.forwardRef<
  React.ElementRef<typeof PlateElement>,
  PlateElementProps
>(({className, children, ...restProps}, forwardedRef) => (
  <PlateElement
    ref={forwardedRef}
    className={cn(
      'PlateBlockquote',
      'my-1 border-natural-70 border-l-2 pl-3 font-light italic',
      className,
    )}
    asChild
    {...restProps}
  >
    <blockquote>{children}</blockquote>
  </PlateElement>
))

// MARK: – PlateParagraph

export const PlateParagraph = React.forwardRef<
  React.ElementRef<typeof PlateElement>,
  PlateElementProps
>(({className, children, ...restProps}, forwardedRef) => (
  <PlateElement
    ref={forwardedRef}
    className={cn('m-0 px-0 py-1', className)}
    {...restProps}
  >
    {children}
  </PlateElement>
))

// MARK: – PlateHeading

export interface PlateHeadingProps
  extends Omit<PlateElementProps, 'as'>,
    Pick<React.ComponentPropsWithoutRef<typeof Heading>, 'as'> {}

export const PlateHeading = React.forwardRef<
  React.ElementRef<typeof PlateElement>,
  PlateHeadingProps
>(({className, as: Comp = 'h1', children, ...restProps}, forwardedRef) => {
  const {element, editor} = restProps
  return (
    <PlateElement
      data-first-block={element === editor.children[0]}
      ref={forwardedRef}
      className={cn('PlateHeading', 'data-[first-block=true]:mt-0', className)}
      asChild
      {...restProps}
    >
      <Heading className={Comp === 'h1' ? 'text-3xl' : undefined} as={Comp}>
        {children}
      </Heading>
    </PlateElement>
  )
})

// MARK: – PlateHR

export const PlateHR = React.forwardRef<
  React.ElementRef<typeof PlateElement>,
  PlateElementProps
>(({className, nodeProps, ...restProps}, forwardedRef) => {
  const {children} = restProps

  const selected = useSelected()
  const focused = useFocused()

  return (
    <PlateElement ref={forwardedRef} {...restProps}>
      <div
        aria-selected={selected}
        data-focused={focused}
        className="group my-4 rounded"
        contentEditable={false}
      >
        <Separator
          className="group-aria-selected:data-[focused=true]:border-teal-50"
          orientation="horizontal"
          {...nodeProps}
        />
      </div>

      {children}
    </PlateElement>
  )
})

// MARK: – PlateLink

export const PlateLink = React.forwardRef<
  React.ElementRef<typeof PlateElement>,
  PlateElementProps<TLinkElement>
>(({className, children, ...restProps}, forwardedRef) => {
  const {props: linkProps} = useLink({element: restProps.element})

  return (
    <PlateElement
      ref={forwardedRef}
      className={cn('PlateLink', className)}
      asChild
      {...linkProps}
      {...(restProps as any)}
    >
      {/* biome-ignore lint/a11y/useValidAnchor: */}
      <a>{children}</a>
    </PlateElement>
  )
})

// MARK: – PlateList

export const PlateList = React.forwardRef<
  React.ElementRef<typeof PlateElement>,
  PlateElementProps
>(({className, children, as: Comp = 'ul', ...restProps}, forwardedRef) => (
  <PlateElement
    ref={forwardedRef}
    asChild
    className={cn('PlateList', className)}
    {...restProps}
  >
    <Comp>{children}</Comp>
  </PlateElement>
))

export interface PlateToolbarItemProps
  extends Omit<
      React.ComponentPropsWithoutRef<typeof ToggleGroupItem>,
      'size' | 'value' | 'as'
    >,
    Pick<IconButtonProps, 'size'> {
  pressed?: boolean
}

export const PlateToolbarItem = React.forwardRef<
  HTMLButtonElement,
  PlateToolbarItemProps
>(({className, pressed, ...restProps}, forwardedRef) => (
  <ToolbarItem
    className="PlateToolbarItem text-ds-md"
    as={ToggleGroup}
    value={pressed ? 'single' : null}
  >
    <ToggleGroupItem
      ref={forwardedRef}
      className={cn(
        'PlateToolbarItem-toggleItem',
        'aria-checked:text-teal-50',
        className,
      )}
      as={IconButton}
      size="default_alt"
      value="single"
      variant="ghost"
      {...restProps}
    />
  </ToolbarItem>
))

export interface PlateMarkToolbarButtonProps
  extends Pick<PlateToolbarItemProps, 'children'> {
  nodeType: string
  clear?: string | string[]
}

export function PlateMarkToolbarButton({
  clear,
  nodeType,
  ...props
}: PlateMarkToolbarButtonProps) {
  const state = useMarkToolbarButtonState({clear, nodeType})
  const {props: buttonProps} = useMarkToolbarButton(state)
  return <PlateToolbarItem {...buttonProps} {...props} />
}

// MARK: – PlateListToolbarButton

export function PlateListToolbarButton({
  nodeType = BulletedListPlugin.key,
}: {nodeType: 'ul' | 'ol'}) {
  const state = useListToolbarButtonState({nodeType})
  const {props} = useListToolbarButton(state)

  return (
    <PlateToolbarItem {...props}>
      {nodeType === BulletedListPlugin.key ? (
        <PhosphorIcon icon="list-dashes" />
      ) : (
        <PhosphorIcon icon="list-numbers" />
      )}
    </PlateToolbarItem>
  )
}

// MARK: – PlateLinkToolbarButton

export function PlateLinkToolbarButton() {
  const state = useLinkToolbarButtonState()
  const {props} = useLinkToolbarButton(state)
  return (
    <PlateToolbarItem {...props}>
      <PhosphorIcon icon="link-simple" />
    </PlateToolbarItem>
  )
}

// MARK: – PlateLinkFloatingToolbar

const floatingOptions: LinkFloatingToolbarState['floatingOptions'] = {
  placement: 'bottom-start',
  middleware: [
    offset(12),
    flip({
      padding: 12,
      fallbackPlacements: ['bottom-end', 'top-start', 'top-end'],
    }),
  ],
}

export interface PlateLinkFloatingToolbarProps {
  state?: LinkFloatingToolbarState
}

export function PlateLinkFloatingToolbar({
  state,
}: PlateLinkFloatingToolbarProps) {
  const insertState = useFloatingLinkInsertState({
    ...state,
    floatingOptions: {
      ...floatingOptions,
      ...state?.floatingOptions,
    },
  })
  const {
    props: insertProps,
    ref: insertRef,
    hidden,
    textInputProps,
  } = useFloatingLinkInsert(insertState)

  const editState = useFloatingLinkEditState({
    ...state,
    floatingOptions: {
      ...floatingOptions,
      ...state?.floatingOptions,
    },
  })
  const {
    props: editProps,
    ref: editRef,
    editButtonProps,
    unlinkButtonProps,
  } = useFloatingLinkEdit(editState)
  const inputProps = useFormInputProps({
    preventDefaultOnEnterKeydown: true,
  })

  if (hidden) {
    return null
  }

  const input = (
    <div
      className="flex flex-col rounded bg-natural-100 shadow-z16"
      {...inputProps}
    >
      <div className="flex flex-row items-center">
        <div className="flex flex-row items-center p-2">
          <PhosphorIcon className="text-ds-lg" icon="link-simple" />
        </div>

        <Input
          size="compact"
          variant="headless"
          placeholder="Paste link"
          as={FloatingLinkUrlInput}
        />
      </div>

      <Separator />

      <div className="flex flex-row items-center">
        <div className="flex flex-row items-center p-2">
          <PhosphorIcon className="text-ds-lg" icon="text-t" />
        </div>
        <Input
          className="w-[240px] max-w-[280px]"
          size="compact"
          variant="headless"
          placeholder="Text to display"
          {...textInputProps}
        />
      </div>
    </div>
  )

  const editContent = editState.isEditing ? (
    input
  ) : (
    <div className="flex flex-row items-center gap-2 rounded bg-natural-100 px-2 py-1 shadow-z16">
      <Button type="button" size="compact" variant="ghost" {...editButtonProps}>
        Edit link
      </Button>

      <Separator orientation="vertical" />

      <IconButton className="text-ds-lg" size="default_alt" as={LinkOpenButton}>
        <PhosphorIcon icon="arrow-square-out" />
      </IconButton>

      <Separator orientation="vertical" />

      <IconButton
        className="text-ds-lg"
        type="button"
        size="default_alt"
        {...unlinkButtonProps}
      >
        <PhosphorIcon icon="link-break" />
        {/* <Icons.unlink width={18} /> */}
      </IconButton>
    </div>
  )

  return (
    <>
      <div ref={insertRef} className="w-auto p-2" {...insertProps}>
        {input}
      </div>

      <div ref={editRef} className="w-auto p-2" {...editProps}>
        {editContent}
      </div>
    </>
  )
}

// MARK: – Plugins

export const PlatePlaceholder = (props: PlaceholderProps) => {
  const {children, placeholder, nodeProps} = props

  const {enabled} = usePlaceholderState(props)

  return React.Children.map(children, (child) =>
    React.cloneElement(child, {
      className: child.props.className,
      nodeProps: {
        'data-enabled': enabled,
        ...nodeProps,
        className: cn(
          `data-[enabled=true]:before:content-[attr(placeholder)]
          data-[enabled=true]:before:absolute data-[enabled=true]:before:cursor-text
          data-[enabled=true]:before:opacity-30`,
          nodeProps?.className,
        ),
        placeholder,
      },
    }),
  )
}

const withPlaceholdersPrimitive = createNodesHOC(PlatePlaceholder)

export const withPlaceholders = (
  components: Record<string, NodeComponent>,
  placeholders: Array<CreateHOCOptions<PlaceholderProps>>,
) => withPlaceholdersPrimitive(components, placeholders)

// MARK: – Helpers

export function createPlateUI(
  overrides?: Partial<Record<string, NodeComponent>>,
  options: {placeholders?: Array<CreateHOCOptions<PlaceholderProps>>} = {},
) {
  let components: Record<string, NodeComponent> = {
    [BlockquotePlugin.key]: PlateBlockquote,
    [ParagraphPlugin.key]: PlateParagraph,
    [HorizontalRulePlugin.key]: PlateHR,
    [HEADING_KEYS.h1]: withProps(PlateHeading, {as: 'h1'}),
    [HEADING_KEYS.h2]: withProps(PlateHeading, {as: 'h2'}),
    [HEADING_KEYS.h3]: withProps(PlateHeading, {as: 'h3'}),
    [HEADING_KEYS.h4]: withProps(PlateHeading, {as: 'h4'}),
    [HEADING_KEYS.h5]: withProps(PlateHeading, {as: 'h5'}),
    [HEADING_KEYS.h6]: withProps(PlateHeading, {as: 'h6'}),
    [ListItemPlugin.key]: withProps(PlateElement, {as: 'li'}),
    [LinkPlugin.key]: PlateLink,
    [BulletedListPlugin.key]: withProps(PlateList, {as: 'ul'}),
    [NumberedListPlugin.key]: withProps(PlateList, {as: 'ol'}),

    [BoldPlugin.key]: withProps(PlateLeaf, {as: 'strong'}),
    [ItalicPlugin.key]: withProps(PlateLeaf, {as: 'em'}),
    [StrikethroughPlugin.key]: withProps(PlateLeaf, {as: 's'}),
    [UnderlinePlugin.key]: withProps(PlateLeaf, {as: 'u'}),
    ...overrides,
  }

  if (options.placeholders) {
    components = withPlaceholders(components, options.placeholders)
  }

  return components
}
