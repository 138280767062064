const checkDifferentRetailPricesOrAmounts = (
  item: Api.TabItem | Api.PublicTabItem,
): [boolean, boolean] => {
  if (
    item.options.variants?.enabled &&
    item.options.variants.listings.length > 1
  ) {
    return item.options.variants.listings.reduce<[boolean, boolean]>(
      (acc, listing, _idx, listings) => [
        acc[0] ||
          (!!listing.retailPrice &&
            !!listings[0]?.retailPrice &&
            listing.retailPrice !== listings[0].retailPrice),
        acc[1] || (!!listings[0] && listing.amount !== listings[0].amount),
      ],
      [false, false],
    )
  }

  return [false, false]
}

export default checkDifferentRetailPricesOrAmounts
