import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import {useState} from 'react'

export interface SearchAndSelectableItemsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  searchVisible?: boolean
  selectedItemIds: number[]
  items: Api.TabItem[]
  onChangeSelectedItemsIds: (newItemIds: number[]) => void
}

export const SearchAndSelectableItems = ({
  searchVisible = true,
  items,
  selectedItemIds,
  onChangeSelectedItemsIds,
  className,
  ...restProps
}: SearchAndSelectableItemsProps) => {
  const [keyword, setKeyword] = useState('')

  const filteredItems = items.filter((item) =>
    item.name.toLowerCase().includes(keyword.toLowerCase()),
  )
  const itemsWithoutCategory = filteredItems.filter((item) => !item.category)
  const itemsGroupedByCategoryId = Util.groupBy(
    filteredItems.filter(
      (
        item,
      ): item is typeof item & {
        category: NonNullable<(typeof item)['category']>
      } => !!item.category,
    ),
    (i: Api.TabItem) => i.category?.id,
  )

  return (
    <WebUI.VStack className={WebUI.cn('gap-3', className)} {...restProps}>
      {searchVisible && (
        <WebUI.HStack className="relative">
          <WebUI.Input
            className="pl-[calc(24px+theme(spacing.2))]"
            size="compact"
            placeholder="Search Items"
            value={keyword}
            onChange={(event) => setKeyword(event.target.value)}
          />
          <WebUI.PhosphorIcon
            className="-translate-y-1/2 absolute top-1/2 left-2 w-6 text-inputPlaceholder"
            icon="magnifying-glass"
          />
        </WebUI.HStack>
      )}

      <WebUI.VStack className="gap-4">
        {Object.keys(itemsGroupedByCategoryId).map((categoryId) => (
          <CategorizedItems
            key={categoryId}
            items={itemsGroupedByCategoryId[Number(categoryId)] ?? []}
            selectedItemIds={selectedItemIds}
            categoryId={categoryId}
            onChangeSelectedItemsIds={onChangeSelectedItemsIds}
          />
        ))}
        {itemsWithoutCategory.length > 0 && (
          <CategorizedItems
            selectedItemIds={selectedItemIds}
            onChangeSelectedItemsIds={onChangeSelectedItemsIds}
            categoryId={null}
            items={itemsWithoutCategory}
          />
        )}
      </WebUI.VStack>
    </WebUI.VStack>
  )
}

interface CategorizedItemsProps extends React.ComponentPropsWithoutRef<'div'> {
  categoryId: string | null
  selectedItemIds: number[]
  items: Api.TabItem[]
  onChangeSelectedItemsIds: (newItemIds: number[]) => void
}

const CategorizedItems = ({
  categoryId,
  items,
  selectedItemIds,
  onChangeSelectedItemsIds,
  className,
  ...restProps
}: CategorizedItemsProps) => {
  const itemIds = items.map((item) => item.id)
  return (
    <WebUI.VStack className={WebUI.cn('gap-4', className)} {...restProps}>
      <WebUI.Checkbox
        className="bg-natural-80 p-2"
        size="compact"
        checked={itemIds.every((itemId) => selectedItemIds.includes(itemId))}
        onChange={(event) => {
          if (event.target.checked) {
            onChangeSelectedItemsIds(
              Util.unique(selectedItemIds.concat(itemIds)),
            )
          } else {
            onChangeSelectedItemsIds(Util.difference(selectedItemIds, itemIds))
          }
        }}
      >
        {categoryId ? items[0]?.category?.name : 'Select All'}
      </WebUI.Checkbox>
      {items.map((item) => (
        <WebUI.Checkbox
          key={item.id}
          className="px-4"
          size="compact"
          checked={selectedItemIds.includes(item.id)}
          onChange={(event) => {
            if (event.target.checked) {
              onChangeSelectedItemsIds(
                Util.unique([...selectedItemIds, item.id]),
              )
            } else {
              onChangeSelectedItemsIds(
                selectedItemIds.filter((iId) => iId !== item.id),
              )
            }
          }}
        >
          {item.name}
        </WebUI.Checkbox>
      ))}
    </WebUI.VStack>
  )
}
