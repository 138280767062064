// @ts-ignore

import {z} from '@cheddarup/util'

// MARK: – Pagination

export const paginationSchema = z.object({
  page: z.number(),
  count: z.number(),
  total: z.number(),
  perPage: z.number(),
})

// MARK: – Localization

export const isoCountryCodeSchema = z.enum(['US', 'CA'])

export const currencySchema = z.enum(['usd', 'cad'])

// MARK: – Images

export const cropDetailsSchema = z.object({
  x: z.number(),
  y: z.number(),
  width: z.number(),
  height: z.number(),
})

export const s3ImageMetadataTypeSchema = z.enum([
  'header',
  'backgroundImage',
  'logo',
])

export const s3ImageMetadataSchema = z.object({
  contentType: z.string().optional(),
  thumbnail: z
    .object({
      order: z.number().optional(),
      cropDetails: cropDetailsSchema.or(z.object({})).optional(),
    })
    .optional(),
  pintura: z.record(z.any()).nullish(),
  type: s3ImageMetadataTypeSchema.optional(),
})

export const s3ImageSchema = z.object({
  id: z.number(),
  url: z.string(),
  upload_path: z.string(),
  edit_path: z.string().optional(),
  edited_image_url: z.string().nullish(),
  metadata: s3ImageMetadataSchema,
  editable: z.boolean().optional(),
})

export const cheddarUpImageSchema = z.object({
  id: z.number(),
  image_file: z
    .object({
      url: z.string().nullish(),
    })
    .optional(),
})

// MARK: – Misc

export const paymentStatusSchema = z.enum([
  'pending',
  'available',
  'failed',
  'fully',
  'partial',
  'approved',
])

declare global {
  namespace Api {
    // @ts-ignore
    type CheddarUpImage = z.infer<typeof cheddarUpImageSchema>
    // @ts-ignore
    type S3Image = z.infer<typeof s3ImageSchema>
    // @ts-ignore
    type S3ImageMetadata = z.infer<typeof s3ImageMetadataSchema>
    // @ts-ignore
    type S3ImageMetadataType = z.infer<typeof s3ImageMetadataTypeSchema>
    // @ts-ignore
    type CropDetails = z.infer<typeof cropDetailsSchema>
    // @ts-ignore
    type Currency = z.infer<typeof currencySchema>
    // @ts-ignore
    type IsoCountryCode = z.infer<typeof isoCountryCodeSchema>
    // @ts-ignore
    type Pagination = z.infer<typeof paginationSchema>
    // @ts-ignore
    type PaymentStatus = z.infer<typeof paymentStatusSchema>
  }
}
