import {endpoints} from '../../endpoints'
import {makeQueryUpdate, makeUseMutation} from '../use-mutation'

export const useCreateTabDonationCodeMutation = makeUseMutation(
  endpoints.tabDonationCodes.create,
  (vars) => ({
    regular: (donationCode) => [
      makeQueryUpdate(
        endpoints.tabs.detail,
        (tab) => (tab ? {...tab, donationCode} : tab),
        vars,
      ),
    ],
  }),
)

export const useUpdateTabDonationCodeMutation = makeUseMutation(
  endpoints.tabDonationCodes.update,
  (vars) => ({
    regular: (updatedDonationCode) => [
      makeQueryUpdate(
        endpoints.tabDonationCodes.detail,
        () => updatedDonationCode,
        vars,
      ),
      makeQueryUpdate(
        endpoints.tabs.detail,
        (tab) => (tab ? {...tab, donationCode: updatedDonationCode} : tab),
        vars,
      ),
    ],
  }),
)

export const useDeleteTabDonationCodeMutation = makeUseMutation(
  endpoints.tabDonationCodes.delete,
  (vars) => ({
    regular: () => [
      makeQueryUpdate(
        endpoints.tabs.detail,
        (tab) =>
          tab
            ? {
                ...tab,
                donationCode: undefined,
              }
            : tab,
        vars,
      ),
    ],
  }),
)
