import {useNavigate} from 'react-router-dom'
import * as Yup from 'yup'
import * as WebUI from '@cheddarup/web-ui'
import {useRef} from 'react'
import {useFormik} from '@cheddarup/react-util'
import {
  api,
  useCreateGroupPageSectionMutation,
  useUpdateGroupPageSectionMutation,
} from '@cheddarup/api-client'
import AboutUsForm from './AboutUsForm'
import {readApiError} from 'src/helpers/error-formatting'
import {GroupPageSectionHeader} from '../components'
import {useUserSlug} from 'src/components/ManageRoleProvider'

interface AboutUsFormValues {
  headline: string
  description: string
  background_color: Api.BrandKitColorKey
  details: {
    url: string
    buttonText: string
  }
}

export type AboutUsFormFormik = ReturnType<typeof useFormik<AboutUsFormValues>>

const AddAboutUsPage = () => {
  const navigate = useNavigate()
  const modalRef = useRef<WebUI.DialogInstance>(null)
  const userSlug = useUserSlug()
  const {data: aboutUsSection} = api.groupPageSections.detail.useQuery({
    pathParams: {
      sectionName: 'about_us',
    },
  })

  const growlActions = WebUI.useGrowlActions()

  const createGroupPageSectionMutation = useCreateGroupPageSectionMutation()
  const updateGroupPageSectionMutation = useUpdateGroupPageSectionMutation()

  const formik = useFormik<AboutUsFormValues>({
    enableReinitialize: true,
    initialValues: {
      headline: aboutUsSection?.headline ?? '',
      description: aboutUsSection?.description ?? '',
      background_color: aboutUsSection?.background_color ?? 'white',
      details: {
        url: aboutUsSection?.details.url ?? '',
        buttonText: aboutUsSection?.details.buttonText ?? '',
      },
    },
    validationSchema: Yup.object().shape({
      headline: Yup.string().required('Required'),
      description: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      try {
        const saveSection = aboutUsSection
          ? updateGroupPageSectionMutation
          : createGroupPageSectionMutation
        await saveSection.mutateAsync({
          pathParams: {sectionName: 'about_us'},
          body: {
            ...values,
            userSlug,
          },
        })
        modalRef.current?.hide()
      } catch (err) {
        const errMsg = readApiError(err)
        if (errMsg) {
          growlActions.show('error', {body: errMsg})
        }
      }
    },
  })

  return (
    <WebUI.Modal
      ref={modalRef}
      aria-label="Group Page About Us form"
      className="[&_>_.ModalContentView]:h-full [&_>_.ModalContentView]:max-w-5xl"
      initialVisible
      onDidHide={() => navigate('..')}
    >
      <WebUI.ModalCloseButton />
      <WebUI.ModalHeader>
        <GroupPageSectionHeader
          subheading="Build credibility with a mission statement or general info about
              your organization."
          quickTourSlideId="about-us"
        >
          About Us Section
        </GroupPageSectionHeader>
      </WebUI.ModalHeader>
      <AboutUsForm
        className="grow overflow-y-auto px-9 py-6 sm:px-12"
        formik={formik}
      />
      <div className="flex flex-row justify-end border-t bg-natural-100 px-4 py-5">
        <WebUI.Button
          variant="primary"
          size="large"
          className="w-48"
          loading={formik.isSubmitting}
          onClick={() => formik.submitForm()}
        >
          Save
        </WebUI.Button>
      </div>
    </WebUI.Modal>
  )
}

export default AddAboutUsPage
