import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import {useUpdateEffect} from '@cheddarup/react-util'
import React, {useRef, useState} from 'react'
import {api} from '@cheddarup/api-client'
import {useManagerRoleId} from 'src/components/ManageRoleProvider'

import CreateFolderFormPrompt from '../../../CollectionsPage/components/CreateFolderFormPrompt'

export interface FolderSelectFieldProps
  extends WebUI.DropdownSelectProps<number | 'CREATE_NEW' | 'NONE' | null> {
  collection?: Api.TabDetailed
  error?: React.ReactNode
  onOwnerIdChange?: (ownerId: number | null) => void
}

export const FolderSelectField = ({
  collection,
  value,
  error,
  onValueChange,
  onOwnerIdChange,
  className,
  ...restProps
}: FolderSelectFieldProps) => {
  const [managerRoleId] = useManagerRoleId()

  const managerId = collection?.access.owner
    ? null
    : (collection?.organizer.id ?? managerRoleId ?? null)

  const defaultFolderNameQuery = api.auth.session.useQuery(undefined, {
    enabled: managerId == null,
    select: (session) =>
      session.user.profile?.uiClientFlags?.customDefaultFolderName ||
      'Collections',
  })
  const managerRoleQuery = api.auth.session.useQuery(undefined, {
    enabled: managerId != null,
    select: (session) =>
      session.manager_roles.find((mr) => mr.id === managerId),
  })
  const foldersQuery = api.tabFolders.list.useQuery(undefined, {
    enabled: managerId == null,
  })
  const managerFoldersQuery = api.tabs.list.useQuery(undefined, {
    enabled: managerId != null,
    select: (collections) =>
      Util.uniqueBy(
        collections
          .filter((c) => c.organizer.id === managerId)
          .map((c) => c.folder),
        (f) => f?.id,
      ).filter((f) => f != null),
  })

  const computedSelectedValue =
    value === null
      ? !collection || collection.access.owner
        ? -1
        : -collection.organizer.id
      : value === 'NONE'
        ? null
        : value

  const [selectedValue, setSelectedValue] = useState(computedSelectedValue)
  const createFolderFormPromptRef = useRef<WebUI.DialogInstance>(null)

  useUpdateEffect(() => {
    setSelectedValue(computedSelectedValue)
  }, [computedSelectedValue])

  return (
    <>
      <WebUI.FormField
        className={className}
        required
        label={managerId ? 'Managed Account Folder' : 'Select Folder'}
        error={error}
      >
        <WebUI.DropdownSelect<number | 'CREATE_NEW' | 'NONE' | null>
          listClassName="[&_.Text]:p-2 [&_.Text]:text-ds-xs [&_.Text]:font-bold [&_.Text]:uppercase [&_.Text]:select-none"
          placeholder="Select Folder"
          onValueChange={(newValue) => {
            if (newValue === 'CREATE_NEW') {
              createFolderFormPromptRef.current?.show()
            } else if (newValue === 'NONE') {
              setSelectedValue(null)
            } else if (newValue) {
              setSelectedValue(newValue)
              onValueChange?.(newValue < 0 ? null : newValue)

              if (newValue === -1) {
                onOwnerIdChange?.(null)
              } else if (newValue < 0) {
                onOwnerIdChange?.(-newValue)
              } else {
                const newOwnerId =
                  managerFoldersQuery.data?.find((f) => f.id === newValue)
                    ?.user_id ??
                  foldersQuery.data?.find((f) => f.id === newValue)?.organizer
                    ?.id
                onOwnerIdChange?.(newOwnerId ?? null)
              }
            }
          }}
          value={selectedValue}
          {...restProps}
        >
          {managerRoleQuery.data && (
            <>
              <WebUI.Ellipsis>{managerRoleQuery.data.name}</WebUI.Ellipsis>
              <WebUI.DropdownSelectOption value={-managerRoleQuery.data.id}>
                {managerRoleQuery.data.profile?.uiClientFlags
                  ?.customDefaultFolderName ?? 'Collections'}
              </WebUI.DropdownSelectOption>
              {managerFoldersQuery.data?.map((folder) => (
                <WebUI.DropdownSelectOption key={folder.id} value={folder.id}>
                  {folder.name}
                </WebUI.DropdownSelectOption>
              ))}
            </>
          )}
          {!managerId && (
            <>
              <WebUI.Ellipsis>My Folders</WebUI.Ellipsis>
              <WebUI.DropdownSelectOption value={-1}>
                {defaultFolderNameQuery.data}
              </WebUI.DropdownSelectOption>
              {foldersQuery.data?.map((folder) => (
                <WebUI.DropdownSelectOption key={folder.id} value={folder.id}>
                  {folder.name}
                </WebUI.DropdownSelectOption>
              ))}
              <WebUI.DropdownSelectOption
                className="mt-1"
                variant="link"
                value="CREATE_NEW"
              >
                Create New Folder
              </WebUI.DropdownSelectOption>
            </>
          )}
        </WebUI.DropdownSelect>
      </WebUI.FormField>
      <CreateFolderFormPrompt
        ref={createFolderFormPromptRef}
        onDidCreateFolder={(newFolder) => onValueChange?.(newFolder.id)}
      />
    </>
  )
}
