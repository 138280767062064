// @ts-ignore

import {z} from '@cheddarup/util'

import {
  cheddarUpImageSchema,
  paymentStatusSchema,
  s3ImageSchema,
} from './common'
import {stripeStatusSchema} from './withdrawals'
import {tabMemberSchema} from './tab-members'
import {paymentShipmentSchema, paymentShippingInfoSchema} from './shipments'
import {tabTaxSchema} from './tab-taxes'
import {paymentItemSchema} from './payment-items'
import {tabPaymentDiscountSchema} from './tab-payments'
import {
  paymentBalanceStatusSchema,
  paymentCheddarUpStatusSchema,
  paymentDisputeSchema,
  paymentMetadataExposedSchema,
  paymentMethodTypeSchema,
  recurringPaymentInvoiceSchema,
  scheduledInvoiceSchema,
} from './base-payments'
import {tabPaymentRefundSchema} from './refunds'

export const paymentToOthersSchema = z.object({
  id: z.number(),
  updated_at: z.string(),
  created_at: z.string(),
  total: z.number(),
  member_fee: z.number(),
  status: paymentStatusSchema,
  shipping_info: paymentShippingInfoSchema,
  shipment: paymentShipmentSchema.optional(),
  discounts: z.record(tabPaymentDiscountSchema),
  taxes: z.record(tabTaxSchema),
  items_total: z.number(),
  total_discounts: z.number(),
  total_refund: z.number(),
  user_fee_refund: z.number(),
  total_taxes: z.number(),
  subtotal: z.number(),
  member_fee_refund: z.number(),
  payment_method: paymentMethodTypeSchema,
  available_on: z.string().nullish(),
  fully_refunded: z.boolean(),
  tab: z.object({
    id: z.number(),
    slug: z.string(),
    name: z.string(),
    image: cheddarUpImageSchema.nullish(),
    user: z.object({
      id: z.number(),
      email: z.string(),
      name: z.string(),
      profile_pic: s3ImageSchema.nullish(),
    }),
  }),
  tab_member: z.object({
    name: z.string(),
    email: z.string(),
    created_at: z.string(),
    profile_pic: s3ImageSchema.nullish(),
  }),
  payment_items: paymentItemSchema.array(),
  metadata_exposed: paymentMetadataExposedSchema.nullish(),
  scheduled_invoices: scheduledInvoiceSchema.array(),
  recurring_payment_invoice: recurringPaymentInvoiceSchema.nullish(),
})

export const paymentToOthersDetailedSchema = paymentToOthersSchema.extend({
  tab_id: z.number(),
  tab_member_id: z.number(),
  parent_payment_id: z.number().nullish(),
  note: z.string().nullish(),
  uuid: z.string(),
  stripe_transfer_id: z.string().nullish(),
  cheddarup_status: paymentCheddarUpStatusSchema,
  stripe_status: stripeStatusSchema,
  balance_status: paymentBalanceStatusSchema.nullish(),
  stripe_charge_id: z.string().nullish(),
  user_fee: z.number(),
  paid: z.boolean(),
  shipping_charge: z.number(),
  can_refund: z.boolean(),
  tab_nember: tabMemberSchema,
  refunds: tabPaymentRefundSchema.array(),
  dispute: paymentDisputeSchema.nullish(),
})

declare global {
  namespace Api {
    // @ts-ignore
    type PaymentToOthersDetailed = z.infer<typeof paymentToOthersDetailedSchema>
    // @ts-ignore
    type PaymentToOthers = z.infer<typeof paymentToOthersSchema>
  }
}
