import * as Util from '@cheddarup/util'
import Papa from 'papaparse'
import {useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import provinces from 'src/data/provinces.json'

import {
  UploadMemberInvitesProgress,
  UploadMemberInvitesSuccess,
  UploadMembersCsvResultsTable,
} from './components'
import MemberToolsTemplateCsv from './assets/member-tools-template.csv?url'

export interface UploadMemberInvitesCsvProps {
  invitingMembers: boolean
  invitedMembers: boolean
  processedMemberCount: number
  onAddMemberInvites: (members: any[]) => void
}

const UploadMemberInvitesCsv = ({
  invitingMembers,
  invitedMembers,
  processedMemberCount,
  onAddMemberInvites,
}: UploadMemberInvitesCsvProps) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [uploadedMembersSubmitted, setUploadedMembersSubmitted] =
    useState(false)
  const [uploadedFileName, setUploadedFileName] = useState('')
  const [uploadedMembers, setUploadedMembers] = useState<any[]>([])
  const [members, setMembers] = useState<any[]>([])
  const growlActions = WebUI.useGrowlActions()

  return (
    <div>
      <span
        className="cursor-pointer text-tint"
        onClick={() => setIsExpanded((prevIsExpanded) => !prevIsExpanded)}
      >
        Upload Member Data
      </span>
      {Boolean(isExpanded) && (
        <div className="mt-4 bg-gray100 p-4">
          {(() => {
            if (invitingMembers) {
              return (
                <UploadMemberInvitesProgress
                  memberCount={uploadedMembers.length}
                  processedMemberCount={processedMemberCount}
                />
              )
            }

            if (invitedMembers) {
              return (
                <UploadMemberInvitesSuccess
                  memberCount={uploadedMembers.length}
                />
              )
            }

            if (uploadedMembersSubmitted) {
              return (
                <UploadMembersCsvResultsTable
                  validMembersCount={
                    uploadedMembers.filter((x) => isMemberValid(x)).length
                  }
                  initialMembers={uploadedMembers}
                  onChangeMembers={setMembers}
                />
              )
            }

            return (
              <>
                {' '}
                <p className="text-ds-sm">
                  The following fields are required for account creation: first
                  name, last name, email address, currency, account type.
                  <br />
                  Other optional fields include: physical address, Tax ID,
                  organization name, bank account information and date of birth.{' '}
                  <a href={MemberToolsTemplateCsv}>Click here</a> to download a
                  <br />
                  CSV template for upload.
                </p>
                <WebUI.FileUploader
                  accept={{'text/csv': ['.csv']}}
                  multiple={false}
                  onDropAccepted={([csvFile]: File[]) => {
                    if (!csvFile) {
                      return
                    }

                    setUploadedFileName(csvFile.name)

                    Papa.parse(csvFile as any, {
                      header: true,
                      skipEmptyLines: 'greedy',
                      complete: ({data}: {data: any}) => {
                        const newUploadedMembers = data
                          .map((member: any) =>
                            Util.omit(
                              {
                                ...member,
                                address: {
                                  city: member['address/city'],
                                  line1: member['address/line1'],
                                  state: member['address/state'],
                                  country: member['address/country'],
                                  postal_code: member['address/postal_code'],
                                },
                                dob: [
                                  member['dob/month'],
                                  member['dob/day'],
                                  member['dob/year'],
                                ].every((dobComponent) => !dobComponent)
                                  ? undefined
                                  : `${member['dob/month']}/${member['dob/day']}/${member['dob/year']}`,
                                bankAccount: {
                                  accountNumber:
                                    member['bank_account/account_number'],
                                  routingNumber:
                                    member['bank_account/routing_number'],
                                },
                              },
                              [
                                'address/city',
                                'address/line1',
                                'address/line2',
                                'address/state',
                                'address/country',
                                'address/postal_code',
                                'dob/day',
                                'dob/month',
                                'dob/year',
                                'bank_account/account_number',
                                'bank_account/routing_number',
                              ],
                            ),
                          )
                          .map((member: any) => ({
                            ...member,
                            address: Object.values(member.address).every(
                              (addressComponent) => !addressComponent,
                            )
                              ? undefined
                              : member.address,
                            bankAccount: Object.values(
                              member.bankAccount,
                            ).every(
                              (bankAccountComponent) => !bankAccountComponent,
                            )
                              ? undefined
                              : member.bankAccount,
                          }))

                        const uniqueUploadedMembers = Util.uniqueBy(
                          newUploadedMembers,
                          (m: any) => m.email,
                        )

                        setUploadedMembers(uniqueUploadedMembers)
                        setMembers(uniqueUploadedMembers)
                      },
                    })
                  }}
                >
                  <WebUI.FileUploaderInput />
                  <WebUI.FileUploaderDropzone
                    className={
                      'mt-2 h-[256px] max-w-[512px] items-center justify-center rounded border-2 border-dashed bg-natural-100'
                    }
                    as={WebUI.VStack}
                  >
                    {uploadedFileName || 'Browse for your csv'}
                  </WebUI.FileUploaderDropzone>
                </WebUI.FileUploader>
              </>
            )
          })()}
          <div
            className={WebUI.cn(
              'flex items-center',
              invitingMembers ? 'mt-16' : 'mt-2',
            )}
          >
            {(invitingMembers || invitedMembers) && (
              <div className="font-normal text-ds-md">
                Total Accounts Processed:{' '}
                {invitingMembers
                  ? processedMemberCount
                  : uploadedMembers.length}
              </div>
            )}
            {!invitedMembers && !invitingMembers && (
              <div className="flex min-h-0 min-w-0 flex-auto items-center justify-end">
                <WebUI.Button
                  className="mr-2"
                  variant="secondary"
                  onClick={() => {
                    if (uploadedFileName.length === 0) {
                      setIsExpanded(false)
                      return
                    }

                    setUploadedMembers([])
                    setUploadedMembersSubmitted(false)
                    setMembers([])
                    setUploadedFileName('')
                  }}
                >
                  Cancel
                </WebUI.Button>
                <WebUI.Button
                  type="submit"
                  disabled={uploadedMembers.length === 0}
                  onClick={() => {
                    const membersWithErrors = members.filter(
                      (m) => !isMemberValid(m),
                    )
                    const uploadedMembersWithErrors = uploadedMembers.filter(
                      (m) => !isMemberValid(m),
                    )

                    if (
                      uploadedMembersSubmitted &&
                      uploadedMembersWithErrors.length === 0
                    ) {
                      const membersToAdd = uploadedMembers.map((member) => {
                        const dobDate = member.dob
                          ? Util.parseDate(member.dob, 'MM/dd/yyyy', new Date())
                          : null

                        return {
                          ...member,
                          dob: dobDate
                            ? {
                                day: String(Util.getDate(dobDate)),
                                month: String(Util.getMonth(dobDate) + 1),
                                year: String(Util.getYear(dobDate)),
                              }
                            : undefined,
                        }
                      })

                      onAddMemberInvites(membersToAdd)
                    } else if (
                      uploadedMembersSubmitted &&
                      membersWithErrors.length === 0
                    ) {
                      const uploadedMembersWithoutErrors =
                        uploadedMembers.filter((x) => isMemberValid(x))

                      setUploadedMembers([
                        ...uploadedMembersWithoutErrors,
                        ...members,
                      ])
                    } else if (!uploadedMembersSubmitted) {
                      setUploadedMembersSubmitted(true)
                    } else if (uploadedMembersSubmitted) {
                      growlActions.show('error', {
                        title: 'Error',
                        body: 'Please correct errors highlighted in orange.',
                      })
                    }
                  }}
                >
                  {uploadedMembers.some((m) => !isMemberValid(m)) &&
                  uploadedMembersSubmitted
                    ? 'Create Accounts and Send Welcome Emails'
                    : 'Submit'}
                </WebUI.Button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

// MARK: – Helpers

export const isMemberValid = ({
  first_name,
  last_name,
  dob,
  address,
  email,
  currency,
  type,
  business_tax_id,
}: any) =>
  [first_name, last_name].every((v) => memberFieldsValidations.isEmpty(v)) &&
  memberFieldsValidations.isEmail(email) &&
  memberFieldsValidations.isCurrency(currency) &&
  memberFieldsValidations.isAccountType(type) &&
  (!business_tax_id ||
    memberFieldsValidations.isBusinessTaxId(business_tax_id)) &&
  (!dob || memberFieldsValidations.isDob(dob)) &&
  (!address ||
    (((Boolean(address.country) && address.country.length === 0) ||
      memberFieldsValidations.isCountry(address.country)) &&
      ((Boolean(address.state) && address.state.length === 0) ||
        (address.country === 'Canada'
          ? memberFieldsValidations.isCanadianProvince(address.state)
          : memberFieldsValidations.isUSState(address.state))) &&
      ((Boolean(address.postal_code) && address.postal_code.length === 0) ||
        (address.country === 'Canada'
          ? memberFieldsValidations.isCanadianPostalCode(address.postal_code)
          : memberFieldsValidations.isUSPostalCode(address.postal_code)))))

const EMAIL_REGEX =
  /[\w!#$%&'*+/=?^`{|}~-]+(?:\.[\w!#$%&'*+/=?^`{|}~-]+)*@(?:[\dA-Za-z](?:[\dA-Za-z-]*[\dA-Za-z])?\.)+[\dA-Za-z](?:[\dA-Za-z-]*[\dA-Za-z])?/
const US_POSTAL_CODE_REGEX = /(^\d{5}$)|(^\d{5}-\d{4}$)/
const CANADA_POSTAL_CODE_REGEX = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/
const BUSINESS_TAX_ID_REGEX = /^\d\d?-\d{7}$/
const VALID_COUNTRIES = ['United States', 'Canada']

const getProvincesOfCountryWithCode = (countryIsoCode: string) =>
  (provinces as any)[countryIsoCode].map((province: any) => province.short)

export const memberFieldsValidations = {
  isEmpty: (candidate: string) => Boolean(candidate) && candidate.length !== 0,
  isEmail: (candidate: string) => EMAIL_REGEX.test(candidate),
  isCurrency: (candidate: string) =>
    ['USD', 'CAD'].includes(candidate.toUpperCase()),
  isAccountType: (candidate: string) =>
    ['individual', 'company'].includes(candidate.toLowerCase()),
  isBusinessTaxId: (candidate: string) => BUSINESS_TAX_ID_REGEX.test(candidate),
  isUSState: (candidate: string) => {
    const usStates = getProvincesOfCountryWithCode('US')

    return usStates.includes(candidate)
  },
  isCanadianProvince: (candidate: string) => {
    const canadaProvinces = getProvincesOfCountryWithCode('CA')

    return canadaProvinces.includes(candidate)
  },
  isCountry: (candidate: string) => VALID_COUNTRIES.includes(candidate),
  isUSPostalCode: (candidate: string) => US_POSTAL_CODE_REGEX.test(candidate),
  isCanadianPostalCode: (candidate: string) =>
    CANADA_POSTAL_CODE_REGEX.test(candidate),
  isDob: (candidate: string) => {
    if (!candidate) {
      return false
    }

    const dobParts = candidate.split('/').map(Number)
    const month = dobParts[0]
    const day = dobParts[1]
    const year = dobParts[2]

    return (
      Boolean(month) &&
      Boolean(day) &&
      Boolean(year) &&
      Util.isValidDate(new Date(year ?? 0, month ?? 0, day ?? 0))
    )
  },
}

export default UploadMemberInvitesCsv
