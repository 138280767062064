import * as Yup from 'yup'
import {useFormik} from 'formik'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {
  api,
  useCreateInventoryGroupMutation,
  useCreateItemMutation,
  useUpdateInventoryGroupMutation,
  useUpdateItemMutation,
} from '@cheddarup/api-client'
import ImagesUtils from 'src/helpers/ImagesUtils'
import {saveItemImages} from 'src/helpers/item-helpers'
import {useSaveFields} from 'src/hooks/fields'
import type {FieldsEditValue} from '@cheddarup/core'
import * as Util from '@cheddarup/util'
import {readApiError} from 'src/helpers/error-formatting'

import ItemFormDetails from './FixedItemFormDetails'
import {
  ItemFormAdvancedSettings,
  ItemFormImagesAndDescription,
  ItemFormItemFields,
} from '../../components'
import type {VariantQuantityGroupFormValues} from '../../components/ItemFormDetails/ItemVariantsFormSection/ItemVariantsListingTable/VariantQuantityGroupModal'

export interface ItemFormValuesListing {
  uuid: string
  imageId: number | null
  localImage: {
    image: File
    thumbnail: {
      cropDetails: {
        x: number
        y: number
        width: number
        height: number
      } | null
    }
  } | null
  optionValues: Record<string, string>
  sku: string
  amount: string
  retailPrice: string
  available_quantity: string
  description: string
}

export interface ItemFormValues {
  name: string
  parent_id: number | null
  amount: string
  limit_per_person_quantity_enabled: boolean
  limit_per_person_quantity: string
  allow_quantity: boolean
  available_quantity_enabled: boolean
  available_quantity: string
  description: string
  required: boolean
  images: Array<null | {
    id: number | string
    contentType: string
    thumbnailCrop: Api.CropDetails | null
    image: Blob
  }>
}

interface FixedItemFormValues extends ItemFormValues {
  options: {
    subcategoryId: string | null
    makeAvailableQuantityPublic: boolean
    variants: {
      enabled: boolean
      options: Api.VariantOption[]
      listings: ItemFormValuesListing[]
    }
    isTaxDeductible: boolean
    waitlist: {
      enabled: boolean
      customMessage: string
    }
    quantityDiscount: {
      enabled: boolean
      calculationMethod: Api.TabItemQuantityDiscountCalculationMethod
      fixedAmount: string
      percentage: string
      minimumQuantity: string
    }
  }
  inventoryGroup?: VariantQuantityGroupFormValues
}

export type FixedItemFormFormik = ReturnType<
  typeof useFormik<FixedItemFormValues>
>

Yup.setLocale({mixed: {notType: 'Must be a number'}})

export interface FixedItemFormProps
  extends Omit<React.ComponentPropsWithoutRef<'form'>, 'onSubmit' | 'onReset'> {
  collectionId: number
  item?: Api.TabItem
  fields?: Api.TabObjectField[]
  onDismiss: () => void
  onDirtyChange?: (value: boolean) => void
}

const FixedItemForm: React.FC<FixedItemFormProps> = ({
  collectionId,
  item,
  fields,
  onDismiss,
  onDirtyChange,
  className,
  ...restProps
}) => {
  const {data: availableQuantityOneByDefault} = api.auth.session.useQuery(
    undefined,
    {
      select: (session) => session.user.availableQuantityOneByDefault,
    },
  )
  const inventoryGroupQuery = api.inventoryGroups.list.useQuery(
    {
      pathParams: {
        tabId: collectionId,
      },
    },
    {
      enabled: !!collectionId && !!item,
      select: (inventoryGroups) =>
        inventoryGroups.find((ig) => ig.tab_item_id === item?.id),
    },
  )
  const createItemMutation = useCreateItemMutation()
  const updateItemMutation = useUpdateItemMutation()
  const saveFields = useSaveFields()
  const createInventoryGroupMutation = useCreateInventoryGroupMutation()
  const updateInventoryGroupMutation = useUpdateInventoryGroupMutation()
  const fieldsEditValueRef = useRef<FieldsEditValue[]>([])
  const tabsRef = useRef<WebUI.TabsInstance>(null)
  const growlActions = WebUI.useGrowlActions()
  const [selectedTabId, setSelectedTabId] = useState('details')

  const _itemInventoryGroup = item ? getItemInventoryGroup(item) : null
  const itemInventoryGroup = _itemInventoryGroup ?? inventoryGroupQuery.data
  const ownAvailableQuantity = item?.inventory_items.find(
    (ii) => ii.variant_uuid === 'NONE',
  )?.available_quantity

  const formik = useFormik<FixedItemFormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required('Required')
        .max(75, 'Must be 75 characters or less'),
      description: Yup.string().max(4000, 'Must be 4000 characters or less'),
      amount: Yup.number().when('options.variants.enabled', {
        is: false,
        // biome-ignore lint/suspicious/noThenProperty:
        then: (schema) =>
          schema
            .required('Required')
            .max(999999, 'Price must be less than 1,000,000.00'),
      }),
      options: Yup.object().shape({
        variants: Yup.object().shape({
          enabled: Yup.boolean(),
          options: Yup.array().when('enabled', {
            is: true,
            // biome-ignore lint/suspicious/noThenProperty:
            then: (schema) => schema.min(1, 'Required'),
          }),
          listings: Yup.array().of(
            Yup.object().shape({
              amount: Yup.number()
                .transform((v) => (Number.isNaN(v) ? undefined : v))
                .required('Required')
                .max(999999, 'Price must be less than 1,000,000.00'),
              available_quantity: Yup.number()
                .transform((v) => (Number.isNaN(v) ? undefined : v))
                .nullable(),
            }),
          ),
        }),
        waitlist: Yup.object().shape({
          enabled: Yup.boolean(),
          customMessage: Yup.string(),
        }),
        quantityDiscount: Yup.object().shape({
          enabled: Yup.boolean(),
          calculationMethod: Yup.string(),
          fixedAmount: Yup.number().when(['enabled', 'calculationMethod'], {
            is: (enabled: boolean, cm: string) => enabled && cm === 'fixed',
            // biome-ignore lint/suspicious/noThenProperty:
            then: (schema) =>
              schema
                .required('Required')
                .min(0.1, 'Can not be less than ${min}'),
          }),
          percentage: Yup.number().when(['enabled', 'calculationMethod'], {
            is: (enabled: boolean, cm: string) =>
              enabled && cm === 'percentage',
            // biome-ignore lint/suspicious/noThenProperty:
            then: (schema) =>
              schema
                .required('Required')
                .min(1, 'Can not be less than ${min}')
                .max(100, 'Can not exceed ${max}'),
          }),
          minimumQuantity: Yup.number().when('enabled', {
            is: true,
            // biome-ignore lint/suspicious/noThenProperty:
            then: (schema) => schema.required('Required'),
          }),
        }),
      }),
      available_quantity_enabled: Yup.boolean(),
      available_quantity: Yup.number().when('available_quantity_enabled', {
        is: true,
        // biome-ignore lint/suspicious/noThenProperty:
        then: (schema) =>
          schema
            .transform((v) => (Number.isNaN(v) ? undefined : v))
            .required('Required'),
      }),
      limit_per_person_quantity_enabled: Yup.boolean(),
      limit_per_person_quantity: Yup.number().when(
        'limit_per_person_quantity_enabled',
        {
          is: true,
          // biome-ignore lint/suspicious/noThenProperty:
          then: (schema) =>
            schema
              .transform((v) => (Number.isNaN(v) ? undefined : v))
              .required('Required')
              .when('options.quantityDiscount.enabled', {
                is: true,
                // biome-ignore lint/suspicious/noThenProperty:
                then: (schema) =>
                  schema.min(
                    Yup.ref('options.quantityDiscount.minimumQuantity'),
                    "Can not be less than quantity discount's minimum quantity",
                  ),
              }),
        },
      ),
    }),
    initialValues: {
      name: item?.name ?? '',
      parent_id: item?.parent_id ?? null,
      amount: item?.amount == null ? '' : String(item.amount.toFixed(2)),
      limit_per_person_quantity_enabled:
        item?.options?.perPersonMaxQuantity?.enabled ?? false,
      limit_per_person_quantity: item?.options?.perPersonMaxQuantity?.value
        ? String(item?.options?.perPersonMaxQuantity?.value)
        : '',
      options: {
        subcategoryId: item?.options?.subcategoryId ?? null,
        makeAvailableQuantityPublic:
          item?.options?.makeAvailableQuantityPublic ?? false,
        variants: {
          enabled: item?.options?.variants?.enabled ?? false,
          options: item?.options?.variants?.options ?? [{key: '', values: []}],
          listings: (item?.options?.variants?.listings ?? []).map(
            (listing) => ({
              uuid: listing.uuid,
              imageId: listing.imageId,
              localImage: null,
              optionValues: listing.optionValues,
              sku: listing.sku ?? '',
              amount: listing.amount ?? null,
              retailPrice:
                listing.retailPrice == null ? '' : String(listing.retailPrice),
              available_quantity:
                listing.available_quantity == null
                  ? ''
                  : Number(listing.available_quantity),
              description: listing.description ?? '',
            }),
          ) as any[],
        },
        isTaxDeductible: item?.options.isTaxDeductible ?? false,
        waitlist: {
          enabled: item?.options?.waitlist?.enabled ?? false,
          customMessage: item?.options?.waitlist?.customMessage ?? '',
        },
        quantityDiscount: {
          enabled: item?.options.quantityDiscount?.enabled ?? false,
          calculationMethod:
            item?.options.quantityDiscount?.calculationMethod ?? 'fixed',
          fixedAmount: String(
            item?.options.quantityDiscount?.fixedAmount ?? '',
          ),
          percentage: String(item?.options.quantityDiscount?.percentage ?? ''),
          minimumQuantity: String(
            item?.options.quantityDiscount?.minimumQuantity ?? '',
          ),
        },
      },
      allow_quantity:
        item?.allow_quantity ?? item?.options?.variants?.enabled ?? false,
      available_quantity_enabled:
        (!item && !!availableQuantityOneByDefault) || item
          ? ownAvailableQuantity != null
          : false,
      available_quantity: String(
        !item && !!availableQuantityOneByDefault
          ? 1
          : (ownAvailableQuantity ?? ''),
      ),
      description: item?.description ?? '',
      required: item?.required ?? false,
      images: getOrderedImages(item?.images ?? []).map((itemImage) => ({
        id: itemImage.id,
        contentType: itemImage.metadata.contentType ?? 'image/jpeg',
        thumbnailCrop:
          Object.keys(itemImage.metadata.thumbnail.cropDetails ?? {}).length > 0
            ? itemImage.metadata.thumbnail.cropDetails
            : null,
        image: {
          ...itemImage,
          preview: ImagesUtils.getImageUrl(itemImage),
        },
      })) as any[],
      inventoryGroup: itemInventoryGroup
        ? {
            availableQuantity: String(itemInventoryGroup.available_quantity),
            selectedVariantUuids: itemInventoryGroup.inventory_items.map(
              (ii) => ii.variant_uuid,
            ),
          }
        : undefined,
    },
    onSubmit: async ({images, ...values}) => {
      const getPayload = (
        uploadedListingsImageIdsEntries: Array<[string[], number]> = [],
      ) => {
        const availableQuantityEnabled =
          !values.options.variants.enabled && values.available_quantity_enabled
        const variantsAvailableQtyEnabled =
          values.options.variants.enabled &&
          values.options.variants.listings?.some(
            (l) => l.available_quantity !== null,
          )

        return {
          name: values.name,
          description: values.description,
          required: values.required,
          parent_id: values.parent_id,
          amount: Number(values.amount),
          amount_type: 'fixed' as const,
          available_quantity_enabled: availableQuantityEnabled,
          allow_quantity: values.allow_quantity,
          available_quantity: values.available_quantity_enabled
            ? Number(values.available_quantity)
            : null,
          options: {
            subcategoryId: values.options.subcategoryId,
            itemSubType: 'fixed' as const,
            makeAvailableQuantityPublic:
              values.options.makeAvailableQuantityPublic,
            variants: {
              enabled: values.options.variants.enabled,
              options: values.options.variants.options.filter(
                (option) => option.key.length > 0 && option.values.length > 0,
              ),
              listings: values.options.variants.listings.map(
                ({localImage, amount: lAmount, retailPrice, ...listing}) => {
                  const imageIdAsStr = uploadedListingsImageIdsEntries.find(
                    ([uuids]) => uuids.includes(listing.uuid),
                  )?.[1]

                  return {
                    ...listing,
                    imageId: imageIdAsStr
                      ? Number(imageIdAsStr)
                      : listing.imageId,
                    amount: Number(lAmount),
                    retailPrice: retailPrice
                      ? Number.parseFloat(retailPrice)
                      : undefined,
                    available_quantity:
                      listing.available_quantity === ''
                        ? null
                        : Number(listing.available_quantity),
                  }
                },
              ),
            },
            isTaxDeductible: values.options.isTaxDeductible,
            waitlist: {
              enabled:
                (availableQuantityEnabled || variantsAvailableQtyEnabled) &&
                values.options.waitlist.enabled,
              customMessage: values.options.waitlist.customMessage,
            },
            quantityDiscount: {
              enabled: values.options.quantityDiscount.enabled,
              calculationMethod:
                values.options.quantityDiscount.calculationMethod,
              fixedAmount:
                Number(values.options.quantityDiscount.fixedAmount) === 0
                  ? undefined
                  : Number(values.options.quantityDiscount.fixedAmount),
              percentage:
                Number(values.options.quantityDiscount.percentage) === 0
                  ? undefined
                  : Number(values.options.quantityDiscount.percentage),
              minimumQuantity: Number(
                values.options.quantityDiscount.minimumQuantity,
              ),
            },
            perPersonMaxQuantity: {
              enabled: values.limit_per_person_quantity_enabled,
              value: values.limit_per_person_quantity_enabled
                ? Number(values.limit_per_person_quantity)
                : 0,
            },
            fieldSets: fieldsEditValueRef.current.map((fev) => fev.fieldSet),
          },
        }
      }

      const localFields = fieldsEditValueRef.current.flatMap(
        (fev) => fev.fields,
      )

      const someCheckboxOrMultipleChoiceFieldsEmpty = localFields
        .filter(
          (f) =>
            f.field_type === 'checkbox' || f.field_type === 'multiple_choice',
        )
        .some((f) => 'values' in f && f.values?.length === 0)
      if (someCheckboxOrMultipleChoiceFieldsEmpty) {
        growlActions.show('error', {
          title: 'Error',
          body: 'Checkbox and dropdown questions require at least one option',
        })
        tabsRef.current?.select('questions')
        return
      }

      let savedItem = item ?? null

      try {
        if (item) {
          const {
            uploadedListingsImageIdsEntries,
            updatedListingImageIdsEntries,
          } = await saveItemImages({
            tabId: collectionId,
            item,
            listings: values.options.variants.listings,
            images: images.filter((image) => image != null),
          })

          const payload = getPayload([
            ...uploadedListingsImageIdsEntries,
            ...updatedListingImageIdsEntries,
          ])

          savedItem = await updateItemMutation.mutateAsync({
            pathParams: {
              tabId: collectionId,
              itemId: item.id,
            },
            body: payload,
          })
        } else {
          const payload = getPayload()
          savedItem = await createItemMutation.mutateAsync({
            pathParams: {
              tabId: collectionId,
            },
            body: payload,
          })

          const {uploadedListingsImageIdsEntries} = await saveItemImages({
            tabId: collectionId,
            item: savedItem,
            listings: values.options.variants.listings,
            images: images.filter((image) => image != null),
          })

          const updateItemPayload = getPayload(uploadedListingsImageIdsEntries)

          await updateItemMutation.mutateAsync({
            pathParams: {
              tabId: collectionId,
              itemId: savedItem.id,
            },
            body: updateItemPayload,
          })
        }

        await saveFields({
          tabId: collectionId,
          tabObjectId: savedItem.id,
          tabObjectType: 'item',
          existingFields: fields ?? [],
          newFields: localFields,
        })

        if (values.inventoryGroup) {
          const savedItemInventoryItems = savedItem.inventory_items
          const savedItemInventoryGroup = getItemInventoryGroup(savedItem)

          const inventoryItemIds = values.inventoryGroup.selectedVariantUuids
            .map((vUuid) =>
              savedItemInventoryItems.find((ii) => ii.variant_uuid === vUuid),
            )
            .filter((ii) => ii != null)
            .map((ii) => ii.id)

          if (savedItemInventoryGroup) {
            await updateInventoryGroupMutation.mutateAsync({
              pathParams: {
                tabId: collectionId,
                inventoryGroupId: savedItemInventoryGroup.id,
              },
              body: {
                tab_item_id: savedItem.id,
                available_quantity: Number(
                  values.inventoryGroup.availableQuantity,
                ),
                inventory_item_ids: inventoryItemIds,
              },
            })
          } else {
            await createInventoryGroupMutation.mutateAsync({
              pathParams: {
                tabId: collectionId,
              },
              body: {
                tab_item_id: savedItem.id,
                available_quantity: Number(
                  values.inventoryGroup.availableQuantity,
                ),
                inventory_item_ids: inventoryItemIds,
              },
            })
          }
        }

        onDismiss()
      } catch (err) {
        const errMessage = readApiError(err)
        if (errMessage) {
          growlActions.clear()
          growlActions.show('error', {body: errMessage})
        }
      }
    },
  })

  const itemImages = useMemo(() => item?.images ?? [], [item?.images])

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    onDirtyChange?.(formik.dirty)
  }, [formik.dirty])

  return (
    <form
      className={WebUI.cn('flex min-h-0 flex-col', className)}
      noValidate
      onSubmit={async (event) => {
        const errors = await formik.validateForm()

        if (Object.keys(errors).length > 0) {
          if (
            formik.errors.available_quantity_enabled ||
            formik.errors.available_quantity ||
            formik.errors.options?.quantityDiscount
          ) {
            tabsRef.current?.select('settings')
          } else if (Object.keys(formik.errors).length > 0) {
            tabsRef.current?.select('details')
          }
        }

        formik.handleSubmit(event)
      }}
      onReset={formik.handleReset}
      {...restProps}
    >
      <WebUI.Tabs
        ref={tabsRef}
        className="min-h-0 grow [&_>_.TabPanel:not([id=questions])]:overflow-y-auto [&_>_.TabPanel:not([id=questions])]:p-6 sm:[&_>_.TabPanel:not([id=questions])]:px-13 sm:[&_>_.TabPanel:not([id=questions])]:py-6 [&_>_.TabPanel[id=questions]]:overflow-y-hidden [&_>_.TabPanel]:grow"
        variant="underlined"
        onChangeSelectedId={(newSelectedId) => {
          if (newSelectedId != null) {
            setSelectedTabId(newSelectedId)
          }
        }}
      >
        <WebUI.TabList
          aria-label="Item form navigation"
          className="mx-6 flex-0 border-b-0 sm:mx-13 [&_>_.TabList-underline]:bg-orange-50 [&_>_.Tab_>_.Button-content]:font-normal [&_>_.Tab_>_.Button-content]:text-ds-sm sm:[&_>_.Tab_>_.Button-content]:text-ds-md"
        >
          <WebUI.Tab id="details">Details</WebUI.Tab>
          <WebUI.Tab id="description">Description</WebUI.Tab>
          <WebUI.Tab id="settings">Settings</WebUI.Tab>
          <WebUI.Tab id="questions">Questions</WebUI.Tab>
        </WebUI.TabList>

        <WebUI.Separator variant="primary" />

        <WebUI.TabPanel id="details">
          <ItemFormDetails
            formik={formik}
            collectionId={collectionId}
            item={item}
            itemImages={itemImages}
          />
        </WebUI.TabPanel>
        <WebUI.TabPanel id="description">
          <ItemFormImagesAndDescription
            collectionId={collectionId}
            formik={formik}
            itemType="fixed"
          />
        </WebUI.TabPanel>
        <WebUI.TabPanel id="settings">
          <ItemFormAdvancedSettings
            formik={formik}
            tabId={collectionId}
            item={item}
          />
        </WebUI.TabPanel>
        <WebUI.TabPanel id="questions">
          <ItemFormItemFields
            className="max-h-full"
            initialFieldSets={item?.options.fieldSets ?? undefined}
            initialFields={fields}
            onInit={(initialFieldsEditValue) => {
              fieldsEditValueRef.current = initialFieldsEditValue
            }}
            onChange={(newFieldsEditValue) => {
              const localFieldSets = newFieldsEditValue.map(
                (fev) => fev.fieldSet,
              )
              const localFields = newFieldsEditValue.flatMap(
                (fev) => fev.fields,
              )

              onDirtyChange?.(
                formik.dirty ||
                  !Util.deepEqual(localFieldSets, item?.options.fieldSets) ||
                  !Util.deepEqual(localFields, fields),
              )

              fieldsEditValueRef.current = newFieldsEditValue
            }}
          />
        </WebUI.TabPanel>
      </WebUI.Tabs>

      <WebUI.Separator />
      <div className="flex flex-row justify-end bg-natural-100 px-4 py-5">
        {!item && selectedTabId !== 'questions' ? (
          <WebUI.Button
            variant="default"
            size="large"
            onClick={() => tabsRef.current?.next()}
          >
            Continue
          </WebUI.Button>
        ) : (
          <WebUI.Button
            type="submit"
            variant="primary"
            size="large"
            loading={formik.isSubmitting}
          >
            Save
          </WebUI.Button>
        )}
      </div>
    </form>
  )
}

// MARK: – Helpers

export function getOrderedImages(images: Api.S3Image[]) {
  return Util.sort(
    images.map((image, idx) => ({
      ...image,
      metadata: {
        ...image.metadata,
        thumbnail: {
          ...image.metadata.thumbnail,
          order: image.metadata.thumbnail?.order ?? idx,
        },
      },
    })),
  ).asc((i) => i.metadata.thumbnail.order)
}

function getItemInventoryGroup(item: Api.TabItem) {
  // there should be only one inventory group per item
  const _inventoryGroup = item?.inventory_items
    .filter((ii) => ii.variant_uuid !== 'NONE')
    .flatMap((ii) => ii.inventory_groups)[0]

  return _inventoryGroup
    ? {
        ..._inventoryGroup,
        inventory_items: item?.inventory_items.filter((ii) =>
          ii.inventory_groups.some((ig) => ig.id === _inventoryGroup.id),
        ),
      }
    : undefined
}

export default FixedItemForm
