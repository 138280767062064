import {makeShortId} from '@cheddarup/util'
import blueImpLoadImage from 'blueimp-load-image'

export function makeCanvasFromText(
  text: string,
  options?: {
    // see https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/font
    font?: string
    color?: string
  },
) {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    throw new Error('Failed to get 2d context')
  }

  if (options?.font) {
    ctx.font = options.font
  }
  ctx.textRendering = 'geometricPrecision'
  ctx.textBaseline = 'top'
  ctx.imageSmoothingEnabled = false

  const ratio = window.devicePixelRatio * 2

  const textMetrics = ctx.measureText(text)

  const fontHeight =
    textMetrics.fontBoundingBoxAscent + textMetrics.fontBoundingBoxDescent
  const actualHeight =
    textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent
  const textHeight = Math.max(fontHeight, actualHeight)

  // this resets all previous ctx props, we'll have to set them again
  ctx.canvas.width = textMetrics.width * ratio
  ctx.canvas.height = textHeight * ratio

  ctx.canvas.style.width = `${textMetrics.width}px`
  ctx.canvas.style.height = `${textHeight}px`

  ctx.scale(ratio, ratio)

  ctx.textRendering = 'geometricPrecision'
  ctx.textBaseline = 'top'
  ctx.imageSmoothingEnabled = false

  if (options?.font) {
    ctx.font = options.font
  }
  if (options?.color) {
    ctx.fillStyle = options?.color
  }

  ctx.fillText(text, 0, 0)

  return trimCanvas(canvas)
}

export function trimCanvas(canvas: HTMLCanvasElement) {
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    throw new Error('Failed to get 2d context')
  }

  let w = canvas.width
  let h = canvas.height
  const pix: {x: number[]; y: number[]} = {x: [], y: []}
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
  let x: number
  let y: number
  let index: number
  for (y = 0; y < h; y++) {
    for (x = 0; x < w; x++) {
      index = (y * w + x) * 4
      const dataItem = imageData.data[index + 3]
      if (dataItem != null && dataItem > 0) {
        pix.x.push(x)
        pix.y.push(y)
      }
    }
  }
  pix.x.sort((a, b) => a - b)
  pix.y.sort((a, b) => a - b)
  const n = pix.x.length - 1

  w = 1 + (pix.x[n] ?? 0) - (pix.x[0] ?? 0)
  h = 1 + (pix.y[n] ?? 0) - (pix.y[0] ?? 0)
  const cut = ctx.getImageData(pix.x[0] ?? 0, pix.y[0] ?? 0, w, h)

  canvas.width = w
  canvas.height = h
  ctx.putImageData(cut, 0, 0)

  return canvas
}

export function isCanvasBlank(canvas: HTMLCanvasElement) {
  const context = canvas.getContext('2d')

  if (!context || canvas.height === 0 || canvas.width === 0) {
    return true
  }

  const pixelBuffer = new Uint32Array(
    context.getImageData(0, 0, canvas.width, canvas.height).data.buffer,
  )

  return !pixelBuffer.some((color) => color !== 0)
}

export interface CroppedImageBlob extends Blob {
  name: string
  preview: string
}

export const getCroppedImage = (
  image: HTMLImageElement,
  pixelCrop: {x: number; y: number; width: number; height: number},
) => {
  const canvas = document.createElement('canvas')
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    return null
  }

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height,
  )

  // change non-opaque pixels to white
  const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height)
  const {data} = imgData
  for (let i = 0; i < data.length; i += 4) {
    // biome-ignore lint/style/noNonNullAssertion:
    if (data[i + 3]! < 255) {
      data[i] = 255
      data[i + 1] = 255
      data[i + 2] = 255
      data[i + 3] = 255
    }
  }
  ctx.putImageData(imgData, 0, 0)

  return new Promise<CroppedImageBlob>((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob) {
        resolve({
          name: `${makeShortId()}.jpg`,
          preview: URL.createObjectURL(blob),
          ...blob,
        } as any)
      } else {
        reject(new Error('Expected `blob` to be non nil'))
      }
    }, 'image/jpeg')
  })
}

export const resetImageOrientation = async (file: File | Blob): Promise<Blob> =>
  // biome-ignore lint/suspicious/noAsyncPromiseExecutor: <explanation>
  new Promise(async (resolve, reject) => {
    try {
      const res = await blueImpLoadImage(file, {
        orientation: true,
        canvas: true,
      })
      ;(res.image as HTMLCanvasElement).toBlob(
        (blob) =>
          resolve(
            new Blob(blob ? [blob] : undefined, {
              type: file.type,
            }),
          ),
        file.type,
      )
    } catch (err) {
      reject(err)
    }
  })

// Based on https://github.com/mat-sz/imtool

export type ImageType = string | Blob | File | HTMLImageElement

export function loadImage(
  src: string,
  options?: {crossOrigin?: string},
): Promise<HTMLImageElement> {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image()
    img.crossOrigin = options?.crossOrigin ?? 'anonymous'

    img.addEventListener('load', () => {
      resolve(img)
    })

    img.addEventListener('error', (err) => {
      reject(err)
    })

    img.src = src
  })
}

export function fileToDataURL(file: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()

    reader.addEventListener('load', () => {
      resolve(reader.result as string)
    })

    reader.addEventListener('error', (error) => {
      reject(error)
    })

    reader.readAsDataURL(file)
  })
}

export class ImTool {
  private canvas = document.createElement('canvas')
  private ctx = this.canvas.getContext('2d')
  private outputType = 'image/jpeg'
  private outputQuality = 0.7

  readonly originalWidth: number
  readonly originalHeight: number

  /**
   * Creates a new instance of ImTool from an image URL, Blob, File or an <img> element.
   * The image be from the same origin, or from an origin accessible to the website.
   * @param image The image to be loaded.
   */
  static async fromImage(image: ImageType): Promise<ImTool> {
    let url: string | undefined

    if (typeof image === 'string') {
      url = image
    } else if (image instanceof Blob) {
      url = await fileToDataURL(image)
    } else if (image instanceof HTMLImageElement) {
      if (image.complete && image.naturalWidth === 0) {
        return new ImTool(image)
      }
      url = image.src
    }

    if (url) {
      const img = await loadImage(url)
      return new ImTool(img)
    }
    throw new Error('Unable to load the image.')
  }

  /**
   * Constructs a new ImTool instance from a loaded image.
   * Do not use this directly, use from* functions from index.ts instead.
   * @param image Loaded image. Must be from the same origin, or from an origin accessible to the website.
   */
  constructor(image: HTMLCanvasElement | HTMLImageElement | ImageBitmap) {
    if (
      image instanceof HTMLImageElement &&
      !image.complete &&
      image.naturalWidth === 0
    ) {
      throw new Error('Image is not fully loaded.')
    }

    if (image instanceof HTMLImageElement) {
      this.canvas.width = image.naturalWidth
      this.canvas.height = image.naturalHeight
    } else {
      this.canvas.width = image.width
      this.canvas.height = image.height
    }

    this.originalWidth = this.canvas.width
    this.originalHeight = this.canvas.height

    if (!this.ctx) {
      throw new Error('Context initialization failure.')
    }

    this.ctx.drawImage(image, 0, 0, this.canvas.width, this.canvas.height)

    try {
      this.ctx.getImageData(0, 0, 1, 1)
    } catch {
      throw new Error(
        'Canvas is tainted. Images must be from the same origin or current host must be specified in Access-Control-Allow-Origin.',
      )
    }
  }

  get width(): number {
    return this.canvas.width
  }

  get height(): number {
    return this.canvas.height
  }

  /**
   * Crops the image.
   * @param x Horizontal offset.
   * @param y Vertical offset.
   * @param width Width.
   * @param height Height.
   */
  crop(x: number, y: number, width: number, height: number): ImTool {
    if (width <= 0 || height <= 0) {
      throw new Error('All arguments must be postive.')
    }

    const newCanvas = document.createElement('canvas')
    newCanvas.width = width
    newCanvas.height = height

    const ctx = newCanvas.getContext('2d')

    if (!ctx) {
      throw new Error('Context initialization failure.')
    }

    ctx.drawImage(this.canvas, -x, -y, this.canvas.width, this.canvas.height)
    this.canvas = newCanvas

    return this
  }

  /**
   * Scales the image, doesn't preserve ratio.
   * @param width New width.
   * @param height New height.
   */
  scale(width: number, height: number): ImTool {
    if (width <= 0 || height <= 0) {
      throw new Error('All arguments must be postive.')
    }

    const newCanvas = document.createElement('canvas')
    newCanvas.width = width
    newCanvas.height = height

    const ctx = newCanvas.getContext('2d')

    if (!ctx) {
      throw new Error('Context initialization failure.')
    }

    ctx.drawImage(this.canvas, 0, 0, width, height)
    this.canvas = newCanvas

    return this
  }

  /**
   * Flips the image.
   * @param vertical When true the image will be flipped vertically, otherwise it will be flipped horizontally.
   */
  flip(vertical = false): ImTool {
    const newCanvas = document.createElement('canvas')
    newCanvas.width = this.canvas.width
    newCanvas.height = this.canvas.height

    const ctx = newCanvas.getContext('2d')

    if (!ctx) {
      throw new Error('Context initialization failure.')
    }

    if (vertical) {
      ctx.translate(0, this.canvas.height)
      ctx.scale(1, -1)
    } else {
      ctx.translate(this.canvas.width, 0)
      ctx.scale(-1, 1)
    }

    ctx.drawImage(this.canvas, 0, 0, this.canvas.width, this.canvas.height)
    this.canvas = newCanvas

    return this
  }

  /**
   * Flips the image horizontally.
   */
  flipH(): ImTool {
    return this.flip(false)
  }

  /**
   * Flips the image vertically.
   */
  flipV(): ImTool {
    return this.flip(true)
  }

  /**
   * Generates a thumbnail.
   * @param maxSize Maximum width or height.
   * @param cover When true this will cause the thumbnail to be a square and image will be centered with its smallest dimension becoming as large as maxDimension and the overflow being cut off. Default: false.
   */
  thumbnail(maxSize: number, cover = false): ImTool {
    const newCanvas = document.createElement('canvas')
    const ctx = newCanvas.getContext('2d')

    if (!ctx) {
      throw new Error('Context initialization failure.')
    }

    let scale = 1
    let x = 0
    let y = 0
    let width = 0
    let height = 0

    if (cover) {
      if (this.canvas.width > this.canvas.height) {
        scale = maxSize / this.canvas.height
        width = this.canvas.width * scale
        height = maxSize
        x = (-1 * (width - maxSize)) / 2
      } else {
        scale = maxSize / this.canvas.width
        width = maxSize
        height = this.canvas.height * scale
        y = (-1 * (height - maxSize)) / 2
      }

      newCanvas.width = maxSize
      newCanvas.height = maxSize
    } else {
      // If any of the dimensions of the given image is higher than our maxSize
      // scale the image down, otherwise leave it as is.
      scale = Math.min(
        Math.min(maxSize / this.canvas.width, maxSize / this.canvas.height),
        1,
      )

      width = this.canvas.width * scale
      height = this.canvas.height * scale

      newCanvas.width = width
      newCanvas.height = height
    }

    ctx.drawImage(this.canvas, x, y, width, height)
    this.canvas = newCanvas

    return this
  }

  /**
   * Rotates the image by a given amount of radians relative to the center of the image. This will change the size of the canvas to fit new image.
   * @param rad Radians.
   */
  rotate(rad: number): ImTool {
    const newCanvas = document.createElement('canvas')

    let angle = rad % (Math.PI * 2)
    if (angle > Math.PI / 2) {
      if (angle <= Math.PI) {
        angle = Math.PI - angle
      } else if (angle <= (Math.PI * 3) / 2) {
        angle = angle - Math.PI
      } else {
        angle = Math.PI * 2 - angle
      }
    }

    // Optimal dimensions for image after rotation.
    newCanvas.width =
      this.canvas.width * Math.cos(angle) +
      this.canvas.height * Math.cos(Math.PI / 2 - angle)
    newCanvas.height =
      this.canvas.width * Math.sin(angle) +
      this.canvas.height * Math.sin(Math.PI / 2 - angle)

    const ctx = newCanvas.getContext('2d')

    if (!ctx) {
      throw new Error('Context initialization failure.')
    }

    ctx.save()

    ctx.translate(newCanvas.width / 2, newCanvas.height / 2)
    ctx.rotate(rad)
    ctx.drawImage(this.canvas, -this.canvas.width / 2, -this.canvas.height / 2)

    ctx.restore()

    this.canvas = newCanvas

    return this
  }

  /**
   * Rotates the image by a given amount of degrees relative to the center of the image. This will change the size of the canvas to fit new image.
   * @param degrees Degrees.
   */
  rotateDeg(degrees: number): ImTool {
    return this.rotate((degrees * Math.PI) / 180)
  }

  /**
   * Sets the input type. (Default: image/jpeg)
   * @param type Type, can be anything supported by the browser, common examples: image/jpeg and image/png.
   */
  type(type: string): ImTool {
    this.outputType = type
    return this
  }

  /**
   * Sets the quality for lossy compression (like image/jpeg). Default: 0.7.
   * @param quality Quality from 0 to 1.
   */
  quality(quality: number): ImTool {
    this.outputQuality = quality
    return this
  }

  /**
   * Exports the resulting image as blob.
   */
  toBlob(type: string = this.outputType): Promise<Blob> {
    return new Promise((resolve, reject) => {
      try {
        this.canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(blob)
            } else {
              reject(new Error('Blob unavailable.'))
            }
          },
          type || this.outputType,
          this.outputQuality,
        )
      } catch (e) {
        // Probably caused by a tainted canvas (i.e. a resource from a foreign origin.)
        reject(e)
      }
    })
  }

  /**
   * Exports the resulting image as blob URL.
   */
  async toBlobURL(type: string = this.outputType): Promise<string> {
    return URL.createObjectURL(await this.toBlob(type || this.outputType))
  }

  /**
   * Exports the resulting image as data URL.
   */
  toDataURL(): Promise<string> {
    return new Promise((resolve, reject) => {
      let dataUrl: null | string = null
      try {
        dataUrl = this.canvas.toDataURL(this.outputType, this.outputQuality)
      } catch (err) {
        // Probably caused by a tainted canvas (i.e. a resource from a foreign origin.)
        reject(err)
        return
      }

      resolve(dataUrl)
    })
  }

  /**
   * Exports the resulting image as HTMLCanvasElement.
   */
  toCanvas(): Promise<HTMLCanvasElement> {
    return new Promise((resolve, reject) => {
      const newCanvas = document.createElement('canvas')
      newCanvas.width = this.canvas.width
      newCanvas.height = this.canvas.height

      const ctx = newCanvas.getContext('2d')

      if (!ctx) {
        reject(new Error('Context initialization failure.'))
        return
      }

      ctx.drawImage(this.canvas, 0, 0, this.canvas.width, this.canvas.height)

      resolve(newCanvas)
    })
  }

  /**
   * Exports the resulting image as a HTMLImageElement.
   */
  async toImage(): Promise<HTMLImageElement> {
    const url = await this.toDataURL()
    return await loadImage(url)
  }

  /**
   * Downloads the resulting image.
   * @param name
   */
  async toDownload(name: string): Promise<void> {
    const url = await this.toDataURL()
    const element = document.createElement('a')
    element.setAttribute('href', url)
    element.setAttribute('download', name)

    element.style.display = 'none'
    element.click()
  }

  /**
   * Exports the resulting image as File.
   * @param name
   */
  async toFile(name: string): Promise<File> {
    const blob = await this.toBlob()
    return new File([blob], name, {lastModified: Date.now()})
  }
}
