import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import Papa from 'papaparse'
import React, {useMemo, useState} from 'react'
import {api, useUpdateTabPaymentMutation} from '@cheddarup/api-client'
import {LinkButton} from 'src/components/LinkButton'
import {SharpAvatar} from 'src/components/SharpAvatar'
import {PaymentNoteButtonDropdown, SearchForm} from 'src/components'
import {Link} from 'src/components/Link'
import config from 'src/config'
import {formatPaymentMethod} from 'src/helpers/formatPaymentMethod'
import {useManagerRole} from 'src/components/ManageRoleProvider'

import {
  DISPUTE_STATUSES,
  PAYMENT_METHOD_STATUSES,
  PAYMENT_METHOD_TOOLTIPS,
  PAYMENT_STATUS_COLORS,
} from '../constants'
import {NoPayments} from './components'

const PAYMENTS_PER_PAGE = 50

export interface CollectionManagePaymentsFilters {
  filters: Record<string, string | Record<string, string | number> | string[]>
  page: number
  perPage: number
  direction: string
  sort: string
  search: string
}

export interface CollectionManagePaymentsTableProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collection: Api.Tab
  collectionId: number
}

const columnHelper = WebUI.createColumnHelper<Api.TabPayment>()

const CollectionManagePaymentsTable = ({
  collection,
  collectionId,
  ...restProps
}: CollectionManagePaymentsTableProps) => {
  const [query, setQuery] = useState({
    page: 1,
    perPage: PAYMENTS_PER_PAGE,
    direction: 'desc',
    sort: 'created_at',
    search: '',
  })
  const [filters, setFilters] = useState<Record<string, WebUI.FilterField>>({})
  const {data: session} = api.auth.session.useQuery()
  const [downloadingPayments, setDownloadingPayments] = useState(false)
  const updatePaymentMutation = useUpdateTabPaymentMutation()

  const paymentsSearchQueryBody = useMemo(() => {
    const apiFilters = Object.entries(filters).reduce(
      (
        acc: Record<
          string,
          Record<string, string | number> | string | string[]
        >,
        [filterId, filter],
      ) => {
        let newFilter:
          | Record<string, string | number>
          | string
          | string[]
          | undefined = undefined

        if (filterId === 'amount') {
          const parsedValues = filter.values.map((v) => Number.parseFloat(v))
          // @ts-expect-error
          newFilter = {
            equals: {min: parsedValues[0], max: parsedValues[0]},
            between: {min: parsedValues[0], max: parsedValues[1]},
            lt: {max: parsedValues[0]},
            gt: {min: parsedValues[0]},
          }[filter.operator]
        } else if (filterId === 'date') {
          // @ts-expect-error
          newFilter = {
            dateEquals: {min: filter.values[0], max: filter.values[1]},
            inDateRange: {min: filter.values[0], max: filter.values[1]},
            afterDate: {min: filter.values[0]},
            beforeDate: {max: filter.values[1]},
          }[filter.operator]
        } else if (filterId === 'method' || filterId === 'status') {
          newFilter = filter.values
        } else if (filterId === 'payment_type') {
          newFilter = filter.values[0]
        }
        if (newFilter == null) {
          return acc
        }

        return {...acc, [filterId]: newFilter}
      },
      {},
    )

    return {...query, filters: apiFilters}
  }, [filters, query])

  const paymentsSearchQuery = api.tabPayments.search.useQuery(
    {
      pathParams: {
        tabId: collection.id,
      },
      body: paymentsSearchQueryBody,
    },
    {
      placeholderData: (prevData) => prevData,
    },
  )

  const {data: payments, pagination} = paymentsSearchQuery.data ?? {
    data: [],
    pagination: {},
  }

  const columns = useMemo(
    () => [
      columnHelper.accessor((p) => p.tab_member.name, {
        id: 'tab_members.last_name',
        size: 240,
        meta: {
          subtle: false,
        },
        header: 'Payer',
        cell: ({cell, row: {original: payment}}) => (
          <WebUI.HStack className="min-w-0 items-center gap-3 self-start">
            <Link to={`collection/${collection.id}/payment/${payment.id}`}>
              <SharpAvatar
                size={40}
                name={cell.getValue()}
                image={payment.tab_member.profile_pic}
              />
            </Link>
            <WebUI.VStack className="min-w-0 gap-1">
              <WebUI.Ellipsis>{cell.getValue()}</WebUI.Ellipsis>
              {payment.note && (
                <WebUI.DeprecatedTooltip
                  label={payment.note}
                  className="cursor-pointer"
                >
                  <PaymentNoteButtonDropdown
                    className="text-left"
                    variant="text"
                    payment={payment}
                  >
                    <span className="line-clamp-1 whitespace-normal pr-[3px] font-light text-ds-sm text-gray600 italic">
                      {payment.note}
                    </span>
                  </PaymentNoteButtonDropdown>
                </WebUI.DeprecatedTooltip>
              )}
            </WebUI.VStack>
          </WebUI.HStack>
        ),
      }),
      columnHelper.accessor((p) => p.total, {
        id: 'total',
        size: 120,
        meta: {
          subtle: false,
        },
        header: 'Amount',
        cell: ({cell, row: {original: payment}}) => {
          const scheduledInvoices = payment.scheduled_invoices ?? []

          return isPaymentScheduledForFuture(payment)
            ? null
            : payment.subtotal > 0
              ? Util.formatAmount(cell.getValue() ?? 0)
              : scheduledInvoices.length === 0 && payment.subtotal === 0
                ? 'No Payment'
                : null
        },
      }),
      columnHelper.accessor((p) => formatPaymentMethod(p), {
        id: 'payment_method',
        size: 140,
        meta: {
          subtle: false,
        },
        header: 'Method',
        cell: ({cell, row: {original: payment}}) => (
          <WebUI.VStack>
            <div className="SubCell">
              <WebUI.Text className="font-light text-gray600">
                {cell.getValue()}
              </WebUI.Text>
            </div>
            {hasFutureScheduledInvoicesAndOthers(payment) && (
              <div className="SubCell">
                <WebUI.Text className="font-light text-gray600">
                  {cell.getValue()}
                </WebUI.Text>
              </div>
            )}
          </WebUI.VStack>
        ),
      }),
      columnHelper.accessor((p) => new Date(p.created_at), {
        id: 'created_at',
        size: 120,
        header: 'Date',
        cell: ({cell, row: {original: payment}}) => {
          const createdAtFormatted = Util.formatDateAs(cell.getValue())

          if (
            (!payment.scheduled_invoices ||
              payment.scheduled_invoices.length === 0) &&
            !payment.recurring_payment_invoice
          ) {
            return (
              <WebUI.Ellipsis className="font-light text-gray600">
                {createdAtFormatted}
              </WebUI.Ellipsis>
            )
          }

          const to = `/collection/${collection.id}/manage/collection/${collection.id}/payment/${payment.id}?termsAndHistory=1`
          const recurringDateLink = (
            <WebUI.DeprecatedTooltip
              variant="light"
              placement="top"
              label={
                <>
                  This includes a future recurring
                  <br />
                  charge.{' '}
                  <Link variant="primary" to={to}>
                    View terms
                  </Link>
                </>
              }
            >
              <Link
                className="[&_>_.Anchor-content]:font-light"
                variant="primary"
                iconAfter={<WebUI.PhosphorIcon icon="arrows-clockwise" />}
                to={to}
              >
                {createdAtFormatted}
              </Link>
            </WebUI.DeprecatedTooltip>
          )

          if (hasFutureScheduledInvoicesAndOthers(payment)) {
            return (
              <WebUI.VStack>
                <div className="SubCell">
                  <WebUI.Ellipsis className="font-light text-gray600">
                    {createdAtFormatted}
                  </WebUI.Ellipsis>
                </div>
                <div className="SubCell">{recurringDateLink}</div>
              </WebUI.VStack>
            )
          }

          return recurringDateLink
        },
      }),
      columnHelper.accessor((p) => p.status, {
        id: 'status',
        size: 120,
        header: 'Status',
        cell: ({
          cell: {
            row: {original: payment},
          },
        }) => {
          if (payment.payment_method === 'cash') {
            return (
              <WebUI.Checkbox
                size="compact"
                state={payment.status === 'available'}
                onChange={() =>
                  updatePaymentMutation.mutate({
                    pathParams: {
                      tabId: collection.id,
                      paymentId: payment.id,
                    },
                    body: {
                      status:
                        payment.status === 'available' ? 'pending' : 'approved',
                    },
                  })
                }
              >
                Received
              </WebUI.Checkbox>
            )
          }

          if (
            !payment.dispute &&
            hasFutureScheduledInvoicesAndOthers(payment)
          ) {
            return (
              <WebUI.VStack>
                <div className="SubCell">
                  <PaymentStatus payment={payment} />
                </div>
                <span className="SubCell">Scheduled</span>
              </WebUI.VStack>
            )
          }

          if (!payment.dispute && isPaymentScheduledForFuture(payment)) {
            return <span>Scheduled</span>
          }

          return payment.subtotal > 0 ? (
            <PaymentStatus payment={payment} />
          ) : null
        },
      }),
      columnHelper.display({
        id: 'actions',
        minSize: 44,
        size: 44,
        meta: {
          align: 'right',
        },
        cell: ({row: {original: payment}}) => (
          <PaymentActionsDropdown
            payment={payment}
            shippingSummaryVisible={
              !!session &&
              !session.capabilities.subscribed_to_team &&
              !session.capabilities.subscribed_to_pro &&
              payment.shipment?.purchased
            }
          />
        ),
      }),
    ],
    [collection.id, session, updatePaymentMutation],
  )

  const paginationState = useMemo(
    () => ({
      pageSize: PAYMENTS_PER_PAGE,
      pageIndex: query.page - 1,
    }),
    [query.page],
  )
  const sortingState = useMemo(
    () => [
      {
        id: query.sort,
        desc: query.direction === 'desc',
      },
    ],
    [query.sort, query.direction],
  )

  const pageCount = Math.ceil((pagination.total ?? 0) / PAYMENTS_PER_PAGE)

  return (
    <WebUI.Panel {...restProps}>
      <WebUI.VStack
        className={
          'items-stretch justify-center gap-4 border-b px-4 py-4 sm:flex-row sm:items-center sm:justify-between sm:px-8'
        }
      >
        <WebUI.VStack className="items-start justify-center gap-4 sm:flex-row sm:items-center sm:justify-start">
          <WebUI.Text className="text-ds-sm">
            Payments:{' '}
            {paymentsSearchQuery.isPending ? (
              <WebUI.Skeleton width={60} height={12} />
            ) : (
              pagination.total
            )}
          </WebUI.Text>

          <WebUI.FiltersPopover>
            {(popover) => (
              <WebUI.Filters
                className="min-w-[280px] sm:min-w-[340px]"
                filters={filters}
                onFiltersApply={(newFilters) => {
                  setFilters(newFilters)
                  popover.hide()
                }}
              >
                <WebUI.FilterFieldEntry filterId="amount" dataType="currency">
                  Amount
                </WebUI.FilterFieldEntry>
                <WebUI.FilterFieldEntry filterId="date" dataType="date">
                  Payment Date
                </WebUI.FilterFieldEntry>
                <WebUI.FilterFieldEntry
                  filterId="method"
                  dataType="checkbox"
                  enumValues={[
                    {title: 'Credit Card', value: 'card'},
                    {title: 'eCheck', value: 'echeck'},
                    {title: 'Cash/Check', value: 'cash'},
                  ]}
                >
                  Payment Method
                </WebUI.FilterFieldEntry>
                <WebUI.FilterFieldEntry
                  filterId="status"
                  dataType="checkbox"
                  enumValues={[
                    {title: 'Cleared', value: 'available'},
                    {title: 'Pending', value: 'pending'},
                    {title: 'Refunded', value: 'refunded'},
                    {title: 'Disputed', value: 'disputed'},
                    {title: 'Failed', value: 'failed'},
                  ]}
                >
                  Payment Status
                </WebUI.FilterFieldEntry>
                <WebUI.FilterFieldEntry
                  filterId="payment_type"
                  dataType="radio"
                  enumValues={[
                    {title: 'One-Time', value: 'once'},
                    {title: 'Recurring', value: 'recurring'},
                  ]}
                >
                  Payment Type
                </WebUI.FilterFieldEntry>
              </WebUI.Filters>
            )}
          </WebUI.FiltersPopover>

          <SearchForm
            className="min-w-[250px] text-ds-xs placeholder:text-ds-xs"
            iconClassName="text-gray400"
            size="compact"
            initialValues={{term: query.search}}
            onSubmit={(values) => {
              if (values.term !== query.search) {
                setQuery((prevQuery) => ({
                  ...prevQuery,
                  search: values.term,
                }))
              }
            }}
            noResult={
              query.search.length > 0 &&
              paymentsSearchQuery.isSuccess &&
              payments.length === 0
            }
            placeholder="Search by name, amount or email"
          />
        </WebUI.VStack>

        <WebUI.HStack className="gap-4">
          <WebUI.DeprecatedTooltip
            label={
              <>
                Record a payment and/or
                <br />
                form submission
              </>
            }
          >
            <LinkButton
              variant="default"
              size="compact"
              target="_blank"
              to={{
                pathname: `/c/${collection.slug}/barriers/visitor-report`,
                search: '?add-payment=1',
              }}
            >
              Record an Order
            </LinkButton>
          </WebUI.DeprecatedTooltip>
          <WebUI.DeprecatedTooltip label="Download Payments table results (.csv)">
            <WebUI.Button
              size="compact"
              aria-label="Download Payments table results (.csv)"
              variant="secondary"
              iconAfter={
                <WebUI.PhosphorIcon icon="download-simple" width={16} />
              }
              disabled={downloadingPayments}
              loading={downloadingPayments}
              onClick={async () => {
                try {
                  setDownloadingPayments(true)
                  const res = await fetchPayments({
                    pathParams: {
                      tabId: collection.id,
                    },
                    body: {
                      ...paymentsSearchQueryBody,
                      page: 1,
                      perPage: pagination.total,
                    },
                  })
                  WebUI.downloadFile(
                    new Blob([makeItemsCSV(res.data ?? [])], {
                      type: 'data:text/csv;charset=utf-8',
                    }),
                    'payments.csv',
                  )
                } catch {
                  // noop
                } finally {
                  setDownloadingPayments(false)
                }
              }}
            >
              Payments Summary
            </WebUI.Button>
          </WebUI.DeprecatedTooltip>
        </WebUI.HStack>
      </WebUI.VStack>

      {query.search.length === 0 &&
      paymentsSearchQuery.isSuccess &&
      payments.length === 0 ? (
        <NoPayments />
      ) : (
        <WebUI.TableView
          className={
            '[&_.TableView-headerGroup]:px-0 sm:[&_.TableView-headerGroup]:px-4 [&_.TableViewCell]:min-h-10 [&_.TableViewCell]:items-center [&_.TableViewCell_.SubCell]:flex [&_.TableViewCell_.SubCell]:min-h-10 [&_.TableViewCell_.SubCell]:items-center [&_.TableViewRow]:px-0 sm:[&_.TableViewRow]:px-4'
          }
          columns={columns}
          loading={paymentsSearchQuery.isPending}
          data={payments}
          state={{
            pagination: paginationState,
            sorting: sortingState,
          }}
          pageCount={pageCount}
          manualPagination
          onPaginationChange={(updater) => {
            const newPagination =
              typeof updater === 'function' ? updater(paginationState) : updater

            setQuery((prevQuery) => ({
              ...prevQuery,
              page: newPagination.pageIndex + 1,
            }))
          }}
          sortable
          sortByTogglesVisible
          manualSortBy
          disableSortRemove
          onSortingChange={(updater) => {
            const [newSorting] =
              typeof updater === 'function' ? updater(sortingState) : updater

            if (newSorting) {
              setQuery((prevQuery) => ({
                ...prevQuery,
                direction: newSorting.desc ? 'desc' : 'asc',
                sort: newSorting.id,
              }))
            }
          }}
        >
          {pageCount > 1 && (
            <WebUI.HStack className="justify-end px-8 py-4">
              <WebUI.TablePaginator />
            </WebUI.HStack>
          )}
        </WebUI.TableView>
      )}
    </WebUI.Panel>
  )
}

// MARK: – PaymentActionsDropdown

interface PaymentActionsDropdownProps
  extends WebUI.MenuButtonProps,
    React.ComponentPropsWithoutRef<'button'> {
  shippingSummaryVisible?: boolean
  payment: Api.TabPayment
}

const PaymentActionsDropdown = React.forwardRef<
  HTMLButtonElement,
  PaymentActionsDropdownProps
>(
  (
    {payment, className, shippingSummaryVisible, ...restProps},
    forwardedRef,
  ) => {
    const [managerRole] = useManagerRole()
    return (
      <WebUI.Menu placement="bottom-start">
        <WebUI.MenuButton
          ref={forwardedRef}
          className={WebUI.cn(
            'rounded-full bg-natural-80 text-gray400',
            className,
          )}
          as={WebUI.IconButton}
          size="compact"
          {...restProps}
        >
          <WebUI.PhosphorIcon icon="dots-three-outline-fill" width={25} />
        </WebUI.MenuButton>

        <WebUI.MenuList className="font-light text-ds-sm" shrinkable={false}>
          <WebUI.MenuItem
            as={Link}
            variant="default"
            to={`payment/${payment.id}/order-summary`}
          >
            Order Summary
          </WebUI.MenuItem>
          {payment.payment_method !== 'cash' &&
            ((payment.scheduled_invoices ?? []).length > 0 ||
              payment.subtotal !== 0) && (
              <WebUI.MenuItem
                as={Link}
                variant="default"
                to={`${payment.id}/refunds`}
              >
                Refunds
              </WebUI.MenuItem>
            )}
          {!!payment.tab_member.email &&
            (!managerRole ||
              managerRole.permissions.address_book_and_message_center) && (
              <WebUI.MenuItem
                as={Link}
                variant="default"
                to={`message-center?to=${payment.tab_member.email}-${payment.tab_member.name}`}
              >
                Send Message
              </WebUI.MenuItem>
            )}
          <WebUI.MenuItem
            as={Link}
            variant="default"
            target="_blank"
            to={`/pdf/collection/${payment.tab_id}/tab-payment/${payment.id}`}
          >
            Print Order
          </WebUI.MenuItem>
          {payment.shipping_info?.charge !== null &&
            !payment.shipment?.purchased && (
              <WebUI.MenuItem
                as={Link}
                variant="default"
                to={`print-shipping-label/${payment.id}`}
              >
                Print Shipping Label
              </WebUI.MenuItem>
            )}
          {shippingSummaryVisible && (
            <WebUI.MenuItem
              as={Link}
              variant="default"
              to={`print-shipping-label-summary/${payment.id}`}
            >
              Shipping Summary
            </WebUI.MenuItem>
          )}
          <WebUI.MenuItem
            hideOnClick={false}
            as={PaymentNoteButtonDropdown}
            payment={payment}
          >
            Add Note
          </WebUI.MenuItem>
        </WebUI.MenuList>
      </WebUI.Menu>
    )
  },
)

// MARK: – PaymentStatus

interface PaymentStatusProps extends React.ComponentPropsWithoutRef<'span'> {
  payment: Api.TabPayment
}

const PaymentStatus = ({
  payment,
  className,
  style,
  ...restProps
}: PaymentStatusProps) => {
  const totalRefund = payment.total_refund || 0
  const fullyRefunded = payment.fully_refunded || false
  const refundedMethod = totalRefund || fullyRefunded ? 'refunded' : ''
  const refunded = !!refundedMethod
  const refundedStatus = fullyRefunded ? 'fully' : 'partial'
  const status = totalRefund ? refundedStatus : payment.status
  const method = refundedMethod || payment.payment_method
  const dispute = payment.dispute
  const availableOn =
    payment.available_on &&
    Util.formatDateAs(Util.addDays(new Date(payment.available_on), 1))
  const title = dispute
    ? DISPUTE_STATUSES[dispute.status]
    : (PAYMENT_METHOD_STATUSES[method ?? '']?.[status] ?? status)

  const tooltip = dispute
    ? () => (
        <>
          Payment Disputed {Util.formatDate(dispute.created_at)}
          <br />
          <a
            href={config.links.disputesSupport}
            rel="noopener noreferrer"
            target="_blank"
          >
            Learn about disputes
          </a>
        </>
      )
    : // @ts-expect-error
      (PAYMENT_METHOD_TOOLTIPS[method]?.[status] ?? (() => status))

  return (
    <WebUI.DeprecatedTooltip
      variant={dispute ? 'light' : 'dark'}
      label={tooltip({payment, availableOn})}
    >
      <span
        className={WebUI.cn(
          refunded ? 'text-tint' : undefined,
          refundedMethod && 'cursor-pointer',
          className,
        )}
        style={
          refunded
            ? style
            : {
                color: (PAYMENT_STATUS_COLORS as any)[
                  dispute ? 'disputed' : status
                ],
                ...style,
              }
        }
        {...restProps}
      >
        {title}
      </span>
    </WebUI.DeprecatedTooltip>
  )
}

// MARK: – Helpers

const getFutureScheduledInvoices = (payment: Api.TabPayment) =>
  payment.scheduled_invoices?.filter(
    (si) => si.status === 'active' && new Date(si.start_date) > new Date(),
  ) ?? []

const hasFutureScheduledInvoicesAndOthers = (payment: Api.TabPayment) => {
  const futureInvoices = getFutureScheduledInvoices(payment).filter(
    (si) =>
      JSON.parse(si.recurring_payment_contract_options).start.type !==
      'first_payment',
  )
  return (
    futureInvoices.length > 0 &&
    payment.items_total > 0 &&
    futureInvoices.some((fi) => fi.amount_pennies > 0)
  )
}

const isPaymentScheduledForFuture = (payment: Api.TabPayment) => {
  const futureInvoices = getFutureScheduledInvoices(payment)

  return (
    futureInvoices.length > 0 &&
    payment.items_total === 0 &&
    futureInvoices.some((fi) => fi.amount_pennies > 0)
  )
}

const makeItemsCSV = (payments: Api.TabPayment[]) => {
  const getTotalPayment = (payment: Api.TabPayment) => {
    const scheduledInvoices = payment.scheduled_invoices ?? []

    return isPaymentScheduledForFuture(payment)
      ? ''
      : payment.subtotal > 0
        ? Util.formatAmount(payment.total ?? 0)
        : scheduledInvoices.length === 0 && payment.subtotal === 0
          ? 'No Payment'
          : ''
  }

  const getPaymentStatus = (payment: Api.TabPayment) => {
    const getStatus = () => {
      const totalRefund = payment.total_refund || 0
      const fullyRefunded = payment.fully_refunded || false
      const refundedMethod = totalRefund || fullyRefunded ? 'refunded' : ''
      const refundedStatus = fullyRefunded ? 'fully' : 'partial'
      const status = totalRefund ? refundedStatus : payment.status
      const method = refundedMethod || payment.payment_method
      const dispute = payment.dispute
      return dispute
        ? DISPUTE_STATUSES[dispute.status]
        : (PAYMENT_METHOD_STATUSES[method ?? '']?.[status] ?? status)
    }

    if (payment.payment_method === 'cash' && payment.status === 'available') {
      return 'Received'
    }

    if (!payment.dispute && hasFutureScheduledInvoicesAndOthers(payment)) {
      return `${getStatus()} Scheduled`
    }

    if (!payment.dispute && isPaymentScheduledForFuture(payment)) {
      return 'Scheduled'
    }

    return payment.subtotal > 0 ? getStatus() : ''
  }

  return Papa.unparse({
    fields: ['Payer Name', 'Amount', 'Method', 'Date', 'Status'],
    data: payments.map((payment) => [
      payment.tab_member.name,
      getTotalPayment(payment),
      formatPaymentMethod(payment),
      Util.formatDateAs(payment.created_at),
      getPaymentStatus(payment),
    ]),
  })
}

const fetchPayments = Util.memoize(api.tabPayments.search.fetch, {
  isMatchingKey: Util.deepEqual,
})

export default CollectionManagePaymentsTable
