import {endpoints} from '../../endpoints'
import {getEndpointKey} from '../../utils'
import {makeQueryUpdate, makeUseMutation} from '../use-mutation'

export const useCreateGroupPageCategoryMutation = makeUseMutation(
  endpoints.groupPageCategories.create,
  () => ({
    regular: (newCategory) => [
      makeQueryUpdate(endpoints.groupPageCategories.list, (categories) => [
        ...(categories || []),
        newCategory,
      ]),
    ],
  }),
)

export const useUpdateGroupPageCategoryMutation = makeUseMutation(
  endpoints.groupPageCategories.update,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(endpoints.groupPageCategories.list, (categories) =>
        categories?.map((c) =>
          c.id === Number(vars.pathParams.id)
            ? {
                ...c,
                ...vars.body?.category,
              }
            : // biome-ignore lint/style/noNonNullAssertion:
              vars.body!.category.position == null
              ? c
              : {
                  ...c,
                  position:
                    // biome-ignore lint/style/noNonNullAssertion:
                    c.position >= vars.body!.category.position
                      ? c.position + 1
                      : c.position,
                },
        ),
      ),
    ],
  }),
)

export const useSortCollectionsInGroupPageCategoryMutation = makeUseMutation(
  endpoints.groupPageCategories.sortCollections,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(endpoints.groupPageCategories.list, (categories) => {
        const updatedCategory = categories?.find(
          (c) => c.id === vars.pathParams.id,
        )

        return categories?.map((c) =>
          c === updatedCategory
            ? {
                ...c,
                collections: (vars.body?.order ?? [])
                  .map((cId) =>
                    updatedCategory.collections.find((c) => c.id === cId),
                  )
                  .filter((c): c is NonNullable<typeof c> => c != null)
                  .map((c, idx) => ({
                    ...c,
                    position: idx + 1,
                  })),
              }
            : c,
        )
      }),
    ],
  }),
)

export const useAddCollectionInGroupPageCategoryMutation = makeUseMutation(
  endpoints.groupPageCategories.addCollections,
  (vars, queryClient) => ({
    optimistic: [
      makeQueryUpdate(endpoints.tabs.list, (tabs) =>
        tabs?.map((tab) =>
          vars.body?.collection_ids.includes(tab.id)
            ? {...tab, collection_section_category_id: vars.body.category_id}
            : tab,
        ),
      ),
      makeQueryUpdate(endpoints.groupPageCategories.list, (categories) => {
        const tabsListQueryKey = getEndpointKey(endpoints.tabs.list)
        const tabs = queryClient.getQueryData<Api.Tab[]>(tabsListQueryKey)

        return categories?.map((c) =>
          c.id === vars.body?.category_id
            ? {
                ...c,
                collections:
                  vars.body?.collection_ids
                    .map((cId) => tabs?.find((t) => t.id === cId))
                    .filter((t) => t != null)
                    .map((t, idx) => ({
                      ...t,
                      collection_section_category_id: Number(c.id),
                      position: idx + 1,
                    })) ?? [],
              }
            : c.id === vars.body?.sourceCategoryId
              ? {
                  ...c,
                  collections: c.collections.filter(
                    (col) => !vars.body?.collection_ids.includes(col.id),
                  ),
                }
              : c,
        )
      }),
    ],
  }),
)

export const useDeleteGroupPageCategoryMutation = makeUseMutation(
  endpoints.groupPageCategories.delete,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(endpoints.groupPageCategories.list, (prevCategories) =>
        prevCategories?.filter((c) => c.id !== vars.pathParams.id),
      ),
      makeQueryUpdate(endpoints.tabs.list, (prevTabs) =>
        prevTabs?.map((t) =>
          t.collection_section_category_id === vars.pathParams.id
            ? {
                ...t,
                collection_section_category_id: null,
              }
            : t,
        ),
      ),
    ],
  }),
)
