import React from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {getLocalTimeZone} from '@internationalized/date'
import * as WebUI from '@cheddarup/web-ui'
import {api, useUpdateTabMutation} from '@cheddarup/api-client'
import {getTabInfoBlocks} from '@cheddarup/core'
import * as Util from '@cheddarup/util'
import {LinkButton} from 'src/components/LinkButton'
import {
  PremiumFeatureSideSheetDisclosure,
  PremiumFeaturesSideSheet,
} from 'src/components/PremiumFeaturesSideSheet'

import type {CollectionFormFormik} from './CollectionDetailsPage'

export interface CollectionAdditionalInformationBlockProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collectionId: number | undefined
  formik: CollectionFormFormik
}

const CollectionAdditionalInformationBlock: React.FC<
  CollectionAdditionalInformationBlockProps
> = ({collectionId, formik, className, ...restProps}) => {
  const navigate = useNavigate()
  const {data: collection} = api.tabs.detail.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: collectionId!,
      },
    },
    {
      enabled: collectionId != null,
    },
  )
  const updateTabMutation = useUpdateTabMutation()

  const sortedInfoBlocskKeys = collection ? getTabInfoBlocks(collection) : []

  return (
    <PremiumFeaturesSideSheet tabId={collectionId}>
      <WebUI.VStack
        className={WebUI.cn('h-fit gap-5 px-7 py-6', className)}
        as={WebUI.Panel}
        {...restProps}
      >
        <WebUI.VStack className="gap-2">
          <WebUI.Text className="text-ds-md">Additional Information</WebUI.Text>
          <WebUI.Text className="font-light">
            Customize your page by adding at-a-glance info blocks: date,
            location, FAQs, a fundraising goal, and more!
          </WebUI.Text>
        </WebUI.VStack>
        <WebUI.Button
          className="self-start"
          loading={formik.isSubmitting}
          onClick={async () => {
            if (collectionId && !formik.dirty) {
              navigate('information')
            } else {
              const errors = await formik.validateForm()
              if (Object.keys(errors).length === 0) {
                const savedCollection = await formik.submitForm()
                if (savedCollection !== null) {
                  navigate(
                    `/collection/${savedCollection.id}/details/information`,
                  )
                }
              }
            }
          }}
        >
          Add Information Blocks
        </WebUI.Button>

        {!!collection && (
          <WebUI.VStack className="gap-2">
            <WebUI.DragAndDrop
              onDragEnd={(event) => {
                if (!event.over) {
                  return
                }

                const oldOrder = event.over.data.current?.sortable.items ?? []
                const newOrder = WebUI.arrayMoveByValue(
                  oldOrder,
                  event.active.id,
                  event.over.id,
                )

                updateTabMutation.mutate({
                  pathParams: {
                    tabId: collection.id,
                  },
                  body: {
                    options: {
                      paymentGoal: {
                        ...collection.options.paymentGoal,
                        order: newOrder.indexOf('paymentGoal'),
                      },
                      infoBlockSettings: Util.mapToObj(
                        newOrder.filter((key) => key !== 'paymentGoal'),
                        (key, idx) => [
                          key,
                          {
                            ...(collection.options.infoBlockSettings as any)?.[
                              key
                            ],
                            order: idx,
                          },
                        ],
                      ),
                    },
                  },
                })
              }}
            >
              <CollectionContactCard className="mb-1" tabId={collection.id} />

              <WebUI.SortableContext items={sortedInfoBlocskKeys}>
                {({items: keys}) =>
                  keys.map((key) => {
                    const ibKey = key as (typeof sortedInfoBlocskKeys)[number]

                    if (
                      (ibKey === 'paymentGoal' &&
                        !collection.options.paymentGoal?.enabled) ||
                      (ibKey !== 'paymentGoal' &&
                        !collection.options.infoBlockSettings?.[
                          ibKey as keyof Api.TabInformationBlockSettings
                        ]?.enabled)
                    ) {
                      return null
                    }

                    switch (ibKey) {
                      case 'promoteSharing':
                        return (
                          <CollectionInfoBlockCard
                            key={ibKey}
                            id={ibKey}
                            label="Share this Collection"
                            editPath="information/participation"
                          />
                        )
                      case 'nonProfitStatus':
                        return (
                          <CollectionInfoBlockCard
                            key={ibKey}
                            id={ibKey}
                            label="Verified 501(c)(3) Organization"
                            editPath="information/participation"
                          />
                        )
                      case 'totalCollected':
                        return (
                          <CollectionInfoBlockCard
                            key={ibKey}
                            id={ibKey}
                            label="Total Collected"
                            editPath="information/participation"
                          />
                        )
                      case 'paymentGoal':
                        return (
                          <CollectionInfoBlockCard
                            key={ibKey}
                            id={ibKey}
                            label={`Fundraising Goal: ${Util.formatAmount(
                              collection.options.paymentGoal?.value ?? 0,
                            )}`}
                            requireUpgrage={
                              !collection.is_pro &&
                              !collection.options.doNotEnforceAddlGated
                            }
                            editPath="information/participation"
                          />
                        )
                      case 'payerList':
                        return (
                          <CollectionInfoBlockCard
                            key={ibKey}
                            id={ibKey}
                            label="Participant List:"
                            editPath="information/participation"
                          >
                            <WebUI.Text className="font-light text-ds-sm">
                              {collection.options.infoBlockSettings?.payerList
                                ?.customContributorName || 'Participants'}
                            </WebUI.Text>
                          </CollectionInfoBlockCard>
                        )
                      case 'time':
                        return (
                          <CollectionInfoBlockCard
                            key={ibKey}
                            id={ibKey}
                            label="Date and Time:"
                            requireUpgrage={!collection.is_team}
                            editPath="information/date-and-location"
                          >
                            <WebUI.Text className="font-light text-ds-sm">
                              {Util.formatDateRange(
                                collection.options.infoBlockSettings?.time
                                  ?.startTime ?? '',
                                collection.options.infoBlockSettings?.time
                                  ?.endTime,
                                {
                                  dateTimeFormatOptions: {
                                    timeZone:
                                      collection.options.infoBlockSettings?.time
                                        ?.timeZone || getLocalTimeZone(),
                                    timeZoneName: 'short',
                                    month: 'short',
                                    year: undefined,
                                    hour12: true,
                                    weekday: undefined,
                                  },
                                },
                              )}
                            </WebUI.Text>
                          </CollectionInfoBlockCard>
                        )
                      case 'location':
                        return (
                          <CollectionInfoBlockCard
                            key={ibKey}
                            id={ibKey}
                            label="Location:"
                            requireUpgrage={!collection.is_team}
                            editPath="information/date-and-location"
                          >
                            <WebUI.Text className="font-light text-ds-sm">
                              {
                                collection.options.infoBlockSettings?.location
                                  ?.address
                              }
                            </WebUI.Text>
                          </CollectionInfoBlockCard>
                        )
                      case 'faqs':
                        return (
                          collection.faqs &&
                          collection.faqs.length > 0 && (
                            <CollectionInfoBlockCard
                              key={ibKey}
                              id={ibKey}
                              label="FAQ:"
                              requireUpgrage={!collection.is_team}
                              editPath="information/faqs"
                            >
                              <ul className="ml-3 font-light text-ds-sm [list-style-type:initial]">
                                {collection.faqs?.map((faq) => (
                                  <li key={faq.id}>{faq.question}</li>
                                ))}
                              </ul>
                            </CollectionInfoBlockCard>
                          )
                        )
                      default:
                        return null
                    }
                  })
                }
              </WebUI.SortableContext>
            </WebUI.DragAndDrop>
          </WebUI.VStack>
        )}
      </WebUI.VStack>
    </PremiumFeaturesSideSheet>
  )
}

// MARK: - CollectionContactCard

interface CollectionInfoBlockCardProps
  extends React.ComponentPropsWithoutRef<'div'>,
    Pick<WebUI.CardProps, 'dragListeners'> {
  editPath?: string
  label: string
  requireUpgrage?: boolean
}

const CollectionInfoBlockCard: React.FC<CollectionInfoBlockCardProps> = ({
  editPath,
  label,
  requireUpgrage,
  children,
  ...restProps
}) => {
  const urlParams = useParams()
  const location = useLocation()
  return (
    <WebUI.Sortable draggable={false} {...restProps}>
      {({dragListeners}) => (
        <WebUI.HStack
          className="gap-3 py-4 pr-5 pl-6"
          as={WebUI.Card}
          dragHandleVisible
          dragListeners={dragListeners}
          accessoryView={
            !!editPath && (
              <WebUI.ActionGroup>
                <WebUI.Action
                  icon={<WebUI.PhosphorIcon icon="pencil" />}
                  as={LinkButton}
                  to={{pathname: editPath, search: location.search}}
                >
                  Edit
                </WebUI.Action>
              </WebUI.ActionGroup>
            )
          }
        >
          {requireUpgrage && (
            <PremiumFeatureSideSheetDisclosure
              tabId={Number(urlParams.collection)}
              // biome-ignore lint/correctness/noChildrenProp:
              children={null}
              disclosureVariant="mini"
              className="shrink-0"
            />
          )}
          <WebUI.VStack className="gap-1">
            <WebUI.Text className="text-ds-sm">{label}</WebUI.Text>
            {children}
          </WebUI.VStack>
        </WebUI.HStack>
      )}
    </WebUI.Sortable>
  )
}

// MARK: - CollectionContactCard

export interface CollectionContactCardProps
  extends React.ComponentPropsWithoutRef<'div'> {
  tabId: number
}

const CollectionContactCard: React.FC<CollectionContactCardProps> = ({
  tabId,
  className,
  ...restProps
}) => {
  const {data: session} = api.auth.session.useQuery()
  const {data: tab} = api.tabs.detail.useQuery({
    pathParams: {
      tabId,
    },
  })
  const {data: groupPage} = api.publicTabs.home.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: session?.user.id!,
      },
    },
    {
      enabled: !!session,
    },
  )

  const userProfileUrl = session?.user.profile_pic?.url
  const showLinkToCollections =
    session?.capabilities.plan === 'team' &&
    !!tab?.options.groupPage?.shared &&
    !!groupPage?.sections?.collection_section

  return (
    <WebUI.VStack
      className={WebUI.cn(
        'gap-4 *:rounded *:bg-[#f7f7f7] *:px-6 *:py-4',
        className,
      )}
      {...restProps}
    >
      <WebUI.HStack className="items-center gap-3">
        {userProfileUrl && (
          <WebUI.Image
            className="rounded-full"
            width={45}
            height={45}
            src={userProfileUrl}
            alt="Organizer Profile Picture"
          />
        )}
        <WebUI.VStack>
          <WebUI.Text className="text-ds-sm">Contact:</WebUI.Text>
          <WebUI.Text className="font-light text-black text-ds-sm">
            {session?.user.display_name}
          </WebUI.Text>
        </WebUI.VStack>
      </WebUI.HStack>
      {showLinkToCollections && (
        <WebUI.Text className="text-ds-sm">Group Page Link</WebUI.Text>
      )}
    </WebUI.VStack>
  )
}

export default CollectionAdditionalInformationBlock
