import React, {useEffect, useRef} from 'react'
import FromTypeStar from 'src/images/FormTypeStar.png'
import * as WebUI from '@cheddarup/web-ui'
import {ActionButton} from 'src/components/ActionButton'
import {TemplatesSuggestionsModal} from 'src/components/TemplatesSuggestionsModal'
import {LinkButton} from 'src/components/LinkButton'
import {NumberParam, useQueryParam, withDefault} from 'use-query-params'

const CollectionCreationMethodModal: React.FC<WebUI.ModalProps> = ({
  initialVisible = false,
  ...restProps
}) => {
  const modalRef = useRef<WebUI.DialogInstance>(null)
  const [convertedTabId] = useQueryParam(
    'convertedTabId',
    withDefault(NumberParam, null),
  )

  useEffect(() => {
    if (convertedTabId) {
      modalRef.current?.hide()
    }
  }, [convertedTabId])

  return (
    <WebUI.Modal
      aria-label="Collection Creation Method Modal"
      className="[&_>_.ModalContentView]:overflow-y-auto [&_>_.ModalContentView]:px-8 [&_>_.ModalContentView]:py-12 sm:[&_>_.ModalContentView]:max-w-screen-sm sm:[&_>_.ModalContentView]:px-16"
      role="dialog"
      initialVisible={initialVisible}
      ref={modalRef}
      {...restProps}
    >
      <WebUI.ModalCloseButton />
      <WebUI.VStack className="items-center gap-5">
        <WebUI.Heading className="text-center" as="h3">
          How do you want to create your Collection?
        </WebUI.Heading>
        <div className="flex flex-row flex-wrap justify-center gap-7">
          <ActionButton
            iconBefore={
              <WebUI.PhosphorIcon
                icon="plus"
                color="#f36d36"
                height={70}
                width={70}
              />
            }
            title="Start from Scratch"
            description="Add details, items and forms. Easy!"
            as={LinkButton}
            to="/collection/details"
          />
          <TemplatesSuggestionsModal
            initialVisible={false}
            disclosure={
              <WebUI.DialogDisclosure
                as={ActionButton}
                iconBefore={
                  <WebUI.Image
                    src={FromTypeStar}
                    height={75}
                    width="auto"
                    alt=""
                  />
                }
                title="Use a Template"
                description="Choose from over 100 premade templates"
              />
            }
          />
        </div>
      </WebUI.VStack>
    </WebUI.Modal>
  )
}
export default CollectionCreationMethodModal
