import {makeUseMutation} from '../use-mutation'
import {endpoints} from '../../endpoints'
import {getEndpointKey} from '../../utils'

export const useCreateMessageMutation = makeUseMutation(
  endpoints.messages.create,
  undefined,
  (queryClient) => ({
    onSuccess: () => {
      const messageListQueryKey = getEndpointKey(endpoints.messages.list)
      const tabListQueryKey = getEndpointKey(endpoints.tabs.list, {
        queryParams: {
          filter: 'history',
        },
      })

      queryClient.invalidateQueries({queryKey: messageListQueryKey})
      queryClient.invalidateQueries({queryKey: tabListQueryKey})
    },
  }),
)

export const useSendTestMessageMutation = makeUseMutation(
  endpoints.messages.sendTest,
  undefined,
  (queryClient) => ({
    onSuccess: () => {
      const messageListQueryKey = getEndpointKey(endpoints.messages.list)
      queryClient.invalidateQueries({queryKey: messageListQueryKey})
    },
  }),
)

export const useResendSingleMessageMutation = makeUseMutation(
  endpoints.messages.resend,
)

export const useCreateReminderMutation = makeUseMutation(
  endpoints.messages.createReminder,
  undefined,
  (queryClient) => ({
    onSuccess: () => {
      const messageListQueryKey = getEndpointKey(endpoints.messages.list)
      queryClient.invalidateQueries({queryKey: messageListQueryKey})
    },
  }),
)

export const useCancelReminderMutation = makeUseMutation(
  endpoints.messages.deleteReminder,
)
