import React from 'react'
import * as WebUI from '@cheddarup/web-ui'

import RecurringPaymentFormatter from '../helpers/RecurringPaymentFormatter'

export interface RecurringOptionsAnnotationProps
  extends React.ComponentPropsWithoutRef<'p'> {
  recurringOptions?: Api.TabItemOptionsRecurring['options']
  noBold?: boolean
}

const RecurringOptionsAnnotation = ({
  recurringOptions,
  noBold,
  className,
  ...restProps
}: RecurringOptionsAnnotationProps) => {
  const Text = noBold ? 'span' : 'b'
  const annotation = recurringOptions
    ? RecurringPaymentFormatter.getAnnotation({
        ...recurringOptions,
        start: {
          ...recurringOptions.start,
          date: new Date(recurringOptions.start.date),
        },
      })
    : {}

  return (
    <p className={WebUI.cn('font-normal text-ds-xs', className)} {...restProps}>
      Payment will be automatically charged
      {annotation.repeatInterval && <Text> {annotation.repeatInterval}</Text>}
      {annotation.start && <Text>{annotation.start}</Text>}
      {annotation.ends && (
        <>
          {' '}
          and ending after <Text>{annotation.ends}</Text>
        </>
      )}
      .
    </p>
  )
}

export default RecurringOptionsAnnotation
