import * as WebUI from '@cheddarup/web-ui'
import {useRef} from 'react'
import {LinkButton} from 'src/components/LinkButton'

import {DeactivateUserAlert} from './DeactivateUserAlert'

export interface CollectorPayerDropdownProps {
  isCollectorVisible: boolean
  isPayerVisible: boolean
  isCollector: boolean
  customerLink?: string
  distributorLink?: string
  userId?: number
}

const CollectorPayerDropdown: React.FC<CollectorPayerDropdownProps> = ({
  isCollectorVisible,
  isPayerVisible,
  isCollector,
  customerLink,
  distributorLink,
  userId,
}) => {
  const deactivateUserAlertRef = useRef<WebUI.DialogInstance>(null)
  return (
    <>
      <WebUI.Menu>
        <WebUI.MenuButton
          variant="outlined"
          iconBefore={
            isCollector ? (
              <WebUI.PhosphorIcon icon="star-fill" className="text-gray400" />
            ) : (
              <WebUI.PhosphorIcon
                icon="address-book-fill"
                className="text-gray400"
              />
            )
          }
          iconAfter={<WebUI.PhosphorIcon icon="caret-down" />}
        >
          {isCollector ? 'Organizer (Active)' : 'Payer (Active)'}
        </WebUI.MenuButton>
        <WebUI.MenuList>
          {!!distributorLink && isCollectorVisible && (
            <WebUI.MenuItem
              iconBefore={
                <WebUI.PhosphorIcon icon="star-fill" className="text-gray400" />
              }
              as={LinkButton}
              to={distributorLink}
            >{`Organizer${isCollector ? ' (Active)' : ''}`}</WebUI.MenuItem>
          )}
          {!!customerLink && isPayerVisible && (
            <WebUI.MenuItem
              iconBefore={
                <WebUI.PhosphorIcon
                  icon="address-book-fill"
                  className="text-gray400"
                />
              }
              as={LinkButton}
              to={customerLink}
            >{`Payer${isCollector ? '' : ' (Active)'}`}</WebUI.MenuItem>
          )}
          <WebUI.MenuItem
            onClick={() => deactivateUserAlertRef.current?.show()}
          >
            Disable Organizer
          </WebUI.MenuItem>
        </WebUI.MenuList>
      </WebUI.Menu>
      {userId != null && (
        <DeactivateUserAlert ref={deactivateUserAlertRef} memberId={userId} />
      )}
    </>
  )
}

export default CollectorPayerDropdown
