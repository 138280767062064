// @ts-ignore

import {z} from '@cheddarup/util'

import {s3ImageSchema} from './common'
import {groupTabSchema} from './public-tabs'
import {userBrandingSchema} from './user-brandings'

export const groupPageCategorySchema = z.object({
  id: z.union([z.number(), z.string()]),
  name: z.string(),
  uuid: z.string().nullable(),
  collections: groupTabSchema.array(),
  collectionOrder: z.number().array().optional(),
  position: z.number(),
})

export const topBannerDetailsSchema = z.object({
  backgroundImageId: z.number().nullish(),
  logoId: z.number().nullish(),
  complementaryColor: z.string().optional(),
})

export const groupPageCollectionSpotlightItemSchema = z.object({
  id: z.number(),
  position: z.number(),
  collection_id: z.number(),
  headline: z.string(),
  description: z.string(),
  button_text: z.string(),
  collection: z.object({
    id: z.number(),
    name: z.string(),
    user_id: z.number(),
    status: z.string(),
    slug: z.string(),
  }),
  image_id: z.number().nullable(),
  image: s3ImageSchema.optional(),
})

export const groupPageTeamMemberSchema = z.object({
  id: z.number(),
  position: z.number(),
  name: z.string(),
  title: z.string(),
  email: z.string().nullable(),
  profile_picture_id: z.number().nullable(),
  profile_picture: s3ImageSchema.optional(),
})

export const groupPageSponsorSchema = z.object({
  id: z.number(),
  position: z.number(),
  name: z.string(),
  url: z.string(),
  logo_id: z.number().nullable(),
  logo: s3ImageSchema.optional(),
})

export const collectionSpotlightDetailsSchema = z.object({
  removeWhenClosed: z.boolean().optional(),
})

export const groupPageAboutUsSectionSchema = z.object({
  url: z.string().optional(),
  buttonText: z.string().optional(),
})

export const groupPageTotalCollectionTimePeriodSchema = z.enum([
  'all_time',
  'last_year',
  'last_month',
  'last_week',
])

export const groupPageTotalCollectedSectionSchema = z.object({
  timePeriod: groupPageTotalCollectionTimePeriodSchema.optional(),
})

export const groupPageSectionTypeSchema = z.enum([
  'TopBanner',
  'CollectionSection',
  'CollectionSpotlight',
  'AboutUsSection',
  'TotalCollected',
  'MeetTheTeam',
  'Sponsor',
])

export const brandKitColorKey = z.enum([
  'primary',
  'secondary',
  'neutral',
  'white',
])

export const groupPageSectionSchema = z.object({
  id: z.number(),
  position: z.number(),
  headline: z.string(),
  tagline: z.string().nullable(),
  description: z.string(),
  logo: s3ImageSchema.nullish(),
  details: z
    .object({})
    .merge(topBannerDetailsSchema)
    .merge(collectionSpotlightDetailsSchema)
    .merge(groupPageAboutUsSectionSchema)
    .merge(groupPageTotalCollectedSectionSchema),
  background_color: brandKitColorKey,
  background_image: s3ImageSchema.nullish(),
  spotlight_items: groupPageCollectionSpotlightItemSchema.array().optional(),
  team_members: groupPageTeamMemberSchema.array().optional(),
  sponsor_items: groupPageSponsorSchema.array().optional(),
  categories: groupPageCategorySchema.array().optional(),
  type: groupPageSectionTypeSchema,
  total_collected: z.string().optional(),
})

export const groupPageStatusSchema = z.enum(['draft', 'published'])
export const groupPageSchema = z.object({
  user_id: z.number(),
  status: groupPageStatusSchema,
  published_at: z.string().nullable(),
  branding: userBrandingSchema.optional(),
  sections: z
    .object({
      collection_section: groupPageSectionSchema.nullable(),
      top_banner: groupPageSectionSchema.nullable(),
      collection_spotlight: groupPageSectionSchema.nullable(),
      about_us: groupPageSectionSchema.nullable(),
      total_collected: groupPageSectionSchema.nullable(),
      meet_the_team: groupPageSectionSchema.nullable(),
      sponsor: groupPageSectionSchema.nullable(),
    })
    .optional(),
  user: z.object({
    id: z.number(),
    email: z.string(),
    first_name: z.string(),
    last_name: z.string(),
    display_name: z.string(),
    slug: z.string(),
    profile_pic: s3ImageSchema.nullish(),
  }),
})

declare global {
  namespace Api {
    // @ts-ignore
    type BrandKitColorKey = z.infer<typeof brandKitColorKey>
    // @ts-ignore
    type GroupPage = z.infer<typeof groupPageSchema>
    // @ts-ignore
    type GroupPageSectionType = z.infer<typeof groupPageSectionTypeSchema>
    // @ts-ignore
    type GroupPageCategory = z.infer<typeof groupPageCategorySchema>
    // @ts-ignore
    type GroupPageSection = z.infer<typeof groupPageSectionSchema>
    // @ts-ignore
    type GroupPageCollectionSpotlightItem = z.infer<
      typeof groupPageCollectionSpotlightItemSchema
    >
    // @ts-ignore
    type GroupPageTeamMember = z.infer<typeof groupPageTeamMemberSchema>
    // @ts-ignore
    type GroupPageSponsor = z.infer<typeof groupPageSponsorSchema>
    // @ts-ignore
    type GroupPageTotalCollectionTimePeriod = z.infer<
      typeof groupPageTotalCollectionTimePeriodSchema
    >
  }
}
