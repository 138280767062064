import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {Suspense, lazy} from 'react'
import {PageContainer} from 'src/components/PageContainer'

import AccountDetailsPage from './AccountDetailsPage'
import AccountSettingsLayout from './AccountSettingsLayout'
import AlertDetailsPage from './AlertDetailsPage'
import AlertsPage from './AlertsPage'
import BackupSecurityCodeAboutPage from './BackupSecurityCodeAboutPage'
import BackupSecurityCodeInputPage from './BackupSecurityCodeInputPage'
import BankModificationDisabledPage from './BankModificationDisabledPage'
import BrandKitLogoPage from './BrandKitLogoPage'
import BrankKitFooterPage from './BrandKitFooterPage'
import CanadianVerificationPage from './CanadianVerificationPage'
import BrandKitPalettePage from './BrandKitPalettePage'
import DisplayNamePage from './DisplayNamePage'
import EmailAndPasswordPage from './EmailAndPasswordPage'
import MailedSecurityCodeAboutPage from './MailedSecurityCodeAboutPage'
import MailedSecurityCodeInputPage from './MailedSecurityCodeInputPage'
import MakeItSecurePage from './MakeItSecurePage'
import NotificationSettingsPage from './NotificationSettingsPage'
import PaymentMethodsPage from './PaymentMethodsPage'
import PlanBillingPage from './PlanBillingPage'
import RequestMailedSecurityCodePage from './RequestMailedSecurityCodePage'
import SelectPrimaryBankPage from './SelectPrimaryBankPage'
import StatementDescriptorPage from './StatementDescriptorPage'
import TwoFactorAuthenticationPage from './TwoFactorAuthenticationPage'
import WithdrawalSettingsPage from './WithdrawalSettingsPage'
import ZapierKeyPage from './ZapierKeyPage'
import BrandKitEmailPage from './BrandKitEmailPage'

const LazySecurityPage = lazy(() => import('./SecurityPage'))

const securityRoutes = [
  <Route
    key="two-factor-authentication"
    path="two-factor-authentication"
    element={<TwoFactorAuthenticationPage />}
  />,
  <Route
    key="about-backup-security-code"
    path="about-backup-security-code"
    element={<BackupSecurityCodeAboutPage />}
  />,
  <Route
    key="about-mailed-security-code"
    path="about-mailed-security-code"
    element={<MailedSecurityCodeAboutPage />}
  />,
  <Route
    key="request-mailed-security-code"
    path="request-mailed-security-code"
    element={<RequestMailedSecurityCodePage />}
  />,
  <Route
    key="enter-mailed-security-code"
    path="enter-mailed-security-code"
    element={<MailedSecurityCodeInputPage />}
  />,
  <Route
    key="enter-backup-security-code"
    path="enter-backup-security-code"
    element={<BackupSecurityCodeInputPage />}
  />,
]

const View = () => (
  <AccountSettingsLayout>
    <Suspense>
      <Routes>
        <Route path="" element={<Navigate replace to="display-name" />} />

        <Route path="display-name" element={<DisplayNamePage />} />
        <Route path="email-and-password" element={<EmailAndPasswordPage />} />
        <Route
          path="account-details"
          element={
            <>
              <AccountDetailsPage />
              <Outlet />
            </>
          }
        >
          {securityRoutes}
        </Route>
        <Route
          path="statement-descriptor"
          element={<StatementDescriptorPage />}
        />

        <Route
          path="canada-verification"
          element={
            <>
              <CanadianVerificationPage />
              <Outlet />
            </>
          }
        />
        <Route
          path="plan-billing/*"
          element={
            <PageContainer>
              <PlanBillingPage />
              <Outlet />
            </PageContainer>
          }
        />
        <Route
          path="security"
          element={
            <>
              <LazySecurityPage />
              <Outlet />
            </>
          }
        >
          {securityRoutes}
        </Route>
        <Route
          path="withdrawal-settings/*"
          element={
            <>
              <WithdrawalSettingsPage />
              <Outlet />
            </>
          }
        >
          <Route path="make-it-secure" element={<MakeItSecurePage />} />
          <Route path="primary" element={<SelectPrimaryBankPage />} />
          <Route path="disabled" element={<BankModificationDisabledPage />} />
        </Route>
        <Route
          path="brand-kit-logo/*"
          element={
            <PageContainer>
              <BrandKitLogoPage />
              <Outlet />
            </PageContainer>
          }
        />
        <Route
          path="brand-kit-palette/*"
          element={
            <PageContainer>
              <BrandKitPalettePage />
              <Outlet />
            </PageContainer>
          }
        />
        <Route path="payment-methods" element={<PaymentMethodsPage />} />
        <Route
          path="notification-settings"
          element={<NotificationSettingsPage />}
        />
        <Route
          path="brand-kit-footer/*"
          element={
            <PageContainer>
              <BrankKitFooterPage />
              <Outlet />
            </PageContainer>
          }
        />
        <Route
          path="brand-kit-email/*"
          element={
            <PageContainer>
              <BrandKitEmailPage />
              <Outlet />
            </PageContainer>
          }
        />
        <Route
          path="alerts"
          element={
            <>
              <AlertsPage />
              <Outlet />
            </>
          }
        >
          <Route path=":id" element={<AlertDetailsPage />} />
        </Route>
        <Route
          path="zapier-key/*"
          element={
            <PageContainer>
              <ZapierKeyPage />
              <Outlet />
            </PageContainer>
          }
        />
      </Routes>
    </Suspense>
  </AccountSettingsLayout>
)

export default View
