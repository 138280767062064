import {delay} from '@cheddarup/util'

import {makeQueryUpdate, makeUseMutation} from '../use-mutation'
import {endpoints} from '../../endpoints'
import {getEndpointKey} from '../../utils'

export const useSignupMutation = makeUseMutation(
  endpoints.auth.signup,
  () => ({
    regular: (newSession) => [
      makeQueryUpdate(endpoints.auth.session, () => newSession),
    ],
  }),
  (queryClient) => ({
    onSuccess: () => {
      queryClient.resetQueries()
    },
  }),
)

export const useSignupWithTokenMutation = makeUseMutation(
  endpoints.auth.signupWithToken,
  undefined,
  (queryClient) => ({
    onSuccess: () => {
      queryClient.resetQueries()
    },
  }),
)

export const useLoginMutation = makeUseMutation(
  endpoints.auth.login,
  () => ({
    regular: (newSession) => [
      makeQueryUpdate(endpoints.auth.session, () => newSession),
    ],
  }),
  (queryClient) => ({
    onSuccess: async () => {
      // HACK: sometimes `onSuccess` fires so fast API responds with 401
      await delay(200)
      queryClient.resetQueries()
    },
  }),
)

export const useLoginWithTokenMutation = makeUseMutation(
  endpoints.auth.loginWithToken,
  undefined,
  (queryClient) => ({
    onSuccess: () => {
      queryClient.resetQueries()
    },
  }),
)

export const useLogoutMutation = makeUseMutation(
  endpoints.auth.logout,
  undefined,
  (queryClient) => ({
    onSuccess: () => {
      queryClient.resetQueries()
    },
  }),
)

export const useForgotPasswordMutation = makeUseMutation(
  endpoints.auth.forgotPassword,
)

export const useResetPasswordMutation = makeUseMutation(
  endpoints.auth.resetPassword,
)

export const useCheckEmailExistenceMutation = makeUseMutation(
  endpoints.auth.checkEmailExistence,
)

export const useSendGuestLoginLinkMutation = makeUseMutation(
  endpoints.auth.sendLoginLink,
)

export const useSendGuestLoginCodeMutation = makeUseMutation(
  endpoints.auth.sendLoginCode,
)

export const useLoginViaTokenMutation = makeUseMutation(
  endpoints.auth.loginViaToken,
  () => ({
    regular: (newSession) => [
      makeQueryUpdate(endpoints.auth.session, () => newSession),
    ],
  }),
  (queryClient) => ({
    onSuccess: () => {
      queryClient.resetQueries()
    },
  }),
)

export const useVerifyCaptchaMutation = makeUseMutation(
  endpoints.auth.verifyCaptcha,
)

export const useTestCaptchaRequiredMutation = makeUseMutation(
  endpoints.auth.testCaptchaRequired,
)

export const useTestResetCaptchaMutation = makeUseMutation(
  endpoints.auth.testResetCaptcha,
)

export const useSendOneTimeLoginLinkMutation = makeUseMutation(
  endpoints.auth.sendOneTimeLoginLink,
)

export const useMailedSecurityCodeVerificationMutation = makeUseMutation(
  endpoints.auth.verifyMailedSecurityCode,
  undefined,
  (queryClient) => ({
    onSuccess: () => {
      const sessionQueryKey = getEndpointKey(endpoints.auth.session)
      queryClient.invalidateQueries({queryKey: sessionQueryKey})
    },
  }),
)

export const useConfirmPhoneNumberMutation = makeUseMutation(
  endpoints.auth.confirmPhoneNumber,
  undefined,
  (queryClient) => ({
    onSuccess: () => {
      const sessionQueryKey = getEndpointKey(endpoints.auth.session)
      queryClient.invalidateQueries({queryKey: sessionQueryKey})
    },
  }),
)
