import {capitalize} from '@cheddarup/util'

export function formatTicketStatus(ticketStatus: Api.TicketStatus) {
  switch (ticketStatus) {
    case 'unused':
      return 'Active'
    default:
      return capitalize(ticketStatus)
  }
}

export function formatTicketRedeemedMethod(redeemMethod: Api.RedeemedMethod) {
  switch (redeemMethod) {
    case 'qr':
      return 'QR code'
    default:
      return capitalize(redeemMethod)
  }
}
