import * as WebUI from '@cheddarup/web-ui'
import React, {useMemo, useState} from 'react'
import {api} from '@cheddarup/api-client'
import {useUpdateEffect} from '@cheddarup/react-util'

export type ContactsAutosuggestComboboxField = 'name' | 'email' | 'nameOrEmail'

export interface ContactsAutosuggestComboboxProps
  extends Omit<WebUI.ComboboxProps, 'selectedItem'>,
    Pick<React.ComponentPropsWithoutRef<'input'>, 'placeholder'>,
    Pick<WebUI.InputProps, 'size'> {
  chevronIconName?: WebUI.ComboboxInputProps['chevronIconName']
  field: ContactsAutosuggestComboboxField
  selectedEmail?: string
  onSelectedContactChange?: (
    newContact: {name: string; email: string} | null,
  ) => void
}

export const ContactsAutosuggestCombobox = ({
  size,
  chevronIconName,
  field,
  selectedEmail,
  onSelectedContactChange,
  inputValue: inputValueProp,
  onInputValueChange,
  placeholder,
  ...restProps
}: ContactsAutosuggestComboboxProps) => {
  const [inputValue, setInputValue] = useState(inputValueProp ?? '')

  const autoSuggestContactsQuery = api.contacts.suggest.useQuery(
    {
      queryParams: {
        name: field === 'name' ? inputValue : undefined,
        email: field === 'email' ? inputValue : undefined,
        name_or_email: field === 'nameOrEmail' ? inputValue : undefined,
      },
    },
    {
      enabled: inputValue.length > 2,
    },
  )

  useUpdateEffect(() => {
    if (inputValueProp != null) {
      setInputValue(inputValueProp)
    }
  }, [inputValueProp])

  const options = useMemo(
    () =>
      autoSuggestContactsQuery.data?.map(
        (c): WebUI.ComboboxItem => ({
          value: c.email,
          labelEl: (
            <WebUI.VStack className="gap-1">
              <WebUI.Ellipsis className="text-ds-base">{c.name}</WebUI.Ellipsis>
              <WebUI.ComboboxOptionText className="text-gray400" />
            </WebUI.VStack>
          ),
        }),
      ) ?? [],
    [autoSuggestContactsQuery.data],
  )

  const selectedOption = useMemo(
    () =>
      selectedEmail === undefined
        ? undefined
        : (options.find((o) => o.value === selectedEmail) ?? null),
    [options, selectedEmail],
  )

  return (
    <WebUI.Combobox
      selectedItem={selectedOption}
      onSelectedItemChange={(change) => {
        if (
          change.type ===
          WebUI.useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem
        ) {
          return
        }

        const newSelectedOption = change.selectedItem
        if (!newSelectedOption) {
          return
        }

        const newSelectedContact = autoSuggestContactsQuery.data?.find(
          (r) => r.email === newSelectedOption.value,
        )
        if (newSelectedContact) {
          onSelectedContactChange?.(newSelectedContact)
        }
      }}
      onInputValueChange={(changes) => {
        if (changes.type !== WebUI.useCombobox.stateChangeTypes.InputChange) {
          return
        }

        if (!changes.inputValue) {
          onSelectedContactChange?.(null)
        }
        onInputValueChange?.(changes)
        setInputValue(changes.inputValue ?? '')
      }}
      {...restProps}
    >
      <WebUI.ComboboxInput
        placeholder={placeholder}
        size={size}
        chevronIconName={chevronIconName}
        value={inputValue}
        onClear={() => {
          setInputValue('')
          onSelectedContactChange?.(null)
        }}
      />

      <WebUI.ComboboxPopover>
        <WebUI.ComboboxList
          noResultsFoundText={
            inputValue.length < 4
              ? 'Please type more than 3 characters'
              : autoSuggestContactsQuery.isPending
                ? 'Searching...'
                : undefined
          }
        >
          {options.map((o) => (
            <WebUI.ComboboxOption
              key={o.value}
              className="h-auto py-3"
              {...o}
            />
          ))}
        </WebUI.ComboboxList>
      </WebUI.ComboboxPopover>
    </WebUI.Combobox>
  )
}
