import {useFormik} from '@cheddarup/react-util'
import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {
  useUpdateTabPaymentItemMutation,
  useUpdateTabPaymentMutation,
} from '@cheddarup/api-client'

export type PaymentNoteButtonDropdownProps = WebUI.PopoverDisclosureProps &
  WebUI.ButtonProps &
  React.ComponentPropsWithoutRef<'button'> &
  ConditionalPaymentNoteButtonDropdownFormProps

const PaymentNoteButtonDropdown = React.forwardRef<
  HTMLButtonElement,
  PaymentNoteButtonDropdownProps
>(
  (
    {collectionId, payment, itemPaymentItem, children, ...restProps},
    forwardedRef,
  ) => (
    <WebUI.Popover placement="top-start">
      {(popover) => (
        <>
          <WebUI.PopoverDisclosure ref={forwardedRef} {...restProps}>
            {children}
          </WebUI.PopoverDisclosure>
          <WebUI.PopoverContent aria-label="Payment note form">
            <PaymentNoteButtonDropdownForm
              className="p-4"
              payment={payment as any}
              collectionId={collectionId as any}
              itemPaymentItem={itemPaymentItem as any}
              onCancel={() => popover.hide()}
              onDidUpdateNote={() => popover.hide()}
            />
          </WebUI.PopoverContent>
        </>
      )}
    </WebUI.Popover>
  ),
)

// MARK: – PaymentNoteButtonDropdownForm

type ConditionalPaymentNoteButtonDropdownFormProps =
  | {
      collectionId?: never
      itemPaymentItem?: never
      payment: Api.TabPayment
    }
  | {
      collectionId: number
      itemPaymentItem: Api.TabItemPayment
      payment?: never
    }

type PaymentNoteButtonDropdownFormProps =
  React.ComponentPropsWithoutRef<'form'> &
    ConditionalPaymentNoteButtonDropdownFormProps & {
      onCancel: () => void
      onDidUpdateNote?: () => void
    }

const PaymentNoteButtonDropdownForm = ({
  collectionId,
  itemPaymentItem,
  payment,
  onCancel,
  onDidUpdateNote,
  className,
  ...restProps
}: PaymentNoteButtonDropdownFormProps) => {
  const updatePaymentMutation = useUpdateTabPaymentMutation()
  const updateTabPaymentItemMutation = useUpdateTabPaymentItemMutation()

  const note = payment?.note ?? itemPaymentItem?.detail.organizerNote ?? ''

  const formik = useFormik({
    initialValues: {
      note,
    },
    onSubmit: async (values) => {
      if (itemPaymentItem) {
        await updateTabPaymentItemMutation.mutateAsync({
          pathParams: {
            tabId: collectionId,
            paymentId: itemPaymentItem.payment_id,
            paymentItemId: itemPaymentItem.id,
          },
          body: {
            organizerNote: values.note,
          },
        })
      } else if (payment) {
        await updatePaymentMutation.mutateAsync({
          pathParams: {
            tabId: payment.tab_id,
            paymentId: payment.id,
          },
          body: {
            note: values.note,
          },
        })
      }

      onDidUpdateNote?.()
    },
  })

  return (
    <WebUI.Form
      className={WebUI.cn('[&_>_.Form-inner]:gap-3', className)}
      onReset={formik.handleReset}
      onSubmit={formik.handleSubmit}
      {...restProps}
    >
      <WebUI.Textarea
        className={WebUI.cn(
          'resize-y text-ds-sm',
          itemPaymentItem ? 'h-[100px]' : 'h-auto',
        )}
        name="note"
        placeholder={
          itemPaymentItem
            ? 'This internal note will be seen by ticket taker and not attendee.'
            : 'Internal Note'
        }
        value={formik.values.note}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />

      <WebUI.HStack className="items-center justify-between gap-4">
        <WebUI.HStack className="items-center gap-3">
          <WebUI.Button type="submit" loading={formik.isSubmitting}>
            Save
          </WebUI.Button>
          <WebUI.Button variant="secondary" onClick={onCancel}>
            Cancel
          </WebUI.Button>
        </WebUI.HStack>

        {!!note && !formik.isSubmitting && (
          <WebUI.Button
            className="text-ds-sm"
            variant="link"
            onClick={() => {
              formik.setFieldValue('note', '')
              formik.submitForm()
            }}
          >
            Delete
          </WebUI.Button>
        )}
      </WebUI.HStack>
    </WebUI.Form>
  )
}

export default PaymentNoteButtonDropdown
