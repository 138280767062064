import React from 'react'
import * as WebUI from '@cheddarup/web-ui'

const MarketplaceSectionTitle = ({
  className,
  children,
  ...restProps
}: React.ComponentPropsWithoutRef<'h5'>) => (
  <h5
    className={WebUI.cn('font-normal text-[1.3125rem]', className)}
    {...restProps}
  >
    {children}
  </h5>
)

export default MarketplaceSectionTitle
