import {useFormik} from '@cheddarup/react-util'
import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {useCreateFolderMutation} from '@cheddarup/api-client'

export interface CreateFolderFormPromptProps extends WebUI.PromptProps {
  subheading?: React.ReactNode
  onDidCreateFolder?: (folder: Api.TabFolder) => void
}

const CreateFolderFormPrompt = React.forwardRef<
  WebUI.DialogInstance,
  CreateFolderFormPromptProps
>(({subheading, onDidCreateFolder, ...restProps}, forwardedRef) => (
  <WebUI.Prompt
    ref={forwardedRef}
    aria-label="Create folder form"
    {...restProps}
  >
    {(modal) => (
      <WebUI.VStack className="gap-6">
        <WebUI.PromptHeader
          heading="Create a new folder"
          subheading={subheading}
        />
        <CreateFolderForm
          onCancel={() => modal.hide()}
          onDidCreateFolder={(newFolder) => {
            onDidCreateFolder?.(newFolder)
            modal.hide()
          }}
        />
      </WebUI.VStack>
    )}
  </WebUI.Prompt>
))

// MARK: – CreateFolderForm

interface CreateFolderFormProps extends React.ComponentPropsWithoutRef<'form'> {
  onDidCreateFolder?: (folder: Api.TabFolder) => void
  onCancel: () => void
}

const CreateFolderForm = ({
  onCancel,
  onDidCreateFolder,
  ...restProps
}: CreateFolderFormProps) => {
  const createFolderMutation = useCreateFolderMutation()

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    onSubmit: async (values) => {
      const newFolder = await createFolderMutation.mutateAsync({
        body: {name: values.name},
      })
      onDidCreateFolder?.(newFolder)
    },
  })

  return (
    <WebUI.Form
      onSubmit={formik.handleSubmit}
      onReset={formik.handleReset}
      {...restProps}
    >
      <WebUI.Input
        autoFocus
        name="name"
        maxLength={63}
        value={formik.values.name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <WebUI.HStack className="gap-4">
        <WebUI.Button type="submit" loading={formik.isSubmitting}>
          Create folder
        </WebUI.Button>
        <WebUI.Button type="button" variant="secondary" onClick={onCancel}>
          Cancel
        </WebUI.Button>
      </WebUI.HStack>
    </WebUI.Form>
  )
}

export default CreateFolderFormPrompt
