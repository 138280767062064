import * as WebUI from '@cheddarup/web-ui'

interface BrandKitSettingsDisclosureProps
  extends Omit<WebUI.DisclosureProps, 'children'> {
  label: string
  children?: React.ReactNode
}

const BrandKitSettingsDisclosure: React.FC<BrandKitSettingsDisclosureProps> = ({
  label,
  children,
  className,
  onClick,
  ...restProps
}) => (
  <WebUI.Disclosure className={WebUI.cn('gap-6', className)} {...restProps}>
    <WebUI.DisclosureSwitch
      reverse={false}
      size="compact"
      className="items-start gap-3 font-normal text-base"
      onClick={onClick}
    >
      {label}
    </WebUI.DisclosureSwitch>
    <WebUI.DisclosureContent
      className={WebUI.cn(
        '[&_>_.DisclosureContent-inner]:!flex pb-4 [&_>_.DisclosureContent-inner]:flex-col [&_>_.DisclosureContent-inner]:gap-6',
        !children && 'hidden',
      )}
    >
      {children}
    </WebUI.DisclosureContent>
  </WebUI.Disclosure>
)

export default BrandKitSettingsDisclosure
