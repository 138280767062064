import type {
  ColumnDef,
  ColumnDefResolved,
  ColumnMeta,
} from '@tanstack/react-table'
import React from 'react'
import {SetRequired, sort} from '@cheddarup/util'

import {Stack, StackDirection, VStack} from './Stack'
import {Ellipsis} from './Ellipsis'
import {cn} from '../utils'

export interface RowNode
  extends Pick<ColumnMeta<unknown, unknown>, 'align' | 'subtle'> {
  id: string
  children: React.ReactNode
}

export interface RowActionNode extends Pick<RowNode, 'children'> {}

export interface RowSlot {
  id: string
  direction?: StackDirection
  nodes: RowNode[]
}

export type RowSlots = RowSlot[] & {
  action?: RowActionNode
}

export interface SlottedRowProps extends React.ComponentPropsWithoutRef<'div'> {
  slots: RowSlots
}

export const SlottedRow = React.forwardRef(
  (
    {slots, className, ...restProps}: SlottedRowProps,
    forwardedRef: React.Ref<HTMLDivElement>,
  ) => (
    <VStack
      ref={forwardedRef}
      className={cn('SlottedRow', 'relative gap-4 p-6', className)}
      {...restProps}
    >
      {slots.map((slot) => (
        <Stack
          key={slot.id}
          className={cn(`SlottedRow-slot-${slot.id}`, 'gap-0_5')}
          direction={slot.direction ?? 'vertical'}
        >
          {slot.nodes.map((node) => (
            <div
              key={node.id}
              data-subtle={node.subtle}
              className={`SlottedRow-node-${node.id}`}
            >
              {typeof node.children === 'string' ? (
                <Ellipsis
                  className={cn(
                    `SlottedRow-node-${node.id}-content`,
                    '[[data-subtle=true]_>_&]:font-light',
                  )}
                >
                  {node.children}
                </Ellipsis>
              ) : (
                node.children
              )}
            </div>
          ))}
        </Stack>
      ))}

      {slots.action?.children && (
        <div
          className={cn('Card-accessoryView', '!m-0 absolute top-6 right-6')}
        >
          {slots.action.children}
        </div>
      )}
    </VStack>
  ),
)

// MARK: – Helpers

export function makeTableColumnsToRowSlotsConverter<T extends object>(
  cols: Parameters<typeof tableColumnsToRowSlots<T>>[0],
  slots: Parameters<typeof tableColumnsToRowSlots<T>>[1],
  specialSlots?: Parameters<typeof tableColumnsToRowSlots<T>>[2],
) {
  return (originalRow: T) =>
    tableColumnsToRowSlots(cols, slots, specialSlots, originalRow)
}

export function tableColumnsToRowSlots<T extends object>(
  cols: Array<ColumnDef<T>>,
  slots: Array<
    {
      slotId: string
      colIds: string[]
    } & Pick<RowSlot, 'direction'>
  >,
  specialSlots: {action?: {colId: string}} | undefined,
  originalRow: T,
) {
  const contentSlots: RowSlots = slots.map((slot) => ({
    id: slot.slotId,
    direction: slot.direction,
    nodes: tableColumnsToRowNodes(
      sort(
        cols.filter((col): col is SetRequired<typeof col, 'id'> => {
          if (col.id == null) {
            throw new Error('Column id must be defined')
          }

          return slot.colIds.includes(col.id)
        }),
      ).asc((col) => slot.colIds.indexOf(col.id)),
      originalRow,
    ),
  }))

  const actionSlot = specialSlots?.action
  if (actionSlot) {
    const actionCol = cols.find((col) => col.id === actionSlot.colId)

    if (!actionCol) {
      throw new Error(
        `Could not find action column with id ${actionSlot.colId}`,
      )
    }

    contentSlots.action = tableColumnToRowNode(actionCol, originalRow)
  }

  return contentSlots
}

export function tableColumnsToRowNodes<T extends object>(
  cols: Array<ColumnDef<T>>,
  originalRow: T,
): RowNode[] {
  return cols.map((col) => tableColumnToRowNode(col, originalRow))
}

export function tableColumnToRowNode<T extends object>(
  _col: ColumnDef<T>,
  originalRow: T,
): RowNode {
  if (_col.id == null) {
    throw new Error('Column id must be defined')
  }

  const col = _col as ColumnDefResolved<T>

  // @ts-expect-error
  const computedVal = col.accessorFn?.(originalRow)

  let cellEl = col.cell

  if (typeof cellEl === 'function') {
    cellEl = cellEl({
      // @ts-expect-error
      getValue: () => computedVal,
      cell: {
        // @ts-expect-error
        getValue: () => computedVal,
      },
      // @ts-expect-error
      row: {
        original: originalRow,
      },
    })
  }

  return {
    id: _col.id,
    subtle: col.meta?.subtle ?? true,
    align: col.meta?.align,
    children: cellEl === undefined ? (computedVal as any) : cellEl,
  }
}
