// @ts-ignore

import {z} from '@cheddarup/util'

export const tabDiscountCalculationMethodSchema = z.enum([
  'fixed',
  'percentage',
])

export const tabDiscountAppliesToSchema = z.enum([
  'total_order',
  'total_order_with_minimum',
  'one_item_most_expensive',
])

export const tabDiscountOptionsSchema = z.object({
  calculationMethod: tabDiscountCalculationMethodSchema,
  fixedAmount: z.number(),
  percentage: z.number(),
  appliesTo: tabDiscountAppliesToSchema,
  minimumPurchase: z.number(),
  restrictToSpecificItems: z.boolean().optional(),
  eligibleItemIds: z.number().array().optional(),
})

export const tabDiscountRedeemedStatusSchema = z.enum(['redeemed'])

export const tabDiscountRedeemedInfoSchema = z.object({
  paymentId: z.number(),
  redeemedAt: z.string(),
  status: tabDiscountRedeemedStatusSchema,
})

export const tabDiscountMetadataSchema = z
  .object({
    redeemedInfo: tabDiscountRedeemedInfoSchema,
    singleUseOnly: z.boolean().optional(),
  })
  .partial()

export const tabDiscountSchema = z.object({
  id: z.number(),
  tab_id: z.number(),
  created_at: z.string(),
  updated_at: z.string(),
  deleted_at: z.string().nullable(),
  code: z.string(),
  active: z.boolean(),
  lock_version: z.number(),
  options: tabDiscountOptionsSchema,
  metadata: tabDiscountMetadataSchema,
  errors: z.record(z.any()),
})

declare global {
  namespace Api {
    // @ts-ignore
    type TabDiscountAppliesTo = z.infer<typeof tabDiscountAppliesToSchema>
    // @ts-ignore
    type TabDiscountCalculationMethod = z.infer<
      typeof tabDiscountCalculationMethodSchema
    >
    // @ts-ignore
    type TabDiscount = z.infer<typeof tabDiscountSchema>
    // @ts-ignore
    type TabDiscountOptions = z.infer<typeof tabDiscountOptionsSchema>
    // @ts-ignore
    type TabDiscountRedeemedInfo = z.infer<typeof tabDiscountRedeemedInfoSchema>
  }
}
