import * as Util from '@cheddarup/util'
import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'

import type {
  FixedItemFormFormik,
  ItemFormValuesListing,
} from '../../../../containers/FixedItemForm/FixedItemForm'
import ItemVariantNameSelect from './ItemVariantNameSelect'
import ItemVariantValuesSelect from './ItemVariantValuesSelect'

const MAX_OPTION_COUNT = 3

export interface ItemVariantOptionArrayInputProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collectionId: number
  formik: FixedItemFormFormik
}

const ItemVariantOptionArrayInput = ({
  collectionId,
  formik,
  className,
  ...restProps
}: ItemVariantOptionArrayInputProps) => {
  const variantOptionKeysAndValuesQuery = api.tabItems.list.useQuery(
    {
      pathParams: {
        tabId: collectionId,
      },
    },
    {
      select: (items) => ({
        keys: (() =>
          Util.unique([
            ...formik.values.options.variants.options
              .map((o) => o.key)
              .filter((key) => key.length > 0),
            ...items.flatMap((i) =>
              (i.options.variants?.options ?? [])
                .map(({key}) => key)
                .filter((key) => key.length > 0),
            ),
          ]))(),
        values: (() => {
          const allVariantOptions = items.flatMap(
            (i) => i.options.variants?.options ?? [],
          )

          return Util.mapToObj(allVariantOptions, (vo) => [
            vo.key,
            Util.unique(
              [
                ...allVariantOptions
                  .filter((option) => option.key === vo.key)
                  .map((option) => option.values),
                ...vo.values,
              ].flat(),
            ),
          ])
        })(),
      }),
    },
  )

  const options = formik.values.options.variants.options
  const lastOption = options[options.length - 1]

  return (
    <WebUI.VStack className={WebUI.cn('gap-4', className)} {...restProps}>
      <WebUI.VStack className="gap-2">
        <WebUI.HStack className="gap-3 *:flex-[1_0_0px]">
          <WebUI.Text className="text-ds-xs">Option Name</WebUI.Text>
          {options[0] && options[0].key.length > 0 && (
            <WebUI.Text className="text-ds-xs">Option Values</WebUI.Text>
          )}
        </WebUI.HStack>
        {options.map((option, idx) => (
          <WebUI.HStack key={option.key} className="gap-3 *:flex-[1_0_0px]">
            <ItemVariantNameSelect
              className="min-w-32"
              disabled={option.values.length > 1}
              keys={
                variantOptionKeysAndValuesQuery.data?.keys.filter(
                  (k) => !options.map((o) => o.key).includes(k),
                ) ?? []
              }
              value={option.key}
              onChange={(newKey) =>
                formik.setFieldValue(
                  `options.variants.options[${idx}].key`,
                  newKey,
                )
              }
              onDelete={() => {
                const newOptions =
                  formik.values.options.variants.options.filter(
                    (_option, jdx) => jdx !== idx,
                  )
                const newListings = formik.values.options.variants.listings.map(
                  (listing) => ({
                    ...listing,
                    optionValues: Util.omit(listing.optionValues, [option.key]),
                  }),
                )

                formik.setFieldValue('options.variants.options', newOptions)
                formik.setFieldValue('options.variants.listings', newListings)
              }}
            />
            {option.key.length > 0 || option.values.length > 0 ? (
              <ItemVariantValuesSelect
                className="min-w-32"
                values={
                  variantOptionKeysAndValuesQuery.data?.values?.[option.key] ??
                  []
                }
                optionName={option.key}
                value={option.values}
                onChange={(newValues) => {
                  formik.setFieldValue(
                    `options.variants.options[${idx}].values`,
                    newValues,
                  )

                  const keyValuesMap = Object.fromEntries(
                    options
                      .filter((o) => o.values.length > 0)
                      .map((o) => [o.key, o.values] as const),
                  )

                  if (newValues.length > 0) {
                    keyValuesMap[option.key] = newValues
                  } else {
                    delete keyValuesMap[option.key]
                  }

                  formik.setFieldValue(
                    'options.variants.listings',
                    generateVariantListings(
                      keyValuesMap,
                      formik.values.options.variants.listings,
                    ),
                  )
                }}
                onSelectedValueChange={(oldValue, newValue) => {
                  const existingValues =
                    formik.values.options.variants.options[idx]?.values ?? []

                  if (existingValues.includes(newValue)) {
                    return
                  }

                  formik.setFieldValue(
                    `options.variants.options[${idx}].values`,
                    existingValues.map((v) => (v === oldValue ? newValue : v)),
                  )
                  formik.setFieldValue(
                    'options.variants.listings',
                    formik.values.options.variants.listings.map((l) => ({
                      ...l,
                      optionValues: Util.mapValues(l.optionValues, (ov) =>
                        ov === oldValue ? newValue : ov,
                      ),
                    })),
                  )
                }}
              />
            ) : (
              <div />
            )}
          </WebUI.HStack>
        ))}
      </WebUI.VStack>
      <WebUI.Button
        className="self-start"
        variant="secondary"
        disabled={
          (!!lastOption &&
            ((lastOption?.key ?? '').length === 0 ||
              lastOption?.values.length === 0)) ||
          options.length === MAX_OPTION_COUNT
        }
        type="button"
        onClick={() =>
          formik.setFieldValue('options.variants.options', [
            ...formik.values.options.variants.options,
            {key: '', values: []},
          ])
        }
      >
        Add Option
      </WebUI.Button>
    </WebUI.VStack>
  )
}

// MARK: – Helpers

const generateIdFromOptionValues = (optionValues: Record<string, string>) =>
  Util.sort(Object.keys(optionValues))
    .asc((optionKey) => optionKey)
    .map((optionValueKey) => `${optionValueKey}${optionValues[optionValueKey]}`)
    .join('$$')

const generateVariantListings = (
  keyValuesMap: Record<string, string[]>,
  existingListings: ItemFormValuesListing[] = [],
) => {
  const variantValuesCombinations: Array<Record<string, string>> =
    Util.cartesianProduct(keyValuesMap)
  const newVariantListings: ItemFormValuesListing[] =
    variantValuesCombinations.map((optionValues) => ({
      optionValues,
      uuid: Util.makeUuid(),
      sku: '',
      amount: '',
      imageId: null,
      localImage: null,
      description: '',
      retailPrice: '',
      available_quantity: '',
    }))

  const allValues = Object.values(keyValuesMap).flat()

  const updatedExistingListings = existingListings.map((listing) => {
    const allListingValueKeys = Object.keys(listing.optionValues)

    return {
      ...listing,
      optionValues: {
        ...Util.pickBy(
          Util.mapValues(listing.optionValues, (value) =>
            allValues.includes(value) ? value : null,
          ),
          (v): v is string => !!v,
        ),
        ...Util.pickBy(
          Util.mapValues(keyValuesMap, (values, key) =>
            allListingValueKeys.includes(key) ? null : values[0],
          ),
          (v): v is string => !!v,
        ),
      },
    }
  })

  return newVariantListings.map((newListing) => {
    const id = generateIdFromOptionValues(newListing.optionValues)
    return (
      updatedExistingListings.find(
        (oldListing) =>
          generateIdFromOptionValues(oldListing.optionValues) === id,
      ) || newListing
    )
  })
}

export default ItemVariantOptionArrayInput
