import {omit} from '@cheddarup/util'
import {endpoints} from '../../endpoints'
import {makeQueryUpdate, makeUseMutation} from '../use-mutation'

export const useCreateInventoryGroupMutation = makeUseMutation(
  endpoints.inventoryGroups.create,
  (vars) => ({
    regular: (newInventoryGroup) =>
      [
        makeQueryUpdate(
          endpoints.inventoryGroups.list,
          (prevInventoryGrups) =>
            prevInventoryGrups
              ? [...prevInventoryGrups, newInventoryGroup]
              : prevInventoryGrups,
          vars,
        ),
        makeQueryUpdate(
          endpoints.tabItems.list,
          (prevItems) => {
            if (!prevItems || !vars.body?.tab_item_id) {
              return prevItems
            }
            return prevItems.map((i) =>
              i.id === vars.body?.tab_item_id
                ? {
                    ...i,
                    inventory_items: i.inventory_items?.map((ii) => ({
                      ...ii,
                      inventory_groups: vars.body?.inventory_item_ids.includes(
                        ii.id,
                      )
                        ? [omit(newInventoryGroup, ['inventory_items'])]
                        : [],
                    })),
                  }
                : i,
            )
          },
          vars,
        ),
        vars.body?.tab_item_id
          ? makeQueryUpdate(
              endpoints.tabItems.detail,
              (prevItem) => {
                return prevItem
                  ? {
                      ...prevItem,
                      inventory_items: prevItem.inventory_items?.map((ii) => ({
                        ...ii,
                        inventory_groups:
                          vars.body?.inventory_item_ids.includes(ii.id)
                            ? [omit(newInventoryGroup, ['inventory_items'])]
                            : [],
                      })),
                    }
                  : prevItem
              },
              {
                pathParams: {
                  tabId: vars.pathParams.tabId,
                  itemId: vars.body.tab_item_id,
                },
              },
            )
          : undefined,
      ].filter((u) => u != null),
  }),
)

export const useUpdateInventoryGroupMutation = makeUseMutation(
  endpoints.inventoryGroups.update,
  (vars) => ({
    regular: (updatedInventoryGroup) =>
      [
        makeQueryUpdate(
          endpoints.inventoryGroups.list,
          (prevInventoryGrups) =>
            prevInventoryGrups?.map((inventoryGroup) =>
              inventoryGroup.id === updatedInventoryGroup.id
                ? updatedInventoryGroup
                : inventoryGroup,
            ),
          vars,
        ),
        makeQueryUpdate(
          endpoints.tabItems.list,
          (prevItems) => {
            if (!prevItems || !vars.body?.tab_item_id) {
              return prevItems
            }
            return prevItems.map((i) =>
              i.id === vars.body?.tab_item_id
                ? {
                    ...i,
                    inventory_items: i.inventory_items?.map((ii) => ({
                      ...ii,
                      inventory_groups: vars.body?.inventory_item_ids.includes(
                        ii.id,
                      )
                        ? [omit(updatedInventoryGroup, ['inventory_items'])]
                        : [],
                    })),
                  }
                : i,
            )
          },
          vars,
        ),
        vars.body?.tab_item_id
          ? makeQueryUpdate(
              endpoints.tabItems.detail,
              (prevItem) => {
                return prevItem
                  ? {
                      ...prevItem,
                      inventory_items: prevItem.inventory_items?.map((ii) => ({
                        ...ii,
                        inventory_groups:
                          vars.body?.inventory_item_ids.includes(ii.id)
                            ? [omit(updatedInventoryGroup, ['inventory_items'])]
                            : [],
                      })),
                    }
                  : prevItem
              },
              {
                pathParams: {
                  tabId: vars.pathParams.tabId,
                  itemId: vars.body.tab_item_id,
                },
              },
            )
          : undefined,
      ].filter((u) => u != null),
  }),
)

export const useDeleteInventoryGroupMutation = makeUseMutation(
  endpoints.inventoryGroups.delete,
  (vars) => ({
    regular: () => [
      makeQueryUpdate(
        endpoints.inventoryGroups.list,
        (prevInventoryGrups) =>
          prevInventoryGrups?.filter(
            (inventoryGroup) =>
              inventoryGroup.id !== vars.pathParams.inventoryGroupId,
          ),
        vars,
      ),
    ],
  }),
)
