import * as WebUI from '@cheddarup/web-ui'
import {useUpdateEffect} from '@cheddarup/react-util'
import {useRef, useState} from 'react'
import {api, useCreateTabPaymentShipmentMutation} from '@cheddarup/api-client'
import EasypostAddressHelpers from 'src/helpers/EasypostAddressHelpers'
import PrintShippingHelpers from 'src/helpers/PrintShippingHelpers'
import * as Util from '@cheddarup/util'

import {
  AddressCheckModal,
  PrintShippingInfoForm,
  PrintShippingLabelPayForm,
  PrintShippingLabelShipAddress,
} from '../../components'
import type {PrintShippingLabelFormik} from './PrintShippingLabelContainer'

const OZ_IN_LBS = 16

export interface Addresses {
  origin: Api.PaymentShippingInfo['shipTo']
  easypost: Api.PaymentShippingInfo['shipTo']
  mode: 'shipTo' | 'shipFrom'
}

export interface PrintShippingLabelFormContainerProps {
  stripeReady?: boolean
  collectionId: number
  paymentId: number
  selectedRate: Api.ShipmentRate | null
  paymentTabMember: Api.TabMember | null
  creditCards: Api.CreditCard[]
  formik: PrintShippingLabelFormik
}

const PrintShippingLabelFormContainer = ({
  stripeReady,
  collectionId,
  paymentId,
  selectedRate,
  paymentTabMember,
  creditCards,
  formik,
}: PrintShippingLabelFormContainerProps) => {
  const [amountsVisible, setAmountsVisible] = useState(
    !formik.dirty && formik.initialValues.shipment.packageWeightOz > 0,
  )
  const [calculateShippingCostLoading, setCalculateShippingCostLoading] =
    useState(false)
  const [shipToAddressChecking, setShipToAddressChecking] = useState(false)
  const [shipFromAddressChecking, setShipFromAddressChecking] = useState(false)
  const [addressCheckModalAddresses, setAddressCheckModalAddresses] =
    useState<Addresses | null>(null)
  const calculatingShippingCostRef = useRef(false)
  const {data: userEmail} = api.auth.session.useQuery(undefined, {
    select: (session) => session.user.email,
  })
  const createShipmentMutation = useCreateTabPaymentShipmentMutation()
  const growlActions = WebUI.useGrowlActions()

  const keyValues = JSON.stringify([
    formik.values.shipment,
    formik.values.shipTo,
    formik.values.shipFrom,
  ])

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useUpdateEffect(() => {
    setAmountsVisible(false)
  }, [keyValues])

  const getParcelWeight = () => {
    const needsWeights = PrintShippingHelpers.getNeedsParcelWeight({
      packageType: formik.values.shipment.packageType,
      service: formik.values.shipment.serviceType,
    })

    if (needsWeights.oz || needsWeights.lbs) {
      const weightOz = Number(formik.values.shipment.packageWeightOz)
      const weightLbs = Number(formik.values.shipment.packageWeightLbs)
      const weight =
        (Number.isNaN(weightOz) ? 0 : weightOz) +
        (Number.isNaN(weightLbs) ? 0 : weightLbs * OZ_IN_LBS)

      return {
        weight,
        weightOz,
        weightLbs,
      }
    }
    const {weight: maxWeight, unit: maxWeightUnit} =
      PrintShippingHelpers.getParcelMaxWeightForService(
        formik.values.shipment.serviceType,
      )
    const weightOz = maxWeightUnit === 'oz' ? maxWeight : 0
    const weightLbs = maxWeightUnit === 'lbs' ? maxWeight : 0
    const weight = weightOz + weightLbs * OZ_IN_LBS

    return {
      weight,
      weightOz,
      weightLbs,
    }
  }

  const validateWeightAndRates = () => {
    const {weight, weightOz} = getParcelWeight()
    if (!formik.values.shipment) {
      return false
    }
    if (!weight || weight <= 0) {
      growlActions.clear()
      growlActions.show('error', {title: 'Error', body: 'Weight is invalid'})

      return false
    }
    if (formik.values.shipment.serviceType === 'First' && weightOz > 15.99) {
      growlActions.clear()
      growlActions.show('error', {
        title: 'Error',
        body: 'Package is too heavy for First class mail',
      })
      return false
    }
    return true
  }

  const handleCheckAddressSuccess = ({
    addresses,
    alertsHidden,
  }: {
    addresses: Addresses
    alertsHidden?: boolean
  }) => {
    if (
      'autocorrected' in addresses.easypost &&
      addresses.easypost.autocorrected
    ) {
      setAddressCheckModalAddresses(addresses)
      throw new Error('Address autocorrected')
    }
    if (!alertsHidden) {
      growlActions.clear()
      growlActions.show('success', {
        title: 'Success',
        body: 'Your address looks correct.',
      })
    }
  }

  const handleCheckAddressError = (error: string, source: string) => {
    growlActions.clear()
    growlActions.show('error', {title: `Error in ${source}`, body: error})
  }

  const handleCheckShipToAddress = async (
    {alertsHidden} = {alertsHidden: false},
  ) => {
    try {
      setShipToAddressChecking(true)
      const address = await EasypostAddressHelpers.createEasyPostAddress({
        collectionId,
        paymentId,
        payload: {
          phone: paymentTabMember?.phone,
          email: paymentTabMember?.email,
          ...formik.values.shipTo,
        },
      })
      handleCheckAddressSuccess({
        addresses: {
          origin: formik.values.shipTo,
          easypost: address,
          mode: 'shipTo',
        },
        alertsHidden,
      })
      return address
    } catch (err: any) {
      const {address: addressError, street1, street2, ...formErrors} = err

      if (addressError || street1 || street2) {
        handleCheckAddressError(
          addressError || street1 || street2,
          'Ship To Address',
        )
      } else if (Object.keys(formErrors).length !== 0 && !alertsHidden) {
        handleCheckAddressError('Address incomplete', 'Ship To Address')
      }

      formik.setFieldError('shipTo', formErrors)

      return undefined
    } finally {
      setShipToAddressChecking(false)
    }
  }

  const handleCheckShipFromAddress = async (
    {alertsHidden} = {alertsHidden: false},
  ) => {
    try {
      setShipFromAddressChecking(true)
      const address = await EasypostAddressHelpers.createEasyPostAddress({
        collectionId,
        paymentId,
        payload: {
          email: userEmail ?? null,
          ...formik.values.shipFrom,
        },
      })

      handleCheckAddressSuccess({
        addresses: {
          origin: formik.values.shipFrom,
          easypost: address,
          mode: 'shipFrom',
        },
        alertsHidden,
      })

      return address
    } catch (err: any) {
      const {address: addressError, street1, street2, ...formErrors} = err

      if (addressError || street1 || street2) {
        handleCheckAddressError(
          addressError || street1 || street2,
          'Ship From Address',
        )
      } else if (Object.keys(formErrors).length !== 0 && !alertsHidden) {
        handleCheckAddressError('Address incomplete', 'Ship From Address')
      }

      formik.setFieldError('shipFrom', formErrors)

      return undefined
    } finally {
      setShipFromAddressChecking(false)
    }
  }

  const createPaymentShipment = async ({
    weight,
    shipToAddress,
    shipFromAddress,
  }: {
    weight: number
    shipToAddress: Api.PaymentShippingInfo['shipTo']
    shipFromAddress: Api.PaymentShippingInfo['shipTo']
  }) => {
    try {
      await createShipmentMutation.mutateAsync({
        pathParams: {
          tabId: collectionId,
          paymentId,
        },
        body: {
          to_address: shipToAddress,
          from_address: shipFromAddress,
          parcel: {
            weight,
            predefined_package: formik.values.shipment.packageType,
          },
          options: {
            delivery_confirmation: formik.values.shipment.signatureConfirmation
              ? 'SIGNATURE'
              : null,
          },
          tracking: formik.values.shipment.tracking,
        },
      })
      setAmountsVisible(true)
    } catch (err: any) {
      growlActions.show('error', {
        title: 'Could not calculate shipping cost',
        body: err.data?.errors?.[0]?.details || 'Something went wrong',
      })
    }
  }

  const handleCalculateShippingCost = async () => {
    const {weight} = getParcelWeight()
    const errors = await formik.validateForm()

    if (Object.keys(errors).length > 0) {
      formik.setTouched(Util.mapValues(errors, (v) => !!v) as any)
      handleCheckAddressError(
        'Address incomplete',
        errors.shipTo ? 'Ship To Address' : 'Ship From Address',
      )

      return
    }
    if (!validateWeightAndRates()) {
      return
    }

    calculatingShippingCostRef.current = true
    setCalculateShippingCostLoading(true)

    try {
      const shipToAddress = await handleCheckShipToAddress({
        alertsHidden: true,
      })
      if (shipToAddress) {
        const shipFromAddress = await handleCheckShipFromAddress({
          alertsHidden: true,
        })

        if (shipFromAddress) {
          createPaymentShipment({
            weight,
            shipToAddress,
            shipFromAddress,
          })
        }
      }
    } finally {
      calculatingShippingCostRef.current = false
      setCalculateShippingCostLoading(false)
    }
  }

  const signatureAmount =
    PrintShippingHelpers.getSignatureConfirmationAmountForService(
      (selectedRate?.service as any) ?? formik.values.shipment.serviceType,
    )

  return (
    <WebUI.VStack className="gap-4">
      <WebUI.VStack
        className={
          'shrink-0 basis-auto items-stretch gap-4 *:grow md:flex-row md:items-start *:md:flex-[1_0_0px]'
        }
      >
        <PrintShippingLabelShipAddress
          namePrefix="shipTo"
          title="Recipient"
          loading={shipToAddressChecking}
          values={formik.values}
          isSubmitting={formik.isSubmitting}
          onCheck={handleCheckShipToAddress}
        />
        <PrintShippingLabelShipAddress
          namePrefix="shipFrom"
          title="Sender"
          loading={shipFromAddressChecking}
          values={formik.values}
          isSubmitting={formik.isSubmitting}
          onCheck={handleCheckShipFromAddress}
        />
      </WebUI.VStack>
      <PrintShippingInfoForm
        signatureAmount={signatureAmount}
        values={formik.values}
      />
      <PrintShippingLabelPayForm
        stripeReady={stripeReady}
        formik={formik}
        amountsVisible={amountsVisible}
        amounts={{
          postage: selectedRate
            ? Number.parseFloat(selectedRate.rate) -
              (formik.values.shipment.signatureConfirmation
                ? signatureAmount
                : 0)
            : 0,
          signature: formik.values.shipment.signatureConfirmation
            ? signatureAmount
            : null,
        }}
        creditCards={creditCards}
        calculateShippingCostDisabled={
          createShipmentMutation.isPending || calculateShippingCostLoading
        }
        calculateShippingCostLoading={
          createShipmentMutation.isPending || calculateShippingCostLoading
        }
        onCalculateShippingCost={handleCalculateShippingCost}
      />
      {!!addressCheckModalAddresses && (
        <AddressCheckModal
          initialVisible
          easypostAddress={addressCheckModalAddresses.easypost}
          originAddress={addressCheckModalAddresses.origin}
          onUseEasypostAddress={
            addressCheckModalAddresses.mode === 'shipTo'
              ? async (easypostAddress) => {
                  const address =
                    EasypostAddressHelpers.getAddressFromEasypostAddress(
                      easypostAddress,
                    )
                  formik.setFieldValue('shipTo', address)
                  // Hack to wait for new value to be applied
                  // Pending https://github.com/jaredpalmer/formik/issues/529
                  await Promise.resolve()

                  setAddressCheckModalAddresses(null)

                  if (calculatingShippingCostRef.current) {
                    handleCalculateShippingCost()
                  }
                }
              : async (easypostAddress) => {
                  const address =
                    EasypostAddressHelpers.getAddressFromEasypostAddress(
                      easypostAddress,
                    )

                  formik.setFieldValue('shipFrom', address)
                  // Hack to wait for new value to be applied
                  // Pending https://github.com/jaredpalmer/formik/issues/529
                  await Promise.resolve()

                  setAddressCheckModalAddresses(null)

                  if (calculatingShippingCostRef.current) {
                    handleCalculateShippingCost()
                  }
                }
          }
          onDidHide={() => setAddressCheckModalAddresses(null)}
        />
      )}
    </WebUI.VStack>
  )
}

export default PrintShippingLabelFormContainer
