// Based on https://github.com/uber/baseweb/blob/master/src/timezonepicker/timezone-picker.tsx
import {
  TimezoneMap,
  arrayFromObject,
  cuSupportedTimeZonesIdToNameMap,
  pickBy,
  timeZoneIdToNameMap,
} from '@cheddarup/util'
import {useLiveRef, useUpdateEffect} from '@cheddarup/react-util'
import React, {useMemo, useState} from 'react'

import {
  Combobox,
  ComboboxInput,
  ComboboxInstance,
  ComboboxItem,
  ComboboxOption,
  ComboboxPopover,
  VirtualizedComboboxList,
} from './Combobox'
import type {InputProps} from './Input'
import {cn} from '../utils'

export interface Timezone {
  id: string
  label: string
  offsetLabel: string
}

export interface TimeZoneComboboxProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'defaultValue'> {
  popoverClassName?: string
  inputSize?: InputProps['size']
  disabled?: boolean
  date?: Date
  defaultValue?: string | null
  value?: string | null
  onValueChange?: (value?: string | null) => unknown
  filterTimeZones?: (timeZone: Timezone) => boolean
}

export const TimeZoneCombobox = React.forwardRef<
  ComboboxInstance,
  TimeZoneComboboxProps
>(
  (
    {
      date = new Date(),
      defaultValue,
      value: valueProp,
      onValueChange,
      inputSize,
      popoverClassName,
      className,
      filterTimeZones,
      ...restProps
    },
    forwardedRef,
  ) => {
    const [value, setValue] = useState<string | null>(
      valueProp ?? defaultValue ?? null,
    )

    const [timeZones] = useState(makeTimezones(date))
    const onValueChangeRef = useLiveRef(onValueChange)

    useUpdateEffect(() => {
      onValueChangeRef.current?.(value)
    }, [value])

    const options: ComboboxItem[] = useMemo(
      () =>
        (filterTimeZones ? timeZones.filter(filterTimeZones) : timeZones).map(
          (tz) => ({
            value: tz.id,
            label: tz.label,
            offset: tz.offsetLabel,
          }),
        ),
      [timeZones, filterTimeZones],
    )
    const selectedTimeZone = useMemo(
      () =>
        value
          ? makeTimezones(
              date,
              pickBy(timeZoneIdToNameMap, (_, key) => key === value),
            )[0]
          : null,
      [date, value],
    )
    const selectedOption = useMemo(
      () =>
        options.find(
          (o) =>
            o.value === value ||
            (selectedTimeZone && o.offset === selectedTimeZone.offsetLabel),
        ) ?? null,
      [options, value, selectedTimeZone],
    )

    return (
      <Combobox
        aria-label="Time zone combobox"
        ref={forwardedRef}
        className={cn('TimeZoneCombobox', className)}
        selectedItem={selectedOption}
        onSelectedItemChange={(change) =>
          setValue?.(change.selectedItem?.value ?? null)
        }
        {...restProps}
      >
        <ComboboxInput
          className="TimeZoneCombobox-input"
          size={inputSize}
          placeholder="Time zone"
        />
        <ComboboxPopover className={popoverClassName}>
          <VirtualizedComboboxList>
            {options.map((o) => (
              <ComboboxOption key={o.value} {...o} />
            ))}
          </VirtualizedComboboxList>
        </ComboboxPopover>
      </Combobox>
    )
  },
)

// MARK: – SupportedTimeZoneCombobox

export interface SupportedTimeZoneComboboxProps
  extends Omit<TimeZoneComboboxProps, 'filterTimeZones'> {
  timeZones?: Partial<TimezoneMap>
}

export const SupportedTimeZoneCombobox = React.forwardRef<
  ComboboxInstance,
  SupportedTimeZoneComboboxProps
>(
  (
    {
      date = new Date(),
      timeZones = cuSupportedTimeZonesIdToNameMap,
      ...restProps
    },
    forwardedRef,
  ) => {
    const supportedTimeZoneIds: string[] = Object.keys(timeZones)

    return (
      <TimeZoneCombobox
        ref={forwardedRef}
        filterTimeZones={(tz) => supportedTimeZoneIds.includes(tz.id)}
        {...restProps}
      />
    )
  },
)

export function makeTimezones(
  compareDate: Date,
  timezoneIdToNameMap: Partial<TimezoneMap> = timeZoneIdToNameMap,
): Timezone[] {
  return (
    arrayFromObject(timezoneIdToNameMap, (tzId) => {
      const offsetLabel = compareDate
        .toLocaleDateString('en-US', {
          day: '2-digit',
          timeZoneName: 'shortOffset',
          timeZone: tzId,
        })
        .slice(4)
      const abbr = compareDate
        .toLocaleDateString('en-US', {
          day: '2-digit',
          timeZoneName: 'short',
          timeZone: tzId,
        })
        .slice(4)
      const prefix = compareDate
        .toLocaleDateString('en-US', {
          day: '2-digit',
          timeZoneName: 'long',
          timeZone: tzId,
        })
        .slice(4)
      const label = `${prefix}${abbr && abbr.length < 5 ? ` (${abbr})` : ''}`

      return {
        id: tzId,
        label,
        offsetLabel,
      }
    })
      // Sorts W -> E, prioritizes america. could be more nuanced based on system tz but simple for now
      .sort((a, b) => (a.offsetLabel < b.offsetLabel ? -1 : 1))
  )
}
