import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {
  bankAccountSchema,
  creditCardSchema,
  recurringPaymentContractSchema,
} from '../schemas'

export default {
  list: makeEndpoint({
    path: 'users/recurring_payment_contracts',
    responseSchema: z.object({
      contracts: recurringPaymentContractSchema.array(),
      banks: bankAccountSchema.array(),
      cards: creditCardSchema.array(),
    }),
  }),
  detail: makeEndpoint({
    path: 'users/recurring_payment_contracts/:contractId',
    responseSchema: recurringPaymentContractSchema,
  }),
  update: makeEndpoint({
    path: 'users/recurring_payment_contracts/:contractId',
    method: 'PATCH',
    bodySchema: z.object({
      stripe_source: z.string(),
    }),
    responseSchema: recurringPaymentContractSchema,
  }),
  delete: makeEndpoint({
    path: 'users/recurring_payment_contracts/:contractId',
    method: 'DELETE',
    responseSchema: z.any(),
  }),
}
