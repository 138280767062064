import React from 'react'
import * as WebUI from '@cheddarup/web-ui'

export const DividerFieldsEdit = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => (
  <WebUI.VStack
    className={WebUI.cn('h-[50px] justify-center', className)}
    {...restProps}
  >
    <WebUI.Separator className="mx-4" variant="primary" />
  </WebUI.VStack>
)
