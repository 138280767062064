import React from 'react'
import * as WebUI from '@cheddarup/web-ui'

export interface RecurringOptionsInputValue {
  type: string
  text: string
}

export interface RecurringOptionsInputProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'> {
  inputAriaInvalid?: boolean
  reversed?: boolean
  hideTextInput?: boolean
  textInputPlaceholder?: string
  amountInput?: boolean
  typeOptions: Array<{label: string; value: string}>
  value: RecurringOptionsInputValue
  onChange: (newValue: RecurringOptionsInputValue) => void
}

const RecurringOptionsInput = ({
  inputAriaInvalid,
  reversed,
  hideTextInput,
  textInputPlaceholder,
  typeOptions,
  value,
  amountInput,
  onChange,
  className,
  ...restProps
}: RecurringOptionsInputProps) => {
  const handleChangeInput = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.FocusEvent<HTMLInputElement>,
  ) => onChange({...value, text: event.target.value})
  const InputComp = amountInput ? WebUI.AmountInput : WebUI.Input
  return (
    <WebUI.HStack
      className={WebUI.cn(reversed && 'flex-row-reverse', className)}
      {...restProps}
    >
      <WebUI.Select
        className={WebUI.cn(
          hideTextInput ? 'grow' : 'grow-[0.6]',
          '[&_>_.Select-select]:min-h-10 [&_>_.Select-select]:rounded-none [&_>_.Select-select]:pr-8 [&_>_.Select-select]:pl-4 [&_>_.Select-select]:text-tint [&_>_.Select-select]:shadow-none',
        )}
        value={value.type}
        onChange={(event) => onChange({...value, type: event.target.value})}
      >
        {typeOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </WebUI.Select>
      {!hideTextInput && (
        <>
          <WebUI.Separator
            className="border-natural-70"
            orientation="vertical"
          />
          <InputComp
            aria-invalid={inputAriaInvalid}
            className={`grow-[0.4] rounded-none px-4 ${amountInput ? '' : 'text-tint'} shadow-none`}
            autoFocus
            prefix="$"
            placeholder={textInputPlaceholder}
            value={value.text}
            onBlur={handleChangeInput}
            onChange={handleChangeInput}
          />
        </>
      )}
    </WebUI.HStack>
  )
}

export default RecurringOptionsInput
