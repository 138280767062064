import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {SharpAvatar} from 'src/components/SharpAvatar'
import * as Util from '@cheddarup/util'
import {BrandKitColors} from '@cheddarup/core'
import {getCUReadableColor} from 'src/helpers/color-utils'

interface MeetTheTeamProps extends React.ComponentPropsWithoutRef<'div'> {
  brandKitColors: BrandKitColors
  team: Api.GroupPageSection
}

const MeetTheTeam: React.FC<MeetTheTeamProps> = ({
  brandKitColors,
  team,
  className,
  style,
  ...restProps
}) => {
  const sortedTeamMembers = team.team_members
    ? Util.sort(team.team_members).asc((tm) => tm.position)
    : []

  return (
    <WebUI.VStack
      className={WebUI.cn('px-2 py-18 xl:px-0', className)}
      style={{
        color: getCUReadableColor(brandKitColors[team.background_color], {
          darkColor: '#373737',
        }),
        backgroundColor: brandKitColors[team.background_color],
        ...style,
      }}
      {...restProps}
    >
      <WebUI.VStack className="mx-auto w-full max-w-[1100px] gap-5 px-6">
        <WebUI.Heading className="font-accentAlt text-h-6 sm:text-h-4">
          {team.headline}
        </WebUI.Heading>
        <div className="flex flex-col gap-8">
          <WebUI.Text className="font-light text-ds-md">
            {team.description}
          </WebUI.Text>
          <div className="grid grid-cols-1 gap-10 sm:grid-cols-3 sm:gap-8">
            {sortedTeamMembers.map((member) => (
              <TeamMemberCard
                key={member.id}
                member={member}
                brandKitColors={brandKitColors}
              />
            ))}
          </div>
        </div>
      </WebUI.VStack>
    </WebUI.VStack>
  )
}

// MARK: - TeamMemberCard

interface TeamMemberCardProps {
  member: Api.GroupPageTeamMember
  brandKitColors: BrandKitColors
}

const TeamMemberCard = ({member, brandKitColors}: TeamMemberCardProps) => (
  <WebUI.HStack className="items-start gap-6">
    <SharpAvatar
      size={60}
      image={member.profile_picture}
      name={member.name}
      errorFallbackStyles={{
        backgroundColor: brandKitColors.primary,
        color: getCUReadableColor(brandKitColors.primary),
      }}
    />
    <WebUI.VStack className="max-w-[210px]">
      {member.email ? (
        <WebUI.Anchor
          className="text-ds-md [&_>_.Anchor-content]:font-semibold"
          style={{color: brandKitColors.primary}}
          href={`mailto:${member.email}`}
        >
          {member.name}
        </WebUI.Anchor>
      ) : (
        <WebUI.Text className="font-semibold text-ds-md">
          {member.name}
        </WebUI.Text>
      )}
      <WebUI.Text className="font-light text-ds-md leading-compact">
        {member.title}
      </WebUI.Text>
    </WebUI.VStack>
  </WebUI.HStack>
)

export default MeetTheTeam
