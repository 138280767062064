import * as Util from '@cheddarup/util'
import {useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'

import type {FixedItemFormFormik} from '../../../../../containers/FixedItemForm/FixedItemForm'

export interface ItemVariantsListingQuantityModalProps
  extends WebUI.PromptProps {
  formik: FixedItemFormFormik
  listingsUuids: string[]
}

const ItemVariantsListingQuantityModal = ({
  formik,
  listingsUuids,
  ...restProps
}: ItemVariantsListingQuantityModalProps) => {
  const [quantityValue, setQuantityValue] = useState('')
  return (
    <WebUI.Prompt aria-label="Edit variants quantities" {...restProps}>
      {(dialog) => (
        <WebUI.VStack className="gap-3">
          <WebUI.PromptHeader
            className="[&_>_.PromptHeader-heading]:text-ds-lg"
            heading={`Enter available quantity for ${Util.pluralize(
              'variant',
              listingsUuids.length,
              true,
            )}`}
            subheading={`"Sold out" will show when all available variants have been purchased.`}
          />
          <WebUI.FormField label="Qty Available">
            <WebUI.NumberInput
              decimalScale={0}
              placeholder="Qty Available"
              onChange={(event) => setQuantityValue(event.target.value)}
            />
          </WebUI.FormField>
          <WebUI.HStack className="gap-3">
            <WebUI.Button
              onClick={() => {
                formik.setFieldValue(
                  'options.variants.listings',
                  formik.values.options.variants.listings.map((listing) =>
                    listingsUuids.includes(listing.uuid)
                      ? {
                          ...listing,
                          available_quantity: Number(quantityValue),
                        }
                      : listing,
                  ),
                )

                dialog.hide()
              }}
            >
              Save
            </WebUI.Button>
            <WebUI.Button variant="secondary" onClick={() => dialog.hide()}>
              Cancel
            </WebUI.Button>
          </WebUI.HStack>
        </WebUI.VStack>
      )}
    </WebUI.Prompt>
  )
}

export default ItemVariantsListingQuantityModal
