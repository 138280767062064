import {useForkRef} from '@cheddarup/react-util'
import {forwardRef, useRef} from 'react'

import animationData from '../assets/tesselate.lottie.json'
import {Lottie, LottieInstance, LottieProps} from './Lottie'

export interface TesselateSpinnerProps
  extends Omit<LottieProps, 'width' | 'height'> {
  size?: React.CSSProperties['width'] | React.CSSProperties['height']
}

export const TesselateSpinner = forwardRef<
  LottieInstance,
  TesselateSpinnerProps
>(({size = '8em', loop = true, ...restProps}, forwardedRef) => {
  const ownRef = useRef<LottieInstance>(null)
  const ref = useForkRef(forwardedRef, ownRef)

  ownRef.current?.setSpeed(2)

  return (
    <Lottie
      ref={ref as any}
      animationData={animationData}
      width={size}
      height={size}
      loop={loop}
      {...restProps}
    />
  )
})
