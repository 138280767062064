import {z} from '@cheddarup/util'

import {cubeQuerySchema, makeEndpoint} from '../utils'
import {
  cubeExportSchema,
  cubeReportSchema,
  cubeReportTypeSchema,
} from '../schemas'

export default {
  reportList: makeEndpoint({
    path: 'users/cube/reports',
    responseSchema: cubeReportSchema.array(),
  }),
  reportDetail: makeEndpoint({
    path: 'users/cube/reports/:reportId',
    responseSchema: cubeReportSchema,
  }),
  exportList: makeEndpoint({
    path: 'users/cube/exports',
    responseSchema: cubeExportSchema.array(),
  }),
  createToken: makeEndpoint({
    path: 'users/cube/token',
    method: 'POST',
    responseSchema: z.object({
      token: z.string(),
    }),
  }),
  createReport: makeEndpoint({
    path: 'users/cube/reports',
    method: 'POST',
    bodySchema: z.object({
      name: z.string(),
      report_type: cubeReportTypeSchema.optional(),
      count_query: cubeQuerySchema,
      export_query: cubeQuerySchema.optional(),
    }),
    responseSchema: cubeReportSchema,
  }),
  updateReport: makeEndpoint({
    path: 'users/cube/reports/:reportId',
    method: 'PATCH',
    bodySchema: z
      .object({
        name: z.string(),
        report_type: cubeReportTypeSchema,
        count_query: cubeQuerySchema,
        export_query: cubeQuerySchema,
      })
      .partial(),
    responseSchema: cubeReportSchema,
  }),
  deleteReport: makeEndpoint({
    path: 'users/cube/reports/:reportId',
    method: 'DELETE',
    responseSchema: z.any(),
  }),
  createExport: makeEndpoint({
    path: 'users/cube/exports',
    method: 'POST',
    bodySchema: z.object({
      export_query: cubeQuerySchema,
      count_query: cubeQuerySchema,
    }),
    responseSchema: cubeExportSchema,
  }),
}
