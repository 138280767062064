import {useNavigate} from 'react-router-dom'
import {Waypoint} from 'react-waypoint'
import {useMemo} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'

import {
  MarketplaceItemNoResultsFoundView,
  MarketplaceSalesItem,
  MarketplaceSectionTitle,
} from '../../components'

export interface MarketplaceSalesContainerProps {
  searchKeyword: string
  sortBy: {sort: string; direction: 'asc' | 'desc'}
}

const MarketplaceSalesContainer = ({
  searchKeyword,
  sortBy,
}: MarketplaceSalesContainerProps) => {
  const navigate = useNavigate()
  const {data: session} = api.auth.session.useQuery()

  const {data, hasNextPage, fetchNextPage, isPending, isFetchingNextPage} =
    api.marketplace.organizers.useInfiniteQuery(
      {
        queryParams: {
          search: searchKeyword || undefined,
          sort: sortBy.sort,
          direction: sortBy.direction,
          page: 1,
          per_page: 20,
        },
      },
      {
        initialPageParam: 1,
        enabled: !!session?.organization_data,
        getNextPageParam: ({pagination}) => {
          if (pagination.page < pagination.total) {
            return pagination.page + 1
          }
          return undefined
        },
      },
    )

  const marketplaceName =
    session?.organization_data?.internalMarketplace?.organizerNickname ?? ''

  const activeSales = useMemo(
    () =>
      data?.pages
        .flatMap((p) => p.data)
        ?.filter(
          (organizer) =>
            organizer.collections && organizer.collections?.length > 0,
        ) ?? [],
    [data?.pages],
  )

  return (
    <WebUI.VStack className="gap-4">
      <MarketplaceSectionTitle>{marketplaceName} Sales</MarketplaceSectionTitle>
      {isPending ? (
        <WebUI.VStack className="mt-4 gap-4">
          {Array.from({length: 6}).map((_, idx) => (
            <WebUI.Skeleton key={idx} height={80} />
          ))}
        </WebUI.VStack>
      ) : activeSales.length > 0 ? (
        <>
          <WebUI.VStack className="gap-4">
            {activeSales.map((organizer) => (
              <MarketplaceSalesItem
                key={organizer.id}
                organizer={organizer as any}
              />
            ))}
          </WebUI.VStack>
          {hasNextPage && (
            <Waypoint onEnter={() => fetchNextPage()}>
              {isFetchingNextPage && (
                <div className="flex w-full justify-center py-8">
                  <WebUI.Loader size="3em" />
                </div>
              )}
            </Waypoint>
          )}
        </>
      ) : (
        <MarketplaceItemNoResultsFoundView
          onClearSearch={() => navigate('/marketplace/sales')}
        />
      )}
    </WebUI.VStack>
  )
}

export default MarketplaceSalesContainer
