import {Stripe, StripeElements} from '@stripe/stripe-js'
import {useElements, useStripe} from '@stripe/react-stripe-js'
import React, {useImperativeHandle, useMemo} from 'react'

import {Elements} from './Stripe'

export interface WithStripeInstance extends WithStripeImplInstance {}

export interface WithStripeProps extends WithStripeImplProps {
  clientSecret: string | undefined
}

export const WithStripe = React.forwardRef<WithStripeInstance, WithStripeProps>(
  ({clientSecret, children, ...restProps}, forwardedRef) => {
    if (!clientSecret) {
      return typeof children === 'function'
        ? children({stripe: null, elements: null})
        : children
    }

    return (
      <Elements options={{clientSecret}}>
        <WithStripeImpl ref={forwardedRef} {...restProps}>
          {children}
        </WithStripeImpl>
      </Elements>
    )
  },
)

interface WithStripeImplInstance {
  stripe: Stripe | null
  elements: StripeElements | null
}

interface WithStripeImplProps {
  children:
    | React.ReactElement
    | ((instance: WithStripeImplInstance) => React.ReactElement)
}

const WithStripeImpl = React.forwardRef<
  WithStripeImplInstance,
  WithStripeImplProps
>(({children}, forwardedRef) => {
  const stripe = useStripe()
  const elements = useElements()

  const instance = useMemo(
    () => ({
      stripe,
      elements,
    }),
    [elements, stripe],
  )

  useImperativeHandle(forwardedRef, () => instance, [instance])

  return typeof children === 'function' ? children(instance) : children
})
