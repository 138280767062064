import React, {useEffect} from 'react'
import {StringParam, useQueryParam} from 'use-query-params'
import {Navigate} from 'react-router-dom'
import {useReportDeviceMutation} from '@cheddarup/api-client'
import * as WebUI from '@cheddarup/web-ui'
import {LinkButton} from 'src/components/LinkButton'
import {Logo} from 'src/components'
import config from 'src/config'

const ReportDevicePage: React.FC = () => {
  const [fingerprint] = useQueryParam('fingerprint', StringParam)
  const [deviceIp] = useQueryParam('deviceIp', StringParam)
  const [deviceInformation] = useQueryParam('deviceInformation', StringParam)

  const reportDeviceMutation = useReportDeviceMutation()
  const reportDeviceAsync = reportDeviceMutation.mutateAsync

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    ;(async () => {
      if (fingerprint && deviceIp && deviceInformation) {
        try {
          await reportDeviceAsync({
            body: {
              fingerprint,
              deviceIp,
              deviceInformation,
            },
          })
        } catch (err) {
          console.error(err)
        }
      }
    })()
  }, [fingerprint, deviceIp, deviceInformation])

  if (!fingerprint || !deviceIp || !deviceInformation) {
    return <Navigate to="/" />
  }

  return (
    <WebUI.VStack className="grow">
      <WebUI.HStack className="min-h-17 items-center bg-natural-100 px-3 shadow-[0_1px_3px_#0000000a]">
        <LinkButton to="/">
          <Logo />
        </LinkButton>
      </WebUI.HStack>
      <WebUI.VStack className="grow items-center justify-center gap-6">
        {reportDeviceMutation.isPending ? (
          <WebUI.Loader />
        ) : (
          <>
            <WebUI.Heading className="text-center font-accentAlt text-h-6">
              Your device has been reported
            </WebUI.Heading>
            <WebUI.Text className="max-w-[715px] text-center">
              Thank you for reporting a suspicious device. Your concern is
              important to us and helps ensure a safe environment for everyone.
              If you have any further information or details to add to your
              report, please feel free to contact our support team at{' '}
              <WebUI.Anchor
                className="font-light"
                href={`mailto:${config.supportEmail}`}
              >
                Cheddar Up Support
              </WebUI.Anchor>
              .
            </WebUI.Text>
            <LinkButton
              size="large"
              variant="primary"
              to="https://www.cheddarup.com"
            >
              Return to Homepage
            </LinkButton>
          </>
        )}
      </WebUI.VStack>
    </WebUI.VStack>
  )
}

export default ReportDevicePage
