import {
  ConditionalExcept,
  EmptyObject,
  ExtractParams,
  HTTPMethod,
  HasRequiredKeys,
  SetOptional,
  getPathTokens,
  mapValues,
  pick,
  pickBy,
  z,
} from '@cheddarup/util'

type Headers = Record<
  string,
  string | string[] | number | boolean | null | undefined
>

export interface Endpoint<
  TPath,
  TResponseSchema extends z.ZodTypeAny,
  TBodySchema extends z.ZodTypeAny,
  TQueryParamsSchema extends z.ZodTypeAny,
  TIsMutation extends boolean,
> {
  path: TPath
  method: HTTPMethod
  responseSchema: TResponseSchema
  bodySchema: TBodySchema
  queryParamsSchema: TQueryParamsSchema
  isMutation: TIsMutation
}

export type AnyEndpoint = Endpoint<
  string,
  z.ZodTypeAny,
  z.ZodTypeAny,
  z.ZodTypeAny,
  boolean
>

export function makeEndpoint<
  TPath extends string,
  TMethod extends HTTPMethod,
  TResponseSchema extends z.ZodTypeAny,
  TBodySchema extends z.ZodTypeAny,
  TQueryParamsSchema extends z.ZodTypeAny,
  TIsMutation extends boolean = TMethod extends 'GET' ? false : true,
>({
  path,
  method = 'GET' as any,
  bodySchema = z.void() as any,
  queryParamsSchema = z.record(z.any()).optional() as any,
  responseSchema,
  isMutation = (method !== 'GET') as any,
}: {
  path: TPath
  method?: TMethod
  bodySchema?: TBodySchema
  queryParamsSchema?: TQueryParamsSchema
  responseSchema: TResponseSchema
  isMutation?: TIsMutation
}): Endpoint<
  TPath,
  TResponseSchema,
  TBodySchema,
  TQueryParamsSchema,
  TIsMutation
> {
  return {
    path,
    method,
    bodySchema,
    queryParamsSchema,
    responseSchema,
    isMutation,
  }
}

// MARK: – getEndpointKey

export type EndpointKey<TEndpoint extends AnyEndpoint = AnyEndpoint> =
  readonly [
    TEndpoint['path'],
    ConditionalExcept<GetEndpointKeyInput<TEndpoint>, null | undefined>,
  ]

interface _GetEndpointKeyInput<TEndpoint extends AnyEndpoint> {
  pathParams: ExtractParams<TEndpoint['path']>
  queryParams?: z.infer<TEndpoint['queryParamsSchema']>
  body?: z.infer<TEndpoint['bodySchema']>
  headers?: Headers
}

export type GetEndpointKeyInput<TEndpoint extends AnyEndpoint> = ExtractParams<
  TEndpoint['path']
> extends EmptyObject
  ? SetOptional<_GetEndpointKeyInput<TEndpoint>, 'pathParams'>
  : _GetEndpointKeyInput<TEndpoint>

// TODO: Cover with tests stage 0 @Binur
export function getEndpointKey<
  TEndpoint extends AnyEndpoint,
  TInput extends GetEndpointKeyInput<TEndpoint>,
>(
  endpoint: TEndpoint,
  ...[input]: HasRequiredKeys<TInput> extends true ? [TInput] : [TInput?]
): EndpointKey<TEndpoint> {
  const pathTokens = getPathTokens(endpoint.path)

  const pathParams = input?.pathParams
    ? mapValues(pick(input.pathParams, pathTokens as any[]), String)
    : input?.pathParams

  const parsedBody = endpoint.bodySchema.safeParse(input?.body)
  const parsedQueryParams = endpoint.queryParamsSchema.safeParse(
    input?.queryParams,
  )

  return [
    endpoint.path,
    pickBy(
      {
        ...input,
        headers: {
          ...input?.headers,
        },
        pathParams,
        body: parsedBody.success ? parsedBody.data : input?.body,
        queryParams: parsedQueryParams.success
          ? parsedQueryParams.data
          : undefined,
      },
      (v) => v != null,
    ) as any,
  ]
}

// MARK: – fetchEndpoint

interface _FetchInput<TEndpoint extends AnyEndpoint> {
  pathParams: ExtractParams<TEndpoint['path']>
  queryParams?: z.infer<TEndpoint['queryParamsSchema']>
  body?: z.infer<TEndpoint['bodySchema']>
  signal?: AbortSignal
  headers?: Headers
}

export type FetchInput<TEndpoint extends AnyEndpoint> = ExtractParams<
  TEndpoint['path']
> extends EmptyObject
  ? SetOptional<_FetchInput<TEndpoint>, 'pathParams'>
  : _FetchInput<TEndpoint>

export async function fetchEndpoint<TEndpoint extends AnyEndpoint>(
  endpoint: TEndpoint,
  ...[fetchOptions]: HasRequiredKeys<FetchInput<TEndpoint>> extends true
    ? [FetchInput<TEndpoint>]
    : [FetchInput<TEndpoint>?]
) {
  const parsedQueryParams = endpoint.queryParamsSchema.safeParse(
    fetchOptions?.queryParams,
  )
  if (!parsedQueryParams.success) {
    console.debug(
      'Parse query params error:',
      `path: ${endpoint.path}`,
      `err: ${parsedQueryParams.error}`,
      `queryParams: ${fetchOptions?.queryParams}`,
    )
  }

  const parsedBody = endpoint.bodySchema.safeParse(fetchOptions?.body)
  if (!parsedBody.success) {
    console.debug(
      'Parse body error:',
      `path: ${endpoint.path}`,
      `err: ${parsedBody.error}`,
      `body: ${fetchOptions?.body}`,
    )
  }

  const data = await globalThis.fetchApi(endpoint.path, {
    ...fetchOptions,
    method: endpoint.method,
    queryParams: fetchOptions?.queryParams,
    body: fetchOptions?.body,
  })

  const parsedRes = endpoint.responseSchema.safeParse(data)
  if (!parsedRes.success) {
    console.debug(
      'Parse response error:',
      `path: ${endpoint.path}`,
      `err: ${parsedRes.error}`,
      `data: ${data}`,
    )
  }

  return data as z.infer<typeof endpoint.responseSchema>
}
