import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'

import useCart, {useEnhancedUpdateCartMutation} from '../hooks/useCart'
import usePublicCollection from '../hooks/usePublicCollection'
import type {CheckoutFormik} from './CheckoutPage'
import {useActiveCheckoutStep} from './CheckoutPageFlowContext'

export interface CoverFeePanelProps
  extends React.ComponentPropsWithoutRef<'div'> {
  formik: CheckoutFormik
}

export const CoverFeePanel: React.FC<CoverFeePanelProps> = ({
  formik,
  ...restProps
}) => {
  const {publicCollection} = usePublicCollection()
  const [updateCartMutation, updateCartAsync] = useEnhancedUpdateCartMutation()
  const {cart} = useCart()
  const activeStep = useActiveCheckoutStep()

  const paymentMethod =
    !cart || cart.total === 0 ? null : formik.values.paymentMethod.method

  if (activeStep !== 'payment') {
    return null
  }

  return paymentMethod !== 'cash' &&
    publicCollection.processing_preference === 'user' &&
    publicCollection.payerCanCoverFees &&
    (publicCollection.coverOnlyEcheckFees === false ||
      (publicCollection.coverOnlyEcheckFees === true &&
        paymentMethod === 'echeck')) ? (
    <WebUI.Panel
      className="CoverFeePanel px-8 py-6"
      variant="capsule"
      {...restProps}
    >
      <WebUI.VStack className="gap-2">
        <WebUI.Checkbox
          className="items-center"
          size="compact"
          name="payerChoseToCoverFees"
          disabled={updateCartMutation.isPending}
          checked={!!cart?.payerChoseToCoverFees}
          onChange={(event) => {
            if (cart?.payerChoseToCoverFees !== event.target.checked) {
              updateCartAsync({
                body: {
                  payerChoseToCoverFees: event.target.checked,
                },
              })
            }
          }}
        >
          Cover convenience fees
        </WebUI.Checkbox>
        <WebUI.Text className="font-light text-ds-sm">
          {publicCollection.payerCanCoverFeesCustomMessage
            ? Util.stripMarkdown(
                publicCollection.payerCanCoverFeesCustomMessage,
              ).replaceAll('\n', '').length > 0
              ? publicCollection.payerCanCoverFeesCustomMessage
              : ''
            : 'This will add a small amount to your total to cover convenience fees for the organizer.'}
        </WebUI.Text>
      </WebUI.VStack>
    </WebUI.Panel>
  ) : null
}
