import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'
import {NumberParam, StringParam, useQueryParam} from 'use-query-params'
import React, {useMemo} from 'react'
import {api, useResendSingleMessageMutation} from '@cheddarup/api-client'
import {SearchForm} from 'src/components'
import {formatEmailStatus} from 'src/helpers/email-status-helpers'

export interface DeliveryReportViewProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collectionId: number | null
}

export const DeliveryReportView = ({
  collectionId,
  className,
  ...restProps
}: DeliveryReportViewProps) => {
  const [, setView] = useQueryParam('view', StringParam)
  const [messageId] = useQueryParam('messageId', NumberParam)
  const messageQuery = api.messages.detail.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      messageId: messageId!,
    },
    queryParams: {
      tab_id: collectionId,
    },
  })

  if (!messageQuery.data) {
    return null
  }

  return (
    <WebUI.VStack
      className={WebUI.cn('gap-4 p-6', className)}
      as={WebUI.Panel}
      {...restProps}
    >
      <WebUI.Button
        className="[&_>_.Button-iconBefore]:!mr-0 text-ds-sm"
        variant="link"
        iconBefore={<WebUI.PhosphorIcon icon="caret-left" />}
        onClick={() => setView(undefined)}
      >
        Back to Message History
      </WebUI.Button>

      <WebUI.Heading as="h3">Delivery Report</WebUI.Heading>

      <WebUI.VStack className="text-ds-sm">
        <span>
          <span className="font-medium">Collection:</span>{' '}
          {messageQuery.data.tab?.name ?? 'Group Page'}
        </span>
        <span>
          <span className="font-medium">Subject:</span>{' '}
          {messageQuery.data.detail.subject}
        </span>
        {!!messageQuery.data.send_at && (
          <span>
            <span className="font-medium">Sent:</span>{' '}
            {Util.formatDate(
              new Date(messageQuery.data.send_at),
              'MM/dd/yy, hh:mm a',
            )}
          </span>
        )}
      </WebUI.VStack>

      <DeliveryReportTableView collectionId={collectionId} />
    </WebUI.VStack>
  )
}

// MARK: - DeliveryReportTableView

interface DeliveryReportTableViewProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collectionId: number | null
}

const DeliveryReportTableView: React.FC<DeliveryReportTableViewProps> = ({
  collectionId,
  className,
  ...restProps
}: DeliveryReportTableViewProps) => {
  const [messageId] = useQueryParam('messageId', NumberParam)
  const messageQuery = api.messages.detail.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      messageId: messageId!,
    },
    queryParams: {
      tab_id: collectionId,
    },
  })
  const messageHistoryQuery = api.messages.deliveryHistory.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        messageId: messageId!,
      },
      queryParams: {
        tab_id: collectionId,
      },
    },
    {
      enabled: !!messageId,
    },
  )

  const columnHelper = useMemo(
    () => WebUI.createColumnHelper<Api.DeliveryRecipient>(),
    [],
  )

  const columns = useMemo(
    () => [
      columnHelper.accessor((dr) => dr.name, {
        id: 'name',
        header: 'Recipient(s)',
      }),
      columnHelper.accessor((dr) => dr.email, {
        id: 'email',
        header: 'Email',
      }),
      columnHelper.accessor((dr) => formatEmailStatus(dr.delivery_status), {
        id: 'activity',
        maxSize: 200,
        header: 'Activity',
      }),
      columnHelper.accessor(
        (dr) =>
          dr.payment_count > 0 && dr.payment_total > 0
            ? 'Paid'
            : dr.payment_count > 0 && dr.payment_total === 0
              ? 'Submitted'
              : null,
        {
          id: 'status',
          maxSize: 200,
          header: 'Status',
        },
      ),
      columnHelper.display({
        id: 'resend',
        maxSize: 50,
        meta: {
          align: 'right',
        },
        cell: ({row: {original}}) => (
          <ResendMessageAlert
            collectionId={collectionId}
            disclosure={
              <WebUI.DialogDisclosure className="text-ds-sm" variant="link">
                Resend
              </WebUI.DialogDisclosure>
            }
            deliveryRecipient={original}
          />
        ),
      }),
    ],
    [collectionId, columnHelper],
  )

  return (
    <WebUI.VStack className={WebUI.cn('gap-3', className)} {...restProps}>
      {!!messageHistoryQuery.data && messageHistoryQuery.data.length > 0 && (
        <WebUI.TableView
          className="[&_.TableViewCell]:text-ds-sm"
          sortable
          sortByTogglesVisible
          disableSortRemove
          loading={messageHistoryQuery.isPending}
          data={messageHistoryQuery.data ?? []}
          columns={columns}
          state={{
            columnVisibility: {
              status: !messageQuery.data?.tab_id,
            },
          }}
        >
          {(table) => (
            <WebUI.HStack className="-order-1 items-center gap-3">
              <SearchForm
                containerClassName="basis-[260px] [&_>_.Form]:w-full"
                className="min-h-9"
                size="compact"
                placeholder="Search by recipient name or email"
                onTermChange={table.setGlobalFilter}
              />
            </WebUI.HStack>
          )}
        </WebUI.TableView>
      )}
      {messageHistoryQuery.data?.length === 0 && (
        <p className="px-[2.25rem] py-[0.5rem] text-center font-normal text-ds-sm text-gray800">
          No results found
        </p>
      )}
    </WebUI.VStack>
  )
}

// MARK: – ResendMessageAlert

interface ResendMessageAlertProps extends WebUI.AlertProps {
  collectionId?: number | null
  deliveryRecipient: Api.DeliveryRecipient
}

const ResendMessageAlert = ({
  collectionId,
  deliveryRecipient,
  ...restProps
}: ResendMessageAlertProps) => {
  const resendSingleMessageMutation = useResendSingleMessageMutation()
  const growlActions = WebUI.useGrowlActions()
  return (
    <WebUI.Alert aria-label="Resend Message" {...restProps}>
      <WebUI.AlertHeader>Resend Message</WebUI.AlertHeader>
      <WebUI.AlertContentView
        text={`Resend this message to ${deliveryRecipient.email}`}
        actions={
          <>
            <WebUI.AlertActionButton
              execute={async () => {
                if (collectionId == null) {
                  return
                }

                try {
                  await resendSingleMessageMutation.mutateAsync({
                    pathParams: {
                      messageId: deliveryRecipient.message_id,
                    },
                    body: {
                      sent_email_id: deliveryRecipient.sent_mail_id,
                    },
                  })
                  growlActions.show('success', {
                    title: 'Success!',
                    body: 'Email re-sent successfully.',
                  })
                } catch {
                  growlActions.show('error', {
                    body: 'Something went wrong when re-sending your messages.',
                    title: 'Oops!',
                  })
                }
              }}
            >
              Send Now
            </WebUI.AlertActionButton>
            <WebUI.AlertCancelButton />
          </>
        }
      />
    </WebUI.Alert>
  )
}
