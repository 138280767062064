import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {tabObjectFieldSchema} from '../schemas'

export default {
  itemList: makeEndpoint({
    path: 'users/tabs/:tabId/items/:itemId/fields',
    responseSchema: tabObjectFieldSchema.array(),
  }),
  formList: makeEndpoint({
    path: 'users/tabs/:tabId/forms/:formId/fields',
    responseSchema: tabObjectFieldSchema.array(),
  }),
  createItemField: makeEndpoint({
    path: 'users/tabs/:tabId/items/:itemId/fields',
    method: 'POST',
    bodySchema: tabObjectFieldSchema.partial(),
    responseSchema: tabObjectFieldSchema,
  }),
  updateItemField: makeEndpoint({
    path: 'users/tabs/:tabId/items/:itemId/fields/:fieldId',
    method: 'PATCH',
    bodySchema: tabObjectFieldSchema.partial(),
    responseSchema: tabObjectFieldSchema,
  }),
  deleteItemField: makeEndpoint({
    path: 'users/tabs/:tabId/items/:itemId/fields/:fieldId',
    method: 'DELETE',
    responseSchema: z.any(),
  }),
  createFormField: makeEndpoint({
    path: 'users/tabs/:tabId/forms/:formId/fields',
    method: 'POST',
    bodySchema: tabObjectFieldSchema.partial(),
    responseSchema: tabObjectFieldSchema,
  }),
  updateFormField: makeEndpoint({
    path: 'users/tabs/:tabId/forms/:formId/fields/:fieldId',
    method: 'PATCH',
    bodySchema: tabObjectFieldSchema.partial(),
    responseSchema: tabObjectFieldSchema,
  }),
  deleteFormField: makeEndpoint({
    path: 'users/tabs/:tabId/forms/:formId/fields/:fieldId',
    method: 'DELETE',
    responseSchema: z.any(),
  }),
  createValue: makeEndpoint({
    path: 'users/tabs/:tabId/payments/:paymentId/item_field_values',
    method: 'POST',
    bodySchema: z.object({
      value: z.string().nullable(),
      payment_id: z.number(),
      payment_item_id: z.number(),
      tab_object_id: z.number(),
      item_field_id: z.number(),
    }),
    responseSchema: z.any(),
  }),
  updateValue: makeEndpoint({
    path: 'users/tabs/:tabId/payments/:paymentId/item_field_values/:fieldValueId',
    method: 'PATCH',
    bodySchema: z.object({
      item_field_value: z.object({
        value: z.string().nullable(),
        notifyPayer: z.boolean().optional(),
      }),
    }),
    responseSchema: z.any(),
  }),
}
