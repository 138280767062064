// Based on https://github.com/js-cookie/js-cookie/blob/master/src/api.mjs#L51
export const getCookie = (key: string) => {
  // To prevent the for loop in the first place assign an empty array
  // in case there are no cookies at all.
  const cookies = document.cookie?.split('; ') ?? []
  const jar: Record<string, string> = {}
  for (const cooky of cookies) {
    const parts = cooky.split('=')
    let value = parts.slice(1).join('=')

    if (value[0] === '"') {
      value = value.slice(1, -1)
    }

    try {
      if (parts[0]) {
        const foundKey = decodeCookieValue(parts[0])
        jar[foundKey] = decodeCookieValue(value)

        if (key === foundKey) {
          break
        }
      }
    } catch {
      // noop
    }
  }

  return jar[key]
}

const decodeCookieValue = (value: string) =>
  value.replace(/(%[\da-f]{2})+/gi, decodeURIComponent)
