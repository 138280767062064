import React from 'react'
import {getStringFromChildren} from '@cheddarup/react-util'

import {Text, TextProps} from './Text'
import {cn} from '../../utils'

export interface EllipsisProps extends TextProps {}

export const Ellipsis = React.forwardRef<HTMLSpanElement, EllipsisProps>(
  ({className, children, ...restProps}, forwardedRef) => (
    <Text
      ref={forwardedRef}
      className={cn(
        'max-w-full overflow-hidden text-ellipsis whitespace-nowrap',
        className,
      )}
      title={getStringFromChildren(children)}
      {...restProps}
    >
      {children}
    </Text>
  ),
)
