import {endpoints} from '../../endpoints'
import {makeQueryUpdate, makeUseMutation} from '../use-mutation'

export const useUpdateBrandingMutation = makeUseMutation(
  endpoints.userBrandings.update,
  () => ({
    regular: (newBranding) => [
      makeQueryUpdate(endpoints.userBrandings.detail, () => newBranding),
      makeQueryUpdate(endpoints.groupPages.detail, (prevGroupPage) =>
        prevGroupPage
          ? {
              ...prevGroupPage,
              branding: newBranding,
            }
          : prevGroupPage,
      ),
    ],
  }),
)

export const useDeleteBrandingLogoMutation = makeUseMutation(
  endpoints.userBrandings.deleteLogo,
  () => ({
    optimistic: [
      makeQueryUpdate(endpoints.userBrandings.detail, (prevBrand) => {
        if (prevBrand?.logo) {
          return {
            ...prevBrand,
            logo: undefined,
          }
        }
        return prevBrand
      }),
    ],
  }),
)
