import {formatDuration, formatISODuration} from 'date-fns'
import {
  toMilliseconds as durationToMilliseconds,
  normalize as normalizeDuration,
  parse as parseDuration,
} from 'duration-fns'

export {
  formatISODuration,
  durationToMilliseconds,
  formatDuration,
  normalizeDuration,
  parseDuration,
}

// TODO: get rid of this
export const parseDurationWeird = (candidate: string) => {
  const isFloat = (number: number) => Math.floor(number) !== number

  const dur = parseDuration(candidate)

  return {
    asWeeks:
      !isFloat(dur.weeks) &&
      !candidate.includes('M') &&
      !candidate.includes('Y')
        ? dur.weeks
        : undefined,
    asMonths: isFloat(dur.months) ? undefined : dur.months,
    asYears: isFloat(dur.years) ? undefined : dur.years,
  }
}
