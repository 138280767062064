import * as Yup from 'yup'
import {useFormik, useOnVarChange} from '@cheddarup/react-util'
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api, useStartCodeVerificationMutation} from '@cheddarup/api-client'
import {readApiError} from 'src/helpers/error-formatting'
import {TwoFactorAuthVerificationModal} from './TwoFactorAuthVerificationModal'

export interface InquireVerificationCodeInstance {
  verifyPhone: (options?: {
    lastTwoNumbers?: string
  }) => Promise<PhoneVerificationAlertValues>
}

export interface InquireVerificationCodeProps {
  ignorePhoneNumberExistence?: boolean
  children?:
    | React.ReactNode
    | ((instance: InquireVerificationCodeInstance) => React.ReactNode)
}

export const InquireVerificationCode = React.forwardRef<
  InquireVerificationCodeInstance,
  InquireVerificationCodeProps
>(({ignorePhoneNumberExistence, children}, forwardedRef) => {
  const isPhoneVerifiedQuery = api.auth.session.useQuery(undefined, {
    select: (session) => session.user.profile.phone.verified,
  })
  const [phoneVerificationAlertVisible, setPhoneVerificationAlertVisible] =
    useState(false)
  const [lastTwoNumbers, setLastTwoNumbers] = useState<string | null>(null)
  const verifyPhoneResolveRef = useRef<
    ((values: PhoneVerificationAlertValues) => void) | null
  >(null)
  const inquireTwoFaRef = useRef<(() => void) | null>(null)
  const twoFactorAuthVerificationModalRef = useRef<WebUI.DialogInstance>(null)
  const verifyPhoneRejectRef = useRef<((reason?: any) => void) | null>(null)

  const helpers: InquireVerificationCodeInstance = useMemo(
    () => ({
      verifyPhone: (options) =>
        new Promise((resolve, reject) => {
          function inquireTwoFa() {
            setPhoneVerificationAlertVisible(true)
            if (options?.lastTwoNumbers != null) {
              setLastTwoNumbers(options?.lastTwoNumbers)
            }
          }

          if (ignorePhoneNumberExistence || isPhoneVerifiedQuery.data) {
            inquireTwoFa()
          } else {
            inquireTwoFaRef.current = inquireTwoFa
            twoFactorAuthVerificationModalRef.current?.show()
          }

          verifyPhoneResolveRef.current = resolve
          verifyPhoneRejectRef.current = reject
        }),
    }),
    [isPhoneVerifiedQuery.data, ignorePhoneNumberExistence],
  )

  useImperativeHandle(forwardedRef, () => helpers, [helpers])

  return (
    <>
      {typeof children === 'function' ? children(helpers) : children}
      <PhoneVerificationAlert
        visible={phoneVerificationAlertVisible}
        lastTwoNumbers={lastTwoNumbers ?? undefined}
        onDidSubmit={(newValues) => {
          verifyPhoneResolveRef.current?.(newValues)
          setPhoneVerificationAlertVisible(false)
        }}
        onDidHide={() => {
          verifyPhoneRejectRef.current?.(new Error('Verification cancelled'))
          setPhoneVerificationAlertVisible(false)
        }}
      />
      <TwoFactorAuthVerificationModal
        ref={twoFactorAuthVerificationModalRef}
        onDidComplete={() => inquireTwoFaRef.current?.()}
      />
    </>
  )
})

// MARK: – PhoneVerificationAlert

interface PhoneVerificationAlertValues {
  verificationCode: string
}

interface PhoneVerificationAlertProps extends WebUI.AlertProps {
  lastTwoNumbers?: string
  onDidSubmit: (values: PhoneVerificationAlertValues) => void
}

const PhoneVerificationAlert = ({
  lastTwoNumbers: lastTwoNumbersProp,
  onDidSubmit,
  visible,
  ...restProps
}: PhoneVerificationAlertProps) => {
  const [secondsLeftToResend, setSecondsLeftToResend] = useState(0)
  const {data: lastTwoNumbers} = api.auth.session.useQuery(undefined, {
    enabled: visible === true && !lastTwoNumbersProp,
    select: (session) => session.user.profile.phone?.lastTwoNumbers,
  })
  const startCodeVerificationMutation = useStartCodeVerificationMutation()
  const growlActions = WebUI.useGrowlActions()

  const requestVerificationCode = useCallback(async () => {
    try {
      await startCodeVerificationMutation.mutateAsync()
      growlActions.clear()
      growlActions.show('success', {
        title: 'Success',
        body: 'Verification code sent to your phone number',
      })
    } catch (err: any) {
      const errMsg =
        readApiError(err, {phone_not_verified: 'Phone number not verified'}) ??
        err.response?.data?.errors?.profile?.[0] ??
        err.response?.data?.err ??
        err.message
      if (err.response?.status !== 401) {
        growlActions.show('error', {
          title: 'Error',
          body: errMsg,
        })
      }
    }
  }, [growlActions, startCodeVerificationMutation])

  useOnVarChange(() => setSecondsLeftToResend(45), visible)

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (visible) {
      requestVerificationCode()
    }
  }, [visible])

  useEffect(() => {
    if (visible) {
      const timer = setInterval(() => {
        setSecondsLeftToResend((prev) => Math.max(prev - 1, 0))
      }, 1000)
      return () => clearInterval(timer)
    }

    return undefined
  }, [visible])

  const codeReadyToResend = secondsLeftToResend < 1

  const formik = useFormik<PhoneVerificationAlertValues>({
    validationSchema: Yup.object().shape({
      verificationCode: Yup.string().required('Required'),
    }),
    initialValues: {
      verificationCode: '',
    },
    onSubmit: (values, formikHelpers) => {
      onDidSubmit(values)
      formikHelpers.resetForm()
    },
  })

  return (
    <WebUI.Alert
      aria-label="Phone verification"
      visible={visible}
      {...restProps}
    >
      <WebUI.AlertHeader>
        Authentication Required For This Action
      </WebUI.AlertHeader>
      <WebUI.VStack className="gap-4 p-7">
        <WebUI.Text className="font-light text-gray800">
          Two-factor authentication helps you keep your account secure. Please
          confirm this action by entering the code sent to your mobile phone:
          (***) ***-**-
          {lastTwoNumbers || lastTwoNumbersProp}
        </WebUI.Text>

        <WebUI.Form onSubmit={formik.handleSubmit}>
          <WebUI.FormField
            label="Enter Code"
            error={formik.errors.verificationCode}
          >
            <WebUI.Input
              name="verificationCode"
              inputMode="numeric"
              autoComplete="one-time-code"
              value={formik.values.verificationCode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </WebUI.FormField>

          <WebUI.Button
            className="text-ds-sm"
            disabled={!codeReadyToResend}
            variant="link"
            onClick={() => {
              setSecondsLeftToResend(45)
              requestVerificationCode()
            }}
          >
            {codeReadyToResend
              ? 'Resend a new code'
              : `Seconds left to resend code: ${secondsLeftToResend}`}
          </WebUI.Button>

          <WebUI.HStack className="gap-3">
            <WebUI.Button
              type="submit"
              variant="primary"
              disabled={!formik.isValid}
            >
              Continue
            </WebUI.Button>
          </WebUI.HStack>
        </WebUI.Form>
      </WebUI.VStack>
    </WebUI.Alert>
  )
}
