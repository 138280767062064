import React from 'react'

import {usePayerNavigateToNextSegment} from '../utils/payer-navigation-utils'
import {PayerButton, PayerButtonProps} from './PayerStyled'

export const PayerContinueButton: React.FC<PayerButtonProps> = ({
  onClick,
  children = 'Continue',
  ...restProps
}) => {
  const navigateToNextSegment = usePayerNavigateToNextSegment()

  return (
    <PayerButton
      variant="themed-secondary"
      onClick={(event) => {
        onClick?.(event)

        if (!event.defaultPrevented) {
          navigateToNextSegment()
        }
      }}
      {...restProps}
    >
      {children}
    </PayerButton>
  )
}
