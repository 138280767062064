import {StringParam, useQueryParam, withDefault} from 'use-query-params'
import React from 'react'
import {useParams} from 'react-router-dom'
import Papa from 'papaparse'
import {getTimeSlotFieldViewsFormatted} from 'src/helpers/parseTimeSlotFieldViews'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'
import * as Util from '@cheddarup/util'
import {Link} from 'src/components/Link'
import {LinkButton} from 'src/components/LinkButton'

import SignupFieldViewsList from './SignupFieldViewsList'

const SignupSpecificPage = () => {
  const urlParams = useParams()
  const collectionId = Number(urlParams.collection)
  const signupId = Number(urlParams.signup)

  const selectedSignUpQuery = api.tabSignups.list.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: urlParams.collection!,
      },
    },
    {
      select: (signups) => signups.find((su) => su.id === signupId),
    },
  )

  const respondentsCount = Util.sumBy(
    selectedSignUpQuery.data?.spots ?? [],
    (sp) => sp.active_participant_count,
  )

  return (
    <WebUI.VStack className="gap-5">
      {selectedSignUpQuery.data && (
        <WebUI.Panel className="gap-4 py-6" as={WebUI.VStack}>
          <WebUI.VStack className="gap-2 px-6">
            <Link
              className="text-ds-sm"
              variant="primary"
              iconBefore={<WebUI.PhosphorIcon icon="arrow-left" />}
              to="../forms-signups"
            >
              Back to all forms and sign ups
            </Link>
            <WebUI.VStack className="gap-1">
              <WebUI.HStack className="items-center gap-3">
                <WebUI.PhosphorIcon icon="users-four" />
                <WebUI.Text>{selectedSignUpQuery.data.name}</WebUI.Text>
              </WebUI.HStack>
              <WebUI.VStack className="ml-7">
                <WebUI.Text className="text-ds-sm">
                  Total Filled Spots: {respondentsCount}
                </WebUI.Text>
              </WebUI.VStack>
            </WebUI.VStack>
          </WebUI.VStack>
          <SignupResponseDisplayOptions
            collectionId={collectionId}
            signup={selectedSignUpQuery.data}
          />
        </WebUI.Panel>
      )}
      <SignupFieldViewsList collectionId={collectionId} />
    </WebUI.VStack>
  )
}

// MARK: - SignupResponseDisplayOptions

interface SignupResponseDisplayOptionsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collectionId: number
  signup: Api.SignUp
}

const SignupResponseDisplayOptions: React.FC<
  SignupResponseDisplayOptionsProps
> = ({collectionId, signup, className, ...restProps}) => {
  const urlParams = useParams()
  const selectedSignUpId = Number(urlParams.signup)
  const [viewBy, setViewBy] = useQueryParam(
    'viewBy',
    withDefault(StringParam, 'spot'),
  )
  const {data: rows} = api.tabPayments.signupPayments.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: urlParams.collection!,
        signupId: selectedSignUpId,
      },
    },
    {
      select: (payments) =>
        payments.flatMap((p) =>
          p.payment_items
            .filter(
              (pi): pi is Util.SetRequired<typeof pi, 'time_slot'> =>
                pi.time_slot != null &&
                pi.time_slot.spot.signup.id === selectedSignUpId,
            )
            .map((pi) => {
              const fieldViewsFormatted = getTimeSlotFieldViewsFormatted(
                pi.item_field_views,
              )

              return {
                signupName: pi.time_slot.spot.signup.name,
                date: Util.formatDateAs(p.created_at),
                name: p.tab_member.name,
                email: p.tab_member.email,
                spotDate: `${Util.formatDate(
                  pi.time_slot.options.startTime ?? '',
                  'EEE, MMM d, yy',
                )}`,
                spotTime: `${Util.formatDate(
                  pi.time_slot.options.startTime ?? '',
                  'h:mm aa',
                )} -  ${Util.formatDate(
                  pi.time_slot.options.endTime ?? '',
                  'h:mm aa',
                )}`,
                spotTitle: pi.time_slot.spot.name,
                spotFirstName: fieldViewsFormatted.firstName,
                spotLastName: fieldViewsFormatted.lastName,
                spotComment: fieldViewsFormatted.comment,
                spotQuantityFilled: pi.quantity,
                spotQuantityTotalAvailable:
                  pi.time_slot.available_quantity ?? 'Unlimited',
              }
            }),
        ),
    },
  )

  return (
    <WebUI.VStack className={WebUI.cn('gap-4', className)} {...restProps}>
      <WebUI.Separator variant="primary" />
      <WebUI.VStack className="flex-[1] items-stretch justify-end gap-4 px-6 md:flex-row md:items-end md:justify-between">
        {signup.options.signupType === 'schedule' ? (
          <WebUI.FormField className="max-w-[240px] grow">
            <WebUI.DropdownSelect<string | null>
              size="compact"
              placeholder="Select View"
              value={viewBy}
              onValueChange={(newViewBy) => setViewBy(newViewBy)}
            >
              <WebUI.DropdownSelectOption value="date">
                View by Date
              </WebUI.DropdownSelectOption>
              <WebUI.DropdownSelectOption value="spot">
                View by Spot
              </WebUI.DropdownSelectOption>
            </WebUI.DropdownSelect>
          </WebUI.FormField>
        ) : (
          <div />
        )}
        {viewBy != null && (
          <WebUI.VStack className="gap-4 sm:flex-row">
            <WebUI.DeprecatedTooltip label="CSV file of all responses">
              <WebUI.Button
                variant="secondary"
                iconAfter={
                  <WebUI.PhosphorIcon icon="download-simple" width={20} />
                }
                onClick={() => {
                  const csv = Papa.unparse({
                    fields: [
                      'Signup Name',
                      'Date',
                      'Name',
                      'Email',
                      'Spot Date',
                      'Spot Time',
                      'Spot Title',
                      'Spot First Name',
                      'Spot Last Name',
                      'Spot Comment',
                      'Spot Qty Filled',
                      'Spot Qty Total Available',
                    ],
                    data:
                      rows?.map((row) => [
                        row.signupName,
                        row.date,
                        row.name,
                        row.email,
                        row.spotDate,
                        row.spotTime,
                        row.spotTitle,
                        row.spotFirstName,
                        row.spotLastName,
                        row.spotComment,
                        row.spotQuantityFilled,
                        row.spotQuantityTotalAvailable,
                      ]) ?? [],
                  })

                  WebUI.downloadFile(
                    new Blob([csv], {
                      type: 'data:text/csv;charset=utf-8',
                    }),
                    `${signup.name}.csv`,
                  )
                }}
              >
                Download Responses
              </WebUI.Button>
            </WebUI.DeprecatedTooltip>
            <WebUI.DeprecatedTooltip label="Download pdf to print">
              <LinkButton
                variant="secondary"
                iconAfter={<WebUI.PhosphorIcon icon="printer" width={20} />}
                target="_blank"
                to={`/pdf/collection/${collectionId}/signup/${selectedSignUpId}?viewBy=${viewBy}`}
              >
                Print Responses
              </LinkButton>
            </WebUI.DeprecatedTooltip>
          </WebUI.VStack>
        )}
      </WebUI.VStack>
    </WebUI.VStack>
  )
}

export default SignupSpecificPage
