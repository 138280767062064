import {z} from '@cheddarup/util'
import {inventoryGroupSchema} from '../schemas'
import {makeEndpoint} from '../utils'

export default {
  list: makeEndpoint({
    path: 'users/tabs/:tabId/inventory_groups',
    responseSchema: inventoryGroupSchema.array(),
  }),
  create: makeEndpoint({
    method: 'POST',
    path: 'users/tabs/:tabId/inventory_groups',
    bodySchema: z.object({
      tab_item_id: z.number().optional(),
      name: z.string().optional(),
      available_quantity: z.number(),
      inventory_item_ids: z.number().array(),
    }),
    responseSchema: inventoryGroupSchema,
  }),
  update: makeEndpoint({
    method: 'PATCH',
    path: 'users/tabs/:tabId/inventory_groups/:inventoryGroupId',
    bodySchema: z.object({
      tab_item_id: z.number().optional(),
      name: z.string().optional(),
      available_quantity: z.number(),
      inventory_item_ids: z.number().array(),
    }),
    responseSchema: inventoryGroupSchema,
  }),
  delete: makeEndpoint({
    method: 'DELETE',
    path: 'users/tabs/:tabId/inventory_groups/:inventoryGroupId',
    responseSchema: z.void(),
  }),
}
