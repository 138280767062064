import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'

export default {
  sendFeedback: makeEndpoint({
    path: 'contact',
    method: 'POST',
    bodySchema: z.object({
      name: z.string(),
      orgName: z.string(),
      email: z.string(),
      subject: z.string(),
      message: z.string(),
    }),
    responseSchema: z.any(),
  }),
  log: makeEndpoint({
    path: 'logaction',
    method: 'POST',
    bodySchema: z.object({
      url: z.string(),
      payload: z.any(),
      user: z
        .object({
          email: z.string().nullish(),
        })
        .optional(),
      level: z.enum(['WARNING', 'ERROR', 'INFO']),
    }),
    responseSchema: z.any(),
  }),
  batch: makeEndpoint({
    path: 'batch',
    method: 'PATCH',
    bodySchema: z.object({
      sequential: z.literal(true),
      ops: z
        .object({
          method: z.string(),
          url: z.string(),
          params: z.record(z.any()).optional(),
        })
        .array(),
    }),
    responseSchema: z.object({
      results: z
        .object({
          body: z.any(),
          headers: z.record(z.any()),
          status: z.number(),
        })
        .array(),
    }),
  }),
  makeZapToken: makeEndpoint({
    path: 'users/zap_token',
    method: 'POST',
    responseSchema: z.string(),
  }),
  reportDevice: makeEndpoint({
    path: 'report_device',
    method: 'POST',
    bodySchema: z.object({
      fingerprint: z.string(),
      deviceIp: z.string(),
      deviceInformation: z.string(),
    }),
    responseSchema: z.any(),
  }),
  contactUserBySlug: makeEndpoint({
    path: 'users/:userSlug/contact',
    method: 'POST',
    bodySchema: z.object({
      email: z.string(),
      name: z.string(),
      message: z.string(),
    }),
    responseSchema: z.any(),
  }),
  createStripeAccountLink: makeEndpoint({
    path: 'user/stripe_account_link',
    method: 'POST',
    bodySchema: z.void(),
    responseSchema: z.object({url: z.string()}),
  }),
}
