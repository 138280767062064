import * as Ariakit from '@ariakit/react'
import React, {useState} from 'react'

import {cn} from '../../utils'
import {useUpdateEffect} from '@cheddarup/react-util'

export interface ImageProps extends Ariakit.RoleProps<'img'> {
  errorFallback?: React.ReactNode
}

export const Image = React.forwardRef<HTMLImageElement, ImageProps>(
  (
    {errorFallback, className, src, onLoad, onError, ...restProps},
    forwardedRef,
  ) => {
    const [isLoading, setIsLoading] = useState(true)
    const [isErrored, setIsErrored] = useState(false)

    useUpdateEffect(() => {
      setIsLoading(!!src)
      setIsErrored(false)
    }, [src])

    if (!src || isErrored) {
      return errorFallback
    }

    return (
      <Ariakit.Role.img
        ref={forwardedRef}
        data-loading={isLoading}
        className={cn(
          'object-cover [block-size:auto] [max-block-size:100%] [max-inline-size:100%]',
          'data-[loading=true]:animate-pulse data-[loading=true]:bg-grey-200 data-[loading=true]:[animation-delay:200ms]',
          className,
        )}
        loading="lazy"
        decoding="async"
        src={src}
        onLoad={(event) => {
          setIsLoading(false)

          onLoad?.(event)
        }}
        onError={(event) => {
          setIsErrored(true)

          onError?.(event)
        }}
        {...restProps}
      />
    )
  },
)
