const PAYMENT_METHOD_LABELS: Record<Api.PaymentMethodType, string> = {
  offline: 'Cash or Check',
  cash: 'Cash or Check',
  card: 'Credit Card',
  echeck: 'e-Check',
  paypal: 'PayPal',
  chained: 'Chained',
  refunded: 'Refunded',
}

export {PAYMENT_METHOD_LABELS}
