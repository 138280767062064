import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {
  itemTypeSchema,
  paymentItemSchema,
  redeemedMethodSchema,
  ticketStatusSchema,
} from '../schemas'

export default {
  update: makeEndpoint({
    path: 'users/tabs/:tabId/payments/:paymentId/payment_items/:paymentItemId',
    method: 'PATCH',
    bodySchema: z
      .object({
        organizerNote: z.string(),
        ticketStatus: ticketStatusSchema,
        redeemedMethod: redeemedMethodSchema,
        notify: z.boolean(),
      })
      .partial(),
    queryParamsSchema: z
      .object({
        itemType: itemTypeSchema,
        code: z.string(),
        collectorName: z.string(),
        collectorEmail: z.string(),
      })
      .partial()
      .optional(),
    responseSchema: paymentItemSchema.pick({
      id: true,
      payment_id: true,
      tab_object_id: true,
      created_at: true,
      amount: true,
      quantity: true,
      total: true,
      detail: true,
    }),
  }),
  revokeTickets: makeEndpoint({
    path: 'users/tabs/:tabId/payments/:paymentId/payment_items/revoke_tickets',
    method: 'POST',
    bodySchema: z.object({
      ids: z.number().array(),
    }),
    responseSchema: paymentItemSchema,
  }),
}
