import {
  NavLinkProps,
  UNSAFE_DataRouterStateContext,
  useLocation,
  useResolvedPath,
} from 'react-router-dom'
import {forwardRef, useContext, useEffect} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {ForwardRefComponent, useLiveRef} from '@cheddarup/react-util'

import {AbsoluteLink, UseLinkOptions, useLinkProps} from './Link'

export interface LinkButtonProps
  extends WebUI.AnchorButtonProps,
    UseLinkOptions {}

export const LinkButton = forwardRef(
  (
    {
      as = 'a',
      preserveSearch,
      relative,
      to,
      replace,
      state,
      target,
      preventScrollReset,
      className,
      onClick: onClickProp,
      ...restProps
    },
    forwardedRef,
  ) => {
    const {onClick, ...linkProps} = useLinkProps({
      preserveSearch,
      relative,
      to,
      replace,
      state,
      target,
      preventScrollReset,
    })

    return (
      <WebUI.AnchorButton
        ref={forwardedRef}
        className={WebUI.cn('LinkButton', className)}
        as={as}
        onClick={(event) => {
          onClickProp?.(event)

          if (!event.defaultPrevented) {
            onClick(event)
          }
        }}
        {...linkProps}
        {...restProps}
      />
    )
  },
) as ForwardRefComponent<'a', LinkButtonProps>

// MARK: – AbsoluteLinkButton

export const AbsoluteLinkButton = forwardRef<
  HTMLAnchorElement,
  LinkButtonProps & React.ComponentPropsWithoutRef<'a'>
>(({className, onClick, ...restProps}, forwardedRef) => (
  <WebUI.AnchorButton
    ref={forwardedRef}
    className={WebUI.cn('AbsoluteLinkButton', className)}
    as={AbsoluteLink}
    {...restProps}
  />
)) as ForwardRefComponent<'a', LinkButtonProps>

// MARK: – NavLinkButton

export interface NavLinkButtonProps
  extends LinkButtonProps,
    Omit<NavLinkProps, 'style' | 'children' | 'relative'>,
    React.ComponentPropsWithoutRef<'a'> {
  className?: string
  isActive?: () => boolean
  onActiveChange?: (isActive: boolean) => void
}

export const NavLinkButton = forwardRef(
  (
    {
      'aria-current': ariaCurrentProp = 'page',
      isActive: isActiveProp,
      onActiveChange,
      to,
      caseSensitive = false,
      end = false,
      className,
      ...restProps
    },
    forwardedRef,
  ) => {
    const location = useLocation()
    const path = useResolvedPath(to)
    const routerState = useContext(UNSAFE_DataRouterStateContext)
    const onActiveChangeRef = useLiveRef(onActiveChange)

    let locationPathname = location.pathname
    let toPathname = path.pathname
    let nextLocationPathname = routerState?.navigation?.location
      ? routerState.navigation.location.pathname
      : null

    if (!caseSensitive) {
      locationPathname = locationPathname.toLowerCase()
      nextLocationPathname = nextLocationPathname?.toLowerCase() ?? null
      toPathname = toPathname.toLowerCase()
    }

    const isActive =
      isActiveProp == null
        ? locationPathname === toPathname ||
          (!end &&
            locationPathname.startsWith(toPathname) &&
            locationPathname.charAt(toPathname.length) === '/')
        : isActiveProp()

    useEffect(() => {
      onActiveChangeRef.current?.(isActive)
    }, [isActive])

    const isPending =
      nextLocationPathname != null &&
      (nextLocationPathname === toPathname ||
        (!end &&
          nextLocationPathname.startsWith(toPathname) &&
          nextLocationPathname.charAt(toPathname.length) === '/'))

    const ariaCurrent = isActive ? ariaCurrentProp : undefined

    return (
      <LinkButton
        ref={forwardedRef}
        aria-current={ariaCurrent}
        className={WebUI.cn(
          'NavLinkButton',
          isActive && 'NavLinkButton-active',
          isPending && 'NavLinkButton-pending',
          className,
        )}
        to={to}
        {...restProps}
      />
    )
  },
) as ForwardRefComponent<'a', NavLinkButtonProps>
