import {
  AddressElement as StripeAddressElement,
  AddressElementProps as StripeAddressElementProps,
} from '@stripe/react-stripe-js'
import * as WebUI from '@cheddarup/web-ui'
import {useLiveRef, useUpdateEffect} from '@cheddarup/react-util'
import {useState} from 'react'
import config from 'src/config'

export interface AddressElementProps extends StripeAddressElementProps {
  onLoadingChange?: (isLoading: boolean) => void
}

export const AddressElement = ({
  options,
  onLoadingChange,
  onLoaderStart,
  onReady,
  ...restProps
}: AddressElementProps) => {
  const [isLoaderDisplayed, setIsLoaderDisplayed] = useState(false)
  const [isAddressElementReady, setIsAddressElementReady] = useState(false)
  const onLoadingChangeRef = useLiveRef(onLoadingChange)

  useUpdateEffect(() => {
    onLoadingChangeRef.current?.(!isAddressElementReady)
  }, [isAddressElementReady])

  return (
    <>
      <StripeAddressElement
        options={{
          ...options,
          autocomplete: options.autocomplete ?? {
            mode: 'google_maps_api',
            apiKey: config.googleMapsApiKey,
          },
        }}
        onLoaderStart={(event) => {
          onLoaderStart?.(event)
          setIsLoaderDisplayed(true)
        }}
        onReady={(event) => {
          onReady?.(event)
          setIsAddressElementReady(true)
        }}
        {...restProps}
      />
      {!isLoaderDisplayed && !isAddressElementReady && <WebUI.Loader />}
    </>
  )
}
