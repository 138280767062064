export const templateCategoryToDescriptionMap: Record<
  Api.TemplateCollectionCategory,
  string
> = {
  Fundraisers:
    'Elevate your next big cause with templates that make fundraising a walk in the park.',
  Events:
    'Our events and activities templates will get your party started -- minus the stress.',
  'Online Stores':
    'Move your merch like hotcakes with these online store templates.',
  'Dues & Fees':
    'Simplify collecting membership dues and other fees with these templates.',
  Registrations:
    'From fun runs to workshops, let these registration templates do the heavy lifting.',
  'Staff Picks': "We've seen them all and these are some of our favorites.",
  'Seasonal Collections':
    "From New Year's to Christmas, we've got templates for year-round fun.",
  'Group Gifts': 'Pooling resources for a group gift has never been easier.',
  'Camps & Classes':
    'Enrollments are a cinch with templates for your camps and classes.',
  'Group Travel':
    'Get on the road fast with templates that handle the details for your next group trip.',
  'Sign Ups':
    'From snack schedules to teacher conferences, create beautiful, clutter-free sign-ups.',
}

export const templateGroupTypeToDescriptionMap: Record<
  Api.TabTemplateGroupType,
  string
> = {
  'Schools & PTAs':
    'Easily manage fundraising, dues, events, and more with these templates for schools.',
  Scouts:
    "Simplify your troop's dues, events, and fundraising with these templates.",
  Sports: 'Get in the game faster with these templates for sports teams.',
  Nonprofits:
    'Jumpstart your fundraising with templates built to amplify your impact.',
  Clubs:
    'Manage club membership dues and events efficiently with our templates.',
  Reunions: 'Hassle-free reunion registrations, events, and t-shirt sales.',
  'Bands & Choir':
    'Hit the right note with these templates for concerts, trips, and fundraising.',
  Cheer: 'Flip over templates designed specifically for cheer teams.',
  'Fine Arts':
    'Showcase your talents with templates for performances and fundraising.',
  HOAs: 'Neighborhood governance is a walk in the park with templates for dues, events, and more.',
  Religious:
    'Simplify tithes, event registrations, and community outreach with these templates.',
  'Small Business':
    'Run your business with templates designed for small business owners.',
  'Greek Life':
    'Templates for sorority and fraternity formals, fundraising, and more.',
  'Friends & Family':
    'From group gifts to family reunions, templates make pooling resources simple.',
  Workplace:
    'Elevate office camaraderie with easy templates for social events and group gifts.',
}
