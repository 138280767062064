import {useLocation} from 'react-router-dom'
import * as Util from '@cheddarup/util'
import {useEffect} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api, useUpdateSubscriptionMutation} from '@cheddarup/api-client'
import {Link} from 'src/components/Link'
import {
  AccountSettingsContentCard,
  AccountSettingsContentLayout,
} from './components/AccountSettingsContentLayouts'

const PlanBillingPage = () => {
  const location = useLocation()
  const invoicesQuery = api.subscriptions.invoices.useQuery()

  useEffect(() => {
    const noModals =
      location.pathname.slice(-24) === '/my-account/plan-billing' ||
      location.pathname.slice(-25) === '/my-account/plan-billing/'

    if (noModals) {
      // workaround to refresh plan and billing history when modals are closed
      setTimeout(() => {
        invoicesQuery.refetch()
      }, 0)
    }
  }, [location.pathname, invoicesQuery.refetch])

  return (
    <AccountSettingsContentLayout className="gap-4" heading="Plan and Billing">
      <AccountSettingsContentCard className="gap-5 pb-8" heading="My Plan">
        {invoicesQuery.data ? (
          <>
            <PlanMethod plan={invoicesQuery.data.plan} />
            <PaymentMethodSelect
              paymentMethod={invoicesQuery.data.payment_method}
              plan={invoicesQuery.data.plan}
            />
          </>
        ) : (
          <>
            <WebUI.Skeleton width="100%" height={30} />
            <WebUI.Skeleton width="100%" height={30} />
          </>
        )}
      </AccountSettingsContentCard>
      <AccountSettingsContentCard heading="Invoices" className="gap-5 pb-8">
        {invoicesQuery.data ? (
          <>
            <BillingPaymentList
              billingHistory={invoicesQuery.data.billing_history}
            />
          </>
        ) : (
          <>
            <WebUI.Skeleton width="100%" height={30} />
            <WebUI.Skeleton width="100%" height={30} />
            <WebUI.Skeleton width="100%" height={30} />
          </>
        )}
      </AccountSettingsContentCard>
    </AccountSettingsContentLayout>
  )
}

// MARK: – PaymentMethodSelect

interface PaymentMethodSelectProps {
  paymentMethod?: Api.BankAccount | Api.CreditCard
  plan: Api.SubscriptionPlan
}

const PaymentMethodSelect = ({
  paymentMethod,
  plan,
}: PaymentMethodSelectProps) => {
  const paymentMethodsQuery = api.paymentMethods.list.useQuery()
  let {cards, banks} = paymentMethodsQuery.data ?? {cards: [], banks: []}
  const updateSubscriptionMutation = useUpdateSubscriptionMutation()
  const {data: userProfile} = api.auth.session.useQuery(undefined, {
    select: (session) => session.user.profile,
  })
  const subscriptionEcheckEnabled =
    userProfile?.features?.subscriptionEcheckEnabled
  if (!subscriptionEcheckEnabled) {
    banks = banks.filter((i) => i.id === paymentMethod?.id)
  }
  return plan.name === 'BASIC' ? null : (
    <WebUI.VStack className="gap-2">
      <WebUI.Text className="text-ds-sm">
        Subscription Payment Method
      </WebUI.Text>
      <WebUI.DropdownSelect<string>
        placeholder={
          cards.length === 0 && banks.length === 0
            ? 'No payment methods'
            : 'Select payment method'
        }
        value={paymentMethod?.id ?? ''}
        loading={
          paymentMethodsQuery.isPending || updateSubscriptionMutation.isPending
        }
        onValueChange={(cardId) => {
          if (cardId !== paymentMethod?.id && cardId) {
            updateSubscriptionMutation.mutate({
              body: {plan: plan.identifier, source_id: cardId},
            })
          }
        }}
      >
        {cards.map((c) => (
          <WebUI.DropdownSelectOption key={c.id} value={c.id}>
            {`${c.nickname ? `${c.nickname} ` : ''}${c.brand} ****${c.last4}`}
          </WebUI.DropdownSelectOption>
        ))}
        {banks.map((b) => (
          <WebUI.DropdownSelectOption key={b.id} value={b.id}>
            {`${b.nickname || b.account_holder_name || b.bank_name} *${
              b.last4
            }`}
          </WebUI.DropdownSelectOption>
        ))}
        <WebUI.DropdownSelectOption value="" as={Link} to="../payment-methods">
          Add new payment method
        </WebUI.DropdownSelectOption>
      </WebUI.DropdownSelect>
    </WebUI.VStack>
  )
}

// MARK: – PlanMethod

interface PlanMethodProps {
  plan: Api.SubscriptionPlan
}

const PlanMethod = ({plan}: PlanMethodProps) => {
  const planName = (
    (plan.name === 'BASIC' ? 'Basic (Free)' : plan.name) || ''
  ).toLowerCase()
  return (
    <WebUI.VStack className="gap-4 rounded-default bg-natural-80 px-4 py-2">
      <WebUI.HStack className="items-center justify-between gap-3">
        <WebUI.Text className="font-light capitalize">{planName}</WebUI.Text>
        <Link className="text-ds-sm" variant="primary" to="i/plans">
          Change
        </Link>
      </WebUI.HStack>
      {plan.name !== 'BASIC' &&
        plan.pending_changes &&
        plan.pending_changes.identifier !== 'no_change' && (
          <WebUI.Text className="mb-1 text-ds-sm">
            <>
              You are currently on the{' '}
              <span className="capitalize">{planName}</span> plan. You will
              change to the {plan.pending_changes.new_plan} plan on{' '}
              {Util.formatDateAs(plan.pending_changes.starting)}.
            </>
          </WebUI.Text>
        )}
    </WebUI.VStack>
  )
}

// MARK: – BillingPaymentList

interface BillingPaymentListProps {
  billingHistory?: Api.BillingHistoryItem[]
}

const BillingPaymentList = ({billingHistory = []}: BillingPaymentListProps) => {
  const payments = billingHistory.filter(
    ({charge}) => !!charge && charge.amount > 0,
  )

  return payments.length > 0 ? (
    <WebUI.VStack className="gap-5">
      {payments
        .map(({charge, created, hosted_invoice_url}) => ({
          amount: Util.formatAmount(
            charge ? (charge.amount - charge.amount_refunded) / 100 : 0,
          ),
          fullyRefunded: charge?.amount === charge?.amount_refunded && charge,
          chargeStatus: charge?.status,
          date: Util.formatDateAs(new Date((created || 0) * 1000)),
          hostedInvoiceUrl: hosted_invoice_url,
        }))
        .map(
          (
            {amount, date, hostedInvoiceUrl, chargeStatus, fullyRefunded},
            idx,
          ) => (
            <WebUI.HStack
              key={idx}
              className={
                'items-center gap-1 rounded-default border px-4 py-2 [&_>_.Ellipsis]:font-light'
              }
            >
              <WebUI.Ellipsis className="shrink-0 grow-0 basis-1/2">
                {date}
              </WebUI.Ellipsis>
              {fullyRefunded ? (
                <WebUI.Ellipsis className="shrink-0 grow-0 basis-1/5">
                  Refunded
                </WebUI.Ellipsis>
              ) : chargeStatus && chargeStatus !== 'succeeded' ? (
                <WebUI.Ellipsis className="shrink-0 grow-0 basis-1/5">
                  {Util.startCase(Util.toCamelCase(chargeStatus))}
                </WebUI.Ellipsis>
              ) : (
                <WebUI.Ellipsis className="shrink-0 grow-0 basis-1/5">
                  {amount}
                </WebUI.Ellipsis>
              )}
              {chargeStatus !== 'failed' && (
                <WebUI.Ellipsis className="shrink-0 grow-0 basis-[30%] text-right text-ds-sm">
                  <WebUI.Anchor
                    href={hostedInvoiceUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Receipt
                  </WebUI.Anchor>
                </WebUI.Ellipsis>
              )}
            </WebUI.HStack>
          ),
        )}
    </WebUI.VStack>
  ) : null
}

export default PlanBillingPage
