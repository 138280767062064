import * as WebUI from '@cheddarup/web-ui'
import {useLiveRef, useUpdateEffect} from '@cheddarup/react-util'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import {
  FieldGroup,
  FieldsEditValue,
  normalizeFieldSetLessFields,
  parseFieldGroups,
  unparseFieldGroups,
} from '@cheddarup/core'

export interface FieldsEditInnerContextValue {
  fieldGroups: FieldGroup[]
  setFieldGroups: React.Dispatch<React.SetStateAction<FieldGroup[]>>
}

export const FieldsEditInnerContext = React.createContext(
  {} as FieldsEditInnerContextValue,
)

// MARK: – FieldsEdit

export interface FieldsEditProps {
  initialFieldSets?: Api.FieldSet[]
  initialFields?: Api.TabObjectField[]
  onChange: (value: FieldsEditValue[]) => void
  onInit?: (value: FieldsEditValue[]) => void
  children: React.ReactNode
}

export const FieldsEdit = ({
  initialFieldSets = [],
  initialFields = [],
  onChange,
  onInit,
  children,
}: FieldsEditProps) => {
  const initialFieldsRef = useRef(initialFields)
  const {fields: transientFields, fieldSets: transientFieldSets} = useMemo(
    () => normalizeFieldSetLessFields({fields: initialFieldsRef.current}),
    [],
  )
  const [fieldGroups, setFieldGroups] = useState<FieldGroup[]>(() =>
    parseFieldGroups({
      fieldSets: [...initialFieldSets, ...transientFieldSets],
      fields: [...initialFields, ...transientFields],
    }),
  )
  const onChangeRef = useLiveRef(onChange)
  const onInitRef = useLiveRef(onInit)

  const newFieldSetsAndFields = useMemo(
    () =>
      unparseFieldGroups({
        fieldGroups,
        fields: [...initialFieldsRef.current, ...transientFields],
      }),
    [fieldGroups, transientFields],
  )

  useUpdateEffect(() => {
    onChangeRef.current(newFieldSetsAndFields)
  }, [newFieldSetsAndFields])

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    onInitRef.current?.(newFieldSetsAndFields)
  }, [])

  const contextValue: FieldsEditInnerContextValue = useMemo(
    () => ({
      fieldGroups,
      setFieldGroups,
    }),
    [fieldGroups],
  )

  return (
    <WebUI.DragAndDrop
      touchEnabled
      collisionDetection={WebUI.closestCenter}
      modifiers={[WebUI.followMouseModifier]}
      onDragStart={(event) => {
        if (event.active.data.current) {
          if ('id' in event.active.data.current) {
            setFieldGroups((prevFieldGroups) => [
              ...prevFieldGroups,
              event.active.data.current as FieldGroup,
            ])
          }
        }
      }}
      onDragEnd={({active, over}) => {
        if (!over) {
          return
        }

        const oldOrder = (over.data.current?.sortable.items ??
          []) as WebUI.UniqueIdentifier[]
        const newOrder = WebUI.arrayMoveByValue(oldOrder, active.id, over.id)

        if (active.data.current) {
          setFieldGroups((prevFieldGroups) =>
            newOrder
              .map((oId) =>
                [
                  ...prevFieldGroups,
                  // biome-ignore lint/style/noNonNullAssertion:
                  active.data.current!,
                ].find((fg) => fg.id === oId),
              )
              .filter((fg): fg is FieldGroup => !!fg),
          )
        } else {
          setFieldGroups((prevFieldGroups) =>
            newOrder
              .map((oId) => prevFieldGroups.find((fg) => fg.id === oId))
              .filter((fg) => !!fg),
          )
        }
      }}
    >
      <FieldsEditInnerContext.Provider value={contextValue}>
        {children}
      </FieldsEditInnerContext.Provider>
    </WebUI.DragAndDrop>
  )
}
