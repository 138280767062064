import {NumberParam, useQueryParam} from 'use-query-params'
import {useParams} from 'react-router-dom'
import React, {useMemo, useRef, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'
import {
  api,
  useDeletePaymentItemMutation,
  useUpdateTabPaymentMutation,
} from '@cheddarup/api-client'
import {formatPaymentMethod} from 'src/helpers/formatPaymentMethod'
import {useStableCallback} from '@cheddarup/react-util'
import {
  formatQuantityDiscount,
  getAttendeeName,
  resolveSignatureFieldValue,
} from '@cheddarup/core'

import {Link} from './Link'
import {LinkButton} from './LinkButton'
import {PaymentItemTableView} from './PaymentItemTableView'
import {Ellipsis} from '@cheddarup/web-ui/next'

export type PaymentType = 'tab' | 'toOthers'

export interface OrderSummaryLayoutProps extends WebUI.ModalProps {
  paymentType?: PaymentType
  sidebar?: React.ReactNode
}

export const OrderSummaryLayout = ({
  paymentType = 'tab',
  sidebar,
  preventBodyScroll = true,
  className,
  children,
  ...restProps
}: OrderSummaryLayoutProps) => {
  const [selectedPaymentObject, setSelectedPaymentObjectId] =
    useSelectedPaymentObject(paymentType)
  const [selectedTermAndHistoryTabItemId, setSelectedTermAndHistoryTabItemId] =
    useSelectedTermAndHistoryTabItemId()

  return (
    <WebUI.Modal
      className={WebUI.cn(
        'OrderSummaryLayout',
        '[&_>_.ModalContentView]:h-full [&_>_.ModalContentView]:max-w-screen-lg sm:[&_>_.ModalContentView]:w-full',
        className,
      )}
      preventBodyScroll={preventBodyScroll}
      {...restProps}
    >
      <WebUI.MasterDetailDisclosed
        className={
          'OrderSummaryLayout-masterDetailDisclosed [&_>_.MasterDetailDisclosed-detail]:flex-0 [&_>_.MasterDetailDisclosed-detail]:bg-natural-80'
        }
        visible={!!selectedPaymentObject || !!selectedTermAndHistoryTabItemId}
        onDidHide={() => {
          setSelectedPaymentObjectId(undefined)
          setSelectedTermAndHistoryTabItemId(undefined)
        }}
        detail={
          <div className="OrderSummaryLayout-sidebarWrapper p-4 sm:w-[320px] sm:p-8">
            {sidebar}
          </div>
        }
      >
        {(disclosure) => (
          <>
            <WebUI.ModalCloseButton
              className={WebUI.cn(
                'OrderSummaryLayout-modalCloseButton',
                disclosure.visible && 'hidden sm:block',
              )}
            />

            {children}
          </>
        )}
      </WebUI.MasterDetailDisclosed>
    </WebUI.Modal>
  )
}

// MARK: – Misc sidebar components

export interface OrderSummarySidebarHeaderProps
  extends React.ComponentPropsWithoutRef<'div'> {
  paymentType?: PaymentType
}

export const OrderSummarySidebarHeader: React.FC<
  OrderSummarySidebarHeaderProps
> = ({paymentType = 'tab', ...restProps}) => {
  const urlParams = useParams()
  const [selectedPaymentObject] = useSelectedPaymentObject(paymentType)

  if (!selectedPaymentObject) {
    return null
  }

  return (
    <div {...restProps}>
      <WebUI.Heading className="mt-4 text-ds-base" as="h2">
        {selectedPaymentObject.tab_item?.name ??
          selectedPaymentObject.tab_form?.name ??
          selectedPaymentObject.time_slot?.spot.signup.name ??
          ''}
      </WebUI.Heading>
      {selectedPaymentObject.detail &&
      'itemType' in selectedPaymentObject.detail &&
      selectedPaymentObject.detail.itemType === 'ticket' ? (
        <WebUI.Text className="text-ds-sm">Ticket</WebUI.Text>
      ) : selectedPaymentObject.time_slot ? null : (
        <Link
          className="text-ds-sm"
          variant="primary"
          to={
            paymentType === 'tab'
              ? `/pdf/collection/${urlParams.collection}/tab-payment/${selectedPaymentObject.payment_id}/payment-item/${selectedPaymentObject.id}`
              : `/pdf/payments/${selectedPaymentObject.payment_id}/payment-item/${selectedPaymentObject.id}`
          }
          target="_blank"
        >
          Print Responses
        </Link>
      )}
    </div>
  )
}

export interface ItemVariantOptionValuesListProps
  extends React.ComponentPropsWithoutRef<'div'> {
  paymentType?: PaymentType
}

export const ItemVariantOptionValuesList = ({
  paymentType = 'tab',
  className,
  ...restProps
}: ItemVariantOptionValuesListProps) => {
  const [_selectedPaymentObject] = useSelectedPaymentObject(paymentType)

  if (!_selectedPaymentObject || !('tab_item' in _selectedPaymentObject)) {
    return null
  }

  const selectedPaymentObject =
    _selectedPaymentObject as unknown as Api.TabItemPayment
  const optionValues = selectedPaymentObject.detail?.variant?.optionValues ?? {}

  return (
    <>
      {Object.keys(optionValues).length > 0 && (
        <div
          className={WebUI.cn('flex flex-col gap-1 text-ds-sm', className)}
          {...restProps}
        >
          {Util.arrayFromObject(optionValues, (key, value) => (
            <div key={key}>
              <strong>{key}</strong>
              <p>{value}</p>
            </div>
          ))}
        </div>
      )}
    </>
  )
}

// MARK: – SignupPaymentSummary

export interface SignupPaymentSummaryProps
  extends React.ComponentPropsWithoutRef<'div'> {
  paymentItem: Api.TabSignupPayment
}

export const SignupPaymentSummary: React.FC<SignupPaymentSummaryProps> = ({
  paymentItem,
  className,
  ...restProps
}) => (
  <div className={WebUI.cn('flex flex-col gap-2', className)} {...restProps}>
    <WebUI.Text>Spot: {paymentItem.time_slot.spot.name}</WebUI.Text>
    <div className="flex flex-col font-light">
      <span>Quantity: {paymentItem.quantity}</span>
      {!!paymentItem.time_slot.options.startTime && (
        <>
          <span>
            Date:{' '}
            {Util.formatDate(
              paymentItem.time_slot.options.startTime,
              'MMM d yyyy',
            )}
          </span>
          <span>
            Time:{' '}
            {Util.formatDate(
              paymentItem.time_slot.options.startTime,
              'h:mm aaa',
            )}
            {paymentItem.time_slot.options.endTime
              ? ` – ${Util.formatDate(
                  paymentItem.time_slot.options.endTime,
                  'h:mm aaa',
                )}`
              : ''}
          </span>
        </>
      )}
    </div>
  </div>
)

// MARK: – PaymentOverview

type PaymentOverviewOwnProps =
  | {
      paymentType: 'tab'
      collectionName: string
      payment: Api.TabPayment
    }
  | {
      paymentType: 'toOthers'
      collectionName?: never
      payment: Api.PaymentToOthersDetailed
    }

export type PaymentOverviewProps = React.ComponentPropsWithoutRef<'div'> &
  PaymentOverviewOwnProps

export const PaymentOverview = ({
  collectionName,
  paymentType,
  payment,
  className,
  ...restProps
}: PaymentOverviewProps) => {
  const updatePaymentMutation = useUpdateTabPaymentMutation()

  const createdAtDate = new Date(payment.created_at)
  const source = payment.metadata_exposed?.source as
    | Util.UnionToIntersection<
        NonNullable<NonNullable<(typeof payment)['metadata_exposed']>['source']>
      >
    | undefined
  const paymentMethodLabel = `${
    source?.brand || source?.bank_name || formatPaymentMethod(payment)
  }${source?.last4 ? ` ****${source.last4}` : ''}`
  const {shipping_info: shippingInfo} = payment
  const shipped =
    shippingInfo?.shippingMethod === 'toMe' && !!shippingInfo.shipTo.name
  const hasAdditionalPayments =
    shipped ||
    !!payment.total_discounts ||
    !!payment.total_taxes ||
    (payment.subtotal !== 0 && !!payment.total_refund)

  return (
    <div
      className={WebUI.cn(
        'flex flex-col gap-8 sm:flex-row sm:gap-32',
        className,
      )}
      {...restProps}
    >
      <div className="flex flex-col gap-4 px-2 text-ds-sm">
        <div className="flex flex-col">
          <WebUI.Text>
            Collection:{' '}
            {paymentType === 'toOthers' ? (
              <Link
                target="_blank"
                variant="primary"
                to={`/c/${payment.tab.slug}`}
              >
                {payment.tab.name}
              </Link>
            ) : (
              collectionName
            )}
          </WebUI.Text>
          {paymentType === 'toOthers' && (
            <WebUI.Text>
              Organizer:{' '}
              <WebUI.Anchor href={`mailto:${payment.tab_member.email}`}>
                {payment.tab_member.name}
              </WebUI.Anchor>
            </WebUI.Text>
          )}
          <WebUI.Text className="font-light">
            Date: {Util.formatDateAs(createdAtDate)}
          </WebUI.Text>
          {!!paymentMethodLabel && (
            <WebUI.Text className="font-light">
              Payment Method: {paymentMethodLabel}
            </WebUI.Text>
          )}
          {payment.dispute && (
            <WebUI.Ellipsis className="font-light text-orange-50">
              Payment Disputed: {Util.formatDateAs(payment.dispute.created_at)},{' '}
              {Util.capitalize(payment.dispute.status.toLowerCase())}
            </WebUI.Ellipsis>
          )}
          {'can_delete' in payment && payment.payment_method === 'cash' && (
            <WebUI.Checkbox
              className="mt-3"
              state={payment.status === 'available'}
              onChange={() => {
                updatePaymentMutation.mutate({
                  pathParams: {
                    tabId: payment.tab_id,
                    paymentId: payment.id,
                  },
                  body: {
                    status:
                      payment.status === 'available' ? 'pending' : 'approved',
                  },
                })
              }}
            >
              Received
            </WebUI.Checkbox>
          )}
        </div>
        <div className="flex flex-col gap-2 font-light">
          {(shipped || !!payment.total_discounts || !!payment.total_taxes) && (
            <>
              <div className="flex flex-col">
                <PaymentOverviewInvoiceRow
                  title="Subtotal:"
                  value={payment.items_total}
                />
                {!!payment.total_discounts && (
                  <PaymentOverviewInvoiceRow
                    title={`Discounts: (${Object.keys(
                      payment.discounts || {},
                    ).join(', ')})`}
                    value={-payment.total_discounts}
                  />
                )}
                {!!payment.total_taxes && (
                  <PaymentOverviewInvoiceRow
                    title="Tax:"
                    value={payment.total_taxes}
                  />
                )}
                {shipped && (
                  <PaymentOverviewInvoiceRow
                    title="Shipping:"
                    value={shippingInfo.charge ?? 0}
                  />
                )}
              </div>
              <WebUI.Separator variant="primary" />
            </>
          )}
          {payment.subtotal > 0 && !!payment.total_refund && (
            <>
              <div>
                <PaymentOverviewInvoiceRow
                  title="Gross Amount:"
                  value={payment.subtotal + (payment.total_taxes ?? 0)}
                />
                <PaymentOverviewInvoiceRow
                  title="Total Refunds:"
                  value={-payment.total_refund}
                />
              </div>
              <WebUI.Separator variant="primary" />
            </>
          )}
          <div className="flex flex-row gap-2">
            <PaymentOverviewInvoiceRow
              className="font-normal"
              style={{
                color: payment.status === 'failed' ? '$primary' : '$black',
              }}
              inline={!hasAdditionalPayments}
              title={hasAdditionalPayments ? 'Net Payment:' : 'Payment:'}
              value={payment.status === 'failed' ? 0 : payment.total}
            />
            {payment.status === 'failed' && (
              <div className="font-normal text-orange-50">Failed</div>
            )}
          </div>
        </div>
        {payment.can_refund && (
          <LinkButton
            className="self-start"
            size="compact"
            variant="secondary"
            to={
              'can_delete' in payment
                ? `/collection/${payment.tab_id}/manage/payments/${payment.id}/refunds`
                : `/payments/${payment.id}/refunds`
            }
          >
            Refunds
          </LinkButton>
        )}
      </div>
      {payment.shipping_info.currentOptions?.shipToEnabled && (
        <OrderSummaryShipTo
          className="PaymentOverview-shipping px-2"
          payment={payment}
        />
      )}
    </div>
  )
}

// MARK: – OrderSummaryShipTo

interface OrderSummaryShipToProps
  extends React.ComponentPropsWithoutRef<'div'> {
  payment: Api.TabPayment | Api.PaymentToOthersDetailed
}

const OrderSummaryShipTo = ({
  payment,
  className,
  ...restProps
}: OrderSummaryShipToProps) => {
  const shipTo = payment.shipping_info.shipTo
  const localPickup =
    payment.shipping_info.shippingMethod !== 'toMe' || !shipTo.name

  return (
    <div className={WebUI.cn('text-ds-sm', className)} {...restProps}>
      <WebUI.Text>Ship to:</WebUI.Text>
      <div className="mb-4 font-light">
        {localPickup ? (
          <p>Local Pickup</p>
        ) : (
          <>
            <p>{shipTo.name}</p>
            <p>{shipTo.address}</p>
            <p>
              {shipTo.city}, {shipTo.state} {shipTo.zip}
            </p>
            <p>{shipTo.country === 'CA' ? 'Canada' : 'United States'}</p>
          </>
        )}
      </div>
      {'can_delete' in payment && !localPickup && (
        <LinkButton
          size="compact"
          variant="default"
          to={`/collection/${payment.tab_id}/manage/shipping/print-shipping-label/${payment.id}`}
        >
          {payment.shipment?.purchased
            ? 'View Shipping Summary'
            : 'Print Shipping Label'}
        </LinkButton>
      )}
    </div>
  )
}

// MARK: – PaymentOverviewInvoiceRow

interface PaymentOverviewInvoiceRowProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'title'> {
  inline?: boolean
  title: React.ReactNode
  value: string | number
}

const PaymentOverviewInvoiceRow = ({
  inline,
  title,
  value,
  className,
  ...restProps
}: PaymentOverviewInvoiceRowProps) => (
  <div
    className={WebUI.cn('flex flex-row items-center gap-1', className)}
    {...restProps}
  >
    <div className={inline ? 'mr-1' : 'min-h-0 min-w-0 flex-auto'}>{title}</div>
    <div className="text-right">{Util.formatAmount(value)}</div>
  </div>
)

// MARK: – PaymentItemTable

export interface PaymentItemTableProps
  extends Omit<WebUI.TableViewProps<Api.TabItemPayment>, 'data' | 'columns'> {
  paymentType?: PaymentType
  payment: Api.TabPayment | Api.PaymentToOthersDetailed
  paymentItems: Api.TabItemPayment[]
  shippingInfo: Api.PaymentShippingInfo
  refunds: Api.TabPaymentRefund[]
}

export const PaymentItemTable = ({
  paymentType = 'tab',
  payment,
  paymentItems,
  shippingInfo,
  refunds,
  ...restProps
}: PaymentItemTableProps) => {
  const media = WebUI.useMedia()
  const urlParams = useParams()
  const [selectedPaymentObject, setSelectedPaymentObjectId] =
    useSelectedPaymentObject(paymentType)
  const [selectedTermAndHistoryTabItemId, setSelectedTermAndHistoryTabItemId] =
    useSelectedTermAndHistoryTabItemId()

  const isTabPayment = 'can_delete' in payment

  const getRefundsForPaymentItems = useStableCallback((paymentItemId: number) =>
    refunds.filter((refund) => {
      const refundedPaymentItemIds = (refund.detail?.items || [])
        .filter(({amount}) => amount != null && amount >= 0)
        .map(({payment_item_id}) => String(payment_item_id))
      return refundedPaymentItemIds.includes(String(paymentItemId))
    }),
  )

  const hasItemFieldViews =
    paymentItems.some(
      ({tab_item, item_field_views}) =>
        item_field_views?.length > 0 || tab_item?.fields?.length > 0,
    ) ||
    paymentItems.some(
      (pi) => Object.keys(pi.detail?.variant?.optionValues ?? {}).length > 0,
    )

  const columnHelper = useMemo(
    () => WebUI.createColumnHelper<Api.TabItemPayment>(),
    [],
  )

  const hasTickets = paymentItems.some((pi) => pi.detail.itemType === 'ticket')

  const columns = useMemo(
    () =>
      [
        columnHelper.accessor((pi) => pi.tab_item.name, {
          id: 'itemName',
          meta: {
            subtle: false,
          },
          header: 'Item(s)',
          cell: ({cell, row: {original: pi}}) => {
            const recurringPaymentInvoice = payment.recurring_payment_invoice
            const scheduledInvoice = payment.scheduled_invoices?.find(
              (si) => si.tab_object_id === pi.tab_item.id,
            )
            const recurringPaymentContractOptions =
              recurringPaymentInvoice?.recurring_payment_contract?.options ??
              (scheduledInvoice
                ? JSON.parse(
                    scheduledInvoice.recurring_payment_contract_options,
                  )
                : null)
            const recurringPaymentContractMetadata =
              recurringPaymentInvoice?.recurring_payment_contract?.metadata ??
              (scheduledInvoice
                ? JSON.parse(
                    scheduledInvoice.recurring_payment_contract_metadata,
                  )
                : null)

            return (
              <div className="flex flex-col">
                <WebUI.Ellipsis>{cell.getValue()}</WebUI.Ellipsis>
                {pi.tab_item.options.quantityDiscount?.enabled && (
                  <div className="flex flex-col text-ds-xs">
                    <Ellipsis className="text-orange-500">
                      {formatQuantityDiscount(
                        pi.tab_item.options.quantityDiscount,
                      )}
                    </Ellipsis>
                    {pi.tab_item.amount != null && (
                      <Ellipsis className="text-grey-500">
                        Original price {Util.formatAmount(pi.tab_item.amount)}{' '}
                        each
                      </Ellipsis>
                    )}
                  </div>
                )}
                {!media.sm && (
                  <WebUI.Ellipsis>
                    {Util.formatAmount(pi.amount)}
                  </WebUI.Ellipsis>
                )}
                {getRefundsForPaymentItems(pi.id).length > 0 && (
                  <Link
                    variant="primary"
                    to={
                      isTabPayment
                        ? `/collection/${urlParams.collection}/manage/payments/${urlParams.payment}/refunds`
                        : `/payments/${urlParams.payment}/refunds`
                    }
                    state={{selectedPaymentItemId: pi.id}}
                  >
                    View Refund
                  </Link>
                )}
                {!!recurringPaymentContractMetadata &&
                  !!recurringPaymentContractOptions && (
                    <div className="mt-2 flex flex-col">
                      <WebUI.Button
                        className="text-ds-sm"
                        variant="link"
                        iconBefore={
                          <WebUI.PhosphorIcon icon="arrow-clockwise" />
                        }
                        onClick={() =>
                          setSelectedTermAndHistoryTabItemId((prevTabItemId) =>
                            prevTabItemId === pi.tab_item.id
                              ? undefined
                              : pi.tab_item.id,
                          )
                        }
                      >
                        {selectedTermAndHistoryTabItemId === pi.tab_item.id
                          ? 'Hide Terms & History'
                          : 'View Terms & History'}
                      </WebUI.Button>
                      <span className="ml-[22px] text-gray400">
                        {(recurringPaymentContractMetadata.total_invoices ===
                          0 ||
                          !recurringPaymentInvoice) &&
                        pi.total === 0 &&
                        recurringPaymentContractOptions.start?.type !==
                          'first_payment'
                          ? `First payment scheduled for ${Util.formatDate(
                              recurringPaymentContractMetadata.scheduled_start,
                            )}`
                          : recurringPaymentContractOptions.ends.type ===
                              'payment_count'
                            ? `Payment ${
                                recurringPaymentInvoice?.metadata
                                  .payment_number ?? 1
                              } in a series of ${
                                recurringPaymentContractOptions.ends
                                  .payment_count
                              } payments`
                            : `This is payment ${recurringPaymentContractMetadata.total_invoices}. Payments continue indefinitely`}
                      </span>
                    </div>
                  )}
              </div>
            )
          },
        }),
        columnHelper.accessor(
          (pi) => pi.tab_item.options.itemSubType ?? 'item',
          {
            id: 'type',
            maxSize: 80,
            header: 'Type',
            cell: ({cell}) => Util.capitalize(cell.getValue()),
          },
        ),
        hasTickets
          ? columnHelper.display({
              id: 'ticket-download',
              header: 'Ticket Download',
              cell: ({row: {original: pi}}) =>
                pi.tab_item.options.itemSubType === 'ticket' && (
                  <Link
                    className="font-light"
                    variant="primary"
                    target="_blank"
                    to={`/pdf/payments/${payment.id}/ticket-item/${pi.id}`}
                  >
                    {`#${Util.encodeToBase36(pi.id)}: ${getAttendeeName(
                      pi.item_field_views,
                    )}`}
                  </Link>
                ),
            })
          : undefined,
        columnHelper.accessor((pi) => pi.quantity, {
          id: 'quantity',
          size: 60,
          meta: {
            align: 'right',
          },
          header: 'Qty',
          cell: ({cell, row: {original: pi}}) => {
            const piRefunds = getRefundsForPaymentItems(pi.id)
            const refundQuantitySum = Util.sumBy(
              piRefunds.flatMap((r) =>
                (r.detail?.items ?? []).filter(
                  (i) => i.payment_item_id === pi.id,
                ),
              ),
              (i) => i.quantity ?? 0,
            )
            return (
              <div className="flex flex-col">
                <p>{cell.getValue()}</p>
                {refundQuantitySum > 0 && (
                  <Link
                    to={`/collection/${payment.tab_id}/manage/payments/${payment.id}/refunds`}
                    state={{selectedPaymentItemId: pi.id}}
                  >
                    -{refundQuantitySum}
                  </Link>
                )}
              </div>
            )
          },
        }),
        media.sm
          ? columnHelper.accessor((pi) => pi.amount, {
              id: 'price',
              size: 100,
              meta: {
                align: 'right',
              },
              header: 'Price',
              cell: ({cell}) => Util.formatAmount(cell.getValue()),
            })
          : undefined,
        columnHelper.accessor((pi) => pi.total, {
          id: 'subtotal',
          size: 80,
          meta: {
            align: 'right',
          },
          header: 'Subtotal',
          cell: ({cell, row: {original: pi}}) => {
            const piRefunds = getRefundsForPaymentItems(pi.id)
            const refunded =
              !!pi.refund_data &&
              !Number.isNaN(pi.refund_data.total_refunded_cents) &&
              pi.refund_data.total_refunded_cents >= 0

            const isPartiallyRefunded =
              refunded && (pi.refund_data?.net_amount_cents ?? 0) > 0

            return (
              <div className="flex flex-col">
                <p>{Util.formatAmount(cell.getValue())}</p>
                {refunded && !isPartiallyRefunded && piRefunds.length > 0 && (
                  <Link
                    to={`/collection/${payment.tab_id}/manage/payments/${payment.id}/refunds`}
                    state={{selectedPaymentItemId: pi.id}}
                  >
                    -
                    {Util.formatAmount(
                      Util.sumBy(piRefunds, (refund) => refund.amount),
                      {
                        cents: false,
                      },
                    )}
                  </Link>
                )}
              </div>
            )
          },
        }),
        hasItemFieldViews
          ? columnHelper.display({
              id: 'responses',
              size: 60,
              meta: {
                align: 'right',
              },
              header: 'Details',
              cell: ({row: {original: pi}}) => {
                const showViewHideFieldsButton =
                  Object.keys(pi.detail?.variant?.optionValues ?? {}).length >
                    0 ||
                  pi.item_field_views?.length > 0 ||
                  pi.tab_item.fields?.length > 0
                const isSelected = pi.id === selectedPaymentObject?.id

                if (!showViewHideFieldsButton) {
                  return null
                }

                return (
                  <WebUI.Button
                    variant="link"
                    onClick={() =>
                      setSelectedPaymentObjectId(isSelected ? undefined : pi.id)
                    }
                  >
                    {isSelected ? 'Hide' : 'View'}
                  </WebUI.Button>
                )
              },
            })
          : undefined,
      ].filter((c) => !!c),
    [
      columnHelper,
      media.sm,
      hasTickets,
      hasItemFieldViews,
      payment.recurring_payment_invoice,
      payment.scheduled_invoices,
      payment.id,
      payment.tab_id,
      getRefundsForPaymentItems,
      isTabPayment,
      urlParams.collection,
      urlParams.payment,
      selectedTermAndHistoryTabItemId,
      setSelectedTermAndHistoryTabItemId,
      selectedPaymentObject?.id,
      setSelectedPaymentObjectId,
    ],
  )

  const data = useMemo(
    () =>
      [
        ...paymentItems,
        // fake shipping payment
        shippingInfo.charge
          ? ({
              payment_id: -1,
              tab_object_id: -1,
              amount: shippingInfo.charge,
              quantity: 1,
              tab_item: {
                id: -1,
                name: 'Shipping',
                options: {},
              },
              item_field_views: [],
              total: shippingInfo.charge,
            } as unknown as Api.TabItemPayment)
          : undefined,
      ].filter((pi) => !!pi),
    [paymentItems, shippingInfo.charge],
  )

  return (
    <PaymentItemTableView
      className="[&_>_.TableView-headerGroupList_>_.TableView-headerGroup]:bg-aquaLight"
      columns={columns}
      data={data}
      {...restProps}
    />
  )
}

// MARK: – PaymentFormTable

export interface PaymentFormTableProps
  extends Omit<WebUI.TableViewProps<Api.TabFormPayment>, 'columns'> {
  paymentType?: PaymentType
}

export const PaymentFormTable = ({
  paymentType = 'tab',
  data,
  className,
  ...restProps
}: PaymentFormTableProps) => {
  const [selectedPaymentObject, setSelectedPaymentObjectId] =
    useSelectedPaymentObject(paymentType)

  const columnHelper = useMemo(
    () => WebUI.createColumnHelper<Api.TabFormPayment>(),
    [],
  )

  const columns = useMemo(
    () => [
      columnHelper.accessor((pi) => pi.detail.name ?? pi.tab_form.name, {
        id: 'formName',
        size: 160,
        meta: {
          subtle: false,
        },
        header: 'Forms',
      }),
      columnHelper.accessor(
        (pi) => (pi.tab_form.options.isWaiver ? 'Waiver' : 'Form'),
        {
          id: 'type',
          size: 60,
          header: 'Type',
        },
      ),
      columnHelper.display({
        id: 'responses',
        size: 60,
        meta: {
          align: 'right',
        },
        header: 'Responses',
        cell: ({row: {original: pf}}) => {
          const isSelected = pf.id === selectedPaymentObject?.id
          return pf.item_field_views.length > 0 ||
            pf.tab_form.fields.length > 0 ? (
            <WebUI.Button
              variant="link"
              onClick={() =>
                setSelectedPaymentObjectId(isSelected ? undefined : pf.id)
              }
            >
              {isSelected ? 'Hide' : 'View'}
            </WebUI.Button>
          ) : null
        },
      }),
    ],
    [selectedPaymentObject?.id, setSelectedPaymentObjectId, columnHelper],
  )

  return (
    <PaymentItemTableView
      className={WebUI.cn(
        '[&_>_.TableView-headerGroupList_>_.TableView-headerGroup]:bg-natural-80',
        className,
      )}
      columns={columns}
      data={data}
      {...restProps}
    />
  )
}

// MARK: – PaymentSignupTable

export interface PaymentSignupTableProps
  extends Omit<WebUI.TableViewProps<Api.TabSignupPayment>, 'columns'> {
  collectionId: number
  paymentType?: PaymentType
}

export const PaymentSignupTable: React.FC<PaymentSignupTableProps> = ({
  collectionId,
  paymentType = 'tab',
  className,
  ...restProps
}) => {
  const [selectedPaymentObject, setSelectedPaymentObjectId] =
    useSelectedPaymentObject(paymentType)

  const columnHelper = useMemo(
    () => WebUI.createColumnHelper<Api.TabSignupPayment>(),
    [],
  )

  const columns = useMemo(
    () => [
      columnHelper.accessor((pi) => pi.time_slot.spot.signup.name, {
        id: 'summary',
        meta: {
          subtle: false,
        },
        header: 'Sign Ups',
        cell: ({row: {original: pi}, cell}) => (
          <div className="flex flex-col gap-2">
            <WebUI.Text>{cell.getValue()}</WebUI.Text>

            <div className="flex flex-col gap-2 pl-4">
              <SignupPaymentSummary paymentItem={pi} />

              <CancelSignupPaymentItemButton
                collectionId={collectionId}
                paymentType={paymentType}
                paymentItem={pi}
              />
            </div>
          </div>
        ),
      }),
      columnHelper.display({
        id: 'responses',
        size: 60,
        meta: {
          align: 'right',
        },
        header: 'Responses',
        cell: ({row: {original: pf}}) => {
          const isSelected = pf.id === selectedPaymentObject?.id
          return pf.item_field_views.length > 0 ||
            pf.time_slot.spot.signup.fields.length > 0 ? (
            <WebUI.Button
              variant="link"
              onClick={() =>
                setSelectedPaymentObjectId(isSelected ? undefined : pf.id)
              }
            >
              {isSelected ? 'Hide' : 'View'}
            </WebUI.Button>
          ) : null
        },
      }),
    ],
    [
      collectionId,
      paymentType,
      selectedPaymentObject?.id,
      setSelectedPaymentObjectId,
      columnHelper,
    ],
  )

  return (
    <PaymentItemTableView
      className={WebUI.cn(
        '[&_>_.TableView-headerGroupList_>_.TableView-headerGroup]:bg-natural-80',
        className,
      )}
      columns={columns}
      {...restProps}
    />
  )
}

// MARK: – CancelSignupPaymentItemButton

type CancelSignupPaymentItemButtonOwnProps =
  | {
      compact?: true
      collectionId: number
      paymentType: PaymentType
      payment?: never
      paymentItem: Api.TabSignupPayment
    }
  | {
      compact: false
      collectionId?: never
      paymentType: PaymentType
      payment: Api.PaymentToOthersDetailed | Api.TabPayment
      paymentItem: Api.TabSignupPayment
    }

export type CancelSignupPaymentItemButtonProps = WebUI.ButtonProps &
  React.ComponentPropsWithoutRef<'button'> &
  CancelSignupPaymentItemButtonOwnProps

export const CancelSignupPaymentItemButton: React.FC<
  CancelSignupPaymentItemButtonProps
> = ({
  compact = true,
  collectionId,
  paymentType,
  payment,
  paymentItem,
  className,
  ...restProps
}) => {
  const [sendEmail, setSendEmail] = useState(true)
  const commentRef = useRef('')
  const growlActions = WebUI.useGrowlActions()
  const deletePaymentItemMutation = useDeletePaymentItemMutation()

  return paymentType === 'tab' || paymentItem['cancellable?'] ? (
    <WebUI.Alert
      disclosure={
        <WebUI.DialogDisclosure
          className={className}
          variant="link"
          {...restProps}
        >
          Cancel{paymentType === 'toOthers' ? ' my' : ''} sign up for this spot
        </WebUI.DialogDisclosure>
      }
    >
      <WebUI.AlertHeader>Are you sure?</WebUI.AlertHeader>
      <WebUI.AlertContentView
        text={
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-3 text-ds-sm">
              <span>
                Are you sure you want to{' '}
                {paymentType === 'toOthers'
                  ? 'delete your sign up for'
                  : 'remove this participant from'}{' '}
                "{paymentItem.time_slot.spot.signup.name}"?
              </span>

              {paymentItem.time_slot.spot.signup.options.signupType ===
                'schedule' && (
                <SignupPaymentSummary paymentItem={paymentItem} />
              )}
            </div>

            {paymentType === 'toOthers' && (
              <WebUI.FormField size="compact" label="Comment for organizer">
                <WebUI.Input
                  size="compact"
                  placeholder="Optional Comment"
                  defaultValue={commentRef.current}
                  onChange={(event) => {
                    commentRef.current = event.target.value
                  }}
                />
              </WebUI.FormField>
            )}
            {paymentType === 'tab' && (
              <WebUI.Checkbox
                size="compact"
                state={sendEmail}
                onChange={(event) => setSendEmail(event.target.checked)}
              >
                Send the participant an automatic email that signup has been
                deleted
              </WebUI.Checkbox>
            )}
          </div>
        }
        actions={
          <>
            <WebUI.AlertActionButton
              execute={async () => {
                await deletePaymentItemMutation.mutateAsync({
                  signupId: paymentItem.time_slot.spot.signup.id,
                  spotId: paymentItem.time_slot.spot.id,
                  pathParams: {
                    tabId: collectionId ?? payment.tab_id,
                    paymentId: paymentItem.payment_id,
                    paymentItemId: paymentItem.id,
                  },
                  queryParams: {
                    comment: commentRef.current || undefined,
                    sendEmail,
                  },
                })

                if (paymentType === 'toOthers') {
                  growlActions.show('success', {
                    body: 'You have cancelled your spot. We will send the organizer an email to let them know.',
                  })
                }
              }}
            >
              Delete Spot
            </WebUI.AlertActionButton>
            <WebUI.AlertCancelButton />
          </>
        }
      />
    </WebUI.Alert>
  ) : (
    <span className={WebUI.cn('font-light italic', className)}>
      {compact ? (
        'This spot can no longer be cancelled'
      ) : (
        <>
          This spot can no longer be cancelled. Please contact the organizer of
          this sign up,{' '}
          {payment && 'tab' in payment && (
            <WebUI.Anchor href={`mailto:${payment.tab.user.email}`}>
              {payment.tab.user.name}
            </WebUI.Anchor>
          )}
          , if you have any questions.
        </>
      )}
    </span>
  )
}

// MARK: – Helpers

export const useSelectedPaymentObject = (paymentType: PaymentType = 'tab') => {
  const urlParams = useParams()
  const [selectedPaymentObjectId, setSelectedPaymentObjectId] = useQueryParam(
    'poId',
    NumberParam,
  )
  const tabPaymentItemsQuery = api.tabPayments.detail.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: urlParams.collection!,
        // biome-ignore lint/style/noNonNullAssertion:
        paymentId: urlParams.payment!,
      },
    },
    {
      enabled: paymentType === 'tab',
      select: (tabPayment) =>
        tabPayment.payment_items.map((pi) => ({
          ...pi,
          item_field_views: pi.item_field_views.map((ifv) => ({
            ...ifv,
            value:
              ifv.field_type === 'signature'
                ? resolveSignatureFieldValue(ifv, tabPayment.e_signatures)
                : ifv.value,
          })),
        })),
    },
  )
  const paymentToOthersItemsQuery = api.payments.detail.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        paymentId: urlParams.payment!,
      },
    },
    {
      enabled: paymentType === 'toOthers',
      select: (payment) => payment.payment_items,
    },
  )

  const paymentItems = useMemo(
    () => tabPaymentItemsQuery.data ?? paymentToOthersItemsQuery.data ?? [],
    [paymentToOthersItemsQuery.data, tabPaymentItemsQuery.data],
  )

  const selectedPaymentObject = useMemo(
    () => paymentItems.find((pi) => pi.id === selectedPaymentObjectId) ?? null,
    [paymentItems, selectedPaymentObjectId],
  )

  return [selectedPaymentObject, setSelectedPaymentObjectId] as const
}

export const useSelectedTermAndHistoryTabItemId = () => {
  const [tabItemId, setTabItemId] = useQueryParam('tiId', NumberParam)
  return [tabItemId, setTabItemId] as const
}
