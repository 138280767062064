import React from 'react'
import {useNavigate} from 'react-router-dom'
import {useConvertTemplateToTabMutation} from '@cheddarup/api-client'
import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import {getAuthToken} from 'src/hooks/useAuthToken'
import {useLogout} from 'src/hooks/useAuth'

export interface ConvertTemplateToTabButtonProps
  extends WebUI.ButtonProps,
    React.ComponentPropsWithoutRef<'button'> {
  templateId: number
}

export const ConvertTemplateToTabButton = React.forwardRef<
  HTMLButtonElement,
  ConvertTemplateToTabButtonProps
>(
  (
    {
      templateId,
      variant = 'primary',
      loading: loadingProp,
      onClick,
      children = 'Use Template',
      ...restProps
    },
    forwardedRef,
  ) => {
    const navigate = useNavigate()
    const growlActions = WebUI.useGrowlActions()
    const convertTemplateToTabMutation = useConvertTemplateToTabMutation()
    const [logout] = useLogout()

    return (
      <WebUI.RoundedButton
        ref={forwardedRef}
        loading={loadingProp ?? convertTemplateToTabMutation.isPending}
        variant={variant}
        onClick={async (event) => {
          onClick?.(event)

          if (!event.defaultPrevented) {
            const authToken = getAuthToken()

            if (
              authToken &&
              authToken.tokenType === 'User' &&
              !authToken.claims?.insecure
            ) {
              try {
                const tab = await convertTemplateToTabMutation.mutateAsync({
                  pathParams: {
                    templateId,
                  },
                })
                navigate(`/collections?convertedTabId=${tab.id}`)
              } catch (err: any) {
                const apiError = err?.response?.data?.errors?.[0]
                growlActions.show('error', {
                  title: Util.startCase(apiError?.error || 'Error'),
                  body: apiError?.details || 'Something went wrong',
                })
              }
            } else {
              if (authToken) {
                await logout()
              }
              navigate(`/signup?templateId=${templateId}`)
            }
          }
        }}
        {...restProps}
      >
        {children}
      </WebUI.RoundedButton>
    )
  },
)
