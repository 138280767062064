import {forwardRef} from 'react'
import {ForwardRefComponent} from '@cheddarup/react-util'

import {cn} from '../utils'

export type StackDirection = 'vertical' | 'horizontal'

export interface StackProps {
  direction?: StackDirection
}

// Based on https://every-layout.dev/layouts/stack/
/**
 * @deprecated Use native html instead
 */
export const Stack = forwardRef(
  (
    {as: Comp = 'div', className, direction = 'horizontal', ...restProps},
    forwardedRef,
  ) => (
    <Comp
      ref={forwardedRef}
      className={cn(
        `Stack Stack--${direction}`,
        'flex',
        direction === 'vertical' ? 'flex-col' : 'flex-row',
        className,
      )}
      {...restProps}
    />
  ),
) as ForwardRefComponent<'div', StackProps>

// MARK: - Convenience components

export interface HStackProps extends Omit<StackProps, 'direction'> {}

/**
 * @deprecated Use native html instead
 */
export const HStack = forwardRef((props, forwardedRef) => (
  <Stack ref={forwardedRef} direction="horizontal" {...props} />
)) as ForwardRefComponent<'div', HStackProps>

export interface VStackProps extends Omit<StackProps, 'direction'> {}

/**
 * @deprecated Use native html instead
 */
export const VStack = forwardRef((props, forwardedRef) => (
  <Stack ref={forwardedRef} direction="vertical" {...props} />
)) as ForwardRefComponent<'div', VStackProps>
