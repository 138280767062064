import {forwardRef} from 'react'
import {ForwardRefComponent} from '@cheddarup/react-util'
import {cva} from 'class-variance-authority'

import {Button, ButtonProps} from './Button'
import {VariantsProps, cn} from '../utils'

export const iconButton = cva(
  `px-1 py-1 [font-size:inherit] [&_>_.Button-spinner_>_.Loader]:h-[1em]
  [&_>_.Button-spinner_>_.Loader]:w-[1em]`,
  {
    variants: {
      size: {
        default: 'h-[2.2em] w-[2.2em]',
        default_alt: 'h-[1.5em] w-[1.5em]',
        compact: 'h-[1.9em] w-[1.9em]',
        small: 'h-[1.5em] w-[1.5em]',
      },
    },
    defaultVariants: {
      size: 'default',
    },
  },
)

export interface IconButtonProps
  extends VariantsProps<typeof iconButton>,
    Partial<Omit<ButtonProps, 'size'>> {
  active?: boolean
}

export const IconButton = forwardRef(
  (
    {className, size = 'default', variant = 'ghost', active, ...restProps},
    forwardedRef,
  ) => (
    <Button
      ref={forwardedRef}
      className={cn(
        'IconButton',
        iconButton({size}),
        variant === 'outlined' && 'rounded border',
        className,
      )}
      variant={variant}
      data-active={active}
      {...restProps}
    />
  ),
) as ForwardRefComponent<'button', IconButtonProps>
