import * as Yup from 'yup'
import {useFormik} from '@cheddarup/react-util'
import {useStripe} from '@stripe/react-stripe-js'
import * as WebUI from '@cheddarup/web-ui'
import {useCreateOrgMemberInvitesMutation} from '@cheddarup/api-client'
import provinces from 'src/data/provinces.json'
import {CreateTokenBankAccountData} from '@stripe/stripe-js'

const US_POSTAL_CODE_REGEX = /(^\d{5}$)|(^\d{5}-\d{4}$)/
const CANADA_POSTAL_CODE_REGEX = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/

export interface AddMemberFormProps
  extends Omit<React.ComponentPropsWithoutRef<'form'>, 'onSubmit'> {
  onClose: () => void
}

const AddMemberForm = ({
  onClose,
  className,
  ...restProps
}: AddMemberFormProps) => {
  const createInvitesMutation = useCreateOrgMemberInvitesMutation()
  const growlActions = WebUI.useGrowlActions()
  const stripe = useStripe()

  const formik = useFormik({
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required('Required'),
      last_name: Yup.string().required('Required'),
      email: Yup.string().email('Invalid format'),
      currency: Yup.string().required('Required'),
      isCountryUS: Yup.boolean(),
      address: Yup.object().shape({
        country: Yup.string(),
        postal_code: Yup.string()
          .required('Required')
          .when('country', {
            is: 'United States',
            // biome-ignore lint/suspicious/noThenProperty:
            then: (schema) =>
              schema.matches(US_POSTAL_CODE_REGEX, 'Invalid format'),
            otherwise: (schema) =>
              schema.matches(CANADA_POSTAL_CODE_REGEX, 'Invalid format'),
          }),
      }),
    }),
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      type: 'individual',
      currency: 'USD',
      business_tax_id: '',
      business_name: '',
      address: {
        line1: '',
        city: '',
        postal_code: '',
        state: '',
        country: 'United States',
      },
      bankAccount: {
        accountNumber: '',
        routingNumber: '',
      },
    },
    onSubmit: async ({bankAccount, ...values}) => {
      try {
        const isBankAccountFilledIn =
          bankAccount.accountNumber.length > 0 &&
          bankAccount.routingNumber.length > 0
        const stripeRes = isBankAccountFilledIn
          ? await stripe?.createToken('bank_account', {
              account_holder_name: `${values.first_name} ${values.last_name}`,
              country: values.address?.country === 'Canada' ? 'CA' : 'US',
              currency: values.currency,
              account_number: bankAccount.accountNumber,
              routing_number: bankAccount.routingNumber,
            } as CreateTokenBankAccountData)
          : undefined

        if (stripeRes?.error) {
          growlActions.show('error', {
            title: 'Error',
            body: stripeRes.error.message,
          })
          return
        }

        await createInvitesMutation.mutateAsync({
          body: {
            profiles: [
              {
                ...values,
                stripe: {
                  banks: stripeRes
                    ? [
                        {
                          nickname: `${values.first_name} ${values.last_name}`,
                          token: stripeRes.token.id,
                        },
                      ]
                    : [],
                },
              },
            ],
          },
        })
        growlActions.show('success', {
          title: 'Invite sent!',
          body: `Nice work! A welcome email was just sent to ${values.email}. `,
        })
        onClose()
      } catch (err: any) {
        const errors = err.response?.data?.errors ?? {}
        const email = Object.keys(errors)?.[0]
        const errorMessage = errors[email ?? '']?.[0]?.message || err.message

        growlActions.clear()
        growlActions.show('error', {
          body: email ? `${errorMessage} at ${email}` : errorMessage,
          title: 'Error',
        })
      }
    },
  })

  return (
    <WebUI.Form
      className={WebUI.cn(
        '[&_.FormField]:flex-[1_0_0px] [&_>_.Form-inner]:gap-4',
        className,
      )}
      onSubmit={formik.handleSubmit}
      {...restProps}
    >
      <WebUI.HStack className="justify-end">
        <WebUI.IconButton className="m-1" onClick={onClose}>
          <WebUI.PhosphorIcon icon="x-circle" />
        </WebUI.IconButton>
      </WebUI.HStack>

      <WebUI.FormFieldGroup>
        <WebUI.FormField error={formik.errors.first_name}>
          <WebUI.Input
            name="first_name"
            placeholder="First Name"
            value={formik.values.first_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
        <WebUI.FormField error={formik.errors.last_name}>
          <WebUI.Input
            name="last_name"
            placeholder="Last Name"
            value={formik.values.last_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
      </WebUI.FormFieldGroup>

      <WebUI.FormFieldGroup>
        <WebUI.FormField error={formik.errors.email}>
          <WebUI.Input
            name="email"
            placeholder="Email Address"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
        <WebUI.FormField error={formik.errors.currency}>
          <WebUI.Select
            name="currency"
            value={formik.values.currency}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            <option value="USD">US Dollar</option>
            <option value="CAD">Canadian Dollar</option>
          </WebUI.Select>
        </WebUI.FormField>
      </WebUI.FormFieldGroup>

      <WebUI.FormFieldGroup>
        <WebUI.FormField error={formik.errors.business_tax_id}>
          <WebUI.Input
            name="business_tax_id"
            placeholder="Tax ID (optional)"
            value={formik.values.business_tax_id}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
        <WebUI.FormField error={formik.errors.business_name}>
          <WebUI.Input
            name="business_name"
            placeholder="Organization Name (optional)"
            value={formik.values.business_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
      </WebUI.FormFieldGroup>

      <WebUI.FormField error={formik.errors.type}>
        <WebUI.Select
          name="type"
          value={formik.values.type}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        >
          <option value="individual">Individual</option>
          <option value="company">Company</option>
        </WebUI.Select>
      </WebUI.FormField>

      <WebUI.FormFieldGroup>
        <WebUI.FormField error={formik.errors.address?.line1}>
          <WebUI.Input
            name="address.line1"
            placeholder="Street Address"
            value={formik.values.address.line1}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
        <WebUI.FormField error={formik.errors.address?.city}>
          <WebUI.Input
            name="address.city"
            placeholder="City"
            value={formik.values.address.city}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
      </WebUI.FormFieldGroup>

      <WebUI.FormFieldGroup>
        <WebUI.FormField error={formik.errors.address?.state}>
          <WebUI.Select
            name="address.state"
            value={formik.values.address.state}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            <option value="">
              {formik.values.address.country === 'Canada'
                ? 'Province'
                : 'State'}
            </option>
            {(provinces as any)[
              ({'United States': 'US', Canada: 'CA'} as any)[
                formik.values.address.country
              ]
            ].map((province: any) => (
              <option key={province.short} value={province.short}>
                {province.name}
              </option>
            ))}
          </WebUI.Select>
        </WebUI.FormField>
        <WebUI.FormField error={formik.errors.address?.postal_code}>
          <WebUI.Input
            name="address.postal_code"
            placeholder="Zip Code"
            value={formik.values.address.postal_code}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
      </WebUI.FormFieldGroup>

      <WebUI.FormField error={formik.errors.address?.country}>
        <WebUI.Select
          name="address.country"
          value={formik.values.address.country}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        >
          <option value="">Country</option>
          <option value="United States">United States</option>
          <option value="Canada">Canada</option>
        </WebUI.Select>
      </WebUI.FormField>

      <WebUI.FormFieldGroup>
        <WebUI.FormField error={formik.errors.bankAccount?.accountNumber}>
          <WebUI.Input
            name="bankAccount.accountNumber"
            placeholder="Bank Account Number"
            value={formik.values.bankAccount.accountNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
        <WebUI.FormField error={formik.errors.bankAccount?.routingNumber}>
          <WebUI.Input
            name="bankAccount.routingNumber"
            placeholder="Bank Routing"
            value={formik.values.bankAccount.routingNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
      </WebUI.FormFieldGroup>

      <WebUI.HStack className="gap-2">
        <WebUI.Button loading={formik.isSubmitting} type="submit">
          Send Welcome Email
        </WebUI.Button>
      </WebUI.HStack>
    </WebUI.Form>
  )
}

export default AddMemberForm
