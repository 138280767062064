import * as Util from '@cheddarup/util'
import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {getDayOfWeek, getLocalTimeZone, today} from '@internationalized/date'

import RecurringOptionsStartFieldDropdown from './RecurringOptionsStartFieldDropdown'
import type {RecurringItemFormFormik} from '../../../../containers/RecurringItemForm/RecurringItemForm'

export interface RecurringOptionsStartFieldProps
  extends React.ComponentPropsWithoutRef<'div'> {
  formik: RecurringItemFormFormik
}

const RecurringOptionsStartField = ({
  formik,
  className,
  ...restProps
}: RecurringOptionsStartFieldProps) => {
  const start = formik.values.options.recurring.options.start
  const repeatPeriod = Util.parseDurationWeird(
    formik.values.options.recurring.options.repeatInterval,
  ).asMonths
    ? 'month'
    : 'week'

  return (
    <WebUI.HStack
      className={WebUI.cn('relative items-center', className)}
      {...restProps}
    >
      <RecurringOptionsStartFieldDropdown
        className={start.type === 'date' ? 'w-2/5' : 'w-full'}
        repeatPeriod={repeatPeriod}
        value={start}
        onChange={(newStart) =>
          formik.setFieldValue('options.recurring.options.start', newStart)
        }
      />
      {start.type === 'date' && (
        <WebUI.HStack className={'w-3/5 items-center gap-1 border-l px-2'}>
          <WebUI.Text className="text-ds-xs">
            Beginning no earlier than
          </WebUI.Text>
          <WebUI.DatePicker
            className="[&_>_.DateInput]:pointer-events-none [&_>_.DateInput]:shadow-none"
            minValue={today(getLocalTimeZone())}
            isDateUnavailable={(date) =>
              repeatPeriod === 'month'
                ? date.day !== start.date?.day
                : !!start.date &&
                  getDayOfWeek(date, 'en-US') !==
                    getDayOfWeek(start.date, 'en-US')
            }
            value={start.date ?? null}
            onValueChange={(newValue) =>
              formik.setFieldValue('options.recurring.options.start', {
                type: 'date',
                date: newValue,
              })
            }
          />
        </WebUI.HStack>
      )}
    </WebUI.HStack>
  )
}

export default RecurringOptionsStartField
