import axios from 'axios'
import {makeCheddarUpApiFetcher} from '@cheddarup/api-client'
import {getAuthToken} from 'src/hooks/useAuthToken'
import {useManagerRoleId} from 'src/components/ManageRoleProvider'

import {
  handleGetSession,
  handleLogout,
  handlePostLogin,
  handlePostPaymentMethods,
  handlePostSignup,
} from './analytics'

export const apiClient = axios.create({
  baseURL: `${import.meta.env.REACT_APP_API_PREFIX || '/'}api/`,
  withCredentials: true,
})

export const requestApi = makeCheddarUpApiFetcher({
  apiHost: `${import.meta.env.REACT_APP_API_PREFIX || '/'}api/`,
  baseFetcher: async (url, options) => {
    const authToken = getAuthToken()

    const res = await axios(url, {
      ...options,
      data: options?.body,
      withCredentials: true,
      headers: {
        ...(options.headers || {}),
        Authorization: authToken?.token
          ? `Bearer ${authToken?.token}`
          : options.headers?.Authorization,
      },
    })
    if (
      (!options.method || options.method === 'GET') &&
      options.path === 'session'
    ) {
      handleGetSession(res.data as Api.Session)
    } else if (options.method === 'POST' && options.path === 'login') {
      handlePostLogin(res.data?.session as Api.Session)
    } else if (options.method === 'POST' && options.path === 'signup') {
      handlePostSignup(res.data?.session as Api.Session)
    } else if (options.method === 'DELETE' && options.path === 'logout') {
      handleLogout()
    } else if (
      options.method === 'POST' &&
      options.path === 'users/payment_methods'
    ) {
      handlePostPaymentMethods()
    }

    return res.data
  },
})

export function useApiHeaders() {
  const [managerRoleId] = useManagerRoleId()
  return {
    'X-Manager-Account-Id': managerRoleId,
  }
}
