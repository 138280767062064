import {useNavigate} from 'react-router-dom'
import * as Yup from 'yup'
import * as WebUI from '@cheddarup/web-ui'
import React, {useMemo} from 'react'
import {useFormik} from '@cheddarup/react-util'
import {
  api,
  useCreateGroupPageSectionMutation,
  useDeleteGroupPageTeamMemberMutation,
  useUpdateGroupPageSectionMutation,
  useUpdateGroupPageTeamMemberMutation,
} from '@cheddarup/api-client'
import {LinkButton} from 'src/components/LinkButton'
import {SharpAvatar} from 'src/components/SharpAvatar'
import * as Util from '@cheddarup/util'
import {GroupPageSectionHeader} from '../components'
import {useUserSlug} from 'src/components/ManageRoleProvider'

interface TeamSectionFormValues {
  headline: string
  description: string
  background_color?: Api.BrandKitColorKey
}

const TeamSectionPage = () => {
  const navigate = useNavigate()
  const userSlug = useUserSlug()
  const {data: teamSection} = api.groupPageSections.detail.useQuery({
    pathParams: {
      sectionName: 'meet_the_team',
    },
  })
  const createGroupPageSectionMutation = useCreateGroupPageSectionMutation()
  const updateGroupPageSectionMutation = useUpdateGroupPageSectionMutation()
  const updateGroupPageTeamMemberMutation =
    useUpdateGroupPageTeamMemberMutation()

  const sortedTeamMembers = useMemo(
    () => Util.sort(teamSection?.team_members ?? []).asc((tm) => tm.position),
    [teamSection?.team_members],
  )

  const formik = useFormik<TeamSectionFormValues>({
    enableReinitialize: true,
    initialValues: {
      background_color: teamSection?.background_color ?? 'neutral',
      headline: teamSection?.headline ?? '',
      description: teamSection?.description ?? '',
    },
    validationSchema: Yup.object().shape({
      headline: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      const saveGroupPageSectionMutation = teamSection
        ? updateGroupPageSectionMutation
        : createGroupPageSectionMutation
      const savedSection = await saveGroupPageSectionMutation.mutateAsync({
        pathParams: {sectionName: 'meet_the_team'},
        body: {
          ...values,
          userSlug,
        },
      })
      return savedSection
    },
  })

  return (
    <WebUI.Modal
      aria-label="Group page team section form"
      className="[&_>_.ModalContentView]:h-full [&_>_.ModalContentView]:max-w-screen-xl"
      initialVisible
      onDidHide={() => navigate('..')}
    >
      {(dialog) => (
        <>
          <WebUI.ModalCloseButton />
          <WebUI.ModalHeader>
            <GroupPageSectionHeader
              subheading="Add a personal touch to your Group Page by adding the names,
                  titles and contact info of your team members."
              quickTourSlideId="team"
            >
              Meet the Team Section
            </GroupPageSectionHeader>
          </WebUI.ModalHeader>
          <div className="flex grow flex-col gap-6 overflow-y-auto px-9 py-6">
            <form
              className="flex max-w-96 flex-col gap-6"
              onSubmit={formik.handleSubmit}
              onReset={formik.handleReset}
            >
              <WebUI.FormField
                label="Section Header"
                error={formik.errors.headline}
                required
              >
                <WebUI.Input
                  name="headline"
                  placeholder="Large headline text"
                  value={formik.values.headline}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </WebUI.FormField>
              <WebUI.FormField label="Description">
                <WebUI.Textarea
                  name="description"
                  placeholder="Smaller text under headline"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  rows={3}
                />
              </WebUI.FormField>
            </form>
            <WebUI.Separator />
            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-1">
                <WebUI.Text>Add team members</WebUI.Text>
                <WebUI.Text className="font-light text-ds-sm">
                  Click and drag to reorder.
                </WebUI.Text>
              </div>
              <div className="flex flex-row flex-wrap gap-5">
                <WebUI.DragAndDrop
                  dragOverlayPortal={false}
                  modifiers={[WebUI.followMouseModifier]}
                  onDragEnd={(event) => {
                    if (!event.over) {
                      return
                    }
                    const oldOrder =
                      event.over.data.current?.sortable.items ?? []
                    const newOrder = WebUI.arrayMoveByValue(
                      oldOrder,
                      event.active.id,
                      event.over.id,
                    )
                    const teamMemberId = event.active.id
                    const teamMemberNewPos = newOrder.indexOf(teamMemberId) + 1
                    updateGroupPageTeamMemberMutation.mutate({
                      pathParams: {id: teamMemberId},
                      body: {position: teamMemberNewPos},
                    })
                  }}
                >
                  <WebUI.SortableContext
                    items={sortedTeamMembers}
                    strategy={WebUI.rectSortingStrategy}
                  >
                    {({items: tmIds}) =>
                      tmIds.map((tmId) => {
                        const tm = sortedTeamMembers.find(
                          (tm) => tm.id === tmId,
                        )

                        return tm ? (
                          <TeamMemberCard
                            key={tmId}
                            id={tmId}
                            teamMember={tm}
                          />
                        ) : null
                      })
                    }
                  </WebUI.SortableContext>
                </WebUI.DragAndDrop>

                <AddTeamMemberCta
                  className="cursor-pointer"
                  role="button"
                  onClick={async () => {
                    let savedSection = teamSection
                    if (!savedSection) {
                      savedSection = await formik.submitForm()
                    }
                    if (savedSection) {
                      navigate('add-member')
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-end border-t border-t-borderPrimary border-r-0 border-b-0 border-l-0 border-solid bg-natural-100 px-4 py-5">
            <WebUI.Button
              type="submit"
              variant="primary"
              size="large"
              className="w-48"
              loading={formik.isSubmitting}
              onClick={async () => {
                await formik.submitForm()
                dialog.hide()
              }}
            >
              Save
            </WebUI.Button>
          </div>
        </>
      )}
    </WebUI.Modal>
  )
}

// MARK: - TeamMemberCard

interface TeamMemberCardProps
  extends Util.Merge<
    React.ComponentPropsWithoutRef<'div'>,
    Pick<WebUI.SortableProps, 'id'>
  > {
  teamMember: Api.GroupPageTeamMember
}

const TeamMemberCard: React.FC<TeamMemberCardProps> = ({
  teamMember,
  ...restProps
}) => {
  const deleteGroupPageTeamMemberMutation =
    useDeleteGroupPageTeamMemberMutation()

  return (
    <WebUI.Sortable
      draggable={false}
      className="w-full sm:w-auto"
      {...restProps}
    >
      {({dragListeners}) => (
        <WebUI.Card
          className="flex w-full flex-row gap-4 px-7 py-6 sm:w-96"
          dragHandleVisible
          dragListeners={dragListeners}
          accessoryView={
            <WebUI.ActionGroup>
              <WebUI.Action
                icon={
                  <WebUI.PhosphorIcon
                    icon="x"
                    onClick={async () => {
                      await deleteGroupPageTeamMemberMutation.mutateAsync({
                        pathParams: {id: teamMember.id},
                      })
                    }}
                  />
                }
                loading={deleteGroupPageTeamMemberMutation.isPending}
              >
                Delete
              </WebUI.Action>
              <WebUI.Action
                icon={<WebUI.PhosphorIcon icon="pencil" />}
                as={LinkButton}
                to={`members/${teamMember.id}`}
              >
                Edit
              </WebUI.Action>
            </WebUI.ActionGroup>
          }
        >
          <SharpAvatar
            size={60}
            image={teamMember.profile_picture}
            name={teamMember.name}
          />
          <div className="flex flex-col gap-1">
            <WebUI.Text>{teamMember.name}</WebUI.Text>
            <WebUI.Text className="font-light text-ds-sm">
              {teamMember.title}
            </WebUI.Text>
          </div>
        </WebUI.Card>
      )}
    </WebUI.Sortable>
  )
}

// MARK: - AddTeamMemberCta

interface AddTeamMemberCta extends React.ComponentPropsWithoutRef<'div'> {}

const AddTeamMemberCta: React.FC<AddTeamMemberCta> = ({
  className,
  ...restProps
}) => (
  <WebUI.Panel
    className={WebUI.cn(
      'flex w-96 flex-row items-center gap-6 px-7 py-6',
      className,
    )}
    {...restProps}
  >
    <WebUI.IconButton
      className="h-15 w-15 rounded-full"
      size="default_alt"
      variant="default"
    >
      <WebUI.PhosphorIcon
        className="text-ds-2xl text-natural-100"
        icon="plus-bold"
      />
    </WebUI.IconButton>
    <WebUI.Text className="text-ds-sm">Add team member</WebUI.Text>
  </WebUI.Panel>
)

export default TeamSectionPage
