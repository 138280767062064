import * as WebUI from '@cheddarup/web-ui'

export const RecurringPaymentIndicator = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => (
  <span
    className={WebUI.cn(
      'flex items-center gap-2 font-normal text-ds-sm text-gray750',
      className,
    )}
    {...restProps}
  >
    <WebUI.PhosphorIcon
      className="text-ds-base text-orange-50"
      icon="arrows-clockwise"
    />
    <span>This is a recurring payment.</span>
  </span>
)
