import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {bankAccountSchema, creditCardSchema} from '../schemas'

export default {
  list: makeEndpoint({
    path: 'users/external_accounts',
    responseSchema: z.object({
      banks: bankAccountSchema.array(),
      cards: creditCardSchema.array(),
    }),
  }),
  create: makeEndpoint({
    path: 'users/external_accounts',
    method: 'POST',
    bodySchema: z.object({
      source: z.string(),
      nickname: z.string().optional(),
      default: z.boolean().optional(),
      security: z
        .object({
          token: z.string(),
        })
        .optional(),
    }),
    responseSchema: z.union([bankAccountSchema, creditCardSchema]),
  }),
  update: makeEndpoint({
    path: 'users/external_accounts/:accountId',
    method: 'PATCH',
    bodySchema: z.object({
      default: z.boolean().optional(),
      security: z.object({
        token: z.string(),
      }),
    }),
    responseSchema: z.union([bankAccountSchema, creditCardSchema]),
  }),
  remove: makeEndpoint({
    path: 'users/external_accounts/:accountId/remove',
    method: 'POST',
    responseSchema: z.any(),
  }),
}
