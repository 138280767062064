export const parseTimeSlotFieldViews = <
  T extends {
    metadata: Pick<Api.TabObjectFieldView['metadata'], 'timeSlotFieldType'>
  },
>(
  fieldViews: T[],
) => {
  const firstNameFieldView = fieldViews.find(
    (ifv) => ifv.metadata.timeSlotFieldType === 'first_name',
  )
  const lastNameFieldView = fieldViews.find(
    (ifv) => ifv.metadata.timeSlotFieldType === 'last_name',
  )
  const commentFieldView = fieldViews.find(
    (ifv) => ifv.metadata.timeSlotFieldType === 'comment',
  )

  return {firstNameFieldView, lastNameFieldView, commentFieldView}
}

export const getTimeSlotFieldViewsFormatted = <
  T extends {
    value: Api.TabObjectFieldView['value']
    metadata: Pick<Api.TabObjectFieldView['metadata'], 'timeSlotFieldType'>
  },
>(
  fieldViews: T[],
) => {
  const timeSlotFieldViews = parseTimeSlotFieldViews(fieldViews)

  return {
    firstName: timeSlotFieldViews.firstNameFieldView?.value,
    lastName: timeSlotFieldViews.lastNameFieldView?.value,
    name: `${timeSlotFieldViews.firstNameFieldView?.value ?? ''} ${
      timeSlotFieldViews.lastNameFieldView?.value ?? ''
    }`.trim(),
    comment: timeSlotFieldViews.commentFieldView?.value ?? '',
  }
}
