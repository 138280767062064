import * as WebUI from '@cheddarup/web-ui'
import {forwardRef} from 'react'
import * as Util from '@cheddarup/util'

import {NavLinkProps} from './Link'
import {NavLinkButton} from './LinkButton'

export interface SideNavNavLinkButtonProps
  extends Util.Merge<NavLinkProps, WebUI.SideNavButtonProps> {}

export const SideNavNavLinkButton = forwardRef<
  HTMLAnchorElement,
  SideNavNavLinkButtonProps
>(({className, ...restProps}, forwardedRef) => (
  <WebUI.SideNavButton
    ref={forwardedRef}
    className={WebUI.cn(
      'SideNavNavLinkButton',
      'font-medium transition-colors aria-current-page:bg-teal-80',
      className,
    )}
    as={NavLinkButton}
    {...restProps}
  />
))
