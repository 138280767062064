import {useEffect, useRef} from 'react'

import {useLiveRef} from './useLiveRef'

export const useTimeout = (callback: () => void, delay: number) => {
  const timeoutIdRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const callbackRef = useLiveRef(callback)

  useEffect(() => {
    if (delay < 0) {
      return () => {}
    }

    function tick() {
      callbackRef.current()
    }

    const id = setTimeout(tick, delay)

    timeoutIdRef.current = id

    return () => clearTimeout(id)
  }, [delay])

  return timeoutIdRef.current
}
