import {useLocation, useNavigate} from 'react-router-dom'
import React, {useEffect, useLayoutEffect, useState} from 'react'
import queryString from 'query-string'
import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'
import {api} from '@cheddarup/api-client'
import {AppHeader} from 'src/components/AppHeader'
import {LinkButton} from 'src/components/LinkButton'
import {MarketplaceHeading, SearchForm} from 'src/components'
import {NavLink} from 'src/components/Link'

type SearchBy = 'item' | 'marketplace'

export interface MarketplacePageContainerProps
  extends React.ComponentPropsWithoutRef<'div'> {
  filterSortElement?: React.ReactNode
  initialSearchType?: SearchBy
}

const MarketplacePageContainer = ({
  filterSortElement,
  initialSearchType,
  className,
  children,
  ...restProps
}: MarketplacePageContainerProps) => {
  const media = WebUI.useMedia()
  const navigate = useNavigate()
  const location = useLocation()
  const {data: session} = api.auth.session.useSuspenseQuery()
  const organizationData = session.organization_data

  useLayoutEffect(() => {
    const styleEl = document.createElement('style')
    document.head.append(styleEl)

    const styleSheet = styleEl.sheet

    const ruleIdx = styleSheet?.insertRule('body * { z-index: 1; }', 0)

    return () => {
      if (ruleIdx != null) {
        styleSheet?.deleteRule(ruleIdx)
      }

      styleEl.remove()
    }
  }, [])

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!organizationData || !organizationData.internalMarketplace?.enabled) {
      navigate('/collections')
    }
  }, [organizationData])

  return (
    <WebUI.VStack className={WebUI.cn('grow', className)} {...restProps}>
      {media.sm ? <AppHeader className="border-b" /> : null}
      <MarketplaceTabBar
        className={'sticky top-0 right-0 left-0 border-b'}
        filterSortElement={filterSortElement}
        initialSearchBy={
          (queryString.parse(location.search).searchBy as any) ||
          initialSearchType
        }
        onSearch={({searchBy, term}) => {
          navigate({
            pathname:
              searchBy === 'item'
                ? '/marketplace/shop-items'
                : '/marketplace/sales',
            search: term
              ? queryString.stringify({
                  searchBy,
                  searchKeyword: term,
                })
              : '',
          })
        }}
      />
      <WebUI.VStack className="z-0 grow">{children}</WebUI.VStack>
    </WebUI.VStack>
  )
}

// MARK: – MarketplaceTabBar

interface MarketplaceTabBarProps {
  className?: string
  initialSearchBy: SearchBy
  filterSortElement: React.ReactNode
  onSearch: (values: {term: string; searchBy: SearchBy}) => void
}

const MarketplaceTabBar = ({
  initialSearchBy,
  filterSortElement,
  onSearch,
  className,
}: MarketplaceTabBarProps) => {
  const media = WebUI.useMedia()
  const {data: marketplaceName} = api.auth.session.useQuery(undefined, {
    select: (session) =>
      session.organization_data?.internalMarketplace?.organizerNickname ?? '',
  })

  const navigationLinks = [
    {
      title: 'Search Items',
      to: '/marketplace/shop-items',
    },
    {
      title: 'Search Sales',
      to: '/marketplace/sales',
    },
  ]

  return media.lg ? (
    <MarketplaceTabBarContent
      className={WebUI.cn('h-16 bg-natural-100 px-4', className)}
      filterSortElement={filterSortElement}
      initialSearchBy={initialSearchBy}
      marketplaceName={marketplaceName ?? ''}
      navigationLinks={navigationLinks}
      onSubmitSearchForm={onSearch}
    />
  ) : (
    <MarketplaceTabBarContentMobile
      className={WebUI.cn('h-16 bg-natural-100', className)}
      filterSortElement={filterSortElement}
      initialSearchBy={initialSearchBy}
      marketplaceName={marketplaceName ?? ''}
      navigationLinks={navigationLinks}
      onSubmitSearchForm={onSearch}
    />
  )
}

// MARK: – MarketplaceTabBarContent

interface MarketplaceTabBarContentProps
  extends React.ComponentPropsWithoutRef<'div'> {
  initialSearchBy: SearchBy
  marketplaceName: string
  navigationLinks: Array<{to: string; title: React.ReactNode}>
  onSubmitSearchForm: (values: {term: string; searchBy: SearchBy}) => void
  filterSortElement: React.ReactNode
}

const MarketplaceTabBarContent = ({
  initialSearchBy,
  marketplaceName,
  navigationLinks,
  onSubmitSearchForm,
  filterSortElement,
  className,
  ...restProps
}: MarketplaceTabBarContentProps) => (
  <WebUI.HStack
    className={WebUI.cn('justify-between', className)}
    {...restProps}
  >
    <WebUI.HStack className="flex-[0_0_10%]">{filterSortElement}</WebUI.HStack>
    <WebUI.HStack className="shrink-0 grow-0 basis-1/2 justify-center">
      <MarketplaceTabBarNav navigationLinks={navigationLinks} />
    </WebUI.HStack>
    <MarketplaceSearchBar
      className="flex-[0_0_30%]"
      initialSearchBy={initialSearchBy}
      marketplaceName={marketplaceName}
      onSubmit={onSubmitSearchForm}
    />
  </WebUI.HStack>
)

// MARK: – MarketplaceTabBarContentMobile

interface MarketplaceTabBarContentMobileProps
  extends React.ComponentPropsWithoutRef<'div'> {
  filterSortElement: React.ReactNode
  initialSearchBy: SearchBy
  navigationLinks: Array<{to: string; title: React.ReactNode}>
  marketplaceName: string
  onSubmitSearchForm: (values: {term: string; searchBy: SearchBy}) => void
}

const MarketplaceTabBarContentMobile = ({
  filterSortElement,
  initialSearchBy,
  navigationLinks,
  marketplaceName,
  onSubmitSearchForm,
  className,
  ...restProps
}: MarketplaceTabBarContentMobileProps) => {
  const [searchBarVisible, setSearchBarVisible] = useState(false)
  return (
    <div
      className={WebUI.cn('relative h-16 bg-natural-100', className)}
      {...restProps}
    >
      {searchBarVisible ? (
        <WebUI.HStack className="h-full items-center gap-4">
          <WebUI.IconButton
            className="text-ds-lg"
            size="default_alt"
            onClick={() => setSearchBarVisible(false)}
          >
            <WebUI.PhosphorIcon icon="caret-left" />
          </WebUI.IconButton>
          <MarketplaceSearchBar
            initialSearchBy={initialSearchBy}
            marketplaceName={marketplaceName}
            onSubmit={onSubmitSearchForm}
          />
        </WebUI.HStack>
      ) : (
        <WebUI.NavigationBar
          left={
            <WebUI.Menu>
              <WebUI.MenuButton
                className="text-ds-lg"
                as={WebUI.IconButton}
                size="default_alt"
              >
                <WebUI.PhosphorIcon icon="list" />
              </WebUI.MenuButton>
              <WebUI.MenuList>
                {navigationLinks.map((link) => (
                  <WebUI.MenuItem key={link.to} as={LinkButton} to={link.to}>
                    {link.title}
                  </WebUI.MenuItem>
                ))}

                <WebUI.MenuSeparator />

                <WebUI.MenuItem as={LinkButton} to="/marketplace/my-sales">
                  My Sales
                </WebUI.MenuItem>
                <WebUI.MenuItem as={LinkButton} to="/contact">
                  Support
                </WebUI.MenuItem>
                <WebUI.MenuItem as={LinkButton} to="/collections">
                  Create a Sale
                </WebUI.MenuItem>
              </WebUI.MenuList>
            </WebUI.Menu>
          }
          center={<MarketplaceHeading />}
          right={
            <WebUI.IconButton
              className="text-ds-lg"
              size="default_alt"
              onClick={() => setSearchBarVisible(true)}
            >
              <WebUI.PhosphorIcon icon="magnifying-glass" />
            </WebUI.IconButton>
          }
        />
      )}
      <WebUI.HStack className="absolute right-2 pt-2">
        {filterSortElement}
      </WebUI.HStack>
    </div>
  )
}

// MARK: – MarketplaceSearchBar

interface MarketplaceSearchBarProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'onSubmit'> {
  initialSearchBy: SearchBy
  marketplaceName: string
  onSubmit: (values: {term: string; searchBy: SearchBy}) => void
}

const MarketplaceSearchBar = ({
  initialSearchBy = 'item',
  marketplaceName,
  onSubmit,
  className,
  ...restProps
}: MarketplaceSearchBarProps) => {
  const [searchBy, setSearchBy] = useState<SearchBy>(initialSearchBy)
  return (
    <WebUI.HStack
      className={WebUI.cn(
        'max-w-[256px] items-center justify-end gap-2',
        className,
      )}
      {...restProps}
    >
      <WebUI.DropdownSelect<SearchBy>
        size="compact"
        value={searchBy}
        onValueChange={(newSearchBy) => {
          if (newSearchBy) {
            setSearchBy(newSearchBy)
          }
        }}
      >
        <WebUI.DropdownSelectOption value="item">
          Items
        </WebUI.DropdownSelectOption>
        <WebUI.DropdownSelectOption value="marketplace">
          {Util.pluralize(marketplaceName)}
        </WebUI.DropdownSelectOption>
      </WebUI.DropdownSelect>

      <SearchForm
        placeholder={`${
          searchBy === 'marketplace' ? marketplaceName : 'Item'
        } Name`}
        initialValues={{term: ''}}
        onSubmit={(values) => onSubmit({...values, searchBy})}
      />
    </WebUI.HStack>
  )
}

// MARK: – MarketplaceTabBarNav

interface MarketplaceTabBarNavProps
  extends React.ComponentPropsWithoutRef<'div'> {
  navigationLinks: Array<{to: string; title: React.ReactNode}>
}

const MarketplaceTabBarNav = ({
  navigationLinks,
  className,
  ...restProps
}: MarketplaceTabBarNavProps) => (
  <WebUI.HStack className={WebUI.cn('gap-10', className)} {...restProps}>
    {navigationLinks.map((navigationLink) => (
      <NavLink
        key={navigationLink.to}
        className={
          'h-full aria-current-page:border-primary aria-current-page:border-b-4 aria-current-page:pt-1 aria-current-page:text-inherit [&_>_.Anchor-content]:text-center [&_>_.Anchor-content]:font-bold [&_>_.Anchor-content]:text-ds-sm'
        }
        to={navigationLink.to}
      >
        {navigationLink.title}
      </NavLink>
    ))}
  </WebUI.HStack>
)

export default MarketplacePageContainer
