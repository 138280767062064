import {Outlet, Route} from 'react-router-dom'
import ForgotPasswordPage from 'src/views/onboard/login/ForgotPasswordPage'
import InvitationsPage from 'src/views/InvitationsPage'
import LoginPage from 'src/views/onboard/login/LoginPage'
import LogoutPage from 'src/views/LogoutPage'
import OnboardSignUpAsManagerPage from 'src/views/onboard/signup-as-manager'
import OnboardSignUpPage from 'src/views/onboard/signup'
import OrgSignUpPage from 'src/views/OrgSignUpPage'
import ResetPasswordPage from 'src/views/onboard/login/ResetPasswordPage'
import SignUpViaPosCodePage from 'src/views/onboard/SignUpViaPosCodePage'
import {LoginViaTokenPage} from 'src/views/LoginViaTokenPage'
import {EnsureAuth} from 'src/hooks/useAuthToken'

export const authRoutes = [
  <Route key="logout" path="logout" element={<LogoutPage />} />,
  <Route
    key="invitations/manager/account/:manager/:invitation"
    path="invitations/manager/account/:manager/:invitation"
    element={
      <EnsureAuth redirectTo="/signup-as-manager">
        <InvitationsPage />
      </EnsureAuth>
    }
  />,
  <Route
    key="signup"
    path="signup"
    element={
      <EnsureAuth requireAuth={false} redirectTo="/collections">
        <OnboardSignUpPage />
      </EnsureAuth>
    }
  />,
  <Route
    key="signup-as-manager"
    path="signup-as-manager"
    element={
      <EnsureAuth requireAuth={false}>
        <OnboardSignUpAsManagerPage />
      </EnsureAuth>
    }
  />,
  <Route
    key="orgs/:org/signup"
    path="orgs/:org/signup"
    element={
      <EnsureAuth requireAuth={false}>
        <OrgSignUpPage />
      </EnsureAuth>
    }
  />,
  <Route
    key="sign-up-via-pos-code"
    path="sign-up-via-pos-code"
    element={<SignUpViaPosCodePage />}
  />,
  <Route
    key="login"
    path="login"
    element={
      <EnsureAuth requireAuth={false}>
        <Outlet />
      </EnsureAuth>
    }
  >
    <Route path="" element={<LoginPage />} />
    <Route path="forgot-password" element={<ForgotPasswordPage />} />
    <Route path="forgot-password/reset" element={<ResetPasswordPage />} />
  </Route>,
  <Route
    key="login/token"
    path="login/token"
    element={<LoginViaTokenPage />}
  />,
]
