import {useLocation, useNavigate, useParams} from 'react-router-dom'
import queryString from 'query-string'
import React, {useMemo, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {InferResponse, api, endpoints} from '@cheddarup/api-client'
import * as Util from '@cheddarup/util'
import {Link} from 'src/components/Link'
import {LinkButton} from 'src/components/LinkButton'
import JSZip from 'jszip'
import {apiClient} from 'src/helpers/client'

const FORMS_PER_PAGE = 25

const CollectionManageFormsSignupsTab: React.FC<
  React.ComponentPropsWithoutRef<'div'>
> = (props) => {
  const media = WebUI.useMedia()
  const urlParams = useParams()
  const navigate = useNavigate()
  const tabFormsStatsQuery = api.tabPayments.formsAndSignups.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: urlParams.collection!,
    },
    queryParams: {
      type: 'TabForm',
    },
  })
  const signupsStatsQuery = api.tabPayments.formsAndSignups.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: urlParams.collection!,
    },
    queryParams: {
      type: 'Signup',
    },
  })

  const tableData = useMemo(() => {
    const formsTableData =
      tabFormsStatsQuery.data
        ?.filter((tfs) => tfs.item_field_views_count > 0)
        .map((tfs) => ({
          ...tfs,
          fileFieldViews: tfs.item_field_views.filter(
            (ifv) => ifv.field_type === 'file',
          ),
        })) ?? []

    const signupsTableData = signupsStatsQuery.data ?? []

    return [...formsTableData, ...signupsTableData]
  }, [tabFormsStatsQuery.data, signupsStatsQuery.data])

  const hasFileFieldViews = tableData.some(
    (f) =>
      'fileFieldViews' in f &&
      Array.isArray(f.fileFieldViews) &&
      f.fileFieldViews.length > 0,
  )

  const columnHelper = useMemo(
    () => WebUI.createColumnHelper<NonNullable<typeof tableData>[number]>(),
    [],
  )

  const columns = useMemo(
    () =>
      [
        columnHelper.accessor((f) => f.name, {
          id: 'name',
          size: media.sm ? 400 : 240,
          meta: {
            subtle: false,
          },
          header: 'Name',
          cell: ({cell, row}) => (
            <Link
              className="max-w-[220px] sm:max-w-[384px]"
              variant="primary"
              to={
                row.original.options.signupType == null
                  ? `forms/${row.original.id}`
                  : `signups/${row.original.id}`
              }
            >
              <WebUI.Ellipsis>{cell.getValue()}</WebUI.Ellipsis>
            </Link>
          ),
        }),
        columnHelper.accessor(
          (f) =>
            f.options.isWaiver
              ? 'Waiver'
              : f.options.signupType == null
                ? 'Form'
                : 'Sign Up',
          {
            id: 'type',
            header: 'Type',
          },
        ),
        hasFileFieldViews
          ? columnHelper.accessor(
              (f) => ('fileFieldViews' in f ? f.fileFieldViews : []),
              {
                id: 'fileUploads',
                header: 'File Uploads',
                cell: ({row, cell}) => (
                  <RemoteFilesStack
                    formName={row.original.name}
                    fileFieldViews={cell.getValue() as any}
                  />
                ),
              },
            )
          : undefined,
        columnHelper.accessor((f) => f.respondents, {
          id: 'respondentsCount',
          meta: {
            align: 'center',
          },
          header: 'Respondents',
        }),
        columnHelper.display({
          id: 'actions',
          minSize: 44,
          size: 44,
          meta: {
            align: 'center',
          },
          cell: ({row}) =>
            row.original.options.signupType == null ? (
              <FormActionsDropdown
                className="mx-3 text-ds-sm"
                form={row.original}
                onEdit={() => {
                  navigate(`forms/${row.original.id}/edit`)
                }}
              />
            ) : (
              <SignupsActionsDropdown
                className="mx-3 text-ds-sm"
                signup={row.original}
              />
            ),
        }),
      ].filter((c) => c != null),
    [columnHelper, hasFileFieldViews, media.sm, navigate],
  )

  const initialPaginationState = useMemo(
    () => ({
      pageSize: FORMS_PER_PAGE,
      pageIndex: 0,
    }),
    [],
  )

  const pageCount = Math.ceil(tableData.length / FORMS_PER_PAGE)

  return (
    <WebUI.Panel {...props}>
      <WebUI.HStack className={'items-center gap-4 border-b px-8 py-4'}>
        <WebUI.Text className="text-ds-sm">
          Total: {tableData.length ?? 0}
        </WebUI.Text>
      </WebUI.HStack>

      <WebUI.TableView
        className={
          '[&_.TableView-headerGroup]:px-0 sm:[&_.TableView-headerGroup]:px-4 [&_.TableViewCell_>_.Stack]:py-0 [&_.TableViewRow]:px-0 sm:[&_.TableViewRow]:px-4'
        }
        columns={columns}
        data={tableData}
        initialState={{pagination: initialPaginationState}}
        pageCount={pageCount}
      >
        {pageCount > 1 && (
          <WebUI.HStack className="justify-end px-8 py-4">
            <WebUI.TablePaginator />
          </WebUI.HStack>
        )}
      </WebUI.TableView>
    </WebUI.Panel>
  )
}

// MARK: – FormActionsDropdown

interface FormActionsDropdownProps
  extends WebUI.MenuButtonProps,
    Omit<React.ComponentPropsWithoutRef<'button'>, 'form'> {
  form: InferResponse<typeof endpoints.tabPayments.formsAndSignups>[number]
  onEdit: () => void
}

const FormActionsDropdown: React.FC<FormActionsDropdownProps> = ({
  form,
  className,
  onEdit,
  ...restProps
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const {
    t: _t,
    formId: _formId,
    viewBy: _viewBy,
    ...restSearch
  } = queryString.parse(location.search)

  return (
    <WebUI.Menu>
      <WebUI.MenuButton
        className={WebUI.cn('rounded-full', className)}
        as={WebUI.IconButton}
        variant="secondary"
        {...restProps}
      >
        <WebUI.PhosphorIcon
          className="text-ds-xl text-gray400"
          icon="dots-three-outline-fill"
        />
      </WebUI.MenuButton>

      <WebUI.MenuList>
        <WebUI.MenuItem
          onClick={() =>
            navigate({
              pathname: `forms/${form.id}`,
              search: `${queryString.stringify(restSearch)}viewBy=respondents`,
            })
          }
        >
          View Responses
        </WebUI.MenuItem>
        {(form.respondents ?? 0) > 0 && (
          <WebUI.MenuItem
            as={LinkButton}
            target="_blank"
            to={`/pdf/collection/${form.tab_id}/form/field-views-report`}
          >
            {form.options.isWaiver
              ? 'Print Completed Waivers'
              : 'Print Completed Forms'}
          </WebUI.MenuItem>
        )}

        <WebUI.MenuItem onClick={onEdit}>
          {form.options.isWaiver ? 'Edit Waiver' : 'Edit Form'}
        </WebUI.MenuItem>
      </WebUI.MenuList>
    </WebUI.Menu>
  )
}

// MARK: - SignupsActionsDropdown

interface SignupsActionsDropdownProps
  extends WebUI.MenuButtonProps,
    React.ComponentPropsWithoutRef<'button'> {
  signup: InferResponse<typeof endpoints.tabPayments.formsAndSignups>[number]
}

const SignupsActionsDropdown: React.FC<SignupsActionsDropdownProps> = ({
  signup,
  className,
  ...restProps
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const {
    t: _t,
    signUpId: _signUpId,
    viewBy: _viewBy,
    ...restSearch
  } = queryString.parse(location.search)

  return (
    <WebUI.Menu>
      <WebUI.MenuButton
        className={WebUI.cn('rounded-full', className)}
        as={WebUI.IconButton}
        variant="secondary"
        {...restProps}
      >
        <WebUI.PhosphorIcon
          className="text-ds-xl text-gray400"
          icon="dots-three-outline-fill"
        />
      </WebUI.MenuButton>

      <WebUI.MenuList className="text-ds-sm">
        <WebUI.MenuItem
          as={Link}
          variant="default"
          preserveSearch
          to={`signups/${signup.id}/edit`}
        >
          Edit Sign Up
        </WebUI.MenuItem>
        <WebUI.MenuItem
          as={LinkButton}
          target="_blank"
          to={`/pdf/collection/${signup.tab_id}/signup/${signup.id}/report`}
        >
          Print Filled Spots
        </WebUI.MenuItem>
        <WebUI.MenuItem
          onClick={() =>
            navigate({
              pathname: `signups/${signup.id}`,
              search: `${queryString.stringify(
                restSearch,
              )}&t=responses&viewBy=date`,
            })
          }
        >
          View Responses
        </WebUI.MenuItem>
      </WebUI.MenuList>
    </WebUI.Menu>
  )
}

// MARK: – RemoteFilesStack

interface RemoteFilesStackProps extends React.ComponentPropsWithoutRef<'div'> {
  maxVisible?: number
  formName: string
  fileFieldViews: Array<Api.TabObjectFieldView & {tab_member: Api.TabMember}>
}

const RemoteFilesStack = ({
  maxVisible = 3,
  formName,
  fileFieldViews,
}: RemoteFilesStackProps) => {
  const [isAllDownloading, setIsAllDownloading] = useState(false)

  const visibleFileFieldViews = fileFieldViews.slice(0, maxVisible)
  const hiddenUrlsCount = fileFieldViews.length - visibleFileFieldViews.length

  return (
    <WebUI.HStack className="gap-2">
      <WebUI.Popover>
        {(popover) => (
          <>
            <WebUI.PopoverDisclosure as={WebUI.HStack} className="gap-2">
              {visibleFileFieldViews.map((fileFieldView) => (
                <WebUI.RemoteFileDisplay
                  key={fileFieldView.id}
                  size="compact"
                  url={fileFieldView.value}
                />
              ))}

              {hiddenUrlsCount > 0 && (
                <WebUI.Button
                  className="h-[30px] w-[30px] p-0 font-semibold text-teal-50 text-xs"
                  size="compact"
                  variant="secondary"
                >
                  {`+${hiddenUrlsCount}`}
                </WebUI.Button>
              )}
            </WebUI.PopoverDisclosure>
            <WebUI.PopoverContent className="max-w-[360px]" as={WebUI.Panel}>
              {fileFieldViews.length <= 20 && (
                <WebUI.HStack className="px-4 py-5">
                  <WebUI.Button
                    className="text-ds-sm"
                    loading={isAllDownloading}
                    variant="link"
                    onClick={async () => {
                      setIsAllDownloading(true)
                      try {
                        const res = await Promise.all(
                          fileFieldViews.map((fileFieldView) =>
                            apiClient.get(fileFieldView.value, {
                              responseType: 'blob',
                              timeout: 30000,
                              withCredentials: false,
                            }),
                          ),
                        )

                        const zip = new JSZip()
                        res.forEach((response, idx) => {
                          if (response.status < 200 || response.status >= 300) {
                            return
                          }

                          // biome-ignore lint/style/noNonNullAssertion:
                          const fileFieldView = fileFieldViews[idx]!
                          const blob = response.data as Blob
                          const fileName = response.config.url
                            ? Util.getFileNameFromUrl(response.config.url)
                            : 'file'

                          zip.file(
                            `${Util.kebabCase(fileFieldView.tab_member.name)}-${
                              fileName ?? 'file'
                            }`,
                            blob,
                          )
                        })

                        const zipBlob = await zip.generateAsync({type: 'blob'})

                        WebUI.downloadFile(
                          zipBlob,
                          `${Util.kebabCase(formName)}-file-uploads.zip`,
                        )

                        popover.hide()
                      } finally {
                        setIsAllDownloading(false)
                      }
                    }}
                  >
                    Download All
                  </WebUI.Button>
                </WebUI.HStack>
              )}
              <WebUI.Separator variant="primary" />
              <WebUI.VStack className="max-h-[192px] gap-4 overflow-y-auto overflow-x-hidden p-4">
                {fileFieldViews.map((fileFieldView) => (
                  <WebUI.RemoteFileOverview
                    key={fileFieldView.id}
                    url={fileFieldView.value}
                  />
                ))}
              </WebUI.VStack>
            </WebUI.PopoverContent>
          </>
        )}
      </WebUI.Popover>
    </WebUI.HStack>
  )
}

export default CollectionManageFormsSignupsTab
