import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'

import {FormFieldInlineLabelEdit} from './FormFieldInlineLabelEdit'
import type {FieldGroupFieldsEditProps} from './FieldGroupFieldsEdit'

export const CheckboxFieldsEdit = ({
  required,
  focused,
  initialValue,
  initialLabels,
  placeholders,
  onValueChange,
  onLabelChange,
}: Omit<FieldGroupFieldsEditProps<'checkbox'>, 'fieldGroupType'>) => (
  <FormFieldInlineLabelEdit
    labelInputFocused={focused}
    required={required}
    initialLabel={initialLabels.value}
    placeholder={placeholders.value}
    onLabelChange={(label) => onLabelChange('value', label)}
  >
    <WebUI.OptionsBuilder
      className={`[&_.DataListRow[data-rowfocused="true"]_&_.OptionBuilderRow-removeButton]:visible [&_.OptionBuilderRow-removeButton]:invisible`}
      defaultOptions={initialValue.map((v) => ({
        id: Util.makeShortId(),
        value: v,
      }))}
      onOptionsChange={(options) => onValueChange(options.map((o) => o.value))}
    />
  </FormFieldInlineLabelEdit>
)
