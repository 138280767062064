import {useFormik} from '@cheddarup/react-util'
import React from 'react'
import * as WebUI from '@cheddarup/web-ui'

import type {RecipientsModalRecipient} from './RecipientsModal'

export interface CopyAndPastePanelProps
  extends Omit<React.ComponentPropsWithoutRef<'form'>, 'onSubmit' | 'onReset'> {
  onAddRecipients: (recipients: RecipientsModalRecipient[]) => void
}

export const CopyAndPastePanel = ({
  onAddRecipients,
  ...restProps
}: CopyAndPastePanelProps) => {
  const formik = useFormik({
    initialValues: {
      text: '',
    },
    onSubmit: (values, formikHelpers) => {
      const newRecipients = values.text
        .split(/[\n\r,/[\\\]]/)
        .map((chunk): RecipientsModalRecipient | null => {
          const match = chunk.match(/(?:("?.*"?)\s?)?<(.*@.*)>|(.*@.*)/)
          if (!match) {
            return null
          }

          if (match[1] || match[2]) {
            return {
              name: match[1]?.trim() ?? '',
              email: match[2]?.trim() ?? '',
            }
          }

          return {
            name: chunk.split('@')?.[0] ?? '',
            email: chunk.trim().toLowerCase(),
          }
        })
        .filter((r) => !!r)

      onAddRecipients(newRecipients)

      formikHelpers.resetForm()
    },
  })

  return (
    <WebUI.Form
      onSubmit={formik.handleSubmit}
      onReset={formik.handleReset}
      {...restProps}
    >
      <WebUI.VStack className="gap-4">
        <WebUI.Heading as="h4">
          Copy and paste recipient's email separated by commas or line breaks.
        </WebUI.Heading>
        <WebUI.VStack className="text-ds-sm">
          <span>Recipients must entered in one of these two formats:</span>
          <span className="italic">{'Name<name@email.com>'}</span>
          <span className="italic">name@email.com</span>
        </WebUI.VStack>
      </WebUI.VStack>
      <WebUI.Textarea
        className="resize-y text-ds-sm"
        name="text"
        placeholder="Copy and paste a list of emails separated by commas or line breaks"
        value={formik.values.text}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        rows={8}
      />

      <WebUI.Button className="self-start" type="submit">
        Add
      </WebUI.Button>
    </WebUI.Form>
  )
}
