import React, {useEffect} from 'react'
import {useLiveRef} from '@cheddarup/react-util'

import {getScrollingElement} from '../utils'

export function useScrollToTop(
  options: ScrollToOptions = {
    top: 0,
    left: 0,
    behavior: 'auto',
  },
  scrollElRef?: React.RefObject<HTMLElement>,
  deps: React.DependencyList = [],
) {
  const optionsRef = useLiveRef(options)

  useEffect(() => {
    const scrollEl =
      getScrollingElement(scrollElRef?.current ?? document.body) ?? window

    scrollEl.scrollTo(optionsRef.current)
  }, [scrollElRef, ...deps])
}
