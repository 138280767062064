import React from 'react'
import * as WebUI from '@cheddarup/web-ui'

const OrSeparator = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => (
  <div
    className={WebUI.cn('flex flex-col items-center self-stretch', className)}
    {...restProps}
  >
    <div className="mb-2 min-h-0 w-px min-w-0 flex-auto bg-gray400" />
    OR
    <div className="mt-2 min-h-0 w-px min-w-0 flex-auto bg-gray400" />
  </div>
)

export default OrSeparator
