import {useMemo} from 'react'
import {api} from '@cheddarup/api-client'
import * as Util from '@cheddarup/util'

// MARK: – useAllFolders

export function useAllFolders() {
  const foldersQuery = api.tabFolders.list.useQuery()
  const defaultFolder = useDefaultFolder()
  const managedFolders = useManagedFolders()

  return useMemo(
    () => [defaultFolder, ...(foldersQuery.data ?? []), ...managedFolders],
    [defaultFolder, foldersQuery.data, managedFolders],
  )
}

export function useDefaultFolder() {
  const userQuery = api.auth.session.useQuery(undefined, {
    select: (session) => session.user,
  })
  return useMemo(() => makeDefaultFolder(userQuery.data), [userQuery.data])
}

export function useManagedFolders() {
  const managedFoldersQuery = api.tabs.list.useQuery(undefined, {
    select: (collections) =>
      Util.uniqueBy(
        collections
          ?.filter((collection) => !collection.access?.owner)
          .map((c) => c.folder),
        (f) => f?.id,
      ).filter((f): f is Api.TabFolder => f != null),
  })
  const managerDefaultFoldersQuery = api.auth.session.useQuery(undefined, {
    select: (session) =>
      session.manager_roles.map((mr) => makeDefaultFolder(mr)),
  })

  return useMemo(
    () => [
      ...(managerDefaultFoldersQuery.data ?? []),
      ...(managedFoldersQuery.data ?? []),
    ],
    [managerDefaultFoldersQuery.data, managedFoldersQuery.data],
  )
}

export function useFolders(managerRoleId: number | null | undefined) {
  const foldersQuery = api.tabFolders.list.useQuery(undefined, {
    enabled: managerRoleId == null,
  })
  const defaultFolder = useDefaultFolder()

  const managedFoldersQuery = api.tabs.list.useQuery(undefined, {
    enabled: managerRoleId != null,
    select: (collections) =>
      Util.uniqueBy(
        collections
          ?.filter((c) => c.organizer.id === managerRoleId)
          .filter((collection) => !collection.access?.owner)
          .map((c) => c.folder),
        (f) => f?.id,
      ).filter((f) => f != null),
  })
  const managerRoleQuery = api.auth.session.useQuery(undefined, {
    enabled: managerRoleId != null,
    select: (session) =>
      session.manager_roles.find((mr) => mr.id === managerRoleId),
  })

  const managerDefaultFolder = useMemo(
    () => makeDefaultFolder(managerRoleQuery.data),
    [managerRoleQuery.data],
  )

  return useMemo(
    () =>
      foldersQuery.data == null || managedFoldersQuery.data == null
        ? undefined
        : [
            managerRoleId == null ? defaultFolder : managerDefaultFolder,
            ...(managerRoleId == null
              ? (foldersQuery.data ?? [])
              : (managedFoldersQuery.data ?? [])),
          ],
    [
      defaultFolder,
      managerDefaultFolder,
      foldersQuery.data,
      managedFoldersQuery.data,
      managerRoleId,
    ],
  )
}

// MARK: – Helpers

export function makeDefaultFolder(
  user: Api.UserProfile | Api.ManagerRole | null | undefined,
) {
  const defaultFolderName =
    user?.profile.uiClientFlags?.customDefaultFolderName || 'Collections'

  return {
    id: null,
    name: defaultFolderName,
    organizer: user
      ? {
          id: user.id,
          name: 'display_name' in user ? user.display_name : user.name,
        }
      : undefined,
    owner: !user || 'withdrawal_data' in user,
    position: 0,
    user_id: user ? user.id : undefined,
  } as Util.Merge<Api.TabFolder, {id: null}>
}
