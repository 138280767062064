import * as Util from '@cheddarup/util'
import {useMemo} from 'react'
import {api} from '@cheddarup/api-client'

export const useAlbums = () => {
  const {data: headers} = api.headers.list.useQuery()

  const userAlbums = useMemo(
    () => [
      {
        id: 1,
        name: 'My Album',
        images: (headers ?? []).filter((header) => !header.partner),
      },
    ],
    [headers],
  )
  const partnerAlbums = useMemo(() => {
    const partnerNames = Util.unique(
      (headers ?? [])
        .map((header) => header.partner?.name)
        .filter((pn) => typeof pn === 'string'),
    )

    return partnerNames.map((partnerName, idx) => ({
      id: userAlbums.length + idx + 1,
      name: partnerName,
      images:
        headers?.filter(({partner}) => partner?.name === partnerName) ?? [],
    }))
  }, [headers, userAlbums.length])

  return {userAlbums, partnerAlbums}
}
