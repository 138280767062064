import React from 'react'

import {HStack, HStackProps, VStack} from './Stack'
import {useMedia} from '../hooks'
import {Heading} from './Heading'
import {Text} from './Text'
import {cn} from '../utils'

export interface PageHeaderProps
  extends HStackProps,
    React.ComponentPropsWithoutRef<'div'> {
  graphics?: React.ReactNode
  subheading?: React.ReactNode
}

export const PageHeader = React.forwardRef<HTMLDivElement, PageHeaderProps>(
  ({subheading, graphics, className, children, ...restProps}, forwardedRef) => {
    const media = useMedia()

    return (
      <HStack
        ref={forwardedRef}
        className={cn('PageHeader', 'items-center gap-6', className)}
        {...restProps}
      >
        {media.sm && graphics}
        <VStack className="PageHeader-content min-w-0">
          <Heading className="PageHeader-heading" as="h2">
            {children}
          </Heading>
          <Text className="PageHeader-subheading font-light text-ds-base">
            {subheading}
          </Text>
        </VStack>
      </HStack>
    )
  },
)
