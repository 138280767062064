import {makeQueryUpdate, makeUseMutation} from '../use-mutation'
import {endpoints} from '../../endpoints'
import {getEndpointKey} from '../../utils'

export const useCreateWithdrawalMutation = makeUseMutation(
  endpoints.withdrawals.create,
  (vars) => ({
    regular: (newWithdrawal) => [
      makeQueryUpdate(
        endpoints.withdrawals.list,
        (prevWithdrawals) =>
          prevWithdrawals
            ? [...prevWithdrawals, newWithdrawal]
            : prevWithdrawals,
        vars,
      ),
      makeQueryUpdate(
        endpoints.tabs.detail,
        (tab) =>
          tab
            ? {
                ...tab,
                withdrawal_balance_available:
                  tab.withdrawal_balance_available - newWithdrawal.amount,
              }
            : tab,
        vars,
      ),
    ],
  }),
  (queryClient) => ({
    onSuccess: () => {
      const tabListQueryKey = getEndpointKey(endpoints.tabs.list)
      queryClient.invalidateQueries({queryKey: tabListQueryKey})
    },
    onError: (err) => {
      if (typeof err === 'object' && err && 'response' in err) {
        const untypedErr: any = err
        const isPersonaError = !!(
          untypedErr.response?.data?.type === 'api_error' &&
          untypedErr.response.data.errors[0]?.error === 'persona_required'
        )
        const isTooSoon = !!(
          untypedErr.response?.data?.type === 'api_error' &&
          untypedErr.response.data.errors[0]?.error === 'too_soon'
        )

        if (isPersonaError || isTooSoon) {
          const sessionQueryKey = getEndpointKey(endpoints.auth.session)
          queryClient.invalidateQueries({queryKey: sessionQueryKey})
        }
      }
    },
  }),
)

export const useTestTangoWithdrawalMutation = makeUseMutation(
  endpoints.withdrawals.testTango,
)

export const useResendWithdrawalToRecipientMutation = makeUseMutation(
  endpoints.withdrawals.resendToRecipient,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(
        endpoints.withdrawals.list,
        (withdrawals) =>
          withdrawals?.map((w) =>
            w.id === vars.pathParams.withdrawalId
              ? {
                  ...w,
                  ...(w.tango_card && {
                    tango_card: {
                      ...w.tango_card,
                      ...(w.tango_card.redemption_email && {
                        redemption_email: {
                          ...w.tango_card.redemption_email,
                          status: 'pending',
                        },
                      }),
                    },
                  }),
                }
              : w,
          ),
        vars,
      ),
    ],
  }),
)
