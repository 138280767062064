import React from 'react'
import * as WebUI from '@cheddarup/web-ui'

import {TabTemplateCard} from './TabTemplateCard'

export interface TabTemplatesGridProps
  extends React.ComponentPropsWithoutRef<'div'> {
  loading?: boolean
  loadingCardsCount?: number
  templates: Api.TabTemplate[]
  templateCardWidth?: number
  templateCardImageHeight?: number
  templateCardButtonSize?: WebUI.ButtonProps['size']
}

export const TabTemplatesGrid: React.FC<TabTemplatesGridProps> = ({
  loading,
  loadingCardsCount = 3,
  templates,
  className,
  templateCardWidth: templateCardWidthProp,
  templateCardImageHeight,
  templateCardButtonSize,
  ...restProps
}) => {
  const media = WebUI.useMedia()
  const templateCardWidth = templateCardWidthProp ?? (media.sm ? 332 : 320)

  return (
    <div
      className={WebUI.cn(
        'grid grid-cols-[repeat(auto-fill,minmax(320px,1fr))] place-items-center gap-8 sm:grid-cols-[repeat(auto-fill,minmax(332px,1fr))]',
        className,
      )}
      {...restProps}
    >
      {loading
        ? Array.from({length: loadingCardsCount}).map((_, idx) => (
            <TabTemplateCard
              key={idx}
              width={templateCardWidth}
              imageHeight={templateCardImageHeight}
              template={null}
              buttonSize={templateCardButtonSize}
            />
          ))
        : templates.map((template) => (
            <TabTemplateCard
              key={template.id}
              width={templateCardWidth}
              imageHeight={templateCardImageHeight}
              template={template}
              buttonSize={templateCardButtonSize}
            />
          ))}
    </div>
  )
}
