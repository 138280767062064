import {endpoints} from '../../endpoints'
import {makeQueryUpdate, makeUseMutation} from '../use-mutation'

export const useDeleteAlertMutation = makeUseMutation(
  endpoints.alerts.delete,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(endpoints.alerts.list, (alerts) =>
        alerts?.filter((a) => a.id !== vars.pathParams?.alertId),
      ),
    ],
  }),
)
