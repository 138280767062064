import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {api} from '@cheddarup/api-client'
import * as WebUI from '@cheddarup/web-ui'

import {PayerOverview, RefundOverview, RefundsTable} from '../../components'
import ExpandableSidebarLayout from '../../components/ExpandableSidebarLayout'

const RefundsManagePage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const urlParams = useParams()
  const {data: payment} = api.payments.detail.useSuspenseQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      paymentId: urlParams.payment!,
    },
  })
  const [selectedRefund, setSelectedRefund] =
    useState<Api.TabPaymentRefund | null>(null)

  useEffect(() => {
    if (location.state?.selectedPaymentItemId) {
      const nextSelectedRefund = payment.refunds.find((refund) => {
        const refundPaymentItemIds = (refund.detail?.items || []).map(
          ({payment_item_id}) => payment_item_id,
        )
        return refundPaymentItemIds.includes(
          location.state?.selectedPaymentItemId,
        )
      })

      setSelectedRefund(nextSelectedRefund ?? null)
    }
  }, [payment, location.state?.selectedPaymentItemId])

  return (
    <WebUI.Modal
      className="[&_>_.ModalContentView]:h-full [&_>_.ModalContentView]:max-w-screen-lg"
      onDidHide={() => navigate(-1)}
    >
      <WebUI.ModalCloseButton />

      <ExpandableSidebarLayout
        sidebarVisible={!!selectedRefund}
        // biome-ignore lint/style/noNonNullAssertion:
        sidebar={<RefundOverview refund={selectedRefund!} payment={payment} />}
        title={payment.tab.name}
        heading="Refunds"
        onClickCloseSidebar={() => setSelectedRefund(null)}
      >
        <WebUI.VStack className="gap-4">
          <PayerOverview tabMember={payment.tab_member} />
          <RefundsTable
            data={payment.refunds}
            selectedRefundId={selectedRefund?.id ?? null}
            onViewRefund={(refund) =>
              setSelectedRefund((prevSelectedRefund) =>
                prevSelectedRefund?.id === refund.id ? null : refund,
              )
            }
          />
        </WebUI.VStack>
      </ExpandableSidebarLayout>
    </WebUI.Modal>
  )
}

export default RefundsManagePage
