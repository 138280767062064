import * as WebUI from '@cheddarup/web-ui'

import {FormFieldInlineLabelEdit} from './FormFieldInlineLabelEdit'
import type {FieldGroupFieldsEditProps} from './FieldGroupFieldsEdit'

export const FullNameFieldsEdit = ({
  required,
  focused,
  initialLabels,
  placeholders,
  onLabelChange,
}: Omit<
  FieldGroupFieldsEditProps<'full_name'>,
  'fieldGroupType' | 'initialValue' | 'onValueChange'
>) => (
  <WebUI.FormFieldGroup>
    <FormFieldInlineLabelEdit
      labelInputFocused={focused}
      required={required}
      initialLabel={initialLabels.first_name}
      placeholder={placeholders.first_name}
      onLabelChange={(label) => onLabelChange('first_name', label)}
    >
      <WebUI.Input disabled autoComplete="given-name" />
    </FormFieldInlineLabelEdit>
    <FormFieldInlineLabelEdit
      required={required}
      initialLabel={initialLabels.last_name}
      placeholder={placeholders.last_name}
      onLabelChange={(label) => onLabelChange('last_name', label)}
    >
      <WebUI.Input disabled autoComplete="family-name" />
    </FormFieldInlineLabelEdit>
  </WebUI.FormFieldGroup>
)
