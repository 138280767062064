import React from 'react'
import * as WebUI from '@cheddarup/web-ui'

export interface PlanModalProps {
  buttons: Array<
    WebUI.ButtonProps &
      React.ComponentPropsWithoutRef<'button'> & {text: React.ReactNode}
  >
  heading: React.ReactNode
  message?: React.ReactNode
  status?: 'pending'
  handleDismiss?: () => void
}

const PlanModal = ({
  buttons,
  handleDismiss,
  heading,
  message,
  status,
}: PlanModalProps) => (
  <WebUI.Alert
    aria-label="Plan warning"
    initialVisible
    onDidHide={handleDismiss}
  >
    <WebUI.AlertHeader>{heading}</WebUI.AlertHeader>
    <WebUI.AlertContentView
      text={message}
      actions={buttons.map((button, i) => (
        <WebUI.Button
          key={i}
          className={WebUI.cn(button.className, 'w-full sm:w-auto')}
          loading={status === 'pending'}
          onClick={button.onClick}
        >
          {button.text}
        </WebUI.Button>
      ))}
    />
  </WebUI.Alert>
)

export default PlanModal
