import * as Util from '@cheddarup/util'
import {CalendarDate} from '@internationalized/date'

function parseDob(
  dob: string | null,
): Util.SetRequired<Api.UserDateOfBirth, 'day' | 'month' | 'year'> | null {
  if (dob == null) {
    return null
  }
  const date = Util.parseDate(dob, 'MM/dd/yyyy', new Date())

  return {
    day: Util.getDate(date),
    month: Util.getMonth(date) + 1,
    year: Util.getYear(date),
  }
}

const UserDobHelpers = {
  parseDate: parseDob,
  toString: (dob: Api.UserDateOfBirth | null) =>
    dob?.year && dob?.month && dob?.day
      ? Util.formatDateAs(new Date(dob.year, dob.month, dob.day))
      : '',
  toCalendarDate: (dob: Api.UserDateOfBirth | null | undefined) =>
    dob?.year && dob?.month && dob?.day
      ? new CalendarDate(dob.year, dob.month, dob.day)
      : null,
}

export default UserDobHelpers
