import {forwardRef} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {useDeleteOrgMemberMutation} from '@cheddarup/api-client'

export interface DeactivateUserAlertProps extends WebUI.AlertProps {
  memberId: number
  onDidDeactivate?: () => void
}

export const DeactivateUserAlert = forwardRef<
  WebUI.DialogInstance,
  DeactivateUserAlertProps
>(({memberId, onDidDeactivate, ...restProps}, forwardedRef) => {
  const growlActions = WebUI.useGrowlActions()
  const deleteOrgMemberMutation = useDeleteOrgMemberMutation()

  return (
    <WebUI.Alert
      ref={forwardedRef}
      aria-label="User deactivation confirmation"
      {...restProps}
    >
      <WebUI.AlertHeader>Are you sure?</WebUI.AlertHeader>
      <WebUI.AlertContentView
        text="This organizer will be removed from the partnership and any related benefits."
        actions={
          <>
            <WebUI.AlertActionButton
              execute={async () => {
                try {
                  await deleteOrgMemberMutation.mutateAsync({
                    pathParams: {
                      memberId,
                    },
                  })
                  growlActions.show('success', {
                    title: 'Success',
                    body: 'Organizer was successfully disabled from the partnership.',
                  })
                } catch {
                  growlActions.show('error', {
                    title: 'Error',
                    body: 'There is an error in deactivating the user.',
                  })
                }

                onDidDeactivate?.()
              }}
            >
              Deactivate
            </WebUI.AlertActionButton>
            <WebUI.AlertCancelButton />
          </>
        }
      />
    </WebUI.Alert>
  )
})
