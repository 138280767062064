import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import AppStore from 'src/images/AppStore.png'
import GooglePlay from 'src/images/GooglePlay.png'
import config from 'src/config'

import CreateCollectionButton from './CreateCollectionButton'

const GetStartedPanel = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => (
  <WebUI.Panel
    className={WebUI.cn('gap-3 px-8 py-6', className)}
    as={WebUI.VStack}
    {...restProps}
  >
    <WebUI.Text className="text-ds-md">
      Let&apos;s get started! Create a collection here or by downloading the
      Cheddar Up App.
    </WebUI.Text>
    <WebUI.HStack>
      <CreateCollectionButton />
    </WebUI.HStack>
    {WebUI.isAppleDevice() ? (
      <WebUI.Anchor
        className="self-start"
        href={config.appleStoreLink}
        rel="noopener noreferrer"
        target="_blank"
      >
        <img src={AppStore} alt="App Store" />
      </WebUI.Anchor>
    ) : (
      <WebUI.Anchor
        className="self-start"
        href={config.googlePlayLink}
        rel="noopener noreferrer"
        target="_blank"
      >
        <img src={GooglePlay} alt="Google Play" />
      </WebUI.Anchor>
    )}
  </WebUI.Panel>
)

export default GetStartedPanel
