import * as WebUI from '@cheddarup/web-ui'
import React, {useMemo} from 'react'
import {ObjectParam, useQueryParam, withDefault} from 'use-query-params'

interface Section {
  id: string
  title: string
  initialExpanded: boolean
  items: string[]
}

export interface VariantsFilterDropdownProps {
  filters: Record<string, string[]>
}

const VariantsFilterDropdown = ({filters}: VariantsFilterDropdownProps) => {
  const [selectedFilterValues, setSelectedFilterValues] = useQueryParam(
    'filters',
    withDefault(ObjectParam, {}),
  )

  const sections: Section[] = useMemo(
    () =>
      Object.keys(filters).map((filterTitle, idx) => ({
        id: filterTitle,
        title: filterTitle,
        initialExpanded: idx === 0,
        // biome-ignore lint/style/noNonNullAssertion:
        items: filters[filterTitle]!,
      })),
    [filters],
  )

  return (
    <WebUI.Popover>
      <WebUI.PopoverDisclosure className="ml-8" variant="outlined">
        Filters
      </WebUI.PopoverDisclosure>
      <WebUI.PopoverContent>
        <SectionedExpandingList
          className="min-w-[160px] p-4"
          sections={sections}
          renderItem={(filterValue, _idx, section) => {
            const selectedValues = (
              Array.isArray((selectedFilterValues as any)[section.id])
                ? (selectedFilterValues as any)[section.id]
                : []
            ) as string[]

            return (
              <WebUI.Checkbox
                key={filterValue}
                state={!!selectedValues.includes(filterValue)}
                onChange={(event) =>
                  setSelectedFilterValues({
                    ...selectedFilterValues,
                    [section.id]: event.target.checked
                      ? [...selectedValues, filterValue]
                      : selectedValues.filter((value) => value !== filterValue),
                  } as any)
                }
              >
                {filterValue}
              </WebUI.Checkbox>
            )
          }}
        />
      </WebUI.PopoverContent>
    </WebUI.Popover>
  )
}

// MARK: – SectionedExpandingList

interface SectionedExpandingListProps
  extends React.ComponentPropsWithoutRef<'div'> {
  sections: Section[]
  renderItem: (item: string, idx: number, section: Section) => React.ReactNode
}

const SectionedExpandingList = ({
  sections,
  renderItem,
  className,
  ...restProps
}: SectionedExpandingListProps) => (
  <WebUI.VStack className={WebUI.cn('gap-4', className)} {...restProps}>
    {sections.map((section) => (
      <WebUI.Disclosure
        key={section.id}
        className="gap-4 font-semibold text-ds-sm"
        initialVisible={section.initialExpanded}
      >
        {section.items?.length > 0 && (
          <WebUI.DisclosureButton
            className={
              'aria-expanded:[&_.Button-iconAfter]:rotate-180 [&_>_.Button-iconAfter]:transition-transform [&_>_.Button-iconAfter]:duration-100 [&_>_.Button-iconAfter]:ease-linear'
            }
            arrow={false}
            variant="text"
            iconAfter={<WebUI.PhosphorIcon icon="caret-up" />}
          >
            {section.title}
          </WebUI.DisclosureButton>
        )}
        <WebUI.DisclosureContent>
          <WebUI.VStack className="gap-2">
            {section.items.map((item, idx) => renderItem(item, idx, section))}
          </WebUI.VStack>
        </WebUI.DisclosureContent>
      </WebUI.Disclosure>
    ))}
  </WebUI.VStack>
)

export default VariantsFilterDropdown
