import {endpoints} from '../../endpoints'
import {makeQueryUpdate, makeUseMutation} from '../use-mutation'

export const useCreateOrgMemberInvitesMutation = makeUseMutation(
  endpoints.orgMemberInvites.create,
)

export const useRecreateOrgMemberInviteMutation = makeUseMutation(
  endpoints.orgMemberInvites.recreate,
)

export const useCreateOrgMemberTestInvitesMutation = makeUseMutation(
  endpoints.orgMemberInvites.sendTest,
)

export const useDeleteOrgMemberInviteMutation = makeUseMutation(
  endpoints.orgMemberInvites.delete,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(endpoints.orgMemberInvites.list, (prevInvites) =>
        prevInvites
          ? {
              ...prevInvites,
              data: prevInvites.data.filter(
                (i) => i.id !== Number(vars.pathParams.inviteId),
              ),
            }
          : prevInvites,
      ),
    ],
  }),
)

export const useResendOrgMemberInviteMutation = makeUseMutation(
  endpoints.orgMemberInvites.resend,
)

export const useResendAllOrgMemberInvitesMutation = makeUseMutation(
  endpoints.orgMemberInvites.resendAll,
)

export const useDeleteOrgMemberMutation = makeUseMutation(
  endpoints.orgMembers.delete,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(endpoints.orgMembers.list, (prevMembers) =>
        prevMembers
          ? {
              ...prevMembers,
              data: prevMembers.data.filter(
                (m) => m.id !== Number(vars.pathParams.memberId),
              ),
            }
          : prevMembers,
      ),
    ],
  }),
)
