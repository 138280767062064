import {api} from '@cheddarup/api-client'
import {cleanUrl, getBlobFromUrl, makeNonSecureShortId} from '@cheddarup/util'

type FileValue =
  | string // a url
  | File
  | Blob

export async function uploadImage(
  parentPath: string,
  originalImageValue: FileValue,
  editedImageValue?: FileValue,
  options?: {
    metadata?: Api.S3ImageMetadata
    userId?: number | undefined
  },
) {
  const objectName = makeObjectName({
    prefix: 'image',
    fileName:
      originalImageValue instanceof File ? originalImageValue.name : undefined,
  })

  const [originalImage, editedImage] = await Promise.all([
    makeBlobFromFileValue(originalImageValue),
    editedImageValue
      ? makeBlobFromFileValue(editedImageValue)
      : Promise.resolve(null),
  ])

  const contentType = options?.metadata?.contentType || originalImage.type

  const createSignedUploadUrlRes =
    await api.fileUploads.createSignedUploadUrl.fetch({
      pathParams: {
        parentPath,
      },
      body: {
        objectName,
        metadata: {
          contentType: contentType,
          ...options?.metadata,
        },
      },
      headers: {
        'X-Manager-Account-Id': options?.userId,
      },
    })
  await Promise.all([
    uploadBlobToS3(createSignedUploadUrlRes.signedUrl, originalImage, {
      'Content-Type': contentType,
    }),
    editedImage
      ? uploadBlobToS3(
          createSignedUploadUrlRes.editedImageSignedUrl,
          editedImage,
          {
            'Content-Type': contentType,
          },
        )
      : Promise.resolve(null),
  ])

  return api.fileUploads.createImageRecord.fetch({
    pathParams: {
      parentPath,
    },
    body: {
      objectName,
      upload_path: createSignedUploadUrlRes.uploadPath,
      metadata: options?.metadata ?? {},
    },
    headers: {
      'X-Manager-Account-Id': options?.userId,
    },
  })
}

export async function uploadTabAttachment(
  tabId: number,
  fileValue: FileValue,
  options: {
    createAttachmentQueryFn?: typeof api.tabAttachments.create.fetch
    userId: number | undefined
  },
) {
  const objectName =
    fileValue instanceof File ? fileValue.name : makeObjectName({})

  const blob = await makeBlobFromFileValue(fileValue)

  const signRes = await api.tabAttachments.sign.fetch({
    pathParams: {
      tabId,
    },
    body: {
      objectName,
      contentType: blob.type,
    },
    headers: {
      'X-Manager-Account-Id': options.userId,
    },
  })

  await uploadBlobToS3(signRes.signedUrl, blob)

  const createAttachmentQueryFn =
    options.createAttachmentQueryFn ?? api.tabAttachments.create.fetch

  return createAttachmentQueryFn({
    pathParams: {
      tabId,
    },
    body: {
      file_name: objectName,
    },
    headers: {
      'X-Manager-Account-Id': options.userId,
    },
  })
}

export async function uploadESignature(
  imageValue: FileValue,
  options: {
    tabId: number | string
    cartUuid: string
  },
) {
  const objectName = makeObjectName({
    prefix: 'e-signature',
    fileName: 'signature.png',
  })

  const blob = await makeBlobFromFileValue(imageValue)

  const signRes = await api.carts.sign.fetch({
    pathParams: {
      tabId: options.tabId,
      cartUuid: options.cartUuid,
    },
    body: {
      objectName,
      contentType: blob.type,
    },
  })

  await uploadBlobToS3(signRes.signedUrl, blob)

  return cleanUrl(signRes.signedUrl)
}

// MARK: – Helpers

function uploadBlobToS3(
  signedUrl: string,
  blob: Blob,
  headers?: Record<string, string>,
) {
  return fetch(signedUrl, {
    body: blob,
    mode: 'cors',
    method: 'PUT',
    headers: {
      'x-amz-acl': 'public-read',
      'Content-Type': blob.type,
      ...headers,
    },
  })
}

function makeBlobFromFileValue(imageValue: FileValue) {
  if (typeof imageValue === 'string') {
    return getBlobFromUrl(imageValue)
  }

  return imageValue
}

function makeObjectName({
  prefix,
  fileName,
}: {prefix?: string; fileName?: string}) {
  return [prefix, makeNonSecureShortId(), fileName]
    .filter((str) => str != null)
    .map((str) => encodeURIComponent(str))
    .join('-')
}
