import {useStripe} from '@stripe/react-stripe-js'
import {useNavigate} from 'react-router-dom'
import React, {useRef} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {
  api,
  useCreateExternalAccountMutation,
  useCreatePaymentMethodMutation,
} from '@cheddarup/api-client'
import {
  BankAccountForm,
  BankAccountFormCAValues,
  BankAccountFormUSValues,
} from 'src/components/BankAccountForm'
import {Elements} from 'src/components/Stripe'
import {InquireVerificationCode} from 'src/components/InquireVerificationCode'
import {read2FAError} from 'src/helpers/error-formatting'

import {BankListContainer} from './containers'
import {
  AccountSettingsContentCard,
  AccountSettingsContentLayout,
} from '../components/AccountSettingsContentLayouts'
import {PersonaButton} from 'src/components/PersonaButton'

const WithdrawalSettingsPage = () => {
  const navigate = useNavigate()
  const bankAccountFormModalRef = useRef<WebUI.DialogInstance | null>(null)
  const {data: session} = api.auth.session.useSuspenseQuery()

  const {bankModificationsDisabled} = session.stripe_data
  const addAccountHeading =
    session.user.currency === 'usd'
      ? 'Link to a US Bank Account'
      : 'Link to a CAN Bank Account'

  return (
    <AccountSettingsContentLayout
      heading="Withdrawals"
      body={
        <WebUI.VStack className="gap-5">
          <WebUI.Text className="font-light">
            Your payments will sit securely in your Cheddar Up account until you
            initiate a withdrawal or send funds to a Gift Card.
          </WebUI.Text>
          <WebUI.Anchor
            className="text-ds-sm"
            rel="noopener noreferrer"
            target="_blank"
            href="https://support.cheddarup.com/hc/en-us/articles/360035226192-Withdraw-funds/#fundflowtime"
          >
            How long do withdrawals take?
          </WebUI.Anchor>
        </WebUI.VStack>
      }
    >
      {session?.user.profile?.persona?.required &&
      !session?.user.profile?.persona?.completed ? (
        <PersonaCta />
      ) : (
        <WebUI.Button
          iconBefore={
            <WebUI.PhosphorIcon
              className="text-ds-md text-orange-50"
              icon="check-circle-fill"
            />
          }
          variant="secondaryAlt"
          className="self-start bg-aquaLight text-tint"
        >
          Verified Identity
        </WebUI.Button>
      )}
      <AccountSettingsContentCard
        heading="My Checking Accounts"
        className="pb-6"
      >
        <BankListContainer />
        <WebUI.Button
          variant="secondary"
          className="self-start"
          onClick={() =>
            bankModificationsDisabled
              ? navigate('disabled')
              : session.user.profile.phone.verified
                ? bankAccountFormModalRef.current?.show()
                : navigate('make-it-secure', {replace: true})
          }
        >
          {addAccountHeading}
        </WebUI.Button>
      </AccountSettingsContentCard>
      {!bankModificationsDisabled && (
        <Elements>
          <BankAccountFormModal
            title={addAccountHeading}
            ref={bankAccountFormModalRef}
          />
        </Elements>
      )}
    </AccountSettingsContentLayout>
  )
}

// MARK: - PersonaCta

const PersonaCta = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => {
  const navigate = useNavigate()
  return (
    <WebUI.VStack
      className={WebUI.cn('gap-5 bg-teal-80 p-6 pb-8 text-gray800', className)}
      {...restProps}
    >
      <WebUI.VStack className="gap-3">
        <WebUI.Heading className="font-extrabold" as="h5">
          Prepare for withdrawals by verifying your identity
          <span className="text-orange-50">*</span>
        </WebUI.Heading>
        <WebUI.Text className="font-light text-ds-sm">
          To keep our users safe and mitigate online fraud, it’s important for
          us to validate our users’ identities. We do this using a tool called
          Persona. This is a one-time process and Cheddar Up uses the
          information submitted solely for identity verification.
          <br />
          <br /> Upload a government ID in combination with a live photo. You
          will only need to do this one time.
        </WebUI.Text>
      </WebUI.VStack>
      <PersonaButton
        className="self-start"
        variant="primary"
        onComplete={() => setTimeout(() => navigate('/withdraw?identity=1'), 0)}
      />
    </WebUI.VStack>
  )
}

// MARK: – BankAccountFormModal

export interface BankAccountFormModalProps extends WebUI.ModalProps {
  title: string
}

export const BankAccountFormModal = React.forwardRef<
  WebUI.DialogInstance,
  BankAccountFormModalProps
>(({initialVisible = false, className, title, ...restProps}, forwardedRef) => {
  const growlActions = WebUI.useGrowlActions()
  const createExternalAccountMutation = useCreateExternalAccountMutation()
  const createPaymentMethodMutation = useCreatePaymentMethodMutation()
  const stripe = useStripe()

  return (
    <WebUI.Modal
      ref={forwardedRef}
      className={WebUI.cn(
        '[&_>_.ModalContentView]:max-w-screen-sm [&_>_.ModalContentView]:rounded-large [&_>_.ModalContentView]:px-9 [&_>_.ModalContentView]:py-10',
        className,
      )}
      initialVisible={initialVisible}
      {...restProps}
    >
      {(dialog) => (
        <AccountSettingsContentLayout
          heading={title}
          body="Enter your checking account information below. (You can not withdraw into a savings account.)"
          className="gap-4"
        >
          <WebUI.ModalCloseButton />
          <InquireVerificationCode>
            {(verificationHelpers) => (
              <BankAccountForm
                onSubmit={async (
                  values: BankAccountFormUSValues | BankAccountFormCAValues,
                ) => {
                  if (!stripe) {
                    return
                  }
                  const isCanadian = 'transitNumber' in values
                  const bankCreateTokenParams = {
                    account_holder_name: values.nickName ?? '',
                    account_number: values.accountNumber,
                    country: isCanadian ? 'CA' : 'US',
                    currency: isCanadian ? 'CAD' : 'USD',
                    routing_number: isCanadian
                      ? `${values.transitNumber}-${values.institutionNumber}`
                      : values.routingNumber,
                    account_holder_type: 'individual',
                  }
                  const stripeRes = await stripe.createToken(
                    'bank_account',
                    bankCreateTokenParams,
                  )

                  if (stripeRes.error) {
                    growlActions.clear()
                    growlActions.show('error', {
                      title: 'Error',
                      body: stripeRes.error.message,
                    })
                  } else if (stripeRes.token) {
                    try {
                      const {verificationCode} =
                        await verificationHelpers.verifyPhone()

                      await createExternalAccountMutation.mutateAsync({
                        body: {
                          default: false,
                          nickname: values.nickName,
                          source: stripeRes.token.id,
                          security: {token: verificationCode},
                        },
                      })
                      const createPmRes = await stripe.createToken(
                        'bank_account',
                        bankCreateTokenParams,
                      )
                      if (createPmRes.error) {
                        throw new Error(createPmRes.error.message)
                      }
                      try {
                        await createPaymentMethodMutation.mutateAsync({
                          body: {
                            source: createPmRes.token.id,
                            hidden: true,
                          },
                        })
                      } catch (err: any) {
                        if (
                          err.response?.data?.errors?.[0]?.error !==
                          'account_already_exists'
                        ) {
                          throw new Error(
                            err.message || err.response?.data?.error,
                          )
                        }
                      }
                      growlActions.show('success', {
                        title: 'Account Added',
                        body: `${
                          values.nickName ?? ''
                        } was added to your withdrawal methods.`,
                      })
                      dialog.hide()
                    } catch (err: any) {
                      const errMsg =
                        read2FAError(err) ||
                        err.response?.data?.error ||
                        err.message
                      growlActions.clear()
                      growlActions.show('error', {
                        title: 'Error',
                        body:
                          errMsg ||
                          'Something went wrong while saving your updates',
                      })
                    }
                  }
                }}
              />
            )}
          </InquireVerificationCode>
        </AccountSettingsContentLayout>
      )}
    </WebUI.Modal>
  )
})

export default WithdrawalSettingsPage
