import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'
import {NumberParam, useQueryParam} from 'use-query-params'
import React, {useState} from 'react'
import {
  api,
  useAddContactsToContactListMutation,
  useBulkDeleteContactsMutation,
  useRemoveContactsFromContactListMutation,
} from '@cheddarup/api-client'

import {ContactsAutosuggestCombobox} from '../../components/ContactsAutosuggestCombobox'

export type ContactTableViewSortBy = 'created_at' | 'last_name'

export interface ContactTableViewToolbarProps
  extends React.ComponentPropsWithoutRef<'div'> {
  table: WebUI.TableViewInstance<Api.Contact>
  sortBy: ContactTableViewSortBy
  onNameOrEmailChange: (nameOrEmail: string) => void
  onSortByChange: (sortBy: ContactTableViewSortBy) => void
  onCreateList: () => Promise<Api.ContactList>
}

export const ContactTableViewToolbar = ({
  table,
  sortBy,
  onNameOrEmailChange,
  onSortByChange,
  onCreateList,
  className,
  ...restProps
}: ContactTableViewToolbarProps) => {
  const selectedContacts = table
    .getSelectedRowModel()
    .flatRows.map((r) => r.original)
  return (
    <WebUI.VStack
      className={WebUI.cn(
        'items-stretch justify-center gap-3 sm:flex-row sm:items-center sm:justify-between',
        className,
      )}
      {...restProps}
    >
      <WebUI.HStack className="xs:order-3 gap-3 xs:py-4">
        <WebUI.Checkbox
          className="self-center whitespace-nowrap text-ds-xs"
          state={
            table.getIsSomeRowsSelected()
              ? 'indeterminate'
              : table.getIsAllRowsSelected()
          }
          onChange={table.getToggleAllRowsSelectedHandler()}
        >
          Select All
        </WebUI.Checkbox>
        {selectedContacts.length > 0 && (
          <>
            <AddContactsToListMenu
              selectedContacts={selectedContacts}
              onCreateList={onCreateList}
            />
            <DeleteContactsButton selectedContacts={selectedContacts} />
          </>
        )}
      </WebUI.HStack>

      <WebUI.VStack className="gap-3 sm:flex-row">
        <ContactsAutosuggestCombobox
          className="h-full sm:w-[320px]"
          size="small"
          chevronIconName="magnifying-glass"
          field="nameOrEmail"
          placeholder="Search by contact name or email"
          onSelectedContactChange={(contact) =>
            onNameOrEmailChange(contact?.email ?? '')
          }
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
              onNameOrEmailChange((event.target as any).value ?? '')
            }
          }}
        />
        <WebUI.DropdownSelect<ContactTableViewSortBy>
          className="min-w-[120px]"
          size="small"
          placeholder="Sort"
          value={sortBy}
          onValueChange={(newSortBy) => {
            if (newSortBy) {
              onSortByChange(newSortBy)
            }
          }}
        >
          <WebUI.DropdownSelectOption value="created_at">
            Date Added
          </WebUI.DropdownSelectOption>
          <WebUI.DropdownSelectOption value="last_name">
            Last Name
          </WebUI.DropdownSelectOption>
        </WebUI.DropdownSelect>
      </WebUI.VStack>
    </WebUI.VStack>
  )
}

// MARK: – AddContactsToListMenu

interface AddContactsToListMenuProps
  extends React.ComponentPropsWithoutRef<'button'> {
  selectedContacts: Api.Contact[]
  onCreateList: () => Promise<Api.ContactList>
}

const AddContactsToListMenu = ({
  selectedContacts,
  onCreateList,
  ...restProps
}: AddContactsToListMenuProps) => {
  const contactListsQuery = api.contacts.contactListList.useQuery(undefined, {
    select: (cls) => {
      const contactListIdsToOmit = Util.intersection(
        ...selectedContacts.map((c) => c.contact_lists.map((cl) => cl.id)),
      )
      return cls.filter((cl) => !contactListIdsToOmit.includes(cl.id))
    },
  })
  const addContactsToContactListMutation = useAddContactsToContactListMutation()
  const [selectedContactListIds, setSelectedContactListIds] = useState<
    number[]
  >([])
  return (
    <WebUI.Menu>
      {(menu) => (
        <>
          <WebUI.MenuButton
            className="w-[160px] [&_>_.Button-content]:text-left"
            size="compact"
            variant="outlined"
            iconAfter={<WebUI.PhosphorIcon icon="caret-down" />}
            {...restProps}
          >
            Add to a list
          </WebUI.MenuButton>
          <WebUI.MenuList className="max-h-[180px] max-w-[280px]">
            <WebUI.MenuItem
              className="text-ds-sm"
              variant="link"
              onClick={async () => {
                try {
                  const newList = await onCreateList()
                  addContactsToContactListMutation.mutate({
                    pathParams: {
                      contactListId: newList.id,
                    },
                    body: {contacts: selectedContacts.map((c) => c.id)},
                  })
                } catch {
                  // noop
                }
              }}
            >
              Create List
            </WebUI.MenuItem>
            {contactListsQuery.data?.map((cl) => (
              <WebUI.MenuItem
                key={cl.id}
                className="items-center"
                as={WebUI.Checkbox}
                state={selectedContactListIds.includes(cl.id)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setSelectedContactListIds((prevSelectedContactListIds) =>
                    event.target.checked
                      ? [...prevSelectedContactListIds, cl.id]
                      : prevSelectedContactListIds.filter(
                          (clId) => clId !== cl.id,
                        ),
                  )
                }
                onClick={(event: React.MouseEvent) => event.preventDefault()}
              >
                {cl.name}
              </WebUI.MenuItem>
            ))}
            <WebUI.MenuSeparator />
            <WebUI.MenuItem
              className="m-2 self-start rounded px-8 text-center"
              variant="default"
              loading={addContactsToContactListMutation.isPending}
              hideOnClick={false}
              onClick={async () => {
                await Promise.all(
                  selectedContactListIds.map((clId) => {
                    const contactIdsToAdd = selectedContacts
                      .filter(
                        (c) => !c.contact_lists.some((cl) => cl.id === clId),
                      )
                      .map((c) => c.id)

                    if (contactIdsToAdd.length === 0) {
                      return null
                    }

                    return addContactsToContactListMutation.mutateAsync({
                      pathParams: {
                        contactListId: clId,
                      },
                      body: {
                        contacts: contactIdsToAdd,
                      },
                    })
                  }),
                )

                menu.hide()
              }}
            >
              Add
            </WebUI.MenuItem>
          </WebUI.MenuList>
        </>
      )}
    </WebUI.Menu>
  )
}

// MARK: – DeleteContactsButton

interface DeleteContactsButtonProps
  extends React.ComponentPropsWithoutRef<'button'> {
  selectedContacts: Api.Contact[]
}

const DeleteContactsButton = ({
  selectedContacts,
  className,
  ...restProps
}: DeleteContactsButtonProps) => {
  const [contactListId] = useQueryParam('contactListId', NumberParam)
  const removeContactsFromContactListMutation =
    useRemoveContactsFromContactListMutation()
  const bulkDeleteContactsMutation = useBulkDeleteContactsMutation()
  return (
    <WebUI.IconButton
      className={WebUI.cn('h-auto text-ds-md', className)}
      size="default_alt"
      variant="outlined"
      onClick={() => {
        if (contactListId) {
          removeContactsFromContactListMutation.mutate({
            pathParams: {
              contactListId,
            },
            body: {contacts: selectedContacts.map((c) => c.id)},
          })
        } else {
          bulkDeleteContactsMutation.mutate({
            body: {contact_ids: selectedContacts.map((c) => c.id)},
          })
        }
      }}
      {...restProps}
    >
      <WebUI.PhosphorIcon icon="trash" />
    </WebUI.IconButton>
  )
}
