import {AuthLayout} from 'src/components/AuthLayout'
import {Logo} from 'src/components'
import AppStore from 'src/images/EnlargedAppStoreBadge.png'
import GooglePlay from 'src/images/EnlargedGooglePlayBadge.png'
import {StringParam, useQueryParam} from 'use-query-params'
import config from 'src/config'

const SignUpViaPosCodePage = () => {
  const [posCode] = useQueryParam('code', StringParam)
  // @ts-ignore
  window.location = `cheddarup://hostname/sign-up-via-pos-code?code=${posCode}`

  return (
    <AuthLayout>
      <div className="p-10">
        <div className="md:mx-4 md:px-16">
          <div className="mt-4">
            <Logo className="mb-10 justify-center" />
            <h1 className="mb-4 text-center font-normal">
              Please update your app and try again
            </h1>
            <h2 className="mb-2 text-center font-light text-ds-md text-gray800">
              It looks like you don't have the latest version of the Cheddar Up
              mobile app. Download it and then try scanning your code or
              clicking your link again.
            </h2>
          </div>
          <div className="my-8 flex flex-col items-center">
            <a
              href={config.appleStoreLink}
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src={AppStore} alt="App Store" />
            </a>
            <a
              className="mt-4"
              href={config.googlePlayLink}
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src={GooglePlay} alt="Google Play" />
            </a>
          </div>
        </div>
      </div>
    </AuthLayout>
  )
}

export default SignUpViaPosCodePage
