import {type ClassValue, clsx} from 'clsx'
import {twMerge as _twMerge, extendTailwindMerge} from 'tailwind-merge'
import {tailwindConfig} from '@cheddarup/tailwind-config'

export function cn(...args: ClassValue[]) {
  return twMerge(clsx(args))
}

export const twMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      'font-size': [
        '[font-size:inherit]',
        ...Object.keys(tailwindConfig.theme.fontSize).map(
          (key) => `text-${key}`,
        ),
      ],
      h: ['[height:unset]'],
      'min-h': ['min-h-[auto]'],
      'text-alignment': ['[text-align:unset]'],
    },
  },
})
