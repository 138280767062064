import {Elements as StripeElements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import React, {useMemo} from 'react'
import config from 'src/config'

import {paymentElementAppearance} from './PaymentElement'

const PUBLIC_URL = import.meta.env.PUBLIC_URL

export interface ElementsProps
  extends Omit<
    React.ComponentPropsWithoutRef<typeof StripeElements>,
    'stripe'
  > {}

const Elements: React.FC<ElementsProps> = ({options, children}) => {
  const stripePromise = useMemo(
    () => loadStripe(config.stripePublishableKey),
    [],
  )

  return (
    <StripeElements
      options={{
        loader: 'auto',
        fonts: [
          {
            family: 'avenir-light',
            weight: '300',
            style: 'normal',
            src: `url("${
              PUBLIC_URL ||
              `${window.location.protocol}//${window.location.host}`
            }/webfonts/AvenirLTStd-Light.woff2")`,
          },
          {
            family: 'avenir-light',
            weight: '300',
            style: 'normal',
            src: `url("${
              PUBLIC_URL ||
              `${window.location.protocol}//${window.location.host}`
            }/webfonts/AvenirLTStd-Light.woff")`,
          },
          {
            family: 'avenir-roman',
            weight: '400',
            style: 'normal',
            src: `url("${
              PUBLIC_URL ||
              `${window.location.protocol}//${window.location.host}`
            }/webfonts/AvenirLTStd-Roman.woff2")`,
          },
          {
            family: 'avenir-roman',
            weight: '400',
            style: 'normal',
            src: `url("${
              PUBLIC_URL ||
              `${window.location.protocol}//${window.location.host}`
            }/webfonts/AvenirLTStd-Roman.woff")`,
          },
          ...(options?.fonts ?? []),
        ],
        appearance: paymentElementAppearance,
        ...options,
      }}
      stripe={stripePromise}
    >
      {children}
    </StripeElements>
  )
}

export default Elements
