import * as WebUI from '@cheddarup/web-ui'
import React from 'react'
import {
  FeatureKey,
  PremiumFeatureSideSheetDisclosure,
} from 'src/components/PremiumFeaturesSideSheet'

import {SettingDisclosureSwitch} from '../../../CollectionSettingsPage/SettingDisclosureSwitch'

export interface ItemSettingsDisclosureSwitchProps
  extends Omit<WebUI.DisclosureSwitchProps, 'children'> {
  featureKey?: FeatureKey
  label: string
  description?: string
}

export const ItemSettingDisclosureSwitch: React.FC<
  ItemSettingsDisclosureSwitchProps
> = ({featureKey, label, description, className, ...restProps}) => (
  <WebUI.HStack className="max-w-full flex-[1_0_auto] items-center justify-between gap-4">
    <WebUI.VStack className="flex-[12_1_auto]">
      <WebUI.Text>{label}</WebUI.Text>
      <WebUI.Text className="font-light text-ds-sm">{description}</WebUI.Text>
    </WebUI.VStack>
    {featureKey && (
      <WebUI.DisclosureContent className="flex-[1_0_76px]">
        <PremiumFeatureSideSheetDisclosure featureKeys={[featureKey]} />
      </WebUI.DisclosureContent>
    )}
    <SettingDisclosureSwitch
      className={WebUI.cn('[&_>_.Switch-content]:min-w-0', className)}
      {...restProps}
    />
  </WebUI.HStack>
)
