import * as Util from '@cheddarup/util'
import {useStripe} from '@stripe/react-stripe-js'
import {useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {useCreateOrgMemberInvitesMutation} from '@cheddarup/api-client'
import {CreateTokenBankAccountData} from '@stripe/stripe-js'

import {UploadMemberInvitesCsv} from '../components'

const UploadMemberInvitesCsvContainer = () => {
  const [invitingMembers, setInvitingMembers] = useState(false)
  const [invitedMembers, setInvitedMembers] = useState(false)
  const [processedMemberCount, setProcessedMemberCount] = useState(0)
  const createInvitesMutation = useCreateOrgMemberInvitesMutation()
  const growlActions = WebUI.useGrowlActions()
  const stripe = useStripe()

  return (
    <UploadMemberInvitesCsv
      processedMemberCount={processedMemberCount}
      invitingMembers={invitingMembers}
      invitedMembers={invitedMembers}
      onAddMemberInvites={async (profiles) => {
        setInvitedMembers(false)
        if (profiles.length !== 0) {
          setInvitingMembers(true)

          try {
            const chunkedCreateStripeTokenFuncs = Util.chunk(
              profiles.map(
                (memberInvite) => () =>
                  memberInvite.bankAccount?.accountNumber &&
                  memberInvite.bankAccount?.routingNumber
                    ? stripe?.createToken('bank_account', {
                        account_holder_name: `${memberInvite.first_name} ${memberInvite.last_name}`,
                        country:
                          memberInvite.address &&
                          memberInvite.address.country === 'Canada'
                            ? 'CA'
                            : 'US',
                        currency: memberInvite.currency,
                        account_number: memberInvite.bankAccount.accountNumber,
                        routing_number: memberInvite.bankAccount.routingNumber,
                      } as CreateTokenBankAccountData)
                    : null,
              ),
              20,
            )

            let stripeResults: any = []

            for (const createStripeTokenFuncs of chunkedCreateStripeTokenFuncs) {
              if (stripeResults.length !== 0) {
                await new Promise((resolve) => setTimeout(resolve, 1000))
              }

              const currentStripeResults = await Promise.all(
                createStripeTokenFuncs.map((x) => x()),
              )
              stripeResults = [...stripeResults, ...currentStripeResults]

              const currentStripeErrors = currentStripeResults
                .filter((currentStripeResult) => !!currentStripeResult?.error)
                .map(({error}: any) => error)

              if (currentStripeErrors.length !== 0) {
                const error = currentStripeErrors[0]
                growlActions.show('error', {
                  body: error.message,
                  title: 'Error',
                })
                return
              }
            }

            const chunkedProfiles = Util.chunk(
              profiles.map(({bankAccount, ...profile}, idx) => ({
                ...profile,
                stripe: {
                  banks: stripeResults[idx]
                    ? [
                        {
                          nickname: `${profile.first_name} ${profile.last_name}`,
                          token: stripeResults[idx].token.id,
                        },
                      ]
                    : undefined,
                },
              })),
              10,
            )

            const inviteMemberFuncs = chunkedProfiles.map(
              (ps) => () =>
                createInvitesMutation.mutateAsync({body: {profiles: ps}}),
            )

            for (const inviteMemberFunc of inviteMemberFuncs) {
              if (processedMemberCount !== 0) {
                await new Promise((resolve) => setTimeout(resolve, 1000))
              }

              const {
                invites_sent: invitesSent,
                previously_invited: previouslyInvited,
              } = await inviteMemberFunc()

              setProcessedMemberCount(
                invitesSent.length + previouslyInvited.length,
              )
            }

            growlActions.clear()
            growlActions.show('success', {
              title: 'Invite sent!',
              body: `Nice work! ${profiles.length} welcome email${
                profiles.length === 1 ? '' : 's'
              } were just sent to new members. `,
            })

            setInvitedMembers(true)
          } catch (err: any) {
            const errors = err.response?.data?.errors ?? {}
            const email = Object.keys(errors)[0]
            const errorMessage =
              errors[email ?? '']?.[0]?.message || err.message

            growlActions.clear()
            growlActions.show('error', {
              body:
                !email || profiles.length === 1
                  ? errorMessage
                  : `${errorMessage} at ${email}`,
              title: 'Error',
            })
          } finally {
            setInvitingMembers(false)
          }
        }
      }}
    />
  )
}

export default UploadMemberInvitesCsvContainer
