import {deepPartialify, z} from '@cheddarup/util'

import {userBrandingSchema} from '../schemas'
import {makeEndpoint} from '../utils'

export default {
  detail: makeEndpoint({
    path: 'users/branding',
    responseSchema: userBrandingSchema,
  }),
  update: makeEndpoint({
    path: 'users/branding',
    method: 'PATCH',
    bodySchema: deepPartialify(userBrandingSchema.omit({logo: true})).extend({
      logo_id: z.number().optional(),
    }),
    responseSchema: userBrandingSchema,
  }),
  deleteLogo: makeEndpoint({
    path: 'users/branding/delete_image/:id',
    method: 'DELETE',
    responseSchema: z.void(),
  }),
}
