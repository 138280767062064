import {useEffect} from 'react'

import {useLiveRef} from './useLiveRef'

export function useInterval(callback: () => void, delay: number) {
  const callbackRef = useLiveRef(callback)

  useEffect(() => {
    const intervalId = setInterval(callbackRef.current, delay)
    return () => clearInterval(intervalId)
  }, [delay])
}
