import * as Util from '@cheddarup/util'
import {PAYMENT_METHOD_LABELS} from 'src/views/collection/constants'
import {PAYMENT_METHOD_STATUSES} from 'src/views/collection/manage/constants'

export type CubeSchema = typeof dimensionToSchema
export type Dimension = keyof CubeSchema
export type DataType = CubeSchema[Dimension]['dataType']
export type DataTypeTSTypeMap<TDataType extends DataType> = TDataType extends
  | 'string'
  | 'date'
  | 'currency'
  | 'userStatus'
  | 'collectionStatus'
  | 'sentEmailStatus'
  | 'withdrawalStatus'
  | 'paymentStatus'
  ? string
  : TDataType extends 'number'
    ? number
    : TDataType extends 'paymentMethod' | 'paymentSource'
      ? Api.PaymentMethodType
      : never
export type DimensionDataTypeMap<TDimension extends Dimension> =
  DataTypeTSTypeMap<CubeSchema[TDimension]['dataType']>

export const getDimensionDataType = (
  dimension: keyof typeof dimensionToSchema,
) => dimensionToSchema[dimension].dataType

export const dimensionToSchema = {
  'Users.uuid': {
    dataType: 'string',
    defaultInflection: 'User UUID',
  },
  'Users.id': {
    dataType: 'number',
    defaultInflection: 'User',
  },
  'Users.name': {
    dataType: 'string',
    defaultInflection: 'Name',
  },
  'Users.displayName': {
    dataType: 'string',
    defaultInflection: 'Unit',
  },
  'Users.email': {
    dataType: 'string',
    defaultInflection: 'Email',
  },
  'Users.status': {
    dataType: 'userStatus',
    defaultInflection: 'Status',
  },
  'Users.unit': {
    dataType: 'string',
    defaultInflection: 'Unit',
  },
  'Users.balanceAvailable': {
    dataType: 'currency',
    defaultInflection: 'Balance Available',
  },
  'Payments.totalPaid': {
    dataType: 'currency',
    defaultInflection: 'Total Collected',
  },
  'Users.totalReceived': {
    dataType: 'currency',
    defaultInflection: 'Total Collected',
  },
  'Users.collectionCount': {
    dataType: 'currency',
    defaultInflection: '# of Collections',
  },
  'Users.profilePicturePath': {
    dataType: 'string',
    defaultInflection: 'Profile pic',
  },
  'Users.profilePictureMetadata': {
    dataType: 'string',
    defaultInflection: 'Profile pic meta',
  },
  'Users.banksLast4': {
    dataType: 'string',
    defaultInflection: 'Bank account',
  },
  'Users.createdAt': {
    dataType: 'date',
    defaultInflection: 'Sign Up Date',
  },
  'Collections.id': {
    dataType: 'number',
    defaultInflection: 'Collection',
  },
  'Collections.createdAt': {
    dataType: 'date',
    defaultInflection: 'Created Date',
  },
  'Collections.lastActivity': {
    dataType: 'date',
    defaultInflection: 'Last Activity Date',
  },
  'Collections.name': {
    dataType: 'string',
    defaultInflection: 'Collection Name',
  },
  'Collections.paymentsCount': {
    dataType: 'number',
    defaultInflection: 'Payment Count',
  },
  'Collections.visitCount': {
    dataType: 'number',
    defaultInflection: 'Visit Count',
  },
  'Collections.totalCollected': {
    dataType: 'currency',
    defaultInflection: 'Total Collected',
  },
  'Customers.name': {
    dataType: 'string',
    defaultInflection: 'Payer Name',
  },
  'Customers.email': {
    dataType: 'string',
    defaultInflection: 'Payer Email',
  },
  'Customers.emailCount': {
    dataType: 'number',
    defaultInflection: '?',
  },
  'Customers.collectionCount': {
    dataType: 'number',
    defaultInflection: 'Collection Visits',
  },
  'Customers.paymentCount': {
    dataType: 'number',
    defaultInflection: '# of Payments',
  },
  'Customers.paymentCountDimension': {
    dataType: 'number',
    defaultInflection: '# of Payments',
  },
  'Customers.visitCount': {
    dataType: 'number',
    defaultInflection: '# of Visits',
  },
  'Collections.paymentsTotal': {
    dataType: 'currency',
    defaultInflection: 'Collected',
  },
  'Collections.status': {
    dataType: 'collectionStatus',
    defaultInflection: 'Status',
  },
  'Collection.paymentsCount': {
    dataType: 'number',
    defaultInflection: '# of Payments',
  },
  'Payments.id': {
    dataType: 'number',
    defaultInflection: 'Payment Id',
  },
  'Payments.createdAt': {
    dataType: 'date',
    defaultInflection: 'Payment Date',
  },
  'Payments.paymentSource': {
    dataType: 'paymentSource',
    defaultInflection: 'Payment Source',
  },
  'Payments.paymentSourceLast4': {
    dataType: 'string',
    defaultInflection: 'Last 4',
  },
  'Customers.firstSeenAt': {
    dataType: 'date',
    defaultInflection: 'First Payment Date',
  },
  'Customers.lastSeenAt': {
    dataType: 'date',
    defaultInflection: 'Last Payment Date',
  },
  'Customers.firstVisit': {
    dataType: 'date',
    defaultInflection: 'First Visit Date',
  },
  'Customers.lastVisit': {
    dataType: 'date',
    defaultInflection: 'Last Visit Date',
  },
  'Payments.total': {
    dataType: 'currency',
    defaultInflection: 'Amount',
  },
  'Customers.totalPaid': {
    dataType: 'currency',
    defaultInflection: 'Payments Total',
  },
  'Customers.totalQuantity': {
    dataType: 'number',
    defaultInflection: 'Items Purchased',
  },
  'Payments.count': {
    dataType: 'number',
    defaultInflection: '# of Orders',
  },
  'Payments.paymentMethod': {
    dataType: 'paymentMethod',
    defaultInflection: 'Payment Method',
  },
  'Payments.status': {
    dataType: 'paymentStatus',
    defaultInflection: 'Payment Status',
  },
  'Payments.isPointOfSale': {
    dataType: 'paymentSource',
    defaultInflection: 'Payment Source',
  },
  'Withdrawals.createdAt': {
    dataType: 'date',
    defaultInflection: 'Withdrawal Date',
  },
  'Withdrawals.amount': {
    dataType: 'currency',
    defaultInflection: 'Withdrawal Amount',
  },
  'Withdrawals.payoutMethod': {
    dataType: 'paymentMethod',
    defaultInflection: 'Payout Method',
  },
  'Withdrawals.description': {
    dataType: 'string',
    defaultInflection: 'Withdrawal Description',
  },
  'Withdrawals.status': {
    dataType: 'withdrawalStatus',
    defaultInflection: 'Withdrawal Status',
  },
  'Withdrawals.count': {
    dataType: 'number',
    defaultInflection: 'Withdrawals Count',
  },
  'WithdrawalAccounts.last4': {
    dataType: 'string',
    defaultInflection: 'Bank Account',
  },
  'OrgMemberInvites.createdAt': {
    dataType: 'date',
    defaultInflection: 'Initial Invite Date',
  },
  'OrgMemberInvites.id': {
    dataType: 'number',
    defaultInflection: 'Invite',
  },
  'OrgMemberInvites.firstName': {
    dataType: 'string',
    defaultInflection: 'First Name',
  },
  'OrgMemberInvites.lastName': {
    dataType: 'string',
    defaultInflection: 'Last Name',
  },
  'OrgMemberInvites.accepted': {
    dataType: 'orgMemberInviteStatus',
    defaultInflection: 'Invite Status',
  },
  'OrgMemberInvites.email': {
    dataType: 'string',
    defaultInflection: 'Email',
  },
  'SentEmails.status': {
    dataType: 'sentEmailStatus',
    defaultInflection: 'Email Status',
  },
} as const

export const formatCubeValue = (value: string, dimension: Dimension) => {
  const dataType = dimensionToSchema[dimension]?.dataType ?? 'string'
  switch (dataType) {
    case 'number':
      return Number(value ?? 0).toFixed(0)
    case 'date':
      return Util.formatDateAs(new Date(value), 'date_tabular') ?? '–'
    case 'currency':
      return Util.formatAmount(value ?? 0)
    case 'paymentMethod':
      return (
        (PAYMENT_METHOD_LABELS[value as DataTypeTSTypeMap<'paymentMethod'>] ??
          Util.capitalize(value ?? '')) ||
        '–'
      )
    case 'paymentStatus':
      return (
        (PAYMENT_METHOD_STATUSES.card?.[value] ?? Util.capitalize(value)) || '–'
      )
    case 'paymentSource':
      return {true: 'Point of Sale', false: 'Online'}[value]
    default:
      return value || '–'
  }
}
