import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'

export default {
  itemsXlsx: makeEndpoint({
    path: 'users/tabs/:tabId/exports/items.xlsx',
    responseSchema: z.any(),
  }),
  paymentsXlsx: makeEndpoint({
    path: 'users/tabs/:tabId/exports/payments.xlsx',
    method: 'POST',
    bodySchema: z.object({}),
    responseSchema: z.object({
      file_url: z.string(),
      message: z.string(),
    }),
  }),
  visitorsXlsx: makeEndpoint({
    path: 'users/tabs/:tabId/exports/visitors.xlsx',
    responseSchema: z.any(),
  }),
  reconciliation: makeEndpoint({
    path: 'users/exports/reconciliation',
    method: 'POST',
    bodySchema: z.object({
      interval_start: z.number(),
      interval_end: z.number(),
    }),
    responseSchema: z.any(),
  }),
}
