import * as Util from '@cheddarup/util'

import {makeQueryUpdate, makeUseMutation} from '../use-mutation'
import {endpoints} from '../../endpoints'
import {getEndpointKey} from '../../utils'

export const useCreateFormMutation = makeUseMutation(
  endpoints.tabForms.create,
  (vars) => ({
    regular: (newForm) => [
      makeQueryUpdate(
        endpoints.tabForms.list,
        (prevForms) => (prevForms ? [...prevForms, newForm] : prevForms),
        vars,
      ),
      makeQueryUpdate(
        endpoints.tabs.detail,
        (prevTab) =>
          prevTab
            ? {
                ...prevTab,
                reportsAvailable: {
                  ...prevTab.reportsAvailable,
                  activeFormsCount:
                    prevTab.reportsAvailable.activeFormsCount + 1,
                },
              }
            : prevTab,
        vars,
      ),
    ],
  }),
  (queryClient) => ({
    onSuccess: (_res, vars) => {
      const tabsDetailEndpointKey = getEndpointKey(endpoints.tabs.detail, vars)
      queryClient.invalidateQueries({queryKey: tabsDetailEndpointKey})
    },
  }),
)

export const useUpdateFormMutation = makeUseMutation(
  endpoints.tabForms.update,
  (vars) => ({
    regular: (newForm) => [
      makeQueryUpdate(
        endpoints.tabForms.list,
        (prevForms) =>
          prevForms?.map((f) => (f.id === newForm.id ? newForm : f)),
        vars,
      ),
      makeQueryUpdate(endpoints.tabForms.detail, () => newForm, vars),
    ],
  }),
)

export const useUpdateBatchFormsMutation = makeUseMutation(
  endpoints.tabForms.updateBatch,
  (vars) => ({
    regular: (newForms) => {
      const newFormsById = Util.mapToObj(newForms, (f) => [f.id, f])

      return [
        makeQueryUpdate(
          endpoints.tabForms.list,
          (prevForms) =>
            prevForms?.map((f) =>
              newFormsById[f.id] ? {...f, ...newFormsById[f.id]} : f,
            ),
          vars,
        ),
        ...newForms.map((f) =>
          makeQueryUpdate(
            endpoints.tabForms.detail,
            (prevForm) => (prevForm ? {...prevForm, ...f} : prevForm),
            {
              pathParams: {
                tabId: vars.pathParams.tabId,
                formId: f.id,
              },
            },
          ),
        ),
      ]
    },
  }),
)

export const useDeleteFormMutation = makeUseMutation(
  endpoints.tabForms.delete,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(
        endpoints.tabForms.list,
        (prevForms) =>
          prevForms?.filter((f) => f.id !== Number(vars.pathParams.formId)),
        vars,
      ),
      makeQueryUpdate(
        endpoints.tabs.detail,
        (tab) =>
          tab
            ? {
                ...tab,
                reportsAvailable: {
                  ...tab.reportsAvailable,
                  activeFormsCount: tab.reportsAvailable.activeFormsCount - 1,
                },
              }
            : tab,
        vars,
      ),
    ],
  }),
  (queryClient) => ({
    onSuccess: (_res, vars) => {
      const tabsDetailEndpointKey = getEndpointKey(endpoints.tabs.detail, vars)
      queryClient.invalidateQueries({queryKey: tabsDetailEndpointKey})
    },
  }),
)

export const useBulkDeleteFormsMutation = makeUseMutation(
  endpoints.tabForms.deleteBulk,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(
        endpoints.tabForms.list,
        (prevForms) =>
          prevForms?.filter((f) => !vars.body?.tab_item_ids.includes(f.id)),
        vars,
      ),
    ],
  }),
  (queryClient) => ({
    onSuccess: (_newData, vars) => {
      const tabDetailQueryKey = getEndpointKey(endpoints.tabs.detail, vars)
      queryClient.invalidateQueries({queryKey: tabDetailQueryKey})
    },
  }),
)

export const useCloneFormMutation = makeUseMutation(
  endpoints.tabForms.clone,
  (vars) => ({
    regular: (newForm) => [
      makeQueryUpdate(
        endpoints.tabForms.list,
        (prevForms) => {
          if (!prevForms) {
            return prevForms
          }

          const clonedFormIdx = prevForms.findIndex(
            (f) => f.id === Number(vars.pathParams.formId),
          )
          return [
            ...prevForms.slice(0, clonedFormIdx),
            newForm,
            ...prevForms.slice(clonedFormIdx),
          ]
        },
        vars,
      ),
      makeQueryUpdate(
        endpoints.tabs.detail,
        (prevTab) =>
          prevTab
            ? {
                ...prevTab,
                reportsAvailable: {
                  ...prevTab.reportsAvailable,
                  activeFormsCount:
                    prevTab.reportsAvailable.activeFormsCount + 1,
                },
              }
            : prevTab,
        vars,
      ),
    ],
  }),
  (queryClient) => ({
    onSuccess: (_newForm, vars) => {
      const tabDetailEndpointKey = getEndpointKey(endpoints.tabs.detail, vars)
      queryClient.invalidateQueries({queryKey: tabDetailEndpointKey})
    },
  }),
)

export const useMoveFormsMutation = makeUseMutation(endpoints.tabForms.move)
