import {QueryClient, UseMutationOptions} from '@tanstack/react-query'
import {z} from '@cheddarup/util'

import {Endpoints, endpoints} from '../../endpoints'
import {QueryUpdate, makeUseMutation} from '../use-mutation'
import {FetchInput} from '../../utils'

export const useCreateContactMutation = makeUseMutation(
  endpoints.misc.sendFeedback,
)
export const useLoggerMutation = makeUseMutation(endpoints.misc.log)

export interface BatchResAndOp {
  op: {
    url: string
    method: string
    params?: Record<string, any> | undefined
  }
  result: any
}

export function makeUseBatchMutation<
  TVariables extends FetchInput<Endpoints['misc']['batch']> = FetchInput<
    Endpoints['misc']['batch']
  >,
  TData extends z.infer<Endpoints['misc']['batch']['responseSchema']> = z.infer<
    Endpoints['misc']['batch']['responseSchema']
  >,
>(
  _cacheUpdatesBuilder?: (
    vars: TVariables,
    queryClient: QueryClient,
  ) => {
    regular?: (newData: TData, resAndOps: BatchResAndOp[]) => QueryUpdate[]
    optimistic?: QueryUpdate[]
  },
  patchMutationOptions?: (
    queryClient: QueryClient,
  ) => Omit<
    UseMutationOptions<TData, unknown, TVariables, unknown>,
    'mutationFn'
  >,
) {
  return makeUseMutation(
    endpoints.misc.batch,
    (vars, queryClient) => {
      const updates = _cacheUpdatesBuilder?.(vars, queryClient)

      return {
        regular: (newData) => {
          const resAndOps = newData.results
            .map((result, idx) => {
              const op = vars.body?.ops[idx]
              if (result.status < 200 || result.status >= 300 || !op) {
                return undefined
              }
              return {op, result: result.body}
            })
            .filter((r) => r != null)

          return updates?.regular?.(newData, resAndOps) ?? []
        },
        optimistic: updates?.optimistic,
      }
    },
    patchMutationOptions,
  )
}

export const useReportDeviceMutation = makeUseMutation(
  endpoints.misc.reportDevice,
)

export const useContactUserMutation = makeUseMutation(
  endpoints.misc.contactUserBySlug,
)

export const useCreateStripeAccountLinkMutation = makeUseMutation(
  endpoints.misc.createStripeAccountLink,
)
