import React from 'react'
import {useLocation} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'
import {api} from '@cheddarup/api-client'
import {
  useManagerRole,
  useManagerRoleId,
} from 'src/components/ManageRoleProvider'
import {LinkButton} from 'src/components/LinkButton'

const WithdrawPanel = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => {
  const [managerRoleId] = useManagerRoleId()
  const [managerRole] = useManagerRole()
  const {data: ownTotalAvailableBalance} = api.auth.session.useQuery(
    undefined,
    {
      select: (session) => session.user.withdrawal_data.total_available_balance,
      enabled: managerRoleId == null,
    },
  )
  const location = useLocation()

  return (
    <WebUI.Panel
      className={WebUI.cn('gap-3 px-8 py-6', className)}
      as={WebUI.VStack}
      {...restProps}
    >
      <WebUI.Text className="text-ds-xs" variant="subtle">
        AVAILABLE BALANCE
      </WebUI.Text>
      <WebUI.Text className="font-light text-ds-2xl">
        {Util.formatAmount(
          managerRole?.withdrawal_data?.total_available_balance ??
            ownTotalAvailableBalance ??
            0,
        )}
      </WebUI.Text>
      <WebUI.HStack>
        <LinkButton
          to="/withdraw"
          state={{prevPath: location.pathname}}
          variant="default"
        >
          Transfer Money
        </LinkButton>
      </WebUI.HStack>
    </WebUI.Panel>
  )
}

export default WithdrawPanel
