import {useNavigate} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {useCheckSubscriptionMutation} from '@cheddarup/api-client'

import {Warning} from './components'

const ProPlanWarningPage = () => {
  const navigate = useNavigate()
  const [managers, setManagers] = useState<
    Awaited<
      ReturnType<(typeof checkSubscriptionMutation)['mutateAsync']>
    >['results']
  >([])
  const checkSubscriptionMutation = useCheckSubscriptionMutation()
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    ;(async () => {
      const res = await checkSubscriptionMutation.mutateAsync({
        body: {plan: 'free'},
      })
      const newManagers = (res.results ?? []).filter(
        (result) => result.warning === 'managers_will_be_deleted',
      )
      setManagers(newManagers ?? [])
    })()
  }, [])

  return (
    <Warning
      managers={managers}
      handleDismiss={() => navigate('..')}
      onSubmit={() => navigate('../pro')}
    />
  )
}

export default ProPlanWarningPage
