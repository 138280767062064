import {
  matchPath,
  resolvePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import {useRef} from 'react'
import {api} from '@cheddarup/api-client'
import {Link} from 'src/components/Link'
import {RouteBlock} from 'src/components/RouteBlock'
import {PremiumFeaturesSideSheet} from 'src/components/PremiumFeaturesSideSheet'
import * as Util from '@cheddarup/util'

import {ItemCategoryList} from './containers/ItemsContainer/ItemCategoryList'
import {ItemsNav} from './components'
import {ItemCountLimitMeter} from './components/ItemsNav'

const ItemsPage: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const urlParams = useParams()
  const media = WebUI.useMedia()
  const hasItemCatalogsQuery = api.itemCatalogs.list.useQuery(undefined, {
    enabled: !media.sm,
    select: (data) => data.length > 0,
  })
  const tabQuery = api.tabs.detail.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: urlParams.collection!,
    },
  })
  const addItemDrawerRef = useRef<WebUI.DialogInstance>(null)

  const hasNoItems =
    !!tabQuery.data && tabQuery.data.reportsAvailable.activeItemsCount === 0

  const itemsNavElement = (
    <ItemsNav className="shrink-0 grow basis-auto bg-gray100 sm:flex-0 lg:bg-teal-80" />
  )

  return (
    <PremiumFeaturesSideSheet tabId={Number(urlParams.collection)}>
      <div className="flex max-h-full min-h-0 shrink-0 grow basis-auto flex-col">
        <div className="flex max-h-full min-h-0 flex-auto flex-row">
          {media.sm ? (
            itemsNavElement
          ) : (
            <WebUI.Drawer ref={addItemDrawerRef} disclosure={null}>
              {itemsNavElement}
            </WebUI.Drawer>
          )}

          <ItemCategoryList
            className="min-w-0 flex-auto"
            onAddItem={(type = 'fixed') => {
              if (media.sm || !hasItemCatalogsQuery.data) {
                navigate({
                  pathname: 'add-item',
                  search: Util.mergeSearchParams(location.search, {type}),
                })
              } else {
                addItemDrawerRef.current?.show()
              }
            }}
            onAddTicket={() => navigate('add-ticket')}
          />
        </div>
        <WebUI.PageToolbar className="flex-0">
          {!media.sm && tabQuery.data && tabQuery.data.itemLimit != null && (
            <ItemCountLimitMeter
              maxValue={tabQuery.data.itemLimit}
              value={tabQuery.data.reportsAvailable.activeItemsCount}
            />
          )}
          <WebUI.PageToolbarSubmitButton as={Link} to="../forms">
            Continue
          </WebUI.PageToolbarSubmitButton>
        </WebUI.PageToolbar>

        <RouteBlock
          when={(nextPath) =>
            !!hasNoItems &&
            [
              '/collection/:collection/details/forms',
              '/collection/:collection/details/settings',
              '/collection/:collection/manage',
              '/collection/:collection/share',
            ].some(
              (pathname) =>
                !!matchPath(pathname, resolvePath(nextPath).pathname),
            )
          }
        >
          {({isBlockerVisible, onBlockerDidHide, onProceed}) => (
            <NoItemsConfirmAlert
              visible={isBlockerVisible}
              onProceed={onProceed}
              onDidHide={() => onBlockerDidHide()}
            />
          )}
        </RouteBlock>
      </div>
    </PremiumFeaturesSideSheet>
  )
}

// MARK: – NoItemsConfirmAlert

export interface NoItemsConfirmAlertProps extends WebUI.AlertProps {
  onProceed?: () => void
}

export const NoItemsConfirmAlert = ({
  onProceed,
  ...restProps
}: NoItemsConfirmAlertProps) => {
  const navigate = useNavigate()
  return (
    <WebUI.Alert
      aria-label="Continuing without items confirmation"
      {...restProps}
    >
      {(dialog) => (
        <>
          <WebUI.AlertHeader>
            Hold on – you don't want to receive payments?
          </WebUI.AlertHeader>
          <WebUI.AlertContentView
            text="You haven't added any items for your payers to select. Items are required to receive payments. Are you sure you'd like to continue?"
            actions={
              <>
                <WebUI.AlertActionButton
                  onClick={(event) => {
                    event.preventDefault()
                    dialog.hide()
                    navigate('add-item')
                  }}
                >
                  Add Item
                </WebUI.AlertActionButton>
                <WebUI.AlertCancelButton onClick={() => onProceed?.()}>
                  I'm not collecting payments
                </WebUI.AlertCancelButton>
              </>
            }
          />
        </>
      )}
    </WebUI.Alert>
  )
}

export default ItemsPage
