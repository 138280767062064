import lottie, {AnimationItem} from 'lottie-web/build/player/lottie_light'
import React, {useImperativeHandle, useLayoutEffect, useRef} from 'react'

import {cn} from '../utils'

export interface LottieInstance {
  play: () => void
  pause: () => void
  stop: () => void
  setSpeed: (speed: number) => void
}

export interface LottieProps extends React.ComponentPropsWithRef<'div'> {
  animationData?: unknown
  width: React.CSSProperties['width']
  height: React.CSSProperties['height']
  loop?: boolean | number
  autoplay?: boolean
  onEnterFrame?: (event: unknown) => void
  onComplete?: (event: unknown) => void
}

export const Lottie = React.forwardRef<LottieInstance, LottieProps>(
  (
    {
      className,
      animationData,
      width,
      height,
      loop,
      autoplay,
      onEnterFrame,
      onComplete,
      ...restProps
    },
    forwardedRef,
  ) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const animRef = useRef<AnimationItem | null>(null)

    useLayoutEffect(() => {
      const container = containerRef.current
      if (container) {
        const anim = lottie.loadAnimation({
          container,
          animationData,
          renderer: 'svg',
          loop: loop !== false,
          autoplay: autoplay !== false,
        })
        animRef.current = anim
        return () => anim.destroy()
      }
      return
    }, [animationData, autoplay, loop])

    useImperativeHandle(
      forwardedRef,
      (): LottieInstance => ({
        play: () => animRef.current?.play(),
        pause: () => animRef.current?.pause(),
        stop: () => animRef.current?.stop(),
        setSpeed: (newSpeed: number) => animRef.current?.setSpeed(newSpeed),
      }),
      [],
    )

    return (
      <div
        ref={containerRef}
        role="img"
        aria-label="Animation"
        className={cn('overflow-hidden will-change-transform', className)}
        style={{
          width,
          height,
        }}
        {...restProps}
      />
    )
  },
)
