import {Outlet, Route} from 'react-router-dom'
import {PageContainer} from 'src/components/PageContainer'
import EditAttendeePage from 'src/views/collection/manage/CollectionManageItemsTab/TicketSpecificPage/EditAttendeePage'
import InvoiceDetailsPage from 'src/views/payments/InvoiceDetailsPage'
import PaymentDetailsPage from 'src/views/payments/PaymentDetailsPage'
import PaymentRefundsPage from 'src/views/payments/PaymentRefundsPage'

export const paymentToOthersRoutes = [
  <Route key=":payment" path=":payment">
    <Route
      path="details/*"
      element={
        <PageContainer>
          <PaymentDetailsPage />
          <Outlet />
        </PageContainer>
      }
    >
      <Route
        path="edit-attendee"
        element={<EditAttendeePage paymentType="toOthers" />}
      />
    </Route>
    <Route
      path="refunds"
      element={
        <PageContainer>
          <PaymentRefundsPage />
          <Outlet />
        </PageContainer>
      }
    />
    <Route
      path="scheduled/:invoice/*"
      element={
        <PageContainer>
          <InvoiceDetailsPage />
          <Outlet />
        </PageContainer>
      }
    />
    <Route
      path="recurring/*"
      element={
        <PageContainer>
          <InvoiceDetailsPage />
          <Outlet />
        </PageContainer>
      }
    />
  </Route>,
]
