export {getScrollingElement} from '@ariakit/core/utils/dom'

export function getScrollingPortalElement(element?: Element | null) {
  if (!element) {
    return null
  }
  if (isPortalId(element.id)) {
    return element
  }

  return getScrollingPortalElement(element.parentElement)
}

// See https://github.com/ariakit/ariakit/blob/main/packages/ariakit-react-core/src/portal/portal.tsx#L108
function isPortalId(candidateId: string) {
  return /portal\/:[^:]+:/.test(candidateId)
}
