import {
  createSearchParams,
  LinkProps as LinkPrimitiveProps,
  useHref,
  useLinkClickHandler,
  useLocation,
  useResolvedPath,
} from 'react-router-dom'
import React from 'react'
import {NextAnchor, NextAnchorProps} from '@cheddarup/web-ui/next'

export interface NextLinkProps
  extends Omit<LinkPrimitiveProps, 'relative'>,
    UseLinkOptions,
    NextAnchorProps {}

export const NextLink = React.forwardRef<HTMLAnchorElement, NextLinkProps>(
  (
    {
      to,
      reloadDocument,
      preventScrollReset,
      relative,
      target,
      replace,
      state,
      onClick: onClickProp,
      preserveSearch,
      ...restProps
    },
    forwardedRef,
  ) => {
    const {onClick, ...linkProps} = useLinkProps({
      preserveSearch,
      relative,
      to,
      replace,
      state,
      target,
      preventScrollReset,
    })

    return (
      <NextAnchor
        ref={forwardedRef}
        onClick={(event) => {
          onClickProp?.(event)
          if (reloadDocument !== true && !event.defaultPrevented) {
            onClick(event)
          }
        }}
        {...linkProps}
        {...restProps}
      />
    )
  },
)

// MARK: – Hooks

// Default relative="path" is contextual
// See https://reactrouter.com/en/main/components/link#relative
type LinkRelativeRoutingType =
  | LinkPrimitiveProps['relative']
  | 'nonContextualPath'

interface UseLinkOptions
  extends Pick<
    NextLinkProps,
    'to' | 'replace' | 'state' | 'target' | 'preventScrollReset'
  > {
  preserveSearch?: boolean
  relative?: LinkRelativeRoutingType
}

function useLinkProps({
  preserveSearch,
  relative,
  to,
  replace,
  state,
  target,
  preventScrollReset,
}: UseLinkOptions) {
  const location = useLocation()
  const toPath = useResolvedPath(to)

  const stdRelative = relative === 'nonContextualPath' ? 'route' : relative
  const newPath = toPath

  if (preserveSearch) {
    newPath.search = createSearchParams({
      ...Object.fromEntries(createSearchParams(location.search)),
      ...Object.fromEntries(createSearchParams(toPath.search)),
    }).toString()
  }

  if (relative === 'nonContextualPath') {
    const goBacksCount = toPath.pathname.split('..').length - 1

    const toPathnamePrefix = location.pathname
      .split('/')
      .slice(0, goBacksCount === 0 ? undefined : -goBacksCount)
      .join('/')

    const toPathnameWithoutGoBacks = toPath.pathname
      .split('/')
      .filter((segment) => segment !== '..' && segment !== '.')
      .join('/')

    newPath.pathname = `${toPathnamePrefix}/${toPathnameWithoutGoBacks}`
  }

  return {
    href: useHref(toPath, {relative: stdRelative}),
    onClick: useLinkClickHandler(newPath, {
      replace,
      state,
      target,
      relative: stdRelative,
      preventScrollReset,
    }),
    target,
  }
}
