import {isItemViewSoldOut} from '@cheddarup/core'

import useCart from '../hooks/useCart'
import usePublicCollection from '../hooks/usePublicCollection'

export function useIsItemViewSoldOut({
  itemId,
  listingUuid,
}: {
  itemId: number
  listingUuid?: string | null
}) {
  const {publicCollection} = usePublicCollection()
  const {cart} = useCart()

  const itemView = publicCollection.items.find((i) => i.id === itemId)

  if (!itemView) {
    throw new Error(`Item with id "${itemId}" not found`)
  }

  return isItemViewSoldOut({itemView, listingUuid, cart})
}
