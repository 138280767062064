import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {LinkButton} from 'src/components/LinkButton'
import {Link} from 'src/components/Link'
import {Logo} from 'src/components'
import config from 'src/config'
import {SideNavNavLinkButton} from 'src/components/SideNavNavLinkButton'

export const GuestHeader: React.FC<React.ComponentPropsWithoutRef<'div'>> = ({
  className,
  ...restProps
}) => {
  const media = WebUI.useMedia()
  const logoEl = (
    <WebUI.AnchorButton href={config.links.marketingPage}>
      <Logo
        className={
          '[&_.LogoIcon-graphics]:h-[27px] sm:[&_.LogoIcon-graphics]:h-9 [&_>_.LogoIcon-text]:h-[17.25px] sm:[&_>_.LogoIcon-text]:h-[23px]'
        }
        forceDisplayText
      />
    </WebUI.AnchorButton>
  )

  return (
    <WebUI.NavigationBar
      className={WebUI.cn('border-b', className)}
      left={media.sm ? logoEl : <GuestSidebar />}
      center={media.sm ? null : logoEl}
      right={
        media.sm ? (
          <WebUI.HStack className="items-center gap-6">
            {/* TODO: add some search params to prefill signup form */}
            <WebUI.HStack className="items-center gap-4">
              <LinkButton variant="primary" to="/signup">
                Create an account
              </LinkButton>
              <Link className="text-ds-sm" variant="primary" to="/logout">
                Exit Order History
              </Link>
            </WebUI.HStack>
          </WebUI.HStack>
        ) : null
      }
      {...restProps}
    />
  )
}

// MARK: – GuestSidebar

export const GuestSidebar: React.FC<WebUI.SideNavProps> = ({
  className,
  ...restProps
}) => (
  <WebUI.SideNav
    className={WebUI.cn('[&_.Button-content]:uppercase', className)}
    disclosure={
      <WebUI.DialogDisclosure as={WebUI.IconButton}>
        <WebUI.PhosphorIcon icon="list" width={30} />
      </WebUI.DialogDisclosure>
    }
    {...restProps}
  >
    <SideNavNavLinkButton
      className="m-5 h-[2.5rem] self-start text-center [&_>_.Button-content]:normal-case"
      size="large"
      variant="primary"
      to="/signup"
    >
      Start Collecting – FREE
    </SideNavNavLinkButton>

    <WebUI.Separator />
    <WebUI.SideNavButton
      as="a"
      href={config.links.marketingPage}
      target="_blank"
      rel="noopener noreferrer"
    >
      What is Cheddar Up?
    </WebUI.SideNavButton>
    <WebUI.Separator />
    <WebUI.SideNavButton
      as="a"
      href={`${config.links.marketingPage}/features`}
      target="_blank"
      rel="noopener noreferrer"
    >
      Explore features
    </WebUI.SideNavButton>
    <WebUI.Separator />
    <WebUI.SideNavButton
      as="a"
      href={`${config.links.marketingPage}/reviews`}
      target="_blank"
      rel="noopener noreferrer"
    >
      User stories
    </WebUI.SideNavButton>
    <WebUI.Separator />
    <WebUI.SideNavButton
      as="a"
      href={`${config.links.marketingPage}/pricing`}
      target="_blank"
      rel="noopener noreferrer"
    >
      Pricing
    </WebUI.SideNavButton>
    <WebUI.Separator />
    <WebUI.SideNavButton
      as="a"
      href={`${config.links.marketingPage}/blog`}
      target="_blank"
      rel="noopener noreferrer"
    >
      Latest news
    </WebUI.SideNavButton>

    <WebUI.Separator />
    <SideNavNavLinkButton
      className={
        'mx-5 mt-5 h-[1.875rem] w-[160px] self-start text-center [&_.Button-content]:normal-case'
      }
      variant="secondary"
      to="/logout"
    >
      Exit Order History
    </SideNavNavLinkButton>
  </WebUI.SideNav>
)
