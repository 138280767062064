import * as Ariakit from '@ariakit/react'
import React, {useImperativeHandle} from 'react'

import {NextButton} from './Button'
import {Heading} from '../Heading'

export interface DialogInstance extends Ariakit.DialogStore {}

export interface DialogProps
  extends Omit<Ariakit.DialogStoreProps, 'disclosure'> {
  disclosure?: React.ReactNode
  children?: React.ReactNode | ((dialog: DialogInstance) => React.ReactNode)
}

export const Dialog = React.forwardRef<DialogInstance, DialogProps>(
  ({disclosure, children, ...restProps}, forwardedRef) => {
    const dialog = Ariakit.useDialogStore(restProps)

    useImperativeHandle(forwardedRef, () => dialog, [dialog])

    return (
      <Ariakit.DialogProvider store={dialog}>
        {disclosure}
        {typeof children === 'function' ? children(dialog) : children}
      </Ariakit.DialogProvider>
    )
  },
)

// MARK: - DialogDisclosure

export interface DialogDisclosureProps extends Ariakit.DialogDisclosureProps {}

export const DialogDisclosure = React.forwardRef<
  HTMLButtonElement,
  DialogDisclosureProps
>((props, forwardedRef) => (
  <Ariakit.DialogDisclosure
    ref={forwardedRef}
    render={<NextButton />}
    {...props}
  />
))

// MARK: - DialogContent

export interface DialogContentProps
  extends Omit<Ariakit.DialogProps, 'children'> {
  children?: React.ReactNode | ((dialog: DialogInstance) => React.ReactNode)
}

export const DialogContent = React.forwardRef<
  HTMLDivElement,
  DialogContentProps
>(({children, ...restProps}, forwardedRef) => {
  const dialog = useDialog()
  const isOpen = Ariakit.useStoreState(dialog, (s) => s.open)
  const isAnimating = Ariakit.useStoreState(dialog, (s) => s.animating)

  if (!isOpen && !isAnimating) {
    return null
  }

  return (
    <Ariakit.Dialog ref={forwardedRef} {...restProps}>
      {typeof children === 'function' ? children(dialog) : children}
    </Ariakit.Dialog>
  )
})

// MARK: – DialogHeading

export interface DialogHeadingProps extends Ariakit.DialogHeadingProps {}

export const DialogHeading = React.forwardRef<
  HTMLHeadingElement,
  DialogHeadingProps
>((props, forwardedRef) => (
  <Ariakit.DialogHeading
    ref={forwardedRef}
    render={<Heading as="h2" />}
    {...props}
  />
))

// MARK: – Hooks

export function useDialog() {
  const dialog = Ariakit.useDialogContext()

  if (!dialog) {
    throw new Error('useDialog must be used in a component wrapped by Dialog')
  }

  return dialog
}
