import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {bankAccountSchema, creditCardSchema} from '../schemas'

export default {
  list: makeEndpoint({
    path: 'users/payment_methods',
    responseSchema: z.object({
      banks: bankAccountSchema.array(),
      cards: creditCardSchema.array(),
    }),
  }),
  create: makeEndpoint({
    path: 'users/payment_methods',
    method: 'POST',
    bodySchema: z.object({
      nickname: z.string().optional(),
      default: z.boolean().optional(),
      source: z.string(),
      hidden: z.boolean().optional(),
    }),
    responseSchema: z.union([bankAccountSchema, creditCardSchema]),
  }),
  update: makeEndpoint({
    path: 'users/payment_methods/:paymentMethodId',
    method: 'PUT',
    bodySchema: z.object({
      nickname: z.string().optional(),
    }),
    responseSchema: z.union([bankAccountSchema, creditCardSchema]),
  }),
  delete: makeEndpoint({
    path: 'users/payment_methods/:paymentMethodId',
    method: 'DELETE',
    bodySchema: z.object({
      nickname: z.string().optional(),
    }),
    responseSchema: z.union([bankAccountSchema, creditCardSchema]),
  }),
  createSetupIntent: makeEndpoint({
    path: 'users/payment_methods/setup_intent',
    method: 'POST',
    responseSchema: z.object({
      clientSecret: z.string(),
      setupIntentId: z.string(),
    }),
  }),
}
