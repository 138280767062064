// Inspiredy by https://github.com/adobe/react-spectrum/blob/main/packages/react-aria-components/src/ColorPicker.tsx

import {useControlledState} from '@cheddarup/react-util'
import React, {useContext, useImperativeHandle, useMemo} from 'react'
import {
  Popover,
  PopoverContent,
  PopoverInstance,
  PopoverProps,
} from '../Popover'
import {Merge} from '@cheddarup/util'

interface InternalColorPickerContextValue {
  value: string
  setValue: (value: string) => void
}

const InternalColorPickerContext = React.createContext(
  undefined as InternalColorPickerContextValue | undefined,
)

export interface ColorPickerInstance
  extends Pick<InternalColorPickerContextValue, 'value' | 'setValue'> {}

export interface ColorPickerProps {
  defaultValue?: string
  value?: string
  onValueChange?: (hex: string | null) => void
  children:
    | React.ReactNode
    | ((colorPicker: ColorPickerInstance) => React.ReactNode)
}

export const ColorPicker = React.forwardRef<
  ColorPickerInstance,
  ColorPickerProps
>(
  (
    {defaultValue: defaultValueProp, value: valueProp, onValueChange, children},
    forwardedRef,
  ) => {
    const [value, setValue] = useControlledState(
      valueProp,
      defaultValueProp || '#000000',
      onValueChange,
    )

    const contextValue: InternalColorPickerContextValue = useMemo(
      () => ({
        value,
        setValue,
      }),
      [value, setValue],
    )

    useImperativeHandle(forwardedRef, () => contextValue, [contextValue])

    return (
      <InternalColorPickerContext.Provider value={contextValue}>
        {typeof children === 'function' ? children(contextValue) : children}
      </InternalColorPickerContext.Provider>
    )
  },
)

export function useColorPicker(): InternalColorPickerContextValue | undefined {
  const contextValue = useContext(InternalColorPickerContext)
  return contextValue
}

// MARK: – ColorPickerPopover

export interface ColorPickerPopoverProps
  extends Merge<PopoverProps, ColorPickerProps> {
  disclosure?: React.ReactNode
}

export const ColorPickerPopover = React.forwardRef<
  PopoverInstance,
  ColorPickerPopoverProps
>(
  (
    {disclosure, defaultValue, value, onValueChange, children, ...restProps},
    forwardedRef,
  ) => {
    return (
      <Popover ref={forwardedRef} {...restProps}>
        {disclosure}
        <PopoverContent>
          <ColorPicker
            defaultValue={defaultValue}
            value={value}
            onValueChange={onValueChange}
          >
            {children}
          </ColorPicker>
        </PopoverContent>
      </Popover>
    )
  },
)
