import * as Yup from 'yup'
import {useFormik} from '@cheddarup/react-util'
import {useEffect, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {apiClient} from 'src/helpers/client'
import {readApiError} from 'src/helpers/error-formatting'

export interface CheckoutDiscountCodeProps
  extends React.ComponentPropsWithoutRef<'div'> {
  initialCode?: string
  plan: string
  referralCode?: string | null
  withSeparator?: boolean
  onDidApplyDiscount: (
    params: {
      planCost: number
      discount: number
      total: number
      description: string
      coupon: string
    } | null,
  ) => void
}

const CheckoutDiscountCode = ({
  initialCode,
  plan,
  onDidApplyDiscount,
  referralCode = null,
  withSeparator = true,
  className,
  ...restProps
}: CheckoutDiscountCodeProps) => {
  const [open, setOpen] = useState(!!initialCode || !!referralCode)
  const [submitted, setSubmitted] = useState(false)
  const growlActions = WebUI.useGrowlActions()

  const formik = useFormik({
    validationSchema: Yup.object().shape({
      code: Yup.string().required('Required'),
    }),
    initialValues: {
      code: referralCode || initialCode || '',
    },
    onSubmit: async (values) => {
      try {
        const {data} = await apiClient.post(
          'users/subscription/validate_promo_code',
          {
            plan,
            coupon: values.code,
          },
        )
        setSubmitted(true)
        onDidApplyDiscount({
          ...data,
          coupon: values.code,
        })
      } catch (error) {
        growlActions.clear()
        const errorMessage = readApiError(error, {
          invalid_promo:
            'Promo code is invalid. Please try re-entering the code.',
        })
        growlActions.show('error', {
          title: 'Sorry!',
          body: errorMessage,
        })
      }
    },
  })

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (initialCode) {
      formik.submitForm()
    }
  }, [initialCode])

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (referralCode) {
      formik.setFieldValue('code', referralCode)
      formik.submitForm()
    }
  }, [referralCode])

  return (
    <WebUI.VStack className={WebUI.cn('gap-2', className)} {...restProps}>
      <WebUI.Button
        className="self-start text-ds-xs text-gray400"
        variant="link"
        onClick={() => setOpen((prevOpen) => !prevOpen)}
      >
        <WebUI.HStack className="gap-2">
          <div>{open ? '⤫' : '+'}</div>
          <div>Promo Code</div>
        </WebUI.HStack>
      </WebUI.Button>

      {open && (
        <WebUI.HStack className="gap-2">
          <WebUI.Input
            className="grow"
            name="code"
            disabled={!!referralCode || formik.isSubmitting}
            placeholder="Promo code?"
            value={referralCode || formik.values.code}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <WebUI.Button
            type="button"
            size="large"
            loading={formik.isSubmitting}
            disabled={!!referralCode}
            onClick={() => {
              if (submitted) {
                setSubmitted(false)
                onDidApplyDiscount(null)
                formik.resetForm()
              } else {
                formik.submitForm()
              }
            }}
          >
            {referralCode ? 'Reset' : submitted ? 'Reset' : 'Apply'}
          </WebUI.Button>
        </WebUI.HStack>
      )}

      {withSeparator && <WebUI.Separator />}
    </WebUI.VStack>
  )
}

export default CheckoutDiscountCode
