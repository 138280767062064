import * as WebUI from '@cheddarup/web-ui'
import {useStripe} from '@stripe/react-stripe-js'
import type {TokenCreateParams} from '@stripe/stripe-js'
import config from 'src/config'

const useStripeTokenizer = () => {
  const stripe = useStripe()
  const growlActions = WebUI.useGrowlActions()

  const createPersonToken = async (data: TokenCreateParams.Person) => {
    if (stripe) {
      try {
        const stripeResponse = await stripe.createToken('person', data)
        if (stripeResponse.token) {
          return stripeResponse.token.id
        }
        if (stripeResponse.error) {
          growlActions.show('error', {
            title: 'Stripe Error!',
            body: stripeResponse.error.message,
          })
        }
      } catch (err: any) {
        const body =
          err.response?.data?.error ||
          'Something went wrong. Updating your account details has failed.'
        growlActions.show('error', {title: 'Error!', body})
      }
    }
    return null
  }

  const createAccountToken = async (
    company: TokenCreateParams.Account.Company,
  ) => {
    if (stripe) {
      try {
        const stripeResponse = await stripe.createToken('account', {
          business_type: 'company',
          company,
          tos_shown_and_accepted: true,
        })
        if (stripeResponse.token) {
          return stripeResponse.token.id
        }
        if (stripeResponse.error) {
          growlActions.show('error', {
            title: 'Stripe Error!',
            body: stripeResponse.error.message,
          })
        }
      } catch (err: any) {
        const body =
          err.response?.data?.error ||
          'Something went wrong. Updating your account details has failed.'
        growlActions.show('error', {title: 'Error!', body})
      }
    }
    return null
  }

  const createVerficationDocumentToken = async (document: Blob) => {
    try {
      const data = new FormData()
      data.append('file', document)
      data.append('purpose', 'identity_document')
      const fileResult = await fetch('https://uploads.stripe.com/v1/files', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${config.stripePublishableKey}`,
        },
        body: data,
      })
      const fileData = await fileResult.json()
      if (fileData) {
        return fileData.id as string
      }
    } catch (err: any) {
      const body =
        err.response?.data?.error ||
        'Something went wrong. Updating your account details has failed.'
      growlActions.show('error', {title: 'Error!', body})
    }
    return null
  }

  return {createPersonToken, createAccountToken, createVerficationDocumentToken}
}

export default useStripeTokenizer
