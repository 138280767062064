import {makeQueryUpdate, makeUseMutation} from '../use-mutation'
import {getEndpointKey} from '../../utils'
import {endpoints} from '../../endpoints'

export const useCreateTabPaymentShipmentMutation = makeUseMutation(
  endpoints.shipments.create,
  (vars) => ({
    regular: (newShipment) => [
      makeQueryUpdate(endpoints.shipments.detail, () => newShipment, vars),
    ],
  }),
)

export const useCreateShipmentIntentMutation = makeUseMutation(
  endpoints.shipments.createIntent,
)

export const useSelectShipmentIntentRateMutation = makeUseMutation(
  endpoints.shipments.selectRate,
)

export const usePurchaseTabPaymentShipmentMutation = makeUseMutation(
  endpoints.shipments.purchase,
  (vars) => ({
    regular: (newShipment) => [
      makeQueryUpdate(endpoints.shipments.detail, () => newShipment, vars),
    ],
  }),
  (queryClient) => ({
    onSuccess: (_, vars) => {
      const tabPaymentsListQueryKey = getEndpointKey(
        endpoints.tabPayments.list,
        vars,
      )
      queryClient.invalidateQueries({queryKey: tabPaymentsListQueryKey})
    },
  }),
)
