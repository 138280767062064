import * as WebUI from '@cheddarup/web-ui'
import {forwardRef, useMemo} from 'react'
import {ForwardRefComponent} from '@cheddarup/react-util'

export interface FieldGroupCardProps
  extends Omit<WebUI.CardProps, 'accessoryView'> {
  onReplicate: () => void
  onDelete: () => void
}

export const FieldGroupCard = forwardRef(
  (
    {onReplicate, onDelete, dragHandleVisible = true, className, ...restProps},
    forwardedRef,
  ) => (
    <WebUI.Card
      ref={forwardedRef}
      className={WebUI.cn(
        'FieldGroupCard',
        '*:ml-4 [@media(hover:hover){&_>_.Card-dragHandle}]:visible [@media(hover:hover){&_>_.Card-dragHandle}]:opacity-100',
        className,
      )}
      dragHandleVisible={dragHandleVisible}
      accessoryView={useMemo(
        () => (
          <WebUI.ActionGroup maxVisibleCompact={2}>
            <WebUI.Action
              icon={<WebUI.PhosphorIcon icon="copy" />}
              onClick={onReplicate}
            >
              Replicate
            </WebUI.Action>
            <WebUI.Action
              icon={<WebUI.PhosphorIcon icon="trash" />}
              onClick={onDelete}
            >
              Delete
            </WebUI.Action>
          </WebUI.ActionGroup>
        ),
        [onDelete, onReplicate],
      )}
      {...restProps}
    />
  ),
) as ForwardRefComponent<'div', FieldGroupCardProps>
