import {Link, useNavigate, useParams} from 'react-router-dom'
import * as Util from '@cheddarup/util'
import {memo} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'

const SellerFormsPage = () => {
  const navigate = useNavigate()
  const urlParams = useParams()
  const sellerTemplatesQuery = api.formTemplates.list.useQuery(undefined, {
    select: (templates) =>
      Util.sort(
        templates.filter((t) => t.user.id === Number(urlParams.seller)),
      ).by([{desc: (t) => t.id}]),
  })

  return (
    <WebUI.Modal
      className={
        '[&_>_.ModalContentView]:h-full [&_>_.ModalContentView]:max-w-screen-lg [&_>_.ModalContentView]:overflow-y-auto'
      }
      onDidHide={() => navigate('..')}
    >
      <WebUI.ModalCloseButton />
      <WebUI.ModalHeader variant="compact">
        {sellerTemplatesQuery.data?.[0]?.user.name ?? ''}
      </WebUI.ModalHeader>
      <WebUI.VStack className="gap-8 px-4 pt-4 pb-16 sm:px-8">
        <div className="text-ds-base">
          Click to view and add a form to your collection
        </div>
        <WebUI.VStack className="gap-4">
          {sellerTemplatesQuery.data?.map((form) => (
            <SellerFormRow key={form.id} form={form} />
          ))}
        </WebUI.VStack>
      </WebUI.VStack>
    </WebUI.Modal>
  )
}

// MARK: – SellerFormRow

interface SellerFormRowProps {
  form: Omit<Api.FormTemplate, 'active_forms'>
}

const SellerFormRow = memo(({form}: SellerFormRowProps) => (
  <WebUI.Panel className="h-18" as={Link} to={String(form.id)}>
    <WebUI.HStack className="h-full items-center">
      <div className="text-ds-base text-gray800">{form.name}</div>
    </WebUI.HStack>
  </WebUI.Panel>
))

export default SellerFormsPage
