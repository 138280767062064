import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {tabObjectFieldViewSchema} from '../schemas'

export default {
  modify: makeEndpoint({
    path: 'users/tabs/:tabId/payments/:paymentId/item_field_values/modify',
    method: 'POST',
    bodySchema: z.object({
      tabObjectId: z.number().optional(),
      paymentItemId: z.number(),
      fields: z.record(
        z.object({
          value: z.string().nullable(),
        }),
      ),
      notify: z.boolean().optional(),
    }),
    queryParamsSchema: z
      .object({
        code: z.string(),
      })
      .partial()
      .optional(),
    responseSchema: tabObjectFieldViewSchema.array(),
  }),
  createSignedUrl: makeEndpoint({
    path: 'users/tabs/:tabId/payments/:paymentId/item_field_values/sign',
    method: 'POST',
    bodySchema: z.object({
      objectName: z.string(),
      contentType: z.string(),
    }),
    // TODO type this properly
    responseSchema: z.object({
      signedUrl: z.string(),
    }),
  }),
}
