import React from 'react'

import MasterCardIcon from '../assets/MasterCardIcon.svg'
import VisaCardIcon from '../assets/VisaCardIcon.svg'
import DiscoverCardIcon from '../assets/DiscoverCardIcon.svg'
import AmexCardIcon from '../assets/AmexCardIcon.svg'
import GenericCardBrandIcon from '../assets/GenericCardBrandIcon.svg'
import {cn} from '../utils'

export interface CreditCardBrandLogoProps
  extends React.ComponentPropsWithoutRef<'img'> {
  brand: string
}

export const CreditCardBrandLogo = ({
  brand,
  width = '2.2rem',
  className,
  style,
  ...restProps
}: CreditCardBrandLogoProps) => {
  let cardIconSrc = GenericCardBrandIcon
  const brandLowercase = brand.toLowerCase()
  if (brandLowercase.includes('visa')) {
    cardIconSrc = VisaCardIcon
  }
  if (brandLowercase.includes('mastercard')) {
    cardIconSrc = MasterCardIcon
  }
  if (brandLowercase.includes('discover')) {
    cardIconSrc = DiscoverCardIcon
  }
  if (
    brandLowercase.includes('amex') ||
    brandLowercase.includes('american express')
  ) {
    cardIconSrc = AmexCardIcon
  }

  return (
    // biome-ignore lint/a11y/useAltText:
    <img
      className={cn('CreditCardBrandLogo', className)}
      style={{
        width,
        height: width,
        ...style,
      }}
      src={cardIconSrc}
      alt={brand}
      {...restProps}
    />
  )
}
