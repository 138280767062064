import {HasRequiredKeys, mapValues, z} from '@cheddarup/util'
import type {
  InfiniteData,
  UseInfiniteQueryOptions,
  UseQueryOptions,
  UseSuspenseQueryOptions,
} from '@tanstack/react-query'

import {Endpoints, endpoints} from './endpoints'
import {
  AnyEndpoint,
  FetchInput,
  GetEndpointKeyInput,
  fetchEndpoint,
} from './utils'
import {
  QueryKey,
  makeUseMutation,
  useInfiniteQuery,
  useQuery,
  useSuspenseQuery,
} from './react'

interface Fetch<T extends AnyEndpoint>
  extends ReturnType<typeof makeFetch<T>> {}
interface UseUnstableMutation<T extends AnyEndpoint>
  extends ReturnType<
    typeof makeUseMutation<T, FetchInput<T>, z.infer<T['responseSchema']>>
  > {}
interface UseQuery<T extends AnyEndpoint>
  extends ReturnType<typeof makeUseQuery<T>> {}
interface UseSuspenseQuery<T extends AnyEndpoint>
  extends ReturnType<typeof makeUseSuspenseQuery<T>> {}
interface UseInfiniteQuery<T extends AnyEndpoint>
  extends ReturnType<typeof makeUseInfiniteQuery<T>> {}

interface ApiClientMutationEntry<T extends AnyEndpoint> {
  useUnstableMutation: UseUnstableMutation<T>
}
interface ApiClientQueryEntry<T extends AnyEndpoint> {
  useQuery: UseQuery<T>
  useSuspenseQuery: UseSuspenseQuery<T>
  useInfiniteQuery: UseInfiniteQuery<T>
}
interface ApiClientEntryBase<T extends AnyEndpoint> {
  fetch: Fetch<T>
}

type ApiClientEntry<T extends AnyEndpoint> = ApiClientEntryBase<T> &
  (T['isMutation'] extends true
    ? ApiClientMutationEntry<T>
    : ApiClientQueryEntry<T>)

export type ApiClient = {
  [K in keyof Endpoints]: {
    [KK in keyof Endpoints[K]]: Endpoints[K][KK] extends AnyEndpoint
      ? ApiClientEntry<Endpoints[K][KK]>
      : never
  }
}

export const api: ApiClient = mapValues(endpoints, (endpointsMap) =>
  mapValues(endpointsMap, (endpoint: AnyEndpoint) =>
    endpoint.isMutation
      ? {
          fetch: makeFetch(endpoint),
          useUnstableMutation: makeUseMutation(endpoint),
        }
      : {
          fetch: makeFetch(endpoint),
          useQuery: makeUseQuery(endpoint),
          useSuspenseQuery: makeUseSuspenseQuery(endpoint),
          useInfiniteQuery: makeUseInfiniteQuery(endpoint),
        },
  ),
) as any

// MARK: – Helpers

export type InferResponse<T extends {responseSchema: z.ZodType}> = z.infer<
  T['responseSchema']
>

export type InferQueryParams<T extends {queryParamsSchema: z.ZodType}> =
  Required<NonNullable<z.infer<T['queryParamsSchema']>>>

export type InferBody<T extends {bodySchema: z.ZodType}> = z.infer<
  T['bodySchema']
>

function makeFetch<TEndpoint extends AnyEndpoint>(endpoint: TEndpoint) {
  return (
    ...[input]: HasRequiredKeys<FetchInput<TEndpoint>> extends true
      ? [FetchInput<TEndpoint>]
      : [FetchInput<TEndpoint>?]
  ): Promise<z.infer<TEndpoint['responseSchema']>> =>
    fetchEndpoint(endpoint, input as any)
}

function makeUseQuery<TEndpoint extends AnyEndpoint>(endpoint: TEndpoint) {
  return <
    TQueryFnData extends z.infer<TEndpoint['responseSchema']>,
    TError extends Error,
    TData = TQueryFnData,
  >(
    ...[endpointInput, options]: HasRequiredKeys<
      GetEndpointKeyInput<TEndpoint>
    > extends true
      ? [
          GetEndpointKeyInput<TEndpoint>,
          Omit<
            UseQueryOptions<TQueryFnData, TError, TData, QueryKey<TEndpoint>>,
            'queryKey'
          >?,
        ]
      : [
          GetEndpointKeyInput<TEndpoint>?,
          Omit<
            UseQueryOptions<TQueryFnData, TError, TData, QueryKey<TEndpoint>>,
            'queryKey'
          >?,
        ]
  ) => useQuery([endpoint, endpointInput as any], options)
}

function makeUseSuspenseQuery<TEndpoint extends AnyEndpoint>(
  endpoint: TEndpoint,
) {
  return <
    TQueryFnData extends z.infer<TEndpoint['responseSchema']>,
    TError extends Error,
    TData = TQueryFnData,
  >(
    ...[endpointInput, options]: HasRequiredKeys<
      GetEndpointKeyInput<TEndpoint>
    > extends true
      ? [
          GetEndpointKeyInput<TEndpoint>,
          Omit<
            UseSuspenseQueryOptions<
              TQueryFnData,
              TError,
              TData,
              QueryKey<TEndpoint>
            >,
            'queryKey'
          >?,
        ]
      : [
          GetEndpointKeyInput<TEndpoint>?,
          Omit<
            UseSuspenseQueryOptions<
              TQueryFnData,
              TError,
              TData,
              QueryKey<TEndpoint>
            >,
            'queryKey'
          >?,
        ]
  ) => useSuspenseQuery([endpoint, endpointInput as any], options)
}

function makeUseInfiniteQuery<TEndpoint extends AnyEndpoint>(
  endpoint: TEndpoint,
) {
  return <
    TQueryFnData extends z.infer<TEndpoint['responseSchema']>,
    TData = InfiniteData<TQueryFnData>,
  >(
    ...[endpointInput, options]: [
      HasRequiredKeys<GetEndpointKeyInput<TEndpoint>> extends true
        ? GetEndpointKeyInput<TEndpoint>
        : GetEndpointKeyInput<TEndpoint> | undefined,
      Omit<
        UseInfiniteQueryOptions<
          TQueryFnData,
          unknown,
          TData,
          TQueryFnData,
          QueryKey<TEndpoint>,
          number
        >,
        'queryKey' | 'queryFn' | 'direction'
      >,
    ]
  ) => useInfiniteQuery([endpoint, endpointInput as any], options)
}
