import {useLocation} from 'react-router-dom'
import queryString from 'query-string'

import {MarketplacePageContainer} from '../containers'
import {MarketplacePageContentContainer} from '../components'
import {MarketplaceSalesContainer} from './containers'

const SORT_BY = {
  sort: 'last_name',
  direction: 'desc',
} as const

const MarketplaceSalesPage = () => {
  const location = useLocation()

  const searchKeyword = queryString.parse(location.search).searchKeyword

  return (
    <MarketplacePageContainer initialSearchType="marketplace">
      <MarketplacePageContentContainer>
        <MarketplaceSalesContainer
          searchKeyword={typeof searchKeyword === 'string' ? searchKeyword : ''}
          sortBy={SORT_BY}
        />
      </MarketplacePageContentContainer>
    </MarketplacePageContainer>
  )
}

export default MarketplaceSalesPage
