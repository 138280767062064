import {useLocation, useNavigate} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import {useResendAllOrgMemberInvitesMutation} from '@cheddarup/api-client'

const ResendAllConfirmPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const resendAllOrgMemberInvitesMutation =
    useResendAllOrgMemberInvitesMutation()
  const growlActions = WebUI.useGrowlActions()

  return (
    <WebUI.Alert
      aria-label="Resend emails to all members"
      initialVisible
      onDidHide={() => navigate('..')}
    >
      <WebUI.AlertHeader>
        Are you sure you want to resend to all emails?
      </WebUI.AlertHeader>
      <WebUI.AlertContentView
        text="It cannot be undone."
        actions={
          <>
            <WebUI.AlertActionButton
              execute={() =>
                resendAllOrgMemberInvitesMutation.mutateAsync({
                  body: {
                    sort: 'created_at',
                    direction: 'asc',
                    ...location.state?.resendAllPayload?.query,
                  },
                })
              }
              onDidExecute={(res) =>
                growlActions.show('success', {
                  title: 'Invites resent!',
                  body: `${res.sent} welcome email${
                    res.sent === 1 ? '' : 's'
                  } resent. `,
                })
              }
            >
              Resend
            </WebUI.AlertActionButton>
            <WebUI.AlertCancelButton />
          </>
        }
      />
    </WebUI.Alert>
  )
}

export default ResendAllConfirmPage
