import {SetOptional, SimpleMerge, pickBy} from '@cheddarup/util'
import {
  CollectionActionDefault,
  CollectionStateDefault,
  CollectionStateProvider,
  CollectionStateProviderProps,
  newGenericForwardRef,
  reactDeepEqual,
  useCollectionDispatchHelpers,
  useCollectionState,
} from '@cheddarup/react-util'
import React, {
  Dispatch,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react'

import {Shortcut} from '../hooks'
import {Checkbox, CheckboxProps} from './Checkbox'
import {
  AnyList,
  ListInstance,
  ListRowComponentProps,
  ListRowData,
  ListRowDataId,
  VirtualizedList,
} from './List'
import {HStack} from './Stack'
import {cn} from '../utils'

export interface DataListRowState {
  selected: boolean
  focused: boolean
}

export interface DataListRow<T extends ListRowData> {
  id: T['id']
  original: T
  state: DataListRowState
}

export interface DataListState extends CollectionStateDefault {}

export type DataListAction = CollectionActionDefault

export interface DataRowComponentProps<T extends ListRowData>
  extends React.ComponentPropsWithoutRef<'div'> {
  row?: DataListRow<T>
  setSelected?: (value: boolean) => void
}

export type DataRowComponentType<T extends ListRowData> =
  React.ForwardRefExoticComponent<
    DataRowComponentProps<T> & React.RefAttributes<HTMLDivElement>
  >

export interface DataListInstance extends ListInstance {
  dispatch: Dispatch<DataListAction>
}

export type DataListOwnProps<T extends ListRowData> = SimpleMerge<
  DataListInnerOwnProps<T>,
  SetOptional<
    Pick<CollectionStateProviderProps, 'initialState' | 'onStateChange'>,
    'initialState'
  >
>

export type DataListProps<
  T extends ListRowData,
  L extends AnyList<DataListRow<T>>,
> = L extends React.ComponentType<infer P>
  ? SimpleMerge<
      Omit<P, 'RowComponent'>,
      DataListOwnProps<T> & {
        ListComponent?: L
      }
    >
  : never

export const DataList = newGenericForwardRef(
  <T extends ListRowData, L extends AnyList<DataListRow<T>>>({
    initialState = {selectedItemIdsMap: {}, focusedItemId: null},
    onStateChange,
    data,
    ref: forwardedRef,
    ...restProps
  }: DataListProps<T, L> & {ref?: React.Ref<DataListInstance>}) => (
    <CollectionStateProvider
      initialState={initialState}
      onStateChange={onStateChange}
      items={data}
    >
      <DataListInner<T, L>
        ref={forwardedRef}
        data={data}
        {...(restProps as any)}
      />
    </CollectionStateProvider>
  ),
)

// MARK: - DataListInner

interface DataListInnerOwnProps<T extends ListRowData> {
  data: T[]
  DataRowComponent: DataRowComponentType<T>
  shouldRegisterShortcuts?: boolean
  selectable?: boolean
  children?: (dataList: {
    listElement: React.ReactElement
    state: DataListState
    dispatch: Dispatch<DataListAction>
  }) => React.ReactNode
}

type DataListInnerProps<
  T extends ListRowData,
  L extends AnyList<DataListRow<T>>,
> = L extends React.ComponentType<infer P>
  ? SimpleMerge<
      Omit<P, 'RowComponent'>,
      DataListInnerOwnProps<T> & {
        ListComponent?: L
      }
    >
  : never

const DataListInner = newGenericForwardRef(
  <T extends ListRowData, L extends AnyList<DataListRow<T>>>({
    data,
    ListComponent: _ListComponent,
    DataRowComponent,
    shouldRegisterShortcuts = true,
    selectable,
    children,
    className,
    ref: forwardedRef,
    ...restProps
  }: DataListInnerProps<T, L> & {
    ref?: React.ForwardedRef<DataListInstance>
  }) => {
    const ListComponent = _ListComponent ?? VirtualizedList

    const ownRef = useRef<ListInstance>(null)
    const {state, dispatch} = useCollectionState()
    const stateHelpers = useCollectionDispatchHelpers()

    useImperativeHandle(
      forwardedRef,
      (): DataListInstance => ({
        scrollToRow: (rowKey) => ownRef.current?.scrollToRow(rowKey),
        dispatch,
      }),
      [dispatch],
    )

    const rows = useMemo(
      () =>
        data.map(
          (i): DataListRow<T> => ({
            id: i.id,
            original: i,
            state: {
              selected: state.selectedItemIdsMap[i.id] === true,
              focused: state.focusedItemId === i.id,
            },
          }),
        ),
      [data, state.focusedItemId, state.selectedItemIdsMap],
    )

    const RowComponent = useMemo(
      () =>
        React.memo(
          React.forwardRef<
            HTMLDivElement,
            ListRowComponentProps<DataListRow<T>>
          >(({data: rowData, index, ...rowRestProps}, rowForwardedRef) => (
            <DataListRow
              ref={rowForwardedRef}
              rowKey={rowData.original.id}
              DataRowComponent={DataRowComponent}
              selectable={selectable}
              rowData={rowData.original}
              state={rowData.state}
              setRowSelected={stateHelpers.setItemSelected}
              setRowFocused={stateHelpers.setItemFocused}
              setRangeToRowSelected={stateHelpers.setRangeToItemSelected}
              {...rowRestProps}
            />
          )),
          reactDeepEqual,
        ),
      [DataRowComponent, selectable, stateHelpers],
    )

    const AnyListComp = ListComponent as any
    const listElement = (
      <AnyListComp
        ref={ownRef}
        className={cn('DataList', className)}
        data={rows}
        RowComponent={RowComponent}
        {...restProps}
      />
    )

    return (
      <>
        {children == null
          ? listElement
          : children({listElement, state, dispatch})}
        {shouldRegisterShortcuts && (
          <DataListShortcuts
            listInstance={ownRef.current}
            selectable={selectable}
          />
        )}
      </>
    )
  },
)

// MARK: - DataListRow

export interface DataListRowProps<T extends ListRowData>
  extends React.ComponentPropsWithoutRef<'div'> {
  DataRowComponent: DataRowComponentType<T>
  selectable?: boolean
  rowKey: ListRowDataId
  rowData: T
  state: DataListRowState
  setRowFocused: (key: ListRowDataId | null) => void
  setRowSelected: (key: ListRowDataId, value: boolean) => void
  setRangeToRowSelected: (destKey: ListRowDataId, value: boolean) => void
}

export const DataListRow = newGenericForwardRef(
  <T extends ListRowData>({
    DataRowComponent,
    selectable,
    rowKey,
    rowData,
    state,
    setRowFocused,
    setRowSelected,
    setRangeToRowSelected,
    onFocus,
    onBlur,
    className,
    children,
    ref: forwardedRef,
    ...restProps
  }: DataListRowProps<T> & {ref?: React.Ref<HTMLDivElement>}) => {
    const ownRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
      if (state.focused) {
        ownRef.current?.focus()
      }
    }, [state.focused])

    return (
      <HStack
        ref={forwardedRef}
        className={cn('DataListRow', 'items-center gap-2', className)}
        tabIndex={-1}
        data-rowid={rowKey}
        data-rowfocused={state.focused}
        data-rowselected={state.selected}
        {...restProps}
      >
        {selectable && (
          <Checkbox
            state={state.selected}
            tabIndex={-1}
            onMouseDown={(event) => {
              if (event.getModifierState('Shift')) {
                setRangeToRowSelected(rowKey, !state.selected)
              } else {
                setRowSelected(rowKey, !state.selected)
              }
            }}
          />
        )}
        <DataRowComponent
          ref={ownRef}
          className={`min-w-0 max-w-full flex-[1] focus:outline-none [.DataListRow[data-rowfocused="true"]_&[class]]:border-teal-50`}
          tabIndex={0}
          row={{id: rowData.id, original: rowData, state}}
          setSelected={(value) => setRowSelected(rowKey, value)}
          onFocus={(event) => {
            onFocus?.(event)

            if (event.defaultPrevented) {
              return
            }

            if (!state.focused) {
              setRowFocused(rowKey)
            }
          }}
          onBlur={(event) => {
            onBlur?.(event)

            if (event.defaultPrevented) {
              return
            }

            if (
              state.focused &&
              !event.currentTarget.contains(event.relatedTarget) &&
              'sourceCapabilities' in event.nativeEvent &&
              event.nativeEvent.sourceCapabilities != null
            ) {
              setRowFocused(null)
            }
          }}
        >
          {children}
        </DataRowComponent>
      </HStack>
    )
  },
)

// MARK: – DataListCheckboxSelectAll

export interface DataListCheckboxSelectAllProps
  extends Omit<
    SimpleMerge<React.ComponentPropsWithoutRef<'input'>, CheckboxProps>,
    'state' | 'children'
  > {
  children?:
    | React.ReactNode
    | ((state: boolean | 'indeterminate') => React.ReactNode)
}

export const DataListCheckboxSelectAll = React.forwardRef<
  HTMLInputElement,
  DataListCheckboxSelectAllProps
>(({className, onChange, children, ...restProps}, forwardedRef) => {
  const {itemIds, state, dispatch} = useCollectionState()
  const selectedItemsCount = Object.values(state.selectedItemIdsMap).filter(
    (v) => v === true,
  ).length
  const checkboxState =
    selectedItemsCount === itemIds.length
      ? true
      : selectedItemsCount > 0
        ? 'indeterminate'
        : false
  return (
    <Checkbox
      ref={forwardedRef}
      className={cn(
        'DataListCheckboxSelectAll',
        'items-center',
        '[&_>_.Checkbox-text]:whitespace-nowrap',
        className,
      )}
      state={checkboxState}
      onChange={(event) => {
        onChange?.(event)
        if (!event.defaultPrevented) {
          dispatch({
            type: 'SET_ALL_ITEMS_SELECTED',
            value: event.target.checked,
          })
        }
      }}
      {...restProps}
    >
      {typeof children === 'function' ? children(checkboxState) : children}
    </Checkbox>
  )
})

// MARK: – DataListShortcuts

interface DataListShortcutsProps {
  listInstance: ListInstance | null
  selectable?: boolean
}

const DataListShortcuts = React.memo(
  ({listInstance, selectable}: DataListShortcutsProps) => {
    const {itemIds, state, dispatch} = useCollectionState()

    const moveFocusedRow = (direction: 'up' | 'down') => {
      const adjacentRowKey = getAdjacentRowKey({
        direction,
        rowKeys: itemIds,
        focusedRowKey: state.focusedItemId,
      })
      if (adjacentRowKey) {
        dispatch({type: 'SET_ITEM_FOCUSED', key: adjacentRowKey})
        listInstance?.scrollToRow(adjacentRowKey)
        return true
      }
      return false
    }
    const jumpFocusedRow = (position: 'top' | 'bottom') => {
      const rowIndex = position === 'top' ? 0 : itemIds.length - 1
      const rowKey = itemIds[rowIndex]
      if (rowKey) {
        dispatch({type: 'SET_ITEM_FOCUSED', key: rowKey})
        listInstance?.scrollToRow(rowKey)
        return true
      }
      return false
    }
    const moveFocusedRowWithSelection = (direction: 'up' | 'down') => {
      const adjacentRowKey = getAdjacentRowKey({
        direction,
        rowKeys: itemIds,
        focusedRowKey: state.focusedItemId,
      })
      if (!state.focusedItemId || !adjacentRowKey) {
        return false
      }

      if (selectable) {
        if (state.selectedItemIdsMap[adjacentRowKey]) {
          dispatch({
            type: 'SET_ITEM_SELECTED',
            key: state.focusedItemId,
            value: false,
          })
          listInstance?.scrollToRow(adjacentRowKey)
        } else {
          dispatch({
            type: 'SET_ITEMS_SELECTED',
            keys: [state.focusedItemId, adjacentRowKey],
            value: true,
          })
        }
      }

      dispatch({type: 'SET_ITEM_FOCUSED', key: adjacentRowKey})
      listInstance?.scrollToRow(adjacentRowKey)
      return true
    }

    return (
      <>
        <Shortcut
          priority={10}
          keybinding="Escape"
          onHandle={() => {
            const selectedRowKeys = Object.keys(
              pickBy(state.selectedItemIdsMap, (selected) => selected === true),
            )

            if (selectable && selectedRowKeys.length > 0) {
              dispatch({type: 'SET_ALL_ITEMS_SELECTED', value: false})
              return true
            }
            if (state.focusedItemId) {
              dispatch({type: 'SET_ITEM_FOCUSED', key: null})
              return true
            }
            return false
          }}
        />
        <Shortcut
          priority={10}
          keybinding="$mod+a"
          onHandle={() => {
            if (!selectable) {
              return false
            }
            dispatch({type: 'SET_ALL_ITEMS_SELECTED', value: true})
            return true
          }}
        />

        <Shortcut
          priority={10}
          keybinding="x"
          onHandle={() => {
            if (!selectable) {
              return false
            }
            if (state.focusedItemId) {
              dispatch({
                type: 'SET_ITEM_SELECTED',
                key: state.focusedItemId,
                value: !state.selectedItemIdsMap[state.focusedItemId],
              })
              return false
            }
            return true
          }}
        />
        {['k', 'ArrowUp'].map((key) => (
          <React.Fragment key={key}>
            <Shortcut
              priority={10}
              keybinding={key}
              onHandle={() => moveFocusedRow('up')}
            />
            <Shortcut
              priority={10}
              keybinding={`Shift+${key}`}
              onHandle={() => moveFocusedRowWithSelection('up')}
            />
          </React.Fragment>
        ))}
        {['j', 'ArrowDown'].map((key) => (
          <React.Fragment key={key}>
            <Shortcut
              priority={10}
              keybinding={key}
              onHandle={() => moveFocusedRow('down')}
            />
            <Shortcut
              priority={10}
              keybinding={`Shift+${key}`}
              onHandle={() => moveFocusedRowWithSelection('down')}
            />
          </React.Fragment>
        ))}
        <Shortcut
          priority={10}
          keybinding="$mod+ArrowUp"
          onHandle={() => jumpFocusedRow('top')}
        />
        <Shortcut
          priority={10}
          keybinding="$mod+ArrowDown"
          onHandle={() => jumpFocusedRow('bottom')}
        />
      </>
    )
  },
)

// MARK: – Helpers

export function getAdjacentRowKey({
  rowKeys,
  focusedRowKey,
  direction,
}: {
  rowKeys: ListRowDataId[]
  focusedRowKey: ListRowDataId | null
  direction: 'up' | 'down'
}) {
  const rowIndex = focusedRowKey ? rowKeys.indexOf(focusedRowKey) : -1
  const step = direction === 'down' ? 1 : -1
  const finalIndex = rowIndex + step
  const adjacentRowIndex =
    finalIndex < 0 || finalIndex > rowKeys.length - 1 ? -1 : finalIndex
  return rowKeys[adjacentRowIndex]
}
