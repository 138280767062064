import * as Util from '@cheddarup/util'
import {useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'

import type {FixedItemFormFormik} from '../../../../../containers/FixedItemForm/FixedItemForm'

export interface ItemVariantsListingSkuModalProps extends WebUI.PromptProps {
  formik: FixedItemFormFormik
  listingsUuids: string[]
  onDismiss?: () => void
}

const ItemVariantsListingSkuModal = ({
  formik,
  listingsUuids,
  onDismiss,
  ...restProps
}: ItemVariantsListingSkuModalProps) => {
  const [sku, setSku] = useState('')
  return (
    <WebUI.Prompt
      aria-label="Edit variants skus"
      initialVisible
      onDidHide={onDismiss}
      {...restProps}
    >
      {(dialog) => (
        <WebUI.VStack className="gap-3">
          <WebUI.PromptHeader
            className="[&_>_.PromptHeader-heading]:text-ds-lg"
            heading={`Enter SKU for ${Util.pluralize(
              'variants',
              listingsUuids.length,
              true,
            )}`}
            subheading="SKU will not be visible to customers"
          />
          <WebUI.FormField label="SKU">
            <WebUI.Input
              placeholder="Enter SKU"
              onChange={(event) => setSku(event.target.value)}
            />
          </WebUI.FormField>
          <WebUI.HStack className="gap-3">
            <WebUI.Button
              onClick={() => {
                formik.setFieldValue(
                  'options.variants.listings',
                  formik.values.options.variants.listings.map((listing) =>
                    listingsUuids.includes(listing.uuid)
                      ? {
                          ...listing,
                          sku,
                        }
                      : listing,
                  ),
                )

                dialog.hide()
              }}
            >
              Save
            </WebUI.Button>
            <WebUI.Button variant="secondary" onClick={() => dialog.hide()}>
              Cancel
            </WebUI.Button>
          </WebUI.HStack>
        </WebUI.VStack>
      )}
    </WebUI.Prompt>
  )
}

export default ItemVariantsListingSkuModal
