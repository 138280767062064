import {useLocation} from 'react-router-dom'
import query from 'query-string'
import {AuthLayout} from 'src/components/AuthLayout'
import {Logo} from 'src/components'

import {SignupAsManagerFormContainer} from './containers'
import {SignupFooter} from '../components'

const SignUpPage = () => {
  const location = useLocation()

  const {redirect, org_email, member_email} = query.parse(location.search)
  if (typeof redirect !== 'string') {
    return null
  }

  const [pathname, search] = redirect.split('?')
  const {organizer} = query.parse(search ?? '')
  const segments = pathname?.split('/') ?? []
  const inviteCode = segments.pop()
  const managerId = segments.pop()

  return (
    <AuthLayout>
      <div className="p-10">
        <div className="mt-4">
          <Logo className="mb-10 justify-center" />
          <h1 className="mb-1 text-center font-normal">
            Welcome to Cheddar Up!
          </h1>
          <h2 className="mx-4 mb-4 text-center font-light text-ds-md">
            <a href={`mailto:${org_email}`}>{organizer}</a> has invited you to
            help manage one or more collections. It’s easy to get started:
          </h2>
          <SignupAsManagerFormContainer
            // biome-ignore lint/style/noNonNullAssertion:
            org={managerId!}
            // biome-ignore lint/style/noNonNullAssertion:
            inviteCode={inviteCode!}
            memberEmail={typeof member_email === 'string' ? member_email : ''}
          />
        </div>
      </div>
      <SignupFooter />
    </AuthLayout>
  )
}

export default SignUpPage
