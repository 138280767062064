import {useNavigate, useParams} from 'react-router-dom'
import {Fragment, useRef} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api, useAddObjectsFromCatalogMutation} from '@cheddarup/api-client'
import {LinkButton} from 'src/components/LinkButton'
import {UpgradeRequiredAlert} from 'src/components/UpgradeRequiredAlert'
import {FieldSetList} from 'src/components/FieldSetList'

const AddSellerFormPage = () => {
  const navigate = useNavigate()
  const urlParams = useParams()
  const {data: collection} = api.tabs.detail.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: urlParams.collection!,
    },
  })
  const templateQuery = api.formTemplates.detail.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      templateId: urlParams.form!,
    },
  })
  const addObjectsFromCatalogMutation = useAddObjectsFromCatalogMutation()
  const upgradeRequiredAlertRef = useRef<WebUI.DialogInstance>(null)

  const form = templateQuery.data?.active_forms?.[0]

  return (
    <WebUI.Modal
      className="[&_>_.ModalContentView]:h-full [&_>_.ModalContentView]:max-w-screen-lg"
      onDidHide={() => navigate('../..')}
    >
      <WebUI.ModalCloseButton />
      <WebUI.ModalHeader>{templateQuery.data?.name ?? ''}</WebUI.ModalHeader>
      <WebUI.VStack className="min-h-0 grow">
        <div className="bg-gray100 px-8 py-4 text-ds-base">
          This is a preview only. You can edit and add to these fields after
          adding this form.
        </div>

        <FieldSetList
          className="grow p-8"
          variant="organizer"
          EmptyStateViewComponent={Fragment}
          defaultValues={{}}
          fields={form?.fields ?? []}
        />
      </WebUI.VStack>

      <WebUI.PageToolbar>
        <LinkButton size="large" variant="secondary" to="..">
          Back
        </LinkButton>
        <WebUI.Button
          size="large"
          variant="primary"
          loading={addObjectsFromCatalogMutation.isPending}
          onClick={() => {
            if (!collection || !form) {
              return
            }

            if (
              collection.status !== 'draft' &&
              !collection.is_pro &&
              collection.reportsAvailable.activeFormsCount +
                collection.reportsAvailable.activeSignupsCount >=
                1
            ) {
              upgradeRequiredAlertRef.current?.show()
            } else if (templateQuery.data) {
              addObjectsFromCatalogMutation.mutate({
                pathParams: {
                  tabId: collection.id,
                },
                body: {
                  catalog: {
                    id: templateQuery.data.id,
                    forms: [form.id],
                  },
                },
              })
              navigate('../..')
            }
          }}
        >
          Add Form
        </WebUI.Button>
      </WebUI.PageToolbar>
      <UpgradeRequiredAlert ref={upgradeRequiredAlertRef} />
    </WebUI.Modal>
  )
}

export default AddSellerFormPage
