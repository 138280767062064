import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {
  bankAccountSchema,
  billingHistoryItemSchema,
  creditCardSchema,
  subscriptionPlanSchema,
  subscriptionPlanTypeSchema,
} from '../schemas'

export default {
  own: makeEndpoint({
    path: 'users/subscription',
    responseSchema: z.object({
      billing_history: billingHistoryItemSchema,
      payment_method: bankAccountSchema.or(creditCardSchema),
      plan: subscriptionPlanSchema,
    }),
  }),
  invoices: makeEndpoint({
    path: 'users/subscription/invoices',
    responseSchema: z.object({
      billing_history: billingHistoryItemSchema.array(),
      payment_method: bankAccountSchema.or(creditCardSchema),
      plan: subscriptionPlanSchema,
    }),
  }),
  update: makeEndpoint({
    path: 'users/subscription',
    method: 'PATCH',
    bodySchema: z.object({
      plan: z.string(),
      coupon: z.string().optional(),
      source_id: z.string().optional(),
      force_update: z.boolean().optional(),
      reason: z.string().optional(),
    }),
    responseSchema: z.object({
      newPlan: subscriptionPlanTypeSchema,
      oldPlanName: z.string(),
      oldPlanSku: z.string(),
      results: z
        .object({
          error: z.string(),
          tabs: z
            .object({
              id: z.number(),
              name: z.string(),
              online_pending_total: z.number(),
              withdrawal_balance_available: z.number(),
            })
            .array(),
        })
        .array(),
    }),
  }),
  check: makeEndpoint({
    path: 'users/subscription/check',
    method: 'PATCH',
    bodySchema: z.object({
      plan: z.string(),
    }),
    responseSchema: z
      .object({
        results: z
          .object({
            error: z.string().nullish(),
            warning: z.string().nullish(),
            tabs: z
              .object({
                id: z.number(),
                name: z.string(),
                online_pending_total: z.number(),
                withdrawal_balance_available: z.number(),
              })
              .array(),
          })
          .passthrough()
          .array(),
      })
      .passthrough(),
  }),
}
