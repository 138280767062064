import {FormikHandlers, FormikHelpers, FormikState} from 'formik'
import * as WebUI from '@cheddarup/web-ui'
import React from 'react'

import ECheckAccountNumberHelpImage from '../images/ECheckAccountNumberHelp.jpg'
import ECheckRoutingNumberHelpImage from '../images/ECheckRoutingNumberHelp.jpg'

export interface BankAccountFormSectionValues {
  bankAccount: {
    routingNumber: string
    accountNumber: string
    accountNumberRepeat: string
  }
  [key: string]: any
}

export interface BankAccountFormSectionProps
  extends React.ComponentPropsWithoutRef<'div'> {
  formik: FormikState<BankAccountFormSectionValues> &
    FormikHelpers<BankAccountFormSectionValues> &
    FormikHandlers
}

const BankAccountFormSection = ({
  formik,
  className,
  ...restProps
}: BankAccountFormSectionProps) => (
  <WebUI.VStack className={WebUI.cn('gap-3', className)} {...restProps}>
    <WebUI.FormField
      label="Routing Number"
      error={formik.errors.bankAccount?.routingNumber}
    >
      <WebUI.HStack className="items-center gap-2">
        <WebUI.Input
          className="grow"
          name="bankAccount.routingNumber"
          placeholder="9 digits"
          value={formik.values.bankAccount.routingNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <WebUI.DeprecatedTooltip
          label={
            <WebUI.Image
              src={ECheckRoutingNumberHelpImage}
              alt="ECheck routing number help"
              width={240}
              height={240}
            />
          }
        >
          <WebUI.PhosphorIcon
            className="text-ds-md text-gray400"
            icon="question-fill"
          />
        </WebUI.DeprecatedTooltip>
      </WebUI.HStack>
    </WebUI.FormField>
    <WebUI.FormField
      label="Account Number"
      error={formik.errors.bankAccount?.accountNumber}
    >
      <WebUI.HStack className="items-center gap-2">
        <WebUI.Input
          className="grow"
          name="bankAccount.accountNumber"
          placeholder="up to 17 digits"
          value={formik.values.bankAccount.accountNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <WebUI.DeprecatedTooltip
          label={
            <WebUI.Image
              alt="ECheck account number help"
              src={ECheckAccountNumberHelpImage}
              width={240}
              height={240}
            />
          }
        >
          <WebUI.PhosphorIcon
            className="text-ds-md text-gray400"
            icon="question-fill"
          />
        </WebUI.DeprecatedTooltip>
      </WebUI.HStack>
    </WebUI.FormField>
    <WebUI.FormField
      label="Re-Enter Account Number"
      error={formik.errors.bankAccount?.accountNumberRepeat}
    >
      <WebUI.Input
        name="bankAccount.accountNumberRepeat"
        placeholder="up to 17 digits"
        value={formik.values.bankAccount.accountNumberRepeat}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
    </WebUI.FormField>
  </WebUI.VStack>
)

export default BankAccountFormSection
