import React from 'react'
import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'

import {FormFieldInlineLabelEdit} from './FormFieldInlineLabelEdit'
import type {FieldGroupFieldsEditProps} from './FieldGroupFieldsEdit'

export const MultipleChoiceFieldsEdit = ({
  required,
  focused,
  initialValue,
  initialLabels,
  placeholders,
  onValueChange,
  onLabelChange,
}: Omit<FieldGroupFieldsEditProps<'multiple_choice'>, 'fieldGroupType'>) => (
  <FormFieldInlineLabelEdit
    labelInputFocused={focused}
    required={required}
    initialLabel={initialLabels.value}
    placeholder={placeholders.value}
    onLabelChange={(label) => onLabelChange('value', label)}
  >
    <WebUI.OptionsBuilder
      className={`overflow-x-hidden py-0 [&_.DataListRow[data-rowfocused="true"]_&_.OptionBuilderRow-removeButton]:visible [&_.OptionBuilderRow-checkbox]:hidden [&_.OptionBuilderRow-removeButton]:invisible`}
      defaultOptions={initialValue.map((v) => ({
        id: Util.makeShortId(),
        value: v,
      }))}
      HeaderComponent={DisabledSelect}
      onOptionsChange={(options) => onValueChange(options.map((o) => o.value))}
    />
  </FormFieldInlineLabelEdit>
)

// MARK: – DisabledSelect

const DisabledSelect = ({
  className,
  disabled = true,
  size,
  onMouseDown,
  ...restProps
}: React.ComponentPropsWithoutRef<'select'>) => (
  <WebUI.Select
    className={WebUI.cn(
      '[&_>_.Select-arrow]:right-4 [&_>_.Select-select]:mt-px [&_>_.Select-select]:ml-px [&_>_.Select-select]:w-[calc(100%-2px)] [&_>_.Select-select]:border-none [&_>_.Select-select]:px-8 [&_>_.Select-select]:shadow-none',
      className,
    )}
    size="compact"
    disabled={disabled}
    {...restProps}
  >
    <option>Select one</option>
  </WebUI.Select>
)
