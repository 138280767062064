import * as WebUI from '@cheddarup/web-ui'
import {forwardRef} from 'react'

import {SharpImage, SharpImageProps} from './SharpImage'

export interface SharpAvatarProps
  extends WebUI.AvatarProps,
    Omit<SharpImageProps, 'width' | 'height' | 'alt' | 'onError'> {}

export const SharpAvatar = forwardRef<HTMLImageElement, SharpAvatarProps>(
  ({image, className, ...restProps}, forwardedRef) => (
    <WebUI.Avatar
      ref={forwardedRef}
      className={WebUI.cn('SharpAvatar', className)}
      as={SharpImage}
      image={
        image && typeof image === 'object'
          ? {
              ...image,
              // HACK: prevent using `edited_image_url`, API returns it but
              // it doesn't work
              edited_image_url: null,
            }
          : image
      }
      {...restProps}
    />
  ),
)
