import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {paginationSchema} from '../schemas'
import {orgMemberInviteSchema} from '../schemas/org-member-invites'

export default {
  list: makeEndpoint({
    path: 'users/org_member_invites',
    queryParamsSchema: z
      .object({
        sort: z.string(),
        direction: z.enum(['asc', 'desc']),
        search: z.string(),
        page: z.number(),
        per_page: z.number(),
      })
      .partial()
      .optional(),
    responseSchema: z.object({
      data: orgMemberInviteSchema.array(),
      pagination: paginationSchema,
      search: z.string(),
      sort: z.string(),
    }),
  }),
  create: makeEndpoint({
    path: 'users/org_member_invites',
    method: 'POST',
    bodySchema: z.object({
      profiles: z
        .object({
          stripe: z.object({
            banks: z
              .object({
                nickname: z.string(),
                token: z.string(),
              })
              .array()
              .optional(),
          }),
        })
        .passthrough()
        .array(),
    }),
    responseSchema: z.any(),
  }),
  delete: makeEndpoint({
    path: 'users/org_member_invites/:inviteId',
    method: 'DELETE',
    responseSchema: z.any(),
  }),
  recreate: makeEndpoint({
    path: 'users/org_member_invites/resend/v2',
    method: 'POST',
    bodySchema: z.object({
      id: z.string().array(),
    }),
    responseSchema: z.any(),
  }),
  sendTest: makeEndpoint({
    path: 'users/org_member_invites/send_test',
    method: 'POST',
    bodySchema: z.object({
      email: z.string(),
    }),
    responseSchema: z.any(),
  }),
  resend: makeEndpoint({
    path: 'users/org_member_invites/:inviteId/resend',
    method: 'POST',
    bodySchema: z.object({
      inviteId: z.number(),
    }),
    responseSchema: z.any(),
  }),
  resendAll: makeEndpoint({
    path: 'users/org_member_invites/resend_all',
    method: 'POST',
    bodySchema: z
      .object({
        sort: z.string(),
        direction: z.enum(['asc', 'desc']),
      })
      .passthrough(),
    responseSchema: z.any(),
  }),
}
