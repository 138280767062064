export const formatPaymentMethod = <
  T extends {
    metadata_exposed?: Api.PaymentMetadataExposed | null
    payment_method: Api.PaymentMethodType | null
  },
>(
  payment: T,
) => {
  const paymentMethod = getPaymentMethod(payment)

  if (!paymentMethod) {
    return 'Unknown'
  }

  return (
    {
      offline: 'Cash or Check',
      cash: 'Cash or Check',
      card: 'Credit Card',
      echeck: 'e-Check',
      paypal: 'PayPal',
      chained: 'Chained',
      refunded: 'Refunded',
      link: 'Stripe Link',
    }[paymentMethod] ?? 'No Payment'
  )
}

export const getPaymentMethod = <
  T extends {
    metadata_exposed?: Api.PaymentMetadataExposed | null
    payment_method: Api.PaymentMethodType | null
  },
>(
  payment: T,
) => {
  if (
    payment.metadata_exposed?.source &&
    'type' in payment.metadata_exposed.source &&
    payment.metadata_exposed.source.type === 'link'
  ) {
    return 'link' as const
  }

  return payment.payment_method
}
