import React, {useContext, useImperativeHandle} from 'react'
import {
  Toolbar as ReakitToolbar,
  ToolbarInitialState as ReakitToolbarInitialState,
  ToolbarItem as ReakitToolbarItem,
  ToolbarItemOptions as ReakitToolbarItemOptions,
  ToolbarOptions as ReakitToolbarOptions,
  ToolbarStateReturn as ReakitToolbarStateReturn,
  useToolbarState as useReakitToolbarState,
} from 'reakit'
import {
  ForwardRefComponent,
  useLiveRef,
  useUpdateEffect,
} from '@cheddarup/react-util'

import {Button} from './Button'
import {cn} from '../utils'

interface InternalToolbarContextValue extends ReakitToolbarStateReturn {}

const InternalToolbarContext = React.createContext(
  {} as InternalToolbarContextValue,
)

// MARK: - Toolbar

export interface ToolbarInstance extends ReakitToolbarStateReturn {}

export interface ToolbarProps
  extends ReakitToolbarInitialState,
    Omit<ReakitToolbarOptions, keyof ReakitToolbarStateReturn>,
    React.ComponentPropsWithoutRef<'div'> {
  elementRef?: React.Ref<HTMLDivElement>
  children?: React.ReactNode
  onChangeCurrentId?: (newCurrentId: string | null | undefined) => void
}

export const Toolbar = React.forwardRef<ToolbarInstance, ToolbarProps>(
  (
    {
      className,
      elementRef,
      children,
      onChangeCurrentId,
      baseId,
      unstable_virtual,
      rtl,
      orientation,
      currentId,
      loop,
      wrap,
      ...restProps
    },
    forwardedRef,
  ) => {
    const toolbar = useReakitToolbarState({
      baseId,
      unstable_virtual,
      rtl,
      orientation,
      currentId,
      loop,
      wrap,
    })
    const toolbarRef = useLiveRef(toolbar)
    const onChangeCurrentIdRef = useLiveRef(onChangeCurrentId)

    useImperativeHandle(forwardedRef, () => toolbar, [toolbar])

    useUpdateEffect(() => {
      if (currentId != null) {
        toolbarRef.current.setCurrentId(currentId)
      }
    }, [currentId])

    useUpdateEffect(() => {
      onChangeCurrentIdRef.current?.(toolbar.currentId)
    }, [toolbar.currentId])

    return (
      <InternalToolbarContext.Provider value={toolbar}>
        <ReakitToolbar
          ref={elementRef}
          className={cn(
            'Toolbar',
            'inline-flex',
            'aria-orientation-horizontal:flex-row',
            'aria-orientation-vertical:flex-col',
            className,
          )}
          {...toolbar}
          {...restProps}
        >
          {children}
        </ReakitToolbar>
      </InternalToolbarContext.Provider>
    )
  },
)

// MARK: – ToolbarItem

export interface ToolbarItemProps
  extends Omit<ReakitToolbarItemOptions, keyof ReakitToolbarStateReturn> {}

export const ToolbarItem = React.forwardRef(
  ({as = Button, className, ...restProps}, forwardedRef) => {
    const toolbar = useContext(InternalToolbarContext)
    return (
      <ReakitToolbarItem
        ref={forwardedRef}
        as={as}
        className={cn('ToolbarItem', 'flex-0', className)}
        {...toolbar}
        {...restProps}
      />
    )
  },
) as ForwardRefComponent<typeof Button, ToolbarItemProps>
