import Papa from 'papaparse'
import React, {useMemo} from 'react'
import {formatPaymentMethod} from 'src/helpers/formatPaymentMethod'
import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'
import {Link} from 'src/components/Link'
import {SearchForm} from 'src/components'
import {PaymentItemTableView} from 'src/components/PaymentItemTableView'

export interface ItemPaymentsTableProps
  extends React.ComponentPropsWithoutRef<'div'> {
  data?: Api.TabItemReportPaymentItem[]
}

const ItemPaymentsTable: React.FC<ItemPaymentsTableProps> = ({
  data,
  ...restProps
}) => {
  const refundLinkClassNames = 'text-ds-sm text-tint'

  const columnHelper = useMemo(
    () => WebUI.createColumnHelper<Api.TabItemReportPaymentItem>(),
    [],
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const columns = useMemo(
    () => [
      columnHelper.accessor((pi) => pi.payment.tab_member.name, {
        id: 'payerName',
        meta: {
          subtle: false,
        },
        header: 'Payer',
        cell: ({cell, row: {original: pi}}) => (
          <WebUI.VStack>
            <p>{cell.getValue()}</p>
            {pi.refund_data &&
              (pi.refund_data.total_refunded_cents ?? 0) >= 0 && (
                <p className={refundLinkClassNames}>
                  {(pi.refund_data?.net_amount_cents ?? 0) > 0
                    ? 'Partially Refunded'
                    : 'Refunded'}
                </p>
              )}
          </WebUI.VStack>
        ),
      }),
      columnHelper.accessor((pi) => new Date(pi.payment.created_at), {
        id: 'payment.created_at',
        size: 100,
        sortingFn: 'datetime',
        header: 'Date',
        cell: ({cell}) => Util.formatDateAs(cell.getValue()),
      }),
      columnHelper.accessor((pi) => pi.quantity, {
        id: 'qty',
        size: 60,
        meta: {
          align: 'right',
        },
        header: 'Qty',
        cell: ({cell, row: {original: pi}}) => {
          const refunded = (pi.refund_data?.total_refunded_cents ?? 0) >= 0
          const isPartiallyRefunded =
            (pi.refund_data?.net_amount_cents ?? 0) > 0

          return (
            <WebUI.VStack>
              <p>{cell.getValue()}</p>
              {refunded &&
                !isPartiallyRefunded &&
                pi.refund_data?.quantity_refunded && (
                  <p className={refundLinkClassNames}>
                    -{pi.refund_data?.quantity_refunded}
                  </p>
                )}
            </WebUI.VStack>
          )
        },
      }),
      columnHelper.accessor((pi) => pi.total, {
        id: 'subtotal',
        size: 100,
        meta: {
          align: 'right',
        },
        header: 'Payment',
        cell: ({cell, row: {original: pi}}) => {
          const refunded = (pi.refund_data?.total_refunded_cents ?? 0) >= 0
          const isPartiallyRefunded =
            (pi.refund_data?.net_amount_cents ?? 0) > 0

          return (
            <WebUI.VStack>
              <p>{Util.formatAmount(cell.getValue())}</p>
              {refunded &&
                !isPartiallyRefunded &&
                pi.refund_data?.total_refunded_cents && (
                  <p className={refundLinkClassNames}>
                    -
                    {Util.formatAmount(pi.refund_data?.total_refunded_cents, {
                      cents: true,
                    })}
                  </p>
                )}
            </WebUI.VStack>
          )
        },
      }),
      columnHelper.accessor((pi) => formatPaymentMethod(pi.payment), {
        id: 'payment.payment_method',
        size: 80,
        meta: {
          align: 'right',
        },
        header: 'Method',
      }),
      columnHelper.display({
        id: 'orderSummary',
        size: 80,
        meta: {
          align: 'right',
        },
        header: 'Order Summary',
        cell: ({row: {original: pi}}) => (
          <Link
            className="text-ds-sm"
            variant="primary"
            to={`payment/${pi.payment_id}/order-summary`}
          >
            View
          </Link>
        ),
      }),
    ],
    [columnHelper, refundLinkClassNames],
  )

  return (
    <WebUI.Panel {...restProps}>
      {data && data.length > 0 ? (
        <WebUI.VStack>
          <PaymentItemTableView
            className={
              '[&_.TableView-headerGroup]:px-8 [&_.TableViewCell_>_.Stack]:py-0 [&_.TableViewRow]:px-8 [&_>_.TableView-headerGroupList_>_.TableView-headerGroup]:border-b'
            }
            columns={columns}
            data={data}
          >
            {(table) => (
              <WebUI.VStack
                className={
                  '-order-1 items-stretch justify-center gap-4 border-b px-8 py-4 sm:flex-row sm:items-center sm:justify-between'
                }
              >
                <WebUI.HStack className="items-center gap-4">
                  <SearchForm
                    size="compact"
                    iconClassName="text-gray400"
                    className="min-w-[250px] text-ds-xs placeholder:text-ds-xs"
                    initialValues={{term: ''}}
                    onTermChange={table.setGlobalFilter}
                    noResult={table.getRowModel().rows.length === 0}
                    placeholder="Search by payer name"
                  />
                </WebUI.HStack>
                <WebUI.DeprecatedTooltip label="Download summary for this item (.csv)">
                  <WebUI.IconButton
                    className="h-[30px] w-[30px] text-ds-md"
                    size="default_alt"
                    aria-label="Download Item Summary (.csv)"
                    variant="secondary"
                    onClick={() =>
                      WebUI.downloadFile(
                        new Blob([makeItemPayersCSV(data)], {
                          type: 'data:text/csv;charset=utf-8',
                        }),
                        'summary.csv',
                      )
                    }
                  >
                    <WebUI.PhosphorIcon icon="download-simple" />
                  </WebUI.IconButton>
                </WebUI.DeprecatedTooltip>
              </WebUI.VStack>
            )}
          </PaymentItemTableView>
        </WebUI.VStack>
      ) : (
        <div className="h-[240px]" />
      )}
    </WebUI.Panel>
  )
}

// MARK: – Helpers

const makeItemPayersCSV = (items: Api.TabItemReportPaymentItem[]) => {
  const rows = items.flatMap((item) => ({
    payer: item.payment.tab_member.name,
    date: Util.formatDateAs(item.payment.created_at),
    qty: item.quantity,
    payment: Util.formatAmount(item.total),
    method: formatPaymentMethod(item.payment),
  }))

  const csvRows = rows.map((row) => [
    row.payer,
    row.date,
    row.qty,
    row.payment,
    row.method,
  ])

  const fields = ['Payer', 'Date', 'Qty', 'Payment', 'Method'].filter(
    (f) => f != null,
  )

  return Papa.unparse({
    fields,
    data: csvRows,
  })
}

export default ItemPaymentsTable
