import {Navigate, useParams} from 'react-router-dom'
import {api} from '@cheddarup/api-client'
import React from 'react'

export const CollectionManageRouteOrchestrator: React.FC = () => {
  const urlParams = useParams()
  const isFormsOnlyQuery = api.tabs.detail.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: urlParams.collection!,
      },
    },
    {
      select: (collection) =>
        collection.reportsAvailable.activeItemsCount === 0 &&
        collection.reportsAvailable.activeFormsCount +
          collection.reportsAvailable.activeSignupsCount >
          0,
    },
  )

  return (
    <Navigate
      replace
      to={isFormsOnlyQuery.data ? 'forms-signups' : 'payments'}
    />
  )
}
