import {DropzoneOptions, DropzoneState, useDropzone} from 'react-dropzone'
import React, {useContext, useImperativeHandle} from 'react'
import {ForwardRefComponent} from '@cheddarup/react-util'

import {Button} from './Button'
import {cn} from '../utils'

interface InternalFileUploaderContextValue extends DropzoneState {}

const InternalFileUploaderContext = React.createContext(
  {} as InternalFileUploaderContextValue,
)

export interface FileUploaderInstance
  extends InternalFileUploaderContextValue {}

export interface FileUploaderProps extends DropzoneOptions {
  children:
    | React.ReactNode
    | ((fileUploader: FileUploaderInstance) => React.ReactNode)
}

export const FileUploader = React.forwardRef<
  FileUploaderInstance,
  FileUploaderProps
>(
  (
    {
      accept,
      multiple = false,
      minSize,
      maxSize,
      maxFiles,
      preventDropOnDocument,
      noClick,
      noKeyboard,
      noDrag,
      noDragEventsBubbling,
      disabled,
      onDrop,
      onDropAccepted,
      onDropRejected,
      onFileDialogCancel,
      validator,
      children,
    },
    forwardedRef,
  ) => {
    const dropzone = useDropzone({
      accept,
      multiple,
      minSize,
      maxSize,
      maxFiles,
      preventDropOnDocument,
      noClick,
      noKeyboard,
      noDrag,
      noDragEventsBubbling,
      disabled,
      onDrop,
      onDropAccepted,
      onDropRejected,
      onFileDialogCancel,
      validator,
    })

    useImperativeHandle(forwardedRef, () => dropzone, [dropzone])

    return (
      <InternalFileUploaderContext.Provider value={dropzone}>
        {typeof children === 'function' ? children(dropzone) : children}
      </InternalFileUploaderContext.Provider>
    )
  },
)

// MARK: – FileUploaderButton

export const FileUploaderButton = React.forwardRef(
  (
    {as: Comp = Button, className, disabled, onClick, ...restProps},
    forwardedRef,
  ) => {
    const fileUploader = useContext(InternalFileUploaderContext)
    return (
      <Button
        ref={forwardedRef}
        className={cn('FileUploaderButton', className)}
        disabled={disabled ?? !fileUploader.open}
        onClick={(event) => {
          onClick?.(event)
          if (!event.defaultPrevented) {
            fileUploader.open()
          }
        }}
        {...restProps}
      />
    )
  },
) as ForwardRefComponent<typeof Button, {}>

// MARK: – FileUploaderDropzone

export const FileUploaderDropzone = React.forwardRef(
  ({className, as: Comp = 'div', ...restProps}, forwardedRef) => {
    const fileUploader = useContext(InternalFileUploaderContext)
    return (
      <Comp
        ref={forwardedRef}
        {...fileUploader.getRootProps({
          className: cn('FileUploaderDropzone', className),
          ...restProps,
        })}
      />
    )
  },
) as ForwardRefComponent<'div', {}>

// MARK: – FileUploaderInput

export const FileUploaderInput = React.forwardRef<
  HTMLInputElement,
  React.ComponentPropsWithoutRef<'input'>
>(({className, ...restProps}, forwardedRef) => {
  const fileUploader = useContext(InternalFileUploaderContext)
  return (
    <input
      ref={forwardedRef}
      {...fileUploader.getInputProps({
        className: cn('FileUploaderInput', className),
        ...restProps,
      })}
    />
  )
})
