import * as Yup from 'yup'
import React, {useRef, useState} from 'react'
import {useLocation, useParams} from 'react-router-dom'
import {useForkRef, useFormik} from '@cheddarup/react-util'
import * as WebUI from '@cheddarup/web-ui'
import {
  useResendTicketMutation,
  useUpdateTabPaymentItemMutation,
} from '@cheddarup/api-client'
import {getAttendeeEmail} from '@cheddarup/core'

import {Link} from './Link'
import PaymentNoteButtonDropdown from './PaymentNoteButtonDropdown'
import type {PaymentType} from './OrderSummaryLayout'

interface TicketActionsMenuProps
  extends WebUI.MenuButtonProps,
    React.ComponentPropsWithoutRef<'button'> {
  paymentItem: Api.TabItemPayment
  paymentType?: PaymentType
}

export const TicketActionsMenu = React.forwardRef<
  HTMLButtonElement,
  TicketActionsMenuProps
>(({paymentItem, paymentType, className, ...restProps}, forwardedRef) => {
  const location = useLocation()
  const urlParams = useParams()
  const collectionId = Number(urlParams.collection)
  const [doNotShowForRedeem, setDoNotShowForRedeem] = WebUI.useSessionStorage<
    boolean | null
  >(`DO_NOT_SHOW_REDEEM_${paymentItem.id}`, null)
  const [doNotShowForReactivate, setDoNotShowForReactivate] =
    WebUI.useSessionStorage<boolean | null>(
      `DO_NOT_SHOW_REACTIVATE_${paymentItem.id}`,
      null,
    )
  const redeemOrReactivateTicketAlertRef = useRef<WebUI.DialogInstance>(null)
  const resendTicketAlertRef = useRef<WebUI.DialogInstance>(null)
  const revokeTicketAlertRef = useRef<WebUI.DialogInstance>(null)
  const updateTabPaymentItemMutation = useUpdateTabPaymentItemMutation()

  const redeemed = paymentItem.detail.ticketStatus === 'redeemed'
  const revoked = paymentItem.detail.ticketStatus === 'revoked'

  const confirmRedeemedOrReactivate = () =>
    updateTabPaymentItemMutation.mutateAsync({
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: urlParams.collection!,
        paymentId: paymentItem.payment_id,
        paymentItemId: paymentItem.id,
      },
      body: {
        ticketStatus: redeemed ? 'unused' : 'redeemed',
      },
    })

  return (
    <WebUI.Menu placement="bottom-start">
      <WebUI.MenuButton
        ref={forwardedRef}
        className={WebUI.cn('rounded-full', className)}
        variant="secondary"
        as={WebUI.IconButton}
        {...restProps}
      >
        <WebUI.PhosphorIcon icon="dots-three-outline-fill" width={25} />
      </WebUI.MenuButton>

      <WebUI.MenuList className="text-ds-sm" shrinkable={false}>
        {revoked ? (
          <WebUI.MenuItem disabled>Edit Attendee</WebUI.MenuItem>
        ) : (
          <WebUI.MenuItem
            as={Link}
            variant="default"
            to={
              location.pathname.includes('payment')
                ? `edit-attendee?poId=${paymentItem.id}`
                : `payment/${paymentItem.payment_id}/edit-attendee?poId=${paymentItem.id}`
            }
          >
            Edit Attendee
          </WebUI.MenuItem>
        )}
        {paymentType === 'tab' && (
          <WebUI.MenuItem
            disabled={revoked}
            onClick={() => {
              if (
                (!redeemed && doNotShowForRedeem) ||
                (redeemed && doNotShowForReactivate)
              ) {
                confirmRedeemedOrReactivate()
              } else {
                redeemOrReactivateTicketAlertRef.current?.show()
              }
            }}
          >
            {redeemed ? 'Reactivate Ticket' : 'Redeem Ticket'}
          </WebUI.MenuItem>
        )}
        <WebUI.MenuItem
          disabled={revoked}
          onClick={() => resendTicketAlertRef.current?.show()}
        >
          Resend Ticket
        </WebUI.MenuItem>
        {paymentType === 'tab' && (
          <WebUI.MenuItem
            disabled={revoked}
            onClick={() => revokeTicketAlertRef.current?.show()}
          >
            Revoke Ticket
          </WebUI.MenuItem>
        )}
        <WebUI.MenuItem
          as={Link}
          variant="default"
          preserveSearch
          to={`payment/${paymentItem.payment_id}/order-summary`}
        >
          View Order Summary
        </WebUI.MenuItem>
        {paymentType === 'tab' && (
          <PaymentNoteButtonDropdown
            className="px-[1em] text-left"
            variant="ghost"
            collectionId={collectionId}
            itemPaymentItem={paymentItem}
          >
            Add Attendee Note
          </PaymentNoteButtonDropdown>
        )}
        <WebUI.MenuItem
          as={Link}
          variant="default"
          to={`/pdf/payments/${paymentItem.payment_id}/ticket-item/${paymentItem.id}`}
          target="_blank"
        >
          Download Ticket
        </WebUI.MenuItem>
      </WebUI.MenuList>
      <RedeemOrReactivateTicketAlert
        ref={redeemOrReactivateTicketAlertRef}
        paymentId={paymentItem.payment_id}
        ticketId={paymentItem.id}
        redeemed={redeemed}
        onConfirmRedeemOrReactivate={confirmRedeemedOrReactivate}
        onConfirmDoNotShowAgain={() => {
          if (redeemed) {
            setDoNotShowForReactivate(true)
          } else {
            setDoNotShowForRedeem(true)
          }
        }}
      />
      <ResendTicketAlert
        ref={resendTicketAlertRef}
        paymentId={paymentItem.payment_id}
        ticketId={paymentItem.id}
        email={getAttendeeEmail(paymentItem.item_field_views)}
      />
      <RevokeTicketAlert
        ref={revokeTicketAlertRef}
        paymentId={paymentItem.payment_id}
        ticketId={paymentItem.id}
      />
    </WebUI.Menu>
  )
})

// MARK: – RedeemOrReactivateTicketAlert

interface RedeemOrReactivateTicketAlertProps extends WebUI.AlertProps {
  paymentId: number
  ticketId: number
  redeemed: boolean
  onConfirmRedeemOrReactivate: () => void
  onConfirmDoNotShowAgain: () => void
}

const RedeemOrReactivateTicketAlert = React.forwardRef<
  WebUI.DialogInstance,
  RedeemOrReactivateTicketAlertProps
>(
  (
    {
      paymentId,
      ticketId,
      redeemed,
      onConfirmRedeemOrReactivate,
      onConfirmDoNotShowAgain,
      ...restProps
    },
    forwardedRef,
  ) => {
    const [doNotShowAgain, setDoNotShowAgain] = useState(false)

    return (
      <WebUI.Alert
        aria-label="Redeem or Reactivate ticket confirmation"
        ref={forwardedRef}
        {...restProps}
      >
        <WebUI.AlertHeader>Are you sure?</WebUI.AlertHeader>
        <WebUI.AlertContentView
          text={
            <WebUI.VStack className="gap-4">
              <span>
                {redeemed
                  ? 'This ticket will be reactivated and will be redeemable by attendee.'
                  : 'This ticket will be marked as redeemed and can no longer be used by others.'}
              </span>
              <WebUI.Checkbox
                size="compact"
                state={doNotShowAgain}
                onChange={(event) => setDoNotShowAgain(event.target.checked)}
              >
                Do not show again
              </WebUI.Checkbox>
            </WebUI.VStack>
          }
          actions={
            <>
              <WebUI.AlertActionButton
                execute={async () => {
                  await onConfirmRedeemOrReactivate()
                  if (doNotShowAgain) {
                    onConfirmDoNotShowAgain()
                  }
                }}
              >
                {redeemed ? 'Reactivate Ticket' : 'Redeem Ticket'}
              </WebUI.AlertActionButton>
              <WebUI.AlertCancelButton />
            </>
          }
        />
      </WebUI.Alert>
    )
  },
)

// MARK: – ResendTicketAlert

interface ResendTicketAlertProps extends WebUI.AlertProps {
  paymentId: number
  ticketId: number
  email: string
}

const ResendTicketAlert = React.forwardRef<
  WebUI.DialogInstance,
  ResendTicketAlertProps
>(({paymentId, ticketId, email, ...restProps}, forwardedRef) => {
  const urlParams = useParams()
  const resendTicketMutation = useResendTicketMutation()
  const ownRef = useRef<WebUI.DialogInstance>(null)
  const ref = useForkRef(forwardedRef, ownRef)

  const formik = useFormik({
    initialValues: {
      shouldSendToDifferentEmail: false,
      email: '',
    },
    validationSchema: Yup.object({
      shouldSendToDifferentEmail: Yup.boolean(),
      email: Yup.string().when('shouldSendToDifferentEmail', {
        is: true,
        // biome-ignore lint/suspicious/noThenProperty:
        then: (schema) => schema.email('Invalid format').required('Required'),
      }),
    }),
    onSubmit: async (values) => {
      await resendTicketMutation.mutateAsync({
        pathParams: {
          // biome-ignore lint/style/noNonNullAssertion:
          tabId: urlParams.collection!,
          paymentId,
          paymentItemId: ticketId,
        },
        body: {
          email: values.shouldSendToDifferentEmail ? values.email : undefined,
        },
      })

      ownRef.current?.hide()
    },
  })

  return (
    <WebUI.Alert
      aria-label="Resend ticket confirmation"
      ref={ref}
      {...restProps}
    >
      <WebUI.AlertContentView
        text={
          <WebUI.VStack className="gap-4">
            <WebUI.Text>Where should we send the ticket?</WebUI.Text>
            <span>
              The email we have for this ticket is{' '}
              <span className="font-bold">{email}.</span>
            </span>
            <WebUI.Form
              onReset={formik.handleReset}
              onSubmit={formik.handleSubmit}
            >
              <WebUI.Checkbox
                name="shouldSendToDifferentEmail"
                size="compact"
                state={formik.values.shouldSendToDifferentEmail}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                Send to different email address
              </WebUI.Checkbox>
              {formik.values.shouldSendToDifferentEmail && (
                <WebUI.FormField
                  className="grow"
                  label="Email"
                  error={formik.errors.email}
                >
                  <WebUI.Input
                    name="email"
                    type="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </WebUI.FormField>
              )}
            </WebUI.Form>
          </WebUI.VStack>
        }
        actions={
          <>
            <WebUI.Button
              variant="default"
              loading={formik.isSubmitting}
              onClick={() => formik.submitForm()}
            >
              Resend
            </WebUI.Button>
            <WebUI.AlertCancelButton />
          </>
        }
      />
    </WebUI.Alert>
  )
})

// MARK: – RevokeTicketAlert

interface RevokeTicketAlertProps extends WebUI.AlertProps {
  paymentId: number
  ticketId: number
}

const RevokeTicketAlert = React.forwardRef<
  WebUI.DialogInstance,
  RevokeTicketAlertProps
>(({paymentId, ticketId, ...restProps}, forwardedRef) => {
  const urlParams = useParams()
  const updateTabPaymentItemMutation = useUpdateTabPaymentItemMutation()
  const [shouldSendEmail, setShouldSendEmail] = useState(true)

  return (
    <WebUI.Alert
      aria-label="Redeem or Reactivate ticket confirmation"
      ref={forwardedRef}
      {...restProps}
    >
      <WebUI.AlertHeader>
        <WebUI.HStack className="items-center gap-1">
          <span className="font-bold">Are you sure?</span>
          <span className="text-orange-50">This action can not be undone.</span>
        </WebUI.HStack>
      </WebUI.AlertHeader>
      <WebUI.AlertContentView
        text={
          <WebUI.VStack className="gap-4">
            <span>
              Revoking a ticket voids the QR code ticket sent to the attendee so
              it can no longer be used. When you revoke a ticket, this returns
              the ticket to the ticket pool and cannot be undone.
            </span>
            <span>
              Revoking a ticket will NOT automatically refund the purchaser.
              That must be done separately.{' '}
              <WebUI.Anchor>Learn about refunds.</WebUI.Anchor>
            </span>
            <span>
              Common reasons to revoke a ticket are because a refund was made or
              because an event has been cancelled.
            </span>
            <WebUI.Checkbox
              size="compact"
              state={shouldSendEmail}
              onChange={(event) => setShouldSendEmail(event.target.checked)}
            >
              Send attendee an email that their ticket has been revoked.
            </WebUI.Checkbox>
          </WebUI.VStack>
        }
        actions={
          <>
            <WebUI.AlertActionButton
              execute={() =>
                updateTabPaymentItemMutation.mutateAsync({
                  pathParams: {
                    // biome-ignore lint/style/noNonNullAssertion:
                    tabId: urlParams.collection!,
                    paymentId,
                    paymentItemId: ticketId,
                  },
                  body: {
                    ticketStatus: 'revoked',
                    notify: shouldSendEmail,
                  },
                })
              }
            >
              Revoke Ticket
            </WebUI.AlertActionButton>
            <WebUI.AlertCancelButton />
          </>
        }
      />
    </WebUI.Alert>
  )
})
