import React from 'react'
import * as WebUI from '@cheddarup/web-ui'

import {NewAppHeader, NewAppHeaderMobile} from './AppHeader'

export interface WithAppHeaderLayoutProps {
  children?: React.ReactNode
}

export const WithAppHeaderLayout: React.FC<WithAppHeaderLayoutProps> = ({
  children,
}) => {
  const media = WebUI.useMedia()

  return (
    <>
      {media.sm ? <NewAppHeader /> : <NewAppHeaderMobile />}
      {children}
    </>
  )
}
