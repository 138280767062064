import {useEffect, useMemo, useState} from 'react'

export const useGenericMedia = (query: string) => {
  const [matches, setMatches] = useState(() => matchMedia(query).matches)

  useEffect(() => {
    let mounted = true

    const mediaQueryList = matchMedia(query)
    const handleChange = () => {
      if (mounted) {
        setMatches(!!mediaQueryList.matches)
      }
    }

    if (mediaQueryList.addEventListener) {
      mediaQueryList.addEventListener('change', handleChange)
    } else {
      // See https://github.com/mdn/sprints/issues/858
      mediaQueryList.addListener(handleChange)
    }

    return () => {
      mounted = false
      if (mediaQueryList.removeEventListener) {
        mediaQueryList.removeEventListener('change', handleChange)
      } else {
        mediaQueryList.removeListener(handleChange)
      }
    }
  }, [query])

  return matches
}

export const useMedia = () => {
  const sm = useGenericMedia('(min-width: 640px)')
  const md = useGenericMedia('(min-width: 768px)')
  const lg = useGenericMedia('(min-width: 1024px)')
  const xl = useGenericMedia('(min-width: 1280px)')

  const res = useMemo(
    () => ({
      sm,
      md,
      lg,
      xl,
    }),
    [sm, md, lg, xl],
  )

  return res
}
