import {deepPartialify, z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {
  deliveryRecipientSchema,
  messageRemindersToSchema,
  messageSchema,
  messageTypeSchema,
  paginationSchema,
} from '../schemas'

export default {
  list: makeEndpoint({
    path: 'users/messages',
    queryParamsSchema: z
      .object({
        message_type: messageTypeSchema.nullable(),
        tab_id: z.number().nullable(),
        recipient: z.string().nullable(),
        reminder_id: z.number().nullable(),
        name: z.string().nullable(),
        name_or_email: z.string().nullable(),
        page: z.number(),
        per_page: z.number(),
        group_page: z.boolean().nullable(),
      })
      .partial()
      .optional(),
    responseSchema: z.object({
      pagination: paginationSchema,
      data: messageSchema.array(),
    }),
  }),
  detail: makeEndpoint({
    path: 'users/messages/:messageId',
    queryParamsSchema: z
      .object({
        tab_id: z.number().nullish(),
      })
      .optional(),
    responseSchema: messageSchema,
  }),
  deliveryHistory: makeEndpoint({
    path: 'users/messages/:messageId/history',
    queryParamsSchema: z
      .object({
        tab_id: z.number().nullish(),
      })
      .optional(),
    responseSchema: deliveryRecipientSchema.array(),
  }),
  create: makeEndpoint({
    path: 'users/messages',
    method: 'POST',
    queryParamsSchema: z
      .object({
        tab_id: z.number(),
      })
      .partial()
      .optional(),
    bodySchema: deepPartialify(messageSchema),
    responseSchema: messageSchema,
  }),
  sendTest: makeEndpoint({
    path: 'users/messages/send_test',
    method: 'POST',
    queryParamsSchema: z
      .object({
        tab_id: z.number(),
      })
      .partial()
      .optional(),
    responseSchema: z.object({success: z.boolean()}),
  }),
  resend: makeEndpoint({
    path: 'users/messages/:messageId/resend_single',
    method: 'POST',
    queryParamsSchema: z
      .object({
        tab_id: z.number(),
      })
      .partial()
      .optional(),
    responseSchema: z.any(),
  }),
  createReminder: makeEndpoint({
    path: 'users/messages/:messageId/create_reminder',
    method: 'POST',
    queryParamsSchema: z
      .object({
        tab_id: z.number(),
      })
      .partial()
      .optional(),
    bodySchema: z.object({
      recipients: messageRemindersToSchema,
      start_at: z.string().nullable(),
      end_at: z.string().nullable(),
      repeat_seconds: z.number(),
    }),
    responseSchema: z.any(),
  }),
  deleteReminder: makeEndpoint({
    path: 'users/reminders/:reminderId',
    method: 'DELETE',
    responseSchema: z.any(),
  }),
}
