import React, {useRef} from 'react'
import {DateValue, useDateField} from 'react-aria'
import {DateFieldStateOptions, useDateFieldState} from 'react-stately'
import {useForkRef} from 'reakit-utils'
import {GregorianCalendar} from '@internationalized/date'
import {SetOptional, SimpleMerge} from '@cheddarup/util'
import {genericForwardRef} from '@cheddarup/react-util'

import {DateSegment, DateSegmentBox, DateSegmentBoxProps} from './DateSegment'
import {cn} from '../utils'

export interface DateInputProps<T extends DateValue>
  extends Omit<
    SimpleMerge<
      DateSegmentBoxProps,
      SetOptional<DateFieldStateOptions<T>, 'locale'>
    >,
    'createCalendar' | 'onChange'
  > {
  onValueChange?: (value: DateValue) => void
}

export const DateInput = genericForwardRef(
  <T extends DateValue>(
    {
      variant = 'default',
      size = 'default',
      locale = 'en-US',
      onValueChange,
      className,
      disabled,
      required,
      readOnly,
      ...restProps
    }: DateInputProps<T>,
    forwardedRef: React.Ref<HTMLDivElement>,
  ) => {
    const ownRef = useRef<HTMLDivElement>(null)
    const ref = useForkRef(ownRef, forwardedRef)
    const state = useDateFieldState({
      createCalendar,
      locale,
      isDisabled: disabled,
      isReadOnly: readOnly,
      isRequired: required,
      onChange: onValueChange,
      ...restProps,
    })
    const {fieldProps} = useDateField(restProps, state, ownRef)

    return (
      <DateSegmentBox
        ref={ref}
        className={cn('DateInput', className)}
        size={size}
        variant={variant}
        {...fieldProps}
      >
        {state.segments.map((segment, idx) => (
          <DateSegment key={idx} segment={segment} state={state} />
        ))}
      </DateSegmentBox>
    )
  },
)

// MARK: – Helpers

// See https://react-spectrum.adobe.com/react-aria/useDateField.html#reducing-bundle-size
export function createCalendar(identifier: string) {
  switch (identifier) {
    case 'gregory':
      return new GregorianCalendar()
    default:
      throw new Error(`Unsupported calendar ${identifier}`)
  }
}
