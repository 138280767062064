import * as Util from '@cheddarup/util'

import {formatEmailStatus} from './email-status-helpers'

export const formatGiftCardStatus = (gift: Api.Withdrawal | null) => {
  if (!gift) {
    return 'unknown'
  }
  return gift.tango_card?.redemption_email
    ? formatEmailStatus(gift.tango_card?.redemption_email.status)
    : Util.capitalize(gift.status)
}

// Maximum amount allowed for a single gift card
export const TANGO_GIFT_MAX_AMOUNT = 2000
// Amounts >= this threshold require a bank account
export const TANGO_PER_GIFT_BANK_ACCOUNT_THRESHOLD = 1000
// Total gift card amount across all collections that triggers bank account requirement
export const TANGO_GIFT_TOTAL_BANK_THRESHOLD = 5000
