import {BrandKitColors} from '@cheddarup/core'
import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import React from 'react'
import {SharpImage} from 'src/components/SharpImage'
import {getCUReadableColor} from 'src/helpers/color-utils'
import CollectionEmptyImage from 'src/images/CollectionEmptyImage.svg'

interface CollectionsSectionProps
  extends React.ComponentPropsWithoutRef<'div'> {
  brandKitColors: BrandKitColors
  collectionsSection: Api.GroupPageSection
}

const CollectionsSection: React.FC<CollectionsSectionProps> = ({
  brandKitColors,
  collectionsSection,
  className,
  style,
  ...restProps
}) => {
  const sortedCategoriesAndCollections = collectionsSection.categories
    ? Util.sort(
        collectionsSection.categories
          .filter((cat) => cat.collections.length > 0)
          .map((cat) => ({
            ...cat,
            collections: Util.sort(cat.collections).asc((c) => c.position),
          })),
      ).asc((cat) => cat.position)
    : []

  return (
    <div
      className={WebUI.cn('py-16 sm:pb-[94px]', className)}
      style={{
        color: getCUReadableColor(
          brandKitColors[collectionsSection.background_color],
          {darkColor: '#373737'},
        ),
        backgroundColor: brandKitColors[collectionsSection.background_color],
        ...style,
      }}
      {...restProps}
    >
      <div className="mx-auto flex w-full max-w-[1100px] flex-col gap-9">
        <div className="flex flex-col gap-5 px-8 xl:px-6">
          <WebUI.Heading className="font-accentAlt text-h-6 sm:text-h-4">
            {collectionsSection.headline}
          </WebUI.Heading>
          <WebUI.Text className="font-light text-ds-md">
            {collectionsSection.description}
          </WebUI.Text>
        </div>
        {sortedCategoriesAndCollections.map((category) => (
          <CollectionsCategory
            key={category.id}
            brandKitColors={brandKitColors}
            category={category}
          />
        ))}
      </div>
    </div>
  )
}

// MARK: - CollectionsCategory

interface CollectionsCategoryProps
  extends React.ComponentPropsWithoutRef<'div'> {
  brandKitColors: BrandKitColors
  category: Api.GroupPageCategory
}

const CollectionsCategory: React.FC<CollectionsCategoryProps> = ({
  brandKitColors,
  category,
  className,
  ...restProps
}) => (
  <WebUI.Panel
    className={WebUI.cn(
      'sm:!rounded-[20px] flex flex-col gap-9 border-none px-6 py-5 sm:py-8 sm:shadow-z4',
      className,
    )}
    {...restProps}
  >
    {category.name !== 'default' && (
      <WebUI.Text className="font-extrabold text-ds-lg text-gray800">
        {category.name}
      </WebUI.Text>
    )}
    <div className="flex flex-row flex-wrap gap-x-4 gap-y-6">
      {category.collections.map((collection) => (
        <CollectionCard
          key={collection.id}
          collection={collection}
          brandKitColors={brandKitColors}
        />
      ))}
    </div>
  </WebUI.Panel>
)

// MARK: - CollectionCard

interface CollectionCardProps extends React.ComponentPropsWithoutRef<'div'> {
  brandKitColors: BrandKitColors
  collection: Api.GroupTab
}

const CollectionCard = ({
  brandKitColors,
  collection,
  className,
  ...restProps
}: CollectionCardProps) => {
  const media = WebUI.useMedia()

  const imageWidth = media.sm ? 340 : '100%'
  const imageHeight = 150

  return (
    <WebUI.Panel
      className={WebUI.cn(
        'relative w-full rounded-[15px] border sm:w-[340px]',
        className,
      )}
      variant="capsule"
      {...restProps}
    >
      <WebUI.Anchor
        className="block whitespace-normal"
        href={`/c/${collection.slug}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <SharpImage
          width={imageWidth}
          height={imageHeight}
          alt={`${collection.name} banner`}
          image={collection.featured_image}
          aspectRatio={16 / 9}
          errorFallback={
            <div
              className={WebUI.cn(
                'flex shrink-0 flex-col items-center justify-center bg-natural-70 text-natural-100',
                className,
              )}
              style={{
                width: imageWidth,
                height: imageHeight,
                fontSize: imageHeight / 2,
              }}
              {...restProps}
            >
              <img className="w-[1em]" alt="" src={CollectionEmptyImage} />
            </div>
          }
        />

        <div className="flex h-[88px] flex-col justify-between px-6 py-5">
          <WebUI.Heading className="line-clamp-2 text-gray800" as="h4">
            {collection.name}
          </WebUI.Heading>
        </div>
        {collection.status === 'draft' && (
          <span
            className="absolute top-0 right-0 px-5 py-3 font-extrabold text-ds-sm"
            style={{
              color: brandKitColors.primary,
              backgroundColor: brandKitColors.neutral,
            }}
          >
            Closed
          </span>
        )}
      </WebUI.Anchor>
    </WebUI.Panel>
  )
}

export default CollectionsSection
