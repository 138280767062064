import * as Util from '@cheddarup/util'
import {FormikHandlers, FormikHelpers, FormikState} from 'formik'
import * as WebUI from '@cheddarup/web-ui'
import {PayerOverview, RefundsTable} from 'src/components'
import {getAttendeeName} from '@cheddarup/core'
import {useParams} from 'react-router-dom'
import {forwardRef, useState} from 'react'
import {
  useCreateTabPaymentRefundMutation,
  useRevokeTicketsMutation,
} from '@cheddarup/api-client'
import {useFormik} from '@cheddarup/react-util'

import RefundableItemTable from './RefundableItemTable'

const MAX_DAYS_COUNT = 90

export interface RefundsManageProps {
  totalRefundable: number | undefined
  refundableData: Api.TabPaymentRefundableData
  selectedRefundId: number | null
  payment: Api.TabPayment
  onViewRefund: (refund: Api.TabPaymentRefund) => void
}

const RefundsManage = ({
  totalRefundable,
  refundableData,
  selectedRefundId,
  payment,
  onViewRefund,
}: RefundsManageProps) => {
  const oldToRefund =
    payment.payment_method === 'echeck' &&
    Util.differenceInDays(new Date(), new Date(payment.created_at)) >
      MAX_DAYS_COUNT
  const refundable =
    !!payment.can_refund && !oldToRefund && totalRefundable !== 0

  return (
    <>
      <PayerOverview
        className="border-b px-2 pb-8"
        tabMember={payment.tab_member}
      />
      <div className="py-4 text-ds-sm">
        {refundable || (payment.refunds && payment.refunds.length > 0) ? (
          <>
            <p>
              You can refund all or part of your buyer&apos;s payments. When you
              refund a payment made in the past 10 days, any fees that may have
              been incurred will also be refunded. Payers will see their refund
              processed within 5-10 business days and will receive an e-mail
              from Cheddar Up letting them know that a refund has been issued.
            </p>
            <br />
            <p>
              To issue a refund, select the refund quantity for each item. If
              your item has a limited quantity available, it will be updated
              (relisted) according to this selection.
            </p>
          </>
        ) : (
          <p>
            {(() => {
              if (oldToRefund) {
                return 'e-Check Payments cannot be refunded more than 90 days from the payment date.'
              }

              if (!refundable) {
                return 'This payment cannot be refunded.'
              }

              return totalRefundable
                ? 'You can refund all or part of a payment once it has cleared.'
                : 'You have no available balance on this collection to issue a refund.'
            })()}
          </p>
        )}
      </div>
      {payment.refunds && payment.refunds.length > 0 && (
        <RefundsTable
          className="mb-8"
          data={payment.refunds}
          selectedRefundId={selectedRefundId}
          onViewRefund={onViewRefund}
        />
      )}
      {(refundable || (payment.refunds && payment.refunds.length > 0)) &&
        refundableData && (
          <RefundForm
            refundableData={refundableData}
            payment={payment}
            totalRefundable={totalRefundable ?? 0}
            refundable={refundable}
          />
        )}
    </>
  )
}

// MARK: – RefundForm

export type RefundFormFormik = FormikState<RefundFormValues> &
  FormikHelpers<RefundFormValues> &
  FormikHandlers

export interface RefundFormValues {
  quantities: Record<string, number>
  shipping: string
  additionalRefund: string
  taxes: string[]
}

interface RefundFormProps {
  payment: Api.TabPayment
  refundableData: Api.TabPaymentRefundableData
  totalRefundable: number
  refundable: boolean
}

const RefundForm = ({
  payment,
  refundableData,
  totalRefundable,
  refundable,
}: RefundFormProps) => {
  const urlParams = useParams()
  const [ticketsToRevoke, setTicketsToRevoke] = useState<Api.TicketPayment[]>(
    [],
  )
  const createRefundMutation = useCreateTabPaymentRefundMutation()
  const growlActions = WebUI.useGrowlActions()

  const collectionId = Number(urlParams.collection)
  const paymentId = Number(urlParams.payment)

  const formik = useFormik<RefundFormValues>({
    initialValues: {
      quantities: Util.mapToObj(refundableData.items, (refundableItem) => [
        refundableItem.payment_item_id,
        0,
      ]),
      shipping: '',
      additionalRefund: '',
      taxes: refundableData.taxes.map(() => ''),
    },
    onSubmit: async (values, formikHelpers) => {
      const items = refundableData.items
        .map((refundableItem) => {
          const quantity = Number(
            values.quantities[`quantity-${refundableItem.payment_item_id}`],
          )
          return {
            quantity,
            payment_item_id: refundableItem.payment_item_id,
            name: refundableItem.name,
            amount: quantity * refundableItem.perItemNet,
          }
        })
        .filter((i) => i.quantity > 0)
      const taxes = values.taxes
        .filter((tax) => Number(tax) > 0)
        .map((tax, idx) => ({
          name: refundableData.taxes[idx]?.name ?? '',
          amount: getCents(tax),
        }))
      const shipping = getCents(values.shipping)
      const additional = getCents(values.additionalRefund)
      const total = getTotalRefundAmount({
        values,
        refundableItems: refundableData.items,
      })
      const refundableTicketItems = refundableData.items.filter(
        ({payment_item_id, itemType}) =>
          Number(values.quantities[`quantity-${payment_item_id}`]) > 0 &&
          itemType === 'ticket',
      )

      try {
        await createRefundMutation.mutateAsync({
          pathParams: {
            tabId: collectionId,
            paymentId,
          },
          body: {
            items,
            taxes,
            shipping,
            additional,
            total,
          },
        })

        formikHelpers.resetForm()

        growlActions.show('success', {
          body: `Refunded ${Util.formatAmount(total ?? 0, {
            cents: true,
          })}. Email confirmations will be sent shortly.`,
          title: 'Refund Confirmation:',
        })

        const nonRevokedTicketPaymentItems = refundableTicketItems
          .map(
            (rti) =>
              payment.payment_items.find(
                (pi) => pi.id === rti.payment_item_id,
              ) as any as Api.TicketPayment | null,
          )
          .filter((ticket) => ticket != null)
          .filter((ticket) => ticket.detail.ticketStatus !== 'revoked')

        setTicketsToRevoke(nonRevokedTicketPaymentItems)
      } catch (err: any) {
        let body: any
        const error = err.data?.error ?? ''

        switch (error) {
          case 'Non refundable payment':
            body = 'Sorry, this payment is not refundable.'
            break
          case 'Refundable Balance Exceeded':
            body =
              "Refund amount cannot be more than the collection's available withdrawal balance."
            break
          case 'Refund exceeds total payment':
            body = 'Refund amount cannot exceed the total payment.'
            break
          default:
            body =
              'Refund failed, please try another amount. If you continue having difficulty, please contact Cheddar Up support.'
        }
        growlActions.show('error', {title: 'Error', body})
      }
    },
  })

  const totalRefundAmount = getTotalRefundAmount({
    values: formik.values,
    refundableItems: refundableData.items,
  })

  const lessEqualThanZero = totalRefundAmount <= 0
  const lessThanZero = totalRefundAmount < 0
  const greaterThanTotalRefundable = totalRefundAmount > totalRefundable
  const existingItem = Object.values(formik.values.quantities).some(
    (v) => Number(v) > 0,
  )
  const readyToRefund =
    !greaterThanTotalRefundable &&
    (!lessEqualThanZero || (existingItem && !lessThanZero))

  return (
    <>
      <WebUI.Form onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
        <RefundableItemTable
          selectedQuantities={formik.values.quantities}
          refundableItems={(refundableData?.items ?? []).filter(
            (i) => i.refundableQuantity > 0,
          )}
          formik={formik}
        />
        {refundable && (
          <WebUI.HStack className="flex-end items-center gap-2">
            <WebUI.Text className="text-ds-xs">Additional Refund</WebUI.Text>
            <WebUI.AmountInput
              className="w-32 text-right"
              name="additionalRefund"
              placeholder="$0.00"
              value={formik.values.additionalRefund}
              onValueChange={(newAdditionalRefund) =>
                formik.setFieldValue('additionalRefund', newAdditionalRefund)
              }
              onBlur={formik.handleBlur}
            />
          </WebUI.HStack>
        )}
        {!!refundableData.shipping && (
          <WebUI.HStack className="flex-end items-center gap-2">
            <WebUI.Text className="text-ds-xs">
              Shipping (
              {Util.formatAmount(refundableData.shipping, {cents: true})})
            </WebUI.Text>
            <WebUI.AmountInput
              className="w-32 text-right"
              name="shipping"
              placeholder="$0.00"
              value={formik.values.shipping}
              onValueChange={(newShipping) =>
                formik.setFieldValue('shipping', newShipping)
              }
              onBlur={formik.handleBlur}
            />
          </WebUI.HStack>
        )}
        {refundableData.taxes.map((tax, idx) => (
          <WebUI.HStack key={idx} className="items-center justify-end gap-2">
            <div className="text-ds-xs">
              {tax.name} ({Util.formatAmount(tax.amount, {cents: true})})
            </div>
            <WebUI.AmountInput
              className="w-32 text-right"
              name={`taxes.${idx}`}
              placeholder="$0.00"
              // @ts-expect-error
              value={formik.values[`taxes.${idx}`]}
              onValueChange={(newTax) =>
                formik.setFieldValue(`taxes.${idx}`, newTax)
              }
              onBlur={formik.handleBlur}
            />
          </WebUI.HStack>
        ))}
        <WebUI.Button
          className="self-end"
          variant={readyToRefund ? 'default' : 'secondary'}
          type="submit"
          loading={formik.isSubmitting}
          disabled={!readyToRefund}
        >
          Refund{' '}
          {readyToRefund && Util.formatAmount(totalRefundAmount, {cents: true})}
        </WebUI.Button>
        {greaterThanTotalRefundable && (
          <div className="max-w-[320px] self-end text-right text-ds-sm text-orange-50">
            Your total refund amount should be equal to or less than your
            available collection balance of{' '}
            {Util.formatAmount(totalRefundable, {cents: true})}
          </div>
        )}
      </WebUI.Form>

      <RefundConfirmationAlert
        key={`refund-confirmation-alert-${ticketsToRevoke
          .map((ti) => ti.id)
          .join(',')}`}
        visible={ticketsToRevoke.length > 0}
        collectionId={collectionId}
        paymentId={paymentId}
        ticketItems={ticketsToRevoke}
      />
    </>
  )
}

// MARK: – RefundConfirmationAlert

interface RefundConfirmationAlertProps extends WebUI.AlertProps {
  ticketItems: Api.TicketPayment[]
  collectionId: number
  paymentId: number
}

const RefundConfirmationAlert = forwardRef<
  WebUI.DialogInstance,
  RefundConfirmationAlertProps
>(({collectionId, paymentId, ticketItems, ...restProps}, forwardedRef) => {
  const [selectedTicketIds, setSelectedTicketIds] = useState(
    ticketItems.map((ti) => ti.id),
  )
  const revokeTicketsMutation = useRevokeTicketsMutation()

  return (
    <WebUI.Alert
      aria-label="Revoke refunded tickets confirmation"
      ref={forwardedRef}
      {...restProps}
    >
      <WebUI.AlertHeader>
        Do you also want to revoke these tickets?
      </WebUI.AlertHeader>
      <WebUI.AlertContentView
        text={
          <WebUI.VStack className="gap-4">
            <span>
              Would you like to revoke the following refunded tickets? Revoking
              a ticket voids the QR code ticket sent to the attendee so it can
              no longer be used. When you revoke a ticket, this returns the
              ticket to the ticket pool.
            </span>
            <WebUI.VStack className="gap-2">
              {ticketItems.map((item) => (
                <WebUI.Checkbox
                  key={item.id}
                  size="compact"
                  checked={selectedTicketIds.includes(item.id)}
                  onChange={(event) =>
                    setSelectedTicketIds((prevSelectedTicketIds) =>
                      event.target.checked
                        ? [...prevSelectedTicketIds, item.id]
                        : prevSelectedTicketIds.filter((id) => item.id !== id),
                    )
                  }
                >
                  {`Ticket #${Util.encodeToBase36(
                    item.id,
                  )}, ${getAttendeeName(item.item_field_views)}`}
                </WebUI.Checkbox>
              ))}
            </WebUI.VStack>
          </WebUI.VStack>
        }
        actions={
          <>
            <WebUI.AlertActionButton
              execute={() =>
                revokeTicketsMutation.mutateAsync({
                  pathParams: {
                    tabId: collectionId,
                    paymentId,
                  },
                  body: {
                    ids: selectedTicketIds,
                  },
                })
              }
            >
              Revoke {Util.pluralize('Ticket', selectedTicketIds.length, true)}
            </WebUI.AlertActionButton>
            <WebUI.AlertCancelButton />
          </>
        }
      />
    </WebUI.Alert>
  )
})

// MARK: – Helpers

const getCents = (amount: string | number) => Math.floor(Number(amount) * 100)

const getTotalRefundAmount = ({
  refundableItems,
  values,
}: {
  refundableItems: Api.TabPaymentRefundableItem[]
  values: RefundFormValues
}) => {
  const refundAmounts = Util.arrayFromObject(
    values.quantities,
    (key, quantity) => ({
      paymentItemId: key.replace(/^\D+/g, ''),
      quantity: Number(quantity),
    }),
  ).map(({paymentItemId, quantity}) => {
    const refundableItem = refundableItems.find(
      ({payment_item_id}) => String(payment_item_id) === paymentItemId,
    )

    return quantity * (refundableItem?.perItemNet ?? 0)
  })
  const taxAmounts = values.taxes.map((x) => getCents(x))

  return (
    Util.sum(refundAmounts) +
    Util.sum(taxAmounts) +
    getCents(Number(values.additionalRefund) + Number(values.shipping))
  )
}

export default RefundsManage
