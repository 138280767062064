import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {signUpSpotSchema} from '../schemas'

export default {
  list: makeEndpoint({
    path: 'users/tabs/:tabId/signups/:signupId/spots',
    responseSchema: signUpSpotSchema.array(),
  }),
  detail: makeEndpoint({
    path: 'users/tabs/:tabId/signups/:signupId/spots/:spotId',
    responseSchema: signUpSpotSchema,
  }),
  create: makeEndpoint({
    path: 'users/tabs/:tabId/signups/:signupId/spots',
    method: 'POST',
    bodySchema: z.object({
      name: z.string(),
      description: z.string(),
      hidden: z.boolean(),
      available_quantity: z.number().nullable(),
    }),
    responseSchema: signUpSpotSchema,
  }),
  delete: makeEndpoint({
    path: 'users/tabs/:tabId/signups/:signupId/spots/:spotId',
    method: 'DELETE',
    responseSchema: z.any(),
  }),
  update: makeEndpoint({
    path: 'users/tabs/:tabId/signups/:signupId/spots/:spotId',
    method: 'PATCH',
    bodySchema: z
      .object({
        name: z.string(),
        description: z.string(),
        hidden: z.boolean(),
      })
      .partial(),
    responseSchema: signUpSpotSchema,
  }),
  sort: makeEndpoint({
    path: 'users/tabs/:tabId/signups/:signupId/sort_spots',
    method: 'POST',
    bodySchema: z.object({
      order: z.number().array(),
    }),
    responseSchema: z.any(),
  }),
  clone: makeEndpoint({
    path: 'users/tabs/:tabId/signups/:signupId/spots/:spotId/clone',
    method: 'POST',
    responseSchema: signUpSpotSchema,
  }),
}
