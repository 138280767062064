import React, {useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {ConditionalWrapper} from '@cheddarup/react-util'

import type {RecurringItemFormFormik} from './RecurringItemForm'
import * as Util from '@cheddarup/util'
import {PremiumFeaturesSideSheet} from 'src/components/PremiumFeaturesSideSheet'
import {api} from '@cheddarup/api-client'
import {SettingDisclosureSwitch} from 'src/views/collection/build/CollectionSettingsPage/SettingDisclosureSwitch'
import {CollectionSettingsUpgradePlanButton} from 'src/views/collection/build/CollectionSettingsPage/CollectionSettingsUpgradePlanButton'
import {ItemQuantityGroupsModal} from '../../../ItemsPage/components'
import {FormSettingsSwitch} from 'src/components'

export interface RecurringItemFormSettingsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  formik: RecurringItemFormFormik
  tabId: number
  item?: Api.TabItem
}

const RecurringItemFormSettings: React.FC<RecurringItemFormSettingsProps> = ({
  formik,
  tabId,
  item,
  className,
  ...restProps
}) => {
  const inventoryGroupsQuery = api.inventoryGroups.list.useQuery(
    {
      pathParams: {
        tabId,
      },
    },
    {
      select: (inventoryGroups) =>
        inventoryGroups.filter((ig) =>
          ig.inventory_items.some((ii) => ii.tab_object_id === item?.id),
        ),
    },
  )
  const [enforcedPremiumMeta, setEnforcedPremiumMeta] = useState<
    Util.PartialDeep<Api.TabPremiumMeta>
  >({})
  const tabQuery = api.tabs.detail.useQuery({
    pathParams: {
      tabId,
    },
  })

  const isTeamCollection = tabQuery.data?.is_team
  const isDraftCollection = tabQuery.data?.status === 'draft'
  const waitlistDescription =
    'Once your item is sold out, create a list of who wants to purchase this item so you can contact them if more become available.'

  return (
    <PremiumFeaturesSideSheet
      tabId={tabId}
      enforcedPremiumMeta={enforcedPremiumMeta}
    >
      <WebUI.VStack
        className={WebUI.cn('[&_>_.Separator]:-mx-2 gap-6', className)}
        {...restProps}
      >
        <ConditionalWrapper
          condition={!!item?.hidden}
          wrapper={(children) => (
            <WebUI.Tooltip>
              <WebUI.TooltipAnchor>{children}</WebUI.TooltipAnchor>
              <WebUI.TooltipContent>
                Items cannot be both hidden and required
              </WebUI.TooltipContent>
            </WebUI.Tooltip>
          )}
        >
          <FormSettingsSwitch
            name="required"
            title="Make item required"
            disabled={!!item?.hidden}
            description={
              <div>
                <span className="font-bold">
                  Only toggle this on if this item is required for every payer.
                </span>{' '}
                <span className="font-light">
                  Payers will not be able to proceed to checkout without adding
                  this item to their cart.
                </span>
              </div>
            }
            checked={formik.values.required}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </ConditionalWrapper>
        <WebUI.Separator />
        <WebUI.VStack className="gap-2">
          <FormSettingsSwitch
            name="available_quantity_enabled"
            title="Set available quantity"
            description="“Sold out” will appear when all available recurring items have been sold. The quantity is deducted once upon initial checkout with the recurring item (not upon each recurring charge thereafter)."
            checked={formik.values.available_quantity_enabled}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.values.available_quantity_enabled && (
            <WebUI.FormField
              className="mx-3"
              label="Total Available"
              required
              error={formik.errors.available_quantity}
            >
              <WebUI.Input
                className="max-w-32"
                name="available_quantity"
                placeholder="Qty."
                type="number"
                value={formik.values.available_quantity}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </WebUI.FormField>
          )}
          <ItemQuantityGroupsModal
            disclosure={
              <div
                className={WebUI.cn(
                  'mt-[29px] flex flex-col gap-0_5 py-1',
                  !!formik.errors.available_quantity && 'mb-[26px]',
                )}
              >
                {inventoryGroupsQuery.data?.map((ig) => (
                  <WebUI.DialogDisclosure
                    key={ig.id}
                    className="font-light text-ds-sm"
                    variant="link"
                  >
                    {ig.name || 'Grouped quantity'}: {ig.available_quantity}
                  </WebUI.DialogDisclosure>
                ))}
              </div>
            }
            collectionId={tabId}
          />
        </WebUI.VStack>
        {formik.values.available_quantity_enabled && (
          <>
            <WebUI.Separator />
            <FormSettingsSwitch
              name="options.makeAvailableQuantityPublic"
              title="Make available quantity public"
              description="Payers will see how many items are left to purchase."
              checked={formik.values.options.makeAvailableQuantityPublic}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <WebUI.Separator />
            <WebUI.Disclosure
              className="gap-4"
              visible={formik.values.options.waitlist.enabled}
              onVisibleChange={(enabled) => {
                formik.setFieldValue('options.waitlist.enabled', enabled)
                setEnforcedPremiumMeta((prevEnforcedPremiumMeta) => ({
                  ...prevEnforcedPremiumMeta,
                  team: {
                    ...prevEnforcedPremiumMeta?.team,
                    waitlist: enabled,
                  },
                }))
              }}
            >
              {isDraftCollection || isTeamCollection ? (
                <SettingDisclosureSwitch
                  featureKey={
                    !isTeamCollection && formik.values.options.waitlist.enabled
                      ? 'waitlist'
                      : undefined
                  }
                  checked={formik.values.options.waitlist.enabled}
                >
                  <WebUI.VStack>
                    <WebUI.Text>Create a waitlist</WebUI.Text>
                    <span className="text-ds-sm">{waitlistDescription}</span>
                  </WebUI.VStack>
                </SettingDisclosureSwitch>
              ) : (
                <CollectionSettingsUpgradePlanButton plan="team">
                  <WebUI.VStack className="gap-1">
                    <WebUI.Text> Create a waitlist</WebUI.Text>
                    <span className="text-ds-sm">{waitlistDescription}</span>
                  </WebUI.VStack>
                </CollectionSettingsUpgradePlanButton>
              )}

              <WebUI.DisclosureContent>
                <WebUI.VStack className="gap-2">
                  <WebUI.FormField
                    className="mx-3"
                    label="Custom Message"
                    error={formik.errors.options?.waitlist?.customMessage}
                  >
                    <WebUI.RichTextEditor
                      className="min-h-[112px] max-w-[384px] text-ds-sm"
                      name="options.waitlist.customMessage"
                      placeholder="Optional: Share details with people who join your waitlist (e.g. when they can expect to hear from you, who to contact with questions, etc.)."
                      initialMarkdownValue={
                        formik.values.options.waitlist.customMessage
                      }
                      onMarkdownValueChange={(newDescription) =>
                        formik.setFieldValue(
                          'options.waitlist.customMessage',
                          newDescription,
                        )
                      }
                      onBlur={formik.handleBlur}
                    />
                  </WebUI.FormField>
                </WebUI.VStack>
              </WebUI.DisclosureContent>
            </WebUI.Disclosure>
          </>
        )}
      </WebUI.VStack>
    </PremiumFeaturesSideSheet>
  )
}

export default RecurringItemFormSettings
