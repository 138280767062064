import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {paymentShipmentSchema} from '../schemas'

export default {
  detail: makeEndpoint({
    path: 'users/tabs/:tabId/payments/:paymentId/shipment',
    responseSchema: paymentShipmentSchema,
  }),
  create: makeEndpoint({
    path: 'users/tabs/:tabId/payments/:paymentId/shipment',
    method: 'POST',
    bodySchema: z.object({
      to_address: z.unknown(),
      from_address: z.unknown(),
      parcel: z.object({weight: z.number(), predefined_package: z.string()}),
      options: z.object({
        delivery_confirmation: z.enum(['SIGNATURE']).nullable(),
      }),
      tracking: z.boolean(),
    }),
    responseSchema: paymentShipmentSchema,
  }),
  createAddress: makeEndpoint({
    path: 'users/tabs/:tabId/payments/:paymentId/shipment/address',
    method: 'POST',
    bodySchema: z
      .object({
        phone: z.string().nullable(),
        email: z.string().nullable(),
        name: z.string().nullable(),
        street1: z.string().nullable(),
        address: z.string().nullable(),
        city: z.string().nullable(),
        state: z.string().nullable(),
        country: z.string().nullable(),
        zip: z.string().nullable(),
      })
      .partial(),
    responseSchema: z.object({
      address: z.object({
        name: z.string(),
        street1: z.string(),
        city: z.string(),
        state: z.string(),
        zip: z.string(),
        country: z.string(),
        verifications: z.record(z.any()),
      }),
    }),
  }),
  createIntent: makeEndpoint({
    path: 'users/tabs/:tabId/payments/:paymentId/shipment/intent',
    method: 'POST',
    responseSchema: z.object({
      payment_intent_id: z.string(),
      client_secret: z.string(),
    }),
  }),
  selectRate: makeEndpoint({
    path: 'users/tabs/:tabId/payments/:paymentId/shipment/select_rate_for_intent',
    method: 'POST',
    bodySchema: z.object({
      payment_intent_id: z.string(),
      selected_rate_id: z.string(),
    }),
    responseSchema: z.object({
      paymentIntentId: z.string(),
    }),
  }),
  purchase: makeEndpoint({
    path: 'users/tabs/:tabId/payments/:paymentId/shipment/purchase',
    method: 'POST',
    bodySchema: z.object({
      payment_intent_id: z.string(),
      selected_rate_id: z.string(),
    }),
    responseSchema: paymentShipmentSchema,
  }),
}
