import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import MaintenanceGearsImage from 'src/images/Maintenance-Gears.gif'

export interface AppMaintenanceProps {
  children: React.ReactNode
}

const AppMaintenance = ({children}: AppMaintenanceProps) => {
  if (import.meta.env.REACT_APP_MAINTENANCE_PAGE === 'true') {
    return (
      <div className="flex h-full flex-col gap-5 p-10">
        <div className="flex justify-center">
          <img src={MaintenanceGearsImage} alt="gear" />
        </div>

        <div className="flex flex-col items-center gap-2 text-center">
          <WebUI.Heading className="font-accentAlt">
            Don't cheddar tear
          </WebUI.Heading>
          <WebUI.Text className="font-light text-ds-md">
            We're just doing some maintenance.
            <br />
            We'll be back soon.
          </WebUI.Text>
        </div>

        <div className="flex flex-col items-center">
          <div className="flex flex-col gap-5">
            <WebUI.Button
              size="large"
              variant="primary"
              onClick={() => window.zE?.('webWidget', 'open')}
            >
              Chat with us, 9-5 (MT)
            </WebUI.Button>
            <WebUI.AnchorButton
              size="large"
              variant="default"
              href="https://support.cheddarup.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Send us an email
            </WebUI.AnchorButton>
          </div>
        </div>
      </div>
    )
  }

  return <>{children}</>
}

export default AppMaintenance
