import React, {useEffect, useImperativeHandle, useRef, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {NumberParam, useQueryParam} from 'use-query-params'
import {api} from '@cheddarup/api-client'

import {ContactListFormPrompt} from './ContactListFormPrompt'
import {DeleteContactListAlert} from './DeleteContactListAlert'

export interface ContactListsNavInstance {
  createContactList: () => Promise<Api.ContactList>
}

interface ContactListsNavProps extends React.ComponentPropsWithoutRef<'div'> {
  openModal?: () => void
}

export const ContactListsNav = React.forwardRef<
  ContactListsNavInstance,
  ContactListsNavProps
>(({className, openModal, ...restProps}, forwardedRef) => {
  const [contactListId, setContactListId] = useQueryParam(
    'contactListId',
    NumberParam,
  )
  const contactListsQuery = api.contacts.contactListList.useQuery()
  const contactListFormPromptRef = useRef<WebUI.DialogInstance>(null)
  const [contactListToRename, setContactListToRename] =
    useState<Api.ContactList | null>(null)
  const [contactListToDelete, setContactListToDelete] =
    useState<Api.ContactList | null>(null)
  const createContactListPromiseResolveRef = useRef<
    ((contactList: Api.ContactList) => void) | null
  >(null)
  const createContactListPromiseRejectRef = useRef<
    ((reason?: any) => void) | null
  >(null)

  useImperativeHandle(
    forwardedRef,
    () => ({
      createContactList: () =>
        new Promise<Api.ContactList>((resolve, reject) => {
          contactListFormPromptRef.current?.show()

          createContactListPromiseResolveRef.current = resolve
          createContactListPromiseRejectRef.current = reject
        }),
    }),
    [],
  )

  useEffect(() => {
    if (contactListToRename) {
      contactListFormPromptRef.current?.show()
    }
  }, [contactListToRename])

  useEffect(() => {
    if (
      contactListId !== null &&
      contactListsQuery.data &&
      !contactListsQuery.data.some((cl) => cl.id === contactListId)
    ) {
      setContactListId(null)
    }
  }, [contactListId, contactListsQuery.data, setContactListId])

  return (
    <WebUI.VStack
      className={WebUI.cn('gap-2 overflow-y-auto p-4', className)}
      {...restProps}
    >
      <WebUI.HStack className="items-center gap-1">
        <WebUI.Text className="grow text-ds-sm uppercase">My Lists</WebUI.Text>
        <ContactListFormPrompt
          ref={contactListFormPromptRef}
          disclosure={
            <WebUI.DeprecatedTooltip label="New Contact List">
              <WebUI.DialogDisclosure
                className="text-ds-lg"
                as={WebUI.IconButton}
                size="default_alt"
              >
                <WebUI.PhosphorIcon icon="plus" />
              </WebUI.DialogDisclosure>
            </WebUI.DeprecatedTooltip>
          }
          contactList={contactListToRename}
          onDidSaveContactList={(newContactList) =>
            createContactListPromiseResolveRef.current?.(newContactList)
          }
          onDidHide={() => {
            setContactListToRename(null)
            createContactListPromiseRejectRef.current?.('Form hid')
          }}
        />
      </WebUI.HStack>
      <WebUI.VStack className="gap-4">
        <ContactListItem
          aria-selected={contactListId == null}
          onClick={() => {
            openModal?.()
            setContactListId(null)
          }}
        >
          All Contacts
        </ContactListItem>
        <WebUI.Separator className="-mx-4" />
        <WebUI.VStack>
          {contactListsQuery.data?.map((cl) => (
            <ContactListItem
              key={cl.id}
              aria-selected={contactListId === cl.id}
              onClick={() => {
                openModal?.()
                setContactListId(cl.id)
              }}
              onRename={() => setContactListToRename(cl)}
              onDelete={() => setContactListToDelete(cl)}
            >
              {cl.name}
            </ContactListItem>
          ))}
        </WebUI.VStack>
      </WebUI.VStack>

      <DeleteContactListAlert
        visible={contactListToDelete != null}
        contactListId={contactListToDelete?.id ?? -1}
        onDidHide={() => setContactListToDelete(null)}
      />
    </WebUI.VStack>
  )
})

// MARK: – ContactListItem

interface ContactListItemProps
  extends React.ComponentPropsWithoutRef<'button'> {
  onRename?: () => void
  onDelete?: () => void
}

const ContactListItem = ({
  onRename,
  onDelete,
  className,
  children,
  ...restProps
}: ContactListItemProps) => (
  <WebUI.Button
    className={WebUI.cn(
      'ContactListItem',
      'group',
      'h-[46px] justify-start whitespace-normal py-2 text-left',
      'hover:bg-teal-90 data-[targeted=true]:bg-teal-90',
      'hover:[&_.MenuButton]:visible',
      'aria-selected:bg-teal-90 aria-selected:font-bold',
      className,
    )}
    variant="ghost"
    {...restProps}
  >
    <WebUI.HStack className="min-w-0 grow items-center gap-2">
      <WebUI.Ellipsis className="grow">{children}</WebUI.Ellipsis>

      {(!!onRename || !!onDelete) && (
        <WebUI.Menu onClick={(event) => event.stopPropagation()}>
          <WebUI.MenuButton
            className="invisible group-aria-selected:visible"
            as={WebUI.IconButton}
          >
            <WebUI.PhosphorIcon icon="dots-three-outline-fill" width={25} />
          </WebUI.MenuButton>

          <WebUI.MenuList>
            {!!onRename && (
              <WebUI.MenuItem onClick={() => onRename()}>
                Rename contact list
              </WebUI.MenuItem>
            )}
            {!!onDelete && (
              <WebUI.MenuItem onClick={() => onDelete()}>
                Delete contact list
              </WebUI.MenuItem>
            )}
          </WebUI.MenuList>
        </WebUI.Menu>
      )}
    </WebUI.HStack>
  </WebUI.Button>
)
