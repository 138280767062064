import {SetRequired} from '@cheddarup/util'
import {
  ViewportList as ReactViewportList,
  ViewportListRef as ReactViewportListRef,
  ViewportListPropsWithItems as ReactViewportListWithItemsProps,
} from 'react-viewport-list'
import {newGenericForwardRef} from '@cheddarup/react-util'
import React, {useState} from 'react'

import {cn, getScrollingElement} from '../utils'

export type ViewportListInstnce = ReactViewportListRef

export interface ViewportListProps<T>
  extends SetRequired<
      Pick<
        ReactViewportListWithItemsProps<T>,
        | 'itemSize'
        | 'overscan'
        | 'withCache'
        | 'scrollThreshold'
        | 'renderSpacer'
        | 'items'
        | 'initialIndex'
        | 'initialAlignToTop'
        | 'initialOffset'
        | 'overflowAnchor'
        | 'onViewportIndexesChange'
        | 'children'
      >,
      'items'
    >,
    Omit<React.ComponentPropsWithoutRef<'div'>, 'children'> {
  listRef?: React.Ref<ViewportListInstnce>
}

export const ViewportList = newGenericForwardRef(
  <T,>({
    ref: _ref,
    listRef,
    className,
    itemSize,
    overscan = 1,
    withCache,
    scrollThreshold,
    items,
    renderSpacer,
    initialIndex,
    initialAlignToTop,
    initialOffset,
    overflowAnchor,
    onViewportIndexesChange,
    children,
    ref: forwardedRef,
    ...restProps
  }: ViewportListProps<T> & {ref?: React.Ref<HTMLDivElement>}) => {
    const [scrollParent, setScrollParent] = useState<Element | null>(null)
    return (
      <div
        ref={(divInstance) => {
          if (typeof forwardedRef === 'function') {
            forwardedRef(divInstance)
          } else if (forwardedRef) {
            ;(forwardedRef as any).current = divInstance
          }

          const newScrollParent = getScrollingElement(divInstance)
          if (newScrollParent !== scrollParent) {
            setScrollParent(newScrollParent)
          }
        }}
        className={cn('ViewportList', 'flex flex-col gap-0', className)}
        {...restProps}
      >
        <ReactViewportList<T>
          ref={listRef}
          viewportRef={{current: scrollParent as any}}
          items={items}
          overscan={overscan}
          onViewportIndexesChange={onViewportIndexesChange}
          itemSize={itemSize}
          withCache={withCache}
          scrollThreshold={scrollThreshold}
          renderSpacer={renderSpacer}
          initialIndex={initialIndex}
          initialAlignToTop={initialAlignToTop}
          initialOffset={initialOffset}
          overflowAnchor={overflowAnchor}
        >
          {children}
        </ReactViewportList>
      </div>
    )
  },
)
