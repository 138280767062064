import {deepEqual} from '@cheddarup/util'

import {useMoize} from './useMoize'

export const useMemoValue = <T>(
  value: T,
  isEqual: (prev: T, next: T) => boolean = deepEqual,
) =>
  useMoize((v) => v, [value], {
    matchesKey: ([prev], [next]) => isEqual(prev, next),
  })
