import {deepPartialify, z} from '@cheddarup/util'

import {groupPageCollectionSpotlightItemSchema} from '../schemas'
import {makeEndpoint} from '../utils'

export default {
  detail: makeEndpoint({
    path: 'users/group_page/collection_spotlight/spotlight_items/:id',
    responseSchema: groupPageCollectionSpotlightItemSchema,
  }),
  create: makeEndpoint({
    path: 'users/group_page/collection_spotlight/spotlight_items',
    method: 'POST',
    bodySchema: deepPartialify(groupPageCollectionSpotlightItemSchema),
    responseSchema: groupPageCollectionSpotlightItemSchema,
  }),
  update: makeEndpoint({
    path: 'users/group_page/collection_spotlight/spotlight_items/:id',
    method: 'PATCH',
    bodySchema: deepPartialify(groupPageCollectionSpotlightItemSchema),
    responseSchema: groupPageCollectionSpotlightItemSchema,
  }),
  delete: makeEndpoint({
    path: 'users/group_page/collection_spotlight/spotlight_items/:id',
    method: 'DELETE',
    responseSchema: z.any(),
  }),
  deleteImage: makeEndpoint({
    path: 'users/group_page/collection_spotlight/spotlight_items/:spotlightItemId/delete_image/:imageId',
    method: 'DELETE',
    responseSchema: z.any(),
  }),
}
