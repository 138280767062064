import {useParams} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'
import {Link} from 'src/components/Link'
import {ParammedTabs} from 'src/components/ParammedTabs'
import {PremiumFeaturesSideSheet} from 'src/components/PremiumFeaturesSideSheet'

import {
  CollectionPageSettings,
  CreditCartStatementSettings,
  FeesSettings,
  PaymentMethodsSettings,
  ReceiptsSettings,
  TaxesSettings,
} from './CollectionSettingsPayment'
import {
  EntryCodeSettings,
  MarketplaceSettings,
  SearchSettings,
  TimingSettings,
  VisitorReportSettings,
} from './CollectionSettingsAccessTiming'
import {
  DiscountsSettings,
  ShippingSettings,
} from './CollectionSettingsShippingDiscounts'
import {
  MainContactSettings,
  ManagersSettings,
} from './CollectionSettingsManagersAndContact'

const CollectionSettings = () => {
  const urlParams = useParams()

  const {data: isInternalMarketplaceEnabled} = api.auth.session.useQuery(
    undefined,
    {
      select: (session) =>
        !!session.organization_data?.internalMarketplace?.enabled,
    },
  )
  // TODO: ask Kareem to expose `collectionHasRecurringItems` on the collection itself ?
  // make sure collection.reportsAvailable counts can be used
  const {data: collectionHasRecurringItems} =
    api.tabItems.list.useSuspenseQuery(
      {
        pathParams: {
          // biome-ignore lint/style/noNonNullAssertion:
          tabId: urlParams.collection!,
        },
      },
      {
        select: (items) => items.some((i) => i.options.recurring?.enabled),
      },
    )
  const {data: collection} = api.tabs.detail.useSuspenseQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: urlParams.collection!,
    },
  })

  return (
    <PremiumFeaturesSideSheet tabId={Number(urlParams.collection)}>
      <WebUI.VStack className="min-h-0 shrink-0 grow basis-auto">
        <ParammedTabs
          className="relative flex-auto overflow-auto"
          defaultPaneKey="payments"
          variant="underlined"
          orientation="horizontal"
        >
          <WebUI.VStack className="!mx-auto min-h-0 w-full max-w-[1200px] gap-4 py-8">
            <WebUI.Heading className="px-4">Settings</WebUI.Heading>
            <WebUI.VStack className="gap-4">
              <div className="px-4">
                <WebUI.TabList
                  aria-label="Collection settings navigation"
                  className="[&_.TabList-underline]:bg-orange-50"
                >
                  <WebUI.Tab id="payments">Payments</WebUI.Tab>
                  <WebUI.Tab id="shipping-and-discounts">
                    Shipping and Discounts
                  </WebUI.Tab>
                  <WebUI.Tab id="access-and-timing">
                    Access and Timing
                  </WebUI.Tab>
                  {collection.access.owner && (
                    <WebUI.Tab id="managers">Managers</WebUI.Tab>
                  )}
                </WebUI.TabList>
              </div>
              <WebUI.TabPanel
                className="gap-4"
                tabId="payments"
                as={WebUI.VStack}
              >
                <PaymentMethodsSettings
                  collection={collection}
                  collectionHasRecurringItems={collectionHasRecurringItems}
                />
                <CreditCartStatementSettings collection={collection} />
                <FeesSettings collection={collection} />
                <ReceiptsSettings collection={collection} />
                <CollectionPageSettings collection={collection} />
                <TaxesSettings
                  collection={collection}
                  collectionHasRecurringItems={collectionHasRecurringItems}
                />
              </WebUI.TabPanel>
              <WebUI.TabPanel
                className="gap-4"
                tabId="shipping-and-discounts"
                as={WebUI.VStack}
              >
                <ShippingSettings
                  collection={collection}
                  collectionHasRecurringItems={collectionHasRecurringItems}
                />
                <DiscountsSettings
                  collection={collection}
                  collectionHasRecurringItems={collectionHasRecurringItems}
                />
              </WebUI.TabPanel>
              <WebUI.TabPanel
                className="gap-4"
                tabId="access-and-timing"
                as={WebUI.VStack}
              >
                <TimingSettings
                  collection={collection}
                  collectionHasRecurringItems={collectionHasRecurringItems}
                />
                <SearchSettings collection={collection} />
                {isInternalMarketplaceEnabled && (
                  <MarketplaceSettings collection={collection} />
                )}
                <EntryCodeSettings collection={collection} />
                <VisitorReportSettings collection={collection} />
              </WebUI.TabPanel>
              {collection.access.owner && (
                <WebUI.TabPanel
                  className="gap-4"
                  tabId="managers"
                  as={WebUI.VStack}
                >
                  <ManagersSettings collection={collection} />
                  <MainContactSettings collection={collection} />
                </WebUI.TabPanel>
              )}
            </WebUI.VStack>
          </WebUI.VStack>
        </ParammedTabs>
        <WebUI.PageToolbar>
          <WebUI.PageToolbarSubmitButton as={Link} to="../../share">
            Continue
          </WebUI.PageToolbarSubmitButton>
        </WebUI.PageToolbar>
      </WebUI.VStack>
    </PremiumFeaturesSideSheet>
  )
}

export default CollectionSettings
