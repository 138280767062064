import {z} from '@cheddarup/util'
import type {
  BinaryFilter,
  BinaryOperator,
  DateRange,
  Filter,
  Query,
  QueryOrder,
  TQueryOrderArray,
  TQueryOrderObject,
  TimeDimension,
  TimeDimensionGranularity,
  UnaryOperator,
  // @ts-ignore
} from '@cubejs-client/core'

// MARK: – Primitives

export const cubeDateRangeSchema: z.ZodType<DateRange> = z.union([
  z.string(),
  z.tuple([z.string(), z.string()]),
])

// MARK: – Operators

export const cubeBinaryOperatorSchema: z.ZodType<BinaryOperator> = z.enum([
  'equals',
  'notEquals',
  'contains',
  'notContains',
  'startsWith',
  'notStartsWith',
  'endsWith',
  'notEndsWith',
  'gt',
  'gte',
  'lt',
  'lte',
  'inDateRange',
  'notInDateRange',
  'beforeDate',
  'afterDate',
])

export const cubeUnaryOperatorSchema: z.ZodType<UnaryOperator> = z.enum([
  'set',
  'notSet',
])

// MARK: – Filters

const cubeBinaryFilterSchema: z.ZodType<BinaryFilter> = z.object({
  dimension: z.string().optional(),
  member: z.string().optional(),
  operator: cubeBinaryOperatorSchema,
  values: z.string().array(),
})

export const cubeUnaryFilterSchema = z.object({
  dimension: z.string().optional(),
  member: z.string().optional(),
  operator: cubeUnaryOperatorSchema,
  values: z.never(),
})

export const cubeLogicalOrFilterSchema = z.object({
  or: z.lazy(() => cubeFilterSchema.array()),
})

export const cubeLogicalAndFilterSchema = z.object({
  and: z.lazy(() => cubeFilterSchema.array()),
})

export const cubeFilterSchema: z.ZodType<Filter> = z.union([
  cubeBinaryFilterSchema,
  cubeUnaryFilterSchema,
  cubeLogicalOrFilterSchema,
  cubeLogicalAndFilterSchema,
])

// MARK: – Time dimensions

export const cubeTimeDimensionGranularitySchema: z.ZodType<TimeDimensionGranularity> =
  z.enum([
    'second',
    'minute',
    'hour',
    'day',
    'week',
    'month',
    'quarter',
    'year',
  ])

export const cubeTimeDimensionBaseSchema = z.object({
  dimension: z.string(),
  granularity: cubeTimeDimensionGranularitySchema.optional(),
})

export const cubeTimeDimensionComparisonFieldsSchema = z.object({
  compareDateRange: cubeDateRangeSchema.array(),
  dateRange: z.never(),
})

export const cubeTimeDimensionComparisonSchema =
  cubeTimeDimensionBaseSchema.extend(
    cubeTimeDimensionComparisonFieldsSchema.shape,
  )

export const cubeTimeDimensionRangedFieldsSchema = z.object({
  dateRange: cubeDateRangeSchema.optional(),
})

export const cubeTimeDimensionRangedSchema = cubeTimeDimensionBaseSchema.extend(
  cubeTimeDimensionRangedFieldsSchema.shape,
)

export const cubeTimeDimensionSchema: z.ZodType<TimeDimension> = z.union([
  cubeTimeDimensionComparisonSchema,
  cubeTimeDimensionRangedSchema,
])

// MARK: – Queries

export const cubeQueryResponseFormatSchema = z.enum(['compact', 'default'])

export const cubeQueryOrderSchema: z.ZodType<QueryOrder> = z.enum([
  'asc',
  'desc',
])

export const cubeQueryObjectSchema: z.ZodType<TQueryOrderObject> =
  z.record(cubeQueryOrderSchema)

export const cubeQueryArraySchema: z.ZodType<TQueryOrderArray> = z
  .tuple([z.string(), cubeQueryOrderSchema])
  .array()

export const cubeQuerySchema: z.ZodType<Query> = z.object({
  measures: z.string().array().optional(),
  dimensions: z.string().array().optional(),
  filters: cubeFilterSchema.array().optional(),
  timeDimensions: cubeTimeDimensionSchema.array().optional(),
  segments: z.string().array().optional(),
  limit: z.number().nullish(),
  offset: z.number().optional(),
  order: z.union([cubeQueryObjectSchema, cubeQueryArraySchema]).optional(),
  timezone: z.string().optional(),
  renewQuery: z.boolean().optional(),
  ungrouped: z.boolean().optional(),
  responseFormat: cubeQueryResponseFormatSchema.optional(),
  total: z.boolean().optional(),
})
