import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {
  marketplaceCatalogSchema,
  marketplaceCollectionOrganizerSchema,
  marketplaceCollectionSchema,
  marketplaceItemSchema,
  paginationSchema,
} from '../schemas'

export default {
  home: makeEndpoint({
    path: 'users/marketplace/home',
    responseSchema: z.object({
      catalogs: marketplaceCatalogSchema.array(),
      categories: z.string().array(),
      just_listed: z.object({
        data: marketplaceItemSchema.array(),
        pagination: paginationSchema,
      }),
      new_sales: marketplaceCollectionSchema.array(),
      organizerNickname: z.string(),
    }),
  }),
  items: makeEndpoint({
    path: 'users/marketplace/items',
    queryParamsSchema: z
      .object({
        page: z.number(),
        per_page: z.number(),
        search: z.string(),
        catalogIds: z.number().array(),
        categoryNames: z.string().array(),
        sort: z.string(),
        direction: z.enum(['asc', 'desc']),
      })
      .partial()
      .optional(),
    responseSchema: z.object({
      data: marketplaceItemSchema.array(),
      pagination: paginationSchema,
    }),
  }),
  organizers: makeEndpoint({
    path: 'users/marketplace/organizers',
    queryParamsSchema: z
      .object({
        search: z.string(),
        sort: z.string(),
        direction: z.enum(['asc', 'desc']),
        page: z.number(),
        per_page: z.number(),
      })
      .partial()
      .optional(),
    responseSchema: z.object({
      data: marketplaceCollectionOrganizerSchema
        .extend({
          collections: marketplaceCollectionSchema.array().nullish(),
        })
        .array(),
      pagination: paginationSchema,
    }),
  }),
}
