import {useNavigate, useParams} from 'react-router-dom'
import React, {useRef} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'
import {PremiumFeaturesSideSheet} from 'src/components/PremiumFeaturesSideSheet'

import {CollectionAdditionalInformationForm} from './CollectionAdditionalInformationForm'

const CollectionAdditionalInformationPage: React.FC = () => {
  const navigate = useNavigate()
  const urlParams = useParams()
  const dialogRef = useRef<WebUI.DialogInstance>(null)
  const tabQuery = api.tabs.detail.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: urlParams.collection!,
    },
  })

  return (
    <PremiumFeaturesSideSheet
      tabId={tabQuery.data?.id}
      enforcedPremiumMeta={{team: {ticketing: true}}}
    >
      <WebUI.Modal
        ref={dialogRef}
        aria-label="Collection Additional Information"
        className="[&_>_.ModalContentView]:h-full [&_>_.ModalContentView]:max-w-screen-lg"
        initialVisible
        onDidHide={() => navigate('..')}
      >
        <WebUI.ModalHeader className="border-b-0 pl-6 sm:pl-9">
          <WebUI.PageHeader subheading="Select the information blocks you’d like to add to your collection page.">
            Additional Information
          </WebUI.PageHeader>
        </WebUI.ModalHeader>
        <WebUI.ModalCloseButton />
        <CollectionAdditionalInformationForm
          className="grow"
          collectionId={Number(urlParams.collection)}
          onDidSave={() => dialogRef.current?.hide()}
        />
      </WebUI.Modal>
    </PremiumFeaturesSideSheet>
  )
}

export default CollectionAdditionalInformationPage
