import {makeQueryUpdate, makeUseMutation} from '../use-mutation'
import {endpoints} from '../../endpoints'

export const useCubeTokenMutation = makeUseMutation(endpoints.cube.createToken)

export const useCreateCubeReportMutation = makeUseMutation(
  endpoints.cube.createReport,
  () => ({
    regular: (newReport) => [
      makeQueryUpdate(endpoints.cube.reportList, (prevReports) =>
        prevReports ? [newReport, ...prevReports] : undefined,
      ),
    ],
  }),
)

export const useUpdateCubeReportMutation = makeUseMutation(
  endpoints.cube.updateReport,
  (vars) => ({
    regular: (newReport) => [
      makeQueryUpdate(endpoints.cube.reportList, (prevReports) =>
        prevReports?.map((r) => (r.id === newReport.id ? newReport : r)),
      ),
      makeQueryUpdate(endpoints.cube.reportDetail, () => newReport, vars),
    ],
  }),
)

export const useDeleteCubeReportMutation = makeUseMutation(
  endpoints.cube.deleteReport,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(endpoints.cube.reportList, (prevReports) =>
        prevReports?.filter((r) => r.id !== Number(vars.pathParams.reportId)),
      ),
    ],
  }),
)

export const useCreateCubeExportMutation = makeUseMutation(
  endpoints.cube.createExport,
  () => ({
    regular: (newExport) => [
      makeQueryUpdate(endpoints.cube.exportList, (prevExports) =>
        prevExports ? [newExport, ...prevExports] : prevExports,
      ),
    ],
  }),
)
