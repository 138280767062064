// Based on https://magicui.design/docs/components/number-ticker
import React, {useEffect, useMemo, useRef} from 'react'
import {useInView, useMotionValue, useSpring} from 'framer-motion'
import {cn} from '../utils'
import {useForkRef} from '@cheddarup/react-util'

interface NumberTickerProps extends React.ComponentPropsWithoutRef<'span'> {
  value: number
  direction?: 'up' | 'down'
  delay?: number
  decimalPlaces?: number
  prefix?: string
  variant?: 'slow' | 'default' | 'fast'
}

export const NumberTicker = React.forwardRef<
  HTMLSpanElement,
  NumberTickerProps
>(
  (
    {
      className,
      value,
      direction = 'up',
      delay = 0,
      decimalPlaces = 2,
      prefix = '',
      variant = 'default',
      ...restProps
    },
    forwardedRef,
  ) => {
    const ownRef = useRef<HTMLSpanElement>(null)
    const ref = useForkRef(ownRef, forwardedRef)
    const motionValue = useMotionValue(direction === 'down' ? value : 0)
    const springConfig = useMemo(() => {
      switch (variant) {
        case 'slow':
          return {damping: 40, stiffness: 30, duration: 2}
        case 'fast':
          return {damping: 80, stiffness: 300, duration: 0.5}
        default:
          return {damping: 60, stiffness: 100, duration: 1}
      }
    }, [variant])
    const springValue = useSpring(motionValue, springConfig)
    const isInView = useInView(ownRef, {once: true, margin: '0px'})

    const formatter = useMemo(
      () =>
        new Intl.NumberFormat('en-US', {
          minimumFractionDigits: decimalPlaces,
          maximumFractionDigits: decimalPlaces,
        }),
      [decimalPlaces],
    )

    useEffect(() => {
      if (isInView) {
        setTimeout(() => {
          motionValue.set(direction === 'down' ? 0 : value)
        }, delay)
      }
    }, [motionValue, isInView, delay, value, direction])

    useEffect(() => {
      const unsubscribe = springValue.on('change', (latest) => {
        if (ownRef.current) {
          ownRef.current.textContent = `${prefix}${formatter.format(latest)}`
        }
      })

      return () => unsubscribe()
    }, [springValue, formatter, prefix])

    return (
      <span
        ref={ref}
        className={cn('inline-block tabular-nums tracking-wider', className)}
        {...restProps}
      >
        {`${prefix}${formatter.format(direction === 'down' ? value : 0)}`}
      </span>
    )
  },
)
