import {
  useCreateFieldValueMutation,
  useModifyFieldViewsMutation,
} from '@cheddarup/api-client'
import * as Util from '@cheddarup/util'

export function getTicketHiddenFields<
  T extends Pick<
    Api.TabObjectFieldView | Api.TabObjectField,
    'field_type' | 'metadata'
  >,
>(fieldViews: T[]) {
  return Util.sort(
    fieldViews.filter(
      (field) =>
        field.field_type === 'email' ||
        field.metadata.fieldTypeMetadata?.fieldIdentifier === 'first_name' ||
        field.metadata.fieldTypeMetadata?.fieldIdentifier === 'last_name',
    ),
  ).asc((f) => ['first_name', 'last_name', 'email'].indexOf(f.field_type))
}

export function excludeTicketHiddenFields<
  T extends Pick<
    Api.TabObjectFieldView | Api.TabObjectField,
    'field_type' | 'metadata'
  >,
>(fieldViews: T[]) {
  return fieldViews.filter(
    (field) =>
      field.field_type !== 'email' &&
      field.metadata.fieldTypeMetadata?.fieldIdentifier !== 'first_name' &&
      field.metadata.fieldTypeMetadata?.fieldIdentifier !== 'last_name',
  )
}

export function getAttendeeName(
  fieldViews: Array<Pick<Api.TabObjectFieldView, 'value' | 'metadata'>>,
) {
  const firstName = fieldViews.find(
    (field) =>
      field.metadata.fieldTypeMetadata?.fieldIdentifier === 'first_name',
  )
  const lastName = fieldViews.find(
    (field) =>
      field.metadata.fieldTypeMetadata?.fieldIdentifier === 'last_name',
  )

  return `${firstName?.value ?? ''} ${lastName?.value ?? ''}`
}

export function getAttendeeEmail(
  fieldViews?: Array<Pick<Api.TabObjectFieldView, 'field_type' | 'value'>>,
) {
  const email = fieldViews?.find((field) => field.field_type === 'email')
  return email?.value ?? ''
}

export function resolveSignatureFieldValue(
  fieldView: Pick<Api.TabObjectFieldView, 'field_type' | 'metadata' | 'value'>,
  eSignatures: Api.ESignature[],
) {
  if (fieldView.field_type === 'signature') {
    const eSignature = eSignatures.find(
      (es) => es.signer_number === fieldView.value,
    )
    const fieldIdentifier =
      fieldView.metadata.fieldTypeMetadata?.fieldIdentifier

    if (eSignature) {
      if (fieldIdentifier === 'legal_initials') {
        return eSignature.initials_url
      }
      if (fieldIdentifier === 'legal_signature') {
        return eSignature.signature_url
      }
    }
  }

  return fieldView.value
}

// MARK: – WIP

export function useEditFieldViews() {
  const modifyFieldViewsMutation = useModifyFieldViewsMutation()
  const createFieldValueMutation = useCreateFieldValueMutation()

  async function editFieldViews({
    tabId,
    values,
    paymentObject,
    notifyPayer,
  }: {
    tabId: number
    values: Record<string, string>
    paymentObject: Pick<
      Api.PaymentItem,
      | 'id'
      | 'payment_id'
      | 'tab_object_id'
      | 'tab_item'
      | 'tab_form'
      | 'time_slot'
      | 'item_field_views'
    >
    notifyPayer?: boolean
  }) {
    const tabObjectId = (
      paymentObject.tab_item ??
      paymentObject.tab_form ??
      paymentObject.time_slot
    ).id
    const fields =
      paymentObject.tab_item?.fields ??
      paymentObject.tab_form?.fields ??
      paymentObject.time_slot?.spot.signup.fields ??
      []

    const fieldValuesToEdit = Util.pickBy(values, (value, fieldIdAsStr) => {
      const fieldId = Number(fieldIdAsStr)

      const correspondingFieldView = paymentObject.item_field_views.find(
        (ifv) => ifv.item_field_id === fieldId,
      )
      const field = fields.find((f) => f.id === fieldId)
      const fieldType = correspondingFieldView?.field_type ?? field?.field_type

      if (
        !value ||
        fieldType === 'file' ||
        fieldType === 'signature' ||
        fieldType === 'image' ||
        value === correspondingFieldView?.value
      ) {
        return false
      }

      return true
    })

    const updateFieldsPayload = Util.objectFromObject(
      fieldValuesToEdit,
      (_fieldId, value) => ({value}),
      (fieldIdAsStr) =>
        paymentObject.item_field_views
          .find((ifv) => ifv.item_field_id === Number(fieldIdAsStr))
          ?.id.toString(),
    )

    const createFieldsPayload = Util.arrayFromObject(
      fieldValuesToEdit,
      (fieldIdAsStr, value) =>
        paymentObject.item_field_views.some(
          (ifv) => ifv.item_field_id === Number(fieldIdAsStr),
        )
          ? null
          : ([Number(fieldIdAsStr), value] as const),
    ).filter((p) => p != null)

    const createFieldViewsPromises = createFieldsPayload.map(
      ([fieldId, value]) =>
        createFieldValueMutation.mutateAsync({
          pathParams: {
            tabId,
            paymentId: paymentObject.payment_id,
          },
          body: {
            value,
            payment_id: paymentObject.payment_id,
            payment_item_id: paymentObject.id,
            tab_object_id: tabObjectId,
            item_field_id: fieldId,
          },
        }),
    )

    await Promise.all([
      modifyFieldViewsMutation.mutateAsync({
        pathParams: {
          tabId,
          paymentId: paymentObject.payment_id,
        },
        body: {
          tabObjectId,
          paymentItemId: paymentObject.id,
          fields: updateFieldsPayload,
          notify: notifyPayer,
        },
      }),
      createFieldViewsPromises,
    ])
  }

  return [editFieldViews] as const
}
