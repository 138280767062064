import {merge, mergeDeep} from '@cheddarup/util'

import {makeQueryUpdate, makeUseMutation} from '../use-mutation'
import {Endpoints, endpoints} from '../../endpoints'
import {FetchInput, getEndpointKey} from '../../utils'

export const useUpdateUserMutation = makeUseMutation(
  endpoints.users.update,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(endpoints.auth.session, (prevSession) =>
        prevSession
          ? {
              ...prevSession,
              user: {
                ...mergeDeep(
                  prevSession.user,
                  (vars.body as Record<string, any>) ?? {},
                ),
                ssn_last_4_provided: !!vars.body?.person_token,
              },
            }
          : prevSession,
      ),
    ],
    regular: (newSession) => [
      makeQueryUpdate(endpoints.auth.session, () => newSession),
    ],
  }),
  (queryClient) => ({
    onSuccess: (newSession) => {
      const personaInquiryQueryKey = getEndpointKey(
        endpoints.users.personaInquiry,
      )
      const homeBySlugQueryKey = getEndpointKey(endpoints.publicTabs.home, {
        pathParams: {tabId: newSession.user.slug},
      })
      const homeByIdQueryKey = getEndpointKey(endpoints.publicTabs.home, {
        pathParams: {tabId: newSession.user.id},
      })

      queryClient.invalidateQueries({queryKey: personaInquiryQueryKey})
      queryClient.invalidateQueries({queryKey: homeBySlugQueryKey})
      queryClient.invalidateQueries({queryKey: homeByIdQueryKey})
    },
  }),
)

export const useDeleteUserMutation = makeUseMutation(endpoints.users.delete)

export const useUpdatePasswordMutation = makeUseMutation(
  endpoints.users.updatePassword,
)

export const useSendMobileAppLinkMutation = makeUseMutation(
  endpoints.users.sendMobileAppLink,
)

export const useResetUserAccountMutation = makeUseMutation(
  endpoints.users.resetAccount,
)

export const useVerifyEmailVerificationCodeMutation = makeUseMutation(
  endpoints.users.verifyEmail,
)

export const useStartVerificationMutation = makeUseMutation(
  endpoints.users.startVerification,
)

export const useStartCodeVerificationMutation = makeUseMutation(
  endpoints.users.startCodeVerification,
)

export const useVerifyCodeMutation = makeUseMutation(
  endpoints.users.verifyCode,
  undefined,
  (queryClient) => ({
    onSuccess: () => {
      const sessionQueryKey = getEndpointKey(endpoints.auth.session)
      queryClient.invalidateQueries({queryKey: sessionQueryKey})
    },
  }),
)

export const useResetVerificationMutation = makeUseMutation(
  endpoints.users.resetVerification,
  undefined,
  (queryClient) => ({
    onSuccess: () => {
      const sessionQueryKey = getEndpointKey(endpoints.auth.session)
      queryClient.invalidateQueries({queryKey: sessionQueryKey})
    },
  }),
)

export const useSendEmailVerificationCodeMutation = makeUseMutation(
  endpoints.users.startEmailVerification,
)

export const useUpdateUserClientFlagsMutation = makeUseMutation<
  Endpoints['users']['updateClientFlags'],
  FetchInput<Endpoints['users']['updateClientFlags']> & {
    userId?: number
  },
  any
>(
  endpoints.users.updateClientFlags,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(endpoints.auth.session, (prevSession) =>
        prevSession
          ? {
              ...prevSession,
              manager_roles: prevSession.manager_roles.map((mr) =>
                mr.id === vars.userId ? merge(mr, vars.body) : mr,
              ),
            }
          : prevSession,
      ),
    ],
  }),
  (queryClient) => ({
    onSuccess: (newSession) => {
      const homeBySlugQueryKey = getEndpointKey(endpoints.publicTabs.home, {
        pathParams: {tabId: newSession.user.slug},
      })
      const homeByIdQueryKey = getEndpointKey(endpoints.publicTabs.home, {
        pathParams: {tabId: newSession.user.id},
      })

      queryClient.invalidateQueries({queryKey: homeBySlugQueryKey})
      queryClient.invalidateQueries({queryKey: homeByIdQueryKey})
    },
  }),
)
