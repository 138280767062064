import React from 'react'
import * as WebUI from '@cheddarup/web-ui'

export interface AddressCheckModalProps extends WebUI.ModalProps {
  originAddress: Api.PaymentShippingInfo['shipTo']
  easypostAddress: Api.PaymentShippingInfo['shipTo']
  onUseEasypostAddress: (address: Api.PaymentShippingInfo['shipTo']) => void
}

const AddressCheckModal = ({
  originAddress,
  easypostAddress,
  onUseEasypostAddress,
  className,
  ...restProps
}: AddressCheckModalProps) => (
  <WebUI.Modal
    aria-label="Address confirmation"
    className={WebUI.cn(
      '[&_>_.ModalContentView]:max-w-screen-md [&_>_.ModalContentView]:p-8',
      className,
    )}
    {...restProps}
  >
    {(dialog) => (
      <>
        <WebUI.ModalCloseButton />

        <WebUI.VStack className="gap-8">
          <div className="max-w-[512px] font-normal text-ds-sm">
            We weren&apos;t able to verify your address as entered, but found a
            close match. Please confirm which is the most accurate or correct
            the address and try again.
          </div>
          <WebUI.VStack className="*:md:flex-[1_0_0px]] shrink-0 basis-auto gap-8 *:grow md:flex-row">
            <AddressOverview
              buttonPrimary
              title="Recommended"
              buttonTitle="Use Recommended Address"
              address={easypostAddress}
              onClickButton={() => onUseEasypostAddress(easypostAddress)}
            />
            <WebUI.Separator orientation="vertical" variant="primary" />
            <AddressOverview
              title="You Entered"
              buttonTitle="Edit Address I Entered"
              address={originAddress}
              onClickButton={() => dialog.hide()}
            />
          </WebUI.VStack>
        </WebUI.VStack>
      </>
    )}
  </WebUI.Modal>
)

// MARK: – AddressOverview

interface AddressOverviewProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'title'> {
  buttonPrimary?: boolean
  title: React.ReactNode
  buttonTitle: React.ReactNode
  address: Api.PaymentShippingInfo['shipTo']
  onClickButton: () => void
}

const AddressOverview = ({
  buttonPrimary,
  title,
  buttonTitle,
  address,
  onClickButton,
  className,
  ...restProps
}: AddressOverviewProps) => (
  <WebUI.VStack
    className={WebUI.cn('gap-4 font-normal text-ds-xs', className)}
    {...restProps}
  >
    <div className="text-ds-md">{title}</div>
    <div>
      {address.street1}
      <br />
      {address.city}, {address.state} {address.zip}
      <br />
      {address.country === 'US' ? 'UNITED STATES' : 'CANADA'}
    </div>
    <WebUI.Button
      className="w-full"
      variant={buttonPrimary ? 'primary' : 'secondary'}
      onClick={onClickButton}
    >
      {buttonTitle}
    </WebUI.Button>
  </WebUI.VStack>
)

export default AddressCheckModal
