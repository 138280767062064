import {isMac} from '@ariakit/core/utils/platform'

export {isMac}

// Based on https://github.com/adobe/react-spectrum/blob/main/packages/%40react-aria/utils/src/platform.ts

function testUserAgent(re: RegExp) {
  if (typeof window === 'undefined' || window.navigator == null) {
    return false
  }
  return (
    ('userAgentData' in window.navigator &&
      typeof window.navigator.userAgentData === 'object' &&
      window.navigator.userAgentData &&
      'brands' in window.navigator.userAgentData &&
      Array.isArray(window.navigator.userAgentData.brands) &&
      window.navigator.userAgentData?.brands.some(
        (brand: {brand: string; version: string}) => re.test(brand.brand),
      )) ||
    re.test(window.navigator.userAgent)
  )
}

function testPlatform(re: RegExp) {
  return typeof window !== 'undefined' && window.navigator != null
    ? re.test(
        ('userAgentData' in window.navigator &&
          typeof window.navigator.userAgentData === 'object' &&
          window.navigator.userAgentData &&
          'platform' in window.navigator.userAgentData &&
          typeof window.navigator.userAgentData.platform === 'string' &&
          window.navigator.userAgentData.platform) ||
          window.navigator.platform,
      )
    : false
}

export function isIPhone() {
  return testPlatform(/^iphone/i)
}

export function isIPad() {
  return (
    testPlatform(/^ipad/i) ||
    // iPadOS 13 lies and says it's a Mac, but we can distinguish by detecting touch support.
    (isMac() && navigator.maxTouchPoints > 1)
  )
}

export function isIOS() {
  return isIPhone() || isIPad()
}

export function isAppleDevice() {
  return isMac() || isIOS()
}

export function isWebKit() {
  return testUserAgent(/applewebkit/i) && !isChrome()
}

export function isChrome() {
  return testUserAgent(/chrome/i)
}

export function isAndroid() {
  return testUserAgent(/android/i)
}
