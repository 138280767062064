import {PlanUpgradeButton} from 'src/components/PremiumFeaturesSideSheet'
import {AccountSettingsContentLayout} from './components/AccountSettingsContentLayouts'
import * as WebUI from '@cheddarup/web-ui'
import {api, useUpdateBrandingMutation} from '@cheddarup/api-client'
import {useNavigate} from 'react-router-dom'
import React, {useCallback} from 'react'
import {defaultBrandKitColorMap} from '@cheddarup/core'
import BrandKitSettingsDisclosure from './components/BrandKitSettingsDisclosure'
import {BrandingColorPickerPopoverForm} from './BrandKitPalettePage'
import {NextLink} from 'src/components/__next/Link'
import {useManagerRoleId} from 'src/components/ManageRoleProvider'

const BrandKitEmailPage = () => {
  const navigate = useNavigate()
  const [managerRoleId] = useManagerRoleId()
  const brandingQuery = api.userBrandings.detail.useQuery()
  const {data: notSubscribedToTeam} = api.auth.session.useQuery(undefined, {
    select: (session) => !session.capabilities.subscribed_to_team,
  })

  const updateBrandingMutation = useUpdateBrandingMutation()

  const validateTeamPlan = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (notSubscribedToTeam && !managerRoleId) {
        event.preventDefault()
        navigate('i/plans?recommendedPlan=team')
      }
    },
    [notSubscribedToTeam, managerRoleId, navigate],
  )

  return (
    <AccountSettingsContentLayout
      className="[&_>_.Panel]:px-6 [&_>_.Panel]:py-5"
      heading={
        <div className="flex flex-row items-center gap-4">
          <WebUI.Heading as="h2">Emails</WebUI.Heading>
          {notSubscribedToTeam && !managerRoleId && (
            <PlanUpgradeButton upgradeTo="team" asPaidBadge />
          )}
        </div>
      }
      body="Customize email receipts and messages with your branding."
    >
      <WebUI.Panel>
        <BrandKitSettingsDisclosure
          label="Apply branding to emails"
          visible={brandingQuery.data?.color_palette.email?.enabled}
          onVisibleChange={(visible) =>
            updateBrandingMutation.mutate({
              body: {color_palette: {email: {enabled: visible}}},
            })
          }
          onClick={validateTeamPlan}
        >
          <WebUI.Separator className="-mx-6" />
          <div className="flex flex-col gap-2">
            <WebUI.Text className="text-ds-sm">Logo</WebUI.Text>
            <WebUI.Text className="font-light text-ds-sm">
              If you’ve uploaded a logo to your{' '}
              <NextLink to="../brand-kit-logo">brand kit</NextLink>, this logo
              will replace the Cheddar Up logo at the top of emails that are
              sent to your payers and participants.
            </WebUI.Text>
          </div>
          <WebUI.Separator className="-mx-6" />
          <div className="flex flex-col gap-2">
            <WebUI.Text className="text-ds-sm">Button Color</WebUI.Text>
            <WebUI.Text className="font-light text-ds-sm">
              Replace the orange default button color on emails to match your
              branding.
            </WebUI.Text>
          </div>
          <BrandingColorPickerPopoverForm
            className="h-9 w-32 self-start"
            brandingColorKey="primaryButton"
            heading="Primary Button Color"
            description="Choose a bold color with high contrast to capture the most attention."
            formTarget="email"
            defaultValue={
              brandingQuery.data?.color_palette.email?.primaryButton ??
              defaultBrandKitColorMap.secondary
            }
          />
        </BrandKitSettingsDisclosure>
      </WebUI.Panel>
    </AccountSettingsContentLayout>
  )
}

export default BrandKitEmailPage
