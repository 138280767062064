import {withProps} from '@cheddarup/react-util'
import * as WebUI from '@cheddarup/web-ui'
import usePublicCollection from '../hooks/usePublicCollection'
import {getPayerBrandKitColors} from '@cheddarup/core'
import React, {useEffect} from 'react'
import {getCUReadableColor} from 'src/helpers/color-utils'
import {darken, Merge} from '@cheddarup/util'
import {cva} from 'class-variance-authority'
import {LinkButton} from 'src/components/LinkButton'

export function useInjectThemeCssVars() {
  const {publicCollection} = usePublicCollection()

  useEffect(() => {
    function getHoveredBgColor(color: string) {
      return darken(color, 0.05)
    }
    function getFocusOutlineColor(color: string) {
      return darken(color, -0.5)
    }

    const brandKitColors = getPayerBrandKitColors(
      publicCollection?.organizer.branding?.color_palette.payerPage,
    )

    const variants = ['primary', 'secondary'] as const

    variants.forEach((variant) => {
      const baseColor = brandKitColors[`${variant}Button`]

      document.body.style.setProperty(
        `--payer-${variant}-content-color`,
        getCUReadableColor(baseColor),
      )
      document.body.style.setProperty(
        `--payer-${variant}-bg-hover-color`,
        getHoveredBgColor(baseColor),
      )
      document.body.style.setProperty(`--payer-${variant}-bg-color`, baseColor)
      document.body.style.setProperty(
        `--payer-${variant}-focus-outline-color`,
        getFocusOutlineColor(baseColor),
      )
    })
  }, [publicCollection?.organizer.branding?.color_palette.payerPage])
}

// MARK: – PayerButton

const payerButton = cva(
  'focus:focus-visible:shadow-none focus:focus-visible:ring-2',
  {
    variants: {
      variant: {
        'themed-primary': [
          'bg-[var(--payer-primary-bg-color)] text-[var(--payer-primary-content-color)]',
          'active:bg-[var(--payer-primary-bg-color)]',
          'aria-haspopup:aria-expanded:bg-[var(--payer-primary-bg-color)]',
          'data-[active]:bg-[var(--payer-primary-bg-color)]`,',
          'hover:bg-[var(--payer-primary-bg-hover-color)]',
          'focus:focus-visible:ring-[var(--payer-primary-focus-outline-color)]',
        ],
        'themed-secondary': [
          'bg-[var(--payer-secondary-bg-color)] text-[var(--payer-secondary-content-color)]',
          'active:bg-[var(--payer-secondary-bg-color)]',
          'aria-haspopup:aria-expanded:bg-[var(--payer-secondary-bg-color)]',
          'data-[active]:bg-[var(--payer-secondary-bg-color)]`,',
          'hover:bg-[var(--payer-secondary-bg-hover-color)]',
          'focus:focus-visible:ring-[var(--payer-secondary-focus-outline-color)]',
        ],
        'themed-outlined': [
          'bg-trueWhite ring-none',
          'border border-[var(--payer-primary-bg-color)] border-solid text-[var(--payer-primary-bg-color)]',
          'focus:focus-visible:ring-[var(--payer-primary-focus-outline-color)]',
          'hover:border-[var(--payer-primary-bg-hover-color)] hover:text-[var(--payer-primary-bg-hover-color)]',
        ],
      },
    },
    defaultVariants: {
      variant: 'themed-primary',
    },
  },
)

const themedVariants = ['themed-primary', 'themed-secondary', 'themed-outlined']

export const PayerButton = withProps<
  Merge<
    React.ComponentPropsWithoutRef<typeof WebUI.Button>,
    {
      variant?:
        | 'themed-primary'
        | 'themed-secondary'
        | 'themed-outlined'
        | WebUI.ButtonProps['variant']
    }
  >
>(
  WebUI.Button as any,
  ({variant = 'themed-primary', className, ...restProps}) => {
    const isVariantThemed = themedVariants.includes(variant)

    return {
      className: WebUI.cn(
        isVariantThemed && payerButton({variant: variant as any}),
        className,
      ),
      variant: isVariantThemed ? undefined : variant,
      roundness: 'capsule',
      ...restProps,
    }
  },
)

export type PayerButtonProps = React.ComponentPropsWithoutRef<
  typeof PayerButton
>

// MARK: – PayerIconButton

export const PayerIconButton = withProps<
  Merge<
    React.ComponentPropsWithoutRef<typeof WebUI.IconButton>,
    {
      variant?:
        | 'themed-primary'
        | 'themed-secondary'
        | 'themed-outlined'
        | WebUI.ButtonProps['variant']
    }
  >
>(
  WebUI.IconButton as any,
  ({variant = 'themed-primary', className, ...restProps}) => {
    const isVariantThemed = themedVariants.includes(variant)

    return {
      className: WebUI.cn(
        isVariantThemed && payerButton({variant: variant as any}),
        className,
      ),
      variant: isVariantThemed ? undefined : variant,
      roundness: 'capsule',
      ...restProps,
    }
  },
)

export type PayerIconButtonProps = React.ComponentPropsWithoutRef<
  typeof PayerIconButton
>

// MARK: – PayerLinkButton

export const PayerLinkButton = withProps<
  Merge<
    React.ComponentPropsWithoutRef<typeof LinkButton>,
    {
      variant?:
        | 'themed-primary'
        | 'themed-secondary'
        | 'themed-outlined'
        | WebUI.ButtonProps['variant']
    }
  >
>(
  LinkButton as any,
  ({variant = 'themed-primary', className, ...restProps}) => {
    const isVariantThemed = themedVariants.includes(variant)

    return {
      className: WebUI.cn(
        isVariantThemed && payerButton({variant: variant as any}),
        className,
      ),
      variant: isVariantThemed ? undefined : variant,
      roundness: 'capsule',
      ...restProps,
    }
  },
)

export type PayerLinkButtonProps = React.ComponentPropsWithoutRef<
  typeof PayerLinkButton
>

// MARK: – PayerRadio

const payerRadioIcon = cva('', {
  variants: {
    variant: {
      'themed-secondary':
        'peer-aria-checked/input:bg-[var(--payer-secondary-bg-color)] peer-aria-checked/input:text-[var(--payer-secondary-content-color)]',
    },
  },
  defaultVariants: {
    variant: 'themed-secondary',
  },
})

export const PayerRadio = withProps<
  Merge<
    React.ComponentPropsWithoutRef<typeof WebUI.Radio>,
    {
      variant?: 'themed-secondary' | WebUI.RadioProps['variant']
    }
  >
>(
  WebUI.Radio as any,
  ({variant = 'themed-secondary', radioIconClassName, ...restProps}) => {
    const isVariantThemed = themedVariants.includes(variant)

    return {
      radioIconClassName: WebUI.cn(
        isVariantThemed && payerRadioIcon({variant: variant as any}),
        radioIconClassName,
      ),
      variant: isVariantThemed ? undefined : variant,
      ...restProps,
    }
  },
)

export type PayerRadioProps = React.ComponentPropsWithoutRef<typeof PayerRadio>
