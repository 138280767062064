// @ts-ignore

import {z} from '@cheddarup/util'

import {s3ImageSchema} from './common'

export const headerSchema = z.object({
  id: z.number(),
  created_at: z.string(),
  image: s3ImageSchema.optional(),
  editable: z.boolean(),
  partner: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .nullish(),
})

declare global {
  namespace Api {
    // @ts-ignore
    type Header = z.infer<typeof headerSchema>
  }
}
