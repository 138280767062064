import * as WebUI from '@cheddarup/web-ui'
import {
  Query as CubeQuery,
  Filter,
  TimeDimensionRanged,
} from '@cubejs-client/core'
import * as Util from '@cheddarup/util'
import React, {useMemo, useRef, useState} from 'react'
import {
  api,
  useCreateOrgMemberTestInvitesMutation,
  useDeleteOrgMemberInviteMutation,
  useRecreateOrgMemberInviteMutation,
} from '@cheddarup/api-client'
import {CubeFilterField} from 'src/components/CubeFilters'
import {LinkButton} from 'src/components/LinkButton'
import {
  CubeTable,
  CubeTableColumn,
  CubeTableInstance,
} from 'src/components/CubeTable'
import {SearchForm} from 'src/components'

import {CubeFiltersPopover} from '../../ReportsPage/ReportsPage'

export const InvitedCollectorsTable = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => {
  const cubeTableRef = useRef<CubeTableInstance>(null)
  const [filters, setFilters] = useState<Filter[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [timeDimensions, setTimeDimensions] = useState<TimeDimensionRanged[]>(
    [],
  )
  const [inviteIdsToResend, setInviteIdsToResend] = useState<string[]>([])
  const [selectionState, setSelectionState] = useState<Record<string, boolean>>(
    {},
  )

  const query: CubeQuery = useMemo(
    () => ({
      dimensions: [
        'OrgMemberInvites.id',
        'OrgMemberInvites.firstName',
        'OrgMemberInvites.lastName',
        'OrgMemberInvites.createdUserId',
        'SentEmails.status',
        'OrgMemberInvites.createdAt',
        'OrgMemberInvites.email',
      ],
      filters: [
        ...filters,
        searchValue.length > 0
          ? {
              or: [
                {
                  member: 'OrgMemberInvites.firstName',
                  operator: 'contains',
                  values: [searchValue],
                },
                {
                  member: 'OrgMemberInvites.lastName',
                  operator: 'contains',
                  values: [searchValue],
                },
                {
                  member: 'OrgMemberInvites.email',
                  operator: 'contains',
                  values: [searchValue],
                },
              ],
            }
          : undefined,
      ].filter((f): f is Filter => !!f),
      timeDimensions,
    }),
    [filters, searchValue, timeDimensions],
  )

  const initialSortingState = useMemo(
    () => [{id: 'OrgMemberInvites.createdAt', desc: true}],
    [],
  )

  const columns: CubeTableColumn[] = useMemo(
    () => [
      {
        id: 'name',
        headerTitle: 'Organizer',
        cell: ({row: {original: invite}}) => (
          <div>
            <div className="font-normal text-ds-sm">
              {`${invite['OrgMemberInvites.firstName']} ${invite['OrgMemberInvites.email']}`}
            </div>
            <div className="font-normal text-ds-xs text-gray400">
              {invite['OrgMemberInvites.email']}
            </div>
          </div>
        ),
      },
      {path: 'OrgMemberInvites.createdAt', maxSize: 160},
      {
        path: 'OrgMemberInvites.accepted',
        maxSize: 160,
        cell: ({row: {original: invite}}) => (
          <WebUI.HStack className="gap-3">
            {invite['OrgMemberInvites.createdUserId'] ? (
              <WebUI.Tag className="text-ds-xs capitalize">
                <LinkButton
                  className="text-ds-xs"
                  to={`/reporting/collectors/${invite['OrgMemberInvites.createdUserId']}`}
                >
                  Active
                </LinkButton>
              </WebUI.Tag>
            ) : (
              <WebUI.Tag className="text-ds-xs capitalize">Invited</WebUI.Tag>
            )}
          </WebUI.HStack>
        ),
      },
      {
        path: 'SentEmails.status',
        maxSize: 160,
        cell: ({cell, row: {original: invite}}) => (
          <WebUI.HStack className="gap-3">
            <WebUI.Tag className="text-ds-xs capitalize">
              {String(cell.getValue())}
            </WebUI.Tag>
            <WebUI.Button
              className="text-ds-xs"
              variant="link"
              onClick={() => {
                if (typeof invite['OrgMemberInvites.id'] === 'string') {
                  setInviteIdsToResend([invite['OrgMemberInvites.id']])
                }
              }}
            >
              Resend
            </WebUI.Button>
          </WebUI.HStack>
        ),
      },
      {
        id: 'delete',
        headerTitle: '',
        maxSize: 120,
        align: 'right',
        cell: ({row: {original: user}}) => (
          <DeleteInvitationButton
            invitationId={user['OrgMemberInvites.id'] as number}
            onDelete={() => {
              cubeTableRef.current?.refetch()
            }}
          />
        ),
      },
    ],
    [],
  )

  return (
    <WebUI.VStack className={WebUI.cn('gap-4', className)} {...restProps}>
      <WebUI.HStack
        className="items-center justify-between gap-3 p-4"
        as={WebUI.Panel}
      >
        <span className="font-normal text-ds-md">Invited Collectors</span>
        <WebUI.HStack className="gap-3">
          <SearchForm
            className="h-9 w-[360px]"
            size="compact"
            placeholder="Enter name or Email"
            onSubmit={(values) => setSearchValue(values.term)}
          />
          <WebUI.Button
            variant="secondary"
            onClick={() =>
              setInviteIdsToResend(
                Object.keys(Util.pickBy(selectionState, (r) => r === true)),
              )
            }
          >
            Resend Invitations
          </WebUI.Button>
          <CubeFiltersPopover
            aria-label="all members filters"
            filters={filters}
            timeDimensions={timeDimensions}
            onFiltersApply={(newFilters) => {
              setFilters(newFilters.filters)
              setTimeDimensions(newFilters.timeDimensions)
            }}
          >
            <CubeFilterField dimension="OrgMemberInvites.accepted" />
            <CubeFilterField dimension="OrgMemberInvites.createdAt" />
            <CubeFilterField dimension="SentEmails.status" />
          </CubeFiltersPopover>
          <WebUI.Button
            variant="outlined"
            iconBefore={<WebUI.PhosphorIcon icon="download-simple" />}
            // onClick={() => setShowExportPanel(true)}
          >
            Export
          </WebUI.Button>
        </WebUI.HStack>
      </WebUI.HStack>

      <WebUI.Panel>
        <CubeTable
          ref={cubeTableRef}
          className="[&_.TableView-headerGroup]:px-2 [&_.TableViewRow]:px-2"
          enableRowSelection
          initialState={{sorting: initialSortingState}}
          state={{rowSelection: selectionState}}
          query={query}
          columns={columns}
          countMeasure="OrgMemberInvites.count"
          onRowSelectionChange={setSelectionState}
        />
      </WebUI.Panel>

      <ResendInvitesModal
        inviteIdsToResend={inviteIdsToResend}
        onDidHide={() => setInviteIdsToResend([])}
      />
    </WebUI.VStack>
  )
}

// MARK: – ResendInvitesModal

interface ResendInvitesModalProps extends WebUI.AlertProps {
  inviteIdsToResend: string[]
}

const ResendInvitesModal = ({
  inviteIdsToResend,
  ...restProps
}: ResendInvitesModalProps) => {
  const sessionEmailQuery = api.auth.session.useQuery(undefined, {
    select: (session) => session.user.email,
  })
  const recreateOrgMemberInviteMutation = useRecreateOrgMemberInviteMutation()
  const createOrgMemberTestInvitesMutation =
    useCreateOrgMemberTestInvitesMutation()
  const growlActions = WebUI.useGrowlActions()

  return (
    <WebUI.Alert
      aria-label="Resend invitations confirmation"
      visible={inviteIdsToResend.length > 0}
      {...restProps}
    >
      <WebUI.AlertHeader>Resend Invitations</WebUI.AlertHeader>
      <WebUI.AlertContentView
        text={`You are about to resend invitations to ${inviteIdsToResend.length} collectors.`}
        actions={
          <>
            <WebUI.AlertActionButton
              execute={async () => {
                if (inviteIdsToResend.length) {
                  try {
                    await recreateOrgMemberInviteMutation.mutateAsync({
                      body: {
                        id: inviteIdsToResend,
                      },
                    })
                    const count = inviteIdsToResend.length
                    const isMultiple = count > 1
                    growlActions.show('success', {
                      body: `${count} invitation${
                        isMultiple ? 's were' : ' was'
                      } successfully resent.`,
                    })
                  } catch {
                    growlActions.show('error', {
                      body: 'Failed to resend the invitations!',
                    })
                  }
                }
              }}
            >
              Resend Invitations
            </WebUI.AlertActionButton>
            <WebUI.AlertActionButton
              variant="link"
              execute={async () => {
                try {
                  const email = sessionEmailQuery.data
                  if (email) {
                    await createOrgMemberTestInvitesMutation.mutateAsync({
                      body: {
                        email,
                      },
                    })
                    growlActions.show('success', {
                      title: 'Success',
                      body: `A test invitation was successfully sent to ${email}.`,
                    })
                  }
                } catch {
                  growlActions.show('error', {
                    title: 'Error',
                    body: 'Failed to send the test invitation!',
                  })
                }
              }}
            >
              Send me a test
            </WebUI.AlertActionButton>
          </>
        }
      />
    </WebUI.Alert>
  )
}

// MARK: – DeleteInvitationButton

interface DeleteInvitationButtonProps
  extends React.ComponentPropsWithoutRef<'button'> {
  invitationId: number
  onDelete: () => void
}

const DeleteInvitationButton = ({
  invitationId,
  onDelete,
  ...restProps
}: DeleteInvitationButtonProps) => {
  const deleteOrgMemberInviteMutation = useDeleteOrgMemberInviteMutation()
  return (
    <WebUI.IconButton
      className="text-ds-md"
      size="default_alt"
      loading={deleteOrgMemberInviteMutation.isPending}
      onClick={() =>
        deleteOrgMemberInviteMutation.mutate(
          {
            pathParams: {
              inviteId: invitationId,
            },
          },
          {onSuccess: () => onDelete()},
        )
      }
      {...restProps}
    >
      <WebUI.PhosphorIcon icon="trash" />
    </WebUI.IconButton>
  )
}
