import {PatternFormat, PatternFormatProps} from 'react-number-format'
import {forwardRef} from 'react'
import {ForwardRefComponent} from '@cheddarup/react-util'

import {Input, InputProps} from './Input'
import {cn} from '../utils'

export interface PatternInputProps
  extends Omit<InputProps, 'type'>,
    Omit<PatternFormatProps, 'customInput' | 'size' | '$$typeof'> {}

export const PatternInput = forwardRef(
  (
    {valueIsNumericString = true, as: Comp = Input, className, ...restProps},
    forwardedRef,
  ) => (
    <PatternFormat
      getInputRef={forwardedRef}
      className={cn('PatternInput', className)}
      customInput={Comp as any}
      valueIsNumericString={valueIsNumericString}
      {...restProps}
    />
  ),
) as ForwardRefComponent<
  'input',
  Omit<PatternFormatProps<typeof Input>, 'customInput' | 'size' | '$$typeof'>
>
