import {api} from '@cheddarup/api-client'
import {isAxiosError} from 'axios'
import {useParams} from 'react-router-dom'
import NotFoundPage from 'src/views/NotFoundPage'

export interface CollectionGuardProps {
  children: React.ReactNode
}

const CollectionGuard: React.FC<CollectionGuardProps> = ({children}) => {
  const urlParams = useParams()
  const tabId = Number(urlParams.collection)

  const collectionQuery = api.tabs.detail.useQuery({pathParams: {tabId}})

  const isForbiddenCollection =
    isAxiosError(collectionQuery.error) &&
    (collectionQuery.error.response?.status === 404 ||
      collectionQuery.error.response?.status === 403)

  if (isForbiddenCollection) {
    return <NotFoundPage />
  }

  return <>{children}</>
}

export default CollectionGuard
