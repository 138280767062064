import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'

import type {TicketFormFormik} from './TicketForm'

export interface TicketFormDetailsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  formik: TicketFormFormik
  collectionId: number
}

export const TicketFormDetails: React.FC<TicketFormDetailsProps> = ({
  formik,
  collectionId,
  className,
  ...restProps
}) => {
  const categoriesQuery = api.tabCategories.list.useQuery({
    pathParams: {
      tabId: collectionId,
    },
  })

  return (
    <WebUI.VStack className={WebUI.cn('gap-6', className)} {...restProps}>
      <WebUI.Text className="text-ds-base">Name and Amount</WebUI.Text>
      <WebUI.VStack className="gap-6">
        <WebUI.FormFieldGroup>
          <WebUI.FormField
            label="Ticket Name"
            error={formik.errors.name}
            required
          >
            <WebUI.Input
              className="max-w-[440px]"
              name="name"
              placeholder="Item Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </WebUI.FormField>
          {categoriesQuery.data && categoriesQuery.data.length > 0 && (
            <WebUI.FormField label="Category" error={formik.errors.parent_id}>
              <WebUI.Select
                className="[&_>_.Select-select]:min-h-10"
                name="parent_id"
                value={formik.values.parent_id ?? ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">Add to Category</option>
                {categoriesQuery.data.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </WebUI.Select>
            </WebUI.FormField>
          )}
        </WebUI.FormFieldGroup>

        <WebUI.FormField
          className="max-w-[200px]"
          required
          label="Ticket Price"
          error={formik.errors.amount}
        >
          <WebUI.AmountInput
            className="max-w-[140px]"
            name="amount"
            placeholder="$0"
            value={formik.values.amount}
            onValueChange={(newAmount) =>
              formik.setFieldValue('amount', newAmount)
            }
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
        {formik.values.available_quantity_enabled && (
          <WebUI.FormField
            required
            label="Available Quantity"
            error={formik.errors.available_quantity}
            caption="Sold out will show when all available tickets have been purchased"
          >
            <WebUI.Input
              className="max-w-[125px]"
              name="available_quantity"
              placeholder="Qty"
              value={formik.values.available_quantity}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </WebUI.FormField>
        )}
        <WebUI.Checkbox
          name="available_quantity_enabled"
          size="compact"
          checked={!formik.values.available_quantity_enabled}
          onChange={(event) =>
            formik.setFieldValue(
              'available_quantity_enabled',
              !event.target.checked,
            )
          }
        >
          Unlimited Available Quantity
        </WebUI.Checkbox>
      </WebUI.VStack>
    </WebUI.VStack>
  )
}
