import * as WebUI from '@cheddarup/web-ui'
import {useLiveRef, useUpdateEffect} from '@cheddarup/react-util'
import {Link as ReactRouterLink} from 'react-router-dom'
import React, {useContext, useEffect, useMemo, useState} from 'react'
import {api} from '@cheddarup/api-client'
import {useCubeApi, useCubeQuery} from 'src/hooks/cube'
import {useManagerRoleId} from 'src/components/ManageRoleProvider'

type CollectionsSelectSelectionMode = 'single' | 'multiple'

interface CollectionsSelectInternalContextValue {
  collections: CubeCollectionWithPayments[]
  selectedCollections: CubeCollectionWithPayments[]
  selectionMode: CollectionsSelectSelectionMode
  setSelectedCollectionIds: React.Dispatch<React.SetStateAction<number[]>>
}

const CollectionsSelectInternalContext = React.createContext(
  {} as CollectionsSelectInternalContextValue,
)

// MARK: – CollectionsSelect

export interface CollectionsSelectProps {
  initialSelectedCollectionIds?: number[]
  children?: React.ReactNode
  onSelectedCollectionIdsChange?: (selectedCollectionIds: number[]) => void
}

export const CollectionsSelect = ({
  children,
  initialSelectedCollectionIds = [],
  onSelectedCollectionIdsChange,
}: CollectionsSelectProps) => {
  const [managerRoleId] = useManagerRoleId()
  const {data: session} = api.auth.session.useQuery()
  const {collections: collectionsWithPayments, isLoading} =
    useCollectionsWithPayments()
  const [selectedCollectionIds, setSelectedCollectionIds] = useState(
    initialSelectedCollectionIds,
  )
  const collectionIdsRef = useLiveRef(
    collectionsWithPayments.map((c) => c['Collections.id']),
  )
  const onSelectedCollectionIdsChangeRef = useLiveRef(
    onSelectedCollectionIdsChange,
  )

  const isSubscribedToTeam =
    !!managerRoleId || !!session?.capabilities.subscribed_to_team

  useUpdateEffect(() => {
    onSelectedCollectionIdsChangeRef.current?.(selectedCollectionIds)
  }, [selectedCollectionIds])

  useEffect(() => {
    if (
      !isSubscribedToTeam &&
      !isLoading &&
      selectedCollectionIds.length !== 1 &&
      !!collectionIdsRef.current[0]
    ) {
      setSelectedCollectionIds([collectionIdsRef.current[0]])
    }
  }, [isLoading, isSubscribedToTeam, selectedCollectionIds])

  const selectedCollections = useMemo(
    () =>
      collectionsWithPayments.filter((c) =>
        selectedCollectionIds.includes(c['Collections.id']),
      ),
    [collectionsWithPayments, selectedCollectionIds],
  )

  const contextValue: CollectionsSelectInternalContextValue = useMemo(
    () => ({
      collections: collectionsWithPayments,
      selectedCollections,
      setSelectedCollectionIds,
      selectionMode: isSubscribedToTeam ? 'multiple' : 'single',
    }),
    [collectionsWithPayments, selectedCollections, isSubscribedToTeam],
  )

  return (
    <CollectionsSelectInternalContext.Provider value={contextValue}>
      {children}
    </CollectionsSelectInternalContext.Provider>
  )
}

// MARK: – CollectionDropdownSelect

export interface CollectionDropdownSelectProps
  extends WebUI.PopoverDisclosureProps {}

export const CollectionDropdownSelect = (
  props: CollectionDropdownSelectProps,
) => {
  const {
    selectionMode,
    collections,
    selectedCollections,
    setSelectedCollectionIds,
  } = useContext(CollectionsSelectInternalContext)
  const allSelected = collections.length === selectedCollections.length

  return selectionMode === 'multiple' ? (
    <WebUI.Disclosure>
      {(disclosure) => (
        <WebUI.Popover aria-label="Collections select" placement="bottom-start">
          {(popover) => (
            <>
              <WebUI.PopoverDisclosure
                className="min-w-[160px] justify-start px-2 text-left"
                variant="outlined"
                iconAfter={<WebUI.PhosphorIcon icon="caret-down-fill" />}
                {...props}
              >
                {disclosure.visible ? 'Select Collections' : 'All Collections'}
              </WebUI.PopoverDisclosure>

              <WebUI.PopoverContent
                className={
                  'max-w-[320px] [&_.Button]:justify-start [&_.Button]:px-[0.5em] [&_.Button]:py-2 [&_.Button]:text-left [&_.Checkbox]:justify-start [&_.Checkbox]:px-[0.5em] [&_.Checkbox]:py-2 [&_.Checkbox]:text-left [&_>_.PopoverContent-inner_>_.PopoverContent-body]:p-1'
                }
              >
                <WebUI.Button
                  variant="ghost"
                  onClick={() => {
                    setSelectedCollectionIds([])
                    disclosure.hide()
                    popover.hide()
                  }}
                >
                  All Collections
                </WebUI.Button>
                <WebUI.DisclosureButton variant="ghost" arrow={false}>
                  Select Collections
                </WebUI.DisclosureButton>

                <WebUI.DisclosureContent>
                  <WebUI.Separator className="my-3" />
                  <WebUI.VStack>
                    <WebUI.Button
                      className="text-ds-sm"
                      variant="link"
                      onClick={() =>
                        setSelectedCollectionIds(
                          allSelected
                            ? []
                            : collections.map((c) => c['Collections.id']),
                        )
                      }
                    >
                      {allSelected ? 'Clear' : 'Select All'}
                    </WebUI.Button>

                    {collections.map((c) => (
                      <WebUI.Checkbox
                        key={c['Collections.id']}
                        state={selectedCollections.some(
                          (sc) => sc['Collections.id'] === c['Collections.id'],
                        )}
                        onChange={(event) =>
                          setSelectedCollectionIds(
                            (prevSelectedCollectionIds) =>
                              event.target.checked
                                ? [
                                    ...prevSelectedCollectionIds,
                                    c['Collections.id'],
                                  ]
                                : prevSelectedCollectionIds.filter(
                                    (scId) => scId !== c['Collections.id'],
                                  ),
                          )
                        }
                      >
                        {c['Collections.name']}
                      </WebUI.Checkbox>
                    ))}
                  </WebUI.VStack>
                </WebUI.DisclosureContent>
              </WebUI.PopoverContent>
            </>
          )}
        </WebUI.Popover>
      )}
    </WebUI.Disclosure>
  ) : (
    <WebUI.DropdownSelect
      className="min-w-[160px]"
      listClassName="max-w-[480px] [&_>_.DropdownSelectOption]:font-normal"
      size="compact"
      value={selectedCollections[0]?.['Collections.id']}
      onValueChange={(newSelectedCollectionId) =>
        setSelectedCollectionIds([Number(newSelectedCollectionId)])
      }
      stateReducer={(state, actionAndChanges) => {
        const {changes, type} = actionAndChanges
        switch (type) {
          case WebUI.useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
          case WebUI.useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
          case WebUI.useSelect.stateChangeTypes.ItemClick:
            return changes.selectedItem?.value === ''
              ? {...changes, selectedItem: state.selectedItem}
              : changes
          default:
            return changes
        }
      }}
      {...props}
    >
      <WebUI.DropdownSelectOption
        value=""
        as={ReactRouterLink as any}
        variant="default"
        to="/reports/i/plans/team-upgrade"
      >
        <span className="text-buttonGhostText">All Collections</span> Upgrade to
        Team
      </WebUI.DropdownSelectOption>
      {collections.map((c) => (
        <WebUI.DropdownSelectOption
          key={c['Collections.id']}
          value={c['Collections.id']}
        >
          {c['Collections.name']}
        </WebUI.DropdownSelectOption>
      ))}
    </WebUI.DropdownSelect>
  )
}

// MARK: – CollectionTagsCloud

export interface CollectionTagsCloudProps
  extends React.ComponentPropsWithoutRef<'div'> {}

export const CollectionTagsCloud = ({
  className,
  style,
  ...restProps
}: CollectionTagsCloudProps) => {
  const {selectedCollections, setSelectedCollectionIds} = useContext(
    CollectionsSelectInternalContext,
  )
  return (
    <WebUI.HStack
      className={WebUI.cn(
        'no-scrollbar -mb-2 inline-flex max-h-32 flex-wrap gap-2 overflow-y-auto *:mr-2 *:mb-2 *:ml-0',
        className,
      )}
      {...restProps}
    >
      {selectedCollections.map((c) => (
        <WebUI.Tag
          key={c['Collections.id']}
          className="max-w-[180px] text-ds-sm text-gray800"
          onClear={() =>
            setSelectedCollectionIds((prevSelectedCollectionIds) =>
              prevSelectedCollectionIds.filter(
                (id) => id !== c['Collections.id'],
              ),
            )
          }
        >
          {c['Collections.name']}
        </WebUI.Tag>
      ))}
    </WebUI.HStack>
  )
}

// MARK: – Helpers

export const useCollectionsSelect = () => {
  const collectionsSelect = useContext(CollectionsSelectInternalContext)
  return useMemo(
    () => ({
      selectedCollections: collectionsSelect.selectedCollections,
      selectionMode: collectionsSelect.selectionMode,
    }),
    [collectionsSelect.selectedCollections, collectionsSelect.selectionMode],
  )
}

export interface CubeCollectionWithPayments {
  'Collections.id': number
  'Collections.createdAt': string
  'Collections.deletedAt': string | null
  'Collections.name': string
}

let memoizedCollections: CubeCollectionWithPayments[] | null = null

export const useCollectionsWithPayments = () => {
  const cubeApi = useCubeApi()
  const query = useCubeQuery<CubeCollectionWithPayments>(
    {
      dimensions: [
        'Collections.id',
        'Collections.createdAt',
        'Collections.deletedAt',
        'Collections.name',
      ],
      order: [['Collections.createdAt', 'desc']],
    },
    {cubeApi, skip: !!memoizedCollections},
  )

  const collections = useMemo(
    () =>
      memoizedCollections ??
      (
        query.resultSet?.tablePivot() as any as
          | CubeCollectionWithPayments[]
          | undefined
      )?.filter((c) => c['Collections.deletedAt'] == null),
    [query.resultSet],
  )

  memoizedCollections = collections ?? null

  return useMemo(
    () => ({
      collections: collections ?? [],
      isLoading: query.isLoading,
    }),
    [collections, query.isLoading],
  )
}
