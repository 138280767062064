import * as WebUI from '@cheddarup/web-ui'
import React from 'react'
import {
  FeatureKey,
  PremiumFeatureSideSheetDisclosure,
} from 'src/components/PremiumFeaturesSideSheet'

export interface SettingDisclosureSwitchProps
  extends Omit<WebUI.DisclosureSwitchProps, 'children'> {
  children?: React.ReactNode
  featureKey?: FeatureKey
  enforcePaidBadge?: boolean
  caption?: string
}

export const SettingDisclosureSwitch = ({
  caption,
  children,
  className,
  featureKey,
  enforcePaidBadge = false,
  ...restProps
}: SettingDisclosureSwitchProps) => (
  <div className="flex max-w-full flex-[1_0_auto] items-center gap-4">
    <span className="max-w-full flex-[12_1_auto]">{children}</span>
    {enforcePaidBadge && featureKey ? (
      <div className="flex flex-[1_0_68px]">
        <PremiumFeatureSideSheetDisclosure featureKeys={[featureKey]} />
      </div>
    ) : featureKey ? (
      <WebUI.DisclosureContent className="flex-[1_0_76px]">
        <PremiumFeatureSideSheetDisclosure featureKeys={[featureKey]} />
      </WebUI.DisclosureContent>
    ) : null}
    {caption ? (
      <WebUI.Tooltip placement="top-end">
        <WebUI.TooltipAnchor>
          <WebUI.DisclosureSwitch
            className={WebUI.cn('[&_>_.Switch-content]:min-w-0', className)}
            {...restProps}
          />
        </WebUI.TooltipAnchor>
        <WebUI.TooltipContent variant="light" className="max-w-64">
          {caption}
        </WebUI.TooltipContent>
      </WebUI.Tooltip>
    ) : (
      <WebUI.DisclosureSwitch
        className={WebUI.cn('[&_>_.Switch-content]:min-w-0', className)}
        {...restProps}
      />
    )}
  </div>
)
