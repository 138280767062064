import {endpoints} from '../../endpoints'
import {getEndpointKey} from '../../utils'
import {makeQueryUpdate, makeUseMutation} from '../use-mutation'

export const useUpdateTabPaymentItemMutation = makeUseMutation(
  endpoints.paymentItems.update,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(
        endpoints.tickets.download,
        (prevTicket) =>
          prevTicket
            ? {
                ...prevTicket,
                detail: {
                  ...prevTicket.detail,
                  ticketStatus:
                    prevTicket.detail?.ticketStatus ?? vars.body?.ticketStatus,
                  organizerNote:
                    prevTicket.detail?.organizerNote ??
                    vars.body?.organizerNote,
                },
              }
            : prevTicket,
        {
          pathParams: {
            paymentId: vars.pathParams.paymentId,
          },
          queryParams: {
            ticket_id: Number(vars.pathParams.paymentItemId),
          },
        },
      ),
      makeQueryUpdate(
        endpoints.tickets.detail,
        (prevTicket) =>
          prevTicket
            ? {
                ...prevTicket,
                detail: {
                  ...prevTicket.detail,
                  ticketStatus:
                    prevTicket.detail?.ticketStatus ?? vars.body?.ticketStatus,
                  organizerNote:
                    prevTicket.detail?.organizerNote ??
                    vars.body?.organizerNote,
                },
              }
            : prevTicket,
        {
          pathParams: {
            ticketId: vars.pathParams.paymentItemId,
          },
        },
      ),
      makeQueryUpdate(
        endpoints.tickets.list,
        (prevTickets) =>
          prevTickets?.map((t) =>
            t.id === vars.pathParams.paymentItemId
              ? {
                  ...t,
                  detail: {
                    ...t.detail,
                    ticketStatus:
                      t.detail?.ticketStatus ?? vars.body?.ticketStatus,
                    organizerNote:
                      t.detail?.organizerNote ?? vars.body?.organizerNote,
                  },
                }
              : t,
          ),
        vars,
      ),
    ],
    regular: (newPaymentItem) => [
      makeQueryUpdate(
        endpoints.tabPayments.detail,
        (prevPayment) =>
          prevPayment
            ? {
                ...prevPayment,
                payment_items: prevPayment.payment_items.map((p) =>
                  p.id === newPaymentItem.id
                    ? {...p, detail: newPaymentItem.detail}
                    : p,
                ),
              }
            : prevPayment,
        vars,
      ),
    ],
  }),
  (queryClient) => ({
    onSuccess: (newPaymentItem, vars) => {
      const tabPaymentsListKey = getEndpointKey(
        endpoints.tabPayments.list,
        vars,
      )
      const itemsReportEndpointKey = getEndpointKey(endpoints.tabItems.report, {
        pathParams: {
          ...vars.pathParams,
          itemId: newPaymentItem.tab_object_id,
        },
      })

      queryClient.invalidateQueries({queryKey: tabPaymentsListKey})
      queryClient.invalidateQueries({queryKey: itemsReportEndpointKey})
    },
  }),
)

export const useRevokeTicketsMutation = makeUseMutation(
  endpoints.paymentItems.revokeTickets,
  undefined,
  (queryClient) => ({
    onSuccess: (updatedItem, vars) => {
      const itemsReportEndpointKey = getEndpointKey(endpoints.tabItems.report, {
        pathParams: {
          ...vars.pathParams,
          itemId: updatedItem.tab_object_id,
        },
      })

      queryClient.invalidateQueries({queryKey: itemsReportEndpointKey})
    },
  }),
)
