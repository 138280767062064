import React, {useEffect} from 'react'
import {useCreateShipmentIntentMutation} from '@cheddarup/api-client'

export interface WithShippingIntentProps {
  collectionId: number
  paymentId: number
  shipmentExists: boolean
  children:
    | React.ReactNode
    | ((
        args: {payment_intent_id: string; client_secret: string} | undefined,
      ) => React.ReactNode)
}

export const WithShippingIntent = ({
  collectionId,
  paymentId,
  shipmentExists,
  children,
}: WithShippingIntentProps) => {
  const createShipmentIntentMutation = useCreateShipmentIntentMutation()

  const createShipmentIntent = createShipmentIntentMutation.mutate
  useEffect(() => {
    if (shipmentExists) {
      createShipmentIntent({
        pathParams: {
          tabId: collectionId,
          paymentId,
        },
      })
    }
  }, [collectionId, createShipmentIntent, paymentId, shipmentExists])

  return (
    <>
      {typeof children === 'function'
        ? children(createShipmentIntentMutation.data)
        : children}
    </>
  )
}
