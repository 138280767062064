import {forwardRef} from 'react'
import {ForwardRefComponent} from '@cheddarup/react-util'
import {cva} from 'class-variance-authority'

import {VariantsProps, cn} from '../utils'

export type TextVariant = 'default' | 'subtle' | 'danger'

export const text = cva('font-body font-normal', {
  variants: {
    variant: {
      default: 'text-inherit',
      subtle: 'text-gray400',
      danger: 'text-orange-50',
    },
  },
})

export interface TextProps extends VariantsProps<typeof text> {}

// TODO: improve
export const Text = forwardRef(
  (
    {as: Comp = 'span', className, variant = 'default', ...restProps},
    forwardedRef,
  ) => {
    // GlamourAbsolute does not look good with subpixel antialiase
    const shouldAntialias = className?.includes('font-accentAlt')

    return (
      <Comp
        ref={forwardedRef}
        className={cn(
          `Text Text--${variant}`,
          text({variant}),
          shouldAntialias && 'antialiased',
          className,
        )}
        {...restProps}
      />
    )
  },
) as ForwardRefComponent<'span', TextProps>
