import {endpoints} from '../../endpoints'
import {makeQueryUpdate, makeUseMutation} from '../use-mutation'

export const useCreateGroupPageSponsorMutation = makeUseMutation(
  endpoints.groupPageSponsors.create,
  () => ({
    regular: (newSponsor) => [
      makeQueryUpdate(
        endpoints.groupPageSections.detail,
        (groupPageSection) =>
          groupPageSection
            ? {
                ...groupPageSection,
                sponsor_items: [
                  ...(groupPageSection.sponsor_items || []),
                  newSponsor,
                ],
              }
            : groupPageSection,

        {pathParams: {sectionName: 'sponsor'}},
      ),
    ],
  }),
)

export const useUpdateGroupPageSponsorMutation = makeUseMutation(
  endpoints.groupPageSponsors.update,
  (vars) => ({
    optimistic:
      vars.body?.position == null
        ? []
        : [
            makeQueryUpdate(
              endpoints.groupPageSections.detail,
              (prevGroupPageSection) => {
                if (!prevGroupPageSection) {
                  return prevGroupPageSection
                }

                const newPosition = vars.body?.position ?? -1

                return {
                  ...prevGroupPageSection,
                  sponsor_items: prevGroupPageSection.sponsor_items?.map(
                    (si) =>
                      si.id === vars.body?.id
                        ? {
                            ...si,
                            position: newPosition,
                          }
                        : newPosition >= si.position
                          ? {
                              ...si,
                              position: si.position + 1,
                            }
                          : si,
                  ),
                }
              },
              {pathParams: {sectionName: 'sponsor'}},
            ),
          ],
    regular: (updatedSponsor) => [
      makeQueryUpdate(
        endpoints.groupPageSponsors.detail,
        () => updatedSponsor,
        {
          pathParams: vars.pathParams,
        },
      ),
      makeQueryUpdate(
        endpoints.groupPageSections.detail,
        (groupPageSection) =>
          groupPageSection
            ? {
                ...groupPageSection,
                sponsor_items: groupPageSection.sponsor_items?.map((sponsor) =>
                  sponsor.id === updatedSponsor.id ? updatedSponsor : sponsor,
                ),
              }
            : groupPageSection,

        {pathParams: {sectionName: 'sponsor'}},
      ),
    ],
  }),
)

export const useDeleteGroupPageSponsorMutation = makeUseMutation(
  endpoints.groupPageSponsors.delete,
  (vars) => ({
    regular: () => [
      makeQueryUpdate(
        endpoints.groupPageSections.detail,
        (groupPageSection) =>
          groupPageSection
            ? {
                ...groupPageSection,
                sponsor_items: groupPageSection.sponsor_items?.filter(
                  (sponsor) => sponsor.id !== vars.pathParams.id,
                ),
              }
            : groupPageSection,

        {pathParams: {sectionName: 'sponsor'}},
      ),
    ],
  }),
)
