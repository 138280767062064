import {forwardRef} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {useUndoableMutation} from 'src/hooks/useUndoableMutation'
import {useDeleteCategoryMutation} from '@cheddarup/api-client'

export interface DeleteItemCategoryAlertProps extends WebUI.AlertProps {
  category: Api.Category | null
}

const DeleteItemCategoryAlert = forwardRef<
  WebUI.DialogInstance,
  DeleteItemCategoryAlertProps
>(({category, ...restProps}, forwardedRef) => {
  const deleteCategoryUndoableMutation = useUndoableMutation(
    useDeleteCategoryMutation({timeout: 8000}),
  )
  return (
    <WebUI.Alert
      ref={forwardedRef}
      aria-label="Delete Item Category Alert"
      {...restProps}
    >
      <WebUI.AlertHeader>Are you sure?</WebUI.AlertHeader>
      <WebUI.AlertContentView
        text="Deleting this category will also delete all items in the category"
        actions={
          <>
            <WebUI.AlertActionButton
              execute={() => {
                if (category) {
                  deleteCategoryUndoableMutation.mutateWithUndo(
                    {
                      body: `Category "${category.name}" Deleted`,
                    },
                    {
                      pathParams: {
                        tabId: category.tab_id,
                        categoryId: category.id,
                      },
                    },
                  )
                }
              }}
            >
              Delete
            </WebUI.AlertActionButton>
            <WebUI.AlertCancelButton />
          </>
        }
      />
    </WebUI.Alert>
  )
})

export default DeleteItemCategoryAlert
