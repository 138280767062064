import * as WebUI from '@cheddarup/web-ui'
import React from 'react'
import {Link} from 'src/components/Link'
import {QuickTourSlideId} from './GroupPageQuickTourModal'

interface GroupPageSectionHeader extends WebUI.PageHeaderProps {
  quickTourSlideId: QuickTourSlideId
}

const GroupPageSectionHeader: React.FC<GroupPageSectionHeader> = ({
  subheading,
  quickTourSlideId,
  ...restProps
}) => {
  return (
    <WebUI.PageHeader
      subheading={
        <>
          {subheading}{' '}
          <Link
            to={`group-page-quick-tour?slide=${quickTourSlideId}`}
            variant="primary"
          >
            Quick Tour
          </Link>
        </>
      }
      {...restProps}
    />
  )
}

export default GroupPageSectionHeader
