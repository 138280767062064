import {debounce} from '@cheddarup/util'
import {useLiveRef, useUpdateEffect} from '@cheddarup/react-util'
import {useEffect, useMemo, useState} from 'react'

import {RichTextEditor, RichTextEditorProps} from './Plate'

export interface DeferredRichTextMarkdownProps extends RichTextEditorProps {
  commitValue?: (newValue: string) => void
}

export const DeferredRichTextMarkdown = ({
  initialMarkdownValue,
  commitValue,
  ...restProps
}: DeferredRichTextMarkdownProps) => {
  const [pendingValue, setPendingValue] = useState(initialMarkdownValue ?? '')
  const commitValueRef = useLiveRef(commitValue)

  const debouncedHandleChange = useMemo(
    () =>
      debounce((newValue: string) => {
        if (initialMarkdownValue === newValue) {
          return
        }
        commitValueRef.current?.(newValue)
      }, 500),
    [initialMarkdownValue],
  )

  useUpdateEffect(() => {
    debouncedHandleChange(pendingValue)
  }, [debouncedHandleChange, pendingValue])

  useEffect(() => () => debouncedHandleChange.cancel(), [debouncedHandleChange])

  return (
    <RichTextEditor
      initialMarkdownValue={initialMarkdownValue}
      onMarkdownValueChange={(newMarkdownValue) =>
        setPendingValue(newMarkdownValue)
      }
      {...restProps}
    />
  )
}
