import {z} from '@cheddarup/util'

export const tabDonationCodeSchema = z.object({
  id: z.number(),
  code: z.string(),
  active: z.boolean(),
  last_used_at: z.string().nullable(),
  expired_at: z.string().nullable(),
  tab_id: z.number(),
})

declare global {
  namespace Api {
    // @ts-ignore
    type TabDonationCode = z.infer<typeof tabDonationCodeSchema>
  }
}
