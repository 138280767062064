// based on https://github.com/smooth-code/smooth-ui/blob/3b02613f50fbc02dc0cbd6762086900d4c72da01/packages/shared/core/Switch.js

import React, {useRef} from 'react'
import {
  Checkbox as ReakitCheckbox,
  CheckboxInitialState as ReakitCheckboxInitialState,
  useCheckboxState as useReakitCheckboxState,
} from 'reakit'
import {useForkRef, useLiveRef, useUpdateEffect} from '@cheddarup/react-util'
import {cva} from 'class-variance-authority'

import {HStack} from './Stack'
import {VariantsProps, cn} from '../utils'

export interface SwitchProps
  extends ReakitCheckboxInitialState,
    BaseSwitchProps {
  reverse?: boolean
}

export const Switch = React.forwardRef<HTMLInputElement, SwitchProps>(
  (
    {
      reverse,
      size = 'default',
      onMouseDown,
      state,
      className,
      children,
      ...restProps
    },
    forwardedRef,
  ) => {
    const checkbox = useReakitCheckboxState({state})
    const checkboxRef = useLiveRef(checkbox)
    const ownRef = useRef<HTMLInputElement>(null)
    const ref = useForkRef(ownRef, forwardedRef)

    useUpdateEffect(() => {
      if (state != null) {
        checkboxRef.current.setState(state)
      }
    }, [state])

    return (
      <HStack
        className={cn(
          'Switch',
          'inline-flex cursor-pointer items-center',
          !reverse && 'gap-2',
          className,
        )}
        onMouseDown={onMouseDown}
        onClick={() => {
          // Only handle click if component is not controlled
          if (state == null || restProps.onChange) {
            ownRef.current?.click()
          }
        }}
      >
        <ReakitCheckbox
          ref={ref}
          className={cn(
            'Switch-input',
            'peer/input',
            reverse && 'order-1',
            className,
          )}
          as={BaseSwitch}
          size={size}
          {...checkbox}
          {...restProps}
        />
        <HStack
          className={cn(
            'Switch-content',
            'min-w-0 flex-[1] items-center',
            'peer-disabled/input:cursor-not-allowed peer-disabled/input:opacity-50',
            'peer-aria-disabled/input:cursor-not-allowed peer-aria-disabled/input:opacity-50',
          )}
        >
          {typeof children === 'string' ? (
            <span
              className={cn(
                'Switch-label',
                size === 'small' && 'text-ds-xs',
                size === 'compact' && 'text-ds-sm',
              )}
            >
              {children}
            </span>
          ) : (
            children
          )}
        </HStack>
      </HStack>
    )
  },
)

// MARK: – BaseSwitch

export const baseSwitch = cva(
  [
    `pointer-events-none relative inline-block flex-0 overflow-hidden border-[0.5px]
    border-natural-60 bg-natural-70 shadow-[0px_0.6px_0.61px_#0000004D]`,
    'transition-all',
    'aria-checked:bg-tint',
    'disabled:cursor-not-allowed disabled:opacity-50',
    'aria-disabled:cursor-not-allowed aria-disabled:opacity-50',
    'focus:shadow-[inset_0_0_0_1px_theme(colors.teal.50)]',
  ],
  {
    variants: {
      size: {
        default: ['h-6 w-10 rounded-[34px]', 'duration-300 ease-linear'],
        compact: [
          'h-[18px] w-[30px] rounded-[18px]',
          'duration-200 ease-linear',
        ],
        small: [
          'h-[15.6px] w-[26px] rounded-[18px]',
          'duration-200 ease-linear',
        ],
      },
    },
    defaultVariants: {
      size: 'default',
    },
  },
)
export const baseSwitchContent = cva(
  [
    'pointer-events-none flex h-full items-center',
    'transition-all duration-200 ease-linear',
  ],
  {
    variants: {
      size: {
        default: 'group-aria-checked:translate-x-[calc(40px-20px-12%)]',
        compact: 'group-aria-checked:translate-x-[calc(30px-14px-12%)]',
        small: 'group-aria-checked:translate-x-[calc(25px-12px-12%)]',
      },
    },
    defaultVariants: {
      size: 'default',
    },
  },
)
export const baseSwitchBall = cva(
  [
    'mx-0 my-px shrink-0 rounded-full bg-natural-95',
    'transition-all duration-300 ease-linear',
  ],
  {
    variants: {
      size: {
        default: 'h-[22px] w-[22px]',
        compact: 'h-[14px] w-[14px]',
        small: 'h-3 w-3',
      },
    },
    defaultVariants: {
      size: 'default',
    },
  },
)

interface BaseSwitchProps
  extends VariantsProps<typeof baseSwitch>,
    Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange' | 'onBlur'>,
    Pick<
      React.ComponentPropsWithoutRef<'input'>,
      | 'type'
      | 'defaultChecked'
      | 'checked'
      | 'disabled'
      | 'id'
      | 'name'
      | 'onChange'
      | 'onBlur'
    > {
  value?: string | number
}

const BaseSwitch = React.forwardRef<HTMLInputElement, BaseSwitchProps>(
  (
    {
      size,
      type,
      defaultChecked,
      checked,
      disabled,
      id,
      name,
      value,
      onChange,
      onBlur,
      className,
      ...restProps
    },
    forwardedRef,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null)
    return (
      <div
        className={cn('BaseSwitch', 'group', baseSwitch({size}), className)}
        {...restProps}
      >
        <div
          data-switch-content
          className={cn('BaseSwitch-content', baseSwitchContent({size}))}
        >
          <div
            data-switch-ball
            className={cn('BaseSwitch-ball', baseSwitchBall({size}))}
          />
        </div>
        <input
          ref={useForkRef(inputRef, forwardedRef)}
          className={cn(
            'BaseSwitch-input',
            'absolute top-0 left-0 m-0 h-full w-full cursor-[inherit] p-0 opacity-0',
          )}
          tabIndex={-1}
          id={id}
          name={name}
          type={type}
          defaultChecked={defaultChecked}
          checked={checked}
          disabled={disabled}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={() => inputRef.current?.parentElement?.focus()}
        />
      </div>
    )
  },
)
