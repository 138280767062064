import {useParams} from 'react-router-dom'
import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {LinkButton} from 'src/components/LinkButton'
import {
  useCloneSpotMutation,
  useUpdateSpotMutation,
} from '@cheddarup/api-client'

interface SpotQuickActionGroupProps
  extends React.ComponentPropsWithoutRef<'div'> {
  spot: Api.SignUpSpot
  onEdit: () => void
  onDelete: () => void
}

const SpotQuickActionGroup = ({
  spot,
  onEdit,
  onDelete,
  ...restProps
}: SpotQuickActionGroupProps) => {
  const urlParams = useParams()
  const cloneSpotMutation = useCloneSpotMutation()
  const updateSpotMutation = useUpdateSpotMutation()

  return (
    <WebUI.ActionGroup {...restProps}>
      <WebUI.Action
        icon={<WebUI.PhosphorIcon icon="pencil" />}
        onClick={() => onEdit()}
      >
        Edit
      </WebUI.Action>
      <WebUI.Action
        icon={<WebUI.PhosphorIcon icon="copy" />}
        loading={cloneSpotMutation.isPending}
        execute={() =>
          cloneSpotMutation.mutateAsync({
            pathParams: {
              // biome-ignore lint/style/noNonNullAssertion:
              tabId: urlParams.collection!,
              // biome-ignore lint/style/noNonNullAssertion:
              signupId: urlParams.signup!,
              spotId: spot.id,
            },
          })
        }
      >
        Duplicate
      </WebUI.Action>
      <WebUI.Action
        icon={
          spot.hidden ? (
            <WebUI.PhosphorIcon icon="eye" />
          ) : (
            <WebUI.PhosphorIcon icon="eye-slash" />
          )
        }
        loading={updateSpotMutation.isPending}
        execute={() =>
          updateSpotMutation.mutateAsync({
            pathParams: {
              // biome-ignore lint/style/noNonNullAssertion:
              tabId: urlParams.collection!,
              // biome-ignore lint/style/noNonNullAssertion:
              signupId: urlParams.signup!,
              spotId: spot.id,
            },
            body: {hidden: !spot.hidden},
          })
        }
      >
        {spot.hidden ? 'Show' : 'Hide'}
      </WebUI.Action>
      <WebUI.Action
        icon={<WebUI.PhosphorIcon icon="trash" />}
        onClick={() => onDelete()}
      >
        Delete
      </WebUI.Action>
      <WebUI.Action
        icon={<WebUI.PhosphorIcon icon="table" />}
        as={LinkButton}
        preserveSearch
        to={`spot-report/${spot.id}`}
      >
        Spot Report
      </WebUI.Action>
    </WebUI.ActionGroup>
  )
}

export default SpotQuickActionGroup
