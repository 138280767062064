import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import {forwardRef, useState} from 'react'
import ImagesUtils from 'src/helpers/ImagesUtils'

export interface SharpImageProps extends Omit<WebUI.ImageProps, 'src'> {
  defaultAspectRatio?: number
  edits?: Record<string, any>
  image?:
    | Util.SetOptional<
        Pick<
          Api.S3Image,
          'metadata' | 'edited_image_url' | 'upload_path' | 'edit_path'
        >,
        'edit_path' | 'edited_image_url'
      >
    | null
    | string
}

export const SharpImage = forwardRef<HTMLImageElement, SharpImageProps>(
  (
    {defaultAspectRatio, width, height, edits, image, className, ...restProps},
    forwardedRef,
  ) => {
    const [[frozenWidth, frozenHeight]] = useState(
      () => [width, height] as const,
    )

    return (
      <WebUI.Image
        ref={forwardedRef}
        className={WebUI.cn('SharpImage', className)}
        width={width}
        height={height}
        aspectRatio={
          typeof image === 'string'
            ? defaultAspectRatio
            : (image?.metadata.pintura?.cropAspectRatio ?? defaultAspectRatio)
        }
        src={
          image
            ? typeof image === 'string'
              ? image
              : typeof frozenWidth === 'number' &&
                  typeof frozenHeight === 'number'
                ? ImagesUtils.getCroppedImageUrl(
                    image,
                    {width: frozenWidth, height: frozenHeight},
                    edits,
                  )
                : ImagesUtils.getCroppedImageUrl(image, undefined, edits)
            : null
        }
        {...restProps}
      />
    )
  },
)
