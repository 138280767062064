import {forwardRef} from 'react'
import * as WebUI from '@cheddarup/web-ui'

import {LinkButton} from './LinkButton'

export const UpgradeRequiredAlert = forwardRef<
  WebUI.DialogInstance,
  WebUI.AlertProps
>((props, forwardedRef) => (
  <WebUI.Alert ref={forwardedRef} aria-label="Upgrade required" {...props}>
    <WebUI.AlertHeader>You&apos;ve got a good thing going!</WebUI.AlertHeader>
    <WebUI.AlertContentView
      text="Upgrade to add unlimited items, forms and waivers."
      actions={
        <>
          <WebUI.AlertActionButton as={LinkButton} to="i/plans">
            Learn More
          </WebUI.AlertActionButton>
          <WebUI.AlertCancelButton>No Thanks</WebUI.AlertCancelButton>
        </>
      }
    />
  </WebUI.Alert>
))
