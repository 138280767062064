import * as Util from '@cheddarup/util'
import {Query, TQueryOrderObject} from '@cubejs-client/core'
import * as WebUI from '@cheddarup/web-ui'
import React, {useImperativeHandle, useMemo, useState} from 'react'
import {
  Dimension,
  dimensionToSchema,
  formatCubeValue,
  getDimensionDataType,
} from 'src/helpers/cube-schema'
import {PAYMENT_METHOD_STATUSES} from 'src/views/collection/manage/constants'
import {useCubeQuery} from 'src/hooks/cube'
import {useLiveRef, useUpdateEffect} from '@cheddarup/react-util'

export type CubeObject = Record<string, string | number>

export interface CubeTableColumn
  extends Omit<WebUI.ColumnDef<CubeObject>, 'Header' | 'accessor'> {
  path?: Dimension
  headerTitle?: string
  fallbackPath?: Dimension
}

export interface CubeTableProps
  extends Omit<
    WebUI.TableViewProps<CubeObject>,
    'columns' | 'data' | 'children'
  > {
  columns: CubeTableColumn[] | Util.ReadonlyDeep<CubeTableColumn[]>
  query: Query
  countMeasure?: string
  emptyStateView?: React.ReactNode
  onTableDataChange?: (newData: CubeObject[]) => void
  children?: React.ReactNode
}

export interface CubeTableInstance {
  refetch: () => void
}

export const CubeTable = React.forwardRef<CubeTableInstance, CubeTableProps>(
  (
    {
      query,
      countMeasure,
      onTableDataChange,
      initialState,
      columns: columnsProp,
      emptyStateView,
      className,
      children,
      ...restProps
    },
    forwardedRef,
  ) => {
    const pageSize = countMeasure ? 25 : undefined

    const [pageIndex, setPageIndex] = useState(0)
    const [order, setOrder] = useState(
      tableViewSortByToCubeSortBy(initialState?.sorting ?? []),
    )
    const onTableDataChangeRef = useLiveRef(onTableDataChange)

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useUpdateEffect(() => {
      setPageIndex(0)
    }, [query.filters])

    const countQuery = useCubeQuery(
      {
        measures: [...(query.measures ?? []), countMeasure].filter(
          (m): m is Dimension => !!m,
        ),
        filters: query.filters,
        timeDimensions: query.timeDimensions,
      },
      {skip: !countMeasure},
    )
    const dataQuery = useCubeQuery<CubeObject>({
      ...query,
      order,
      limit: pageSize,
      offset: pageSize ? pageIndex * pageSize : undefined,
    })

    const totalSize = countMeasure
      ? Number(countQuery.resultSet?.tablePivot()[0]?.[countMeasure] ?? '0')
      : 0

    useImperativeHandle(
      forwardedRef,
      () => ({
        refetch: () => dataQuery.refetch(),
      }),
      [dataQuery],
    )

    const columns = useMemo(
      () =>
        columnsProp.map((c, idx) =>
          Util.pickBy(
            {
              ...c,
              id: c.id ?? c.path,
              minSize: c.minSize ?? 60,
              maxSize: c.maxSize ?? Number.MAX_SAFE_INTEGER,
              size: c.size ?? 160,
              meta: {
                align: idx === columnsProp.length - 1 ? 'right' : 'left',
              },
              header:
                c.headerTitle ??
                dimensionToSchema[c.path ?? ('' as any as Dimension)]
                  ?.defaultInflection ??
                c.path,
              accessorFn: (dataRow) =>
                dataRow[c.path ?? ''] ??
                (c.fallbackPath ? dataRow[c.fallbackPath] : undefined),
              cell:
                c.cell ??
                (({cell, row: {original}}) => (
                  <WebUI.Ellipsis
                    data-subtle={[
                      'Payments.paymentMethod',
                      'Payments.createdAt',
                      'Payments.status',
                      'Customers.paymentCount',
                      'Customers.firstSeenAt',
                      'Customers.lastSeenAt',
                      'Withdrawals.payoutMethod',
                      'Withdrawals.status',
                      'Customers.visitCount',
                      'Customers.firstVisit',
                      'Customers.lastVisit',
                      'Withdrawals.createdAt',
                      'Collections.name',
                      'Withdrawals.description',
                      'Withdrawals.payoutMethod',
                      'Withdrawals.status',
                    ].includes(c.path ?? '')}
                    className="data-[subtle=true]:font-light"
                  >
                    {c.path
                      ? formatCellValue(
                          cell.getValue() as any,
                          original,
                          c.path,
                        )
                      : (cell.getValue() as any)}
                  </WebUI.Ellipsis>
                )),
            } as WebUI.ColumnDef<CubeObject>,
            (v) => v != null,
          ),
        ) as Array<WebUI.ColumnDef<CubeObject>>,
      [columnsProp],
    )

    const paginationState = useMemo(
      () => ({
        pageSize: pageSize ?? 1,
        pageIndex,
      }),
      [pageIndex, pageSize],
    )

    const data = useMemo(
      () => (dataQuery.resultSet?.tablePivot() ?? []) as CubeObject[],
      [dataQuery.resultSet],
    )

    useUpdateEffect(() => {
      onTableDataChangeRef.current?.(data)
    }, [data])

    const sortingState = useMemo(
      () =>
        Util.arrayFromObject(order, (key, value) => ({
          id: key,
          desc: value === 'desc',
        })),
      [order],
    )

    const pageCount = pageSize ? Math.ceil(totalSize / pageSize) : undefined

    return (
      <WebUI.TableView
        className={WebUI.cn(
          'CubeTable',
          '[&_>_.TableView-headerGroupList_>_.TableView-headerGroup_>_.TableView-header_>_.TableView-headerText]:text-ds-sm',
          className,
        )}
        initialState={Util.pickBy(initialState ?? {}, (p) => p != null)}
        state={{
          pagination: paginationState,
          sorting: sortingState,
        }}
        pageCount={pageCount}
        manualPagination
        sortable
        sortByTogglesVisible
        manualSortBy
        disableSortRemove
        loading={dataQuery.isLoading}
        columns={columns}
        data={data}
        onSortingChange={(updater) => {
          const newSortings =
            typeof updater === 'function' ? updater(sortingState) : updater

          setOrder(tableViewSortByToCubeSortBy(newSortings))
        }}
        onPaginationChange={(updater) => {
          const newPagination =
            typeof updater === 'function' ? updater(paginationState) : updater

          setPageIndex(newPagination.pageIndex)
        }}
        {...restProps}
      >
        {(table) => {
          const isEmpty =
            !dataQuery.isLoading && table.getRowModel().flatRows.length === 0

          if (isEmpty && table.getVisibleFlatColumns().length > 0) {
            table.toggleAllColumnsVisible(false)
          } else if (!isEmpty && table.getVisibleFlatColumns().length === 0) {
            table.toggleAllColumnsVisible(true)
          }

          return (
            <>
              {dataQuery.isLoading && (
                <WebUI.VStack className="min-h-[240px] items-center justify-center">
                  <WebUI.Loader size="6em" />
                </WebUI.VStack>
              )}
              {isEmpty &&
                (!emptyStateView || typeof emptyStateView === 'string' ? (
                  <WebUI.VStack className="p-4">
                    <WebUI.Text>{emptyStateView ?? 'No results'}</WebUI.Text>
                  </WebUI.VStack>
                ) : (
                  emptyStateView
                ))}
              {pageCount && pageCount > 1 ? (
                <WebUI.HStack className="items-center justify-between border-t py-1">
                  {totalSize > 0 && (
                    <WebUI.Text className="px-2 font-light text-ds-sm">
                      {`${totalSize.toLocaleString()} results`}
                    </WebUI.Text>
                  )}
                  <WebUI.TablePaginator />
                </WebUI.HStack>
              ) : null}
              {children}
            </>
          )
        }}
      </WebUI.TableView>
    )
  },
)

// MARK: – Helpers

const formatCellValue = (
  value: string,
  dataRow: CubeObject,
  dimension: Dimension,
) => {
  const dataType = getDimensionDataType(dimension)
  if (dataType === 'paymentStatus') {
    const status = dataRow['Payments.paymentMethod']

    if (typeof status !== 'string') {
      return '–'
    }

    return (
      (PAYMENT_METHOD_STATUSES[status]?.[value] ?? Util.capitalize(value)) ||
      '–'
    )
  }
  if (dimension === 'WithdrawalAccounts.last4') {
    return `****${value}`
  }
  return formatCubeValue(value, dimension)
}

const tableViewSortByToCubeSortBy = (
  tableViewSortingState: WebUI.SortingState,
): TQueryOrderObject =>
  Util.mapToObj(tableViewSortingState, (sb) => [
    {'Customers.name': 'Customers.nameSearch'}[sb.id] ?? sb.id,
    sb.desc ? 'desc' : 'asc',
  ])
