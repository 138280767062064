import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {contactListSchema, contactSchema, paginationSchema} from '../schemas'

export default {
  list: makeEndpoint({
    path: 'users/contacts',
    queryParamsSchema: z
      .object({
        contact_list_id: z.number().nullable(),
        email: z.string().nullable(),
        name: z.string().nullable(),
        name_or_email: z.string().nullable(),
        page: z.number(),
        perPage: z.number(),
        sort: z.enum(['id', 'created_at', 'last_name']).nullable(),
        direction: z.enum(['asc', 'desc']).nullable(),
      })
      .partial()
      .optional(),
    responseSchema: z.object({
      data: contactSchema.array(),
      pagination: paginationSchema,
    }),
  }),
  create: makeEndpoint({
    path: 'users/contacts',
    method: 'POST',
    bodySchema: z.object({
      contacts: z
        .object({
          name: z.string(),
          email: z.string(),
        })
        .array(),
    }),
    responseSchema: z.object({
      created: contactSchema.array(),
      updated: contactSchema.array(),
      deleted: contactSchema.array(),
    }),
  }),
  update: makeEndpoint({
    path: 'users/contacts/:contactId',
    method: 'PATCH',
    bodySchema: contactSchema.omit({id: true}).partial(),
    responseSchema: z.any(),
  }),
  delete: makeEndpoint({
    path: 'users/contacts/:contactId',
    method: 'DELETE',
    bodySchema: contactSchema.omit({id: true}).partial(),
    responseSchema: z.any(),
  }),
  bulkDelete: makeEndpoint({
    path: 'users/contacts/delete',
    method: 'POST',
    bodySchema: z.object({
      contact_ids: z.number().array(),
    }),
    responseSchema: z.any(),
  }),
  suggest: makeEndpoint({
    path: 'users/contacts/suggest',
    queryParamsSchema: z
      .object({
        name_or_email: z.string().nullable(),
        name: z.string().nullable(),
        email: z.string().nullable(),
      })
      .partial()
      .optional(),
    responseSchema: z
      .object({
        name: z.string(),
        email: z.string(),
        name_or_email: z.string(),
      })
      .array(),
  }),
  contactListList: makeEndpoint({
    path: 'users/contact_lists',
    responseSchema: contactListSchema.array(),
  }),
  contactListDetail: makeEndpoint({
    path: 'users/contact_lists/:contactListId',
    responseSchema: contactListSchema,
  }),
  createContactList: makeEndpoint({
    path: 'users/contact_lists',
    method: 'POST',
    bodySchema: z.object({
      name: z.string(),
    }),
    responseSchema: contactListSchema,
  }),
  updateContactList: makeEndpoint({
    path: 'users/contact_lists/:contactListId',
    method: 'PUT',
    bodySchema: z
      .object({
        name: z.string(),
      })
      .partial(),
    responseSchema: contactListSchema,
  }),
  deleteContactList: makeEndpoint({
    path: 'users/contact_lists/:contactListId',
    method: 'DELETE',
    bodySchema: z
      .object({
        name: z.string(),
      })
      .partial(),
    responseSchema: z.object({
      success: z.boolean(),
    }),
  }),
  addContactsToContactList: makeEndpoint({
    path: 'users/contact_lists/:contactListId/add',
    method: 'POST',
    bodySchema: z.object({
      contacts: z.number().array(),
    }),
    responseSchema: z.any(),
  }),
  removeContactsFromContactList: makeEndpoint({
    path: 'users/contact_lists/:contactListId/remove',
    method: 'POST',
    bodySchema: z.object({
      contacts: z.number().array(),
    }),
    responseSchema: z.any(),
  }),
}
