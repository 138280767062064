import React from 'react'
import * as WebUI from '@cheddarup/web-ui'

export interface ClearableAmountInputProps extends WebUI.AmountInputProps {
  onClear?: () => void
}

const ClearableAmountInput = React.forwardRef<
  HTMLInputElement,
  ClearableAmountInputProps
>(({className, onClear, value, onValueChange, ...restProps}, ref) => (
  <div className="relative">
    <WebUI.AmountInput
      ref={ref}
      className={WebUI.cn('pr-8', className)}
      value={value}
      onValueChange={onValueChange}
      {...restProps}
    />
    {value && (
      <WebUI.IconButton
        type="button"
        onClick={() => {
          onClear?.()
          onValueChange?.(undefined)
        }}
        className="-translate-y-1/2 absolute top-1/2 right-2"
      >
        <WebUI.PhosphorIcon icon="x" className="text-ds-lg text-gray400" />
      </WebUI.IconButton>
    )}
  </div>
))

export default ClearableAmountInput
