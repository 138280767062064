import {endpoints} from '../../endpoints'
import {makeUseMutation} from '../use-mutation'

export const useResendReceiptPaymentToOthersMutation = makeUseMutation(
  endpoints.payments.resendReceipt,
)

export const useRetryScheduledPaymentToOthersMutation = makeUseMutation(
  endpoints.payments.retryInvoice,
)
