import {useEffect, useRef} from 'react'

// Adapted from https://github.com/Shopify/quilt/blob/306a827e987370607466a6119829cb0c5cdc9382/packages/react-hooks/src/hooks/mounted-ref.ts
export function useMountedRef() {
  const ref = useRef(true)
  useEffect(() => {
    function cleanup() {
      ref.current = false
    }
    return cleanup
  }, [])
  return ref
}
