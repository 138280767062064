import {useNavigate, useParams} from 'react-router-dom'
import {useFormik} from '@cheddarup/react-util'
import React, {useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {
  PaymentType,
  useSelectedPaymentObject,
} from 'src/components/OrderSummaryLayout'
import {FieldSetList} from 'src/components/FieldSetList'
import {useEditFieldViews} from '@cheddarup/core'

export interface EditAttendeePageProps {
  paymentType?: PaymentType
}

const EditAttendeePage = ({paymentType = 'tab'}: EditAttendeePageProps) => {
  const navigate = useNavigate()
  const urlParams = useParams()
  const [paymentObject] = useSelectedPaymentObject(paymentType)
  const [editFieldViews] = useEditFieldViews()
  const [notifyPayer, setNotifyPayer] = useState(false)

  const tabObject =
    paymentObject?.tab_item ??
    paymentObject?.tab_form ??
    paymentObject?.time_slot
  const fields =
    paymentObject?.tab_item?.fields ??
    paymentObject?.tab_form?.fields ??
    paymentObject?.time_slot?.spot.signup.fields ??
    []

  const formik = useFormik<Record<number, string>>({
    enableReinitialize: true,
    initialValues: Object.fromEntries(
      (paymentObject?.item_field_views ?? []).map(
        (ifv) => [ifv.item_field_id, ifv.value ?? ''] as const,
      ),
    ),
    onSubmit: async (values) => {
      if (!paymentObject) {
        return
      }

      await editFieldViews({
        tabId: Number(urlParams.collection),
        values,
        paymentObject: paymentObject as any,
        notifyPayer,
      })
      navigate('..')
    },
  })

  return (
    <WebUI.Modal
      aria-label="Edit Attendee"
      className="sm:[&_>_.ModalContentView]:w-full sm:[&_>_.ModalContentView]:max-w-[548px]"
      preventBodyScroll
      onDidHide={() => navigate('..')}
    >
      <WebUI.ModalCloseButton />

      <WebUI.ModalHeader variant="compact">Attendee</WebUI.ModalHeader>
      <form
        className="flex flex-col gap-6 px-9 pb-9"
        onSubmit={formik.handleSubmit}
        onReset={formik.handleReset}
      >
        {!!tabObject && (
          <FieldSetList
            key={JSON.stringify(formik.initialValues)}
            className="h-auto flex-0 gap-3 overflow-visible"
            EmptyStateViewComponent={React.Fragment}
            defaultValues={formik.initialValues}
            errors={formik.errors as Record<number, string>}
            fieldSets={
              'fieldSets' in tabObject.options
                ? (tabObject.options.fieldSets ?? undefined)
                : undefined
            }
            fields={fields}
            onValuesChange={(fieldId, value) =>
              formik.setFieldValue(String(fieldId), value)
            }
          />
        )}
        <WebUI.Disclosure visible={formik.dirty}>
          <WebUI.DisclosureContent>
            <WebUI.Checkbox
              name="notifyPayer"
              size="compact"
              checked={notifyPayer}
              onChange={(event) => setNotifyPayer(event.target.checked)}
            >
              Send automatic confirmation email to attendee
            </WebUI.Checkbox>
            <div className="mt-6 flex flex-row gap-5">
              <WebUI.Button type="submit" loading={formik.isSubmitting}>
                Save
              </WebUI.Button>

              <WebUI.Button
                className="text-ds-sm"
                type="reset"
                variant="text"
                disabled={formik.isSubmitting}
                onClick={() => navigate(-1)}
              >
                Cancel
              </WebUI.Button>
            </div>
          </WebUI.DisclosureContent>
        </WebUI.Disclosure>
      </form>
    </WebUI.Modal>
  )
}

export default EditAttendeePage
