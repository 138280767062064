import {BooleanParam, useQueryParam, withDefault} from 'use-query-params'
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import React, {useEffect, useMemo, useRef} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {PageContainer} from 'src/components/PageContainer'
import CartHelpers from 'src/helpers/CartHelpers'
import * as Util from '@cheddarup/util'
import {generalCollectionTracking} from 'src/helpers/analytics'
import {useTimeout} from '@cheddarup/react-util'
import {RoutableTabs} from 'src/components/RoutableTabs'

import {CheckoutElements} from './CheckoutElements'
import {PayerPageHelmet} from './components'
import CheckoutPage from './CheckoutPage'
import CheckoutThankYouPage from './CheckoutThankYouPage'
import CollectionPayerListPage from './CollectionPayerListPage'
import FormViewPage from './FormViewPage'
import ItemViewPage from './ItemViewPage'
import PrePayerTimingPage from './PrePayerTimingPage'
import PrePayerVisitorReportPage from './PrePayerVisitorReportPage'
import useCart from './hooks/useCart'
import usePublicCollection from './hooks/usePublicCollection'
import {CheckoutPageFlowProvider} from './CheckoutPage/CheckoutPageFlowContext'
import NotFoundPage from '../NotFoundPage'
import SignupViewPage from './SignupViewPage'
import {ErrorModal} from './components/ErrorModal'
import {PayerUIStateProvider} from './PayerUIStateProvider'
import {PayerHeader} from './components/PayerHeader'
import {PayerToolbar} from './components/PayerToolbar'
import {PayerBands} from './PayerPage/components/PayerBands'
import PayerItemsPage from './PayerItemsPage'
import {PayerPageFiltersOverviewBar} from './PayerPage/components'
import {
  getPayerSegments,
  useCurrentPayerSegment,
} from './utils/payer-navigation-utils'
import PayerFormsPage from './PayerFormsPage'
import {PayerFooter} from './components/PayerFooter'
import {
  hasMissingRequiredFormFields,
  hasMissingRequiredItems,
  hasMissingRequiredSignups,
} from '@cheddarup/core'
import {getMeRoutes} from 'src/routes/MeRoutes'
import {useInjectThemeCssVars} from './components/PayerStyled'

export interface PayerRouterProps {
  shouldRenderModalCloseButton?: boolean
}

const PayerRouter: React.FC<PayerRouterProps> = ({
  shouldRenderModalCloseButton = false,
}) => {
  useInjectThemeCssVars()

  const urlParams = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const errorModalRef = useRef<WebUI.DialogInstance>(null)
  const {publicCollection} = usePublicCollection()
  const scrollElRef = useRef<HTMLDivElement>(null)
  const currentPayerSegment = useCurrentPayerSegment()

  WebUI.useScrollToTop(
    {
      top: 0,
      left: 0,
      behavior: 'smooth',
    },
    scrollElRef,
    [currentPayerSegment],
  )

  useEffect(() => {
    import('./payer.css')

    return () => {
      import('./unload-payer.css')
    }
  }, [])

  return (
    <EnsurePublicCollection>
      <EnsureUserIsManagerWhenAddPayment>
        <EnsureBeforeUnload>
          <PayerPageHelmet />
          <PayerUIStateProvider
            shouldRenderModalCloseButton={shouldRenderModalCloseButton}
          >
            <Routes>
              <Route
                path="*"
                element={
                  <RoutableTabs
                    elementRef={scrollElRef}
                    className="z-0 grow overflow-y-auto bg-gray100 *:min-h-0 *:flex-0 [&_*]:z-0"
                    variant="underlined"
                  >
                    <PayerHeader
                      className={WebUI.cn(
                        'flex-0 sm:border-b',
                        (publicCollection?.type === 'Template' ||
                          !publicCollection?.published_at) &&
                          '!z-[1] sticky top-0',
                      )}
                    />
                    {currentPayerSegment != null && (
                      <PayerToolbar
                        className={WebUI.cn(
                          publicCollection?.published_at &&
                            publicCollection?.type !== 'Template' &&
                            '!z-[1] sticky top-0',
                        )}
                      />
                    )}
                    <PayerBands />

                    <WebUI.VStack className="relative grow gap-4 sm:gap-8 sm:pt-8">
                      <Routes>
                        <Route
                          path="*"
                          element={
                            <Routes>
                              <Route
                                path=""
                                element={
                                  publicCollection ? (
                                    <Navigate
                                      replace
                                      to={{
                                        pathname: getPayerSegments({
                                          publicCollection,
                                        })[0],
                                        search: location.search,
                                      }}
                                    />
                                  ) : null
                                }
                              />
                              <Route
                                path="items"
                                element={
                                  <EnsurePublicCollectionHasNoBarriers>
                                    <EnsureHasItems>
                                      <PageContainer>
                                        <PayerPageFiltersOverviewBar />
                                        <PayerItemsPage />
                                        <Outlet />
                                      </PageContainer>
                                    </EnsureHasItems>
                                  </EnsurePublicCollectionHasNoBarriers>
                                }
                              >
                                <Route
                                  path="item/:item"
                                  element={
                                    <EnsureItemExists>
                                      <ItemViewPage />
                                    </EnsureItemExists>
                                  }
                                />
                                <Route
                                  path="payers"
                                  element={<CollectionPayerListPage />}
                                />
                                {getMeRoutes()}
                              </Route>
                              <Route
                                path="forms"
                                element={
                                  <EnsurePublicCollectionHasNoBarriers>
                                    <PayerFormsPage />
                                    <Outlet />
                                  </EnsurePublicCollectionHasNoBarriers>
                                }
                              >
                                <Route
                                  path="form/:form"
                                  element={
                                    <EnsureFormExists>
                                      <FormViewPage />
                                    </EnsureFormExists>
                                  }
                                />
                                <Route
                                  path="signups/:signup"
                                  element={
                                    <EnsureSignupExists>
                                      <SignupViewPage />
                                    </EnsureSignupExists>
                                  }
                                />
                                {getMeRoutes()}
                              </Route>
                              <Route
                                path="checkout"
                                element={
                                  <EnsurePublicCollectionHasNoBarriers>
                                    <EnsureRequiredItemsInCart>
                                      <EnsureRequiredFormFieldsFilled>
                                        <EnsurePaymentRequired>
                                          <EnsureCartIsNotEmpty>
                                            <CheckoutPageFlowProvider>
                                              <CheckoutElements>
                                                {({clientSecret}) => (
                                                  <CheckoutPage
                                                    paymentIntentClientSecret={
                                                      clientSecret
                                                    }
                                                    onShouldReload={() =>
                                                      errorModalRef.current?.show()
                                                    }
                                                  />
                                                )}
                                              </CheckoutElements>
                                              <Outlet />
                                            </CheckoutPageFlowProvider>
                                          </EnsureCartIsNotEmpty>
                                        </EnsurePaymentRequired>
                                      </EnsureRequiredFormFieldsFilled>
                                    </EnsureRequiredItemsInCart>
                                  </EnsurePublicCollectionHasNoBarriers>
                                }
                              >
                                <Route
                                  path="item/:item"
                                  element={
                                    <EnsureItemExists>
                                      <ItemViewPage />
                                    </EnsureItemExists>
                                  }
                                />
                                <Route
                                  path="form/:form"
                                  element={
                                    <EnsureFormExists>
                                      <FormViewPage />
                                    </EnsureFormExists>
                                  }
                                />
                                <Route
                                  path="signups/:signup"
                                  element={
                                    <EnsureSignupExists>
                                      <SignupViewPage />
                                    </EnsureSignupExists>
                                  }
                                />
                              </Route>
                            </Routes>
                          }
                        />

                        <Route path="barriers">
                          <Route
                            path="timing"
                            element={
                              <EnsurePublicCollectionHasBarrier>
                                <PrePayerTimingPage />
                                <Outlet />
                              </EnsurePublicCollectionHasBarrier>
                            }
                          >
                            {getMeRoutes()}
                          </Route>
                          <Route
                            path="visitor-report"
                            element={
                              <EnsurePublicCollectionHasBarrier>
                                <PrePayerVisitorReportPage />
                                <Outlet />
                              </EnsurePublicCollectionHasBarrier>
                            }
                          >
                            {getMeRoutes()}
                          </Route>
                        </Route>
                      </Routes>

                      <PayerFooter className="w-full flex-0 lg:mt-32" />
                    </WebUI.VStack>
                  </RoutableTabs>
                }
              />
              <Route
                path="checkout/thank-you"
                element={
                  <>
                    <CheckoutThankYouPage />
                    <Outlet />
                  </>
                }
              >
                {getMeRoutes()}
              </Route>
            </Routes>

            <ErrorModal
              ref={errorModalRef}
              onDidHide={() => {
                if (window.location.pathname.includes('/checkout')) {
                  navigate(`/c/${urlParams.tabSlug}`)
                }
              }}
            />
          </PayerUIStateProvider>
        </EnsureBeforeUnload>
      </EnsureUserIsManagerWhenAddPayment>
    </EnsurePublicCollection>
  )
}

// MARK: – Ensurers

const EnsurePublicCollection: React.FC<{children: React.ReactNode}> = ({
  children,
}) => {
  const {publicCollection, isPending, refetchPublicCollection} =
    usePublicCollection()

  const timingBarrier =
    !!publicCollection &&
    !publicCollection.userManagesCollection &&
    publicCollection.unavailable === 'timing_out_of_range'

  useEffect(() => {
    if (publicCollection) {
      generalCollectionTracking(publicCollection)
    }
  }, [publicCollection])

  const now = useMemo(() => new Date(), [])
  const [openDate, closeDate] = useMemo(() => {
    if (!publicCollection?.timing || !timingBarrier) {
      return [null, null]
    }

    return [
      publicCollection.timing.opens
        ? new Date(publicCollection.timing.opens)
        : null,
      publicCollection.timing.closes
        ? new Date(publicCollection.timing.closes)
        : null,
    ] as const
  }, [publicCollection, timingBarrier])

  useTimeout(
    () => refetchPublicCollection(),
    openDate && openDate > now
      ? Util.differenceInMilliseconds(openDate, now)
      : -1,
  )
  useTimeout(
    () => refetchPublicCollection(),
    closeDate && closeDate > now
      ? Util.differenceInMilliseconds(closeDate, now)
      : -1,
  )

  if (isPending) {
    return <div className="grow" />
  }

  if (!publicCollection) {
    return <NotFoundPage />
  }

  return <>{children}</>
}

const EnsureUserIsManagerWhenAddPayment = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [addPayment, setAddPayment] = useQueryParam('add-payment', BooleanParam)
  const {publicCollection} = usePublicCollection()

  useEffect(() => {
    if (addPayment && !publicCollection.userManagesCollection) {
      setAddPayment(undefined)
    }
  }, [addPayment, publicCollection.userManagesCollection, setAddPayment])

  return <>{children}</>
}

interface EnsureBeforeUnloadProps {
  disabled?: boolean
  children: React.ReactNode
}

const EnsureBeforeUnload: React.FC<EnsureBeforeUnloadProps> = ({
  disabled,
  children,
}) => {
  const {cart} = useCart()

  const isCartPristine =
    !cart ||
    cart.cartPaid ||
    (Object.keys(cart.discounts).length === 0 &&
      cart.forms.length === 0 &&
      cart.items.length === 0 &&
      cart.time_slots.length === 0 &&
      cart.shippingInfo.shippingMethod == null)

  useEffect(() => {
    function handleBeforeunload(event: BeforeUnloadEvent) {
      if (!isCartPristine && !disabled) {
        event.preventDefault()
        event.returnValue = ''
      }
    }

    window.addEventListener('beforeunload', handleBeforeunload)

    return () => window.removeEventListener('beforeunload', handleBeforeunload)
  }, [isCartPristine, disabled])

  return <>{children}</>
}

const EnsurePublicCollectionHasBarrier: React.FC<{
  children: React.ReactNode
}> = ({children}) => {
  const location = useLocation()
  const {publicCollection} = usePublicCollection()
  const [addPayment] = useQueryParam('add-payment', BooleanParam)

  const timingBarrier =
    !publicCollection.userManagesCollection &&
    publicCollection.unavailable === 'timing_out_of_range'

  const visitorReportBarrier =
    !publicCollection.userManagesCollection &&
    !!publicCollection.fields_required &&
    publicCollection.fields_required.length > 0

  if (!visitorReportBarrier && !timingBarrier && !addPayment) {
    return (
      <Navigate
        replace
        to={{
          pathname: '../../',
          search: location.search,
        }}
      />
    )
  }

  return <>{children}</>
}

const EnsurePublicCollectionHasNoBarriers: React.FC<{
  children: React.ReactNode
}> = ({children}) => {
  const location = useLocation()
  const [addPayment] = useQueryParam(
    'add-payment',
    withDefault(BooleanParam, false),
  )
  const [preview, setPreview] = useQueryParam(
    'preview',
    withDefault(BooleanParam, false),
  )
  const {publicCollection} = usePublicCollection()
  const {cart, isLoading: isCartLoading} = useCart()

  const timingBarrier =
    !publicCollection.userManagesCollection &&
    publicCollection.unavailable === 'timing_out_of_range'

  useEffect(() => {
    if (preview && !timingBarrier) {
      setPreview(undefined)
    }
  }, [preview, setPreview, timingBarrier])

  const visitorReportBarrier =
    !publicCollection.userManagesCollection &&
    !!publicCollection.fields_required &&
    publicCollection.fields_required.length > 0

  if (!preview && timingBarrier) {
    return (
      <Navigate
        replace
        to={{
          pathname: '../barriers/timing',
          search: location.search,
        }}
      />
    )
  }

  if (visitorReportBarrier || (addPayment && !isCartLoading && !cart?.member)) {
    return (
      <Navigate
        replace
        to={{
          pathname: '../barriers/visitor-report',
          search: location.search,
        }}
      />
    )
  }

  return <>{children}</>
}

const EnsureHasItems = ({children}: {children: React.ReactNode}) => {
  const location = useLocation()
  const {publicCollection} = usePublicCollection()
  if (
    publicCollection &&
    publicCollection.items.length === 0 &&
    (publicCollection.forms.some((f) => f.fields.length > 0) ||
      publicCollection.signups.some((s) => s.visible_spots.length > 0))
  ) {
    const pathSegments = location.pathname.split('/')
    const itemsIndex = pathSegments.indexOf('items')

    // Replace 'items' with 'forms' and keep the rest of the path
    if (itemsIndex !== -1) {
      pathSegments[itemsIndex] = 'forms'
    }
    const newPathname = pathSegments.join('/')

    return (
      <Navigate
        replace
        to={{
          pathname: newPathname,
          search: location.search,
        }}
      />
    )
  }
  return <>{children}</>
}

const EnsureItemExists = ({children}: {children: React.ReactNode}) => {
  const location = useLocation()
  const urlParams = useParams()
  const {publicCollection} = usePublicCollection()

  const itemViewId = Number(urlParams.item)
  const itemViewExists = publicCollection.items.some((i) => i.id === itemViewId)

  if (!itemViewExists) {
    return (
      <Navigate
        replace
        to={{
          pathname: '..',
          search: location.search,
        }}
      />
    )
  }
  return <>{children}</>
}

const EnsureFormExists = ({children}: {children: React.ReactNode}) => {
  const location = useLocation()
  const urlParams = useParams()
  const {publicCollection} = usePublicCollection()

  const formViewId = Number(urlParams.form)
  const formViewExists = publicCollection.forms.some((f) => f.id === formViewId)

  if (!formViewExists) {
    return (
      <Navigate
        replace
        to={{pathname: `../signups/${formViewId}`, search: location.search}}
      />
    )
  }
  return <>{children}</>
}

const EnsureSignupExists: React.FC<{children: React.ReactNode}> = ({
  children,
}) => {
  const location = useLocation()
  const urlParams = useParams()
  const {publicCollection} = usePublicCollection()

  const signupId = Number(urlParams.signup)
  const signupExists = publicCollection.signups.some((s) => s.id === signupId)

  if (!signupExists) {
    return (
      <Navigate replace to={{pathname: '../..', search: location.search}} />
    )
  }
  return <>{children}</>
}

const EnsureRequiredItemsInCart: React.FC<{children: React.ReactNode}> = ({
  children,
}) => {
  const location = useLocation()
  const {publicCollection} = usePublicCollection()
  const {cart} = useCart()

  if (
    publicCollection &&
    hasMissingRequiredItems({publicTab: publicCollection, cart})
  ) {
    return (
      <Navigate replace to={{pathname: '../items', search: location.search}} />
    )
  }

  return <>{children}</>
}

const EnsureRequiredFormFieldsFilled: React.FC<{children: React.ReactNode}> = ({
  children,
}) => {
  const location = useLocation()
  const {publicCollection} = usePublicCollection()
  const {cart} = useCart()

  if (
    cart &&
    publicCollection &&
    hasMissingRequiredItems({publicTab: publicCollection, cart})
  ) {
    return <Navigate replace to={{pathname: '..', search: location.search}} />
  }

  if (
    cart &&
    publicCollection &&
    (hasMissingRequiredFormFields({publicTab: publicCollection, cart}) ||
      hasMissingRequiredSignups({publicTab: publicCollection, cart}))
  ) {
    return (
      <Navigate
        replace
        to={{
          pathname: '../forms',
          search: location.search,
        }}
      />
    )
  }

  return <>{children}</>
}

const EnsureCartIsNotEmpty: React.FC<{children: React.ReactNode}> = ({
  children,
}) => {
  const location = useLocation()
  const {cart, isPending} = useCart()

  if (
    !isPending &&
    (!cart ||
      (cart.items.length === 0 &&
        cart.forms.length === 0 &&
        cart.time_slots.length === 0))
  ) {
    return (
      <Navigate replace to={{pathname: '../items', search: location.search}} />
    )
  }

  if (!cart) {
    return (
      <div className="flex h-screen min-h-0 min-w-0 shrink-0 grow basis-auto items-center justify-center">
        <WebUI.Loader />
      </div>
    )
  }

  return <>{children}</>
}

const EnsurePaymentRequired: React.FC<{children: React.ReactNode}> = ({
  children,
}) => {
  const location = useLocation()
  const {publicCollection} = usePublicCollection()
  const {cart, isPending} = useCart()

  if (
    publicCollection &&
    !isPending &&
    (!cart ||
      (publicCollection.requirePayment &&
        !CartHelpers.hasNonZeroAmountItems(cart)))
  ) {
    return (
      <Navigate replace to={{pathname: '../items', search: location.search}} />
    )
  }

  return <>{children}</>
}

export default PayerRouter
