import React from 'react'
import {Banner} from 'src/components/Banner'
import {MatchQueryParams} from 'src/components/WithQueryParams'

import useCart from '../hooks/useCart'

// MARK: – AddPaymentItemViewsBanner

export const AddPaymentItemViewsBanner: React.FC<
  React.ComponentPropsWithoutRef<'div'>
> = (props) => {
  const {cart} = useCart()

  if (!cart?.member?.name) {
    return null
  }

  return (
    <MatchQueryParams queryParams={{'add-payment': true}}>
      <Banner {...props}>
        Add items to the order on behalf of{' '}
        <span className="font-semibold">{cart?.member?.name}</span>
      </Banner>
    </MatchQueryParams>
  )
}

// MARK: – AddPaymentFormViewsBanner

export const AddPaymentFormViewsBanner: React.FC<
  React.ComponentPropsWithoutRef<'div'>
> = (props) => {
  const {cart} = useCart()

  if (!cart?.member?.name) {
    return null
  }

  return (
    <MatchQueryParams queryParams={{'add-payment': true}}>
      <Banner {...props}>
        Add forms to the order on behalf of{' '}
        <span className="font-semibold">{cart.member.name}</span>
      </Banner>
    </MatchQueryParams>
  )
}
