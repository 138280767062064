// @ts-ignore

import {z} from '@cheddarup/util'

import {cubeQuerySchema} from '../utils/zod-cube'

export const cubeReportTypeSchema = z.enum([
  'payments',
  'payers',
  'visitors',
  'withdrawals',
])

export const cubeReportDetailSchema = z.object({
  name: z.string(),
  reportType: cubeReportTypeSchema,
})

export const cubeReportSchema = z.object({
  id: z.number(),
  uuid: z.string(),
  user_id: z.number(),
  created_at: z.string(),
  updated_at: z.string(),
  deleted_at: z.string().nullish(),
  count_query: cubeQuerySchema,
  detail: cubeReportDetailSchema,
  export_query: cubeQuerySchema,
})

export const cubeExportDetailSchema = z.object({
  token: z.string(),
})

export const cubeExportStatusSchema = z.enum(['queued', 'complete'])

export const cubeExportSchema = z.object({
  count_query: cubeQuerySchema,
  created_at: z.string(),
  current_offset: z.number(),
  deleted_at: z.string().nullish(),
  detail: cubeExportDetailSchema,
  export_query: cubeQuerySchema,
  id: z.number(),
  status: cubeExportStatusSchema,
  total_records: z.number().nullish(),
  updated_at: z.string(),
  upload_path: z.string().nullish(),
  user_id: z.number(),
  uuid: z.string().nullish(),
})

declare global {
  namespace Api {
    // @ts-ignore
    type CubeExport = z.infer<typeof cubeExportSchema>
    // @ts-ignore
    type CubeExportStatus = z.infer<typeof cubeExportStatusSchema>
    // @ts-ignore
    type CubeExportDetail = z.infer<typeof cubeExportDetailSchema>
    // @ts-ignore
    type CubeReport = z.infer<typeof cubeReportSchema>
    // @ts-ignore
    type CubeReportDetail = z.infer<typeof cubeReportDetailSchema>
    // @ts-ignore
    type CubeReportType = z.infer<typeof cubeReportTypeSchema>
    // @ts-ignore
    type CubeQuery = z.infer<typeof cubeQuerySchema>
  }
}
