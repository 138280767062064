import * as WebUI from '@cheddarup/web-ui'

export interface GroupPageSpotlightItemCardProps {
  item: Api.GroupPageCollectionSpotlightItem
}

const GroupPageSpotlightItemCard: React.FC<GroupPageSpotlightItemCardProps> = ({
  item,
}) => (
  <WebUI.Panel className="flex w-[280px] flex-col gap-2 p-4 sm:gap-4 sm:p-5">
    <WebUI.Text className="line-clamp-1 font-bold" title={item.headline}>
      {item.headline}
    </WebUI.Text>
    <WebUI.Text className="line-clamp-2 text-ds-sm">
      {item.description}
    </WebUI.Text>
    <WebUI.Text className="text-[13px] text-gray400">
      {item.collection.name}
    </WebUI.Text>
  </WebUI.Panel>
)

export default GroupPageSpotlightItemCard
