import React, {useMemo, useState} from 'react'
import {StringParam, useQueryParam, withDefault} from 'use-query-params'
import {useParams} from 'react-router-dom'
import {getTimeSlotFieldViewsFormatted} from 'src/helpers/parseTimeSlotFieldViews'
import * as WebUI from '@cheddarup/web-ui'
import {api, useDeletePaymentItemMutation} from '@cheddarup/api-client'
import * as Util from '@cheddarup/util'
import {Link} from 'src/components/Link'
import {SharpAvatar} from 'src/components/SharpAvatar'

interface SignupSpotsByDateDatum {
  id: string
  timeSlots: Array<Api.TimeSlot & {spot: Api.SignUpSpot}>
}

interface SignupFieldViewsListProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collectionId: number
}

const SignupFieldViewsList: React.FC<SignupFieldViewsListProps> = ({
  collectionId,
  className,
  ...restProps
}) => {
  const urlParams = useParams()
  const signupId = Number(urlParams.signup)
  const [viewBy] = useQueryParam('viewBy', withDefault(StringParam, 'spot'))
  const signupQuery = api.tabSignups.detail.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: urlParams.collection!,
      // biome-ignore lint/style/noNonNullAssertion:
      signupId: signupId!,
    },
  })

  const spotsData = useMemo(
    () =>
      Util.sort(signupQuery.data?.spots ?? [])
        .asc((s) => s.position)
        .map((spot) => ({
          ...spot,
          time_slots: Util.sort(spot.time_slots).asc(
            (ts) => ts.options.startTime,
          ),
        })),
    [signupQuery.data?.spots],
  )

  const filteredSpots = useMemo(
    () => spotsData.filter((s) => s.time_slots.length > 0),
    [spotsData],
  )
  const spotsByDate = useMemo(() => {
    const timeSlots = spotsData
      .flatMap((s) => s.time_slots.map((ts) => ({...ts, spot: s})))
      .filter((ts) => !!ts.options.startTime)

    const timeSlotsByDate = Util.groupBy(timeSlots, (ts) =>
      new Date(ts.options.startTime ?? '').toDateString(),
    )

    return Util.sort(
      Util.arrayFromObject(timeSlotsByDate, (dateString, tss) => ({
        id: dateString,
        timeSlots: tss,
      })),
    ).asc((ts) => new Date(ts.id))
  }, [spotsData])

  return (
    <WebUI.VStack className={WebUI.cn('gap-5', className)} {...restProps}>
      {signupQuery.data?.options.signupType === 'list' ? (
        <ListSignupList spots={signupQuery.data.spots} />
      ) : filteredSpots.length > 0 ? (
        viewBy === 'spot' ? (
          filteredSpots.map((data, idx) => (
            <ScheduleSignupSpotListRow
              key={String(idx)}
              heading={data.name}
              timeSlots={data.time_slots}
            />
          ))
        ) : (
          spotsByDate.map((data, idx) => (
            <ScheduleSignupDateListRow
              key={String(idx)}
              heading={data.id}
              data={data}
            />
          ))
        )
      ) : (
        <WebUI.VStack
          className="items-center justify-center p-8"
          as={WebUI.Panel}
        >
          You don't have any responses
        </WebUI.VStack>
      )}
    </WebUI.VStack>
  )
}

// MARK: - SignupSpotListRow

interface ScheduleSignupSpotListRowProps
  extends React.ComponentPropsWithoutRef<'div'> {
  heading: string
  timeSlots: Api.TimeSlot[]
}

const ScheduleSignupSpotListRow: React.FC<ScheduleSignupSpotListRowProps> = ({
  heading,
  timeSlots,
  className,
  ...restProps
}) => {
  const urlParams = useParams()
  const selectedSignUpId = Number(urlParams.signup)
  const [viewBy] = useQueryParam('viewBy', withDefault(StringParam, 'spot'))

  const columnHelper = useMemo(
    () => WebUI.createColumnHelper<Api.TimeSlot>(),
    [],
  )

  const columns = useMemo(
    () => [
      columnHelper.accessor(
        (f) => Util.formatDate(f.options?.startTime ?? '', 'EEE, MMM d yyyy'),
        {
          id: 'date',
          meta: {
            subtle: false,
          },
          header: 'Date',
          cell: (cellProps) =>
            'isRowSpanned' in cellProps.cell && cellProps.cell.isRowSpanned
              ? null
              : cellProps.cell.getValue(),
        },
      ),
      columnHelper.display({
        id: 'time',
        header: 'Time',
        cell: ({row: {original: f}}) => (
          <div>
            {Util.formatDate(f.options?.startTime ?? '', 'hh:mm aaa')} -{' '}
            {Util.formatDate(f.options?.endTime ?? '', 'hh:mm aaa')}
          </div>
        ),
      }),
      columnHelper.display({
        id: 'respondents',
        size: 280,
        header: 'Respondents',
        cell: ({row: {original: f}}) => (
          <WebUI.AvatarGroup<{
            paymentId: number
            paymentItemId: number
            name: string
            comment: string
            quantity: number
          }>
            popoverContentClassName="[&_>_.PopoverContent-inner_>_.PopoverContent-body]:bg-natural-100"
            AvatarComp={SharpAvatar}
            popoverShrinkable={false}
            data={Util.arrayFromObject(
              Util.mapToObj(f.payment_items, (ppi) => [ppi.id, ppi]),
              (piId, ppi) => {
                const timeSlotFieldsFormatted = getTimeSlotFieldViewsFormatted(
                  ppi.item_field_views,
                )
                return {
                  paymentId: ppi.payment_id,
                  paymentItemId: Number(piId),
                  name: timeSlotFieldsFormatted.name,
                  comment: timeSlotFieldsFormatted.comment,
                  quantity: ppi.quantity,
                }
              },
            )}
            renderSummary={(di, _idx) => (
              <WebUI.HStack className="items-center gap-2">
                <SignupDeleteParticipantAlert
                  paymentId={di.paymentId}
                  paymentItemId={di.paymentItemId}
                  signupId={selectedSignUpId}
                  disclosure={
                    <WebUI.DeprecatedTooltip label="Delete respondent">
                      <WebUI.Action
                        className="text-ds-base text-gray400"
                        labelVisible={false}
                        tooltipVisible={false}
                        icon={<WebUI.PhosphorIcon icon="x-circle-fill" />}
                        as={WebUI.DialogDisclosure}
                      />
                    </WebUI.DeprecatedTooltip>
                  }
                />

                <WebUI.VStack className="gap-1">
                  <WebUI.Text className="text-ds-sm leading-[24px]">
                    {di.name} ({di.quantity})
                  </WebUI.Text>
                  {!!di.comment && (
                    <WebUI.Text className="font-light text-ds-xs">
                      {di.comment}
                    </WebUI.Text>
                  )}
                </WebUI.VStack>
              </WebUI.HStack>
            )}
          />
        ),
      }),
      columnHelper.accessor((f) => f.active_participant_count ?? 0, {
        id: 'totalSpots',
        size: 80,
        meta: {
          align: 'right',
        },
        header: 'Filled Spots',
      }),
      columnHelper.accessor(
        (f) =>
          f.available_quantity === 0
            ? 'Full'
            : (f.available_quantity ?? 'Unlimited'),
        {
          id: 'availableSpots',
          size: 100,
          meta: {
            align: 'right',
          },
          header: 'Available Spots',
        },
      ),
    ],
    [selectedSignUpId, columnHelper],
  )

  return (
    <WebUI.Panel
      className={WebUI.cn('gap-4 px-8 py-6 text-gray800', className)}
      as={WebUI.VStack}
      {...restProps}
    >
      <WebUI.VStack className="gap-1">
        <WebUI.HStack className="justify-between">
          <WebUI.Heading as="h4">{heading}</WebUI.Heading>
          <WebUI.DeprecatedTooltip label="Download Responses (.pdf)">
            <WebUI.IconButton
              as={Link}
              variant="secondary"
              to={`/pdf/collection/${urlParams.collection}/signup/${selectedSignUpId}?viewBy=${viewBy}`}
              target="_blank"
            >
              <WebUI.PhosphorIcon icon="printer" width={20} />
            </WebUI.IconButton>
          </WebUI.DeprecatedTooltip>
        </WebUI.HStack>

        <WebUI.TableView
          className={
            '[&_.TableView-body]:text-ds-sm [&_.TableView-headerText]:px-0 [&_.TableViewCell]:px-0'
          }
          data={timeSlots}
          columns={columns}
          getRowProps={WebUI.getSpannedRowHelpers}
        />
      </WebUI.VStack>
    </WebUI.Panel>
  )
}

// MARK: - SignupDateListRow

interface ScheduleSignupDateListRowProps
  extends React.ComponentPropsWithoutRef<'div'> {
  heading: string
  data: SignupSpotsByDateDatum
}

const ScheduleSignupDateListRow: React.FC<ScheduleSignupDateListRowProps> = ({
  heading,
  data,
  className,
  ...restProps
}) => {
  const urlParams = useParams()
  const selectedSignUpId = Number(urlParams.signup)
  const [viewBy] = useQueryParam('viewBy', withDefault(StringParam, 'date'))

  type Datum = (typeof data.timeSlots)[number]
  const columnHelper = useMemo(() => WebUI.createColumnHelper<Datum>(), [])

  const columns = useMemo(
    () => [
      columnHelper.accessor((f) => f.spot.name, {
        id: 'spot',
        meta: {
          subtle: false,
        },
        header: 'Spot',
        cell: ({cell}) =>
          'isRowSpanned' in cell && cell.isRowSpanned ? null : cell.getValue(),
      }),
      columnHelper.display({
        id: 'time',
        header: 'Time',
        cell: ({row: {original: f}}) => (
          <div>
            {Util.formatDate(f.options?.startTime ?? '', 'hh:mm aaa')} -{' '}
            {Util.formatDate(f.options?.endTime ?? '', 'hh:mm aaa')}
          </div>
        ),
      }),
      columnHelper.display({
        id: 'respondents',
        size: 280,
        header: 'Respondents',
        cell: ({row: {original: f}}) => (
          <WebUI.AvatarGroup<{
            paymentId: number
            paymentItemId: number
            name: string
            comment: string
            quantity: number
          }>
            popoverContentClassName="[&_>_.PopoverContent-inner_>_.PopoverContent-body]:bg-natural-100"
            AvatarComp={SharpAvatar}
            popoverShrinkable={false}
            data={Util.arrayFromObject(
              Util.mapToObj(f.payment_items, (ppi) => [ppi.id, ppi]),
              (piId, ppi) => {
                const timeSlotFieldsFormatted = getTimeSlotFieldViewsFormatted(
                  ppi.item_field_views,
                )
                return {
                  paymentId: ppi.payment_id,
                  paymentItemId: Number(piId),
                  name: timeSlotFieldsFormatted.name,
                  comment: timeSlotFieldsFormatted.comment,
                  quantity: ppi.quantity,
                }
              },
            )}
            renderSummary={(di, _idx) => (
              <WebUI.HStack className="items-center gap-2">
                <SignupDeleteParticipantAlert
                  paymentId={di.paymentId}
                  paymentItemId={di.paymentItemId}
                  signupId={selectedSignUpId}
                  disclosure={
                    <WebUI.DeprecatedTooltip label="Delete respondent">
                      <WebUI.Action
                        className="text-ds-base text-gray400"
                        labelVisible={false}
                        tooltipVisible={false}
                        icon={<WebUI.PhosphorIcon icon="x-circle-fill" />}
                        as={WebUI.DialogDisclosure}
                      />
                    </WebUI.DeprecatedTooltip>
                  }
                />

                <WebUI.VStack className="gap-1">
                  <WebUI.Text className="text-ds-sm leading-[24px]">
                    {di.name} ({di.quantity})
                  </WebUI.Text>
                  {!!di.comment && (
                    <WebUI.Text className="font-light text-ds-xs">
                      {di.comment}
                    </WebUI.Text>
                  )}
                </WebUI.VStack>
              </WebUI.HStack>
            )}
          />
        ),
      }),
      columnHelper.accessor((f) => f.active_participant_count ?? 0, {
        id: 'totalSpots',
        size: 80,
        meta: {
          align: 'right',
        },
        header: 'Filled Spots',
      }),
      columnHelper.accessor(
        (f) =>
          f.available_quantity === 0
            ? 'Full'
            : (f.available_quantity ?? 'Unlimited'),
        {
          id: 'availableSpots',
          size: 100,
          meta: {
            align: 'right',
          },
          header: 'Available Spots',
        },
      ),
    ],
    [selectedSignUpId, columnHelper],
  )

  return (
    <WebUI.Panel
      className={WebUI.cn('gap-4 px-8 py-6 text-gray800', className)}
      as={WebUI.VStack}
      {...restProps}
    >
      <WebUI.VStack className="gap-1">
        <WebUI.HStack className="justify-between">
          <WebUI.Heading as="h4">{heading}</WebUI.Heading>
          <WebUI.DeprecatedTooltip label="Download Responses (.pdf)">
            <WebUI.IconButton
              as={Link}
              variant="secondary"
              to={`/pdf/collection/${urlParams.collection}/signup/${selectedSignUpId}?viewBy=${viewBy}`}
              target="_blank"
            >
              <WebUI.PhosphorIcon icon="printer" width={20} />
            </WebUI.IconButton>
          </WebUI.DeprecatedTooltip>
        </WebUI.HStack>

        <WebUI.TableView
          className={
            '[&_.TableView-body]:text-ds-sm [&_.TableView-headerText]:px-0 [&_.TableViewCell]:px-0'
          }
          data={data.timeSlots}
          columns={columns}
          getRowProps={WebUI.getSpannedRowHelpers}
        />
      </WebUI.VStack>
    </WebUI.Panel>
  )
}

// MARK: - SignupSpotListRow

interface ListSignupListProps extends React.ComponentPropsWithoutRef<'div'> {
  spots: Api.SignUpSpot[]
}

const ListSignupList: React.FC<ListSignupListProps> = ({
  spots,
  className,
  ...restProps
}) => {
  const urlParams = useParams()
  const selectedSignUpId = Number(urlParams.signup)
  const [viewBy] = useQueryParam('viewBy', withDefault(StringParam, 'spot'))

  const columnHelper = useMemo(
    () => WebUI.createColumnHelper<Api.SignUpSpot>(),
    [],
  )

  const columns = useMemo(
    () => [
      columnHelper.accessor((f) => f.name, {
        id: 'spot',
        meta: {
          subtle: false,
        },
        header: 'Spot',
      }),
      columnHelper.display({
        id: 'respondents',
        size: 280,
        header: 'Respondents',
        cell: ({row: {original: f}}) => (
          <WebUI.AvatarGroup<{
            paymentId: number
            paymentItemId: number
            name: string
            comment: string
          }>
            popoverContentClassName="[&_>_.PopoverContent-inner_>_.PopoverContent-body]:bg-natural-100"
            AvatarComp={SharpAvatar}
            popoverShrinkable={false}
            data={Util.arrayFromObject(
              Util.mapToObj(f.time_slots?.[0]?.payment_items ?? [], (ppi) => [
                ppi.id,
                ppi,
              ]),
              (piId, ppi) => {
                const timeSlotFieldsFormatted = getTimeSlotFieldViewsFormatted(
                  ppi.item_field_views,
                )
                return {
                  paymentId: ppi.payment_id,
                  paymentItemId: Number(piId),
                  name: timeSlotFieldsFormatted.name,
                  comment: timeSlotFieldsFormatted.comment,
                }
              },
            )}
            renderSummary={(di, _idx) => (
              <WebUI.HStack className="items-center gap-2">
                <SignupDeleteParticipantAlert
                  paymentId={di.paymentId}
                  paymentItemId={di.paymentItemId}
                  signupId={selectedSignUpId}
                  disclosure={
                    <WebUI.DeprecatedTooltip label="Delete respondent">
                      <WebUI.Action
                        className="text-ds-base text-gray400"
                        labelVisible={false}
                        tooltipVisible={false}
                        icon={<WebUI.PhosphorIcon icon="x-circle-fill" />}
                        as={WebUI.DialogDisclosure}
                      />
                    </WebUI.DeprecatedTooltip>
                  }
                />

                <WebUI.VStack className="gap-1">
                  <WebUI.Text className="text-ds-sm leading-[24px]">
                    {di.name}
                  </WebUI.Text>
                  {!!di.comment && (
                    <WebUI.Text className="font-light text-ds-xs">
                      {di.comment}
                    </WebUI.Text>
                  )}
                </WebUI.VStack>
              </WebUI.HStack>
            )}
          />
        ),
      }),
      columnHelper.accessor(
        (f) =>
          (f?.available_quantity ?? 0) + (f?.active_participant_count ?? 0),
        {
          id: 'totalSpots',
          size: 80,
          meta: {
            align: 'right',
          },
          header: 'Total Spots',
        },
      ),
      columnHelper.accessor(
        (f) =>
          f?.available_quantity === 0
            ? 'Full'
            : (f?.available_quantity ?? 'Unlimited'),
        {
          id: 'availableSpots',
          size: 100,
          meta: {
            align: 'right',
          },
          header: 'Available Spots',
        },
      ),
    ],
    [selectedSignUpId, columnHelper],
  )

  return (
    <WebUI.Panel
      className={WebUI.cn('gap-4 px-8 py-6 text-gray800', className)}
      as={WebUI.VStack}
      {...restProps}
    >
      <WebUI.VStack className="gap-1">
        <WebUI.HStack className="justify-between">
          <WebUI.Heading as="h4">Sign Ups</WebUI.Heading>
          <WebUI.DeprecatedTooltip label="Download Responses (.pdf)">
            <WebUI.IconButton
              as={Link}
              variant="secondary"
              to={`/pdf/collection/${urlParams.collection}/signup/${selectedSignUpId}?viewBy=${viewBy}`}
              target="_blank"
            >
              <WebUI.PhosphorIcon icon="printer" width={20} />
            </WebUI.IconButton>
          </WebUI.DeprecatedTooltip>
        </WebUI.HStack>

        <WebUI.TableView
          className={
            '[&_.TableView-body]:text-ds-sm [&_.TableView-headerText]:px-0 [&_.TableViewCell]:px-0'
          }
          data={spots}
          columns={columns}
        />
      </WebUI.VStack>
    </WebUI.Panel>
  )
}

// MARK: – SignupDeleteParticipantAlert

interface SignupDeleteParticipantAlertProps extends WebUI.AlertProps {
  paymentId: number
  paymentItemId: number
  signupId: number
}

const SignupDeleteParticipantAlert = React.forwardRef<
  WebUI.DialogInstance,
  SignupDeleteParticipantAlertProps
>(({paymentId, paymentItemId, signupId, ...restProps}, forwardedRef) => {
  const urlParams = useParams()
  const deletePaymentItemMutation = useDeletePaymentItemMutation()
  const [sendEmail, setSendEmail] = useState(true)

  return (
    <WebUI.Alert
      aria-label="Delete signup participant confirmation"
      ref={forwardedRef}
      {...restProps}
    >
      <WebUI.AlertHeader>Are you sure?</WebUI.AlertHeader>
      <WebUI.AlertContentView
        text={
          <WebUI.VStack className="gap-4">
            <span>
              Are you sure you want to remove this individual from this sign up?
              This action cannot be undone.
            </span>
            <WebUI.Checkbox
              size="compact"
              state={sendEmail}
              onChange={(event) => setSendEmail(event.target.checked)}
            >
              Send the participant an automatic email that signup has been
              deleted
            </WebUI.Checkbox>
          </WebUI.VStack>
        }
        actions={
          <>
            <WebUI.AlertActionButton
              execute={() =>
                deletePaymentItemMutation.mutateAsync({
                  pathParams: {
                    // biome-ignore lint/style/noNonNullAssertion:
                    tabId: urlParams.collection!,
                    paymentId,
                    paymentItemId,
                  },
                  signupId,
                  queryParams: {
                    sendEmail,
                  },
                })
              }
            >
              Delete
            </WebUI.AlertActionButton>
            <WebUI.AlertCancelButton />
          </>
        }
      />
    </WebUI.Alert>
  )
})

export default SignupFieldViewsList
