import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'

import {api} from '@cheddarup/api-client'
import {uploadImage} from '@cheddarup/core'

export function getItemType(item: Api.TabItem): Api.ItemType {
  if (item.options.itemSubType && item.amount_type !== 'open') {
    return item.options.itemSubType
  }
  if (item.options.recurring?.enabled) {
    return 'recurring'
  }

  return (
    {
      open: 'donation',
      fixed: 'fixed',
    } as const
  )[item.amount_type]
}

export const itemTypes: Api.ItemType[] = [
  'fixed',
  'donation',
  'recurring',
  'ticket',
]

export function parseItemType(candidate: string) {
  return itemTypes.find((it) => it === candidate) ?? 'fixed'
}

export interface LocalItemImage {
  id: number | string // numbers are API ids, strings are local
  contentType: string
  thumbnailCrop: Api.CropDetails | null
  image: Blob
}

export interface ListingLocalImage {
  uuid: string
  imageId: number | null
  localImage: {
    image: File
    thumbnail: {
      cropDetails: Api.CropDetails | null
    }
  } | null
}

export async function saveItemImages({
  tabId,
  item,
  images,
  listings = [],
}: {
  tabId: number
  item: Api.TabItem
  images: LocalItemImage[]
  listings?: ListingLocalImage[]
}) {
  const imagesToDelete = Util.differenceWith(
    item.images ?? [],
    images,
    (a, b) => a.id === b.id,
  )

  const orderedImages = images.map((valueImage, idx) => ({
    ...valueImage,
    order: idx,
  }))
  const imagesToUpload = orderedImages.filter(
    (image) => typeof image.id === 'string',
  )
  const imagesToUpdate = orderedImages.filter(
    (image) => typeof image.id === 'number',
  )

  const deleteImagePromises = imagesToDelete.map((image) =>
    api.fileUploads.deleteImageRecord.fetch({
      pathParams: {
        parentPath: `users/tabs/${tabId}/items/${item.id}`,
        imageId: image.id,
      },
    }),
  )
  const uploadImagePromises = imagesToUpload.map((image) =>
    uploadImage(
      `users/tabs/${tabId}/items/${item.id}`,
      image.image,
      undefined,
      {
        metadata: {
          thumbnail: {
            order: image.order,
            cropDetails: image.thumbnailCrop ?? {},
          },
        },
      },
    ),
  )
  const updateImagePromises = imagesToUpdate.map((image) =>
    api.fileUploads.updateImageRecord.fetch({
      pathParams: {
        parentPath: `users/tabs/${tabId}/items/${item.id}`,
        imageId: image.id,
      },
      body: {
        metadata: {
          thumbnail: {
            order: image.order,
            cropDetails: image.thumbnailCrop ?? {},
          },
        },
      },
    }),
  )

  await Promise.all(deleteImagePromises)
  const uploadedImages = await Promise.all([
    ...uploadImagePromises,
    ...updateImagePromises,
  ])

  const valuesListingsImages = listings.reduce<
    Array<[string[], NonNullable<ListingLocalImage['localImage']>]>
  >((acc, listing) => {
    if (
      !listing.localImage ||
      acc.some(([uuids]) => uuids.includes(listing.uuid))
    ) {
      return acc
    }
    const uuidsWithSameLocalImage = listings
      .filter(
        (l) =>
          l.localImage &&
          listing.localImage &&
          `${WebUI.getFileId(l.localImage.image)}-${Util.sort(
            Object.values(l.localImage.thumbnail?.cropDetails ?? {}),
          ).asc()}` ===
            `${WebUI.getFileId(listing.localImage.image)}-${Util.sort(
              Object.values(listing.localImage.thumbnail?.cropDetails ?? {}),
            ).asc()}`,
      )
      .map((l) => l.uuid)
    return [...acc, [uuidsWithSameLocalImage, listing.localImage]]
  }, [])

  const uploadListingImagePromises = valuesListingsImages.map(
    ([, image], idx) =>
      uploadImage(
        `users/tabs/${tabId}/items/${item.id}`,
        image.image,
        undefined,
        {
          metadata: {
            thumbnail: {
              order: images.length + idx,
              cropDetails: image.thumbnail.cropDetails ?? {},
            },
          },
        },
      ),
  )
  const uploadedListingImages = await Promise.all(uploadListingImagePromises)
  const uploadedListingsImageIdsEntries = valuesListingsImages.map(
    ([listingUuids], idx) =>
      // biome-ignore lint/style/noNonNullAssertion:
      [listingUuids, uploadedListingImages[idx]!.id] as [string[], number],
  )
  const updatedListingImageIdsEntries = imagesToUpload
    .map((image, idx) => {
      const listing = listings.find((l) => l.imageId === image.id)

      return listing
        ? // biome-ignore lint/style/noNonNullAssertion:
          ([[listing.uuid], uploadedImages[idx]!.id] as [[string], number])
        : null
    })
    .filter((image) => !!image)

  return {
    updatedListingImageIdsEntries,
    uploadedListingsImageIdsEntries,
  }
}
