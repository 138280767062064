import {useCloseTabMutation} from '@cheddarup/api-client'
import * as WebUI from '@cheddarup/web-ui'

interface CloseCollectionAlertProps extends WebUI.AlertProps {
  collectionId: number
}

export const CloseCollectionAlert: React.FC<CloseCollectionAlertProps> = ({
  collectionId,
  ...restProps
}) => {
  const closeCollectionMutation = useCloseTabMutation()
  return (
    <WebUI.Alert aria-label="Close collection confirmation" {...restProps}>
      <WebUI.AlertHeader>Close Collection?</WebUI.AlertHeader>
      <WebUI.AlertContentView
        text="Are you sure you'd like to close this collection and stop accepting payments? Please note, any scheduled recurring payments will continue."
        actions={
          <>
            <WebUI.AlertActionButton
              execute={() =>
                closeCollectionMutation.mutateAsync({
                  pathParams: {
                    tabId: collectionId,
                  },
                })
              }
            >
              Close collection
            </WebUI.AlertActionButton>
            <WebUI.AlertCancelButton />
          </>
        }
      />
    </WebUI.Alert>
  )
}

export default CloseCollectionAlert
