import React, {useMemo} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'
import {
  api,
  useCreateTabPOSCodeMutation,
  useUpdateTabMutation,
} from '@cheddarup/api-client'
import config from 'src/config'
import {PaymentItemTableView} from 'src/components/PaymentItemTableView'
import {ShareQRCodeLinkModal} from 'src/components/ShareQRCodeLinkModal'
import {shareEventTracking} from 'src/helpers/analytics'

export interface SharePosCodeProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collection: Api.Tab
}

export const SharePosCode = ({
  collection,
  className,
  ...restProps
}: SharePosCodeProps) => {
  const createPOSCodeMutation = useCreateTabPOSCodeMutation()
  const updateCollectionMutation = useUpdateTabMutation()
  const growlActions = WebUI.useGrowlActions()
  return (
    <WebUI.VStack className={WebUI.cn('gap-8', className)} {...restProps}>
      <WebUI.Panel>
        <WebUI.HStack className="border-b p-7">
          <WebUI.Heading className="uppercase" as="h5">
            Share an Event Code
          </WebUI.Heading>
        </WebUI.HStack>
        <WebUI.VStack className="gap-3 p-7">
          <p className="text-ds-base text-gray700">
            Sharing Event Codes enables your team to take in-person payments and
            scan tickets without needing your account’s email and password.{' '}
            <WebUI.Anchor
              href={config.links.sharePOSCode}
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more
            </WebUI.Anchor>
          </p>
          <WebUI.Button
            className="self-start"
            loading={createPOSCodeMutation.isPending}
            onClick={() =>
              createPOSCodeMutation.mutate({
                pathParams: {
                  tabId: collection.id,
                },
              })
            }
          >
            New Event Code
          </WebUI.Button>
          {!!collection.options?.posCode?.value && (
            <div>
              <WebUI.Separator className="my-8" variant="primary" />
              <p className="text-gray700">Copy and share your Event Code</p>
              <p className="mt-1 font-light text-ds-sm text-gray700">
                Instruct your team members to download the latest version of the
                app (
                <WebUI.Anchor
                  href={config.links.appStore}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  iOS
                </WebUI.Anchor>{' '}
                or{' '}
                <WebUI.Anchor
                  href={config.links.googlePlay}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Android
                </WebUI.Anchor>
                ). They can then use the Event Code to start accepting
                point-of-sale payments and checking in ticket holders.
              </p>
              <WebUI.VStack
                className={
                  'mt-4 max-w-[512px] items-start justify-center gap-4 sm:flex-row sm:items-center sm:justify-start'
                }
              >
                <WebUI.Input
                  className="grow"
                  disabledVariant="filled"
                  disabled
                  value={collection.options?.posCode?.value ?? ''}
                />
                <WebUI.HStack className="items-center gap-3">
                  <WebUI.DeprecatedTooltip label="Copy link">
                    <WebUI.IconButton
                      className="text-ds-xl"
                      size="default_alt"
                      variant="secondaryAlt"
                      onClick={() => {
                        WebUI.copyToClipboard(
                          collection.options?.posCode?.value ?? '',
                        )
                        growlActions.show('success', {
                          title: 'Success',
                          body: 'Event code copied',
                        })
                      }}
                    >
                      <WebUI.PhosphorIcon icon="link" width={28} />
                    </WebUI.IconButton>
                  </WebUI.DeprecatedTooltip>
                  {!!collection.options?.posCode?.value && (
                    <WebUI.DeprecatedTooltip label="Send in email">
                      <WebUI.IconButton
                        className="text-ds-xl text-natural-100 hover:text-natural-100"
                        as={WebUI.Anchor}
                        size="default_alt"
                        variant="default"
                        rel="noopener noreferrer"
                        target="_blank"
                        href={`mailto:?&subject=Your Cheddar Up Point-of-Sale Code&body=${encodeURIComponent(
                          `${
                            collection.organizer.name
                          } is inviting you to accept point-of-sale payments on their collection. To get started, download the latest version of the Cheddar Up mobile app (iOS or Android). Then click the link below to get started.\r\r${makePOSCodeAppLink(
                            collection.options.posCode.value,
                          )}\r\r\rLearn more: ${config.links.sharePOSCode}.`,
                        )}`}
                        onClick={() =>
                          shareEventTracking(collection, 'email', 'pos')
                        }
                      >
                        <WebUI.PhosphorIcon icon="envelope-simple" />
                      </WebUI.IconButton>
                    </WebUI.DeprecatedTooltip>
                  )}
                  {!!collection.options?.posCode?.value && (
                    <ShareQRCodeLinkModal
                      link={makePOSCodeAppLink(
                        collection.options?.posCode?.value,
                      )}
                      heading="Share a point-of-sale QR code with your team"
                      text={
                        <>
                          Instruct your POS team members to download the latest
                          version of the{' '}
                          <WebUI.Anchor
                            href={config.links.appStore}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            iOS
                          </WebUI.Anchor>{' '}
                          or{' '}
                          <WebUI.Anchor
                            href={config.links.googlePlay}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Android
                          </WebUI.Anchor>{' '}
                          mobile app. Then, they can scan the QR code below to
                          start accepting point-of-sale payments.{' '}
                          <WebUI.Anchor
                            href={config.links.sharePOSCode}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Learn more.
                          </WebUI.Anchor>
                        </>
                      }
                      disclosure={
                        <WebUI.DeprecatedTooltip label="QR Code">
                          <WebUI.DialogDisclosure
                            className="bg-gray400 text-ds-xl text-natural-100"
                            as={WebUI.IconButton}
                            variant="secondary"
                            size="default_alt"
                            onClick={() =>
                              shareEventTracking(collection, 'qr', 'pos')
                            }
                          >
                            <WebUI.PhosphorIcon icon="qr-code" />
                          </WebUI.DialogDisclosure>
                        </WebUI.DeprecatedTooltip>
                      }
                    />
                  )}
                </WebUI.HStack>
              </WebUI.VStack>
              <WebUI.Separator className="my-8" variant="primary" />
              <p className="text-gray700">Code status</p>
              <WebUI.DropdownSelect
                className="mt-4 h-9 w-32"
                size="compact"
                value={String(!!collection.options?.posCode?.enabled)}
                onValueChange={(newValue) =>
                  updateCollectionMutation.mutate({
                    pathParams: {
                      tabId: collection.id,
                    },
                    body: {
                      options: {
                        posCode: {enabled: newValue === 'true'},
                      },
                    },
                  })
                }
              >
                <WebUI.DropdownSelectOption value="true">
                  Active
                </WebUI.DropdownSelectOption>
                <WebUI.DropdownSelectOption value="false">
                  Inactive
                </WebUI.DropdownSelectOption>
              </WebUI.DropdownSelect>
            </div>
          )}
        </WebUI.VStack>
      </WebUI.Panel>

      <POSCodeUsersTablePanel collection={collection} />
    </WebUI.VStack>
  )
}

interface POSCodeUsersTablePanelProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collection: Api.Tab
}

const POSCodeUsersTablePanel = ({
  className,
  collection,
  ...restProps
}: POSCodeUsersTablePanelProps) => {
  const {data: posUsers} = api.tabs.posReport.useQuery({
    pathParams: {
      tabId: collection.id,
    },
  })

  const columnHelper = useMemo(
    () => WebUI.createColumnHelper<Api.PosUser>(),
    [],
  )

  const columns = useMemo(
    () => [
      columnHelper.accessor((u) => u.collector_name, {
        id: 'collector_name',
        header: 'Event Code User',
      }),
      columnHelper.accessor((u) => u.collector_email, {
        id: 'collector_email',
        header: 'Email',
      }),
      columnHelper.accessor((u) => u.last_payment, {
        id: 'last_payment',
        header: 'Last Sign in',
        cell: ({cell}) => Util.formatDateAs(cell.getValue()),
      }),
      columnHelper.accessor((u) => u.transactions, {
        id: 'transactions',
        header: 'Transactions',
      }),
      columnHelper.accessor((u) => u.total, {
        id: 'total',
        header: 'Collected',
        cell: ({cell}) => Util.formatAmount(cell.getValue()),
      }),
    ],
    [columnHelper],
  )

  if (!Array.isArray(posUsers) || posUsers.length === 0) {
    return null
  }

  return (
    <WebUI.Panel className={WebUI.cn('mt-8 p-7', className)} {...restProps}>
      <PaymentItemTableView
        className="[&_>_.TableView-headerGroupList_>_.TableView-headerGroup]:bg-aquaLight"
        columns={columns}
        data={posUsers}
      />
    </WebUI.Panel>
  )
}

// MARK: – Helpers

const makePOSCodeAppLink = (posCode: string) => {
  const isProd = config.isProd
  const hostname = isProd ? 'my.cheddarup.com' : 'dev.cheddarup.com'
  return `https://${hostname}/sign-up-via-pos-code?code=${posCode}`
}
