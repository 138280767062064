import * as WebUI from '@cheddarup/web-ui'

export interface ContactListDropdownSelection {
  type: 'contactList' | 'collectionList'
  ids: number[]
}

export interface ContactListDropdownProps {
  collections: Api.Tab[]
  contactLists: Api.ContactList[]
  selection: ContactListDropdownSelection
  onSelectionChange: (selection: ContactListDropdownSelection) => void
}

export const ContactListDropdown = ({
  collections,
  contactLists,
  selection,
  onSelectionChange,
}: ContactListDropdownProps) => (
  <WebUI.Popover placement="bottom-start">
    {(popover) => (
      <>
        <WebUI.PopoverDisclosure
          className="h-10 w-full max-w-[320px] [&_>_.Button-content]:text-left"
          variant="outlined"
          iconAfter={<WebUI.PhosphorIcon icon="caret-down-fill" />}
        >
          {collections.find(
            (c) =>
              selection.type === 'collectionList' &&
              selection.ids.includes(c.id),
          )?.name ??
            (contactLists
              .filter(
                (l) =>
                  selection.type === 'contactList' &&
                  selection.ids.includes(l.id),
              )
              .map((l) => l.name)
              .join(', ') ||
              'Select')}
        </WebUI.PopoverDisclosure>

        <WebUI.PopoverContent className="max-w-[480px]">
          <WebUI.VStack className="gap-6 py-4 text-ds-sm [&_>_strong]:px-3">
            {contactLists.length > 0 && (
              <>
                <strong>My Lists</strong>
                <WebUI.Button
                  className="px-3"
                  variant="link"
                  onClick={() =>
                    onSelectionChange({
                      type: 'contactList',
                      ids: contactLists.map((cl) => cl.id),
                    })
                  }
                >
                  Select All
                </WebUI.Button>
                <WebUI.VStack className="gap-3">
                  {contactLists.map((list) => (
                    <WebUI.Checkbox
                      key={list.id}
                      className="px-4"
                      size="compact"
                      onChange={(event) =>
                        onSelectionChange({
                          type: 'contactList',
                          ids: event.target.checked
                            ? [...selection.ids, list.id].filter((id) =>
                                contactLists.find((c) => c.id === id),
                              )
                            : selection.ids.filter((id) => id !== list.id),
                        })
                      }
                      checked={
                        selection.type === 'contactList' &&
                        selection.ids.includes(list.id)
                      }
                    >
                      {list.name}
                    </WebUI.Checkbox>
                  ))}
                </WebUI.VStack>
              </>
            )}

            {collections.length > 0 && (
              <>
                <strong>Collection Lists</strong>
                <WebUI.VStack className="gap-3">
                  {collections.map((c) => (
                    <WebUI.Button
                      key={c.id}
                      className="text-left"
                      variant="ghost"
                      onClick={() => {
                        onSelectionChange({type: 'collectionList', ids: [c.id]})
                        popover.hide()
                      }}
                    >
                      {c.name}
                    </WebUI.Button>
                  ))}
                </WebUI.VStack>
              </>
            )}
          </WebUI.VStack>
        </WebUI.PopoverContent>
      </>
    )}
  </WebUI.Popover>
)

export default ContactListDropdown
