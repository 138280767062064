import {forwardRef} from 'react'
import * as WebUI from '@cheddarup/web-ui'

export interface MarketplaceHeadingProps {
  className?: string
}

const MarketplaceHeading = forwardRef<
  HTMLHeadingElement,
  MarketplaceHeadingProps
>(({className}, forwardedRef) => (
  <h4
    ref={forwardedRef}
    className={WebUI.cn('font-accent text-ds-sm italic', className)}
  >
    Internal Marketplace
  </h4>
))

export default MarketplaceHeading
