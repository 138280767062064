import {tailwindConfig} from '@cheddarup/tailwind-config'
import * as Util from '@cheddarup/util'

export const PAYMENT_METHOD_STATUSES: Record<string, Record<string, string>> = {
  cash: {
    pending: 'Not Received',
    available: 'Received',
  },
  card: {
    pending: 'Pending',
    available: 'Cleared',
    failed: 'Failed',
  },
  echeck: {
    pending: 'Pending',
    available: 'Cleared',
    failed: 'Failed',
  },
  paypal: {
    pending: 'Pending',
    available: 'Cleared',
  },
  refunded: {
    fully: 'Refunded',
    partial: 'Partial Refund',
  },
}

export const DISPUTE_STATUSES = {
  needs_response: (
    <span>
      Disputed: <span className="text-teal-50">Needs Response</span>
    </span>
  ),
  under_review: (
    <span>
      Disputed: <span className="text-teal-50">Under Review</span>
    </span>
  ),
  won: (
    <span>
      Disputed: <span className="text-teal-50">Won</span>
    </span>
  ),
  lost: (
    <span>
      Disputed: <span className="text-teal-50">Lost</span>
    </span>
  ),
}

export const PAYMENT_STATUS_COLORS = {
  pending: tailwindConfig.theme.colors.tint,
  failed: tailwindConfig.theme.colors.orange[50],
  disputed: tailwindConfig.theme.colors.orange[50],
  available: '#000000',
}

export const PAYMENT_METHOD_TOOLTIPS = {
  card: {
    pending: ({
      userView,
      availableOn,
    }: {
      userView?: boolean
      availableOn: string
    }) =>
      `This payment is still being processed and should be available to ${
        userView ? 'the organizer ' : 'withdraw '
      }${availableOn || 'within 1-2 business days of payment date.'}`,
    available: ({userView}: {userView?: boolean}) =>
      userView
        ? 'This payment has cleared and is available to the organizer.'
        : 'This payment is now available for withdrawal',
  },
  cash: {
    pending: () => 'This payment has not been marked as received yet.',
    available: () => 'This payment has been marked received.',
  },
  echeck: {
    pending: ({
      userView,
      availableOn,
    }: {
      userView?: boolean
      availableOn: string
    }) =>
      `This eCheck is still being processed and should be available to ${
        userView ? 'the organizer ' : 'withdraw '
      }${availableOn || 'within 5 business days of payment date.'}`,
    available: ({userView}: {userView?: boolean}) =>
      userView
        ? 'This payment has cleared and is available to the organizer.'
        : 'This payment is now available for withdrawal',
    failed: ({userView}: {userView: boolean}) =>
      userView
        ? 'This eCheck failed to clear from your bank account.'
        : "This eCheck failed to clear from the customer's bank account.",
  },
  paypal: {
    available: () =>
      'This payment should is now available in your PayPal account.',
    pending: () =>
      'PayPal is no longer supported. Please contact CheddarUp to withdraw any PayPal funds.',
  },
  refunded: {
    fully: ({payment}: {payment: Api.TabPayment}) =>
      `${Util.formatAmount(
        payment.total_refund ?? 0,
      )} was refunded on this payment.`,
    partial: ({payment}: {payment: Api.TabPayment}) =>
      `${Util.formatAmount(
        payment.total_refund ?? 0,
      )} was refunded on this payment.`,
  },
}
