import {cn} from '@cheddarup/web-ui'
import {LinkButton, LinkButtonProps} from 'src/components/LinkButton'

interface MoneyTransferOptionCtaProps
  extends LinkButtonProps,
    React.ComponentPropsWithoutRef<'a'> {}

export const MoneyTransferOptionCta: React.FC<MoneyTransferOptionCtaProps> = ({
  className,
  variant = 'secondary',
  ...restProps
}) => (
  <LinkButton
    className={cn(
      'MoneyTransferOptionCta',
      'h-18 max-w-60 rounded-lg bg-gray-100 p-4 text-left leading-snug [&_>_.Text]:text-wrap',
      className,
    )}
    variant={variant}
    {...restProps}
  />
)

export default MoneyTransferOptionCta
