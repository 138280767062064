import {useState} from 'react'

// See https://react.dev/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes
export function useOnVarChange(callback: () => void, propDep: any) {
  const [prevProp, setPrevProp] = useState(propDep)

  if (propDep !== prevProp) {
    setPrevProp(propDep)
    callback()
  }
}
