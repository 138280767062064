import {Axios, AxiosError} from 'axios'
import React, {useEffect, useRef} from 'react'
import {useLiveRef} from '@cheddarup/react-util'
import {useVerifyCaptchaMutation} from '@cheddarup/api-client'
import * as WebUI from '@cheddarup/web-ui'

export interface RecaptchaErrorHandlerProps {
  axios: Axios
}

export const RecaptchaErrorHandler: React.FC<RecaptchaErrorHandlerProps> = ({
  axios,
}) => {
  const recaptchaModalRef = useRef<WebUI.RecaptchaVisibleAlertInstance>(null)
  const axiosRef = useLiveRef(axios)
  const verifyCaptchaMutation = useVerifyCaptchaMutation()

  const verifyCaptchaAsync = verifyCaptchaMutation.mutateAsync
  useEffect(() => {
    const axiosClient = axiosRef.current

    const recaptchaInterceptor = axiosClient.interceptors.response.use(
      undefined,
      async (error: AxiosError) => {
        if (!isInvalidCaptchaError(error) || !recaptchaModalRef.current) {
          throw error
        }

        const captchaToken = await recaptchaModalRef.current.show()
        await verifyCaptchaAsync({body: {captchaToken}})

        if (error.config) {
          return axiosClient.request(error.config)
        }

        throw error
      },
    )

    return () => axiosClient.interceptors.response.eject(recaptchaInterceptor)
  }, [verifyCaptchaAsync])

  return <WebUI.RecaptchaVisibleAlert ref={recaptchaModalRef} />
}

// MARK: – Helpers

function isInvalidCaptchaError(error: AxiosError) {
  return (
    error.response?.status === 400 &&
    error.response.data &&
    typeof error.response.data === 'object' &&
    'errors' in error.response.data &&
    Array.isArray(error.response.data.errors) &&
    error.response.data.errors[0]?.error === 'invalid_captcha'
  )
}
