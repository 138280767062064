import * as Yup from 'yup'
import * as WebUI from '@cheddarup/web-ui'
import {useFormik} from '@cheddarup/react-util'
import React, {useState} from 'react'
import {useForgotPasswordMutation} from '@cheddarup/api-client'
import {LinkButton} from 'src/components/LinkButton'
import {AuthLayout} from 'src/components/AuthLayout'
import {Logo} from 'src/components'
import {Link} from 'src/components/Link'

import {LoginFooter} from '../components'

const ForgotPasswordPage = () => {
  const [passwordDidReset, setPasswordDidReset] = useState(false)

  return (
    <AuthLayout>
      <WebUI.VStack className="gap-10 p-10">
        <Logo />
        <WebUI.VStack className="gap-1">
          <WebUI.Heading className="text-center">
            {passwordDidReset ? 'Got it!' : 'Reset Password'}
          </WebUI.Heading>
          <WebUI.Text className="text-center font-light text-ds-md">
            {passwordDidReset ? (
              <>
                If your email is linked to an account, we’ll send you an email
                with instructions to reset your password.
                <br />
                <br />
                If you don’t see it, try checking your spam folder or consider
                that you may have signed up with a different email address.
              </>
            ) : (
              "Enter the email address you use to login and we'll email you instructions on how to reset your password."
            )}
          </WebUI.Text>
        </WebUI.VStack>
        <WebUI.VStack className="gap-4">
          {passwordDidReset ? (
            <LinkButton
              className="self-center"
              size="large"
              variant="primary"
              to="/login"
            >
              OK
            </LinkButton>
          ) : (
            <ForgotPasswordForm
              onDidResetPassword={() => setPasswordDidReset(true)}
            />
          )}
          <Link
            className="self-center text-center text-ds-sm"
            variant="primary"
            iconBefore={<WebUI.PhosphorIcon icon="arrow-left" />}
            to="/login"
          >
            Return to login
          </Link>
        </WebUI.VStack>
      </WebUI.VStack>
      <LoginFooter />
    </AuthLayout>
  )
}

// MARK: – ForgotPasswordForm

interface ForgotPasswordFormProps
  extends React.ComponentPropsWithoutRef<'form'> {
  onDidResetPassword: () => void
}

const ForgotPasswordForm = ({
  onDidResetPassword,
  className,
  ...restProps
}: ForgotPasswordFormProps) => {
  const forgotPasswordMutation = useForgotPasswordMutation()
  const formik = useFormik({
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Email is invalid').required('Required'),
    }),
    initialValues: {
      email: '',
    },
    onSubmit: async (values) => {
      await forgotPasswordMutation.mutateAsync({body: values})
      onDidResetPassword()
    },
  })

  return (
    <WebUI.Form
      className={WebUI.cn('[&_>_.Form-inner]:gap-7', className)}
      onSubmit={formik.handleSubmit}
      {...restProps}
    >
      <WebUI.FormField label="Email" error={formik.errors.email}>
        <WebUI.Input
          name="email"
          type="email"
          placeholder="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </WebUI.FormField>
      <WebUI.Button
        className="self-center"
        type="submit"
        variant="primary"
        size="large"
        loading={formik.isSubmitting}
      >
        Send Instructions
      </WebUI.Button>
    </WebUI.Form>
  )
}

export default ForgotPasswordPage
