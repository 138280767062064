import React, {useImperativeHandle, useRef, useState} from 'react'
import RecaptchaPrimitive, {
  ReCAPTCHAProps as RecaptchaPrimitiveProps,
} from 'react-google-recaptcha'

import {Alert, AlertContentView, AlertHeader, AlertProps} from './Alert'
import {DialogInstance} from './Dialog'
import {Loader} from './Loader'
import {cn} from '../utils'

export interface RecaptchaVisibleInstance extends RecaptchaPrimitive {}

export interface RecaptchaVisibleProps
  extends Omit<RecaptchaPrimitiveProps, 'sitekey'> {
  sitekey?: string
}

export const RecaptchaVisible = React.forwardRef<
  RecaptchaVisibleInstance,
  RecaptchaVisibleProps
>(
  (
    {
      sitekey = '6LdqOlMUAAAAAGEUIK5jzFytOGxS-sHfvQiIhFjn',
      size = 'normal',
      badge = 'bottomright',
      className,
      ...restProps
    },
    forwardedRef,
  ) => (
    <RecaptchaPrimitive
      ref={forwardedRef}
      className={cn('RecaptchaVisible', className)}
      sitekey={sitekey}
      size={size}
      badge={badge}
      {...restProps}
    />
  ),
)

// MARK: – RecaptchaVisibleAlert

export interface RecaptchaVisibleAlertInstance {
  show: () => Promise<string>
  dialog: DialogInstance | null
}

export interface RecaptchaVisibleAlertProps extends AlertProps {
  onTokenSubmit?: (token: string) => void
}

export const RecaptchaVisibleAlert = React.forwardRef<
  RecaptchaVisibleAlertInstance,
  RecaptchaVisibleAlertProps
>(
  (
    {
      onTokenSubmit,
      closeButtonVisible = false,
      hideOnClickOutside = false,
      hideOnEsc = false,
      className,
      ...restProps
    },
    forwardedRef,
  ) => {
    const dialogRef = useRef<DialogInstance>(null)
    const onShowDidResolveRef = useRef<((token: string) => void) | undefined>(
      undefined,
    )
    const onShowDidRejectRef = useRef<((reason?: any) => void) | undefined>(
      undefined,
    )
    const [isRecaptchaLoading, setIsRecaptchaLoading] = useState(true)

    useImperativeHandle(
      forwardedRef,
      () => ({
        show: () =>
          new Promise((resolve, reject) => {
            dialogRef.current?.show()

            onShowDidResolveRef.current = resolve
            onShowDidRejectRef.current = reject
          }),
        dialog: dialogRef.current,
      }),
      [],
    )

    return (
      <Alert
        ref={dialogRef}
        className={cn('RecaptchaAlert', className)}
        backdropClassName={cn('RecaptchaAlert-backdrop', 'z-[1]', className)}
        closeButtonVisible={closeButtonVisible}
        hideOnClickOutside={hideOnClickOutside}
        hideOnEsc={hideOnEsc}
        {...restProps}
      >
        {(alert) => (
          <>
            <AlertHeader>One more step</AlertHeader>
            <AlertContentView
              text="We need to make sure you're not a robot..."
              actions={
                <>
                  {alert.visible && (
                    <RecaptchaVisible
                      onChange={(token) => {
                        if (token != null) {
                          onTokenSubmit?.(token)
                          dialogRef.current?.hide()

                          onShowDidResolveRef.current?.(token)
                        }
                      }}
                      onErrored={() => {
                        onShowDidRejectRef.current?.()
                      }}
                      asyncScriptOnLoad={() => setIsRecaptchaLoading(false)}
                    />
                  )}
                  {isRecaptchaLoading && <Loader size="3em" />}
                </>
              }
            />
          </>
        )}
      </Alert>
    )
  },
)
