import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'

export default function useScrollToHash() {
  const location = useLocation()
  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace('#', '')
      const element = document.getElementById(id)
      if (element) {
        element.scrollIntoView({behavior: 'smooth'})
      }
    }
  }, [location])
}
