import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {sessionSchema, userCustomerCategorySchema} from '../schemas'

const loginBodySchema = z.object({
  fpHash: z.string().optional(),
  timeZone: z.string().optional(),
  zip: z.string().optional(),
  email: z.string(),
  password: z.string().optional(),
  captchaToken: z.string().optional(),
  forCheckout: z.boolean().optional(),
  identification_token: z.string().optional(),
  otp_code: z.string().optional(),
  device_information: z.string().optional(),
  via: z.enum(['sms', 'call']).optional(),
})

const signupBodySchema = z
  .object({
    timeZone: z.string().optional(),
    zip: z.string().optional(),
    city: z.string().optional(),
    state: z.string().optional(),
    country_code: z.string().optional(),
    first_name: z.string().optional(),
    last_name: z.string().optional(),
    captchaToken: z.string().optional(),
    customer_category: userCustomerCategorySchema.or(z.literal('')).optional(),
    country: z.string().optional(),
    profile: z
      .object({
        signupSource: z
          .enum(['payment_page', 'mobile_ios', 'mobile_android', 'web'])
          .optional(),
        bestDescribesYou: z.string().optional(),
        referrer: z
          .object({
            collection: z.string(),
            payment: z.string(),
            url: z.any(),
          })
          .partial()
          .optional(),
        uiClientFlags: z
          .object({
            disabledEmailNotifications: z
              .object({
                marketingOptIn: z.boolean().optional(),
              })
              .optional(),
          })
          .optional(),
      })
      .optional(),
    email: z.string(),
    password: z.string(),
  })
  .passthrough()

export default {
  session: makeEndpoint({
    path: 'session',
    responseSchema: sessionSchema,
  }),
  signup: makeEndpoint({
    path: 'signup',
    method: 'POST',
    queryParamsSchema: z
      .object({
        respondWithToken: z.literal(false),
      })
      .partial(),
    bodySchema: signupBodySchema,
    responseSchema: sessionSchema,
  }),
  signupWithToken: makeEndpoint({
    path: 'signup',
    method: 'POST',
    queryParamsSchema: z
      .object({
        respondWithToken: z.literal(true),
      })
      .partial(),
    bodySchema: signupBodySchema,
    responseSchema: z.object({
      token: z.string(),
      session: sessionSchema.optional(),
    }),
  }),
  login: makeEndpoint({
    path: 'login',
    method: 'POST',
    queryParamsSchema: z
      .object({
        respondWithToken: z.literal(false),
      })
      .partial(),
    bodySchema: loginBodySchema,
    responseSchema: sessionSchema,
  }),
  loginWithToken: makeEndpoint({
    path: 'login',
    method: 'POST',
    queryParamsSchema: z.object({
      respondWithToken: z.literal(true),
      includeSession: z.boolean().optional(),
    }),
    bodySchema: loginBodySchema,
    responseSchema: z.object({
      session: sessionSchema.optional(),
      token: z.string(),
    }),
  }),
  loginViaToken: makeEndpoint({
    path: 'token',
    method: 'GET',
    queryParamsSchema: z.object({
      token: z.string(),
      respondWithToken: z.boolean().optional(),
      respondWithJson: z.boolean().optional(),
      includeSession: z.boolean().optional(),
    }),
    responseSchema: sessionSchema,
  }),
  logout: makeEndpoint({
    path: 'logout',
    method: 'DELETE',
    responseSchema: z.any(),
  }),
  forgotPassword: makeEndpoint({
    path: 'password/forgot',
    method: 'POST',
    responseSchema: z.any(),
  }),
  resetPassword: makeEndpoint({
    path: 'password/reset',
    method: 'POST',
    bodySchema: z.object({
      email: z.string(),
      code: z.string(),
      password: z.string(),
      password_confirmation: z.string(),
      security: z
        .object({
          token: z.string(),
        })
        .optional(),
    }),
    responseSchema: z.object({
      success: z.boolean(),
    }),
  }),
  checkEmailExistence: makeEndpoint({
    path: 'check',
    method: 'POST',
    bodySchema: z.object({
      email: z.string(),
      fpHash: z.string().optional(),
    }),
    responseSchema: z.object({
      status: z.string(),
      show_opt_in: z.boolean(),
      exists: z.boolean(),
    }),
  }),
  sendLoginLink: makeEndpoint({
    path: 'send_login_link',
    method: 'POST',
    bodySchema: z.object({
      email: z.string(),
    }),
    responseSchema: z.object({
      success: z.boolean(),
    }),
  }),
  sendLoginCode: makeEndpoint({
    path: 'send_login_code',
    method: 'POST',
    bodySchema: z.object({
      email: z.string(),
    }),
    responseSchema: z.object({
      success: z.boolean(),
    }),
  }),
  verifyCaptcha: makeEndpoint({
    path: 'verify_captcha',
    method: 'POST',
    bodySchema: z.object({
      captchaToken: z.string(),
    }),
    responseSchema: z.any(),
  }),
  testCaptchaRequired: makeEndpoint({
    path: 'test_captcha_required',
    method: 'POST',
    responseSchema: z.any(),
  }),
  testResetCaptcha: makeEndpoint({
    path: 'test_reset_captcha',
    method: 'POST',
    responseSchema: z.any(),
  }),
  sendOneTimeLoginLink: makeEndpoint({
    path: 'one_time_login_link',
    method: 'POST',
    bodySchema: z.object({
      email: z.string(),
      collection: z.string(),
      cart: z.string(),
    }),
    responseSchema: z.object({success: z.boolean()}),
  }),
  verifyMailedSecurityCode: makeEndpoint({
    path: 'users/mailed_security_codes/verify',
    method: 'POST',
    bodySchema: z.object({
      code: z.string().optional(),
      password: z.string(),
    }),
    responseSchema: z.any(),
  }),
  confirmPhoneNumber: makeEndpoint({
    path: 'user/confirm_phone_number',
    method: 'PATCH',
    responseSchema: z.any(),
  }),
}
