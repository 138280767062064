import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {deepEqual, sort} from '@cheddarup/util'

import {Keybinding, KeybindingPress, parseKeybinding} from '../utils'

export interface ShortcutOptions {
  /** Definitions with higher priority get executed first */
  priority?: number
  ignoreInput?: boolean
}

export interface ShortcutDefinition extends ShortcutOptions {
  keybinding: Keybinding
  onHandle: (event?: KeyboardEvent) => boolean
}

// MARK: - Shortcut

export interface ShortcutProps extends ShortcutOptions {
  keybinding: string
  onHandle: (event?: KeyboardEvent) => boolean
  children?: never
}

export const Shortcut = ({
  keybinding: keybindingStr,
  onHandle,
  ...options
}: ShortcutProps) => {
  useShortcut(keybindingStr, onHandle, options)
  return null
}

// MARK: - useTriggerShortcut

export function useTriggerShortcut() {
  const shortcutManager = useContext(ShortcutContext)
  return useCallback(
    (keybindingStr: string) => {
      shortcutManager?.findByKeybinding(keybindingStr)?.onHandle()
    },
    [shortcutManager],
  )
}

// MARK: - useShortcut

export function useShortcut(
  keybindingStr: string,
  onHandle: (event?: KeyboardEvent) => boolean,
  options: ShortcutOptions = {},
) {
  const shortcutManager = useContext(ShortcutContext)

  const shortcut = useMemo(
    (): ShortcutDefinition => ({
      keybinding: parseKeybinding(keybindingStr),
      onHandle,
      ...options,
    }),
    [keybindingStr, options, onHandle],
  )

  useEffect(() => {
    const unsub = shortcutManager?.subscribe(shortcut)
    return () => unsub?.()
  }, [shortcut, shortcutManager])
}

// MARK: - ShortcutContext

export const ShortcutContext = React.createContext<ShortcutManager | null>(null)

export interface ShortcutProviderProps {
  children?: React.ReactNode
}

export const ShortcutProvider = ({children}: ShortcutProviderProps) => {
  const [shortcutManager] = useState(() => new ShortcutManager())

  useEffect(() => shortcutManager.init(), [shortcutManager])

  return (
    <ShortcutContext.Provider value={shortcutManager}>
      {children}
    </ShortcutContext.Provider>
  )
}

// MARK: - ShortcutManager

class ShortcutManager {
  private shortcuts: ShortcutDefinition[] = []

  findByKeybinding(keybindingStr: string) {
    const keybinding = parseKeybinding(keybindingStr)
    return this.shortcuts.find((s) => deepEqual(s.keybinding, keybinding))
  }

  subscribe(shortcut: ShortcutDefinition) {
    this.shortcuts = sort([...this.shortcuts, shortcut]).desc([
      (s) => s.keybinding.length,
      (s) => s.priority ?? 0,
    ])
    return () => {
      this.shortcuts.splice(this.shortcuts.indexOf(shortcut), 1)
    }
  }

  init() {
    const possibleMatches = new Map<KeybindingPress[], KeybindingPress[]>()
    let timerId: number | null = null

    const onKeyDown: EventListener = (event) => {
      // Ensure and stop any event that isn't a full keyboard event.
      // Autocomplete option navigation and selection would fire a instanceof Event,
      // instead of the expected KeyboardEvent
      if (!(event instanceof KeyboardEvent)) {
        return
      }

      for (const shortcut of this.shortcuts) {
        if (isActiveElementInputLike() && !shortcut.ignoreInput) {
          continue
        }

        const prev = possibleMatches.get(shortcut.keybinding)
        const remainingExpectedPresses = prev ?? shortcut.keybinding

        // biome-ignore lint/style/noNonNullAssertion:
        const currentExpectedPress = remainingExpectedPresses[0]!

        const matches = matchKeybindingPress(event, currentExpectedPress)
        if (matches) {
          if (remainingExpectedPresses.length > 1) {
            possibleMatches.set(
              shortcut.keybinding,
              remainingExpectedPresses.slice(1),
            )
          } else {
            possibleMatches.delete(shortcut.keybinding)
            const handled = shortcut.onHandle(event)
            if (handled) {
              event.preventDefault()
              break
            }
          }
        } else {
          // Modifier keydown events shouldn't break sequences
          // Note: This works because:
          // - non-modifiers will always return false
          // - if the current keypress is a modifier then it will return true when we check its state
          // MDN: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/getModifierState
          if (!event.getModifierState(event.key)) {
            possibleMatches.delete(shortcut.keybinding)
          }
        }
      }

      if (timerId) {
        window.clearTimeout(timerId)
      }

      timerId = window.setTimeout(
        possibleMatches.clear.bind(possibleMatches),
        KEYBINDING_SEQUENCE_TIMEOUT,
      )
    }

    document.addEventListener('keydown', onKeyDown)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }
}

/**
 * This tells us if a series of events matches a key binding sequence either
 * partially or exactly
 *
 * Borrowed from https://github.com/jamiebuilds/tinykeys/blob/8245bf6084f23a5c268ddbbc4b48038a16a58395/src/tinykeys.ts#L56
 */
function matchKeybindingPress(
  event: KeyboardEvent,
  press: KeybindingPress,
): boolean {
  return !(
    // Allow either the `event.key` or the `event.code`
    // MDN event.key: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key
    // MDN event.code: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code
    // KEYBINDING_MODIFIER_KEYS (Shift/Control/etc) change the meaning of a
    // keybinding. So if they are pressed but aren't part of the current
    // keybinding press, then we don't have a match
    (
      (press[1].toUpperCase() !== event.key.toUpperCase() &&
        press[1] !== event.code) ||
      // Ensure all the modifiers in the keybinding are pressed
      press[0].some((mod) => !event.getModifierState(mod)) ||
      KEYBINDING_MODIFIER_KEYS.some(
        (mod) =>
          !press[0].includes(mod) &&
          press[1] !== mod &&
          event.getModifierState(mod),
      )
    )
  )
}

/**
 * These are the modifier keys that change the meaning of keybindings
 */
const KEYBINDING_MODIFIER_KEYS = ['Shift', 'Meta', 'Alt', 'Control']

/**
 * Keybinding sequences should timeout if individual key presses are more than
 * 1s apart
 */
const KEYBINDING_SEQUENCE_TIMEOUT = 1000

// MARK: - Helpers

function isActiveElementInputLike() {
  const activeEl = document.activeElement
  if (activeEl == null || activeEl.tagName == null) {
    return false
  }
  return isElementInputLike(activeEl as HTMLElement)
}

function isElementInputLike(el: HTMLElement) {
  return (
    el.tagName === 'INPUT' ||
    el.tagName === 'SELECT' ||
    el.tagName === 'TEXTAREA' ||
    el.tagName === 'EMOJI-PICKER' ||
    el.hasAttribute('contenteditable') ||
    el.closest('[role="menu"]') ||
    el.closest('[role="toolbar"]')
  )
}
