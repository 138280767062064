import {useParams} from 'react-router-dom'
import {api} from '@cheddarup/api-client'

import CollectionManagePaymentsTable from './CollectionManagePaymentsTable'

const CollectionManagePayments = () => {
  const urlParams = useParams()
  const {data: collection} = api.tabs.detail.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: urlParams.collection!,
    },
  })

  if (!collection) {
    return null
  }
  return (
    <CollectionManagePaymentsTable
      className="grow"
      collection={collection}
      collectionId={Number(urlParams.collection)}
    />
  )
}

export default CollectionManagePayments
