import {deepPartialify, z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {paginationSchema, tabFormSchema} from '../schemas'

export default {
  list: makeEndpoint({
    path: 'users/tabs/:tabId/forms',
    responseSchema: tabFormSchema.array(),
  }),
  detail: makeEndpoint({
    path: 'users/tabs/:tabId/forms/:formId',
    responseSchema: tabFormSchema,
  }),
  search: makeEndpoint({
    path: 'users/tabs/:tabId/forms/search',
    queryParamsSchema: z
      .object({
        perPage: z.number(),
        page: z.number(),
        sort: z.string(),
        direction: z.enum(['asc', 'desc']),
        search: z.string(),
      })
      .partial()
      .optional(),
    responseSchema: z.object({
      data: tabFormSchema.array(),
      pagination: paginationSchema,
      search: z.string(),
      sort: z.string(),
    }),
  }),
  create: makeEndpoint({
    path: 'users/tabs/:tabId/forms',
    method: 'POST',
    bodySchema: deepPartialify(tabFormSchema),
    responseSchema: tabFormSchema,
  }),
  update: makeEndpoint({
    path: 'users/tabs/:tabId/forms/:formId',
    method: 'PATCH',
    bodySchema: deepPartialify(tabFormSchema),
    responseSchema: tabFormSchema,
  }),
  delete: makeEndpoint({
    path: 'users/tabs/:tabId/forms/:formId',
    method: 'DELETE',
    responseSchema: z.any(),
  }),
  updateBatch: makeEndpoint({
    path: 'users/tabs/:tabId/forms/modify',
    method: 'POST',
    bodySchema: z.object({
      tab_item_ids: z.number().array(),
      hidden: z.boolean().optional(),
      parent_id: z.boolean().optional(),
    }),
    responseSchema: tabFormSchema.array(),
  }),
  deleteBulk: makeEndpoint({
    path: 'users/tabs/:tabId/forms/delete',
    method: 'POST',
    bodySchema: z.object({
      tab_item_ids: z.number().array(),
    }),
    responseSchema: z.any(),
  }),
  clone: makeEndpoint({
    path: 'users/tabs/:tabId/forms/:formId/clone',
    method: 'POST',
    bodySchema: tabFormSchema.partial(),
    responseSchema: tabFormSchema,
  }),
  move: makeEndpoint({
    path: 'users/tabs/:tabId/forms/move',
    method: 'POST',
    responseSchema: z.any(),
  }),
}
