import React from 'react'

import {GrowlAlerts, GrowlAlertsProvider} from './components'
import {ShortcutProvider} from './hooks'

export interface UIProviderProps {
  children?: React.ReactNode
}

export const UIProvider = ({children}: UIProviderProps) => (
  <GrowlAlertsProvider>
    <ShortcutProvider>{children}</ShortcutProvider>
    <GrowlAlerts />
  </GrowlAlertsProvider>
)
