import * as WebUI from '@cheddarup/web-ui'
import React from 'react'
import {api} from '@cheddarup/api-client'
import * as Util from '@cheddarup/util'
import {
  AccountProfileItem,
  ManagerAccounts,
} from 'src/views/CollectionsPage/components/ManagerAccounts'
import {isAccountDetailsFieldsFilled} from '@cheddarup/core'
import {LinkButton, LinkButtonProps} from './LinkButton'
import {useManagerRole} from './ManageRoleProvider'
import {CurrentUserAvatar} from './CurrentUserAvatar'

export interface UserMenuProps {
  forNewNav?: boolean // TODO: Temporary workaround to show additional items in UserMenu till completion of Gated Feature
}

export const UserMenu = React.forwardRef<HTMLButtonElement, UserMenuProps>(
  ({forNewNav = false, ...restProps}, forwardedRef) => {
    const [managerRole] = useManagerRole()
    const sessionQuery = api.auth.session.useQuery()
    const isAnyFieldNeededQuery = api.auth.session.useQuery(undefined, {
      select: (session) =>
        session.stripe_data.fieldsNeeded.length > 0 &&
        isAccountDetailsFieldsFilled(session.user),
    })
    const hasAlertsQuery = api.alerts.list.useQuery(undefined, {
      select: (alerts) => alerts.length > 0,
    })

    const isAccountOwner = !managerRole
    const managerHasEditAccessOnAllCollections =
      managerRole &&
      managerRole.permissions.role !== 'viewer' &&
      managerRole.permissions.type === 'all'

    return (
      <>
        <WebUI.Menu>
          <WebUI.MenuButton
            ref={forwardedRef}
            iconAfter={<WebUI.PhosphorIcon icon="caret-down" />}
            variant="text"
            size="large"
            {...restProps}
          >
            <UserAvatar size={32} />
          </WebUI.MenuButton>

          <WebUI.MenuList className="min-w-[400px] [&_>_.MenuList-inner_>_.MenuList-body]:rounded-extended [&_>_.MenuList-inner_>_.MenuList-body]:p-0">
            <div className="flex flex-col gap-4 px-8 pt-6 pb-4">
              {managerRole ? (
                <AccountProfileItem managerRole={managerRole} />
              ) : (
                <AccountProfileItem
                  isPersonal
                  showPlan={forNewNav}
                  plan={sessionQuery.data?.capabilities?.plan}
                  managerRole={
                    {
                      id: sessionQuery.data?.user.id,
                      email: sessionQuery.data?.user.email,
                      name: sessionQuery.data?.user.display_name,
                      full_name: sessionQuery.data?.user.display_name,
                      profile_pic: sessionQuery.data?.user.profile_pic ?? null,
                      canCreate: true,
                      profile: {
                        uiClientFlags:
                          sessionQuery.data?.user.profile.uiClientFlags,
                      },
                    } as any
                  }
                />
              )}
              <ManagerAccounts menuPlacement="bottom-start" />
            </div>
            <WebUI.Separator variant="primary" />

            {(isAccountOwner || managerHasEditAccessOnAllCollections) && (
              <>
                <div className="flex justify-between px-8 py-5">
                  <div className="flex flex-col gap-2">
                    <UserMenuHeading className="mb-1">Account</UserMenuHeading>
                    {sessionQuery.data?.partner_admin?.access && (
                      <UserMenuLinkButton to="/reporting/dashboard">
                        Partner Dashboard
                      </UserMenuLinkButton>
                    )}
                    {isAccountOwner && (
                      <UserMenuLinkButton to="my-account/display-name">
                        <div className="flex items-center gap-1">
                          <span className="font-normal">Profile</span>
                          {isAnyFieldNeededQuery.data && (
                            <WebUI.PhosphorIcon
                              className="text-orange-50"
                              icon="warning-circle-fill"
                              width={15}
                            />
                          )}
                        </div>
                      </UserMenuLinkButton>
                    )}
                    {(isAccountOwner || managerRole.permissions.group_page) && (
                      <UserMenuLinkButton to="my-account/brand-kit-logo">
                        Brand Kit
                      </UserMenuLinkButton>
                    )}
                    {(isAccountOwner ||
                      managerHasEditAccessOnAllCollections) && (
                      <UserMenuLinkButton to="my-account/zapier-key">
                        Integrations
                      </UserMenuLinkButton>
                    )}
                    {isAccountOwner && (
                      <UserMenuLinkButton to="my-account/alerts">
                        <div className="flex items-center gap-1">
                          <span className="font-normal">Alerts</span>
                          {hasAlertsQuery.data && (
                            <WebUI.PhosphorIcon
                              className="text-orange-50"
                              width={15}
                              icon="warning-circle-fill"
                            />
                          )}
                        </div>
                      </UserMenuLinkButton>
                    )}

                    {(isAccountOwner ||
                      managerRole.permissions
                        .address_book_and_message_center) && (
                      <UserMenuLinkButton to="/address-book">
                        Address Book
                      </UserMenuLinkButton>
                    )}
                    {isAccountOwner && (
                      <UserMenuLinkButton to="/payments">
                        Your Order History
                      </UserMenuLinkButton>
                    )}
                  </div>
                  <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-2">
                      <UserMenuHeading className="mb-1">
                        Explore
                      </UserMenuHeading>
                      {(isAccountOwner ||
                        managerRole.permissions.group_page) && (
                        <UserMenuLinkButton to="/group">
                          Create a group website
                        </UserMenuLinkButton>
                      )}
                      <UserMenuLinkButton to="/templates">
                        Start with a template
                      </UserMenuLinkButton>
                      <UserMenuLinkButton to="/reports">
                        Create custom reports
                      </UserMenuLinkButton>
                    </div>

                    {(isAccountOwner ||
                      managerHasEditAccessOnAllCollections) && (
                      <>
                        <WebUI.Separator variant="primary" />
                        <div className="flex flex-col gap-2">
                          {isAccountOwner && (
                            <UserMenuLinkButton to="/managers?addManager=1">
                              Invite managers
                            </UserMenuLinkButton>
                          )}
                          <WebUI.AnchorButton
                            className="text-ds-sm text-teal-50"
                            variant="text"
                            href={'https://support.cheddarup.com'}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Get help
                          </WebUI.AnchorButton>

                          {!forNewNav &&
                            isAccountOwner &&
                            !sessionQuery.data?.capabilities
                              .subscribed_to_team && (
                              <UserMenuLinkButton to="i/plans">
                                Upgrade account
                              </UserMenuLinkButton>
                            )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <WebUI.Separator variant="primary" />
              </>
            )}

            <div className="p-7">
              <LinkButton variant="secondary" to="/logout">
                Log Out
              </LinkButton>
            </div>
          </WebUI.MenuList>
        </WebUI.Menu>
      </>
    )
  },
)

// MARK: – UserAvatar

export interface UserAvatarProps
  extends Pick<WebUI.ImageProps, 'errorFallback'>,
    Util.SetOptional<Pick<WebUI.AvatarProps, 'size'>, 'size'>,
    React.ComponentPropsWithoutRef<'div'> {}

export const UserAvatar: React.FC<UserAvatarProps> = ({
  errorFallback,
  size = 30,
  className,
  ...restProps
}) => {
  const sessionQuery = api.auth.session.useQuery()
  const hasAlertsQuery = api.alerts.list.useQuery(undefined, {
    select: (alerts) => alerts.length > 0,
  })
  const [managerRole] = useManagerRole()

  const alertVisible =
    !managerRole &&
    !!sessionQuery.data &&
    (sessionQuery.data.stripe_data.fieldsNeeded.length > 0 ||
      !isAccountDetailsFieldsFilled(sessionQuery.data.user) ||
      !!hasAlertsQuery.data)

  return (
    <div className={WebUI.cn('relative', className)} {...restProps}>
      <CurrentUserAvatar size={size} errorFallback={errorFallback} />
      {alertVisible && (
        <WebUI.PhosphorIcon
          className="-right-[7px] -top-[5px] absolute text-orange-50"
          width={14}
          icon="warning-circle-fill"
        />
      )}
    </div>
  )
}

// MARK: - UserMenuHeading

export interface UserMenuHeadingProps
  extends React.ComponentPropsWithoutRef<'span'> {}

export const UserMenuHeading: React.FC<UserMenuHeadingProps> = ({
  className,
  ...restProps
}) => (
  <WebUI.Text
    className={WebUI.cn(
      'font-medium text-ds-sm text-gray-800 leading-relaxed',
      className,
    )}
    {...restProps}
  />
)

// MARK: - UserMenuLinkButton

export interface UserMenuLinkButtonProps
  extends LinkButtonProps,
    React.ComponentPropsWithoutRef<'a'> {}

export const UserMenuLinkButton: React.FC<UserMenuLinkButtonProps> = ({
  className,
  ...restProps
}) => (
  <LinkButton
    className={WebUI.cn('text-ds-sm leading-relaxed', className)}
    variant="link"
    {...restProps}
  />
)
