import {formatDuration, normalizeDuration, z} from '@cheddarup/util'

// MARK: – ApiError

export class ApiError extends Error {
  public readonly data: z.infer<typeof apiErrorSchema>

  public get significantError() {
    return this.data.errors[0]
  }

  public get significantCode() {
    return this.significantError.error
  }

  constructor(data: unknown) {
    const parsedData = apiErrorSchema.safeParse(data)

    if (!parsedData.success) {
      console.debug(
        'Parse data error:',
        `err: ${parsedData.error}`,
        `data: ${data}`,
      )
    }

    const typedData = parsedData.success
      ? parsedData.data
      : (data as z.infer<typeof apiErrorSchema>)

    const significantError = typedData.errors[0]
    const displayMessage = getDisplayMessageForErrorError(significantError)

    super(displayMessage || '')

    this.data = typedData
    this.name = 'ApiError'
  }
}

// MARK: – Helpers

const apiErrorWithDetailsCodeSchema = z.enum([
  'login_lockout',
  'two_factor_lockout',
  'two_factor_required',
  'security_token_sent',
  'invalid_email',
  'invalid_captcha',
  'intent_total_changed',
  'invalid_ticket_details',
  'withdrawals_pending',
  'contact_list_add_error',
  'required_field_missing',
  'required_item_missing',
  'amount_required',
  'exceeded_available_quantity',
  'items_sold_out',
  'batch_limit_exceeded',
  'per_day_limit_exceeded',
])

const apiErrorErrorSchema = z.object({
  error: z.string(),
  details: z.any(),
})

const apiErrorErrorWithDetailsSchema = z.discriminatedUnion('error', [
  z.object({
    error: apiErrorWithDetailsCodeSchema.extract([
      'login_lockout',
      'two_factor_lockout',
    ]),
    details: z.object({
      permanent: z.boolean(),
      remaining: z.number(),
    }),
  }),
  z.object({
    error: z.literal(apiErrorWithDetailsCodeSchema.enum.two_factor_required),
    details: z.object({
      phone: z.object({
        last2: z.string(),
      }),
    }),
  }),
  z.object({
    error: z.literal(apiErrorWithDetailsCodeSchema.enum.security_token_sent),
    details: z.object({
      lastTwoNumbers: z.string(),
    }),
  }),
  z.object({
    error: apiErrorWithDetailsCodeSchema.extract([
      'invalid_email',
      'invalid_captcha',
      'intent_total_changed',
    ]),
    details: z.string().optional(),
  }),
  z.object({
    error: z.literal(apiErrorWithDetailsCodeSchema.enum.invalid_ticket_details),
    details: z.unknown(),
  }),
  z.object({
    error: z.literal(apiErrorWithDetailsCodeSchema.enum.withdrawals_pending),
    details: z.object({
      count: z.number(),
    }),
  }),
  z.object({
    error: z.literal(apiErrorWithDetailsCodeSchema.enum.contact_list_add_error),
    details: z.object({
      contact: z.record(z.any()),
      error: z.record(z.any()),
    }),
  }),
  z.object({
    error: apiErrorWithDetailsCodeSchema.extract([
      'required_field_missing',
      'required_item_missing',
    ]),
    details: z.any().array(),
  }),
  z.object({
    error: z.literal(apiErrorWithDetailsCodeSchema.enum.amount_required),
    details: z.object({}),
  }),
  z.object({
    error: z.literal(
      apiErrorWithDetailsCodeSchema.enum.exceeded_available_quantity,
    ),
    details: z.object({
      tab_item_name: z.string(),
      requested: z.number(),
      available: z.number(),
    }),
  }),
  z.object({
    error: z.literal(apiErrorWithDetailsCodeSchema.enum.items_sold_out),
    details: z.object({
      actions_taken: z.record(z.any()).array(),
    }),
  }),
  z.object({
    error: apiErrorWithDetailsCodeSchema.extract([
      'batch_limit_exceeded',
      'per_day_limit_exceeded',
    ]),
    details: z.object({
      limit: z.number(),
    }),
  }),
])

const apiErrorSchema = z.object({
  type: z.literal('api_error'),
  errors: apiErrorErrorSchema.array().nonempty(),
})

export function getDisplayMessageForErrorError(
  error: z.infer<typeof apiErrorErrorSchema>,
) {
  const parseErrorRes = apiErrorErrorWithDetailsSchema.safeParse(error)

  if (parseErrorRes.success) {
    const parsedError = parseErrorRes.data

    switch (parsedError.error) {
      case 'login_lockout':
        if (parsedError.details.permanent) {
          return 'Your account has been locked. Please reset your password to log in again.'
        }
        return `Your account is temporarily locked. Please try again in ${formatDuration(
          normalizeDuration({
            seconds: Math.ceil(Number(parsedError.details.remaining)),
          }),
        )}, or reset your password to continue.`
      case 'two_factor_lockout':
        if (parsedError.details.permanent) {
          return 'Too many two-factor codes requested. Please contact support.'
        }
        return `Too many two-factor codes requested. Please try again in ${formatDuration(
          normalizeDuration({
            seconds: Math.ceil(Number(parsedError.details.remaining)),
          }),
        )}.`
      case 'two_factor_required':
        return 'A two-factor authentication code has been sent to your devices. Enter the code to continue.'
      case 'security_token_sent':
        return 'Secury token sent'
      case 'invalid_email':
        return 'Email is invalid'
      case 'invalid_captcha':
        return 'Invalid captcha'
      case 'intent_total_changed':
        return 'There was an error trying to process your payment, please try again.'
      case 'withdrawals_pending':
        return 'There is a pending transfer to this account. Account can not be deleted until funds have cleared.'
      case 'required_field_missing':
        return "Please make sure you've filled out all required form fields on this collection, then try again."
      case 'amount_required':
        return 'Amount is required'
      case 'exceeded_available_quantity': {
        const quantityError =
          parsedError.details.available === 0
            ? 'it is sold out'
            : `there ${
                parsedError.details.available === 1 ? 'is' : 'are'
              } only ${parsedError.details.available} available for sale`
        return `Sorry, you requested ${parsedError.details.requested} of ${parsedError.details.tab_item_name}, but ${quantityError}.`
      }
      case 'items_sold_out':
        return 'Some of your items have been sold out'
    }
  }

  switch (error.error) {
    case 'invalid_password':
      return 'Invalid password'
    case 'invalid_code':
      return 'Invalid code'
    case 'invalid_security_token':
      return 'Invalid verification code'
    case 'incorrect_reset_code':
      return 'Invalid backup security code'
    case 'unauthorized':
      return 'Your password is not correct'
    case 'not_found':
      return 'Invalid discount code'
    case 'persona_required':
      return 'We need a little more information'
    case 'account_already_exists':
      return 'Account already exists'
    case 'too_soon':
      return 'We can only process one withdrawal per minute on a single collection.'
    case 'payment_required':
      return 'This collection requires that your total is at least $1.00. Please add an item to your cart then attempt checkout again.'
    case 'name_and_email_missing_at_validation':
      return 'Did you fill out your name and email address properly?'
    case 'slug_taken':
      return 'This slug is already in use'
    case 'invalid_promo':
      return 'Promo code is invalid. Please try re-entering the code.'
    case 'recurring_contracts_active':
      return 'This item has active recurring payers and cannot be updated. If you wish to make changes, please clone the item and hide this version.'
    case 'security_code_expired':
      return 'Your security code expired. Request for new one.'
    case 'invalid_security_code':
      return 'Invalid security code'
    case 'payment_intent_authentication_failure':
      return 'The provided payment method has failed authentication. Provide a new payment method to attempt to checkout again.'
    case 'tabs_have_balances':
      return 'You must withdraw your balance prior to downgrading'
  }

  if (typeof error.details === 'string' && error.details.length > 0) {
    return error.details
  }

  return undefined
}
