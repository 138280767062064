import {api} from '@cheddarup/api-client'
import * as WebUI from '@cheddarup/web-ui'
import React, {useState} from 'react'
import {useUpdateEffect} from '@cheddarup/react-util'

const CollectionAppBanner = () => {
  const {data} = api.global.activeBanner.useQuery()
  const [bannerShownSession] = WebUI.useLocalStorage(
    'banner_shown_session',
    false,
  )

  const initialVisible = data?.status === 'active' && !bannerShownSession

  return (
    <AppBanner
      initialVisible={initialVisible}
      iconType={data?.icon}
      href={data?.link_url}
      linkContent={data?.link_text}
      variant={data?.bg_color}
    >
      {data?.banner_text}
    </AppBanner>
  )
}

// MARK: – AppBanner

type AppBannerVariant = 'default' | 'light' | 'dark'
type AppBannerIconType = 'warning' | 'informative' | 'marketing'

interface AppBannerProps extends React.ComponentPropsWithoutRef<'div'> {
  initialVisible?: boolean
  variant?: AppBannerVariant
  iconType?: AppBannerIconType
  href?: string
  linkContent?: React.ReactNode
}

const AppBanner = ({
  initialVisible = false,
  iconType,
  href,
  linkContent = 'Learn More',
  variant = 'default',
  className,
  children,
  ...restProps
}: AppBannerProps) => {
  const [, setBannerShown] = WebUI.useLocalStorage(
    'banner_shown_session',
    false,
  )
  const [visible, setVisible] = useState(initialVisible)

  useUpdateEffect(() => setVisible(initialVisible), [initialVisible])

  const icon = iconType
    ? {
        warning: (
          <WebUI.PhosphorIcon
            className="text-ds-lg text-orange-50"
            icon="warning-fill"
          />
        ),
        informative: (
          <WebUI.PhosphorIcon
            className="text-ds-lg text-orange-50"
            icon="info-fill"
          />
        ),
        marketing: (
          <WebUI.PhosphorIcon
            className="text-ds-lg text-orange-50"
            icon="arrow-fat-right-fill"
          />
        ),
      }[iconType]
    : null

  return (
    <>
      {visible && (
        <div
          className={WebUI.cn(
            'relative flex items-center justify-center gap-8 p-3',
            {
              default: 'bg-teal-50',
              light: 'bg-natural-100',
              dark: 'bg-gray750',
            }[variant],
            className,
          )}
          {...restProps}
        >
          <div className="flex grow justify-end">{icon}</div>
          <div className="flex max-w-[80%] flex-col gap-1 sm:flex-row sm:gap-8">
            <WebUI.Ellipsis
              className={WebUI.cn(
                'text-wrap font-bold text-ds-sm sm:text-nowrap',
                {
                  default: 'text-natural-100',
                  light: 'text-gray800',
                  dark: 'text-natural-100',
                }[variant],
              )}
            >
              {children}
            </WebUI.Ellipsis>
            {href && (
              <WebUI.Anchor
                className={WebUI.cn(
                  'text-ds-sm underline',
                  {
                    default:
                      'text-natural-100 [&.Anchor:hover]:text-natural-70',
                    light: 'text-gray800 [&.Anchor:hover]:text-gray700',
                    dark: 'text-natural-100 [&.Anchor:hover]:text-natural-70',
                  }[variant],
                )}
                href={href}
                target="_blank"
                rel="noreferrer"
              >
                {linkContent}
              </WebUI.Anchor>
            )}
          </div>
          <div className="flex grow justify-end">
            <WebUI.IconButton
              className={
                {
                  default: 'text-natural-100',
                  light: 'text-gray400',
                  dark: 'text-natural-100',
                }[variant]
              }
              onClick={() => {
                setVisible(false)
                setBannerShown(true)
              }}
            >
              <WebUI.PhosphorIcon icon="x" width={22} />
            </WebUI.IconButton>
          </div>
        </div>
      )}
    </>
  )
}

export default CollectionAppBanner
