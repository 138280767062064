import * as Util from '@cheddarup/util'

const getRepeatPeriod = (
  parsedRepeat: ReturnType<typeof Util.parseDurationWeird>,
) => {
  if (parsedRepeat.asMonths) {
    return 'month'
  }
  if (parsedRepeat.asWeeks) {
    return 'week'
  }

  return ''
}

const RecurringPaymentFormatter = {
  getAnnotationStartFormatted: <TType extends 'date' | 'first_payment'>({
    type,
    date,
  }: {
    type: TType
    date: TType extends 'date' ? string : never
  }) => {
    switch (type) {
      case 'date':
        return ` (beginning no earlier than ${Util.formatDateAs(date)})`
      case 'first_payment':
        return ' starting on the first day of payment'
      default:
        return ''
    }
  },
  getAnnotationEndFormatted: (ends: {payment_count: number}) =>
    !ends || !ends.payment_count
      ? ''
      : `${ends.payment_count} payment${ends.payment_count > 1 ? 's' : ''}`,
  getAnnotationRepeatFormatted: <TType extends 'date' | string>(
    repeatInterval: string | null | undefined,
    start: {type: TType; date: TType extends 'date' ? string : never},
  ) => {
    if (!repeatInterval) {
      return ''
    }

    const duration = Util.parseDuration(repeatInterval)
    const period = duration.months ? 'month' : 'week'
    const repeat = {
      period,
      count: period === 'month' ? duration.months : duration.days / 7,
    }

    let day = ''
    const startDate = start.type === 'date' ? start.date : null

    if (startDate) {
      day =
        repeat.period === 'month'
          ? `on day ${Util.formatDate(startDate, 'd')}`
          : `starting on ${Util.formatDate(startDate, 'EEEE')}`
    }

    return `every ${repeat.count === 1 ? '' : repeat.count} ${repeat.period}${
      repeat.count === 1 ? '' : 's'
    } ${day}`
  },
  getAnnotation: (recurringOptions: Record<string, any> | undefined | null) =>
    recurringOptions
      ? {
          start: RecurringPaymentFormatter.getAnnotationStartFormatted(
            recurringOptions.start,
          ),
          ends: RecurringPaymentFormatter.getAnnotationEndFormatted(
            recurringOptions.ends,
          ),
          repeatInterval:
            RecurringPaymentFormatter.getAnnotationRepeatFormatted(
              recurringOptions.repeatInterval,
              recurringOptions.start,
            ),
        }
      : {},
  getLabelsStartFormatted: (
    start: Api.RecurringPaymentContract['options']['start'],
  ) =>
    start?.date && start.type === 'date'
      ? `On ${Util.formatDateAs(start.date)}`
      : 'First day of payment',
  getLabelsEndFormatted: (
    end: Api.RecurringPaymentContract['options']['ends'],
  ) => {
    if (!end) {
      return ''
    }

    return end.type === 'never'
      ? 'Never'
      : `After ${end.payment_count} payment${
          end.payment_count === 1 ? '' : 's'
        }`
  },
  getLabelsRepeatFormatted: (repeat: string) => {
    const duration = Util.parseDuration(repeat)
    let period = ''
    let quantity = 0

    if (duration.months) {
      period = 'Month'
      quantity = duration.months
    }

    if (duration.days) {
      period = 'Week'
      quantity = duration.days / 7
    }

    const end = quantity === 1 ? '' : 's'

    return `${quantity} ${period}${end}`
  },
  getLabelsNextFormatted: ({
    start,
    repeat,
  }: {
    start: Api.RecurringPaymentContract['options']['start']
    repeat: Api.RecurringPaymentContract['options']['repeatInterval']
  }) => {
    if (!start || !repeat) {
      return ''
    }

    const durationMs = Util.durationToMilliseconds(Util.parseDuration(repeat))
    const startDate =
      start.type === 'date' && start.date ? new Date(start.date) : new Date()

    return Util.formatDateAs(Util.addMilliseconds(startDate, durationMs))
  },
  getLabels: (
    parsedRecurringPayment: Api.RecurringPaymentContract['options'],
  ) => ({
    start: RecurringPaymentFormatter.getLabelsStartFormatted(
      parsedRecurringPayment.start,
    ),
    end: RecurringPaymentFormatter.getLabelsEndFormatted(
      parsedRecurringPayment.ends,
    ),
    repeat: RecurringPaymentFormatter.getLabelsRepeatFormatted(
      parsedRecurringPayment.repeatInterval,
    ),
    next: RecurringPaymentFormatter.getLabelsNextFormatted({
      start: parsedRecurringPayment.start,
      repeat: parsedRecurringPayment.repeatInterval,
    }),
  }),
  parseEnd: (end: any) =>
    Boolean(end) && end.type === 'payment_count'
      ? {
          payments: end.payment_count,
        }
      : {},
  parseRepeat: (repeat: any) => {
    const parsedRepeat = Util.parseDurationWeird(repeat)

    return {
      period: getRepeatPeriod(parsedRepeat),
      quantity: parsedRepeat.asMonths || parsedRepeat.asWeeks,
    }
  },
  parseRecurringPayment: (recurringPayment: any) => ({
    start: recurringPayment.start,
    end: RecurringPaymentFormatter.parseEnd(
      recurringPayment.end || recurringPayment.ends,
    ),
    repeat: RecurringPaymentFormatter.parseRepeat(
      recurringPayment.repeat || recurringPayment.repeatInterval,
    ),
  }),
}

export default RecurringPaymentFormatter
