import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {tabFolderSchema} from '../schemas'

export default {
  list: makeEndpoint({
    path: 'users/folders',
    responseSchema: tabFolderSchema.array(),
  }),
  create: makeEndpoint({
    path: 'users/folders',
    method: 'POST',
    bodySchema: tabFolderSchema.partial(),
    responseSchema: tabFolderSchema,
  }),
  update: makeEndpoint({
    path: 'users/folders/:folderId',
    method: 'PATCH',
    bodySchema: tabFolderSchema.partial(),
    responseSchema: tabFolderSchema,
  }),
  delete: makeEndpoint({
    path: 'users/folders/:folderId',
    method: 'DELETE',
    responseSchema: z.any(),
  }),
  sort: makeEndpoint({
    path: 'users/folders/sort',
    method: 'PATCH',
    bodySchema: z.object({
      order: z.number().array(),
    }),
    responseSchema: tabFolderSchema.array(),
  }),
}
