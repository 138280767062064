import * as Yup from 'yup'
import {useFormik} from '@cheddarup/react-util'
import * as Util from '@cheddarup/util'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {
  api,
  endpoints,
  getEndpointKey,
  useCheckSubscriptionMutation,
  useQueryClient,
  useUpdateSubscriptionMutation,
} from '@cheddarup/api-client'
import * as WebUI from '@cheddarup/web-ui'
import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import UnSubscribeLogo from 'src/images/why-unsubscribe.png'
import {trackUserSubscriptionChangeEvent} from 'src/helpers/analytics'

const CONFIRM_KEYWORD = 'DELETE'

const DowngradePage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const urlParams = useParams()
  const queryClient = useQueryClient()
  const [downgrading, setDowngrading] = useState(false)
  const [checkResults, setCheckResults] = useState<Awaited<
    ReturnType<(typeof checkSubscriptionMutation)['mutateAsync']>
  > | null>(null)
  const {data: session} = api.auth.session.useSuspenseQuery()
  const {data: billingPeriodEndDay} = api.subscriptions.own.useSuspenseQuery(
    undefined,
    {
      select: ({plan}) =>
        plan.pending_changes
          ? Util.formatDateAs(plan.pending_changes.starting)
          : '',
    },
  )
  const {data: collections} = api.tabs.list.useSuspenseQuery()
  const checkSubscriptionMutation = useCheckSubscriptionMutation()
  const updateSubscriptionMutation = useUpdateSubscriptionMutation()
  const growlActions = WebUI.useGrowlActions()

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    ;(async () => {
      const res = await checkSubscriptionMutation.mutateAsync({
        body: {plan: 'free'},
      })
      setCheckResults(res)
    })()
  }, [])

  const isTeamUser = !!session.capabilities.subscribed_to_team
  const collectionsPro = useMemo(
    () =>
      collections?.filter(
        (c) =>
          !c.closed_at &&
          c.user_id === session?.user.id &&
          c.requires_pro &&
          c.status !== 'draft',
      ) ?? [],
    [collections, session],
  )

  const downgradeError = (checkResults?.results ?? []).some(({error}) => error)
  const handleDismiss = useCallback(() => {
    navigate('..')
  }, [navigate])
  const downgradeTo = urlParams.to || 'basic'
  const team2pro = isTeamUser && downgradeTo === 'pro'
  const team2basic = isTeamUser && downgradeTo === 'basic'

  const cancelSubscription = useCallback(
    async (confirm: string, reason?: string) => {
      if (downgradeError) {
        return
      }

      if (team2pro) {
        const managersWarning = (checkResults?.results ?? []).filter(
          (result) => result.warning === 'managers_will_be_deleted',
        )
        if (managersWarning.length > 0) {
          navigate('../pro/warning')
        } else {
          navigate('../pro', {state: location.state})
        }

        return
      }

      if (
        team2pro ||
        confirm === CONFIRM_KEYWORD ||
        collectionsPro.length === 0
      ) {
        setDowngrading(true)
        try {
          const newPlan = await updateSubscriptionMutation.mutateAsync({
            body: {plan: 'free', source_id: '', reason},
          })
          trackUserSubscriptionChangeEvent(newPlan)
          growlActions.show('success', {
            body: "You've been downgraded",
            title: 'Back to Basics',
          })

          if (urlParams.collection) {
            const tabQueryKey = getEndpointKey(endpoints.tabs.detail, {
              pathParams: {
                tabId: urlParams.collection,
              },
            })
            queryClient.invalidateQueries({queryKey: tabQueryKey})
          }
          handleDismiss()
        } catch (err: any) {
          let errMsg = err.message || err.response?.data?.error
          if (errMsg === 'tabs_have_balances') {
            errMsg = 'You must withdraw your balance prior to downgrading'
          }

          if (errMsg) {
            growlActions.show('error', {title: 'Error', body: errMsg})
          }
        } finally {
          setDowngrading(false)
        }
      } else {
        growlActions.clear()
        growlActions.show('error', {
          body: `Please type "${CONFIRM_KEYWORD}" to proceed with cancelling your subscription.`,
          title: 'Error',
        })
      }
    },
    [
      downgradeError,
      team2pro,
      collectionsPro.length,
      checkResults?.results,
      location.state,
      navigate,
      updateSubscriptionMutation,
      growlActions,
      urlParams.collection,
      handleDismiss,
      queryClient,
    ],
  )
  const pauseSubscription = useCallback(() => {
    navigate(`../pause/${downgradeTo}`)
  }, [downgradeTo, navigate])

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (downgradeError) {
      navigate('../basic/error', {replace: true})
    }
  }, [downgradeError])

  return (
    <WebUI.Alert
      aria-label="Downgrade plan"
      initialVisible
      onDidHide={handleDismiss}
    >
      <WebUI.AlertHeader>
        <span>
          {team2pro ? 'Downgrade' : 'Cancel'} my subscription.
          {(team2pro || collectionsPro.length > 0) && (
            <span className="text-orange-50"> This cannot be undone.</span>
          )}
        </span>
      </WebUI.AlertHeader>
      <WebUI.AlertContentView
        text={
          <>
            <p>
              Your{' '}
              {team2pro || (team2basic && collectionsPro.length !== 0)
                ? 'Team Plan'
                : 'subscription'}{' '}
              will be downgraded to our{' '}
              {team2pro ? 'Pro Plan' : 'FREE Basic Plan'} at the end of your
              current billing period
              {billingPeriodEndDay ? ` ${billingPeriodEndDay}` : ''}.
              {isTeamUser && (
                <>
                  {' '}
                  At that time,{' '}
                  <span className="underline">recurring payments </span>
                  will be cancelled, managers will be deleted, and your Group
                  Page link will no longer be active.
                </>
              )}
              {!team2pro && collectionsPro.length !== 0 && (
                <span>
                  {' '}
                  At that time, your Pro features will be disabled and all{' '}
                  collections utilizing Pro Plan features will be deleted.
                </span>
              )}
            </p>
            {!team2pro && collectionsPro.length !== 0 && (
              <>
                <p className="my-4 font-bold text-ds-sm text-gray800">
                  Collections to be deleted:
                </p>
                <ul className="mt-2 max-h-[200px] overflow-y-scroll">
                  {collectionsPro.map((c) => (
                    <li key={c.id} className="mb-2">
                      <WebUI.PhosphorIcon
                        className="mr-4 text-ds-md text-orange-50"
                        icon="x"
                      />
                      {c.name}
                    </li>
                  ))}
                </ul>
              </>
            )}
          </>
        }
        actions={
          <DowngradeButtons
            showPause={!team2basic && !team2pro}
            confirm={!team2pro && collectionsPro.length !== 0}
            isPending={downgrading}
            cancelSubscription={cancelSubscription}
            pauseSubscription={pauseSubscription}
            buttonName={team2pro ? 'Downgrade' : 'Cancel'}
          />
        }
      />
    </WebUI.Alert>
  )
}

// MARK: – DowngradeButtons

interface DowngradeButtonsProps {
  confirm?: boolean
  showPause?: boolean
  isPending?: boolean
  cancelSubscription: (text: string, reason: string) => void
  pauseSubscription: () => void
  buttonName?: string
}

const DowngradeButtons = ({
  confirm,
  showPause,
  isPending,
  cancelSubscription,
  pauseSubscription,
  buttonName,
}: DowngradeButtonsProps) => {
  const modalRef = useRef<WebUI.DialogInstance>(null)
  const [text, setText] = useState('')

  const formik = useFormik({
    initialValues: {
      reason: '',
    },
    validationSchema: Yup.object().shape({
      reason: Yup.string().required('Required'),
    }),
    onSubmit: (values) => {
      cancelSubscription(text, values.reason)
      modalRef.current?.hide()
    },
  })

  return (
    <>
      <div>
        {confirm && (
          <WebUI.VStack className="gap-2">
            <p className="font-bold text-ds-sm">
              Type {CONFIRM_KEYWORD} to confirm
            </p>
            <WebUI.Input
              className="max-w-[400px]"
              value={text}
              onChange={(event) => setText(event.target.value)}
            />
          </WebUI.VStack>
        )}
        <div className="mt-4 text-center sm:flex sm:items-center">
          <WebUI.Button
            className="mr-4"
            variant="primary"
            loading={isPending}
            onClick={() => modalRef.current?.show()}
          >
            {`${buttonName || 'Cancel'} my subscription`}
          </WebUI.Button>
          {showPause && (
            <div className="mt-1 sm:mt-0">
              <WebUI.Button
                variant="secondary"
                disabled={isPending}
                onClick={pauseSubscription}
              >
                Pause my subscription
              </WebUI.Button>
            </div>
          )}
        </div>
      </div>
      <WebUI.Modal
        ref={modalRef}
        aria-label="Why are you unsubscribing?"
        className="sm:[&_>_.ModalContentView]:max-w-[580px]"
        initialVisible={false}
      >
        {() => (
          <>
            <WebUI.ModalCloseButton />
            <WebUI.Form onSubmit={formik.handleSubmit}>
              <div className="px-14 py-8">
                <WebUI.VStack className="mt-4 items-center gap-6">
                  <WebUI.Image
                    width={79}
                    height={70}
                    src={UnSubscribeLogo}
                    alt="Why unsubscribe?"
                  />
                  <WebUI.Text className="text-center">
                    <span className="font-bold">We value your feedback</span>{' '}
                    and are sorry you had to cancel your Cheddar Up
                    subscription. Could you please share the reason with us so
                    we can improve?
                  </WebUI.Text>
                  <WebUI.FormField error={formik.errors.reason}>
                    <WebUI.DropdownSelect
                      className="max-w-[380px]"
                      name="customerCategory"
                      placeholder="Select One"
                      value={formik.values.reason}
                      onValueChange={(newCustomerCategory) =>
                        formik.setFieldValue('reason', newCustomerCategory)
                      }
                    >
                      <WebUI.DropdownSelectOption value="missing_features">
                        Missing features I need
                      </WebUI.DropdownSelectOption>
                      <WebUI.DropdownSelectOption value="not_right_now">
                        Don't need it right now, might reactivate later
                      </WebUI.DropdownSelectOption>
                      <WebUI.DropdownSelectOption value="switch_product">
                        Switching to another product
                      </WebUI.DropdownSelectOption>
                      <WebUI.DropdownSelectOption value="too_expensive">
                        Too expensive
                      </WebUI.DropdownSelectOption>
                      <WebUI.DropdownSelectOption value="technical_issues">
                        Technical issues
                      </WebUI.DropdownSelectOption>
                      <WebUI.DropdownSelectOption value="other">
                        Other
                      </WebUI.DropdownSelectOption>
                    </WebUI.DropdownSelect>
                  </WebUI.FormField>
                </WebUI.VStack>
                <WebUI.HStack className="justify-center">
                  <WebUI.Button
                    className="mt-12 w-[240px] self-center"
                    type="submit"
                    size="large"
                    variant="primary"
                  >
                    Submit
                  </WebUI.Button>
                </WebUI.HStack>
              </div>
            </WebUI.Form>
          </>
        )}
      </WebUI.Modal>
    </>
  )
}

export default DowngradePage
