import {forwardRef} from 'react'

import {PhosphorIcon} from '../icons'
import type {ButtonProps} from './Button'
import {
  Popover,
  PopoverContent,
  PopoverDisclosure,
  PopoverInstance,
  PopoverProps,
} from './Popover'

export interface FiltersPopoverProps
  extends Pick<ButtonProps, 'size'>,
    PopoverProps {}

export const FiltersPopover = forwardRef<PopoverInstance, FiltersPopoverProps>(
  ({size = 'compact', children, ...restProps}, forwardedRef) => (
    <Popover ref={forwardedRef} aria-label="Filters" {...restProps}>
      {(popover) => (
        <>
          <PopoverDisclosure
            size={size}
            variant="outlined"
            iconBefore={<PhosphorIcon icon="funnel" />}
          >
            Filters
          </PopoverDisclosure>

          <PopoverContent>
            {typeof children === 'function' ? children(popover) : children}
          </PopoverContent>
        </>
      )}
    </Popover>
  ),
)
