import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'
import React from 'react'
import {ObjectParam, useQueryParam, withDefault} from 'use-query-params'

const PayerPageFiltersOverviewBar = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => {
  const [selectedFilterValues, setSelectedFilterValues] = useQueryParam(
    'filters',
    withDefault(ObjectParam, {} as Record<string, string | undefined>),
  )

  const selectedFilterKeys = Object.keys(selectedFilterValues)

  if (selectedFilterKeys.length === 0) {
    return null
  }

  return (
    <WebUI.HStack
      className={WebUI.cn(
        'overflow-x-auto border-b bg-natural-100 px-4 py-2 sm:px-16',
        className,
      )}
      {...restProps}
    >
      {selectedFilterKeys.map((filterValuesKey) => {
        // biome-ignore lint/style/noNonNullAssertion:
        const filterValue = selectedFilterValues[filterValuesKey]!
        return (
          <FiltersOverviewFilterItem
            key={`${filterValuesKey}-${filterValue}`}
            className="mr-8"
            title={filterValue}
            onClickCross={() =>
              setSelectedFilterValues(
                Util.omit(selectedFilterValues, [filterValuesKey]),
              )
            }
          />
        )
      })}
    </WebUI.HStack>
  )
}

// MARK: – FiltersOverviewFilterItem

interface FiltersOverviewFilterItemProps {
  className?: string
  title: React.ReactNode
  onClickCross: () => void
}

const FiltersOverviewFilterItem = ({
  className,
  title,
  onClickCross,
}: FiltersOverviewFilterItemProps) => (
  <WebUI.HStack className="gap-2">
    <WebUI.Button
      className={WebUI.cn(
        'max-w-[200px] text-ellipsis text-ds-xs [&_>_.Button-content]:leading-none',
        className,
      )}
      variant="text"
    >
      {title}
    </WebUI.Button>
    <WebUI.Button
      className="hidden sm:block"
      variant="text"
      onClick={onClickCross}
    >
      <WebUI.PhosphorIcon
        className="text-ds-md text-gray400"
        icon="x-circle-fill"
      />
    </WebUI.Button>
  </WebUI.HStack>
)

export default PayerPageFiltersOverviewBar
