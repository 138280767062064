import {ForwardRefComponent} from '@cheddarup/react-util'
import {prependProtocol} from '@cheddarup/util'
import {Anchor, AnchorProps} from '@cheddarup/web-ui'
import React from 'react'

export interface SmartAnchorProps extends AnchorProps {
  normalizeLink?: boolean
}

export const SmartAnchor = React.forwardRef(
  ({href: hrefProp, normalizeLink, ...restProps}, forwardedRef) => {
    const href =
      normalizeLink && hrefProp ? prependProtocol(hrefProp) : hrefProp

    return <Anchor ref={forwardedRef} href={href} {...restProps} />
  },
) as ForwardRefComponent<'a', SmartAnchorProps>
