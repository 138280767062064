// @ts-ignore

import {z} from '@cheddarup/util'

export const paymentReceivedNotificationPayloadDataSchema = z.object({
  id: z.number(),
  user_id: z.number().optional(),
  notification_type: z.string(),
  contents: z.object({
    id: z.number(),
    total: z.number(),
    tab: z.object({
      id: z.number(),
      name: z.string(),
    }),
    tab_member: z.object({
      name: z.string().nullable(),
      email: z.string().nullable(),
    }),
  }),
})

export const paymentReceivedNotificationSchema = z.object({
  one_signal_id: z.string().nullable(),
  title: z.string(),
  body: z.string(),
  data: z.string().nullish(),
  contents: z.object({
    body: z.string().optional(),
    data: z.string().optional(),
    title: z.string().optional(),
  }),
  payload: z.object({
    app_id: z.string(),
    data: paymentReceivedNotificationPayloadDataSchema,
  }),
})

export const otherNotificationSchema = z.object({
  data: z.string().optional(),
  one_signal_id: z.string().nullable(),
  title: z.string().nullable(),
  body: z.string().nullable(),
  contents: z.object({}),
  payload: z.object({
    data: z.any(),
  }),
})

export const anyNotificationSchema = z.object({
  id: z.number(),
  updated_at: z.string(),
  created_at: z.string(),
  user_id: z.number(),
  seen_at: z.string().nullish(),
  sent_at: z.string(),
  one_signal_id: z.string(),
  title: z.string(),
  body: z.string(),
  hidden_at: z.string().nullish(),
  payload: z.any(),
})

export const notificationSchema = z.discriminatedUnion('notification_type', [
  anyNotificationSchema
    .merge(paymentReceivedNotificationSchema)
    .extend({notification_type: z.literal('payment.received')}),
  anyNotificationSchema.merge(otherNotificationSchema).extend({
    notification_type: z.enum([
      'additional_account_information_needed',
      'more_information_due',
      'payments_disabled',
      'notifications.balance.first_balance_available',
      'notifications.balance.taxable_threshold_close',
    ]),
  }),
])

declare global {
  namespace Api {
    // @ts-ignore
    type PaymentReceivedNotification = z.infer<
      typeof paymentReceivedNotificationSchema
    >
    // @ts-ignore
    type PaymentReceivedNotificationPayloadData = z.infer<
      typeof paymentReceivedNotificationPayloadDataSchema
    >
    // @ts-ignore
    type OtherNotification = z.infer<typeof otherNotificationSchema>
    // @ts-ignore
    type AnyNotification = z.TypeOf<typeof anyNotificationSchema>
    // @ts-ignore
    type Notification = z.infer<typeof notificationSchema>
  }
}
