import * as Ariakit from '@ariakit/react'
export type {Item as CompositeItem} from 'reakit/ts/Composite/__utils/types'

import React, {useContext, useImperativeHandle, useMemo, useRef} from 'react'

import {cn} from '../../utils'
import {NextButton} from './Button'
import {getStringFromChildren} from '@cheddarup/react-util'

interface InternalTabsContextValue {
  lazy?: boolean
}

export const InternalTabsContext = React.createContext(
  {} as InternalTabsContextValue,
)

// MARK: – Tabs

export interface TabsInstance extends Ariakit.TabStore {}

export interface TabsProps extends Ariakit.TabStoreProps {
  lazy?: boolean
  children: React.ReactNode | ((tabs: TabsInstance) => React.ReactNode)
}

export const Tabs = React.forwardRef<TabsInstance, TabsProps>(
  ({lazy = false, children, ...restProps}, forwardedRef) => {
    const tabStore = Ariakit.useTabStore(restProps)

    useImperativeHandle(forwardedRef, () => tabStore, [tabStore])

    const contextValue = useMemo(
      () => ({
        lazy,
      }),
      [lazy],
    )

    return (
      <Ariakit.TabProvider store={tabStore}>
        <InternalTabsContext.Provider value={contextValue}>
          {typeof children === 'function' ? children(tabStore) : children}
        </InternalTabsContext.Provider>
      </Ariakit.TabProvider>
    )
  },
)

// MARK: - TabList

export interface TabListProps
  extends Ariakit.TabListProps,
    React.ComponentPropsWithoutRef<'div'> {}

export const TabList = React.forwardRef<HTMLDivElement, TabListProps>(
  ({className, ...restProps}, forwardedRef) => {
    return (
      <Ariakit.TabList
        ref={forwardedRef}
        className={cn(
          'relative flex h-15 flex-row items-center gap-3 overflow-x-auto border-b',
          className,
        )}
        {...restProps}
      />
    )
  },
)

// MARK: – SelectedTabUnderline

export interface SelectedTabUnderlineProps
  extends React.ComponentPropsWithoutRef<'div'> {}

export const SelectedTabUnderline = React.forwardRef<
  HTMLDivElement,
  SelectedTabUnderlineProps
>(({className, style, ...restProps}, forwardedRef) => {
  const tab = Ariakit.useTabContext()
  const selectedTabEl = Ariakit.useStoreState(
    tab,
    (s) => s?.items.find((i) => i.id === s.selectedId)?.element,
  )
  const selectedTabWidth = selectedTabEl?.getBoundingClientRect().width ?? 0
  const underlineX = selectedTabEl?.offsetLeft ?? 0

  return (
    <div
      ref={forwardedRef}
      role="presentation"
      className={cn(
        'absolute bottom-0 left-0 h-1 rounded-full bg-orange-50 transition-transform ease-linear',
        className,
      )}
      style={{
        transform: `translateX(${underlineX}px)`,
        width: selectedTabWidth,
        ...style,
      }}
      {...restProps}
    />
  )
})

// MARK: - Tab

export interface TabProps
  extends Ariakit.TabProps,
    React.ComponentPropsWithoutRef<'button'> {}

export const Tab = React.forwardRef<HTMLButtonElement, TabProps>(
  ({className, children, ...restProps}, forwardedRef) => {
    return (
      <Ariakit.Tab
        ref={forwardedRef}
        className={cn(
          'text-natural-10 transition-all aria-selected:font-extrabold',
          className,
        )}
        render={<NextButton size="md" variant="transparent" />}
        {...restProps}
      >
        <span
          data-content={getStringFromChildren(children)}
          // HACK: Preserving width when font-width changes
          className="after:invisible after:block after:h-px after:overflow-hidden after:font-extrabold after:text-transparent after:content-[attr(data-content)]"
        >
          {children}
        </span>
      </Ariakit.Tab>
    )
  },
)

// MARK: - TabPanel

export interface TabPanelProps
  extends Ariakit.TabPanelProps,
    React.ComponentPropsWithoutRef<'div'> {}

export const TabPanel = React.forwardRef<HTMLDivElement, TabPanelProps>(
  ({tabId, children, ...restProps}, forwardedRef) => {
    const {lazy} = useContext(InternalTabsContext)
    const tabStore = Ariakit.useTabStore()
    const selectedId = Ariakit.useStoreState(tabStore, (s) => s.selectedId)
    const childrenHasMountedRef = useRef(false)

    const tabPanelVisible = lazy ? selectedId === tabId : true

    if (tabPanelVisible) {
      childrenHasMountedRef.current = true
    }

    return (
      <Ariakit.TabPanel ref={forwardedRef} tabId={tabId} {...restProps}>
        {(tabPanelVisible || childrenHasMountedRef.current) && children}
      </Ariakit.TabPanel>
    )
  },
)
