import {StringParam, useQueryParam, withDefault} from 'use-query-params'
import * as WebUI from '@cheddarup/web-ui'
import {forwardRef} from 'react'

export interface ParammedTabsProps extends WebUI.TabsProps {
  paneKeyName?: string
  defaultPaneKey: string
}

export const ParammedTabs = forwardRef<WebUI.TabsInstance, ParammedTabsProps>(
  (
    {
      paneKeyName = 'p',
      defaultPaneKey,
      children,
      onChangeCurrentId,
      onChangeSelectedId,
      ...restProps
    },
    forwardedRef,
  ) => {
    const [paneKey, setPaneKey] = useQueryParam(
      paneKeyName,
      withDefault(StringParam, defaultPaneKey),
    )
    return (
      <WebUI.Tabs
        ref={forwardedRef}
        selectedId={paneKey}
        currentId={paneKey}
        onChangeCurrentId={(newPaneKey) => {
          setPaneKey(newPaneKey)
          onChangeCurrentId?.(newPaneKey)
        }}
        onChangeSelectedId={(newId) => {
          setPaneKey(newId)
          onChangeSelectedId?.(newId)
        }}
        {...restProps}
      >
        {children}
      </WebUI.Tabs>
    )
  },
)
