import {forwardRef} from 'react'
import {ForwardRefComponent} from '@cheddarup/react-util'

import {Button} from './Button'
import {HStack} from './Stack'
import {
  Tab,
  TabList,
  TabListProps,
  TabProps,
  Tabs,
  TabsInstance,
  TabsProps,
} from './Tabs'
import {cn} from '../utils'

export interface SegmentedTabsProps extends TabsProps {}

export const SegmentedTabs = forwardRef<TabsInstance, SegmentedTabsProps>(
  ({className, ...restProps}, forwardedRef) => (
    <Tabs
      ref={forwardedRef}
      className={cn('SegmentedTabs', className)}
      {...restProps}
      orientation="horizontal"
    />
  ),
)

// MARK: - SegmentedTabList

export interface SegmentedTabListProps extends TabListProps {}

export const SegmentedTabList = forwardRef(
  ({className, as = HStack, ...restProps}, forwardedRef) => (
    <TabList
      ref={forwardedRef}
      className={cn(
        'TabList',
        'gap-2 rounded border-none bg-natural-80 p-1',
        '[&_>_.SegmentedTab]:flex-[1_0_0px]',
        className,
      )}
      as={as}
      {...restProps}
    />
  ),
) as ForwardRefComponent<'div', SegmentedTabListProps>

// MARK: – SegmentedTab

export interface SegmentedTabProps extends TabProps {}

export const SegmentedTab = forwardRef(
  ({className, ...restProps}, forwardedRef) => (
    <Tab
      ref={forwardedRef}
      className={cn(
        'SegmentedTab',
        'h-auto justify-center rounded bg-transparent px-3 py-2 text-center',
        'hover:bg-natural-100 hover:text-tint',
        'aria-selected:bg-natural-100 aria-selected:font-[inherit]',
        className,
      )}
      {...restProps}
    />
  ),
) as ForwardRefComponent<typeof Button, SegmentedTabProps>
