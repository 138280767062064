import * as Util from '@cheddarup/util'
import React from 'react'

const MONTH_DAY_COUNT = 28
const DAYS_PER_ROW_COUNT = 7

export interface MonthDaySelectorProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'onSelect'> {
  onSelect: (monthDay: number) => void
}

const MonthDaySelector = ({onSelect, ...restProps}: MonthDaySelectorProps) => {
  const monthDays = Array.from({length: MONTH_DAY_COUNT}).map(
    (_, monthDay) => monthDay + 1,
  )
  const monthDaysChunked = Util.chunk(monthDays, DAYS_PER_ROW_COUNT)

  return (
    <div {...restProps}>
      {monthDaysChunked.map((rowMonthDays, idx) => (
        <div key={idx} className="flex">
          {rowMonthDays.map((monthDay, jdx) => (
            <div
              key={`${idx}-${jdx}`}
              className="h-8 w-8 cursor-pointer text-right font-normal"
              onClick={() => onSelect(monthDay)}
            >
              {monthDay}
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

export default MonthDaySelector
