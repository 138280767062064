import * as Yup from 'yup'
import {
  BooleanParam,
  StringParam,
  useQueryParam,
  withDefault,
} from 'use-query-params'
import * as Util from '@cheddarup/util'
import {useFormik} from '@cheddarup/react-util'
import {Navigate, useLocation, useNavigate} from 'react-router-dom'
import {useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {Banner} from 'src/components/Banner'

import config from '../../config'
import {useEnhancedUpdateCartMutation} from './hooks/useCart'
import usePublicCollection from './hooks/usePublicCollection'
import {
  PayerContentContainerLayout,
  PayerContentLayout,
  PayerMainBlock,
} from './components/PayerLayouts'
import {CollectionOverview} from './components/CollectionOverview'

const PrePayerVisitorReportPage = () => {
  const location = useLocation()
  const [addPayment] = useQueryParam(
    'add-payment',
    withDefault(BooleanParam, false),
  )
  const [accessCode] = useQueryParam(
    'access-code',
    withDefault(StringParam, ''),
  )
  const [submitErrorMessage, setSubmitErrorMessage] = useState<string | null>(
    null,
  )
  const {publicCollection, updatePublicCollection} = usePublicCollection()
  const [, updateCartAsync] = useEnhancedUpdateCartMutation()
  const navigate = useNavigate()

  const visibleFields = addPayment
    ? ['name']
    : (publicCollection.fields_required ?? []).map((s) => Util.toCamelCase(s))
  const requiredFields = addPayment
    ? ['name']
    : (publicCollection.fields_required ?? []).map((s) => Util.toCamelCase(s))

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      accessCode,
    },
    validationSchema: () =>
      Yup.object().shape(
        Util.mapToObj(visibleFields, (field) => {
          let schema = Yup.string()

          if (requiredFields.includes(field)) {
            schema = schema.required('Required').max(50)
          }
          if (field === 'email') {
            schema = schema.email('Incorrect format')
          }

          return [field, schema]
        }),
      ),
    onSubmit: async (values) => {
      try {
        if (addPayment) {
          await updateCartAsync({
            body: {
              name: values.name,
              email: values.email,
            },
          })

          navigate(
            {
              pathname: '../..',
              search: location.search,
            },
            {
              replace: true,
            },
          )
        } else {
          const nextPublicCollection = await updatePublicCollection({
            name: values.name,
            email: values.email,
            access_code: values.accessCode,
          })

          if (nextPublicCollection.fields_required) {
            throw new Error(
              nextPublicCollection.fields_required.includes('access_code')
                ? 'Sorry, that code is incorrect. Please try again.'
                : 'Something went wrong... Please recheck your info and try again.',
            )
          }
        }
      } catch (err: any) {
        setSubmitErrorMessage(err.message)
      }
    },
  })

  if (
    !addPayment &&
    (!publicCollection.fields_required ||
      publicCollection.fields_required.length === 0)
  ) {
    return (
      <Navigate
        to={{
          pathname: '../..',
          search: location.search,
        }}
        replace
      />
    )
  }

  return (
    <>
      {addPayment && (
        <Banner className="-mt-8">
          You are recording a payment and/or form submission on behalf of
          somebody else
        </Banner>
      )}
      <PayerContentContainerLayout>
        <PayerContentLayout className="justify-center">
          <PayerMainBlock className="grow">
            <CollectionOverview detailsHidden />

            <WebUI.VStack
              className="max-w-full items-center p-8"
              as={WebUI.Panel}
              variant="capsule"
            >
              <WebUI.Form
                className="w-[480px] max-w-full"
                onReset={formik.handleReset}
                onSubmit={formik.handleSubmit}
              >
                {addPayment && (
                  <WebUI.Text>
                    Record a payment and/or form submission on behalf of:
                  </WebUI.Text>
                )}

                {visibleFields.includes('name') && (
                  <WebUI.FormField
                    label="Name"
                    required={requiredFields.includes('name')}
                    error={formik.errors.name}
                  >
                    <WebUI.Input
                      name="name"
                      size="large"
                      autoComplete="name"
                      autoCapitalize="words"
                      placeholder="Name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </WebUI.FormField>
                )}
                {visibleFields.includes('email') && (
                  <WebUI.FormField
                    label="Email"
                    required={requiredFields.includes('email')}
                    error={formik.errors.email}
                  >
                    <WebUI.Input
                      name="email"
                      type="email"
                      size="large"
                      autoComplete="email"
                      placeholder="Email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </WebUI.FormField>
                )}
                {visibleFields.includes('accessCode') && (
                  <WebUI.FormField
                    label="Access Code"
                    error={formik.errors.accessCode}
                  >
                    <WebUI.Input
                      name="accessCode"
                      size="large"
                      placeholder="Access Code"
                      autoComplete="off"
                      autoCapitalize="none"
                      value={formik.values.accessCode}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </WebUI.FormField>
                )}
                {submitErrorMessage && (
                  <div className="text-center font-normal text-ds-sm text-orange-50">
                    {submitErrorMessage}
                  </div>
                )}
                <WebUI.Button
                  className="w-full"
                  type="submit"
                  size="large"
                  variant="primary"
                  roundness="capsule"
                  loading={formik.isSubmitting}
                >
                  Enter
                </WebUI.Button>
                {!addPayment && visibleFields.includes('email') && (
                  <div className="text-ds-xs leading-compact sm:text-center">
                    By providing your email address, you may receive occasional
                    promotional emails. You may unsubscribe at any time.{' '}
                    <WebUI.Anchor
                      target="_blank"
                      rel="noopener noreferrer"
                      href={config.links.privacyPolicy}
                    >
                      Privacy policy
                    </WebUI.Anchor>
                  </div>
                )}
              </WebUI.Form>
            </WebUI.VStack>
          </PayerMainBlock>
        </PayerContentLayout>
      </PayerContentContainerLayout>
    </>
  )
}

export default PrePayerVisitorReportPage
