import {useLiveRef} from '@cheddarup/react-util'
import React, {useImperativeHandle, useRef} from 'react'

import {cn} from '../utils'
import {useEventListener} from '../hooks'

export interface CanvasProps extends React.ComponentPropsWithoutRef<'canvas'> {
  elementRef?: React.Ref<HTMLCanvasElement>
  disabled?: boolean
  readOnly?: boolean
  lineWidth?: number
  strokeStyle?: string
  onDataURLChange?: (dataURL: string | null) => void
  onIsEmptyChange?: (isEmpty: boolean) => void
}

export interface CanvasInstance {
  clear: () => void
  toDataURL: (type?: string, quality?: any) => string | undefined
  drawImage: CanvasDrawImage['drawImage']
  context?: CanvasRenderingContext2D | null
}

export const Canvas = React.forwardRef<CanvasInstance, CanvasProps>(
  (
    {
      elementRef,
      className,
      disabled,
      readOnly,
      lineWidth = 4,
      strokeStyle = '#000000',
      onDataURLChange,
      onIsEmptyChange,
      ...restProps
    },
    forwardedRef,
  ) => {
    const canvasElRef = useRef<HTMLCanvasElement>(null)
    const prevPosRef = useRef({offsetX: 0, offsetY: 0})
    const isPaintingRef = useRef(false)
    const isEmptyRef = useRef(true)
    const onDataURLChangeRef = useLiveRef(onDataURLChange)
    const onIsEmptyChangeRef = useLiveRef(onIsEmptyChange)

    const ctxRef = useLiveRef(canvasElRef.current?.getContext('2d'))

    useImperativeHandle(
      forwardedRef,
      () => ({
        clear: () => {
          if (canvasElRef.current) {
            ctxRef.current?.clearRect(
              0,
              0,
              canvasElRef.current.width,
              canvasElRef.current.height,
            )
          }

          onDataURLChangeRef.current?.(null)

          if (!isEmptyRef.current) {
            isEmptyRef.current = true
            onIsEmptyChangeRef.current?.(true)
          }
        },
        toDataURL: (type, quality) =>
          canvasElRef.current?.toDataURL(type, quality),
        drawImage: ctxRef.current ? ctxRef.current.drawImage : () => {},
        context: ctxRef.current,
      }),
      [],
    )

    function endPaintEvent() {
      if (isPaintingRef.current) {
        onDataURLChangeRef.current?.(canvasElRef.current?.toDataURL() ?? null)
      }

      isPaintingRef.current = false
    }

    const documentRef = useRef<Document>(document)
    useEventListener('pointerup', endPaintEvent, documentRef)
    useEventListener('pointercancel', endPaintEvent, documentRef)

    function normalizePos(pos: {offsetX: number; offsetY: number}) {
      if (!ctxRef.current || !canvasElRef.current) {
        return pos
      }

      const ratioX =
        ctxRef.current.canvas.width / canvasElRef.current.clientWidth
      const ratioY =
        ctxRef.current.canvas.height / canvasElRef.current.clientHeight

      return {
        offsetX: ratioX * pos.offsetX,
        offsetY: ratioY * pos.offsetY,
      }
    }

    return (
      <canvas
        ref={(ref) => {
          ;(canvasElRef as any).current = ref

          if (typeof elementRef === 'function') {
            elementRef?.(ref)
          } else if (elementRef) {
            ;(elementRef as any).current = ref
          }
        }}
        aria-readonly={readOnly}
        aria-disabled={disabled}
        className={cn(
          'Canvas cursor-crosshair touch-none aria-disabled:pointer-events-none aria-readonly:pointer-events-none',
          className,
        )}
        onPointerDown={(event) => {
          isPaintingRef.current = true

          prevPosRef.current = normalizePos({
            offsetX: event.nativeEvent.offsetX,
            offsetY: event.nativeEvent.offsetY,
          })
        }}
        onPointerMove={(event) => {
          if (isPaintingRef.current) {
            if (isEmptyRef.current) {
              isEmptyRef.current = false
              onIsEmptyChangeRef.current?.(false)
            }

            if (disabled || readOnly) {
              return
            }

            const prevPos = prevPosRef.current
            const currPos = normalizePos({
              offsetX: event.nativeEvent.offsetX,
              offsetY: event.nativeEvent.offsetY,
            })

            const ctx = ctxRef.current
            if (ctx) {
              ctx.lineJoin = 'round'
              ctx.lineCap = 'round'
              ctx.lineWidth = lineWidth
              ctx.strokeStyle = strokeStyle

              ctx.beginPath()
              ctx.moveTo(prevPos.offsetX, prevPos.offsetY)
              ctx.lineTo(currPos.offsetX, currPos.offsetY)
              ctx.stroke()

              prevPosRef.current = currPos
            }
          }
        }}
        {...restProps}
      />
    )
  },
)
