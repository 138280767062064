import {randomInteger} from 'remeda'

export {nanoid as makeShortId} from 'nanoid'
export {nanoid as makeNonSecureShortId} from 'nanoid/non-secure'
export {v4 as makeUuid} from 'uuid'

export function makeUniqueIntGenerator(min = 0, max = Number.MAX_SAFE_INTEGER) {
  const usedIntSet = new Set()

  return function makeUniqueInt() {
    const randomInt = randomInteger(min, max)
    if (usedIntSet.has(randomInt)) {
      return makeUniqueInt()
    }

    usedIntSet.add(randomInt)

    return randomInt
  }
}
