import Papa from 'papaparse'
import React, {useMemo, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {getAttendeeName} from '@cheddarup/core'
import {PaymentNoteButtonDropdown, SearchForm} from 'src/components'
import {Link} from 'src/components/Link'
import {TicketActionsMenu} from 'src/components/TicketActionsMenu'
import {PaymentItemTableView} from 'src/components/PaymentItemTableView'
import {encodeToBase36} from '@cheddarup/util'

export interface ItemPaymentsTableProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collectionId: number
  data?: Api.TabItemReportPaymentItem[]
}

const TicketPaymentsTable: React.FC<ItemPaymentsTableProps> = ({
  collectionId,
  data,
  ...restProps
}) => {
  const refundLinkClassNames = 'text-ds-sm text-tint'
  const [search, setSearch] = useState('')

  const items = useMemo(
    () =>
      data?.filter(
        (item) =>
          item.payment.tab_member.name?.includes(search) ||
          getAttendeeName(item.item_field_views).includes(search),
      ) ?? [],
    [data, search],
  )

  const columnHelper = useMemo(
    () => WebUI.createColumnHelper<Api.TabItemReportPaymentItem>(),
    [],
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const columns = useMemo(
    () =>
      [
        columnHelper.accessor((pi) => getAttendeeName(pi.item_field_views), {
          id: 'attendees',
          meta: {
            subtle: false,
          },
          header: 'Attendees',
          cell: ({cell, row: {original: pi}}) => (
            <WebUI.VStack>
              <p>{cell.getValue()}</p>
              {pi.detail?.organizerNote && (
                <WebUI.DeprecatedTooltip
                  className="cursor-pointer"
                  label={pi.detail.organizerNote}
                >
                  <PaymentNoteButtonDropdown
                    className="text-left"
                    variant="text"
                    collectionId={collectionId}
                    itemPaymentItem={pi as any as Api.TabItemPayment}
                  >
                    <span className="line-clamp-1 whitespace-normal pr-[3px] font-light text-ds-sm text-gray600 italic">
                      {pi.detail.organizerNote}
                    </span>
                  </PaymentNoteButtonDropdown>
                </WebUI.DeprecatedTooltip>
              )}
            </WebUI.VStack>
          ),
        }),
        columnHelper.accessor((pi) => pi.payment.tab_member.name, {
          id: 'payerName',
          meta: {
            subtle: false,
          },
          header: 'Payment by',
          cell: ({cell, row: {original: pi}}) => (
            <WebUI.VStack>
              <Link
                className="text-ds-sm"
                variant="primary"
                to={`payment/${pi.payment_id}/order-summary`}
              >
                <p>{cell.getValue()}</p>
              </Link>

              {pi.refund_data &&
                (pi.refund_data.total_refunded_cents ?? 0) >= 0 && (
                  <p className={refundLinkClassNames}>
                    {(pi.refund_data?.net_amount_cents ?? 0) > 0
                      ? 'Partially Refunded'
                      : 'Refunded'}
                  </p>
                )}
            </WebUI.VStack>
          ),
        }),
        columnHelper.accessor((pi) => encodeToBase36(pi.id), {
          id: 'ticket',
          size: 80,
          header: 'Ticket #',
        }),
        columnHelper.accessor((pi) => pi.detail.ticketStatus, {
          id: 'status',
          size: 80,
          header: 'Status',
          cell: ({cell}) => (
            <span className="capitalize">
              {cell.getValue() === 'unused' ? 'active' : cell.getValue()}
            </span>
          ),
        }),
        columnHelper.display({
          id: 'actions',
          minSize: 60,
          size: 60,
          meta: {
            align: 'center',
          },
          header: '',
          cell: ({row: {original: pi}}) => (
            <TicketActionsMenu
              className="text-ds-sm"
              paymentItem={pi as any as Api.TabItemPayment}
              paymentType="tab"
            >
              View
            </TicketActionsMenu>
          ),
        }),
      ].filter((c) => c != null),
    [collectionId, refundLinkClassNames, columnHelper],
  )

  return (
    <WebUI.Panel {...restProps}>
      <div
        className={
          'flex flex-col items-stretch justify-center gap-4 border-b px-8 py-4 sm:flex-row sm:items-center sm:justify-between'
        }
      >
        <SearchForm
          className="min-w-[250px] text-ds-xs placeholder:text-ds-xs"
          iconClassName="text-gray400"
          size="compact"
          initialValues={{term: ''}}
          onSubmit={(values) => {
            if (values.term !== search) {
              setSearch(values.term)
            }
          }}
          noResult={items.length === 0}
          placeholder="Search by attendee or payer name"
        />
        <WebUI.DeprecatedTooltip label="Download CSV file">
          <WebUI.Button
            size="compact"
            variant="secondary"
            iconAfter={<WebUI.PhosphorIcon icon="download-simple" width={20} />}
            onClick={() =>
              WebUI.downloadFile(
                new Blob([makeTicketAttendeesCSV(items)], {
                  type: 'data:text/csv;charset=utf-8',
                }),
                'attendee_list.csv',
              )
            }
          >
            Attendee List
          </WebUI.Button>
        </WebUI.DeprecatedTooltip>
      </div>

      {items.length > 0 ? (
        <PaymentItemTableView
          className={
            '[&_.TableView-headerGroup]:px-8 [&_.TableViewCell_>_.Stack]:py-0 [&_.TableViewRow]:px-8 [&_>_.TableView-headerGroupList_>_.TableView-headerGroup]:border-b'
          }
          manualPagination
          columns={columns}
          data={items}
        />
      ) : (
        <div className="h-[240px]" />
      )}
    </WebUI.Panel>
  )
}

// MARK: – Helpers

const makeTicketAttendeesCSV = (items: Api.TabItemReportPaymentItem[]) => {
  const rows = items.flatMap((item) => ({
    attendee: getAttendeeName(item.item_field_views),
    payer: item.payment.tab_member.name,
    ticket: encodeToBase36(item.id),
    status: item.detail.ticketStatus,
  }))

  const csvRows = rows.map((row) => [
    row.attendee,
    row.payer,
    row.ticket,
    row.status,
  ])

  const fields = ['Attendees', 'Payment by', 'Ticket', 'Status'].filter(
    (f) => f != null,
  )

  return Papa.unparse({
    fields,
    data: csvRows,
  })
}

export default TicketPaymentsTable
