import {useState} from 'react'
import {
  FormikConfig,
  FormikValues,
  useFormik as useFormikPrimitive,
} from 'formik'

export function useFormik<T extends FormikValues>({
  validateOnBlur,
  validateOnChange,
  ...config
}: FormikConfig<T>) {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const formik = useFormikPrimitive<T>({
    validateOnBlur: validateOnBlur ?? isSubmitted,
    validateOnChange: validateOnChange ?? isSubmitted,
    ...config,
  })

  const _isSubmitted = formik.submitCount > 0
  if (_isSubmitted !== isSubmitted) {
    setIsSubmitted(_isSubmitted)
  }

  return formik
}
