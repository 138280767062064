import * as WebUI from '@cheddarup/web-ui'

export const PaymentItemTableView = <D extends object>({
  className,
  sortable = true,
  sortByTogglesVisible = true,
  ...restProps
}: WebUI.TableViewProps<D>) => (
  <WebUI.TableView<D>
    className={WebUI.cn(
      '[&_.TableViewCell_>_*]:py-0 [&_.TableViewRow]:text-ds-sm [&_>_.TableView-headerGroupList_>_.TableView-headerGroup]:border-b-0',
      className,
    )}
    sortable={sortable}
    sortByTogglesVisible={sortByTogglesVisible}
    {...restProps}
  />
)
