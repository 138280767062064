import {PlanUpgradeButton} from 'src/components/PremiumFeaturesSideSheet'
import {
  AccountSettingsContentCard,
  AccountSettingsContentLayout,
} from './components/AccountSettingsContentLayouts'
import * as WebUI from '@cheddarup/web-ui'
import {uploadImage} from '@cheddarup/core'
import {
  api,
  useDeleteBrandingLogoMutation,
  useUpdateBrandingMutation,
} from '@cheddarup/api-client'
import {useNavigate} from 'react-router-dom'
import {useState} from 'react'
import {NextLink} from 'src/components/__next/Link'
import BrandKitSettingsDisclosure from './components/BrandKitSettingsDisclosure'
import {useManagerRoleId} from 'src/components/ManageRoleProvider'

const BrandKitLogoPage = () => {
  const navigate = useNavigate()
  const [managerRoleId] = useManagerRoleId()
  const {data: branding} = api.userBrandings.detail.useQuery()
  const {data: notSubscribedToTeam} = api.auth.session.useQuery(undefined, {
    select: (session) => !session.capabilities.subscribed_to_team,
  })
  const updateBrandingMutation = useUpdateBrandingMutation()

  return (
    <AccountSettingsContentLayout
      heading={
        <div className="flex flex-row items-center gap-4">
          <WebUI.Heading as="h2">Logo</WebUI.Heading>
          {notSubscribedToTeam && !managerRoleId && (
            <PlanUpgradeButton upgradeTo="team" asPaidBadge />
          )}
        </div>
      }
      body={
        <span>
          Put your organization front and center with premium branding. Upload a
          logo that will appear in the page header of all of your collection
          pages and Group Page. You can also use this logo to customize{' '}
          <NextLink to="../brand-kit-email">emails</NextLink>.
        </span>
      }
    >
      <WebUI.Panel className="px-6 py-5">
        <BrandKitSettingsDisclosure
          label="Replace Cheddar Up Logo"
          visible={branding?.enable_brand_logo}
          onVisibleChange={(visible) =>
            updateBrandingMutation.mutate({
              body: {
                enable_brand_logo: visible,
              },
            })
          }
          onClick={(event) => {
            if (notSubscribedToTeam && !managerRoleId) {
              event.preventDefault()
              navigate('i/plans?recommendedPlan=team')
            }
          }}
        >
          <WebUI.Separator className="-mx-6" />
          <UploadBrandLogo logo={branding?.logo} />
        </BrandKitSettingsDisclosure>
      </WebUI.Panel>
    </AccountSettingsContentLayout>
  )
}

// MARK: – UploadBrandLogo

interface UploadBrandLogoProps extends React.ComponentPropsWithoutRef<'div'> {
  logo: Api.UserBranding['logo']
}

const UploadBrandLogo = ({
  logo,
  className,
  ...restProps
}: UploadBrandLogoProps) => {
  const [uploading, setUploading] = useState(false)
  const updateBrandingMutation = useUpdateBrandingMutation()
  const deleteBrandingLogoMutation = useDeleteBrandingLogoMutation()

  return (
    <AccountSettingsContentCard
      heading="Logo"
      className={WebUI.cn('gap-4 border-0 p-0', className)}
      {...restProps}
    >
      <WebUI.Text className="font-light text-ds-sm">
        Upload a logo to appear on all collection pages and your Group Page.
        This logo can also be applied to custom-branded{' '}
        <NextLink to="../brand-kit-email">emails</NextLink>.
      </WebUI.Text>
      <WebUI.FileUploader
        accept={{'image/*': []}}
        multiple={false}
        onDropAccepted={async ([newImage]) => {
          if (newImage) {
            setUploading(true)
            const image = await uploadImage(
              'users/branding',
              newImage,
              undefined,
              {
                metadata: {
                  contentType: newImage.type,
                },
                userId: undefined,
              },
            )
            await updateBrandingMutation.mutateAsync({
              body: {
                logo_id: image?.id,
              },
            })
            setUploading(false)
          }
        }}
      >
        <WebUI.FileUploaderDropzone className="flex w-full max-w-[280px] flex-row items-center justify-center border py-5">
          {uploading ? (
            <WebUI.Loader />
          ) : logo ? (
            <div className="relative">
              <WebUI.Image alt="" src={logo.url} />
              <WebUI.IconButton
                variant="secondary"
                className="-top-4 absolute right-1"
                size="default_alt"
                loading={deleteBrandingLogoMutation.isPending}
                onClick={async (e) => {
                  e.stopPropagation()
                  if (logo.id) {
                    await deleteBrandingLogoMutation.mutateAsync({
                      pathParams: {id: logo.id},
                    })
                  }
                }}
              >
                <WebUI.PhosphorIcon icon="x" />
              </WebUI.IconButton>
            </div>
          ) : (
            <WebUI.PhosphorIcon className="text-8xl" icon="upload-simple" />
          )}
        </WebUI.FileUploaderDropzone>
        <WebUI.FileUploaderInput />
      </WebUI.FileUploader>
      <span className="font-body text-ds-xs text-gray600">
        We recommend a horizontal format for your logo.
      </span>
    </AccountSettingsContentCard>
  )
}

export default BrandKitLogoPage
