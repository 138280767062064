import {forwardRef} from 'react'
import * as WebUI from '@cheddarup/web-ui'

export const ErrorModal = forwardRef<WebUI.DialogInstance, WebUI.AlertProps>(
  (props, forwardedRef) => (
    <WebUI.Alert
      aria-label="Items in cart sold out"
      ref={forwardedRef}
      {...props}
    >
      <WebUI.AlertHeader>Item availability has changed</WebUI.AlertHeader>
      <WebUI.AlertContentView
        text={
          <>
            <span className="font-semibold">
              Your order has not been processed.
            </span>{' '}
            Items have been removed from your cart, please review your cart
            contents before processing this payment. Although a hold may appear
            on your card, it has not been charged.
          </>
        }
      />
    </WebUI.Alert>
  ),
)
