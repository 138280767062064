import * as Util from '@cheddarup/util'
import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import ImagesUtils from 'src/helpers/ImagesUtils'
import CartHelpers from 'src/helpers/CartHelpers'
import EmptyCartIcon from 'src/images/EmptyCartIcon.svg'
import {LinkButton} from 'src/components/LinkButton'

import useCart from '../../hooks/useCart'
import usePublicCollection from '../../hooks/usePublicCollection'
import {
  CartObjectDetailsDisclosure,
  CartOverviewFormRow,
  CartOverviewSignUpRow,
} from '../../components/CartOverview'
import {usePayerUIState} from '../../PayerUIStateProvider'
import {isFormsBeforeItems} from '../../utils/public-collection-utils'
import {PayerContinueButton} from '../../components/PayerContinueButton'
import {RecurringPaymentIndicator} from '../../components/RecurringPaymentIndicator'
import {useCurrentPayerSegment} from '../../utils/payer-navigation-utils'
import {
  formatQuantityDiscount,
  getVisibleCartForms,
  hasMissingRequiredFormFields,
  hasMissingRequiredForms,
  hasMissingRequiredItems,
  hasMissingRequiredSignups,
} from '@cheddarup/core'
import {RemoveCartItemButton} from '../../components'
import {Text} from '@cheddarup/web-ui/next'
import {useItemPriceWithFee} from '../../hooks/useItemPriceWithFee'

const CartInfoContainer: React.FC<React.ComponentPropsWithoutRef<'div'>> = ({
  className,
  ...restProps
}) => {
  const {publicCollection} = usePublicCollection()
  const {cart} = useCart()
  const payerUIState = usePayerUIState()
  const currentPayerSegment = useCurrentPayerSegment()

  const isItemlessCollection = publicCollection.items.length === 0
  const isCartEmpty = CartHelpers.getRecordsCount(cart) === 0
  const isCartFormsOnly =
    cart?.items.length === 0 && getVisibleCartForms(cart).length > 0
  const feeTransparencyEnabled = publicCollection.fee_transparency
  const perOrderConvenienceFee = feeTransparencyEnabled
    ? publicCollection.fee_structure.fixedCents / 100
    : 0
  const processingFee = feeTransparencyEnabled ? (cart?.fees?.card ?? 0) : 0

  return (
    <WebUI.Panel
      className={WebUI.cn(
        'flex h-full min-w-[240px] flex-col gap-4 p-8',
        className,
      )}
      variant="capsule"
      {...restProps}
    >
      <div className="flex flex-col gap-1">
        <WebUI.Heading className="font-bold text-ds-lg" as="h2">
          {isCartFormsOnly ? 'Summary' : 'Order Summary'}
        </WebUI.Heading>
        {cart?.items.some((i) => i.tab_item.available_quantity != null) && (
          <WebUI.Text className="text-ds-sm text-orange-50">
            Items with a limited quantity are only secured once checkout is
            complete.
          </WebUI.Text>
        )}
      </div>

      <WebUI.Separator />

      <div className="flex grow flex-col gap-4 overflow-hidden text-trueBlack">
        {isCartEmpty ? (
          <div className="flex h-[160px] w-full flex-col justify-center gap-3 py-8">
            <img className="h-[3.4em]" src={EmptyCartIcon} alt="Empty cart" />
            <WebUI.Text className="text-center text-ds-sm">
              Your cart is empty
            </WebUI.Text>
          </div>
        ) : (
          <>
            <div className="flex grow flex-col gap-6 overflow-y-auto sm:max-h-[calc(100vh-470px)] sm:min-h-[88px] [&_>_.Stack]:flex-0">
              {cart &&
                getVisibleCartForms(cart).map((cartForm) => (
                  <CartOverviewFormRow
                    key={cartForm.id}
                    collectionSlug={publicCollection.slug}
                    cart={cart}
                    cartForm={cartForm}
                  />
                ))}
              {CartHelpers.getSignUps(cart).map((cartSignUp) => (
                <CartOverviewSignUpRow
                  key={cartSignUp.signUp.id}
                  collectionSlug={publicCollection.slug}
                  cartUuid={cart?.uuid ?? ''}
                  cartSignUp={cartSignUp}
                />
              ))}
              {Util.sort(cart?.items ?? [])
                .asc((ci) => ci.id)
                .map((cartItem, idx) => {
                  const itemView = publicCollection.items.find(
                    ({id}) => id === cartItem.tab_item.id,
                  )
                  const hasEditableVariants =
                    !!itemView?.options.variants?.enabled &&
                    itemView.options.variants.options.some(
                      ({values}) => values.length > 1,
                    )

                  return (
                    <React.Fragment key={cartItem.id}>
                      {idx > 0 && <WebUI.Separator variant="primary" />}
                      <CartInfoItemListItemContainer
                        cart={cart}
                        allowQuantity={itemView?.allow_quantity ?? false}
                        hasItemViewFieldViews={
                          !!itemView && itemView.fields?.length > 0
                        }
                        hasEditableVariants={hasEditableVariants}
                        cartItem={cartItem}
                      />
                    </React.Fragment>
                  )
                })}
            </div>
            {!isItemlessCollection && cart && (
              <div className="flex flex-col gap-2">
                <WebUI.Separator />
                <WebUI.Text className="flex justify-between text-ds-sm text-gray750">
                  <span>Subtotal:</span>
                  <span className="font-semibold">
                    {Util.formatAmount(
                      cart.subtotal + processingFee - perOrderConvenienceFee,
                    )}
                  </span>
                </WebUI.Text>
                {feeTransparencyEnabled && (
                  <>
                    <WebUI.Text className="flex justify-between text-ds-sm text-gray750">
                      <span>Per-Order Convenience Fee</span>
                      <span className="font-semibold">
                        {Util.formatAmount(perOrderConvenienceFee)}
                      </span>
                    </WebUI.Text>
                    <WebUI.Separator />
                    <WebUI.Text className="py-4 text-right font-extrabold text-ds-sm">
                      <span>Total:</span>{' '}
                      <span className="font-extrabold">
                        {Util.formatAmount(cart.total + processingFee)}
                      </span>
                    </WebUI.Text>
                  </>
                )}
              </div>
            )}
          </>
        )}
        <div className="flex flex-0 flex-col gap-4">
          {currentPayerSegment === 'items' &&
          hasMissingRequiredItems({publicTab: publicCollection, cart}) ? (
            <>
              <div className="text-center font-normal text-ds-sm text-orange-50">
                Additional items are required to proceed to checkout
              </div>
              <WebUI.Button
                className="w-full"
                size="large"
                variant="primary"
                roundness="capsule"
                onClick={() => {
                  payerUIState.setCartVisible(false)
                  payerUIState.itemPickerRef.current?.viewRequiredItems()
                }}
              >
                View Required Items
              </WebUI.Button>
            </>
          ) : currentPayerSegment === 'forms' &&
            (hasMissingRequiredForms({
              publicTab: publicCollection,
              cart,
            }) ||
              hasMissingRequiredFormFields({
                publicTab: publicCollection,
                cart,
              }) ||
              hasMissingRequiredSignups({
                publicTab: publicCollection,
                cart,
              })) ? (
            <>
              <div className="text-center font-normal text-ds-sm text-orange-50">
                Additional forms are required to proceed to checkout
              </div>
              <WebUI.Button
                className="w-full"
                size="large"
                variant="primary"
                roundness="capsule"
                onClick={() => {
                  payerUIState.setCartVisible(false)
                  payerUIState.formPickerRef.current?.viewRequiredForms()
                }}
              >
                View Required Forms
              </WebUI.Button>
            </>
          ) : publicCollection?.requirePayment &&
            !CartHelpers.hasNonZeroAmountItems(cart) ? (
            <>
              <WebUI.Text className="text-center text-ds-sm text-orange-50">
                An item is required to proceed to checkout.
              </WebUI.Text>
              <LinkButton
                variant="primary"
                roundness="capsule"
                preserveSearch
                to="../items"
                relative="nonContextualPath"
                onClick={() => payerUIState.setCartVisible(false)}
              >
                Add an item
              </LinkButton>
            </>
          ) : currentPayerSegment === 'checkout' ? null : (
            <>
              {!isItemlessCollection &&
                !isFormsBeforeItems(publicCollection) && (
                  <WebUI.Button
                    size="large"
                    variant="secondary"
                    roundness="capsule"
                    onClick={() => payerUIState.setCartVisible(false)}
                  >
                    Keep Shopping
                  </WebUI.Button>
                )}
              <PayerContinueButton
                size="large"
                disabled={isCartEmpty}
                onClick={() => payerUIState.setCartVisible(false)}
              />
            </>
          )}
        </div>
      </div>
    </WebUI.Panel>
  )
}

// MARK: – CartInfoItemListItemContainer

interface CartInfoItemListItemContainerProps
  extends React.ComponentPropsWithoutRef<'div'> {
  cart: Api.Cart | undefined
  allowQuantity: boolean
  hasItemViewFieldViews: boolean
  hasEditableVariants: boolean
  cartItem: Api.CheddarUpCartItem
}

const CartInfoItemListItemContainer = ({
  allowQuantity,
  cart,
  hasItemViewFieldViews,
  hasEditableVariants,
  cartItem,
  className,
  ...restProps
}: CartInfoItemListItemContainerProps) => {
  const {publicCollection} = usePublicCollection()
  const {total: itemPriceWithFees} = useItemPriceWithFee(cartItem.total)

  return (
    <div
      className={WebUI.cn('flex flex-col gap-3 font-normal', className)}
      {...restProps}
    >
      <div className="flex flex-col gap-1">
        <WebUI.Heading as="h4">{cartItem.tab_item.name}</WebUI.Heading>
        {cartItem.tab_item.options.recurring?.enabled && (
          <RecurringPaymentIndicator />
        )}
        <div className="flex flex-row items-center gap-1">
          <WebUI.Text className="min-h-0 min-w-0 flex-auto text-ds-sm">
            Qty: {cartItem.quantity}
          </WebUI.Text>
          <WebUI.Text className="text-right text-ds-sm">
            {cartItem.tab_item.amount_type === 'fixed' &&
              !!cartItem.tab_item.retailPrice &&
              cartItem.amount !== cartItem.tab_item.retailPrice && (
                <WebUI.Text className="mr-2 font-light text-gray400 line-through">
                  {Util.formatAmount(
                    cartItem.tab_item.retailPrice * cartItem.quantity,
                  )}
                </WebUI.Text>
              )}
            {cartItem.detail.itemDiscount &&
              cartItem.tab_item.amount !== cartItem.amount && (
                <WebUI.Text className="mr-2 line-through">
                  {Util.formatAmount(cartItem.tab_item.amount)}
                </WebUI.Text>
              )}
            <WebUI.Text>{Util.formatAmount(itemPriceWithFees)}</WebUI.Text>
          </WebUI.Text>
        </div>
        {cartItem.tab_item.options.quantityDiscount?.enabled && (
          <Text className="font-bold text-ds-xs text-orange-500">
            DEAL:{' '}
            {formatQuantityDiscount(cartItem.tab_item.options.quantityDiscount)}
          </Text>
        )}
      </div>
      <CartObjectDetailsDisclosure
        collectionSlug={publicCollection.slug}
        cartObject={cartItem}
        maxVisibleFieldsCount={3}
        cart={cart}
        isFeeTransparencyEnabled={publicCollection.fee_transparency}
      />
      {cartItem.tab_item.images.length > 0 && (
        <img
          className="w-16"
          alt={cartItem.tab_item.name}
          src={
            ImagesUtils.getItemMainThumbnailUrl(
              cartItem.tab_item.images,
              {
                width: 240,
                height: 240,
              },
              cartItem.detail?.variant?.imageId,
            ) as any
          }
        />
      )}
      <div className="flex flex-row gap-3">
        <RemoveCartItemButton
          cart={cart}
          cartItemId={cartItem.id}
          collectionSlug={publicCollection.slug}
        >
          Remove
        </RemoveCartItemButton>
        {(allowQuantity ||
          cartItem.tab_item.amount_type === 'open' ||
          hasItemViewFieldViews ||
          hasEditableVariants) && (
          <LinkButton
            size="compact"
            variant="secondary"
            preserveSearch
            to={{
              pathname: `../items/item/${cartItem.tab_item.id}`,
              search: `ciId=${cartItem.id}`,
            }}
            relative="nonContextualPath"
          >
            Edit
          </LinkButton>
        )}
      </div>
    </div>
  )
}

export default CartInfoContainer
