import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'

const FIELD_CHOICE_DELIMITER = '|||'

const getCollectionFieldValueFormatted = ({
  field_type: fieldType,
  value,
  timeZone,
}: {
  field_type: Api.TabObjectFieldType
  value: string
  timeZone?: string
}) => {
  switch (fieldType) {
    case 'checkbox':
      return value.split(FIELD_CHOICE_DELIMITER).join(', ')
    case 'date':
      return Util.nativeFormatDate(value, {timeZone}) ?? ''
    case 'time':
      return Util.formatDateAs(value, 'time') ?? ''
    case 'phone':
      try {
        const phoneNumber = WebUI.parsePhoneNumber(value)
        return phoneNumber?.isValid() ? phoneNumber.formatNational() : value
      } catch {
        return value
      }
    default:
      return value
  }
}

export default getCollectionFieldValueFormatted
