import {Navigator, UNSAFE_NavigationContext} from 'react-router-dom'
import React, {useContext, useEffect, useRef, useState} from 'react'

export interface RouteBlockProps {
  when: (...args: Parameters<Navigator['push']>) => boolean
  children?:
    | ((options: {
        isBlockerVisible: boolean
        onBlockerDidHide: () => void
        onProceed: () => void
      }) => React.ReactNode)
    | React.ReactNode
}

// Based on https://github.com/remix-run/react-router/issues/8139#issuecomment-977790637
export const RouteBlock = ({when, children}: RouteBlockProps) => {
  const [isBlockerVisible, setIsBlockerVisible] = useState(false)
  const proceedRef = useRef<(() => void) | null>(null)

  useConfirmExit((originalPush, ...args) => {
    const res = when(...args)
    setIsBlockerVisible(res)

    proceedRef.current = () => {
      originalPush(...args)
    }

    return !res
  }, true)

  return (
    <>
      {typeof children === 'function'
        ? children({
            isBlockerVisible,
            onBlockerDidHide: () => setIsBlockerVisible(false),
            onProceed: () => proceedRef.current?.(),
          })
        : children}
    </>
  )
}

// MARK: – Helpers

// Based on https://gist.github.com/MarksCode/64e438c82b0b2a1161e01c88ca0d0355
function useConfirmExit(
  confirmExit: (
    originalPush: Navigator['push'],
    ...params: Parameters<Navigator['push']>
  ) => boolean,
  when = true,
) {
  const {navigator} = useContext(UNSAFE_NavigationContext)

  useEffect(() => {
    if (!when) {
      return
    }

    const push = navigator.push

    navigator.push = (...args: Parameters<typeof push>) => {
      const result = confirmExit(push, ...args)
      if (result !== false) {
        push(...args)
      }
    }

    return () => {
      navigator.push = push
    }
  }, [navigator, confirmExit, when])
}
