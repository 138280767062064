// Based on https://github.com/samuelmeuli/react-magnifier/blob/master/src/Magnifier.tsx
import {debounce, throttle} from '@cheddarup/util'
import {forwardRef, useCallback, useEffect, useRef, useState} from 'react'
import {ForwardRefComponent} from '@cheddarup/react-util'

import {cn} from '../utils'

export interface ImageMagnifierProps {
  rootClassName?: string
  zoomFactor?: number
  mgWidth?: number
  mgHeight?: number
  mgBorderWidth?: number
}

export const ImageMagnifier = forwardRef(
  (
    {
      rootClassName,
      as: Comp = 'img',
      src,
      width = '100%',
      height = 'auto',
      zoomFactor = 1.5,
      mgWidth = 150,
      mgHeight = 150,
      mgBorderWidth = 2,
      className,
      ...restProps
    },
    forwardedRef,
  ) => {
    const [showZoom, setShowZoom] = useState(false)
    const [mgOffsetX, setMgOffsetX] = useState(0)
    const [mgOffsetY, setMgOffsetY] = useState(0)
    const [relX, setRelX] = useState(0)
    const [relY, setRelY] = useState(0)
    const imgRef = useRef<HTMLImageElement>(null)
    const imgBoundsRef = useRef<DOMRect | null>(null)

    const calcImgBounds = useCallback(() => {
      if (imgRef.current) {
        imgBoundsRef.current = imgRef.current.getBoundingClientRect()
      }
    }, [])

    useEffect(() => {
      const handleMouseMoveThrottled = throttle((event: MouseEvent) => {
        if (imgBoundsRef.current) {
          const target = event.target as HTMLElement
          const newRelX =
            (event.clientX - imgBoundsRef.current.left) / target.clientWidth
          const newRelY =
            (event.clientY - imgBoundsRef.current.top) / target.clientHeight

          setMgOffsetX(0)
          setMgOffsetY(0)
          setRelX(newRelX)
          setRelY(newRelY)
          setShowZoom(true)
        }
      }, 20)

      const setShowZoomToFalse = () => setShowZoom(false)

      const handleTouchStart = (event: TouchEvent) => {
        event.preventDefault()
        calcImgBounds()
      }

      const handleTouchMoveThrottled = throttle((event: TouchEvent) => {
        // Disable scroll on touch
        event.preventDefault()

        if (imgBoundsRef.current) {
          const target = event.target as HTMLElement
          const newRelX =
            ((event.targetTouches[0]?.clientX ?? 0) -
              imgBoundsRef.current.left) /
            target.clientWidth
          const newRelY =
            ((event.targetTouches[0]?.clientY ?? 0) -
              imgBoundsRef.current.top) /
            target.clientHeight

          // Only show magnifying glass if touch is inside image
          if (newRelX >= 0 && newRelY >= 0 && newRelX <= 1 && newRelY <= 1) {
            setMgOffsetX(-50)
            setMgOffsetY(-50)
            setRelX(newRelX)
            setRelY(newRelY)
            setShowZoom(true)
          } else {
            setShowZoom(false)
          }
        }
      }, 20)

      const img = imgRef.current
      img?.addEventListener('mouseenter', calcImgBounds, {passive: false})
      img?.addEventListener('mousemove', handleMouseMoveThrottled, {
        passive: false,
      })
      img?.addEventListener('mouseout', setShowZoomToFalse, {passive: false})
      img?.addEventListener('touchstart', handleTouchStart, {passive: false})
      img?.addEventListener('touchmove', handleTouchMoveThrottled, {
        passive: false,
      })
      img?.addEventListener('touchend', setShowZoomToFalse, {passive: false})

      return () => {
        img?.removeEventListener('mouseenter', calcImgBounds)
        img?.removeEventListener('mousemove', handleMouseMoveThrottled)
        img?.removeEventListener('mouseout', setShowZoomToFalse)
        img?.removeEventListener('touchstart', handleTouchStart)
        img?.removeEventListener('touchmove', handleTouchMoveThrottled)
        img?.removeEventListener('touchend', setShowZoomToFalse)
      }
    }, [calcImgBounds])

    useEffect(() => {
      const calcImgBoundsDebounced = debounce(calcImgBounds, 200)

      // Re-calculate image bounds on window resize
      window.addEventListener('resize', calcImgBoundsDebounced)
      // Re-calculate image bounds on scroll (useCapture: catch scroll events in entire DOM)
      window.addEventListener('scroll', calcImgBoundsDebounced, true)

      return () => {
        window.removeEventListener('resize', calcImgBoundsDebounced)
        window.removeEventListener('scroll', calcImgBoundsDebounced, true)
      }
    }, [calcImgBounds])

    return (
      <div
        ref={forwardedRef}
        className={cn(
          'ImageMagnifier',
          'relative overflow-hidden',
          rootClassName,
        )}
        style={{width, height}}
      >
        <Comp
          ref={imgRef}
          className={cn('ImageMagnifier-image', 'cursor-none', className)}
          src={src}
          width="100%"
          height="100%"
          onLoad={() => calcImgBounds()}
          {...restProps}
        />
        {imgBoundsRef.current && (
          <div
            aria-hidden={!showZoom}
            className={
              'ImageMagnifier-magnifyingGlass pointer-events-none absolute rounded-full border-[#e5e5e5] bg-[#e5e5e5] bg-no-repeat opacity-100 shadow-[2px_2px_3px_rgba(0,0,0,0.3)] transition-opacity duration-300 aria-hidden:opacity-0'
            }
            style={{
              width: mgWidth,
              height: mgHeight,
              left: `calc(${relX * 100}% - ${
                mgWidth / 2
              }px + ${mgOffsetX}px - ${mgBorderWidth}px)`,
              top: `calc(${relY * 100}% - ${
                mgHeight / 2
              }px + ${mgOffsetY}px - ${mgBorderWidth}px)`,
              backgroundImage: `url("${src}")`,
              backgroundPosition: `calc(${relX * 100}% + ${mgWidth / 2}px - ${
                relX * mgWidth
              }px) calc(${relY * 100}% + ${mgHeight / 2}px - ${
                relY * mgWidth
              }px)`,
              backgroundSize: `${zoomFactor * imgBoundsRef.current.width}% ${
                zoomFactor * imgBoundsRef.current.height
              }%`,
              borderWidth: mgBorderWidth,
            }}
          />
        )}
      </div>
    )
  },
) as ForwardRefComponent<'img', ImageMagnifierProps>
