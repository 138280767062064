import {ForwardRefComponent, useForkRef} from '@cheddarup/react-util'
import React from 'react'

import {cn} from '../utils'

// TODO: Get rid of unknown props warning somehow

export interface LockProps {
  LockComponent?: React.ComponentType
  locked?: boolean
  content: React.ReactNode
  children: React.ReactElement<{
    'data-locked'?: boolean
    className?: string
    disabled?: boolean
    value?: string
    children?: React.ReactNode
  }>
}

export const Lock = React.forwardRef(
  (
    {
      as,
      LockComponent = 'div',
      locked = true,
      content: contentProp,
      className,
      children,
      ...restProps
    },
    forwardedRef,
  ) => {
    const child = React.Children.only(children)

    const ref = useForkRef((child as any)?.ref, forwardedRef)

    const content = contentProp ?? child.props.value ?? <wbr />
    const childChildren = [
      child.props.children,
      locked ? content : undefined,
    ].filter((c) => c != null)

    return React.cloneElement(
      child,
      {
        ref,
        'data-locked': locked,
        'aria-disabled': locked,
        disabled: locked,
        className: cn(
          'Lock',
          'data-[locked=true]:!pointer-events-none data-[locked=true]:text-inherit',
          className,
          child.props.className,
        ),
        ...restProps,
        ...(locked && {as: LockComponent}),
      } as any,
      ...childChildren,
    )
  },
) as ForwardRefComponent<'div', LockProps>
