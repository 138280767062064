const viteModes = ['development', 'production'] as const

type ViteMode = (typeof viteModes)[number]

const commonConfig = {
  appleStoreLink: 'https://apps.apple.com/us/app/cheddar-up/id1141129202?ls=1',
  googlePlayLink:
    'https://play.google.com/store/apps/details?id=com.cheddarup.CheddarUp',
  googleMapsApiKey: 'AIzaSyB9skK0Hi_VYBztagQWMeo6UXi-am-Y5xY',
  sentryDsn:
    'https://5d870a5ea513437298d0ff4f4af19ad6@o87262.ingest.us.sentry.io/4504696347820032',
  supportEmail: 'friendlysupport@cheddarup.com',
  appStoreAppId: 'id1141129202',
  links: {
    marketingPage: 'https://www.cheddarup.com',
    appStore:
      'https://itunes.apple.com/us/app/cheddar-up/id1141129202?ls=1&mt=8',
    googlePlay:
      'https://play.google.com/store/apps/details?id=com.cheddarup.CheddarUp',
    supportPage: 'https://support.cheddarup.com/',
    withdraw:
      'https://support.cheddarup.com/hc/en-us/articles/360035226192-Withdraw-funds',
    privacyPolicy: 'https://www.cheddarup.com/privacypolicy/',
    terms: 'https://www.cheddarup.com/termsofuse/',
    achTerms: 'https://www.cheddarup.com/achpaymentterms/',
    videoTutorials:
      'https://support.cheddarup.com/hc/en-us/categories/360002241391-Videos',
    disputesSupport:
      'https://support.cheddarup.com/hc/en-us/articles/360035587011-Prevent-and-manage-disputes',
    shareCollection:
      'https://support.cheddarup.com/hc/en-us/articles/360035226152-Share-your-collection-',
    sharePOSCode:
      'https://support.cheddarup.com/hc/en-us/articles/360052121632-Share-a-POS-Code',
    contact: 'https://my.cheddarup.com/contact',
    proccessingFees:
      'https://support.cheddarup.com/hc/en-us/articles/360035586711-What-payers-need-to-know#payerguidefees',
  },
}

const configsByMode = {
  development: {
    isProd: false,
    clientId: '80a63ab7-5acb-48ce-9a55-1c7f48894ddb',
    ruptClientId: '88b7a031-34ef-4d79-8925-b918cd2aed3b',
    s3BucketName: 'static.dev.cheddarup.com',
    stripePublishableKey: 'pk_test_QAsehMtYdfbeWsaKctVeKGlE',
    cubeApiUrl: 'https://dev-cube.cheddarup.com',
    sharpHost: 'https://d1629l0zjryuxa.cloudfront.net/',
    tangoGiftCardPreview:
      'https://www.rl.tangocard.com/r/1/euulsYNwyr86z--ROsa4S6QN__mKlKGgSARYyHvspUw/catalog-preview',
    // cubeApiUrl: 'http://localhost:4000',
  },
  production: {
    isProd: true,
    clientId: '69d1bcb3-a2ab-4f13-927e-9b0a21d9a71b',
    ruptClientId: 'f50eb172-1735-4a4e-9bd4-4eaf7407be97',
    s3BucketName: 'cheddar-up',
    stripePublishableKey: 'pk_live_gMKTY2aBEzFcCOSye8YMm98X',
    cubeApiUrl: 'https://cube.cheddarup.com',
    sharpHost: 'https://images.cheddarcdn.com/',
    tangoGiftCardPreview:
      'https://www.rl.tangocard.com/r/1/iXqiY8xtlIDAw2k_7O6yvASwhCENFfn5oZM42kHt8TA/catalog-preview',
  },
} satisfies Record<ViteMode, Record<string, any>>

export const viteMode = getMode()

const config = {
  ...commonConfig,
  ...configsByMode[viteMode],
  helpers: {
    shareUrl: (slug: string) => `https://${slug}.cheddarup.com`,
  },
}

// https://vitejs.dev/guide/env-and-mode.html#modes
function getMode(): ViteMode {
  const mode = viteModes.find(
    (supportedMode) => supportedMode === import.meta.env.MODE,
  )

  if (!mode) {
    return 'development'
  }
  return mode
}

export default config
