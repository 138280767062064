import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import NoResultFoundIcon from 'src/images/NoResultFoundIcon.svg'

export interface MarketplaceItemNoResultsFoundViewProps
  extends React.ComponentPropsWithoutRef<'div'> {
  onClearSearch: () => void
}

const MarketplaceItemNoResultsFoundView = ({
  onClearSearch,
  className,
  ...restProps
}: MarketplaceItemNoResultsFoundViewProps) => (
  <WebUI.VStack
    className={WebUI.cn('mt-15 items-center gap-4', className)}
    {...restProps}
  >
    <img className="w-[3.6em]" src={NoResultFoundIcon} alt="" />
    <div className="text-ds-lg">No results were found</div>
    <WebUI.Button onClick={() => onClearSearch()}>Clear Search</WebUI.Button>
  </WebUI.VStack>
)

export default MarketplaceItemNoResultsFoundView
