// @ts-ignore

import {z} from '@cheddarup/util'

import {tabObjectFieldMetadataSchema, tabObjectFieldTypeSchema} from './fields'

export const tabObjectFieldViewSchema = z.object({
  id: z.number(),
  payment_id: z.number(),
  item_field_id: z.number(),
  payment_item_id: z.number(),
  value: z.string(),
  name: z.string(),
  required: z.boolean(),
  position: z.number(),
  field_type: tabObjectFieldTypeSchema,
  values: z.string(),
  deleted_at: z.string().nullish(),
  tab_object_id: z.number(),
  tab_member_id: z.number(),
  tab_id: z.number(),
  metadata: tabObjectFieldMetadataSchema,
})

declare global {
  namespace Api {
    // @ts-ignore
    type TabObjectFieldView = z.infer<typeof tabObjectFieldViewSchema>
  }
}
