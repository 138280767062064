import * as WebUI from '@cheddarup/web-ui'
import {To} from 'react-router-dom'
import React, {useMemo} from 'react'
import {IconLink} from 'src/components/IconLink'

import {
  MarketplaceItemsGallery,
  MarketplaceSectionTitle,
} from '../../components'

const MAX_ITEM_COUNT = 4

export interface JustListedItemsSectionProps
  extends React.ComponentPropsWithoutRef<'div'> {
  categoriesPath: To
  items: any[]
}

const JustListedItemsSection = ({
  categoriesPath,
  items: allItems,
  ...restProps
}: JustListedItemsSectionProps) => {
  const items = useMemo(() => allItems.slice(0, MAX_ITEM_COUNT), [allItems])

  return (
    <div {...restProps}>
      <div
        className={
          'flex items-center [&_>_.Link]:opacity-0 [&_>_.Link]:transition-opacity [&_>_.Link]:duration-150 [&_>_.Link]:ease-in hover:[&_>_.Link]:opacity-100 focus:[&_>_.Link]:opacity-100 active::[&_>_.Link]:opacity-100'
        }
      >
        <MarketplaceSectionTitle>Just Listed</MarketplaceSectionTitle>
        <IconLink className="ml-4 text-ds-2xl" to={categoriesPath}>
          <WebUI.PhosphorIcon icon="arrow-circle-right" />
        </IconLink>
      </div>
      <MarketplaceItemsGallery className="mt-4" items={items} />
    </div>
  )
}

export default JustListedItemsSection
