import * as Ariakit from '@ariakit/react'
import React from 'react'
import {cva} from 'class-variance-authority'
import type {Merge} from '@cheddarup/util'

import {VariantsProps, cn} from '../../utils'

export const input = cva(
  [
    'min-w-[1rem] appearance-none font-[inherit] outline-none transition-colors',
    'placeholder:text-natural-70',
    'aria-disabled:cursor-not-allowed',
  ],
  {
    variants: {
      variant: {
        default: [
          'text-natural-20 ring-1 ring-natural-70',
          'read-only:ring-0',
          'aria-invalid:ring-orange-50',
          'focus:ring-teal-50',
          'hover:[&:not(:focus):not([aria-invalid=true]):not(:[aria-disabled=true])]:bg-inputHoverBackground',
        ],
        headless: '',
      },
      size: {
        default: 'input-md px-[14px] py-2 text-ds-base',
        headless: '',
      },
      roundness: {
        default: 'rounded',
        headless: '',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
      roundness: 'default',
    },
  },
)

export interface InputProps
  extends Merge<Ariakit.RoleProps<'input'>, VariantsProps<typeof input>> {}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      roundness = 'default',
      variant = 'default',
      size = 'default',
      ...restProps
    },
    forwardedRef,
  ) => (
    <Ariakit.Role.input
      ref={forwardedRef}
      className={cn(input({variant, size, roundness}), className)}
      {...restProps}
    />
  ),
)
