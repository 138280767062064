import {memo} from 'react'
import * as WebUI from '@cheddarup/web-ui'

export interface UploadMembersCsvResultsTableCellProps {
  isEmail?: boolean
  initialValue: string
  value: string
  validate?: (value: string) => boolean
  onChangeValue: (value: string) => void
}

const UploadMembersCsvResultsTableCell = memo(
  ({
    isEmail,
    initialValue,
    value,
    validate,
    onChangeValue,
  }: UploadMembersCsvResultsTableCellProps) => (
    <td className="max-w-[6vw] overflow-hidden text-ellipsis whitespace-nowrap p-2">
      {!validate || validate(initialValue) ? (
        <>
          {isEmail ? (
            <a href={`mailto:${initialValue}`}>{initialValue}</a>
          ) : (
            initialValue
          )}
        </>
      ) : (
        <WebUI.Input
          aria-invalid={!validate(value)}
          className="rounded-none"
          size="compact"
          value={value}
          onChange={(event) => onChangeValue(event.target.value)}
        />
      )}
    </td>
  ),
)

export default UploadMembersCsvResultsTableCell
