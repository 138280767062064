import React, {useEffect} from 'react'
import {useCreateSetupIntentMutation} from '@cheddarup/api-client'
import * as WebUI from '@cheddarup/web-ui'

export interface WithSetupIntentProps {
  children: (res: {
    clientSecret: string
    setupIntentId: string
  }) => React.ReactNode
}

export const WithSetupIntent = ({children}: WithSetupIntentProps) => {
  const createSetupIntentMutation = useCreateSetupIntentMutation()

  const createSetupIntent = createSetupIntentMutation.mutate
  useEffect(() => {
    createSetupIntent()
  }, [createSetupIntent])

  if (createSetupIntentMutation.isPending) {
    return (
      <WebUI.VStack className="items-center justify-center">
        <WebUI.Loader />
      </WebUI.VStack>
    )
  }

  if (!createSetupIntentMutation.data) {
    return null
  }

  return <>{children(createSetupIntentMutation.data)}</>
}
