import * as Util from '@cheddarup/util'
import {api} from '@cheddarup/api-client'

export interface EasypostAddress {
  country: string
  name: string
  street1: string
  city: string
  state: string
  zip: string
}

const allEqual = (arr: any[]) =>
  arr.length === 0 || arr.every((element) => element === arr[0])

const isAddressAutocorrectted = <T extends Partial<EasypostAddress>>({
  originAddress,
  easypostAddress,
}: {
  originAddress: T
  easypostAddress: EasypostAddress
}) => {
  const cities = [originAddress.city, easypostAddress.city].map(
    (str) => str?.toLowerCase() ?? '',
  )
  const streets = [originAddress.street1, easypostAddress.street1].map(
    (str) => str?.toLowerCase() ?? '',
  )
  const zips = [originAddress.zip, easypostAddress.zip].map(
    (str) => str?.toLowerCase() ?? '',
  )

  return !allEqual(cities) || !allEqual(streets) || !allEqual(zips)
}

const EasypostAddressHelpers = {
  createEasyPostAddress: async ({
    collectionId,
    paymentId,
    payload,
  }: {
    collectionId: number
    paymentId: number
    payload: Partial<{
      phone: string | null
      email: string | null
      name: string | null
      street1: string | null
      address: string | null
      city: string | null
      state: string | null
      country: string | null
      zip: string | null
    }>
  }) => {
    const {address} = await api.shipments.createAddress.fetch({
      pathParams: {
        tabId: collectionId,
        paymentId,
      },
      body: payload,
    })

    const errors = Util.mapToObj(
      Object.values(
        address.country === 'US'
          ? address.verifications
          : {delivery: address.verifications.delivery},
      ).flatMap(({errors: verificationErrors}) => verificationErrors),
      (error) => [error.field, error.message],
    )

    if (Object.keys(errors).length !== 0) {
      throw errors
    }

    const autocorrected = isAddressAutocorrectted({
      originAddress: payload as any,
      easypostAddress: address,
    })

    return {
      ...address,
      autocorrected,
    }
  },
  getAddressFromEasypostAddress: <T extends Partial<EasypostAddress>>(
    easypostAddress: T,
  ): Api.PaymentShippingInfo['shipTo'] => ({
    country: easypostAddress.country,
    name: easypostAddress.name,
    street1: easypostAddress.street1,
    city: easypostAddress.city,
    state: easypostAddress.state,
    zip: easypostAddress.zip,
  }),
}

export default EasypostAddressHelpers
