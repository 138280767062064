import * as Util from '@cheddarup/util'
import React, {useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'

import {
  ReportOverviewDateRangePicker,
  getDateRangeFromTimeDimension,
} from '../../ReportsPage/components/ReportOverview'
import {AnalyticChart} from './AnalyticChart'

export const HomePageChartsView = (
  props: React.ComponentPropsWithoutRef<'div'>,
) => {
  const [targetRange, setTargetRange] = useState<[string, string]>(
    getDateRangeFromTimeDimension('yearToDate'),
  )
  const [baseRange, setBaseRange] = useState<
    'prevYear' | 'prevMonth' | 'prevWeek'
  >('prevYear')

  const comparedToDateRange = targetRange
    .map((isoDate) => new Date(isoDate))
    .map((date) =>
      Util.subDate(
        date,
        {
          prevYear: {years: 1},
          prevMonth: {months: 1},
          prevWeek: {weeks: 1},
        }[baseRange],
      ),
    )
    .map((date) => Util.formatISO(date)) as [string, string]

  return (
    <WebUI.VStack as={WebUI.Panel} {...props}>
      <WebUI.HStack className="justify-between gap-3 p-4">
        <WebUI.Heading as="h2">Analytics</WebUI.Heading>

        <WebUI.HStack className="items-center gap-3">
          <ReportOverviewDateRangePicker
            allTimeExcluded
            defaultTimeDimension="yearToDate"
            dateRange={targetRange}
            onDateRangeChange={(newDateRange) => setTargetRange(newDateRange)}
          />
          <WebUI.HStack className="items-center">compared to</WebUI.HStack>
          <WebUI.DropdownSelect<'prevYear' | 'prevMonth' | 'prevWeek'>
            size="compact"
            value={baseRange}
            onValueChange={(newBaseRange) => {
              if (newBaseRange != null) {
                setBaseRange(newBaseRange)
              }
            }}
          >
            <WebUI.DropdownSelectOption value="prevYear">
              Previous Year
            </WebUI.DropdownSelectOption>
            <WebUI.DropdownSelectOption value="prevMonth">
              Previous Month
            </WebUI.DropdownSelectOption>
            <WebUI.DropdownSelectOption value="prevWeek">
              Previous Week
            </WebUI.DropdownSelectOption>
          </WebUI.DropdownSelect>
        </WebUI.HStack>
      </WebUI.HStack>
      <WebUI.Separator variant="primary" />
      <WebUI.VStack className="*:border-b *:p-4">
        <AnalyticChart
          targetRange={targetRange}
          baseRange={comparedToDateRange}
          measure="Payments.totalPaid"
          dimension="Payments.createdAt"
          title="Collecting Volume"
          isAmount
        />
        <AnalyticChart
          targetRange={targetRange}
          baseRange={comparedToDateRange}
          measure="Payments.paidCount"
          dimension="Payments.createdAt"
          title="Payments"
        />
        <AnalyticChart
          targetRange={targetRange}
          baseRange={comparedToDateRange}
          measure="Collections.count"
          dimension="Payments.createdAt"
          title="Collection Pages"
        />
        <AnalyticChart
          targetRange={targetRange}
          baseRange={comparedToDateRange}
          measure="PaymentItems.totalQuantity"
          dimension="PaymentItems.purchaseDate"
          title="Items Sold"
        />
        <AnalyticChart
          targetRange={targetRange}
          baseRange={comparedToDateRange}
          measure="Collections.totalCollectors"
          dimension="Payments.createdAt"
          title="Collectors"
        />
        <AnalyticChart
          targetRange={targetRange}
          baseRange={comparedToDateRange}
          measure="Payments.totalPayers"
          dimension="Payments.createdAt"
          title="Payers"
        />
        <AnalyticChart
          targetRange={targetRange}
          baseRange={comparedToDateRange}
          measure="Carts.visitCount"
          dimension="Carts.createdAt"
          title="Visits"
        />
      </WebUI.VStack>
    </WebUI.VStack>
  )
}
