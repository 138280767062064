import React, {useEffect, useMemo, useState} from 'react'
import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import {usePrevious} from '@cheddarup/react-util'

export interface SearchModalProps extends WebUI.ModalProps {
  placeholder?: string
  renderResults: () => React.ReactNode
  search: (keyword: string) => Promise<any>
  onClear?: () => void
}

const SearchModal = ({
  placeholder = 'Find a collection or folder',
  renderResults,
  search,
  onClear,
  ...restProps
}: SearchModalProps) => {
  const [input, setInput] = useState('')

  const [loading, setLoading] = useState(false)
  const doSoftInteractiveSearch = useMemo(
    () =>
      Util.debounce(async (keyword: string) => {
        setLoading(true)
        await search(keyword)
        setLoading(false)
      }, 1000),
    [search],
  )

  const previousInput = usePrevious(input)
  useEffect(() => {
    if (input.length > 0 && previousInput !== input) {
      try {
        doSoftInteractiveSearch(input)
      } catch {
        // noop
      }
    }
  }, [previousInput, input, doSoftInteractiveSearch])

  const media = WebUI.useMedia()

  return (
    <WebUI.Modal
      className="sm:[&_>_.ModalContentView]:max-w-[512px]"
      {...restProps}
    >
      {!media.sm && <WebUI.ModalCloseButton />}
      <div className="p-4 sm:p-9">
        <div className="relative m-2 mt-10 sm:m-0">
          <WebUI.Input
            className="w-full"
            name="input"
            autoFocus
            value={input}
            placeholder={placeholder}
            onChange={(event) => setInput(event.target.value)}
          />
          <div className="absolute top-0 right-0 flex h-10 w-10 items-center justify-center text-gray400">
            {input ? (
              <WebUI.PhosphorIcon
                className="cursor-pointer"
                icon="x"
                width={24}
                onClick={() => {
                  setInput('')
                  onClear?.()
                }}
              />
            ) : (
              <WebUI.PhosphorIcon icon="magnifying-glass" width={24} />
            )}
          </div>
        </div>
      </div>
      {loading ? (
        <WebUI.VStack className="items-center justify-center pb-4 sm:pb-9">
          <WebUI.Loader size="2em" />
        </WebUI.VStack>
      ) : (
        renderResults()
      )}
    </WebUI.Modal>
  )
}

export default SearchModal
