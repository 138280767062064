import {type To} from 'react-router-dom'
import type React from 'react'
import {useManagerRole} from 'src/components/ManageRoleProvider'
import {api, useUpdateTabMutation} from '@cheddarup/api-client'
import * as WebUI from '@cheddarup/web-ui'
import {LinkButton} from 'src/components/LinkButton'
import * as Util from '@cheddarup/util'
import {Link} from 'src/components/Link'

import {CollectionPaymentsStats} from './CollectionManagePaymentsTable/components'
import {TransferIconWrap} from 'src/views/withdraw/WithdrawPage/containers/WithdrawPageContentContainer/WithdrawPageContentContainer'
import {
  CloseCollectionAlert,
  CollectionAccessGroupPageLink,
  MoneyTransferOptionCta,
} from './components'

// MARK: – CollectionTotalsSummaryPanel

export interface CollectionTotalsSummaryPanelProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collection: Api.TabDetailed
}

export const CollectionTotalsSummaryPanel: React.FC<
  CollectionTotalsSummaryPanelProps
> = ({collection, className, ...restProps}) => {
  const [managerRole] = useManagerRole()
  const media = WebUI.useMedia()

  return (
    <WebUI.Panel
      className={WebUI.cn('flex min-h-44 items-center p-6 ', className)}
      {...restProps}
    >
      <div className="flex grow flex-col justify-center gap-6 sm:flex-row">
        <div className="flex flex-col gap-3">
          <CollectionPaymentsStats
            heading="Total Collected"
            paymentTotal={collection.payments_total}
          />
          <LinkButton
            className="text-ds-sm"
            variant="link"
            disabled={
              !collection.access.owner &&
              managerRole?.permissions.role !== 'admin'
            }
            to={`i/collection/${collection.id}/summary`}
          >
            View Balance Summary
          </LinkButton>
        </div>

        {managerRole?.permissions?.role !== 'viewer' && (
          <>
            <WebUI.Separator
              orientation={media.sm ? 'vertical' : 'horizontal'}
              variant="primary"
              className="sm:-my-3"
            />
            <CollectionPaymentsStats
              heading="Available to Transfer"
              paymentTotal={collection.withdrawal_balance_available}
            />
            <MoneyTransferOptionCta
              iconBefore={
                <TransferIconWrap className="bg-teal-40">
                  <WebUI.PhosphorIcon
                    icon="bank"
                    width={25}
                    className="text-white"
                  />
                </TransferIconWrap>
              }
              to={`i/collection/${collection.id}/summary?selectedTabId=bank-transfer`}
            >
              Transfer to your bank account
            </MoneyTransferOptionCta>
            <MoneyTransferOptionCta
              iconBefore={
                <TransferIconWrap className="bg-yellow-40">
                  <WebUI.PhosphorIcon
                    icon="gift"
                    width={25}
                    className="text-white"
                  />
                </TransferIconWrap>
              }
              to={`i/collection/${collection.id}/summary?selectedTabId=send-gift`}
            >
              Transfer to a gift card or prepaid Mastercard
            </MoneyTransferOptionCta>
          </>
        )}
      </div>
    </WebUI.Panel>
  )
}

// MARK: – CollectionAccessPanel

export interface CollectionAccessPanelProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collection: Api.Tab
}

export const CollectionAccessPanel: React.FC<CollectionAccessPanelProps> = ({
  collection,
  className,
  ...restProps
}) => {
  const discountsCountQuery = api.tabDiscounts.list.useQuery(
    {
      pathParams: {
        tabId: collection.id,
      },
    },
    {
      select: (discounts) => discounts.length,
    },
  )

  const now = new Date()
  const isTimeAvailable =
    !!collection.close_datetime && new Date(collection.close_datetime) > now
  const isStarted =
    isTimeAvailable && new Date(collection.open_datetime ?? '') < now

  if (
    !(
      isTimeAvailable ||
      !!collection.access_code ||
      (!!discountsCountQuery.data && collection.discounts_enabled) ||
      !!collection.payer_identify
    )
  ) {
    return null
  }

  return (
    <WebUI.Panel
      className={WebUI.cn('flex flex-col gap-4 p-6', className)}
      {...restProps}
    >
      <WebUI.Heading className="text-gray400 uppercase" as="h6">
        Access and Discount Settings
      </WebUI.Heading>
      {isTimeAvailable && !isStarted && (
        <ManagerAccessEditLink
          label={`Timing: Starts ${Util.formatDateAs(
            collection.open_datetime ?? '',
            'datetime',
          )}`}
          to="../details/settings?p=access-and-timing"
        />
      )}
      {isTimeAvailable && isStarted && (
        <ManagerAccessEditLink
          label={`Timing: Ends ${Util.formatDateAs(
            collection.close_datetime ?? '',
            'datetime',
          )}`}
          to="../details/settings?p=access-and-timing"
        />
      )}
      {!!collection.access_code && (
        <ManagerAccessEditLink
          label="Access: Requires Code"
          to="../details/settings?p=access-and-timing"
        />
      )}
      {!!discountsCountQuery.data && !!collection.discounts_enabled && (
        <ManagerAccessEditLink
          label={`Discounts: ${discountsCountQuery.data} Code${
            discountsCountQuery.data > 1 ? 's' : ''
          }`}
          to="../details/settings?p=shipping-and-discounts"
        />
      )}
      {!!collection.payer_identify && (
        <ManagerAccessEditLink
          label="Visitor Report: Enabled"
          to="../details/settings?p=access-and-timing"
        />
      )}
    </WebUI.Panel>
  )
}

// MARK: – ManagerAccessEditLink

interface ManagerAccessEditLinkProps
  extends React.ComponentPropsWithoutRef<'div'> {
  label: React.ReactNode
  to: To
}

const ManagerAccessEditLink: React.FC<ManagerAccessEditLinkProps> = ({
  label,
  to,
  className,
  ...restProps
}) => (
  <div className={WebUI.cn('flex gap-1', className)} {...restProps}>
    <p className="font-light text-ds-sm">{label}</p>
    <Link className="text-ds-sm" variant="primary" to={to}>
      edit
    </Link>
  </div>
)

// MARK: – CollectionGroupPagePanel

export interface CollectionGroupPagePanelProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collection: Api.Tab
}

export const CollectionGroupPagePanel: React.FC<
  CollectionGroupPagePanelProps
> = ({collection, className, ...restProps}) => (
  <WebUI.Panel
    className={WebUI.cn('flex flex-col gap-4 p-6', className)}
    {...restProps}
  >
    <WebUI.Heading className="font-medium text-gray400 uppercase" as="h6">
      GROUP PAGE
    </WebUI.Heading>
    <CollectionAccessGroupPageLink collection={collection} />
  </WebUI.Panel>
)

// MARK: – ShareCollectionPanel

export interface ShareCollectionPanelProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collection: Api.Tab
}

export const ShareCollectionPanel: React.FC<ShareCollectionPanelProps> = ({
  collection,
  className,
  ...restProps
}) => {
  const updateCollectionMutation = useUpdateTabMutation()
  const growlActions = WebUI.useGrowlActions()

  return (
    <WebUI.Panel
      className={WebUI.cn('flex flex-col gap-4 p-6', className)}
      {...restProps}
    >
      <WebUI.Heading className="font-medium text-gray400 uppercase" as="h6">
        Collection Access
      </WebUI.Heading>
      <WebUI.Button
        className="self-start"
        size="compact"
        variant="default"
        onClick={() => {
          WebUI.copyToClipboard(`https://${collection.slug}.cheddarup.com`)
          growlActions.show('success', {
            title: 'Success',
            body: 'Link copied',
          })
        }}
        disabled={collection.status === 'closed'}
      >
        Share Collection
      </WebUI.Button>
      <WebUI.Separator variant="primary" />
      {collection.closed_at ? (
        <WebUI.Button
          variant="link"
          className="text-ds-sm"
          onClick={() =>
            updateCollectionMutation.mutate({
              pathParams: {
                tabId: collection.id,
              },
              body: {closed_at: null},
            })
          }
          loading={updateCollectionMutation.isPending}
        >
          Reopen Collection
        </WebUI.Button>
      ) : (
        <CloseCollectionAlert
          collectionId={collection.id}
          disclosure={
            <WebUI.DialogDisclosure variant="link" className="text-ds-sm">
              Close Collection
            </WebUI.DialogDisclosure>
          }
        />
      )}
    </WebUI.Panel>
  )
}
