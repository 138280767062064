import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {
  categorySchema,
  itemCatalogItemSchema,
  itemCatalogSchema,
  tabSchema,
} from '../schemas'

export default {
  list: makeEndpoint({
    path: 'users/item_catalogs',
    responseSchema: itemCatalogSchema.omit({active_items: true}).array(),
  }),
  detail: makeEndpoint({
    path: 'users/item_catalogs/:catalogId',
    responseSchema: z.object({
      catalog: itemCatalogSchema,
      categories: categorySchema.array(),
    }),
  }),
  search: makeEndpoint({
    path: 'users/item_catalogs/search',
    queryParamsSchema: z
      .object({
        organization_id: z.number(),
        search_term: z.string(),
      })
      .optional(),
    responseSchema: itemCatalogItemSchema
      .pick({
        id: true,
        name: true,
        type: true,
      })
      .extend({
        tab: tabSchema.pick({
          id: true,
          name: true,
        }),
      })
      .array(),
  }),
  add: makeEndpoint({
    path: 'users/tabs/:tabId/catalog/add',
    method: 'POST',
    bodySchema: z.object({
      catalog: z.object({
        id: z.number(),
        items: z.number().array().optional(),
        forms: z.number().array().optional(),
      }),
    }),
    responseSchema: tabSchema,
  }),
}
