import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {
  excludeTicketHiddenFields,
  formatTicketStatus,
  getAttendeeEmail,
  getAttendeeName,
} from '@cheddarup/core'

import type {PaymentType} from './OrderSummaryLayout'
import {TicketActionsMenu} from './TicketActionsMenu'
import PaymentNoteButtonDropdown from './PaymentNoteButtonDropdown'
import {encodeToBase36} from '@cheddarup/util'

export interface TicketOverviewCardProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collectionId: number
  paymentObject: Api.TabItemPayment
  paymentType?: PaymentType
}

export const TicketOverviewCard: React.FC<TicketOverviewCardProps> = ({
  collectionId,
  paymentObject,
  paymentType = 'tab',
  className,
  ...restProps
}) => (
  <WebUI.Card
    className={WebUI.cn('TicketOverviewCard', 'p-4', className)}
    accessoryView={
      <TicketActionsMenu
        className="text-ds-sm"
        paymentItem={paymentObject}
        paymentType={paymentType}
      >
        View
      </TicketActionsMenu>
    }
    {...restProps}
  >
    <WebUI.VStack className="gap-3 text-ds-sm">
      <WebUI.VStack className="gap-2">
        <WebUI.Ellipsis className="font-bold">
          Ticket #{encodeToBase36(paymentObject.id)}
        </WebUI.Ellipsis>
        <WebUI.VStack>
          <WebUI.Ellipsis>
            Status:{' '}
            {formatTicketStatus(paymentObject.detail.ticketStatus ?? 'unused')}
          </WebUI.Ellipsis>
          <WebUI.Ellipsis>
            Attendee: {getAttendeeName(paymentObject.item_field_views)}
          </WebUI.Ellipsis>
          <WebUI.Ellipsis>
            Email: {getAttendeeEmail(paymentObject.item_field_views)}
          </WebUI.Ellipsis>
        </WebUI.VStack>
      </WebUI.VStack>

      <WebUI.VStack className="gap-1">
        {excludeTicketHiddenFields(paymentObject.item_field_views).map(
          (question) => (
            <WebUI.VStack key={question.id}>
              <WebUI.Text className="text-gray400">{question.name}</WebUI.Text>
              <WebUI.Text>{question.value}</WebUI.Text>
            </WebUI.VStack>
          ),
        )}
      </WebUI.VStack>

      {paymentObject.detail.organizerNote && (
        <>
          <WebUI.Separator variant="primary" />
          <WebUI.VStack className="gap-1">
            <WebUI.VStack>
              <WebUI.Text className="text-gray400">Attendee Note:</WebUI.Text>
              <WebUI.Text>{paymentObject.detail.organizerNote}</WebUI.Text>
            </WebUI.VStack>
            <PaymentNoteButtonDropdown
              variant="link"
              collectionId={collectionId}
              itemPaymentItem={paymentObject}
            >
              Edit
            </PaymentNoteButtonDropdown>
          </WebUI.VStack>
        </>
      )}
    </WebUI.VStack>
  </WebUI.Card>
)
