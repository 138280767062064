import * as WebUI from '@cheddarup/web-ui'
import {PaymentsToOthersView} from 'src/views/payments/PaymentsPage'
import React, {useMemo} from 'react'
import {LinkButton} from 'src/components/LinkButton'
import GirlSittingImage from 'src/images/girl-sitting.png'
import ReviewBandImage from 'src/images/review-band.png'
import {api} from '@cheddarup/api-client'
import {useLocation} from 'react-router-dom'
import StarsIcon from 'src/images/StarsIcon.svg'
import * as Util from '@cheddarup/util'
import {UpsellCard} from 'src/components/UpsellCard'
import UpsellCardBballBoy from 'src/images/Upsell-Card-Bball-Boy.png'
import queryString from 'query-string'

export const GuestPaymentsPage = () => {
  const memberNameAndEmailQuery = api.users.authenticatedParty.useQuery(
    undefined,
    {
      select: (authenticatedParty) => {
        const tokenType = authenticatedParty.claims.data.payload.tokenType
        const member =
          tokenType === 'User'
            ? authenticatedParty.claims.data.payload.user
            : authenticatedParty.claims.data.payload.member
        return member?.name
          ? [member.name ?? '', member.email].join(', ')
          : member?.email
      },
    },
  )

  const randomUpsell = useMemo(
    () =>
      Util.sample(
        [
          <FeaturesCTA key="features" />,
          <TrustCTA key="trust" />,
          <UpsellCta key="upsell" />,
        ],
        1,
      )[0],
    [],
  )
  return (
    <WebUI.HStack className="min-h-0 grow">
      <PaymentsToOthersView
        className="max-w-full shrink-0 grow basis-auto overflow-y-auto"
        heading={
          <WebUI.VStack className="gap-1">
            <WebUI.Heading className="leading-compact" as="h2">
              Your Order History
            </WebUI.Heading>
            {memberNameAndEmailQuery.data && (
              <WebUI.Text className="text-ds-sm">
                {memberNameAndEmailQuery.data}
              </WebUI.Text>
            )}
          </WebUI.VStack>
        }
      />
      <WebUI.VStack className="hidden flex-0 gap-4 overflow-y-auto p-6 *:w-[340px] *:flex-0 xl:flex">
        <HaveQuestionsCTA />
        {randomUpsell}
      </WebUI.VStack>
    </WebUI.HStack>
  )
}

// MARK: – CTA Panels

export const HaveQuestionsCTA: React.FC<
  React.ComponentPropsWithoutRef<'div'>
> = ({className, ...restProps}) => (
  <WebUI.VStack
    className={WebUI.cn('gap-3 bg-aquaLight px-8 py-7', className)}
    as={WebUI.Panel}
    {...restProps}
  >
    <WebUI.Heading className="font-accent font-bold text-ds-md">
      Have questions?
    </WebUI.Heading>
    <WebUI.Text className="font-light text-ds-sm">
      For questions related to shipping, refunds, or special requests, please
      contact your organizer.
    </WebUI.Text>
    <LinkButton
      className="self-start"
      variant="default"
      preserveSearch
      to="help"
    >
      Ask a question
    </LinkButton>
  </WebUI.VStack>
)

export const FeaturesCTA: React.FC<React.ComponentPropsWithoutRef<'div'>> = ({
  className,
  ...restProps
}) => (
  <WebUI.VStack
    className={WebUI.cn(
      'relative gap-3 bg-[linear-gradient(#FAEAE7_0%,#F7D3D1_70%,#FAE2E1_85%,#F9DADA_90%)] px-8 py-7',
      className,
    )}
    as={WebUI.Panel}
    {...restProps}
  >
    <WebUI.Heading className="font-accent font-bold text-ds-md">
      Simple. Easy. Free.
    </WebUI.Heading>
    <WebUI.Text className="font-light text-ds-sm">
      No matter the size of your group, Cheddar Up can help you collect money
      and forms for anything:
    </WebUI.Text>
    <WebUI.RadioGroup
      size="compact"
      className={
        '[&_>_.Radio-text]:text-ds-sm [&_>_.Radio]:mt-0 [&_>_.Radio]:mb-2 [&_>_.Radio_>_.Radio-icon]:h-[1em] [&_>_.Radio_>_.Radio-icon]:w-[1em] [&_>_.Radio_>_.Radio-icon_>_.Radio-checkIcon]:text-[0.75em]'
      }
    >
      <WebUI.Radio size="default" checked>
        Fundraisers
      </WebUI.Radio>
      <WebUI.Radio size="default" checked>
        Registrations
      </WebUI.Radio>
      <WebUI.Radio size="default" checked>
        Online Sales
      </WebUI.Radio>
      <WebUI.Radio size="default" checked>
        Sign-Ups
      </WebUI.Radio>
      <WebUI.Radio size="default" checked>
        Waivers and Forms
      </WebUI.Radio>
      <WebUI.Radio size="default" checked>
        Group Gifts
      </WebUI.Radio>
    </WebUI.RadioGroup>
    <LinkButton
      className="self-start"
      variant="primary"
      preserveSearch
      to={{
        pathname: '/signup',
        search: queryString.stringify({
          utm_source: 'cheddaruppro',
          utm_medium: 'sidebar',
          utm_campaign: 'trusted_groups',
        }),
      }}
    >
      Get Started
    </LinkButton>

    <WebUI.Image
      className="absolute right-0 bottom-0"
      width={110}
      height={249}
      alt="Girl sitting"
      src={GirlSittingImage}
    />
  </WebUI.VStack>
)

export const TrustCTA: React.FC<React.ComponentPropsWithoutRef<'div'>> = ({
  className,
  ...restProps
}) => (
  <WebUI.VStack
    className={WebUI.cn(
      'gap-4 bg-teal-50 px-8 py-7 text-natural-100',
      className,
    )}
    as={WebUI.Panel}
    {...restProps}
  >
    <WebUI.Heading className="font-accent font-bold text-ds-md">
      Trusted by over 100,000+ Groups and Non-Profits
    </WebUI.Heading>
    <WebUI.Text className="font-light text-ds-sm">
      Don’t just take our word for it—see for yourself what thousands of groups
      are raving about.
    </WebUI.Text>
    <WebUI.VStack className="gap-1">
      <img className="h-5 w-32" src={StarsIcon} alt="Rating stars" />
      <WebUI.Text className="text-[13px]">
        Average 4.8 stars from over 2k iOS ratings
      </WebUI.Text>
    </WebUI.VStack>
    <WebUI.Image
      width={265}
      height={62}
      alt="Review team"
      src={ReviewBandImage}
    />
    <LinkButton
      className="self-start"
      variant="primary"
      preserveSearch
      to={{
        pathname: '/signup',
        search: queryString.stringify({
          utm_source: 'cheddaruppro',
          utm_medium: 'sidebar',
          utm_campaign: 'trusted_groups',
        }),
      }}
    >
      Sign Up — It’s FREE
    </LinkButton>
  </WebUI.VStack>
)

export const UpsellCta: React.FC = () => {
  const location = useLocation()
  return (
    <UpsellCard
      className="bg-natural-80"
      heading="Who uses Cheddar Up?"
      descriptionText="Groups! Groups love us as a flexible, easy way to collect payments and information for:"
      tiles={[
        'Clubs',
        'Sports',
        'Schools & PTAs',
        'Scouts',
        'Nonprofits',
        'Religious Organizations',
        'Friends & Offices',
      ]}
      ctaText="Get Started — FREE"
      imgSrc={UpsellCardBballBoy}
      to={{
        pathname: '/signup',
        search: Util.mergeSearchParams(location.search, {
          utm_source: 'cheddaruppro',
          utm_medium: 'sidebar',
          utm_campaign: 'who_uses',
        }),
      }}
    />
  )
}
