import React from 'react'
import * as WebUI from '@cheddarup/web-ui'

const MarketplacePageContentContainer = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => (
  <div
    className={WebUI.cn(
      'mx-auto w-[90%] py-8 sm:w-[70%]',
      {
        width: '90%',
        marginX: 'auto',
        paddingY: '$8',
        '@sm': {width: '70%'},
      },
      className,
    )}
    {...restProps}
  />
)

export default MarketplacePageContentContainer
