// Based on https://github.com/adobe/react-spectrum/blob/main/packages/%40react-stately/utils/src/useControlledState.ts

import {useCallback, useRef, useState} from 'react'

import {useLiveRef} from './useLiveRef'

export function useControlledState<T>(
  value: T | undefined,
  defaultValue: T,
  onChange?: (value: T, ...args: any[]) => void,
): [T, (value: T, ...args: any[]) => void] {
  const [stateValue, setStateValue] = useState(value || defaultValue)
  const onChangeRef = useLiveRef(onChange)
  const ref = useRef(value !== undefined)
  const wasControlled = ref.current
  const isControlled = value !== undefined
  // Internal state reference for useCallback
  const stateRef = useRef(stateValue)
  if (wasControlled !== isControlled) {
    console.warn(
      `WARN: A component changed from ${
        wasControlled ? 'controlled' : 'uncontrolled'
      } to ${isControlled ? 'controlled' : 'uncontrolled'}.`,
    )
  }

  ref.current = isControlled

  const setValue = useCallback(
    (newValue: T, ...args: any[]) => {
      const onChangeCaller = (changeValue: T, ...onChangeArgs: any[]) => {
        if (!Object.is(stateRef.current, changeValue)) {
          onChangeRef.current?.(changeValue, ...onChangeArgs)
        }
        if (!isControlled) {
          stateRef.current = changeValue
        }
      }

      if (!isControlled) {
        setStateValue(newValue)
      }
      onChangeCaller(newValue, ...args)
    },
    [isControlled],
  )

  // If a controlled component's value prop changes, we need to update stateRef
  if (isControlled) {
    stateRef.current = value as T
  } else {
    // biome-ignore lint/style/noParameterAssign:
    value = stateValue
  }

  return [value as T, setValue]
}
