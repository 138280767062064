import React from 'react'
import * as WebUI from '@cheddarup/web-ui'

export interface MarketplaceShopItemsFilterBarProps
  extends React.ComponentPropsWithoutRef<'div'> {
  sortBy: {sort: string; direction: 'asc' | 'desc'}
  filtersCount: number
  onChangeSortBy: (sortBy: {sort: string; direction: 'asc' | 'desc'}) => void
  onClickFilters: () => void
}

const MarketplaceShopItemsFilterBar = ({
  sortBy,
  filtersCount,
  onChangeSortBy,
  onClickFilters,
  className,
  ...restProps
}: MarketplaceShopItemsFilterBarProps) => (
  <WebUI.HStack
    className={WebUI.cn('items-center gap-4', className)}
    {...restProps}
  >
    <WebUI.Button variant="outlined" onClick={onClickFilters}>
      Filters{filtersCount > 0 ? ` (${filtersCount})` : ''}
    </WebUI.Button>
    <WebUI.DropdownSelect<string>
      className="h-9"
      size="compact"
      value={sortBy.sort}
      onValueChange={(newValue) => {
        if (newValue) {
          const [newSort, newDirection] = newValue.split('|||')
          if (newSort && newDirection) {
            onChangeSortBy({
              sort: newSort,
              direction: newDirection === 'asc' ? 'asc' : 'desc',
            })
          }
        }
      }}
    >
      <WebUI.DropdownSelectOption value="created_at|||desc">
        Newest
      </WebUI.DropdownSelectOption>
      <WebUI.DropdownSelectOption value="name|||asc">
        Item Name
      </WebUI.DropdownSelectOption>
      <WebUI.DropdownSelectOption value="amount|||asc">
        Price: Low to High
      </WebUI.DropdownSelectOption>
      <WebUI.DropdownSelectOption value="amount|||desc">
        Newest
      </WebUI.DropdownSelectOption>
    </WebUI.DropdownSelect>
  </WebUI.HStack>
)

export default MarketplaceShopItemsFilterBar
