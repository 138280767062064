// Inspired by https://github.com/adobe/react-spectrum/blob/main/packages/%40react-aria/color/src/useColorField.ts#L62

import React, {useState} from 'react'
import {Input, InputProps} from '../Input'
import {addHexValue, hexToInt, Merge, toHex} from '@cheddarup/util'
import {useControlledState} from '@cheddarup/react-util'
import {useColorPicker} from './ColorPicker'

const MIN_COLOR = '#000000'
const MAX_COLOR = '#FFFFFF'
const MIN_COLOR_INT = Number.parseInt(MIN_COLOR, 16)
const MAX_COLOR_INT = Number.parseInt(MAX_COLOR, 16)

export interface ColorInputProps
  extends Merge<React.ComponentPropsWithoutRef<'input'>, InputProps> {
  defaultValue?: string
  value?: string
  onValueChange?: (hex: string | null) => void
}

export const ColorInput = React.forwardRef<HTMLInputElement, ColorInputProps>(
  (
    {
      type = 'text',
      disabled,
      maxLength = 7,
      autoComplete = 'off',
      autoCorrect = 'off',
      spellCheck = false,
      readOnly,
      defaultValue,
      value: valueProp,
      onChange,
      onBlur,
      onKeyDown,
      onWheel,
      onValueChange,
      ...restProps
    },
    forwardedRef,
  ) => {
    const colorPicker = useColorPicker()

    const value = valueProp ?? colorPicker?.value

    const initialValue = toHex(value ?? '')
    const initialDefaultValue = toHex(defaultValue ?? '')
    const [colorValue, setColorValue] = useControlledState<string | null>(
      initialValue,
      initialDefaultValue ?? null,
      (newValue) => {
        if (newValue != null) {
          colorPicker?.setValue(newValue)
        }

        onValueChange?.(newValue)
      },
    )
    const [inputValue, setInputValue] = useState(
      colorValue || value || defaultValue || '',
    )

    const [prevColorValue, setPrevColorValue] = useState(colorValue)
    if (prevColorValue !== colorValue) {
      setInputValue(colorValue ?? '')
      setPrevColorValue(colorValue)
    }

    const inputValueAsHex = toHex(inputValue)

    function addToColorValue(step: number) {
      const newColorValue = addHexValue(inputValueAsHex ?? MIN_COLOR, step)
      setColorValue(newColorValue)
    }

    return (
      <Input
        ref={forwardedRef}
        aria-valuenow={colorValue ? hexToInt(colorValue) : undefined}
        aria-valuetext={colorValue ? colorValue : undefined}
        aria-valuemin={MIN_COLOR_INT}
        aria-valuemax={MAX_COLOR_INT}
        type={type}
        disabled={disabled}
        maxLength={maxLength}
        autoComplete={autoComplete}
        autoCorrect={autoCorrect}
        spellCheck={spellCheck}
        defaultValue={defaultValue}
        value={inputValue}
        onKeyDown={(event) => {
          onKeyDown?.(event)

          if (
            event.defaultPrevented ||
            event.ctrlKey ||
            event.metaKey ||
            event.shiftKey ||
            event.altKey ||
            readOnly
          ) {
            return
          }

          switch (event.key) {
            case 'ArrowUp':
            case 'Up':
              event.preventDefault()
              addToColorValue(1)
              break
            case 'ArrowDown':
            case 'Down':
              event.preventDefault()
              addToColorValue(-1)
              break
            case 'Home':
              event.preventDefault()
              setColorValue(MAX_COLOR)
              break
            case 'End':
              event.preventDefault()
              setColorValue(MIN_COLOR)
              break
          }
        }}
        onChange={(event) => {
          onChange?.(event)
          if (event.defaultPrevented) {
            return
          }

          if (event.target.value.replace('#', '').length === 6) {
            const newHex = toHex(event.target.value)
            if (newHex) {
              setColorValue(newHex)
            }
          }

          setInputValue(event.target.value)
        }}
        onWheel={(event) => {
          onWheel?.(event)
          if (disabled || event.defaultPrevented || event.ctrlKey) {
            return
          }

          event.preventDefault()
          event.stopPropagation()

          if (Math.abs(event.deltaY) <= Math.abs(event.deltaX)) {
            return
          }
          if (event.deltaY > 0) {
            addToColorValue(1)
          } else if (event.deltaY < 0) {
            addToColorValue(-1)
          }
        }}
        onBlur={(event) => {
          onBlur?.(event)
          if (event.defaultPrevented) {
            return
          }

          setInputValue(colorValue ?? '')
          if (inputValueAsHex != null) {
            setColorValue(inputValueAsHex)
          }
        }}
        {...restProps}
      />
    )
  },
)
