import * as WebUI from '@cheddarup/web-ui'
import {Logo} from 'src/components'
import DiamondYellow from 'src/images/DiamondYellow.svg'
import DiamondOrange from 'src/images/DiamondOrange.svg'
import config from 'src/config'

export const AuthBackground = ({
  className,
  children,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => (
  <WebUI.VStack
    className={WebUI.cn(
      'relative min-w-[512px] gap-32 overflow-hidden bg-natural-80',
      className,
    )}
    {...restProps}
  >
    <div className="-translate-y-1/2 absolute inset-x-0 top-1/2">
      <img
        className="-translate-x-1/4 translate-y-32"
        width="auto"
        height="auto"
        src={DiamondYellow}
        alt=""
      />
      <img
        className="-translate-y-16 translate-x-1/2"
        width="auto"
        height="auto"
        src={DiamondOrange}
        alt=""
      />
    </div>

    <WebUI.VStack className="absolute inset-0 mx-auto max-w-[500px] py-16 pl-2">
      <WebUI.Anchor
        className="justify-start"
        href={config.links.marketingPage}
        target="_blank"
      >
        <Logo className="justify-start text-ds-2xl" iconVariant="flat" />
      </WebUI.Anchor>
      <WebUI.VStack className="grow justify-center">{children}</WebUI.VStack>
    </WebUI.VStack>
  </WebUI.VStack>
)
