import * as WebUI from '@cheddarup/web-ui'

import type {AboutUsFormFormik} from './AddAboutUsPage'

interface AbousFormProps
  extends Omit<React.ComponentPropsWithoutRef<'form'>, 'onSubmit'> {
  formik: AboutUsFormFormik
}

const AboutUsForm: React.FC<AbousFormProps> = ({formik, ...restProps}) => (
  <form
    onSubmit={formik.handleSubmit}
    onReset={formik.handleReset}
    noValidate
    {...restProps}
  >
    <div className="flex flex-col gap-9">
      <div className="flex grow flex-col gap-5">
        <WebUI.FormField
          label="Section Header"
          className="max-w-96"
          error={formik.errors.headline}
          required
        >
          <WebUI.Input
            name="headline"
            value={formik.values.headline}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
        <WebUI.FormField className="grow" error={formik.errors.description}>
          <WebUI.RichTextEditor
            key={formik.initialValues.description}
            className="h-full min-h-60 text-ds-base [&_h2]:text-ds-md"
            name="description"
            placeholder="Add a description"
            initialMarkdownValue={formik.values.description}
            onMarkdownValueChange={(newDescription) =>
              formik.setFieldValue('description', newDescription)
            }
          >
            <WebUI.RichTextEditorToolbar rootClassName="-order-1" />
          </WebUI.RichTextEditor>
        </WebUI.FormField>
      </div>
      <WebUI.Panel className="flex flex-col gap-4 px-8 py-6">
        <WebUI.Text className="font-bold text-ds-sm">
          Include a Button
        </WebUI.Text>
        <WebUI.Text className="font-light text-ds-sm">
          Include a button linking to a collection page or your main website
        </WebUI.Text>
        <WebUI.FormField label="URL" className="max-w-96">
          <WebUI.Input
            name="details.url"
            placeholder="Enter a valid URL"
            value={formik.values.details.url}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
        <WebUI.FormField label="Button Text" className="max-w-96">
          <WebUI.Input
            name="details.buttonText"
            placeholder="e.g. “Donate” or “Learn More”"
            value={formik.values.details.buttonText}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
      </WebUI.Panel>
    </div>
  </form>
)

export default AboutUsForm
