import * as Util from '@cheddarup/util'

import AppStoreBadge from '../../images/AppStoreBadge.svg'
import GooglePlayBadge from '../../images/GooglePlayBadge.svg'
import config from '../../config'

// MARK: – Data

const bottomLinks = [
  {
    label: `© ${Util.formatDateAs(new Date(), 'year')} Cheddar Up, Inc.`,
  },
  {
    label: 'Terms',
    url: config.links.terms,
  },
  {
    label: 'Privacy',
    url: config.links.privacyPolicy,
  },
]

const links = [
  {
    heading: 'Learn',
    ul: [
      {
        label: 'How Cheddar Up Works',
        target: '_blank',
        url: 'https://www.cheddarup.com/how-does-cheddar-up-work/',
      },
      {
        label: 'Watch a Demo',
        target: '_blank',
        url: 'https://www.cheddarup.com/demo/',
      },
      {
        link: true,
        label: 'Template Library',
        target: '_blank',
        url: '/templates',
      },
      {
        label: 'Features',
        target: '_blank',
        url: 'https://www.cheddarup.com/features/',
      },
      {
        label: 'Learning Sessions',
        target: '_blank',
        url: 'https://www.cheddarup.com/learning-sessions/',
      },
      {
        label: 'User Stories',
        target: '_blank',
        url: 'https://www.cheddarup.com/reviews',
      },
      {
        label: 'Knowledge Center',
        target: '_blank',
        url: 'https://support.cheddarup.com/hc/en-us',
      },
      {
        label: 'Pricing',
        target: '_blank',
        url: 'https://www.cheddarup.com/pricing/',
      },
    ],
  },
  {
    heading: 'About',
    ul: [
      {
        label: 'About Us',
        target: '_blank',
        url: 'https://www.cheddarup.com/about-cheddar-up/',
      },
      {
        label: 'Press',
        target: '_blank',
        url: 'http://www.cheddarup.com/press/',
      },
      {
        label: 'Blog',
        target: '_blank',
        url: 'https://www.cheddarup.com/blog/',
      },
      {
        label: 'Partner with Us',
        target: '_blank',
        url: 'https://www.cheddarup.com/partners/',
      },
      {
        label: 'Affiliate Program',
        target: '_blank',
        url: 'https://www.cheddarup.com/affiliate/',
      },
      {
        link: true,
        label: 'Contact Us',
        target: '_blank',
        url: '/contact',
      },
    ],
  },
  {
    heading: 'Collect Money From Any Group',
    ul: [
      {
        label: 'PTAs',
        target: '_blank',
        url: 'https://www.cheddarup.com/pta-payments',
      },
      {
        label: 'Girl Scouts',
        target: '_blank',
        url: 'https://www.cheddarup.com/girlscouts/',
      },
      {
        label: 'Boy Scouts',
        target: '_blank',
        url: 'https://www.cheddarup.com/boyscouts/',
      },
      {
        label: 'Nonprofits',
        target: '_blank',
        url: 'https://www.cheddarup.com/nonprofit-payment-processing/',
      },
      {
        label: 'Teams',
        target: '_blank',
        url: 'https://www.cheddarup.com/collect-team-payments',
      },
      {
        label: 'Teacher Gifts',
        target: '_blank',
        url: 'https://www.cheddarup.com/teacher-gift-collection',
      },
      {
        label: 'Group Gifts',
        target: '_blank',
        url: 'https://www.cheddarup.com/group-gift/',
      },
      {
        label: 'Schools',
        target: '_blank',
        url: 'https://www.cheddarup.com/collect-school-payments',
      },
      {
        label: 'Clubs',
        target: '_blank',
        url: 'https://www.cheddarup.com/collect-club-payments',
      },
      {
        label: 'Reunions',
        target: '_blank',
        url: 'https://www.cheddarup.com/collect-money-reunion',
      },
      {
        label: 'Bands',
        target: '_blank',
        url: 'https://www.cheddarup.com/band-fees',
      },
      {
        label: 'HOAs',
        target: '_blank',
        url: 'https://www.cheddarup.com/hoa-online-payments',
      },
      {
        label: 'Churches',
        target: '_blank',
        url: 'https://www.cheddarup.com/church-payments',
      },
      {
        label: 'Cheer',
        target: '_blank',
        url: 'https://www.cheddarup.com/cheer',
      },
    ],
  },
  {
    heading: 'Mobile app',
    ul: [
      {
        label: (
          <img className="w-32" src={AppStoreBadge} alt="App Store Link" />
        ),
        target: '_blank',
        url: 'https://itunes.apple.com/us/app/cheddar-up/id1141129202?ls=1&mt=8',
      },
      {
        label: (
          <img className="w-32" src={GooglePlayBadge} alt="Play Store link" />
        ),
        target: '_blank',
        url: 'https://play.google.com/store/apps/details?id=com.cheddarup.CheddarUp',
      },
    ],
  },
] as const

const social = [
  {
    iconName: 'facebook-logo',
    target: '_blank',
    url: 'https://www.facebook.com/CheddarUp',
  },
  {
    iconName: 'instagram-logo',
    target: '_blank',
    url: 'https://www.instagram.com/cheddarup/',
  },
] as const

export {bottomLinks, links, social}
