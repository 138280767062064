import {ForwardRefComponent, useCollectionState} from '@cheddarup/react-util'
import React from 'react'

import {Button, ButtonProps} from './Button'
import {DataListCheckboxSelectAll} from './DataList'
import {DataSectionListCheckboxSelectAll} from './DataSectionList'
import {cn} from '../utils'

export interface DataListToolbarProps
  extends React.ComponentPropsWithoutRef<'div'> {
  sectionList?: boolean
  selectedAllHidden?: boolean
}

export const DataListToolbar = React.forwardRef<
  HTMLDivElement,
  DataListToolbarProps
>(
  (
    {
      sectionList = false,
      selectedAllHidden = false,
      className,
      children,
      ...restProps
    },
    forwardedRef,
  ) => {
    const {state} = useCollectionState()
    const selectedRowsCount = Object.values(state.selectedItemIdsMap).filter(
      (v) => v === true,
    ).length

    const SelectAllCheckboxComp = sectionList
      ? DataSectionListCheckboxSelectAll
      : DataListCheckboxSelectAll

    return (
      <div
        ref={forwardedRef}
        className={cn(
          'DataListToolbar',
          'scrollbar-hide relative flex flex-0 flex-row overflow-x-auto border-b bg-natural-100',
          className,
        )}
      >
        <div
          className="DataListToolbar-inner flex grow flex-row items-center justify-between gap-6"
          {...restProps}
        >
          {selectedAllHidden ? (
            <div />
          ) : (
            <SelectAllCheckboxComp
              className="DataListToolbar-selectAllCheckbox flex-0 [&_>_.Checkbox-text]:flex-0"
              size="compact"
            >
              {selectedRowsCount > 0
                ? `${selectedRowsCount} Selected`
                : 'Select all'}
            </SelectAllCheckboxComp>
          )}

          <div
            className={
              'DataListToolbar-content flex flex-row items-center gap-2 [&_>_.Button_>_.Button-iconBefore_>_svg]:text-ds-lg'
            }
          >
            {children}
          </div>
        </div>
      </div>
    )
  },
)

// MARK: – DataListToolbarBulkActionButton

export const DataListToolbarBulkActionButton = React.forwardRef<
  HTMLButtonElement,
  ButtonProps & React.ComponentPropsWithoutRef<'button'>
>(
  (
    {size = 'compact', variant = 'secondaryAlt', className, ...restProps},
    forwardedRef,
  ) => (
    <Button
      ref={forwardedRef}
      className={cn('DataListToolbarBulkActionButton', className)}
      size={size}
      variant={variant}
      {...restProps}
    />
  ),
) as ForwardRefComponent<'button', ButtonProps>
