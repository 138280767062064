import {useMemo} from 'react'
import useCart from './useCart'
import usePublicCollection from './usePublicCollection'
import {getItemPriceWithFee} from '@cheddarup/core'

export function useItemPriceWithFee(itemBasePrice: number | null) {
  const {cart} = useCart()
  const {publicCollection} = usePublicCollection()

  const itemPriceWithFee = useMemo(() => {
    return getItemPriceWithFee({
      itemBasePrice,
      cart,
      collection: publicCollection,
    })
  }, [itemBasePrice, cart, publicCollection])

  return itemPriceWithFee
}
