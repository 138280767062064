import {Field} from 'formik'
import * as WebUI from '@cheddarup/web-ui'
import PrintShippingHelpers from 'src/helpers/PrintShippingHelpers'

import type {PrintShippingLabelValues} from '../containers/PrintShippingLabelContainer/PrintShippingLabelContainer'

export interface PrintShippingInfoFormProps {
  className?: string
  signatureAmount: number
  values: PrintShippingLabelValues
}

const PrintShippingInfoForm = ({
  signatureAmount,
  values,
  className,
}: PrintShippingInfoFormProps) => {
  const selectedServiceType =
    PrintShippingHelpers.SERVICE_TYPES.find(
      ({value}) => value === values.shipment.serviceType,
    ) || PrintShippingHelpers.SERVICE_TYPES[0]
  const {weight: maxWeight, unit: maxWeightUnit} =
    PrintShippingHelpers.getParcelMaxWeightForService(
      values.shipment.serviceType,
    )
  const {lbs: weightLbsVisible, oz: weightOzVisible} =
    PrintShippingHelpers.getNeedsParcelWeight({
      packageType: values.shipment.packageType,
      service: values.shipment.serviceType,
    })

  return (
    <WebUI.VStack className={WebUI.cn('gap-8 p-4', className)} as={WebUI.Panel}>
      <div className="font-normal text-ds-md">Shipping Service</div>
      <WebUI.VStack className="shrink-0 basis-auto justify-start gap-16 *:grow md:flex-row md:justify-between">
        <WebUI.VStack className="gap-4">
          <WebUI.FormField label="USPS Service Type">
            <Field name="shipment.serviceType">
              {({field}: any) => (
                <WebUI.Select size="compact" {...field}>
                  {PrintShippingHelpers.SERVICE_TYPES.map(
                    ({packageTypes, ...option}) => (
                      <option key={option.value} {...option} />
                    ),
                  )}
                </WebUI.Select>
              )}
            </Field>
          </WebUI.FormField>
          <WebUI.FormField label="Package Type">
            <Field name="shipment.packageType">
              {({field}: any) => (
                <WebUI.Select size="compact" {...field}>
                  {selectedServiceType.packageTypes.map((pt) => (
                    <option key={pt.value} {...pt} />
                  ))}
                </WebUI.Select>
              )}
            </Field>
          </WebUI.FormField>
          <WebUI.FormField label="Package Weight">
            <WebUI.VStack className="gap-2">
              <WebUI.HStack className="items-center gap-2">
                {weightLbsVisible && (
                  <WebUI.HStack className="items-center gap-2">
                    <Field
                      className="min-w-0 max-w-[100px]"
                      name="shipment.packageWeightLbs"
                      as={WebUI.Input}
                      size="compact"
                      type="number"
                      placeholder="0"
                    />
                    <div>lbs.</div>
                  </WebUI.HStack>
                )}
                {weightOzVisible && (
                  <WebUI.HStack className="items-center gap-2">
                    <Field
                      className="min-w-0 max-w-[100px]"
                      name="shipment.packageWeightOz"
                      as={WebUI.Input}
                      type="number"
                      size="compact"
                      placeholder="0"
                    />
                    <div>oz.</div>
                  </WebUI.HStack>
                )}
              </WebUI.HStack>
              {!(
                values.shipment.serviceType === 'Express' &&
                (values.shipment.packageType === 'Parcel' ||
                  values.shipment.packageType === 'LargeParcel')
              ) && (
                <div className="text-ds-xs text-gray600">
                  Up to {maxWeight} {maxWeightUnit}{' '}
                  {(values.shipment.serviceType === 'First' &&
                    (values.shipment.packageType === 'Parcel' ||
                      values.shipment.packageType === 'LargeParcel')) ||
                  (values.shipment.serviceType === 'Priority' &&
                    values.shipment.packageType === 'Parcel')
                    ? `and 108" girth`
                    : 'or less'}
                </div>
              )}
            </WebUI.VStack>
          </WebUI.FormField>
        </WebUI.VStack>
        <WebUI.VStack>
          <WebUI.FormField label="Optional">
            <Field name="shipment.tracking">
              {({field}: any) => (
                <WebUI.Checkbox className="mb-2" state={field.value} {...field}>
                  USPS Tracking (Included)
                </WebUI.Checkbox>
              )}
            </Field>
          </WebUI.FormField>
          <WebUI.HStack className="items-baseline gap-2">
            <Field name="shipment.signatureConfirmation">
              {({field}: any) => (
                <WebUI.Checkbox state={field.value} {...field}>
                  {`Signature Confirmation (${
                    signatureAmount === 0
                      ? 'Included'
                      : `$${signatureAmount} USD`
                  })`}
                </WebUI.Checkbox>
              )}
            </Field>
            <WebUI.DeprecatedTooltip label="Provides the date and time of delivery or attempted delivery and the name of the person who signed for the item. You can request a printed copy of the signature electronically or by email. After delivery, you’ll be able to determine the date and time of delivery using USPS Tracking®.">
              <WebUI.PhosphorIcon
                icon="question-fill"
                color="lightGray"
                width={20}
              />
            </WebUI.DeprecatedTooltip>
          </WebUI.HStack>
        </WebUI.VStack>
      </WebUI.VStack>
    </WebUI.VStack>
  )
}

export default PrintShippingInfoForm
