import {MarketplaceMySalesContainer} from './containers'
import {MarketplacePageContainer} from '../containers'
import {MarketplacePageContentContainer} from '../components'

const MarketplaceMySalesPage = () => (
  <MarketplacePageContainer>
    <MarketplacePageContentContainer>
      <MarketplaceMySalesContainer />
    </MarketplacePageContentContainer>
  </MarketplacePageContainer>
)

export default MarketplaceMySalesPage
