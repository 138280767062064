import {makeQueryUpdate, makeUseMutation} from '../use-mutation'
import {Endpoints, endpoints} from '../../endpoints'
import {FetchInput, getEndpointKey} from '../../utils'

export const useUpdateTabPaymentMutation = makeUseMutation(
  endpoints.tabPayments.update,
  (vars) => ({
    regular: (newTabPayment) => [
      makeQueryUpdate(
        endpoints.tabPayments.list,
        (prevTabPayments) =>
          prevTabPayments
            ? ({
                ...prevTabPayments,
                // HACK: payments is used in `useInfiniteQuery` and might not have `data` here
                data: prevTabPayments.data?.map((p) =>
                  p.id === newTabPayment.id ? newTabPayment : p,
                ),
              } as any)
            : prevTabPayments,
        vars,
      ),
      makeQueryUpdate(endpoints.tabPayments.detail, () => newTabPayment, vars),
    ],
  }),
  (queryClient) => ({
    onSuccess: (_newTabPayment, vars) => {
      const paymentsSearchKey = getEndpointKey(endpoints.tabPayments.search, {
        ...vars,
        body: {},
      })
      const membersListKey = getEndpointKey(endpoints.tabMembers.list, vars)

      queryClient.invalidateQueries({queryKey: paymentsSearchKey})
      queryClient.invalidateQueries({queryKey: membersListKey})
    },
  }),
)

export const useDeleteTabPaymentMutation = makeUseMutation(
  endpoints.tabPayments.delete,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(
        endpoints.tabPayments.search,
        (prevTabPayments) =>
          prevTabPayments
            ? {
                ...prevTabPayments,
                data: prevTabPayments.data.filter(
                  (p) => p.id !== Number(vars.pathParams.paymentId),
                ),
              }
            : undefined,
        {
          ...vars,
          body: {},
        },
      ),
    ],
  }),
)

export const useSendReceiptTabPaymentMutation = makeUseMutation(
  endpoints.tabPayments.resendReceipt,
)

export const useCreateTabPaymentRefundMutation = makeUseMutation(
  endpoints.tabPayments.createRefund,
  undefined,
  (queryClient) => ({
    onSuccess: (_newRefund, vars) => {
      const tabPaymentsListQueryKey = getEndpointKey(
        endpoints.tabPayments.list,
        vars,
      )
      const tabPaymentsDetailQueryKey = getEndpointKey(
        endpoints.tabPayments.detail,
        vars,
      )
      const tabPaymentsSearchQueryKey = getEndpointKey(
        endpoints.tabPayments.search,
        {...vars, body: {}},
      )
      const refundableDataQueryKey = getEndpointKey(
        endpoints.tabPaymentRefunds.refundableData,
        vars,
      )

      queryClient.invalidateQueries({queryKey: tabPaymentsListQueryKey})
      queryClient.invalidateQueries({queryKey: tabPaymentsDetailQueryKey})
      queryClient.invalidateQueries({queryKey: tabPaymentsSearchQueryKey})
      queryClient.invalidateQueries({queryKey: refundableDataQueryKey})
    },
  }),
)

export const useResendTabPaymentRefundEmailsMutation = makeUseMutation(
  endpoints.tabPayments.resendEmails,
)

export const useDeleteRecurringPaymentContractMutation = makeUseMutation(
  endpoints.recurringPaymentContracts.delete,
  undefined,
  (queryClient) => ({
    onSuccess: (_res, vars) => {
      const recurringPaymentContractsListQueryKey = getEndpointKey(
        endpoints.recurringPaymentContracts.list,
      )
      const recurringPaymentContractsDetailQueryKey = getEndpointKey(
        endpoints.recurringPaymentContracts.detail,
        vars,
      )
      const paymentsListQueryKey = getEndpointKey(endpoints.payments.list)

      queryClient.invalidateQueries({
        queryKey: recurringPaymentContractsListQueryKey,
      })
      queryClient.invalidateQueries({
        queryKey: recurringPaymentContractsDetailQueryKey,
      })
      queryClient.invalidateQueries({queryKey: paymentsListQueryKey})
    },
  }),
)

export const useUpdateRecurringPaymentContractCard = makeUseMutation(
  endpoints.recurringPaymentContracts.update,
  () => ({
    regular: (newContract) => [
      makeQueryUpdate(
        endpoints.recurringPaymentContracts.list,
        (prevContracts) =>
          prevContracts
            ? {
                ...prevContracts,
                contracts: prevContracts.contracts.map((c) =>
                  c.id === newContract.id ? newContract : c,
                ),
              }
            : prevContracts,
      ),
    ],
  }),
  (queryClient) => ({
    onSuccess: () => {
      const paymentsListQueryKey = getEndpointKey(endpoints.payments.list)
      queryClient.invalidateQueries({queryKey: paymentsListQueryKey})
    },
  }),
)

export const useDeletePaymentItemMutation = makeUseMutation<
  Endpoints['payments']['deletePaymentItem'],
  FetchInput<Endpoints['payments']['deletePaymentItem']> & {
    signupId?: number
    spotId?: number
  },
  any
>(endpoints.payments.deletePaymentItem, undefined, (queryClient) => ({
  onSuccess: (_res, vars) => {
    const tabPaymentsListQueryKey = getEndpointKey(endpoints.tabPayments.list, {
      pathParams: {
        tabId: vars.pathParams.tabId,
      },
    })
    const tabPaymentsDetailQueryKey = getEndpointKey(
      endpoints.tabPayments.detail,
      vars,
    )
    const tabPaymentsExportDataQueryKey = getEndpointKey(
      endpoints.tabPayments.exportData,
      vars,
    )
    const spotsDetailQueryKey =
      vars.signupId && vars.spotId
        ? getEndpointKey(endpoints.tabSignupSpots.detail, {
            pathParams: {
              tabId: vars.pathParams.tabId,
              signupId: vars.signupId,
              spotId: vars.spotId,
            },
          })
        : null
    const paymentsListQueryKey = getEndpointKey(endpoints.payments.list)
    const paymentsDetailQueryKey = getEndpointKey(
      endpoints.payments.detail,
      vars,
    )
    const signupDetailQueryKey = vars.signupId
      ? getEndpointKey(endpoints.tabSignups.detail, {
          pathParams: {
            tabId: vars.pathParams.tabId,
            signupId: vars.signupId,
          },
        })
      : null

    queryClient.invalidateQueries({queryKey: tabPaymentsListQueryKey})
    queryClient.invalidateQueries({queryKey: tabPaymentsDetailQueryKey})
    queryClient.invalidateQueries({queryKey: tabPaymentsExportDataQueryKey})
    queryClient.invalidateQueries({queryKey: paymentsListQueryKey})
    queryClient.invalidateQueries({queryKey: paymentsDetailQueryKey})
    if (spotsDetailQueryKey) {
      queryClient.invalidateQueries({queryKey: spotsDetailQueryKey})
    }
    if (signupDetailQueryKey) {
      queryClient.invalidateQueries({queryKey: signupDetailQueryKey})
    }
  },
}))
