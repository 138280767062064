import * as Yup from 'yup'
import {useNavigate, useParams} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import {useEffect, useMemo, useRef, useState} from 'react'
import {useFormik} from '@cheddarup/react-util'
import {
  api,
  useCreateGroupPageTeamMemberMutation,
  useUpdateGroupPageTeamMemberMutation,
} from '@cheddarup/api-client'
import {useManagerRoleId} from 'src/components/ManageRoleProvider'
import {UploadPinturaImageFormModal} from 'src/components/UploadPinturaImageForm'
import {uploadImage} from '@cheddarup/core'
import {guessError} from 'src/helpers/error-utils'
import {SharpAvatar} from 'src/components/SharpAvatar'

const TEAM_MEMBER_NAME_MAX_LEN = 30
const TEAM_MEMBER_TITLE_MAX_LEN = 46

interface TeamMemberFormValues {
  name: string
  title: string
  email?: string
  includeEmail: boolean
  profilePic: Api.S3Image | WebUI.PinturaDefaultImageWriterResult | null
}

const TeamMemberForm = () => {
  const navigate = useNavigate()
  const urlParams = useParams()
  const [managerRoleId] = useManagerRoleId()
  const growlActions = WebUI.useGrowlActions()

  const {data: teamMember} = api.groupPageTeamMembers.detail.useQuery(
    {
      pathParams: {id: Number(urlParams.id)},
    },
    {enabled: !!urlParams.id},
  )
  const uploadPinturaImageFormModalRef = useRef<WebUI.DialogInstance>(null)
  const [localProfilePic, setLocalProfilePic] = useState<Blob | null>(null)

  const createGroupPageTeamMemberMutation =
    useCreateGroupPageTeamMemberMutation()
  const updateGroupPageTeamMemberMutation =
    useUpdateGroupPageTeamMemberMutation()

  const formik = useFormik<TeamMemberFormValues>({
    enableReinitialize: true,
    initialValues: {
      name: teamMember?.name ?? '',
      title: teamMember?.title ?? '',
      email: teamMember?.email ?? '',
      includeEmail: !!teamMember?.email,
      profilePic: teamMember?.profile_picture ?? null,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required('Required')
        .max(
          TEAM_MEMBER_NAME_MAX_LEN,
          `Name can be ${TEAM_MEMBER_NAME_MAX_LEN} characters at max`,
        ),
      title: Yup.string()
        .required('Required')
        .max(
          TEAM_MEMBER_TITLE_MAX_LEN,
          `Title can be ${TEAM_MEMBER_TITLE_MAX_LEN} characters at max`,
        ),
      email: Yup.string().when('includeEmail', ([enabled], schema) =>
        enabled
          ? schema.email('Invalid email format').required('Required')
          : schema,
      ),
    }),
    onSubmit: async (values) => {
      let savedTeamMember = teamMember ?? null

      const payload = {
        name: values.name,
        title: values.title,
        email: values.email,
      }

      try {
        if (!savedTeamMember) {
          savedTeamMember = await createGroupPageTeamMemberMutation.mutateAsync(
            {body: payload},
          )
        }

        let profilePicId =
          values.profilePic && 'id' in values.profilePic
            ? values.profilePic.id
            : null
        if (values.profilePic && 'dest' in values.profilePic) {
          const uploadedLogo = await uploadImage(
            `users/group_page/meet_the_team/team_members/${savedTeamMember.id}`,
            values.profilePic.dest,
            undefined,
            {
              userId: managerRoleId ?? undefined,
              metadata: {
                pintura: values.profilePic.imageState,
              },
            },
          )

          if (profilePicId != null) {
            api.groupPageTeamMembers.deleteProfilePicture.fetch({
              pathParams: {
                teamMemberId: savedTeamMember.id,
                imageId: profilePicId,
              },
            })
          }

          profilePicId = uploadedLogo.id
        }

        if (savedTeamMember) {
          await updateGroupPageTeamMemberMutation.mutateAsync({
            pathParams: {
              id: savedTeamMember.id,
            },
            body: {
              ...payload,
              profile_picture_id: profilePicId,
            },
          })
        }

        return savedTeamMember
      } catch (err) {
        growlActions.show('error', {body: guessError(err).message})
        return null
      }
    },
  })

  return (
    <WebUI.Modal
      aria-label="Group page team member form"
      className="[&_>_.ModalContentView]:h-full [&_>_.ModalContentView]:max-w-2xl [&_>_.ModalContentView]:px-12"
      onDidHide={() => navigate('..')}
    >
      {(dialog) => (
        <>
          <WebUI.ModalCloseButton />
          <WebUI.ModalHeader className="pl-2">
            Add team member
          </WebUI.ModalHeader>
          <form
            className="flex min-h-0 grow flex-col divide-y divide-solid divide-borderPrimary"
            noValidate
            onSubmit={formik.handleSubmit}
            onReset={formik.handleReset}
          >
            <div className="flex grow flex-col gap-6 overflow-y-auto px-2 py-8">
              <div className="flex max-w-80 flex-col gap-5">
                <WebUI.FormField
                  label="Team Member Name"
                  error={formik.errors.name}
                  required
                >
                  <WebUI.Input
                    name="name"
                    placeholder="First and Last Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    maxLength={TEAM_MEMBER_NAME_MAX_LEN}
                  />
                </WebUI.FormField>
                <WebUI.FormField
                  label="Team Member Title"
                  error={formik.errors.title}
                  required
                >
                  <WebUI.Textarea
                    name="title"
                    placeholder="Title or Committee"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    rows={3}
                    maxLength={TEAM_MEMBER_TITLE_MAX_LEN}
                  />
                </WebUI.FormField>
              </div>
              <WebUI.Separator />
              <WebUI.Disclosure
                className="gap-5"
                visible={formik.values.includeEmail}
                onVisibleChange={(newVisible) =>
                  formik.setFieldValue('includeEmail', newVisible)
                }
              >
                <WebUI.DisclosureSwitch
                  className="flex-row-reverse gap-2"
                  size="compact"
                >
                  Include link to contact via email
                </WebUI.DisclosureSwitch>
                <WebUI.DisclosureContent>
                  <WebUI.FormField
                    label="Email Address"
                    error={formik.errors.email}
                    required
                  >
                    <WebUI.Input
                      name="email"
                      placeholder="Email Address"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </WebUI.FormField>
                </WebUI.DisclosureContent>
              </WebUI.Disclosure>
              <div className="flex flex-col gap-1">
                <WebUI.Text>Profile Picture</WebUI.Text>
                <WebUI.Text className="font-light text-ds-sm">
                  If no picture is uploaded we’ll display team member’s initials
                </WebUI.Text>
              </div>

              <UploadPinturaImageFormModal
                key={teamMember?.profile_picture?.id}
                ref={uploadPinturaImageFormModalRef}
                imageCropAspectRatio={1 / 1}
                circularCrop
                imageCropLimitToImage={false}
                utils={['crop', 'filter', 'finetune', 'fill']}
                uploaderOptions={['upload']}
                initialImage={teamMember?.profile_picture}
                image={localProfilePic}
                onSubmitAsPinturaRes={(newPinturaRes) =>
                  formik.setFieldValue('profilePic', newPinturaRes)
                }
                disclosure={
                  formik.values.profilePic ? (
                    <div className="relative h-[78px] w-[78px]">
                      <WebUI.DialogDisclosure
                        className="!h-full w-full"
                        as={WebUI.IconButton}
                        variant="ghost"
                      >
                        {'id' in formik.values.profilePic ? (
                          <SharpAvatar
                            alt="Team member profile"
                            size={70}
                            image={formik.values.profilePic}
                            name={formik.values.name}
                          />
                        ) : (
                          <WebUI.Avatar
                            alt="Team member profile"
                            as={BlobImage}
                            size={70}
                            blob={formik.values.profilePic.dest}
                            name={formik.values.name}
                          />
                        )}
                      </WebUI.DialogDisclosure>
                      <WebUI.IconButton
                        className="absolute top-1 right-1 text-ds-sm"
                        size="default_alt"
                        variant="secondary"
                        onClick={() => formik.setFieldValue('profilePic', null)}
                      >
                        <WebUI.PhosphorIcon icon="x" />
                      </WebUI.IconButton>
                    </div>
                  ) : (
                    <WebUI.FileUploader
                      accept={{'image/*': []}}
                      onDropAccepted={([imageFile]) => {
                        if (imageFile) {
                          uploadPinturaImageFormModalRef.current?.show()
                          setLocalProfilePic(imageFile)
                        }
                      }}
                    >
                      <WebUI.FileUploaderInput />
                      <WebUI.FileUploaderButton
                        className="!h-[70px] w-[70px]"
                        as={WebUI.IconButton}
                        variant="ghost"
                      >
                        <WebUI.Avatar size={70} />
                      </WebUI.FileUploaderButton>
                    </WebUI.FileUploader>
                  )
                }
              />
            </div>
            <div className="-mx-12 flex flex-row gap-4 px-12 py-6">
              <WebUI.Button
                type="submit"
                loading={formik.isSubmitting}
                onClick={async () => {
                  const res = await formik.submitForm()
                  if (res) {
                    dialog.hide()
                  }
                }}
              >
                Save
              </WebUI.Button>
              {!teamMember && (
                <WebUI.Button
                  type="submit"
                  loading={formik.isSubmitting}
                  onClick={async () => {
                    const res = await formik.submitForm()
                    if (res) {
                      formik.resetForm()
                    }
                  }}
                >
                  Save and add another
                </WebUI.Button>
              )}
            </div>
          </form>
        </>
      )}
    </WebUI.Modal>
  )
}

// MARK: – BlobImage

interface BlobImageProps extends WebUI.ImageProps {
  blob: Blob
}

export const BlobImage = ({blob, ...restProps}: BlobImageProps) => {
  const objectUrl = useMemo(() => URL.createObjectURL(blob), [blob])

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(objectUrl)
    }
  }, [objectUrl])

  return <WebUI.Image src={objectUrl} {...restProps} />
}

export default TeamMemberForm
