import {useLiveRef, useUpdateEffect} from '@cheddarup/react-util'
import {useState} from 'react'

import {isMemberValid} from '../../UploadMemberInvitesCsv'
import UploadMembersCsvResultsTableRow from './UploadMembersCsvResultsTableRow'

export interface UploadMembersCsvResultsTableProps {
  validMembersCount: number
  initialMembers: any[]
  onChangeMembers: (members: any[]) => void
}

const UploadMembersCsvResultsTable = ({
  validMembersCount,
  initialMembers,
  onChangeMembers,
}: UploadMembersCsvResultsTableProps) => {
  const [members, setMembers] = useState<any[]>(
    initialMembers.filter((m) => !isMemberValid(m)),
  )
  const onChangeMembersRef = useLiveRef(onChangeMembers)

  useUpdateEffect(() => {
    onChangeMembersRef.current(members)
  }, [members])

  return (
    <div>
      <div className="my-2">
        <h5 className="mb-2">Upload Results</h5>
        <p className="text-ds-sm">
          {validMembersCount === initialMembers.length
            ? "Congrats! You've successfully uploaded all new members. Click the button below to send member welcome emails."
            : `The following is the result of your member upload. Welcome emails have not yet been sent.${'\n'}Please fix the errors below to send member welcome emails.`}
        </p>
        <div className="mt-8 text-ds-sm">
          <p>
            <span className="font-bold">SUCCESS:</span> {validMembersCount} new{' '}
            {`member${validMembersCount === 1 ? '' : 's'}`} successfully
            uploaded
          </p>
          <p className="mt-2">
            <span className="font-bold">ERRORS:</span>{' '}
            {validMembersCount === initialMembers.length
              ? '0'
              : 'The following members were not added due to the errors noted below:'}
          </p>
        </div>
      </div>
      {validMembersCount === initialMembers.length ? (
        <div className="mt-8" />
      ) : (
        <table className="mt-2 w-full table-fixed border-collapse border-spacing-0 text-ds-xs">
          <thead
            className={
              'bg-gray600 after:block after:h-[1em] after:w-full after:bg-gray100 after:text-natural-100'
            }
          >
            <tr className="border">
              {[
                'First Name',
                'Last Name',
                'Email Address',
                'Currency',
                'Account Type',
                'Tax ID',
                'Org Name',
                'Date of birth',
                'Street Address',
                'City',
                'State',
                'Country',
                'Zip/Postal Code',
              ].map((title) => (
                <th
                  key={title}
                  className="max-w-[5rem] px-[0.5rem] py-[0.25rem]"
                >
                  {title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-natural-100 text-gray400">
            {members.map((member, idx) => {
              const initialMember = initialMembers.find(
                (m) => m.email === member.email,
              )

              return (
                !!initialMember && (
                  <UploadMembersCsvResultsTableRow
                    key={member.email}
                    isLeading={idx === 0}
                    initialMember={initialMembers.find(
                      (m) => m.email === member.email,
                    )}
                    member={member}
                    onChangeMember={(nextMember) =>
                      setMembers((prevMembers) => {
                        const nextMembers = prevMembers.slice()
                        nextMembers[idx] = nextMember
                        return nextMembers
                      })
                    }
                  />
                )
              )
            })}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default UploadMembersCsvResultsTable
