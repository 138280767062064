import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {tabDepositSchema} from '../schemas'

export default {
  list: makeEndpoint({
    path: 'users/tabs/:tabId/deposits',
    responseSchema: tabDepositSchema.array(),
  }),
  create: makeEndpoint({
    path: 'users/tabs/:tabId/deposits',
    method: 'POST',
    bodySchema: tabDepositSchema
      .extend({
        idempotency_key: z.string(),
        stripe_payment_source_id: z.string(),
        security: z.object({
          token: z.string(),
        }),
      })
      .partial(),
    responseSchema: tabDepositSchema,
  }),
}
