import * as WebUI from '@cheddarup/web-ui'
import {useUpdateEffect} from '@cheddarup/react-util'
import {forwardRef, useRef, useState} from 'react'
import ImagesUtils from 'src/helpers/ImagesUtils'

import type {
  FixedItemFormFormik,
  ItemFormValuesListing,
} from '../../../../../containers/FixedItemForm/FixedItemForm'

export interface ItemVariantsListingSelectImageModalProps
  extends WebUI.PromptProps {
  formik: FixedItemFormFormik
  listingUuids: string[]
  itemImages: Array<
    Api.S3Image | NonNullable<ItemFormValuesListing['localImage']>
  >
}

const ItemVariantsListingSelectImageModal = forwardRef<
  WebUI.DialogInstance,
  ItemVariantsListingSelectImageModalProps
>(({formik, listingUuids, itemImages, ...restProps}, forwardedRef) => {
  const listing = listingUuids[0]
    ? formik.values.options.variants.listings.find(
        (l) => l.uuid === listingUuids[0],
      )
    : undefined

  const [uploadedImage, setUploadedImage] = useState<File | null>(
    listing?.localImage?.image ?? null,
  )
  const [selectedImageId, setSelectedImageId] = useState<
    number | string | null
  >(
    listing?.localImage?.image.webkitRelativePath ??
      listing?.localImage?.image.name ??
      listing?.imageId ??
      null,
  )
  const thumbnailCropRef = useRef<WebUI.ImageEditorCrop | null>(
    listing?.localImage?.thumbnail.cropDetails ?? null,
  )
  const selectedImageAsBlob = WebUI.useImageAsBlob(
    (
      itemImages.find((i) => 'id' in i && i.id === selectedImageId) as
        | Api.S3Image
        | undefined
    )?.url,
  )

  useUpdateEffect(() => {
    setSelectedImageId(
      listing?.localImage?.image.webkitRelativePath ??
        listing?.localImage?.image.name ??
        listing?.imageId ??
        null,
    )
  }, [
    listing?.imageId,
    listing?.localImage?.image.name,
    listing?.localImage?.image.webkitRelativePath,
  ])

  useUpdateEffect(() => {
    setUploadedImage(listing?.localImage?.image ?? null)
    thumbnailCropRef.current =
      listing?.localImage?.thumbnail.cropDetails ?? null
  }, [listing?.localImage])

  return (
    <WebUI.Prompt
      ref={forwardedRef}
      aria-label="Edit variant's image"
      {...restProps}
    >
      {(dialog) => (
        <WebUI.VStack className="gap-3">
          <WebUI.PromptHeader heading="Add variant image" />
          <WebUI.ImageEditor
            toolbarTitle={
              <>
                Shows initial thumbnail view.
                <br />
                Payers can click to see full image.
              </>
            }
            image={uploadedImage ?? selectedImageAsBlob}
            onChangeImage={async (newImage) => {
              setSelectedImageId(null)
              const newImageImTool = newImage
                ? await WebUI.ImTool.fromImage(newImage)
                : null
              const newImageFile =
                (await newImageImTool?.toFile(`${listing?.uuid}`)) ?? null
              setUploadedImage(newImageFile)

              if (newImage == null) {
                formik.setValues((prevValues) => ({
                  ...prevValues,
                  options: {
                    ...prevValues.options,
                    variants: {
                      ...prevValues.options.variants,
                      listings: prevValues.options.variants.listings.map((l) =>
                        listingUuids.includes(l.uuid)
                          ? {...l, localImage: null}
                          : l,
                      ),
                    },
                  },
                }))
              }
            }}
            onApplyCrop={(newCrop) => {
              thumbnailCropRef.current = newCrop
            }}
          />

          {itemImages.length > 0 && (
            <div className="[&_>_.IconButton]:float-left [&_>_.IconButton]:m-2">
              {itemImages.map((image) => {
                const imageId =
                  'id' in image
                    ? image.id
                    : ((image as any).image.webkitRelativePath ??
                      (image as any).image.name)
                return (
                  <WebUI.IconButton
                    key={imageId}
                    aria-selected={imageId === selectedImageId}
                    className={
                      'h-18 w-18 p-0 shadow-[0_0_0_1px_theme(colors.natural.70)] *:overflow-hidden *:rounded'
                    }
                    onClick={() => {
                      if ('image' in image) {
                        setUploadedImage(image.image)
                        thumbnailCropRef.current = image.thumbnail.cropDetails
                      } else {
                        setUploadedImage(null)
                      }

                      setSelectedImageId(imageId)
                    }}
                  >
                    {'image' in image ? (
                      <WebUI.ImageFilePreview
                        className={
                          '[&_>_.ImageFilePreview-image_>_.Image-image]:object-cover'
                        }
                        alt="Thumbnail"
                        crossOrigin="anonymous"
                        width={80}
                        height={80}
                        imageFile={image.image}
                      />
                    ) : (
                      <WebUI.Image
                        className="[&_>_.Image-image]:object-cover"
                        alt="Thumbnail"
                        crossOrigin="anonymous"
                        width={80}
                        height={80}
                        src={ImagesUtils.getCroppedImageUrl(image, {
                          width: 80,
                          height: 80,
                        })}
                      />
                    )}
                  </WebUI.IconButton>
                )
              })}
            </div>
          )}

          <WebUI.HStack className="gap-3">
            <WebUI.Button
              onClick={() => {
                const listingIdx =
                  formik.values.options.variants.listings.findIndex(
                    (l) => l.uuid === listingUuids[0],
                  )
                if (listingIdx > -1) {
                  formik.setValues((prevValues) => ({
                    ...prevValues,
                    options: {
                      ...prevValues.options,
                      variants: {
                        ...prevValues.options.variants,
                        listings: prevValues.options.variants.listings.map(
                          (l) =>
                            listingUuids.includes(l.uuid)
                              ? {
                                  ...l,
                                  imageId: uploadedImage
                                    ? null
                                    : (selectedImageId as any),
                                  localImage: uploadedImage
                                    ? {
                                        image: uploadedImage,
                                        thumbnail: {
                                          cropDetails: thumbnailCropRef.current,
                                        },
                                      }
                                    : null,
                                }
                              : l,
                        ),
                      },
                    },
                  }))
                }
                setUploadedImage(null)
                dialog.hide()
              }}
            >
              Save
            </WebUI.Button>
            <WebUI.Button variant="secondary" onClick={() => dialog.hide()}>
              Cancel
            </WebUI.Button>
          </WebUI.HStack>
        </WebUI.VStack>
      )}
    </WebUI.Prompt>
  )
})

export default ItemVariantsListingSelectImageModal
