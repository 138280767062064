import {Link, useLocation} from 'react-router-dom'
import {useMemo, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api, useDeleteOrgMemberMutation} from '@cheddarup/api-client'
import {SearchForm} from 'src/components'
import {fetchAndSave} from 'src/helpers/api-helpers'

import {UserBasePage} from '../components'

const MembersPage = () => {
  const location = useLocation()
  const {data: session} = api.auth.session.useSuspenseQuery()
  return (
    <UserBasePage currentUrl={location.pathname} heading="Member Listing">
      {session.partner_admin?.org?.id && (
        <>
          <h5 className="my-4">
            Below are the members currently in your organization. You can view
            this data online or download an export, which has
            <br />
            additional information about each member account. To add new
            members, go to &quot;
            <Link to="/organizations/invite-members">Invite Members</Link>
            &quot;.
          </h5>
          <MemberTable />
        </>
      )}
    </UserBasePage>
  )
}

// MARK: – MemberTable

const MEMBERS_PER_PAGE = 50

const MemberTable = () => {
  const [query, setQuery] = useState<{
    sort?: string
    direction?: 'asc' | 'desc'
    search: string
    page: number
    perPage: number
  }>({
    search: '',
    page: 1,
    perPage: MEMBERS_PER_PAGE,
  })
  const {data} = api.orgMembers.list.useSuspenseQuery({
    queryParams: query,
  })
  const {data: orgMembers, pagination} = data
  const deleteMemberMutation = useDeleteOrgMemberMutation()

  const deleteMember = deleteMemberMutation.mutate

  const columns = useMemo(
    () => [
      {
        id: 'first_name',
        Header: 'First Name',
        accessor: 'first_name',
      },
      {
        id: 'last_name',
        Header: 'Last Name',
        accessor: 'last_name',
      },
      {
        id: 'email',
        Header: 'Email',
        accessor: 'email',
      },
      {
        id: 'display_name',
        Header: 'Organization',
        accessor: 'display_name',
      },
      {
        id: 'collector_stats.total_payments',
        Header: 'Total Collected',
        accessor: 'collector_stats.total_payments',
      },
      {
        id: 'collector_stats.number_collections',
        Header: '# of Collections',
        accessor: 'collector_stats.number_collections',
      },
      {
        id: 'report',
        Header: 'Report',
        Cell: ({row: {original: orgMember}}: any) => (
          <WebUI.Button
            variant="link"
            onClick={() =>
              fetchAndSave({
                url: `users/exports/account_by_organization.xlsx?user_id=${orgMember.id}`,
                fileName: `account_by_organization-${orgMember.id}.xlsx`,
              })
            }
          >
            download
          </WebUI.Button>
        ),
      },
      {
        id: 'remove',
        Header: 'Remove',
        Cell: ({row: {original: orgMember}}) => (
          <WebUI.IconButton
            className="text-gray400"
            onClick={() =>
              deleteMember({
                pathParams: {
                  memberId: orgMember.id,
                },
              })
            }
          >
            <WebUI.PhosphorIcon icon="trash-fill" />
          </WebUI.IconButton>
        ),
      },
    ],
    [deleteMember],
  )

  const paginationState = useMemo(
    () => ({
      pageSize: MEMBERS_PER_PAGE,
      pageIndex: query.page - 1,
    }),
    [query.page],
  )
  const sortingState = useMemo(
    () => [
      {
        id: query.sort ?? 'first_name',
        desc: query.direction === 'desc',
      },
    ],
    [query.sort, query.direction],
  )

  const pageCount = pagination
    ? Math.ceil(pagination.total / MEMBERS_PER_PAGE)
    : undefined

  return (
    <WebUI.VStack className="gap-2">
      <WebUI.HStack className="justify-between">
        <SearchForm
          className="mb-2 w-[180px]"
          onSubmit={(values) => {
            if (values.term !== query.search) {
              setQuery((prevQuery) => ({
                ...prevQuery,
                page: 1,
                search: values.term,
              }))
            }
          }}
        />
        <WebUI.IconButton
          className="text-ds-xl"
          variant="default"
          size="default_alt"
          onClick={() =>
            fetchAndSave({
              url: 'users/org_members/members.xlsx',
              fileName: 'members.xlsx',
            })
          }
        >
          <WebUI.PhosphorIcon icon="download-simple-fill" />
        </WebUI.IconButton>
      </WebUI.HStack>

      <WebUI.TableView
        className="[&_.TableViewRow]:py-0"
        columns={columns}
        data={orgMembers}
        state={{pagination: paginationState}}
        pageCount={pageCount}
        manualPagination
        onPaginationChange={(updater) => {
          const newPagination =
            typeof updater === 'function' ? updater(paginationState) : updater

          setQuery((prevQuery) => ({
            ...prevQuery,
            page: newPagination.pageIndex + 1,
          }))
        }}
        sortable
        sortByTogglesVisible
        manualSortBy
        disableSortRemove
        onSortingChange={(updater) => {
          const [newSorting] =
            typeof updater === 'function' ? updater(sortingState) : updater

          setQuery((prevQuery) => ({
            ...prevQuery,
            sort: newSorting?.id,
            direction: newSorting?.desc ? 'desc' : 'asc',
          }))
        }}
      >
        {pageCount && pageCount > 1 && (
          <WebUI.HStack className="justify-end px-8 py-4">
            <WebUI.TablePaginator />
          </WebUI.HStack>
        )}
      </WebUI.TableView>
    </WebUI.VStack>
  )
}

export default MembersPage
