import {
  AxisLinearOptions,
  AxisTimeOptions,
  Chart as ChartPrimitive,
  ChartOptions as ChartPrimitiveOptions,
  UserSerie,
} from 'react-charts'
import {formatDateAs} from '@cheddarup/util'
import {generic} from '@cheddarup/react-util'
import React, {useMemo} from 'react'

import {cn} from '../utils'

export type {UserSerie}

export interface ChartDatum {
  primary: Date
  secondary: number
}

export interface ChartProps<TDatum extends ChartDatum>
  extends React.ComponentPropsWithoutRef<'div'> {
  options: Omit<ChartPrimitiveOptions<TDatum>, 'primaryAxis' | 'secondaryAxes'>
}

export const Chart = generic(
  <TDatum extends ChartDatum>({
    options,
    className,
    ...restProps
  }: ChartProps<TDatum>) => {
    const primaryAxis: AxisTimeOptions<TDatum> = useMemo(
      () => ({
        show: false,
        showGrid: false,
        scaleType: 'time',
        getValue: (datum) => datum.primary,
        formatters: {
          cursor: () => null,
          tooltip: (date) => formatDateAs(date),
        },
      }),
      [],
    )
    const secondaryAxes = useMemo(
      () => [
        {
          show: false,
          scaleType: 'linear',
          getValue: (datum) => datum.secondary,
          showGrid: false,
          formatters: {
            cursor: () => null,
          },
        } as AxisLinearOptions<TDatum>,
      ],
      [],
    )

    return (
      <ChartPrimitive
        className={cn('LineChart', className)}
        options={{
          primaryAxis,
          secondaryAxes,
          ...options,
        }}
        {...restProps}
      />
    )
  },
)
