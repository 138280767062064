import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {LinkButton} from 'src/components/LinkButton'
import WomanInBluewithBadgeCircleSmall from 'src/images/Woman-in-Blue-with-Badge_Circle_Small.png'

export interface EinVerificationUpsellDisclosureProps
  extends Omit<WebUI.DisclosureProps, 'children'> {}

const EinVerificationUpsellDisclosure: React.FC<
  EinVerificationUpsellDisclosureProps
> = (props) => (
  <WebUI.Disclosure {...props}>
    {(disclosure) => (
      <WebUI.DisclosureContent className="[&_>_.DisclosureContent-inner]:!flex max-w-[300px] rounded-extended bg-teal-100 px-6 py-4 [&_>_.DisclosureContent-inner]:flex-col [&_>_.DisclosureContent-inner]:gap-4">
        <div className="flex items-center gap-4">
          <WebUI.Image
            className="shrink-0 rounded-full"
            width={56}
            src={WomanInBluewithBadgeCircleSmall}
            alt=""
          />
          <WebUI.Text className="font-extrabold text-ds-sm">
            Your Account has been verified as a 501(c)(3)!
          </WebUI.Text>
        </div>

        <WebUI.Text className="font-light text-ds-sm">
          Collect more by showing this item as tax-deductible. This makes it
          easier for your donors with instant tax-deductible receipts delivered
          to their inbox.
        </WebUI.Text>
        <div className="flex justify-between">
          <LinkButton
            className="text-ds-sm"
            variant="link"
            to="i/plans?recommendedPlan=team"
            preserveSearch
          >
            Upgrade to Team
          </LinkButton>
          <WebUI.Button
            className="text-ds-sm text-gray400"
            variant="text"
            onClick={disclosure.hide}
          >
            Dismiss
          </WebUI.Button>
        </div>
      </WebUI.DisclosureContent>
    )}
  </WebUI.Disclosure>
)

export default EinVerificationUpsellDisclosure
