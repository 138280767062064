import * as Yup from 'yup'
import React, {useMemo, useRef, useState} from 'react'
import {StringParam, useQueryParam} from 'use-query-params'
import * as WebUI from '@cheddarup/web-ui'
import {
  api,
  useCreateManagerMutation,
  useUpdateManagerMutation,
} from '@cheddarup/api-client'
import {ParammedTabs} from 'src/components/ParammedTabs'
import {
  ConditionalWrapper,
  useFormik,
  useLiveRef,
  useUpdateEffect,
} from '@cheddarup/react-util'
import * as Util from '@cheddarup/util'
import {SearchForm} from 'src/components'

export interface ManagerFormModalProps extends WebUI.ModalProps {
  manager?: Api.Manager | null
}

export const ManagerFormModal: React.FC<ManagerFormModalProps> = ({
  manager,
  initialVisible = false,
  className,
  onDidHide,
  ...restProps
}) => {
  const [_, setTabKey] = useQueryParam('p', StringParam)

  return (
    <WebUI.Modal
      aria-label="Manager access form"
      className={WebUI.cn(
        '[&_>_.ModalContentView]:h-full [&_>_.ModalContentView]:max-w-screen-xl',
        className,
      )}
      initialVisible={initialVisible}
      onDidHide={() => {
        setTabKey(undefined)
        onDidHide?.()
      }}
      {...restProps}
    >
      {(dialog) => (
        <>
          <WebUI.ModalCloseButton />
          <WebUI.ModalHeader className="border-b-0">
            {manager ? 'Edit Manager' : 'Add Manager'}
          </WebUI.ModalHeader>
          <ManagerForm
            key={manager?.id ?? 'new'}
            manager={manager}
            onDidSendInvite={() => dialog.hide()}
          />
        </>
      )}
    </WebUI.Modal>
  )
}

// MARK: – ManagerForm

export interface ManagerFormValues {
  name: string
  email: string
  role: 'viewer' | 'editor' | 'admin'
  scope: 'all' | 'specific'
  collectionIds: number[]
  address_book_and_message_center: boolean
  group_page: boolean
}

type ManagerFormFormik = ReturnType<typeof useFormik<ManagerFormValues>>

export interface ManagerFormProps
  extends Omit<React.ComponentPropsWithoutRef<'form'>, 'onSubmit' | 'onReset'> {
  manager?: Api.Manager | null
  onDidSendInvite?: () => void
}

export const ManagerForm: React.FC<ManagerFormProps> = ({
  className,
  manager,
  onDidSendInvite,
  ...restProps
}) => {
  const createManagerMutation = useCreateManagerMutation()
  const updateManagerMutation = useUpdateManagerMutation()
  const growlActions = WebUI.useGrowlActions()
  const parammedTabsRef = useRef<WebUI.TabsInstance>(null)
  const [selectedTabId, setSelectedTabId] = useState('manager-details')

  const formik = useFormik<ManagerFormValues>({
    initialValues: {
      name: manager?.name ?? '',
      email: manager?.invited_email ?? '',
      role: manager?.permissions.role ?? 'viewer',
      scope: manager?.permissions.scope ?? 'all',
      collectionIds: manager?.permissions.collectionIds ?? [],
      address_book_and_message_center:
        manager?.permissions.address_book_and_message_center ?? false,
      group_page: manager?.permissions.group_page ?? false,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required'),
      email: Yup.string().required('Required').email('Invalid format'),
      role: Yup.string()
        .oneOf(['viewer', 'editor', 'admin'])
        .required('Required'),
      scope: Yup.string().oneOf(['all', 'specific']).required('Required'),
      collectionIds: Yup.array(Yup.string()),
      address_book_and_message_center: Yup.boolean(),
      group_page: Yup.boolean(),
    }),
    onSubmit: async (values) => {
      const body = {
        invited_email: values.email,
        name: values.name,
        permissions: {
          role: values.role,
          scope: values.scope,
          address_book_and_message_center:
            values.role !== 'viewer' &&
            values.scope === 'all' &&
            values.address_book_and_message_center,
          group_page:
            values.role !== 'viewer' &&
            values.scope === 'all' &&
            values.group_page,
          collectionIds: values.collectionIds,
        },
      }

      if (manager) {
        try {
          await updateManagerMutation.mutateAsync({
            pathParams: {
              managerId: manager.id,
            },
            body,
          })
          growlActions.show('success', {
            title: 'Manager Updated!',
            body: 'Successfully updated the manager',
          })
          onDidSendInvite?.()
        } catch {
          growlActions.show('error', {
            title: 'Sorry!',
            body: `Couldn't update the manager!`,
          })
        }
      } else {
        try {
          await createManagerMutation.mutateAsync({
            body,
          })
          onDidSendInvite?.()
          growlActions.show('success', {
            title: 'Invite sent!',
            body: `Manager invite sent to: ${values.email}`,
          })
        } catch {
          growlActions.show('error', {
            title: 'Sorry!',
            body: `Manager with email ${values.email} is already invited. Please try another email.`,
          })
        }
      }
    },
  })

  const isViewer = formik.values.role === 'viewer'
  const setFieldValue = formik.setFieldValue
  useUpdateEffect(() => {
    if (isViewer) {
      setFieldValue('address_book_and_message_center', false)
      setFieldValue('group_page', false)
    } else {
      setFieldValue('address_book_and_message_center', true)
      setFieldValue('group_page', true)
    }
  }, [isViewer, setFieldValue])

  return (
    <WebUI.Form
      className={WebUI.cn(
        'h-full min-h-0 [&_>_.Form-inner]:h-full [&_>_.Form-inner]:gap-0',
        className,
      )}
      onReset={formik.handleReset}
      onSubmit={(event) => {
        const hasManagerDetailsError = formik.errors.name || formik.errors.email
        if (hasManagerDetailsError) {
          parammedTabsRef.current?.select('manager-details')
        }

        formik.handleSubmit(event)
      }}
      {...restProps}
    >
      <ParammedTabs
        ref={parammedTabsRef}
        className={
          'min-h-0 grow [&_>_.TabPanel]:overflow-y-auto [&_>_.TabPanel]:p-6 sm:[&_>_.TabPanel]:px-13 sm:[&_>_.TabPanel]:py-6'
        }
        defaultPaneKey={selectedTabId}
        variant="underlined"
        onChangeSelectedId={(newSelectedId) => {
          if (newSelectedId != null) {
            setSelectedTabId(newSelectedId)
          }
        }}
      >
        <WebUI.TabList
          aria-label="Navigation"
          className="mx-6 flex-0 border-b-0 sm:mx-13 [&_>_.TabList-underline]:bg-orange-50"
        >
          <WebUI.Tab id="manager-details">1. Manager Details</WebUI.Tab>
          <WebUI.Tab id="collection-access">2. Collection Access</WebUI.Tab>
        </WebUI.TabList>

        <WebUI.Separator variant="primary" />

        <WebUI.TabPanel id="manager-details">
          <ManagerDetails manager={manager} formik={formik} />
        </WebUI.TabPanel>
        <WebUI.TabPanel id="collection-access">
          <CollectionAccess formik={formik} />
        </WebUI.TabPanel>
      </ParammedTabs>

      <WebUI.HStack
        className={
          'flex-[0_0_84px] justify-end border-natural-80 border-t bg-natural-100 p-4'
        }
      >
        {selectedTabId === 'collection-access' ? (
          <WebUI.Button
            variant="primary"
            className="min-w-[180px]"
            type="submit"
            size="large"
            loading={formik.isSubmitting}
          >
            {manager ? 'Save' : 'Send Invite'}
          </WebUI.Button>
        ) : (
          <WebUI.Button
            className="min-w-[180px]"
            type="button"
            size="large"
            onClick={() =>
              parammedTabsRef.current?.setSelectedId('collection-access')
            }
          >
            Next
          </WebUI.Button>
        )}
      </WebUI.HStack>
    </WebUI.Form>
  )
}

// MARK: – ManagerDetails

interface ManagerDetailsProps extends React.ComponentPropsWithoutRef<'div'> {
  manager?: Api.Manager | null
  formik: ManagerFormFormik
}

const ManagerDetails: React.FC<ManagerDetailsProps> = ({
  manager,
  formik,
  className,
  ...restProps
}) => (
  <WebUI.VStack className={WebUI.cn('gap-8', className)} {...restProps}>
    {manager ? (
      <WebUI.VStack className="gap-2">
        <WebUI.Heading className="text-ds-md" as="h3">
          Manager: {manager.name}
        </WebUI.Heading>
        <WebUI.Text className="font-light text-ds-sm">
          {manager.invited_email}
        </WebUI.Text>
      </WebUI.VStack>
    ) : (
      <WebUI.VStack className="max-w-[680px] gap-4">
        <WebUI.VStack className="gap-2">
          <WebUI.Heading className="text-ds-md" as="h3">
            Manager
          </WebUI.Heading>
          <WebUI.Text className="font-light">
            Enter the information for the manager you'd like to invite to your
            account.
          </WebUI.Text>
        </WebUI.VStack>

        <WebUI.FormFieldGroup>
          <WebUI.FormField label="Full Name" error={formik.errors.name}>
            <WebUI.Input
              name="name"
              placeholder="Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </WebUI.FormField>
        </WebUI.FormFieldGroup>

        <WebUI.FormField label="Email" error={formik.errors.email}>
          <WebUI.Input
            name="email"
            placeholder="Email Address"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
      </WebUI.VStack>
    )}

    <WebUI.Separator />

    <WebUI.VStack className="gap-4">
      <WebUI.VStack className="gap-2">
        <WebUI.Heading className="text-ds-md" as="h3">
          Role
        </WebUI.Heading>
        <WebUI.Text className="font-light">
          Assign this manager a role:
        </WebUI.Text>
      </WebUI.VStack>

      <WebUI.FormField error={formik.errors.role}>
        <WebUI.RadioGroup
          className="gap-4"
          name="role"
          state={formik.values.role}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        >
          <WebUI.Radio className="items-start" value="viewer">
            <WebUI.VStack>
              <WebUI.Text>Viewer</WebUI.Text>
              <WebUI.Text className="font-light text-ds-sm">
                Can view collection reporting and issue refunds.
              </WebUI.Text>
            </WebUI.VStack>
          </WebUI.Radio>
          <WebUI.Radio className="items-start" value="editor">
            <WebUI.VStack>
              <WebUI.Text>Editor</WebUI.Text>
              <WebUI.Text className="font-light text-ds-sm">
                Can create and edit collections in addition to Viewer access.
              </WebUI.Text>
            </WebUI.VStack>
          </WebUI.Radio>
          <WebUI.Radio className="items-start" value="admin">
            <WebUI.VStack>
              <WebUI.Text>Administrator</WebUI.Text>
              <WebUI.Text className="font-light text-ds-sm">
                Can withdraw funds in addition to Editor access. <br />
                Receives withdrawal notifications providing a second level of
                oversight.
              </WebUI.Text>
            </WebUI.VStack>
          </WebUI.Radio>
        </WebUI.RadioGroup>
      </WebUI.FormField>

      <WebUI.Text className="px-8 font-light text-ds-sm italic">
        Note that managers cannot access bank account information.{' '}
        <WebUI.Anchor
          href="https://support.cheddarup.com/hc/en-us/articles/360035587291-Assign-manager-permissions"
          rel="noopener noreferrer"
          target="_blank"
        >
          Learn more about roles
        </WebUI.Anchor>
      </WebUI.Text>
    </WebUI.VStack>
  </WebUI.VStack>
)

// MARK: – CollectionAccess

interface CollectionAccessProps extends React.ComponentPropsWithoutRef<'div'> {
  formik: ManagerFormFormik
}

const CollectionAccess: React.FC<CollectionAccessProps> = ({
  formik,
  className,
  ...restProps
}) => (
  <WebUI.VStack className={WebUI.cn('gap-4', className)} {...restProps}>
    <WebUI.RadioGroup
      className={
        'gap-0 [&_>_*:not(:first-child):not(.Disclosure)]:mt-6 [&_>_.Disclosure:has(.DisclosureContent)]:mt-3'
      }
      name="scope"
      state={formik.values.scope}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
    >
      <WebUI.Radio className="text-ds-md" value="all">
        All Collections
      </WebUI.Radio>

      <WebUI.Disclosure visible={formik.values.scope === 'all'}>
        <WebUI.DisclosureContent>
          <WebUI.VStack className="gap-4">
            <WebUI.Text className="font-light">
              {formik.values.role === 'viewer'
                ? 'Managers will have access to the account-wide Report Center. Additionally, for managers with the role of “Editor” or “Admin”, you can allow them to view and edit the following:'
                : 'Managers will have access to the account-wide Report Center. Additionally, you can allow them to view and edit the following:'}
            </WebUI.Text>
            <WebUI.VStack className="flex-start gap-2">
              <ConditionalWrapper
                condition={formik.values.role === 'viewer'}
                wrapper={(children) => (
                  <WebUI.DeprecatedTooltip
                    label={`Unavailable to managers with "Viewer" role`}
                  >
                    {children}
                  </WebUI.DeprecatedTooltip>
                )}
              >
                <WebUI.Switch
                  size="small"
                  name="address_book_and_message_center"
                  disabled={formik.values.role === 'viewer'}
                  state={formik.values.address_book_and_message_center}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  Address Book and Message Center
                </WebUI.Switch>
              </ConditionalWrapper>
              <ConditionalWrapper
                condition={formik.values.role === 'viewer'}
                wrapper={(children) => (
                  <WebUI.DeprecatedTooltip
                    label={`Unavailable to managers with "Viewer" role`}
                  >
                    {children}
                  </WebUI.DeprecatedTooltip>
                )}
              >
                <WebUI.Switch
                  size="small"
                  name="group_page"
                  disabled={formik.values.role === 'viewer'}
                  state={formik.values.group_page}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  Group Page
                </WebUI.Switch>
              </ConditionalWrapper>
            </WebUI.VStack>
          </WebUI.VStack>
        </WebUI.DisclosureContent>
      </WebUI.Disclosure>

      <WebUI.Separator />

      <WebUI.Radio className="text-ds-md" value="specific">
        Specific Collections
      </WebUI.Radio>

      <WebUI.Disclosure visible={formik.values.scope === 'specific'}>
        <WebUI.DisclosureContent>
          <WebUI.VStack className="gap-4">
            <WebUI.Text className="font-light">
              Managers who only have access to specific collections will NOT
              have access to account-wide tools including Group Page, Address
              Book, Message Center and Report Center.
            </WebUI.Text>
            <WebUI.VStack>
              <SpecificCollectionsTableView formik={formik} />
            </WebUI.VStack>
          </WebUI.VStack>
        </WebUI.DisclosureContent>
      </WebUI.Disclosure>
    </WebUI.RadioGroup>
  </WebUI.VStack>
)

// MARK: - SpecificCollectionsTableView

interface SpecificCollectionsTableViewProps
  extends React.ComponentPropsWithoutRef<'div'> {
  formik: ManagerFormFormik
}

const SpecificCollectionsTableView: React.FC<
  SpecificCollectionsTableViewProps
> = ({formik, className, ...restProps}) => {
  const collectionsQuery = api.tabs.list.useQuery(undefined, {
    select: (tabs) => tabs.filter((t) => t.access.owner),
  })

  const columnHelper = useMemo(() => WebUI.createColumnHelper<Api.Tab>(), [])

  const columns = useMemo(
    () => [
      columnHelper.accessor((item) => item.name, {
        id: 'name',
        meta: {
          subtle: true,
        },
        header: 'Name',
      }),
    ],
    [columnHelper],
  )

  const selectionState = useMemo(
    () => Util.mapToObj(formik.values.collectionIds, (tId) => [tId, true]),
    [formik.values.collectionIds],
  )
  const selectionStateRef = useLiveRef(selectionState)

  return (
    <WebUI.TableView
      className={WebUI.cn(
        '[&_.TableViewCell]:text-ds-sm [&_.TableViewRow]:py-0',
        className,
      )}
      loading={collectionsQuery.isPending}
      getRowId={(t) => String(t.id)}
      enableRowSelection
      selectAllVisible
      columns={columns}
      data={collectionsQuery.data ?? []}
      state={{rowSelection: selectionState}}
      onRowSelectionChange={(updater) => {
        const newSelectionState =
          typeof updater === 'function'
            ? updater(selectionStateRef.current)
            : updater
        formik.setFieldValue(
          'collectionIds',
          Object.keys(Util.pickBy(newSelectionState, (v) => v === true)).map(
            Number,
          ),
        )
      }}
      {...restProps}
    >
      {(table) => (
        <WebUI.HStack
          className={'-order-1 items-center justify-between border-b px-1 py-2'}
        >
          <div />
          <SearchForm
            className="min-w-[250px] text-ds-xs placeholder:text-ds-xs"
            iconClassName="text-gray400"
            size="compact"
            placeholder="Search by collection name"
            onTermChange={table.setGlobalFilter}
          />
        </WebUI.HStack>
      )}
    </WebUI.TableView>
  )
}
