import {useParams} from 'react-router-dom'
import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import config from 'src/config'
import {canShare} from 'src/helpers/share-helpers'
import {LinkButton} from 'src/components/LinkButton'

export interface CollectionShareButtonProps
  extends Omit<React.ComponentPropsWithoutRef<'button'>, 'onClick'>,
    WebUI.ButtonProps {
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>,
  ) => void
  onDidSucceed?: () => void
  onDidFail?: () => void
}

export const CollectionShareButton = React.forwardRef<
  HTMLButtonElement,
  CollectionShareButtonProps
>(({onDidSucceed, onDidFail, onClick, ...restProps}, forwardedRef) => {
  const urlParams = useParams()

  const collectionUrl = urlParams.user
    ? `https://${urlParams.user}.mycheddarup.com`
    : // biome-ignore lint/style/noNonNullAssertion:
      config.helpers.shareUrl((urlParams.tabSlug || urlParams.slug)!)

  const shareData: ShareData = {url: collectionUrl}

  return canShare(shareData) ? (
    <WebUI.Button
      ref={forwardedRef}
      onClick={async (event) => {
        onClick?.(event)

        if (event.defaultPrevented) {
          return
        }

        try {
          await navigator.share(shareData)
          onDidSucceed?.()
        } catch {
          onDidFail?.()
        }
      }}
      {...restProps}
    />
  ) : (
    <LinkButton
      ref={forwardedRef as any}
      relative="nonContextualPath"
      preserveSearch
      to="share"
      onClick={(event) => {
        onClick?.(event)

        if (!event.defaultPrevented) {
          onDidSucceed?.()
        }
      }}
      {...(restProps as any)}
    />
  )
})
