import {NumberParam, useQueryParam} from 'use-query-params'
import * as WebUI from '@cheddarup/web-ui'
import {useLiveRef} from '@cheddarup/react-util'
import React, {useCallback, useContext, useEffect, useMemo} from 'react'

import {useManagerRoleFolders} from './ManageRoleProvider'

export type FolderId = number | null | undefined

interface FolderContextValue {
  folderId: FolderId
  setFolderId: (folderId: FolderId) => void
}

const FolderContext = React.createContext({} as FolderContextValue)

// MARK: – FolderProvider

export interface FolderProviderProps {
  children: React.ReactNode
}

export const FolderProvider = ({children}: FolderProviderProps) => {
  const [initialFolderId, setInitialFolderId] = useInitialFolderId()
  const managerRoleFolders = useManagerRoleFolders()
  const [folderId, setFolderId] = useQueryParam('fId', NumberParam)

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (folderId === undefined && initialFolderId) {
      setFolderId(initialFolderId)
    }
  }, [])

  useEffect(() => setInitialFolderId(folderId), [folderId, setInitialFolderId])

  const folderIdExists =
    folderId == null ||
    !managerRoleFolders ||
    managerRoleFolders.some((f) => f.id === folderId)
  useEffect(() => {
    if (!folderIdExists) {
      setFolderId(null)
    }
  }, [folderIdExists, setFolderId])

  const contextValue: FolderContextValue = useMemo(
    () => ({
      folderId,
      setFolderId,
    }),
    [folderId, setFolderId],
  )

  return (
    <FolderContext.Provider value={contextValue}>
      {children}
    </FolderContext.Provider>
  )
}

// MARK: – Helper hooks

export function useFolderId() {
  const contextValue = useContext(FolderContext)

  return useMemo(
    () => [contextValue.folderId, contextValue.setFolderId] as const,
    [contextValue.folderId, contextValue.setFolderId],
  )
}

// MARK: – Helpers

type FolderIdInput = number | null | undefined
type FolderIdInitialValue = number | 'default' | null

function convertInputToInitialValue(
  input: FolderIdInput,
): FolderIdInitialValue {
  return input === null ? 'default' : input === undefined ? null : input
}

function convertInitialToInputValue(
  innerValue: FolderIdInitialValue,
): FolderIdInput {
  return innerValue === 'default'
    ? null
    : innerValue == null
      ? undefined
      : innerValue
}

function useInitialFolderId() {
  const [folderId, _setFolderId] = WebUI.useLocalStorage<FolderIdInitialValue>(
    'initialFId',
    convertInputToInitialValue(undefined),
  )
  const folderIdRef = useLiveRef(folderId)

  const setFolderId: React.Dispatch<React.SetStateAction<FolderIdInput>> =
    useCallback(
      (newValue) => {
        const newFolderId =
          newValue instanceof Function
            ? newValue(
                folderIdRef.current === 'default' ? null : folderIdRef.current,
              )
            : newValue

        _setFolderId(convertInputToInitialValue(newFolderId))
      },
      [_setFolderId],
    )

  return [convertInitialToInputValue(folderId), setFolderId] as const
}
