export const bannedWordIncludedIn = (str: string | undefined | null) => {
  const bannedWords = ['venmo', 'cashapp', 'paypal', 'zelle', 'vnmo', 'vinmo']
  const cleanedStr = (str ?? '')
    .toLowerCase()
    .replace(
      /even more|have more|given more|give more|save more|have moved|save money/g,
      '',
    ) // false positives
    .replace('3', 'e')
    .replace('4', 'a')
    .replace('0', 'o')
    .replace(/[^A-Za-z]/g, '')
  return bannedWords.some((bannedWord) => cleanedStr.includes(bannedWord))
}
