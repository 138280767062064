import * as WebUI from '@cheddarup/web-ui'
import {useCancelReminderMutation} from '@cheddarup/api-client'

export interface CancelReminderAlertProps extends WebUI.AlertProps {
  reminderId: number
  onCancel: () => void
}

export const CancelReminderAlert = ({
  reminderId,
  onCancel,
  ...restProps
}: CancelReminderAlertProps) => {
  const cancelReminderMutation = useCancelReminderMutation()
  const growlActions = WebUI.useGrowlActions()

  return (
    <WebUI.Alert aria-label="Cancel Reminder" {...restProps}>
      <WebUI.AlertHeader>Cancel Reminder</WebUI.AlertHeader>
      <WebUI.AlertContentView
        text="Are you sure you want to cancel this reminder?"
        actions={
          <>
            <WebUI.AlertActionButton
              execute={async () => {
                try {
                  await cancelReminderMutation.mutateAsync({
                    pathParams: {
                      reminderId,
                    },
                  })
                  growlActions.show('success', {
                    title: 'Success!',
                    body: 'Reminder cancelled successfully.',
                  })
                  if (onCancel) {
                    onCancel()
                  }
                } catch {
                  growlActions.show('error', {
                    body: 'Something went wrong when canceling the reminder.',
                    title: 'Oops!',
                  })
                }
              }}
            >
              Confirm
            </WebUI.AlertActionButton>
            <WebUI.AlertCancelButton />
          </>
        }
      />
    </WebUI.Alert>
  )
}
