import * as WebUI from '@cheddarup/web-ui'
import {forwardRef} from 'react'
import {ForwardRefComponent} from '@cheddarup/react-util'

import {Link, LinkProps} from './Link'

export interface IconLinkProps extends LinkProps {}

export const IconLink = forwardRef(
  ({className, ...restProps}, forwardedRef) => (
    <Link
      ref={forwardedRef}
      className={WebUI.cn(
        'IconLink',
        'rounded px-1 py-1 [font-size:inherit] hover:bg-iconLinkHoverBackground',
        className,
      )}
      {...restProps}
    />
  ),
) as ForwardRefComponent<'a', IconLinkProps>
