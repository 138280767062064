import * as Yup from 'yup'
import * as WebUI from '@cheddarup/web-ui'
import {useLiveRef} from '@cheddarup/react-util'
import * as Util from '@cheddarup/util'
import {useMemo} from 'react'

import type {RecipientsModalRecipient} from './RecipientsModal'

export interface RecipientsTableViewProps
  extends Omit<
    WebUI.TableViewProps<RecipientsModalRecipient>,
    'columns' | 'data'
  > {
  recipients: RecipientsModalRecipient[]
  onRecipientsChange: (recipients: RecipientsModalRecipient[]) => void
}

export const RecipientsTableView = ({
  recipients,
  onRecipientsChange,
  className,
  ...restProps
}: RecipientsTableViewProps) => {
  const recipientsRef = useLiveRef(recipients)
  const onRecipientsChangeRef = useLiveRef(onRecipientsChange)
  const initialSortingState = useMemo(() => [{id: 'name', desc: false}], [])
  const isRecipientsEmpty = recipients.length === 0

  const columnHelper = useMemo(
    () => WebUI.createColumnHelper<RecipientsModalRecipient>(),
    [],
  )

  const columns = useMemo(
    () => [
      columnHelper.accessor((recipient) => recipient.name, {
        id: 'name',
        size: 120,
        sortingFn: (rowA, rowB) => {
          const fNameA = rowA.original.name.split(' ')[0] || ''
          const fNameB = rowB.original.name.split(' ')[0] || ''
          const lNameA = rowA.original.name.split(' ')[1] || 'zzz'
          const lNameB = rowB.original.name.split(' ')[1] || 'zzz'
          if (lNameA !== lNameB) {
            return lNameA > lNameB ? 1 : -1
          }
          return fNameA > fNameB ? 1 : -1
        },
        header: 'First and Last Name',
        cell: ({cell, row: {original: recipient}}) => (
          <WebUI.InlineEditInput<typeof WebUI.Input>
            className="text-ds-sm [&_.Input[class]]:max-w-[120px]"
            inputOffset={[-8, -8]}
            defaultValue={cell.getValue()}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                ;(event.target as any).blur()
              }
            }}
            onChange={(event) =>
              onRecipientsChangeRef.current(
                recipientsRef.current.map((r) =>
                  r.email === recipient.email
                    ? {...r, name: event.target.value.trim()}
                    : r,
                ),
              )
            }
          />
        ),
      }),
      columnHelper.accessor((r) => r.email, {
        id: 'email',
        header: 'Email',
        cell: ({cell, row: {original: recipient}}) => (
          <WebUI.InlineEditInput<typeof WebUI.Input>
            aria-invalid={
              Yup.string().email().isValidSync(recipient.email)
                ? 'false'
                : 'true'
            }
            className={`text-ds-sm [&_.Input[class]]:max-w-[120px] [&_>_.InlineEdit-disclosure[aria-invalid="true"]]:text-error`}
            inputOffset={[-8, -8]}
            defaultValue={cell.getValue()}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                ;(event.target as any).blur()
              }
            }}
            onChange={(event) =>
              onRecipientsChangeRef.current(
                recipientsRef.current.map((r) =>
                  r.email === recipient.email
                    ? {...r, email: event.target.value.trim()}
                    : r,
                ),
              )
            }
          />
        ),
      }),
      columnHelper.display({
        id: 'delete',
        size: 20,
        meta: {
          align: 'right',
        },
        header: () =>
          isRecipientsEmpty ? null : (
            <WebUI.Alert
              aria-label="Delete all recipients"
              disclosure={
                <WebUI.DialogDisclosure className="text-ds-xs" variant="link">
                  Delete all
                </WebUI.DialogDisclosure>
              }
            >
              <WebUI.AlertHeader>Are you sure?</WebUI.AlertHeader>
              <WebUI.AlertContentView
                text={`You’re about to delete ${Util.pluralize(
                  'recipient',
                  recipientsRef.current.length,
                  true,
                )} from this message.`}
                actions={
                  <>
                    <WebUI.AlertActionButton
                      onClick={(event) => {
                        onRecipientsChangeRef.current([])
                        event.preventDefault()
                      }}
                    >
                      Delete
                    </WebUI.AlertActionButton>
                    <WebUI.AlertCancelButton />
                  </>
                }
              />
            </WebUI.Alert>
          ),
        cell: ({row: {original: recipient}}) => (
          <WebUI.IconButton
            className="text-ds-md"
            size="default_alt"
            onClick={() =>
              onRecipientsChangeRef.current(
                recipientsRef.current.filter(
                  (r) => r.email !== recipient.email,
                ),
              )
            }
          >
            <WebUI.PhosphorIcon icon="trash" />
          </WebUI.IconButton>
        ),
      }),
    ],
    [columnHelper, isRecipientsEmpty],
  )

  return (
    <WebUI.TableView
      className={WebUI.cn(
        `[&_.TableViewCell[data-columnid="delete"]]:h-auto [&_.TableViewCell]:h-9 [&_.TableViewCell]:py-2 [&_.TableViewRow]:max-h-[50px]`,
        className,
      )}
      data={recipients}
      columns={columns}
      initialState={{sorting: initialSortingState}}
      sortable
      sortByTogglesVisible
      {...restProps}
    />
  )
}
