import React, {useMemo} from 'react'
import {
  BooleanParam,
  DecodedValueMap,
  NumberParam,
  QueryParamConfigMap,
  StringParam,
  useQueryParams,
} from 'use-query-params'
import {generic} from '@cheddarup/react-util'
import * as Util from '@cheddarup/util'

export interface WithQueryParamsProps<T extends QueryParamConfigMap> {
  configMap: T
  children:
    | React.ReactNode
    | ((valueMap: DecodedValueMap<T>) => React.ReactNode)
}

export const WithQueryParams = generic(
  <T extends QueryParamConfigMap>({
    configMap,
    children,
  }: WithQueryParamsProps<T>) => {
    const [valueMap] = useQueryParams(configMap)

    if (typeof children === 'function') {
      return <>{children(valueMap)}</>
    }

    return <>{children}</>
  },
)

// MARK: – MatchQueryParams

export interface MatchQueryParamsProps {
  queryParams: Record<string, string | number | boolean>
  children: React.ReactNode | ((isMatch: boolean) => React.ReactNode)
}

export const MatchQueryParams: React.FC<MatchQueryParamsProps> = ({
  queryParams,
  children: childrenProp,
}) => {
  const configMap = useMemo(
    () =>
      Util.mapValues(queryParams, (val) => {
        switch (typeof val) {
          case 'boolean':
            return BooleanParam
          case 'number':
            return NumberParam
          case 'string':
            return StringParam
          default:
            throw new Error(`Unknown type: ${typeof val}`)
        }
      }),
    [queryParams],
  )

  const children =
    typeof childrenProp === 'function'
      ? childrenProp
      : (isMatch: boolean) => (isMatch ? childrenProp : null)

  return (
    <WithQueryParams configMap={configMap}>
      {(resQueryParams) => {
        const isMatch = Util.deepEqual(queryParams, resQueryParams)
        return children(isMatch)
      }}
    </WithQueryParams>
  )
}
