import * as WebUI from '@cheddarup/web-ui'
import React from 'react'

export interface FolderNavItemProps
  extends React.ComponentPropsWithoutRef<'button'> {
  droppable?: boolean
  defaultFolder?: boolean
  selectFolder: (folderId: number | null) => void
  active?: boolean
  folder: Pick<Api.TabFolder, 'name'> & {id: Api.TabFolder['id'] | null}
  onRename?: () => void
  onDelete?: () => void
}

const FolderNavItem = React.forwardRef<HTMLButtonElement, FolderNavItemProps>(
  (
    {
      defaultFolder,
      selectFolder,
      droppable,
      active,
      folder,
      onRename,
      onDelete,
      className,
      ...restProps
    },
    forwardedRef,
  ) => (
    <WebUI.Button
      ref={forwardedRef}
      aria-selected={active}
      className={WebUI.cn(
        'FolderNavItem h-[46px] justify-start whitespace-normal py-2 text-left hover:bg-teal-90 aria-selected:bg-teal-90 aria-selected:font-bold data-[targeted-true]:bg-teal-90 hover:[&_.MenuButton]:invisible',
        className,
      )}
      onClick={() => selectFolder(folder.id)}
      variant="ghost"
      {...(droppable
        ? ({
            as: WebUI.Droppable,
            dropData: folder,
          } as any)
        : undefined)}
      {...restProps}
    >
      <WebUI.HStack className="min-w-0 grow items-center gap-2">
        <WebUI.Ellipsis className="grow">{folder.name}</WebUI.Ellipsis>

        <WebUI.Menu onClick={(event) => event.stopPropagation()}>
          <WebUI.MenuButton
            className="invisible [.FolderNavItem[aria-selected=true]_&]:visible"
            as={WebUI.IconButton}
          >
            <WebUI.PhosphorIcon icon="dots-three-outline-fill" width={25} />
          </WebUI.MenuButton>

          <WebUI.MenuList>
            <WebUI.MenuItem onClick={() => onRename?.()}>
              {defaultFolder ? 'Rename default folder' : 'Rename folder'}
            </WebUI.MenuItem>
            {!defaultFolder && (
              <WebUI.MenuItem onClick={() => onDelete?.()}>
                Delete folder
              </WebUI.MenuItem>
            )}
          </WebUI.MenuList>
        </WebUI.Menu>
      </WebUI.HStack>
    </WebUI.Button>
  ),
)

export default FolderNavItem
