import {useCreateStripeAccountLinkMutation} from '@cheddarup/api-client'
import * as WebUI from '@cheddarup/web-ui'
import React from 'react'
import config from 'src/config'

const CanadianVerificationPage: React.FC = () => {
  const createStripeAccountLinkMutation = useCreateStripeAccountLinkMutation()

  return (
    <WebUI.VStack className="flex-[1] gap-2">
      <WebUI.Heading className="text-center">Canada Verification</WebUI.Heading>
      <WebUI.VStack className="gap-4">
        <span className="text-center text-ds-md">
          Have questions?{' '}
          <WebUI.Anchor
            target="_blank"
            rel="noopener noreferrer"
            href={`mailto:${config.supportEmail}?subject=Contact My Cheddar Up Support`}
          >
            Contact our support team
          </WebUI.Anchor>{' '}
        </span>
        <WebUI.Separator variant="primary" />
        <WebUI.VStack className="gap-2">
          <WebUI.Text className="font-light text-ds-base leading-7">
            To receive payments in Canada, you must verify your identity with
            our partner, Stripe. This process is required by Canadian law and is
            separate from the verification process you completed when you signed
            up for Cheddar Up. When you click below, you will be redirected to
            our partner to complete the verification.
          </WebUI.Text>
        </WebUI.VStack>
        <WebUI.VStack className="gap-2">
          <WebUI.Button
            className="self-center"
            size="large"
            variant="default"
            loading={createStripeAccountLinkMutation.isPending}
            onClick={async () => {
              try {
                const data = await createStripeAccountLinkMutation.mutateAsync()
                if (data) {
                  window.open(data.url, '_blank')
                }
              } catch (error) {
                console.error('Failed to create Stripe account link:', error)
                // Consider showing an error message to the user
              }
            }}
          >
            Complete Verification
          </WebUI.Button>
        </WebUI.VStack>
      </WebUI.VStack>
    </WebUI.VStack>
  )
}
export default CanadianVerificationPage
