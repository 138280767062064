import {z} from '@cheddarup/util'

export const appBannerSchema = z.object({
  id: z.number(),
  name: z.string(),
  banner_text: z.string(),
  link_text: z.string(),
  link_url: z.string(),
  status: z.enum(['active', 'inactive']),
  bg_color: z.enum(['default', 'light', 'dark']),
  icon: z.enum(['warning', 'informative', 'marketing']),
  user_id: z.number(),
  metadata: z.object({
    archived: z.boolean(),
  }),
  deleted_at: z.string().nullish(),
  created_at: z.string(),
  updated_at: z.string(),
})
