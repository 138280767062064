import {useParams} from 'react-router-dom'
import React from 'react'
import * as Util from '@cheddarup/util'
import {excludeTicketHiddenFields, getAttendeeName} from '@cheddarup/core'
import * as WebUI from '@cheddarup/web-ui'
import {RespondentListRowResponse} from 'src/components/FieldViewsList'
import {Link} from 'src/components/Link'
import {InferResponse, endpoints} from '@cheddarup/api-client'

// MARK: – TicketRespondentListRow

export interface TicketRespondentListRowProps
  extends React.ComponentPropsWithoutRef<'div'> {
  tabObjectId: number
  tabMember?: InferResponse<typeof endpoints.tabMembers.list>[number]
}

export const TicketRespondentListRow: React.FC<
  TicketRespondentListRowProps
> = ({tabObjectId, tabMember, className, ...restProps}) => {
  interface PaymentItemResponse {
    name: string
    field_type: Api.TabObjectFieldType
    value: string
  }

  const urlParams = useParams()

  const [latestPayment, ...restPayments] = tabMember?.payments ?? []
  const latestPaymentItemResponses: PaymentItemResponse[] = [
    ...(latestPayment?.payment_items.flatMap((pi) =>
      Util.sort(excludeTicketHiddenFields(pi.item_field_views)).asc(
        (ifv) => ifv.item_field_id ?? ifv.position,
      ),
    ) ?? []),
  ]

  const renderFieldViewRow = (res: PaymentItemResponse, idx: number) => (
    <React.Fragment key={idx}>
      <WebUI.Separator variant="primary" />
      <RespondentListRowResponse
        name={res.name}
        fieldType={res.field_type}
        value={res.value}
      />
    </React.Fragment>
  )

  return (
    <WebUI.Panel
      className={WebUI.cn('gap-4 px-8 py-6 text-gray800', className)}
      as={WebUI.VStack}
      {...restProps}
    >
      <WebUI.VStack className="gap-1">
        <WebUI.HStack className="justify-between gap-3">
          <WebUI.VStack className="mb-3 gap-1">
            <WebUI.Heading as="h4">
              {latestPayment ? (
                getAttendeeName(
                  latestPayment.payment_items[0]?.item_field_views ?? [],
                )
              ) : (
                <WebUI.Skeleton width={160} height={12} />
              )}
            </WebUI.Heading>
            {tabMember && latestPayment ? (
              <WebUI.VStack>
                <WebUI.Text className="font-light text-ds-xs uppercase">
                  Ticket Number:{' '}
                  {latestPayment?.payment_items[0]?.id?.toString(16)}
                </WebUI.Text>
                <WebUI.Text className="font-light text-ds-xs">
                  Payment by: {tabMember.name}
                </WebUI.Text>
                <Link
                  className="text-ds-xs"
                  variant="primary"
                  preserveSearch
                  to={`payment/${latestPayment?.id}/order-summary`}
                >
                  View Order Summary
                </Link>
              </WebUI.VStack>
            ) : (
              <WebUI.Skeleton width={120} height={12} />
            )}
          </WebUI.VStack>

          {!!tabMember && (
            <WebUI.DeprecatedTooltip label="Download Responses (.pdf)">
              <WebUI.IconButton
                as={Link}
                variant="secondary"
                to={`/pdf/collection/${urlParams.collection}/tab-object/${tabObjectId}/tab-member/${tabMember.id}`}
                target="_blank"
              >
                <WebUI.PhosphorIcon icon="printer" width={20} />
              </WebUI.IconButton>
            </WebUI.DeprecatedTooltip>
          )}
        </WebUI.HStack>
        <WebUI.VStack className="max-h-[400px] overflow-y-auto sm:max-h-[800px]">
          <WebUI.VStack className="gap-4">
            {latestPaymentItemResponses.map(renderFieldViewRow)}
            {restPayments.map((payment) => (
              <WebUI.VStack key={payment.id} className="gap-5">
                <WebUI.VStack className="gap-4">
                  {payment.payment_items.flatMap((pi) =>
                    excludeTicketHiddenFields(pi.item_field_views).map(
                      renderFieldViewRow,
                    ),
                  )}
                </WebUI.VStack>
              </WebUI.VStack>
            ))}
            <WebUI.Separator variant="primary" />
          </WebUI.VStack>
        </WebUI.VStack>
      </WebUI.VStack>
    </WebUI.Panel>
  )
}
