import React from 'react'
import * as WebUI from '@cheddarup/web-ui'

import type {TicketFormFormik} from './TicketForm'
import {FormSettingsSwitch} from 'src/components'

export interface TicketFormAdvancedSettingsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  formik: TicketFormFormik
}

export const TicketFormAdvancedSettings: React.FC<
  TicketFormAdvancedSettingsProps
> = ({formik, className, ...restProps}) => (
  <WebUI.VStack
    className={WebUI.cn('[&_>_.Separator]:-mx-2 gap-6', className)}
    {...restProps}
  >
    <FormSettingsSwitch
      name="options.makeAvailableQuantityPublic"
      title="Make available quantity public"
      description="Payers will see how many items are left to purchase."
      checked={formik.values.options.makeAvailableQuantityPublic}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
    />
    <WebUI.Separator />

    {formik.values.available_quantity &&
      formik.values.available_quantity_enabled && (
        <>
          <FormSettingsSwitch
            name="options.waitlist.enabled"
            title="Create a waitlist"
            description="Once your ticket is sold out, create a list of who wants to purchase this ticket so you can contact them if more become available."
            checked={formik.values.options.waitlist.enabled}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.values.options.waitlist.enabled && (
            <WebUI.FormField
              className="mx-3"
              label="Custom Message"
              error={formik.errors.options?.waitlist?.customMessage}
            >
              <WebUI.RichTextEditor
                className="min-h-[112px] max-w-[384px] text-ds-sm"
                name="options.waitlist.customMessage"
                placeholder="Optional: Share details with people who join your waitlist (e.g. when they can expect to hear from you, who to contact with questions, etc.)."
                initialMarkdownValue={
                  formik.values.options.waitlist.customMessage
                }
                onMarkdownValueChange={(newDescription) =>
                  formik.setFieldValue(
                    'options.waitlist.customMessage',
                    newDescription,
                  )
                }
                onBlur={formik.handleBlur}
              />
            </WebUI.FormField>
          )}
          <WebUI.Separator />
        </>
      )}
    <WebUI.VStack className="gap-2">
      <FormSettingsSwitch
        name="limit_per_person_quantity_enabled"
        title="Limit per-person quantity"
        description="Limit the quantity of this item that each payer can add to their cart."
        checked={formik.values.limit_per_person_quantity_enabled}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      {formik.values.limit_per_person_quantity_enabled && (
        <WebUI.FormField
          label="Per-Person Maximum Quantity"
          error={formik.errors.limit_per_person_quantity}
        >
          <WebUI.Input
            className="max-w-32"
            name="limit_per_person_quantity"
            placeholder="Qty."
            type="number"
            value={formik.values.limit_per_person_quantity}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
      )}
    </WebUI.VStack>
    <WebUI.Separator />
    <FormSettingsSwitch
      name="required"
      title="Make purchasing a ticket required"
      description={
        <div>
          <span className="font-bold">
            Only toggle this on if this ticket is required for every payer.
          </span>{' '}
          <span className="font-light">
            Payers will not be able to proceed to checkout without adding this
            ticket to their cart.
          </span>
        </div>
      }
      checked={formik.values.required}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
    />
  </WebUI.VStack>
)
