import {Navigate, useLocation, useParams} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'
import queryString from 'query-string'

import config from '../../config'
import {SignUpForm} from './components'
import {Link} from '../../components/Link'
import {Logo} from '../../components'

const OrgSignUpPage = () => {
  const location = useLocation()
  const urlParams = useParams()

  const queryParams = queryString.parse(location.search)
  const orgSlug = urlParams?.org
  const orgMetadataQuery = api.users.orgMetadata.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        orgSlug: orgSlug!,
      },
    },
    {
      enabled: orgSlug != null,
    },
  )
  const orgMetadata = orgMetadataQuery.data

  const growlActions = WebUI.useGrowlActions()
  const media = WebUI.useMedia()

  if (orgMetadataQuery.isLoading) {
    return null
  }

  const validateAccess = (title: string, body: string) => {
    growlActions.clear()
    growlActions.show('error', {title, body})
    return <Navigate replace to="/signup" />
  }

  if (!orgSlug || !orgMetadata) {
    return validateAccess('Invalid Partner!', 'Partner does not exist')
  }
  if (orgMetadata.disableSignup) {
    return validateAccess('Access Denied!', 'Partner signup not allowed')
  }
  if (!orgMetadata.setupCompleted) {
    return validateAccess('Access Denied!', 'Partner setup incomplete')
  }
  if (orgMetadata.requireInvitation && !queryParams?.invite_code) {
    return validateAccess(
      'Access Denied!',
      "Partner doesn't allow signup without invitation",
    )
  }

  const {branding, assets, formCountrySelectDisplayed} = orgMetadata
  const contentColor = branding?.contentColor?.trim() ?? '#000'
  const buttonColor = branding?.formSubmitButtonColor?.trim() ?? '#f36d36'
  return (
    <>
      <WebUI.NavigationBar
        className="[&_>_.NavigationBar-centerWrapper]:justify-start"
        left={
          assets?.faqUrl ? (
            <WebUI.Anchor
              className="text-ds-sm"
              href={assets.faqUrl}
              target="_blank"
            >
              Knowledge Center
            </WebUI.Anchor>
          ) : (
            <WebUI.AnchorButton
              className="mr-2"
              href={config.links.marketingPage}
            >
              <Logo />
            </WebUI.AnchorButton>
          )
        }
        right={
          <Link
            className="text-ds-sm"
            variant="primary"
            preserveSearch
            to="/login"
          >
            <WebUI.HStack className="gap-1">
              {media.sm && <span>Have an account?</span>}
              <span className="text-orange-50">Log in</span>
            </WebUI.HStack>
          </Link>
        }
      />
      <div
        className="grow bg-center bg-cover sm:bg-left-bottom sm:bg-no-repeat"
        style={{
          backgroundColor: branding?.backgroundColor,
          backgroundImage: `url(${assets?.backgroundImage})`,
        }}
      >
        <div className="mx-auto max-w-[700px] px-8">
          <div
            className={WebUI.cn('text-center', assets?.logo ? 'pt-8' : 'pt-16')}
          >
            {!!assets?.logo && (
              <img
                className="mt-4 mb-8 max-h-[8rem] max-w-[20rem]"
                alt="Logo"
                src={assets.logo}
              />
            )}
            {!!branding?.heading && (
              <WebUI.Heading
                className="mt-1 mb-2"
                style={{color: contentColor}}
              >
                {branding.heading}
              </WebUI.Heading>
            )}
            {branding?.subheading ? (
              <p
                className="mb-8 text-ds-md"
                style={{color: contentColor}}
                // biome-ignore lint/security/noDangerouslySetInnerHtml:
                dangerouslySetInnerHTML={{__html: branding.subheading}}
              />
            ) : (
              <p className="mb-8 text-ds-md" style={{color: contentColor}}>
                Have an account?{' '}
                <Link variant="primary" preserveSearch to="/login">
                  Log in
                </Link>
              </p>
            )}
          </div>
          <SignUpForm
            orgSlug={orgSlug}
            orgMetadata={orgMetadataQuery.data}
            countrySelectDisplayed={formCountrySelectDisplayed}
            submitButtonColor={buttonColor}
            contentColor={contentColor}
          />
          <p
            className="mt-4 text-center font-normal text-ds-sm"
            style={{color: contentColor}}
          >
            By continuing, I agree to Cheddar Up's{' '}
            <WebUI.Anchor
              className="underline"
              target="_blank"
              rel="noopener noreferrer"
              href={config.links.terms}
            >
              Terms of Use
            </WebUI.Anchor>
            {config.links.privacyPolicy && (
              <>
                {' '}
                and{' '}
                <WebUI.Anchor
                  className="underline"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={config.links.privacyPolicy}
                >
                  Privacy Policy
                </WebUI.Anchor>
              </>
            )}
          </p>
        </div>
      </div>
    </>
  )
}

export default OrgSignUpPage
