/// <reference types="@types/google.maps" />

import usePlacesAutocompleteService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import React, {useState} from 'react'

import type {InputProps} from './Input'
import {
  Combobox,
  ComboboxInput,
  ComboboxInstance,
  ComboboxItem,
  ComboboxList,
  ComboboxOption,
  ComboboxOptionCreate,
  ComboboxPopover,
  ComboboxPopoverProps,
  ComboboxProps,
} from './Combobox'
import {PhosphorIcon} from '../icons'

export interface LocationValue {
  address: string
  details: google.maps.places.PlaceResult | null
}

export interface AddressComboboxProps
  extends ComboboxProps,
    Pick<ComboboxPopoverProps, 'placement'>,
    Pick<React.ComponentPropsWithoutRef<'input'>, 'disabled'> {
  googleMapsApiKey?: string
  defaultAddress?: string
  popoverClassName?: string
  inputSize?: InputProps['size']
  onAddressChange?: (address: LocationValue) => void
  withDetails?: boolean
}

export const AddressCombobox = React.forwardRef<
  ComboboxInstance,
  AddressComboboxProps
>(
  (
    {
      googleMapsApiKey = 'AIzaSyB9skK0Hi_VYBztagQWMeo6UXi-am-Y5xY',
      defaultAddress,
      inputSize,
      popoverClassName,
      creatable,
      onAddressChange,
      onInputValueChange,
      onSelectedItemChange,
      defaultSelectedItem,
      selectedItem: selectedItemProp,
      disabled,
      placement,
      withDetails,
      ...restProps
    },
    forwardedRef,
  ) => {
    const {placePredictions, getPlacePredictions, placesService} =
      usePlacesAutocompleteService({
        apiKey: googleMapsApiKey,
        language: 'en',
      })

    const defaultOption =
      defaultAddress && (creatable ? defaultAddress !== '__create__' : true)
        ? {
            value: defaultAddress,
            label: defaultAddress,
          }
        : undefined

    const [selectedItem, setSelectedItem] = useState<ComboboxItem | null>(
      defaultOption ?? defaultSelectedItem ?? null,
    )

    const handleAddressChange = (
      address: string | undefined,
      placeId?: string,
    ) => {
      if (placeId && address && withDetails) {
        try {
          placesService?.getDetails({placeId}, (details) => {
            onAddressChange?.({address, details})
          })
        } catch {
          onAddressChange?.({address, details: null})
        }
      } else {
        onAddressChange?.({address: address ?? '', details: null})
      }
    }

    return (
      <Combobox
        ref={forwardedRef}
        creatable={creatable}
        selectedItem={selectedItemProp ?? selectedItem}
        onInputValueChange={(newValue) => {
          if (newValue.inputValue && newValue.inputValue.length > 1) {
            getPlacePredictions({input: newValue.inputValue})
          }
          if (newValue.inputValue != null && newValue.inputValue.length === 0) {
            handleAddressChange?.('')
          }

          onInputValueChange?.(newValue)
        }}
        onSelectedItemChange={(changes) => {
          onSelectedItemChange?.(changes)

          if (changes.selectedItem?.value) {
            setSelectedItem(changes.selectedItem)

            if (changes.selectedItem.value === '__create__') {
              if (changes.inputValue) {
                onAddressChange?.({address: changes.inputValue, details: null})
              }
            } else {
              handleAddressChange?.(
                changes.selectedItem.label,
                changes.selectedItem.value,
              )
            }
          }
        }}
        {...restProps}
      >
        <ComboboxInput disabled={disabled} size={inputSize} />

        <ComboboxPopover className={popoverClassName} placement={placement}>
          <ComboboxList>
            {placePredictions.length > 0 ? (
              placePredictions.map((pp) => (
                <ComboboxOption
                  key={pp.place_id}
                  value={pp.place_id}
                  label={pp.description}
                  iconBefore={<PhosphorIcon icon="map-pin" />}
                />
              ))
            ) : defaultOption ? (
              <ComboboxOption
                value={defaultOption.value}
                label={defaultOption.label}
                iconBefore={<PhosphorIcon icon="map-pin" />}
              />
            ) : null}

            {creatable && (
              <ComboboxOptionCreate
                sortingScoreMultiplier={999}
                value="__create__"
              >
                {(inputValue) => `Custom: ${inputValue}`}
              </ComboboxOptionCreate>
            )}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    )
  },
)
