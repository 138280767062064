import {endpoints} from '../../endpoints'
import {makeQueryUpdate, makeUseMutation} from '../use-mutation'

export const useDeleteHeaderMutation = makeUseMutation(
  endpoints.headers.delete,
  (vars) => ({
    optimistic: [
      makeQueryUpdate(endpoints.headers.list, (prevHeaders) =>
        prevHeaders?.filter((h) => h.id !== Number(vars.pathParams.headerId)),
      ),
    ],
  }),
)
