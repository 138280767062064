import * as WebUI from '@cheddarup/web-ui'
import {Link, To} from 'react-router-dom'
import React, {useMemo} from 'react'
import ImagesUtils from 'src/helpers/ImagesUtils'
import {IconLink} from 'src/components/IconLink'

import {MarketplaceSectionTitle} from '../../components'

const MAX_SALE_COUNT = 4

export interface NewSalesSectionProps
  extends React.ComponentPropsWithoutRef<'div'> {
  sales: Api.MarketplaceCollection[]
  salesPath: To
}

const NewSalesSection = ({
  sales: allSales,
  salesPath,
  ...restProps
}: NewSalesSectionProps) => {
  const sales = useMemo(() => allSales.slice(0, MAX_SALE_COUNT), [allSales])

  return (
    <div {...restProps}>
      <div
        className={
          'flex items-center [&_>_.Link]:opacity-0 [&_>_.Link]:transition-opacity [&_>_.Link]:duration-150 [&_>_.Link]:ease-in hover:[&_>_.Link]:opacity-100 focus::[&_>_.Link]:opacity-100 active::[&_>_.Link]:opacity-100'
        }
      >
        <MarketplaceSectionTitle>New Sales</MarketplaceSectionTitle>
        <IconLink className="ml-4 text-ds-2xl" to={salesPath}>
          <WebUI.PhosphorIcon icon="arrow-circle-right" />
        </IconLink>
      </div>
      <div className="mt-4 [zoom:1] before:table after:clear-both after:table">
        {sales.map((sale, idx) => (
          <div
            key={sale.id}
            className={WebUI.cn(
              'float-left inline h-32 w-full md:w-1/2 lg:w-1/4',
              idx !== 0 && 'pt-4 sm:pt-0 lg:pl-4',
              idx % 2 !== 0 && 'md:pl-4',
              idx > 1 && 'md:pt-4 lg:pt-0',
            )}
          >
            <NewSalesItem sale={sale} />
          </div>
        ))}
      </div>
    </div>
  )
}

// MARK: – NewSalesItem

interface NewSalesItemProps extends React.ComponentPropsWithoutRef<'a'> {
  sale: Api.MarketplaceCollection
}

const NewSalesItem = React.memo(
  ({sale, className, ...restProps}: NewSalesItemProps) => (
    <Link
      className={WebUI.cn(
        'flex h-full w-full flex-col bg-natural-100 p-4 text-gray800',
        className,
      )}
      target="_blank"
      to={`/c/${sale.slug}?access-code=${sale.access_code}`}
      {...restProps}
    >
      <div className="min-h-0 min-w-0 flex-auto whitespace-normal bg-natural-100 p-1">
        <h5 className="overflow-hidden text-ellipsis whitespace-nowrap font-normal text-ds-base">
          {sale.name} {sale.name} {sale.name}
        </h5>
      </div>
      <div className="w-full p-1 [zoom:1] before:table after:clear-both after:table">
        {sale.organizer.profilePic ? (
          <img
            className="float-left inline h-[2.5rem] w-[2.5rem] rounded-full"
            alt="Organizer profile"
            src={ImagesUtils.getCroppedImageUrl(sale.organizer.profilePic)}
          />
        ) : (
          <WebUI.PhosphorIcon
            className="float-left inline text-teal-70"
            icon="user-circle"
            width={40}
          />
        )}
        <div className="float-left ml-4 inline w-4/5 sm:w-3/5">
          <div
            className={
              'overflow-hidden text-ellipsis whitespace-nowrap font-accent text-ds-sm text-gray400 italic'
            }
          >
            {sale.organizer.display_name || sale.organizer.name}
          </div>
          <div
            className={
              'overflow-hidden text-ellipsis whitespace-nowrap font-light text-ds-sm text-gray400'
            }
          >
            {sale.itemCount} item{sale.itemCount === 1 ? '' : 's'}
          </div>
        </div>
      </div>
    </Link>
  ),
)

export default NewSalesSection
