import * as WebUI from '@cheddarup/web-ui'
import React from 'react'
import {useParams} from 'react-router-dom'

import {NavLink} from './Link'
import {
  SlideOutNav,
  SlideOutNavLinkButton,
  SlideOutNavProps,
} from './SlideOutNav'

export interface CollectionBuilderLayoutProps {
  children: React.ReactNode
}

const CollectionBuilderLayout = ({children}: CollectionBuilderLayoutProps) => {
  const media = WebUI.useMedia()
  const urlParams = useParams()

  return (
    <>
      {!media.lg && !!urlParams.collection && (
        <>
          <CollectionBuilderNavMobile />
          <WebUI.Separator />
        </>
      )}
      <WebUI.HStack className="relative min-h-0 grow">
        <WebUI.VStack className="grow overflow-x-hidden lg:ml-16">
          {children}
        </WebUI.VStack>
        {media.lg && (
          <CollectionBuilderNav className="absolute top-0 bottom-0 left-0" />
        )}
      </WebUI.HStack>
    </>
  )
}

// MARK: – CollectionBuilderNav

const CollectionBuilderNav = (props: SlideOutNavProps) => {
  const urlParams = useParams()

  return (
    <SlideOutNav collapsable {...props}>
      <SlideOutNavLinkButton
        iconBefore={<WebUI.PhosphorIcon icon="info" />}
        to=""
        end
      >
        Details
      </SlideOutNavLinkButton>
      <SlideOutNavLinkButton
        disabled={!urlParams.collection}
        iconBefore={<WebUI.PhosphorIcon icon="tag" />}
        to="items"
      >
        Items
      </SlideOutNavLinkButton>
      <SlideOutNavLinkButton
        disabled={!urlParams.collection}
        iconBefore={<WebUI.PhosphorIcon icon="clipboard-text" />}
        to="forms"
      >
        Forms
      </SlideOutNavLinkButton>
      <SlideOutNavLinkButton
        disabled={!urlParams.collection}
        iconBefore={<WebUI.PhosphorIcon className="rotate-90" icon="sliders" />}
        to="settings"
      >
        Settings
      </SlideOutNavLinkButton>
    </SlideOutNav>
  )
}

// MARK: – CollectionBuilderNavMobile

const CollectionBuilderNavMobile = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => (
  <WebUI.HStack
    className={WebUI.cn(
      'bg-natural-100 shadow-z4 [&_>_.NavLink]:flex-[1_0_0px] [&_>_.NavLink]:py-3 [&_>_.NavLink]:text-center [&_>_.NavLink]:text-ds-xs [&_>_.NavLink]:uppercase',
      className,
    )}
    {...restProps}
  >
    <NavLink to="" end>
      Details
    </NavLink>
    <NavLink to="items">Items</NavLink>
    <NavLink to="forms">Forms</NavLink>
    <NavLink to="settings">Settings</NavLink>
  </WebUI.HStack>
)

export default CollectionBuilderLayout
