// @ts-ignore

import {z} from '@cheddarup/util'

export const tabFolderSchema = z.object({
  id: z.number(),
  name: z.string(),
  position: z.number(),
  organizer: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .nullish(),
  owner: z.boolean().nullish(),
  user_id: z.number().nullish(),
})

declare global {
  namespace Api {
    // @ts-ignore
    type TabFolder = z.infer<typeof tabFolderSchema>
  }
}
