import * as WebUI from '@cheddarup/web-ui'
import React from 'react'
import config from 'src/config'

import usePublicCollection from '../hooks/usePublicCollection'
import {PayerButton, PayerButtonProps} from './PayerStyled'

export interface CheckoutButtonProps extends PayerButtonProps {
  // HACK: refactor, CheckoutButton should be a button only
  echeckSelected?: boolean
}

export const CheckoutButton: React.FC<CheckoutButtonProps> = ({
  echeckSelected = false,
  disabled: disabledProp,
  style,
  ...restProps
}) => {
  const {publicCollection} = usePublicCollection()

  return (
    <WebUI.VStack className="gap-3">
      <PayerButton
        disabled={
          disabledProp ||
          !publicCollection.published_at ||
          publicCollection.type === 'Template'
        }
        variant="themed-secondary"
        {...restProps}
      />
      {(publicCollection.type === 'Template' ||
        !publicCollection.published_at) && (
        <WebUI.Text className="text-center text-ds-xs text-orange-50 leading-compact">
          Preview Only
        </WebUI.Text>
      )}
    </WebUI.VStack>
  )
}

// MARK: – TermsText

export interface TermsTextProps extends React.ComponentPropsWithoutRef<'span'> {
  eCheck?: boolean
}

export const TermsText = ({
  eCheck,
  className,
  ...restProps
}: TermsTextProps) => (
  <p className={WebUI.cn('font-light text-ds-sm', className)} {...restProps}>
    By continuing, I accept the{' '}
    <WebUI.Anchor
      href={config.links.terms}
      rel="noopener noreferrer"
      target="_blank"
    >
      <span>terms of use</span>
    </WebUI.Anchor>
    {eCheck ? ', ' : ' and '}
    <WebUI.Anchor
      href={config.links.privacyPolicy}
      rel="noopener noreferrer"
      target="_blank"
    >
      <span>privacy policy</span>
    </WebUI.Anchor>
    {eCheck && (
      <>
        {' '}
        and{' '}
        <WebUI.Anchor
          href={config.links.achTerms}
          rel="noopener noreferrer"
          target="_blank"
        >
          ACH payment terms
        </WebUI.Anchor>
      </>
    )}
    .
  </p>
)
