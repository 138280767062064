import {endpoints} from '../../endpoints'
import {makeQueryUpdate, makeUseMutation} from '../use-mutation'

export const useCreateGroupPageSpotlightItemMutation = makeUseMutation(
  endpoints.groupPageSpotlightItems.create,
  () => ({
    regular: (newSpotlightItem) => [
      makeQueryUpdate(
        endpoints.groupPageSections.detail,
        (groupPageSection) =>
          groupPageSection
            ? {
                ...groupPageSection,
                spotlight_items: [
                  ...(groupPageSection.spotlight_items || []),
                  newSpotlightItem,
                ],
              }
            : groupPageSection,

        {pathParams: {sectionName: 'collection_spotlight'}},
      ),
    ],
  }),
)

export const useUpdateGroupPageSpotlightItemMutation = makeUseMutation(
  endpoints.groupPageSpotlightItems.update,
  (vars) => ({
    optimistic:
      vars.body?.position == null
        ? []
        : [
            makeQueryUpdate(
              endpoints.groupPageSections.detail,
              (prevGroupPageSection) => {
                if (!prevGroupPageSection) {
                  return prevGroupPageSection
                }

                const newPosition = vars.body?.position ?? -1

                return {
                  ...prevGroupPageSection,
                  spotlight_items: prevGroupPageSection.spotlight_items?.map(
                    (si) =>
                      si.id === vars.body?.id
                        ? {
                            ...si,
                            position: newPosition,
                          }
                        : newPosition >= si.position
                          ? {
                              ...si,
                              position: si.position + 1,
                            }
                          : si,
                  ),
                }
              },
              {pathParams: {sectionName: 'collection_spotlight'}},
            ),
          ],
    regular: (updatedSpotlightItem) => [
      makeQueryUpdate(
        endpoints.groupPageSpotlightItems.detail,
        () => updatedSpotlightItem,
        {
          pathParams: vars.pathParams,
        },
      ),
      makeQueryUpdate(
        endpoints.groupPageSections.detail,
        (groupPageSection) =>
          groupPageSection
            ? {
                ...groupPageSection,
                spotlight_items: groupPageSection.spotlight_items?.map(
                  (spotlightItem) =>
                    spotlightItem.id === updatedSpotlightItem.id
                      ? updatedSpotlightItem
                      : spotlightItem,
                ),
              }
            : groupPageSection,

        {pathParams: {sectionName: 'collection_spotlight'}},
      ),
    ],
  }),
)

export const useDeleteGroupPageSpotlightItemMutation = makeUseMutation(
  endpoints.groupPageSpotlightItems.delete,
  (vars) => ({
    regular: () => [
      makeQueryUpdate(
        endpoints.groupPageSections.detail,
        (groupPageSection) =>
          groupPageSection
            ? {
                ...groupPageSection,
                spotlight_items: groupPageSection.spotlight_items?.filter(
                  (spotlightItem) => spotlightItem.id !== vars.pathParams.id,
                ),
              }
            : groupPageSection,

        {pathParams: {sectionName: 'collection_spotlight'}},
      ),
    ],
  }),
)
