import * as Util from '@cheddarup/util'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import React, {useCallback} from 'react'
import queryString from 'query-string'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'

import {CreditCardSelectRow, PrintShippingLabelInvoice} from '../../components'
import PrintShippingHelpers from '../../helpers/PrintShippingHelpers'

const PrintShippingLabelSummaryPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const urlParams = useParams()
  const firstVisit = queryString.parse(location.search).firstVisit

  const handleReprintShippingLabel = useCallback(() => {
    const url =
      import.meta.env.PUBLIC_URL ||
      `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ''
      }`
    const tab = window.open(
      `${url}/pdf/collection/${urlParams.collection}/tab-payment/${urlParams.payment}/shipping-label`,
    )
    tab?.focus()
  }, [urlParams])

  return (
    <WebUI.Modal
      className="[&_>_.ModalContentView]:max-w-screen-lg"
      onDidHide={() => navigate('..')}
    >
      <WebUI.ModalCloseButton />
      <div className="h-full bg-gray100 px-8 py-16">
        <PrintShippingLabelSummaryContainer
          collectionId={Number(urlParams.collection)}
          paymentId={Number(urlParams.payment)}
          firstVisit={!!firstVisit}
          onReprintShippingLabel={handleReprintShippingLabel}
        />
      </div>
    </WebUI.Modal>
  )
}

// MARK: – PrintShippingLabelSummaryContainer
interface PrintShippingLabelSummaryContainerProps {
  collectionId: number
  paymentId: number
  firstVisit: boolean
  onReprintShippingLabel: () => void
}

const PrintShippingLabelSummaryContainer = ({
  collectionId,
  paymentId,
  firstVisit,
  onReprintShippingLabel,
}: PrintShippingLabelSummaryContainerProps) => {
  const {data: shipment} = api.shipments.detail.useSuspenseQuery({
    pathParams: {
      tabId: collectionId,
      paymentId,
    },
  })

  const handleViewOrderSummary = useCallback(() => {
    const openedWindow = window.open(
      `/pdf/collection/${collectionId}/tab-payment/${paymentId}`,
      '_blank',
    )
    openedWindow?.focus()
  }, [collectionId, paymentId])

  return (
    <>
      <PrintShippingLabelSummaryHeader
        className="mb-4"
        reprintShippingLabelVisible={!firstVisit}
        onReprintShippingLabel={onReprintShippingLabel}
      />
      {firstVisit && (
        <PrintShippingLabelBanner
          className="mb-8"
          onPrintShippingLabel={onReprintShippingLabel}
        />
      )}
      {shipment.shipTo && (
        <RecipientInfo
          className="mb-8 w-full"
          address={shipment.shipTo}
          onViewOrderSummary={handleViewOrderSummary}
        />
      )}
      {shipment.tracking && shipment.selected_rate?.service && (
        <USPSTracking
          className="mb-8 w-full"
          tracking={shipment.tracking}
          service={shipment.selected_rate.service}
        />
      )}
      <ShippingCosts className="w-full" shipment={shipment} />
    </>
  )
}

// MARK: – PrintShippingLabelBanner

interface PrintShippingLabelBannerProps
  extends React.ComponentPropsWithoutRef<'div'> {
  onPrintShippingLabel: () => void
}

const PrintShippingLabelBanner = ({
  className,
  onPrintShippingLabel,
  ...restProps
}: PrintShippingLabelBannerProps) => (
  <div
    className={WebUI.cn(
      'flex items-center justify-between bg-natural-100 p-4',
      className,
    )}
    {...restProps}
  >
    <h3>Success! You can now print your label.</h3>
    <WebUI.Button size="large" variant="primary" onClick={onPrintShippingLabel}>
      Print Shipping Label
    </WebUI.Button>
  </div>
)

// MARK: – PrintShippingLabelSummaryHeader

interface PrintShippingLabelSummaryHeaderProps
  extends React.ComponentPropsWithoutRef<'div'> {
  reprintShippingLabelVisible: boolean
  onReprintShippingLabel: () => void
}

const PrintShippingLabelSummaryHeader = ({
  reprintShippingLabelVisible,
  onReprintShippingLabel,
  className,
  ...restProps
}: PrintShippingLabelSummaryHeaderProps) => (
  <div
    className={WebUI.cn(
      'flex items-center justify-between font-normal',
      className,
    )}
    {...restProps}
  >
    <h2>Shipping Summary</h2>
    {reprintShippingLabelVisible && (
      <div
        className="cursor-pointer text-ds-md text-tint"
        onClick={onReprintShippingLabel}
      >
        Reprint Shipping Label
      </div>
    )}
  </div>
)

// MARK: – RecipientInfo

interface RecipientInfoProps extends React.ComponentPropsWithoutRef<'div'> {
  address: Api.PaymentShippingInfo['shipTo']
  onViewOrderSummary: () => void
}

const RecipientInfo = ({
  address,
  onViewOrderSummary,
  className,
  ...restProps
}: RecipientInfoProps) => (
  <div
    className={WebUI.cn('bg-natural-100 p-4 font-normal', className)}
    {...restProps}
  >
    <div className="mb-4 text-ds-md">Recipient Information</div>
    <div className="text-ds-xs">
      {address.name}
      <br />
      {address.street1}
      <br />
      {address.city}, {address.state} {address.zip}
      <br />
      {address.country === 'US' ? 'UNITED STATES' : 'CANADA'}
      <br />
      {address.phone && (
        <>
          Phone: {address.phone}
          <br />
        </>
      )}
      <a href={`mailto:${address.email}`}>{address.email}</a>
      <div
        className="mt-4 cursor-pointer text-tint"
        onClick={onViewOrderSummary}
      >
        View Order Summary
      </div>
    </div>
  </div>
)

// MARK: – ShippingCosts

interface ShippingCostsProps extends React.ComponentPropsWithoutRef<'div'> {
  shipment: Api.PaymentShipment
}

const ShippingCosts = ({
  shipment,
  className,
  ...restProps
}: ShippingCostsProps) => {
  const signatureAmount = shipment.selected_rate
    ? PrintShippingHelpers.getSignatureConfirmationAmountForService(
        shipment.selected_rate.service,
      )
    : 0

  return (
    <div
      className={WebUI.cn('bg-natural-100 p-4 font-normal', className)}
      {...restProps}
    >
      <div className="flex">
        <div className="mr-4 w-1/2">
          <div className="mb-4 text-ds-md">Shipping Costs</div>
          <PrintShippingLabelInvoice
            titleHidden
            amountsVisible
            amounts={{
              postage: Number.parseFloat(shipment.selected_rate?.rate ?? ''),
              signature:
                shipment.options?.delivery_confirmation === 'SIGNATURE'
                  ? signatureAmount
                  : null,
            }}
          />
        </div>
        {shipment.paymentMethod && (
          <div className="ml-4 w-1/2">
            <div className="mb-4 text-ds-md">Payment method</div>
            <CreditCardSelectRow creditCard={shipment.paymentMethod} />
          </div>
        )}
      </div>
    </div>
  )
}

// MARK: – USPSTracking

const USPS_TRACKING_URL = 'https://tools.usps.com/go/TrackConfirmAction'

interface USPSTrackingProps extends React.ComponentPropsWithoutRef<'div'> {
  tracking: Api.PaymentShipmentTracking
  service: Api.PaymentShipmentService
}

const USPSTracking = ({
  tracking,
  service,
  className,
  ...restProps
}: USPSTrackingProps) => (
  <div
    className={WebUI.cn('bg-natural-100 p-4 font-normal', className)}
    {...restProps}
  >
    <div className="mb-4 text-ds-md">USPS Tracking</div>
    <div className="text-ds-xs">
      Tracking Number:{' '}
      <a
        href={`${USPS_TRACKING_URL}?tLabels=${tracking.code}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        {Util.chunk(tracking.code.split(''), 4)
          .map((codeChunk) => codeChunk.join(''))
          .join(' ')}
      </a>
      <br />
      Service name: {PrintShippingHelpers.getServiceName(service)}
    </div>
  </div>
)

export default PrintShippingLabelSummaryPage
