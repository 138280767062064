import React, {useImperativeHandle, useRef, useState} from 'react'
import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import {useDeleteTimeSlotMutation} from '@cheddarup/api-client'
import {getLocalTimeZone, today} from '@internationalized/date'

import type {SpotFormFormik} from './SpotFormModal'
import type {TimeSlotsFormTimeValue, TimeSlotsFormValues} from './TimeSlotsForm'

export interface TimeSlotsEditFormTimeValue extends TimeSlotsFormTimeValue {
  id?: number
}

export interface TimeSlotsEditFormValues
  extends Pick<TimeSlotsFormValues, 'date'> {
  times: TimeSlotsEditFormTimeValue[]
}

export interface TimeSlotsEditFormProps
  extends React.ComponentPropsWithoutRef<'div'> {
  formik: SpotFormFormik
  collectionId: number
  signUpId: number
  spot: Api.SignUpSpot
  timeValues: TimeSlotsEditFormValues
}

export const TimeSlotsEditForm: React.FC<TimeSlotsEditFormProps> = ({
  formik,
  collectionId,
  signUpId,
  spot,
  timeValues,
  className,
  ...restProps
}) => {
  const timeValueDeleteAlertRef = useRef<TimeValueDeleteAlertInstance>(null)
  const deleteTimeSlotMutation = useDeleteTimeSlotMutation()

  const idx = (formik.values.time_slots as TimeSlotsEditFormValues[]).indexOf(
    timeValues,
  )
  const errors = formik.errors.time_slots?.[idx] as
    | Record<string, any>
    | undefined

  return (
    <div className={WebUI.cn('flex flex-col gap-4', className)} {...restProps}>
      <WebUI.FormField
        className="max-w-[148px]"
        size="compact"
        required
        label="Date"
        error={errors?.date}
      >
        <WebUI.DatePicker
          size="compact"
          minValue={today(getLocalTimeZone())}
          value={timeValues.date}
          onValueChange={(newISO) =>
            formik.setFieldValue(`time_slots[${idx}].date`, newISO)
          }
          onBlur={formik.handleBlur}
        />
      </WebUI.FormField>

      {timeValues.times.map((t, index) => (
        <WebUI.FormFieldGroup
          key={String(index)}
          className="gap-3 [&_.FormField]:max-w-[140px]"
        >
          <WebUI.FormField
            size="compact"
            label={index === 0 ? 'Start Time' : undefined}
            error={errors?.times?.[index]?.start}
          >
            <WebUI.TimeInput
              size="compact"
              value={t.start}
              onValueChange={(newStartTime) =>
                formik.setFieldValue(
                  `time_slots[${idx}].times[${index}].start`,
                  newStartTime,
                )
              }
            />
          </WebUI.FormField>
          <WebUI.FormField
            size="compact"
            label={index === 0 ? 'End Time' : undefined}
            error={errors?.times?.[index]?.end}
          >
            <WebUI.TimeInput
              size="compact"
              value={t.end}
              onValueChange={(newEndTime) =>
                formik.setFieldValue(
                  `time_slots[${idx}].times[${index}].end`,
                  newEndTime,
                )
              }
            />
          </WebUI.FormField>
          <WebUI.FormField
            size="compact"
            label={index === 0 ? 'Available Spots' : undefined}
            error={errors?.times?.[index]?.available_quantity}
          >
            <WebUI.NumberInput
              name={`time_slots[${idx}].times[${index}].available_quantity`}
              size="compact"
              placeholder="Qty."
              value={t.available_quantity}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </WebUI.FormField>

          <WebUI.FormField
            className={WebUI.cn(
              index === 0 ? 'max-h-[62px]' : 'max-h-9',
              'relative flex-0 self-stretch',
            )}
            size="compact"
          >
            <WebUI.IconButton
              className={WebUI.cn(
                index === 0 ? 'top-[calc(50%+28px/2)]' : 'top-1/2',
                '-translate-y-1/2 absolute h-9 w-auto text-ds-md text-gray400',
              )}
              size="default_alt"
              onClick={() => {
                const timeSlot = spot.time_slots.find((ts) => ts.id === t.id)

                if (timeSlot) {
                  timeValueDeleteAlertRef.current?.show({
                    timeSlotId: timeSlot.id,
                    submissionCount: timeSlot?.payment_items.length ?? 0,
                  })
                } else {
                  const newTimes = timeValues.times.filter(
                    (_, ind) => ind !== index,
                  )

                  formik.setFieldValue(`time_slots[${idx}].times`, newTimes)
                  if (newTimes.length === 0) {
                    formik.setFieldValue(
                      'time_slots',
                      (
                        formik.values.time_slots as TimeSlotsEditFormValues[]
                      ).filter((_ts, i) => i !== idx),
                    )
                  }
                }
              }}
            >
              <WebUI.PhosphorIcon icon="x-circle-fill" />
            </WebUI.IconButton>
          </WebUI.FormField>
        </WebUI.FormFieldGroup>
      ))}
      <WebUI.Button
        className="text-ds-sm"
        variant="link"
        onClick={() =>
          formik.setFieldValue(`time_slots[${idx}].times`, [
            ...timeValues.times,
            {
              start: null,
              end: null,
              available_quantity: '',
            },
          ])
        }
      >
        Add time
      </WebUI.Button>

      <TimeValueDeleteAlert
        ref={timeValueDeleteAlertRef}
        onDelete={async (timeSlotId, sendEmail) => {
          if (spot) {
            await deleteTimeSlotMutation.mutateAsync({
              pathParams: {
                tabId: collectionId,
                signupId: signUpId,
                spotId: spot.id,
                timeSlotId,
              },
              queryParams: {
                sendEmail,
              },
            })
          } else {
            const index = timeValues.times.findIndex((t) => t.id === timeSlotId)
            const newTimes = timeValues.times.filter((_t, i) => i !== index)

            if (newTimes.length === 0) {
              formik.setFieldValue(
                'time_slots',
                (formik.values.time_slots as TimeSlotsEditFormValues[]).filter(
                  (_ts, i) => i !== idx,
                ),
              )
            } else {
              formik.setFieldValue(`time_slots[${idx}].times`, newTimes)
            }
          }
        }}
      />
    </div>
  )
}

// MARK: – TimeValueDeleteAlert

interface TimeValueDeleteAlertInstance {
  show: (input: {timeSlotId: number; submissionCount: number}) => void
  dialog: WebUI.DialogInstance | null
}

interface TimeValueDeleteAlertProps extends WebUI.AlertProps {
  onDelete: (timeSlotId: number, sendEmail: boolean) => void
}

const TimeValueDeleteAlert = React.forwardRef<
  TimeValueDeleteAlertInstance,
  TimeValueDeleteAlertProps
>(({onDelete, ...restProps}, forwardedRef) => {
  const ownRef = useRef<WebUI.DialogInstance>(null)
  const [submissionCount, setSubmissionCount] = useState<number | null>(null)
  const [timeSlotId, setTimeSlotId] = useState<number | null>(null)
  const [sendEmail, setSendEmail] = useState(true)

  useImperativeHandle(
    forwardedRef,
    () => ({
      show: (input) => {
        setTimeSlotId(input.timeSlotId)
        setSubmissionCount(input.submissionCount)

        ownRef.current?.show()
      },
      dialog: ownRef.current,
    }),
    [],
  )

  return (
    <WebUI.Alert
      ref={ownRef}
      aria-label="Delete time slot confirmation"
      {...restProps}
    >
      <WebUI.AlertHeader>Are you sure?</WebUI.AlertHeader>
      {timeSlotId && (
        <WebUI.AlertContentView
          text={
            <WebUI.VStack className="gap-4">
              {submissionCount ? (
                <>
                  <span>
                    There {submissionCount === 1 ? 'is' : 'are'}{' '}
                    {Util.pluralize('participant', submissionCount, true)}{' '}
                    signed up for this time. Their information will be removed.
                  </span>
                  <WebUI.Checkbox
                    size="compact"
                    state={sendEmail}
                    onChange={(event) => setSendEmail(event.target.checked)}
                  >
                    Send participants an automatic email that spot has been
                    deleted
                  </WebUI.Checkbox>
                </>
              ) : (
                <span>This action cannot be undone.</span>
              )}
            </WebUI.VStack>
          }
          actions={
            <>
              <WebUI.AlertActionButton
                execute={() => onDelete(timeSlotId, sendEmail)}
              >
                Delete
              </WebUI.AlertActionButton>
              <WebUI.AlertCancelButton />
            </>
          }
        />
      )}
    </WebUI.Alert>
  )
})
