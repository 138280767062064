// @ts-ignore

import {z} from '@cheddarup/util'

import {tabObjectFieldSchema} from './fields'
import {tabPaymentSchema} from './tab-payments'
import {tabItemPaymentSchema} from './payment-items'
import {tabItemSchema} from './tab-items'

export const variantSaleSchema = z.object({
  listing: z.string(),
  optionvalues: z.string(),
  quantity_sold: z.number(),
  tab_object_id: z.number(),
  total_sold: z.number(),
})

export const tabItemWithPaymentInfoSchema = tabItemSchema.extend({
  amount_discounted: z.number(),
  amount_refunded: z.number(),
  amount_sold: z.number(),
  net_amount: z.number(),
  net_quantity: z.number(),
  quantity_refunded: z.number(),
  quantity_sold: z.number(),
  total_buyers: z.number(),
  variant_sales: variantSaleSchema.array().nullish(),
  available_variant_quantity: z.number().nullish(),
  waitlist_count: z.number(),
})

export const tabItemReportPaymentItemPaymentSchema = tabPaymentSchema.pick({
  created_at: true,
  payment_method: true,
  scheduled_invoices: true,
  status: true,
  tab_member: true,
})

export const tabItemReportPaymentItemSchema = tabItemPaymentSchema
  .pick({
    id: true,
    amount: true,
    detail: true,
    item_field_views: true,
    payment_id: true,
    quantity: true,
    total: true,
    refund_data: true,
    ticket_number: true,
  })
  .extend({
    payment: tabItemReportPaymentItemPaymentSchema,
  })

export const tabItemReportSchema = tabItemWithPaymentInfoSchema
  .pick({
    id: true,
    created_at: true,
    deleted_at: true,
    name: true,
    description: true,
    position: true,
    required: true,
    images: true,
    options: true,
    allow_quantity: true,
    available_quantity: true,
    amount: true,
    amount_type: true,
    amount_discounted: true,
    amount_refunded: true,
    amount_sold: true,
    net_amount: true,
    quantity_sold: true,
    quantity_refunded: true,
    net_quantity: true,
    total_buyers: true,
    waitlist_count: true,
  })
  .extend({
    fields: tabObjectFieldSchema.array(),
    payment_items: tabItemReportPaymentItemSchema.array(),
  })

declare global {
  namespace Api {
    // @ts-ignore
    type TabItemReportPaymentItem = z.infer<
      typeof tabItemReportPaymentItemSchema
    >
    // @ts-ignore
    type TabItemReportPaymentItemPayment = z.infer<
      typeof tabItemReportPaymentItemPaymentSchema
    >
    // @ts-ignore
    type TabItemWithPaymentInfo = z.infer<typeof tabItemWithPaymentInfoSchema>
    // @ts-ignore
    type VariantSale = z.infer<typeof variantSaleSchema>
    // @ts-ignore
    type TabItemReport = z.infer<typeof tabItemReportSchema>
  }
}
