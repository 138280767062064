import {useMemo, useRef, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'

export interface ItemVariantValuesSelectProps
  extends Omit<WebUI.MultiComboboxProps, 'onChange'> {
  values: string[]
  optionName: string
  value: string[]
  onChange: (newValue: string[]) => void
  onSelectedValueChange: (oldValue: string, newValue: string) => void
}

const ItemVariantValuesSelect = ({
  values,
  optionName,
  value,
  onChange,
  onSelectedValueChange,
  ...restProps
}: ItemVariantValuesSelectProps) => {
  const [valueToDelete, setValueToDelete] = useState<string | null>(null)
  const comboboxInputWrapperRef = useRef<HTMLDivElement>(null)
  const inlineEditDialogRefMap = useRef<
    Record<number, WebUI.InlineEditDialogInstance>
  >({})

  return (
    <>
      <WebUI.MultiCombobox
        creatable
        selectedItems={useMemo(() => value.map((v) => ({value: v})), [value])}
        onSelectedItemsChange={(change) => {
          if (
            change.selectedItems &&
            change.type ===
              WebUI.useMultipleSelection.stateChangeTypes
                .FunctionRemoveSelectedItem
          ) {
            const newSelectedValues = change.selectedItems
              .map((i) => i.value)
              .filter((v) => v !== '__create__')
            const newValueToDelete = value.find(
              (v) => !newSelectedValues.includes(v),
            )
            if (newValueToDelete) {
              setValueToDelete(newValueToDelete)
            }
          } else {
            onChange(
              change.selectedItems?.map((o) =>
                o.value === '__create__' ? (o.label ?? o.value) : o.value,
              ) ?? [],
            )
          }
        }}
        {...restProps}
      >
        <div ref={comboboxInputWrapperRef}>
          <WebUI.MultiComboboxStatefulInput
            className="[&_>_.MultiComboboxValues_>_.MultiComboboxValues-item_>_.MultiComboboxValues-itemTag]:text-ds-xs"
            size="compact"
            placeholder="E.g. Small"
            TagComponent={({
              // @ts-expect-error
              'data-index': tagDataIndex,
              className: tagClassName,
              onClick: onTagClick,
              children: tagChildren,
              ...restTagProps
            }) => (
              <WebUI.InlineEditInput<typeof WebUI.Input>
                dialogRef={(dialogInstance) => {
                  if (typeof tagDataIndex === 'number' && dialogInstance) {
                    inlineEditDialogRefMap.current[tagDataIndex] =
                      dialogInstance
                  }
                }}
                inputClassName="w-18"
                inputSize="compact"
                DisplayComponent={({DefaultDisplayComponent}) => (
                  <DefaultDisplayComponent
                    content={
                      <WebUI.Tag
                        className={WebUI.cn(tagClassName, 'text-tint')}
                        {...restTagProps}
                        onClick={(event) => {
                          onTagClick?.(event)

                          inlineEditDialogRefMap.current[tagDataIndex]?.show()
                        }}
                      >
                        {tagChildren}
                      </WebUI.Tag>
                    }
                  />
                )}
                defaultValue={
                  typeof tagChildren === 'string' ? tagChildren : undefined
                }
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    ;(event.target as any).blur()
                  }

                  event.stopPropagation()
                }}
                onBlur={(event) => {
                  const oldValue =
                    typeof tagChildren === 'string' ? tagChildren : undefined

                  if (oldValue != null) {
                    onSelectedValueChange(oldValue, event.target.value)
                  }
                }}
              />
            )}
          />
        </div>
        <WebUI.ComboboxPopover reference={comboboxInputWrapperRef.current}>
          <WebUI.ComboboxList className="[&_>_.ComboboxOption]:text-ds-sm">
            {values
              .filter((v) => !value.includes(v))
              .map((v) => (
                <WebUI.ComboboxOption key={v} value={v} />
              ))}
            <WebUI.ComboboxOptionCreate value="__create__" />
          </WebUI.ComboboxList>
        </WebUI.ComboboxPopover>
      </WebUI.MultiCombobox>

      <WebUI.Alert
        aria-label="Delete item option"
        visible={!!valueToDelete}
        onDidHide={() => setValueToDelete(null)}
      >
        <WebUI.AlertHeader>Delete option value</WebUI.AlertHeader>
        <WebUI.AlertContentView
          text={
            <>
              You&apos;re about to delete 1 variant with a{' '}
              <span className="font-bold">{optionName.toLowerCase()}</span> of{' '}
              <span className="font-bold">{valueToDelete}</span>. This action
              can&apos;t be undone.
            </>
          }
          actions={
            <>
              <WebUI.AlertActionButton
                onClick={() => {
                  if (valueToDelete) {
                    onChange(value.filter((v) => v !== valueToDelete))
                    setValueToDelete(null)
                  }
                }}
              >
                Delete
              </WebUI.AlertActionButton>
              <WebUI.AlertCancelButton />
            </>
          }
        />
      </WebUI.Alert>
    </>
  )
}

export default ItemVariantValuesSelect
