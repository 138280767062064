import {getCookie} from 'src/helpers/cookie-helpers'

const AdvertisingUtils = {
  getSignupParams: () => {
    const payload: Record<string, string | undefined> = {}
    payload.gclid = getCookie('gclid1') || getCookie('gclid1legacy')
    const attributionParams = [
      'gclid',
      'msclkid',
      'fbclid',
      'utm_medium',
      'utm_source',
      'utm_campaign',
      'utm_content',
      'utm_term',
      'term',
      'campaignid',
      'adgroupid',
      'creative',
      'placement',
      'adposition',
      'placement',
    ]
    for (const a of attributionParams) {
      ;['firsttouch', 'lasttouch'].forEach((b) => {
        const name = `${a}${b}`
        const value = getCookie(name)
        if (value) {
          payload[name] = value
        }
      })
    }
    payload.gaClientId = window?.getGAClientId?.() ?? undefined
    return payload
  },
}

export default AdvertisingUtils
