import 'react-image-crop/dist/ReactCrop.css'
import {useNavigate} from 'react-router-dom'
import {
  PixelCrop,
  Component as ReactCrop,
  centerCrop,
  defaultCrop,
  makeAspectCrop,
} from 'react-image-crop'
import {useEffect, useMemo, useRef, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'

import {LinkButton} from '../../components/LinkButton'

export interface ImageCropPageProps extends WebUI.ModalProps {
  image: Blob
  onCropSubmit: (crop: Api.CropDetails) => void
}

const ImageCropPage = ({
  image,
  onCropSubmit,
  className,
  ...restProps
}: ImageCropPageProps) => {
  const navigate = useNavigate()
  const [crop, setCrop] = useState<PixelCrop>(defaultCrop)
  const scaleRef = useRef<{x: number; y: number}>({x: 1, y: 1})

  const imageUri = useMemo(() => URL.createObjectURL(image), [image])

  useEffect(() => () => URL.revokeObjectURL(imageUri), [imageUri])

  return (
    <WebUI.Modal
      aria-label="Crop image form"
      className={WebUI.cn(
        '[&_>_.ModalContentView]:h-full [&_>_.ModalContentView]:max-h-full [&_>_.ModalContentView]:w-full [&_>_.ModalContentView]:rounded-none',
        className,
      )}
      onDidHide={() => navigate({pathname: '..'})}
      {...restProps}
    >
      <WebUI.ModalCloseButton />

      <WebUI.ModalHeader>Crop Image</WebUI.ModalHeader>

      <WebUI.VStack className="min-h-0 grow">
        <WebUI.VStack className="grow items-center overflow-y-auto p-2">
          {imageUri && (
            <ReactCrop
              className="!max-w-screen-lg !overflow-visible w-full"
              keepSelection
              crop={crop}
              aspect={3 / 1}
              onChange={(pixelCrop) => setCrop(pixelCrop)}
            >
              <img
                className="!max-w-screen-lg w-full"
                alt="Uploaded"
                src={imageUri}
                onLoad={(event) => {
                  const imageEl = event.currentTarget

                  setCrop(
                    centerCrop(
                      makeAspectCrop(
                        {
                          unit: 'px',
                          width: imageEl.width,
                        },
                        3 / 1,
                        imageEl.width,
                        imageEl.height,
                      ),
                      imageEl.width,
                      imageEl.height,
                    ) as PixelCrop,
                  )
                  scaleRef.current = {
                    x: imageEl.naturalWidth / imageEl.width,
                    y: imageEl.naturalHeight / imageEl.height,
                  }
                }}
              />
            </ReactCrop>
          )}
        </WebUI.VStack>
        <WebUI.Separator variant="primary" />
        <WebUI.HStack className="justify-end gap-3 p-4">
          <LinkButton size="large" variant="secondary" to="../edit">
            Cancel
          </LinkButton>
          <WebUI.Button
            size="large"
            variant="primary"
            onClick={() =>
              onCropSubmit({
                x: scaleRef.current.x * (crop?.x ?? 0),
                y: scaleRef.current.y * (crop?.y ?? 0),
                width: scaleRef.current.x * (crop?.width ?? 0),
                height: scaleRef.current.y * (crop?.height ?? 0),
              })
            }
          >
            Select
          </WebUI.Button>
        </WebUI.HStack>
      </WebUI.VStack>
    </WebUI.Modal>
  )
}

export default ImageCropPage
