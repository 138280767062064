import React from 'react'
import * as WebUI from '@cheddarup/web-ui'

export interface CollectionSettingsPanelProps
  extends React.ComponentPropsWithoutRef<'div'> {
  heading: React.ReactNode
  settings: Array<React.ReactElement | null>
}

export const CollectionSettingsPanel = ({
  heading,
  settings,
  ...restProps
}: CollectionSettingsPanelProps) => (
  <WebUI.VStack as={WebUI.Panel} {...restProps}>
    <WebUI.HStack className="items-center px-6 py-5">
      <WebUI.Text className="text-ds-sm uppercase">{heading}</WebUI.Text>
    </WebUI.HStack>
    <WebUI.VStack>
      {settings
        .filter((setting) => !!setting)
        .map((setting, idx) => (
          <React.Fragment key={idx}>
            <WebUI.Separator variant="primary" />
            <div className="px-6 py-5 *:w-full">{setting}</div>
          </React.Fragment>
        ))}
    </WebUI.VStack>
  </WebUI.VStack>
)
