import * as Ariakit from '@ariakit/react'
import React from 'react'
import {cva} from 'class-variance-authority'

import {cn, VariantsProps} from '../../utils'

export const tag = cva(
  [
    'relative inline-flex flex-0 items-center justify-center gap-2 px-3 py-[0.5em]',
    'max-w-full overflow-hidden text-ellipsis whitespace-nowrap',
    'transition-all',
  ],
  {
    variants: {
      variant: {
        default: [
          'border border-grey-200 bg-trueWhite text-grey-600',
          'font-normal',
          'hover:shadow-100',
          'aria-disabled:text-grey-300',
        ],
        headless: '',
      },
      roundness: {
        default: 'rounded',
        headless: '',
      },
    },
    defaultVariants: {
      variant: 'default',
      roundness: 'default',
    },
  },
)

export interface TagProps
  extends Ariakit.RoleProps<'div'>,
    VariantsProps<typeof tag> {
  clearLabel?: string
  onClear?: () => void
}

// Inspired by https://nextui.org/docs/components/chip
// This is kind of useless right now
// We might want to introduce TagGroup, single/multi select, etc...
// but this is more about behavior, at least we have the styling
export const Tag = React.forwardRef<HTMLDivElement, TagProps>(
  (
    {
      variant = 'default',
      roundness = 'default',
      onClear,
      clearLabel,
      className,
      children,
      ...restProps
    },
    forwardedRef,
  ) => (
    <Ariakit.Role.div
      ref={forwardedRef}
      className={cn(tag({variant, roundness}), className)}
      {...restProps}
    >
      {children}
    </Ariakit.Role.div>
  ),
)
