import * as WebUI from '@cheddarup/web-ui'

import {FormFieldInlineLabelEdit} from './FormFieldInlineLabelEdit'
import type {FieldGroupFieldsEditProps} from './FieldGroupFieldsEdit'

export const EmailFieldsEdit = ({
  required,
  focused,
  initialLabels,
  placeholders,
  onLabelChange,
}: Omit<
  FieldGroupFieldsEditProps<'email'>,
  'fieldGroupType' | 'initialValue' | 'onValueChange'
>) => (
  <FormFieldInlineLabelEdit
    labelInputFocused={focused}
    required={required}
    initialLabel={initialLabels.value}
    placeholder={placeholders.value}
    onLabelChange={(label) => onLabelChange('value', label)}
  >
    <WebUI.Input disabled />
  </FormFieldInlineLabelEdit>
)
