import {useNavigate} from 'react-router-dom'
import {useEffect} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {useLogout} from 'src/hooks/useAuth'

import {Logo} from '../components'

const LogoutPage = () => {
  const navigate = useNavigate()
  const [logout] = useLogout()
  const [, setBannerShown] = WebUI.useLocalStorage(
    'banner_shown_session',
    false,
  )
  const [, setTabsSortParams] = WebUI.useLocalStorage('tabsSortParams', null)
  const [, setNavCollapsed] = WebUI.useLocalStorage('collections_nav', false)

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    ;(async () => {
      setBannerShown(false)
      setNavCollapsed(false)
      setTabsSortParams(null)
      await logout().catch(() => {
        // noop
      })
      navigate('/login', {replace: true})
    })()
  }, [])

  return (
    <>
      <WebUI.NavigationBar left={<Logo />} />
      <WebUI.VStack className="grow items-center justify-center gap-4">
        <WebUI.Heading as="h4">Logging out...</WebUI.Heading>
        <WebUI.Loader />
      </WebUI.VStack>
    </>
  )
}

export default LogoutPage
