import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {managerSchema} from '../schemas'

export default {
  list: makeEndpoint({
    path: 'users/managers',
    responseSchema: managerSchema.array(),
  }),
  create: makeEndpoint({
    path: 'users/managers',
    method: 'POST',
    bodySchema: managerSchema.partial(),
    responseSchema: managerSchema,
  }),
  update: makeEndpoint({
    path: 'users/managers/:managerId',
    method: 'PATCH',
    bodySchema: z.any(),
    responseSchema: managerSchema,
  }),
  delete: makeEndpoint({
    path: 'users/managers/:managerId/delete',
    method: 'POST',
    responseSchema: z.any(),
  }),
  remind: makeEndpoint({
    path: 'users/managers/:managerId/remind',
    method: 'POST',
    responseSchema: z.object({
      invited_email: z.string(),
    }),
  }),
  acceptInvitation: makeEndpoint({
    path: 'users/managers/:managerId/accept_invite',
    method: 'POST',
    bodySchema: z.object({
      invite_code: z.string(),
    }),
    responseSchema: z.any(),
  }),
  updateNotification: makeEndpoint({
    path: 'user/manager_notifications/:managerId',
    method: 'PATCH',
    bodySchema: z.object({
      notifications: z.object({
        payments: z.boolean(),
        reminders: z.boolean(),
      }),
    }),
    responseSchema: z.any(),
  }),
}
