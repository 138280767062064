// Based on https://github.com/ariakit/ariakit/blob/main/packages/ariakit-react-core/src/utils/hooks.ts#L212

import React, {useEffect, useRef} from 'react'

export function useUpdateEffect(
  effect: React.EffectCallback,
  deps?: React.DependencyList,
) {
  const mounted = useRef(false)

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (mounted.current) {
      return effect()
    }
    mounted.current = true
  }, deps)

  useEffect(
    () => () => {
      mounted.current = false
    },
    [],
  )
}
