// See https://www.w3.org/WAI/ARIA/apg/patterns/accordion/

import React from 'react'
import {ForwardRefComponent} from '@cheddarup/react-util'

import {HStack} from './Stack'
import {DisclosureButton} from './Disclosure'
import {Text} from './Text'
import {PhosphorIcon} from '../icons'
import {cn} from '../utils'

export interface AccordionHeaderProps {}

export const AccordionHeader = React.forwardRef(
  ({as: Comp = 'h3', className, children, ...restProps}, forwardedRef) => (
    <Comp
      ref={forwardedRef}
      className={cn(
        'AccordionHeader relative flex min-h-18 overflow-hidden text-ds-base',
        className,
      )}
      {...restProps}
    >
      <DisclosureButton
        className={
          'AccordionHeader-button flex h-auto shrink-0 grow basis-full items-center justify-start rounded-[unset] px-[unset] py-[unset] text-inherit transition-opacity duration-100 ease-in-out [font-size:inherit] [text-align:inherit] hover:bg-transparent hover:[&_.AccordionHeader-icon]:opacity-70 hover:[&_.AccordionHeader-title]:opacity-70'
        }
        variant="ghost"
        arrow={false}
      >
        <HStack className="AccordionHeader-content shrink-0 grow basis-full gap-3">
          <PhosphorIcon
            className={`AccordionHeader-icon text-ds-md transition-transform duration-100 ease-linear [.AccordionHeader-button[aria-expanded="true"]_>_.AccordionHeader-content_>_&]:rotate-90`}
            icon="caret-right-fill"
          />

          {typeof children === 'string' ? (
            <Text className="AccordionHeader-title">{children}</Text>
          ) : (
            children
          )}
        </HStack>
      </DisclosureButton>
    </Comp>
  ),
) as ForwardRefComponent<
  'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6',
  AccordionHeaderProps
>
