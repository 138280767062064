import {HTMLMotionProps, motion} from 'framer-motion'
import {
  Popover as ReakitPopover,
  PopoverArrow as ReakitPopoverArrow,
  PopoverDisclosure as ReakitPopoverDisclosure,
  PopoverDisclosureOptions as ReakitPopoverDisclosureOptions,
  PopoverInitialState as ReakitPopoverInitialState,
  PopoverOptions as ReakitPopoverOptions,
  PopoverStateReturn as ReakitPopoverStateReturn,
  usePopoverState,
} from 'reakit'
import {
  ForwardRefComponent,
  useForkRef,
  useLiveRef,
  useUpdateEffect,
} from '@cheddarup/react-util'
import React, {useContext, useId, useImperativeHandle, useState} from 'react'

import {Button} from './Button'
import {VStack} from './Stack'
import {usePreventBodyScroll} from '../hooks'
import {cn} from '../utils'

interface InternalPopoverContextValue extends ReakitPopoverStateReturn {}

const InternalPopoverContext = React.createContext(
  {} as InternalPopoverContextValue,
)

// MARK: - Popover

export interface PopoverInstance extends ReakitPopoverStateReturn {}
export interface PopoverProps extends ReakitPopoverInitialState {
  children?: React.ReactNode | ((popover: PopoverInstance) => React.ReactNode)
  initialVisible?: boolean
  onVisibleChange?: (newVisible: boolean) => void
  onDidShow?: () => void
  onDidHide?: () => void
}

export const Popover = React.forwardRef<PopoverInstance, PopoverProps>(
  (
    {
      baseId,
      initialVisible,
      visible,
      animated = true,
      modal = true,
      placement,
      unstable_fixed,
      unstable_flip,
      unstable_offset,
      gutter = 8,
      unstable_preventOverflow,
      children,
      onVisibleChange,
      onDidShow,
      onDidHide,
    },
    forwardedRef,
  ) => {
    const popover = usePopoverState({
      baseId,
      visible: initialVisible ?? visible,
      animated,
      modal,
      placement,
      unstable_fixed,
      unstable_flip,
      unstable_offset,
      gutter,
      unstable_preventOverflow,
    })
    const popoverRef = useLiveRef(popover)
    const onVisibleChangeRef = useLiveRef(onVisibleChange)
    const onDidShowRef = useLiveRef(onDidShow)
    const onDidHideRef = useLiveRef(onDidHide)

    useImperativeHandle(forwardedRef, () => popover, [popover])

    useUpdateEffect(() => {
      if (visible != null) {
        popoverRef.current.setVisible(visible)
      }
    }, [visible])

    useUpdateEffect(() => {
      if (!popover.animating) {
        onVisibleChangeRef.current?.(popover.visible)
        if (popover.visible) {
          onDidShowRef.current?.()
        } else {
          onDidHideRef.current?.()
        }
      }
    }, [popover.visible, popover.animating])

    return (
      <InternalPopoverContext.Provider value={popover}>
        {typeof children === 'function' ? children(popover) : children}
      </InternalPopoverContext.Provider>
    )
  },
)

// MARK: - PopoverDisclosure

export interface PopoverDisclosureProps
  extends Omit<
    ReakitPopoverDisclosureOptions,
    keyof ReakitPopoverStateReturn
  > {}

export const PopoverDisclosure = React.forwardRef(
  ({as: Comp = Button, children, className, ...restProps}, forwardedRef) => {
    const popover = useContext(InternalPopoverContext)
    return (
      <ReakitPopoverDisclosure
        ref={forwardedRef}
        className={cn('PopoverDisclosure', className)}
        {...popover}
        {...restProps}
      >
        {(props: any) => <Comp {...props}>{children}</Comp>}
      </ReakitPopoverDisclosure>
    )
  },
) as ForwardRefComponent<typeof Button, PopoverDisclosureProps>

// MARK: - PopoverContent

export interface PopoverContentProps
  extends Omit<ReakitPopoverOptions, keyof ReakitPopoverStateReturn> {
  children?: React.ReactNode | ((popover: PopoverInstance) => React.ReactNode)
  arrow?: boolean
  portal?: boolean
  fullWidth?: boolean
  shrinkable?: boolean
}

export const PopoverContent = React.forwardRef(
  (
    {
      as: Comp = 'div',
      children,
      id: idProp,
      className,
      arrow,
      shrinkable = true,
      fullWidth = true,
      preventBodyScroll = false,
      ...restProps
    },
    forwardedRef,
  ) => {
    const popover = useContext(InternalPopoverContext)
    const [ownElement, setOwnElement] = useState<HTMLElement | null>(null)
    const ref = useForkRef(forwardedRef, setOwnElement)

    const id = idProp ?? useId()

    usePreventBodyScroll(ownElement, id, preventBodyScroll && popover.visible)

    return (
      <ReakitPopover
        ref={ref}
        className={cn('PopoverContent', 'focus:outline-none', className)}
        preventBodyScroll={false}
        {...popover}
        {...restProps}
      >
        {(props) => {
          if (!(popover.visible || popover.animating)) {
            return <>{null}</>
          }

          const referenceRect =
            popover.unstable_referenceRef.current?.getBoundingClientRect()
          return (
            <Comp
              {...props}
              style={(() => {
                const isRightAligned =
                  popover.placement === 'top-end' ||
                  popover.placement === 'bottom-end'
                const isBottomAligned =
                  popover.placement === 'left-end' ||
                  popover.placement === 'right-end'

                if (
                  props.style?.position === 'fixed' &&
                  referenceRect &&
                  (isRightAligned || isBottomAligned)
                ) {
                  const match = props.style.transform?.match(
                    /^translate3d\((.+), (.+), (.+)\)$/,
                  )
                  if (match) {
                    const [, tx, ty, tz] = match

                    if (isRightAligned) {
                      return {
                        ...props.style,
                        left: 'auto',
                        right: 0,
                        transform: `translate3d(calc(${referenceRect.right}px - 100vw), ${ty}, ${tz})`,
                      }
                    }
                    if (isBottomAligned) {
                      return {
                        ...props.style,
                        top: 'auto',
                        bottom: 0,
                        transform: `translate3d(${tx}, calc(${
                          referenceRect.bottom
                        }px - ${
                          CSS.supports('max-height: 100dvh')
                            ? '100dvh'
                            : '100vh'
                        }), ${tz})`,
                      }
                    }
                  }
                }

                return props.style
              })()}
            >
              <PopoverContentInnerView className="PopoverContent-inner">
                {arrow && (
                  <ReakitPopoverArrow
                    className={cn(
                      'PopoverContent-arrow',
                      'bg-transparent leading-none',
                      '[&_.stroke]:fill-transparent',
                      '[&_.fill]:fill-natural-100',
                    )}
                    {...popover}
                  />
                )}
                <VStack
                  className={cn(
                    'PopoverContent-body',
                    'overflow-auto rounded bg-natural-100 shadow-z16',
                    '*:flex-0',
                  )}
                  style={{
                    ...(referenceRect && {
                      minWidth: fullWidth ? referenceRect.width : undefined,
                      maxWidth: (() => {
                        if (popover.placement.startsWith('left')) {
                          return `${referenceRect.left - 16}px`
                        }
                        if (popover.placement.startsWith('right')) {
                          return `calc(100vw - ${referenceRect.right + 16}px)`
                        }
                        return 'calc(100vw - 32px)'
                      })(),
                      maxHeight: (() => {
                        if (shrinkable && popover.placement.startsWith('top')) {
                          return `${referenceRect.top - 16}px`
                        }
                        if (
                          shrinkable &&
                          popover.placement.startsWith('bottom')
                        ) {
                          return `calc(${
                            CSS.supports('max-height: 100dvh')
                              ? '100dvh'
                              : '100vh'
                          } - ${referenceRect.bottom + 16}px)`
                        }
                        return `calc(${
                          CSS.supports('max-height: 100dvh')
                            ? '100dvh'
                            : '100vh'
                        } - 32px)`
                      })(),
                    }),
                  }}
                >
                  {typeof children === 'function'
                    ? children(popover)
                    : children}
                </VStack>
              </PopoverContentInnerView>
            </Comp>
          )
        }}
      </ReakitPopover>
    )
  },
) as ForwardRefComponent<'div', PopoverContentProps>

// MARK: – PopoverContentInnerView

const PopoverContentInnerView = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'>
>((props, forwardedRef) => {
  const popover = useContext(InternalPopoverContext)
  return popover.animated ? (
    <motion.div
      ref={forwardedRef}
      initial={{scale: 0.9, y: '-1rem', opacity: 0}}
      animate={
        popover.visible
          ? {
              scale: 1,
              y: 0,
              opacity: 1,
              transition: {duration: 0.15},
            }
          : {
              scale: 0.9,
              y: '-1rem',
              opacity: 0,
              transition: {duration: 0.15},
              transitionEnd: {display: 'none'},
            }
      }
      onAnimationComplete={() => setTimeout(() => popover.stopAnimation(), 0)}
      {...(props as HTMLMotionProps<'div'>)}
    />
  ) : (
    <div ref={forwardedRef} {...props} />
  )
})
