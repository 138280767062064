import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'
import {LinkButton} from 'src/components/LinkButton'

import RecurringPaymentField from '../../components/ItemFormDetails/RecurringPaymentField'
import type {RecurringItemFormFormik} from './RecurringItemForm'

export interface RecurringItemFormDetailsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collectionId: number
  formik: RecurringItemFormFormik
  itemImages: any[]
}

const RecurringItemFormDetails: React.FC<RecurringItemFormDetailsProps> = ({
  formik,
  collectionId,
  itemImages,
  className,
  ...restProps
}) => {
  const tabQuery = api.tabs.detail.useQuery({pathParams: {tabId: collectionId}})
  const categoriesQuery = api.tabCategories.list.useQuery({
    pathParams: {tabId: collectionId},
  })

  const subcategories =
    categoriesQuery.data?.find(
      (category) => String(category.id) === String(formik.values.parent_id),
    )?.options.subcategories ?? []

  return (
    <WebUI.VStack className={WebUI.cn('gap-8', className)} {...restProps}>
      <WebUI.VStack className="gap-4">
        <WebUI.VStack className="gap-2">
          <WebUI.FormFieldGroup>
            <WebUI.FormField
              label="Plan Name"
              error={formik.errors.name}
              required
            >
              <WebUI.Input
                className="max-w-[340px]"
                name="name"
                placeholder="Plan Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </WebUI.FormField>
            {categoriesQuery.data && categoriesQuery.data.length > 0 && (
              <WebUI.FormField label="Category" error={formik.errors.parent_id}>
                <WebUI.Select
                  className="[&_>_.Select-select]:min-h-10"
                  name="parent_id"
                  value={formik.values.parent_id ?? ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="">Add to Category</option>
                  {categoriesQuery.data.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </WebUI.Select>
              </WebUI.FormField>
            )}
          </WebUI.FormFieldGroup>
        </WebUI.VStack>

        <WebUI.VStack className="justify-center gap-3 *:flex-[1_0_0px] sm:flex-row sm:items-center sm:justify-start">
          {subcategories.length > 0 && (
            <WebUI.Select
              name="options.subcategoryId"
              value={formik.values.options.subcategoryId ?? ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option value="">Add to Subcategory</option>
              {subcategories.map((subcategory) => (
                <option key={subcategory.uuid} value={subcategory.uuid}>
                  {subcategory.name}
                </option>
              ))}
            </WebUI.Select>
          )}
        </WebUI.VStack>
        {tabQuery.data &&
        tabQuery.data.status !== 'draft' &&
        !tabQuery.data.is_team ? (
          <WebUI.VStack className="gap-3">
            <WebUI.Text className="font-normal text-ds-md">
              Set up recurring payments
            </WebUI.Text>
            <WebUI.Text className="max-w-[440px] font-normal text-ds-sm">
              Upgrade to our Team Plan to automatically get paid on the schedule
              you select. Great for monthly tuition, team fee installments, and
              monthly giving campaigns
            </WebUI.Text>

            <WebUI.HStack className="gap-3">
              <LinkButton variant="default" to="i/plans">
                Upgrade to Team
              </LinkButton>
              <WebUI.Button
                variant="secondary"
                onClick={() =>
                  formik.setFieldValue('options.recurring.enabled', false)
                }
              >
                Not Now
              </WebUI.Button>
            </WebUI.HStack>
          </WebUI.VStack>
        ) : (
          <RecurringPaymentField formik={formik} />
        )}
      </WebUI.VStack>
    </WebUI.VStack>
  )
}

export default RecurringItemFormDetails
