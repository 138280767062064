import {ForwardRefComponent} from '@cheddarup/react-util'
import * as WebUI from '@cheddarup/web-ui'
import React from 'react'

export interface ActionButtonProps extends WebUI.ButtonProps {
  title: string
  description: string
}

export const ActionButton = React.forwardRef(
  ({title, description, className, ...restProps}, forwardedRef) => (
    <WebUI.Button
      ref={forwardedRef}
      className={WebUI.cn(
        '!rounded-large [&_>_.Button-iconBefore]:!mb-0 w-[214px] gap-2 text-wrap bg-natural-100 shadow-z4 aria-orientation-vertical:p-2 sm:aria-orientation-vertical:p-6',
        className,
      )}
      variant="secondary"
      orientation="vertical"
      {...restProps}
    >
      <WebUI.Text className="font-extrabold text-ds-base leading-compact">
        {title}
      </WebUI.Text>
      <WebUI.Text className="whitespace-break-spaces font-light text-ds-sm">
        {description}
      </WebUI.Text>
    </WebUI.Button>
  ),
) as ForwardRefComponent<'button', ActionButtonProps>
