import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {PlanUpgradeButton} from 'src/components/PremiumFeaturesSideSheet'

export interface CollectionSettingsUpgradePlanButtonProps
  extends React.ComponentPropsWithoutRef<'div'> {
  asPaidBadge?: boolean
  plan: Exclude<Api.SubscriptionPlanType, 'free' | 'pause'>
  children: React.ReactNode
}

export const CollectionSettingsUpgradePlanButton = ({
  asPaidBadge,
  plan,
  children,
  className,
  ...restProps
}: CollectionSettingsUpgradePlanButtonProps) => (
  <div
    className={WebUI.cn('flex items-center justify-between gap-4', className)}
    {...restProps}
  >
    <WebUI.Text className="grow font-light text-ds-base leading-compact">
      {children}
    </WebUI.Text>
    <PlanUpgradeButton upgradeTo={plan} asPaidBadge={asPaidBadge} />
  </div>
)
