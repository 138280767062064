import {parseDateTime} from './date-utils'

export function makeIcsContent(
  startTime: Date,
  endTime: Date,
  timeZone: string,
  summary = '',
  description = '',
  location = '',
) {
  const start = formatIcsDateTime(startTime, timeZone)
  const end = formatIcsDateTime(endTime, timeZone)

  const calendarEventContent = `BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
DTSTART;TZID=${timeZone};VALUE=DATE-TIME:${start}
DTEND;TZID=${timeZone};VALUE=DATE-TIME:${end}
SUMMARY:${summary}
DESCRIPTION:${description}
LOCATION:${location}
END:VEVENT
END:VCALENDAR`

  return calendarEventContent
}

function formatIcsDateTime(date: Date, timeZone: string) {
  // biome-ignore lint/style/noNonNullAssertion:
  const zonedDateTime = parseDateTime(date.toISOString(), timeZone)!

  const month = zonedDateTime.month.toString().padStart(2, '0')
  const day = zonedDateTime.day.toString().padStart(2, '0')
  const hour = zonedDateTime.hour.toString().padStart(2, '0')
  const minute = zonedDateTime.minute.toString().padStart(2, '0')
  const second = zonedDateTime.second.toString().padStart(2, '0')

  return `${zonedDateTime.year}${month}${day}T${hour}${minute}${second}Z`
}
