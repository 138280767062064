import * as Yup from 'yup'
import {FormikHandlers, FormikHelpers, FormikState} from 'formik'
import {useParams} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import React, {useMemo, useRef, useState} from 'react'
import {
  api,
  useCreateTabDiscountMutation,
  useDeleteTabDiscountMutation,
  useUpdateTabDiscountMutation,
  useUpdateTabMutation,
} from '@cheddarup/api-client'
import * as Util from '@cheddarup/util'
import {SearchForm} from 'src/components'
import {useFormik, useLiveRef} from '@cheddarup/react-util'
import {
  ButtonAnchor,
  Checkbox,
  CheckboxGroup,
  NextButton,
  Tag,
  Text,
} from '@cheddarup/web-ui/next'

export interface DiscountsProps extends React.ComponentPropsWithoutRef<'div'> {
  tabId: number
}

const Discounts = ({tabId, className, ...restProps}: DiscountsProps) => {
  const discountsQuery = api.tabDiscounts.list.useQuery({
    pathParams: {
      tabId,
    },
  })
  const [discountToEdit, setDiscountToEdit] = useState<Api.TabDiscount | null>(
    null,
  )

  return (
    <div
      className={WebUI.cn(
        'flex max-w-screen-[700px] flex-col gap-4 text-ds-sm',
        className,
      )}
      {...restProps}
    >
      <div className="flex flex-col gap-2">
        {discountsQuery.data?.map((discount) => (
          <DiscountItem
            key={discount.id}
            discount={discount}
            onEdit={(d) => setDiscountToEdit(d)}
          />
        ))}
      </div>

      {discountsQuery.data && discountsQuery.data.length > 1 && (
        <AllowMultipleDiscount />
      )}

      <div className="flex flex-row">
        <DiscountCodeModal
          key={discountToEdit?.id ?? 'new'}
          discount={discountToEdit}
          visible={discountToEdit !== null}
          disclosure={<WebUI.DialogDisclosure>Add Code</WebUI.DialogDisclosure>}
          onDidHide={() => setDiscountToEdit(null)}
        />
      </div>
    </div>
  )
}

// MARK: – DiscountItem

interface DiscountItemProps extends React.ComponentPropsWithoutRef<'div'> {
  discount: Api.TabDiscount
  onEdit: (discount: Api.TabDiscount) => void
}

const DiscountItem = ({
  discount,
  onEdit,
  className,
  ...restProps
}: DiscountItemProps) => {
  const updateDiscountMutation = useUpdateTabDiscountMutation()
  return (
    <div
      className={WebUI.cn(
        'flex flex-row items-center gap-3 rounded border px-6 py-4',
        className,
      )}
      {...restProps}
    >
      <div className="flex grow flex-col gap-2">
        <div className="inline-flex flex-row items-center gap-2">
          <ButtonAnchor onClick={() => onEdit(discount)}>
            {discount.code}
          </ButtonAnchor>
          {discount.metadata.singleUseOnly && (
            <Tag className="px-2 py-0_5 text-ds-xs">Single Use</Tag>
          )}
        </div>

        <Text>
          {discount.options.calculationMethod === 'fixed'
            ? Util.formatAmount(discount.options.fixedAmount)
            : `${discount.options.percentage}%`}{' '}
          off{' '}
          {discount.options.restrictToSpecificItems
            ? 'specific items'
            : discount.options.appliesTo === 'one_item_most_expensive'
              ? 'most expensive item'
              : 'all items'}
          {discount.options.appliesTo === 'total_order_with_minimum' &&
          discount.options.minimumPurchase
            ? ` with purchase of at least ${Util.formatAmount(
                discount.options.minimumPurchase,
              )}`
            : ' with no minimum purchase'}
        </Text>
      </div>
      <div className="w-28 text-grey-500">
        {discount.metadata.singleUseOnly && (
          <SingleUseDiscountRedeemedInfo
            tabId={discount.tab_id}
            redeemedInfo={discount.metadata.redeemedInfo}
          />
        )}
      </div>

      <WebUI.DropdownSelect
        size="compact"
        defaultValue={discount.active ? 'true' : 'false'}
        onValueChange={(newValue) =>
          updateDiscountMutation.mutate({
            pathParams: {
              tabId: discount.tab_id,
              discountId: discount.id,
            },
            body: {
              active: newValue === 'true',
            },
          })
        }
      >
        <WebUI.DropdownSelectOption value="true">
          Active
        </WebUI.DropdownSelectOption>
        <WebUI.DropdownSelectOption value="false">
          Inactive
        </WebUI.DropdownSelectOption>
      </WebUI.DropdownSelect>
      <DeleteDiscountAlert
        discount={discount}
        disclosure={
          <WebUI.DialogDisclosure
            className="text-lg"
            as={NextButton}
            variant="transparent"
          >
            <WebUI.PhosphorIcon icon="x" />
          </WebUI.DialogDisclosure>
        }
      />
    </div>
  )
}

// MARK: – SingleUseDiscountRedeemedInfo

interface SingleUseDiscountRedeemedInfoProps
  extends React.ComponentPropsWithoutRef<'span'> {
  tabId: number
  redeemedInfo: Api.TabDiscountRedeemedInfo | undefined
}

const SingleUseDiscountRedeemedInfo = ({
  tabId,
  redeemedInfo,
  className,
  ...restProps
}: SingleUseDiscountRedeemedInfoProps) => {
  const tabPaymentQuery = api.tabPayments.detail.useQuery(
    {
      pathParams: {
        tabId,
        // biome-ignore lint/style/noNonNullAssertion:
        paymentId: redeemedInfo?.paymentId!,
      },
    },
    {
      enabled: !!redeemedInfo,
    },
  )

  const text = (
    <Text className={WebUI.cn('text-grey-500', className)} {...restProps}>
      {redeemedInfo?.status === 'redeemed' ? 'Redeemed' : 'Unclaimed'}
    </Text>
  )

  if (!tabPaymentQuery.data) {
    return text
  }

  // 8/9/2024

  return redeemedInfo?.status === 'redeemed' ? (
    <WebUI.Tooltip>
      <WebUI.TooltipAnchor
        render={
          <span className="inline-flex items-center gap-0_5">
            {text}
            <WebUI.PhosphorIcon icon="info" />
          </span>
        }
      />
      <WebUI.TooltipContent>
        Redeemed by {tabPaymentQuery.data.tab_member.name}{' '}
        {Util.nativeFormatDate(redeemedInfo.redeemedAt)}
      </WebUI.TooltipContent>
    </WebUI.Tooltip>
  ) : (
    text
  )
}

// MARK: – AllowMultipleDiscount

const AllowMultipleDiscount = (props: WebUI.CheckboxProps) => {
  const urlParams = useParams()
  const collectionId = Number(urlParams.collection)

  const allowMultipleDiscountAlertRef = useRef<WebUI.DialogInstance>(null)
  const {data: collection} = api.tabs.detail.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: urlParams.collection!,
    },
  })
  const updateCollectionMutation = useUpdateTabMutation()

  return (
    <>
      <WebUI.Checkbox
        size="compact"
        checked={!collection?.options?.onlyAllowOneDiscountPerPurchase}
        onChange={(event) => {
          if (event.target.checked) {
            allowMultipleDiscountAlertRef.current?.show()
          } else {
            updateCollectionMutation.mutate({
              pathParams: {
                tabId: collectionId,
              },
              body: {
                options: {
                  onlyAllowOneDiscountPerPurchase: true,
                },
              },
            })
          }
        }}
        {...props}
      >
        Allow more than one discount code to be used at checkout.
      </WebUI.Checkbox>

      <WebUI.Alert
        ref={allowMultipleDiscountAlertRef}
        aria-label="Allow multiple discount codes"
        initialVisible={false}
      >
        <WebUI.AlertHeader>Are you sure?</WebUI.AlertHeader>
        <WebUI.AlertContentView
          text={
            <>
              An item could have a deeper discount than intented if multiple
              codes are allowed. Want to test ?
              <WebUI.Anchor
                className="[&_>_.Anchor-content]:font-light"
                href="#"
              >
                Preview your collection.
              </WebUI.Anchor>
            </>
          }
          actions={
            <>
              <WebUI.AlertActionButton
                execute={async () => {
                  await updateCollectionMutation.mutateAsync({
                    pathParams: {
                      tabId: collectionId,
                    },
                    body: {
                      options: {
                        onlyAllowOneDiscountPerPurchase: false,
                      },
                    },
                  })
                }}
              >
                Yes, I'm sure
              </WebUI.AlertActionButton>
              <WebUI.AlertCancelButton>Cancel</WebUI.AlertCancelButton>
            </>
          }
        />
      </WebUI.Alert>
    </>
  )
}

// MARK: – DeleteDiscountAlert

interface DeleteDiscountAlertProps extends WebUI.AlertProps {
  discount: Api.TabDiscount
}

const DeleteDiscountAlert = React.forwardRef<
  WebUI.DialogInstance,
  DeleteDiscountAlertProps
>(({discount, ...restProps}, forwardedRef) => {
  const deleteTabDiscountMutation = useDeleteTabDiscountMutation()
  return (
    <WebUI.Alert
      ref={forwardedRef}
      aria-label="Delete discount code"
      {...restProps}
    >
      <WebUI.AlertHeader>Delete discount code</WebUI.AlertHeader>
      <WebUI.AlertContentView
        text="Are you sure you want to delete this discount code? It might currently be in use."
        actions={
          <>
            <WebUI.AlertActionButton
              execute={() =>
                deleteTabDiscountMutation.mutateAsync({
                  pathParams: {
                    tabId: discount.tab_id,
                    discountId: discount.id,
                  },
                })
              }
            >
              Delete
            </WebUI.AlertActionButton>
            <WebUI.AlertCancelButton />
          </>
        }
      />
    </WebUI.Alert>
  )
})

// MARK: - DiscountCodeModal

interface DiscountCodeModalProps extends WebUI.ModalProps {
  discount: Api.TabDiscount | null
}

interface DiscountValues {
  fixedAmount: string
  percentage: string
  appliesTo: Api.TabDiscount['options']['appliesTo']
  calculationMethod: Api.TabDiscount['options']['calculationMethod']
  code: string
  minimumPurchase: string | number
  singleUseOnly: boolean
  restrictToSpecificItems: boolean
  eligibleItemIds: number[]
}

const DiscountCodeModal = ({
  discount,
  ...restProps
}: DiscountCodeModalProps) => {
  const discountModalRef = useRef<WebUI.DialogInstance>(null)
  const urlParams = useParams()
  const collectionId = Number(urlParams.collection)

  const createDiscountMutation = useCreateTabDiscountMutation()
  const updateDiscountMutation = useUpdateTabDiscountMutation()
  const growlActions = WebUI.useGrowlActions()

  const formik = useFormik<DiscountValues>({
    validationSchema: Yup.object().shape({
      calculationMethod: Yup.string()
        .required('Required')
        .matches(/^(fixed|percentage)*/, 'not available value'),
      fixedAmount: Yup.number().when('calculationMethod', {
        is: 'fixed',
        // biome-ignore lint/suspicious/noThenProperty:
        then: (schema) =>
          schema
            .transform((v) => (Number.isNaN(v) ? undefined : v))
            .required('Required'),
      }),
      percentage: Yup.number().when('calculationMethod', {
        is: 'percentage',
        // biome-ignore lint/suspicious/noThenProperty:
        then: (schema) =>
          schema
            .transform((v) => (Number.isNaN(v) ? undefined : v))
            .required('Required'),
      }),
      code: Yup.string()
        .required('Required')
        .matches(
          /^((?![!"#$%&'()*+,./:;<=>?@[\\\]^_{|}-]).)*$/,
          'cannot include (%, &, ", #, etc.)',
        ),
      appliesTo: Yup.string().required('Required'),
      minimumPurchase: Yup.number().when('appliesTo', {
        is: 'total_order_with_minimum',
        // biome-ignore lint/suspicious/noThenProperty:
        then: (schema) =>
          schema
            .transform((v) => (Number.isNaN(v) ? undefined : v))
            .required('Required'),
      }),
      restrictToSpecificItems: Yup.boolean(),
      eligibleItemIds: Yup.array().of(Yup.number()),
    }),
    initialValues: {
      fixedAmount: discount?.options.fixedAmount
        ? String(discount?.options.fixedAmount)
        : '',
      percentage: discount?.options.percentage
        ? String(discount?.options.percentage)
        : '',
      appliesTo: discount?.options.appliesTo ?? 'total_order',
      calculationMethod: discount?.options.calculationMethod ?? 'fixed',
      code: discount?.code ?? '',
      minimumPurchase: discount?.options.minimumPurchase ?? '',
      singleUseOnly: discount?.metadata.singleUseOnly ?? false,
      restrictToSpecificItems:
        discount?.options.restrictToSpecificItems ?? false,
      eligibleItemIds: discount?.options.eligibleItemIds ?? [],
    },
    onSubmit: async (values, formikHelpers) => {
      const body = {
        code: values.code,
        options: {
          fixedAmount: values.fixedAmount ? Number(values.fixedAmount) : 0,
          percentage: values.percentage ? Number(values.percentage) : 0,
          appliesTo: values.appliesTo,
          calculationMethod: values.calculationMethod,
          minimumPurchase: values.minimumPurchase
            ? Number(values.minimumPurchase)
            : 0,
          restrictToSpecificItems: values.restrictToSpecificItems,
          eligibleItemIds: values.restrictToSpecificItems
            ? values.eligibleItemIds
            : [],
        },
        metadata: {
          singleUseOnly: values.singleUseOnly,
        },
      }

      if (discount) {
        try {
          await updateDiscountMutation.mutateAsync({
            pathParams: {
              tabId: collectionId,
              discountId: discount.id,
            },
            body,
          })
          growlActions.show('success', {
            body: `Discount "${values.code}" was updated successfully`,
            title: 'Success!',
          })
          discountModalRef.current?.hide()
        } catch (err: any) {
          growlActions.show('error', {body: err, title: 'Sorry!'})
        }
      } else {
        try {
          await createDiscountMutation.mutateAsync({
            pathParams: {
              tabId: collectionId,
            },
            body,
          })
          growlActions.show('success', {
            body: `Discount "${values.code}" was added successfully`,
            title: 'Success!',
          })
          discountModalRef.current?.hide()
          formikHelpers.resetForm()
        } catch (err: any) {
          const errCode = err.response?.data?.errors?.code ?? []
          let errBody = ''
          switch (errCode[0]) {
            case 'has already been taken':
              errBody = `The code "${err.response.data?.code}" is already taken. Please use another one.`
              break
            default:
              errBody = 'Error happened. Please try again'
          }
          growlActions.show('error', {body: errBody, title: 'Sorry!'})
        }
      }
    },
  })

  return (
    <WebUI.Modal
      aria-label="Discount Code Form"
      ref={discountModalRef}
      className="[&_>_.ModalContentView]:h-full [&_>_.ModalContentView]:max-w-screen-lg [&_>_.ModalContentView]:overflow-hidden"
      initialVisible={false}
      {...restProps}
    >
      <WebUI.ModalCloseButton />
      <WebUI.ModalHeader variant="compact">Discount Code</WebUI.ModalHeader>
      <WebUI.Form
        className="h-full min-h-0 justify-between [&_>_.Form-inner]:h-full [&_>_.Form-inner]:gap-0"
        onSubmit={formik.handleSubmit}
      >
        <div className="flex min-h-0 grow flex-col justify-between gap-8 overflow-y-auto px-8 py-4">
          <div className="flex max-w-[540px] flex-col gap-4">
            <WebUI.Heading as="h3">Details</WebUI.Heading>

            <WebUI.FormField label="Code Name" error={formik.errors.code}>
              <WebUI.Input
                name="code"
                placeholder="(e.g. VIP123)"
                value={formik.values.code}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </WebUI.FormField>
            <WebUI.FormFieldGroup>
              <WebUI.FormField
                label="Discount Type"
                error={formik.errors.calculationMethod}
              >
                <WebUI.DropdownSelect
                  className="h-[38px]"
                  name="calculationMethod"
                  placeholder="Discount Method"
                  value={formik.values.calculationMethod}
                  onValueChange={(value) =>
                    formik.setFieldValue('calculationMethod', value)
                  }
                  onBlur={formik.handleBlur}
                >
                  <WebUI.DropdownSelectOption value="fixed">
                    Fixed amount off
                  </WebUI.DropdownSelectOption>
                  <WebUI.DropdownSelectOption value="percentage">
                    Percentage off
                  </WebUI.DropdownSelectOption>
                </WebUI.DropdownSelect>
              </WebUI.FormField>
              {formik.values.calculationMethod === 'fixed' && (
                <WebUI.FormField
                  label="Amount"
                  error={formik.errors.fixedAmount}
                >
                  <WebUI.AmountInput
                    name="fixedAmount"
                    value={formik.values.fixedAmount}
                    onValueChange={(newFixedAmount) =>
                      formik.setFieldValue(
                        'fixedAmount',
                        String(newFixedAmount),
                      )
                    }
                    onBlur={formik.handleBlur}
                  />
                </WebUI.FormField>
              )}
              {formik.values.calculationMethod === 'percentage' && (
                <WebUI.FormField
                  label="Percentage"
                  error={formik.errors.percentage}
                >
                  <WebUI.PercentInput
                    name="percentage"
                    value={formik.values.percentage}
                    onValueChange={(newPercent) =>
                      formik.setFieldValue('percentage', newPercent)
                    }
                    onBlur={formik.handleBlur}
                  />
                </WebUI.FormField>
              )}
            </WebUI.FormFieldGroup>
            <WebUI.FormField label="Applicable Items">
              <WebUI.DropdownSelect<'all' | 'specific'>
                value={
                  formik.values.restrictToSpecificItems ? 'specific' : 'all'
                }
                onValueChange={(value) => {
                  formik.setFieldValue(
                    'restrictToSpecificItems',
                    value === 'specific',
                  )
                  if (
                    value === 'specific' &&
                    formik.values.appliesTo === 'one_item_most_expensive'
                  ) {
                    formik.setFieldValue('appliesTo', null)
                  }
                }}
                onBlur={formik.handleBlur}
              >
                <WebUI.DropdownSelectOption value="all">
                  Apply to all items
                </WebUI.DropdownSelectOption>
                <WebUI.DropdownSelectOption value="specific">
                  Apply to specific items
                </WebUI.DropdownSelectOption>
              </WebUI.DropdownSelect>
            </WebUI.FormField>

            <div className="flex flex-col gap-2">
              <div className="inline-flex flex-row items-center gap-1">
                <Checkbox
                  className="text-ds-sm"
                  checked={formik.values.singleUseOnly}
                  onChange={(event) =>
                    formik.setFieldValue('singleUseOnly', event.target.checked)
                  }
                >
                  Make discount code inactive after one use
                </Checkbox>
                <WebUI.Tooltip>
                  <WebUI.TooltipAnchor
                    className="text-grey-300 transition-colors hover:text-grey-500"
                    render={<WebUI.PhosphorIcon icon="question" />}
                  />
                  <WebUI.TooltipContent>
                    Once a payer uses this code, it will become inactive. This
                    code is strictly for one-time use only.
                  </WebUI.TooltipContent>
                </WebUI.Tooltip>
              </div>
              <WebUI.FormField error={formik.errors.appliesTo}>
                <CheckboxGroup
                  className="text-ds-sm"
                  name="appliesTo"
                  aria-label="Discount Applies To"
                >
                  {!formik.values.restrictToSpecificItems && (
                    <Checkbox
                      checked={
                        formik.values.appliesTo === 'one_item_most_expensive'
                      }
                      value="one_item_most_expensive"
                      onChange={(e) =>
                        formik.setFieldValue(
                          'appliesTo',
                          formik.values.appliesTo === e.target.value
                            ? 'total_order'
                            : e.target.value,
                        )
                      }
                    >
                      Apply discount to one item (most expensive)
                    </Checkbox>
                  )}
                  <Checkbox
                    checked={
                      formik.values.appliesTo === 'total_order_with_minimum'
                    }
                    value="total_order_with_minimum"
                    onChange={(e) =>
                      formik.setFieldValue(
                        'appliesTo',
                        formik.values.appliesTo === e.target.value
                          ? 'total_order'
                          : e.target.value,
                      )
                    }
                  >
                    Require minimum purchase to receive discount
                  </Checkbox>
                </CheckboxGroup>
              </WebUI.FormField>
            </div>
            {formik.values.appliesTo === 'total_order_with_minimum' && (
              <WebUI.FormField
                label="Minimum Purchase"
                error={formik.errors.minimumPurchase}
              >
                <WebUI.AmountInput
                  className="max-w-[160px]"
                  name="minimumPurchase"
                  autoFocus
                  value={formik.values.minimumPurchase}
                  onValueChange={(newMinimumPurchase) =>
                    formik.setFieldValue('minimumPurchase', newMinimumPurchase)
                  }
                  onBlur={formik.handleBlur}
                />
              </WebUI.FormField>
            )}
          </div>

          {formik.values.restrictToSpecificItems && (
            <MinimumPurchaseDiscountTable
              collectionId={collectionId}
              formik={formik}
            />
          )}
        </div>

        <div className="flex flex-row items-center justify-end border-t bg-natural-100 px-4 py-5">
          <WebUI.Button
            type="submit"
            variant="primary"
            size="large"
            loading={formik.isSubmitting}
          >
            Save
          </WebUI.Button>
        </div>
      </WebUI.Form>
    </WebUI.Modal>
  )
}

// MARK: - MinimumPurchaseDiscountTable

interface MinimumPurchaseDiscountTableProps
  extends Omit<WebUI.TableViewProps<Partial<Api.TabItem>>, 'data' | 'columns'> {
  collectionId: number
  formik: FormikState<DiscountValues> &
    FormikHelpers<DiscountValues> &
    FormikHandlers
}

const MinimumPurchaseDiscountTable: React.FC<
  MinimumPurchaseDiscountTableProps
> = ({collectionId, formik, ...restProps}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const {data: tabItems, isPending} = api.tabItems.list.useQuery(
    {
      pathParams: {
        tabId: collectionId,
      },
    },
    {
      select: (items) =>
        items
          .filter((item) => item.amount_type !== 'open')
          .filter(
            Util.fuzzyFilterIterator(searchTerm, {
              iterator: (item) => item.name,
            }),
          ),
    },
  )

  const columnHelper = useMemo(
    () => WebUI.createColumnHelper<Partial<Api.TabItem>>(),
    [],
  )

  const columns = useMemo(
    () => [
      columnHelper.accessor((item) => item.name, {
        id: 'name',
      }),
    ],
    [columnHelper],
  )

  const selectionState = useMemo(
    () =>
      formik.values.eligibleItemIds.reduce(
        (o, key) => Object.assign(o, {[key]: true}),
        {},
      ),
    [formik.values.eligibleItemIds],
  )
  const selectionStateRef = useLiveRef(selectionState)

  return (
    <div className="flex flex-col">
      <WebUI.TableView<Partial<Api.TabItem>>
        className={
          'min-h-[300px] overflow-y-auto pb-3 [&_.TableViewCell]:min-h-[30px] [&_.TableViewCell]:items-center [&_.TableViewCell]:px-1 [&_.TableViewRow]:px-0'
        }
        enableRowSelection
        selectAllVisible={false}
        loading={isPending}
        columns={columns}
        data={tabItems ?? []}
        getRowId={(row) => String(row.id)}
        state={{rowSelection: selectionState}}
        onRowSelectionChange={(updater) => {
          const newRowSelection =
            typeof updater === 'function'
              ? updater(selectionStateRef.current)
              : updater
          const newSelectedItemIdsIds = Object.keys(
            Util.pickBy(newRowSelection, (v) => v === true),
          ).map(Number)
          formik.setFieldValue('eligibleItemIds', newSelectedItemIdsIds)
        }}
        {...restProps}
      >
        {(table) => (
          <div className="-order-1 flex flex-col gap-4">
            <WebUI.Heading as="h3">Select Items</WebUI.Heading>
            <div className="mb-4 flex flex-row items-center justify-between">
              <WebUI.Checkbox
                state={
                  table.getIsSomeRowsSelected()
                    ? 'indeterminate'
                    : table.getIsAllRowsSelected()
                }
                onChange={table.getToggleAllRowsSelectedHandler()}
              >
                Select All
              </WebUI.Checkbox>
              <SearchForm
                size="compact"
                iconClassName="text-gray400"
                className="min-w-[250px] text-ds-xs placeholder:text-ds-xs"
                onTermChange={(newSearchTerm) => setSearchTerm(newSearchTerm)}
                initialValues={{term: ''}}
                placeholder="Search by item name"
              />
            </div>
          </div>
        )}
      </WebUI.TableView>
    </div>
  )
}

export default Discounts
