import * as Util from '@cheddarup/util'
import config from 'src/config'

interface Size {
  width: number
  height: number
}

interface GetSharpImageUrlInput {
  path: string
  cropDetails?: Api.CropDetails | null
  size?: Partial<Size> | null
  edits?: Record<string, any>
  outputFormat?: string
}

// https://sharp.pixelplumbing.com/api-operation
export const getSharpImageUrl = ({
  path,
  cropDetails,
  size,
  edits: additionalEdits,
  outputFormat,
}: GetSharpImageUrlInput) => {
  if (!path) {
    return ''
  }
  const edits: Record<string, any> = {
    ...additionalEdits,
    extract: {
      ...additionalEdits?.extract,
    },
    resize: {
      ...additionalEdits?.resize,
    },
    flatten: {
      background: {
        r: 255,
        g: 255,
        b: 255,
      },
      ...additionalEdits?.flatten,
    },
  }
  const cropHeight =
    typeof cropDetails?.height === 'number'
      ? Math.floor(cropDetails.height)
      : null
  const cropWidth = Math.floor(
    typeof cropDetails?.height === 'number'
      ? cropDetails.width
      : (cropDetails?.width ?? document.body.offsetWidth),
  )

  const sizeHeight =
    typeof size?.height === 'number'
      ? Math.floor(size.height * window.devicePixelRatio)
      : null
  const sizeWidth =
    typeof size?.width === 'number'
      ? Math.floor(size.width * window.devicePixelRatio)
      : null

  if (typeof cropDetails?.x === 'number' && cropWidth > 0) {
    edits.extract.left = Math.max(Math.floor(cropDetails.x), 0)
    edits.extract.width = Math.floor(cropWidth)
  }
  if (
    typeof cropDetails?.y === 'number' &&
    cropHeight != null &&
    cropDetails.height > 0
  ) {
    edits.extract.top = Math.max(Math.floor(cropDetails.y), 0)
    edits.extract.height = cropHeight
  }
  if (sizeHeight != null && sizeHeight > 0) {
    edits.resize.height = sizeHeight
  }
  if (sizeWidth != null && sizeWidth > 0) {
    edits.resize.width = sizeWidth
  }
  Object.keys(edits).forEach(
    (key) =>
      typeof edits[key] !== 'number' &&
      Object.keys(edits[key]).length === 0 &&
      delete edits[key],
  )
  const payload = {
    edits,
    outputFormat: outputFormat ?? 'webp',
    bucket: import.meta.env.REACT_APP_S3_UPLOAD_BUCKET ?? config.s3BucketName,
    key: path,
  }
  // See https://gist.github.com/davidfurlong/463a83a33b70a3b6618e97ec9679e490
  // order matters – `edits.extract` has to be before `edits.resize`
  const payloadJsonStr = JSON.stringify(payload, (_key, value) =>
    value instanceof Object && !Array.isArray(value)
      ? Object.keys(value)
          .sort()
          .reduce(
            (sorted, key) => {
              sorted[key] = value[key]
              return sorted
            },
            {} as Record<string, any>,
          )
      : value,
  )
  const payloadJsonB64 = btoa(payloadJsonStr)
  const fullPath = `${config.sharpHost}${payloadJsonB64}`

  return fullPath
}

const ImagesUtils = {
  sortImages: (images: Api.S3Image[]) =>
    Util.sort(images).asc((i) => i.metadata.thumbnail?.order),
  getImageUrl: (image: Api.S3Image, size?: Size) =>
    getSharpImageUrl({
      path: image.upload_path,
      size,
    }),
  getCroppedImageUrl: (
    image: Util.SetOptional<
      Pick<
        Api.S3Image,
        'metadata' | 'edited_image_url' | 'upload_path' | 'edit_path'
      >,
      'edit_path' | 'edited_image_url'
    >,
    size?: Partial<Size>,
    edits?: Record<string, any>,
    options?: Omit<GetSharpImageUrlInput, 'path'>,
  ) =>
    getSharpImageUrl({
      path:
        image.metadata?.pintura && image.edited_image_url && image.edit_path
          ? image.edit_path
          : image.upload_path,
      cropDetails: image.metadata?.pintura
        ? {}
        : (image.metadata?.thumbnail?.cropDetails as any),
      size,
      edits,
      ...options,
    }),
  getItemMainThumbnailUrl: (
    images: Api.S3Image[],
    size?: Partial<Size>,
    listingImageId?: number | null,
    options?: Omit<GetSharpImageUrlInput, 'path'>,
  ) => {
    const listingImage = images.find(({id}) => id === listingImageId)
    const [mainImage] = ImagesUtils.sortImages(images)
    const image = listingImage || mainImage

    return image
      ? ImagesUtils.getCroppedImageUrl(image, size, undefined, options)
      : null
  },
  getMainImage: (images: Api.S3Image[], listingImageId?: number | null) => {
    const listingImage = images.find(({id}) => id === listingImageId)
    const [mainImage] = ImagesUtils.sortImages(images)

    return listingImage || mainImage
  },
  getThumbnailCrop: (image: Api.S3Image) => {
    const cropDetails = image.metadata.thumbnail?.cropDetails

    return !cropDetails || Object.keys(cropDetails).length === 0
      ? null
      : (cropDetails as Api.CropDetails)
  },
}

export default ImagesUtils
