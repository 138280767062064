import * as WebUI from '@cheddarup/web-ui'
import {LinkButton} from 'src/components/LinkButton'
import {NumberParam, useQueryParam, withDefault} from 'use-query-params'

const TemplatePostConversionModal = () => {
  const [tabId, setTabId] = useQueryParam(
    'convertedTabId',
    withDefault(NumberParam, null),
  )

  return (
    <WebUI.Modal
      aria-label="Template Post Conversion Modal"
      className="[&_>_.ModalContentView]:h-auto sm:[&_>_.ModalContentView]:max-w-[500px]"
      initialVisible={false}
      visible={!!tabId}
      onDidHide={() => setTabId(null)}
    >
      {(dialog) => (
        <>
          <WebUI.ModalCloseButton />
          <WebUI.ModalHeader
            as="h3"
            className="px-6 [&_>_.Text]:font-bold [&_>_.Text]:text-ds-base"
          >
            Let’s go!
          </WebUI.ModalHeader>
          <WebUI.VStack className="gap-4 p-6 sm:gap-12">
            <WebUI.Text className="font-light">
              Your template has been saved to your account and is ready for you
              to customize.
            </WebUI.Text>
            <WebUI.HStack className="gap-4">
              <LinkButton to={`/collection/${tabId}/details`} variant="primary">
                Customize Template
              </LinkButton>
              <WebUI.Button variant="secondary" onClick={dialog.hide}>
                Maybe Later
              </WebUI.Button>
            </WebUI.HStack>
          </WebUI.VStack>
        </>
      )}
    </WebUI.Modal>
  )
}

export default TemplatePostConversionModal
