import {useLocation, useNavigate, useParams} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'

import {RefundsManageContainer} from './containers'

const RefundsManagePage = () => {
  const urlParams = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <WebUI.Modal
      className="[&_>_.ModalContentView]:max-w-screen-lg"
      onDidHide={() => navigate('..')}
    >
      <RefundsManageContainer
        collectionId={Number(urlParams.collection)}
        paymentId={Number(urlParams.payment)}
        selectedPaymentItemId={location.state?.selectedPaymentItemId ?? null}
        onDismiss={() => navigate('..')}
      />
    </WebUI.Modal>
  )
}

export default RefundsManagePage
