import React, {useContext, useMemo} from 'react'

import {Image, ImageProps} from './Image'
import {PhosphorIcon} from '../../icons'
import {cn} from '../../utils'
import {Text} from './Text'
import {Group, GroupProps} from './Group'
import flattenChildren from 'react-keyed-flatten-children'

export type AvatarSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export interface AvatarProps extends ImageProps {
  size?: number | AvatarSize
  name?: string
  fallbackStrategy?: 'initials' | 'raw'
}

export const Avatar = React.forwardRef<HTMLImageElement, AvatarProps>(
  (
    {
      size: sizeProp,
      name = '',
      fallbackStrategy = 'initials',
      className,
      style,
      ...restProps
    },
    forwardedRef,
  ) => {
    const avatarGroupContextValue = useContext(InternalAvatarGroupContext)

    const fallbackText =
      fallbackStrategy === 'initials' ? getInitials(name) : name

    const _size =
      (sizeProp == null ? avatarGroupContextValue?.size : sizeProp) ?? 'md'
    const size =
      typeof _size === 'number'
        ? _size
        : {
            xs: 32,
            sm: 40,
            md: 44,
            lg: 56,
            xl: 64,
          }[_size]

    const avatarCircleClassName = cn(
      'rounded-full',
      'group-[&]/avatar-group:ring group-[&]/avatar-group:ring-trueWhite group-[&]/avatar-group:ring-offset-1 group-[&]/avatar-group:ring-offset-trueWhite',
      'group-[&]/avatar-group:hover:-translate-x-2 group-[&]/avatar-group:translate-x-0 group-[&]/avatar-group:transition-transform group-[&]/avatar-group:delay-75',
      className,
    )

    return (
      <Image
        ref={forwardedRef}
        className={avatarCircleClassName}
        style={{
          width: size,
          height: size,
          ...style,
        }}
        alt="Avatar"
        width={size}
        height={size}
        errorFallback={
          fallbackText.length > 0 ? (
            <div
              ref={forwardedRef}
              className={cn(
                avatarCircleClassName,
                'flex flex-row items-center justify-center bg-teal-70',
              )}
              style={{
                width: size,
                height: size,
              }}
              {...restProps}
            >
              <Text
                title={fallbackText === name ? undefined : name}
                className="text-center font-semibold text-trueWhite leading-none"
                style={{fontSize: 0.4 * size}}
              >
                {fallbackText}
              </Text>
            </div>
          ) : (
            <PhosphorIcon
              className={cn(avatarCircleClassName, 'text-teal-70')}
              icon="user-circle"
              width={size}
              height={size}
            />
          )
        }
        {...restProps}
      />
    )
  },
)
Avatar.displayName = 'Avatar'

// MARK: – InternalAvatarGroupContext

interface InternalAvatarGroupContextValue
  extends Pick<Required<AvatarProps>, 'size'> {
  hiddenChildrenCount: number
}

const InternalAvatarGroupContext = React.createContext<
  InternalAvatarGroupContextValue | undefined
>(undefined)

// MARK: – AvatarGroup

export interface AvatarGroupProps
  extends GroupProps,
    Pick<AvatarProps, 'size'> {
  maxVisibleCount?: number
  hiddenAvatarsCountHidden?: boolean
}

export const AvatarGroup = React.forwardRef<HTMLDivElement, AvatarGroupProps>(
  (
    {
      maxVisibleCount = 3,
      hiddenAvatarsCountHidden = false,
      size = 'md',
      className,
      children,
      ...restProps
    },
    forwardedRef,
  ) => {
    const validChildren = flattenChildren(children)
    const visibleChildren = validChildren.slice(0, maxVisibleCount)
    const hiddenChildrenCount = validChildren.slice(maxVisibleCount).length

    const contextValue: InternalAvatarGroupContextValue = useMemo(
      () => ({
        hiddenChildrenCount,
        size,
      }),
      [size, hiddenChildrenCount],
    )

    return (
      <InternalAvatarGroupContext.Provider value={contextValue}>
        <Group
          ref={forwardedRef}
          className={cn(
            'group/avatar-group -space-x-2 flex w-max flex-row items-center',
            className,
          )}
          {...restProps}
        >
          {visibleChildren}

          {!hiddenAvatarsCountHidden && hiddenChildrenCount > 0 && (
            <Avatar
              size={size}
              fallbackStrategy="raw"
              name={`+${hiddenChildrenCount}`}
            />
          )}
        </Group>
      </InternalAvatarGroupContext.Provider>
    )
  },
)

// MARK: – Helpers

export function getInitials(name = '') {
  return name
    .trim()
    .replace(SPECIAL_CHAR_RE, '')
    .replace(WHITESPACE_RE, ' ')
    .split(' ')
    .slice(0, 2)
    .map((v) => v[0])
    .join('')
    .toUpperCase()
}

const SPECIAL_CHAR_RE = /[^\s\w]/g
const WHITESPACE_RE = /\s+/g
