import React, {useMemo} from 'react'
import {StringParam, useQueryParam} from 'use-query-params'
import {Navigate} from 'react-router-dom'
import TicketLogo from 'src/images/ticket-logo.svg'
import * as WebUI from '@cheddarup/web-ui'
import {InferResponse, api, endpoints} from '@cheddarup/api-client'
import {
  formatTicketStatus,
  getAttendeeEmail,
  getAttendeeName,
} from '@cheddarup/core'
import {Link} from 'src/components/Link'
import * as Util from '@cheddarup/util'

import {
  FeaturesCTA,
  HaveQuestionsCTA,
  TrustCTA,
  UpsellCta,
} from './GuestPaymentsPage'

export const GuestTicketsPage: React.FC = () => {
  const randomUpsell = useMemo(
    () =>
      Util.sample(
        [
          <UpsellCta key="upsell" />,
          <FeaturesCTA key="features" />,
          <TrustCTA key="trust" />,
        ],
        1,
      )[0],
    [],
  )

  return (
    <WebUI.HStack className="min-h-0 grow">
      <TicketsView className="max-w-full shrink-0 grow basis-auto overflow-y-auto" />
      <WebUI.VStack className="hidden flex-0 gap-4 overflow-y-auto p-6 *:flex-0 xl:flex">
        <HaveQuestionsCTA />
        {randomUpsell}
      </WebUI.VStack>
    </WebUI.HStack>
  )
}

// MARK: – TicketsView

const TicketsView: React.FC<React.ComponentPropsWithoutRef<'div'>> = ({
  className,
  ...restProps
}) => {
  const [token] = useQueryParam('token', StringParam)
  const media = WebUI.useMedia()
  const ticketsQuery = api.tickets.listAlt.useQuery({
    queryParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      token: token!,
    },
  })

  type Ticket = InferResponse<typeof endpoints.tickets.listAlt>[number]

  const columnHelper = useMemo(() => WebUI.createColumnHelper<Ticket>(), [])

  const columns = useMemo(
    () => [
      columnHelper.accessor((ticket) => ticket.tab_object.name, {
        id: 'ticket_name',
        size: 90,
        header: 'Ticket Name',
      }),
      columnHelper.accessor((ticket) => `#${ticket.ticket_number}`, {
        id: 'ticket_number',
        size: 90,
        header: 'Ticket Number',
      }),
      columnHelper.accessor(
        (ticket) => getAttendeeName(ticket.item_field_views),
        {
          id: 'attendee',
          size: 160,
          header: 'Attendee',
        },
      ),
      columnHelper.accessor((ticket) => ticket.payment.tab_member.name, {
        id: 'purchaser',
        header: 'Purchaser',
        cell: ({cell, row: {original: ticket}}) => (
          <WebUI.Anchor
            href={`mailto:${
              ticket.payment.tab_member.email
            }?subject=${encodeURIComponent(cell.getValue())}`}
          >
            {cell.getValue()}
          </WebUI.Anchor>
        ),
      }),
      columnHelper.accessor(
        (ticket) => formatTicketStatus(ticket.detail?.ticketStatus ?? 'unused'),
        {
          id: 'status',
          size: 90,
          header: 'Status',
        },
      ),
      columnHelper.display({
        id: 'download_tickets',
        size: 100,
        header: 'Download Tickets',
        cell: ({row: {original: ticket}}) => (
          <Link
            aria-disabled={ticket.detail.ticketStatus !== 'unused'}
            className="aria-disabled:pointer-events-none aria-disabled:text-gray600"
            to={`/pdf/tickets/${ticket.id}?token=${token}`}
            target="_blank"
          >
            <WebUI.PhosphorIcon icon="ticket" width={20} />
          </Link>
        ),
      }),
    ],
    [columnHelper, token],
  )

  const initialSortingState = useMemo(
    () => [{id: 'created_at', desc: true}],
    [],
  )

  const tableColumnsToRowSlots = useMemo(
    () =>
      WebUI.makeTableColumnsToRowSlotsConverter(
        columns as any[],
        [
          {
            slotId: 'ticket_overview',
            colIds: ['ticket_name', 'ticket_number'],
          },
          {
            slotId: 'status',
            colIds: ['status'],
          },
        ],
        {
          action: {
            colId: 'download_tickets',
          },
        },
      ),
    [columns],
  )

  const GuestTicketListDataRow: WebUI.DataRowComponentType<Ticket> = useMemo(
    () =>
      React.forwardRef(({row, setSelected, ...rowRestProps}, forwardedRef) => (
        <WebUI.SlottedRow
          ref={forwardedRef}
          slots={row?.original ? tableColumnsToRowSlots(row.original) : []}
          {...rowRestProps}
        />
      )),
    [tableColumnsToRowSlots],
  )

  if (!token || (ticketsQuery.isError && !ticketsQuery.data)) {
    return <Navigate to="/login" />
  }

  const firstTicket = ticketsQuery.data?.[0]

  return (
    <WebUI.VStack
      className={WebUI.cn('grow rounded-none border-y-0', className)}
      as={WebUI.Panel}
      {...restProps}
    >
      <WebUI.HStack className="-order-1 min-h-[84px] flex-0 gap-8 p-6">
        {media.sm && (
          <img className="h-[4em]" alt="Item Logo" src={TicketLogo} />
        )}
        <WebUI.VStack className="gap-1">
          <WebUI.Heading className="leading-compact" as="h2">
            Download your tickets for {firstTicket?.payment.tab.name}
          </WebUI.Heading>
          {firstTicket && (
            <WebUI.Text className="text-ds-sm">
              Attendee email: {getAttendeeEmail(firstTicket.item_field_views)}
            </WebUI.Text>
          )}
        </WebUI.VStack>
      </WebUI.HStack>

      {media.sm ? (
        <WebUI.TableView<Ticket>
          className={
            '[&_.TableView-headerGroup]:border-t [&_.TableView-headerGroup]:px-6 [&_.TableViewRow]:px-6 [@media(hover:hover){&_.TableViewRow}]:hover:[&_.PaymentAction]:visible [@media(hover:hover){&_.TableViewRow}]:hover:[&_.PaymentAction]:opacity-100'
          }
          loading={ticketsQuery.isPending}
          sortable
          sortByTogglesVisible
          disableSortRemove
          initialState={{sorting: initialSortingState}}
          columns={columns}
          data={ticketsQuery.data ?? []}
        />
      ) : (
        <WebUI.DataList
          className="border-t [&_.List-item]:border-b"
          data={ticketsQuery.data ?? []}
          initialState={{focusedItemId: null, selectedItemIdsMap: {}}}
          DataRowComponent={GuestTicketListDataRow}
        />
      )}
    </WebUI.VStack>
  )
}
