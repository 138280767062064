import {Link, useLocation, useNavigate} from 'react-router-dom'
import {useEffect, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'
import {useCheckSubscriptionMutation} from '@cheddarup/api-client'

const PlansBasicErrorPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [collectionItems, setCollectionItems] = useState<Array<{
    id: number
    name: string
    url: string
  }> | null>(null)
  const checkSubscriptionMutation = useCheckSubscriptionMutation()

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    checkSubscriptionMutation
      .mutateAsync({body: {plan: 'free'}})
      .then((res) => {
        // biome-ignore lint/style/noNonNullAssertion:
        const results = res.results[0]!
        const proCollections =
          results.error === 'tabs_have_balances' ? results.tabs : []

        const items = proCollections.map((collection) => {
          const balance = Util.formatAmount(
            Number(collection.online_pending_total) +
              Number(collection.withdrawal_balance_available),
          )

          return {
            id: collection.id,
            name: `${collection.name} (${balance})`,
            url: `/collection/${collection.id}`,
          }
        })

        setCollectionItems(items)
      })
      .catch(() => {
        // noop
      })
  }, [])

  return (
    <WebUI.Alert
      aria-label="Withdraw funds to downgrade subscription plan"
      initialVisible
      onDidHide={() => navigate('..')}
    >
      <WebUI.AlertHeader>Withdraw funds to downgrade</WebUI.AlertHeader>
      <WebUI.AlertContentView
        className="max-h-[400px] overflow-y-scroll"
        text={
          <>
            The following collections have funds remaining. Once the funds are
            withdrawn, you will be able to downgrade:
            {Array.isArray(collectionItems) && collectionItems.length > 0 && (
              <ul className="mb-4">
                {collectionItems.map((collectionItem) => (
                  <li key={collectionItem.id} className="my-1">
                    <WebUI.HStack className="items-baseline gap-2">
                      <span>
                        <WebUI.PhosphorIcon
                          icon="check"
                          width={14}
                          color="#287991"
                        />
                      </span>
                      <Link
                        className="font-light text-ds-base text-gray800"
                        to={collectionItem.url}
                      >
                        {collectionItem.name}
                      </Link>
                    </WebUI.HStack>
                  </li>
                ))}
              </ul>
            )}
          </>
        }
        actions={
          <>
            <WebUI.AlertActionButton
              execute={() =>
                navigate('/withdraw', {state: {prevPath: location.pathname}})
              }
            >
              Withdraw Funds
            </WebUI.AlertActionButton>
            <WebUI.AlertCancelButton />
          </>
        }
      />
    </WebUI.Alert>
  )
}

export default PlansBasicErrorPage
