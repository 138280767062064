import {api} from '@cheddarup/api-client'
import * as Util from '@cheddarup/util'

export const memoizedFetchCheckEmailExistence = Util.memoize(
  api.auth.checkEmailExistence.fetch,
  {
    isMatchingKey: ([{body: bodyA}], [{body: bodyB}]) =>
      bodyA.email === bodyB.email,
    isPromise: true,
    maxSize: Number.POSITIVE_INFINITY,
  },
)
