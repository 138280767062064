import {useNavigate} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'

import PayerRouter from '../../c/PayerRouter'

export const CollectionDraftPreviewPage = () => {
  const navigate = useNavigate()

  return (
    <WebUI.Modal
      aria-label="Collection payer preview"
      contentViewAppearance="overlay"
      onDidHide={() => navigate('..')}
    >
      <PayerRouter shouldRenderModalCloseButton />
    </WebUI.Modal>
  )
}
