import {generic} from '@cheddarup/react-util'
import type {FieldGroupTypeLabelled, FieldGroupValue} from '@cheddarup/core'

import {AddressFieldsEdit} from './AddressFieldsEdit'
import {CheckboxFieldsEdit} from './CheckboxFieldsEdit'
import {DateFieldsEdit} from './DateFieldsEdit'
import {EmailFieldsEdit} from './EmailFieldsEdit'
import {SignatureFieldsEdit} from './SignatureFieldsEdit'
import {FileFieldsEdit} from './FileFieldsEdit'
import {FullNameFieldsEdit} from './FullNameFieldsEdit'
import {MultipleChoiceFieldsEdit} from './MultipleChoiceFieldsEdit'
import {PhoneFieldsEdit} from './PhoneFieldsEdit'
import {TextFieldsEdit} from './TextFieldsEdit'
import {TextMultilineFieldsEdit} from './TextMultilineFieldsEdit'
import {TimeFieldsEdit} from './TimeFieldsEdit'

export interface FieldGroupFieldsEditProps<
  TGroupType extends FieldGroupTypeLabelled,
  TLabels extends
    FieldGroupToFieldLabelKeysMap[TGroupType] = FieldGroupToFieldLabelKeysMap[TGroupType],
> {
  focused?: boolean
  fieldGroupType: TGroupType
  initialValue: FieldGroupValue<TGroupType>
  initialLabels: TLabels
  placeholders: TLabels
  required?: boolean
  onValueChange: (value: FieldGroupValue<TGroupType>) => void
  onLabelChange: <TKey extends keyof TLabels>(key: TKey, value: string) => void
}

export const FieldGroupFieldsEdit = generic(
  <
    TGroupType extends FieldGroupTypeLabelled,
    TLabels extends FieldGroupToFieldLabelKeysMap[TGroupType],
  >({
    fieldGroupType,
    initialLabels,
    placeholders,
    initialValue,
    onValueChange,
    ...restProps
  }: FieldGroupFieldsEditProps<TGroupType, TLabels>) => {
    switch (fieldGroupType) {
      case 'address':
        return (
          <AddressFieldsEdit
            initialLabels={
              initialLabels as FieldGroupToFieldLabelKeysMap['address']
            }
            placeholders={
              placeholders as FieldGroupToFieldLabelKeysMap['address']
            }
            {...restProps}
          />
        )
      case 'text_multiline':
        return (
          <TextMultilineFieldsEdit
            initialLabels={
              initialLabels as FieldGroupToFieldLabelKeysMap['text_multiline']
            }
            placeholders={
              placeholders as FieldGroupToFieldLabelKeysMap['text_multiline']
            }
            {...restProps}
          />
        )
      case 'full_name':
        return (
          <FullNameFieldsEdit
            initialLabels={
              initialLabels as FieldGroupToFieldLabelKeysMap['full_name']
            }
            placeholders={
              placeholders as FieldGroupToFieldLabelKeysMap['full_name']
            }
            {...restProps}
          />
        )
      case 'email':
        return (
          <EmailFieldsEdit
            initialLabels={
              initialLabels as FieldGroupToFieldLabelKeysMap['email']
            }
            placeholders={
              placeholders as FieldGroupToFieldLabelKeysMap['email']
            }
            {...restProps}
          />
        )
      case 'date':
        return (
          <DateFieldsEdit
            initialLabels={
              initialLabels as FieldGroupToFieldLabelKeysMap['date']
            }
            placeholders={placeholders as FieldGroupToFieldLabelKeysMap['date']}
            {...restProps}
          />
        )
      case 'phone':
        return (
          <PhoneFieldsEdit
            initialLabels={
              initialLabels as FieldGroupToFieldLabelKeysMap['phone']
            }
            placeholders={
              placeholders as FieldGroupToFieldLabelKeysMap['phone']
            }
            {...restProps}
          />
        )
      case 'text':
        return (
          <TextFieldsEdit
            initialLabels={
              initialLabels as FieldGroupToFieldLabelKeysMap['text']
            }
            placeholders={placeholders as FieldGroupToFieldLabelKeysMap['text']}
            {...restProps}
          />
        )
      case 'multiple_choice':
        return (
          <MultipleChoiceFieldsEdit
            initialLabels={
              initialLabels as FieldGroupToFieldLabelKeysMap['multiple_choice']
            }
            placeholders={
              placeholders as FieldGroupToFieldLabelKeysMap['multiple_choice']
            }
            initialValue={initialValue ?? []}
            onValueChange={onValueChange as any}
            {...restProps}
          />
        )
      case 'checkbox':
        return (
          <CheckboxFieldsEdit
            initialLabels={
              initialLabels as FieldGroupToFieldLabelKeysMap['checkbox']
            }
            placeholders={
              placeholders as FieldGroupToFieldLabelKeysMap['checkbox']
            }
            initialValue={initialValue ?? []}
            onValueChange={onValueChange as any}
            {...restProps}
          />
        )
      case 'time':
        return (
          <TimeFieldsEdit
            initialLabels={
              initialLabels as FieldGroupToFieldLabelKeysMap['time']
            }
            placeholders={placeholders as FieldGroupToFieldLabelKeysMap['time']}
            {...restProps}
          />
        )
      case 'file':
        return (
          <FileFieldsEdit
            initialLabels={
              initialLabels as FieldGroupToFieldLabelKeysMap['file']
            }
            placeholders={placeholders as FieldGroupToFieldLabelKeysMap['file']}
            {...restProps}
          />
        )
      case 'signature':
        return (
          <SignatureFieldsEdit
            initialLabels={
              initialLabels as FieldGroupToFieldLabelKeysMap['signature']
            }
            placeholders={
              placeholders as FieldGroupToFieldLabelKeysMap['signature']
            }
            {...restProps}
          />
        )
      default:
        return null
    }
  },
)

// MARK: – Helpers

export interface FieldGroupToFieldLabelKeysMap
  extends Record<
    FieldGroupTypeLabelled,
    {[TKey in Api.TabObjectFieldIdentifier | 'value']?: string}
  > {
  address: {
    line1: string
    line2: string
    city: string
    state: string
    zip: string
  }
  full_name: {
    first_name: string
    last_name: string
  }
  signature_and_initials: {
    legal_signature: string
    legal_initials: string
    value: string
  }
  file: {value: string}
  email: {value: string}
  phone: {value: string}
  text: {value: string}
  text_multiline: {value: string}
  multiple_choice: {value: string}
  checkbox: {value: string}
  date: {value: string}
  time: {value: string}
  signature: {value: string}
  initials: {value: string}
}
