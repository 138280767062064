export function isFormsEmpty(publicCollection: Api.PublicTab) {
  return (
    !publicCollection.signups.some((s) => s.visible_spots.length > 0) &&
    !publicCollection.forms.some((f) => f.fields.length > 0)
  )
}

export function isFormsBeforeItems(publicCollection: Api.PublicTab) {
  return (
    (publicCollection.items.length === 0 ||
      publicCollection.displayOrder[0] === 'forms') &&
    !isFormsEmpty(publicCollection)
  )
}
