export function isBase64(
  candidate: string,
  options?: {mime?: 'required' | 'allowed' | 'none'},
) {
  let regex =
    '(?:[A-Za-z0-9+\\/]{4})*(?:[A-Za-z0-9+\\/]{2}==|[A-Za-z0-9+/]{3}=)?'
  const mimeRegex = '(data:\\w+\\/[a-zA-Z\\+\\-\\.]+;base64,)'

  if (options?.mime === 'required') {
    regex = mimeRegex + regex
  } else if (options?.mime === 'allowed') {
    regex = `${mimeRegex}?${regex}`
  }

  return new RegExp(`^${regex}$`, 'gi').test(candidate)
}
