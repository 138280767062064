import {Helmet, HelmetProps} from 'react-helmet-async'

const SignUpPageHelmet = ({defer = false, ...restProps}: HelmetProps) => (
  <Helmet defer={defer} {...restProps}>
    <meta
      property="description"
      content="Collect Money Online. Start for free."
    />
    <meta
      property="og:description"
      content="Collect Money Online. Start for free."
    />
    <meta
      property="twitter:description"
      content="Collect Money Online. Start for free."
    />
    <meta
      itemProp="name"
      content="Sign Up for Free - Collect Money Online for Groups - Cheddar Up"
    />
    <title>
      Sign Up for Free - Collect Money Online for Groups - Cheddar Up
    </title>
    <meta
      property="og:title"
      content="Sign Up for Free - Collect Money Online for Groups - Cheddar Up"
    />
    <meta
      property="twitter:title"
      content="Sign Up for Free - Collect Money Online for Groups - Cheddar Up"
    />
  </Helmet>
)

export default SignUpPageHelmet
