import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'

const NO_AMOUNT_PLACEHOLDER = '$ --.--'

export interface PrintShippingLabelInvoiceAmounts {
  postage: number
  signature: number | null
}

export interface PrintShippingLabelInvoiceProps {
  amounts: PrintShippingLabelInvoiceAmounts
  amountsVisible?: boolean
  titleHidden?: boolean
}

const PrintShippingLabelInvoice = ({
  amounts,
  amountsVisible,
  titleHidden,
}: PrintShippingLabelInvoiceProps) => {
  const signatureAmountVisible =
    typeof amounts.signature === 'number' && amountsVisible
  const total = amounts.postage + (amounts.signature ?? 0)

  return (
    <div className="font-normal text-ds-xs">
      {!titleHidden && <div className="mb-4 text-ds-sm">Shipping Costs</div>}
      <div
        className={WebUI.cn(
          'flex items-center justify-between',
          signatureAmountVisible ? 'mb-2' : 'mb-4',
        )}
      >
        <div>Postage Cost</div>
        <div className="text-right">
          {amountsVisible
            ? Util.formatAmount(amounts.postage)
            : NO_AMOUNT_PLACEHOLDER}
        </div>
      </div>
      {signatureAmountVisible && (
        <div className="mb-4 flex items-center justify-between">
          <div>Signature Confirmation</div>
          <div className="text-right">
            {Util.formatAmount(amounts.signature ?? 0)}
          </div>
        </div>
      )}
      <hr />
      <div className="mt-2 mb-4 flex items-center justify-between text-ds-sm">
        <div className="font-normal text-ds-base text-gray800">Total</div>
        <div className="text-right font-normal text-ds-md text-gray800">
          {amountsVisible ? Util.formatAmount(total) : NO_AMOUNT_PLACEHOLDER}
        </div>
      </div>
    </div>
  )
}

export default PrintShippingLabelInvoice
