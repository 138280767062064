import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'
import {Link} from 'src/components/Link'
import {useManagerRoleQuery} from 'src/components/ManageRoleProvider'

export interface MessageCenterPanelProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collectionId: number
}

export const MessageCenterPanel = ({
  collectionId,
  className,
  ...restProps
}: MessageCenterPanelProps) => {
  const [managerRoleQuery] = useManagerRoleQuery()
  const hasInvitesQuery = api.messages.list.useQuery(
    {
      queryParams: {
        message_type: 'invite',
        tab_id: collectionId,
        per_page: 1,
      },
    },
    {
      enabled:
        !managerRoleQuery.isPending &&
        (!managerRoleQuery.data ||
          !!managerRoleQuery.data.permissions.address_book_and_message_center),
      select: (res) => res.pagination.total > 0,
    },
  )
  const hasPayersQuery = api.tabMembers.list.useQuery(
    {
      pathParams: {
        tabId: collectionId,
      },
    },
    {
      select: (members) =>
        members.some((m) => m.payments && m.payments.length > 0),
    },
  )
  const isTabInDraftQuery = api.tabs.detail.useQuery(
    {
      pathParams: {
        tabId: collectionId,
      },
    },
    {
      select: (tab) => tab.status === 'draft',
    },
  )

  return (
    <WebUI.VStack
      className={WebUI.cn(
        `gap-6 p-7 [&_>_.Stack[aria-disabled="true"]]:pointer-events-none [&_>_.Stack[aria-disabled="true"]]:cursor-not-allowed [&_>_.Stack[aria-disabled="true"]]:opacity-50 [&_>_.Stack_>_.Link]:text-ds-sm [&_>_.Stack_>_span]:text-ds-xs`,
        className,
      )}
      as={WebUI.Panel}
      {...restProps}
    >
      <WebUI.VStack className="gap-3">
        <WebUI.Heading className="text-ds-sm uppercase" as="h2">
          Message Center
        </WebUI.Heading>
        <WebUI.Separator variant="primary" />
      </WebUI.VStack>

      <WebUI.VStack
        aria-disabled={!!isTabInDraftQuery.data || !hasInvitesQuery.data}
        className="gap-1"
      >
        <Link
          variant="primary"
          to="message-center?messageType=message&p=history"
        >
          Track Invitations
        </Link>
        <span>View your invitations and track recipients</span>
      </WebUI.VStack>

      <WebUI.Separator variant="primary" />

      <WebUI.VStack
        aria-disabled={
          !!isTabInDraftQuery.data ||
          (!hasPayersQuery.data && !hasInvitesQuery.data) ||
          (!!managerRoleQuery.data &&
            (managerRoleQuery.data.permissions?.role === 'viewer' ||
              !managerRoleQuery.data.permissions
                .address_book_and_message_center))
        }
        className="gap-1"
      >
        <Link variant="primary" to="message-center?messageType=reminder">
          Schedule Reminders
        </Link>
        <span>Schedule a reminder to invitees and payers</span>
      </WebUI.VStack>

      <WebUI.Separator variant="primary" />

      <WebUI.VStack
        aria-disabled={
          !!isTabInDraftQuery.data ||
          (!!managerRoleQuery.data &&
            (managerRoleQuery.data.permissions?.role === 'viewer' ||
              !managerRoleQuery.data.permissions
                .address_book_and_message_center))
        }
        className="gap-1"
      >
        <Link variant="primary" to="message-center?messageType=message">
          Message Your Group
        </Link>
        <span>Send a one-time message or followup</span>
      </WebUI.VStack>
    </WebUI.VStack>
  )
}
