import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {alertSchema} from '../schemas'

export default {
  list: makeEndpoint({
    path: 'users/alerts',
    responseSchema: alertSchema.array(),
  }),
  delete: makeEndpoint({
    path: 'users/alerts/:alertId',
    method: 'DELETE',
    responseSchema: z.void(),
  }),
}
