import {api} from '@cheddarup/api-client'
import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import {useMemo} from 'react'
import {Link} from 'src/components/Link'
import {LinkButton} from 'src/components/LinkButton'
import {TabTemplatesGrid} from 'src/components/TabTemplatesGrid'
import {useIsAuthed} from 'src/hooks/useAuthToken'
import {StringParam, useQueryParam, withDefault} from 'use-query-params'

import {templateCategoriesPickerOrder} from './TemplatesLayout'

const TemplatesOverviewPage = () => {
  const isLoggedIn = useIsAuthed()
  const [searchTerm] = useQueryParam('q', withDefault(StringParam, ''))
  const templatesQuery = api.templates.list.useQuery({
    queryParams: {
      orderBy: {
        key: 'categories',
      },
    },
  })

  const templates = useMemo(
    () =>
      templatesQuery.data?.filter(
        Util.fuzzyFilterIterator(searchTerm, {
          iterator: (template) =>
            `${template.name} ${template.collection_subcategory?.join(' ')}`,
        }),
      ) ?? [],
    [templatesQuery.data, searchTerm],
  )

  const categoryTitles = useMemo(() => {
    const categoriesOrder: Api.TemplateCollectionCategory[] = Util.unique([
      'Staff Picks',
      'Seasonal Collections',
      'Fundraisers',
      ...templateCategoriesPickerOrder,
    ] as const)

    return Util.sort(
      Util.unique(
        templates.flatMap((template) =>
          (template.options.template.categories || []).map((c) => c.type),
        ) ?? [],
      ),
    ).asc((ct) => {
      const index = categoriesOrder.indexOf(ct)
      return index > -1 ? index : Number.MAX_SAFE_INTEGER
    })
  }, [templates])

  return (
    <WebUI.VStack className="w-full max-w-[calc(1080px+2*theme(spacing.4))] self-center px-4 pb-16">
      <WebUI.VStack className="gap-16 pb-16">
        {templatesQuery.isLoading
          ? Array.from({length: 3}).map((_, idx) => (
              <TabTemplatesOverviewSection
                key={idx}
                categoryTitle=""
                loading
                templates={[]}
              />
            ))
          : categoryTitles.map((categoryTitle) => {
              const categoryTemplates = Util.sort(
                templates.filter((template) =>
                  (template.options.template.categories || []).some(
                    (c) => c.type === categoryTitle,
                  ),
                ),
              ).asc(
                (template) =>
                  (template.options.template.categories || []).find(
                    (cat) => cat.type === categoryTitle,
                  )?.order,
              )

              return (
                <TabTemplatesOverviewSection
                  key={categoryTitle}
                  categoryTitle={categoryTitle}
                  templates={categoryTemplates}
                />
              )
            })}

        {templates.length === 0 && (
          <WebUI.VStack className="items-center gap-4">
            <WebUI.PhosphorIcon icon="magnifying-glass" width={35} />
            <WebUI.VStack className="center gap-1 text-center">
              <WebUI.Heading as="h2">No results found</WebUI.Heading>
              <WebUI.Heading as="h3">
                Try a different search or{' '}
                <Link variant="primary" to="/templates">
                  view all
                </Link>
              </WebUI.Heading>
            </WebUI.VStack>
          </WebUI.VStack>
        )}
      </WebUI.VStack>

      <WebUI.RoundedButton
        className="w-[280px] self-center"
        as={LinkButton}
        size="large"
        variant="primary"
        to={isLoggedIn ? '/collections' : '/signup'}
      >
        Create your own — FREE
      </WebUI.RoundedButton>
    </WebUI.VStack>
  )
}

// MARK: – TabTemplatesOverviewSection

interface TabTemplatesOverviewSectionProps
  extends React.ComponentPropsWithoutRef<'div'> {
  loading?: boolean
  categoryTitle: string
  templates: Api.TabTemplate[]
}

const TabTemplatesOverviewSection: React.FC<
  TabTemplatesOverviewSectionProps
> = ({loading, categoryTitle, templates, className, ...restProps}) => (
  <WebUI.VStack className={WebUI.cn('gap-8', className)} {...restProps}>
    <WebUI.HStack className="items-baseline justify-between gap-3">
      {loading ? (
        <WebUI.Skeleton width={Util.randomInteger(140, 240)} height={24} />
      ) : (
        <WebUI.Heading className="font-bold" as="h2">
          {categoryTitle}
        </WebUI.Heading>
      )}
      <Link
        className="[&_>_.Anchor-content]:font-bold"
        variant="primary"
        to={`category/${categoryTitle}`}
      >
        View All
      </Link>
    </WebUI.HStack>

    <TabTemplatesGrid
      loading={loading}
      loadingCardsCount={3}
      templates={templates.slice(0, 3)}
    />
  </WebUI.VStack>
)

export default TemplatesOverviewPage
