import * as WebUI from '@cheddarup/web-ui'
import React from 'react'
import * as Util from '@cheddarup/util'

import {Link, LinkProps} from './Link'

export interface UpgradeTagProps
  extends Omit<WebUI.TagProps, 'children'>,
    Pick<Util.SetOptional<LinkProps, 'to'>, 'to'> {
  plan?: Extract<Api.SubscriptionPlanType, 'pro' | 'team'>
}

export const UpgradeTag = React.forwardRef<HTMLDivElement, UpgradeTagProps>(
  (
    {plan = 'pro', to = 'i/plans', tabIndex = -1, ...restProps},
    forwardedRef,
  ) => {
    const formattedPlan = Util.capitalize(plan)
    return (
      <WebUI.Tooltip>
        <WebUI.TooltipAnchor
          ref={forwardedRef}
          tabIndex={tabIndex}
          render={({className: innerClassName, ...innerRestProps}) => (
            <Link className={innerClassName} preserveSearch to="i/plans">
              <WebUI.Tag
                className="bg-natural-80 text-ds-xs text-linkPrimaryText"
                {...innerRestProps}
              />
            </Link>
          )}
          {...restProps}
        >
          {formattedPlan}
        </WebUI.TooltipAnchor>
        <WebUI.TooltipContent variant="light">
          You’ll need to upgrade to our{' '}
          <Link variant="primary" to={to}>
            {formattedPlan} Plan
          </Link>{' '}
          when you publish your collection page
        </WebUI.TooltipContent>
      </WebUI.Tooltip>
    )
  },
)
