import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'

const PartnerBenefitsPanel = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => {
  const {data: orgData} = api.auth.session.useQuery(undefined, {
    select: (session) => session.organization_data,
  })

  if (!orgData) {
    return null
  }

  const orgLogoSrc = orgData.logo
  const partnerBenefitsLink = orgData.partnerBenefitsLink

  return (
    <WebUI.Panel
      className={WebUI.cn('flex flex-col gap-3 px-8 py-6', className)}
      {...restProps}
    >
      <div className="mb-2 font-normal text-ds-xs text-gray400">PARTNER</div>
      <div className="flex flex-col gap-4">
        <img
          className="max-h-[70px] object-contain object-[0]"
          alt="Org logo"
          src={orgLogoSrc}
        />
        <div className="flex flex-row">
          <WebUI.AnchorButton
            variant="default"
            href={partnerBenefitsLink || `i/orgs/${orgData?.slug}/how-it-works`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Partner Resources
          </WebUI.AnchorButton>
        </div>
      </div>
    </WebUI.Panel>
  )
}

export default PartnerBenefitsPanel
