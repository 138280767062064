import type {TaggedUnion} from '@rorystokes/ad-ts/match'

// Adapted from https://github.com/devexperts/remote-data-ts

export interface AsyncResultInitial {
  readonly _tag: 'AsyncResultInitial'
}

export interface AsyncResultPending {
  readonly _tag: 'AsyncResultPending'
}

export interface AsyncResultFailure<E> {
  readonly _tag: 'AsyncResultFailure'
  readonly error: E
}

export interface AsyncResultSuccess<A> {
  readonly _tag: 'AsyncResultSuccess'
  readonly value: A
}

/**
 * @see https://medium.com/@gcanti/slaying-a-ui-antipattern-with-flow-5eed0cfb627b
 */
export type AsyncResult<E, A> = TaggedUnion<{
  initial: AsyncResultInitial
  pending: AsyncResultPending
  failure: AsyncResultFailure<E>
  success: AsyncResultSuccess<A>
}>

export type AsyncResultErrorType<R> = R extends AsyncResult<infer E, any>
  ? E
  : never

export type AsyncResultValueType<R> = R extends AsyncResult<any, infer A>
  ? A
  : never

// MARK: - Constructors

export const initial: AsyncResult<never, never> = {
  _tag: 'AsyncResultInitial',
}

export const pending: AsyncResult<never, never> = {
  _tag: 'AsyncResultPending',
}

export function failure<E>(error: E): AsyncResult<E, never> {
  return {
    _tag: 'AsyncResultFailure',
    error,
  }
}

export function success<A>(value: A): AsyncResult<never, A> {
  return {
    _tag: 'AsyncResultSuccess',
    value,
  }
}

// MARK: - Guards

export function isFailure<E>(
  res: AsyncResult<E, unknown>,
): res is AsyncResultFailure<E> {
  return res._tag === 'AsyncResultFailure'
}

export function isSuccess<A>(
  res: AsyncResult<unknown, A>,
): res is AsyncResultSuccess<A> {
  return res._tag === 'AsyncResultSuccess'
}

export function isPending(
  res: AsyncResult<unknown, unknown>,
): res is AsyncResultPending {
  return res._tag === 'AsyncResultPending'
}

export function isInitial(
  res: AsyncResult<unknown, unknown>,
): res is AsyncResultInitial {
  return res._tag === 'AsyncResultInitial'
}

// MARK: - Helpers

export function toNullable<E, A>(res: AsyncResult<E, A>): A | null {
  return isSuccess(res) ? res.value : null
}

export function toUndefined<E, A>(res: AsyncResult<E, A>): A | undefined {
  return isSuccess(res) ? res.value : undefined
}

export function mergeAsyncResults<TDataTuple extends [any, ...any[]]>(
  ...results: {[K in keyof TDataTuple]: AsyncResult<Error, TDataTuple[K]>}
): AsyncResult<Error, TDataTuple> {
  for (const res of results) {
    if (isFailure(res)) {
      // TODO: Combine messages from all results?
      return failure(res.error as Error)
    }
  }
  for (const res of results) {
    if (isInitial(res)) {
      return initial
    }
    if (isPending(res)) {
      return res
    }
  }

  return success(
    (results as Array<AsyncResultSuccess<TDataTuple[number]>>).map(
      (res) => res.value,
    ) as TDataTuple,
  )
}
