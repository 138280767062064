import * as Ariakit from '@ariakit/react'
import React from 'react'

import {cn, VariantsProps} from '../../utils'
import {cva} from 'class-variance-authority'

export const nextAnchor = cva(
  [
    'group/anchor relative cursor-pointer overflow-hidden transition-colors',
    'text-ellipsis whitespace-nowrap font-body',
  ],
  {
    variants: {
      variant: {
        default: 'text-teal-600 transition-colors hover:text-teal-600/50',
        headless: '',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
)

export interface NextAnchorProps
  extends VariantsProps<typeof nextAnchor>,
    Ariakit.RoleProps<'a'> {}

export const NextAnchor = React.forwardRef<HTMLAnchorElement, NextAnchorProps>(
  ({variant, className, ...restProps}, forwardedRef) => (
    <Ariakit.Role.a
      ref={forwardedRef}
      className={cn(nextAnchor({variant}), className)}
      {...restProps}
    />
  ),
)
