import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'
import * as Util from '@cheddarup/util'
import {Link} from 'src/components/Link'
import {LinkButton} from 'src/components/LinkButton'
import {useLocation} from 'react-router-dom'

export interface WithdrawFundsAlertProps extends WebUI.AlertProps {
  intent: 'delete-account' | 'transfer-account'
}

export const WithdrawFundsAlert = React.forwardRef<
  WebUI.DialogInstance,
  WithdrawFundsAlertProps
>(({intent, ...restProps}, forwardedRef) => {
  const location = useLocation()

  const intentLabel = {
    'delete-account': 'delete',
    'transfer-account': 'transfer',
  }[intent]

  const tabsQuery = api.tabs.list.useQuery(undefined, {
    select: (tabs) =>
      tabs.filter((c) => c.withdrawal_balance_available > 0 && c.access?.owner),
  })

  return (
    <WebUI.Alert ref={forwardedRef} aria-label="Withdrawal" {...restProps}>
      <WebUI.AlertHeader>
        Withdraw funds to {intentLabel} your account
      </WebUI.AlertHeader>
      <WebUI.AlertContentView
        className="overflow-y-auto"
        text={
          <div className="flex flex-col gap-3">
            The following collections have funds remaining and/or pending. Once
            the funds are withdrawn, you will be able to {intentLabel} your
            account.
            {tabsQuery.data && tabsQuery.data.length > 0 && (
              <div className="flex flex-col gap-2">
                {tabsQuery.data.map((t) => (
                  <Link
                    key={t.id}
                    className="font-light"
                    iconBefore={
                      <WebUI.PhosphorIcon
                        icon="check"
                        width={14}
                        color="#287991"
                      />
                    }
                    to={`/collection/${t.id}`}
                  >
                    {`${t.name.slice(0, 40)} (${Util.formatAmount(
                      Number(t.online_pending_total) +
                        Number(t.withdrawal_balance_available),
                    )})`}
                  </Link>
                ))}
              </div>
            )}
          </div>
        }
        actions={
          <>
            <WebUI.AlertActionButton
              as={LinkButton}
              to="/withdraw"
              state={{prevPath: location.pathname}}
            >
              Withdraw Funds
            </WebUI.AlertActionButton>
            <WebUI.AlertCancelButton />
          </>
        }
      />
    </WebUI.Alert>
  )
})
