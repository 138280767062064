import {Query} from '@tanstack/react-query'
import {hasSubObject} from '@cheddarup/util'

import {AnyEndpoint, EndpointKey} from '../utils'
import {QueryKey} from './use-query'

// TODO: Cover with tests stage 0 @Binur
export function makeQueryPredicate(queryKey: QueryKey<AnyEndpoint>) {
  return function predicate(query: Query) {
    const [updatePath, updateInput] = queryKey
    const [queryPath, queryInput] = query.queryKey as EndpointKey

    const isBaseEqual =
      queryPath === updatePath &&
      hasSubObject(queryInput.pathParams ?? {}, updateInput.pathParams ?? {})

    if ('queryParams' in updateInput) {
      return (
        isBaseEqual &&
        'queryParams' in queryInput &&
        hasSubObject(
          (queryInput.queryParams as any) ?? {},
          (updateInput.queryParams as any) ?? {},
        )
      )
    }

    return isBaseEqual
  }
}
