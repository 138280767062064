import {api} from '@cheddarup/api-client'
import * as WebUI from '@cheddarup/web-ui'
import {Route, useParams} from 'react-router-dom'
import PayerHelpPage from 'src/views/c/PayerHelpPage'
import PayerSharePage from 'src/views/c/PayerSharePage'
import NotFoundPage from 'src/views/NotFoundPage'

export const getMeRoutes = (isUserSlug = false) => [
  <Route
    key="help"
    path="help"
    element={<PayerHelpPage isUserSlug={isUserSlug} />}
  />,
  <Route key="share" path="share" element={<PayerSharePage />} />,
]

// MARK: - Ensurers

export const EnsureGroupPage: React.FC<{children: React.ReactNode}> = ({
  children,
}) => {
  const urlParams = useParams()
  const {data: groupPage, isPending} = api.groupPages.home.useQuery({
    // biome-ignore lint/style/noNonNullAssertion: <explanation>
    pathParams: {slug: urlParams.user!},
  })

  if (isPending) {
    return (
      <WebUI.VStack className="grow items-center justify-center">
        <WebUI.Loader />
      </WebUI.VStack>
    )
  }

  if (!groupPage) {
    return <NotFoundPage />
  }

  return <>{children}</>
}
