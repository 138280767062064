import queryString from 'query-string'
import * as Util from '@cheddarup/util'

import {apiClient} from './client'
import {api} from '@cheddarup/api-client'

export async function uploadCollectionLogo({tabId, image, thumbnail}: any) {
  const objectName = generateObjectName({prefixes: [tabId], image})
  const parentPath = `users/tabs/${tabId}`

  try {
    const {uploadPath, signedUrl} = await getSignedUrl({
      parentPath,
      objectName,
      thumbnail,
      image,
    })

    await uploadImageToS3({
      signedUrl,
      image,
      contentType: image.type,
    })

    return createImageRecord({
      parentPath,
      objectName,
      uploadPath,
      thumbnail,
      image,
    })
  } catch {
    return undefined
  }
}

export async function uploadCartFile(
  file: File,
  {collectionSlug, cartUuid}: {collectionSlug: string; cartUuid: string},
) {
  const objectName = generateObjectName({prefixes: [cartUuid], image: file})

  const getCartSignedUrl = async () => {
    const res = await apiClient.post(
      `collections/${collectionSlug}/carts/${cartUuid}/sign`,
      {
        objectName: encodeURIComponent(objectName),
        contentType: file.type,
      },
    )

    return res.data.signedUrl
  }

  try {
    const signedUrl = await getCartSignedUrl()

    return uploadImageToS3({
      signedUrl,
      image: file,
      contentType: file.type,
      contentEncoding: 'base64',
    })
  } catch {
    return undefined
  }
}

export async function uploadFieldViewFile(
  file: File | Blob,
  {tabId, paymentId}: {tabId: number; paymentId: number},
) {
  const objectName = generateObjectName({
    prefixes: [String(paymentId)],
    image: file,
  })

  const getFieldViewSignedUrl = async () => {
    const res = await api.fieldViews.createSignedUrl.fetch({
      pathParams: {
        tabId,
        paymentId,
      },
      body: {
        objectName: encodeURIComponent(objectName),
        contentType: file.type,
      },
    })

    return res.signedUrl
  }

  try {
    const signedUrl = await getFieldViewSignedUrl()

    return uploadImageToS3({
      signedUrl,
      image: file,
      contentType: file.type,
      contentEncoding: 'base64',
    })
  } catch {
    return undefined
  }
}

export async function uploadSignatureBase64(
  {dataUri, objectName, contentType}: any,
  {collectionSlug, cartUuid}: {collectionSlug: string; cartUuid: string},
) {
  const getCartSignedUrl = async () => {
    const res = await apiClient.post(
      `collections/${collectionSlug}/carts/${cartUuid}/sign`,
      {objectName: encodeURIComponent(objectName), contentType},
    )

    return res.data.signedUrl
  }

  try {
    const signedUrl = await getCartSignedUrl()
    const image = base64ToUploadableData(dataUri)

    return uploadImageToS3({
      signedUrl,
      image,
      contentType,
      contentEncoding: 'base64',
    })
  } catch {
    return undefined
  }
}

export async function uploadBannerImage({image, thumbnail, userId}: any) {
  const objectName = generateObjectName({prefixes: ['banner'], image})
  const parentPath = 'user'

  try {
    const {uploadPath, signedUrl} = await getSignedUrl({
      parentPath,
      objectName,
      thumbnail,
      image,
      userId,
    })

    await uploadImageToS3({
      signedUrl,
      image,
      contentType: image.type,
    })

    return createImageRecord({
      parentPath,
      objectName,
      uploadPath,
      thumbnail,
      image,
      userId,
    })
  } catch {
    return undefined
  }
}

export async function uploadMessageAttachment(
  file: Blob | File,
  options: {tabId?: number | null},
) {
  const objectName = `${Util.makeShortId()}-${
    'name' in file ? `-${file.name.replace(/[^\s\w.]/gi, '')}` : ''
  }`

  try {
    const {signedUrl} = await getMessageAttachmentSignedUrl({
      objectName,
      file,
      tabId: options.tabId,
    })

    const url = await uploadImageToS3({
      signedUrl,
      image: file,
      contentType: file.type,
    })

    return {
      file_name: objectName,
      content_type: file.type,
      url,
    }
  } catch {
    return undefined
  }
}

// MARK: – Helpers

const base64ToUploadableData = (dataUri: string) => {
  const base64Data = dataUri.split(',')[1]
  const binary = window.atob(base64Data ?? '')

  return new Uint8Array(
    binary
      .split('')
      // biome-ignore lint/style/noNonNullAssertion:
      .map((_char, idx) => binary.codePointAt(idx)!),
  )
}

const uploadImageToS3 = async ({
  signedUrl,
  image,
  contentType,
  contentEncoding,
}: {
  signedUrl: string
  image: any
  contentType: string
  contentEncoding?: string
}) => {
  try {
    const res = await fetch(signedUrl, {
      body: image,
      mode: 'cors',
      method: 'PUT',
      headers: {
        'Content-Type': contentType,
        ...(contentEncoding ? {'Content-Encoding': contentEncoding} : {}),
        'x-amz-acl': 'public-read',
      },
    })
    const imageUrlParsed = queryString.parseUrl(res.url)

    return imageUrlParsed.url
  } catch {
    return undefined
  }
}

const getSignedUrl = async ({
  parentPath,
  objectName,
  thumbnail,
  image,
  userId,
  pintura,
}: {
  parentPath: string
  objectName: string
  thumbnail: any
  image: any
  userId?: number
  pintura?: any
}) => {
  const res = await apiClient.post(
    `${parentPath}/signed_upload_url`,
    {
      objectName,
      metadata: {
        contentType: image.type,
        pintura,
        thumbnail,
      },
    },
    {
      headers: {
        'X-Manager-Account-Id': userId,
      },
    },
  )

  return res.data
}

const getMessageAttachmentSignedUrl = async ({
  objectName,
  file,
  tabId,
}: {
  objectName: string
  file: Blob
  tabId?: number | null
}) => {
  const res = await apiClient.post(
    'users/messages/sign',
    {
      objectName,
      contentType: file.type,
    },
    {
      params: {
        tab_id: tabId,
      },
    },
  )
  return res.data
}

const createImageRecord = async ({
  parentPath,
  objectName,
  uploadPath,
  thumbnail,
  pintura,
  image,
  type,
  userId,
}: any) => {
  const res = await apiClient.post(
    `${parentPath}/create_image_record`,
    {
      objectName,
      upload_path: uploadPath,
      metadata: {
        contentType: image.type,
        thumbnail,
        pintura,
        type,
      },
    },
    {
      headers: {
        'X-Manager-Account-Id': userId,
      },
    },
  )

  return res.data
}

// MARK: – Helpers

const generateObjectName = ({
  prefixes = [],
  image,
}: {
  prefixes: string[]
  image: any
}) =>
  `${Util.makeShortId()}-${prefixes.join('-')}${
    image.name ? `-${image.name.replace(/[^\s\w.]/gi, '')}` : ''
  }`
