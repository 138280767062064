import React from 'react'

import {Merge} from '@cheddarup/util'
import {cn} from '../utils'
import {Input, InputProps} from './__next'

export interface InitialsInputProps
  extends Merge<React.ComponentPropsWithoutRef<'input'>, InputProps> {}

export const InitialsInput = React.forwardRef<
  HTMLInputElement,
  InitialsInputProps
>(({className, onKeyDown, onInput, ...restProps}, forwardedRef) => {
  return (
    <Input
      ref={forwardedRef}
      className={cn(
        'min-w-[2em] rounded-none text-center font-cursive',
        className,
      )}
      autoCapitalize="characters"
      maxLength={2}
      pattern="^[a-zA-Z]*$"
      onKeyDown={(event) => {
        onKeyDown?.(event)

        if (event.defaultPrevented) {
          return
        }

        if (!/[\p{Letter}\p{Mark}]+/gu.test(event.key)) {
          event.preventDefault()
        }
      }}
      onInput={(event) => {
        onInput?.(event)

        if (event.defaultPrevented) {
          return
        }

        if ('value' in event.target && typeof event.target.value === 'string') {
          event.target.value = event.target.value.toUpperCase()
        }
      }}
      {...restProps}
    />
  )
})
